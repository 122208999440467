import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {DeleteAuthorizationModalComponent} from './delete-authorization-modal/delete-authorization-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorWrapperConfig} from '../../common/components/error-wrapper/error-wrapper.component';
import {
  ApplicationConstants,
  ErrorTypes,
  SessionStorageKeys,
  UserTypeQualifier
} from '../../common/constants/application.constants';
import {
  getFormattedFullName,
  isNullOrUndefined,
  isStringNullUndefinedOrEmpty, openDialog,
  trimWhitespaceFromControlValue
} from '../../common/utility';
import {Subscriber, Subscription} from 'rxjs';
import {
  AddDependentRequest,
  BusinessMemberPoliciesResults,
  ConsumerResultsData,
} from '../../models/consumer';
import {MembershipName} from '../../models/membership';
import {AuthorizationsService} from '../../common/services/data-model/app/authorizations/authorizations.service';
import {VisionServiceRequest} from '../../models/VisionServiceRequestResults';
import {CustomValidatorsService} from '../../common/services/support/custom-validators/custom-validators.service';
import {DatePickerConfiguration} from '../../common/components/date-picker/date-picker.component';
import {EligibilityService} from '../../common/services/data-model/app/eligibility/eligibility.service';
import {Eligibility} from '../../models/eligibility';
import {ClaimFormNavigationService} from '../../common/services/support/claim-form-navigation/claim-form-navigation.service';
import {ConsumerService} from '../../common/services/data-model/app/consumer/consumer.service';
import {Observable} from 'rxjs';
import {combineLatest} from 'rxjs';
import {take} from 'rxjs/operators';

enum RelationToMember {
  Member = 'M',
  Spouse = 'S',
  Child = 'C',
  Student = 'T',
  DisabledParent = 'D',
  DomesticPartner = 'P',
  DisabledDependent = 'H',
  Other = 'O'
}

enum RelationDescription {
  Member = 'Member',
  Spouse = 'Spouse',
  Child = 'Child',
  Student = 'Student',
  DisabledParent = 'Disabled Parent',
  DomesticPartner = 'Domestic Partner',
  DisabledDependent = 'Disabled Dependent',
  Other = 'Other'
}

enum CoverageCode {
  MemberPlusFamily = 'A',
  MemberPlusOne = 'B',
  MemberOnly = 'C',
  MemberPlusChildren = 'D',
  Unknown = 'U'
}
enum CoverageCodeDescription {
  MemberPlusFamily = 'Member Plus Family',
  MemberPlusOne = 'Member Plus One',
  MemberOnly = 'Member Only',
  MemberPlusChildren = 'Member Plus Children',
  Unknown = 'Unknown'
}

interface RelationShipOptionCode {
  readonly relation: string;
  readonly relationCode: string;
}

@Component({
  selector: 'app-patient-selection',
  templateUrl: './patient-selection.component.html',
  styleUrls: ['./patient-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PatientSelectionComponent implements OnInit, OnDestroy, AfterViewInit {

  /***** START - PRIVATE MEMBERS *****/
  private _addDependentLink: string;
  private _businessMemberPoliciesResults: BusinessMemberPoliciesResults;
  private observableSubscriptions: Subscription[] = [];
  /***** END - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/
  @ViewChild(MatTable, {static: true}) authorizationSelectionResultTable: MatTable<any>;
  @ViewChild(MatSort, {static: true}) authorizationSelectionResultSort: MatSort;
  @ViewChild(MatTable, {static: true}) patientSelectionResultTable: MatTable<any>;
  @ViewChild(MatSort, {static: true}) patientSelectionResultSort: MatSort;
  @ViewChild('addDependentForm', {read: FormGroupDirective, static: true}) addDependentForm: NgForm;

  searchPlansData = {
    lastName: '',
    firstName: '',
    relationship: '',
    dateOfBirth: ''
  };
  loadingAuthorizations: boolean;
  initialLoadingOfAuthorizations: boolean;
  // options to display in the Relationship dropdown in the Search Plans form
  searchPlansRelationshipOptions: RelationShipOptionCode[] = [
    {
      relation: 'Spouse',
      relationCode: RelationToMember.Spouse
    },
    {
      relation: 'Domestic Partner',
      relationCode: RelationToMember.DomesticPartner
    },
    {
      relation: 'Child',
      relationCode: RelationToMember.Child
    },
    {
      relation: 'Student',
      relationCode: RelationToMember.Student
    },
    {
      relation: 'Disabled Dependent',
      relationCode: RelationToMember.DisabledDependent
    },
    {
      relation: 'Dependent Parent',
      relationCode: RelationToMember.DisabledParent
    }
  ];
  today = ApplicationConstants.todaysDate;
  patientSelectionAddDependentForm: FormGroup;
  errorWrapperConfig = {
    lastName: new ErrorWrapperConfig(),
    firstName: new ErrorWrapperConfig(),
    relationship: new ErrorWrapperConfig(),
  };
  datePickerConfigurationDateOfBirth: DatePickerConfiguration;
  visionServiceRequestSearchResults: VisionServiceRequest[];
  visionServiceRequestsLink: string;
  membershipCoverageText: string;
  memberFullName: string;
  authorizationSelectionTableDataSource: MatTableDataSource<VisionServiceRequest> = new MatTableDataSource();
  authorizationSelectionColumnHeaders: string[] = ['authorization', 'name', 'product', 'expires', 'delete'];
  searchResults: ConsumerResultsData[];
  searchResultsData: ConsumerResultsData[] = [];
  patientSelectionTableDataSource: MatTableDataSource<ConsumerResultsData> = new MatTableDataSource();
  patientSelectionColumnHeaders: string[] = ['name', 'relationToSubscriber', 'dateOfBirth'];
  readonly firstNameMaxLength: number = ApplicationConstants.maxFirstNameLength;
  readonly lastNameMaxLength: number = ApplicationConstants.maxLastNameLength;

  /***** END - PUBLIC MEMBERS *****/

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private consumerService: ConsumerService,
    private authorizationsService: AuthorizationsService,
    private customValidatorsService: CustomValidatorsService,
    private eligibilityService: EligibilityService,
    private claimFormNavigationService: ClaimFormNavigationService
  ) {
  }

  /***** START - PROPERTY ACCESSORS *****/
  private get addDependentLink(): string {
    return this._addDependentLink;
  }

  private set addDependentLink(link: string) {
    this._addDependentLink = link;
  }

  get isNonTrackingDependentClient(): boolean {
    return !isStringNullUndefinedOrEmpty(this.addDependentLink);
  }

  get businessMemberPoliciesResults(): BusinessMemberPoliciesResults {
    return (this._businessMemberPoliciesResults) ? Object.deepClone(this._businessMemberPoliciesResults) : this._businessMemberPoliciesResults;
  }

  set businessMemberPoliciesResults(businessMemberPoliciesResults: BusinessMemberPoliciesResults) {
    this._businessMemberPoliciesResults = businessMemberPoliciesResults;
  }

  /***** END - PROPERTY ACCESSORS *****/

  /***** START - PRIVATE METHODS *****/
  private closeAllPendingDialogs = (): void => {
    if (this.dialog.openDialogs && this.dialog.openDialogs.length > 0) {
      this.dialog.closeAll();
    }
  }

  private buildForm(): void {
    this.patientSelectionAddDependentForm = this.formBuilder.group({
      lastName: ['', [Validators.required, Validators.pattern(ApplicationConstants.ValidNameRegex), Validators.minLength(2)]],
      firstName: ['', [Validators.required, Validators.pattern(ApplicationConstants.ValidNameRegex), Validators.minLength(2)]],
      relationship: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(ApplicationConstants.minDate)]]
    });
    this.patientSelectionAddDependentForm.valueChanges.subscribe((viewModel) => {
      // Build up the data model based on changes to the view model
      Object.keys(viewModel).forEach((viewModelKey) => {
        this.searchPlansData[viewModelKey] = viewModel[viewModelKey];
      });
    });
  }

  private loadAuthorizationTable(): Observable<void> {
    return new Observable((observer: Subscriber<void>) => {
      this.visionServiceRequestSearchResults = this.authorizationsService.authorizations;
      this.loadingAuthorizations = false;

      if (this.visionServiceRequestSearchResults) {
        this.authorizationSelectionTableDataSource.data = this.visionServiceRequestSearchResults;
        observer.complete();
      } else {
        // check if authorization search parameters are in session storage, if they are recall vsr search API to return the VSRs to
        // populate the authorization table
        const visionServiceRequestsLink = this.authorizationsService.getSessionStoredAuthorizationSearchLink();
        if (!isNullOrUndefined(visionServiceRequestsLink) &&
          !isStringNullUndefinedOrEmpty(visionServiceRequestsLink.href)
        ) {
          this.initialLoadingOfAuthorizations = true;
          this.loadingAuthorizations = true;
          this.authorizationsService.searchAuthorizations(visionServiceRequestsLink.href).subscribe(authSearchResults => {
            if (authSearchResults) {
              this.visionServiceRequestSearchResults = authSearchResults;
              this.authorizationSelectionTableDataSource.data = authSearchResults;
            }
            this.loadingAuthorizations = false;
            this.initialLoadingOfAuthorizations = false;
            observer.complete();
          });
        } else {
          observer.complete();
          this.router.navigate([ApplicationConstants.routing.secure.memberSearchPageUrl]);
        }
      }
    });
  }

  private addMembersAndDependentsForViewCoverageSummaryTable() {
    const businessMemberResults = this.consumerService.businessMemberRetrieveResults;
    // Add Member Info to View Coverage Summary And Issue Authorization table
    if (!isNullOrUndefined(businessMemberResults.name)) {
      this.searchResultsData.push({
        name: getFormattedFullName(businessMemberResults.firstName, businessMemberResults.lastName),
        dateOfBirth: businessMemberResults.dateOfBirth,
        relationCode: businessMemberResults.relationCode,
        externalEligibilityLink: businessMemberResults.externalEligibilityLink,
        consumerId: businessMemberResults.consumerId,
        subscriberConsumerId: businessMemberResults.subscriberConsumerId
      });
    }
    // Add Dependent information to View Coverage Summary And Issue Authorization table
    if (!isNullOrUndefined(businessMemberResults.dependents)) {
      businessMemberResults.dependents.forEach(dependent => {
        if (dependent.consumerId) {
          this.searchResultsData.push({
            name: getFormattedFullName(dependent.dependentsName.firstName, dependent.dependentsName.lastName),
            dateOfBirth: dependent.dateOfBirth,
            relationCode: dependent.relationCode,
            externalEligibilityLink: dependent.externalEligibilityLink,
            consumerId: dependent.consumerId,
            subscriberConsumerId: businessMemberResults.subscriberConsumerId
          });
        }
      });
    }
  }

  private loadPatientsTable(): Observable<void> {
    return new Observable((observer: Subscriber<void>) => {
      this.businessMemberPoliciesResults = this.consumerService.businessMemberRetrieveResults;
      // Initialize table data
      if (this.businessMemberPoliciesResults) {
        // Get Member & Dependent Information to display on View Coverage Summary And Issue Authorization table
        this.addMembersAndDependentsForViewCoverageSummaryTable();
        this.patientSelectionTableDataSource.data = this.searchResultsData;
        observer.complete();
      } else {
        // check if member retrieve results are stored in session storage and use that to populate patient selection table
        this.consumerService.getMemberRetrieveResultsOrRecallApiIfDataIsNotInMemory().subscribe((businessMemberRetrieveResults: BusinessMemberPoliciesResults) => {
          if (!isNullOrUndefined(businessMemberRetrieveResults) && !isNullOrUndefined(businessMemberRetrieveResults.consumerId)) {
            this.businessMemberPoliciesResults = businessMemberRetrieveResults;
            // Get Member & Dependent Information to display on View Coverage Summary And Issue Authorization table
            this.addMembersAndDependentsForViewCoverageSummaryTable();
            this.patientSelectionTableDataSource.data = this.searchResultsData;
            observer.complete();
          } else {
            observer.complete();
            this.router.navigate([ApplicationConstants.routing.secure.memberSearchPageUrl]);
          }
        });
      }
    });
  }

  private buildDatePickerConfigurationDateOfBirth(): void {
    this.datePickerConfigurationDateOfBirth = {
      control: this.patientSelectionAddDependentForm.controls.dateOfBirth,
      controlName: 'dateOfBirth',
      errorWrapperId: {
        defaultValidations: 'patient-selection-dob-error',
        minDate: 'patient-selection-dob-min-date-error'
      },
      attributes: {
        id: 'patientSelect-searchPlans-dateOfBirth'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please supply the Date of Birth'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Date Of Birth must be in MM/DD/YYYY format'
        }
      ]
    };
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      lastName: {
        control: this.patientSelectionAddDependentForm.controls.lastName,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please supply the Last Name'
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: ApplicationConstants.invalidLastNameMessage(UserTypeQualifier.Patient)
        }, {
          validatorType: ErrorTypes.MinLength,
          errorMessage: `Last Name must contain at least two characters`
        }]
      },
      firstName: {
        control: this.patientSelectionAddDependentForm.controls.firstName,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please supply the First Name'
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: ApplicationConstants.invalidFirstNameMessage(UserTypeQualifier.Patient)
        }, {
          validatorType: ErrorTypes.MinLength,
          errorMessage: `First Name must contain at least two characters`
        }]
      },
      relationship: {
        control: this.patientSelectionAddDependentForm.controls.relationship,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please indicate the Relationship'
        }]
      },
    };
  }

  /***** START - PRIVATE METHODS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    // Calling LoadingModalComponent for Spinner (While loading data to populate authorization and patient tables)
    openDialog('', this.dialog);
    const loadPatientsTable: Observable<void> = this.loadPatientsTable();
    const loadAuthorizationTable: Observable<void> = this.loadAuthorizationTable();

    // Execute all queued tasks
    combineLatest(loadPatientsTable, loadAuthorizationTable,
      (loadPatientsTable, loadAuthorizationTable) => ({
        loadPatientsTable,
        loadAuthorizationTable
      }))
      .pipe(take(1))
      .subscribe(
        () => {
          // since the method returns an Observable<void>, this will never be called, but is needed to get to the error and onComplete
        },
        () => {
          // on observer error
          this.dialog.closeAll();
        }, () => {
        this.createCoverageText();
        this.setMemberFullName();
        this.buildForm();
        this.buildErrorWrapperConfig();
        this.buildDatePickerConfigurationDateOfBirth();
        if (!isStringNullUndefinedOrEmpty(this.consumerService.addDependentLink)) {
          this.addDependentLink = this.consumerService.addDependentLink;
        }
        this.dialog.closeAll();
      });
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    if (this.observableSubscriptions && this.observableSubscriptions.length > 0) {
      this.observableSubscriptions.forEach(observableSubscription => observableSubscription.unsubscribe());
    }
    this.dialog.closeAll();
  }

  ngAfterViewInit(): void {
    this.patientSelectionTableDataSource.sort = this.patientSelectionResultSort;
  }

  /***** END - EVENT HANDLERS *****/


  /***** START - PUBLIC METHODS *****/

  createCoverageText() {
    let coverageText: string;
    if (!isNullOrUndefined(this.businessMemberPoliciesResults) && !isNullOrUndefined(this.businessMemberPoliciesResults.coverageCode)) {
      coverageText = this.getCoverageMatchToCoverageCode(this.businessMemberPoliciesResults.coverageCode);
    }
    coverageText += ' Coverage';
    this.membershipCoverageText = coverageText;
  }

  setMemberFullName() {
    if (this.businessMemberPoliciesResults) {
      this.memberFullName = getFormattedFullName(this.businessMemberPoliciesResults.firstName, this.businessMemberPoliciesResults.lastName);
    }
  }

  onViewPlansAndIssueAuthorization(): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Loading Authorization...', this.dialog);
    const dateOfBirth: string = this.patientSelectionAddDependentForm.get('dateOfBirth').value;
    const firstName: string = this.patientSelectionAddDependentForm.get('firstName').value;
    const lastName: string = this.patientSelectionAddDependentForm.get('lastName').value;
    const relationCode: string = this.patientSelectionAddDependentForm.get('relationship').value;
    const request: AddDependentRequest = new AddDependentRequest(
      dateOfBirth,
      {firstName: firstName, lastName: lastName},
      relationCode
    );

    this.consumerService.addDependentToMemberPolicy(this.addDependentLink, request.payload)
      .subscribe((newlyAddedDependent: ConsumerResultsData) => {
        if (newlyAddedDependent) {
          // Retrieve the newly added dependent's eligibility and navigate to coverage summary
          this.retrieveEligibilityOnPatientSelection(newlyAddedDependent);
        }
      }, () => {
        // on observer error
        this.dialog.closeAll();
      });

    // Reset all controls after the dialog has been closed
    this.dialog.afterAllClosed.subscribe(() => {
      this.patientSelectionAddDependentForm.reset();
      if (this.addDependentForm) {
        // Reset the DOM form to remove error highlighting as inputs will be marked as invalid
        this.addDependentForm.resetForm();
      }
    });
  }

  // checks if there are any authorizations to display
  hasAuthorizations(): boolean {
    if (this.visionServiceRequestSearchResults) {
      return this.visionServiceRequestSearchResults.length > 0;
    } else {
      return false;
    }
  }

  // invoked when 'Retrieve' is clicked
  retrieveAuthorization(vsr: VisionServiceRequest): void {
    openDialog('', this.dialog);
    const mappedEffectiveDateFromVsr: string = this.authorizationsService.getEffectiveDateFromVsr(vsr.vsrNumber);
    if (isNullOrUndefined(mappedEffectiveDateFromVsr)) {
      // If no mapped effective date is returned, an error is displayed through the authorization service so we close the dialog here
      this.dialog.closeAll();
    } else {
      this.claimFormNavigationService.searchAndRetrieveOrCreatePatientEncounterThenLoadDoctorsThenNavigateToClaimForm(
        vsr.vsrNumber, undefined, mappedEffectiveDateFromVsr).subscribe(() => {
        // Broadcast value is null, no need for closure
      }, () => {
        // Errors are handled in the HTTP layer for PE, no need for error closure
      }, () => {
        // Close the dialog when the observer completes
        this.dialog.closeAll();
      });
    }
  }

  // invoked when 'Delete' is clicked
  deleteAuthorization(vsr: VisionServiceRequest): void {
    const dialogRef = this.dialog.open(DeleteAuthorizationModalComponent, {
      width: '500px',
      panelClass: 'eclaim-popup-modal',
      disableClose: true,
      data: {
        authorization: vsr,
        patientFullName: this.getFullName(vsr.reifiedPatient.name)
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingAuthorizations = true;
        this.authorizationsService.deleteAuthorization(this.visionServiceRequestSearchResults.indexOf(vsr)).subscribe(result => {
          if (result) {
            this.visionServiceRequestSearchResults = result;
            this.authorizationSelectionTableDataSource.data = this.visionServiceRequestSearchResults;
          }
          this.loadingAuthorizations = false;
        });
      }
    });
  }

  // invoked when 'Refresh' is clicked
  refreshAuthorizations(): void {
    this.loadingAuthorizations = true;
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Refreshing Authorization List...', this.dialog);
    const visionServiceRequestsLink = this.authorizationsService.getSessionStoredAuthorizationSearchLink();
    if (!isNullOrUndefined(visionServiceRequestsLink) && !isStringNullUndefinedOrEmpty(visionServiceRequestsLink.href)) {
      this.authorizationsService.searchAuthorizations(visionServiceRequestsLink.href).subscribe(authSearchResults => {
          if (authSearchResults) {
            this.visionServiceRequestSearchResults = authSearchResults;
            this.authorizationSelectionTableDataSource.data = authSearchResults;
          }
          this.loadingAuthorizations = false;
        }, () => {
          // on observer error
        },
        () => {
          // Close the dialog when the observer completes
          this.dialog.closeAll();
        });
    }
  }

  // build the full name of the patient/member
  getFullName(name: MembershipName): string {
    const middleInitial = name.middleInitial ? name.middleInitial : null;
    return getFormattedFullName(name.firstName, name.lastName, middleInitial);
  }

  // get patient's relation to the member based upon the value of the relation code
  getRelationToMatchRelationCode(relationCode: string): string {
    let relation: string;
    switch (relationCode) {
      case(RelationToMember.Member):
        relation = RelationDescription.Member;
        break;
      case(RelationToMember.Spouse):
        relation = RelationDescription.Spouse;
        break;
      case(RelationToMember.Child):
        relation = RelationDescription.Child;
        break;
      case(RelationToMember.Student):
        relation = RelationDescription.Student;
        break;
      case(RelationToMember.DisabledParent):
        relation = RelationDescription.DisabledParent;
        break;
      case(RelationToMember.DisabledDependent):
        relation = RelationDescription.DisabledDependent;
        break;
      case(RelationToMember.DomesticPartner):
        relation = RelationDescription.DomesticPartner;
        break;
      case(RelationToMember.Other):
        relation = RelationDescription.Other;
        break;
    }
    return relation;
  }

  // get coverage based upon the value of the coverage code
  getCoverageMatchToCoverageCode(coverageCode: string): string {
    let coverage: string;
    switch (coverageCode) {
      case(CoverageCode.MemberPlusFamily):
        coverage = CoverageCodeDescription.MemberPlusFamily;
      break;
      case(CoverageCode.MemberPlusOne):
        coverage = CoverageCodeDescription.MemberPlusOne;
      break;
      case(CoverageCode.MemberOnly):
        coverage = CoverageCodeDescription.MemberOnly;
      break;
      case(CoverageCode.MemberPlusChildren):
        coverage = CoverageCodeDescription.MemberPlusChildren;
      break;
      case(CoverageCode.Unknown):
        coverage = CoverageCodeDescription.Unknown;
      break;
    }
    return coverage;
  }

  // go to the Coverage Summary page when a patient is selected
  retrieveEligibilityOnPatientSelection(patient: ConsumerResultsData | BusinessMemberPoliciesResults): void {
    this.dialog.closeAll();
    openDialog('Loading Coverage...', this.dialog);
    // change asOfDate used in calling member policy api with DateOfService on Member Search
    const dateOfService = sessionStorage.getItem(SessionStorageKeys.MemberDateOfService);
    const patientEligibilityLinkAsOfDate = this.consumerService.changeUrlWithSpecifiedAsOfDate(patient.externalEligibilityLink.href, dateOfService);
    this.eligibilityService.retrieveMemberEligibilityFromConsumerLink(patientEligibilityLinkAsOfDate).subscribe((memberEligibility: Eligibility) => {
        if (memberEligibility) {
          // Set the Patient information (i.e. patient first name, patient last name, patient consumer id) in session storage so it can be used for the VPS report
          sessionStorage.setItem(SessionStorageKeys.PatientConsumerId, patient.consumerId);
          this.router.navigate([ApplicationConstants.routing.secure.coverageSummaryPageUrl]);
        } else {
          this.router.navigate([ApplicationConstants.routing.secure.memberSearchPageUrl]);
        }
      }, () => {
        // on observer error
      },
      () => {
        // Close the dialog when the observer completes
        this.dialog.closeAll();
      });
  }

  trimName(control: AbstractControl): void {
    trimWhitespaceFromControlValue(control);
  }

  /***** END - PUBLIC METHODS *****/

}
