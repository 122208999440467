import { Injector } from '@angular/core';
import { ApplicationConstants, HttpStatusCode } from '../../../../constants/application.constants';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { MessageService } from '../../../support/message/message.service';
import { DataMarshallService } from '../data-marshall/data-marshall.service';
import {Environment} from '../../../../../../environments/environment.interface';
import {environment} from '../../../../../../environments/environment';

export class HttpClientService {

  /***** START - PRIVATE MEMBERS *****/
  private readonly _serviceClientId: string;
  protected http: HttpClient;
  protected messageService: MessageService;
  protected dataMarshallService: DataMarshallService;
  protected _environment: Environment;
  /***** END - PRIVATE MEMBERS *****/

  constructor(
    protected injector: Injector,
    private clientId: string
  ) {
    this.http = this.injector.get(HttpClient);
    this.messageService = this.injector.get(MessageService);
    this.dataMarshallService = this.injector.get(DataMarshallService);
    this._environment = environment;
    this._serviceClientId = clientId;
  }

  get environment(): Environment {
    return this._environment;
  }

  /***** START - PROPERTY ACCESSORS *****/
  private get serviceClientId(): string {
    return this._serviceClientId;
  }
  /***** END - PROPERTY ACCESSORS *****/

  /***** START - PROTECTED MEMBERS *****/
  protected log(message: string, messageObject?: any): void {
    this.messageService.add(`${this.serviceClientId}: ` + message, messageObject);
  }

  protected handleError(operation = 'operation', error: any): void {
    const errorMessage = this.getErrorMessage(error);
    this.log(`${operation} failed from service client ${this.serviceClientId}: ${errorMessage}`, error);
  }

  protected isNotFoundOrBadRequest(error: any): boolean {
    if (error && error.status) {
      return error.status === HttpStatusCode.NotFound || error.status === HttpStatusCode.BadRequest;
    }
    return false;
  }

  protected isInternalServerError(error): boolean {
    if (error && error.status) {
      return error.status === HttpStatusCode.InternalServerError;
    }
    return false;
  }

  protected isForbidden(error: any): boolean {
    if (error && error.status) {
      return error.status === HttpStatusCode.Forbidden;
    }
    return false;
  }

  protected isGatewayTimeOut(error: any): boolean {
    if (error && error.status) {
      return error.status === HttpStatusCode.GatewayTimeOut;
    }
    return false;
  }

  protected isEmpty(errorString: string): boolean {
    return errorString === undefined || errorString === null || errorString === '';
  }

  protected getErrorMessage(error: any): string {
    return ApplicationConstants.errorMessages.genericApiFailMessage;
  }

  protected getHttpHeaders(apiName: string, isBusinessApiRetrieveCall = false): HttpHeaders {
    let headers: string | { [name: string]: string | string[]; };
    // TODO: Needed to add this for ECLAIM-252 to get the latest attribute needed. We created ECLAIM-268 to remove this special logic.
    if (apiName === ApplicationConstants.dataServiceClientIDs.externalServiceLocationDataService || apiName === ApplicationConstants.api.catalogLensApi) {
      headers = { 'Accept': 'application/json;version=7.8' };
    } else {
      headers = { 'Accept': 'application/json;version=6.6' };
    }

    if (apiName === ApplicationConstants.api.consumerBusinessApi && isBusinessApiRetrieveCall) {
      headers['detail'] = ApplicationConstants.api.consumerBusinessApiDetailHeader;
    }

    return new HttpHeaders(headers);
  }

  protected getDetailDescriptionFromError(error: any): string {
    let errorMessage: string;
    if (error instanceof HttpErrorResponse) {
      const httpErrorResponse: HttpErrorResponse = error;
      // Check the size to make sure that there is only one details in the error message.
      if (httpErrorResponse.error.details.length === 1) {
        // Display the error message returned from the api.
        errorMessage = httpErrorResponse.error.details[0].description;
      } else {
        // If there are more than one details in the error message display the generic message just in case.
        errorMessage = this.getErrorMessage(error);
      }
    } else {
      errorMessage = this.getErrorMessage(error);
    }
    return errorMessage;
  }

  /***** END - PROTECTED MEMBERS *****/
}
