import {Inject, Injectable, OnDestroy} from "@angular/core";
import {DOCUMENT} from "@angular/common";

@Injectable()
export class FPOService implements OnDestroy {

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  // sets the FPO Background (container-mock class) on the <body> to indicate the page is UI-only and not fully functional
  setFpoBackground = function(_this) {
    // add the container-mock class to the body
    this.document.body.classList.add('container-mock');
  }

  // remove the container-mock class when scope is destroyed
  ngOnDestroy() {
    this.document.body.classList.remove('container-mock');
  }
}
