import {Injectable} from '@angular/core';
import {
  AddDependent,
  BusinessMemberPoliciesResults, FederalEnrolleeHealthBenefitPlan,
  SubscriberSearchResult,
  ConsumerResultsData,
  Dependents
} from '../../../../../models/consumer';
import {BehaviorSubject, Observable, of, Subscriber} from 'rxjs';
import {ApplicationConstants, SessionStorageKeys} from '../../../../constants/application.constants';
import {ConsumerDataService} from '../../http/http-client-data/consumer-data/consumer-data.service';
import {DateUtility, getFormattedFullName, isNullOrUndefined, isStringNullUndefinedOrEmpty} from '../../../../utility';
import {MessageService} from '../../../support/message/message.service';
import {ConsumerSearchRequest} from '../../../../../models/ConsumerSearchRequest';
import {ParamMap, Params, Router, convertToParamMap} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConsumerService {

  constructor(
    private consumerDataService: ConsumerDataService,
    private messageService: MessageService,
    private router: Router
  ) {
  }


  /***** START - PRIVATE MEMBERS *****/
  private _memberSearchResult: SubscriberSearchResult;
  private _businessMemberRetrieveResults: BusinessMemberPoliciesResults;
  private _federalEnrolleeHealthBenefitPlan: FederalEnrolleeHealthBenefitPlan;
  private _initialBusinessMemberRetrieveResult: BehaviorSubject<BusinessMemberPoliciesResults> = new BehaviorSubject(undefined);
  private _initialMemberRetrieveCallMade = false;
  /***** END - PRIVATE MEMBERS *****/


  /***** START - PUBLIC MEMBERS *****/
  onMemberSearch = new BehaviorSubject(undefined);

  /***** END - PUBLIC MEMBERS *****/


  /***** START - PROPERTY ACCESSORS *****/
  get memberSearchResult(): SubscriberSearchResult {
    return (this._memberSearchResult) ? Object.deepClone(this._memberSearchResult) : this._memberSearchResult;
  }

  set memberSearchResult(searchResult: SubscriberSearchResult) {
    this._memberSearchResult = searchResult;
    this.onMemberSearch.next(this._memberSearchResult);
  }

  get businessMemberRetrieveResults(): BusinessMemberPoliciesResults {
    return (this._businessMemberRetrieveResults) ? Object.deepClone(this._businessMemberRetrieveResults) : this._businessMemberRetrieveResults;
  }

  set businessMemberRetrieveResults(businessMemberRetrieveResults: BusinessMemberPoliciesResults) {
    this._businessMemberRetrieveResults = businessMemberRetrieveResults;
  }

  get federalEnrolleeHealthBenefitPlan(): FederalEnrolleeHealthBenefitPlan {
    return (this._federalEnrolleeHealthBenefitPlan) ? Object.deepClone(this._federalEnrolleeHealthBenefitPlan) : this._federalEnrolleeHealthBenefitPlan;
  }

  set federalEnrolleeHealthBenefitPlan(federalEnrolleeHealthBenefitPlan: FederalEnrolleeHealthBenefitPlan) {
    this._federalEnrolleeHealthBenefitPlan = federalEnrolleeHealthBenefitPlan;
  }

  get addDependentLink(): string {
    return JSON.parse(sessionStorage.getItem(SessionStorageKeys.AddDependentLink));
  }

  /***** END - PROPERTY ACCESSORS *****/

  /***** START - PRIVATE METHODS *****/
  private isNewlyAddedDependentEligibilityAvailable = (newlyAddedDependent: ConsumerResultsData): boolean =>
    !isNullOrUndefined(newlyAddedDependent) && !isNullOrUndefined(newlyAddedDependent.externalEligibilityLink) && !isStringNullUndefinedOrEmpty(newlyAddedDependent.externalEligibilityLink.href)

  private showErrorSnackBarAndCompleteRequest(observer: Subscriber<ConsumerResultsData>): Observable<ConsumerResultsData> {
    this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
    observer.next(undefined);
    observer.complete();
    return of(undefined);
  }

  /***** END - PRIVATE METHODS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  searchMembersOnly(searchQueryObject: ConsumerSearchRequest): void {
    this.consumerDataService.searchMembers(this.setDefaultSearchParameters(searchQueryObject)).subscribe((memberSearchResults) => {
      this.memberSearchResult = memberSearchResults;
    });
  }

  /**
   * This method was added add default search criteria in the Member search request.
   *
   * @param searchQueryObject - Payload that has default criteria being added.
   */
  setDefaultSearchParameters(searchQueryObject: ConsumerSearchRequest): ConsumerSearchRequest {
    searchQueryObject._size = ApplicationConstants.maxSubscriberSearchResult;
    searchQueryObject._membershipInd = ApplicationConstants.yesSingleCharIndicator;
    searchQueryObject._searchOnlyActiveMembers = true;
    searchQueryObject._activeDependentFilter = true;
    return searchQueryObject;
  }

  /**
   * This method was added for VEC-3397 to do additional searches when the search criteria being used contains Last 4 SSS.
   *
   * @param searchQueryObject - Payload being used to do search.
   */
  searchForMembersWithLastFourInTheSearchCriteria(searchQueryObject: ConsumerSearchRequest): void {
    this.consumerDataService.searchMembers(this.setDefaultSearchParameters(searchQueryObject)).subscribe((memberSearchResults) => {
      // If the first search returns zero results, then perform a Second search with the 'searchEmptyDob' parameter equal to 'true'
      if (this.areSearchResultsEmpty(memberSearchResults)) {
        // Set the 'searchEmptyDob' flag to 'true' for the second search.
        searchQueryObject._searchEmptyDob = true;
        this.consumerDataService.searchMembers(searchQueryObject).subscribe((memberSearchResultsTwo) => {
          // If the second search still returns zero results, then perform a Third search with the additional criteria, 'searchFirstInitialFirstName ' equal to 'true'.
          // Note the 'searchEmptyDob' parameter will also be sent in with a value equal to 'true'.
          if (this.areSearchResultsEmpty(memberSearchResultsTwo)) {
            // Set the 'searchFirstInitialFirstName' flag to 'true' for the third search.
            searchQueryObject._searchFirstInitialFirstName = true;
            this.consumerDataService.searchMembers(searchQueryObject).subscribe((memberSearchResultsThree) => {
              // Set the Search Results regardless of the search results.
              this.memberSearchResult = memberSearchResultsThree;
            });
          } else {
            // Set Search Results if results return from Second search
            this.memberSearchResult = memberSearchResultsTwo;
          }
        });
      } else {
        // Set Search Results if results return from First search
        this.memberSearchResult = memberSearchResults;
      }
    });
  }

  /**
   * Method validates if Member search results are empty.
   *
   * @param memberSearchResults - Member search we want to validate if they are null or empty.
   */
  areSearchResultsEmpty(memberSearchResults: SubscriberSearchResult): boolean {
    return !isNullOrUndefined(memberSearchResults) && !isNullOrUndefined(memberSearchResults.count) && memberSearchResults.count === 0;
  }

  retrieveMemberPolicy(memberPoliciesRetrievalLink: string): Observable<BusinessMemberPoliciesResults> {
    return new Observable((observer) => {
      this.consumerDataService.retrieveMember(memberPoliciesRetrievalLink).subscribe((businessMemberRetrieveResults) => {
        if (businessMemberRetrieveResults) {
          this.businessMemberRetrieveResults = businessMemberRetrieveResults;
          sessionStorage.setItem(SessionStorageKeys.MemberRetrieveLink, JSON.stringify(memberPoliciesRetrievalLink));
        }
        observer.next(businessMemberRetrieveResults);
        observer.complete();
      });
    });
  }

  // Changing URL asOfDate to Specified AsOfDate
  changeUrlWithSpecifiedAsOfDate(currentURL: string, asOfDate: string): string {
    const currentLinkWithoutDomain = currentURL.replace(ApplicationConstants.domainOfUrlRegex, '');
    const currentLinkQueryParams: Params = this.router.parseUrl(currentLinkWithoutDomain).queryParams;
    if (currentLinkQueryParams['asofdate']) {
      return currentURL;
    } else {
      asOfDate = (asOfDate) ? DateUtility.buildYyyyMmDdDateFromDate(asOfDate) : currentLinkQueryParams.asOfDate;
      let updatedURL = currentURL.split('?', 1)[0] + '?asofdate=' + asOfDate;
      const paramMap: ParamMap = convertToParamMap(currentLinkQueryParams);
      if (!isNullOrUndefined(paramMap)) {
        const paramKeys: string[] = paramMap.keys;
        if (!isNullOrUndefined(paramKeys)) {
          for (let i = 0; i < paramKeys.length; i++) {
            updatedURL = updatedURL + ApplicationConstants.ampersandSymbol + paramKeys[i] + '=' + paramMap.get(paramKeys[i]);
          }
        }
      }
      return updatedURL;
    }
  }


  retrieveBusinessApiMemberPolicyAddDependentsLink(businessApiMemberPolicyLink: string): Observable<BusinessMemberPoliciesResults> {
    this.federalEnrolleeHealthBenefitPlan = undefined;
    return new Observable((observer) => {
      this.consumerDataService.retrieveAddDependentsLink(businessApiMemberPolicyLink).subscribe((businessMemberRetrieveResults: BusinessMemberPoliciesResults) => {
        // Initialize to empty string, so that if the currently selected member doesn't have a dependent link we can overwrite a potentially cached link from a previous member selection
        let addDependentLink = '';
        if (businessMemberRetrieveResults) {
          this.businessMemberRetrieveResults = businessMemberRetrieveResults;
          sessionStorage.setItem(SessionStorageKeys.MemberRetrieveLink, JSON.stringify(businessApiMemberPolicyLink));
          if (!isNullOrUndefined(businessMemberRetrieveResults.dependentsLink) && !isStringNullUndefinedOrEmpty(businessMemberRetrieveResults.dependentsLink.href)) {
            addDependentLink = businessMemberRetrieveResults.dependentsLink.href;
          }
        }
        sessionStorage.setItem(SessionStorageKeys.AddDependentLink, JSON.stringify(addDependentLink));
        // Push a value into the observable, or combineLatest will not complete
        observer.next(businessMemberRetrieveResults);
        observer.complete();
      });
    });
  }

  retrieveClientWebFEHBPlan(clientWebFEHBPlanLink: string): Observable<FederalEnrolleeHealthBenefitPlan> {
    return new Observable((observer) => {
      this.consumerDataService.retrieveClientWebFEHBPlan(clientWebFEHBPlanLink).subscribe((federalEnrolleeHealthBenefitPlan) => {
        if (federalEnrolleeHealthBenefitPlan) {
          this.federalEnrolleeHealthBenefitPlan = federalEnrolleeHealthBenefitPlan;
        }
        observer.next(federalEnrolleeHealthBenefitPlan);
        observer.complete();
      });
    });
  }

  addDependentToMemberPolicy(addDependentLink: string, payload: AddDependent): Observable<ConsumerResultsData> {
    return new Observable((observer: Subscriber<ConsumerResultsData>) => {
      this.consumerDataService.addDependent(addDependentLink, payload).subscribe((consumerId: string) => {
        // Retrieve the stored member policy if the call to add dependent was successful and returned the consumer ID
        const cachedMemberRetrieveLink: string = this.getMemberRetrieveLink();

        // Invalidate empty consumer IDs returned or unavailable retrieve link
        if (isStringNullUndefinedOrEmpty(consumerId) || isStringNullUndefinedOrEmpty(cachedMemberRetrieveLink)) {
          return this.showErrorSnackBarAndCompleteRequest(observer);
        }

        // Call member policy to retrieve the eligibility link for the newly added dependent (will also cache the new response for navigation back to the page)
        this.retrieveMemberPolicy(cachedMemberRetrieveLink).subscribe((updatedMemberPolicy: BusinessMemberPoliciesResults) => {
          // Invalidate requests that did not return proper member policies
          if (isNullOrUndefined(updatedMemberPolicy)) {
            return this.showErrorSnackBarAndCompleteRequest(observer);
          }

          // Match on the consumer ID returned from adding the dependent, invalidate if the member is not found in the response
          let newlyAddedDependentResultsData: ConsumerResultsData = null;
          updatedMemberPolicy.dependents.forEach(dependent => {
            if (dependent.consumerId === consumerId) {
              newlyAddedDependentResultsData = {
                name: getFormattedFullName(dependent.firstName, dependent.lastName),
                dateOfBirth: dependent.dateOfBirth,
                relationCode: dependent.relationCode,
                externalEligibilityLink: dependent.externalEligibilityLink,
                consumerId: dependent.consumerId,
                subscriberConsumerId: updatedMemberPolicy.subscriberConsumerId
              };
            }
          });
          if (this.isNewlyAddedDependentEligibilityAvailable(newlyAddedDependentResultsData)) {
            // Return the new member to navigate to the coverage page
            observer.next(newlyAddedDependentResultsData);
            observer.complete();
          } else {
            return this.showErrorSnackBarAndCompleteRequest(observer);
          }
        });
      });
    });
  }

  getMemberRetrieveLink(): string {
    return JSON.parse(sessionStorage.getItem(SessionStorageKeys.MemberRetrieveLink));
  }

  getMemberRetrieveResultsOrRecallApiIfDataIsNotInMemory(): Observable<BusinessMemberPoliciesResults> {
    return new Observable((observer: Subscriber<BusinessMemberPoliciesResults>) => {
      const stringifiedMemberRetrieveResults = this.getMemberRetrieveLink();
      if (!isNullOrUndefined(this.businessMemberRetrieveResults)) {
        // When we have member retrieve results stored in memory, return that data
        observer.next(this.businessMemberRetrieveResults);
        observer.complete();
      } else if (!isStringNullUndefinedOrEmpty(stringifiedMemberRetrieveResults)) {
        // if we dont have member retrieve results stored in memory, but have a member retrieve link stored in session
        // storage and the member retrieve api call has not been sent out yet, we need to call the api once initially and
        // let other requests for the data wait for that response so that we aren't needlessly calling the API more than
        // once in parallel.
        // EX: refresh the patient-selection page, both the patient-selection and breadcrumb components make calls to
        // this method in parallel causing 2 api calls when we only need one.
        if (!this._initialMemberRetrieveCallMade) {
          this._initialMemberRetrieveCallMade = true;
          this.retrieveMemberPolicy(stringifiedMemberRetrieveResults).subscribe((businessMemberPolicyResults: BusinessMemberPoliciesResults) => {
            this.businessMemberRetrieveResults = businessMemberPolicyResults;
            this._initialBusinessMemberRetrieveResult.next(businessMemberPolicyResults);
            observer.next(this.businessMemberRetrieveResults);
            observer.complete();
          });
        } else {
          this._initialBusinessMemberRetrieveResult.subscribe((initialBusinessMemberRetrieveResult: BusinessMemberPoliciesResults) => {
            if (!isNullOrUndefined(initialBusinessMemberRetrieveResult)) {
              observer.next(this.businessMemberRetrieveResults);
              observer.complete();
            }
          });
        }
      } else {
        // If we dont have member retrieve results stored in memory, and we also dont have the link to retrieve the member policies, then return undefined
        observer.next(undefined);
        observer.complete();
      }
    });
  }

  /***** END - PUBLIC FUNCTIONS *****/
}
