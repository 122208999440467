import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ErrorTypes} from '../../constants/application.constants';
import { ClaimsService } from '../../services/data-model/app/claims/claims.service';
import { isNullOrUndefined, isStringNullUndefinedOrEmpty } from '../../utility';
import { ViewRef } from '@angular/core';
import {NgSelectComponent} from '@ng-select/ng-select';

export class ErrorWrapperConfig {
  selectInputField ?: NgSelectComponent;
  control: AbstractControl;
  errors: {
    validatorType: string;
    errorMessage: string;
  }[];
}

@Component({
  selector: 'app-error-wrapper',
  templateUrl: './error-wrapper.component.html',
  styleUrls: ['./error-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorWrapperComponent implements OnInit, OnDestroy {

  constructor(
    private rootElement: ElementRef,
    private claimsService: ClaimsService,
    private changeDetector: ChangeDetectorRef
  ) { }

  /***** START - PRIVATE MEMBERS *****/
  private control: AbstractControl;
  private controlElementRef: HTMLElement;
  private observableSubscriptions: Subscription[] = [];
  /***** START - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/
  @Input() config: ErrorWrapperConfig;
  controlHasBeenTouched = false;
  controlHasFocus = false;
  /***** END - PUBLIC MEMBERS *****/

  /***** START - PUBLIC FUNCTIONS *****/
  public hasDateErrors() {
    const invalidDateFormat = this.control.hasError(ErrorTypes.InvalidDateFormat);
    const invalidDate = this.control.hasError(ErrorTypes.InvalidDate);
    const futureDate = this.control.hasError(ErrorTypes.NoFutureDate);
    const invalidMinDate = this.control.hasError(ErrorTypes.MinDate);
    const hasDateError = invalidDateFormat || invalidDate || futureDate || invalidMinDate;
    const controlName = this.getControlName();
    if (!isStringNullUndefinedOrEmpty(controlName) && hasDateError) {
      if (!this.claimsService.dateErrorFormControlNameList.includes(controlName)) {
        this.claimsService.addToDateErrorControlNamesList(controlName);
      }
    }
    return hasDateError;
  }

  public hasDollarAmountErrors() {
    return this.control.hasError(ErrorTypes.DollarAmount);
  }

  public getControlName(): string {
    if (this.control) {
      const parent = this.control.parent;
      if (parent) {
        for (const key in parent.controls) {
          if (this.control === parent.controls[ key ]) {
            return key;
            break;
          }
        }
      }
    }
    return '';
  }

  public containsClass(className: string ): boolean {
    return this.rootElement.nativeElement.firstElementChild.classList.contains(className);
  }
  /***** END - PUBLIC FUNCTIONS *****/

  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    const { control } = this.config;
    this.control = control;
    // NOTE: The 'nativeElement' property of the AbstractControl is assigned in the polyfill file by
    // extending the ngOnChanges prototype
    if (!isNullOrUndefined(this.control) && !isNullOrUndefined(this.control['nativeElement'])) {
      this.controlElementRef = this.control['nativeElement'];
    } else if (!isNullOrUndefined(this.config.selectInputField)) { // use input passed in with config if native element isn't available. This was needed for ng-select
      this.controlElementRef = this.config.selectInputField.element;
    }
    // Add a valueChanges event handler to the AbstractControl to capture the touched state
    this.observableSubscriptions.push(this.control.valueChanges.subscribe(() => {
      this.controlHasBeenTouched = this.control.touched || !this.control.pristine;
      // If the nativeElement was added to the control via polyfill, set the focus flag
      if (this.controlElementRef) {
        this.controlHasFocus = this.controlElementRef === document.activeElement;
      } else {
        /*
          If this.controlElementRef is not defined on the control, it is not an instance of a FormControl (see polyfills.ts).
          If app-error-wrapper wraps a form group, we need to iterate through the group and match the nativeElement on
          the active control. Current architecture should follow the hierarchy: FormGroup => FormControl || FormArray.
          No nested FormGroups, and the logic below follows that assumption.

          NOTE: AbstractControl property `parent` refers to the control inherited from AbstractControl containing child
          FormControl elements, in this case, returning the FormGroup or FormArray instance of the control.
        */
        const controlType: FormGroup | FormArray = this.control.parent;
        if (controlType && controlType.controls) {
          for (const controlKey in controlType.controls) {
            const controlValue = controlType.controls[controlKey];
            // Catch instances of form arrays (i.e. diagnosis pointers) to match the nativeElement and the active control
            if (controlValue instanceof FormArray) {
              const formArrayControls = controlType.controls[controlKey] as FormArray;
              for (const formArrayControlKey in formArrayControls.controls) {
                const formArrayControl = formArrayControls.controls[formArrayControlKey];
                if (formArrayControl['nativeElement'] && formArrayControl['nativeElement'] === document.activeElement) {
                  this.controlHasFocus = true;
                  break;
                }
              }
            } else {
              this.controlHasFocus = controlValue['nativeElement'] === document.activeElement;
            }

            if (this.controlHasFocus) {
              this.controlElementRef = controlValue['nativeElement'];
              this.buildBlurEventListener();
              break;
            }
          }
        }
      }
    }));
    // Add a blur event handler to the native form element to capture the touched state of the AbstractControl
    this.buildBlurEventListener();
  }

  buildBlurEventListener(): void {
    if (this.controlElementRef && isNullOrUndefined(this.control['checked'])) {
      this.controlElementRef.addEventListener('blur', () => {
        this.controlHasBeenTouched = this.control.touched || !this.control.pristine;
        this.controlHasFocus = false;
        // Wrapped the detectChanges() call in if statement to avoid changeDetection erros while the view is being destroyed.
        // (it was still trying to update a control that had already been destoryed.)
        if (this.changeDetector && !(this.changeDetector as ViewRef).destroyed) {
          this.changeDetector.detectChanges();
        }
      });
    }
  }

  ngOnDestroy() {
    this.changeDetector.detach();
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }
  /***** END - EVENT HANDLERS *****/

}
