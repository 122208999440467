import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicationConstants, ErrorTypes, UserTypeQualifier} from '../../../common/constants/application.constants';
import {ErrorWrapperConfig} from '../../../common/components/error-wrapper/error-wrapper.component';
import {CustomValidatorsService} from '../../../common/services/support/custom-validators/custom-validators.service';
import {
  DateUtility,
  isNullOrUndefined,
  isStringNullUndefinedOrEmpty, onKeypressEventRadiobutton,
  returnBooleanFromFormState,
  returnFormStateFromBoolean
} from '../../../common/utility';
import {ClaimFormItem} from '../../../common/classes/claim-form-item';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ClaimsService} from '../../../common/services/data-model/app/claims/claims.service';
import {Claim, ConditionRelatedTo, SoftAndHardValidationMessages, ValidationMessage} from '../../../models/claim';
import {ClaimFormUtilityFunctions} from '../claim-form.utilities';
import {Name} from '../../../models/name';
import {ComponentMaskComponent} from '../../../common/components/component-mask/component-mask.component';
import {FormStateYesNo} from '../../../common/enum/form-state-yes-no';
import {ClaimCardsToUpdate} from '../../../models/claimCardsToUpdate';
import {DatePickerConfiguration} from '../../../common/components/date-picker/date-picker.component';
import {MatRadioChange} from '@angular/material/radio';
import {FormConstants} from '../../../common/constants/form.constants';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';

enum FormState {
  conditionRelatedToAutoAccident = 'conditionRelatedToAutoAccident',
  repairOrReplacement = 'repairOrReplacement',
  conditionRelatedIllnessInjuryPregnancy = 'conditionRelatedIllnessInjuryPregnancy',
  similarIllness = 'similarIllness',
  patientUnableToWork = 'patientUnableToWork',
  patientReferredByPhysician = 'patientReferredByPhysician',
  patientHospitalized = 'patientHospitalized',
  resubmission = 'resubmission',
  serviceVerification = 'serviceVerification'
}

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class AdditionalInformationComponent extends ClaimFormItem implements OnInit, OnDestroy {

  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
    private claimService: ClaimsService,
    private changeDetector: ChangeDetectorRef,
    private claimEditService: ClaimEditService,
  ) {
    super(injector);
    this._stateCodes = FormConstants.stateCodes;
  }

  /***** START - PRIVATE MEMBERS *****/
  private observableSubscriptions: Subscription[] = [];
  private originalClaim: Claim;
  private activeClaim: Claim;
  // Used to store previous form state
  private oldViewModel;
  private readonly _stateCodes: string[];
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;
  @ViewChild('conditionRelatedToAutoAccidentAddressStateCode', {static: true}) private conditionRelatedToAutoAccidentAddressStateCode: NgSelectComponent;
  /***** END - PRIVATE MEMBERS *****/


  /***** START - PUBLIC MEMBERS *****/
  id = ApplicationConstants.componentIDs.additionalInformation;
  title = 'Additional Information';
  additionalInformationForm: FormGroup;
  ERROR_TYPES = {...ErrorTypes};
  // Form state/data variables
  errorWrapperConfig = {
    additionalClaimInformation: new ErrorWrapperConfig(),
    conditionRelatedToAutoAccidentStateCode: new ErrorWrapperConfig(),
    resubmissionOriginalRefNum: new ErrorWrapperConfig(),
    resubmissionCode: new ErrorWrapperConfig(),
    referringProviderFirstName: new ErrorWrapperConfig(),
    referringProviderMiddleInitial: new ErrorWrapperConfig(),
    referringProviderLastName: new ErrorWrapperConfig(),
    referringProviderNpi: new ErrorWrapperConfig(),
    referringProviderNpiLength: new ErrorWrapperConfig(),
    referringProviderNpiValue: new ErrorWrapperConfig(),
    serviceVerificationValue: new ErrorWrapperConfig(),
  };
  datePickerConfigurationPatientHospitalizedDateTo: DatePickerConfiguration;
  datePickerConfigurationPatientHospitalizedDateFrom: DatePickerConfiguration;
  datePickerConfigurationPatientUnableToWorkDateTo: DatePickerConfiguration;
  datePickerConfigurationPatientUnableToWorkDateFrom: DatePickerConfiguration;
  datePickerConfigurationSimilarIllnessDate: DatePickerConfiguration;
  datePickerConfigurationConditionRelatedIllnessInjuryPregnancyDate: DatePickerConfiguration;
  formState = FormState;
  formStateYesNo = FormStateYesNo;
  todayDate = new Date();
  numberOfCharactersLeftForAdditionalClaimInformation = ApplicationConstants.additionalClaimInformationMaxLength;
  additionalClaimInformationMaxLength = ApplicationConstants.additionalClaimInformationMaxLength;
  // Assigning functions from utility class to variables so that these can be accessed  in template
  onKeypressEventRadiobutton = onKeypressEventRadiobutton;
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;

  get splitPrintedForm(): boolean {
    return this.isFormLongEnoughToSplitWhenPrinted();
  }

  get stateCodes(): string[] {
    return this._stateCodes;
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }

  /***** END - PUBLIC MEMBERS *****/

  /***** START - PUBLIC FUNCTIONS ******/

  isEmpty(formControlValue: any): boolean {
    return formControlValue === undefined || formControlValue === null || formControlValue === '';
  }

  /***** END - PUBLIC FUNCTIONS ******/
  /***** START - PRIVATE FUNCTIONS *****/

  private buildDatePickerConfigurations(): void {
    this.datePickerConfigurationConditionRelatedIllnessInjuryPregnancyDate = {
      control: this.additionalInformationForm.controls.conditionRelatedIllnessInjuryPregnancyDate,
      controlName: 'conditionRelatedIllnessInjuryPregnancyDate',
      errorWrapperId: {
        defaultValidations: 'additional-information-condition-related-illness-injury-pregnancy-date-error',
        minDate: 'additional-information-condition-related-illness-injury-pregnancy-min-date-error'
      },
      attributes: {
        id: 'additional-information-condition-related-illness-injury-pregnancy-date-input-field',
        datePickerId: 'additional-information-condition-related-illness-injury-pregnancy-date-picker',
        datePickerToggleId: 'additional-information-condition-related-illness-injury-pregnancy-date-picker-toggle'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the Date of Onset'
        },
        {
          validatorType: ErrorTypes.NoFutureDate,
          errorMessage: 'Date of Onset cannot be a future date'
        }
      ]
    };

    this.datePickerConfigurationSimilarIllnessDate = {
      control: this.additionalInformationForm.controls.similarIllnessDate,
      controlName: 'similarIllnessDate',
      errorWrapperId: {
        defaultValidations: 'additional-information-similar-illness-date-error',
        minDate: 'additional-information-similar-illness-min-date-error'
      },
      attributes: {
        id: 'additional-information-similar-illness-date-input-field',
        datePickerId: 'additional-information-similar-illness-date-picker',
        datePickerToggleId: 'additional-information-similar-illness-date-picker-toggle'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.NoFutureDate,
          errorMessage: 'Date of Onset cannot be a future date'
        },
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the Date of Onset'
        }
      ]
    };

    this.datePickerConfigurationPatientUnableToWorkDateFrom = {
      control: this.additionalInformationForm.controls.patientUnableToWorkDateFrom,
      controlName: 'patientUnableToWorkDateFrom',
      errorWrapperId: {
        defaultValidations: 'additional-information-patient-unable-to-work-from-date-error',
        minDate: 'additional-information-patient-unable-to-work-from-min-date-error'
      },
      attributes: {
        id: 'additional-information-patient-unable-to-work-from-date-input-field',
        datePickerId: 'additional-information-patient-unable-to-work-from-date-picker',
        datePickerToggleId: 'additional-information-patient-unable-to-work-from-date-picker-toggle'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the date range the patient was unable to work'
        }
      ]
    };

    this.datePickerConfigurationPatientUnableToWorkDateTo = {
      control: this.additionalInformationForm.controls.patientUnableToWorkDateTo,
      controlName: 'patientUnableToWorkDateTo',
      errorWrapperId: {
        defaultValidations: 'additional-information-patient-unable-to-work-to-date-error',
        minDate: 'additional-information-patient-unable-to-work-to-min-date-error'
      },
      attributes: {
        id: 'additional-information-patient-unable-to-work-to-date-input-field',
        datePickerId: 'additional-information-patient-unable-to-work-to-date-picker',
        datePickerToggleId: 'additional-information-patient-unable-to-work-to-date-picker-toggle'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the date range the patient was unable to work'
        }
      ]
    };

    this.datePickerConfigurationPatientHospitalizedDateFrom = {
      control: this.additionalInformationForm.controls.patientHospitalizedDateFrom,
      controlName: 'patientHospitalizedDateFrom',
      errorWrapperId: {
        defaultValidations: 'additional-information-patient-hospitalized-date-from-date-error',
        minDate: 'additional-information-patient-hospitalized-date-from-min-date-error'
      },
      attributes: {
        id: 'additional-information-patient-hospitalized-date-from-date-input-field',
        datePickerId: 'additional-information-patient-hospitalized-date-from-date-picker',
        datePickerToggleId: 'additional-information-patient-hospitalized-date-from-date-picker-toggle'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the date range the patient was hospitalized'
        }
      ]
    };

    this.datePickerConfigurationPatientHospitalizedDateTo = {
      control: this.additionalInformationForm.controls.patientHospitalizedDateTo,
      controlName: 'patientHospitalizedDateTo',
      errorWrapperId: {
        defaultValidations: 'additional-information-patient-hospitalized-date-to-date-error',
        minDate: 'additional-information-patient-hospitalized-date-to-min-date-error'
      },
      attributes: {
        id: 'additional-information-patient-hospitalized-date-to-date-input-field',
        datePickerId: 'additional-information-patient-hospitalized-date-to-date-picker',
        datePickerToggleId: 'additional-information-patient-hospitalized-date-to-date-picker-toggle'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the date range the patient was hospitalized'
        }
      ]
    };
  }

  private buildForm(): void {

    // pull data from the data model
    const {
      additionalClaimInformationHCFABox19, conditionRelatedTo, repairOrReplaceIndicator, originalReferenceNumber,
      illnessInjuryOnsetDate, priorIllnessInjuryOnsetDate, unableToWorkStartDate, medicaidResubmissionCode,
      unableToWorkEndDate, referringDoctorName, referringDoctorNPI, hospitalizationAdmitDate, hospitalizationReleaseDate,
      serviceVerificationValue
    } = this.originalClaim;
    let employment, autoAccident, autoAccidentStateCode, otherAccident;
    if (conditionRelatedTo) {
      ({employment, autoAccident, autoAccidentStateCode, otherAccident} = conditionRelatedTo);
    }
    let firstName, middle, lastName;
    if (referringDoctorName) {
      ({firstName, middle, lastName} = referringDoctorName);
    }


    this.additionalInformationForm = this.formBuilder.group({
      additionalClaimInformation: [additionalClaimInformationHCFABox19, [Validators.pattern('[a-zA-Z0-9 \\+\\$.!@#%&\\*\\-<>,{}\\[\\\\\\]/\\?\\(\\)]*')]],
      conditionRelatedToEmployment: employment ? returnFormStateFromBoolean(employment) : this.formStateYesNo.No,
      conditionRelatedToAutoAccident: autoAccident ? returnFormStateFromBoolean(autoAccident) : this.formStateYesNo.No,
      conditionRelatedToAutoAccidentStateCode: [autoAccidentStateCode, [Validators.required, Validators.pattern(ApplicationConstants.stateCodeRegex)]],
      conditionRelatedToOtherAccident: otherAccident ? returnFormStateFromBoolean(otherAccident) : this.formStateYesNo.No,
      repairOrReplacement: repairOrReplaceIndicator ? returnFormStateFromBoolean(repairOrReplaceIndicator) : this.formStateYesNo.No,
      conditionRelatedIllnessInjuryPregnancy: illnessInjuryOnsetDate ? this.formStateYesNo.Yes : this.formStateYesNo.No,
      conditionRelatedIllnessInjuryPregnancyDate: [DateUtility.buildFriendlyDateFromJsDate(illnessInjuryOnsetDate),
        [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(ApplicationConstants.minDate)]],
      similarIllness: priorIllnessInjuryOnsetDate ? this.formStateYesNo.Yes : this.formStateYesNo.No,
      similarIllnessDate: [DateUtility.buildFriendlyDateFromJsDate(priorIllnessInjuryOnsetDate),
        [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(ApplicationConstants.minDate)]],
      patientUnableToWork: unableToWorkStartDate ? this.formStateYesNo.Yes : this.formStateYesNo.No,
      patientUnableToWorkDateFrom: [DateUtility.buildFriendlyDateFromJsDate(unableToWorkStartDate),
        [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(ApplicationConstants.minDate)]],
      patientUnableToWorkDateTo: [DateUtility.buildFriendlyDateFromJsDate(unableToWorkEndDate ? unableToWorkEndDate : undefined),
        [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(ApplicationConstants.minDate)]],
      patientReferredByPhysician: (firstName || middle || lastName || referringDoctorNPI) ? this.formStateYesNo.Yes : this.formStateYesNo.No,
      referringProviderFirstName: [firstName, [Validators.required, Validators.pattern(ApplicationConstants.ValidNameRegex)]],
      referringProviderMiddleInitial: [middle, [this.customValidatorsService.MiddleInitial]],
      referringProviderLastName: [lastName, [Validators.required, Validators.pattern(ApplicationConstants.ValidNameRegex)]],
      referringProviderNpi: [(referringDoctorNPI ? referringDoctorNPI : undefined),
        [Validators.required, Validators.minLength(10), Validators.pattern(ApplicationConstants.NpiIdRegex)]],
      patientHospitalized: hospitalizationAdmitDate ? this.formStateYesNo.Yes : this.formStateYesNo.No,
      patientHospitalizedDateFrom: [DateUtility.buildFriendlyDateFromJsDate(hospitalizationAdmitDate),
        [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(ApplicationConstants.minDate)]],
      patientHospitalizedDateTo: [DateUtility.buildFriendlyDateFromJsDate(hospitalizationReleaseDate),
        [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(ApplicationConstants.minDate)]],
      resubmission: originalReferenceNumber ? this.formStateYesNo.Yes : this.formStateYesNo.No,
      resubmissionOriginalRefNum: [(originalReferenceNumber ? originalReferenceNumber : undefined),
        [Validators.required, Validators.pattern('[0-9]*')]],
      resubmissionCode: [(medicaidResubmissionCode ? medicaidResubmissionCode : undefined),
        [Validators.pattern('[0-9]*')]],
      serviceVerification: serviceVerificationValue ? this.formStateYesNo.Yes : this.formStateYesNo.No,
      serviceVerificationValue: [serviceVerificationValue,
        [Validators.pattern('(^(A|a)[0-9]{6})|([ECERT]{5})')]],
    });
    this.additionalInformationForm.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
    ).subscribe((viewModel) => {
      // Defaulting these 5 form fields in the viewModel since their values are not being captured by the API and this
      // will keep an unnecessary update of the data model from occurring
      Object.assign(viewModel, {
        conditionRelatedIllnessInjuryPregnancy: undefined,
        similarIllness: undefined,
        patientUnableToWork: undefined,
        patientHospitalized: undefined,
        serviceVerification: undefined,
      });
      // Run a simple comparison of the form viewModel data since distictUntilChanged uses the === comparative operator
      const previous = JSON.stringify(this.oldViewModel);
      const current = JSON.stringify(viewModel);
      const sameAsPrevious = previous === current;
      this.oldViewModel = Object.deepClone(viewModel);
      if (!sameAsPrevious) {
        this.updateDataModelFromViewModel();
      }
    });
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      additionalClaimInformation: {
        control: this.additionalInformationForm.controls.additionalClaimInformation,
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'This field does not allow special characters'
        }]
      },
      conditionRelatedToAutoAccidentStateCode: {
        selectInputField: this.conditionRelatedToAutoAccidentAddressStateCode,
        control: this.additionalInformationForm.controls.conditionRelatedToAutoAccidentStateCode,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a valid state code'
        }]
      },
      resubmissionOriginalRefNum: {
        control: this.additionalInformationForm.controls.resubmissionOriginalRefNum,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Original Ref. No. Required.'
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'Please enter a valid Original Ref. No.'
        }]
      },
      referringProviderFirstName: {
        control: this.additionalInformationForm.controls.referringProviderFirstName,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: `Please enter the Referring Provider's First Name`
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: ApplicationConstants.invalidFirstNameMessage(UserTypeQualifier.ReferringProvider)
        }],
      },
      referringProviderMiddleInitial: {
        control: this.additionalInformationForm.controls.referringProviderMiddleInitial,
        errors: [{
          validatorType: ErrorTypes.MiddleInitial,
          errorMessage: `Please enter a valid Middle Initial`
        }],
      },
      referringProviderLastName: {
        control: this.additionalInformationForm.controls.referringProviderLastName,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: `Please enter the Referring Provider's Last Name`
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: ApplicationConstants.invalidLastNameMessage(UserTypeQualifier.ReferringProvider)
        }],
      },
      referringProviderNpi: {
        control: this.additionalInformationForm.controls.referringProviderNpi,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: `Please enter the Referring Provider's NPI`
        }],
      },
      referringProviderNpiValue: {
        control: this.additionalInformationForm.controls.referringProviderNpi,
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: `Please enter a valid 10 digit NPI`
        }]
      },
      referringProviderNpiLength: {
        control: this.additionalInformationForm.controls.referringProviderNpi,
        errors: [{
          validatorType: ErrorTypes.MinLength,
          errorMessage: `NPI requires 10 digit`
        }]
      },

      serviceVerificationValue: {
        control: this.additionalInformationForm.controls.serviceVerificationValue,
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: `The Service Verification number you have entered is not valid. Please refer to
                         your approval letter to verify the Service Verification number indicated. Service
                         Verification numbers begin with an A followed by a six digit number i.e. A123456`
        }]
      },
      resubmissionCode: {
        control: this.additionalInformationForm.controls.resubmissionCode,
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'Please enter a valid Code.'
        }]
      }
    };
  }

  private setViewModelFromDataModel(dataModel: Claim): void {
    // pull data from the data model
    const {
      additionalClaimInformationHCFABox19, conditionRelatedTo, repairOrReplaceIndicator, originalReferenceNumber,
      illnessInjuryOnsetDate, priorIllnessInjuryOnsetDate, unableToWorkStartDate, medicaidResubmissionCode,
      unableToWorkEndDate, referringDoctorName, referringDoctorNPI, hospitalizationAdmitDate, hospitalizationReleaseDate,
      serviceVerificationValue
    } = dataModel;
    let employment, autoAccident, autoAccidentStateCode, otherAccident;
    if (conditionRelatedTo) {
      ({employment, autoAccident, autoAccidentStateCode, otherAccident} = conditionRelatedTo);
    }
    let firstName, middle, lastName;
    if (referringDoctorName) {
      ({firstName, middle, lastName} = referringDoctorName);
    }
    // pull data from the view model
    const {
      additionalClaimInformation, conditionRelatedToEmployment, conditionRelatedToAutoAccident,
      conditionRelatedToAutoAccidentStateCode, conditionRelatedToOtherAccident, repairOrReplacement,
      conditionRelatedIllnessInjuryPregnancy,
      conditionRelatedIllnessInjuryPregnancyDate, similarIllness, similarIllnessDate, patientUnableToWork,
      patientUnableToWorkDateFrom, patientUnableToWorkDateTo,
      referringProviderFirstName, referringProviderMiddleInitial, referringProviderLastName,
      referringProviderNpi, patientHospitalized, patientHospitalizedDateFrom,
      patientHospitalizedDateTo, resubmissionOriginalRefNum, resubmissionCode, serviceVerification
    }
      = this.additionalInformationForm.controls;
    const serviceVerificationValueControl = this.additionalInformationForm.controls.serviceVerificationValue;
    const currentAdditionalClaimInformation = additionalClaimInformation.value;
    const currentConditionRelatedToEmployment = returnBooleanFromFormState(conditionRelatedToEmployment.value);
    const currentConditionRelatedToAutoAccident = returnBooleanFromFormState(conditionRelatedToAutoAccident.value);
    const currentConditionRelatedToAutoAccidentStateCode = conditionRelatedToAutoAccidentStateCode.value;
    const currentConditionRelatedToOtherAccident = returnBooleanFromFormState(conditionRelatedToOtherAccident.value);
    const currentRepairOrReplacement = returnBooleanFromFormState(repairOrReplacement.value);
    const currentReferringProviderFirstName = referringProviderFirstName.value;
    const currentReferringProviderMiddleInitial = referringProviderMiddleInitial.value;
    const currentReferringProviderLastName = referringProviderLastName.value;
    const currentReferringProviderNpi = referringProviderNpi.value;
    const currentResubmissionOriginalRefNum = resubmissionOriginalRefNum.value;
    const currentResubmissionCode = resubmissionCode.value;
    const currentServiceVerificationValue = serviceVerificationValueControl.value;
    // set data in the view model only if different from the data model
    if (currentAdditionalClaimInformation !== additionalClaimInformationHCFABox19) {
      additionalClaimInformation.setValue(additionalClaimInformationHCFABox19);
    }
    if (currentConditionRelatedToEmployment !== employment) {
      const employmentValue = returnFormStateFromBoolean(employment);
      conditionRelatedToEmployment.setValue(employmentValue);
    }
    if (currentConditionRelatedToAutoAccidentStateCode !== autoAccidentStateCode) {
      conditionRelatedToAutoAccidentStateCode.setValue(autoAccidentStateCode);
    }
    if (currentConditionRelatedToAutoAccident !== autoAccident) {
      const autoAccidentValue = returnFormStateFromBoolean(autoAccident);
      conditionRelatedToAutoAccident.setValue(autoAccidentValue);
    }
    if (currentConditionRelatedToOtherAccident !== otherAccident) {
      const otherAccidentValue = returnFormStateFromBoolean(otherAccident);
      conditionRelatedToOtherAccident.setValue(otherAccidentValue);
    }
    if (currentRepairOrReplacement !== repairOrReplaceIndicator) {
      const repairOrReplacementIndicatorValue = returnFormStateFromBoolean(repairOrReplaceIndicator);
      repairOrReplacement.setValue(repairOrReplacementIndicatorValue);
    }
    if (conditionRelatedIllnessInjuryPregnancyDate.status === 'VALID') {
      ClaimFormUtilityFunctions.setDateFieldIfDifferent(conditionRelatedIllnessInjuryPregnancyDate, illnessInjuryOnsetDate, true);
      const conditionRelatedIllnessInjuryPregnancyValue = conditionRelatedIllnessInjuryPregnancy.value;
      if (illnessInjuryOnsetDate && conditionRelatedIllnessInjuryPregnancyValue !== FormStateYesNo.Yes) {
        conditionRelatedIllnessInjuryPregnancy.setValue(FormStateYesNo.Yes);
      } else if (!illnessInjuryOnsetDate && conditionRelatedIllnessInjuryPregnancyValue !== FormStateYesNo.No) {
        conditionRelatedIllnessInjuryPregnancy.setValue(FormStateYesNo.No);
      }
    }
    if (similarIllnessDate.status === 'VALID') {
      ClaimFormUtilityFunctions.setDateFieldIfDifferent(similarIllnessDate, priorIllnessInjuryOnsetDate, true);
      const similarIllnessValue = similarIllness.value;
      if (priorIllnessInjuryOnsetDate && similarIllnessValue !== FormStateYesNo.Yes) {
        similarIllness.setValue(FormStateYesNo.Yes);
      } else if (!priorIllnessInjuryOnsetDate && similarIllnessValue !== FormStateYesNo.No) {
        similarIllness.setValue(FormStateYesNo.No);
      }
    }
    if (patientUnableToWorkDateFrom.status === 'VALID' && patientUnableToWorkDateTo.status === 'VALID') {
      ClaimFormUtilityFunctions.setDateFieldIfDifferent(patientUnableToWorkDateFrom, unableToWorkStartDate, true);
      ClaimFormUtilityFunctions.setDateFieldIfDifferent(patientUnableToWorkDateTo, unableToWorkEndDate, true);
      const patientUnableToWorkValue = patientUnableToWork.value;
      if (unableToWorkStartDate && patientUnableToWorkValue !== FormStateYesNo.Yes) {
        patientUnableToWork.setValue(FormStateYesNo.Yes);
      } else if (!unableToWorkStartDate && patientUnableToWorkValue !== FormStateYesNo.No) {
        patientUnableToWork.setValue(FormStateYesNo.No);
      }
    }
    if (currentReferringProviderFirstName !== firstName) {
      referringProviderFirstName.setValue(firstName);
    }
    if (currentReferringProviderMiddleInitial !== middle) {
      referringProviderMiddleInitial.setValue(middle);
    }
    if (currentReferringProviderLastName !== lastName) {
      referringProviderLastName.setValue(lastName);
    }
    if (currentReferringProviderNpi !== referringDoctorNPI) {
      referringProviderNpi.setValue(referringDoctorNPI);
    }

    if (patientHospitalizedDateFrom.status === 'VALID' && patientHospitalizedDateTo.status === 'VALID') {
      ClaimFormUtilityFunctions.setDateFieldIfDifferent(patientHospitalizedDateFrom, hospitalizationAdmitDate, true);
      ClaimFormUtilityFunctions.setDateFieldIfDifferent(patientHospitalizedDateTo, hospitalizationReleaseDate, true);
      const patientHospitalizedValue = patientHospitalized.value;
      if (hospitalizationAdmitDate && patientHospitalizedValue !== FormStateYesNo.Yes) {
        patientHospitalized.setValue(FormStateYesNo.Yes);
      } else if (!hospitalizationAdmitDate && patientHospitalizedValue !== FormStateYesNo.No) {
        patientHospitalized.setValue(FormStateYesNo.No);
      }
    }
    if (currentResubmissionOriginalRefNum !== originalReferenceNumber) {
      resubmissionOriginalRefNum.setValue(originalReferenceNumber);
    }
    if (currentResubmissionCode !== medicaidResubmissionCode) {
      resubmissionCode.setValue(medicaidResubmissionCode);
    }

    if (currentServiceVerificationValue !== serviceVerificationValue) {
      serviceVerificationValueControl.setValue(serviceVerificationValue);
    }
    const serviceVerificationControlValue = serviceVerification.value;
    if (serviceVerificationValue && serviceVerificationControlValue !== FormStateYesNo.Yes) {
      serviceVerification.setValue(FormStateYesNo.Yes);
    } else if (!serviceVerificationValue && serviceVerificationControlValue !== FormStateYesNo.No) {
      serviceVerification.setValue(FormStateYesNo.No);
    }
  }

  private updateDataModelFromViewModel(): void {
    const {
      additionalClaimInformation, conditionRelatedToEmployment, conditionRelatedToAutoAccident,
      conditionRelatedToAutoAccidentStateCode, conditionRelatedToOtherAccident, repairOrReplacement,
      conditionRelatedIllnessInjuryPregnancyDate, similarIllnessDate, patientUnableToWorkDateFrom, patientUnableToWorkDateTo,
      referringProviderFirstName, referringProviderMiddleInitial, referringProviderLastName,
      referringProviderNpi, patientHospitalizedDateFrom,
      patientHospitalizedDateTo, resubmissionOriginalRefNum, resubmissionCode, serviceVerificationValue
    }
      = this.additionalInformationForm.controls;
    if (!this.activeClaim.conditionRelatedTo) {
      this.activeClaim.conditionRelatedTo = {} as ConditionRelatedTo;
    }
    if (!this.activeClaim.referringDoctorName) {
      this.activeClaim.referringDoctorName = {} as Name;
    }
    // Set value from the form
    this.activeClaim.additionalClaimInformationHCFABox19 = additionalClaimInformation.value;
    Object.assign(this.activeClaim.conditionRelatedTo, {
      employment: returnBooleanFromFormState(conditionRelatedToEmployment.value),
      autoAccidentStateCode: conditionRelatedToAutoAccidentStateCode.value,
      autoAccident: returnBooleanFromFormState(conditionRelatedToAutoAccident.value),
      otherAccident: returnBooleanFromFormState(conditionRelatedToOtherAccident.value),
    });
    this.activeClaim.repairOrReplaceIndicator = returnBooleanFromFormState(repairOrReplacement.value);
    this.activeClaim.illnessInjuryOnsetDate = ClaimFormUtilityFunctions.getJsDateFromDateField(conditionRelatedIllnessInjuryPregnancyDate);
    this.activeClaim.priorIllnessInjuryOnsetDate = ClaimFormUtilityFunctions.getJsDateFromDateField(similarIllnessDate);
    this.activeClaim.unableToWorkStartDate = ClaimFormUtilityFunctions.getJsDateFromDateField(patientUnableToWorkDateFrom);
    this.activeClaim.unableToWorkEndDate = ClaimFormUtilityFunctions.getJsDateFromDateField(patientUnableToWorkDateTo);
    this.activeClaim.referringDoctorNPI = referringProviderNpi.value;
    Object.assign(this.activeClaim.referringDoctorName, {
      firstName: referringProviderFirstName.value,
      middle: referringProviderMiddleInitial.value,
      lastName: referringProviderLastName.value,
    });
    this.activeClaim.hospitalizationAdmitDate = ClaimFormUtilityFunctions.getJsDateFromDateField(patientHospitalizedDateFrom);
    this.activeClaim.hospitalizationReleaseDate = ClaimFormUtilityFunctions.getJsDateFromDateField(patientHospitalizedDateTo);
    this.activeClaim.originalReferenceNumber = resubmissionOriginalRefNum.value;
    this.activeClaim.medicaidResubmissionCode = resubmissionCode.value;
    this.activeClaim.serviceVerificationValue = serviceVerificationValue.value;
    // Update the active claim in the claim service
    if (this.additionalInfoCardHasChanged()) {
      this.claimService.setActiveClaim(this.activeClaim, this.id);
    }
  }

  private additionalInfoCardHasChanged(): boolean {
    const {
      additionalClaimInformationHCFABox19: additionalClaimInformationHCFABox19FromCard, repairOrReplaceIndicator: repairOrReplaceIndicatorFromCard,
      illnessInjuryOnsetDate: illnessInjuryOnsetDateFromCard, priorIllnessInjuryOnsetDate: priorIllnessInjuryOnsetDateFromCard,
      unableToWorkStartDate: unableToWorkStartDateFromCard, unableToWorkEndDate: unableToWorkEndDateFromCard,
      referringDoctorNPI: referringDoctorNPIFromCard, hospitalizationAdmitDate: hospitalizationAdmitDateFromCard,
      hospitalizationReleaseDate: hospitalizationReleaseDateFromCard, originalReferenceNumber: originalReferenceNumberFromCard,
      medicaidResubmissionCode: medicaidResubmissionCodeFromCard, serviceVerificationValue: serviceVerificationValueFromCard
    } = this.activeClaim;
    const illnessInjuryOnsetDateFromCardString = DateUtility.buildFriendlyDateFromJsDate(illnessInjuryOnsetDateFromCard);
    const priorIllnessInjuryOnsetDateFromCardString = DateUtility.buildFriendlyDateFromJsDate(priorIllnessInjuryOnsetDateFromCard);
    const unableToWorkStartDateFromCardString = DateUtility.buildFriendlyDateFromJsDate(unableToWorkStartDateFromCard);
    const unableToWorkEndDateFromCardString = DateUtility.buildFriendlyDateFromJsDate(unableToWorkEndDateFromCard);
    const hospitalizationAdmitDateFromCardString = DateUtility.buildFriendlyDateFromJsDate(hospitalizationAdmitDateFromCard);
    const hospitalizationReleaseDateFromCardString = DateUtility.buildFriendlyDateFromJsDate(hospitalizationReleaseDateFromCard);
    const {
      employment: employmentFromCard, autoAccidentStateCode: autoAccidentStateCodeFromCard, autoAccident: autoAccidentFromCard,
      otherAccident: otherAccidentFromCard
    } = this.activeClaim.conditionRelatedTo;
    const {
      firstName: referringDoctorFirstNameFromCard, middle: referringDoctorMiddleFromCard,
      lastName: referringDoctorLastNameFromCard
    } = this.activeClaim.referringDoctorName;


    const activeClaimFromService = this.claimService.getActiveClaim();
    // Instantiate claim objects if they don't exist
    if (isNullOrUndefined(activeClaimFromService.conditionRelatedTo)) {
      activeClaimFromService.conditionRelatedTo = {} as ConditionRelatedTo;
    }
    if (isNullOrUndefined(activeClaimFromService.referringDoctorName)) {
      activeClaimFromService.referringDoctorName = {} as Name;
    }
    const {
      additionalClaimInformationHCFABox19: additionalClaimInformationHCFABox19FromService, repairOrReplaceIndicator: repairOrReplaceIndicatorFromService,
      illnessInjuryOnsetDate: illnessInjuryOnsetDateFromService, priorIllnessInjuryOnsetDate: priorIllnessInjuryOnsetDateFromService,
      unableToWorkStartDate: unableToWorkStartDateFromService, unableToWorkEndDate: unableToWorkEndDateFromService,
      referringDoctorNPI: referringDoctorNPIFromService, hospitalizationAdmitDate: hospitalizationAdmitDateFromService,
      hospitalizationReleaseDate: hospitalizationReleaseDateFromService, originalReferenceNumber: originalReferenceNumberFromService,
      medicaidResubmissionCode: medicaidResubmissionCodeFromService, serviceVerificationValue: serviceVerificationValueFromService
    } = activeClaimFromService;
    const illnessInjuryOnsetDateFromServiceString = DateUtility.buildFriendlyDateFromJsDate(illnessInjuryOnsetDateFromService);
    const priorIllnessInjuryOnsetDateFromServiceString = DateUtility.buildFriendlyDateFromJsDate(priorIllnessInjuryOnsetDateFromService);
    const unableToWorkStartDateFromServiceString = DateUtility.buildFriendlyDateFromJsDate(unableToWorkStartDateFromService);
    const unableToWorkEndDateFromServiceString = DateUtility.buildFriendlyDateFromJsDate(unableToWorkEndDateFromService);
    const hospitalizationAdmitDateFromServiceServiceString = DateUtility.buildFriendlyDateFromJsDate(hospitalizationAdmitDateFromService);
    const hospitalizationReleaseDateFromServiceString = DateUtility.buildFriendlyDateFromJsDate(hospitalizationReleaseDateFromService);
    const {
      employment: employmentFromService, autoAccidentStateCode: autoAccidentStateCodeFromService, autoAccident: autoAccidentFromService,
      otherAccident: otherAccidentFromService
    } = activeClaimFromService.conditionRelatedTo;
    const {
      firstName: referringDoctorFirstNameFromService, middle: referringDoctorMiddleFromService,
      lastName: referringDoctorLastNameFromService
    } = activeClaimFromService.referringDoctorName;

    return (additionalClaimInformationHCFABox19FromCard || undefined) !== (additionalClaimInformationHCFABox19FromService || undefined) ||
      (repairOrReplaceIndicatorFromCard || undefined) !== (repairOrReplaceIndicatorFromService || undefined) ||
      (illnessInjuryOnsetDateFromCardString || undefined) !== (illnessInjuryOnsetDateFromServiceString || undefined) ||
      (priorIllnessInjuryOnsetDateFromCardString || undefined) !== (priorIllnessInjuryOnsetDateFromServiceString || undefined) ||
      (unableToWorkStartDateFromCardString || undefined) !== (unableToWorkStartDateFromServiceString || undefined) ||
      (unableToWorkEndDateFromCardString || undefined) !== (unableToWorkEndDateFromServiceString || undefined) ||
      (referringDoctorNPIFromCard || undefined) !== (referringDoctorNPIFromService || undefined) ||
      (originalReferenceNumberFromCard || undefined) !== (originalReferenceNumberFromService || undefined) ||
      (medicaidResubmissionCodeFromCard || undefined) !== (medicaidResubmissionCodeFromService || undefined) ||
      (serviceVerificationValueFromCard || undefined) !== (serviceVerificationValueFromService || undefined) ||
      (hospitalizationAdmitDateFromCardString || undefined) !== (hospitalizationAdmitDateFromServiceServiceString || undefined) ||
      (hospitalizationReleaseDateFromCardString || undefined) !== (hospitalizationReleaseDateFromServiceString || undefined) ||
      (employmentFromCard || undefined) !== (employmentFromService || undefined) ||
      (autoAccidentStateCodeFromCard || undefined) !== (autoAccidentStateCodeFromService || undefined) ||
      (autoAccidentFromCard || undefined) !== (autoAccidentFromService || undefined) ||
      (otherAccidentFromCard || undefined) !== (otherAccidentFromService || undefined) ||
      (referringDoctorFirstNameFromCard || undefined) !== (referringDoctorFirstNameFromService || undefined) ||
      (referringDoctorMiddleFromCard || undefined) !== (referringDoctorMiddleFromService || undefined) ||
      (referringDoctorLastNameFromCard || undefined) !== (referringDoctorLastNameFromService || undefined);
  }

  private isFormLongEnoughToSplitWhenPrinted() {
    // Rule:  If 8 or more of these fields are marked yes then this section of the form needs to be split across pages when printed.
    // see ECR-7175
    // All nine of these fields marked yes seems to be the tipping point for when the printed form screws up, but splitting at 8
    // to allow for possible unknowns, like wide margin settings, large font size, lack of familarity with technology, poor choices, etc.
    let trueMarkers = 0;
    // when any of these are marked yes they cause the form to expand vertically.
    const yesNoFieldNames = ['conditionRelatedToAutoAccident', 'conditionRelatedToOtherAccident', 'conditionRelatedIllnessInjuryPregnancy', 'similarIllness',
      'patientUnableToWork', 'patientReferredByPhysician', 'patientHospitalized', 'resubmission', 'serviceVerification'];
    yesNoFieldNames.forEach(name => {
      trueMarkers += this.additionalInformationForm.get(name).value === FormStateYesNo.Yes ? 1 : 0;
    });
    return (trueMarkers >= 8);
  }

  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.originalClaim = this.claimService.getOriginalClaim();
    this.registerWithClaimProgressService();
    this.buildForm();
    this.onAdditionalClaimInformationChange(); // after loading the saved claim, update the characters left value for additional claim info
    this.buildErrorWrapperConfig();
    this.buildDatePickerConfigurations();
    // Register for updates to the active claim
    // NOTE: uiFormattedClaim will be undefined if any errors occurred upstream
    this.observableSubscriptions.push(this.claimService.onCardsToUpdate.subscribe((onCardsToUpdate: ClaimCardsToUpdate) => {
      this.activeClaim = this.claimService.getActiveClaim();
      // Set form data if the data exists
      if (onCardsToUpdate.all) {
        this.setViewModelFromDataModel(this.activeClaim);
      }
    }));
    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      if (mask) {
        this.disableFormGroupComponents(this.additionalInformationForm);
        this.componentMask.show();
      } else {
        this.additionalInformationForm.enable();
        this.componentMask.hide();
      }
    }));
    // When submit finds UI edits detect changes on component
    this.observableSubscriptions.push(this.viewStateService.onSubmitFoundUIEdits.subscribe((hasUIEdits: boolean) => {
      if (hasUIEdits) {
        this.changeDetector.detectChanges();
        }
    }));
    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
             (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }
    }));
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {string} newVal - FormState.Yes || FormState.No
   */
  onConditionRelatedToAutoAccidentChange(newVal: MatRadioChange): void {
    const matRadioChangeValue: string = newVal.value.toString();
    const enabled = this.additionalInformationForm.controls.conditionRelatedToAutoAccidentStateCode.enabled;
    const disabled = !enabled;
    if (matRadioChangeValue === this.formStateYesNo.Yes && disabled) {
      this.additionalInformationForm.controls.conditionRelatedToAutoAccidentStateCode.enable();
    } else if (matRadioChangeValue === this.formStateYesNo.No && enabled) {
      this.additionalInformationForm.controls.conditionRelatedToAutoAccidentStateCode.disable();
      this.additionalInformationForm.controls.conditionRelatedToAutoAccidentStateCode.reset();
    }
  }

  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {string} newVal - FormState.Yes || FormState.No
   */
  onConditionRelatedIllnessInjuryPregnancyChange(newVal: MatRadioChange): void {
    const matRadioChangeValue: string = newVal.value;
    const enabled = this.additionalInformationForm.controls.conditionRelatedIllnessInjuryPregnancyDate.enabled;
    const disabled = !enabled;
    if (matRadioChangeValue === this.formStateYesNo.Yes && disabled) {
      this.additionalInformationForm.controls.conditionRelatedIllnessInjuryPregnancyDate.enable();
    } else if (matRadioChangeValue === this.formStateYesNo.No && enabled) {
      this.additionalInformationForm.controls.conditionRelatedIllnessInjuryPregnancyDate.disable();
      this.additionalInformationForm.controls.conditionRelatedIllnessInjuryPregnancyDate.reset();
    }
  }

  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {string} newVal - FormState.Yes || FormState.No
   */
  onResubmissionChange(newVal: MatRadioChange): void {
    const matRadioChangeValue: string = newVal.value;
    const enabled = this.additionalInformationForm.controls.resubmissionOriginalRefNum.enabled;
    const disabled = !enabled;
    if (matRadioChangeValue === this.formStateYesNo.Yes && disabled) {
      this.additionalInformationForm.controls.resubmissionOriginalRefNum.enable();
      this.additionalInformationForm.controls.resubmissionCode.enable();
    } else if (matRadioChangeValue === this.formStateYesNo.No && enabled) {
      this.additionalInformationForm.controls.resubmissionOriginalRefNum.disable();
      this.additionalInformationForm.controls.resubmissionOriginalRefNum.reset();
      this.additionalInformationForm.controls.resubmissionCode.disable();
      this.additionalInformationForm.controls.resubmissionCode.reset();
    }
  }

  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {string} newVal - FormState.Yes || FormState.No
   */
  onSimilarIllnessChange(newVal: MatRadioChange): void {
    const matRadioChangeValue: string = newVal.value;
    const enabled = this.additionalInformationForm.controls.similarIllnessDate.enabled;
    const disabled = !enabled;
    if (matRadioChangeValue === this.formStateYesNo.Yes && disabled) {
      this.additionalInformationForm.controls.similarIllnessDate.enable();
    } else if (matRadioChangeValue === this.formStateYesNo.No && enabled) {
      this.additionalInformationForm.controls.similarIllnessDate.disable();
      this.additionalInformationForm.controls.similarIllnessDate.reset();
    }
  }

  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {string} newVal - FormState.Yes || FormState.No
   */
  onPatientUnableToWorkChange(newVal: MatRadioChange): void {
    const matRadioChangeValue: string = newVal.value;
    const enabled = this.additionalInformationForm.controls.patientUnableToWorkDateFrom.enabled;
    const disabled = !enabled;
    if (matRadioChangeValue === this.formStateYesNo.Yes && disabled) {
      this.additionalInformationForm.controls.patientUnableToWorkDateFrom.enable();
      this.additionalInformationForm.controls.patientUnableToWorkDateTo.enable();
    } else if (matRadioChangeValue === this.formStateYesNo.No && enabled) {
      this.additionalInformationForm.controls.patientUnableToWorkDateFrom.disable();
      this.additionalInformationForm.controls.patientUnableToWorkDateFrom.reset();
      this.additionalInformationForm.controls.patientUnableToWorkDateTo.disable();
      this.additionalInformationForm.controls.patientUnableToWorkDateTo.reset();
    }
  }

  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {string} newVal - FormState.Yes || FormState.No
   */
  onPatientHospitalizedChange(newVal: MatRadioChange): void {
    const matRadioChangeValue: string = newVal.value;
    const enabled = this.additionalInformationForm.controls.patientHospitalizedDateFrom.enabled;
    const disabled = !enabled;
    if (matRadioChangeValue === this.formStateYesNo.Yes && disabled) {
      this.additionalInformationForm.controls.patientHospitalizedDateFrom.enable();
      this.additionalInformationForm.controls.patientHospitalizedDateTo.enable();
    } else if (matRadioChangeValue === this.formStateYesNo.No && enabled) {
      this.additionalInformationForm.controls.patientHospitalizedDateFrom.disable();
      this.additionalInformationForm.controls.patientHospitalizedDateFrom.reset();
      this.additionalInformationForm.controls.patientHospitalizedDateTo.disable();
      this.additionalInformationForm.controls.patientHospitalizedDateTo.reset();
    }
  }

  onPatientReferredByPhysician(newVal: MatRadioChange): void {
    const matRadioChangeValue: string = newVal.value;
    const enabled = this.additionalInformationForm.controls.referringProviderFirstName.enabled;
    const disabled = !enabled;
    if (matRadioChangeValue === this.formStateYesNo.Yes && disabled) {
      this.additionalInformationForm.controls.referringProviderFirstName.enable();
      this.additionalInformationForm.controls.referringProviderMiddleInitial.enable();
      this.additionalInformationForm.controls.referringProviderLastName.enable();
      this.additionalInformationForm.controls.referringProviderNpi.enable();
    } else if (matRadioChangeValue === this.formStateYesNo.No && enabled) {
      this.additionalInformationForm.controls.referringProviderFirstName.disable();
      this.additionalInformationForm.controls.referringProviderFirstName.reset();
      this.additionalInformationForm.controls.referringProviderMiddleInitial.disable();
      this.additionalInformationForm.controls.referringProviderMiddleInitial.reset();
      this.additionalInformationForm.controls.referringProviderLastName.disable();
      this.additionalInformationForm.controls.referringProviderLastName.reset();
      this.additionalInformationForm.controls.referringProviderNpi.disable();
      this.additionalInformationForm.controls.referringProviderNpi.reset();
    }
  }

  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {string} newVal - FormState.Yes || FormState.No
   */
  onServiceVerificationChange(newVal: MatRadioChange): void {
    const matRadioChangeValue: string = newVal.value;
    const enabled = this.additionalInformationForm.controls.serviceVerificationValue.enabled;
    const disabled = !enabled;
    if (matRadioChangeValue === this.formStateYesNo.Yes && disabled) {
      this.additionalInformationForm.controls.serviceVerificationValue.enable();
    } else if (matRadioChangeValue === this.formStateYesNo.No && enabled) {
      this.additionalInformationForm.controls.serviceVerificationValue.disable();
      this.additionalInformationForm.controls.serviceVerificationValue.reset();
    }
  }

  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {string} newVal - value of the additional claim information
   */

  onAdditionalClaimInformationChange(): void {
    const additionalClaimInformationValue = this.additionalInformationForm.controls.additionalClaimInformation.value;
    if (!isStringNullUndefinedOrEmpty(additionalClaimInformationValue)) {
      this.numberOfCharactersLeftForAdditionalClaimInformation =
        this.additionalClaimInformationMaxLength - additionalClaimInformationValue.length;
    } else {
      this.numberOfCharactersLeftForAdditionalClaimInformation = this.additionalClaimInformationMaxLength;
    }
  }

  /***** END - EVENT HANDLERS *****/

}
