import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidatorsService} from '../../../common/services/support/custom-validators/custom-validators.service';
import {InputMaskService} from '../../../common/services/support/input-mask/input-mask.service';
import {DateUtility, isNullOrUndefined, onKeypressEventRadiobutton} from '../../../common/utility';
import {ApplicationConstants, ErrorTypes, UserTypeQualifier} from '../../../common/constants/application.constants';
import {ClaimFormItem} from '../../../common/classes/claim-form-item';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Claim, SoftAndHardValidationMessages, ValidationMessage} from '../../../models/claim';
import {Subscription} from 'rxjs';
import {ClaimsService} from '../../../common/services/data-model/app/claims/claims.service';
import {Address} from '../../../models/address';
import {Membership, MembershipName} from '../../../models/membership';
import {OtherInsured} from '../../../models/otherInsured';
import {ClaimFormUtilityFunctions} from '../claim-form.utilities';
import {ComponentMaskComponent} from '../../../common/components/component-mask/component-mask.component';
import {FormStateYesNo} from '../../../common/enum/form-state-yes-no';
import {
  returnBooleanFromFormState,
  returnFormStateFromBoolean
} from '../../../common/utility';
import {ClaimCardsToUpdate} from '../../../models/claimCardsToUpdate';
import {DatePickerConfiguration} from '../../../common/components/date-picker/date-picker.component';
import {MatRadioChange} from '@angular/material/radio';
import {FormConstants} from '../../../common/constants/form.constants';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';

enum FormState {
  Member = 'Member',
  Spouse = 'Spouse',
  otherInsurance = 'otherInsurance',
  Male = 'M',
  Female = 'F',
}

@Component({
  selector: 'app-insured',
  templateUrl: './insured.component.html',
  styleUrls: ['./insured.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsuredComponent extends ClaimFormItem implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    public inputMaskService: InputMaskService,
    private customValidatorsService: CustomValidatorsService,
    private claimService: ClaimsService,
    private claimEditService: ClaimEditService,
  ) {
    super(injector);
    this._stateCodes = FormConstants.stateCodes;
  }


  /***** START - PRIVATE MEMBERS *****/
  private originalClaim: Claim;
  private activeClaim: Claim;
  private observableSubscriptions: Subscription[] = [];
  private _patientRelationToSubscriber: string = FormState.Member;
  private _otherInsurance: string;
  // flag used to capture the first load of the activeClaim in the interface
  private initialLoad = true;
  private readonly _stateCodes: string[];
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;
  @ViewChild('memberStateCodeDropdown', {static: true}) private memberStateCodeDropdown: NgSelectComponent;
  /***** START - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/
  id = ApplicationConstants.componentIDs.insured;
  title = 'Insured';
  insuredForm: FormGroup;
  errorWrapperConfig = {
    insuredFirstName: undefined,
    insuredMiddleInitial: undefined,
    insuredLastName: undefined,
    memberPolicyGroupNumber: undefined,
    memberAddressStreet1: undefined,
    memberAddressStreet2: undefined,
    memberAddressCity: undefined,
    memberAddressZipCode: undefined,
    memberAddressStateCode: undefined,
    insuredPhone: undefined,
    memberGender: undefined,
    memberInsurancePlan: undefined,
    otherInsurance: undefined,
    otherInsuredFirstName: undefined,
    otherInsuredMiddleInitial: undefined,
    otherInsuredLastName: undefined,
    otherInsuredPlanName: undefined,
    otherInsuredPolicyNumber: undefined,
    otherInsuredVsrNumber: undefined
  };
  datePickerConfigurationDateOfBirth: DatePickerConfiguration;
  @ViewChild('insuredPhoneControl', {read: ElementRef, static: true}) insuredPhoneControl: ElementRef;
  @ViewChild('memberAddressZipCodeControl', {read: ElementRef, static: true}) memberAddressZipCodeControl: ElementRef;

  formState = FormState;
  formStateYesNo = FormStateYesNo;
  insuredAddressMaxLength: number = ApplicationConstants.addressMaxLength;
  insuredCityMaxLength: number = ApplicationConstants.cityMaxLength;
  // Assigning functions from utility class to variables so that these can be accessed  in template
  onKeypressEventRadiobutton = onKeypressEventRadiobutton;
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;
  showRequiredDateOfBirthAsterisk: boolean = true;

  get stateCodes(): string[] {
    return this._stateCodes;
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }
  /***** END - PUBLIC MEMBERS *****/

  /***** START - PRIVATE FUNCTIONS *****/
  private buildForm(): void {
    const {
      otherInsuranceIndicator, otherInsured, memberPolicyGroupNumber, memberInsurancePlanProgramName,
      cobAuthorizationNumber, member, patient
    } = this.originalClaim;
    let insuredFirstName: string, insuredMiddleInitial: string, insuredLastName: string, gender: string,
      relationToSubscriber: string;
    let phones: string[];
    let name: MembershipName;
    let dateOfBirth: Date;
    let addresses: Address[];
    if (patient) {
      ({relationToSubscriber, gender, dateOfBirth} = this.originalClaim.patient);
    }
    if (member) {
      ({name, dateOfBirth, gender, addresses, phones} = this.originalClaim.member);
      if (name) {
        insuredFirstName = name.firstName;
        insuredMiddleInitial = name.middle;
        insuredLastName = name.lastName;
      }
    }
    if (relationToSubscriber === this.formState.Member) {
      addresses = this.originalClaim.patient.addresses;
      gender = this.originalClaim.patient.gender;
      phones = this.originalClaim.patient.phones;
    }
    let street1: string, street2: string, city: string, stateCode: string, zipCode: string = '';
    if (addresses[0]) {
      ({street1, street2, city, stateCode} = addresses[0]);
      if (addresses[0].zipCode) {
        ({zipCode = ''} = addresses[0].zipCode);
      }
    }
    if (zipCode === undefined) {
      zipCode = '';
    }
    let otherInsuredFirstName: string, otherInsuredLastName: string, otherInsuredMiddleInitial: string,
      policyGroupNumber: string, insurancePlanProgramName: string;
    if (otherInsured) {
      ({policyGroupNumber, insurancePlanProgramName} = otherInsured);
      otherInsuredFirstName = otherInsured.firstName;
      otherInsuredMiddleInitial = otherInsured.middleInitial;
      otherInsuredLastName = otherInsured.lastName;
    }
    this.insuredForm = this.formBuilder.group({
      insuredFirstName: [insuredFirstName, [Validators.required, Validators.pattern(ApplicationConstants.ValidNameRegex)]],
      insuredMiddleInitial: [insuredMiddleInitial, [this.customValidatorsService.MiddleInitial]],
      insuredLastName: [insuredLastName, [Validators.required, Validators.pattern(ApplicationConstants.ValidNameRegex)]],
      memberPolicyGroupNumber: [memberPolicyGroupNumber, [this.customValidatorsService.AlphaNumeric]],
      memberDateOfBirth: [
        DateUtility.isValidDate(dateOfBirth)
          ? DateUtility.buildFriendlyDateFromJsDate(dateOfBirth)
          : undefined, [this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(ApplicationConstants.minDate)]],
      memberGender: [gender],
      otherInsurance: [returnFormStateFromBoolean(otherInsuranceIndicator), [Validators.required]],
      memberAddressStreet1: [street1, [Validators.pattern(ApplicationConstants.addressStreetAndCityValidRegex)]],
      memberAddressStreet2: [street2, [Validators.pattern(ApplicationConstants.addressStreetAndCityValidRegex)]],
      memberAddressCity: [city, [Validators.pattern(ApplicationConstants.addressStreetAndCityValidRegex)]],
      memberAddressStateCode: [stateCode, [Validators.pattern(ApplicationConstants.stateCodeRegex)]],
      memberAddressZipCode: [`${zipCode}`, [this.customValidatorsService.ZipCodeInvalid]],
      memberInsurancePlan: [memberInsurancePlanProgramName, [Validators.pattern(ApplicationConstants.memberInsurancePlanValidRegex)]],
      insuredPhone: [phones[0], [this.customValidatorsService.PhoneInvalid(this.inputMaskService)]],
      otherInsuredFirstName: [otherInsuredFirstName, [Validators.required, Validators.pattern(ApplicationConstants.ValidNameRegex)]],
      otherInsuredMiddleInitial: [otherInsuredMiddleInitial, [this.customValidatorsService.MiddleInitial]],
      otherInsuredLastName: [otherInsuredLastName, [Validators.required, Validators.pattern(ApplicationConstants.ValidNameRegex)]],
      otherInsuredPolicyNumber: [policyGroupNumber, [Validators.required, this.customValidatorsService.AlphaNumeric]],
      otherInsuredVsrNumber: [cobAuthorizationNumber, [this.customValidatorsService.AlphaNumeric]],
      otherInsuredPlanName: [insurancePlanProgramName, [Validators.required, Validators.pattern(ApplicationConstants.memberInsurancePlanValidRegex)]],
    });
  }

  private buildDatePickerConfigurationDateOfBirth(): void {
    this.datePickerConfigurationDateOfBirth = {
      control: this.insuredForm.controls.memberDateOfBirth,
      controlName: 'memberDateOfBirth',
      errorWrapperId: {
        defaultValidations: 'insured-dob-error',
        minDate: 'insured-dob-error-min-date'
      },
      attributes: {
        id: 'insured-dob-input',
        name: 'memberDateOfBirth',
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please indicate Signature on File'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Provider Signature Date must be in MM/DD/YYYY format'
        }
      ]
    };
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      insuredFirstName: {
        control: this.insuredForm.controls.insuredFirstName,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: `Please enter the Insured's First Name`
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: ApplicationConstants.invalidFirstNameMessage(UserTypeQualifier.Insured)
        }]
      },
      insuredMiddleInitial: {
        control: this.insuredForm.controls.insuredMiddleInitial,
        errors: [{
          validatorType: ErrorTypes.MiddleInitial,
          errorMessage: `Please enter a valid Middle Initial`
        }]
      },
      insuredLastName: {
        control: this.insuredForm.controls.insuredLastName,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: `Please enter the Insured's Last Name`
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: ApplicationConstants.invalidLastNameMessage(UserTypeQualifier.Insured)
        }]
      },
      memberPolicyGroupNumber: {
        control: this.insuredForm.controls.memberPolicyGroupNumber,
        errors: [{
          validatorType: ErrorTypes.AlphaNumeric,
          errorMessage: `Please enter a valid Policy or FECA Number`
        }]
      },
      memberAddressStreet1: {
        control: this.insuredForm.controls.memberAddressStreet1,
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'Please enter a valid Address'
        },
        ]
      },
      memberAddressStreet2: {
        control: this.insuredForm.controls.memberAddressStreet2,
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid Address'
          },
        ]
      },
      memberAddressCity: {
        control: this.insuredForm.controls.memberAddressCity,
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid Address'
          },
        ]
      },
      memberAddressZipCode: {
        control: this.insuredForm.controls.memberAddressZipCode,
        errors: [{
          validatorType: ErrorTypes.ZipCodeInvalid,
          errorMessage: 'Please enter a valid Zip Code'
        }]
      },
      memberAddressStateCode: {
        selectInputField: this.memberStateCodeDropdown,
        control: this.insuredForm.controls.memberAddressStateCode,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the Insured\'s State'
        }]
      },
      memberGender: {
        control: this.insuredForm.controls.memberGender,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please indicate the Insured\'s Sex'
        }]
      },
      memberInsurancePlan: {
        control: this.insuredForm.controls.memberInsurancePlan,
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid Plan or Program Name'
          },
        ]
      },
      otherInsuredPolicyNumber: {
        control: this.insuredForm.controls.otherInsuredPolicyNumber,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the Policy or Group Number'
        }, {
          validatorType: ErrorTypes.AlphaNumeric,
          errorMessage: 'Please enter a valid Policy or Group Number'
        }]
      },
      otherInsuredFirstName: {
        control: this.insuredForm.controls.otherInsuredFirstName,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: `Please enter the Other Insured's First Name`
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: ApplicationConstants.invalidFirstNameMessage(UserTypeQualifier.Patient)
        }]
      },
      otherInsuredMiddleInitial: {
        control: this.insuredForm.controls.otherInsuredMiddleInitial,
        errors: [{
          validatorType: ErrorTypes.MiddleInitial,
          errorMessage: `Please enter a valid Middle Initial`
        }]
      },
      otherInsuredLastName: {
        control: this.insuredForm.controls.otherInsuredLastName,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: `Please enter the Other Insured's Last Name`
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: ApplicationConstants.invalidLastNameMessage(UserTypeQualifier.Patient)
        }]
      },
      otherInsuredPlanName: {
        control: this.insuredForm.controls.otherInsuredPlanName,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the Plan or Program Name'
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'Please enter a valid Plan or Program Name'
        }]
      },
      otherInsuredVsrNumber: {
        control: this.insuredForm.controls.otherInsuredVsrNumber,
        errors: [{
          validatorType: ErrorTypes.AlphaNumeric,
          errorMessage: 'Please enter a valid secondary authorization number'
        }]
      },
      otherInsurance: {
        control: this.insuredForm.controls.otherInsurance,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please select an option for another Health Benefit Plan for Eyecare'
        }]
      },
      insuredPhone: {
        control: this.insuredForm.controls.insuredPhone,
        errors: [{
          validatorType: ErrorTypes.PhoneInvalid,
          errorMessage: 'Please enter a valid phone number'
        }]
      },
    };
  }

  private buildInputMasks(): void {
    this.inputMaskService.createInputMask(this.insuredPhoneControl.nativeElement, this.inputMaskService.phoneAlias, {
      autoUnmask: false,
      clearIncomplete: false
    });
    this.inputMaskService.createInputMask(this.memberAddressZipCodeControl.nativeElement, this.inputMaskService.zipCodeAlias, {placeholder: ''});
  }

  private setViewModelFromDataModel(dataModel: Claim): void {
    // pull data from the data model
    const {
      otherInsuranceIndicator, otherInsured, memberPolicyGroupNumber, memberInsurancePlanProgramName,
      cobAuthorizationNumber, member, patient
    } = dataModel;
    let insuredFirstName: string, insuredMiddleInitial: string, insuredLastName: string, gender: string;
    let phones: string[];
    let name: MembershipName;
    let dateOfBirth: Date;
    let addresses: Address[];
    if (member) {
      ({name, dateOfBirth, gender, addresses, phones} = dataModel.member);
      if (name) {
        insuredFirstName = name.firstName;
        insuredMiddleInitial = name.middle;
        insuredLastName = name.lastName;
      }
    }
    let relationToSubscriber: string;
    if (patient) {
      ({relationToSubscriber} = dataModel.patient);
    }
    if (relationToSubscriber === this.formState.Member) {
      addresses = dataModel.patient.addresses;
      gender = dataModel.patient.gender;
      phones = dataModel.patient.phones;
    }
    let otherInsuredFirstName: string, otherInsuredLastName: string, otherInsuredMiddleInitial: string,
      policyGroupNumber: string, insurancePlanProgramName: string;
    if (otherInsured) {
      ({policyGroupNumber, insurancePlanProgramName} = otherInsured);
      otherInsuredFirstName = otherInsured.firstName;
      otherInsuredMiddleInitial = otherInsured.middleInitial;
      otherInsuredLastName = otherInsured.lastName;
    }
    // pull data from the view model
    const {
      memberDateOfBirth, memberGender, otherInsurance, memberAddressStreet1, memberAddressStreet2,
      memberAddressCity, memberAddressStateCode, memberAddressZipCode, memberInsurancePlan, insuredPhone,
      otherInsuredPolicyNumber,
      otherInsuredVsrNumber, otherInsuredPlanName
    } = this.insuredForm.controls;
    const insuredFirstNameControl = this.insuredForm.controls.insuredFirstName;
    const insuredMiddleInitialControl = this.insuredForm.controls.insuredMiddleInitial;
    const insuredLastNameControl = this.insuredForm.controls.insuredLastName;
    const memberPolicyGroupNumberFromControl = this.insuredForm.controls.memberPolicyGroupNumber;
    const otherInsuredFirstNameControl = this.insuredForm.controls.otherInsuredFirstName;
    const otherInsuredMiddleInitialControl = this.insuredForm.controls.otherInsuredMiddleInitial;
    const otherInsuredLastNameControl = this.insuredForm.controls.otherInsuredLastName;
    // capture the current values from the form fields
    const currentInsuredFirstName = insuredFirstNameControl.value;
    const currentInsuredMiddleInitial = insuredMiddleInitialControl.value;
    const currentInsuredLastName = insuredLastNameControl.value;
    const currentGender = memberGender.value;
    const currentRelationToSubscriber = this.patientRelationToSubscriber;
    // const currentDateOfBirth = memberDateOfBirth.value;
    const currentOtherInsurance = otherInsurance.value;
    const zipCode = memberAddressZipCode.value;
    const currentAddress: Address = {
      street1: memberAddressStreet1.value,
      street2: memberAddressStreet2.value,
      city: memberAddressCity.value,
      stateCode: memberAddressStateCode.value,
      zipCode: {
        zipCode: zipCode
      }
    };
    const currentInsurancePlan = memberInsurancePlan.value;
    const currentPhoneNumber = insuredPhone.value;
    const currentOtherInsuredFirstName = otherInsuredFirstNameControl.value;
    const currentOtherInsuredMiddleInitial = otherInsuredMiddleInitialControl.value;
    const currentOtherInsuredLastName = otherInsuredLastNameControl.value;
    const currentOtherInsuredPolicyNumber = otherInsuredPolicyNumber.value;
    const currentOtherInsuredVsrNumber = otherInsuredVsrNumber.value;
    const currentOtherInsuredPlanName = otherInsuredPlanName.value;
    const currentMemberPolicyGroupNumber = memberPolicyGroupNumberFromControl.value;
    // set data in the view model only if different from the data model
    if (currentInsuredFirstName !== insuredFirstName) {
      insuredFirstNameControl.setValue(insuredFirstName);
    }
    if (currentInsuredMiddleInitial !== insuredMiddleInitial) {
      insuredMiddleInitialControl.setValue(insuredMiddleInitial);
    }
    if (currentInsuredLastName !== insuredLastName) {
      insuredLastNameControl.setValue(insuredLastName);
    }
    if (currentGender !== gender) {
      memberGender.setValue(gender);
    }
    if (currentRelationToSubscriber !== relationToSubscriber) {
      this.patientRelationToSubscriber = relationToSubscriber;
    }
    ClaimFormUtilityFunctions.setDateFieldIfDifferent(memberDateOfBirth, dateOfBirth);
    // Need to guard against otherInsuranceIndicator being undefined; default to false if that's the case
    const otherInsuranceInd = (otherInsuranceIndicator === undefined) ? FormStateYesNo.No : returnFormStateFromBoolean(otherInsuranceIndicator);
    if (currentOtherInsurance !== otherInsuranceInd) {
      otherInsurance.setValue(otherInsuranceInd, ApplicationConstants.updateFormWithoutEmit);
      this.otherInsurance = otherInsuranceInd;
    }
    if (JSON.stringify(currentAddress) !== JSON.stringify(addresses[0])) {
      memberAddressStreet1.setValue(addresses[0].street1);
      memberAddressStreet2.setValue(addresses[0].street2);
      memberAddressCity.setValue(addresses[0].city);
      memberAddressStateCode.setValue(addresses[0].stateCode);
      const zipCodeValue = (addresses[0].zipCode.zipCode !== undefined) ? addresses[0].zipCode.zipCode : '';
      memberAddressZipCode.setValue(`${zipCodeValue}`);
    }
    if (currentInsurancePlan !== memberInsurancePlanProgramName) {
      memberInsurancePlan.setValue(memberInsurancePlanProgramName, ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentPhoneNumber !== phones[0]) {
      insuredPhone.setValue(phones[0]);
    }
    if (currentOtherInsuredFirstName !== otherInsuredFirstName) {
      otherInsuredFirstNameControl.setValue(otherInsuredFirstName, ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentOtherInsuredMiddleInitial !== otherInsuredMiddleInitial) {
      otherInsuredMiddleInitialControl.setValue(otherInsuredMiddleInitial, ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentOtherInsuredLastName !== otherInsuredLastName) {
      otherInsuredLastNameControl.setValue(otherInsuredLastName, ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentOtherInsuredPolicyNumber !== policyGroupNumber) {
      otherInsuredPolicyNumber.setValue(policyGroupNumber, ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentOtherInsuredVsrNumber !== cobAuthorizationNumber) {
      otherInsuredVsrNumber.setValue(cobAuthorizationNumber, ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentOtherInsuredPlanName !== insurancePlanProgramName) {
      otherInsuredPlanName.setValue(insurancePlanProgramName, ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentMemberPolicyGroupNumber !== memberPolicyGroupNumber) {
      memberPolicyGroupNumberFromControl.setValue(memberPolicyGroupNumber, ApplicationConstants.updateFormWithoutEmit);
    }
  }

  private setDisabledFieldsFromDataModel(dataModel: Claim): void {
    const {otherInsurance, memberInsurancePlan, otherInsuredPolicyNumber, otherInsuredVsrNumber, otherInsuredPlanName} = this.insuredForm.controls;
    const insuredFirstNameControl = this.insuredForm.controls.insuredFirstName;
    const insuredMiddleInitialControl = this.insuredForm.controls.insuredMiddleInitial;
    const insuredLastNameControl = this.insuredForm.controls.insuredLastName;
    const memberPolicyGroupNumberFromControl = this.insuredForm.controls.memberPolicyGroupNumber;
    const otherInsuredFirstNameControl = this.insuredForm.controls.otherInsuredFirstName;
    const otherInsuredMiddleInitialControl = this.insuredForm.controls.otherInsuredMiddleInitial;
    const otherInsuredLastNameControl = this.insuredForm.controls.otherInsuredLastName;
    // pull data from the data model
    const {
      otherInsuranceIndicator, otherInsured, memberPolicyGroupNumber, memberInsurancePlanProgramName,
      cobAuthorizationNumber, member, patient
    } = dataModel;
    let insuredFirstName: string, insuredMiddleInitial: string, insuredLastName: string, gender: string;
    let phones: string[];
    let name: MembershipName;
    let dateOfBirth: Date;
    let addresses: Address[];
    let relationToSubscriber: string;
    if (member) {
      ({name, dateOfBirth, gender, addresses, phones} = member);
      if (name) {
        insuredFirstName = name.firstName;
        insuredMiddleInitial = name.middleInitial;
        insuredLastName = name.lastName;
      }
    }
    if (patient) {
      relationToSubscriber = patient.relationToSubscriber;
    }
    let otherInsuredFirstName: string, otherInsuredLastName: string, otherInsuredMiddleInitial: string,
      policyGroupNumber: string, insurancePlanProgramName: string;
    if (otherInsured) {
      ({policyGroupNumber, insurancePlanProgramName} = otherInsured);
      otherInsuredFirstName = otherInsured.firstName;
      otherInsuredLastName = otherInsured.lastName;
      otherInsuredMiddleInitial = otherInsured.middleInitial;
    }
    // if a claim exists on load, some existing data should not be editable
    if (insuredFirstName || insuredMiddleInitial || insuredLastName) {
      insuredFirstNameControl.disable(ApplicationConstants.updateFormWithoutEmit);
      insuredMiddleInitialControl.disable(ApplicationConstants.updateFormWithoutEmit);
      insuredLastNameControl.disable(ApplicationConstants.updateFormWithoutEmit);
    }
    if (otherInsuranceIndicator !== undefined && otherInsuranceIndicator !== false) {
      otherInsurance.disable(ApplicationConstants.updateFormWithoutEmit);
    }
    if (memberInsurancePlanProgramName) {
      memberInsurancePlan.disable(ApplicationConstants.updateFormWithoutEmit);
    }
    if (otherInsuredFirstName) {
      otherInsuredFirstNameControl.disable(ApplicationConstants.updateFormWithoutEmit);
    }
    if (otherInsuredMiddleInitial || (otherInsuredFirstName && otherInsuredLastName)) {
      otherInsuredMiddleInitialControl.disable(ApplicationConstants.updateFormWithoutEmit);
    }
    if (otherInsuredLastName) {
      otherInsuredLastNameControl.disable(ApplicationConstants.updateFormWithoutEmit);
    }
    if (policyGroupNumber) {
      otherInsuredPolicyNumber.disable(ApplicationConstants.updateFormWithoutEmit);
    }
    if (cobAuthorizationNumber) {
      otherInsuredVsrNumber.disable(ApplicationConstants.updateFormWithoutEmit);
    }
    if (insurancePlanProgramName) {
      otherInsuredPlanName.disable(ApplicationConstants.updateFormWithoutEmit);
    }
    if (memberPolicyGroupNumber) {
      memberPolicyGroupNumberFromControl.disable(ApplicationConstants.updateFormWithoutEmit);
    }
    if (isNullOrUndefined(dateOfBirth)) {
      this.showRequiredDateOfBirthAsterisk = false;
    }
    this.patientRelationToSubscriber = relationToSubscriber;
  }

  private enableFieldsOnUnmask(): void {
    const {
      otherInsurance, memberInsurancePlan, otherInsuredPolicyNumber, otherInsuredVsrNumber, otherInsuredPlanName,
      memberAddressStreet1, memberAddressStreet2, memberAddressCity, memberAddressStateCode, memberAddressZipCode, memberGender, insuredPhone
    } = this.insuredForm.controls;
    const insuredFirstNameControl = this.insuredForm.controls.insuredFirstName;
    const insuredMiddleInitialControl = this.insuredForm.controls.insuredMiddleInitial;
    const insuredLastNameControl = this.insuredForm.controls.insuredLastName;
    const memberPolicyGroupNumberFromControl = this.insuredForm.controls.memberPolicyGroupNumber;
    const otherInsuredFirstNameControl = this.insuredForm.controls.otherInsuredFirstName;
    const otherInsuredMiddleInitialControl = this.insuredForm.controls.otherInsuredMiddleInitial;
    const otherInsuredLastNameControl = this.insuredForm.controls.otherInsuredLastName;
    // pull data from the data model
    const {member} = this.activeClaim;
    let insuredFirstName: string, insuredMiddleInitial: string, insuredLastName: string, gender: string;
    let phones: string[];
    let name: MembershipName;
    let dateOfBirth: Date;
    let addresses: Address[];
    if (member) {
      ({name, dateOfBirth, gender, addresses, phones} = member);
      if (name) {
        insuredFirstName = name.firstName;
        insuredMiddleInitial = name.middleInitial;
        insuredLastName = name.lastName;
      }
    }

    // if a claim exists on load, some existing data should be editable
    if (!insuredFirstName && !insuredMiddleInitial && !insuredLastName) {
      insuredFirstNameControl.enable(ApplicationConstants.updateFormWithoutEmit);
      insuredMiddleInitialControl.enable(ApplicationConstants.updateFormWithoutEmit);
      insuredLastNameControl.enable(ApplicationConstants.updateFormWithoutEmit);
    }
    // Keep COB button and COB claim number input enabled regardless
    otherInsurance.enable(ApplicationConstants.updateFormWithoutEmit);
    otherInsuredVsrNumber.enable(ApplicationConstants.updateFormWithoutEmit);
    memberInsurancePlan.enable(ApplicationConstants.updateFormWithoutEmit);
    otherInsuredFirstNameControl.enable(ApplicationConstants.updateFormWithoutEmit);
    otherInsuredMiddleInitialControl.enable(ApplicationConstants.updateFormWithoutEmit);
    otherInsuredLastNameControl.enable(ApplicationConstants.updateFormWithoutEmit);
    otherInsuredPolicyNumber.enable(ApplicationConstants.updateFormWithoutEmit);
    otherInsuredPlanName.enable(ApplicationConstants.updateFormWithoutEmit);
    memberPolicyGroupNumberFromControl.enable(ApplicationConstants.updateFormWithoutEmit);

    // Enable address, sex, and phone if the patient is not the insured during unmasking
    if (this.patientRelationToSubscriber !== this.formState.Member) {
      memberAddressStreet1.enable(ApplicationConstants.updateFormWithoutEmit);
      memberAddressStreet2.enable(ApplicationConstants.updateFormWithoutEmit);
      memberAddressCity.enable(ApplicationConstants.updateFormWithoutEmit);
      memberAddressStateCode.enable(ApplicationConstants.updateFormWithoutEmit);
      memberAddressZipCode.enable(ApplicationConstants.updateFormWithoutEmit);
      memberGender.enable(ApplicationConstants.updateFormWithoutEmit);
      insuredPhone.enable(ApplicationConstants.updateFormWithoutEmit);
    }
  }

  updateInsuredGenderOnActiveClaim(selectedInsuredGender: string): void {
    const memberGender = this.insuredForm.controls.memberGender;
    memberGender.setValue(selectedInsuredGender, ApplicationConstants.updateFormWithoutEmit);
    this.activeClaim.member.gender = selectedInsuredGender;
    if (this.insuredCardHasChanged()) {
      this.claimService.setActiveClaim(this.activeClaim, this.id);
    }
  }

  private updateDataModelFromViewModel(): void {
    const {
      memberDateOfBirth, memberGender, otherInsurance,
      memberAddressStreet1, memberAddressStreet2, memberAddressCity, memberAddressStateCode, memberAddressZipCode,
      memberInsurancePlan, insuredPhone, otherInsuredFirstName, otherInsuredMiddleInitial, otherInsuredLastName,
      otherInsuredPolicyNumber, otherInsuredVsrNumber, otherInsuredPlanName
    } = this.insuredForm.controls;
    const memberPolicyGroupNumberControl = this.insuredForm.controls.memberPolicyGroupNumber;
    // Gather and convert view model data
    const zipCodeFormValue = memberAddressZipCode.value;
    let zipCode: string;
    if (zipCodeFormValue && zipCodeFormValue.length === 5) {
      zipCode = zipCodeFormValue;
    }
    const dateOfBirthValue = (DateUtility.isValidDate(memberDateOfBirth.value))
      ? new Date(memberDateOfBirth.value)
      : this.activeClaim.member.dateOfBirth;
    const patientPhoneFormValue = (insuredPhone.value)
      ? insuredPhone.value
      : undefined;
    const address: Address = {
      street1: memberAddressStreet1.value,
      street2: memberAddressStreet2.value,
      city: memberAddressCity.value,
      stateCode: memberAddressStateCode.value,
      zipCode: {
        zipCode
      }
    };
    // Instantiate claim objects if they don't exist
    if (!this.activeClaim.member) {
      this.activeClaim.member = {} as Membership;
    }
    if (!this.activeClaim.otherInsured) {
      this.activeClaim.otherInsured = {} as OtherInsured;
    }
    // Populate claim data from the converted view model data
    this.activeClaim.memberPolicyGroupNumber = memberPolicyGroupNumberControl.value;
    this.activeClaim.member.addresses[0] = address;
    this.activeClaim.member.dateOfBirth = dateOfBirthValue;
    this.activeClaim.member.gender = memberGender.value;
    this.activeClaim.member.phones[0] = patientPhoneFormValue;
    this.activeClaim.memberInsurancePlanProgramName = memberInsurancePlan.value;
    this.activeClaim.otherInsuranceIndicator = returnBooleanFromFormState(otherInsurance.value);
    this.activeClaim.otherInsured.firstName = otherInsuredFirstName.value;
    this.activeClaim.otherInsured.middleInitial = otherInsuredMiddleInitial.value;
    this.activeClaim.otherInsured.lastName = otherInsuredLastName.value;
    this.activeClaim.otherInsured.policyGroupNumber = otherInsuredPolicyNumber.value;
    this.activeClaim.otherInsured.insurancePlanProgramName = otherInsuredPlanName.value;
    this.activeClaim.cobAuthorizationNumber = otherInsuredVsrNumber.value;
    // Update the active claim in the claim service
    if (this.insuredCardHasChanged()) {
      this.claimService.setActiveClaim(this.activeClaim, this.id);
    }
  }

  private insuredCardHasChanged(): boolean {
    const {
      memberPolicyGroupNumber: memberPolicyGroupNumberFromCard, memberInsurancePlanProgramName: memberInsurancePlanProgramNameFromCard,
      cobAuthorizationNumber: cobAuthorizationNumberFromCard, otherInsuranceIndicator: otherInsuranceIndicatorFromCard
    } = this.activeClaim;
    const {addresses: addressesFromCard, dateOfBirth: dateOfBirthFromCard, gender: genderFromCard, phones: phonesFromCard} = this.activeClaim.member;
    const dateOfBirthFromCardString = DateUtility.buildFriendlyDateFromJsDate(dateOfBirthFromCard);
    const {
      firstName: firstNameFromCard, middleInitial: middleInitialFromCard, lastName: lastNameFromCard, policyGroupNumber: policyGroupNumberFromCard,
      insurancePlanProgramName: insurancePlanProgramNameFromCard
    } = this.activeClaim.otherInsured;


    const activeClaimFromService = this.claimService.getActiveClaim();
    // Instantiate claim objects if they don't exist
    if (isNullOrUndefined(activeClaimFromService.member)) {
      activeClaimFromService.member = {} as Membership;
    }
    if (isNullOrUndefined(activeClaimFromService.otherInsured)) {
      activeClaimFromService.otherInsured = {} as OtherInsured;
    }
    if (isNullOrUndefined(activeClaimFromService.member.addresses)) {
      activeClaimFromService.member.addresses = [{}] as Address[];
    }
    if (isNullOrUndefined(activeClaimFromService.member.addresses[0].zipCode)) {
      activeClaimFromService.member.addresses[0].zipCode = {};
    }
    const {
      memberPolicyGroupNumber: memberPolicyGroupNumberFromService, memberInsurancePlanProgramName: memberInsurancePlanProgramNameFromService,
      cobAuthorizationNumber: cobAuthorizationNumberFromService, otherInsuranceIndicator: otherInsuranceIndicatorFromService
    } = activeClaimFromService;
    const {addresses: addressesFromService, dateOfBirth: dateOfBirthFromService, gender: genderFromService, phones: phonesFromService} = activeClaimFromService.member;
    const dateOfBirthFromServiceString = DateUtility.buildFriendlyDateFromJsDate(dateOfBirthFromService);
    const {
      firstName: firstNameFromService, middleInitial: middleInitialFromService, lastName: lastNameFromService, policyGroupNumber: policyGroupNumberFromService,
      insurancePlanProgramName: insurancePlanProgramNameFromService
    } = activeClaimFromService.otherInsured;

    return (memberPolicyGroupNumberFromCard || undefined) !== (memberPolicyGroupNumberFromService || undefined) ||
      (memberInsurancePlanProgramNameFromCard || undefined) !== (memberInsurancePlanProgramNameFromService || undefined) ||
      (cobAuthorizationNumberFromCard || undefined) !== (cobAuthorizationNumberFromService || undefined) ||
      (addressesFromCard[0].city || undefined) !== (addressesFromService[0].city || undefined) ||
      (addressesFromCard[0].stateCode || undefined) !== (addressesFromService[0].stateCode || undefined) ||
      (addressesFromCard[0].street1 || undefined) !== (addressesFromService[0].street1 || undefined) ||
      (addressesFromCard[0].street2 || undefined) !== (addressesFromService[0].street2 || undefined) ||
      (addressesFromCard[0].zipCode.zipCode || undefined) !== (addressesFromService[0].zipCode.zipCode || undefined) ||
      (addressesFromCard[0].zipCode.zipExtension || undefined) !== (addressesFromService[0].zipCode.zipExtension || undefined) ||
      (dateOfBirthFromCardString || undefined) !== (dateOfBirthFromServiceString || undefined) ||
      (genderFromCard || undefined) !== (genderFromService || undefined) ||
      (phonesFromCard[0] || undefined) !== (phonesFromService[0] || undefined) ||
      (otherInsuranceIndicatorFromCard || undefined) !== (otherInsuranceIndicatorFromService || undefined) ||
      (firstNameFromCard || undefined) !== (firstNameFromService || undefined) ||
      (middleInitialFromCard || undefined) !== (middleInitialFromService || undefined) ||
      (lastNameFromCard || undefined) !== (lastNameFromService || undefined) ||
      (policyGroupNumberFromCard || undefined) !== (policyGroupNumberFromService || undefined) ||
      (insurancePlanProgramNameFromCard || undefined) !== (insurancePlanProgramNameFromService || undefined);
  }

  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  // Convenience getter for toggling 11d. controls
  get otherInsuranceDependentControls(): AbstractControl[] {
    return [
      this.insuredForm.get('otherInsuredFirstName'),
      this.insuredForm.get('otherInsuredMiddleInitial'),
      this.insuredForm.get('otherInsuredLastName'),
      this.insuredForm.get('otherInsuredPolicyNumber'),
      this.insuredForm.get('otherInsuredPlanName')
    ];
  }

  set otherInsurance(newVal: string) {
    this._otherInsurance = newVal;
    this.otherInsuranceDependentControls.forEach((control: AbstractControl) => {
      if (this._otherInsurance === FormStateYesNo.Yes) {
        control.enable();
      } else {
        control.reset();
        control.disable();
      }
    });
  }

  get otherInsurance(): string {
    return this._otherInsurance;
  }

  /**
   * Value is updated by changes to the data model value: patient.relationToSubscriber
   * @param {string} newVal
   */
  set patientRelationToSubscriber(newVal: string) {
    this._patientRelationToSubscriber = newVal;
    // patient is insured
    if (this._patientRelationToSubscriber === this.formState.Member) {
      this.insuredForm.controls.insuredFirstName.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.insuredMiddleInitial.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.insuredLastName.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberDateOfBirth.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberGender.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberAddressStreet1.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberAddressStreet2.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberAddressCity.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberAddressStateCode.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberAddressZipCode.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.insuredPhone.disable(ApplicationConstants.updateFormWithoutEmit);
    } else {
      // patient is not insured
      this.insuredForm.controls.insuredFirstName.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.insuredMiddleInitial.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.insuredLastName.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberDateOfBirth.disable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberGender.enable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberAddressStreet1.enable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberAddressStreet2.enable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberAddressCity.enable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberAddressStateCode.enable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.memberAddressZipCode.enable(ApplicationConstants.updateFormWithoutEmit);
      this.insuredForm.controls.insuredPhone.enable(ApplicationConstants.updateFormWithoutEmit);
    }
  }

  get patientRelationToSubscriber(): string {
    return this._patientRelationToSubscriber;
  }

  /***** END - PUBLIC FUNCTIONS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.originalClaim = this.claimService.getOriginalClaim();
    this.registerWithClaimProgressService();
    this.buildForm();
    this.buildErrorWrapperConfig();
    this.buildDatePickerConfigurationDateOfBirth();
    if (this.originalClaim.otherInsuranceIndicator) {
      this.viewStateService.setCobViewState(this.originalClaim.otherInsuranceIndicator);
      this.otherInsurance = this.originalClaim.otherInsuranceIndicator ? this.formStateYesNo.Yes : this.formStateYesNo.No;
    }
    // Register for updates to the active claim
    // NOTE: uiFormattedClaim will be undefined if any errors occurred upstream
    this.observableSubscriptions.push(this.claimService.onCardsToUpdate.subscribe((onCardsToUpdate: ClaimCardsToUpdate) => {
      this.activeClaim = this.claimService.getActiveClaim();
      // Set form data if the data exists
      if (onCardsToUpdate.insured || onCardsToUpdate.all) {
        this.setViewModelFromDataModel(this.activeClaim);
      }
      // TODO: assuming that an empty claim is undefined from the service side.  This will likely need to be updated
      // to a different checking mechanism once claims are created for the first time
      if (this.initialLoad && this.activeClaim) {
        this.initialLoad = false;
        // On initial load, if the claim exists, disable any prefilled fields
        this.setDisabledFieldsFromDataModel(this.activeClaim);
      }
    }));
    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      if (mask) {
        this.disableFormGroupComponents(this.insuredForm);
        this.componentMask.show();
      } else {
        this.enableFieldsOnUnmask();
        this.componentMask.hide();
      }
    }));

    // Register form changes to be cleaned up upon component destruction
    this.observableSubscriptions.push(this.insuredForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe(() => {
      this.updateDataModelFromViewModel();
    }));

    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
          (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }
      }));

  }

  ngAfterViewInit(): void {
    this.buildInputMasks();
  }


  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {MatRadioChange} newVal - FormState.Yes || FormState.No
   */
  onOtherInsuranceChange(newVal: MatRadioChange): void {
    const matRadioChangeValue: string = newVal.value;
    if (matRadioChangeValue !== undefined && this.otherInsurance !== matRadioChangeValue) {
      this.otherInsurance = matRadioChangeValue;
      this.viewStateService.setCobViewState(returnBooleanFromFormState(matRadioChangeValue));
    }
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /***** END - EVENT HANDLERS *****/
}
