<app-component-mask></app-component-mask>

<!-- Show/Hide CMS Numbers -->
<div cms-number-toggle id="exam-cmsNumberToggle"></div>

<div id="{{id}}">
  <mat-card class="container-white-full-column">

    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings" class="sticky-card-header" [ngClass]="{ 'disabled-card-header': examForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="exam-title">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits" [ngClass]="{ 'disabled-card-header': examForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="exam-title-edits">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning" [ngClass]="{ 'disabled-card-header': examForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="exam-title-warning">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Main Form -->
    <mat-card-content>
      <form [formGroup]="examForm" fxLayout="column" novalidate>

        <div fxLayout="row" fxLayoutAlign="start center" class="margin-top-30">
          <label id="exam-type-label" class="nested-column-7">Exam Type</label>
          <div class="nested-column-2"></div>
          <label id="exam-dilation-performed-label" class="nested-column-3"><span class="required-label-asterisk" *ngIf="dilationRequired">* </span>Dilation Performed?</label>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" class="margin-top-15 margin-bottom-30">
          <!-- Exam Type -->
          <div id="exam-type-dropdown-container" class="nested-column-7">
            <select matSelect formControlName="examType" class="custom-select" placeholder="Select Exam Type"
                    (change)="onExamTypeChange()" id="exam-type-group">
              <option selected></option>
              <option *ngFor="let type of examTypeGroup1" id="exam-type-group1-{{type.value}}"
                      [value]="type.value">{{type.label}}</option>
              <option disabled></option>
              <option *ngFor="let type of examTypeGroup2" id="exam-type-group2-{{type.value}}"
                      [value]="type.value">{{type.label}}</option>
              <option disabled></option>
            </select>
          </div>

          <!-- Refraction -->
          <div id="exam-refraction-checkbox-container" class="nested-column-2" fxLayout="row">
            <mat-checkbox formControlName="refractionTestPerformed" id="exam-refraction-performed-checkbox"
                          (keyup.enter)="onKeypressEventCheckbox( examForm.get(examConstants.REFRACTION_INDICATOR))">Refraction Performed</mat-checkbox>
          </div>

          <!-- Dilation Performed -->
          <div fxLayout="column" class="nested-column-3">
            <mat-radio-group fxLayout="row" formControlName="physicianInvoicePatientDilated"
                             id="exam-dilation-performed-group" fxLayoutAlign="start center">
              <mat-radio-button #patientDilatedButton id="exam-dilation-performed-yes-toggle" value="{{formState.Yes}}"
                                (click)="onphysicianInvoicePatientDilatedClicked()"
                                (keyup.enter)="onKeypressEventRadiobutton(patientDilatedButton)">{{formState.Yes}}</mat-radio-button>
              <mat-radio-button #patientNotDilatedButton id="exam-dilation-performed-no-toggle" fxFlexOffset="30px"
                                value="{{formState.No}}"
                                (click)="onphysicianInvoicePatientDilatedClicked()"
                                (keyup.enter)="onKeypressEventRadiobutton(patientNotDilatedButton)">{{formState.No}} - Reason on File
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- Rendering Provider -->
        <div id="exam-rendering-provider-flex-container" fxLayout="column">
          <div id="exam-rendering-provider-label-container" fxLayout="row" class="nested-column-3">
            <label id="exam-federal-tax-id-label"><span class="required-label-asterisk">* </span>25. Federal Tax ID Number</label>
          </div>

          <div fxLayout="row" class="nested-column-3">
            <label id="exam-select-doctor-label" class="margin-top-20" fxLayoutAlign="start end">Please select Physician or Supplier</label>
          </div>

          <div fxLayout="row" class="margin-top-15 margin-bottom-30" fxLayoutAlign="start center">
            <div class="nested-column-7" fxLayout="column">
              <select matSelect formControlName="selectedDoctor" class="custom-select required-form-field"
                      placeholder="Please Select a Doctor" id="exam-rendering-provider-group">
                <option selected disabled value="0">Please Select a Doctor</option>
                <option *ngIf="containsMultipleDoctors" selected></option>
                <option *ngFor="let type of doctorList" id="exam-rendering-provider-{{type.value}}"
                        [value]="type.value">{{type.label}}</option>
              </select>
              <app-error-wrapper id="exam-rendering-provider-group-error"
                                 [config]="errorWrapperConfig.selectedDoctor"></app-error-wrapper>
            </div>
            <!-- Lens and Frame -->
            <div class="nested-column-1 checkbox-alignment">
              <mat-checkbox [id]="lensCheckboxId"
                            (change)="onCheckboxClick(lensCheckboxId, examForm.get(examConstants.LENS_INDICATOR))"
                            [formControlName]="examConstants.LENS_INDICATOR"
                            (keyup.enter)="onKeypressEventCheckbox(examForm.get(examConstants.LENS_INDICATOR));
                            onCheckboxClick(lensCheckboxId, examForm.get(examConstants.LENS_INDICATOR))">Lens
              </mat-checkbox>
            </div>
            <div class="nested-column-2 checkbox-alignment">
              <mat-checkbox [id]="frameCheckboxId"
                            (change)="onCheckboxClick(frameCheckboxId, examForm.get(examConstants.FRAME_INDICATOR))"
                            [formControlName]="examConstants.FRAME_INDICATOR"
                            (keyup.enter)="onKeypressEventCheckbox(examForm.get(examConstants.FRAME_INDICATOR));
                            onCheckboxClick(frameCheckboxId, examForm.get(examConstants.FRAME_INDICATOR))">Frame
              </mat-checkbox>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
