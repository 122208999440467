// START - 3rd Party Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
// END - 3rd Party Modules


// START - Components
import { VspIconSnackbarComponent } from './vsp.icon-snackbar.component';
// END - Components


@NgModule({
  declarations: [
    VspIconSnackbarComponent
  ],
  exports: [
    VspIconSnackbarComponent,
    MatIconModule,
    MatTooltipModule
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    MatTooltipModule
  ]
})
export class VspIconSnackbarModule { }
