<app-component-mask></app-component-mask>
<!-- TODO uncomment all app-error-wrappers when mask is disabled -->

<!-- Show/Hide CMS Numbers -->
<div cms-number-toggle id="contacts-cmsNumberToggle"></div>

<div id="{{id}}">
  <mat-card class="container-white-full-column">

    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings" class="sticky-card-header" [ngClass]="{ 'disabled-card-header': contactsForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="contacts-title">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits" [ngClass]="{ 'disabled-card-header': contactsForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="contacts-title-edits">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning" [ngClass]="{ 'disabled-card-header': contactsForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="contacts-title-warning">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Contacts Form -->
    <mat-card-content>
      <form fxLayout="column" [formGroup]="contactsForm" novalidate>

        <div fxLayout="row" class="margin-top-30">
          <label id="contacts-material-type-label" class="nested-column-4">Material/Type</label>
          <label id="contacts-services-label" class="nested-column-6">Services</label>
          <label id="contacts-reason-label" class="nested-column-2">
              <span *ngIf="isReasonRequired" class="required-label-asterisk">* </span>Reason</label>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-30">
          <!--Material Type Control-->
            <div class="nested-column-4" id="contacts-material-type-container">
            <select matSelect formControlName="materialType" id="contacts-material-type-dropdown" class="custom-select"
                    placeholder="Select Material/Type" (click)="onMaterialTypeClick()">
              <option selected value></option>
              <option *ngFor="let materialType of materialType"
                      [value]="materialType.code">{{materialType.code}} - {{materialType.description}}</option>
            </select>
            <app-loading-dropdown *ngIf="loadingMaterialTypes" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
          </div>

          <!--Services Control-->
          <div class="nested-column-6" id="contacts-service-code-container">
            <select matSelect formControlName="service" id="contacts-services-dropdown" class="custom-select"
                    placeholder="Select a Service" (click)="onServicesClick()">
              <option selected value></option>
              <option *ngFor="let service of service"
                      [value]="service.code">{{service.code}} - {{service.description}}</option>
            </select>
            <app-loading-dropdown *ngIf="loadingServices" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
          </div>

          <!--Reason Control-->
          <div class="nested-column-2" id="contacts-reason-code-container">
            <select matSelect formControlName="reason" id="contacts-reason-dropdown" class="custom-select" [ngClass]="{'required-form-field':isReasonRequired}"
                    placeholder="Select a Reason" (click)="onReasonClick()">
              <option selected value></option>
              <option *ngFor="let reason of reason"
                      [value]="reason.code">{{reason.description}}</option>
            </select>
            <app-loading-dropdown *ngIf="loadingReasons" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
            <app-error-wrapper id="contacts-reason-error-message" [config]="errorWrapperConfig.reason"></app-error-wrapper>
          </div>
        </div>

        <div fxLayout="row">
          <label id="contacts-manufacturer-label" class="nested-column-4"><span *ngIf="isManufacturerRequired" class="required-label-asterisk">* </span>Manufacturer</label>
          <label id="contacts-brand-label" class="nested-column-4"><span *ngIf="isRequiredByManufacturer" class="required-label-asterisk">* </span>Brand</label>
          <label id="contacts-modality-label" class="nested-column-2">Modality</label>
          <label id="contacts-number-of-boxes-label" class="nested-column-2"><span *ngIf="isRequiredByManufacturer" class="required-label-asterisk">* </span>Number of Boxes</label>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-30">
          <!--Manufacturer Control-->
          <div class="nested-column-4" id="contacts-manufacturer-container">
            <select matSelect formControlName="manufacturer" id="contacts-manufacturer-dropdown" class="custom-select" [ngClass]="{'required-form-field':isManufacturerRequired}"
                    placeholder="Select a Manufacturer" (click)="onManufacturersClick()">
              <option selected value></option>
              <option *ngFor="let manufacturer of manufacturer"
                      [value]="manufacturer.name">{{manufacturer.name}}</option>
            </select>
            <app-loading-dropdown *ngIf="loadingManufacturers" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
          </div>

          <!--Brand Control-->
          <div class="nested-column-4" id="contacts-brand-name-container">
            <select matSelect formControlName="brand" id="contacts-brand-dropdown" class="custom-select" [ngClass]="{'required-form-field':isRequiredByManufacturer}"
                    placeholder="Select a Brand">
              <option selected value></option>
              <option *ngFor="let brand of brands"
                      [value]="brand.name">{{brand.name}}</option>
            </select>
          </div>

          <!--Modality Control-->
          <div class="nested-column-2" id="contacts-modality-container">
            <select matSelect formControlName="modality" id="contacts-modality-dropdown" class="custom-select"
                    placeholder="Select a Modality" (click)="onModalityClick()">
              <option selected value></option>
              <option *ngFor="let modality of modality"
                      [value]="modality.code">{{modality.description}}</option>
            </select>
          </div>

          <!--# of Boxes Control-->
          <div class="nested-column-2" fxLayout="column" id="contacts-number-of-boxes-container">
              <mat-form-field appearance="outline" [ngClass]="{'required-mat-form-field':isRequiredByManufacturer}">
                <input
                  matInput
                  #numberOfBoxesHtmlRef
                  formControlName="numberBoxes"
                  id="contacts-number-of-boxes-textbox"
                  type="text"/>
              </mat-form-field>
            </div>
          </div>

      </form>
    </mat-card-content>
  </mat-card>
</div>
