import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Inventories, Inventory} from '../../../../models/Inventory';
import {InventoryService} from '../../../../common/services/data-model/app/inventory/inventory.service';
import {ApplicationConstants, ErrorTypes} from '../../../../common/constants/application.constants';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {isNullOrUndefined} from '../../../../common/utility';

@Component({
  selector: 'app-hcpc-cpt-codes-modal',
  templateUrl: './hcpc-cpt-codes-descriptions-modal.component.html',
  styleUrls: ['./hcpc-cpt-codes-descriptions-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HcpcCptCodesDescriptionsModalComponent {

  /***** START - PUBLIC MEMBERS *****/

  hcpcCptCodeDescriptionForm: FormGroup;
  doServiceLinesContainHcpcOrCptCodes: boolean = false;
  hasSearchBegan: boolean = false;
  hasHcpcOrCptCodeSearchCompleted: boolean = false;
  inventories: Inventories = {} as Inventories;
  inventory: Inventory;
  errorWrapperConfig = {
    hcpcCptCode: undefined,
    hcpcCptCodeRequired: undefined
  };

  /***** END - PUBLIC MEMBERS *****/

  constructor(
    public dialogRef: MatDialogRef<HcpcCptCodesDescriptionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private inventoryService: InventoryService,
    private formBuilder: FormBuilder) {
    this.inventories = this.data.inventories;
    this.buildForm();
    this.buildErrorWrapperConfig();
    if (!isNullOrUndefined(this.inventories) && !isNullOrUndefined(this.inventories.inventory) && this.inventories.inventory.length > 0) {
      this.doServiceLinesContainHcpcOrCptCodes = true;
    }
  }

  /***** START - PUBLIC METHODS *****/

  onCloseClick(): void {
    this.dialogRef.close();
  }

  getDescriptions() {
    if (this.isSearchCombinationValid()) {
      this.hasHcpcOrCptCodeSearchCompleted = false;
      this.hasSearchBegan = true;
      this.inventoryService.getHcpcCptCodeDescription(this.hcpcCptCodeDescriptionForm.controls.hcpcCptCode.value).subscribe((inventory: Inventory) => {
        if (!isNullOrUndefined(inventory)) {
          this.inventory = {} as Inventory;
          this.inventory = inventory;
          this.hasSearchBegan = false;
          this.hasHcpcOrCptCodeSearchCompleted = true;
        }
      });
    }
  }

  isSearchCombinationValid(): boolean {
    let valid: boolean;
    if (!this.hcpcCptCodeDescriptionForm.valid) {
      valid = false;
    } else {
      valid = this.isValidFormControlValue(this.hcpcCptCodeDescriptionForm.controls.hcpcCptCode);
    }
    return valid;
  }

  public buildForm(): void {
    this.hcpcCptCodeDescriptionForm = this.formBuilder.group({
      hcpcCptCode: [undefined, [Validators.pattern(ApplicationConstants.cptHcpcsCodeRegex), Validators.required]]
    });
  }

  /***** END - PUBLIC METHODS *****/

  /***** START - PRIVATE METHODS *****/

  private isValidFormControlValue = (formControl: AbstractControl) => (formControl.value && formControl.valid);

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      hcpcCptCode: {
        control: this.hcpcCptCodeDescriptionForm.get('hcpcCptCode'),
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'Procedure code must be 5 characters'
        }]
      },
      hcpcCptCodeRequired: {
        control: this.hcpcCptCodeDescriptionForm.get('hcpcCptCode'),
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a HCPC/CPT code'
        }]
      },
    };
  }

  /***** END - PRIVATE METHODS *****/

}
