import {Component, HostListener, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {UserSessionService} from '../../common/services/support/user-session/user-session.service';
import {MessageService} from '../../common/services/support/message/message.service';
import {CookieService} from 'ngx-cookie-service';
import {ApplicationConstants} from '../../common/constants/application.constants';
import {Subscription} from 'rxjs';
import {ViewStateService} from '../../common/services/view-state/view-state.service';
import {ClaimFormNavigationService} from '../../common/services/support/claim-form-navigation/claim-form-navigation.service';
import {ClaimsService} from '../../common/services/data-model/app/claims/claims.service';
import {isNullOrUndefined} from '../../common/utility';

@Component({
  selector: 'app-secure-header',
  templateUrl: './secure-header.component.html',
  styleUrls: ['./secure-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  })
export class SecureHeaderComponent implements OnInit, OnDestroy {

  /***** START - PUBLIC MEMBERS *****/
  eyefinityUrl: string;
  secureTEFUrl: string;
  claimFormPartialUrl: string = ApplicationConstants.routing.secure.claimForm + '/' + ApplicationConstants.routing.secure.claimFormEdit;
  /***** END - PUBLIC MEMBERS *****/

  /***** START - PRIVATE MEMBERS *****/
  private observableSubscriptions: Subscription[] = [];
  private _urlNavigatingToFromClaimForm: string = undefined;
  private _isUrlNavigatingToFromClaimFormInternal: boolean = true;

  /***** END - PRIVATE MEMBERS *****/

  constructor(
    private router: Router,
    private messageService: MessageService,
    private userSessionService: UserSessionService,
    private cookieService: CookieService,
    private claimFormNavigationService: ClaimFormNavigationService,
    private claimService: ClaimsService,
    private viewStateService: ViewStateService
  ) {
    this.eyefinityUrl = cookieService.get(ApplicationConstants.efSurlCookie);
    this.secureTEFUrl = cookieService.get(ApplicationConstants.tefSurlCookie);
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!this.routerUrl.includes(this.claimFormPartialUrl)) {
          this.urlNavigatingToFromClaimFormValue = this.routerUrl;
          this.isUrlNavigatingToFromClaimFormInternalValue = true;
        }
      }
    });
  }

  /**
   * This is a host listener that monitors a specific condition on the claim form. If it is met a warning modal will be rendered prompting the user to do something.
   * @param $event - Window event the browser is listening to.
   */
  @HostListener('window:beforeunload', ['$event'])
  renderWarningPopUp($event: any) {
    if (this.shouldWarningPopUpRenderWhenTheUserIsOnTheClaimForm()) {
      $event.returnValue = true;
    }
  }

  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    // Register if save was successful after clicking the save button in the leave claim from warning modal.
    this.observableSubscriptions.push(this.viewStateService.onSaveFromLeaveClaimFormWarningModalWasSuccessful.subscribe((wasSaveSuccessful) => {
      if (!isNullOrUndefined(wasSaveSuccessful)) {
        this.navigateToDesiredUrl(this.urlNavigatingToFromClaimFormValue, this.isUrlNavigatingToFromClaimFormInternalValue);
        // This rests the observable in the autoSave logic back to its original value
        this.userSessionService.autoSavedClaimForm = undefined;
      }
    }));
  }

  ngOnDestroy() {
    this.observableSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  renderLeaveClaimFormModal(urlToNavigateTo: string, isLinkInternal: boolean) {
    this.urlNavigatingToFromClaimFormValue = urlToNavigateTo;
    this.isUrlNavigatingToFromClaimFormInternalValue = isLinkInternal;
    if (this.shouldWarningPopUpRenderWhenTheUserIsOnTheClaimForm()) {
      this.claimFormNavigationService.allowUserToLeaveClaimFormWarningModal().subscribe(allowUserToLeaveClaimForm => {
        if (allowUserToLeaveClaimForm) {
          this.navigateToDesiredUrl(urlToNavigateTo, isLinkInternal);
        }
      });
    } else {
      this.navigateToDesiredUrl(urlToNavigateTo, isLinkInternal);
    }
  }
  /***** END - EVENT HANDLERS *****/

  /***** START - PUBLIC FUNCTIONS *****/

  get routerUrl(): string {
    return this.router.url;
  }

  get urlNavigatingToFromClaimFormValue(): string {
    return this._urlNavigatingToFromClaimForm;
  }

  set urlNavigatingToFromClaimFormValue(value: string) {
    this._urlNavigatingToFromClaimForm = value;
  }

  get isUrlNavigatingToFromClaimFormInternalValue(): boolean {
    return this._isUrlNavigatingToFromClaimFormInternal;
  }

  set isUrlNavigatingToFromClaimFormInternalValue(value: boolean) {
    this._isUrlNavigatingToFromClaimFormInternal = value;
  }

  homeLinkClick() {
    this.renderLeaveClaimFormModal(this.eyefinityUrl + '/eyefinity/html/home_page.htm', false);
  }

  eInsuranceLinkClick() {
    this.renderLeaveClaimFormModal(this.eyefinityUrl + '/eyefinity/html/eInsurance.htm', false);
  }

  newEInsuranceLinkClick() {
    this.renderLeaveClaimFormModal('secure/eInsurance', true);
  }

  eClaimLinkClick() {
    this.renderLeaveClaimFormModal(this.eyefinityUrl + '/servlet/COREServlet?msgProc=520&pageMode=NEXTINFO', false);
  }

  eClaimTrackingLinkClick() {
    this.renderLeaveClaimFormModal(this.eyefinityUrl + '/servlet/EClaimTrkServlet?msgProc=530', false);
  }

  vspOnlineLinkClick() {
    this.renderLeaveClaimFormModal(this.eyefinityUrl + '/eyefinity/html/vsponlinetransfer.htm?VSPOnlineRef=eInsurance_Nav', false);
  }

  claimResourceCenterLinkClick() {
    this.renderLeaveClaimFormModal(this.secureTEFUrl + '/elearn/Page.do?key=claimresource', false);
  }

  authTrackingLinkClick() {
    this.renderLeaveClaimFormModal('/secure/eInsurance/auth-tracking', true);
  }

  eLabLinkClick() {
    this.renderLeaveClaimFormModal(this.secureTEFUrl + '/elab/Home.do', false);
  }

  eBuyLinkClick() {
    this.renderLeaveClaimFormModal(this.secureTEFUrl + '/darwin/eBuyHome.do?method=eBuyHome', false);
  }

  eLearnLinkClick() {
    this.renderLeaveClaimFormModal(this.secureTEFUrl + '/elearn/Home.do', false);
  }

  eWebLinkClick() {
    this.renderLeaveClaimFormModal(this.secureTEFUrl + '/eweb/main', false);
  }

  eHRSolutionsLinkClick() {
    this.renderLeaveClaimFormModal(this.secureTEFUrl + '/erewards/Home.do', false);
  }

  isUserInTheClaimForm(): boolean {
    return this.routerUrl.includes(this.claimFormPartialUrl);
  }

  shouldWarningPopUpRenderWhenTheUserIsOnTheClaimForm(): boolean {
    return this.isUserInTheClaimForm() && !this.claimService.allowClaimFormToBeDeactivated;
  }

  navigateToDesiredUrl(urlToNavigateTo: string, isLinkInternal: boolean): void {
    if (isLinkInternal) {
      this.router.navigate([urlToNavigateTo]);
    } else {
      window.location.href = urlToNavigateTo;
    }
  }
  /***** END - PUBLIC FUNCTIONS *****/
}
