import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber, of } from 'rxjs';
import {ExternalServiceLocation, ExternalServiceLocationResponse} from 'src/app/models/externalServiceLocation';
import { ApplicationConstants } from 'src/app/common/constants/application.constants';
import { MessageService } from '../../../support/message/message.service';
import { ExternalServiceLocationDataService } from '../../http/http-client-data/external-service-location-data/external-service-location-data.service';
import {DateUtility, isNullOrUndefined, isStringNullUndefinedOrEmpty} from 'src/app/common/utility';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ExternalServiceLocationService {

  constructor(
    private externalServiceLocationDataSvc: ExternalServiceLocationDataService,
    private router: Router,
    private messageService: MessageService
  ) { }

  /***** START - PRIVATE MEMBERS *****/
  private externalServiceLocation: ExternalServiceLocation;
  private loadingServiceLocationCallInProgress: boolean;
  private practiceNameValue: string;
  /***** END - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/
  onExternalServiceLocation = new BehaviorSubject(undefined);
  onLoadingServiceLocationCallInProgress = new BehaviorSubject(undefined);
  /***** END - PUBLIC MEMBERS *****/

  /***** START - PRIVATE FUNCTIONS *****/
  private setExternalServiceLocation(externalServiceLocation: ExternalServiceLocation): void {
    this.externalServiceLocation = (externalServiceLocation) ? Object.deepClone(externalServiceLocation) : externalServiceLocation;
    this.onExternalServiceLocation.next(this.externalServiceLocation);
  }

  private setLoadingExternalServiceLocationCallInProgress(callInProgress: boolean) {
    this.loadingServiceLocationCallInProgress = callInProgress;
    this.onLoadingServiceLocationCallInProgress.next(this.loadingServiceLocationCallInProgress);
  }

  private showErrorSnackBarAndCompleteRequest(observer: Subscriber<ExternalServiceLocation>, errorMessage: string): Observable<ExternalServiceLocation> {

    this.messageService.showErrorSnackbar(errorMessage);

    observer.next(undefined);
    observer.complete();
    return of(undefined);
  }
  /***** END - PRIVATE FUNCTIONS *****/

  /***** START - PUBLIC FUNCTIONS *****/

  loadExternalServiceLocation(extSvcLocURL: string, asOfDate?: string, showSnackbar: boolean = true): Observable<ExternalServiceLocation> {
    this.setLoadingExternalServiceLocationCallInProgress(true);
    this.externalServiceLocation = undefined; // resetting back to undefined for next request
    const extSvcLocUrlWithoutDomain = extSvcLocURL.replace(ApplicationConstants.domainOfUrlRegex, '');
    const extSvcLocUrlQueryParams = this.router.parseUrl(extSvcLocUrlWithoutDomain).queryParams;
    asOfDate = (asOfDate) ? asOfDate : extSvcLocUrlQueryParams.asOfDate;
    const networkIdFromExtSvcLocUrl = extSvcLocUrlQueryParams.networkId;
    const updatedURL = extSvcLocURL.split('?', 1)[0] + '?asOfDate=' + asOfDate + '&networkId=' + networkIdFromExtSvcLocUrl;
    return new Observable((observer) => {
      this.externalServiceLocationDataSvc.getExternalServiceLocation(updatedURL, showSnackbar).subscribe((externalServiceLocationResponse) => {

        // If the API failed
        if (isNullOrUndefined(externalServiceLocationResponse)) {
          observer.next(undefined);
          observer.complete();
          return;
        }

        // Response is good but no doctors... display error...
        if (!externalServiceLocationResponse.doctors || externalServiceLocationResponse.doctors.length < 1) {
          const errorMessage = ApplicationConstants.errorMessages.doctorsNotFoundForDateOfService(DateUtility.formatApiDateStringToPresentationDate(asOfDate));
          return this.showErrorSnackBarAndCompleteRequest(observer, errorMessage);
        }

        const externalServiceLocation: ExternalServiceLocation = {
          uncutLensInd: externalServiceLocationResponse.uncutLensInd,
          iofStockProgramAuthorized: externalServiceLocationResponse.iofStockProgramAuthorized,
          labNetworkOptOutInd: externalServiceLocationResponse.labNetworkOptOutInd,
          uiFormattedDoctors: []
        };
        externalServiceLocationResponse.doctors.forEach(doctor => {
          if (doctor.nationalProviderId) {// If Doctor is without NPI then don't display
            externalServiceLocation.uiFormattedDoctors.push({
              value: doctor.nationalProviderId,
              label: externalServiceLocationResponse.providerTaxId + ' - ' + doctor.name.firstName + ' ' + doctor.name.lastName
            });
          }
        });

        this.setExternalServiceLocation(externalServiceLocation);
        this.setLoadingExternalServiceLocationCallInProgress(false);
        observer.next(externalServiceLocation);
        observer.complete();
      });
    });
  }

  getExternalServiceLocationOfficeInfo(showErrorSnackbar: boolean = true): Observable<ExternalServiceLocationResponse> {
    const todayApiFormattedDate: string = DateUtility.buildYyyyMmDdDateFromDate(ApplicationConstants.todaysDate);
    const externalServiceLocationUrlNoHost: string = ApplicationConstants.api.externalServiceLocationEndpoint + '?asOfDate=' + todayApiFormattedDate;
    return new Observable((observer) => {
      this.externalServiceLocationDataSvc.getExternalServiceLocationWithEnvironmentHost(externalServiceLocationUrlNoHost, showErrorSnackbar).subscribe((externalServiceLocationResponse) => {

        // If the API failed
        if (isNullOrUndefined(externalServiceLocationResponse)) {
          observer.next(undefined);
          observer.complete();
          return;
        }

        // Set the practice name in the service so that the claims tracking search results can get it.
        if (!isStringNullUndefinedOrEmpty(externalServiceLocationResponse.name)) {
          this.practiceName = externalServiceLocationResponse.name;
        } else {
          this.practiceName = ApplicationConstants.claimsTracking.tempPracticeName;
        }

        observer.next(externalServiceLocationResponse);
        observer.complete();
      });
    });
  }

  get practiceName(): string {
    return this.practiceNameValue;
  }

  set practiceName(value: string) {
    this.practiceNameValue = value;
  }

  /***** END - PUBLIC FUNCTIONS *****/
}
