import { Pipe, PipeTransform } from '@angular/core';
import { isStringNullUndefinedOrEmpty } from '../utility/utility-functions/utility-functions';

@Pipe({
  name: 'modifierCode'
})
export class ModifierCodesPipe implements PipeTransform {

  transform(value: string | string[]): any {

    if (value) {
      let stringValue: string = (typeof value == "string") ? value : value.toString();
      if (!isStringNullUndefinedOrEmpty(stringValue)) {
        let returnString = [value];
        // Each modifier code should be 2 characters in length, separated by a comma
        if (stringValue.indexOf(",") > -1) {
          let splitCodes = stringValue.split(",");
          let lastEl = splitCodes[splitCodes.length - 1];
          if (lastEl.length > 2) {
            returnString = this.splitString(lastEl, splitCodes);
          }
          return splitCodes.join();
        } else {
          let splitCodes = [];
          if (stringValue.length > 2) {
            returnString = this.splitString(stringValue, splitCodes);
          }
        }
        return returnString.join();
      }
    }

    return value;
  }

  private splitString(stringToSplit: string, stringArray: string[]) {
    let firstTwo = stringToSplit.slice(0, 2);
    let lastIndex = stringArray.length == 0 ? 0 : stringArray.length - 1;
    stringArray[lastIndex] = firstTwo;
    stringArray.push(stringToSplit.slice(2));
    return stringArray;
  }

}
