<div fxLayout="column" class="frame-source-modal">

  <mat-card>
    <!-- Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 class="nested-column-12"
            id="frame-source-modal-header" mat-dialog-title>Frame Source</h1>
      </mat-card-title>

    </mat-card-header>

    <!-- Body -->
    <mat-card-content>
      <div class="eclaim-body-text nested-column-12">
        <label id="frame-source-modal-message-text-1" class="eclaim-body-text-bold first-paragraph">Your Marchon/Altair frame board management program recommends Order From Vendor for the Frame Source to keep this top selling frame on your board.</label>
        <label id="frame-source-modal-message-text-2" class="second-paragraph">If you select Doctor Supplied, Marchon/Altair will send you the following curated frame to replace your board sample.
          You may be charged a shipping fee. See <span class="action-link see-program-faqs" (click)="onSeeProgramFAQsClick()" id="frame-source-modal-see-program-faqs-link">program FAQs</span>.</label>
      </div>
    </mat-card-content>

    <!-- Footer Line -->
    <hr/>

    <!-- KScope Logo/Cancel/Continue buttons -->
    <mat-card-actions>
      <!-- Top Level Row-->
      <div fxLayout="row">
        <!-- KScope Logo-->
        <div class="nested-column-2">
          <img class="margin-top-5" id="frame-source-kaleyedoscope-logo" height="50px" width="120px"
               ngSrc="assets/images/kaleyedoscopecircle.svg" alt="">
        </div>
        <!-- Cancel/Continue buttons -->
        <div class="nested-column-10">
          <div fxLayout="row" fxLayoutAlign="end center"
               class="margin-top-15 margin-bottom-15">
            <!-- Use Order From Vendor Button -->
            <button mat-dialog-close class="cancel-button" (click)="onUseOrderFromVendorButtonClick()"
                    id="frame-source-modal-use-order-from-vendor-button"><span class="recommended-text" id="frame-source-modal-recommended">Recommended: </span>Order From Vendor</button>
            <!-- Use Doctor Supplied Button -->
            <button mat-dialog-close class="btn btn-primary margin-left-30" (click)="onUseDoctorSuppliedButtonClick()"
                    id="frame-source-modal-use-doctor-supplied-button">Continue With Doctor Supplied</button>
          </div>
        </div>
      </div>
    </mat-card-actions>

  </mat-card>

</div>
