<app-component-mask></app-component-mask>

<div claim-progress-item item-title="Frames" form="forms.frames" id="{{id}}">
  <mat-card class="container-white-full-column">

    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings" class="sticky-card-header" [ngClass]="{ 'disabled-card-header': frameForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="frame-title">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits" [ngClass]="{ 'disabled-card-header': frameForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="frame-title-edits">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning" [ngClass]="{ 'disabled-card-header': frameForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="frame-title-warning">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Main Form -->
    <mat-card-content>
      <form fxLayout="column" [formGroup]="frameForm" novalidate>

        <!-- ############################################################################################################################################################################## -->
        <!-- Non-Kaleyedoscope Practice - First Row - Labels -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" fxLayoutAlign="start center" class="margin-top-30 margin-bottom-15"
             *ngIf="!isKaleyedoscopePractice">
          <div class="nested-column-4">
            <label id="frame-supplier-label"><span class="required-label-asterisk"
                                                   *ngIf="isFrameSupplierRequired"
                                                   id="frames-frame-supplier-dropdown-required-asterisk">* </span>Supplier</label>
          </div>
          <div class="nested-column-4">
            <label id="frame-frame-label">Frame
              <mat-icon
                id="frame-frame-link"
                class="help-circle"
                [ngClass]="{ 'disabled-help-circle': frameForm.disabled }"
                (keydown.space)="onHelpLinkClick($event)"
                (keydown.enter)="onHelpLinkClick($event)"
                (click)="onHelpLinkClick($event)"
                svgIcon="eclaim-icons:helpCircle">
              </mat-icon>
            </label>
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Non-Kaleyedoscope Practice - Second Row - Input Fields -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30" *ngIf="!isKaleyedoscopePractice">
          <!-- Supplier -->
          <div class="nested-column-4">
            <select matSelect formControlName="supplier" (change)="onSupplierChange()" class="custom-select"
                    id="frames-frame-supplier-dropdown" [ngClass]="{'required-form-field':isFrameSupplierRequired}">
              <option selected value></option>
              <option *ngFor="let supplier of selectOptions.suppliers" [value]="supplier.value">{{supplier.label}}</option>
            </select>
            <app-error-wrapper id="frame-supplier-error-message" [config]="errorWrapperConfig.supplier"
                               *ngIf="isFrameSupplierRequired"></app-error-wrapper>
          </div>

          <!-- Frame Search -->
          <div class="nested-column-4">
            <mat-form-field appearance="outline" fxFill>
              <input matInput type="text" formControlName="query" autocomplete="off" id="frame-search-textbox"
                     class="form-control full-width" placeholder="Enter UPC or Collection and Model Information"
                     [maxLength]="queryMaxLength"/>
            </mat-form-field>
          </div>

          <!-- Search Button - Hide in readonly mode -->
          <div class="nested-column-4" [ngClass]="{ 'claim-form-readonly': readOnly }">
            <!-- make sure to remove the disabled attribute on this button after frame section is enabled for claim form -->
            <!--  formControlName="search" -->
            <button mat-flat-button id="frame-search-button" class="mat-flat-button mat-primary" *ngIf="!readOnly" (click)="onSearchClick()"
                    [disabled]="editingDetails || !frameForm.controls.query.value || frameForm.disabled">Search
            </button>
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - First Row - Labels - "Changing the Order of Supplier Frame & Search" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" fxLayoutAlign="start center" class="margin-top-30 margin-bottom-15" *ngIf="isKaleyedoscopePractice">
          <div class="nested-column-4">
            <label id="kscope-frame-label">Frame
              <mat-icon
                id="kscope-frame-link"
                class="help-circle"
                [ngClass]="{ 'disabled-help-circle': frameForm.disabled }"
                (keydown.space)="onHelpLinkClick($event)"
                (keydown.enter)="onHelpLinkClick($event)"
                (click)="onHelpLinkClick($event)"
                svgIcon="eclaim-icons:helpCircle">
              </mat-icon>
            </label>
          </div>
          <div class="custom-nested-column-1_25"></div>
          <div class="nested-column-4">
            <label id="kscope-frame-supplier-label"><span class="required-label-asterisk"
                                                          *ngIf="isFrameSupplierRequired"
                                                          id="kscope-frame-supplier-dropdown-required-asterisk">* </span>Supplier</label>
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Second Row - Input Fields -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" *ngIf="isKaleyedoscopePractice">
          <!-- Frame Search -->
          <div class="nested-column-4">
            <mat-form-field appearance="outline" fxFill>
              <input matInput type="text" formControlName="query" autocomplete="off" id="kscope-frame-search-textbox"
                     class="form-control full-width" placeholder="Enter UPC or Collection and Model Information"
                     [maxLength]="queryMaxLength"/>
            </mat-form-field>
          </div>

          <!-- Search Button - Hide in readonly mode -->
          <div class="custom-nested-column-1_25" [ngClass]="{ 'claim-form-readonly': readOnly }">
            <!-- make sure to remove the disabled attribute on this button after frame section is enabled for claim form -->
            <!--  formControlName="search" -->
            <button mat-flat-button id="kscope-frame-search-button" class="mat-flat-button mat-primary"
                    *ngIf="!readOnly" (click)="onSearchClick()"
                    [disabled]="editingDetails || !frameForm.controls.query.value || frameForm.disabled">
              Search
            </button>
          </div>

          <!-- Supplier -->
          <div class="nested-column-5">
            <select matSelect formControlName="supplier" (change)="onSupplierChange()" class="custom-select"
                    id="kscope-frames-frame-supplier-dropdown"
                    [ngClass]="{'required-form-field':isFrameSupplierRequired}">
              <option selected value></option>
              <option *ngFor="let supplier of selectOptions.suppliers" [value]="supplier.value">
                {{supplier.label}}
              </option>
            </select>
            <app-error-wrapper id="kscope-frame-supplier-error-message" [config]="errorWrapperConfig.supplier"
                               *ngIf="isFrameSupplierRequired"></app-error-wrapper>
          </div>

          <div class="custom-nested-column-1_75"></div>

        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Top Selling Frame -->

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Top Selling Frame - Third Row - Success, Warning & Error Messages -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30"
             *ngIf="isKaleyedoscopePractice && !(selectedFrame && selectedFrame.manufacturer)"></div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Top Selling Frame - Top Selling Frame Successfully Found -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30 margin-top-5"
             *ngIf="isTopSellingFrame">
          <div class="nested-column-4"></div>
          <div class="custom-nested-column-1_25"></div>
          <div class="custom-nested-column-5">
            <mat-form-field appearance="outline" fxFill
                            class="kscope-frame-success-control custom-mat-form-field-success-outline">
              <mat-icon matPrefix class="info-circle reports-icon padding-right-5"
                        svgIcon="eclaim-icons:infoCircleIcon">
              </mat-icon>
              <label id="top-selling-frame-practice-success-message"><strong>Top Seller!</strong> Keep this frame at your practice.</label>
              <input matInput type="text" autocomplete="off" readonly="readonly"
                     id="top-selling-frame-vendor-success-message"
                     value="The vendor will send the frame directly to the lab."/>

            </mat-form-field>
          </div>
          <div class="custom-nested-column-1_75">
            <img id="kaleyedoscope-logo-success" height="50px" width="100px"
                 ngSrc="assets/images/kaleyedoscopecircle.svg" alt="">
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Top Selling Frame And Slow Selling Frame with Frame available in office - Top Selling Frame Successfully Found AND IOF Lens Selected -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30 margin-top-5"
             *ngIf="isIOFLensAndFrameIsTopSellerOrFrameIsSlowSellerAndAvailableInOffice">
          <div class="nested-column-4"></div>
          <div class="custom-nested-column-1_25"></div>
          <div class="custom-nested-column-5">
            <mat-form-field appearance="outline" fxFill
                            class="kscope-frame-success-control custom-mat-form-field-success-outline">
              <mat-icon matPrefix class="info-circle reports-icon padding-right-5"
                        svgIcon="eclaim-icons:infoCircleIcon">
              </mat-icon>
              <label id="top-selling-frame-or-slow-selling-frame-and-available-in-office-success-message"><strong>You've selected In-Office Finishing (IOF) for lenses.</strong></label>
              <input matInput type="text" autocomplete="off" readonly="readonly"
                     id="top-selling-frame-or-slow-selling-frame-and-available-in-office-success-message-2"
                     value="Keep the frame and a replacement will be sent to your practice."/>
            </mat-form-field>
          </div>
          <div class="custom-nested-column-1_75">
            <img id="kaleyedoscope-logo-success-2" height="50px" width="100px"
                 ngSrc="assets/images/kaleyedoscopecircle.svg" alt="">
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Top Selling Frame - Error Code or Empty Response from Fulfillment Api OR Missing SKU - Error Message-->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30 margin-top-5"
             *ngIf="(isFrameDispositionHasErrors || isSkuMissingInFrame)">
          <div class="nested-column-4"></div>
          <div class="custom-nested-column-1_25"></div>
          <div class="custom-nested-column-5">
            <mat-form-field appearance="outline" fxFill
                            class="kscope-frame-error-control custom-mat-form-field-error-outline">
              <mat-icon matPrefix class="info-circle reports-icon padding-right-5"
                        svgIcon="eclaim-icons:infoCircleIcon">
              </mat-icon>

              <label id="top-selling-frame-unavailable-error-message"><strong>Frame guidance is currently unavailable for this selection.</strong></label>
              <input matInput type="text" autocomplete="off" readonly="readonly"
                     id="top-selling-frame-practice-error-message"
                     value="Send this frame from your practice to the lab."/>

            </mat-form-field>
          </div>
          <div class="custom-nested-column-1_75">
            <img id="kaleyedoscope-logo-error" height="50px" width="100px" ngSrc="assets/images/kaleyedoscopecircle.svg"
                 alt="">
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Top Selling Frame - Warning - Manufacturer not Participating -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30 margin-top-5"
             *ngIf="isManufacturerNotParticipating">
          <div class="nested-column-4"></div>
          <div class="custom-nested-column-1_25"></div>
          <div class="custom-nested-column-5">
            <mat-form-field appearance="outline" fxFill
                            class="kscope-frame-warning-control custom-mat-form-field-warning-outline">
              <mat-icon matPrefix class="info-circle reports-icon padding-right-5"
                        svgIcon="eclaim-icons:infoCircleIcon">
              </mat-icon>

              <label id="top-selling-frame-unavailable-warning-message"><strong>This selection is not eligible for frame guidance.</strong></label>
              <input matInput type="text" autocomplete="off" readonly="readonly"
                     id="top-selling-frame-practice-warning-message"
                     value="Place your order as usual."/>

            </mat-form-field>
          </div>
          <div class="custom-nested-column-1_75">
            <img id="kaleyedoscope-logo-warning" height="50px" width="100px"
                 ngSrc="assets/images/kaleyedoscopecircle.svg" alt="">
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Slow Selling Frame -->

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Slow Selling Frame - Success - At Practice -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30 margin-top-5"
             *ngIf="isSlowSellingFrameAtPractice">
          <div class="nested-column-4"></div>
          <div class="custom-nested-column-1_25"></div>
          <div class="custom-nested-column-5">
            <mat-form-field appearance="outline" fxFill
                            class="kscope-frame-success-control custom-mat-form-field-success-outline">
              <mat-icon matPrefix class="info-circle reports-icon padding-right-5"
                        svgIcon="eclaim-icons:infoCircleIcon">
              </mat-icon>
              <label id="slow-selling-frame-practice-success-message"><strong>Send this frame to the lab.</strong></label>
              <input matInput type="text" autocomplete="off" readonly="readonly"
                     id="slow-selling-frame-vendor-success-message"
                     value="A top-selling replacement will be sent to your practice."/>
              <label *ngIf="displayViewReplacementFrameOptionsLink" class="margin-top-3" id="slow-selling-frame-view-frame-replacement-options"><span
                                                                             class="action-link view-replacement-frame-options"
                                                                             (click)="onViewFrameReplacementOptionsClick()"
                                                                             id="slow-selling-frame-view-frame-replacement-options-link">View replacement frame options</span></label>

            </mat-form-field>
          </div>
          <div class="custom-nested-column-1_75">
            <img id="kaleyedoscope-logo-slow-selling-frame-success" height="50px" width="100px"
                 ngSrc="assets/images/kaleyedoscopecircle.svg" alt="">
          </div>
        </div>


        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Slow Selling Frame - Success- Not At Practice Orderable -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30 margin-top-5"
             *ngIf="isSlowSellingFrameNotAtPracticeOrderable">
          <div class="nested-column-4"></div>
          <div class="custom-nested-column-1_25"></div>
          <div class="custom-nested-column-5">
            <mat-form-field appearance="outline" fxFill
                            class="kscope-frame-success-control custom-mat-form-field-success-outline">
              <mat-icon matPrefix class="info-circle reports-icon padding-right-5"
                        svgIcon="eclaim-icons:infoCircleIcon">
              </mat-icon>
              <label id="slow-selling-frame-practice-orderable-message"><strong>Frame available from vendor.</strong></label>
              <input matInput type="text" autocomplete="off" readonly="readonly"
                     id="slow-selling-frame-vendor-orderable-message"
                     value="The vendor will send the frame directly to the lab."/>

            </mat-form-field>
          </div>
          <div class="custom-nested-column-1_75">
            <img id="kaleyedoscope-logo-slow-selling-frame-orderable" height="50px" width="100px"
                 ngSrc="assets/images/kaleyedoscopecircle.svg" alt="">
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Slow Selling Frame - Success - NOT At Practice and IOF Lens is Selected -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30 margin-top-5"
             *ngIf="isIOFLensAndFrameIsSlowSellerAndNotAvailableInOffice">
          <div class="nested-column-4"></div>
          <div class="custom-nested-column-1_25"></div>
          <div class="custom-nested-column-5">
            <mat-form-field appearance="outline" fxFill
                            class="kscope-frame-success-control custom-mat-form-field-success-outline">
              <mat-icon matPrefix class="info-circle reports-icon padding-right-5"
                        svgIcon="eclaim-icons:infoCircleIcon">
              </mat-icon>
              <label id="slow-selling-frame-and-not-available-in-office-success-message"><strong>Frame available from vendor.</strong></label>
              <input matInput type="text" autocomplete="off" readonly="readonly"
                     id="slow-selling-frame-and-not-available-in-office-success-message-2"
                     value="The vendor will send the frame directly to your practice."/>
            </mat-form-field>
          </div>
          <div class="custom-nested-column-1_75">
            <img id="kaleyedoscope-logo-slow-selling-frame-success-2" height="50px" width="100px"
                 ngSrc="assets/images/kaleyedoscopecircle.svg" alt="">
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Slow Selling Frame - Warning - Not At Practice Not Orderable -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30 margin-top-5"
             *ngIf="isSlowSellingFrameNotAtPracticeNotOrderable">
          <div class="nested-column-4"></div>
          <div class="custom-nested-column-1_25"></div>
          <div class="custom-nested-column-5">
            <mat-form-field appearance="outline" fxFill
                            class="kscope-frame-warning-control custom-mat-form-field-warning-outline">
              <mat-icon matPrefix class="info-circle reports-icon padding-right-5"
                        svgIcon="eclaim-icons:infoCircleIcon">
              </mat-icon>

              <label id="slow-selling-frame-unavailable-warning-message"><strong>Frame not available from vendor.</strong></label>
              <input matInput type="text" autocomplete="off" readonly="readonly"
                     id="slow-selling-frame-practice-warning-message"
                     value="This frame cannot be ordered from vendor."/>

            </mat-form-field>
          </div>
          <div class="custom-nested-column-1_75">
            <img id="kaleyedoscope-logo-warning-slowFrame" height="50px" width="100px"
                 ngSrc="assets/images/kaleyedoscopecircle.svg" alt="">
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Kaleyedoscope Practice - Back Ordered Frame - Warning - BackOrdered -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30 margin-top-5"
             *ngIf="isBackOrderedFrame">
          <div class="nested-column-4"></div>
          <div class="custom-nested-column-1_25"></div>
          <div class="custom-nested-column-5">
            <mat-form-field appearance="outline" fxFill
                            class="kscope-frame-warning-control custom-mat-form-field-warning-outline">
              <mat-icon matPrefix class="info-circle reports-icon padding-right-5"
                        svgIcon="eclaim-icons:infoCircleIcon">
              </mat-icon>

              <label id="back-ordered-frame-unavailable-warning-message"><strong>Frame backordered from vendor.</strong></label>
              <input matInput type="text" autocomplete="off" readonly="readonly"
                     id="back-ordered-frame-practice-warning-message"
                     value="Expected ship date: {{dateOfService}}"/>

            </mat-form-field>
          </div>
          <div class="custom-nested-column-1_75">
            <img id="kaleyedoscope-logo-warning-backOrderedFrame" height="50px" width="100px"
                 ngSrc="assets/images/kaleyedoscopecircle.svg" alt="">
          </div>
        </div>

        <app-frame-details-display
          *ngIf="(selectedFrame && selectedFrame.manufacturer)"
          [frame]="selectedFrame"
          [startFrameDetailsComponenetInEditMode]="startFrameDetailsComponenetInEditMode"
          [supplier]="activeClaim.labOrderInformation.frameSupplier"
          [isFrameSearchApiOffline]="isFrameSearchApiOffline"
          [allowFullFrameDetailEdit]="allowFullFrameDetailEdit"
          [isFrameCardDisabled]="frameForm.disabled"
          [isOnFrameResultsModal]="false"
          (isEditing)="onDetailsEdit($event)"
          (saveAfterEdit)="onSaveAfterEdit($event)"
          addedToOrder="selectedFrame"
          (clear)="clearSelectedFrame($event)"
          class="margin-bottom-30">
        </app-frame-details-display>


        <!-- Commenting out for a future story:ECR-8475 -->
        <!-- eBuy Help Section -->
        <!-- <div class="claim-form-frame-ebuy-section"> -->
        <!-- eBuy Logo -->
        <!-- <img src="assets/images/eBuy.gif" /> -->
        <!-- Link / Help Text -->
        <!-- <p class="claim-form-frame-ebuy-text" id="frame-ebuy-learn-message"> -->
        <!-- make sure to remove the label element and uncomment the a element after frame section is enabled for claim form -->
        <!-- <label id="frame-ebuy-learn-link">Learn</label> -->
        <!--<a href="https://secure.eyefinity.com/eyefinity/html/ebuy_lffo1.htm" target="_blank" id="frame-ebuy-learn-link">Learn</a>-->
        <!-- how to order frames from this form with eBuy <i>Express</i>. -->
        <!-- </p> -->
        <!-- </div> -->

        <!-- Frame Search Query Error -->
        <!--TODO: this error must be tied to the directive above, so need to build this once services are in place -->
        <div ng-model="claim.frame" ng-required="isFrameRequired()" id="missing-frame-error" class="position-relative"
             input-message="required:The frame manufacturer, collection and model are required for lens orders;
                                  appendToElement:true;offset:0,25;scrollToOffset:250"></div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
