import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ApplicationConstants} from '../../common/constants/application.constants';
import {AuthorizationsService} from '../../common/services/data-model/app/authorizations/authorizations.service';
import {ConsumerService} from '../../common/services/data-model/app/consumer/consumer.service';
import {EligibilityService} from '../../common/services/data-model/app/eligibility/eligibility.service';
import {ClaimFormNavigationService} from '../../common/services/support/claim-form-navigation/claim-form-navigation.service';
import {getFormattedFullName, isNullOrUndefined, openDialog} from '../../common/utility';
import {
  BusinessMemberPoliciesResults,
} from '../../models/consumer';
import {MembershipName} from '../../models/membership';
import {DrReportsNavigationService} from '../../common/services/support/dr-reports-navigation/dr-reports-navigation.service';

@Component({
  selector: 'app-cob-coverage',
  templateUrl: './cob-coverage.component.html',
  styleUrls: ['./cob-coverage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CobCoverageComponent implements OnInit, OnDestroy {

  private _authorizationNumber: string;
  private _memberName: MembershipName = {firstName: '', lastName: ''};


  constructor(private dialog: MatDialog,
              private router: Router,
              private authorizationService: AuthorizationsService,
              private eligibilityService: EligibilityService,
              private consumerService: ConsumerService,
              private claimFormNavigationService: ClaimFormNavigationService,
              private drReportsNavigationService: DrReportsNavigationService) {
  }

  ngOnInit() {
    this.setMemberNameFromMemberPolicyUsingMatchOnCachedEligibilityLink();
    if (this.authorizationService.vsrNumber) {
      this.authorizationNumber = this.authorizationService.vsrNumber;
    } else {
      this.router.navigate([ApplicationConstants.routing.secure.memberSearchPageUrl]);
    }
  }

  get authorizationNumber(): string {
    return this._authorizationNumber;
  }

  set authorizationNumber(number: string) {
    this._authorizationNumber = number;
  }

  get memberName(): MembershipName {
    return this._memberName;
  }

  set memberName(name: MembershipName) {
    this._memberName = name;
  }

  get planName(): string {
    return isNullOrUndefined(this.consumerService.federalEnrolleeHealthBenefitPlan) ? '' : this.consumerService.federalEnrolleeHealthBenefitPlan.planName;
  }


  // build the full name of the patient/member
  getFullName(): string {
    const middleInitial = this.memberName.middleInitial ? this.memberName.middleInitial : null;
    return getFormattedFullName(this.memberName.firstName, this.memberName.lastName, middleInitial);
  }

  /**
   * This method is doing following
   * 1. Search a claim
   * 2. If found, then retrieve it and go to Claim Form page
   * 3. If not found, then create and got to Claim Form
   * 4. After claim is returned, call external service location API to load doctor list
   * 5. If any of the API call fails then stay on this page
   */
  navigateToClaimForm(): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('', this.dialog);
    this.claimFormNavigationService.searchAndRetrieveOrCreatePatientEncounterThenLoadDoctorsThenNavigateToClaimForm(this.authorizationNumber).subscribe(
      () => {
        // since the method returns an Observable<void>, this will never be called, but is needed to get to the error and onComplete
      },
      () => {
        // on observer error
      },
      () => {
        // on observer complete
        this.dialog.closeAll();
      });
  }

  onDoctorReportClick(): void {
    this.drReportsNavigationService.navigateToPatientRecordReport(this.authorizationNumber);
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }

  private setMemberNameFromMemberPolicyUsingMatchOnCachedEligibilityLink(): void {
    this.consumerService.getMemberRetrieveResultsOrRecallApiIfDataIsNotInMemory().subscribe( ( businessMemberRetrieveResults: BusinessMemberPoliciesResults ) => {
      if (businessMemberRetrieveResults) {
          this.memberName = businessMemberRetrieveResults[0].name;
    }});
  }
}
