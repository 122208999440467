<div fxLayout="column" class="submit-claim-form-modal">

  <!-- Header Section -->
  <mat-card>
    <!-- Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 class="nested-column-11"
            id="submit-claim-form" mat-dialog-title>Submit Claim Form</h1>
      </mat-card-title>
      <!-- Close Icon -->
      <button [disableRipple]="true" class="close-button" mat-icon-button>
        <mat-icon (click)="onCloseClick()" id="submit-claim-form-close-button"
                  svgIcon="eclaim-icons:close"></mat-icon>
      </button>
    </mat-card-header>

    <!-- Body -->
    <mat-card-content id="submit-claim-modal-message">
      <!-- Submit Claim Main Message with Patient Name & VSR Number -->
      <h2>{{submitClaimMainMessage}}</h2>

      <p>This certifies that the claim information contained herein does not contain any
        false, incomplete, or
        misleading information, and correctly and accurately describes the services and/or materials provided to the
        patient.</p>

      <p>Do you want to continue?</p>
    </mat-card-content>

    <!--Footer Line-->
    <hr/>

    <!-- OK/Cancel buttons -->
    <mat-card-actions>
      <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="end center"
           class="margin-top-20 margin-bottom-20 submit-claim-form-modal">

        <!-- Cancel Button -->
        <button mat-button color="primary" class="cancel-button"
                id="submit-claim-modal-cancel-button" mat-dialog-close (click)="cancel()">
          Cancel
        </button>
        <div fxFlexOffset="30px"></div>

        <!-- OK Button -->
        <button mat-dialog-close class="btn btn-primary btn-extra-pad" (click)="closeDialog()"
                id="submit-claim-modal-ok-button">OK
        </button>
        <div fxFlexOffset="20px"></div>
      </div>
    </mat-card-actions>

  </mat-card>

</div>
