<div class="hidden-print">
  <div class="header-container">
    <app-secure-header></app-secure-header>
    <app-breadcrumb class="header-links-container"></app-breadcrumb>
  </div>
</div>

<div class="eclaim-page-contents">
  <div class="full-column">
    <!-- Routed views go here -->
    <router-outlet></router-outlet>
  </div>
</div>

<vsp-icon-snackbar></vsp-icon-snackbar>

