import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApplicationConstants } from '../../../../../constants/application.constants';
import { HttpClientService } from '../http-client-service';
import { ExternalServiceLocationResponse } from 'src/app/models/externalServiceLocation';
import {getApiUrl, isStringNullUndefinedOrEmptyWithTrim} from '../../../../../utility';

@Injectable({
  providedIn: 'root'
})
export class ExternalServiceLocationDataService extends HttpClientService {

  constructor(protected injector: Injector) {
    super(injector, ApplicationConstants.dataServiceClientIDs.externalServiceLocationDataService);
  }

  /***** START - PRIVATE FUNCTIONS *****/
  /***** START - END FUNCTIONS *****/

  /***** START - PUBLIC FUNCTIONS *****/
  getExternalServiceLocation(externalSvcLocURL: string, showSnackbar: boolean = true): Observable<ExternalServiceLocationResponse> {
    externalSvcLocURL = getApiUrl(externalSvcLocURL, this.environment.externalServiceLocationUrl);
    return this.http.get<ExternalServiceLocationResponse>(externalSvcLocURL, { headers: this.getHttpHeaders(ApplicationConstants.dataServiceClientIDs.externalServiceLocationDataService)}).pipe(
      map((externalServiceLocationResponse: ExternalServiceLocationResponse) => {
        if (externalServiceLocationResponse) {
          return externalServiceLocationResponse;
        } else {
          throw new Error(ApplicationConstants.errorMessages.genericApiFailMessage);
        }
      }),
      catchError((error: any) => {
        const errorMessage = this.getErrorMessage(error);
        this.messageService.setErrorMessage(showSnackbar, `${errorMessage}`);
        this.handleError('getExternalServiceLocation', error);
        return of(undefined);
      })
    );
  }

  getExternalServiceLocationWithEnvironmentHost(externalServiceLocationURLWithNoHost: string, showErrorSnackbar: boolean = true): Observable<ExternalServiceLocationResponse> {
    const environmentHost: string = !isStringNullUndefinedOrEmptyWithTrim(this.environment.externalServiceLocationUrl) ? this.environment.externalServiceLocationUrl : this.environment.apiUrl;
    const externalServiceLocationURL = environmentHost + externalServiceLocationURLWithNoHost;
    return this.getExternalServiceLocation(externalServiceLocationURL, showErrorSnackbar);
  }
  /***** END - PUBLIC FUNCTIONS *****/
}
