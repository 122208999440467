import {isStringNullUndefinedOrEmpty} from '../common/utility';

export enum TypeOfFeedBack {
  compliment = 'COMPLIMENT',
  suggestion = 'SUGGESTION',
  problem = 'PROBLEM',
  other = 'OTHER'
}

export class FeedBackFormRequest {
  private firstName: string;
  private lastName: string;
  private typeOfFeedback: string;
  private feedbackMessage: string;

  get _firstName(): string {
    return this.firstName;
  }

  set _firstName(value: string) {
    this.firstName = value;
  }

  get _lastName(): string {
    return this.lastName;
  }

  set _lastName(value: string) {
    this.lastName = value;
  }

  get _typeOfFeedback(): string {
    return this.typeOfFeedback;
  }

  set _typeOfFeedback(value: string) {
    let typeOFeedback: string = TypeOfFeedBack.other;
    if (!isStringNullUndefinedOrEmpty(value)) {
      for (const itemInEnum of Object.keys(TypeOfFeedBack)) {
        if (itemInEnum.toUpperCase() === value.toUpperCase().trim()) {
          typeOFeedback = TypeOfFeedBack[itemInEnum];
          break;
        }
      }
    }
    this.typeOfFeedback = typeOFeedback;
  }

  get _feedbackMessage(): string {
    return this.feedbackMessage;
  }

  set _feedbackMessage(value: string) {
    this.feedbackMessage = value;
  }

}
