import { BasePrescription } from './lensPrescription';

export interface ContactLens {
  contactLensMaterialType?: MaterialType;
  contactLensService?: Service;
  clReasonCode?: Reason;
  clManufacturer?: Manufacturer;
  clModality?: Modality;
  brandName: string;
  materialType: string;
  numberOfBoxes: number;
  serviceCode: string;
  necessaryContactLensPrescription?: BasePrescription;
}

export enum ContactLensModality {
  DISPOSABLE_1DAY = 'DISPOSABLE_1DAY',
  DISPOSABLE_2WEEK = 'DISPOSABLE_2WEEK',
  DISPOSABLE_1MONTH = 'DISPOSABLE_1MONTH',
  DISPOSABLE_3MONTH = 'DISPOSABLE_3MONTH',
  DISPOSABLE_6MONTH = 'DISPOSABLE_6MONTH',
  CONVENTIONAL_1YEAR = 'CONVENTIONAL_1YEAR',
  CONVENTIONAL_2YEAR = 'CONVENTIONAL_2YEAR'
}

export enum ContactLensReasonCode {
  E = 'E', // Elective contact lenses
  N = 'N' // Necessary contact lenses
}

export enum ContactLensManufacturer {
  VISTAKON = 'VISTAKON',
  CIBA_VISION_ALCON = 'CIBA_VISION_ALCON',
  BAUSCH_AND_LOMB = 'BAUSCH_AND_LOMB',
  OCULAR_SCIENCES = 'OCULAR_SCIENCES',
  COOPER_VISION = 'COOPER_VISION',
  HYDROGEL = 'HYDROGEL',
  VSP = 'VSP',
  OTHER = 'OTHER',
  NONE = 'NONE'
}

export interface ContactLensDropdownSelection {
  name: string;
  label: string;
}

export interface ContactLensManufacturerOffering {
  manufacturer: string;
  selections: ContactLensDropdownSelection[];
}

/**************** Contacts - Material/Type ****************/
export interface ContactLensResponse<T> {
  items: T[];
  manufacturers?: Manufacturer[];
}
// Generic boxed type interface for lensLabServiceFactory, responses will be unboxed upon observer completion
export interface ContactLensService { }

export enum ContactLensOperation {
  MaterialType = 'getMaterialType',
  Service = 'getService',
  Reason = 'getReason',
  Manufacturer = 'getManufacturer',
  Modality = 'getModality'
}

export interface MaterialType extends ContactLensService {
  code: string;
  description: string;
}

export interface ContactsMaterialTypesResponse extends ContactLensResponse<MaterialType> { }

export enum ContactLensOperation {
  MaterialTypes = 'getMaterialType'
}

export interface Service extends ContactLensService {
  code: string;
  description: string;
}

export interface ContactsServicesResponse extends ContactLensResponse<Service> { }

export interface Reason extends ContactLensService {
  code: string;
  description: string;
}

export interface ContactsReasonsResponse extends ContactLensResponse<Reason> { }

export interface Modality extends ContactLensService {
  code: string;
  description: string;
}

export interface ContactsModalityResponse extends ContactLensResponse<Modality> { }

export interface Manufacturer extends ContactLensService {
  name: string;
  code: string;
  brands: Brand[];
}

export interface Brand {
  name: string;
}

export interface ContactsManufacturersResponse extends ContactLensResponse<Manufacturer> { }
