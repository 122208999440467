<div fxLayout="column" fxLayoutAlign="center center">
  <div fxFlexOffset="60px" class="nested-column-12">
    <mat-icon class="check-circle" svgIcon="eclaim-icons:checkCircle"></mat-icon>
  </div>

  <label class="nested-column-12 margin-top-20 margin-bottom-15 check-confirmation">CONFIRMATION</label>

  <!-- Auth Message-->
  <label id="auth-confirmation-auth-message" class="nested-column-12 margin-bottom-30 auth-message">An authorization was
    successfully issued. Your authorization number is<span><strong
      id="auth-confirmation-number"> {{authorizationNumber}}</strong></span>
  </label>

  <div fxLayout="row" class="nested-column-12 margin-bottom-30">
    <!-- Show Patient Record Report -->
    <button id="authorization-confirmation-go-to-patient-record-report-button"
            type="button" class="record-button" (click)="showPatientRecordReport()">
      Show Patient Record Report
    </button>
    <!-- View CMS 1500 Form -->
    <button mat-flat-button class="mat-flat-button mat-primary" id="authorization-confirmation-go-to-claim-form-button" fxFlexOffset="30px"
            type="button" (click)="goToClaimForm()">View CMS 1500 Form
    </button>
  </div>
</div>


