import {Environment} from './environment.interface';

export const environment: Environment = {
  envName: 'production', // Per ECLAIM-165 we are now using this property in the app.component.ts as well. If this ever changes we need to modify that logic as well.
  dynatraceUrl: 'https://js-cdn.dynatrace.com/jstag/147f273fa2a/bf63206omp/c2605d635bc0065b_complete.js',
  production: true,
  apiUrl: 'https://api.vspglobal.com',
  applicationUrl: 'https://eclaim.eyefinity.com/secure',
  doctorUrl: 'https://doctor.vsp.com',
  trueLoginUrl: 'https://secureb.eyefinity.com',
  newTrueFarmLogin: 'https://portal.eyefinity.com/secure-welcome',
  fulfillmentApiUrl: 'https://api.eyefinity.com/client-fulfillment',
  oauthBase: 'api',
  showBuildNumbers: false,
  clientId: 'vsp-eclaim-ui',
  gtmAccountNum: 'GTM-MZMCJWK',
  siteKey: '6LcbkSodAAAAABn9kuNrxenI8gG-5Ytl-4AirfJd'
};
