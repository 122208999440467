import {Injectable} from '@angular/core';
import {AppDataService} from '../../http/http-client-data/app-data/app-data.service';
import {BehaviorSubject, Observable, Observer} from 'rxjs';
import {ApiFormattedClaim, Claim} from '../../../../../models/claim';
import {DataMarshallService} from '../../http/data-marshall/data-marshall.service';
import {ClaimCardsToUpdate} from '../../../../../models/claimCardsToUpdate';
import {ApplicationConstants, ClaimAction, ClaimStatus} from '../../../../constants/application.constants';
import {isNullOrUndefined, isStringNullUndefinedOrEmpty} from '../../../../utility';
import {EclaimLensPrescription } from 'src/app/models/lensPrescription';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  private readonly cardsToUpdateMapper: Map<string, ClaimCardsToUpdate>;
  private id: string = ApplicationConstants.componentIDs.updateAllSections;

  constructor(
    private appDataService: AppDataService,
    private dataMarshallService: DataMarshallService
  ) {
    this.cardsToUpdateMapper = new Map()
      .set(ApplicationConstants.componentIDs.additionalInformation, this.dontUpdateAnyCards())
      .set(ApplicationConstants.componentIDs.contacts, this.cardsToUpdateAfterContactsUpdate())
      .set(ApplicationConstants.componentIDs.dateOfService, this.cardsToUpdateAfterDateOfServiceUpdate())
      .set(ApplicationConstants.componentIDs.exam, this.dontUpdateAnyCards())
      .set(ApplicationConstants.componentIDs.frame, this.cardsToUpdateAfterFrameUpdate())
      .set(ApplicationConstants.componentIDs.frameDetails, this.dontUpdateAnyCards())
      .set(ApplicationConstants.componentIDs.lens, this.cardsToUpdateAfterLensUpdate()) // TODO: This may change depending on what data the lens card needs
      .set(ApplicationConstants.componentIDs.facilityAndBilling, this.dontUpdateAnyCards())
      .set(ApplicationConstants.componentIDs.insured, this.cardsToUpdateAfterInsuredUpdate())
      .set(ApplicationConstants.componentIDs.patient, this.cardsToUpdateAfterPatientUpdate())
      .set(ApplicationConstants.componentIDs.prescription, this.dontUpdateAnyCards())
      .set(ApplicationConstants.componentIDs.services, this.dontUpdateAnyCards())
      .set(ApplicationConstants.componentIDs.signatures, this.dontUpdateAnyCards())
      .set(ApplicationConstants.componentIDs.claimEdit, this.dontUpdateAnyCards())
      .set(ApplicationConstants.componentIDs.lab, this.dontUpdateAnyCards())
      .set(ApplicationConstants.componentIDs.softAndHardEdits, this.dontUpdateAnyCards())
      .set(ApplicationConstants.componentIDs.updateAllSections, this.updateAllCards());
  }


  /***** START - PRIVATE MEMBERS *****/
  private originalClaim: Claim;
  private activeClaim: Claim;
  private cardsToUpdate: ClaimCardsToUpdate;
  private dateErrorFormControlNames: string[] = [];
  private _allowClaimFormToBeDeactivated = true;
  /***** END - PRIVATE MEMBERS *****/


  /***** START - PUBLIC MEMBERS *****/
  onActiveClaim = new BehaviorSubject(undefined);
  onOriginalClaim = new BehaviorSubject(undefined);
  onCardsToUpdate = new BehaviorSubject(undefined);
  onSuccessfulCalculate = new BehaviorSubject(false);

  get allowClaimFormToBeDeactivated(): boolean {
    return this._allowClaimFormToBeDeactivated;
  }

  set allowClaimFormToBeDeactivated(value: boolean) {
    this._allowClaimFormToBeDeactivated = value;
  }

  /***** END - PUBLIC MEMBERS *****/


  /***** START - PRIVATE FUNCTIONS *****/
  private hasLensDataWithoutSelectedLens(claim: Claim): boolean {
    return !isNullOrUndefined(claim.labOrderInformation.lens) &&
      isStringNullUndefinedOrEmpty(claim.labOrderInformation.lens.externalId);
  }

  private hasPrescriptionDataWithoutSphere(claim: Claim): boolean {
    return !isNullOrUndefined(claim.labOrderInformation.lensPrescription) &&
      isStringNullUndefinedOrEmpty(claim.labOrderInformation.lensPrescription.sphereLeft) &&
      isStringNullUndefinedOrEmpty(claim.labOrderInformation.lensPrescription.sphereRight);
  }

  /**
   * Check to see if the frame was removed from the form, yet still sent on the request. In the frame component, if a frame
   * is removed from the claim form, while its values will be undefined, the object structure will not. E.g.,
   *
   * frame: {
   *   shape: {
   *     shape: undefined
   *   },
   *   ...
   *   material: {},
   *   ...
   * }
   *
   * will still be serialized to PE as a frame object with certain nested objects initialized, with each child value undefined.
   * Flattens the frame object with children containing only initialized values. If no values exist in the flattened object,
   * we can safely assume all children underneath the frame object have been non-initialized.
   *
   * @param claim - UI formatted Patient Encounter
   */
  private hasResetFrameData(claim: Claim): boolean {
    return !isNullOrUndefined(claim.labOrderInformation.frame) &&
      Object.keys(Object.flatten(claim.labOrderInformation.frame)).length === 0;
  }

  private  hasFrameWithoutModel(claim: Claim): boolean {
    return !isNullOrUndefined(claim.labOrderInformation.frame) &&
      isStringNullUndefinedOrEmpty(claim.labOrderInformation.frame.name);
  }

  private clearLensPrescriptionAndFrameObjectValuesIfNotPopulated(claim: Claim): void {
    // ECR-7900: If a calculate/submit is attempted without a spectacle lens selected, do not send the lens information
    if (this.hasLensDataWithoutSelectedLens(claim)) {
      claim.labOrderInformation.lens = undefined;
    }

    // ECR-7900: If a calculate/submit is attempted without a frame data entered, do not send the frame information
    if (this.hasResetFrameData(claim) || this.hasFrameWithoutModel(claim)) {
      claim.labOrderInformation.frameSupplier = undefined;
      claim.labOrderInformation.frame = undefined;
    }

    // ECR-7900: If a calculate/submit is attempted without a sphere entered, do not send the prescription information
    if (this.hasPrescriptionDataWithoutSphere(claim)) {
      if (claim.labOrderInformation.lensPrescription.planoLensSelected) {
        claim.labOrderInformation.lensPrescription = {} as EclaimLensPrescription;
        claim.labOrderInformation.lensPrescription.planoLensSelected = true;
      } else {
        claim.labOrderInformation.lensPrescription = undefined;
      }
    }
  }

  /**
   * Sets the base prescription based on user selection from the contacts section. If a user has selected 'Elective Contact Lenses,'
   * or 'Necessary Contact Lenses,' we need to set the prescription values underneath the contactLens.necessaryContactLensPrescription property
   * and remove the values from the lens prescription for OE/CVT validations to fire correctly. Prescription values for contact lenses will be
   * stored in the PE database rather than the Lab Order database as well. Since the NCL prescription is not recognized by the form and has no place
   * for values, we always rely on the active claim sending those values through the lens prescription on outbound calls to PE, and make the switch at call time.
   *
   * @param claim PE resource to send
   */

  private setOriginalClaim(claim: Claim): void {
    this.originalClaim = (claim) ? Object.deepClone(claim) : claim;
    this.onOriginalClaim.next(this.originalClaim);
  }

  private dontUpdateAnyCards(): ClaimCardsToUpdate {
    const claimCardsToUpdate = new ClaimCardsToUpdate();
    return claimCardsToUpdate;
  }

  private updateAllCards(): ClaimCardsToUpdate {
    const claimCardsToUpdate = new ClaimCardsToUpdate();
    claimCardsToUpdate.all = true;
    return claimCardsToUpdate;
  }

  private cardsToUpdateAfterDateOfServiceUpdate(): ClaimCardsToUpdate {
    const claimCardsToUpdate = new ClaimCardsToUpdate();
    claimCardsToUpdate.exam = true;
    claimCardsToUpdate.services = true;
    return claimCardsToUpdate;
  }

  private cardsToUpdateAfterLensUpdate(): ClaimCardsToUpdate {
    const claimCardsToUpdate = new ClaimCardsToUpdate();
    claimCardsToUpdate.prescription = true;
    claimCardsToUpdate.exam = true;
    claimCardsToUpdate.lab = true;
    claimCardsToUpdate.frame = true;
    return claimCardsToUpdate;
  }

  private cardsToUpdateAfterContactsUpdate(): ClaimCardsToUpdate {
    const claimCardsToUpdate = new ClaimCardsToUpdate();
    claimCardsToUpdate.prescription = true;
    return claimCardsToUpdate;
  }

  private cardsToUpdateAfterPatientUpdate(): ClaimCardsToUpdate {
    const claimCardsToUpdate = new ClaimCardsToUpdate();
    claimCardsToUpdate.insured = true;
    return claimCardsToUpdate;
  }

  private cardsToUpdateAfterInsuredUpdate(): ClaimCardsToUpdate {
    const claimCardsToUpdate = new ClaimCardsToUpdate();
    claimCardsToUpdate.services = true;
    return claimCardsToUpdate;
  }

  private cardsToUpdateAfterFrameUpdate(): ClaimCardsToUpdate {
    const claimCardsToUpdate = new ClaimCardsToUpdate();
    claimCardsToUpdate.lab = true;
    claimCardsToUpdate.exam = true;
    return claimCardsToUpdate;
  }

  private handleUpdatedClaim(apiFormattedClaim: ApiFormattedClaim, observer: Observer<boolean>): void {
    this.allowClaimFormToBeDeactivated = true;
    if (!isNullOrUndefined(apiFormattedClaim)) {
      const uiFormattedClaim = this.dataMarshallService.formatClaimForUi(apiFormattedClaim);
      this.setOriginalClaim(uiFormattedClaim);
      this.setActiveClaim(uiFormattedClaim, this.id);
      observer.next(true);
    } else {
      observer.next(false);
    }
  }

  /***** START - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  addToDateErrorControlNamesList(formControlName: string) {
    this.dateErrorFormControlNames.push(formControlName);
  }

  get dateErrorFormControlNameList(): string[] {
    return this.dateErrorFormControlNames;
  }

  getOriginalClaim(): any {
    return Object.deepClone(this.originalClaim);
  }

  getActiveClaim(): any {
    return Object.deepClone(this.activeClaim);
  }

  loadClaim(authorizationNumber: string, showSnackbar: boolean = true): Observable<Claim> {
    return new Observable((observer) => {
      this.appDataService.getClaim(authorizationNumber, showSnackbar).subscribe((uiFormattedClaim) => {
        const claim = (uiFormattedClaim) ? Object.deepClone(uiFormattedClaim) : uiFormattedClaim;
        this.setOriginalClaim(claim);
        this.setActiveClaim(claim, this.id);
        observer.next(claim);
        observer.complete();
      });
    });
  }

  saveClaim(uiFormattedClaim: Claim): Observable<boolean> {
    return new Observable((observer) => {
      this.appDataService.updateClaim(uiFormattedClaim, ClaimAction.Save).subscribe((uiFormattedClaim) => {
        this.handleUpdatedClaim(uiFormattedClaim, observer);
      });
    });
  }

  searchClaim(authorizationNumber: string, showSnackbar: boolean = true): Observable<boolean> {
    // resetting back to undefined for next request
    this.setActiveClaim(undefined, this.id);
    return new Observable((observer) => {
      this.appDataService.searchClaim(authorizationNumber, showSnackbar).subscribe((claims) => {
        if (claims !== undefined && claims.totalElements !== undefined && claims.totalElements > 0) {
          observer.next(true);
        } else if (claims !== undefined && claims.totalElements !== undefined && claims.totalElements === 0) {
          observer.next(false);
        } else {
          observer.next(undefined);
        }
        observer.complete();
      });
    });
  }

  calculateClaim(uiFormattedClaim: Claim): Observable<boolean> {
    // TODO: Add unit tests for lens, prescription, and frame scenarios
    if (!isNullOrUndefined(uiFormattedClaim) && !isNullOrUndefined(uiFormattedClaim.labOrderInformation)) {
      this.clearLensPrescriptionAndFrameObjectValuesIfNotPopulated(uiFormattedClaim);
    }

    return new Observable((observer) => {
      this.appDataService.updateClaim(uiFormattedClaim, ClaimAction.Calculate).subscribe((uiFormattedClaim) => {
        this.handleUpdatedClaim(uiFormattedClaim, observer);
      });
    });
  }

  submitClaim(uiFormattedClaim: Claim): Observable<boolean> {
    // TODO: Add unit tests for lens, prescription, and frame scenarios
    if (!isNullOrUndefined(uiFormattedClaim) && !isNullOrUndefined(uiFormattedClaim.labOrderInformation)) {
      this.clearLensPrescriptionAndFrameObjectValuesIfNotPopulated(uiFormattedClaim);
    }

    return new Observable((observer) => {
      this.appDataService.updateClaim(uiFormattedClaim, ClaimAction.Submit).subscribe((uiFormattedClaim) => {
        this.handleUpdatedClaim(uiFormattedClaim, observer);
      });
    });
  }

  createClaim(trackingNumber: string, dateOfService: Date, showSnackbar: boolean = true): Observable<Claim> {
    const resetClaim = {} as Claim;
    this.setOriginalClaim(resetClaim);
    this.setActiveClaim(resetClaim, this.id);
    return new Observable((observer) => {
      this.appDataService.createClaim(trackingNumber, dateOfService, showSnackbar).subscribe((createdClaim) => {
        const claim = (createdClaim) ? Object.deepClone(createdClaim) : createdClaim;
        this.setOriginalClaim(claim);
        this.setActiveClaim(claim, this.id);
        observer.next(claim);
        observer.complete();
      });
    });
  }

    /**
   * Set and broadcast the active claim
   * @param claim
   * @param cardId
   */
  setActiveClaim(claim: Claim, cardId: string): void {
    // TODO potentially remove this console log below if we have full confidence that the set active claim data flow is completely fixed and only called when fields are updated by the user.
    if (cardId !== ApplicationConstants.componentIDs.updateAllSections && this.activeClaim && this.activeClaim.status !== ClaimStatus.SubmittedClaim && this.activeClaim.status !== ClaimStatus.SubmittedLab) {
      this.allowClaimFormToBeDeactivated = false;
    }
    const claimsToUpdate = this.cardsToUpdateMapper.get(cardId);
    this.cardsToUpdate = Object.deepClone(claimsToUpdate);
    // TODO per code review: Though this wasn't coded in this PR, should the else value be something else in this case? If claim is not defined, it is still assigning activeClaim to claim. We can remove the ternary or set it to null/undefined in the else block?
    this.activeClaim = (claim) ? Object.deepClone(claim) : claim;
    this.onActiveClaim.next(this.activeClaim);
    this.onCardsToUpdate.next(this.cardsToUpdate);
  }

  /***** END - PUBLIC FUNCTIONS *****/

}
