import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FrameAPIResponse} from '../../../../../models/frame';
import {Subscriber} from 'rxjs';
import {FramesDataService} from '../../http/http-client-data/frames-data/frames-data.service';

@Injectable({
  providedIn: 'root'
})
export class FramesService {

  constructor(private frameDataService: FramesDataService) { }

  /***** START - PRIVATE MEMBERS *****/
  private _frameSearchResult: FrameAPIResponse;
  /***** END - PRIVATE MEMBERS *****/

  /***** START - PUBLIC FUNCTIONS *****/
  get frameSearchResult(): FrameAPIResponse {
    return (this._frameSearchResult) ? JSON.parse(JSON.stringify(this._frameSearchResult)) : this._frameSearchResult;
  }
  set frameSearchResult(items: FrameAPIResponse) {
    this._frameSearchResult = items;
  }

  loadFrames(frameSearch: string): Observable<FrameAPIResponse> {
    return new Observable<FrameAPIResponse>((observer: Subscriber<FrameAPIResponse>) => {
      this.frameDataService.searchFrames(frameSearch).subscribe((frameSearchResponse) => {
        if (frameSearchResponse) {
          this.frameSearchResult = frameSearchResponse;
          observer.next(frameSearchResponse);
        } else {
          observer.next(undefined);
        }
        observer.complete();
      });
    });
  }

  loadFramesUsingUpcAndSkuParameters(upc: string, sku: string): Observable<FrameAPIResponse> {
    return new Observable<FrameAPIResponse>((observer: Subscriber<FrameAPIResponse>) => {
      this.frameDataService.searchFramesUsingUpcAndSkuParameters(upc, sku).subscribe((frameSearchResponse) => {
        if (frameSearchResponse) {
          this.frameSearchResult = frameSearchResponse;
          observer.next(frameSearchResponse);
        } else {
          observer.next(undefined);
        }
        observer.complete();
      });
    });
  }
  /***** END - PUBLIC FUNCTIONS *****/
}
