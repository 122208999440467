import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApplicationConstants} from '../../../common/constants/application.constants';

export interface DialogData {
  authorization: {};
  patientFullName: string;
}

@Component({
  selector: 'app-valid-search-combinations',
  templateUrl: './valid-search-combinations.component.html',
  styleUrls: ['./valid-search-combinations.component.scss']
})
export class ValidSearchCombinationsComponent {

  constructor(
    public dialogRef: MatDialogRef<ValidSearchCombinationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  public confirm() {
    this.dialogRef.close(ApplicationConstants.modalSelectionOk);
  }

  public cancel() {
    this.dialogRef.close();
  }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }

}
