export enum KScopeBanners {
  TOP_SELLER_KEEP_FRAME_AT_OFFICE = 'TOP_SELLER_KEEP_FRAME_AT_OFFICE',
  K_SCOPE_IS_UNAVAILABLE_FOR_THIS_FRAME = 'K_SCOPE_IS_UNAVAILABLE_FOR_THIS_FRAME',
  K_SCOPE_INF0_IS_UNAVAILABLE_FOR_THIS_FRAME = 'K_SCOPE_INFO_IS_UNAVAILABLE_FOR_THIS_FRAME',
  THE_SELECTED_FRAME_IS_NOT_ELIGIBLE_FOR_K_SCOPE = 'THE_SELECTED_FRAME_IS_NOT_ELIGIBLE_FOR_K_SCOPE',
  SEND_THIS_FRAME_TO_THE_LAB = 'SEND_THIS_FRAME_TO_THE_LAB',
  FRAME_AVAILABLE_FROM_VENDOR = 'FRAME_AVAILABLE_FROM_VENDOR',
  FRAME_NOT_AVAILABLE_FROM_VENDOR = 'FRAME_NOT_AVAILABLE_FROM_VENDOR',
  FRAME_BACK_ORDERED_FROM_VENDOR = 'FRAME_BACK_ORDERED_FROM_VENDOR'
}
