<div fxLayout="column">
  <div *ngIf="loadingGcOrClaimFormHadErrors" class="nested-column-12">
        <div fxLayout="row" fxLayoutAlign="center center" class="margin-top-60">
          <h3 id="cannot-continue-for-the-following-reason-label">{{cannotContinueForTheFollowingReason}}</h3>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" class="margin-top-20">
          <label id="api-error-message-label" class="eclaim-body-text-bold">{{gcOrClaimFormErrorMessage}}</label>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" class="margin-top-20">
          <label id="please-return-to-the-einsurance-page-label" class="eclaim-body-text">{{pleaseReturnToEInsurancePage}}</label>
        </div>

        <!-- Return to the eINSURANCE Page -->
        <div fxLayout="row" fxLayoutAlign="center center" class="margin-top-30">
          <button mat-flat-button
                  class="mat-flat-button mat-primary"
                  id="return-to-legacy-einsurance-button"
                  (click)="navigateToLegacyEInsurancePage()">{{buttonContent}}
          </button>
        </div>
  </div>
</div>
