import { Injectable, Injector } from '@angular/core';
import { HttpClientService } from '../http-client-service';
import { ApplicationConstants } from '../../../../../constants/application.constants';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {ClaimSummaries, ClaimSummary, ClaimSummaryError} from '../../../../../../models/claimSummary';
import {ClaimSummarySearchRequest} from '../../../../../../models/ClaimSummarySearchRequest';
import {isNullOrUndefined, isStringNullUndefinedOrEmptyWithTrim} from '../../../../../utility';

@Injectable({
  providedIn: 'root'
})
export class ClaimSummaryDataService extends HttpClientService {

  constructor(protected injector: Injector) {
    super(injector, ApplicationConstants.dataServiceClientIDs.supplierWebDataService);
  }

  /***** START - PRIVATE FUNCTIONS *****/

  /**
   * Method builds out the Claim Summary API Endpoint.
   * @private
   */
  private getClaimSummaryApiEndpoint(): string {
    const claimSummaryApi = ApplicationConstants.api.claimSummaryApi;
    const domain = (!isStringNullUndefinedOrEmptyWithTrim(this.environment.claimSummaryUrl)) ? this.environment.claimSummaryUrl : this.environment.apiUrl;
    return `${domain}/${claimSummaryApi}/summaries`;
  }

  /***** START - END FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/

  /**
   * Method executes the Claims tracking Search Request.
   *
   * @param claimSummarySearchRequest - Claims tracking Request we want to use.
   */
  searchClaimSummaries(claimSummarySearchRequest: ClaimSummarySearchRequest): Observable<ClaimSummaries> {
    const endpoint: string = this.getClaimSummaryApiEndpoint();
    return this.http.post<ClaimSummaries>(endpoint, claimSummarySearchRequest, { headers: this.getHttpHeaders(ApplicationConstants.api.claimSummaryApi)})
      .pipe(
        map((claimSummaries: ClaimSummaries) => {
          return claimSummaries;
        }),
        catchError((error: any) => {
          this.handleError('searchClaimSummaries', error);
          this.messageService.showErrorSnackbar(`${this.buildClaimSummaryErrorMessage(error)}`);
          return of(undefined);
        })
      );
  }

  /**
   * Method executes the Claim Summary Self url to retrieve the auth.
   *
   * @param claimSummarySelfUrl - Claim Summary self url we want to use to retrieve the Auth.
   */
  retrieveClaimSummaryDetail(claimSummarySelfUrl: string): Observable<ClaimSummary> {
    return this.http.get<ClaimSummary>(claimSummarySelfUrl, { headers: this.getHttpHeaders(ApplicationConstants.api.claimSummaryApi)})
      .pipe(
        map((claimSummary: ClaimSummary) => {
          return claimSummary;
        }),
        catchError((error: any) => {
          this.handleError('retrieveClaimSummaryDetail', error);
          this.messageService.showErrorSnackbar(`${this.buildClaimSummaryErrorMessage(error)}`);
          return of(undefined);
        })
      );
  }

  /**
   * Method builds the error message we will display in the ui.
   */
  buildClaimSummaryErrorMessage(error: any): string {
    // Set the default error message just in case.
    let errorMessage = ApplicationConstants.errorMessages.genericApiFailMessage;
    if (!isNullOrUndefined(error) && !isNullOrUndefined(error.error)) {
      const claimSummaryError: ClaimSummaryError = error.error;
      if (!isStringNullUndefinedOrEmptyWithTrim(claimSummaryError.errorMessage) && !isStringNullUndefinedOrEmptyWithTrim(claimSummaryError.errorId)) {
        errorMessage = claimSummaryError.errorId + ' : ' + claimSummaryError.errorMessage;
      }
    }
    return errorMessage;
  }

  /***** END - PUBLIC FUNCTIONS *****/
}
