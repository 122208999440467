
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LensDto } from '../../../models/lens';
import { LensOrFrame } from 'src/app/secure/claim-form/exam/exam.constants';

export interface ClaimFormItemDataFormat {
  title: string;
  isActive: boolean;
  isComplete: boolean;
  hasInputError: boolean;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class ViewStateService {

  /***** START - PRIVATE MEMBERS *****/
  private claimFormItems: ClaimFormItemDataFormat[] = [];
  private cardsMasked: boolean = true;
  private dateOfServiceCardMasked: boolean = false;
  private isCobClaim: boolean = false;
  private isPlanoLensesCheckBoxChecked: boolean = false;
  private selectedSpectacleLens: LensDto = undefined;
  private calculateFailed: boolean = false;
  private submitFailed: boolean = false;
  private isManualFrameEntryEnabled: boolean = false;
  private materialToRemoveFromClaimForm: LensOrFrame = null;
  private saveFromLeaveClaimFormWarningModalWasSuccessful: boolean = undefined;
  private hasEdits: boolean = false;
  private hasUIEdits: boolean = false;
  /***** END - PRIVATE MEMBERS *****/

  constructor() { }

  /***** START - PUBLIC MEMBERS *****/
  onClaimFormItemsUpdated: BehaviorSubject<ClaimFormItemDataFormat[]> = new BehaviorSubject(this.claimFormItems);
  onMaskCards: BehaviorSubject<boolean> = new BehaviorSubject(this.cardsMasked);
  onMaskDateOfServiceCard: BehaviorSubject<boolean> = new BehaviorSubject(this.dateOfServiceCardMasked);
  onIsCobClaim: BehaviorSubject<boolean> = new BehaviorSubject(this.isCobClaim);
  onIsPlanoLensesCheckBoxChecked: BehaviorSubject<boolean> = new BehaviorSubject(this.isPlanoLensesCheckBoxChecked);
  onSelectedSpectacleLens: BehaviorSubject<LensDto> = new BehaviorSubject(this.selectedSpectacleLens);
  onCalculateFailed: BehaviorSubject<boolean> = new BehaviorSubject(this.calculateFailed);
  onSubmitFailed: BehaviorSubject<boolean> = new BehaviorSubject(this.submitFailed);
  onManualEnterFrameClick: BehaviorSubject<boolean> = new BehaviorSubject(this.isManualFrameEntryEnabled);
  onLensOrFrameCheckboxRemovalConfirmation: BehaviorSubject<LensOrFrame> = new BehaviorSubject(this.materialToRemoveFromClaimForm);
  onSaveFromLeaveClaimFormWarningModalWasSuccessful: BehaviorSubject<boolean> = new BehaviorSubject(this.saveFromLeaveClaimFormWarningModalWasSuccessful);
  onHasEdits: BehaviorSubject<boolean> = new BehaviorSubject(this.hasEdits);
  onSubmitFoundUIEdits: BehaviorSubject<boolean> = new BehaviorSubject(this.hasUIEdits);
  /***** END - PUBLIC MEMBERS *****/

  /***** START - PUBLIC FUNCTIONS *****/
  registerClaimFormItem(item: ClaimFormItemDataFormat): void {
    const existingItemIndex = this.claimFormItems.findIndex((arrItem) => arrItem.id === item.id);
    if (existingItemIndex > -1) {
      this.claimFormItems.splice(existingItemIndex, 1, item);
    } else {
      this.claimFormItems.push(item);
    }
    this.onClaimFormItemsUpdated.next(this.claimFormItems);
  }

  unregisterClaimFormItem(item: ClaimFormItemDataFormat): void {
    this.claimFormItems = this.claimFormItems.filter(arrayItem => arrayItem.id !== item.id);
    this.onClaimFormItemsUpdated.next(this.claimFormItems);
  }

  updateClaimFormItem(item: ClaimFormItemDataFormat): void {
    this.claimFormItems = this.claimFormItems.map(arrayItem => (arrayItem.id === item.id) ? item : arrayItem);
    this.onClaimFormItemsUpdated.next(this.claimFormItems);
  }

  maskCards(): void {
    this.cardsMasked = true;
    this.onMaskCards.next(this.cardsMasked);
  }

  unmaskCards(): void {
    this.cardsMasked = false;
    this.onMaskCards.next(this.cardsMasked);
  }

  maskDateOfServiceCard(): void {
    this.dateOfServiceCardMasked = true;
    this.onMaskDateOfServiceCard.next(this.dateOfServiceCardMasked);
  }

  unmaskDateOfServiceCard(): void {
    this.dateOfServiceCardMasked = false;
    this.onMaskDateOfServiceCard.next(this.dateOfServiceCardMasked);
  }

  setCobViewState(state: boolean): void {
    this.isCobClaim = state;
    this.onIsCobClaim.next(state);
  }

  setIsPlanoLensesCheckBoxChecked(state: boolean): void {
    this.isPlanoLensesCheckBoxChecked = state;
    this.onIsPlanoLensesCheckBoxChecked.next(state);
  }

  setSelectedSpectacleLens(state: LensDto): void {
    this.selectedSpectacleLens = state;
    this.onSelectedSpectacleLens.next(state);
  }

  setCalculateFailed(state: boolean): void {
    this.calculateFailed = state;
    this.onCalculateFailed.next(state);
  }

  setSubmitFailed(state: boolean): void {
    this.submitFailed = state;
    this.onSubmitFailed.next(state);
  }

  setManualEntryState(state: boolean): void {
    this.isManualFrameEntryEnabled = state;
    this.onManualEnterFrameClick.next(state);
  }

  setMaterialToRemoveState(state: LensOrFrame): void {
    this.materialToRemoveFromClaimForm = state;
    this.onLensOrFrameCheckboxRemovalConfirmation.next(state);
  }

  setSaveFromLeaveClaimFormWarningModalWasSuccessfulState(state: boolean): void {
    this.saveFromLeaveClaimFormWarningModalWasSuccessful = state;
    this.onSaveFromLeaveClaimFormWarningModalWasSuccessful.next(state);
  }

  setHasEdits(state: boolean) {
    this.hasEdits = state;
    this.onHasEdits.next(state);
  }

  setHasUIEdits(state: boolean) {
    this.hasUIEdits = state;
    this.onSubmitFoundUIEdits.next(state);
  }
  /***** END - PUBLIC FUNCTIONS *****/

}
