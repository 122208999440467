import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { LensCatalogResponse, SpectacleLens } from '../../../../../../models/lens';
import { catchError, map } from 'rxjs/operators';
import { ApplicationConstants } from '../../../../../constants/application.constants';
import { of } from 'rxjs';
import { HttpClientService } from '../http-client-service';
import { HttpParams } from '@angular/common/http';
import {isStringNullUndefinedOrEmpty} from '../../../../../utility';

@Injectable({
  providedIn: 'root'
})
export class LensCatalogDataService extends HttpClientService {

  constructor(protected injector: Injector) {
    super(injector, ApplicationConstants.dataServiceClientIDs.lensCatalogServiceDataService);
  }

  private getLensCatalogUrl(isNonLabService: boolean): string {
    const domain = (!isStringNullUndefinedOrEmpty(this.environment.lensCatalogUrl)) ? this.environment.lensCatalogUrl : this.environment.apiUrl;
    const catalogLensApi = `${domain}/${ApplicationConstants.api.catalogLensApi}`;
    return isNonLabService ? catalogLensApi : `${catalogLensApi}/labservices`;
  }

  /**
   * Generic implementation of API calls to the lens catalog.
   *
   * @param operation - typed endpoint to retrieve from
   * @param endpoint - path the resource
   * @param params - optional query parameters
   * @param isNonLabService - optional non-lab service call
   */
  getLensCatalogItem<T>(operation: string, endpoint: string, params?: HttpParams, isNonLabService?: boolean): Observable<LensCatalogResponse<T>> {
    const url: string = `${this.getLensCatalogUrl(isNonLabService)}/${endpoint}`;

    return this.http.get<LensCatalogResponse<T>>(url, { params: params, headers: this.getHttpHeaders(ApplicationConstants.api.catalogLensApi) }).pipe(
      map((lensCatalogResponse: LensCatalogResponse<T>) => {
        return lensCatalogResponse;
      }),
      catchError((error: any) => {
        this.handleError(operation, error);
        return of(undefined);
      })
    );
  }

  retrieveLensDetail(link: string): Observable<SpectacleLens> {
    return this.http.get<SpectacleLens>(link, { headers: this.getHttpHeaders(ApplicationConstants.api.catalogLensApi)}).pipe(
      map((spectacleLensDetail: SpectacleLens) => {
        return spectacleLensDetail;
      }),
      catchError((error: any) => {
        // TODO: Need to check with Martha whether or not to display messages when a retrieve fails
        // const errorMessage = this.getErrorMessage(error);
        // this.messageService.showErrorSnackbar(`${errorMessage}`);
        this.handleError('retrieveLensDetail', error);
        return of(undefined);
      })
    );
  }
}
