import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
  DateUtility,
  isNullOrUndefined,
  isStringNullUndefinedOrEmptyWithTrim,
  onKeypressEventRadiobutton,
  openDialog
} from '../../common/utility';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  ApplicationConstants,
  ErrorTypes
} from '../../common/constants/application.constants';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ErrorWrapperConfig} from '../../common/components/error-wrapper/error-wrapper.component';
import {CustomValidatorsService} from '../../common/services/support/custom-validators/custom-validators.service';
import {FormStateYesNo} from '../../common/enum/form-state-yes-no';
import {MatDialog} from '@angular/material/dialog';
import {MatRadioChange} from '@angular/material/radio';
import {MatSort} from '@angular/material/sort';
import {ExternalServiceLocationResponse} from '../../models/externalServiceLocation';
import {ExternalServiceLocationService} from '../../common/services/data-model/app/external-service-location/external-service-location.service';
import {CookieService} from 'ngx-cookie-service';
import {ClaimSummaries, ClaimSummaryStatusCodeEnum} from '../../models/claimSummary';
import {ClaimSummaryService} from '../../common/services/data-model/app/claim-summary/claim-summary.service';
import {ClaimSummarySearchRequest} from '../../models/ClaimSummarySearchRequest';

@Component({
  selector: 'app-claims-tracking',
  templateUrl: './claims-tracking.component.html',
  styleUrls: ['./claims-tracking.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ClaimsTrackingComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
    private dialog: MatDialog,
    private externalServiceLocationService: ExternalServiceLocationService,
    private cookieService: CookieService,
    private claimSummaryService: ClaimSummaryService
  ) { }

  /***** START - PRIVATE MEMBERS *****/



  /***** END - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  hasExternalServiceLocationCallFinished: boolean = false;
  hasExternalServiceLocationCallFinishedWithErrors: boolean = false;
  externalServiceLocationResponse: ExternalServiceLocationResponse = undefined;
  hasSearchFinished: boolean = false;
  claimSummaries: ClaimSummaries = undefined;
  // We are setting temp
  practiceName: string = ApplicationConstants.claimsTracking.tempPracticeName;
  practiceStreet: string = ApplicationConstants.claimsTracking.tempPracticeStreet;
  practiceCityStateZip: string = ApplicationConstants.claimsTracking.tempPracticeCityStateZip;
  externalServiceLocationApiErrorMessage: string = ApplicationConstants.errorMessages.providerWebApiFailMessageTwo;
  cannotContinueForTheFollowingReason: string = ApplicationConstants.routingPageContent.cannotContinueForTheFollowingReason;
  pleaseReturnToEInsurancePage: string = ApplicationConstants.routingPageContent.pleaseReturnToEInsurancePage;
  buttonContent: string = ApplicationConstants.routingPageContent.buttonContent;
  claimsTrackingForm: FormGroup;
  formStateYesNo = FormStateYesNo;
  errorWrapperConfig = {
    searchByMemberIdField: new ErrorWrapperConfig(),
    searchByClaimNumberField: new ErrorWrapperConfig()
  };
  onKeypressEventRadiobutton = onKeypressEventRadiobutton;
  isPaidClaimSearch: boolean = false;
  isMemberIdOrClaimSearch: boolean = false;
  claimPaidDateDropdownValues: { monthDateRange: string; monthNumber: number; monthName: string; monthYear: number; defaultMonth: boolean}[] = [];
  defaultMonthSelected: string;

  public resetRadioButtonFields(): void {
    this.claimsTrackingForm.controls.searchByMemberIdField.reset(ApplicationConstants.emptyString);
    this.claimsTrackingForm.controls.searchByClaimNumberField.reset(ApplicationConstants.emptyString);
    this.claimsTrackingForm.controls.searchByMemberIdField.enable();
    this.claimsTrackingForm.controls.searchByClaimNumberField.enable();
}

  public resetForm(): void {
    this.resetRadioButtonFields();
    this.clearSearchResults();
    this.claimsTrackingForm.controls.searchByPricedClaimStatusRadioButton.setValue(this.formStateYesNo.No);
    this.claimsTrackingForm.controls.searchByPaidClaimStatusRadioButton.setValue(this.formStateYesNo.Yes);
    this.hasSearchFinished = false;
    this.isPaidClaimSearch = true;
    this.claimSummaryService.serviceStartDateFrom = undefined;
    this.claimSummaryService.serviceStartDateTo = undefined;
    this.isMemberIdOrClaimSearch = false;
    this.claimsTrackingForm.controls.claimPaidDateDropdown.setValue(this.defaultMonthSelected);
    this.claimsTrackingForm.controls.claimPaidDateDropdown.enable();
  }

  public onSearchButtonClick(): void {
    // Clear existing results
    this.clearSearchResults();
    // This is needed to handle edge case that came up where the search results section was being displayed empty
    this.hasSearchFinished = false;
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Searching For Claim Summaries', this.dialog);
    this.claimSummaryService.searchClaimSummaries(this.buildClaimSummaryRequest()).subscribe((claimSummaries) => {
      if (!isNullOrUndefined(claimSummaries)) {
        this.claimSummaries = claimSummaries;
        this.hasSearchFinished = true;
      }
    }, () => {
        // on observer error
        this.dialog.closeAll();
      },
      () => {
        // Close dialog for LoadingModalComponent - Spinner
        this.dialog.closeAll();
      });
  }

  public buildClaimSummaryRequest(): ClaimSummarySearchRequest {
    const claimSummarySearchRequest: ClaimSummarySearchRequest = new ClaimSummarySearchRequest();
    if (!isStringNullUndefinedOrEmptyWithTrim(this.claimsTrackingForm.controls.searchByMemberIdField.value)) {
      claimSummarySearchRequest._memberId = this.claimsTrackingForm.controls.searchByMemberIdField.value;
    } else if (!isStringNullUndefinedOrEmptyWithTrim(this.claimsTrackingForm.controls.searchByClaimNumberField.value)) {
      claimSummarySearchRequest._authorizationNumber = this.claimsTrackingForm.controls.searchByClaimNumberField.value;
    }

    if (!isStringNullUndefinedOrEmptyWithTrim(this.claimsTrackingForm.controls.searchByPaidClaimStatusRadioButton.value) &&
          this.claimsTrackingForm.controls.searchByPaidClaimStatusRadioButton.value === this.formStateYesNo.Yes ) {
      claimSummarySearchRequest._claimStatus = ClaimSummaryStatusCodeEnum.Paid;
      if (!isNullOrUndefined(this.claimsTrackingForm.controls.claimPaidDateDropdown) && !isStringNullUndefinedOrEmptyWithTrim(this.claimsTrackingForm.controls.claimPaidDateDropdown.value)) {
        this.setServiceStartFromAndToDateInService(this.claimsTrackingForm.controls.claimPaidDateDropdown.value);
        // ECLAIM-92 - We added a filter date range to the claim summary request on a Paid Claim Search.
        claimSummarySearchRequest._serviceStartDateFrom = this.claimSummaryService.serviceStartDateFrom;
        // We need to check to see if the service date to is not in the future. If it is in the future, we need to send over today's date instead.
        claimSummarySearchRequest._serviceStartDateTo = !DateUtility.isStringDateInFuture(this.claimSummaryService.serviceStartDateTo) ? this.claimSummaryService.serviceStartDateTo : DateUtility.buildYyyyMmDdDateFromDate(new Date());
      }
      // Per ECLAIM-92 we want to set a date range of ONE YEAR in the past when a Member Id or Claim Number search is performed.
      if (this.isMemberIdOrClaimSearch) {
        const todayDate: Date = new Date();
        const oneYearInThePast: Date = new Date(todayDate.getFullYear() - 1, todayDate.getMonth(), todayDate.getDate());
        const formattedTodayDate: string = DateUtility.buildYyyyMmDdDateFromDate(todayDate);
        const formattedOneYearInThePast: string = DateUtility.buildYyyyMmDdDateFromDate(oneYearInThePast);
        this.claimSummaryService.serviceStartDateFrom = formattedOneYearInThePast;
        this.claimSummaryService.serviceStartDateTo = formattedTodayDate;
        claimSummarySearchRequest._serviceStartDateFrom = formattedOneYearInThePast;
        claimSummarySearchRequest._serviceStartDateTo = formattedTodayDate;

      }
    } else if (!isStringNullUndefinedOrEmptyWithTrim(this.claimsTrackingForm.controls.searchByPricedClaimStatusRadioButton.value) &&
              this.claimsTrackingForm.controls.searchByPricedClaimStatusRadioButton.value === this.formStateYesNo.Yes ) {
      claimSummarySearchRequest._claimStatus = ClaimSummaryStatusCodeEnum.Priced;
      // ECLAIM-92 - For PR claim search we want to add a filter date range of one month in the past. For now this is just for the Date of Service filter on the search results.
      const todayDate: Date = new Date();
      const oneMonthInThePast: Date = new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, todayDate.getDate());
      this.claimSummaryService.serviceStartDateFrom = DateUtility.buildYyyyMmDdDateFromDate(oneMonthInThePast);
      this.claimSummaryService.serviceStartDateTo = DateUtility.buildYyyyMmDdDateFromDate(todayDate);
    }
    return claimSummarySearchRequest;
  }

  public setServiceStartFromAndToDateInService(serviceStartFromAndToDate: string) {
    if (!isStringNullUndefinedOrEmptyWithTrim(serviceStartFromAndToDate) && serviceStartFromAndToDate.includes(ApplicationConstants.comma)) {
      const splitServiceStartFromAndTo: string [] = serviceStartFromAndToDate.split(ApplicationConstants.comma);
      this.claimSummaryService.serviceStartDateFrom = splitServiceStartFromAndTo[0].trim();
      this.claimSummaryService.serviceStartDateTo = splitServiceStartFromAndTo[1].trim();
    }
  }

  /**
   * Conditions required for a valid search combo:
   * 1. Tax ID And Claim Number
   * 2. Tax ID And Member ID
   * 3. Tax ID And Office Patient List
   * @returns {boolean}
   */
  get hasValidClaimsSearchCombination(): boolean {
    let validSearchCombination: boolean = false;
    if (!this.claimsTrackingForm.valid) {
      validSearchCombination = false;
    } else {
      // Claim Number
      const isValidClaimNumber = this.isValidFormControlValue(this.claimsTrackingForm.controls.searchByClaimNumberField);
      const claimNumberFieldIsEmpty = this.claimsTrackingForm.controls.searchByClaimNumberField.value === ApplicationConstants.emptyString;
      // Member Id
      const isValidMemberId = this.isValidFormControlValue(this.claimsTrackingForm.controls.searchByMemberIdField);
      const memberIdFieldIsEmpty = this.claimsTrackingForm.controls.searchByMemberIdField.value === ApplicationConstants.emptyString;
      // Paid Claim Date Range Search
      let isClaimPaidDateRangeValid: boolean = false;
      if (this.claimsTrackingForm.controls.searchByPaidClaimStatusRadioButton.value === this.formStateYesNo.Yes) {
        if (!isNullOrUndefined(this.claimsTrackingForm.controls.claimPaidDateDropdown) && (!isStringNullUndefinedOrEmptyWithTrim(this.claimsTrackingForm.controls.claimPaidDateDropdown.value) || this.claimsTrackingForm.controls.claimPaidDateDropdown.status === 'DISABLED')) {
          isClaimPaidDateRangeValid = true;
        }
      } else {
        isClaimPaidDateRangeValid = true;
      }

      if (isClaimPaidDateRangeValid && (isValidClaimNumber || isValidMemberId || (claimNumberFieldIsEmpty && memberIdFieldIsEmpty))) {
        validSearchCombination = true;
      }
    }
    // if the above conditions are met, we're valid
    return validSearchCombination;
  }

  /**
   * Method disables Member Id or Claim Number fields so that only one is enabled when one field or another has a value in it.
   */
  onMemberIdOrClaimNumberInput() {
    if (!isStringNullUndefinedOrEmptyWithTrim(this.claimsTrackingForm.controls.searchByMemberIdField.value)) {
      this.claimsTrackingForm.controls.searchByClaimNumberField.disable();
      // Per ECLAIM-92 new ACS we want to disable and clear out the Claim Pade Date dropdown if Member ID is entered.
      this.claimsTrackingForm.controls.claimPaidDateDropdown.setValue(ApplicationConstants.emptyString);
      this.claimsTrackingForm.controls.claimPaidDateDropdown.disable();
      this.isMemberIdOrClaimSearch = true;
    } else if (!isStringNullUndefinedOrEmptyWithTrim(this.claimsTrackingForm.controls.searchByClaimNumberField.value)) {
      this.claimsTrackingForm.controls.searchByMemberIdField.disable();
      // Per ECLAIM-92 new ACS we want to disable and clear out the Claim Pade Date dropdown if Claim Number is entered.
      this.claimsTrackingForm.controls.claimPaidDateDropdown.setValue(ApplicationConstants.emptyString);
      this.claimsTrackingForm.controls.claimPaidDateDropdown.disable();
      this.isMemberIdOrClaimSearch = true;
    } else {
      this.claimsTrackingForm.controls.searchByMemberIdField.enable();
      this.claimsTrackingForm.controls.searchByClaimNumberField.enable();
      // Per ECLAIM-92 new ACS we want to enable and reset the Claim Pade Date dropdown if Member Id and Claim Number fields are cleared out.
      this.claimsTrackingForm.controls.claimPaidDateDropdown.setValue(this.defaultMonthSelected);
      this.claimsTrackingForm.controls.claimPaidDateDropdown.enable();
      this.isMemberIdOrClaimSearch = false;
    }
  }

  /***** END - PUBLIC MEMBERS *****/

  /***** START - PRIVATE FUNCTIONS *****/

  private buildForm(): void {
    this.buildClaimPaidDateDropdownValues();
    this.claimsTrackingForm = this.formBuilder.group({
      searchByMemberIdField: [ApplicationConstants.emptyString, [this.customValidatorsService.AlphaNumeric, this.customValidatorsService.validMemberID]],
      searchByClaimNumberField: [ApplicationConstants.emptyString, [Validators.pattern(/^[0-9]{1,10}$/)]],
      searchByPaidClaimStatusRadioButton: this.formStateYesNo.Yes,
      searchByPricedClaimStatusRadioButton: this.formStateYesNo.No,
      claimPaidDateDropdown: this.defaultMonthSelected
    });
    this.isPaidClaimSearch = true;
    this.claimsTrackingForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe((viewModel) => {
    });
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      searchByMemberIdField: {
        control: this.claimsTrackingForm.controls.searchByMemberIdField,
        errors: [{
          validatorType: ErrorTypes.AlphaNumeric,
          errorMessage: ApplicationConstants.errorMessages.memberIdAlphaNumericErrorMessage
        }, {
          validatorType: ErrorTypes.InvalidMemberId,
          errorMessage: ApplicationConstants.errorMessages.memberIdInvalidMemberIdErrorMessage
        }]
      },
      searchByClaimNumberField: {
        control: this.claimsTrackingForm.controls.searchByClaimNumberField,
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid Claim Number'
          },
        ]
      }
    };
  }

  private isValidFormControlValue = (formControl: AbstractControl) => (formControl.value && formControl.valid);

  private clearSearchResults(): void {
    this.claimSummaries = undefined;
  }

  private buildClaimPaidDateDropdownValues() {
    let defaultCurrentMonth: boolean = false;
    for (let i = 0; i < 12; i++) {
      const date = new Date();
      // The .setDate(1) was added to avoid the ECLAIM-189 issue that came up with the Date() object.
      // The .setDate(1) insures the new Date() generated is set to the first of the month to avoid all
      // The weird issues the date object was causing at the end of the month.
      date.setDate(1);
      date.setMonth(date.getMonth() - i);
      const monthNumber: number = date.getMonth();
      let isDefaultSelectedMonth: boolean = false;
      if (i === 0) {
        // Since we are setting the date object above toi the first of the month we need to get the actual day
        // from a new instance of an object to properly set the selected month flag.
        const dayOfCurrentMonth: number = new Date().getDate();
        if (dayOfCurrentMonth >= 10) {
          defaultCurrentMonth = true;
          this.defaultMonthSelected = DateUtility.buildMonthDateFromToRange(date);
          this.setServiceStartFromAndToDateInService(this.defaultMonthSelected);
        }
      } else if (i === 1) {
        if (!defaultCurrentMonth) {
          isDefaultSelectedMonth = true;
          this.defaultMonthSelected = DateUtility.buildMonthDateFromToRange(date);
          this.setServiceStartFromAndToDateInService(this.defaultMonthSelected);
        }
      }
      this.claimPaidDateDropdownValues.push({
          monthDateRange: DateUtility.buildMonthDateFromToRange(date),
          monthNumber: (date.getMonth() + 1),
          monthName: ApplicationConstants.monthNames[monthNumber],
          monthYear: date.getFullYear(),
          defaultMonth: isDefaultSelectedMonth});
    }
  }

  /***** END - PRIVATE FUNCTIONS *****/

  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.buildForm();
    this.buildErrorWrapperConfig();
    this.loadPracticeInformation();
    // Clear out any data in the if present on load for the below fields.
    this.claimSummaryService.serviceStartDateFrom = undefined;
    this.claimSummaryService.serviceStartDateTo = undefined;
  }

  ngOnDestroy(): void {
    this.externalServiceLocationService.practiceName = undefined;
  }

  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {string} newVal - FormState.Yes || FormState.No
   */
  onPaidClaimStatusRadioButtonChange(newVal: MatRadioChange): void {
    const paidClaimStatusRadioButtonChangeValue: string = newVal.value;
    if (paidClaimStatusRadioButtonChangeValue === this.formStateYesNo.Yes) {
      this.claimsTrackingForm.controls.searchByPaidClaimStatusRadioButton.setValue(this.formStateYesNo.Yes);
      this.claimsTrackingForm.controls.searchByPricedClaimStatusRadioButton.setValue(this.formStateYesNo.No);
      this.isPaidClaimSearch = true;
    }
  }


  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {string} newVal - FormState.Yes || FormState.No
   */
  onPricedClaimStatusRadioButtonChange(newVal: MatRadioChange): void {
    const pricedClaimStatusRadioButtonChangeValue: string = newVal.value;
    if (pricedClaimStatusRadioButtonChangeValue === this.formStateYesNo.Yes) {
      this.claimsTrackingForm.controls.searchByPaidClaimStatusRadioButton.setValue(this.formStateYesNo.No);
      this.claimsTrackingForm.controls.searchByPricedClaimStatusRadioButton.setValue(this.formStateYesNo.Yes);
      this.isPaidClaimSearch = false;
    }
  }

  /**
   * This is to call external service location to get practice information.
   */
  loadPracticeInformation(): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Loading Claims Tracking Page...', this.dialog);
    this.externalServiceLocationService.getExternalServiceLocationOfficeInfo(false).subscribe((externalServiceLocationServiceResponse: ExternalServiceLocationResponse) => {
        if (isNullOrUndefined(externalServiceLocationServiceResponse)) {
          this.hasExternalServiceLocationCallFinishedWithErrors = true;
        } else {
          if (!isNullOrUndefined(externalServiceLocationServiceResponse.physicalAddress) &&
            !isStringNullUndefinedOrEmptyWithTrim(externalServiceLocationServiceResponse.physicalAddress.street1) &&
            !isStringNullUndefinedOrEmptyWithTrim(externalServiceLocationServiceResponse.physicalAddress.city) &&
            !isStringNullUndefinedOrEmptyWithTrim(externalServiceLocationServiceResponse.physicalAddress.stateCode) &&
            !isStringNullUndefinedOrEmptyWithTrim(externalServiceLocationServiceResponse.physicalAddress.zipCode)) {
            // Set variables
            const space: string = ApplicationConstants.blankSpace;
            const providerStreet: string = externalServiceLocationServiceResponse.physicalAddress.street1;
            const providerCity: string = externalServiceLocationServiceResponse.physicalAddress.city;
            const providerStateCode: string = externalServiceLocationServiceResponse.physicalAddress.stateCode;
            const providerZipCode: string = externalServiceLocationServiceResponse.physicalAddress.zipCode;
            // Set Practice Street
            this.practiceStreet = providerStreet;
            // Set Practice City State Zip
            this.practiceCityStateZip = providerCity + ApplicationConstants.comma +
              space + providerStateCode + space + providerZipCode;
            if (!isStringNullUndefinedOrEmptyWithTrim(externalServiceLocationServiceResponse.physicalAddress.zipExtension)) {
              const providerZipExtension: string = externalServiceLocationServiceResponse.physicalAddress.zipExtension;
              this.practiceCityStateZip = this.practiceCityStateZip + ApplicationConstants.minusSign + providerZipExtension;
            }
          }
          this.practiceName = this.externalServiceLocationService.practiceName;
          // Make sure Provider Tax Id is not null in response. Display Error Page instead of Claims Tracking Page
          if (isStringNullUndefinedOrEmptyWithTrim(externalServiceLocationServiceResponse.providerTaxId)) {
            this.hasExternalServiceLocationCallFinishedWithErrors = true;
          } else {
            this.hasExternalServiceLocationCallFinished = true;
            this.externalServiceLocationResponse = externalServiceLocationServiceResponse;
          }
        }
      }, () => {
        // on observer error
        this.dialog.closeAll();
      },
      () => {
        // Close dialog for LoadingModalComponent - Spinner
        this.dialog.closeAll();
      });
  }

  navigateToLegacyEInsurancePage() {
    window.location.href = this.cookieService.get(ApplicationConstants.efSurlCookie) + ApplicationConstants.legacyEInsurancePageURL;
  }

  /***** END - EVENT HANDLERS *****/

}
