import {ClaimSummaryStatusCodeEnum} from './claimSummary';

export class ClaimSummarySearchRequest {
  private memberId: string;
  private authorizationNumber: string;
  private claimStatus: ClaimSummaryStatusCodeEnum;
  private serviceStartDateFrom: string;
  private serviceStartDateTo: string;

  set _memberId(memberId: string) {
    this.memberId = memberId;
  }

  get _memberId(): string {
    return this.memberId;
  }

  get _authorizationNumber(): string {
    return this.authorizationNumber;
  }

  set _authorizationNumber(authorizationNumber: string) {
    this.authorizationNumber = authorizationNumber;
  }

  get _claimStatus(): ClaimSummaryStatusCodeEnum {
    return this.claimStatus;
  }

  set _claimStatus(claimStatus: ClaimSummaryStatusCodeEnum) {
    this.claimStatus = claimStatus;
  }

  get _serviceStartDateFrom(): string {
    return this.serviceStartDateFrom;
  }

  set _serviceStartDateFrom(serviceStartDateFrom: string) {
    this.serviceStartDateFrom = serviceStartDateFrom;
  }

  get _serviceStartDateTo(): string {
    return this.serviceStartDateTo;
  }

  set _serviceStartDateTo(serviceStartDateTo: string) {
    this.serviceStartDateTo = serviceStartDateTo;
  }
}
