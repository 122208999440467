import {Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Claim, SoftAndHardValidationMessages, ValidationMessage} from '../../../models/claim';
import {ApplicationConstants, ErrorTypes} from '../../../common/constants/application.constants';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClaimsService} from '../../../common/services/data-model/app/claims/claims.service';
import {Subscription} from 'rxjs';
import {ClaimFormItem} from '../../../common/classes/claim-form-item';
import {ComponentMaskComponent} from '../../../common/components/component-mask/component-mask.component';
import {InputMaskService} from '../../../common/services/support/input-mask/input-mask.service';
import {Signature} from '../../../models/signature';
import {CustomValidatorsService} from '../../../common/services/support/custom-validators/custom-validators.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ClaimCardsToUpdate} from '../../../models/claimCardsToUpdate';
import {DatePickerConfiguration} from '../../../common/components/date-picker/date-picker.component';
import {DateUtility, onKeypressEventCheckbox, isNullOrUndefined} from '../../../common/utility';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';

@Component({
  selector: 'app-signatures',
  templateUrl: './signatures.component.html',
  styleUrls: ['./signatures.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignaturesComponent extends ClaimFormItem implements OnInit, OnDestroy {


  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
    private claimService: ClaimsService,
    public inputMaskService: InputMaskService,
    private claimEditService: ClaimEditService
  ) {
    super(injector);
  }

  /***** START - PRIVATE MEMBERS *****/
  private observableSubscriptions: Subscription[] = [];
  private originalClaim: Claim;
  private activeClaim: Claim;
  private initialLoad = true;
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  /***** END - PRIVATE MEMBERS *****/

  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;
  @ViewChild('patientSignatureDateControl', {read: ElementRef, static: true}) patientSignatureDateControl: ElementRef;

  /***** START - PUBLIC MEMBERS *****/
  errorWrapperConfig = {
    patientSignature: undefined
  };
  todayDate = new Date();
  id = ApplicationConstants.componentIDs.signatures;
  title = 'Signatures';
  signaturesForm: FormGroup;
  datePickerConfigurationPatientSignatureDate: DatePickerConfiguration;
  // Assigning functions from utility class to variables so that these can be accessed  in template
  onKeypressEventCheckbox = onKeypressEventCheckbox;
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;
  /***** END - PUBLIC MEMBERS *****/
  /***** START - PROPERTY ACCESSORS *****/
  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }
  /***** END - PROPERTY ACCESSORS *****/

  private buildDatePickerConfigurationForPatientSignatureDate(): void {
    this.datePickerConfigurationPatientSignatureDate = {
      control: this.signaturesForm.controls.patientSignatureDate,
      controlName: 'patientSignatureDate',
      errorWrapperId: {
        defaultValidations: 'patient-signature-date-error',
        minDate: 'patient-signature-min-date-error'
      },
      attributes: {
        id: 'patient-signature-date-input'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: `Please indicate signature on file`
        }
      ]
    };
  }

  private buildForm() {
    // pull data from the original claim
    let patientSignatureIndicator, patientDateSigned, insuredSignatureIndicator,
      defaultPatientEncounterSignatureResponse: boolean = false;
    if (this.originalClaim.patientSignature) {
      patientSignatureIndicator = this.originalClaim.patientSignature.indicator;
      patientDateSigned = DateUtility.isValidDate(this.originalClaim.patientSignature.dateSigned) ? this.originalClaim.patientSignature.dateSigned : undefined;
    }
    if (this.originalClaim.memberSignature) {
      ({indicator: insuredSignatureIndicator} = this.originalClaim.memberSignature);
    }
    insuredSignatureIndicator = this.buildSignatureIndicatorValue(insuredSignatureIndicator);
    patientSignatureIndicator = this.buildSignatureIndicatorValue(patientSignatureIndicator);
    patientDateSigned = isNullOrUndefined(patientDateSigned) || !DateUtility.isValidDate(patientDateSigned) ? this.buildPatientSignatureDateValue(patientSignatureIndicator) : patientDateSigned;
    defaultPatientEncounterSignatureResponse = this.isDefaultPatientEncounterSignatureResponse(this.originalClaim.patientSignature, this.originalClaim.memberSignature);

    // Set patient signature values on active claim on initial load of the form
    if (defaultPatientEncounterSignatureResponse) {
      this.activeClaim = this.claimService.getActiveClaim();
      this.activeClaim.patientSignature.dateSigned = patientDateSigned;
      this.activeClaim.patientSignature.indicator = patientSignatureIndicator;
      this.activeClaim.memberSignature.indicator = insuredSignatureIndicator;
      this.claimService.setActiveClaim(this.activeClaim, this.id);
    }

    // build the form
    this.signaturesForm = this.formBuilder.group({
      patientSignature: [patientSignatureIndicator, [Validators.requiredTrue]],
      patientSignatureDate: [
        DateUtility.buildFriendlyDateFromJsDate(patientDateSigned),
        [
          Validators.required,
          this.customValidatorsService.dateFormatAndValidity,
          this.customValidatorsService.MinDate(ApplicationConstants.minDate)
        ]],
      insuredSignature: [insuredSignatureIndicator]
    });
    this.signaturesForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe((viewModel) => {
      this.updateDataModelFromViewModel();
    });
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      patientSignature: {
        control: this.signaturesForm.controls.patientSignature,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: `Please indicate signature on file`
        }]
      }
    };
  }

  private setViewModelFromDataModel(dataModel: Claim): void {
    // pull data from the view model
    let dataModelPatientSignatureIndicator, dataModelPatientSignatureDate, dataModelInsuredSignatureIndicator;
    if (dataModel.patientSignature) {
      dataModelPatientSignatureIndicator = dataModel.patientSignature.indicator;
      dataModelPatientSignatureDate = DateUtility.isValidDate(dataModel.patientSignature.dateSigned) ? dataModel.patientSignature.dateSigned : null;
    }
    if (dataModel.memberSignature) {
      ({indicator: dataModelInsuredSignatureIndicator} = dataModel.memberSignature);
    }
    const {patientSignature, patientSignatureDate} = this.signaturesForm.controls;
    const {insuredSignature} = this.signaturesForm.controls;
    const currentInsuredSignature = insuredSignature.value;
    const currentPatientSignature = patientSignature.value;
    dataModelInsuredSignatureIndicator = this.buildSignatureIndicatorValue(dataModelInsuredSignatureIndicator);
    dataModelPatientSignatureIndicator = this.buildSignatureIndicatorValue(dataModelPatientSignatureIndicator);
    dataModelPatientSignatureDate = isNullOrUndefined(dataModelPatientSignatureDate) ? this.buildPatientSignatureDateValue(dataModelPatientSignatureIndicator) : dataModelPatientSignatureDate;

    // set data in the view model only if different from the data model
    if (currentPatientSignature !== dataModelPatientSignatureIndicator) {
      patientSignature.setValue(dataModelPatientSignatureIndicator, ApplicationConstants.updateFormWithoutEmit);
    }
    if (dataModelPatientSignatureIndicator === false) {
      patientSignatureDate.disable(ApplicationConstants.updateFormWithoutEmit);
    } else {
      patientSignatureDate.setValue(DateUtility.buildFriendlyDateFromJsDate(dataModelPatientSignatureDate), ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentInsuredSignature !== dataModelInsuredSignatureIndicator) {
      insuredSignature.setValue(dataModelInsuredSignatureIndicator, ApplicationConstants.updateFormWithoutEmit);
    }
  }

  private updateDataModelFromViewModel(): void {
    /*if (this.viewModelUpdatedByUser()) {*/
    const {patientSignature, patientSignatureDate, insuredSignature} = this.signaturesForm.controls;
    // Gather and convert view model data
    const patientSignatureDateValue = (patientSignatureDate.value && patientSignatureDate.valid) ? new Date(patientSignatureDate.value) : this.activeClaim.patientSignature.dateSigned;
    // Instantiate signature objects if they don't exist
    if (!this.activeClaim.patientSignature) {
      this.activeClaim.patientSignature = {} as Signature;
    }
    if (!this.activeClaim.memberSignature) {
      this.activeClaim.memberSignature = {} as Signature;
    }
    // Populate claim data from the converted view model data
    this.activeClaim.patientSignature.indicator = patientSignature.value;
    this.activeClaim.patientSignature.dateSigned = patientSignatureDateValue;
    this.activeClaim.memberSignature.indicator = insuredSignature.value;
    // Update the active claim in the claim service
    if (this.signaturesCardHasChanged()) {
      this.claimService.setActiveClaim(this.activeClaim, this.id);
    }
    /*}*/
  }

  private signaturesCardHasChanged(): boolean {
    const {indicator: patientSignatureIndicatorFromCard, dateSigned: patientSignatureDateFromCard} = this.activeClaim.patientSignature;
    const patientSignatureDateFromCardString = DateUtility.buildFriendlyDateFromJsDate(patientSignatureDateFromCard);
    const {indicator: memberSignatureIndicatorFromCard} = this.activeClaim.memberSignature;


    const activeClaimFromService = this.claimService.getActiveClaim();
    // Instantiate claim objects if they don't exist
    if (isNullOrUndefined(activeClaimFromService.patientSignature)) {
      activeClaimFromService.patientSignature = {} as Signature;
    }
    if (isNullOrUndefined(activeClaimFromService.memberSignature)) {
      activeClaimFromService.memberSignature = {} as Signature;
    }
    const {indicator: patientSignatureIndicatorFromService, dateSigned: patientSignatureDateFromService} = activeClaimFromService.patientSignature;
    const patientSignatureDateFromServiceString = DateUtility.buildFriendlyDateFromJsDate(patientSignatureDateFromService);
    const {indicator: memberSignatureIndicatorFromService} = activeClaimFromService.memberSignature;

    return (patientSignatureIndicatorFromCard || undefined) !== (patientSignatureIndicatorFromService || undefined) ||
      (patientSignatureDateFromCardString || undefined) !== (patientSignatureDateFromServiceString || undefined) ||
      (memberSignatureIndicatorFromCard || undefined) !== (memberSignatureIndicatorFromService || undefined);
  }

  private isDefaultPatientEncounterSignatureResponse = (patientSignature: Signature, memberSignature: Signature): boolean => {
    if (patientSignature && memberSignature) {
      return Object.keys(patientSignature).length === 0 && Object.keys(memberSignature).length === 0;
    }
  }

  private buildSignatureIndicatorValue = (signatureIndicator: boolean): boolean =>
    !isNullOrUndefined(signatureIndicator) ? signatureIndicator : true;

  private buildPatientSignatureDateValue(patientSignatureIndicator: boolean): string {
    return patientSignatureIndicator ? new Date().toISOString() : null;
  }

  ngOnInit() {
    this.originalClaim = this.claimService.getOriginalClaim();
    this.registerWithClaimProgressService();
    this.buildForm();
    this.buildErrorWrapperConfig();
    this.buildDatePickerConfigurationForPatientSignatureDate();
    // Register for updates to the active claim
    // NOTE: uiFormattedClaim will be undefined if any errors occurred upstream
    this.observableSubscriptions.push(this.claimService.onCardsToUpdate.subscribe((onCardsToUpdate: ClaimCardsToUpdate) => {
      this.activeClaim = this.claimService.getActiveClaim();
      // Set form data if update is required
      if (onCardsToUpdate.all) {
        this.setViewModelFromDataModel(this.activeClaim);
      }
      // TODO: assuming that an empty claim is undefined from the service side.  This will likely need to be updated
      // to a different checking mechanism once claims are created for the first time
      if (this.initialLoad && this.activeClaim) {
        this.initialLoad = false;
      }
    }));
    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      if (mask) {
        this.disableFormGroupComponents(this.signaturesForm);
        this.componentMask.show();
      } else {
        this.signaturesForm.enable();
        this.componentMask.hide();
      }
    }));
    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
          (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }
    }));
  }

  onPatientSignatureChange() {
    const value = this.signaturesForm.controls.patientSignature.value;
    if (value === true) {
      this.signaturesForm.controls.patientSignatureDate.enable();
      this.activeClaim.patientSignature.dateSigned = new Date();
      this.signaturesForm.controls.patientSignatureDate.setValue(DateUtility.buildFriendlyDateFromJsDate(this.activeClaim.patientSignature.dateSigned));
    } else {
      this.signaturesForm.controls.patientSignatureDate.reset();
      this.signaturesForm.controls.patientSignatureDate.disable();
    }
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
