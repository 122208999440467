import {
  AfterViewInit, ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {CobValidators, ServicesValidators} from './services.validators';
import {MatDialog} from '@angular/material/dialog';
import {FSAExplanationModalComponent} from './fsa-explanation-modal/fsa-explanation-modal.component';
import {ClaimFormItem} from '../../../common/classes/claim-form-item';
import {
  allStringArrayElementsEmpty,
  twoDecimalPlaceFormat,
  isNullOrUndefined,
  isDollarAmountEmptyOrNull,
  isStringNullUndefinedOrEmpty,
  returnBooleanFromFormState,
  isEmptyList,
  isNotEmptyList, toDollarAmount, isChecked
} from '../../../common/utility';
import {HcpcCptCodesModalComponent} from './hcpc-cpt-codes-modal/hcpc-cpt-codes-modal.component';
import {MINIMUM_SERVICE_LINE_COUNT, PLACE_OF_SERVICE_CODE, ServicesConstants} from './services.constants';
import {UsdCurrencyPipe} from '../../../common/pipes/usd-currency.pipe';
import {ServiceLineComponent} from './service-line/service-line.component';
import {ApplicationConstants, ErrorTypes} from '../../../common/constants/application.constants';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ClaimsService} from '../../../common/services/data-model/app/claims/claims.service';
import {Subscription} from 'rxjs';
import {ServiceLine} from '../../../models/serviceLine';
import {UIFormattedDiagnosis} from '../../../models/UIFormattedDiagnosis';
import {DataMarshallService} from '../../../common/services/data-model/http/data-marshall/data-marshall.service';
import {DateUtility} from '../../../common/utility';
import {ComponentMaskComponent} from '../../../common/components/component-mask/component-mask.component';
import {MessageService} from '../../../common/services/support/message/message.service';
import {Claim, PatientConditions, SoftAndHardValidationMessages, ValidationMessage} from '../../../models/claim';
import {ClaimCardsToUpdate} from '../../../models/claimCardsToUpdate';
import {HtmlConstants} from '../../../common/constants/html.constants';
import {
  SimpleModalComponent,
  SimpleModalDialogData
} from '../../../common/components/simple-modal/simple-modal.component';
import {DrReportsNavigationService} from '../../../common/services/support/dr-reports-navigation/dr-reports-navigation.service';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';
import {HcpcCptCodesDescriptionsModalComponent} from './hcpc-cpt-codes-descriptions-modal/hcpc-cpt-codes-descriptions-modal.component';
import {Inventories} from '../../../models/Inventory';
import {InventoryService} from '../../../common/services/data-model/app/inventory/inventory.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss', '../claim-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicesComponent extends ClaimFormItem implements OnInit, OnDestroy, AfterViewInit {


  /***** START - PRIVATE MEMBERS *****/
  private _serviceLineValidatorMap: Map<AbstractControl, ValidatorFn | ValidatorFn[]>;
  private _isCobClaim: boolean;
  private _detectChangesForServiceLines: boolean;
  private _cptHcpcsCodeMatches = false;
  private _hasKnownCondition = false;
  private _serviceLineHtmlConstants: any;
  private _isInitialPageLoad: boolean;
  private _activeClaim: Claim;
  private observableSubscriptions: Subscription[] = [];
  private originalClaim: Claim;
  private successfulCalculateComplete = false;
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  /***** END - PRIVATE MEMBERS *****/

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private injector: Injector,
    private claimsService: ClaimsService,
    private dataMarshallService: DataMarshallService,
    private messageService: MessageService,
    private changeDetector: ChangeDetectorRef,
    private drReportsNavigationService: DrReportsNavigationService,
    private claimEditService: ClaimEditService,
    private inventoryService: InventoryService
  ) {
    super(injector);
  }

  /***** START - PUBLIC MEMBERS *****/
  public id = ApplicationConstants.componentIDs.services;
  public title = 'Services';
  public servicesForm: FormGroup;
  public lastSelectedProcServiceLineIndex: number;
  public addDiagnosisCodes = true;
  public servicesConstants = ServicesConstants;
  @ViewChild('fsaPaidInput', {static: true}) fsaPaidInput: ElementRef;
  @ViewChild(ServiceLineComponent, {static: true}) serviceLineComponent: ServiceLineComponent;
  @ViewChild(ComponentMaskComponent, {static: true}) componentMask: ComponentMaskComponent;
  // Needed for the template even though it says unused
  allStringArrayElementsEmpty = allStringArrayElementsEmpty;
  isDollarAmountEmptyOrNull = isDollarAmountEmptyOrNull;
  DIAGNOSIS_CODES = ServicesConstants.DIAGNOSIS_CODES;
  SERVICE_LINES = ServicesConstants.SERVICE_LINES;
  CPT_HCPCS_CODE = ServicesConstants.CPT_HCPCS_CODE;
  BILLED_AMOUNT = ServicesConstants.BILLED_AMOUNT;
  FSA_PAID = ServicesConstants.FSA_PAID;
  TOTAL_AMOUNT = ServicesConstants.TOTAL_AMOUNT;
  PATIENT_PAYMENT_AMOUNT = ServicesConstants.PATIENT_PAYMENT_AMOUNT;
  TOTAL_DUE = ServicesConstants.TOTAL_DUE;
  OTHER_INSURANCE_ALLOWED_AMOUNT = ServicesConstants.OTHER_INSURANCE_ALLOWED_AMOUNT;
  OTHER_INSURANCE_PAID_AMOUNT = ServicesConstants.OTHER_INSURANCE_PAID_AMOUNT;
  OTHER_INSURANCE_PATIENT_PAID_AMOUNT = ServicesConstants.OTHER_INSURANCE_PATIENT_PAID_AMOUNT;
  OTHER_INSURANCE_DENIED_NOT_COVERED_REASON = ServicesConstants.OTHER_INSURANCE_DENIED_NOT_COVERED_REASON;
  ERROR_TYPES = {...ErrorTypes};
  twoDecimalPlaceFormat = twoDecimalPlaceFormat;
  precedingZeroForDecimalPipe = UsdCurrencyPipe.precedingZeroForDecimalPipe;
  disabledForm: boolean;
  fsaIndicator: boolean = false;
  // Form state/data variables
  errorWrapperConfig = {
    noEmptyDiagnosisCodes: undefined,
    fsaPaid: undefined,
    patientPaymentAmount: undefined,
    patientPaymentMaxAmount: undefined,
    fsaPaidMaxAmount: undefined,
    invalidDiagnosisCode: undefined,
  };
  selectOptions = {
    pcpCommunicationOptions: ServicesConstants.pcpCommunicationOptions,
  };
  fsaPaidValueUpdated: boolean;
  serviceLineHtmlAttributes: any = HtmlConstants.attributes;
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;

  /***** END - PUBLIC MEMBERS *****/

  /***** START - PROPERTY ACCESSORS *****/

  get cptHcpcsCodeMatches(): boolean {
    return this._cptHcpcsCodeMatches;
  }

  set cptHcpcsCodeMatches(newVal: boolean) {
    this._cptHcpcsCodeMatches = newVal;
  }

  get hasKnownCondition(): boolean {
    return this._hasKnownCondition;
  }

  set hasKnownCondition(newVal: boolean) {
    this._hasKnownCondition = newVal;
    const patientConditionNoneDisabled = this.servicesForm.controls.patientConditionNone.disabled;
    const patientConditionNoneHasValue = isChecked(this.servicesForm.controls.patientConditionNone.value);
    // If there was a selection made to any of the known conditions and the 'none' checkbox is enabled, disable it
    if (this._hasKnownCondition && !patientConditionNoneDisabled) {
      this.servicesForm.controls.patientConditionNone.disable();
      // If no known conditions are selected, proceed
    } else if (!this._hasKnownCondition) {
      // If the 'none' checkbox is disabled, enable it
      if (patientConditionNoneDisabled) {
        this.servicesForm.controls.patientConditionNone.enable();
      }
      // If the 'none' checkbox has a value, disable the other checkboxes
      if (patientConditionNoneHasValue) {
        this.servicesForm.controls.prediabetesIndicator.disable();
        this.servicesForm.controls.diabetesIndicator.disable();
        this.servicesForm.controls.diabeticRetinopathyIndicator.disable();
        this.servicesForm.controls.hypertensionIndicator.disable();
        this.servicesForm.controls.highCholesterolIndicator.disable();
        this.servicesForm.controls.glaucomaIndicator.disable();
        this.servicesForm.controls.ageRelatedMacularDegenerationIndicator.disable();
        // If the 'none' checkbox does not have a value, enable the other checkboxes
      } else {
        this.servicesForm.controls.prediabetesIndicator.enable();
        this.servicesForm.controls.diabetesIndicator.enable();
        this.servicesForm.controls.diabeticRetinopathyIndicator.enable();
        this.servicesForm.controls.hypertensionIndicator.enable();
        this.servicesForm.controls.highCholesterolIndicator.enable();
        this.servicesForm.controls.glaucomaIndicator.enable();
        this.servicesForm.controls.ageRelatedMacularDegenerationIndicator.enable();
      }
    }
  }

  get isCobClaim(): boolean {
    return this._isCobClaim;
  }

  set isCobClaim(value: boolean) {
    this._isCobClaim = value;
  }

  get detectChangesForServiceLines(): boolean {
    return this._detectChangesForServiceLines;
  }

  set detectChangesForServiceLines(value: boolean) {
    this._detectChangesForServiceLines = value;
  }

  get serviceLineHtmlConstants(): any {
    return this._serviceLineHtmlConstants;
  }

  set serviceLineHtmlConstants(updatedValues: any) {
    this._serviceLineHtmlConstants = updatedValues;
  }

  get isInitialPageLoad(): boolean {
    return this._isInitialPageLoad;
  }

  set isInitialPageLoad(isInitialLoad: boolean) {
    this._isInitialPageLoad = isInitialLoad;
  }

  get activeClaim(): Claim {
    return this._activeClaim;
  }

  set activeClaim(updatedClaim: Claim) {
    this._activeClaim = updatedClaim;
  }

  get serviceLineValidatorMap(): Map<AbstractControl, ValidatorFn | ValidatorFn[]> {
    return this._serviceLineValidatorMap;
  }

  set serviceLineValidatorMap(mapValues: Map<AbstractControl, ValidatorFn | ValidatorFn[]>) {
    this._serviceLineValidatorMap = mapValues;
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }
  /***** END - PROPERTY ACCESSORS *****/

  /***** START - PRIVATE FUNCTIONS *****/
  private isCobClaimWithValidHcpcCode = (hcpcCode: string): boolean => this.isCobClaim && !isStringNullUndefinedOrEmpty(hcpcCode);

  private addServiceLineEventListenerToFormatCharges = (): Subscription => this.servicesForm.get(ServicesConstants.SERVICE_LINES).valueChanges.subscribe(changed => this.calculateTotalCharges(changed));

  private getPCPIndicatorFromSelection(pcpValue: string): boolean {
    let pcpCommunicationCompleted: boolean;
    if (pcpValue === ServicesConstants.pcpCommunicationOptions[0]) {
      pcpCommunicationCompleted = true;
    } else if (pcpValue === ServicesConstants.pcpCommunicationOptions[1]) {
      pcpCommunicationCompleted = false;
    }
    return pcpCommunicationCompleted;
  }

  private getPCPIndicatorFromDataModel(pcpValue: boolean): string {
    let pcpCommunicationCompleted: string;
    if (pcpValue === true) {
      pcpCommunicationCompleted = ServicesConstants.pcpCommunicationOptions[0];
    } else if (pcpValue === false) {
      pcpCommunicationCompleted = ServicesConstants.pcpCommunicationOptions[1];
    }
    return pcpCommunicationCompleted;
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      fsaPaid: {
        control: this.servicesForm.get(this.FSA_PAID),
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the FSA amount Paid'
        }, {
          validatorType: ErrorTypes.DollarAmount,
          errorMessage: 'Please enter a valid amount'
        }]
      },
      fsaPaidMaxAmount: {
        control: this.servicesForm.get(this.FSA_PAID),
        errors: [{
          validatorType: ErrorTypes.MaxDollarAmount,
          errorMessage: `The maximum value accepted is $${ApplicationConstants.maxDollarAmountAllowed}`
        }]
      },
      patientPaymentAmount: {
        control: this.servicesForm.get(this.PATIENT_PAYMENT_AMOUNT),
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the amount Paid'
        },
          {
            validatorType: ErrorTypes.DollarAmount,
            errorMessage: 'Please enter a valid amount'
          }]
      },
      patientPaymentMaxAmount: {
        control: this.servicesForm.get(this.PATIENT_PAYMENT_AMOUNT),
        errors: [{
          validatorType: ErrorTypes.MaxDollarAmount,
          errorMessage: `The maximum value accepted is $${ApplicationConstants.maxDollarAmountAllowed}`
        }]
      },
      noEmptyDiagnosisCodes: {
        control: this.servicesForm.get(this.DIAGNOSIS_CODES),
        errors: [{
          validatorType: ErrorTypes.NoEmptyDiagnosisCodes,
          errorMessage: 'Please enter at least one valid Diagnosis Code'
        }]
      },
      invalidDiagnosisCode: {
        control: this.servicesForm,
        errors: [{
          validatorType: ErrorTypes.InvalidDiagnosisCode,
          errorMessage: 'Cannot reference an empty Diagnosis'
        }]
      }
    };
  }


  private setViewModelFromDataModel(dataModel: Claim, onCardsToUpdate: ClaimCardsToUpdate): void {
    if (!this.isInitialPageLoad && onCardsToUpdate.all) {
      // Rebuild the service line controls in the services form array
      // TODO: Write unit/integration tests for rebuilding the services lines based on PE response
      this.rebuildServiceLinesFromPatientEncounterResponse();
    }

    // get values from activeClaim
    let dataModelPrediabetesIndicator, dataModelDiabetesIndicator, dataModelDiabeticRetinopathyIndicator,
      dataModelHypertensionIndicator,
      dataModelHighCholesterolIndicator, dataModelPatientConditionNone,
      dataModelGlaucomaIndicator, dataModelAgeRelatedMacularDegenerationIndicator;
    if (dataModel.patientConditions) {
      ({
        prediabetesIndicator: dataModelPrediabetesIndicator,
        diabetesIndicator: dataModelDiabetesIndicator,
        diabeticRetinopathyIndicator: dataModelDiabeticRetinopathyIndicator,
        hypertensionIndicator: dataModelHypertensionIndicator,
        highCholesterolIndicator: dataModelHighCholesterolIndicator,
        glaucomaIndicator: dataModelGlaucomaIndicator,
        ageRelatedMacularDegenerationIndicator: dataModelAgeRelatedMacularDegenerationIndicator,
        noConditionIndicator: dataModelPatientConditionNone
      } = dataModel.patientConditions);
    }
    const {cms1500Diagnoses: dataModelDiagnosisCodes, patientPaymentAmount: dataModelPatientPaymentAmount} = dataModel;
    const dataModelPcpCompletedIndicator = this.getPCPIndicatorFromDataModel(dataModel.primaryCarePhysicianFormIndicator);

    // get form fields from reactive form
    const {
      diagnosisCodes, patientPaymentAmount, pcpCommunicationCompleted, prediabetesIndicator, diabetesIndicator,
      diabeticRetinopathyIndicator, hypertensionIndicator, highCholesterolIndicator, patientConditionNone,
      glaucomaIndicator, ageRelatedMacularDegenerationIndicator
    } = this.servicesForm.controls;

    // set data in the view model from what is in the data model
    diagnosisCodes.patchValue(dataModelDiagnosisCodes, ApplicationConstants.updateFormWithoutEmit);
    patientPaymentAmount.setValue(!isDollarAmountEmptyOrNull(dataModelPatientPaymentAmount) ? dataModelPatientPaymentAmount : null,
      ApplicationConstants.updateFormWithoutEmit);
    twoDecimalPlaceFormat(patientPaymentAmount);
    if (isDollarAmountEmptyOrNull(patientPaymentAmount.value)) {
      patientPaymentAmount.markAsPristine();
      patientPaymentAmount.markAsUntouched();
      patientPaymentAmount.updateValueAndValidity();
    }
    pcpCommunicationCompleted.setValue(dataModelPcpCompletedIndicator, ApplicationConstants.updateFormWithoutEmit);
    prediabetesIndicator.setValue(dataModelPrediabetesIndicator, ApplicationConstants.updateFormWithoutEmit);
    diabetesIndicator.setValue(dataModelDiabetesIndicator, ApplicationConstants.updateFormWithoutEmit);
    diabeticRetinopathyIndicator.setValue(dataModelDiabeticRetinopathyIndicator, ApplicationConstants.updateFormWithoutEmit);
    hypertensionIndicator.setValue(dataModelHypertensionIndicator, ApplicationConstants.updateFormWithoutEmit);
    highCholesterolIndicator.setValue(dataModelHighCholesterolIndicator, ApplicationConstants.updateFormWithoutEmit);
    glaucomaIndicator.setValue(dataModelGlaucomaIndicator, ApplicationConstants.updateFormWithoutEmit);
    ageRelatedMacularDegenerationIndicator.setValue(dataModelAgeRelatedMacularDegenerationIndicator, ApplicationConstants.updateFormWithoutEmit);
  }

  private generateDefaultServiceLine(index: number): ServiceLine {
    const startDate = Object.deepClone(this.activeClaim.dateOfService);
    const endDate = Object.deepClone(this.activeClaim.dateOfService);
    return {
      // startDate and endDate are fed in from the API, POS is always 11
      serviceStartDate: startDate,
      serviceEndDate: endDate,
      placeOfServiceCode: PLACE_OF_SERVICE_CODE,
      emergencyIndicator: false,
      cptHcpcsCode: '',
      modifierCodes: [''],
      diagnosisPointers: ['', '', '', ''],
      billedAmount: '',
      unitCount: '',
      epsdtIndicator: false,
      otherInsuranceAllowedAmount: '',
      otherInsurancePaidAmount: '',
      otherInsurancePatientPaidAmount: '',
      otherInsuranceDeniedNotCoveredReason: '',
      lineNumber: index
    };
  }

  /**
   * Rebuild the service lines controls by removing the current service line controls and initializing
   * a new set based on the service lines returned by Patient Encounter.
   */
  private rebuildServiceLinesFromPatientEncounterResponse(): void {
    // Grab a reference to the service lines returned by PE and initialize the service lines form array to replace the current
    const activeClaimServiceLines = this.activeClaim.serviceLines;
    const serviceLinesToAddToServicesFormGroup: FormGroup[] = [];

    // Create new service lines from the service lines return from patient encounter
    if (isNotEmptyList(activeClaimServiceLines)) {
      activeClaimServiceLines.forEach((serviceLine: ServiceLine) => {
        const serviceLineForm: FormGroup = this.createServiceLinesFormGroup(serviceLine);
        serviceLinesToAddToServicesFormGroup.push(serviceLineForm);
      });
    }

    // Generate the default six service lines if no lines were created, or pad the current amount until we reach the default six
    if (isEmptyList(serviceLinesToAddToServicesFormGroup) || serviceLinesToAddToServicesFormGroup.length < MINIMUM_SERVICE_LINE_COUNT) {
      let lineNumber = serviceLinesToAddToServicesFormGroup.length;
      while (serviceLinesToAddToServicesFormGroup.length < MINIMUM_SERVICE_LINE_COUNT) {
        ++lineNumber;
        const serviceLine: ServiceLine = this.generateDefaultServiceLine(lineNumber);
        const defaultServiceLineFormGroup: FormGroup = this.createServiceLinesFormGroup(serviceLine);
        serviceLinesToAddToServicesFormGroup.push(defaultServiceLineFormGroup);
      }
    }

    // Instantiate the new service lines form array from and add back to the services form
    const rebuiltServiceLines: FormArray = this.formBuilder.array(serviceLinesToAddToServicesFormGroup);

    // Remove the previous calculated total subscription, as the connection is broken when the service lines are reassigned
    this.observableSubscriptions.pop();
    this.servicesForm.setControl(this.SERVICE_LINES, rebuiltServiceLines);

    // Add the calculated total subscription back to the array
    this.observableSubscriptions.push(this.addServiceLineEventListenerToFormatCharges());
    this.servicesForm.get(this.SERVICE_LINES).updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);
    this.changeDetector.detectChanges();
  }

  private initializeServiceLines(): FormArray {
    const serviceLinesList = this.originalClaim.serviceLines;
    if (serviceLinesList.length < MINIMUM_SERVICE_LINE_COUNT) {
      for (let i = serviceLinesList.length; i < MINIMUM_SERVICE_LINE_COUNT; i++) {
        const serviceLine: ServiceLine = this.generateDefaultServiceLine(i + 1);
        serviceLinesList.push(serviceLine);
      }
    }
    return this.formBuilder.array(serviceLinesList.map(serviceLine => this.createServiceLinesFormGroup(serviceLine)));
  }

  private initializeDiagnosisCodes(): FormArray {
    const savedDiagnosisCodes: boolean = !isNullOrUndefined(this.originalClaim.cms1500Diagnoses) && this.originalClaim.cms1500Diagnoses.length > 0;
    const diagnosisCodesList: UIFormattedDiagnosis[] = savedDiagnosisCodes ? this.originalClaim.cms1500Diagnoses : [];
    const totalDiagnosisCodesLength = diagnosisCodesList.length;
    const diagnosisCodeInitialMax = totalDiagnosisCodesLength <= ServicesConstants.DEFAULT_DIAGNOSIS_CODES ?
      ServicesConstants.DEFAULT_DIAGNOSIS_CODES : ServicesConstants.MAX_DIAGNOSIS_CODES;
    for (let i = totalDiagnosisCodesLength; i < diagnosisCodeInitialMax; i++) {
      const diagnosisCode: UIFormattedDiagnosis = {
        position: this.dataMarshallService.getDiagnosisCodeLabelFromIndex(i),
        diagnosisCode: ''
      };
      diagnosisCodesList.push(diagnosisCode);
    }
    if (diagnosisCodesList.length > ServicesConstants.DEFAULT_DIAGNOSIS_CODES) {
      this.addDiagnosisCodes = false;
    }
    return this.formBuilder.array(diagnosisCodesList.map(diagnosisCode => this.createDiagnosisCodeFormGroup(diagnosisCode)), ServicesValidators.noEmptyDiagnosisCodes);
  }

  private enableFieldsOnUnmask(): void {
    this.servicesForm.enable(ApplicationConstants.updateFormWithoutEmit);
    this.rebuildKnownConditions();
    this.servicesForm.controls.totalAmount.disable(ApplicationConstants.updateFormWithoutEmit);
    this.servicesForm.controls.totalDue.disable(ApplicationConstants.updateFormWithoutEmit);
    const serviceLinesFormControl = <FormArray>this.servicesForm.get(this.SERVICE_LINES);
    for (const serviceLine of serviceLinesFormControl.controls) {
      (<FormGroup>serviceLine).controls.serviceStartDate.disable();
      (<FormGroup>serviceLine).controls.serviceEndDate.disable();
      (<FormGroup>serviceLine).controls.placeOfServiceCode.disable();
    }

    // Keep the COB controls disabled if not a COB claim, unbox and iterate through each service line
    serviceLinesFormControl.controls.forEach((formGroup: FormGroup) => {
      if (!this.isCobClaimWithValidHcpcCode(formGroup.get(this.CPT_HCPCS_CODE).value as string)) {
        formGroup.get(ServicesConstants.OTHER_INSURANCE_ALLOWED_AMOUNT).disable();
        formGroup.get(ServicesConstants.OTHER_INSURANCE_PAID_AMOUNT).disable();
        formGroup.get(ServicesConstants.OTHER_INSURANCE_PATIENT_PAID_AMOUNT).disable();
        formGroup.get(ServicesConstants.OTHER_INSURANCE_DENIED_NOT_COVERED_REASON).disable();
      }
    });

    if (this.successfulCalculateComplete) {
      const chargesFieldFromFirstServiceLine = document.getElementById(HtmlConstants.attributes.firstBilledAmountId);
      chargesFieldFromFirstServiceLine.parentElement.parentElement.parentElement.scrollIntoView({behavior: 'smooth'});
      chargesFieldFromFirstServiceLine.focus();
      this.successfulCalculateComplete = false;
    }
  }

  private rebuildKnownConditions() {
    // set a flag indicating whether a known condition is selected (any condition besides "None")
    this.hasKnownCondition = (
      isChecked(this.servicesForm.controls.prediabetesIndicator.value)
      || isChecked(this.servicesForm.controls.diabetesIndicator.value)
      || isChecked(this.servicesForm.controls.diabeticRetinopathyIndicator.value)
      || isChecked(this.servicesForm.controls.hypertensionIndicator.value)
      || isChecked(this.servicesForm.controls.highCholesterolIndicator.value)
      || isChecked(this.servicesForm.controls.glaucomaIndicator.value)
      || isChecked(this.servicesForm.controls.ageRelatedMacularDegenerationIndicator.value)
    );

    // Disable 'None' if any condition selected.
    if (this.hasKnownCondition === true) {
      this.servicesForm.controls.patientConditionNone.setValue(false);
      this.servicesForm.controls.patientConditionNone.disable();
    } else {
      this.servicesForm.controls.patientConditionNone.enable();
    }
    // TODO: Need an observable to populate this data.  The old service loops through claim.serviceLines looking for first matching contactConstants.services
    // // if any of the PROC values in the service lines matches a value in the Exam Type Dropdown
    // // make the Known Conditions checkboxes required.
    // this.cptHcpcsCodeMatches = claimFormHcpcService.anyServiceLineHasExamTypeHcpcCode($scope.claim);

    // set a flag indicating whether ANY known condition checkbox is selected (any condition or "None")
    /*const patientConditionNoneHasValue = !this.isEmpty(this.contactsForm.controls.patientConditionNone.value);
    this.knownConditionOptionSelected = !this.cptHcpcsCodeMatches
      || this.hasKnownCondition
      || patientConditionNoneHasValue;*/
  }

  private initializeServiceLineFormControlsFromExistingLines(cptHcpcsCode: string): any {
    return {
      // startDate and endDate should be the current Date of Service from the patient section, POS is always 11
      serviceStartDate: !isNullOrUndefined(this.activeClaim.dateOfService) ? this.activeClaim.dateOfService : this.activeClaim.serviceLines[0].serviceStartDate,
      serviceEndDate: !isNullOrUndefined(this.activeClaim.dateOfService) ? this.activeClaim.dateOfService : this.activeClaim.serviceLines[0].serviceEndDate,
      placeOfServiceCode: PLACE_OF_SERVICE_CODE,
      emergencyIndicator: false,
      cptHcpcsCode: cptHcpcsCode,
      modifierCodes: [''],
      diagnosisPointers: ['', '', '', ''],
      billedAmount: null,
      unitCount: null,
      otherInsuranceAllowedAmount: null,
      otherInsurancePaidAmount: null,
      otherInsurancePatientPaidAmount: null,
      otherInsuranceDeniedNotCoveredReason: null
    };
  }

  private updateCobFormControlsOnButtonToggle(): void {
    // Grab a reference to the service lines
    const serviceLinesReference: FormArray = this.servicesForm.get(ServicesConstants.SERVICE_LINES) as FormArray;

    // Loop through each control if we have a valid reference
    if (serviceLinesReference && serviceLinesReference.controls && serviceLinesReference.controls.length > 0) {
      serviceLinesReference.controls.forEach((serviceLineControl: AbstractControl) => {
        // Grab a reference to the HCPC code and the COB form controls
        const hcpcCodeForValidation: AbstractControl = serviceLineControl.get(ServicesConstants.CPT_HCPCS_CODE);
        const cobControlReferences: AbstractControl[] = [
          serviceLineControl.get(ServicesConstants.OTHER_INSURANCE_ALLOWED_AMOUNT),
          serviceLineControl.get(ServicesConstants.OTHER_INSURANCE_PAID_AMOUNT),
          serviceLineControl.get(ServicesConstants.OTHER_INSURANCE_PATIENT_PAID_AMOUNT),
          serviceLineControl.get(ServicesConstants.OTHER_INSURANCE_DENIED_NOT_COVERED_REASON)
        ];

        // Loop through each COB control and enable/disable based on the COB claim state
        cobControlReferences.forEach((cobControl: AbstractControl) => {
          if (!this.isCobClaim) {
            // Reset and disable each COB field
            cobControl.reset();
            cobControl.disable();
          } else {
            if (hcpcCodeForValidation && !isStringNullUndefinedOrEmpty(hcpcCodeForValidation.value as string)) {
              cobControl.enable();
            } else {
              cobControl.disable();
            }
          }
        });
      });
    }
  }

  private servicesCardHasChanged(): boolean {
    const {
      prediabetesIndicator: prediabetesIndicatorFromCard, diabetesIndicator: diabetesIndicatorFromCard,
      diabeticRetinopathyIndicator: diabeticRetinopathyIndicatorFromCard, hypertensionIndicator: hypertensionIndicatorFromCard,
      highCholesterolIndicator: highCholesterolIndicatorFromCard, glaucomaIndicator: glaucomaIndicatorFromCard,
      ageRelatedMacularDegenerationIndicator: ageRelatedMacularDegenerationIndicatorFromCard, noConditionIndicator: noConditionIndicatorFromCard
    } = this.activeClaim.patientConditions;
    const {
      primaryCarePhysicianFormIndicator: primaryCarePhysicianFormIndicatorFromCard, patientPaymentAmount: patientPaymentAmountFromCard,
      cms1500Diagnoses: cms1500DiagnosesFromCard, memberFsaAmount: memberFsaAmountFromCard
    } = this.activeClaim;


    const activeClaimFromService = this.claimsService.getActiveClaim();
    // Instantiate claim objects if they don't exist
    if (isNullOrUndefined(activeClaimFromService.patientConditions)) {
      activeClaimFromService.patientConditions = {} as PatientConditions;
    }
    if (isNullOrUndefined(activeClaimFromService.services)) {
      activeClaimFromService.services = [] as ServiceLine[];
    }
    const {
      prediabetesIndicator: prediabetesIndicatorFromService, diabetesIndicator: diabetesIndicatorFromService,
      diabeticRetinopathyIndicator: diabeticRetinopathyIndicatorFromService, hypertensionIndicator: hypertensionIndicatorFromService,
      highCholesterolIndicator: highCholesterolIndicatorFromService, glaucomaIndicator: glaucomaIndicatorFromService,
      ageRelatedMacularDegenerationIndicator: ageRelatedMacularDegenerationIndicatorFromService, noConditionIndicator: noConditionIndicatorFromService
    } = activeClaimFromService.patientConditions;
    const {
      primaryCarePhysicianFormIndicator: primaryCarePhysicianFormIndicatorFromService, patientPaymentAmount: patientPaymentAmountFromService,
      cms1500Diagnoses: cms1500DiagnosesFromService, memberFsaAmount: memberFsaAmountFromService
    } = activeClaimFromService;

    if ((prediabetesIndicatorFromCard || undefined) !== (prediabetesIndicatorFromService || undefined) ||
      (diabetesIndicatorFromCard || undefined) !== (diabetesIndicatorFromService || undefined) ||
      (diabeticRetinopathyIndicatorFromCard || undefined) !== (diabeticRetinopathyIndicatorFromService || undefined) ||
      (hypertensionIndicatorFromCard || undefined) !== (hypertensionIndicatorFromService || undefined) ||
      (highCholesterolIndicatorFromCard || undefined) !== (highCholesterolIndicatorFromService || undefined) ||
      (glaucomaIndicatorFromCard || undefined) !== (glaucomaIndicatorFromService || undefined) ||
      (ageRelatedMacularDegenerationIndicatorFromCard || undefined) !== (ageRelatedMacularDegenerationIndicatorFromService || undefined) ||
      (noConditionIndicatorFromCard || undefined) !== (noConditionIndicatorFromService || undefined) ||
      (primaryCarePhysicianFormIndicatorFromCard) !== (primaryCarePhysicianFormIndicatorFromService) ||
      (toDollarAmount(patientPaymentAmountFromCard) || undefined) !== (toDollarAmount(patientPaymentAmountFromService) || undefined) ||
      (memberFsaAmountFromCard || undefined) !== (memberFsaAmountFromService || undefined)) {
      return true;
    }

    const numOfDiagnosisFromCard = cms1500DiagnosesFromCard.length;
    if (numOfDiagnosisFromCard !== cms1500DiagnosesFromService.length) {
      return true;
    }
    for (let i = 0; i < numOfDiagnosisFromCard; i++) {
      if (cms1500DiagnosesFromCard[i].diagnosisCode !== cms1500DiagnosesFromService[i].diagnosisCode) {
        return true;
      }
    }

    const serviceLinesFromCardWithCptHcpcCodes = this.activeClaim.serviceLines.filter((serviceLine) => !isStringNullUndefinedOrEmpty(serviceLine.cptHcpcsCode));
    const numOfServiceLinesFromCard = serviceLinesFromCardWithCptHcpcCodes.length;
    const serviceLinesFromServiceWithCptHcpcCodes = activeClaimFromService.serviceLines.filter((serviceLine) => !isStringNullUndefinedOrEmpty(serviceLine.cptHcpcsCode));
    if (serviceLinesFromCardWithCptHcpcCodes.length !== serviceLinesFromServiceWithCptHcpcCodes.length) {
      return true;
    }
    // filter list of service codes per service line
    for (let i = 0; i < numOfServiceLinesFromCard; i++) {
      const {
        serviceStartDate: serviceStartDateFromCard, serviceEndDate: serviceEndDateFromCard, placeOfServiceCode: placeOfServiceCodeFromCard,
        lineNumber: lineNumberFromCard, diagnosisPointers: diagnosisPointersFromCard, billedAmount: billedAmountFromCard,
        otherInsuranceAllowedAmount: otherInsuranceAllowedAmountFromCard, otherInsurancePaidAmount: otherInsurancePaidAmountFromCard,
        otherInsurancePatientPaidAmount: otherInsurancePatientPaidAmountFromCard, otherInsuranceDeniedNotCoveredReason: otherInsuranceDeniedNotCoveredReasonFromCard,
        cptHcpcsCode: cptHcpcsCodeFromCard, epsdtIndicator: epsdtIndicatorFromCard, emergencyIndicator: emergencyIndicatorFromCard,
        modifierCodes: modifierCodesFromCard, unitCount: unitCountFromCard,
        serviceLineValidationMessages: serviceLineValidationMessagesFromCard
      } = serviceLinesFromCardWithCptHcpcCodes[i];
      const serviceStartDateFromCardString = DateUtility.buildFriendlyDateFromJsDate(serviceStartDateFromCard);
      const serviceEndDateFromCardString = DateUtility.buildFriendlyDateFromJsDate(serviceEndDateFromCard);

      const {
        serviceStartDate: serviceStartDateFromService, serviceEndDate: serviceEndDateFromService, placeOfServiceCode: placeOfServiceCodeFromService,
        lineNumber: lineNumberFromService, diagnosisPointers: diagnosisPointersFromService, billedAmount: billedAmountFromService,
        otherInsuranceAllowedAmount: otherInsuranceAllowedAmountFromService, otherInsurancePaidAmount: otherInsurancePaidAmountFromService,
        otherInsurancePatientPaidAmount: otherInsurancePatientPaidAmountFromService, otherInsuranceDeniedNotCoveredReason: otherInsuranceDeniedNotCoveredReasonFromService,
        cptHcpcsCode: cptHcpcsCodeFromService, epsdtIndicator: epsdtIndicatorFromService, emergencyIndicator: emergencyIndicatorFromService,
        modifierCodes: modifierCodesFromService, unitCount: unitCountFromService,
        serviceLineValidationMessages: serviceLineValidationMessagesFromService
      } = serviceLinesFromServiceWithCptHcpcCodes[i];
      const serviceStartDateFromServiceString = DateUtility.buildFriendlyDateFromJsDate(serviceStartDateFromService);
      const serviceEndDateFromServiceString = DateUtility.buildFriendlyDateFromJsDate(serviceEndDateFromService);

      if ((serviceStartDateFromCardString || undefined) !== (serviceStartDateFromServiceString || undefined) ||
        (serviceEndDateFromCardString || undefined) !== (serviceEndDateFromServiceString || undefined) ||
        (placeOfServiceCodeFromCard || undefined) !== (placeOfServiceCodeFromService || undefined) ||
        (lineNumberFromCard || undefined) !== (lineNumberFromService || undefined) ||
        (toDollarAmount(billedAmountFromCard) || undefined) !== (toDollarAmount(billedAmountFromService) || undefined) ||
        (toDollarAmount(otherInsuranceAllowedAmountFromCard) || undefined) !== (toDollarAmount(otherInsuranceAllowedAmountFromService) || undefined) ||
        (toDollarAmount(otherInsurancePaidAmountFromCard) || undefined) !== (toDollarAmount(otherInsurancePaidAmountFromService) || undefined) ||
        (toDollarAmount(otherInsurancePatientPaidAmountFromCard) || undefined) !== (toDollarAmount(otherInsurancePatientPaidAmountFromService) || undefined) ||
        (otherInsuranceDeniedNotCoveredReasonFromCard || undefined) !== (otherInsuranceDeniedNotCoveredReasonFromService || undefined) ||
        (cptHcpcsCodeFromCard || undefined) !== (cptHcpcsCodeFromService || undefined) ||
        (epsdtIndicatorFromCard || undefined) !== (epsdtIndicatorFromService || undefined) ||
        (emergencyIndicatorFromCard || undefined) !== (emergencyIndicatorFromService || undefined) ||
        (unitCountFromCard || undefined) !== (unitCountFromService || undefined) ||
        (serviceLineValidationMessagesFromCard || undefined) !== (serviceLineValidationMessagesFromService || undefined)) {
        return true;
      }

      const numOfDiagnosisPointersFromCard = (isNullOrUndefined(diagnosisPointersFromCard)) ? 0 : diagnosisPointersFromCard.length;
      const numOfDiagnosisPointersFromService = (isNullOrUndefined(diagnosisPointersFromService)) ? 0 : diagnosisPointersFromService.length;
      if (numOfDiagnosisPointersFromCard !== numOfDiagnosisPointersFromService) {
        return true;
      }
      for (let j = 0; j < numOfDiagnosisPointersFromCard; j++) {
        if (diagnosisPointersFromCard[j] !== diagnosisPointersFromService[j]) {
          return true;
        }
      }

      const numOfModifierCodesFromCard = (isNullOrUndefined(modifierCodesFromCard)) ? 0 : modifierCodesFromCard.length;
      const numOfModifierCodesFromService = (isNullOrUndefined(modifierCodesFromService)) ? 0 : modifierCodesFromService.length;
      if (numOfModifierCodesFromCard !== numOfModifierCodesFromService) {
        return true;
      }
      for (let j = 0; j < numOfModifierCodesFromCard; j++) {
        if (modifierCodesFromCard[j] !== modifierCodesFromService[j]) {
          return true;
        }
      }
    }
    return false;
  }

  /***** END - PRIVATE FUNCTIONS *****/

  /***** START - LIFECYCLE HOOKS *****/
  ngOnInit() {
    this.isInitialPageLoad = true;
    this.registerWithClaimProgressService();
    this.originalClaim = this.claimsService.getOriginalClaim();
    this.activeClaim = this.claimsService.getOriginalClaim();
    this.isCobClaim = this.activeClaim.otherInsuranceIndicator ? true : false;
    this.serviceLineHtmlConstants = HtmlConstants.serviceLine(this.isCobClaim);
    // PE might send the fsaIndicator as a null or with value 'N', but returnBooleanFromFormState returns undefined for a null value
    this.fsaIndicator = returnBooleanFromFormState(this.activeClaim.clientFsaIndicator) === true;
    this.buildForm();
    this.buildErrorWrapperConfig();
    this.observableSubscriptions.push(this.claimsService.onCardsToUpdate.subscribe((onCardsToUpdate: ClaimCardsToUpdate) => {
      this.activeClaim = this.claimsService.getActiveClaim();
      if (onCardsToUpdate.services || onCardsToUpdate.all) {
        this.isCobClaim = this.activeClaim.otherInsuranceIndicator ? true : false;
        if (this.activeClaim) {
          this.setViewModelFromDataModel(this.activeClaim, onCardsToUpdate);
        }
        if (this.activeClaim.dateOfService) {
          const formDate = this.servicesForm.controls.serviceLines['controls'][0].controls.serviceStartDate.value;
          const claimDate = DateUtility.buildFriendlyDateFromJsDate(this.activeClaim.dateOfService);
          if (formDate !== claimDate) {
            this.updateDateOfService();
          }
        }

        // Reassign the reference to the static constants
        this.serviceLineHtmlConstants = HtmlConstants.serviceLine(this.isCobClaim);

        // Reset each COB field if the user toggled the field 11d from the insured field
        this.updateCobFormControlsOnButtonToggle();
        this.changeDetector.detectChanges();

        if (!this.isInitialPageLoad) {
          this.setTabIndexAttribute(this.disabledForm);
        }
      }
    }));

    this.disabledForm = this.servicesForm.controls.pcpCommunicationCompleted.disabled;

    this.setTabIndexAttribute(this.disabledForm);

    this.observableSubscriptions.push(this.claimsService.onSuccessfulCalculate.subscribe((successfulCalculate) => {
      if (successfulCalculate) {
        this.successfulCalculateComplete = successfulCalculate;
        this.claimsService.onSuccessfulCalculate.next(false);
      }
    }));

    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      if (mask) {
        this.servicesForm.disable(ApplicationConstants.updateFormWithoutEmit);
        this.disabledForm = true;
        this.setTabIndexAttribute(true);
        this.componentMask.show();
      } else {
        this.enableFieldsOnUnmask();
        this.disabledForm = false;
        this.setTabIndexAttribute(false);
        this.componentMask.hide();
      }
    }));

    // Push the calculated total as the last subscription onto the array so we can manage it during rebuilding the service lines
    this.observableSubscriptions.push(this.addServiceLineEventListenerToFormatCharges());

    // When submit finds UI edits detect changes on component
    this.observableSubscriptions.push(this.viewStateService.onSubmitFoundUIEdits.subscribe((hasUIEdits: boolean) => {
      if (hasUIEdits) {
        this.changeDetector.detectChanges();
      }
    }));

    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
          (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }

    }));
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.isInitialPageLoad = false;
  }

  /***** END - LIFECYCLE HOOKS *****/

  /***** START - PUBLIC FUNCTIONS *****/

  public buildForm(): void {
    let prediabetesIndicator, diabetesIndicator, diabeticRetinopathyIndicator, hypertensionIndicator,
      highCholesterolIndicator, noConditionIndicator, glaucomaIndicator, ageRelatedMacularDegenerationIndicator;
    if (this.originalClaim.patientConditions) {
      ({
        prediabetesIndicator,
        diabetesIndicator,
        diabeticRetinopathyIndicator,
        hypertensionIndicator,
        highCholesterolIndicator,
        glaucomaIndicator,
        ageRelatedMacularDegenerationIndicator,
        noConditionIndicator
      } = this.originalClaim.patientConditions);
    }
    // If checkboxes are not undefined then get a corresponding Yes, No value for it.
    const prediabetes = prediabetesIndicator;
    const diabetes = diabetesIndicator;
    const diabeticRetinopathy = diabeticRetinopathyIndicator;
    const hypertension = hypertensionIndicator;
    const highCholesterol = highCholesterolIndicator;
    const glaucoma = glaucomaIndicator;
    const ageRelatedMacularDegeneration = ageRelatedMacularDegenerationIndicator;
    const noCondition = noConditionIndicator;
    const disableNone = (isChecked(prediabetes) || isChecked(diabetes) || isChecked(diabeticRetinopathy) || isChecked(hypertension) || isChecked(highCholesterol));

    let pcpCompletedIndicator: string;
    if (this.originalClaim) {
      pcpCompletedIndicator = this.getPCPIndicatorFromDataModel(this.originalClaim.primaryCarePhysicianFormIndicator);
    }
    const pcpCompletedValue = pcpCompletedIndicator;

    this.servicesForm = this.formBuilder.group({
      diagnosisCodes: this.initializeDiagnosisCodes(),
      serviceLines: this.initializeServiceLines(),
      fsaPaid: [(!isNullOrUndefined(this.originalClaim.memberFsaAmount) ? this.originalClaim.memberFsaAmount : undefined), [ServicesValidators.dollarAmountRequired, ServicesValidators.dollarAmount, ServicesValidators.MaxDollarAmount]],
      totalAmount: [{value: '', disabled: true}],
      patientPaymentAmount: [(!isNullOrUndefined(this.originalClaim.patientPaymentAmount) ? this.originalClaim.patientPaymentAmount : undefined), [ServicesValidators.dollarAmountRequired, ServicesValidators.dollarAmount, ServicesValidators.MaxDollarAmount]],
      totalDue: [{value: '', disabled: true}],
      pcpCommunicationCompleted: pcpCompletedValue,
      prediabetesIndicator: prediabetes,
      diabetesIndicator: diabetes,
      diabeticRetinopathyIndicator: diabeticRetinopathy,
      hypertensionIndicator: hypertension,
      highCholesterolIndicator: highCholesterol,
      glaucomaIndicator: glaucoma,
      ageRelatedMacularDegenerationIndicator: ageRelatedMacularDegeneration,
      patientConditionNone: [{value: noCondition, disabled: disableNone}]
    }, {validator: [ServicesValidators.diagnosisCodeValidator, ServicesValidators.serviceLineRequired]});

    this.calculateTotalCharges();

    // Format dollar amounts
    this.twoDecimalPlaceFormat(this.servicesForm.get(this.FSA_PAID));
    this.twoDecimalPlaceFormat(this.servicesForm.get(this.TOTAL_AMOUNT));
    this.twoDecimalPlaceFormat(this.servicesForm.get(this.PATIENT_PAYMENT_AMOUNT));
    this.twoDecimalPlaceFormat(this.servicesForm.get(this.TOTAL_DUE));

    this.observableSubscriptions.push(this.servicesForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged())
      .subscribe(() => {
        this.updateDataModelFromViewModel();
        this.changeDetector.detectChanges(); // needed to update the service lines so validation dependence between services and service lines occurs at the right time
      }));

    this.observableSubscriptions.push(this.servicesForm.controls.diagnosisCodes.valueChanges.subscribe(() => {
      this.detectChangesForServiceLines = !this.detectChangesForServiceLines;
    }));

    this.servicesForm.get(ServicesConstants.PATIENT_PAYMENT_AMOUNT).valueChanges.subscribe((changed) => {
      this.calculateTotalDue(changed);
    });

    this.servicesForm.get(ServicesConstants.FSA_PAID).valueChanges.subscribe((changed) => {
      this.fsaPaidValueUpdated = this.servicesForm.value[ServicesConstants.FSA_PAID] !== changed;
    });
  }

  public updateDateOfService() {
    const formArray: FormArray = <FormArray>this.servicesForm.get(ServicesConstants.SERVICE_LINES);
    formArray.controls.forEach((serviceLine) => {
      serviceLine.get(ServicesConstants.SERVICE_START_DATE).setValue(DateUtility.buildFriendlyDateFromJsDate(this.activeClaim.dateOfService));
      serviceLine.get(ServicesConstants.SERVICE_END_DATE).setValue(DateUtility.buildFriendlyDateFromJsDate(this.activeClaim.dateOfService));
    });
  }

  public calculateTotalCharges(updatedServiceLines?: any) {
    const serviceLines = updatedServiceLines ? updatedServiceLines : this.servicesForm.get(ServicesConstants.SERVICE_LINES).value;
    let totalAmount: number = 0.00;

    for (let i = 0; i < serviceLines.length; i++) {
      const amount = serviceLines[i].billedAmount;
      if (amount && !Number.isNaN(Number(amount))) {
        const dollarAmount = parseFloat(amount);
        totalAmount += dollarAmount;
      }
    }
    totalAmount = parseFloat(totalAmount.toFixed(2));
    const currentValue = this.servicesForm.get(ServicesConstants.TOTAL_AMOUNT).value;
    if (currentValue !== totalAmount) {
      this.servicesForm.get(ServicesConstants.TOTAL_AMOUNT).setValue(totalAmount);
      this.twoDecimalPlaceFormat(this.servicesForm.get(ServicesConstants.TOTAL_AMOUNT));
      this.calculateTotalDue();
    }
  }

  public calculateTotalDue(updatedPatientPaidAmount?: any) {
    const patientPaidAmountStr = updatedPatientPaidAmount ? updatedPatientPaidAmount : this.servicesForm.get(ServicesConstants.PATIENT_PAYMENT_AMOUNT).value;
    const totalAmount = this.servicesForm.get(ServicesConstants.TOTAL_AMOUNT).value;
    const patientPaidAmount = patientPaidAmountStr;
    let patientPaidValidAmount: number = 0;
    if ((patientPaidAmount && !Number.isNaN(Number(patientPaidAmount))) && !isDollarAmountEmptyOrNull(totalAmount)) {
      patientPaidValidAmount = parseFloat(patientPaidAmount);
    }
    const totalDue: number = parseFloat(totalAmount) - patientPaidValidAmount;
    const roundedTotalDue: number = Math.round(totalDue * 100) / 100; // Scaling to two decimal places
    const currentValue = this.servicesForm.get(ServicesConstants.TOTAL_DUE).value;
    if (!Number.isNaN(Number(roundedTotalDue)) && currentValue !== roundedTotalDue) {
      this.servicesForm.get(ServicesConstants.TOTAL_DUE).setValue(roundedTotalDue, ApplicationConstants.updateFormWithoutEmit);
      this.twoDecimalPlaceFormat(this.servicesForm.get(ServicesConstants.TOTAL_DUE));
    }
  }

  public updateDataModelFromViewModel() {
    this.activeClaim.patientConditions = (isNullOrUndefined(this.activeClaim.patientConditions)) ? {} as PatientConditions : this.activeClaim.patientConditions;
    this.activeClaim.patientConditions.prediabetesIndicator = this.servicesForm.controls.prediabetesIndicator.value;
    this.activeClaim.patientConditions.diabetesIndicator = this.servicesForm.controls.diabetesIndicator.value;
    this.activeClaim.patientConditions.diabeticRetinopathyIndicator = this.servicesForm.controls.diabeticRetinopathyIndicator.value;
    this.activeClaim.patientConditions.hypertensionIndicator = this.servicesForm.controls.hypertensionIndicator.value;
    this.activeClaim.patientConditions.highCholesterolIndicator = this.servicesForm.controls.highCholesterolIndicator.value;
    this.activeClaim.patientConditions.glaucomaIndicator = this.servicesForm.controls.glaucomaIndicator.value;
    this.activeClaim.patientConditions.ageRelatedMacularDegenerationIndicator = this.servicesForm.controls.ageRelatedMacularDegenerationIndicator.value;
    this.activeClaim.patientConditions.noConditionIndicator = this.servicesForm.controls.patientConditionNone.value;
    this.activeClaim.primaryCarePhysicianFormIndicator = this.getPCPIndicatorFromSelection(this.servicesForm.controls.pcpCommunicationCompleted.value);
    this.activeClaim.patientPaymentAmount = parseFloat(this.servicesForm.get(ServicesConstants.PATIENT_PAYMENT_AMOUNT).value);
    this.activeClaim.cms1500Diagnoses = this.servicesForm.get(ServicesConstants.DIAGNOSIS_CODES).value.filter((diagnosisCode) => !isStringNullUndefinedOrEmpty(diagnosisCode.diagnosisCode));
    this.dataMarshallService.formatDiagnosisCodes(this.activeClaim);
    this.activeClaim.memberFsaAmount = this.fsaIndicator ? this.servicesForm.get(ServicesConstants.FSA_PAID).value : undefined;
    const serviceLines = <FormArray>this.servicesForm.get(ServicesConstants.SERVICE_LINES);
    const finalServiceLines: ServiceLine[] = [];
    const numOfServiceLines = serviceLines.controls.length;
    let arrayIndex = 0;
    for (let i = 0; i < numOfServiceLines; i++) {
      arrayIndex++;
      const serviceLineForm = <FormGroup>serviceLines.controls[i];
      const serviceLine = serviceLineForm.getRawValue();
      const finalServiceLine: ServiceLine = {
        serviceStartDate: serviceLine.serviceStartDate,
        serviceEndDate: serviceLine.serviceEndDate,
        placeOfServiceCode: serviceLine.placeOfServiceCode,
        lineNumber: serviceLine.lineNumber
      };
      if (!allStringArrayElementsEmpty(serviceLine.diagnosisPointers)) {
        finalServiceLine.diagnosisPointers = serviceLine.diagnosisPointers.filter((diagnosisCode) => !isStringNullUndefinedOrEmpty(diagnosisCode));
        finalServiceLine.diagnosisPointers = finalServiceLine.diagnosisPointers.map((diagnosisCode) => diagnosisCode.toUpperCase());
      }
      if (!isStringNullUndefinedOrEmpty(serviceLine.billedAmount)) {
        finalServiceLine.billedAmount = parseFloat(serviceLine.billedAmount);
      }
      if (this.isCobClaim && !isStringNullUndefinedOrEmpty(serviceLine.otherInsuranceAllowedAmount)) {
        finalServiceLine.otherInsuranceAllowedAmount = parseFloat(serviceLine.otherInsuranceAllowedAmount);
      }
      if (this.isCobClaim && !isStringNullUndefinedOrEmpty(serviceLine.otherInsurancePaidAmount)) {
        finalServiceLine.otherInsurancePaidAmount = parseFloat(serviceLine.otherInsurancePaidAmount);
      }
      if (this.isCobClaim && !isStringNullUndefinedOrEmpty(serviceLine.otherInsurancePatientPaidAmount)) {
        finalServiceLine.otherInsurancePatientPaidAmount = parseFloat(serviceLine.otherInsurancePatientPaidAmount);
      }
      if (this.isCobClaim && serviceLine.otherInsuranceDeniedNotCoveredReason) {
        finalServiceLine.otherInsuranceDeniedNotCoveredReason = serviceLine.otherInsuranceDeniedNotCoveredReason;
      }
      if (serviceLine.cptHcpcsCode) {
        finalServiceLine.cptHcpcsCode = serviceLine.cptHcpcsCode;
      }
      if (serviceLine.epsdtIndicator) {
        finalServiceLine.epsdtIndicator = serviceLine.epsdtIndicator;
      }
      if (serviceLine.emergencyIndicator) {
        finalServiceLine.emergencyIndicator = serviceLine.emergencyIndicator;
      }
      if (serviceLine.modifierCodes) {
        finalServiceLine.modifierCodes = serviceLine.modifierCodes.split(',');
      }
      if (serviceLine.unitCount) {
        finalServiceLine.unitCount = serviceLine.unitCount;
      }
      if (this.activeClaim.serviceLines && this.activeClaim.serviceLines.length > 0) {
        this.activeClaim.serviceLines.forEach(activeClaimServiceLine => {
          if (activeClaimServiceLine.cptHcpcsCode === serviceLine.cptHcpcsCode) {
            finalServiceLine.serviceLineValidationMessages = activeClaimServiceLine.serviceLineValidationMessages;
          }
        });
      }
      finalServiceLines.push(finalServiceLine);
    }
    this.activeClaim.serviceLines = finalServiceLines;
    // Update the active claim in the claim service
    if (this.servicesCardHasChanged()) {
      this.claimsService.setActiveClaim(this.activeClaim, this.id);
    }
  }

  public createDiagnosisCodeFormGroup(diagnosisCode: UIFormattedDiagnosis): FormGroup {
    return new FormGroup({
      position: new FormControl(diagnosisCode.position),
      diagnosisCode: new FormControl(diagnosisCode.diagnosisCode, Validators.pattern(/^[a-zA-Z0-9.]*$/i))
    });
  }

  public createServiceLinesFormGroup(serviceLine: ServiceLine): FormGroup {
    const startDate: string = DateUtility.buildFriendlyDateFromJsDate(serviceLine.serviceStartDate);
    const endDate: string = DateUtility.buildFriendlyDateFromJsDate(serviceLine.serviceEndDate);

    // Initialize the COB control values, default to empty if not a COB claim
    const otherInsuranceAllowedValue: number | string = this.isCobClaim ? (serviceLine.otherInsuranceAllowedAmount ? serviceLine.otherInsuranceAllowedAmount : null) : null;
    const otherInsurancePaidValue: number | string = this.isCobClaim ? (serviceLine.otherInsurancePaidAmount ? serviceLine.otherInsurancePaidAmount : null) : null;
    const otherInsurancePatientPaidValue: number | string = this.isCobClaim ? (serviceLine.otherInsurancePatientPaidAmount ? serviceLine.otherInsurancePatientPaidAmount : null) : null;
    const otherInsuranceDeniedNotCoveredReasonValue: string = this.isCobClaim ? serviceLine.otherInsuranceDeniedNotCoveredReason : null;

    const cobValues: any[] = [
      otherInsuranceAllowedValue,
      otherInsurancePaidValue,
      otherInsurancePatientPaidValue,
      otherInsuranceDeniedNotCoveredReasonValue
    ];

    let billedAmountValidators: ValidatorFn[] = CobValidators.billedAmount;
    let cptHcpcValidators: ValidatorFn[] = CobValidators.cptHcpcCode;
    let unitCountValidators: ValidatorFn[] = CobValidators.unitCount;

    if (this.isCobClaimWithValidHcpcCode(serviceLine.cptHcpcsCode as string) && cobValues.some((value: any) => !isStringNullUndefinedOrEmpty(value as string))) {
      billedAmountValidators = CobValidators.billedAmountRequired;
      cptHcpcValidators = CobValidators.cptHcpcCodeRequired;
      unitCountValidators = CobValidators.unitCountRequired;
    }

    const serviceLineFormGroup: FormGroup = new FormGroup({
      serviceStartDate: new FormControl({value: startDate, disabled: true}),
      serviceEndDate: new FormControl({value: endDate, disabled: true}),
      placeOfServiceCode: new FormControl({value: serviceLine.placeOfServiceCode, disabled: true}),
      emergencyIndicator: new FormControl(serviceLine.emergencyIndicator),
      cptHcpcsCode: new FormControl(serviceLine.cptHcpcsCode, cptHcpcValidators),
      modifierCodes: new FormControl(serviceLine.modifierCodes ? serviceLine.modifierCodes.toString() : null, [ServicesValidators.modifierCodesLengthValidator, ServicesValidators.modifierCodesValidator]),
      diagnosisPointers: this.formBuilder.array(this.initializeDiagnosisPointers(serviceLine.diagnosisPointers || [])),
      billedAmount: new FormControl(!isNullOrUndefined(serviceLine.billedAmount) ? toDollarAmount(serviceLine.billedAmount) : undefined, billedAmountValidators),
      unitCount: new FormControl(serviceLine.unitCount, unitCountValidators),
      epsdtIndicator: new FormControl(serviceLine.epsdtIndicator),
      lineNumber: new FormControl(serviceLine.lineNumber),
      otherInsuranceAllowedAmount: new FormControl({
        value: otherInsuranceAllowedValue,
        disabled: !this.isCobClaimWithValidHcpcCode(serviceLine.cptHcpcsCode)
      }, [Validators.required, ServicesValidators.MaxDollarAmount, ServicesValidators.dollarAmount]),
      otherInsurancePaidAmount: new FormControl({
        value: otherInsurancePaidValue,
        disabled: !this.isCobClaimWithValidHcpcCode(serviceLine.cptHcpcsCode)
      }, [Validators.required, ServicesValidators.MaxDollarAmount, ServicesValidators.dollarAmount]),
      otherInsurancePatientPaidAmount: new FormControl({
        value: otherInsurancePatientPaidValue,
        disabled: !this.isCobClaimWithValidHcpcCode(serviceLine.cptHcpcsCode)
      }, [Validators.required, ServicesValidators.MaxDollarAmount, ServicesValidators.dollarAmount]),
      otherInsuranceDeniedNotCoveredReason: new FormControl({
        value: otherInsuranceDeniedNotCoveredReasonValue,
        disabled: !this.isCobClaimWithValidHcpcCode(serviceLine.cptHcpcsCode)
      })
    });

    // Set the denied or paid validator
    const otherInsurancePaidAmountControl: AbstractControl = serviceLineFormGroup.get(this.OTHER_INSURANCE_PAID_AMOUNT);
    ServicesValidators.deniedOrPaidReasonConditionallyRequired(serviceLineFormGroup);
    otherInsurancePaidAmountControl.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);

    return serviceLineFormGroup;
  }

  public initializeDiagnosisPointers(APIdiagnosisPointers: string[]) {
    // If the API returns < four diagnosis codes, initialize the remainder with empty diagnosis codes
    const diagnosisPointers = APIdiagnosisPointers;
    if (diagnosisPointers && diagnosisPointers.length < 4) {
      for (let index = diagnosisPointers.length; index < 4; index++) {
        diagnosisPointers.push('');
      }
    }
    return diagnosisPointers;
  }

  public knownConditionsNoneLabelDisable(): boolean {
    return this.servicesForm.controls.patientConditionNone.status === 'DISABLED';
  }

  public knownConditionsLabelDisable(): boolean {
    return isChecked(this.servicesForm.controls.patientConditionNone.value);
  }

  public addMoreDiagnosisCodes(): void {
    const diagnosisCodesFormArray = <FormArray>this.servicesForm.get(this.DIAGNOSIS_CODES);
    for (let i = diagnosisCodesFormArray.value.length; i < ServicesConstants.MAX_DIAGNOSIS_CODES; i++) {
      const diagnosisCode: UIFormattedDiagnosis = {
        position: this.dataMarshallService.getDiagnosisCodeLabelFromIndex(i),
        diagnosisCode: ''
      };
      diagnosisCodesFormArray.push(this.createDiagnosisCodeFormGroup(diagnosisCode));
    }
    this.addDiagnosisCodes = false;
  }

  public allowAddServiceLines(): boolean {
    return this.servicesForm.get(this.SERVICE_LINES).value.length < ServicesConstants.MAX_SERVICE_LINES;
  }

  public addMoreServiceLines(): void {
    const maxNumberOfLinesToAdd = ServicesConstants.MAX_SERVICE_LINES - this.servicesForm.get(this.SERVICE_LINES).value.length;
    const linesToAdd = maxNumberOfLinesToAdd < 3 ? maxNumberOfLinesToAdd : 3;

    for (let i = 0; i < linesToAdd; i++) {
      this.addServiceLine();
    }
  }

  public addServiceLine() {
    const newServiceLine = {
      ...this.initializeServiceLineFormControlsFromExistingLines(''),
      lineNumber: this.servicesForm.get(this.SERVICE_LINES).value.length + 1
    };
    const formArray = <FormArray>this.servicesForm.get(this.SERVICE_LINES);
    formArray.push(this.createServiceLinesFormGroup(newServiceLine));
  }

  public addNewServiceLine(cptHcpcsCode: string, serviceLine?: ServiceLine): void {
    const additionalServiceLine = isNullOrUndefined(serviceLine) ? this.initializeServiceLineFormControlsFromExistingLines(cptHcpcsCode) : serviceLine;
    if (this.allowAddServiceLines()) {
      const formArray = <FormArray>this.servicesForm.get(this.SERVICE_LINES);
      formArray.push(this.createServiceLinesFormGroup(additionalServiceLine));
    } else {
      this.openServiceLineExceededSnackBar();
    }
  }

  public onProcServiceLineIndexUpdated(index: number): void {
    this.lastSelectedProcServiceLineIndex = index;
  }

  public showHcpcCodesModal(): void {
    const lastSelectedProcServiceLineIndex: number = this.lastSelectedProcServiceLineIndex;
    event.preventDefault();
    const dialogRef = this.dialog.open(HcpcCptCodesModalComponent, {panelClass: 'sticky-footer-popup-modal'});
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const serviceLines: FormArray = <FormArray>this.servicesForm.get(this.SERVICE_LINES);
        // If a PROC field was selected before the dialog box was opened, update that PROC field with selected hcpc code
        if (lastSelectedProcServiceLineIndex != null && lastSelectedProcServiceLineIndex >= 0) {
          serviceLines.controls[lastSelectedProcServiceLineIndex].get(this.CPT_HCPCS_CODE).setValue(data);
        } else {
          let firstEmptyProcIndex: number;
          // If there is an empty PROC field, update that PROC field with selected hcpc code
          for (let serviceLineIndex = 0; serviceLineIndex < serviceLines.value.length; serviceLineIndex++) {
            if (isStringNullUndefinedOrEmpty(serviceLines.controls[serviceLineIndex].get(this.CPT_HCPCS_CODE).value)) {
              firstEmptyProcIndex = serviceLineIndex;
              break;
            }
          }
          if (firstEmptyProcIndex >= 0) {
            serviceLines.controls[firstEmptyProcIndex].get(this.CPT_HCPCS_CODE).setValue(data);
          } else {
            // Add a new service line and update that PROC field with selected hcpc code
            this.addNewServiceLine(data);
          }
        }
      }
    });

  }

  public showHcpcCodesDescriptionsModal(): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    // TODO: uncomment this once we have the api hooked up
    // openDialog('Searching for existing codes...', this.dialog);
    this.inventoryService.SearchForServiceLinesHcpcAndCptCodes(this.activeClaim.serviceLines).subscribe((inventories: Inventories) => {
      // CLose loading spinner
      // TODO: uncomment this once we have the api hooked up
      // this.dialog.closeAll();
      if (!isNullOrUndefined(inventories) && !isNullOrUndefined(inventories.inventory) && isNullOrUndefined(this.messageService.currentErrorMessageString)) {
        this.dialog.open(HcpcCptCodesDescriptionsModalComponent, {
          width: '500px',
          panelClass: 'eclaim-popup-modal',
          disableClose: true,
          data: {
            inventories: inventories
          }
        });
      }
    }, () => {
      // on observer error
      // TODO: uncomment this once we have the api hooked up
      // this.dialog.closeAll();
    });

  }

  public openFsaExplanationModal(): void {
    event.preventDefault();
    this.dialog.open(FSAExplanationModalComponent, {
      panelClass: 'eclaim-popup-modal'
    });
  }

  public openServiceLineExceededSnackBar(): void {
    this.messageService.showErrorSnackbar(ServicesConstants.SERVICELINE_EXCEEDED_ERROR_MESSAGE);
  }

  onKnownConditionChange(): void {
    this.rebuildKnownConditions();
  }

  /**
   * Format dollar amounts on any blur event for the patient paid amount field and trigger the FSA modal.
   */
  onPaidAmountBlur(): void {
    // Trigger 10% modal and format the dollar amount
    twoDecimalPlaceFormat(this.servicesForm.get(this.PATIENT_PAYMENT_AMOUNT));
  }

  /**
   * Keydown reverse tab navigation event handler for patient FSA paid amount helper link, navigates the user to the charges field of the last service line on shift-tab.
   *
   * @return false - Returning false to the keydown event handler calls preventPropagation internally, preventing tab skipping
   */
  onFsaPaidAmountReverseTabNavigation(): boolean {
    // Focus on the last charges field if the user shift-tabs (i.e. targets EPSDT checkbox from paid amount)
    // Reference the the service line form array, break the UI thread if the service lines are unavailable
    const serviceLineFormArray: FormArray = this.servicesForm.get(this.SERVICE_LINES) as FormArray;
    if (isNullOrUndefined(serviceLineFormArray)) {
      return;
    }

    // Reference the last added form control, break the UI thread if unavailable
    const lastServiceLine: FormGroup = (serviceLineFormArray.controls && serviceLineFormArray.controls.length > 0) ? serviceLineFormArray.controls[serviceLineFormArray.controls.length - 1] as FormGroup : undefined;
    if (isNullOrUndefined(lastServiceLine) || isNullOrUndefined(lastServiceLine.get(this.BILLED_AMOUNT))) {
      return;
    }

    // Focus on the element, does not contain a unit test as the spy that listens for .focus() would not exist
    const lastBilledAmountInput: HTMLElement = lastServiceLine.get(this.BILLED_AMOUNT)['nativeElement'] as HTMLElement;
    if (lastBilledAmountInput) {
      lastBilledAmountInput.focus();
    }

    return false;
  }

  /**
   * Keydown reverse tab navigation event handler for patient paid amount field,
   * if FSA Paid field is not present or disabled, it navigates the user to the charges field of the last service line on shift-tab,
   * if FSA is pesent and enabled it navigates to FSA Paid field
   *
   * @return false - Returning false to the keydown event handler calls preventPropagation internally, preventing tab skipping
   */
  onPaidAmountReverseTabNavigation(): boolean {
    // Checks that the FSA Paid field is either hidden or disabled
    const fsaPaidElement: HTMLElement = this.servicesForm.get(this.FSA_PAID)['nativeElement'] as HTMLElement;
    if (isNullOrUndefined(fsaPaidElement) || fsaPaidElement['disabled']) {

      // Focus on the last charges field if the user shift-tabs (i.e. targets EPSDT checkbox from paid amount)
      // Reference the the service line form array, break the UI thread if the service lines are unavailable
      const serviceLineFormArray: FormArray = this.servicesForm.get(this.SERVICE_LINES) as FormArray;
      if (isNullOrUndefined(serviceLineFormArray)) {
        return;
      }

      // Reference the last added form control, break the UI thread if unavailable
      const lastServiceLine: FormGroup = (serviceLineFormArray.controls && serviceLineFormArray.controls.length > 0) ? serviceLineFormArray.controls[serviceLineFormArray.controls.length - 1] as FormGroup : undefined;
      if (isNullOrUndefined(lastServiceLine) || isNullOrUndefined(lastServiceLine.get(this.BILLED_AMOUNT))) {
        return;
      }

      // Focus on the element, does not contain a unit test as the spy that listens for .focus() would not exist
      const lastBilledAmountInput: HTMLElement = lastServiceLine.get(this.BILLED_AMOUNT)['nativeElement'] as HTMLElement;
      if (lastBilledAmountInput) {
        lastBilledAmountInput.focus();
      }

      return false;
    }
  }

  /**
   * Keydown tab navigation event handler for patient paid amount field, navigates the user to the unit count field of the first service line on shift-tab.
   *
   * @return false - Returning false to the keydown event handler calls preventPropagation internally, preventing tab skipping
   */
  onPaidAmountForwardTabNavigation(): boolean {
    // Focus the first units field if navigating forward
    const firstUnitCountElement: HTMLElement = document.getElementById(HtmlConstants.attributes.firstUnitCountInputId);
    if (firstUnitCountElement) {
      firstUnitCountElement.focus();
    }

    return false;
  }

  onCobFieldLinkClicked(cobField: string): void {
    // Should always be true if invoked, added protection for displaying the modal
    if (this.isCobClaim) {
      let headerToDisplay: string[];
      let messagesToDisplay: string[];

      switch (cobField) {
        case ServicesConstants.OTHER_INSURANCE_ALLOWED_AMOUNT:
          headerToDisplay = ServicesConstants.cobHelpMessages.otherInsuredAllowedAmountHeader;
          messagesToDisplay = ServicesConstants.cobHelpMessages.otherInsuranceAllowedAmount;
          break;

        case ServicesConstants.OTHER_INSURANCE_PAID_AMOUNT:
          headerToDisplay = ServicesConstants.cobHelpMessages.otherInsurancePaidAmountHeader;
          messagesToDisplay = ServicesConstants.cobHelpMessages.otherInsurancePaidAmount;
          break;

        case ServicesConstants.OTHER_INSURANCE_PATIENT_PAID_AMOUNT:
          headerToDisplay = ServicesConstants.cobHelpMessages.otherInsurancePatientPaidAmountHeader;
          messagesToDisplay = ServicesConstants.cobHelpMessages.otherInsurancePatientPaidAmount;
          break;

        default:
          headerToDisplay = ServicesConstants.cobHelpMessages.otherInsuranceDeniedNotCoveredReasonHeader;
          messagesToDisplay = ServicesConstants.cobHelpMessages.otherInsuranceDeniedNotCoveredReason;
          break;
      }

      // Open the modal based on dialog to display
      this.dialog.open(SimpleModalComponent, {
        data: {
          header: headerToDisplay,
          messages: messagesToDisplay
        } as SimpleModalDialogData,
        panelClass: 'eclaim-popup-modal',
      });
    }
  }

  openPCPForm(): void {
    this.drReportsNavigationService.navigateToPCPForm(this.activeClaim.trackingNumber);
  }

  onKeypressKnownConditionsCheckbox(control: AbstractControl): void {
    control.setValue(!control.value);
    this.rebuildKnownConditions();
  }

  /***** END - PUBLIC FUNCTIONS *****/

  /***** START - PRIVATE FUNCTIONS *****/

  /**
   * If the form is disabled, it makes the elements not TAB-able, else it adds the elements to the TAB
   *
   */
  setTabIndexAttribute(formDisabled: boolean) {
    if (formDisabled) {
      this.setTabIndexOfElementById('services-pcp-form-link', '-1');
      this.setTabIndexOfElementById('services-service-line-proc-link', '-1');
      if (this.isCobClaim) {
        this.setTabIndexOfElementById('services-service-line-other-insured-allowed-link', '-1');
        this.setTabIndexOfElementById('services-service-line-other-insured-paid-link', '-1');
        this.setTabIndexOfElementById('services-service-line-other-insured-patient-responsibility-link', '-1');
        this.setTabIndexOfElementById('services-service-line-denied-or-paid-link', '-1');
      }
      if (this.fsaIndicator) {
        this.setTabIndexOfElementById('services-fsa-paid-link', '-1');
      }
    } else {
      this.setTabIndexOfElementById('services-pcp-form-link', '0');
      this.setTabIndexOfElementById('services-service-line-proc-link', '0');
      if (this.isCobClaim) {
        this.setTabIndexOfElementById('services-service-line-other-insured-allowed-link', '0');
        this.setTabIndexOfElementById('services-service-line-other-insured-paid-link', '0');
        this.setTabIndexOfElementById('services-service-line-other-insured-patient-responsibility-link', '0');
        this.setTabIndexOfElementById('services-service-line-denied-or-paid-link', '0');
      }
      if (this.fsaIndicator) {
        this.setTabIndexOfElementById('services-fsa-paid-link', '0');
      }
    }
  }

  setTabIndexOfElementById(idOfElement: string, tabIndex: string) {
    const element: HTMLElement = document.getElementById(idOfElement);
    if (!isNullOrUndefined(element)) {
      element.setAttribute('tabindex', tabIndex);
    }
  }
}
