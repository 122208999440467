<div id="{{id}}">
  <mat-card class="container-white-full-column">
    <app-component-mask></app-component-mask>
    <!-- Main Form -->
    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings"  class="sticky-card-header" [ngClass]="{ 'disabled-card-header': dateOfServiceForm.disabled }">
      <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
        <h1 id="date-of-service-name">{{name}}</h1>
        <h2 id="date-of-service-auth-number" >Authorization #: {{authorization}}</h2>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits" [ngClass]="{ 'disabled-card-header': dateOfServiceForm.disabled }">
      <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
        <h1 id="date-of-service-name-edits">{{name}}</h1>
        <h2 id="date-of-service-auth-number-edits" >Authorization #: {{authorization}}</h2>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning" [ngClass]="{ 'disabled-card-header': dateOfServiceForm.disabled }">
      <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
        <h1 id="date-of-service-name-warning">{{name}}</h1>
        <h2 id="date-of-service-auth-number-warning" >Authorization #: {{authorization}}</h2>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form  [formGroup]="dateOfServiceForm" novalidate fxLayout="column">
        <!-- ############################################################################################################################################################################## -->
        <!-- First Row In Patient Name Card: "Field Labels" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-top-30 margin-bottom-15">
          <!-- Date of Service Label -->
          <label id="date-of-service-label" class="nested-column-3"><span class="required-label-asterisk">* </span>Date of Service</label>
          <!-- Select a Health Coverage Label -->
          <label id="select-a-health-coverage-label" class="nested-column-3"><span class="required-label-asterisk">* </span>Select a Health Coverage</label>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Second Row In Patient Name Card: "Field Inputs and Dropdown's" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30">
          <!-- Date of Service Input Field -->
          <app-date-picker class="required-mat-form-field nested-column-3" id="date-of-service-date-picker" [datePickerConfiguration]="dateOfServiceDatePickerConfig"></app-date-picker>
          <!-- Select a Health Coverage Dropdown Field -->
          <div fxLayout="column" class="nested-column-3">
            <select matSelect formControlName="healthCoverages" class="custom-select required-form-field" id="health-coverage-group">
              <option id="health-coverage-{{toSlugId(healthCoverageType.label)}}-option" *ngFor="let healthCoverageType of selectOptions.healthCoverages"
                      [value]="healthCoverageType.value">{{healthCoverageType.label}}</option>
            </select>
          </div>
        </div>
        <app-error-wrapper [config]="errorWrapperConfig.healthCoverages"></app-error-wrapper>
      </form>
    </mat-card-content>
  </mat-card>
</div>
