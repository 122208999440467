import { Injectable } from '@angular/core';
import { DataMarshallService } from '../../http/data-marshall/data-marshall.service';
import { Observable, of, Subscriber } from 'rxjs';
import { ApiFormattedClaim, Claim } from '../../../../../models/claim';
import { SupplierWebDataService } from '../../http/http-client-data/supplier-web/supplier-web-data.service';
import {LabOrderInformation, PatientEncounterLab} from '../../../../../models/labOrderInformation';
import { MessageService } from '../../../support/message/message.service';
import { ApplicationConstants } from '../../../../constants/application.constants';
import { LabConstants } from '../../../../../secure/claim-form/lab/lab.constants';
import {FulfillmentService} from '../fulfillment/fulfillment.service';
import {FulfillmentDataService} from '../../http/http-client-data/fulfillment-data/fulfillment-data.service';
import {UserSessionService} from '../../../support/user-session/user-session.service';
import {ParticipatingLabsRequest} from '../../../../../models/ParticipatingLabsRequest';
import {ParticipatingLabsResponse} from '../../../../../models/ParticipatingLabsResponse';
import {isNullOrUndefined, isStringNullUndefinedOrEmptyWithTrim} from '../../../../utility';

@Injectable({
  providedIn: 'root'
})
export class SupplierWebService {

  constructor(
    private dataMarshallService: DataMarshallService,
    private supplierWebDataService: SupplierWebDataService,
    private messageService: MessageService,
    private fulfillmentService: FulfillmentService,
    private fulfillmentDataService: FulfillmentDataService,
    private userSessionService: UserSessionService,
  ) {}

  /***** START - PRIVATE METHODS *****/
  private showErrorSnackBarAndCompleteRequest(observer: Subscriber<PatientEncounterLab[]>): Observable<PatientEncounterLab[]> {
    this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
    observer.next(undefined);
    observer.complete();
    return of(undefined);
  }
  /***** END - PRIVATE METHODS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  /**
   * Calls Patient Encounter with the received claim to update it with a list of valid labs in the response. PE will update the
   * received PE with a list of labs underneath PatientEncounter::LabOrderInformation::Labs.
   *
   * @param uiFormattedPatientEncounter
   */
  retrieveLabListFromCurrentClaim(uiFormattedPatientEncounter: Claim): Observable<PatientEncounterLab[]> {
    // Map the current patient encounter model to the API model
    const apiFormattedPatientEncounter: ApiFormattedClaim = this.dataMarshallService.formatClaimForApi(uiFormattedPatientEncounter);

    return new Observable<PatientEncounterLab[]>((observer: Subscriber<PatientEncounterLab[]>) => {
      this.supplierWebDataService.retrieveLabList(apiFormattedPatientEncounter).subscribe((patientEncounterWithLabList: ApiFormattedClaim) => {
        if (isNullOrUndefined(patientEncounterWithLabList) && this.messageService.currentErrorMessage === ApplicationConstants.errorMessages.failSafeLabList) {
          // If the current message is the fail safe lab message due to a 500 being return from PE (for supplier-web), hand back the VSP select labs
          observer.next(LabConstants.failSafeLabList);
        } else if (!isNullOrUndefined(patientEncounterWithLabList)) {
          // If PE returns, but with no lab order information, display the generic error message
          if (isNullOrUndefined(patientEncounterWithLabList.labOrderInformation)) {
            return this.showErrorSnackBarAndCompleteRequest(observer);
          }

          if (this.userSessionService.isKaleyedoscopePractice && this.fulfillmentService.participatesInKScope && this.isManufacturerPresent(patientEncounterWithLabList)) {
            const participatingLabsRequest = new ParticipatingLabsRequest();
            const processedLabOrderInformation: LabOrderInformation = patientEncounterWithLabList.labOrderInformation;
            participatingLabsRequest.setManufacturer = processedLabOrderInformation.frame.manufacturer.name;
            // build the lab list from the lab order.
            participatingLabsRequest.setExternalLabIds = this.buildExternalLabIds(processedLabOrderInformation);
            // Make the call go get the participating labs from the fulfillment api.
            this.fulfillmentDataService.getParticipatingLabs(participatingLabsRequest).subscribe((participatingLabsResponseList: ParticipatingLabsResponse) => {
              // Make sure the what we need to use to rebuild the LabOrderInformation is not null, undefined or empty.
              if (!isNullOrUndefined(processedLabOrderInformation) && !isNullOrUndefined(processedLabOrderInformation.labs) && processedLabOrderInformation.labs.length > 0
                && !isNullOrUndefined(participatingLabsResponseList) && !isNullOrUndefined(participatingLabsResponseList.labs) && participatingLabsResponseList.labs.length > 0) {
                for (const lab of processedLabOrderInformation.labs) {
                  for (const participatingLab of participatingLabsResponseList.labs) {
                    for (const externalLabId of participatingLabsRequest.getExternalLabIds) {
                      if ( externalLabId.indexOf(participatingLab.labid) >= 0 && lab.labId.indexOf(participatingLab.labid) >= 0 ) {
                        lab.labDescription = ' - ' + participatingLab.description;
                        break;
                      }
                    }
                  }
                }
              }
              // Reset the LabOrderInformation in the patient encounter with the processed LabOrderInformation.
              patientEncounterWithLabList.labOrderInformation = processedLabOrderInformation;
              // Map and emit the PE labs returned
              const uiFormattedUpdatedPatientEncounter: Claim = this.dataMarshallService.formatClaimForUi(patientEncounterWithLabList);
              observer.next(uiFormattedUpdatedPatientEncounter.labOrderInformation.labs);
              // Complete the closure
              observer.complete();
            });
          } else {
            // Map and emit the PE labs returned
            const uiFormattedUpdatedPatientEncounter: Claim = this.dataMarshallService.formatClaimForUi(patientEncounterWithLabList);
            observer.next(uiFormattedUpdatedPatientEncounter.labOrderInformation.labs);
            // Complete the closure
            observer.complete();
          }
        }
      });
    });
  }

  /**
   * Method validates if the manufacturer is present in the ApiFormattedClaim.
   *
   * @param apiFormattedPatientEncounter - ApiFormattedClaim we want to check to see if the manufacturer is present.
   */
  isManufacturerPresent(apiFormattedPatientEncounter: ApiFormattedClaim): boolean {
    return !isNullOrUndefined(apiFormattedPatientEncounter) && !isNullOrUndefined(apiFormattedPatientEncounter.labOrderInformation)
      && !isNullOrUndefined(apiFormattedPatientEncounter.labOrderInformation.frame) && !isNullOrUndefined(apiFormattedPatientEncounter.labOrderInformation.frame.manufacturer)
      && !isStringNullUndefinedOrEmptyWithTrim(apiFormattedPatientEncounter.labOrderInformation.frame.manufacturer.name);
  }

  /**
   * Method builds a list of labs using a LabOrderInformation object.
   *
   * @param labOrderInformation - LabOrderInformation we are using to build the lab list.
   */
  buildExternalLabIds(labOrderInformation: LabOrderInformation): string[] {
    const labIds = [];
    for (const lab of labOrderInformation.labs) {
      const id = lab.labId;
      labIds.push( id );
    }
    return labIds;
  }
  /***** END - PUBLIC FUNCTIONS *****/

}
