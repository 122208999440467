import {Injectable, Injector} from '@angular/core';
import {HttpClientService} from '../http-client-service';
import {ApplicationConstants} from '../../../../../constants/application.constants';
import {Observable, of} from 'rxjs';
import {Eligibility} from '../../../../../../models/eligibility';
import {catchError, map} from 'rxjs/operators';
import {getApiUrl, isNullOrUndefined} from '../../../../../utility';

@Injectable({
  providedIn: 'root'
})
export class EligibilityDataService extends HttpClientService {

  /***** START - PRIVATE MEMBERS *****/
  /***** END - PRIVATE MEMBERS *****/

  constructor(public injector: Injector) {
    super(injector, ApplicationConstants.dataServiceClientIDs.eligibilityDataService);
  }

  /***** START - PRIVATE METHODS *****/
  /***** START - PRIVATE METHODS *****/
  /***** START - PUBLIC METHODS *****/
  retrieveMemberEligibility(memberEligibilityLink: string): Observable<Eligibility> {
    memberEligibilityLink = getApiUrl(memberEligibilityLink, this.environment.eligibilityUrl);
    return this.http.get<Eligibility>(memberEligibilityLink, {headers: this.getHttpHeaders(ApplicationConstants.dataServiceClientIDs.eligibilityDataService)})
      .pipe(
        map((eligibilityResponse: Eligibility) => {
          // Return the generic failure message if response is null
          if (isNullOrUndefined(eligibilityResponse)) {
            console.log('retrieveMemberEligibility.... return undefined');
            return undefined;
          } else {
            return eligibilityResponse;
          }
        }),
        catchError((error: any) => {
          this.handleError('retrieveMemberEligibility', error);
          const errorMessage = this.getErrorMessage(error);
          this.messageService.showErrorSnackbar(`${errorMessage}`);
          return of(undefined);
        })
      );
  }
  /***** END - PUBLIC METHODS *****/
}
