<div fxLayout="column">

  <mat-card>
    <!-- Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 class="nested-column-11"
            id="confirmation-modal-header" mat-dialog-title>Replace Auth Confirmation</h1>
      </mat-card-title>

      <!-- Close Icon -->
      <button [disableRipple]="true" class="close-button" mat-icon-button>
        <mat-icon (click)="onCloseClick()" id="confirmation-modal-close-button"
                  svgIcon="eclaim-icons:close"></mat-icon>
      </button>
    </mat-card-header>

    <!-- Body -->
    <mat-card-content>
      <div class="margin-left-15 margin-top-15 margin-bottom-15">
        <p id="confirmation-modal-message-text">Authorization number will be replaced.{{data.authNumber}}</p>
        <p>Click OK to access the new authorization.</p>
      </div>
    </mat-card-content>

    <!-- Footer Line -->
    <hr/>

    <!-- OK/Cancel buttons  -->
    <mat-card-actions>
      <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="end center"
           class="margin-top-20 margin-bottom-20">

        <!-- OK Button  -->
        <button mat-dialog-close class="btn btn-primary btn-extra-pad" (click)="okClick()"
                id="confirmation-modal-ok-button">OK
        </button>
        <div fxFlexOffset="30px"></div>

        <!-- Cancel Button  -->
        <button mat-button color="primary" class="cancel-button margin-left-30"
                id="confirmation-modal-cancel-button" mat-dialog-close>Cancel
        </button>
        <div fxFlexOffset="20px"></div>
      </div>
    </mat-card-actions>

  </mat-card>

</div>
