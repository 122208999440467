export interface GiftCertificateSearchResponse {
  items: GiftCertificate[];
  totalItems: number;
}

export interface GiftCertificate {
  expirationDate: string;
  giftCertificateStatus: GiftCertificateStatusEnum;
  redeem: {
    href: string;
    type: string;
    rel: string;
  };
  vsrNumber: string;
}

export enum GiftCertificateStatusEnum {
  REDEEMED = 'REDEEMED',
  EXPIRED = 'EXPIRED',
  VALID = 'VALID',
  NOTFOUND = 'NOTFOUND'
}
export enum GiftCertificateErrorMessageEnum {
  REDEEMED = 'Gift Certificate has already been used.',
  EXPIRED = 'Gift Certificate is no longer valid.',
  NOTFOUND = 'Gift Certificate was not found.'
}


export enum GiftCertificateRedemptionErrorMessage {
  GC0001 = 'Unable to process a Gift Certificate at this time, please try again later.',
  GC0002 = 'VSP cannot process your request electronically for the following reason: No Gift Certificate found.',
  GC0003 = 'VSP cannot process your request electronically for the following reason: Unable to add member.',
  GC0004 = 'VSP cannot process your request electronically for the following reason: Unable to add member.',
  GC0005 = 'Unable to process a Gift Certificate at this time, please try again later.',
  GC0006 = 'VSP records indicate the patient has received services previously and is not eligible at this time.',
  GC0009 = 'VSP is unable to redeem this Gift Certificate:',
  GC0011 = 'The provider does not participate in any of the patient\'s benefits.',
  GC0012 = 'VSP is unable to redeem this Gift Certificate.',
  GC0013 = 'Unable to process a Gift Certificate at this time, please try again later.',
  GC0014 = 'Unable to process a Gift Certificate at this time, please try again later.'
}

export interface GiftCertificateRedemption {
  gcNumber: string;
  patientId: string;
  memberCovEffectiveStartDate: string;
  memberName: {
    firstName: string;
    lastName: string;
  };
  dateOfBirth: string;
}

export interface GiftCertificateRedemptionResponse {
  gcNumber: string;
  vsrNumber?: string;
  status?: number;
  error?: string;
}
