import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AbstractControl, FormControl } from '@angular/forms';
import { ApplicationConstants, ErrorTypes, PrimitiveType } from '../../constants/application.constants';
import { DateUtility } from '../../utility';
import { isNullOrUndefined, isStringNullUndefinedOrEmpty } from '../../utility';

export class DatePickerConfiguration {
  control: AbstractControl;
  controlName: string;
  errorWrapperId: {
    defaultValidations: string;
    minDate: string;
    maxDate?: string;
  };
  attributes?: {
    id?: string;
    datePickerId?: string;
    datePickerToggleId?: string;
    name?: string;
  };
  minDate?: Date;
  maxDate?: Date;
  customErrorMessages?: {
    validatorType: ErrorTypes;
    errorMessage: string;
  }[];
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent implements OnInit {

  /***** START - PRIVATE MEMBERS *****/
  private readonly _maxDateLength: number;
  private _maxDate: Date;
  private _minDate: Date;
  private _lastValidDate: string;
  /***** END - PRIVATE MEMBER *****/


  /***** START - PUBLIC MEMBERS *****/
  @Input() datePickerConfiguration: DatePickerConfiguration;
  @ViewChild('dateTextBox', {static: true}) dateTextBox: ElementRef;
  errorWrapperConfig = {};
  /***** END - PUBLIC MEMBER *****/


  /***** START - PROPERTY ACCESSORS *****/
  get minDate(): Date {
    return this._minDate;
  }

  set minDate(newDate: Date) {
    this._minDate = newDate;
  }

  get maxDate(): Date {
    return this._maxDate;
  }

  set maxDate(newDate: Date) {
    this._maxDate = newDate;
  }

  get maxDateLength(): number {
    return this._maxDateLength;
  }

  get lastValidDate(): string {
    return this._lastValidDate;
  }

  set lastValidDate(date: string) {
    this._lastValidDate = date;
  }

  get lastValidJsDate(): Date {
    return new Date(this._lastValidDate);
  }
  /***** END - PROPERTY ACCESSORS *****/

  constructor() {
    this._minDate = ApplicationConstants.errorMessageDisplayMinDate;
    this._maxDate = ApplicationConstants.todaysDate;
    this._maxDateLength = ApplicationConstants.maxDateLength;
  }

  /***** START - PRIVATE METHODS *****/

  /**
   * Build the error wrapper configuration with default validations.
   */
  private buildDefaultErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      date: {
        control: this.datePickerConfiguration.control,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a date'
        }, {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Date must be in MM/DD/YYYY format'
        }, {
          validatorType: ErrorTypes.InvalidDate,
          errorMessage: 'Not a valid calendar date'
        }, {
          validatorType: ErrorTypes.NoFutureDate,
          errorMessage: 'Date cannot be in the future'
        }]
      },
      minDate: {
        control: this.datePickerConfiguration.control,
        errors: [{
          validatorType: ErrorTypes.MinDate,
          errorMessage: `Date must be greater than ${DateUtility.buildFriendlyDateFromJsDate(this.minDate)}`
        }]
      },
      maxDate: {
        control: this.datePickerConfiguration.control,
        errors: [{
          validatorType: ErrorTypes.MaxDate,
          errorMessage: `Date must be before or equal to ${DateUtility.buildFriendlyDateFromJsDate(this.maxDate)}`
        }]
      }
    };
  }

  /**
   * Override any error validations passed in from the date picker configuration.
   */
  private overrideDefaultErrorMessages(): void {
    // Grab the error validations to override passed in from the configuration
    const customErrorMessages: any[] = this.datePickerConfiguration.customErrorMessages;
    const defaultDateErrorWrapperConfigValidations: any[] = this.errorWrapperConfig['date']['errors'];
    const defaultMinDateErrorWrapperConfigValidation: any[] = this.errorWrapperConfig['minDate']['errors'];
    const defaultMaxDateErrorWrapperConfigValidation: any[] = this.errorWrapperConfig['maxDate']['errors'];

    // For each custom validation passed in, override the default configuration
    customErrorMessages.forEach((errorValidation) => {
      // Retrieve the error type and message from the override
      const overrideErrorType: ErrorTypes = errorValidation['validatorType'];
      const overrideErrorMessage: string = errorValidation['errorMessage'];

      // Override the min date and max date validation and continue to next custom error validator
      if (overrideErrorType === ErrorTypes.MinDate) {
        defaultMinDateErrorWrapperConfigValidation[0]['errorMessage'] = overrideErrorMessage;
      } else if (overrideErrorType === ErrorTypes.MaxDate) {
        defaultMaxDateErrorWrapperConfigValidation[0]['errorMessage'] = overrideErrorMessage;
      } else {
        // Find the corresponding default error validator and override the error message
        for (let defaultErrorWrapperValidator in defaultDateErrorWrapperConfigValidations) {
          const defaultValidation: any = defaultDateErrorWrapperConfigValidations[defaultErrorWrapperValidator];
          if (defaultValidation['validatorType'] === overrideErrorType) {
            defaultValidation['errorMessage'] = overrideErrorMessage;
          }
        }
      }
    });
  }

  /**
   * Builds a simple date format of MM/DD/YYYY used.
   *
   * @see DatePickerComponent#formatDateStringFromUserInput
   * @param month
   * @param day
   * @param year
   */
  private buildStringDate = (month: string, day: string, year: string): string => [month, day, year].join('/');

  /**
   * Checks if the form control is invalid, or its current value is empty. If so, check to make sure we have a valid cached date.
   * Used when a user opens the material calendar to replace invalid dates with the users last valid date.
   *
   * @see DatePickerComponent#onMatCalendarClose
   */
  private hasInvalidOrEmptyDateAndValidCacheDateExists = (): boolean =>
    (this.datePickerConfiguration.control.invalid || isStringNullUndefinedOrEmpty(this.datePickerConfiguration.control.value)) && !isStringNullUndefinedOrEmpty(this.lastValidDate)

  /**
   * Checks if current user input date is equal to the last valid cache date. If not, we have a new valid date, so we'll
   * update the view model date with the current control value and emit to listeners.
   *
   * @see DatePickerComponent#onMatCalendarClose
   */
  private doesCurrentUserInputDateRequireViewModelUpdate = (): boolean =>
    this.lastValidDate !== this.datePickerConfiguration.control.value

  /**
   * Checks if the new date value from the updated form control requires formatting by validating its existence and that it has not been formatted correctly.
   *
   * @param newDate
   */
  private doesUpdatedDateRequireFormatting = (newDate: any): boolean => !isNullOrUndefined(newDate) && !this.isValidParsedDate(newDate as string);

  /**
   * Checks if the date has been formatted correctly and the new date does not match the current date, used for protection when loading a submitted claim.
   *
   * @param newDate
   */
  private hasValidFormattedDateBeenUpdateFromOriginalDate = (newDate: any): boolean =>
    typeof(newDate) === PrimitiveType.String && (this.isValidParsedDate(newDate) && newDate !== this.datePickerConfiguration.control.value);

  /**
   * Checks the current user input date and validity before assigning to the last valid cache date used by the calendar.
   *
   * @see DatePickerComponent#ngOnInit
   * @see DatePickerComponent#onMatCalendarClose
   */
  private isPendingCacheDateValid = (): boolean => {
    const controlValue = this.datePickerConfiguration.control.value;
    const isControlValueStringAndNullUndefinedOrEmpty: boolean = typeof(controlValue) === PrimitiveType.String ? isStringNullUndefinedOrEmpty(controlValue) : isNullOrUndefined(controlValue);
    return (DateUtility.isValidDate(controlValue) && this.datePickerConfiguration.control.valid)
    || isControlValueStringAndNullUndefinedOrEmpty;
  }

  /**
   * Updates the last valid user input date if the current value is a valid date and no errors are found on the control.
   *
   * @param cacheDate - nullable string passed when the date is reset
   */
  private setLastValidDateFromUserInput(cacheDate?: string): void {
    if (!isStringNullUndefinedOrEmpty(cacheDate)) {
      this.lastValidDate = cacheDate;
    } else {
      this.lastValidDate = this.isPendingCacheDateValid() ? this.datePickerConfiguration.control.value : this.lastValidDate;
    }
  }

  /**
   * Updates the view model with the parsed and validated date value and emits the change event to any listeners.
   *
   * @param formattedDateValue
   */
  private updateViewModelFormControlValueAndEmitChange(formattedDateValue: string): void {
    this.datePickerConfiguration.control.setValue(formattedDateValue);
  }

  /**
   * Ripped from new 2020source with modifications to fit our needs for new eClaim. This is unique to the date picker component,
   * hence why it is housed within this component rather than DateUtility. This should be the ONLY component that is concerned
   * with date formatting specific to the date picker used throughout the application.
   *
   * @param userInputDateString
   */
  private formatDateStringFromUserInput(userInputDateString: string): string {
    if (new RegExp(ApplicationConstants.mmDdYyyyDateRegex).test(userInputDateString)) {
      return;
    }

    // Replace any '-' delimiters with '/' to conform to the succeeding logic
    let formattedValue: string = userInputDateString.replace(/-/g, '/');

    // Check if the value has '/' separators
    if (formattedValue.indexOf('/') < 0) {
      // If the value doesn't have any separators but is 8 digits long, automatically add the separators
      // i.e. convert "01021999" to "01/02/1999"
      if (formattedValue.length === 8) {
        formattedValue = this.buildStringDate(
          // Add '/' after month
          formattedValue.substr(0, 2),
          // Add '/' after day
          formattedValue.substr(2, 2),
          // Add '/' after year
          formattedValue.substr(4));
      }
    } else {
      // Split the value by the '/' separator to get the different date pieces
      const delimitedDateValues: string[] = formattedValue.split('/');

      // If we have two date pieces, we need to check if each piece is complete and add the '/' separator
      if (delimitedDateValues.length === 2) {
        // If the first date piece is 4 digits we can put a separator between them
        // i.e. convert "0102/1999" to "01/02/1999"
        if (delimitedDateValues[0].length === 4) {
          formattedValue = this.buildStringDate(
            // Add '/' to the first two digits representing the month
            delimitedDateValues[0].substr(0, 2),
            // Add '/' to the second two digits representing the day
            delimitedDateValues[0].substr(2, 2),
            // Add '/' to the year token
            delimitedDateValues[1]);
        }
        // If the second date piece is 6 digits we can put a separator between them
        // i.e. convert "01/021999" to "01/02/1999"
        else if (delimitedDateValues[1].length === 6) {
          formattedValue = this.buildStringDate(
            // Add '/' to the month token
            delimitedDateValues[0],
            // Add '/' to the second two digits representing the day
            delimitedDateValues[1].substr(0, 2),
            // Add '/' to the last four digits representing the year
            delimitedDateValues[1].substr(2));
        }
      }
    }
    return formattedValue;
  }

  /**
   * Check for valid parsed dates through the date utility, and catch cases of invalid instantiated dates that are technically valid,
   * e.g. '00/00/0000', '01/00/0000', '00/01/0000', etc.
   *
   * @param parsedDateValue
   */
  private isValidParsedDate(parsedDateValue: string): boolean {
    if (!isStringNullUndefinedOrEmpty(parsedDateValue) && parsedDateValue.indexOf('/') > 0) {
      const tokenizedParsedDate: string[] = parsedDateValue.split('/');
      // Check if delimited date length is three pieces
      if (tokenizedParsedDate.length !== 3) {
        return false;
      }
      // Check for valid characters that came through the formatter
      const validDigitDate: RegExp = new RegExp(ApplicationConstants.generalizedDateRegex);
      if (!validDigitDate.test(parsedDateValue)) {
        return false;
      }
      return tokenizedParsedDate.length === 3;
    }
  }
  /***** END - PRIVATE METHODS *****/

  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    // Update the minDate property if passed from the configuration
    this.minDate = (this.datePickerConfiguration.minDate) ? this.datePickerConfiguration.minDate : this.minDate;

    // Update the maxDate property if passed from the configuration
    this.maxDate = (this.datePickerConfiguration.maxDate) ? this.datePickerConfiguration.maxDate : this.maxDate;

    // Initialize the error wrapper
    this.buildDefaultErrorWrapperConfig();

    // Override any error messages passed from
    if (this.datePickerConfiguration.customErrorMessages && this.datePickerConfiguration.customErrorMessages.length > 0) {
      this.overrideDefaultErrorMessages();
    }

    // Assign the current control date to the last valid cache date
    if (this.isPendingCacheDateValid()) {
      this.lastValidDate = this.datePickerConfiguration.control.value;
    }

    // Listen for date input changes - if the value requires formatting, attempt to format it and update the form control value
    this.datePickerConfiguration.control.valueChanges.subscribe((newDate: any) => {
      if (this.doesUpdatedDateRequireFormatting(newDate) || this.hasValidFormattedDateBeenUpdateFromOriginalDate(newDate)) {
        const formattedDateValue = this.formatDateStringFromUserInput(newDate);
        if (this.isValidParsedDate(formattedDateValue)) {
          this.setLastValidDateFromUserInput();
          this.updateViewModelFormControlValueAndEmitChange(formattedDateValue);
        }
      }
    });
  }

  /***** END - EVENT HANDLERS *****/


  /***** START - PUBLIC FUNCTIONS *****/

  /**
   * Delegate to check date the date format to fire invalid date error messages.
   *
   * @param control
   */
  hasInvalidDate = (control: AbstractControl): boolean => DateUtility.hasDateFormatAndValidityError(control as FormControl);

  /**
   * On a date selection from the widget, set the control value and emit to any listeners after we've converted and validated the date.
   *
   * @param event
   */
  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    const viewModelDate: string = DateUtility.buildFriendlyDateFromJsDate(event.value);
    if (!isStringNullUndefinedOrEmpty(viewModelDate) && DateUtility.isValidDate(viewModelDate)) {
      this.updateViewModelFormControlValueAndEmitChange(viewModelDate);
    }
  }

  /**
   * Update the view model date on calendar close with the last valid cache date
   * if the date selected has not been updated from the last user input date.
   */
  onMatCalendarClose(): void {
    if (this.hasInvalidOrEmptyDateAndValidCacheDateExists() && this.doesCurrentUserInputDateRequireViewModelUpdate()) {
      this.updateViewModelFormControlValueAndEmitChange(this.lastValidDate);
    }
  }

  /**
   * Update the cache value when the material calendar is opened to bind the JS date on the calendar UI.
   *
   * @see DatePickerComponent#lastValidJsDate
   */
  onMatCalendarOpen(): void {
    if (isNullOrUndefined(this.datePickerConfiguration.control.value)) {
      this.setLastValidDateFromUserInput(DateUtility.buildFriendlyDateFromJsDate(ApplicationConstants.todaysDate));
    } else {
      this.setLastValidDateFromUserInput();
    }
  }

  /**
   * Prepend any single digit day, or month, with a zero if a formatted match is found.
   */
  formatDateOnBlur(): void {
    if (new RegExp(ApplicationConstants.singleDigitMonthOrDayDateRegex).test(this.dateTextBox.nativeElement.value)) {
      // Split the date and prepend zeroes
      const delimitedDateValues: string[] = (this.dateTextBox.nativeElement.value as string).split('/');
      delimitedDateValues.slice(0, 2).forEach((dateValue: string, index: number) => {
        if (new RegExp(/^[1-9]$/).test(dateValue)) {
          delimitedDateValues[index] = `0${dateValue}`;
        }
      });
      this.datePickerConfiguration.control.setValue(delimitedDateValues.join('/'));
    }
  }
  /***** END - PUBLIC FUNCTIONS *****/
}
