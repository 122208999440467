import {Link} from './link';

export interface FrameAPIResponse {
  count: number;
  duration?: number;
  frames: Frame[];
}

// This Frame is the model returns from the Frame Search API
export interface Frame {
  isActiveSelection?: boolean;
  frameServiceSelected?: boolean;
  manufacturer?: string;
  model?: string;
  material?: string;
  eyesize?: number;
  dbl?: string;
  temple?: string;
  color?: string;
  shape?: string;
  retailCost?: number;
  collection?: string;
  b?: number;
  ed?: number;
  c?: number;
  eBuyFrameId?: number;
  sku?: string;
  upc?: string;
  dataSource?: string;
  wholesaleOriginal?: string;
  imageLink?: Link;
  wholeSalePrice?: string;
}

// This FrameDataModel is used in the Claim.LabInformation model and submitted to the Patient Encounter API
export interface FrameDataModel {
  name?: string;
  options?: FrameOptionDetail[];
  manufacturer?: Manufacturer;
  brand?: Brand;
  images?: Image[];
  shape?: Shape;
  model?: string;
  material?: string;
  keywords?: string;
  catalogSource?: string;
  genderType?: string;
  ageGroup?: string;
  activeStatus?: string;
  rimType?: string;
  colorCode?: string;
  productGroupName?: string;
}

export interface FrameOptionDetail {
  color?: Color;
  size?: FrameSize;
  sku?: string;
  upc?: string;
  internalId?: string;
  ebuyUpc?: string;
  jobsonProductCode?: string;
  retailPrice?: number;
  salePrice?: number;
  wholeSalePrice?: number;
  image?: Image;
}

export interface Manufacturer {
  name?: string;
  description?: string;
}

export interface Brand {
  name?: string;
  description?: string;
  logo?: Image;
  background?: Image;
}

export interface Image {
  type?: ImageNameEnum;
  name?: string;
  mainUrl?: string;
  thumbnailUrl?: string;
}


export interface Color {
  code?: string;
  hexCode?: string;
  name?: string;
  description?: string;
}

export interface FrameSize {
  code?: string;
  eye?: string;
  a?: string;
  b?: string;
  ed?: string;
  dbl?: string;
  bridge?: string;
  temple?: string;
  wrap?: string;
  panto?: string;
  addPower?: string;
  supportedRx?: SupportedRx;
  shortDescription?: string;
}

export interface SupportedRx {
  minSphere?: string;
  maxSphere?: string;
  minCylinder?: string;
  maxCylinder?: string;
  minMonoPd?: string;
  minTotalPower?: string;
  maxTotalPower?: string;
}

export interface Shape {
  mappedShapeId?: number;
  shape?: string;
  description?: string;
}

export enum ImageNameEnum {
  FRONTVIEW,
  MAIN,
  TEMPLEVIEW
}

export enum Suppliers {
  LENS_ONLY = 'LENS_ONLY',
  LAB = 'LAB',
  DOCTOR = 'DOCTOR',
  PATIENT = 'PATIENT',
  VENDOR = 'VENDOR',
}

export enum FrameMaterials {
  ZYL = 'ZYL',
  METAL = 'METAL',
  DRILLED_RIMLESS = 'DRILLED_RIMLESS',
  GROOVED_RIMLESS = 'GROOVED_RIMLESS',
}

export enum Shapes {
  SQUARE = 'SQUARE',
  MODERATE = 'MODERATE',
  AVIATOR = 'AVIATOR',
  CAT = 'CAT',
  OVAL = 'OVAL',
  GOGGLE = 'GOGGLE',
  RECTANGLE = 'RECTANGLE',
  LADY = 'LADY',
  P_3 = 'P_3',
  CORNERED_RECT = 'CORNERED_RECT',
  TAPERED_OVAL = 'TAPERED_OVAL'
}
