import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  allStringArrayElementsEmpty,
  isBoolean,
  isNullOrUndefined,
  isStringNullUndefinedOrEmpty
} from '../../../common/utility';
import { ServicesConstants } from './services.constants';
import { ApplicationConstants, ErrorTypes } from '../../../common/constants/application.constants';

export class ServicesValidators {

  static noEmptyDiagnosisCodes(formArray: FormArray): ValidationErrors {
    let emptyDiagnosisCodes = true;
    for (const diagnosisCode of formArray.value) {
      if (diagnosisCode.diagnosisCode !== '') {
        emptyDiagnosisCodes = false;
        break;
      }
    }
    if (emptyDiagnosisCodes) {
      return {
        diagnosisCodesEmpty: 'Please enter at least one valid Diagnosis Code'
      };
    }
    return null;
  }

  static diagnosisCodeValidator(form: FormGroup): ValidationErrors {
    let validDiagnosisCode: boolean = true;
    const serviceLines: FormArray = <FormArray> form.controls[ServicesConstants.SERVICE_LINES];
    serviceLines.controls.forEach((serviceLine) => {
      const diagnosisPointers: FormArray = <FormArray> serviceLine.get(ServicesConstants.DIAGNOSIS_POINTERS);
      diagnosisPointers.controls.forEach((diagnosisPointer) => {

        // This if else block was added to fix a bug of 'getRawValue()' was getting a null exception in Unit Tests
        let aggregateDiagnosisCodeFormArray: any[];
        if (isNullOrUndefined(form.get(ServicesConstants.DIAGNOSIS_CODES) as FormArray)) {
          aggregateDiagnosisCodeFormArray = [];
        } else {
          aggregateDiagnosisCodeFormArray = (form.get(ServicesConstants.DIAGNOSIS_CODES) as FormArray).getRawValue();
        }

        const hasAnyMatchingDxPointerValues: boolean = aggregateDiagnosisCodeFormArray.some(diagnosisCode => !isStringNullUndefinedOrEmpty(diagnosisPointer.value as string) && diagnosisCode.position === diagnosisPointer.value.toUpperCase() && !isStringNullUndefinedOrEmpty(diagnosisCode.diagnosisCode));
        if (diagnosisPointer.value && !hasAnyMatchingDxPointerValues) {
          validDiagnosisCode = false;
          diagnosisPointer.setErrors({ ...diagnosisPointer.errors, invalidDiagnosisCode: true });
        } else if (validDiagnosisCode && diagnosisPointer.hasError('invalidDiagnosisCode')) {
          // Remove the invalid diagnosis code validator if the user corrects the error on the diagnosis pointers
          delete diagnosisPointer.errors['invalidDiagnosisCode'];
          if (Object.keys(diagnosisPointer.errors).length === 0) {
            // Remove validation errors if no preexisting errors are found, updates form control validity
            diagnosisPointer.setErrors(null);
          }
        }
      });
    });
    if (!validDiagnosisCode) {
      return {
        invalidDiagnosisCode: 'Cannot reference an empty Diagnosis'
      };
    }
    return null;
  }

  static serviceLineAllOrNoneValidator(form: FormGroup): ValidationErrors {
    const allOrNoneArray = {
      cptHcpcsCode: form.get(ServicesConstants.CPT_HCPCS_CODE).value,
      diagnosisPointers: form.get(ServicesConstants.DIAGNOSIS_POINTERS).value,
      billedAmount: form.get(ServicesConstants.BILLED_AMOUNT).value,
      unitCount: form.get(ServicesConstants.UNIT_COUNT).value
    };

    let all = false;
    let none = false;

    Object.keys(allOrNoneArray).forEach((key) => {
      const nullValue: boolean = ServicesValidators.isAllOrNoneValueNull(allOrNoneArray, key);
      if (nullValue) {
        none = true;
      } else {
        all = true;
      }
    });

    if (all === none) {
      return {
        serviceLinesAllOrNone: 'Please enter all or none'
      };
    }
    return null;
  }

  static modifierCodesValidator(form: FormControl): ValidationErrors {
    const modifierCodesValue: string = form.value;
    if (!isStringNullUndefinedOrEmpty(modifierCodesValue)) {
      const modifierCodes: String[] = modifierCodesValue.split(',');
      const firstFour = modifierCodes.slice(0, 4);
      if (firstFour.some(code => code === '' || code.length !== 2)) {
        return {
          modifierCodes: 'Please enter a valid two character modifier'
        };
      }
    }
    return null;
  }

  static modifierCodesLengthValidator(form: FormControl): ValidationErrors {
    // Limit: 4
    const modifierCodesValue: string = form.value;
    if (!isStringNullUndefinedOrEmpty(modifierCodesValue)) {
      const modifierCodes: string[] = modifierCodesValue.split(',');
      if (modifierCodes.length > 4) {
        return {
          modifierCodesLength: 'Please enter up to 4 modifier codes'
        };
      }
    }
    return null;
  }

  static unitCountsValidator(form: FormControl): ValidationErrors {
    const val = form.value;
    const validCharacterRegex = /^([0-9])+$/;
    if (val && val !== null && val.toString().trim().length !== 0) {
      if (!validCharacterRegex.test(val) || parseInt(val) < 1) {
        return {
          unitCountsValidator: 'Please enter a valid value for Units'
        };
      }
    }
    return null;
  }

  static dollarAmount(form: FormControl): ValidationErrors {
    const val = form.value;
    const validCharacterRegex = /^[0-9]+(\.[0-9]{0,2})?$/;
    if (val && val !== null && val.toString().trim().length !== 0) {
      if (!validCharacterRegex.test(val)) {
        return {
          dollarAmount: 'Please enter a valid amount'
        };
      }
    }
  }

  // TODO Check with Allison if first service line required or any
  static serviceLineRequired(form: FormGroup): ValidationErrors {
    const serviceLinesFormArray = <FormArray>form.get(ServicesConstants.SERVICE_LINES);
    for (let controlIndex = 0; controlIndex < serviceLinesFormArray.value.length; controlIndex++) {
      for (const editableField of ServicesConstants.EDITABLE_SERVICELINE_FIELDS) {
        const controlValue = serviceLinesFormArray['controls'][controlIndex].get(editableField) ? serviceLinesFormArray['controls'][controlIndex].get(editableField).value : null;
        if (controlValue) {
          if (controlValue instanceof Array) {
            if (!allStringArrayElementsEmpty(controlValue)) {
              return null;
            }
          } else {
            let notNull: boolean;
            if (isBoolean(controlValue)) {
              notNull = !isNullOrUndefined(controlValue);
            } else {
              notNull = !isStringNullUndefinedOrEmpty(controlValue);
            }
            if (notNull) {
              return null;
            }
          }
        }
      }
    }
    // If all of the serviceLines editable values are empty, it is required.
    return {
      serviceLineRequired : 'Service Line required'
    };
  }

  // Need to extract the "$" from the formControl value before required validation
  static dollarAmountRequired(form: FormControl): ValidationErrors {
    const newFormControl = new FormControl(form.value);
    return Validators.required(newFormControl);
  }

  static isAllOrNoneValueNull(allOrNoneArray, key): boolean {
    const value = allOrNoneArray[key];
    if (key === ServicesConstants.UNIT_COUNT) {
      return isStringNullUndefinedOrEmpty(value) || value === '0';
    } else if (key === ServicesConstants.DIAGNOSIS_POINTERS) {
      return value.every(element => isStringNullUndefinedOrEmpty(element));
    } else {
      return isStringNullUndefinedOrEmpty(value);
    }
  }

  static MaxDollarAmount(control: FormControl): ValidationErrors {
    const controlValue = parseFloat(<string>control.value);
    if (!isNullOrUndefined(controlValue) && controlValue > ApplicationConstants.maxDollarAmountAllowed) {
      return {
        maxDollarAmount: `The maximum value accepted is $${ApplicationConstants.maxDollarAmountAllowed}`
      };
    }
    return null;
  }

  /**
   * ECR-6451 AC11 - AC13: Make Denied Or Paid COB field required if 'Other Insured Paid' is zero.
   *
   * @param {FormControl} formGroup - other insured paid form control
   */
  static deniedOrPaidReasonConditionallyRequired(formGroup: FormGroup): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const deniedOrPaidReasonControl: AbstractControl = formGroup.get(ServicesConstants.OTHER_INSURANCE_DENIED_NOT_COVERED_REASON);
      const isDeniedOrPaidRequiredDueToZeroOtherInsuredPaid: boolean = isStringNullUndefinedOrEmpty(deniedOrPaidReasonControl.value as string) && parseFloat(control.value as string) === 0;

      // Set the required validator on denied or paid if the other insured paid value is zero
      if (isDeniedOrPaidRequiredDueToZeroOtherInsuredPaid) {
        deniedOrPaidReasonControl.setErrors({
          deniedOrPaidReasonConditionallyRequired: 'Denied or paid is a required field'
        });
      } else if (deniedOrPaidReasonControl.hasError(ErrorTypes.DeniedOrPaidReasonConditionallyRequired)) {
        deniedOrPaidReasonControl.setErrors(null);
      }

      return null;
    };
  }
}

export const CobValidators = {
  billedAmount: [ServicesValidators.dollarAmount, ServicesValidators.MaxDollarAmount] as ValidatorFn[],
  billedAmountRequired: [ServicesValidators.dollarAmount, ServicesValidators.MaxDollarAmount, Validators.required] as ValidatorFn[],
  cptHcpcCode: [Validators.pattern(ApplicationConstants.cptHcpcsCodeRegex)] as ValidatorFn[],
  cptHcpcCodeRequired: [Validators.pattern(ApplicationConstants.cptHcpcsCodeRegex), Validators.required] as ValidatorFn[],
  unitCount: [ServicesValidators.unitCountsValidator] as ValidatorFn[],
  unitCountRequired: [ServicesValidators.unitCountsValidator, Validators.required] as ValidatorFn[]
};
