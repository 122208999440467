export class ClaimCardsToUpdate {
  public exam: boolean = false;
  public services: boolean = false;
  public insured: boolean = false;
  public lens: boolean = false;
  public prescription: boolean = false;
  public frame: boolean = false;
  public lab: boolean = false;
  public all: boolean = false;
}
