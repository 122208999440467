import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {BehaviorSubject, fromEvent, interval, Observable, Subscription} from 'rxjs';
import {MessageService} from '../message/message.service';
import {Router} from '@angular/router';
import {ApplicationConstants, SessionStorageKeys} from '../../../constants/application.constants';
import {filter, map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {DateUtility, isNullOrUndefined, isStringNullUndefinedOrEmpty, isStringNullUndefinedOrEmptyWithTrim} from '../../../utility';
import {CookieService} from 'ngx-cookie-service';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Guid} from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  /***** START - PRIVATE MEMBERS *****/
  private observableSubscriptions: Subscription[] = [];
  private config = environment;
  private _acsMetadata = {
    base: this.config.oauthBase,
    redirectUri: this.config.applicationUrl,
    clientId: this.config.clientId,
    scopes: [
      'auth_ecp',
      'claim_view',
      'member_view',
      'reference_view',
      'eligibility_view',
      'promotion_view',
      'provider_view',
      'product_view',
      'supplier_view',
      'lab_view',
      'openid',
      'profile',
      'write:claim',
      'write:eligibility',
      'read:reference.lens',
      'read:vc.vsr',
      'write:vc.vsr',
      'read:vc.giftcertificate',
      'write:vc.giftcertificate',
      'read:vc.patientencounter',
      'write:vc.patientencounter',
      'read:vc.external_eligibility',
      'read:vc.reference.frame',
      'client_view',
      'write:vc.member',
      'read:vc.eclaim-reference',
      'read:vc.claims-online-reference',
      'read:ef.supplychain',
      'write:ef.supplychain'
    ]
  };
  private renderer: Renderer2;
  private iFrame: HTMLIFrameElement;
  private iFrameInitialized = false;
  private _loggedIn = false;
  private _accessToken: string = '';
  private _sessionTimestamp: Date;
  private sessionCheckDuration = 60000; // 60 seconds
  private sessionIntervalSubscription: Subscription;
  private _sessionTimedOut = false;
  private _isAutoSaveFromSessionTimeOut = false;
  private _acsSingOffHasHappened = false;
  private _isKaleyedoscopePractice = false;
  private _guid: string;

  private set sessionTimestamp(newSessionTimestamp: Date) {
    if (this._sessionTimestamp !== newSessionTimestamp) {
      this._sessionTimestamp = newSessionTimestamp;
      sessionStorage.setItem(SessionStorageKeys.SessionTimestamp, JSON.stringify(this._sessionTimestamp));
      this.onSessionTimestamp.next(this._sessionTimestamp);
    }
  }
  private get sessionTimestamp(): Date {
    const sessionTimestamp = sessionStorage.getItem(SessionStorageKeys.SessionTimestamp);
    // Parse timestamp from sessionStorage if it exists
    if (sessionTimestamp !== null) {
      this._sessionTimestamp = new Date(JSON.parse(sessionTimestamp));
      // If timestamp doesn't exist in session storage, set it
    } else {
      this.sessionTimestamp = new Date();
    }
    return this._sessionTimestamp;
  }

  /***** END - PRIVATE MEMBERS *****/


  constructor(
    private messageService: MessageService,
    private router: Router,
    private rendererFactory: RendererFactory2,
    private cookieService: CookieService,
    private dialogRef: MatDialog
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  sessionTimeoutNotifier: BehaviorSubject<boolean> = new BehaviorSubject(this.sessionTimedOut);



  /***** START - PUBLIC MEMBERS *****/
  onLoggedIn = new BehaviorSubject(this._loggedIn);
  onAccessToken = new BehaviorSubject(undefined);
  onSessionTimestamp = new BehaviorSubject(undefined);
  autoSaveClaimForm = new BehaviorSubject(false);
  onAutoSavedClaimForm = new BehaviorSubject(undefined);
  jwtHelperService = new JwtHelperService();

  get sessionTimedOut(): boolean {
    return this._sessionTimedOut;
  }

  set sessionTimedOut(val: boolean) {
    this._sessionTimedOut = val;
    this.sessionTimeoutNotifier.next(val);
  }

  set loggedIn(loggedInValue: boolean) {
    if (this._loggedIn !== loggedInValue) {
      this._loggedIn = loggedInValue;
      this.onLoggedIn.next(this._loggedIn);
    }
  }
  get loggedIn(): boolean {
    return this._loggedIn;
  }

  set accessToken(newAccessToken: string) {
    if (this._accessToken !== newAccessToken) {
      this._accessToken = newAccessToken;
      this.onAccessToken.next(this._accessToken);
    }
  }
  get accessToken(): string {
    return this._accessToken;
  }

  get isKaleyedoscopePractice(): boolean {
    return this._isKaleyedoscopePractice;
  }

  set isKaleyedoscopePractice(value: boolean) {
    this._isKaleyedoscopePractice = value;
  }

  get guid(): string {
    return this._guid;
  }

  set guid(value: string) {
    this._guid = value;
  }

  set autoSavedClaimForm(autoSaved: boolean) {
    this.onAutoSavedClaimForm.next(autoSaved);
  }

  get acsSingOffHasHappened(): boolean {
    return this._acsSingOffHasHappened;
  }

  set acsSingOffHasHappened(value: boolean) {
    this._acsSingOffHasHappened = value;
  }

  /***** END - PUBLIC MEMBERS *****/


  /***** START - PRIVATE FUNCTIONS *****/
  private isSessionExpired(): boolean {
    const currentDate = DateUtility.buildMmDdYyyyDateFromDate(new Date());
    const vspIdcCookieAsString: string = this.cookieService.get(ApplicationConstants.VSPIDC_ACCESS);
    const loginDate = vspIdcCookieAsString.split('|')[0];
    return currentDate !== loginDate;
  }

  private initActiveSessionListeners(): void {
    this.sessionIntervalSubscription = interval(this.sessionCheckDuration).subscribe(this.sessionTimeoutListener.bind(this));
  }

  private initAuthenticationService(): Observable<boolean> {
    return new Observable((observer) => {
      fromEvent(window, 'message')
        .pipe(
          filter(({ origin }: Partial<MessageEvent> = { origin: '' }) => origin.includes(`${this.config.oauthBase}.eyefinity.com`)),
          map((event) => event.data)
        ).subscribe((token: string) => {
        if (token) {
          if (this.isSessionExpired()) {
            this.guid = null;
            this.messageService.clear();
            observer.next(false);
            observer.complete();
          } else {
            if (!this.doesTokenUserMatchCookieUser(token)) {
              this.handleUnauthenticatedSession();
            } else {
              // ECLAIM-353 - Added the check for the VSPIDC_OFFICEID cookie to determine if the user is a VSP doctor.
              if (!this.isNonVspDoctor()) {
              this.accessToken = token;
              this.loggedIn = true;
              // This was added for ECLAIM-3.
              this.setKaleyedoscopeParticipationIndicator(token);
              this.guid = Guid.create().toString().replace(/-/g, '');
              if (!this.sessionIntervalSubscription) {
                this.initActiveSessionListeners();
              }
              observer.next(true);
              observer.complete();
            } else {
              this.navigateToLegacyHomePage();
              }
            }
          }
        } else {
          this.handleUnauthenticatedSession();
          this.guid = null;
          observer.next(false);
          observer.complete();
        }
      });
      if (!this.iFrameInitialized) {
        this.iFrame = this.renderer.createElement('iframe');
        this.iFrame.src = `https://${this.config.oauthBase}.eyefinity.com/acs2/iframe?${this.createParams()}`;
        this.iFrame.style.display = 'none';
        this.renderer.appendChild(document.body, this.iFrame);
      }
    });
  }

  private clearSessionStorage(): void {
    sessionStorage.clear();
  }

  /**
   * This method was added to set the isKaleyedoscopePractice flag for ECLAIM-3.
   *
   * @param accessToken - Token we want to decode to see if logged in user participates in Kaleyedoscope.
   * @private
   */
  private setKaleyedoscopeParticipationIndicator(accessToken: string) {
    if (!isStringNullUndefinedOrEmptyWithTrim(accessToken)) {
      const decodedAccessToken = this.jwtHelperService.decodeToken(accessToken);
      const inventoryProgram = decodedAccessToken.inventory_program;
      if (!isStringNullUndefinedOrEmptyWithTrim(inventoryProgram) && inventoryProgram === ApplicationConstants.kaleyedoscope) {
        this.isKaleyedoscopePractice = true;
      }
    }
  }

  private doesTokenUserMatchCookieUser(accessToken: string): boolean {
    if (!isStringNullUndefinedOrEmptyWithTrim(accessToken)
      && !isNullOrUndefined(this.cookieService.get(ApplicationConstants.ACCT_USER))) {
      const decodedAccessToken = this.jwtHelperService.decodeToken(accessToken);
      const principleUser: string = decodedAccessToken.principal;
      const cookieUser: string = this.cookieService.get(ApplicationConstants.ACCT_USER);
      return principleUser === cookieUser;
    }
    return false;
  }

   isNonVspDoctor(): boolean {
    if (!isNullOrUndefined(this.cookieService.get(ApplicationConstants.VSPIDC_OFFICEID))) {
    const vspIdcOfficeIdCookieAsString: string = this.cookieService.get(ApplicationConstants.VSPIDC_OFFICEID);
    return vspIdcOfficeIdCookieAsString.startsWith('EFAN9');
    }
    return false;
  }

  navigateToLegacyHomePage() {
    window.location.href = this.cookieService.get(ApplicationConstants.efSurlCookie) + ApplicationConstants.legacyHomePageURL;
  }

  private terminateSession(): void {
    // trigger close of session expired modal if still open
    this.messageService.closeSessionAlmostExpiredModal();
    // remove session data from sessionStorage
    this.clearSessionStorage();
    this.guid = null;
    // unsubscribe from the session check interval
    if (this.sessionIntervalSubscription) {
      this.sessionIntervalSubscription.unsubscribe();
    }
    // sign out of ACS
    this.iFrame.contentWindow.postMessage(JSON.stringify({ type: 'logout' } ), '*');
    // Set the acsSingOffHasHappened flag to true;
    this.acsSingOffHasHappened = true;
    // remove auth service observable subscriptions
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private handleUnauthenticatedSession(): void {
    // trigger close of session expired modal if still open
    this.messageService.closeSessionAlmostExpiredModal();
    // remove session data from sessionStorage
    this.clearSessionStorage();
    // unsubscribe from the session check interval
    if (this.sessionIntervalSubscription) {
      this.sessionIntervalSubscription.unsubscribe();
    }
    // remove auth service observable subscriptions
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
    // Only do this if the session has not terminated.
    // In other words this will only happen if the 24 hour time limit has not been met.
    // This will only happen if the VSPIDC_ACCESS has a current date on the cookie value.
    if (!this.acsSingOffHasHappened) {
      // If the VSPIDC_ACCESS cookie is null or missing in the log in route the user to the expired page login.
      // Else navigate them to the PingFed log in page.
      if (isNullOrUndefined(this.cookieService.get(ApplicationConstants.VSPIDC_ACCESS)) || isStringNullUndefinedOrEmpty(this.cookieService.get(ApplicationConstants.VSPIDC_ACCESS))) {
        // redirect to the logon page
        this.redirectToExpiredSessionPageUrl();
      } else {
        // redirect to the PingFed logon page
        this.navigateToThePingFedLogInPage();
      }
    }
  }

  /**
   * This builds the PingFed page link.
   *
   * @private
   */
  private getThePinFedLogInPageUrl(): string {
    return `https://${this.config.oauthBase}.eyefinity.com/acs2/login?${this.createParams(true)}`;
  }

  private navigateToThePingFedLogInPage() {
    // Set current window url in session storage so we can use it later once logged in.
    sessionStorage.setItem(SessionStorageKeys.RequestIsComingInFromPingFedLogInPage, ApplicationConstants.trueString);
    sessionStorage.setItem(SessionStorageKeys.WindowUrlBeforeNavigatingToPingFedLogInPage, window.location.href);
    window.open(this.getThePinFedLogInPageUrl(), '_parent');
  }

  private createParams(usePingFedPageRedirectLink: boolean = false) {
    let httpParams = new HttpParams()
      .append('client_id', this._acsMetadata.clientId)
      .append('scope', this._acsMetadata.scopes.join(' '))
      .append('target', 'cloud-eyefinity'); // Ping1AS login page
    let redirectUri = this._acsMetadata.redirectUri;
    if (usePingFedPageRedirectLink) {
      redirectUri = redirectUri + '/eInsurance';
    }
    httpParams = httpParams.append('redirect_uri', redirectUri);
    return httpParams.toString();
  }
  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  terminateSessionAndRedirectToLogonPage(): void {
    this.terminateSession();
    // redirect to the logon page
    this.redirectToExpiredSessionPageUrl();
  }

  redirectToExpiredSessionPageUrl() {
    // redirect to the logon page
    this.router.navigate([ApplicationConstants.routing.public.expiredSessionPageUrl]).catch((error) => {
      this.messageService.showErrorSnackbar(`Routing Error.  Check paths...`);
    });
  }

  /**
   * Called by the IsAuthenticatedRouteGuard to determine if the session is active for all secure pages
   * @returns {Observable<boolean>}
   */
  isSessionActive(): Observable<boolean> {
    return new Observable((observer) => {
      // resolve with true if there is a valid ACS session
      if (this.loggedIn) {
        observer.next(true);
        observer.complete();
        // if not logged in, initialize authenticationService first to build the iframe before checking session info
      } else {
        if (this.iFrameInitialized) {
          this.initAuthenticationService().subscribe((acsActive: boolean) => {
            if (!acsActive) {
              this.terminateSessionAndRedirectToLogonPage();
            }
            observer.next(acsActive);
            observer.complete();
          });
        } else {
          this.initAuthenticationService().subscribe((acsActive: boolean) => {
            observer.next(acsActive);
            observer.complete();
          });
        }
      }
    });
  }

  clearMessagesAndInitializeTermination() {
    this.messageService.clear();
    this.terminateSessionAndRedirectToLogonPage();
  }

  get isAutoSaveFromSessionTimeOut(): boolean {
    return this._isAutoSaveFromSessionTimeOut;
  }

  set isAutoSaveFromSessionTimeOut(value: boolean) {
    this._isAutoSaveFromSessionTimeOut = value;
  }

  /***** END - PUBLIC FUNCTIONS *****/


  /***** START - EVENT HANDLERS *****/
  sessionTimeoutListener(): void {
    this.sessionTimedOut = this.isSessionExpired();
    if (this.sessionTimedOut) {
      // This line below (this.dialogRef.closeAll()) was added to close any dialog modals that are present when the sessions times out.
      // This line needed to be moved to outside the below if statement since we want to make sure we close any modal that is open when the session is expired.
      this.dialogRef.closeAll();
      if (this.router.url.includes(ApplicationConstants.routing.secure.claimFormEdit)) {
        this.isAutoSaveFromSessionTimeOut = true;
        this.autoSaveClaimForm.next(true);
        this.onAutoSavedClaimForm.subscribe((saved) => {
          if (!isNullOrUndefined(saved)) {
            this.clearMessagesAndInitializeTermination();
          }
        });
      } else {
        this.clearMessagesAndInitializeTermination();
      }
    }
  }
  /***** END - EVENT HANDLERS *****/

}
