import { Name } from './name';
import { DateUtility } from '../common/utility';
import { Link } from './link';
import {MembershipName} from './membership';

export interface Address {
  type: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
}

export interface Subscriber {
  address: Address[];
  partialDob: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  clientName: string;
  memberPolicyLink: Link;
  businessApiMemberPolicyLink: Link;
  businessApiSecureMemberPolicyLink: Link;
  secureMemberPolicyLink: Link;
  visionServiceRequestsLink: Link;
  clientId: string;
  divId: string;
  membersConsumerId: string;
  coverageCode?: string;
  dependents?: Dependents[];
  relationCode?: string;
  status?: string;
  dependentsLink?: Link;
  externalEligibilityLink?: Link;
  eligibilityCoverageLink?: Link;
  visionServiceRequestLink?: Link;
}

export interface SubscriberSearchResult {
  subscribers: Subscriber[];
  count: number;
}

export interface Member {
  consumerId: string;
  dateOfBirth: string;
  externalEligibility: Link;
  name: Name;
  originalEffectiveDate: string;
  relationToSubscriber: string;
  status: string;
  suppliedFirstName: string;
  suppliedLastName: string;
}

export interface Dependents {
  consumerId: string;
  dateOfBirth: string;
  externalEligibilityLink?: Link;
  dependentsName: Name;
  originalEffectiveDate: string;
  relationCode: string;
  status: string;
  firstName: string;
  lastName: string;
}

export interface ConsumerName {
  firstName: string;
  firstNameSrc: string;
  lastName: string;
  lastNameSrc: string;
}
export class ClientInfo {
  clientDivisionName: string;
  clientDivisionStatus: string;
  clientName: string;
  status: string;
}

export interface ConsumerResultsData {
  name?: string;
  relationCode: string;
  dateOfBirth: string;
  externalEligibilityLink?: Link;
  consumerId?: string;
  subscriberConsumerId?: string;
}

export interface BusinessMemberPoliciesResults {
  consumerId: string;
  name?: ConsumerName[];
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dateOfBirth: string;
  cityState: string;
  clientInfo: ClientInfo;
  relationCode?: string;
  clientId: string;
  clientDivisionId: string;
  coverageCode?: string;
  dependents?: Dependents[];
  status?: string;
  memberName?: string;
  subscriberConsumerId?: string;
  self?: Link;
  memberPoliciesLink?: Link;
  dependentsLink?: Link;
  externalEligibilityLink?: Link;
  eligibilityCoverageLink?: Link;
  visionServiceRequestLink?: Link;
  businessApiSecureMemberPolicyLink?: Link;
  fehb?: FederalEnrolleeHealthBenefit;
}

export interface FederalEnrolleeHealthBenefit {
  fehbEnrollInd: boolean;
  fehbPlanLink?: Link;
}

export interface FederalEnrolleeHealthBenefitPlan {
  planCode: string;
  planType: string;
  planName: string;
}

export interface AddDependent {
  coverageDate: string;
  dateOfBirth: string;
  dependentsName: DependentsNameDto;
  relationCode: string;
}

export interface DependentsNameDto {
  readonly firstName: string;
  readonly lastName: string;
}

export class AddDependentRequest {

  private readonly _dateOfBirth: string;
  private readonly _dependentsName: DependentsNameDto;
  private readonly _relationCode: string;

  constructor(
    private dateOfBirth$: string,
    private dependentsName$: DependentsNameDto,
    private relationCode$: string
  ) {
    this._dateOfBirth = dateOfBirth$;
    this._dependentsName = dependentsName$;
    this._relationCode = relationCode$;
  }

  private get dateOfBirth(): string {
    return this._dateOfBirth;
  }

  private get dependentsName(): DependentsNameDto {
    return this._dependentsName;
  }

  private get relationCode(): string {
    return this._relationCode;
  }

  get payload(): AddDependent {
    return {
      // Per ECLAIM-128 we are setting the 'coverageDate' to undefined. I have a feeling this will be used later for an actual dependent so I'm leaving it in the object until then.
      coverageDate: undefined,
      dateOfBirth: DateUtility.buildYyyyMmDdDateFromDate(this.dateOfBirth),
      dependentsName: this.dependentsName,
      relationCode: this.relationCode
    };
  }
}
