<div fxLayout="row">
  <ol class="breadcrumb nested-column-9">
    <li *ngFor="let breadcrumb of breadcrumbs; index as breadcrumbIndex; first as isFirst; last as isLast">
    <span *ngIf="isFirst === true" (click)="navigateToLegacyEInsurance()"
          id="breadcrumb-{{breadcrumb.label}}-link">
      {{ breadcrumb.label }}
    </span>
      <span *ngIf="isFirst === false" [routerLink]="breadcrumb.url" routerLinkActive="router-link-active"
            id="breadcrumb-{{breadcrumbIndex}}-link">
      {{ breadcrumb.label }}
    </span>
      <div *ngIf="isLast === false" class="header-grey-divider" fxFlexOffset="10px"></div>
    </li>
  </ol>
  <div *ngFor="let breadcrumb of breadcrumbs; first as isFirst" class=" breadcrumb nested-column-3 action-link">
        <span href="" *ngIf="isFirst === true" id="return-to-classic-mode-link"
              (click)="navigateToLegacyEInsurance()">Return to the eInsurance Page</span>
  </div>
</div>
