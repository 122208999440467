import {ApplicationConstants} from '../../../common/constants/application.constants';

export const PrescriptionConstants = {
  binocularMin: 20,
  binocularMax: 90,
  monocularMin: 16,
  monocularMax: 38,
  opticalCenterBottomMin: 1,
  opticalCenterBottomMax: 40,
  opticalCenterCenterMin: -12,
  opticalCenterCenterMax: 12,
  segmentHeightBottomMin: 1,
  segmentHeightBottomMax: 40,
  segmentHeightCenterMin: -12,
  segmentHeightCenterMax: 12,
  lensOptionValues: {
    singleVision: 'Single Vision',
    progressive: 'Progressive'
  },

  prescriptionFormMeta: {
    inputId: {
      sphereRight: 'prescriptionRightEyeSphereInput',
      sphereLeft: 'prescriptionLeftEyeSphereInput',
      cylinderRight: 'prescriptionRightEyeCylinderInput',
      cylinderLeft: 'prescriptionLeftEyeCylinderInput',
      axisRight: 'prescriptionRightEyeAxisInput',
      axisLeft: 'prescriptionLeftEyeAxisInput',
      addRight: 'prescriptionRightEyeAddInput',
      addLeft: 'prescriptionLeftEyeAddInput',
      biMono: 'prescriptionBinocularMonocularSelect',
      distanceRight: 'prescriptionRightEyeDistanceInput',
      distanceLeft: 'prescriptionLeftEyeDistanceInput',
      nearRight: 'prescriptionRightEyeNearInput',
      nearLeft: 'prescriptionLeftEyeNearInput',
      labSpecialInstructionRedoOrder: 'labSpecialInstructionRedoOrderInput',
      opticalCenterRight: 'prescriptionRightEyeOpticalCenterHeightInput',
      opticalCenterLeft: 'prescriptionLeftEyeOpticalCenterHeightInput',
      opticalCenterStartingPoint: 'prescriptionOpticalCenterHeightStartingPointSelect',
      segmentHeightStartingPoint: 'prescriptionSegmentHeightStartingPointSelect',
      segmentHeightRight: 'prescriptionRightEyeSegmentHeightInput',
      segmentHeightLeft: 'prescriptionLeftEyeSegmentHeightInput',
    },
    formControlName: {
      sphereRight: 'prescriptionRightEyeSphere',
      sphereLeft: 'prescriptionLeftEyeSphere',
      cylinderRight: 'prescriptionRightEyeCylinder',
      cylinderLeft: 'prescriptionLeftEyeCylinder',
      axisRight: 'prescriptionRightEyeAxis',
      axisLeft: 'prescriptionLeftEyeAxis',
      addRight: 'prescriptionRightEyeAdd',
      addLeft: 'prescriptionLeftEyeAdd',
      biMono: 'prescriptionBinocularMonocular',
      distanceRight: 'prescriptionRightEyeDistance',
      distanceLeft: 'prescriptionLeftEyeDistance',
      nearRight: 'prescriptionRightEyeNear',
      nearLeft: 'prescriptionLeftEyeNear',
      labSpecialInstructionRedoOrder: 'labSpecialInstructionRedoOrder',
      opticalCenterRight: 'prescriptionRightEyeOpticalCenterHeight',
      opticalCenterLeft: 'prescriptionLeftEyeOpticalCenterHeight',
      opticalCenterStartingPoint: 'prescriptionOpticalCenterHeightStartingPoint',
      segmentHeightStartingPoint: 'prescriptionSegmentHeightStartingPoint',
      segmentHeightRight: 'prescriptionRightEyeSegmentHeight',
      segmentHeightLeft: 'prescriptionLeftEyeSegmentHeight',
      horizontalPrismRight: 'prescriptionRightEyeHPrism',
      horizontalPrismLeft: 'prescriptionLeftEyeHPrism',
      verticalPrismRight: 'prescriptionRightEyeVPrism',
      verticalPrismLeft: 'prescriptionLeftEyeVPrism',
      prescriptionSpecifiedThickness: 'prescriptionSpecifiedThickness',
      wrapAngle: 'wrapAngle',
      pantoscopicTilt: 'pantoscopicTilt',
      backVertexDistance: 'backVertexDistance',
    },
    formControlMaxLength: {
      sphereRight: 6,
      sphereLeft: 6,
      cylinderRight: 6,
      cylinderLeft: 6,
      axisRight: 3,
      axisLeft: 3,
      addRight: 6,
      addLeft: 6,
      distanceRight: 4,
      distanceLeft: 4,
      nearRight: 4,
      nearLeft: 4,
      opticalCenterRight: 5,
      opticalCenterLeft: 5,
      segmentHeightRight: 5,
      segmentHeightLeft: 5,
      horizontalPrismRight: 5,
      horizontalPrismLeft: 5,
      verticalPrismRight: 5,
      verticalPrismLeft: 5,
      prescriptionSpecifiedThickness: 3,
      wrapAngle: 5,
      pantoscopicTilt: 5,
      backVertexDistance: 5,
    },
    regexp: {
      prescriptionFieldsKeypressRegex: ApplicationConstants.prescriptionLeftRightFieldsKeypressRegex,
      prescriptionFieldsPasteFilterRegex: ApplicationConstants.prescriptionLeftRightFieldsPasteFilterRegex
    }
  },

  PRESCRIPTION_BI_MONO: 'prescriptionBinocularMonocular',
  PRESCRIPTION_OPTICAL_CENTER_STARTING_POINT: 'prescriptionOpticalCenterHeightStartingPoint',
  PRESCRIPTION_SEGMENT_HEIGHT_STARTING_POINT: 'prescriptionSegmentHeightStartingPoint',
  WRAP_ANGLE: 'wrapAngle',
  PANTOSCOPIC_TILT: 'pantoscopicTilt',
  BACK_VERTEX_DISTANCE: 'backVertexDistance',
  PRESCRIPTION_RIGHT_SPHERE: 'prescriptionRightEyeSphere',
  PRESCRIPTION_RIGHT_CYLINDER: 'prescriptionRightEyeCylinder',
  PRESCRIPTION_RIGHT_AXIS: 'prescriptionRightEyeAxis',
  PRESCRIPTION_RIGHT_ADD: 'prescriptionRightEyeAdd',
  PRESCRIPTION_RIGHT_DISTANCE: 'prescriptionRightEyeDistance',
  PRESCRIPTION_RIGHT_NEAR: 'prescriptionRightEyeNear',
  PRESCRIPTION_RIGHT_OPTICAL_CENTER_HEIGHT: 'prescriptionRightEyeOpticalCenterHeight',
  PRESCRIPTION_RIGHT_SEGMENT_HEIGHT: 'prescriptionRightEyeSegmentHeight',
  PRESCRIPTION_RIGHT_HPRISM: 'prescriptionRightEyeHPrism',
  PRESCRIPTION_RIGHT_VPRISM: 'prescriptionRightEyeVPrism',
  PRESCRIPTION_LEFT_SPHERE: 'prescriptionLeftEyeSphere',
  PRESCRIPTION_LEFT_CYLINDER: 'prescriptionLeftEyeCylinder',
  PRESCRIPTION_LEFT_AXIS: 'prescriptionLeftEyeAxis',
  PRESCRIPTION_LEFT_ADD: 'prescriptionLeftEyeAdd',
  PRESCRIPTION_LEFT_DISTANCE: 'prescriptionLeftEyeDistance',
  PRESCRIPTION_LEFT_NEAR: 'prescriptionLeftEyeNear',
  PRESCRIPTION_LEFT_SEGMENT_HEIGHT: 'prescriptionLeftEyeSegmentHeight',
  PRESCRIPTION_LEFT_OPTICAL_CENTER_HEIGHT: 'prescriptionLeftEyeOpticalCenterHeight',
  PRESCRIPTION_LEFT_HPRISM: 'prescriptionLeftEyeHPrism',
  PRESCRIPTION_LEFT_VPRISM: 'prescriptionLeftEyeVPrism',
  PRESCRIPTION_SPECIFIED_THICKNESS: 'prescriptionSpecifiedThickness',
  PLANO_INDICATOR: 'planoLensInd',
  LAB_SPECIAL_INSTRUCTIONS_INDICATOR: 'labSpecialInstructionIndicator'
};
