import {Injectable} from '@angular/core';
import {CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ClaimFormNavigationService} from '../../../support/claim-form-navigation/claim-form-navigation.service';

export interface CanClaimFormBeDeactivate {
  canDeactivate: (currentState: RouterStateSnapshot) => Observable<boolean> | Promise<boolean> | boolean;
}

/**
 * This class was added to handle the situation where the back button is clicked in the claim form when in edit mode so that the leave claim form warning modal renders.
 * NOTE: This modal will only render if you have navigated to the claim form from anywhere in the angular app. If you went directly to the claim from an external source
 * it will not work. Currently deactivation guards do not work with external links.
 *
 */
@Injectable()
export class LeaveClaimFormWhenInEditModeGuard implements CanDeactivate<CanClaimFormBeDeactivate> {

  constructor(
    private claimFormNavigationService: ClaimFormNavigationService
  ) { }

  /***** START - PUBLIC FUNCTIONS *****/
  /**
   * Implementing canDeactivate
   * @returns {Observable<boolean>}
   */
  canDeactivate(component: CanClaimFormBeDeactivate): Observable<boolean> {
    return this.claimFormNavigationService.allowUserToLeaveClaimFormWarningModal();
  }
  /***** END - PUBLIC FUNCTIONS *****/
}
