import {Pipe, PipeTransform} from '@angular/core';
import {isStringNullUndefinedOrEmpty} from '../utility/utility-functions/utility-functions';
import {AbstractControl} from '@angular/forms';
import {ApplicationConstants} from '../constants/application.constants';

@Pipe({
  name: 'usdCurrency'
})
export class UsdCurrencyPipe implements PipeTransform {

  transform(value: any): any {
    if (isStringNullUndefinedOrEmpty(value)) {
      return "";
    }
    let amount = value;
    if(Number.isNaN(Number(amount))) {
      return value;
    }
    let resultingAmount;
    let decimal = false;
    let amountValue: string = typeof value == "number" ? value.toString() : value;
    let initialDollarAmount: string = null;
    let dollarAmount: string = null;
    let initialCentsAmount: string = null;
    let centsAmount: string = null;
    if (amountValue.indexOf(".") >= 0) {
      decimal = true;
      let splitAmount = amountValue.split(".");
      initialDollarAmount = splitAmount[0];
      initialCentsAmount = splitAmount[1];
      dollarAmount = initialDollarAmount;
      centsAmount = initialCentsAmount.length > 2 ? initialCentsAmount.slice(0, 2) : initialCentsAmount;
    } else {

      initialDollarAmount = amountValue;
      dollarAmount = parseInt(initialDollarAmount) >= 0 ? initialDollarAmount : "0";
    }
    resultingAmount = dollarAmount;
    if (decimal) {
      resultingAmount +=  ".";
    }
    if (!isStringNullUndefinedOrEmpty(centsAmount)) {
      resultingAmount += centsAmount;
    }
    return resultingAmount;
  }

  static precedingZeroForDecimalPipe(formControl: AbstractControl) {
    if (formControl.value) {
      let dollarAmount = formControl.value.toString();
      if (dollarAmount.split('.')[0].length <= 0) {
        dollarAmount = `0${dollarAmount}`;
      }
      const pipeReturnedValue = new UsdCurrencyPipe().transform(dollarAmount);
      if (formControl.value !== pipeReturnedValue) {
        formControl.patchValue(pipeReturnedValue, ApplicationConstants.updateFormWithoutEmit);
        return pipeReturnedValue;
      }
    }
    return formControl.value;
  }

}
