import { DiagnosisCodeGrouping } from '../../../../../models/diagnosisCodeList';

export const MockDiagnosisCodeList = {
  VisionTherapy: [
    {
      conditionDescription: 'VisionTherapyEvaluation',
      diagnosis: [
        {
          diagnosisCode: 'H50.06',
          diagnosisDescription: 'Alternating esotropia with A pattern'
        },
        {
          diagnosisCode: 'H50.07',
          diagnosisDescription: 'Alternating esotropia with V pattern'
        },
        {
          diagnosisCode: 'H50.111',
          diagnosisDescription: 'Monocular exotropia, right eye'
        },
        {
          diagnosisCode: 'H50.112',
          diagnosisDescription: 'Monocular exotropia, left eye'
        },
        {
          diagnosisCode: 'H50.141',
          diagnosisDescription: 'Monocular exotropia with other noncomitancies, right eye'
        },
        {
          diagnosisCode: 'H50.142',
          diagnosisDescription: 'Monocular exotropia with other noncomitancies, left eye'
        },
        {
          diagnosisCode: 'H50.15',
          diagnosisDescription: 'Alternating exotropia'
        },
        {
          diagnosisCode: 'H50.18',
          diagnosisDescription: 'Alternating exotropia with other noncomitancies'
        },
        {
          diagnosisCode: 'H50.30',
          diagnosisDescription: 'Unspecified intermittent heterotropia'
        },
        {
          diagnosisCode: 'H50.311',
          diagnosisDescription: 'Intermittent monocular esotropia, right eye'
        },
        {
          diagnosisCode: 'H50.312',
          diagnosisDescription: 'Intermittent monocular esotropia, left eye'
        },
        {
          diagnosisCode: 'H50.32',
          diagnosisDescription: 'Intermittent alternating esotropia'
        },
        {
          diagnosisCode: 'H50.331',
          diagnosisDescription: 'Intermittent monocular exotropia, right eye'
        },
        {
          diagnosisCode: 'H50.332',
          diagnosisDescription: 'Intermittent monocular exotropia, left eye'
        },
        {
          diagnosisCode: 'H50.34',
          diagnosisDescription: 'Intermittent alternating exotropia'
        },
        {
          diagnosisCode: 'H50.51',
          diagnosisDescription: 'Esophoria'
        },
        {
          diagnosisCode: 'H50.52',
          diagnosisDescription: 'Exophoria'
        },
        {
          diagnosisCode: 'H51.11',
          diagnosisDescription: 'Convergence insufficiency'
        },
        {
          diagnosisCode: 'H51.12',
          diagnosisDescription: 'Convergence excess'
        },
        {
          diagnosisCode: 'H51.8',
          diagnosisDescription: 'Other specified disorders of binocular movement'
        },
        {
          diagnosisCode: 'H53.32',
          diagnosisDescription: 'Fusion with defective stereopsis'
        },
        {
          diagnosisCode: 'H55.81',
          diagnosisDescription: 'Saccadic eye movements'
        },
        {
          diagnosisCode: 'H55.89',
          diagnosisDescription: 'Other irregular eye movements'
        }
      ]
    },
    {
      conditionDescription: 'VisionTherapySessions',
      diagnosis: [
        {
          diagnosisCode: 'H50.041',
          diagnosisDescription: 'Monocular esotropia with other noncomitancies, right eye'
        },
        {
          diagnosisCode: 'H50.042',
          diagnosisDescription: 'Monocular esotropia with other noncomitancies, left eye'
        },
        {
          diagnosisCode: 'H50.05',
          diagnosisDescription: 'Alternating esotropia'
        },
        {
          diagnosisCode: 'H50.06',
          diagnosisDescription: 'Alternating esotropia with A pattern'
        },
        {
          diagnosisCode: 'H50.07',
          diagnosisDescription: 'Alternating esotropia with V pattern'
        },
        {
          diagnosisCode: 'H50.10',
          diagnosisDescription: 'Unspecified exotropia'
        },
        {
          diagnosisCode: 'H50.111',
          diagnosisDescription: 'Monocular exotropia, right eye'
        },
        {
          diagnosisCode: 'H50.112',
          diagnosisDescription: 'Monocular exotropia, left eye'
        },
        {
          diagnosisCode: 'H50.141',
          diagnosisDescription: 'Monocular exotropia with other noncomitancies, right eye'
        },
        {
          diagnosisCode: 'H50.142',
          diagnosisDescription: 'Monocular exotropia with other noncomitancies, left eye'
        },
        {
          diagnosisCode: 'H50.15',
          diagnosisDescription: 'Alternating exotropia'
        },
        {
          diagnosisCode: 'H50.18',
          diagnosisDescription: 'Alternating exotropia with other noncomitancies'
        },
        {
          diagnosisCode: 'H50.30',
          diagnosisDescription: 'Unspecified intermittent heterotropia'
        },
        {
          diagnosisCode: 'H50.311',
          diagnosisDescription: 'Intermittent monocular esotropia, right eye'
        },
        {
          diagnosisCode: 'H50.312',
          diagnosisDescription: 'Intermittent monocular esotropia, left eye'
        },
        {
          diagnosisCode: 'H50.32',
          diagnosisDescription: 'Intermittent alternating esotropia'
        },
        {
          diagnosisCode: 'H50.331',
          diagnosisDescription: 'Intermittent monocular exotropia, right eye'
        },
        {
          diagnosisCode: 'H50.332',
          diagnosisDescription: 'Intermittent monocular exotropia, left eye'
        },
        {
          diagnosisCode: 'H50.34',
          diagnosisDescription: 'Intermittent alternating exotropia'
        },
        {
          diagnosisCode: 'H50.51',
          diagnosisDescription: 'Esophoria'
        },
        {
          diagnosisCode: 'H50.52',
          diagnosisDescription: 'Exophoria'
        },
        {
          diagnosisCode: 'H50.53',
          diagnosisDescription: 'Vertical heterophoria'
        },
        {
          diagnosisCode: 'H50.54',
          diagnosisDescription: 'Cyclophoria'
        },
        {
          diagnosisCode: 'H50.55',
          diagnosisDescription: 'Alternating heterophoria'
        },
        {
          diagnosisCode: 'H51.0',
          diagnosisDescription: 'Palsy (spasm) of conjugate gaze'
        },
        {
          diagnosisCode: 'H51.11',
          diagnosisDescription: 'Convergence insufficiency'
        },
        {
          diagnosisCode: 'H51.12',
          diagnosisDescription: 'Convergence excess'
        },
        {
          diagnosisCode: 'H51.8',
          diagnosisDescription: 'Other specified disorders of binocular movement'
        },
        {
          diagnosisCode: 'H52.511',
          diagnosisDescription: 'Internal ophthalmoplegia (complete) (total), right eye'
        },
        {
          diagnosisCode: 'H52.512',
          diagnosisDescription: 'Internal ophthalmoplegia (complete) (total), left eye'
        },
        {
          diagnosisCode: 'H52.513',
          diagnosisDescription: 'Internal ophthalmoplegia (complete) (total), bilateral'
        },
        {
          diagnosisCode: 'H52.519',
          diagnosisDescription: 'Internal ophthalmoplegia (complete) (total), unspecified eye'
        },
        {
          diagnosisCode: 'H52.521',
          diagnosisDescription: 'Paresis of accommodation, right eye'
        },
        {
          diagnosisCode: 'H52.522',
          diagnosisDescription: 'Paresis of accommodation, left eye'
        },
        {
          diagnosisCode: 'H52.523',
          diagnosisDescription: 'Paresis of accommodation, bilateral'
        },
        {
          diagnosisCode: 'H52.529',
          diagnosisDescription: 'Paresis of accommodation, unspecified eye'
        },
        {
          diagnosisCode: 'H52.531',
          diagnosisDescription: 'Spasm of accommodation, right eye'
        },
        {
          diagnosisCode: 'H52.532',
          diagnosisDescription: 'Spasm of accommodation, left eye'
        },
        {
          diagnosisCode: 'H52.533',
          diagnosisDescription: 'Spasm of accommodation, bilateral'
        },
        {
          diagnosisCode: 'H52.539',
          diagnosisDescription: 'Spasm of accommodation, unspecified eye'
        },
        {
          diagnosisCode: 'H53.011',
          diagnosisDescription: 'Deprivation amblyopia, right eye'
        },
        {
          diagnosisCode: 'H53.012',
          diagnosisDescription: 'Deprivation amblyopia, left eye'
        },
        {
          diagnosisCode: 'H53.013',
          diagnosisDescription: 'Deprivation amblyopia, bilateral'
        },
        {
          diagnosisCode: 'H53.019',
          diagnosisDescription: 'Deprivation amblyopia, unspecified eye'
        },
        {
          diagnosisCode: 'H53.021',
          diagnosisDescription: 'Refractive amblyopia, right eye'
        },
        {
          diagnosisCode: 'H53.022',
          diagnosisDescription: 'Refractive amblyopia, left eye'
        },
        {
          diagnosisCode: 'H53.023',
          diagnosisDescription: 'Refractive amblyopia, bilateral'
        },
        {
          diagnosisCode: 'H53.029',
          diagnosisDescription: 'Refractive amblyopia, unspecified eye'
        },
        {
          diagnosisCode: 'H53.031',
          diagnosisDescription: 'Strabismic amblyopia, right eye'
        },
        {
          diagnosisCode: 'H53.032',
          diagnosisDescription: 'Strabismic amblyopia, left eye'
        },
        {
          diagnosisCode: 'H53.033',
          diagnosisDescription: 'Strabismic amblyopia, bilateral'
        },
        {
          diagnosisCode: 'H53.039',
          diagnosisDescription: 'Strabismic amblyopia, unspecified eye'
        },
        {
          diagnosisCode: 'H53.30',
          diagnosisDescription: 'Unspecified disorder of binocular vision'
        },
        {
          diagnosisCode: 'H53.32',
          diagnosisDescription: 'Fusion with defective stereopsis'
        },
        {
          diagnosisCode: 'H53.33',
          diagnosisDescription: 'Simultaneous visual perception without fusion'
        },
        {
          diagnosisCode: 'H53.34',
          diagnosisDescription: 'Suppression of binocular vision'
        },
        {
          diagnosisCode: 'H55.01',
          diagnosisDescription: 'Congenital nystagmus'
        },
        {
          diagnosisCode: 'H55.02',
          diagnosisDescription: 'Latent nystagmus'
        },
        {
          diagnosisCode: 'H55.03',
          diagnosisDescription: 'Visual deprivation nystagmus'
        },
        {
          diagnosisCode: 'H55.81',
          diagnosisDescription: 'Saccadic eye movements'
        },
        {
          diagnosisCode: 'H55.89',
          diagnosisDescription: 'Other irregular eye movements'
        }
      ]
    },
  ] as DiagnosisCodeGrouping[],
  PrimaryEyeCare: [
    {
      conditionDescription: 'This list represents commonly utilized codes and is not a complete list of billable diagnoses.'
    },
    {
      conditionDescription : 'Essential Medical Eye Care',
      diagnosis: [
        {
          diagnosisCode : 'H01.001',
          diagnosisDescription : 'Unspecified Blepharitis Right Upper Eyelid'
        },
        {
          diagnosisCode : 'H01.002',
          diagnosisDescription : 'Unspecified Blepharitis Right Lower Eyelid'
        },
        {
          diagnosisCode : 'H01.004',
          diagnosisDescription : 'Unspecified Blepharitis Left Upper Eyelid'
        },
        {
          diagnosisCode : 'H01.005',
          diagnosisDescription : 'Unspecified Blepharitis Left Lower Eyelid'
        },
        {
          diagnosisCode : 'H02.001',
          diagnosisDescription : 'Unspecified Entropion of Right Upper Eyelid'
        },
        {
          diagnosisCode : 'H02.002',
          diagnosisDescription : 'Unspecified Entropion of Right Lower Eyelid'
        },
        {
          diagnosisCode : 'H02.004',
          diagnosisDescription : 'Unspecified Entropion of Left Upper Eyelid'
        },
        {
          diagnosisCode : 'H02.005',
          diagnosisDescription : 'Unspecified Entropion of Left Lower Eyelid'
        },
        {
          diagnosisCode : 'H02.101',
          diagnosisDescription : 'Unspecified Ectropion of Right Upper Eyelid'
        },
        {
          diagnosisCode : 'H02.102',
          diagnosisDescription : 'Unspecified Ectropion of Right Lower Eyelid'
        },
        {
          diagnosisCode : 'H02.104',
          diagnosisDescription : 'Unspecified Ectropion of Left Upper Eyelid'
        },
        {
          diagnosisCode : 'H02.105',
          diagnosisDescription : 'Unspecified Ectropion of Left Lower Eyelid'
        },
        {
          diagnosisCode : 'H04.121',
          diagnosisDescription : 'Dry Eye Syndrome of Right Lacrimal Gland'
        },
        {
          diagnosisCode : 'H04.122',
          diagnosisDescription : 'Dry Eye Syndrome of Left Lacrimal Gland'
        },
        {
          diagnosisCode : 'H04.123',
          diagnosisDescription : 'Dry Eye Syndrome of Bilateral Lacrimal Glands'
        },
        {
          diagnosisCode : 'H10.31',
          diagnosisDescription : 'Unspecified Acute Conjunctivitis, Right Eye'
        },
        {
          diagnosisCode : 'H10.32',
          diagnosisDescription : 'Unspecified Acute Conjunctivitis, Left Eye'
        },
        {
          diagnosisCode : 'H10.33',
          diagnosisDescription : 'Unspecified Acute Conjunctivitis, Bilateral'
        },
        {
          diagnosisCode : 'H10.401',
          diagnosisDescription : 'Unspecified Chronic Conjunctivitis, Right Eye'
        },
        {
          diagnosisCode : 'H10.402',
          diagnosisDescription : 'Unspecified Chronic Conjunctivitis, Left Eye'
        },
        {
          diagnosisCode : 'H10.403',
          diagnosisDescription : 'Unspecified Chronic Conjunctivitis, Bilateral'
        },
        {
          diagnosisCode : 'H10.501',
          diagnosisDescription : 'Unspecified Blepharoconjunctivitis, Right Eye'
        },
        {
          diagnosisCode : 'H10.502',
          diagnosisDescription : 'Unspecified Blepharoconjunctivitis, Left Eye'
        },
        {
          diagnosisCode : 'H10.503',
          diagnosisDescription : 'Unspecified Blepharoconjunctivitis, Bilateral'
        },
        {
          diagnosisCode : 'H11.151',
          diagnosisDescription : 'Pinguecula, Right Eye'
        },
        {
          diagnosisCode : 'H11.152',
          diagnosisDescription : 'Pinguecula, Left Eye'
        },
        {
          diagnosisCode : 'H11.153',
          diagnosisDescription : 'Pinguecula, Bilateral'
        },
        {
          diagnosisCode : 'H16.001',
          diagnosisDescription : 'Unspecified Corneal Ulcer, Right Eye'
        },
        {
          diagnosisCode : 'H16.002',
          diagnosisDescription : 'Unspecified Corneal Ulcer, Left Eye'
        },
        {
          diagnosisCode : 'H16.003',
          diagnosisDescription : 'Unspecified Corneal Ulcer, Bilateral'
        },
        {
          diagnosisCode : 'H16.101',
          diagnosisDescription : 'Unspecified Superficial Keratitis, Right Eye'
        },
        {
          diagnosisCode : 'H16.102',
          diagnosisDescription : 'Unspecified Superficial Keratitis, Left Eye'
        },
        {
          diagnosisCode : 'H16.103',
          diagnosisDescription : 'Unspecified Superficial Keratitis, Bilateral'
        },
        {
          diagnosisCode : 'H16.201',
          diagnosisDescription : 'Unspecified Keratoconjunctivitis, Right Eye'
        },
        {
          diagnosisCode : 'H16.202',
          diagnosisDescription : 'Unspecified Keratoconjunctivitis, Left Eye'
        },
        {
          diagnosisCode : 'H16.203',
          diagnosisDescription : 'Unspecified Keratoconjunctivitis, Bilateral'
        },
        {
          diagnosisCode : 'H17.11',
          diagnosisDescription : 'Central Corneal Opacity, Right Eye'
        },
        {
          diagnosisCode : 'H17.12',
          diagnosisDescription : 'Central Corneal Opacity, Left Eye'
        },
        {
          diagnosisCode : 'H17.13',
          diagnosisDescription : 'Central Corneal Opacity, Bilateral'
        },
        {
          diagnosisCode : 'H17.811',
          diagnosisDescription : 'Minor Opacity of Cornea, Right Eye'
        },
        {
          diagnosisCode : 'H17.812',
          diagnosisDescription : 'Minor Opacity of Cornea, Left Eye'
        },
        {
          diagnosisCode : 'H17.813',
          diagnosisDescription : 'Minor Opacity of Cornea, Bilateral'
        },
        {
          diagnosisCode : 'H17.89',
          diagnosisDescription : 'Other Corneal Scars and Opacities'
        },
        {
          diagnosisCode : 'H17.9',
          diagnosisDescription : 'Unspecified Corneal Scar and Opacity'
        },
        {
          diagnosisCode : 'H18.601',
          diagnosisDescription : 'Keratoconus, Unspecified, Right Eye'
        },
        {
          diagnosisCode : 'H18.602',
          diagnosisDescription : 'Keratoconus, Unspecified, Left Eye'
        },
        {
          diagnosisCode : 'H18.603',
          diagnosisDescription : 'Keratoconus, Unspecified, Bilateral'
        },
        {
          diagnosisCode : 'H25.011',
          diagnosisDescription : 'Cortical Age-Related Cataract, Right Eye'
        },
        {
          diagnosisCode : 'H25.012',
          diagnosisDescription : 'Cortical Age-Related Cataract, Left Eye'
        },
        {
          diagnosisCode : 'H25.013',
          diagnosisDescription : 'Cortical Age-Related Cataract, Bilateral'
        },
        {
          diagnosisCode : 'H26.491',
          diagnosisDescription : 'Other Secondary Cataract, Right Eye'
        },
        {
          diagnosisCode : 'H26.492',
          diagnosisDescription : 'Other Secondary Cataract, Left Eye'
        },
        {
          diagnosisCode : 'H26.493',
          diagnosisDescription : 'Other Secondary Cataract, Bilateral'
        },
        {
          diagnosisCode : 'H31.011',
          diagnosisDescription : 'Macula Scars Posterior Pole (Post-Trauma) Right Eye'
        },
        {
          diagnosisCode : 'H31.012',
          diagnosisDescription : 'Macula Scars Posterior Pole (Post-Trauma) Left Eye'
        },
        {
          diagnosisCode : 'H31.013',
          diagnosisDescription : 'Macula Scars Posterior Pole (Post-Traumatic), Bilateral'
        },
        {
          diagnosisCode : 'H34.11',
          diagnosisDescription : 'Central Retinal Artery Occlusion, Right Eye'
        },
        {
          diagnosisCode : 'H34.12',
          diagnosisDescription : 'Central Retinal Artery Occlusion, Left Eye'
        },
        {
          diagnosisCode : 'H34.13',
          diagnosisDescription : 'Central Retinal Artery Occlusion, Bilateral'
        },
        {
          diagnosisCode : 'H34.211',
          diagnosisDescription : 'Partial Retinal Artery Occlusion, Right Eye'
        },
        {
          diagnosisCode : 'H34.212',
          diagnosisDescription : 'Partial Retinal Artery Occlusion, Left Eye'
        },
        {
          diagnosisCode : 'H34.213',
          diagnosisDescription : 'Partial Retinal Artery Occlusion, Bilateral'
        },
        {
          diagnosisCode : 'H34.231',
          diagnosisDescription : 'Retinal Artery Branch Occlusion, Right Eye'
        },
        {
          diagnosisCode : 'H34.232',
          diagnosisDescription : 'Retinal Artery Branch Occlusion, Left Eye'
        },
        {
          diagnosisCode : 'H34.233',
          diagnosisDescription : 'Retinal Artery Branch Occlusion, Bilateral'
        },
        {
          diagnosisCode : 'H34.821',
          diagnosisDescription : 'Venous Engorgement, Right Eye'
        },
        {
          diagnosisCode : 'H34.822',
          diagnosisDescription : 'Venous Engorgement, Left Eye'
        },
        {
          diagnosisCode : 'H34.823',
          diagnosisDescription : 'Venous Engorgement, Bilateral'
        },
        {
          diagnosisCode : 'H34.9',
          diagnosisDescription : 'Unspecified Retinal Vascular Occlusion'
        },
        {
          diagnosisCode : 'H35.031',
          diagnosisDescription : 'Hypertensive Retinopathy, Right Eye'
        },
        {
          diagnosisCode : 'H35.032',
          diagnosisDescription : 'Hypertensive Retinopathy, Left Eye'
        },
        {
          diagnosisCode : 'H35.033',
          diagnosisDescription : 'Hypertensive Retinopathy, Bilateral'
        },
        {
          diagnosisCode : 'H35.30',
          diagnosisDescription : 'Unspecified Macular Degeneration'
        },
        {
          diagnosisCode : 'H35.3111',
          diagnosisDescription : 'Nonexudative AMD, Right eye, early dry stage'
        },
        {
          diagnosisCode : 'H35.3112',
          diagnosisDescription : 'Nonexudative AMD, Right eye, intermediate dry stage'
        },
        {
          diagnosisCode : 'H35.3113',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, right eye, advanced atrophic without subfoveal involvement'
        },
        {
          diagnosisCode : 'H35.3114',
          diagnosisDescription : 'Nonexudative AMD, Right eye, advanced atrophic with subfoveal involvement'
        },
        {
          diagnosisCode : 'H35.3121',
          diagnosisDescription : 'Nonexudative AMD, Left eye, early dry stage'
        },
        {
          diagnosisCode : 'H35.3122',
          diagnosisDescription : 'Nonexudative AMD, Left eye, intermediate dry stage'
        },
        {
          diagnosisCode : 'H35.3123',
          diagnosisDescription : 'Nonexudative AMD, Left eye, advanced atrophic without subfoveal involvement'
        },
        {
          diagnosisCode : 'H35.3124',
          diagnosisDescription : 'Nonexudative AMD, Left eye, advanced atrophic with subfoveal involvement'
        },
        {
          diagnosisCode : 'H35.3131',
          diagnosisDescription : 'Nonexudative AMD, bilateral, early dry stage'
        },
        {
          diagnosisCode : 'H35.3132',
          diagnosisDescription : 'Nonexudative AMD, bilateral, intermediate dry stage'
        },
        {
          diagnosisCode : 'H35.3133',
          diagnosisDescription : 'Nonexudative AMD, bilateral, advanced atrophic without subfoveal involvement'
        },
        {
          diagnosisCode : 'H35.3134',
          diagnosisDescription : 'Nonexudative AMD, bilateral, advanced atrophic with subfoveal involvement'
        },
        {
          diagnosisCode : 'H35.3211',
          diagnosisDescription : 'Exudative AMD, Right eye, with active choroidal neovascularization'
        },
        {
          diagnosisCode : 'H35.3212',
          diagnosisDescription : 'Exudative AMD, Right eye, with inactive choroidal neovascularization'
        },
        {
          diagnosisCode : 'H35.3213',
          diagnosisDescription : 'Exudative AMD, Right eye, with inactive scar'
        },
        {
          diagnosisCode : 'H35.3221',
          diagnosisDescription : 'Exudative AMD, Left eye, with active choroidal neovascularization'
        },
        {
          diagnosisCode : 'H35.3222',
          diagnosisDescription : 'Exudative AMD, Left eye, with inactive choroidal neovascularization'
        },
        {
          diagnosisCode : 'H35.3223',
          diagnosisDescription : 'Exudative AMD, Left eye, with inactive scar'
        },
        {
          diagnosisCode : 'H35.3231',
          diagnosisDescription : 'Exudative AMD, bilateral, with active choroidal neovascularization'
        },
        {
          diagnosisCode : 'H35.3232',
          diagnosisDescription : 'Exudative AMD, bilateral, with inactive choroidal neovascularization'
        },
        {
          diagnosisCode : 'H35.3233',
          diagnosisDescription : 'Exudative AMD, bilateral, with inactive scar'
        },
        {
          diagnosisCode : 'H35.361',
          diagnosisDescription : 'Drusen (Degenerative) of Macula, Right Eye'
        },
        {
          diagnosisCode : 'H35.362',
          diagnosisDescription : 'Drusen (Degenerative) of Macula, Left Eye'
        },
        {
          diagnosisCode : 'H35.363',
          diagnosisDescription : 'Drusen (Degenerative) of Macula, Bilateral'
        },
        {
          diagnosisCode : 'H35.371',
          diagnosisDescription : 'Puckering of Macula, Right Eye'
        },
        {
          diagnosisCode : 'H35.372',
          diagnosisDescription : 'Puckering of Macula, Left Eye'
        },
        {
          diagnosisCode : 'H35.373',
          diagnosisDescription : 'Puckering of Macula, Bilateral'
        },
        {
          diagnosisCode : 'H35.89',
          diagnosisDescription : 'Other Specified Retinal Disorders'
        },
        {
          diagnosisCode : 'H35.9',
          diagnosisDescription : 'Unspecified Retinal Disorder'
        },
        {
          diagnosisCode : 'H40.001',
          diagnosisDescription : 'Preglaucoma, Unspecified, Right Eye'
        },
        {
          diagnosisCode : 'H40.002',
          diagnosisDescription : 'Preglaucoma, Unspecified, Left Eye'
        },
        {
          diagnosisCode : 'H40.003',
          diagnosisDescription : 'Preglaucoma, Unspecified, Bilateral'
        },
        {
          diagnosisCode : 'H40.011',
          diagnosisDescription : 'Open Angle with Borderline Findings, Low Risk, Right Eye'
        },
        {
          diagnosisCode : 'H40.012',
          diagnosisDescription : 'Open Angle with Borderline Findings, Low Risk, Left Eye'
        },
        {
          diagnosisCode : 'H40.013',
          diagnosisDescription : 'Open Angle with Borderline Findings, Low Risk, Bilateral'
        },
        {
          diagnosisCode : 'H40.021',
          diagnosisDescription : 'Open Angle with Borderline Findings, High Risk Right Eye'
        },
        {
          diagnosisCode : 'H40.022',
          diagnosisDescription : 'Open Angle with Borderline Findings, High Risk Left Eye'
        },
        {
          diagnosisCode : 'H40.023',
          diagnosisDescription : 'Open Angle with Borderline Findings, High Risk, Bilateral'
        },
        {
          diagnosisCode : 'H40.9',
          diagnosisDescription : 'Unspecified Glaucoma'
        },
        {
          diagnosisCode : 'H43.811',
          diagnosisDescription : 'Vitreous Degeneration, Right Eye'
        },
        {
          diagnosisCode : 'H43.812',
          diagnosisDescription : 'Vitreous Degeneration, Left Eye'
        },
        {
          diagnosisCode : 'H43.813',
          diagnosisDescription : 'Vitreous Degeneration, Bilateral'
        },
        {
          diagnosisCode : 'H47.321',
          diagnosisDescription : 'Drusen of Optic Disc, Right Eye'
        },
        {
          diagnosisCode : 'H47.322',
          diagnosisDescription : 'Drusen of Optic Disc, Left Eye'
        },
        {
          diagnosisCode : 'H47.323',
          diagnosisDescription : 'Drusen of Optic Disc, Bilateral'
        },
        {
          diagnosisCode : 'H53.021',
          diagnosisDescription : 'Refractive Amblyopia, Right Eye'
        },
        {
          diagnosisCode : 'H53.022',
          diagnosisDescription : 'Refractive Amblyopia, Left Eye'
        },
        {
          diagnosisCode : 'H53.023',
          diagnosisDescription : 'Refractive Amblyopia, Bilateral'
        },
        {
          diagnosisCode : 'H53.031',
          diagnosisDescription : 'Strabismic Amblyopia, Right Eye'
        },
        {
          diagnosisCode : 'H53.032',
          diagnosisDescription : 'Strabismic Amblyopia, Left Eye'
        },
        {
          diagnosisCode : 'H53.033',
          diagnosisDescription : 'Strabismic Amblyopia, Bilateral'
        },
        {
          diagnosisCode : 'H53.10',
          diagnosisDescription : 'Unspecified Subjective Visual Disturbances'
        },
        {
          diagnosisCode : 'H53.141',
          diagnosisDescription : 'Visual Discomfort, Right Eye'
        },
        {
          diagnosisCode : 'H53.142',
          diagnosisDescription : 'Visual Discomfort, Left Eye'
        },
        {
          diagnosisCode : 'H53.143',
          diagnosisDescription : 'Visual Discomfort, Bilateral'
        },
        {
          diagnosisCode : 'H57.11',
          diagnosisDescription : 'Ocular Pain, Right Eye'
        },
        {
          diagnosisCode : 'H57.12',
          diagnosisDescription : 'Ocular Pain, Left Eye'
        },
        {
          diagnosisCode : 'H57.13',
          diagnosisDescription : 'Ocular Pain, Bilateral'
        },
        {
          diagnosisCode : 'S00.251A',
          diagnosisDescription : 'Superficial Foreign Body of Right Eyelid and Periocular Area, Initial'
        },
        {
          diagnosisCode : 'S00.252A',
          diagnosisDescription : 'Superficial Foreign Body of Left Eyelid and Periocular Area, Initial'
        },
        {
          diagnosisCode : 'T15.01XA',
          diagnosisDescription : 'Foreign Body In Cornea, Right Eye, Initial Encounter'
        },
        {
          diagnosisCode : 'T15.02XA',
          diagnosisDescription : 'Foreign Body In Cornea, Left Eye, Initial Encounter'
        },
        {
          diagnosisCode : 'T15.11XA',
          diagnosisDescription : 'Foreign Body In Conjunctival Sac, Right Eye, Initial Encounter'
        },
        {
          diagnosisCode : 'T15.12XA',
          diagnosisDescription : 'Foreign Body In Conjunctival Sac, Left Eye, Initial Encounter'
        },
        {
          diagnosisCode : 'Z96.1',
          diagnosisDescription : 'Presence of Intraocular Lens'
        },
        {
          diagnosisCode : 'Z98.83',
          diagnosisDescription : 'Filtering (vitreous) bleb after glaucoma surgery status'
        }
      ]
    },
    {
      conditionDescription : 'Retinal Screening',
      diagnosis: [
        {
          diagnosisCode : 'Z13.5',
          diagnosisDescription : 'Encounter for screening for eye and ear disorders'
        },
        {
          diagnosisCode : 'E10.9',
          diagnosisDescription : 'Type 1 diabetes mellitus without complications'
        },
        {
          diagnosisCode : 'E11.9',
          diagnosisDescription : 'Type 2 diabetes mellitus without complications'
        },
        {
          diagnosisCode : 'E13.9',
          diagnosisDescription : 'Other specified diabetes mellitus without complications'
        }
      ]
    },
    {
      conditionDescription: 'Diabetic Retinopathy',
      diagnosis: [
        {
          diagnosisCode : 'E10.311',
          diagnosisDescription : 'Type 1 diabetes mellitus with unspecified diabetic retinopathy with macular edema'
        },
        {
          diagnosisCode : 'E10.319',
          diagnosisDescription : 'Type 1 diabetes mellitus with unspecified diabetic retinopathy without macular edema'
        },
        {
          diagnosisCode : 'E10.3211',
          diagnosisDescription : 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3212',
          diagnosisDescription : 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3213',
          diagnosisDescription : 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.3291',
          diagnosisDescription : 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3292',
          diagnosisDescription : 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3293',
          diagnosisDescription : 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.3311',
          diagnosisDescription : 'Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3312',
          diagnosisDescription : 'Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3313',
          diagnosisDescription : 'Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.3391',
          diagnosisDescription : 'Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3392',
          diagnosisDescription : 'Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3393',
          diagnosisDescription : 'Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.3411',
          diagnosisDescription : 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3412',
          diagnosisDescription : 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3413',
          diagnosisDescription : 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.3491',
          diagnosisDescription : 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3492',
          diagnosisDescription : 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3493',
          diagnosisDescription : 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.3511',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3512',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3513',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.3521',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, right eye'
        },
        {
          diagnosisCode : 'E10.3522',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, left eye'
        },
        {
          diagnosisCode : 'E10.3523',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, bilateral'
        },
        {
          diagnosisCode : 'E10.3531',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, right eye'
        },
        {
          diagnosisCode : 'E10.3532',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, left eye'
        },
        {
          diagnosisCode : 'E10.3533',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, bilateral'
        },
        {
          diagnosisCode : 'E10.3541',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, right eye'
        },
        {
          diagnosisCode : 'E10.3542',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, left eye'
        },
        {
          diagnosisCode : 'E10.3543',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, bilateral'
        },
        {
          diagnosisCode : 'E10.3551',
          diagnosisDescription : 'Type 1 diabetes mellitus with stable proliferative diabetic retinopathy, right eye'
        },
        {
          diagnosisCode : 'E10.3552',
          diagnosisDescription : 'Type 1 diabetes mellitus with stable proliferative diabetic retinopathy, left eye'
        },
        {
          diagnosisCode : 'E10.3553',
          diagnosisDescription : 'Type 1 diabetes mellitus with stable proliferative diabetic retinopathy, bilateral'
        },
        {
          diagnosisCode : 'E10.3591',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3592',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3593',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.311',
          diagnosisDescription : 'Type 2 diabetes mellitus with unspecified diabetic retinopathy with macular edema'
        },
        {
          diagnosisCode : 'E11.319',
          diagnosisDescription : 'Type 2 diabetes mellitus with unspecified diabetic retinopathy without macular edema'
        },
        {
          diagnosisCode : 'E11.3211',
          diagnosisDescription : 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3212',
          diagnosisDescription : 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3213',
          diagnosisDescription : 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.3291',
          diagnosisDescription : 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3292',
          diagnosisDescription : 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3293',
          diagnosisDescription : 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.3311',
          diagnosisDescription : 'Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3312',
          diagnosisDescription : 'Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3313',
          diagnosisDescription : 'Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.3391',
          diagnosisDescription : 'Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3392',
          diagnosisDescription : 'Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3393',
          diagnosisDescription : 'Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.3411',
          diagnosisDescription : 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3412',
          diagnosisDescription : 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3413',
          diagnosisDescription : 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.3491',
          diagnosisDescription : 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3492',
          diagnosisDescription : 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3493',
          diagnosisDescription : 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.3511',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3512',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3513',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.3521',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, right eye'
        },
        {
          diagnosisCode : 'E11.3522',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, left eye'
        },
        {
          diagnosisCode : 'E11.3523',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, bilateral'
        },
        {
          diagnosisCode : 'E11.3531',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, right eye'
        },
        {
          diagnosisCode : 'E11.3532',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, left eye'
        },
        {
          diagnosisCode : 'E11.3533',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, bilateral'
        },
        {
          diagnosisCode : 'E11.3541',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, right eye'
        },
        {
          diagnosisCode : 'E11.3542',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, left eye'
        },
        {
          diagnosisCode : 'E11.3543',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, bilateral'
        },
        {
          diagnosisCode : 'E11.3551',
          diagnosisDescription : 'Type 2 diabetes mellitus with stable proliferative diabetic retinopathy, right eye'
        },
        {
          diagnosisCode : 'E11.3552',
          diagnosisDescription : 'Type 2 diabetes mellitus with stable proliferative diabetic retinopathy, left eye'
        },
        {
          diagnosisCode : 'E11.3553',
          diagnosisDescription : 'Type 2 diabetes mellitus with stable proliferative diabetic retinopathy, bilateral'
        },
        {
          diagnosisCode : 'E11.3591',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3592',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3593',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.311',
          diagnosisDescription : 'Other specified diabetes mellitus with unspecified diabetic retinopathy with macular edema'
        },
        {
          diagnosisCode : 'E13.319',
          diagnosisDescription : 'Other specified diabetes mellitus with unspecified diabetic retinopathy without macular edema'
        },
        {
          diagnosisCode : 'E13.3211',
          diagnosisDescription : 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3212',
          diagnosisDescription : 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3213',
          diagnosisDescription : 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.3291',
          diagnosisDescription : 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3292',
          diagnosisDescription : 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3293',
          diagnosisDescription : 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.3311',
          diagnosisDescription : 'Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3312',
          diagnosisDescription : 'Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3313',
          diagnosisDescription : 'Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.3391',
          diagnosisDescription : 'Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3392',
          diagnosisDescription : 'Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3393',
          diagnosisDescription : 'Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.3411',
          diagnosisDescription : 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3412',
          diagnosisDescription : 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3413',
          diagnosisDescription : 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.3491',
          diagnosisDescription : 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3492',
          diagnosisDescription : 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3493',
          diagnosisDescription : 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.3511',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3512',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3513',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.3521',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, right eye'
        },
        {
          diagnosisCode : 'E13.3522',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, left eye'
        },
        {
          diagnosisCode : 'E13.3523',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, bilateral'
        },
        {
          diagnosisCode : 'E13.3531',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, right eye'
        },
        {
          diagnosisCode : 'E13.3532',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, left eye'
        },
        {
          diagnosisCode : 'E13.3533',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, bilateral'
        },
        {
          diagnosisCode : 'E13.3541',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, right eye'
        },
        {
          diagnosisCode : 'E13.3542',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, left eye'
        },
        {
          diagnosisCode : 'E13.3543',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, bilateral'
        },
        {
          diagnosisCode : 'E13.3551',
          diagnosisDescription : 'Other specified diabetes mellitus with stable proliferative diabetic retinopathy, right eye'
        },
        {
          diagnosisCode : 'E13.3552',
          diagnosisDescription : 'Other specified diabetes mellitus with stable proliferative diabetic retinopathy, left eye'
        },
        {
          diagnosisCode : 'E13.3553',
          diagnosisDescription : 'Other specified diabetes mellitus with stable proliferative diabetic retinopathy, bilateral'
        },
        {
          diagnosisCode : 'E13.3591',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3592',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3593',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.311',
          diagnosisDescription : 'Type 1 diabetes mellitus with unspecified diabetic retinopathy with macular edema'
        },
        {
          diagnosisCode : 'E10.319',
          diagnosisDescription : 'Type 1 diabetes mellitus with unspecified diabetic retinopathy without macular edema'
        },
        {
          diagnosisCode : 'E10.3211',
          diagnosisDescription : 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3212',
          diagnosisDescription : 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3213',
          diagnosisDescription : 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.3291',
          diagnosisDescription : 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3292',
          diagnosisDescription : 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3293',
          diagnosisDescription : 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.3411',
          diagnosisDescription : 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3412',
          diagnosisDescription : 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3413',
          diagnosisDescription : 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.3491',
          diagnosisDescription : 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3492',
          diagnosisDescription : 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3493',
          diagnosisDescription : 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.3511',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3512',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3513',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E10.3591',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E10.3592',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E10.3593',
          diagnosisDescription : 'Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.311',
          diagnosisDescription : 'Type 2 diabetes mellitus with unspecified diabetic retinopathy with macular edema'
        },
        {
          diagnosisCode : 'E11.319',
          diagnosisDescription : 'Type 2 diabetes mellitus with unspecified diabetic retinopathy without macular edema'
        },
        {
          diagnosisCode : 'E11.3211',
          diagnosisDescription : 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3212',
          diagnosisDescription : 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3213',
          diagnosisDescription : 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.3291',
          diagnosisDescription : 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3292',
          diagnosisDescription : 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3293',
          diagnosisDescription : 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.3411',
          diagnosisDescription : 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3412',
          diagnosisDescription : 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3413',
          diagnosisDescription : 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.3491',
          diagnosisDescription : 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3492',
          diagnosisDescription : 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3493',
          diagnosisDescription : 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.3511',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3512',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3513',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E11.3591',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E11.3592',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E11.3593',
          diagnosisDescription : 'Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.311',
          diagnosisDescription : 'Other specified diabetes mellitus with unspecified diabetic retinopathy with macular edema'
        },
        {
          diagnosisCode : 'E13.319',
          diagnosisDescription : 'Other specified diabetes mellitus with unspecified diabetic retinopathy without macular edema'
        },
        {
          diagnosisCode : 'E13.3211',
          diagnosisDescription : 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3212',
          diagnosisDescription : 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3213',
          diagnosisDescription : 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.3291',
          diagnosisDescription : 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3292',
          diagnosisDescription : 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3293',
          diagnosisDescription : 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.3411',
          diagnosisDescription : 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3412',
          diagnosisDescription : 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3413',
          diagnosisDescription : 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.3491',
          diagnosisDescription : 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3492',
          diagnosisDescription : 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3493',
          diagnosisDescription : 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.3511',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3512',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3513',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode : 'E13.3591',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode : 'E13.3592',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode : 'E13.3593',
          diagnosisDescription : 'Other specified diabetes mellitus with proliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode : 'H21.1X1',
          diagnosisDescription : 'Other vascular disorders of iris and ciliary body, right eye'
        },
        {
          diagnosisCode : 'H21.1X2',
          diagnosisDescription : 'Other vascular disorders of iris and ciliary body, left eye'
        },
        {
          diagnosisCode : 'H21.1X3',
          diagnosisDescription : 'Other vascular disorders of iris and ciliary body, bilateral'
        }
      ]
    },
    {
      conditionDescription: 'High Cholesterol',
      diagnosis: [
        {
          diagnosisCode : 'E78.00',
          diagnosisDescription : 'Pure hypercholesterolemia, unspecified'
        },
        {
          diagnosisCode : 'E78.1',
          diagnosisDescription : 'Pure hyperglyceridemia'
        },
        {
          diagnosisCode : 'E78.2',
          diagnosisDescription : 'Mixed hyperlipidemia'
        },
        {
          diagnosisCode : 'E78.4',
          diagnosisDescription : 'Other hyperlipidemia'
        },
        {
          diagnosisCode : 'E78.5',
          diagnosisDescription : 'Hyperlipidemia, unspecified'
        }
      ]
    },
    {
      conditionDescription: 'Hypertension',
      diagnosis: [
        {
          diagnosisCode: 'I10',
          diagnosisDescription: 'Essential (primary) hypertension'
        },
        {
          diagnosisCode : 'I97.3',
          diagnosisDescription : 'Postprocedural hypertension'
        },
        {
          diagnosisCode : 'H35.031',
          diagnosisDescription : 'Hypertensive Retinopathy, Right Eye'
        },
        {
          diagnosisCode : 'H35.032',
          diagnosisDescription : 'Hypertensive Retinopathy, Left Eye'
        },
        {
          diagnosisCode : 'H35.033',
          diagnosisDescription : 'Hypertensive Retinopathy, Bilateral'
        }
      ]
    },
    {
      conditionDescription: 'AMD',
      diagnosis: [
        {
          diagnosisCode : 'H35.30',
          diagnosisDescription : 'Unspecified macular degeneration'
        },
        {
          diagnosisCode : 'H35.3111',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, right eye, early dry stage'
        },
        {
          diagnosisCode : 'H35.3112',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, right eye, intermediate dry stage'
        },
        {
          diagnosisCode : 'H35.3113',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, right eye, advanced atrophic without subfoveal involvement'
        },
        {
          diagnosisCode : 'H35.3114',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, right eye, advanced atrophic with subfoveal involvement'
        },
        {
          diagnosisCode : 'H35.3121',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, left eye, early dry stage'
        },
        {
          diagnosisCode : 'H35.3122',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, left eye, intermediate dry stage'
        },
        {
          diagnosisCode : 'H35.3123',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, left eye, advanced atrophic without subfoveal involvement'
        },
        {
          diagnosisCode : 'H35.3124',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, left eye, advanced atrophic with subfoveal involvement'
        },
        {
          diagnosisCode : 'H35.3131',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, bilateral, early dry stage'
        },
        {
          diagnosisCode : 'H35.3132',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, bilateral, intermediate dry stage'
        },
        {
          diagnosisCode : 'H35.3133',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, bilateral, advanced atrophic without subfoveal involvement'
        },
        {
          diagnosisCode : 'H35.3134',
          diagnosisDescription : 'Nonexudative age-related macular degeneration, bilateral, advanced atrophic with subfoveal involvement'
        },
        {
          diagnosisCode : 'H35.3211',
          diagnosisDescription : 'Exudative age-related macular degeneration, right eye, with active choroidal neovascularization'
        },
        {
          diagnosisCode : 'H35.3212',
          diagnosisDescription : 'Exudative age-related macular degeneration, right eye, with inactive choroidal neovascularization'
        },
        {
          diagnosisCode : 'H35.3213',
          diagnosisDescription : 'Exudative age-related macular degeneration, right eye, with inactive scar'
        },
        {
          diagnosisCode : 'H35.3221',
          diagnosisDescription : 'Exudative age-related macular degeneration, left eye, with active choroidal neovascularization'
        },
        {
          diagnosisCode : 'H35.3222',
          diagnosisDescription : 'Exudative age-related macular degeneration, left eye, with inactive choroidal neovascularization'
        },
        {
          diagnosisCode : 'H35.3223',
          diagnosisDescription : 'Exudative age-related macular degeneration, left eye, with inactive scar'
        },
        {
          diagnosisCode : 'H35.3231',
          diagnosisDescription : 'Exudative age-related macular degeneration, bilateral, with active choroidal neovascularization'
        },
        {
          diagnosisCode : 'H35.3232',
          diagnosisDescription : 'Exudative age-related macular degeneration, bilateral, with inactive choroidal neovascularization'
        },
        {
          diagnosisCode : 'H35.3233',
          diagnosisDescription : 'Exudative age-related macular degeneration, bilateral, with inactive scar'
        },
        {
          diagnosisCode : 'H35.351',
          diagnosisDescription : 'Cystoid macular degeneration, right eye'
        },
        {
          diagnosisCode : 'H35.352',
          diagnosisDescription : 'Cystoid macular degeneration, left eye'
        },
        {
          diagnosisCode : 'H35.353',
          diagnosisDescription : 'Cystoid macular degeneration, bilateral'
        }
      ]
    },
    {
      conditionDescription: 'Arcus',
      diagnosis: [
        {
          diagnosisCode : 'H18.411',
          diagnosisDescription : 'Arcus senilis, right eye'
        },
        {
          diagnosisCode : 'H18.412',
          diagnosisDescription : 'Arcus senilis, left eye'
        },
        {
          diagnosisCode : 'H18.413',
          diagnosisDescription : 'Arcus senilis, bilateral'
        }
      ]
    },
    {
      conditionDescription: 'Glaucoma',
      diagnosis: [
        {
          diagnosisCode : 'H40.001',
          diagnosisDescription : 'Preglaucoma, Unspecified, Right Eye'
        },
        {
          diagnosisCode : 'H40.002',
          diagnosisDescription : 'Preglaucoma, Unspecified, Left Eye'
        },
        {
          diagnosisCode : 'H40.003',
          diagnosisDescription : 'Preglaucoma, Unspecified, Bilateral'
        },
        {
          diagnosisCode : 'H40.021',
          diagnosisDescription : 'Open angle with borderline findings, high risk, right eye'
        },
        {
          diagnosisCode : 'H40.022',
          diagnosisDescription : 'Open angle with borderline findings, high risk, left eye'
        },
        {
          diagnosisCode : 'H40.023',
          diagnosisDescription : 'Open angle with borderline findings, high risk, bilateral'
        },
        {
          diagnosisCode : 'H40.051',
          diagnosisDescription : 'Ocular Hypertension, Right Eye'
        },
        {
          diagnosisCode : 'H40.052',
          diagnosisDescription : 'Ocular Hypertension, Left Eye'
        },
        {
          diagnosisCode : 'H40.053',
          diagnosisDescription : 'Ocular Hypertension, Bilateral'
        },
        {
          diagnosisCode : 'H40.061',
          diagnosisDescription : 'Primary angle closure without glaucoma damage, right eye'
        },
        {
          diagnosisCode : 'H40.10X2',
          diagnosisDescription : 'Unspecified open-angle glaucoma, moderate stage'
        },
        {
          diagnosisCode : 'H40.10X3',
          diagnosisDescription : 'Unspecified open-angle glaucoma, severe stage'
        },
        {
          diagnosisCode : 'H40.10X4',
          diagnosisDescription : 'Unspecified open-angle glaucoma, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.1111',
          diagnosisDescription : 'Primary open-angle glaucoma, right eye, mild stage'
        },
        {
          diagnosisCode : 'H40.1112',
          diagnosisDescription : 'Primary open-angle glaucoma, right eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.1113',
          diagnosisDescription : 'Primary open-angle glaucoma, right eye, severe stage'
        },
        {
          diagnosisCode : 'H40.1114',
          diagnosisDescription : 'Primary open-angle glaucoma, right eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.1121',
          diagnosisDescription : 'Primary open-angle glaucoma, left eye, mild stage'
        },
        {
          diagnosisCode : 'H40.1122',
          diagnosisDescription : 'Primary open-angle glaucoma, left eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.1123',
          diagnosisDescription : 'Primary open-angle glaucoma, left eye, severe stage'
        },
        {
          diagnosisCode : 'H40.1124',
          diagnosisDescription : 'Primary open-angle glaucoma, left eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.1131',
          diagnosisDescription : 'Primary open-angle glaucoma, bilateral, mild stage'
        },
        {
          diagnosisCode : 'H40.1132',
          diagnosisDescription : 'Primary open-angle glaucoma, bilateral, moderate stage'
        },
        {
          diagnosisCode : 'H40.1133',
          diagnosisDescription : 'Primary open-angle glaucoma, bilateral, severe stage'
        },
        {
          diagnosisCode : 'H40.1134',
          diagnosisDescription : 'Primary open-angle glaucoma, bilateral, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.1211',
          diagnosisDescription : 'Low-tension glaucoma, right eye, mild stage'
        },
        {
          diagnosisCode : 'H40.1212',
          diagnosisDescription : 'Low-tension glaucoma, right eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.1213',
          diagnosisDescription : 'Low-tension glaucoma, right eye, severe stage'
        },
        {
          diagnosisCode : 'H40.1214',
          diagnosisDescription : 'Low-tension glaucoma, right eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.1221',
          diagnosisDescription : 'Low-tension glaucoma, left eye, mild stage'
        },
        {
          diagnosisCode : 'H40.1222',
          diagnosisDescription : 'Low-tension glaucoma, left eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.1223',
          diagnosisDescription : 'Low-tension glaucoma, left eye, severe stage'
        },
        {
          diagnosisCode : 'H40.1224',
          diagnosisDescription : 'Low-tension glaucoma, left eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.1231',
          diagnosisDescription : 'Low-tension glaucoma, bilateral, mild stage'
        },
        {
          diagnosisCode : 'H40.1232',
          diagnosisDescription : 'Low-tension glaucoma, bilateral, moderate stage'
        },
        {
          diagnosisCode : 'H40.1233',
          diagnosisDescription : 'Low-tension glaucoma, bilateral, severe stage'
        },
        {
          diagnosisCode : 'H40.1234',
          diagnosisDescription : 'Low-tension glaucoma, bilateral, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.1311',
          diagnosisDescription : 'Pigmentary glaucoma, right eye, mild stage'
        },
        {
          diagnosisCode : 'H40.1312',
          diagnosisDescription : 'Pigmentary glaucoma, right eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.1313',
          diagnosisDescription : 'Pigmentary glaucoma, right eye, severe stage'
        },
        {
          diagnosisCode : 'H40.1314',
          diagnosisDescription : 'Pigmentary glaucoma, right eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.1321',
          diagnosisDescription : 'Pigmentary glaucoma, left eye, mild stage'
        },
        {
          diagnosisCode : 'H40.1322',
          diagnosisDescription : 'Pigmentary glaucoma, left eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.1323',
          diagnosisDescription : 'Pigmentary glaucoma, left eye, severe stage'
        },
        {
          diagnosisCode : 'H40.1324',
          diagnosisDescription : 'Pigmentary glaucoma, left eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.1331',
          diagnosisDescription : 'Pigmentary glaucoma, bilateral, mild stage'
        },
        {
          diagnosisCode : 'H40.1332',
          diagnosisDescription : 'Pigmentary glaucoma, bilateral, moderate stage'
        },
        {
          diagnosisCode : 'H40.1333',
          diagnosisDescription : 'Pigmentary glaucoma, bilateral, severe stage'
        },
        {
          diagnosisCode : 'H40.1334',
          diagnosisDescription : 'Pigmentary glaucoma, bilateral, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.1411',
          diagnosisDescription : 'Capsular glaucoma with pseudoexfoliation of lens, right eye, mild stage'
        },
        {
          diagnosisCode : 'H40.1412',
          diagnosisDescription : 'Capsular glaucoma with pseudoexfoliation of lens, right eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.1413',
          diagnosisDescription : 'Capsular glaucoma with pseudoexfoliation of lens, right eye, severe stage'
        },
        {
          diagnosisCode : 'H40.1414',
          diagnosisDescription : 'Capsular glaucoma with pseudoexfoliation of lens, right eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.1421',
          diagnosisDescription : 'Capsular glaucoma with pseudoexfoliation of lens, left eye, mild stage'
        },
        {
          diagnosisCode : 'H40.1422',
          diagnosisDescription : 'Capsular glaucoma with pseudoexfoliation of lens, left eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.1423',
          diagnosisDescription : 'Capsular glaucoma with pseudoexfoliation of lens, left eye, severe stage'
        },
        {
          diagnosisCode : 'H40.1424',
          diagnosisDescription : 'Capsular glaucoma with pseudoexfoliation of lens, left eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.1431',
          diagnosisDescription : 'Capsular glaucoma with pseudoexfoliation of lens, bilateral, mild stage'
        },
        {
          diagnosisCode : 'H40.1432',
          diagnosisDescription : 'Capsular glaucoma with pseudoexfoliation of lens, bilateral, moderate stage'
        },
        {
          diagnosisCode : 'H40.1433',
          diagnosisDescription : 'Capsular glaucoma with pseudoexfoliation of lens, bilateral, severe stage'
        },
        {
          diagnosisCode : 'H40.1434',
          diagnosisDescription : 'Capsular glaucoma with pseudoexfoliation of lens, bilateral, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.151',
          diagnosisDescription : 'Residual stage of open-angle glaucoma, right eye'
        },
        {
          diagnosisCode : 'H40.152',
          diagnosisDescription : 'Residual stage of open-angle glaucoma, left eye'
        },
        {
          diagnosisCode : 'H40.153',
          diagnosisDescription : 'Residual stage of open-angle glaucoma, bilateral'
        },
        {
          diagnosisCode : 'H40.20X1',
          diagnosisDescription : 'Unspecified primary angle-closure glaucoma, mild stage'
        },
        {
          diagnosisCode : 'H40.20X2',
          diagnosisDescription : 'Unspecified primary angle-closure glaucoma, moderate stage'
        },
        {
          diagnosisCode : 'H40.20X3',
          diagnosisDescription : 'Unspecified primary angle-closure glaucoma, severe stage'
        },
        {
          diagnosisCode : 'H40.20X4',
          diagnosisDescription : 'Unspecified primary angle-closure glaucoma, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.211',
          diagnosisDescription : 'Acute angle-closure glaucoma, right eye'
        },
        {
          diagnosisCode : 'H40.212',
          diagnosisDescription : 'Acute angle-closure glaucoma, left eye'
        },
        {
          diagnosisCode : 'H40.213',
          diagnosisDescription : 'Acute angle-closure glaucoma, bilateral'
        },
        {
          diagnosisCode : 'H40.2211',
          diagnosisDescription : 'Chronic angle-closure glaucoma, right eye, mild stage'
        },
        {
          diagnosisCode : 'H40.2212',
          diagnosisDescription : 'Chronic angle-closure glaucoma, right eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.2213',
          diagnosisDescription : 'Chronic angle-closure glaucoma, right eye, severe stage'
        },
        {
          diagnosisCode : 'H40.2214',
          diagnosisDescription : 'Chronic angle-closure glaucoma, right eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.2221',
          diagnosisDescription : 'Chronic angle-closure glaucoma, left eye, mild stage'
        },
        {
          diagnosisCode : 'H40.2222',
          diagnosisDescription : 'Chronic angle-closure glaucoma, left eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.2223',
          diagnosisDescription : 'Chronic angle-closure glaucoma, left eye, severe stage'
        },
        {
          diagnosisCode : 'H40.2224',
          diagnosisDescription : 'Chronic angle-closure glaucoma, left eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.2231',
          diagnosisDescription : 'Chronic angle-closure glaucoma, bilateral, mild stage'
        },
        {
          diagnosisCode : 'H40.2232',
          diagnosisDescription : 'Chronic angle-closure glaucoma, bilateral, moderate stage'
        },
        {
          diagnosisCode : 'H40.2233',
          diagnosisDescription : 'Chronic angle-closure glaucoma, bilateral, severe stage'
        },
        {
          diagnosisCode : 'H40.2234',
          diagnosisDescription : 'Chronic angle-closure glaucoma, bilateral, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.231',
          diagnosisDescription : 'Intermittent angle-closure glaucoma, right eye'
        },
        {
          diagnosisCode : 'H40.232',
          diagnosisDescription : 'Intermittent angle-closure glaucoma, left eye'
        },
        {
          diagnosisCode : 'H40.233',
          diagnosisDescription : 'Intermittent angle-closure glaucoma, bilateral'
        },
        {
          diagnosisCode : 'H40.241',
          diagnosisDescription : 'Residual stage of angle-closure glaucoma, right eye'
        },
        {
          diagnosisCode : 'H40.242',
          diagnosisDescription : 'Residual stage of angle-closure glaucoma, left eye'
        },
        {
          diagnosisCode : 'H40.243',
          diagnosisDescription : 'Residual stage of angle-closure glaucoma, bilateral'
        },
        {
          diagnosisCode : 'H40.31X1',
          diagnosisDescription : 'Glaucoma secondary to eye trauma, right eye, mild stage'
        },
        {
          diagnosisCode : 'H40.31X2',
          diagnosisDescription : 'Glaucoma secondary to eye trauma, right eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.31X3',
          diagnosisDescription : 'Glaucoma secondary to eye trauma, right eye, severe stage'
        },
        {
          diagnosisCode : 'H40.31X4',
          diagnosisDescription : 'Glaucoma secondary to eye trauma, right eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.32X1',
          diagnosisDescription : 'Glaucoma secondary to eye trauma, left eye, mild stage'
        },
        {
          diagnosisCode : 'H40.32X2',
          diagnosisDescription : 'Glaucoma secondary to eye trauma, left eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.32X3',
          diagnosisDescription : 'Glaucoma secondary to eye trauma, left eye, severe stage'
        },
        {
          diagnosisCode : 'H40.32X4',
          diagnosisDescription : 'Glaucoma secondary to eye trauma, left eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.33X1',
          diagnosisDescription : 'Glaucoma secondary to eye trauma, bilateral, mild stage'
        },
        {
          diagnosisCode : 'H40.33X2',
          diagnosisDescription : 'Glaucoma secondary to eye trauma, bilateral, moderate stage'
        },
        {
          diagnosisCode : 'H40.33X3',
          diagnosisDescription : 'Glaucoma secondary to eye trauma, bilateral, severe stage'
        },
        {
          diagnosisCode : 'H40.33X4',
          diagnosisDescription : 'Glaucoma secondary to eye trauma, bilateral, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.41X1',
          diagnosisDescription : 'Glaucoma secondary to eye inflammation, right eye, mild stage'
        },
        {
          diagnosisCode : 'H40.41X2',
          diagnosisDescription : 'Glaucoma secondary to eye inflammation, right eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.41X3',
          diagnosisDescription : 'Glaucoma secondary to eye inflammation, right eye, severe stage'
        },
        {
          diagnosisCode : 'H40.41X4',
          diagnosisDescription : 'Glaucoma secondary to eye inflammation, right eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.42X1',
          diagnosisDescription : 'Glaucoma secondary to eye inflammation, left eye, mild stage'
        },
        {
          diagnosisCode : 'H40.42X2',
          diagnosisDescription : 'Glaucoma secondary to eye inflammation, left eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.42X3',
          diagnosisDescription : 'Glaucoma secondary to eye inflammation, left eye, severe stage'
        },
        {
          diagnosisCode : 'H40.42X4',
          diagnosisDescription : 'Glaucoma secondary to eye inflammation, left eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.43X1',
          diagnosisDescription : 'Glaucoma secondary to eye inflammation, bilateral, mild stage'
        },
        {
          diagnosisCode : 'H40.43X2',
          diagnosisDescription : 'Glaucoma secondary to eye inflammation, bilateral, moderate stage'
        },
        {
          diagnosisCode : 'H40.43X3',
          diagnosisDescription : 'Glaucoma secondary to eye inflammation, bilateral, severe stage'
        },
        {
          diagnosisCode : 'H40.43X4',
          diagnosisDescription : 'Glaucoma secondary to eye inflammation, bilateral, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.51X1',
          diagnosisDescription : 'Glaucoma secondary to other eye disorders, right eye, mild stage'
        },
        {
          diagnosisCode : 'H40.51X2',
          diagnosisDescription : 'Glaucoma secondary to other eye disorders, right eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.51X3',
          diagnosisDescription : 'Glaucoma secondary to other eye disorders, right eye, severe stage'
        },
        {
          diagnosisCode : 'H40.51X4',
          diagnosisDescription : 'Glaucoma secondary to other eye disorders, right eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.52X1',
          diagnosisDescription : 'Glaucoma secondary to other eye disorders, left eye, mild stage'
        },
        {
          diagnosisCode : 'H40.52X2',
          diagnosisDescription : 'Glaucoma secondary to other eye disorders, left eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.52X3',
          diagnosisDescription : 'Glaucoma secondary to other eye disorders, left eye, severe stage'
        },
        {
          diagnosisCode : 'H40.52X4',
          diagnosisDescription : 'Glaucoma secondary to other eye disorders, left eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.53X1',
          diagnosisDescription : 'Glaucoma secondary to other eye disorders, bilateral, mild stage'
        },
        {
          diagnosisCode : 'H40.53X2',
          diagnosisDescription : 'Glaucoma secondary to other eye disorders, bilateral, moderate stage'
        },
        {
          diagnosisCode : 'H40.53X3',
          diagnosisDescription : 'Glaucoma secondary to other eye disorders, bilateral, severe stage'
        },
        {
          diagnosisCode : 'H40.53X4',
          diagnosisDescription : 'Glaucoma secondary to other eye disorders, bilateral, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.61X1',
          diagnosisDescription : 'Glaucoma secondary to drugs, right eye, mild stage'
        },
        {
          diagnosisCode : 'H40.61X2',
          diagnosisDescription : 'Glaucoma secondary to drugs, right eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.61X3',
          diagnosisDescription : 'Glaucoma secondary to drugs, right eye, severe stage'
        },
        {
          diagnosisCode : 'H40.61X4',
          diagnosisDescription : 'Glaucoma secondary to drugs, right eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.62X1',
          diagnosisDescription : 'Glaucoma secondary to drugs, left eye, mild stage'
        },
        {
          diagnosisCode : 'H40.62X2',
          diagnosisDescription : 'Glaucoma secondary to drugs, left eye, moderate stage'
        },
        {
          diagnosisCode : 'H40.62X3',
          diagnosisDescription : 'Glaucoma secondary to drugs, left eye, severe stage'
        },
        {
          diagnosisCode : 'H40.62X4',
          diagnosisDescription : 'Glaucoma secondary to drugs, left eye, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.63X1',
          diagnosisDescription : 'Glaucoma secondary to drugs, bilateral, mild stage'
        },
        {
          diagnosisCode : 'H40.63X2',
          diagnosisDescription : 'Glaucoma secondary to drugs, bilateral, moderate stage'
        },
        {
          diagnosisCode : 'H40.63X3',
          diagnosisDescription : 'Glaucoma secondary to drugs, bilateral, severe stage'
        },
        {
          diagnosisCode : 'H40.63X4',
          diagnosisDescription : 'Glaucoma secondary to drugs, bilateral, indeterminate stage'
        },
        {
          diagnosisCode : 'H40.811',
          diagnosisDescription : 'Glaucoma with increased episcleral venous pressure, right eye'
        },
        {
          diagnosisCode : 'H40.812',
          diagnosisDescription : 'Glaucoma with increased episcleral venous pressure, left eye'
        },
        {
          diagnosisCode : 'H40.813',
          diagnosisDescription : 'Glaucoma with increased episcleral venous pressure, bilateral'
        },
        {
          diagnosisCode : 'H40.821',
          diagnosisDescription : 'Hypersecretion glaucoma, right eye'
        },
        {
          diagnosisCode : 'H40.822',
          diagnosisDescription : 'Hypersecretion glaucoma, left eye'
        },
        {
          diagnosisCode : 'H40.823',
          diagnosisDescription : 'Hypersecretion glaucoma, bilateral'
        },
        {
          diagnosisCode : 'H40.831',
          diagnosisDescription : 'Aqueous misdirection, right eye'
        },
        {
          diagnosisCode : 'H40.832',
          diagnosisDescription : 'Aqueous misdirection, left eye'
        },
        {
          diagnosisCode : 'H40.833',
          diagnosisDescription : 'Aqueous misdirection, bilateral'
        },
        {
          diagnosisCode : 'H40.89',
          diagnosisDescription : 'Other specified glaucoma'
        },
        {
          diagnosisCode : 'H40.9',
          diagnosisDescription : 'Unspecified glaucoma'
        },
        {
          diagnosisCode: 'H42',
          diagnosisDescription: 'Glaucoma in diseases classified elsewhere'
        },
        {
          diagnosisCode : 'Q15.0',
          diagnosisDescription : 'Congenital glaucoma'
        }
      ]
    },
    {
      conditionDescription: 'Prediabetes',
      diagnosis: [
        {
          diagnosisCode : 'R73.03',
          diagnosisDescription : 'Prediabetes'
        }
      ]
    },
    {
      conditionDescription: 'Diabetes',
      diagnosis: [
        {
          diagnosisCode : 'E10.36',
          diagnosisDescription : 'Type 1 diabetes mellitus with diabetic cataract'
        },
        {
          diagnosisCode : 'E10.37X1',
          diagnosisDescription : 'Type 1 diabetes mellitus with diabetic macular edema, resolved following treatment, right eye'
        },
        {
          diagnosisCode : 'E10.37X2',
          diagnosisDescription : 'Type 1 diabetes mellitus with diabetic macular edema, resolved following treatment, left eye'
        },
        {
          diagnosisCode : 'E10.37X3',
          diagnosisDescription : 'Type 1 diabetes mellitus with diabetic macular edema, resolved following treatment, bilateral'
        },
        {
          diagnosisCode : 'E10.39',
          diagnosisDescription : 'Type 1 diabetes mellitus with other diabetic ophthalmic complication'
        },
        {
          diagnosisCode : 'E10.40',
          diagnosisDescription : 'Type 1 diabetes mellitus with diabetic neuropathy, unspecified'
        },
        {
          diagnosisCode : 'E10.41',
          diagnosisDescription : 'Type 1 diabetes mellitus with diabetic mononeuropathy'
        },
        {
          diagnosisCode : 'E10.42',
          diagnosisDescription : 'Type 1 diabetes mellitus with diabetic polyneuropathy'
        },
        {
          diagnosisCode : 'E10.43',
          diagnosisDescription : 'Type 1 diabetes mellitus with diabetic autonomic (poly)neuropathy'
        },
        {
          diagnosisCode : 'E10.49',
          diagnosisDescription : 'Type 1 diabetes mellitus with other diabetic neurological complication'
        },
        {
          diagnosisCode : 'E10.59',
          diagnosisDescription : 'Type 1 diabetes mellitus with other circulatory complications'
        },
        {
          diagnosisCode : 'E10.69',
          diagnosisDescription : 'Type 1 diabetes mellitus with other specified complication'
        },
        {
          diagnosisCode : 'E10.8',
          diagnosisDescription : 'Type 1 diabetes mellitus with unspecified complications'
        },
        {
          diagnosisCode : 'E10.9',
          diagnosisDescription : 'Type 1 diabetes mellitus without complications'
        },
        {
          diagnosisCode : 'E11.36',
          diagnosisDescription : 'Type 2 diabetes mellitus with diabetic cataract'
        },
        {
          diagnosisCode : 'E11.37X1',
          diagnosisDescription : 'Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, right eye'
        },
        {
          diagnosisCode : 'E11.37X2',
          diagnosisDescription : 'Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, left eye'
        },
        {
          diagnosisCode : 'E11.37X3',
          diagnosisDescription : 'Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, bilateral'
        },
        {
          diagnosisCode : 'E11.39',
          diagnosisDescription : 'Type 2 diabetes mellitus with other diabetic ophthalmic complication'
        },
        {
          diagnosisCode : 'E11.40',
          diagnosisDescription : 'Type 2 diabetes mellitus with diabetic neuropathy, unspecified'
        },
        {
          diagnosisCode : 'E11.41',
          diagnosisDescription : 'Type 2 diabetes mellitus with diabetic mononeuropathy'
        },
        {
          diagnosisCode : 'E11.42',
          diagnosisDescription : 'Type 2 diabetes mellitus with diabetic polyneuropathy'
        },
        {
          diagnosisCode : 'E11.43',
          diagnosisDescription : 'Type 2 diabetes mellitus with diabetic autonomic (poly)neuropathy'
        },
        {
          diagnosisCode : 'E11.49',
          diagnosisDescription : 'Type 2 diabetes mellitus with other diabetic neurological complication'
        },
        {
          diagnosisCode : 'E11.59',
          diagnosisDescription : 'Type 2 diabetes mellitus with other circulatory complications'
        },
        {
          diagnosisCode : 'E11.69',
          diagnosisDescription : 'Type 2 diabetes mellitus with other specified complication'
        },
        {
          diagnosisCode : 'E11.8',
          diagnosisDescription : 'Type 2 diabetes mellitus with unspecified complications'
        },
        {
          diagnosisCode : 'E11.9',
          diagnosisDescription : 'Type 2 diabetes mellitus without complications'
        },
        {
          diagnosisCode : 'E13.36',
          diagnosisDescription : 'Other specified diabetes mellitus with diabetic cataract'
        },
        {
          diagnosisCode : 'E13.37X1',
          diagnosisDescription : 'Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, right eye'
        },
        {
          diagnosisCode : 'E13.37X2',
          diagnosisDescription : 'Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, left eye'
        },
        {
          diagnosisCode : 'E13.37X3',
          diagnosisDescription : 'Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, bilateral'
        },
        {
          diagnosisCode : 'E13.39',
          diagnosisDescription : 'Other specified diabetes mellitus with other diabetic ophthalmic complication'
        },
        {
          diagnosisCode : 'E13.40',
          diagnosisDescription : 'Other specified diabetes mellitus with diabetic neuropathy, unspecified'
        },
        {
          diagnosisCode : 'E13.41',
          diagnosisDescription : 'Other specified diabetes mellitus with diabetic mononeuropathy'
        },
        {
          diagnosisCode : 'E13.42',
          diagnosisDescription : 'Other specified diabetes mellitus with diabetic polyneuropathy'
        },
        {
          diagnosisCode : 'E13.43',
          diagnosisDescription : 'Other specified diabetes mellitus with diabetic autonomic (poly)neuropathy'
        },
        {
          diagnosisCode : 'E13.59',
          diagnosisDescription : 'Other specified diabetes mellitus with other circulatory complications'
        },
        {
          diagnosisCode : 'E13.69',
          diagnosisDescription : 'Other specified diabetes mellitus with other specified complication'
        },
        {
          diagnosisCode : 'E13.8',
          diagnosisDescription : 'Other specified diabetes mellitus with unspecified complications'
        },
        {
          diagnosisCode : 'E13.9',
          diagnosisDescription : 'Other specified diabetes mellitus without complications'
        }
      ]
    },
    {
      conditionDescription: 'Rubeosis Iridis',
      diagnosis: [
        {
          diagnosisCode : 'H21.1X1',
          diagnosisDescription : 'Other vascular disorders of iris and ciliary body, right eye'
        },
        {
          diagnosisCode : 'H21.1X2',
          diagnosisDescription : 'Other vascular disorders of iris and ciliary body, left eye'
        },
        {
          diagnosisCode : 'H21.1X3',
          diagnosisDescription : 'Other vascular disorders of iris and ciliary body, bilateral'
        }
      ]
    }
  ] as DiagnosisCodeGrouping[],
  DiabeticEyeCarePlus: [
    {
      conditionDescription: 'Diabetic Retinopathy',
      diagnosis: [
        {
          diagnosisCode: 'E10.311',
          diagnosisDescription: 'Type 1 Diabetes W Unsp Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode: 'E10.319',
          diagnosisDescription: 'Type 1 Diabetes W Unsp Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E10.321',
          diagnosisDescription: 'Type 1 Diab W Mild Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E10.329',
          diagnosisDescription: 'Type 1 Diab W Mild Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E10.331',
          diagnosisDescription: 'Type 1 Diab W Moderate Nonprlf Diab RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E10.339',
          diagnosisDescription: 'Type 1 Diab W Moderate Nonprlf Diab RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E10.341',
          diagnosisDescription: 'Type 1 Diab W Severe Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E10.349',
          diagnosisDescription: 'Type 1 Diab W Severe Nonprlf DiabRTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E10.351',
          diagnosisDescription: 'Type 1 Diabetes W Prolif DiabeticRTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E10.359',
          diagnosisDescription: 'Type 1 Diabetes W Prolif DiabeticRTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E11.311',
          diagnosisDescription: 'Type 2 Diabetes W Unsp Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode: 'E11.319',
          diagnosisDescription: 'Type 2 Diabetes W Unsp Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E11.321',
          diagnosisDescription: 'Type 2 Diab W Mild Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E11.329',
          diagnosisDescription: 'Type 2 Diab W Mild Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E11.331',
          diagnosisDescription: 'Type 2 Diab W Moderate Nonprlf Diab RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E11.339',
          diagnosisDescription: 'Type 2 Diab W Moderate Nonprlf Diab RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E11.341',
          diagnosisDescription: 'Type 2 Diab W Severe Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E11.349',
          diagnosisDescription: 'Type 2 Diab W Severe Nonprlf DiabRTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E11.351',
          diagnosisDescription: 'Type 2 Diabetes W Prolif DiabeticRTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E11.359',
          diagnosisDescription: 'Type 2 Diabetes W Prolif DiabeticRTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E13.311',
          diagnosisDescription: 'Oth Diabetes W Unsp Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode: 'E13.319',
          diagnosisDescription: 'Oth Diabetes W Unsp Diabetic Retinopathy w/o Macular Edema'
        },
        {
          diagnosisCode: 'E13.321',
          diagnosisDescription: 'Oth Diabetes W Mild Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E13.329',
          diagnosisDescription: 'Oth Diabetes W Mild Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E13.331',
          diagnosisDescription: 'Oth Diab W Moderate Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E13.339',
          diagnosisDescription: 'Oth Diab W Moderate Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E13.341',
          diagnosisDescription: 'Oth Diabetes W Severe Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E13.349',
          diagnosisDescription: 'Oth Diab W Severe Nonprlf DiabeticRTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E13.351',
          diagnosisDescription: 'Oth Diabetes W Prolif Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode: 'E13.359',
          diagnosisDescription: 'Oth Diabetes W Prolif Diabetic Retinopathy w/o Macular Edema'
        },
        {
          diagnosisCode: 'H21.1X1',
          diagnosisDescription: 'Oth Vascular Disorders Iris and Ciliary Body RT Eye'
        },
        {
          diagnosisCode: 'H21.1X2',
          diagnosisDescription: 'Oth Vascular Disorders Iris and Ciliary Body LT Eye'
        },
        {
          diagnosisCode: 'H21.1X3',
          diagnosisDescription: 'Oth Vascular Disorders Iris and Ciliary Body Bi'
        },
        {
          diagnosisCode: 'H21.1X9',
          diagnosisDescription: 'Oth Vascular Disord Iris and Ciliary Body Unsp Eye'
        },

      ]
    },
    {
      conditionDescription: 'Diabetes',
      diagnosis: [
        {
          diagnosisCode: 'E10.10',
          diagnosisDescription: 'Type 1 diabetes mellitus with ketoacidosis without coma'
        },
        {
          diagnosisCode: 'E10.11',
          diagnosisDescription: 'Type 1 diabetes mellitus with ketoacidosis with coma'
        },
        {
          diagnosisCode: 'E10.21',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic nephropathy'
        },
        {
          diagnosisCode: 'E10.22',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic chronic kidney disease'
        },
        {
          diagnosisCode: 'E10.29',
          diagnosisDescription: 'Type 1 diabetes mellitus with other diabetic kidney complication'
        },
        {
          diagnosisCode: 'E10.36',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic cataract'
        },
        {
          diagnosisCode: 'E10.39',
          diagnosisDescription: 'Type 1 diabetes mellitus with other diabetic ophthalmic complication'
        },
        {
          diagnosisCode: 'E10.40',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic neuropathy, unspecified'
        },
        {
          diagnosisCode: 'E10.41',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic mononeuropathy'
        },
        {
          diagnosisCode: 'E10.42',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic polyneuropathy'
        },
        {
          diagnosisCode: 'E10.43',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic autonomic (poly)neuropathy'
        },
        {
          diagnosisCode: 'E10.44',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic amyotrophy'
        },
        {
          diagnosisCode: 'E10.49',
          diagnosisDescription: 'Type 1 diabetes mellitus with other diabetic neurological complication'
        },
        {
          diagnosisCode: 'E10.51',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic peripheral angiopathy without gangrene'
        },
        {
          diagnosisCode: 'E10.52',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic peripheral angiopathy with gangrene'
        },
        {
          diagnosisCode: 'E10.59',
          diagnosisDescription: 'Type 1 diabetes mellitus with other circulatory complications'
        },
        {
          diagnosisCode: 'E10.610',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic neuropathic arthropathy'
        },
        {
          diagnosisCode: 'E10.618',
          diagnosisDescription: 'Type 1 diabetes mellitus with other diabetic arthropathy'
        },
        {
          diagnosisCode: 'E10.620',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic dermatitis'
        },
        {
          diagnosisCode: 'E10.621',
          diagnosisDescription: 'Type 1 diabetes mellitus with foot ulcer'
        },
        {
          diagnosisCode: 'E10.622',
          diagnosisDescription: 'Type 1 diabetes mellitus with other skin ulcer'
        },
        {
          diagnosisCode: 'E10.628',
          diagnosisDescription: 'Type 1 diabetes mellitus with other skin complications'
        },
        {
          diagnosisCode: 'E10.630',
          diagnosisDescription: 'Type 1 diabetes mellitus with periodontal disease'
        },
        {
          diagnosisCode: 'E10.638',
          diagnosisDescription: 'Type 1 diabetes mellitus with other oral complications'
        },
        {
          diagnosisCode: 'E10.641',
          diagnosisDescription: 'Type 1 diabetes mellitus with hypoglycemia with coma'
        },
        {
          diagnosisCode: 'E10.649',
          diagnosisDescription: 'Type 1 diabetes mellitus with hypoglycemia without coma'
        },
        {
          diagnosisCode: 'E10.65',
          diagnosisDescription: 'Type 1 diabetes mellitus with hyperglycemia'
        },
        {
          diagnosisCode: 'E10.69',
          diagnosisDescription: 'Type 1 diabetes mellitus with other specified complication'
        },
        {
          diagnosisCode: 'E10.8',
          diagnosisDescription: 'Type 1 diabetes mellitus with unspecified complications'
        },
        {
          diagnosisCode: 'E10.9',
          diagnosisDescription: 'Type 1 diabetes mellitus without complications'
        },
        {
          diagnosisCode: 'E11.00',
          diagnosisDescription: 'Type 2 diabetes mellitus with hyperosmolarity without nonketotic hyperglycemic-hyperosmolar coma (NKHHC)'
        },
        {
          diagnosisCode: 'E11.01',
          diagnosisDescription: 'Type 2 diabetes mellitus with hyperosmolarity with coma'
        },
        {
          diagnosisCode: 'E11.21',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic nephropathy'
        },
        {
          diagnosisCode: 'E11.22',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic chronic kidney disease'
        },
        {
          diagnosisCode: 'E11.29',
          diagnosisDescription: 'Type 2 diabetes mellitus with other diabetic kidney complication'
        },
        {
          diagnosisCode: 'E11.36',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic cataract'
        },
        {
          diagnosisCode: 'E11.39',
          diagnosisDescription: 'Type 2 diabetes mellitus with other diabetic ophthalmic complication'
        },
        {
          diagnosisCode: 'E11.40',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic neuropathy, unspecified'
        },
        {
          diagnosisCode: 'E11.41',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic mononeuropathy'
        },
        {
          diagnosisCode: 'E11.42',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic polyneuropathy'
        },
        {
          diagnosisCode: 'E11.43',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic autonomic (poly)neuropathy'
        },
        {
          diagnosisCode: 'E11.44',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic amyotrophy'
        },
        {
          diagnosisCode: 'E11.49',
          diagnosisDescription: 'Type 2 diabetes mellitus with other diabetic neurological complication'
        },
        {
          diagnosisCode: 'E11.51',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic peripheral angiopathy without gangrene'
        },
        {
          diagnosisCode: 'E11.52',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic peripheral angiopathy with gangrene'
        },
        {
          diagnosisCode: 'E11.59',
          diagnosisDescription: 'Type 2 diabetes mellitus with other circulatory complications'
        },
        {
          diagnosisCode: 'E11.610',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic neuropathic arthropathy'
        },
        {
          diagnosisCode: 'E11.618',
          diagnosisDescription: 'Type 2 diabetes mellitus with other diabetic arthropathy'
        },
        {
          diagnosisCode: 'E11.620',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic dermatitis'
        },
        {
          diagnosisCode: 'E11.621',
          diagnosisDescription: 'Type 2 diabetes mellitus with foot ulcer'
        },
        {
          diagnosisCode: 'E11.622',
          diagnosisDescription: 'Type 2 diabetes mellitus with other skin ulcer'
        },
        {
          diagnosisCode: 'E11.628',
          diagnosisDescription: 'Type 2 diabetes mellitus with other skin complications'
        },
        {
          diagnosisCode: 'E11.630',
          diagnosisDescription: 'Type 2 diabetes mellitus with periodontal disease'
        },
        {
          diagnosisCode: 'E11.638',
          diagnosisDescription: 'Type 2 diabetes mellitus with other oral complications'
        },
        {
          diagnosisCode: 'E11.641',
          diagnosisDescription: 'Type 2 diabetes mellitus with hypoglycemia with coma'
        },
        {
          diagnosisCode: 'E11.649',
          diagnosisDescription: 'Type 2 diabetes mellitus with hypoglycemia without coma'
        },
        {
          diagnosisCode: 'E11.65',
          diagnosisDescription: 'Type 2 diabetes mellitus with hyperglycemia'
        },
        {
          diagnosisCode: 'E11.69',
          diagnosisDescription: 'Type 2 diabetes mellitus with other specified complication'
        },
        {
          diagnosisCode: 'E11.8',
          diagnosisDescription: 'Type 2 diabetes mellitus with unspecified complications'
        },
        {
          diagnosisCode: 'E11.9',
          diagnosisDescription: 'Type 2 diabetes mellitus without complications'
        },
        {
          diagnosisCode: 'E13.00',
          diagnosisDescription: 'Other specified diabetes mellitus with hyperosmolarity without nonketotic hyperglycemic-hyperosmolar coma (NKHHC)'
        },
        {
          diagnosisCode: 'E13.01',
          diagnosisDescription: 'Other specified diabetes mellitus with hyperosmolarity with coma'
        },
        {
          diagnosisCode: 'E13.10',
          diagnosisDescription: 'Other specified diabetes mellitus with ketoacidosis without coma'
        },
        {
          diagnosisCode: 'E13.11',
          diagnosisDescription: 'Other specified diabetes mellitus with ketoacidosis with coma'
        },
        {
          diagnosisCode: 'E13.21',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic nephropathy'
        },
        {
          diagnosisCode: 'E13.22',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic chronic kidney disease'
        },
        {
          diagnosisCode: 'E13.29',
          diagnosisDescription: 'Other specified diabetes mellitus with other diabetic kidney complication'
        },
        {
          diagnosisCode: 'E13.36',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic cataract'
        },
        {
          diagnosisCode: 'E13.39',
          diagnosisDescription: 'Other specified diabetes mellitus with other diabetic ophthalmic complication'
        },
        {
          diagnosisCode: 'E13.40',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic neuropathy, unspecified'
        },
        {
          diagnosisCode: 'E13.41',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic mononeuropathy'
        },
        {
          diagnosisCode: 'E13.42',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic polyneuropathy'
        },
        {
          diagnosisCode: 'E13.43',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic autonomic (poly)neuropathy'
        },
        {
          diagnosisCode: 'E13.44',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic amyotrophy'
        },
        {
          diagnosisCode: 'E13.49',
          diagnosisDescription: 'Other specified diabetes mellitus with other diabetic neurological complication'
        },
        {
          diagnosisCode: 'E13.51',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic peripheral angiopathy without gangrene'
        },
        {
          diagnosisCode: 'E13.52',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic peripheral angiopathy with gangrene'
        },
        {
          diagnosisCode: 'E13.59',
          diagnosisDescription: 'Other specified diabetes mellitus with other circulatory complications'
        },
        {
          diagnosisCode: 'E13.610',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic neuropathic arthropathy'
        },
        {
          diagnosisCode: 'E13.618',
          diagnosisDescription: 'Other specified diabetes mellitus with other diabetic arthropathy'
        },
        {
          diagnosisCode: 'E13.620',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic dermatitis'
        },
        {
          diagnosisCode: 'E13.621',
          diagnosisDescription: 'Other specified diabetes mellitus with foot ulcer'
        },
        {
          diagnosisCode: 'E13.622',
          diagnosisDescription: 'Other specified diabetes mellitus with other skin ulcer'
        },
        {
          diagnosisCode: 'E13.628',
          diagnosisDescription: 'Other specified diabetes mellitus with other skin complications'
        },
        {
          diagnosisCode: 'E13.630',
          diagnosisDescription: 'Other specified diabetes mellitus with periodontal disease'
        },
        {
          diagnosisCode: 'E13.638',
          diagnosisDescription: 'Other specified diabetes mellitus with other oral complications'
        },
        {
          diagnosisCode: 'E13.641',
          diagnosisDescription: 'Other specified diabetes mellitus with hypoglycemia with coma'
        },
        {
          diagnosisCode: 'E13.649',
          diagnosisDescription: 'Other specified diabetes mellitus with hypoglycemia without coma'
        },
        {
          diagnosisCode: 'E13.65',
          diagnosisDescription: 'Other specified diabetes mellitus with hyperglycemia'
        },
        {
          diagnosisCode: 'E13.69',
          diagnosisDescription: 'Other specified diabetes mellitus with other specified complication'
        },
        {
          diagnosisCode: 'E13.8',
          diagnosisDescription: 'Other specified diabetes mellitus with unspecified complications'
        },
        {
          diagnosisCode: 'E13.9',
          diagnosisDescription: 'Other specified diabetes mellitus without complications'
        },

      ]
    },
    {
      conditionDescription: 'Retinal Screening',
      diagnosis: [
        {
          diagnosisCode: 'Z13.5',
          diagnosisDescription: 'Encounter For Screening For Eye and Ear Disorders'
        },
        {
          diagnosisCode: 'E11.9',
          diagnosisDescription: 'Type 2 diabetes mellitus without complications'
        },
        {
          diagnosisCode: 'E13.9',
          diagnosisDescription: 'Other specified diabetes mellitus without complications'
        },

      ]
    },
    {
      conditionDescription: 'AMD',
      diagnosis: [
        {
          diagnosisCode: 'H35.30',
          diagnosisDescription: 'Unspecified Macular Degeneration'
        },
        {
          diagnosisCode: 'H35.31',
          diagnosisDescription: 'Nonexudative Age-Related Macular Degeneration'
        },
        {
          diagnosisCode: 'H35.32',
          diagnosisDescription: 'Exudative Age-Related Macular Degeneration'
        },
        {
          diagnosisCode: 'H35.351',
          diagnosisDescription: 'Cystoid Macular Degeneration, Right Eye'
        },
        {
          diagnosisCode: 'H35.352',
          diagnosisDescription: 'Cystoid Macular Degeneration, Left Eye'
        },
        {
          diagnosisCode: 'H35.353',
          diagnosisDescription: 'Cystoid Macular Degeneration, Bilateral'
        },
        {
          diagnosisCode: 'H35.359',
          diagnosisDescription: 'Cystoid Macular Degeneration, Unspecified Eye'
        }
      ]
    },
    {
      conditionDescription: 'Glaucoma',
      diagnosis: [
        {
          diagnosisCode: 'H40.021',
          diagnosisDescription: 'Open Angle W Borderline Findings, High Risk RT Eye'
        },
        {
          diagnosisCode: 'H40.022',
          diagnosisDescription: 'Open Angle W Borderline Findings, High Risk LT Eye'
        },
        {
          diagnosisCode: 'H40.023',
          diagnosisDescription: 'Open Angle with Borderline Findings, High Risk, Bi'
        },
        {
          diagnosisCode: 'H40.029',
          diagnosisDescription: 'Open Angle W Borderline Find, High Risk, Unsp Eye'
        },
        {
          diagnosisCode: 'H40.061',
          diagnosisDescription: 'Primary Angle Closure w/o Glau Damage RT Eye'
        },
        {
          diagnosisCode: 'H40.062',
          diagnosisDescription: 'Primary Angle Closure w/o Glau Damage LT Eye'
        },
        {
          diagnosisCode: 'H40.063',
          diagnosisDescription: 'Primary Angle Closure w/o Glau Damage, Bilateral'
        },
        {
          diagnosisCode: 'H40.069',
          diagnosisDescription: 'Primary Angle Closure w/o Glau Damage, Unsp Eye'
        },
        {
          diagnosisCode: 'H40.10X0',
          diagnosisDescription: 'Unspecified Open-Angle Glaucoma, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.10X1',
          diagnosisDescription: 'Unspecified Open-Angle Glaucoma, Mild Stage'
        },
        {
          diagnosisCode: 'H40.10X2',
          diagnosisDescription: 'Unspecified Open-Angle Glaucoma, Mod Stage'
        },
        {
          diagnosisCode: 'H40.10X3',
          diagnosisDescription: 'Unspecified Open-Angle Glaucoma, Severe Stage'
        },
        {
          diagnosisCode: 'H40.10X4',
          diagnosisDescription: 'Unspecified Open-Angle Glaucoma, Indeterminate Stage'
        },
        {
          diagnosisCode: 'H40.11X0',
          diagnosisDescription: 'Primary Open-Angle Glaucoma, Stage Unspecified'
        },
        {
          diagnosisCode: 'H40.11X1',
          diagnosisDescription: 'Primary Open-Angle Glaucoma, Mild Stage'
        },
        {
          diagnosisCode: 'H40.11X2',
          diagnosisDescription: 'Primary Open-Angle Glaucoma, Moderate Stage'
        },
        {
          diagnosisCode: 'H40.11X3',
          diagnosisDescription: 'Primary Open-Angle Glaucoma, Severe Stage'
        },
        {
          diagnosisCode: 'H40.11X4',
          diagnosisDescription: 'Primary Open-Angle Glauc, Indeterminate Stage'
        },
        {
          diagnosisCode: 'H40.1210',
          diagnosisDescription: 'Low-Tension Glaucoma, RT Eye, Stage Unspecified'
        },
        {
          diagnosisCode: 'H40.1211',
          diagnosisDescription: 'Low-Tension Glaucoma, Right Eye, Mild Stage'
        },
        {
          diagnosisCode: 'H40.1212',
          diagnosisDescription: 'Low-Tension Glaucoma, Right Eye, Moderate Stage'
        },
        {
          diagnosisCode: 'H40.1213',
          diagnosisDescription: 'Low-Tension Glaucoma, Right Eye, Severe Stage'
        },
        {
          diagnosisCode: 'H40.1214',
          diagnosisDescription: 'Low-Tension Glauc, RT Eye, Indeterminate Stage'
        },
        {
          diagnosisCode: 'H40.1220',
          diagnosisDescription: 'Low-Tension Glaucoma, Left Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.1221',
          diagnosisDescription: 'Low-Tension Glaucoma, Left Eye, Mild Stage'
        },
        {
          diagnosisCode: 'H40.1222',
          diagnosisDescription: 'Low-Tension Glaucoma, Left Eye, Moderate Stage'
        },
        {
          diagnosisCode: 'H40.1223',
          diagnosisDescription: 'Low-Tension Glaucoma, Left Eye, Severe Stage'
        },
        {
          diagnosisCode: 'H40.1224',
          diagnosisDescription: 'Low-Tension Glau, LT Eye, Indeterminate Stage'
        },
        {
          diagnosisCode: 'H40.1230',
          diagnosisDescription: 'Low-Tension Glau, Bilateral, Stage Unspecified'
        },
        {
          diagnosisCode: 'H40.1231',
          diagnosisDescription: 'Low-Tension Glaucoma, Bilateral, Mild Stage'
        },
        {
          diagnosisCode: 'H40.1232',
          diagnosisDescription: 'Low-Tension Glaucoma, Bilateral, Moderate Stage'
        },
        {
          diagnosisCode: 'H40.1233',
          diagnosisDescription: 'Low-Tension Glaucoma, Bilateral, Severe Stage'
        },
        {
          diagnosisCode: 'H40.1234',
          diagnosisDescription: 'Low-Tension Glau, Bilateral, Indeterminate Stage'
        },
        {
          diagnosisCode: 'H40.1290',
          diagnosisDescription: 'Low-Tension Glaucoma, Unsp Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.1291',
          diagnosisDescription: 'Low-Tension Glaucoma, Uns Eye, Mild Stage'
        },
        {
          diagnosisCode: 'H40.1292',
          diagnosisDescription: 'Low-Tension Glaucoma, Unsp Eye, Moderate Stage'
        },
        {
          diagnosisCode: 'H40.1293',
          diagnosisDescription: 'Low-Tension Glaucoma, Unsp, Severe Stage'
        },
        {
          diagnosisCode: 'H40.1294',
          diagnosisDescription: 'Low-Tension Glau, Unsp Eye, Indeterminate Stage'
        },
        {
          diagnosisCode: 'H40.1310',
          diagnosisDescription: 'Pigmentary Glaucoma, RT Eye, Stage Unspecified'
        },
        {
          diagnosisCode: 'H40.1311',
          diagnosisDescription: 'Pigmentary Glaucoma, RT Eye, Mild Stage'
        },
        {
          diagnosisCode: 'H40.1312',
          diagnosisDescription: 'Pigmentary Glaucoma, RT Eye, Moderate Stage'
        },
        {
          diagnosisCode: 'H40.1313',
          diagnosisDescription: 'Pigmentary Glaucoma, RT Eye, Severe Stage'
        },
        {
          diagnosisCode: 'H40.1314',
          diagnosisDescription: 'Pigmentary Glau, RT Eye, Indeterminate Stage'
        },
        {
          diagnosisCode: 'H40.1320',
          diagnosisDescription: 'Pigmentary Glaucoma, Left Eye, Stage Unspecified'
        },
        {
          diagnosisCode: 'H40.1321',
          diagnosisDescription: 'Pigmentary Glaucoma, Left Eye, Mild Stage'
        },
        {
          diagnosisCode: 'H40.1322',
          diagnosisDescription: 'Pigmentary Glaucoma, Left Eye, Moderate Stage'
        },
        {
          diagnosisCode: 'H40.1323',
          diagnosisDescription: 'Pigmentary Glaucoma, Left Eye, Severe Stage'
        },
        {
          diagnosisCode: 'H40.1324',
          diagnosisDescription: 'Pigmentary Glau, LT Eye, Indeterminate Stage'
        },
        {
          diagnosisCode: 'H40.1330',
          diagnosisDescription: 'Pigmentary Glaucoma, Bilateral, Stage Unspecified'
        },
        {
          diagnosisCode: 'H40.1331',
          diagnosisDescription: 'Pigmentary Glaucoma, Bilateral, Mild Stage'
        },
        {
          diagnosisCode: 'H40.1332',
          diagnosisDescription: 'Pigmentary Glaucoma, Bilateral, Moderate Stage'
        },
        {
          diagnosisCode: 'H40.1333',
          diagnosisDescription: 'Pigmentary Glaucoma, Bilateral, Severe Stage'
        },
        {
          diagnosisCode: 'H40.1334',
          diagnosisDescription: 'Pigmentary Glau, Bilateral, Indeterminate Stage'
        },
        {
          diagnosisCode: 'H40.1390',
          diagnosisDescription: 'Pigmentary Glaucoma, Unspecified Eye, Stage Unspecified'
        },
        {
          diagnosisCode: 'H40.1391',
          diagnosisDescription: 'Pigmentary Glaucoma, Unsp Eye, Mild Stage'
        },
        {
          diagnosisCode: 'H40.1392',
          diagnosisDescription: 'Pigmentary Glaucoma, Unsp Eye, Moderate Stage'
        },
        {
          diagnosisCode: 'H40.1393',
          diagnosisDescription: 'Pigmentary Glaucoma, Unsp Eye, Severe Stage'
        },
        {
          diagnosisCode: 'H40.1394',
          diagnosisDescription: 'Pigmentary Glau, Unsp Eye, Indeterminate Stage'
        },
        {
          diagnosisCode: 'H40.1410',
          diagnosisDescription: 'Capslr Glauc W/Pseudxf Lens, RT Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.1411',
          diagnosisDescription: 'Capslr Glauc W/Pseudxf Lens, RT Eye, Mild Stage'
        },
        {
          diagnosisCode: 'H40.1412',
          diagnosisDescription: 'Capslr Glauc W/Pseudxf Lens, RT Eye, Moder Stage'
        },
        {
          diagnosisCode: 'H40.1413',
          diagnosisDescription: 'Capslr Glauc W/Pseudxf Lens, RT Eye, Severe Stage'
        },
        {
          diagnosisCode: 'H40.1414',
          diagnosisDescription: 'Capslr Glauc W/Pseudxf Lens, RT Eye, Indetermin'
        },
        {
          diagnosisCode: 'H40.1420',
          diagnosisDescription: 'Capslr Glauc W/Pseudxf Lens, LT Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.1421',
          diagnosisDescription: 'Capslr Glaucoma W/Pseudxf Lens LT Eye Mild'
        },
        {
          diagnosisCode: 'H40.1422',
          diagnosisDescription: 'Capslr Glaucoma W/Pseudxf Lens LT Eye Moderate'
        },
        {
          diagnosisCode: 'H40.1423',
          diagnosisDescription: 'Capslr Glaucoma W/Pseudxf Lens, LT Eye, Severe'
        },
        {
          diagnosisCode: 'H40.1424',
          diagnosisDescription: 'Capslr Glauc W/Pseudxf Lens LT Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.1430',
          diagnosisDescription: 'Capslr Glaucoma W/Pseudxf Lens, Bi, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.1431',
          diagnosisDescription: 'Capslr Glaucoma W/Pseudxf Lens, Bi, Mild Stage'
        },
        {
          diagnosisCode: 'H40.1432',
          diagnosisDescription: 'Capslr Glaucoma W/Pseudxf Lens, Bi, Moderate Stage'
        },
        {
          diagnosisCode: 'H40.1433',
          diagnosisDescription: 'Capslr Glaucoma W/Pseudxf Lens, Bi, Severe Stage'
        },
        {
          diagnosisCode: 'H40.1434',
          diagnosisDescription: 'Capslr Glauc W/Pseudxf Lens, Bi, Indeterminate'
        },
        {
          diagnosisCode: 'H40.1490',
          diagnosisDescription: 'Capslr Glau W/Pseudxf Lens, Unsp Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.1491',
          diagnosisDescription: 'Capslr Glaucoma W/Pseudxf Lens, Unsp Eye, Mild'
        },
        {
          diagnosisCode: 'H40.1492',
          diagnosisDescription: 'Capslr Glaucoma W/Pseudxf Lens, Unsp Eye, Moderate Stage'
        },
        {
          diagnosisCode: 'H40.1493',
          diagnosisDescription: 'Capslr Glaucoma W/Pseudxf Lens, Unsp Eye, Severe Stage'
        },
        {
          diagnosisCode: 'H40.1494',
          diagnosisDescription: 'Capslr Glau W/Pseud Lens Unsp Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.151',
          diagnosisDescription: 'Residual Stage of Open-Angle Glaucoma, Right Eye'
        },
        {
          diagnosisCode: 'H40.152',
          diagnosisDescription: 'Residual Stage of Open-Angle Glaucoma, Left Eye'
        },
        {
          diagnosisCode: 'H40.153',
          diagnosisDescription: 'Residual Stage of Open-Angle Glaucoma, Bilateral'
        },
        {
          diagnosisCode: 'H40.159',
          diagnosisDescription: 'Residual Stage of Open-Angle Glaucoma, Unsp Eye'
        },
        {
          diagnosisCode: 'H40.20X0',
          diagnosisDescription: 'Unsp Primary Angle-Closure Glaucoma Stage Unsp'
        },
        {
          diagnosisCode: 'H40.20X1',
          diagnosisDescription: 'Unspecified Primary Angle-Closure Glaucoma, Mild'
        },
        {
          diagnosisCode: 'H40.20X2',
          diagnosisDescription: 'Unspecified Primary Angle-Closure Glau Moderate'
        },
        {
          diagnosisCode: 'H40.20X3',
          diagnosisDescription: 'Unspecified Primary Angle-Closure Glauc, Severe'
        },
        {
          diagnosisCode: 'H40.20X4',
          diagnosisDescription: 'Unsp Primary Angle-Closure Glauc, Indeterminate'
        },
        {
          diagnosisCode: 'H40.211',
          diagnosisDescription: 'Acute Angle-Closure Glaucoma, Right Eye'
        },
        {
          diagnosisCode: 'H40.212',
          diagnosisDescription: 'Acute Angle-Closure Glaucoma, Left Eye'
        },
        {
          diagnosisCode: 'H40.213',
          diagnosisDescription: 'Acute Angle-Closure Glaucoma, Bilateral'
        },
        {
          diagnosisCode: 'H40.219',
          diagnosisDescription: 'Acute Angle-Closure Glaucoma, Unspecified Eye'
        },
        {
          diagnosisCode: 'H40.2210',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma RTeye Stage Unsp'
        },
        {
          diagnosisCode: 'H40.2211',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma, RT Eye Mild'
        },
        {
          diagnosisCode: 'H40.2212',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma RT Eye Moderate'
        },
        {
          diagnosisCode: 'H40.2213',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma, RT Eye, Severe'
        },
        {
          diagnosisCode: 'H40.2214',
          diagnosisDescription: 'Chronic Angle-Closure Glauc RT Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.2220',
          diagnosisDescription: 'Chronic Angleclosure Glaucoma LT Eye Stage Unsp'
        },
        {
          diagnosisCode: 'H40.2221',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma LT Eye Mild Stage'
        },
        {
          diagnosisCode: 'H40.2222',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma LT Eye Moderate'
        },
        {
          diagnosisCode: 'H40.2223',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma, LT Eye, Severe'
        },
        {
          diagnosisCode: 'H40.2224',
          diagnosisDescription: 'Chronic Angle-Closure Glauc, LT Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.2230',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma, Bi, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.2231',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma, Bi, Mild Stage'
        },
        {
          diagnosisCode: 'H40.2232',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma, Bi, Moderate'
        },
        {
          diagnosisCode: 'H40.2233',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma, Bilateral, Severe Stage'
        },
        {
          diagnosisCode: 'H40.2234',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma, Bi Indeterminate Stage'
        },
        {
          diagnosisCode: 'H40.2290',
          diagnosisDescription: 'Chronic Angle-Closure Glauc, Unsp Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.2291',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma, Unsp Eye Mild'
        },
        {
          diagnosisCode: 'H40.2292',
          diagnosisDescription: 'Chronic Angle-Closure Glauc Unsp Eye Moderate'
        },
        {
          diagnosisCode: 'H40.2293',
          diagnosisDescription: 'Chronic Angle-Closure Glaucoma, Unsp Eye Severe'
        },
        {
          diagnosisCode: 'H40.2294',
          diagnosisDescription: 'Chr Angle-Closure Glauc Unsp Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.231',
          diagnosisDescription: 'Intermittent Angle-Closure Glaucoma, Right Eye'
        },
        {
          diagnosisCode: 'H40.232',
          diagnosisDescription: 'Intermittent Angle-Closure Glaucoma, Left Eye'
        },
        {
          diagnosisCode: 'H40.233',
          diagnosisDescription: 'Intermittent Angle-Closure Glaucoma, Bilateral'
        },
        {
          diagnosisCode: 'H40.239',
          diagnosisDescription: 'Intermittent Angle-Closure Glaucoma, Unsp Eye'
        },
        {
          diagnosisCode: 'H40.241',
          diagnosisDescription: 'Residual Stage of Angle-Closure Glaucoma, RT Eye'
        },
        {
          diagnosisCode: 'H40.242',
          diagnosisDescription: 'Residual Stage of Angle-Closure Glaucoma, LT Eye'
        },
        {
          diagnosisCode: 'H40.243',
          diagnosisDescription: 'Residual Stage of Angle-Closure Glaucoma, Bilat'
        },
        {
          diagnosisCode: 'H40.249',
          diagnosisDescription: 'Residual Stage Angle-Closure Glaucoma, Unsp Eye'
        },
        {
          diagnosisCode: 'H40.30X0',
          diagnosisDescription: 'Glauc Sec Eye Trauma Unsp Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.30X1',
          diagnosisDescription: 'Glauc Sec To Eye Trauma, Unsp Eye Mild Stage'
        },
        {
          diagnosisCode: 'H40.30X2',
          diagnosisDescription: 'Glauc Sec To Eye Trauma, Unsp Eye, Moderate'
        },
        {
          diagnosisCode: 'H40.30X3',
          diagnosisDescription: 'Glauc Sec To Eye Trauma, Unsp Eye, Severe Stage'
        },
        {
          diagnosisCode: 'H40.30X4',
          diagnosisDescription: 'Glauc Sec To Eye Trauma, Unsp Eye, Indeterminate'
        },
        {
          diagnosisCode: 'H40.31X0',
          diagnosisDescription: 'Glaucoma Sec To Eye Trauma, RT Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.31X1',
          diagnosisDescription: 'Glaucoma Secondary To Eye Trauma, RT Eye, Mild'
        },
        {
          diagnosisCode: 'H40.31X2',
          diagnosisDescription: 'Glaucoma Sec To Eye Trauma, RT Eye, Moderate'
        },
        {
          diagnosisCode: 'H40.31X3',
          diagnosisDescription: 'Glaucoma Secondary To Eye Trauma RT Eye Severe'
        },
        {
          diagnosisCode: 'H40.31X4',
          diagnosisDescription: 'Glauc Sec To Eye Trauma, RT Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.32X0',
          diagnosisDescription: 'Glauc Secy To Eye Trauma, LT Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.32X1',
          diagnosisDescription: 'Glaucoma Secondary To Eye Trauma, LT Eye, Mild'
        },
        {
          diagnosisCode: 'H40.32X2',
          diagnosisDescription: 'Glaucoma Sec To Eye Trauma, LT Eye Moderate'
        },
        {
          diagnosisCode: 'H40.32X3',
          diagnosisDescription: 'Glaucoma Secondary To Eye Trauma LT Eye Severe'
        },
        {
          diagnosisCode: 'H40.32X4',
          diagnosisDescription: 'Glaucoma Sec To Eye Trauma LT Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.33X0',
          diagnosisDescription: 'Glaucoma Sec To Eye Trauma, Bi, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.33X1',
          diagnosisDescription: 'Glaucoma Secondary To Eye Trauma, Bi, Mild'
        },
        {
          diagnosisCode: 'H40.33X2',
          diagnosisDescription: 'Glaucoma Secondary To Eye Trauma, Bi, Moderate'
        },
        {
          diagnosisCode: 'H40.33X3',
          diagnosisDescription: 'Glaucoma Secondary To Eye Trauma, Bi Severe'
        },
        {
          diagnosisCode: 'H40.33X4',
          diagnosisDescription: 'Glaucoma Sec To Eye Trauma, Bi, Indeterminate'
        },
        {
          diagnosisCode: 'H40.40X0',
          diagnosisDescription: 'Glaucoma Sec To Eye Inflam, Unsp Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.40X1',
          diagnosisDescription: 'Glaucoma Sec To Eye Inflam, Unsp Eye, Mild Stage'
        },
        {
          diagnosisCode: 'H40.40X2',
          diagnosisDescription: 'Glaucoma Sec To Eye Inflam, Unsp Eye, Moderate'
        },
        {
          diagnosisCode: 'H40.40X3',
          diagnosisDescription: 'Glaucoma Sec To Eye Inflam,Unsp Eye, Severe Stage'
        },
        {
          diagnosisCode: 'H40.40X4',
          diagnosisDescription: 'Glauc Sec To Eye Inflam Unsp Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.41X0',
          diagnosisDescription: 'Glaucoma Sec To Eye Inflam, RT Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.41X1',
          diagnosisDescription: 'Glaucoma Secondary To Eye Inflam, RT Eye, Mild'
        },
        {
          diagnosisCode: 'H40.41X2',
          diagnosisDescription: 'Glaucoma Sec To Eye Inflam, RT Eye, Moderate'
        },
        {
          diagnosisCode: 'H40.41X3',
          diagnosisDescription: 'Glaucoma Secondary To Eye Inflam, RT Eye Severe'
        },
        {
          diagnosisCode: 'H40.41X4',
          diagnosisDescription: 'Glaucoma Sec To Eye Inflam RT Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.42X0',
          diagnosisDescription: 'Glaucoma Sec To Eye Inflam, LT Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.42X1',
          diagnosisDescription: 'Glaucoma Secondary To Eye Inflam, LT Eye, Mild'
        },
        {
          diagnosisCode: 'H40.42X2',
          diagnosisDescription: 'Glaucoma Sec To Eye Inflam LT Eye, Moderate'
        },
        {
          diagnosisCode: 'H40.42X3',
          diagnosisDescription: 'Glaucoma Secondary To Eye Inflam LT Eye, Severe'
        },
        {
          diagnosisCode: 'H40.42X4',
          diagnosisDescription: 'Glaucoma Sec To Eye Inflam LT Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.43X0',
          diagnosisDescription: 'Glaucoma Secondary To Eye Inflam, Bi, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.43X1',
          diagnosisDescription: 'Glaucoma Secondary To Eye Inflam, Bi, Mild'
        },
        {
          diagnosisCode: 'H40.43X2',
          diagnosisDescription: 'Glaucoma Secondary To Eye Inflam, Bi, Moderate'
        },
        {
          diagnosisCode: 'H40.43X3',
          diagnosisDescription: 'Glaucoma Secondary To Eye Inflam, Bi, Severe'
        },
        {
          diagnosisCode: 'H40.43X4',
          diagnosisDescription: 'Glaucoma Sec To Eye Inflam, Bi, Indeterminate'
        },
        {
          diagnosisCode: 'H40.50X0',
          diagnosisDescription: 'Glauc Sec To Oth Eye Disord, Unsp Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.50X1',
          diagnosisDescription: 'Glauc Sec To Oth Eye Disord, Unsp Eye, Mild Stage'
        },
        {
          diagnosisCode: 'H40.50X2',
          diagnosisDescription: 'Glauc Sec To Oth Eye Disord, Unsp Eye, Moderate'
        },
        {
          diagnosisCode: 'H40.50X3',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disord, Unsp Eye, Severe'
        },
        {
          diagnosisCode: 'H40.50X4',
          diagnosisDescription: 'Glauc Sec To Oth Eye Disord Uns Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.51X0',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disord, RT Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.51X1',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disord, RT Eye, Mild'
        },
        {
          diagnosisCode: 'H40.51X2',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disord RT Eye Moderate'
        },
        {
          diagnosisCode: 'H40.51X3',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disord RT Eye Severe'
        },
        {
          diagnosisCode: 'H40.51X4',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disord RT Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.52X0',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disord LT Eye Stage Unsp'
        },
        {
          diagnosisCode: 'H40.52X1',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disord, LT Eye, Mild'
        },
        {
          diagnosisCode: 'H40.52X2',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disord LT Eye Moderate'
        },
        {
          diagnosisCode: 'H40.52X3',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disord, LT Eye, Severe'
        },
        {
          diagnosisCode: 'H40.52X4',
          diagnosisDescription: 'Glauc Sec To Oth Eye Disord LT Eye Indeterminate'
        },
        {
          diagnosisCode: 'H40.53X0',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disorders, Bi, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.53X1',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disorders, Bi, Mild Stage'
        },
        {
          diagnosisCode: 'H40.53X2',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disorders, Bi, Moderate'
        },
        {
          diagnosisCode: 'H40.53X3',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disorders, Bi, Severe'
        },
        {
          diagnosisCode: 'H40.53X4',
          diagnosisDescription: 'Glaucoma Sec To Oth Eye Disord, Bi Indeterminate'
        },
        {
          diagnosisCode: 'H40.60X1',
          diagnosisDescription: 'Glaucoma Sec To Drugs, Unspecified Eye, Mild'
        },
        {
          diagnosisCode: 'H40.60X2',
          diagnosisDescription: 'Glaucoma Sec To Drugs, Unspec Eye, Moderate'
        },
        {
          diagnosisCode: 'H40.60X3',
          diagnosisDescription: 'Glaucoma Sec To Drugs, Unspe Eye, Severe Stage'
        },
        {
          diagnosisCode: 'H40.60X4',
          diagnosisDescription: 'Glaucoma Sec To Drugs, Unsp Eye, Indeterminate'
        },
        {
          diagnosisCode: 'H40.61X0',
          diagnosisDescription: 'Glaucoma Secondary To Drugs, RT Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.61X1',
          diagnosisDescription: 'Glaucoma Secondary To Drugs, RT Eye, Mild Stage'
        },
        {
          diagnosisCode: 'H40.61X2',
          diagnosisDescription: 'Glaucoma Secondary To Drugs, RT Eye, Moderate'
        },
        {
          diagnosisCode: 'H40.61X3',
          diagnosisDescription: 'Glaucoma Secondary To Drugs, RT Eye, Severe'
        },
        {
          diagnosisCode: 'H40.61X4',
          diagnosisDescription: 'Glaucoma Sec To Drugs, RT Eye, Indeterminate'
        },
        {
          diagnosisCode: 'H40.62X0',
          diagnosisDescription: 'Glaucoma Secondary To Drugs, LT Eye, Stage Unsp'
        },
        {
          diagnosisCode: 'H40.62X1',
          diagnosisDescription: 'Glaucoma Secondary To Drugs,LT Eye, Mild Stage'
        },
        {
          diagnosisCode: 'H40.62X2',
          diagnosisDescription: 'Glaucoma Secondary To Drugs, LT Eye, Moderate'
        },
        {
          diagnosisCode: 'H40.62X3',
          diagnosisDescription: 'Glaucoma Secondary To Drugs, LT Eye, Severe'
        },
        {
          diagnosisCode: 'H40.62X4',
          diagnosisDescription: 'Glaucoma Sec To Drugs, LT Eye, Indeterminate'
        },
        {
          diagnosisCode: 'H40.63X0',
          diagnosisDescription: 'Glaucoma Secondary To Drugs, Bi, Stage Unspecified'
        },
        {
          diagnosisCode: 'H40.63X1',
          diagnosisDescription: 'Glaucoma Secondary To Drugs, Bi, Mild Stage'
        },
        {
          diagnosisCode: 'H40.63X2',
          diagnosisDescription: 'Glaucoma Secondary To Drugs, Bi, Moderate Stage'
        },
        {
          diagnosisCode: 'H40.63X3',
          diagnosisDescription: 'Glaucoma Secondary To Drugs, Bi, Severe Stage'
        },
        {
          diagnosisCode: 'H40.63X4',
          diagnosisDescription: 'Glaucoma Secondary To Drugs, Bi, Indeterminate'
        },
        {
          diagnosisCode: 'H40.811',
          diagnosisDescription: 'Glauc W Increased Episcleral Venous Pres RT Eye'
        },
        {
          diagnosisCode: 'H40.812',
          diagnosisDescription: 'Glauc W Increased Episcleral Venous Pres, LT Eye'
        },
        {
          diagnosisCode: 'H40.813',
          diagnosisDescription: 'Glauc W Increased Episcleral Venous Pressure, Bi'
        },
        {
          diagnosisCode: 'H40.819',
          diagnosisDescription: 'Glauc W Increased Episcleral Venous Pres Unsp Eye'
        },
        {
          diagnosisCode: 'H40.821',
          diagnosisDescription: 'Hypersecretion Glaucoma, Right Eye'
        },
        {
          diagnosisCode: 'H40.822',
          diagnosisDescription: 'Hypersecretion Glaucoma, Left Eye'
        },
        {
          diagnosisCode: 'H40.823',
          diagnosisDescription: 'Hypersecretion Glaucoma, Bilateral'
        },
        {
          diagnosisCode: 'H40.829',
          diagnosisDescription: 'Hypersecretion Glaucoma, Unspecified Eye'
        },
        {
          diagnosisCode: 'H40.831',
          diagnosisDescription: 'Aqueous Misdirection, Right Eye'
        },
        {
          diagnosisCode: 'H40.832',
          diagnosisDescription: 'Aqueous Misdirection, Left Eye'
        },
        {
          diagnosisCode: 'H40.833',
          diagnosisDescription: 'Aqueous Misdirection, Bilateral'
        },
        {
          diagnosisCode: 'H40.839',
          diagnosisDescription: 'Aqueous Misdirection, Unspecified Eye'
        },
        {
          diagnosisCode: 'H40.89',
          diagnosisDescription: 'Other Specified Glaucoma'
        },
        {
          diagnosisCode: 'H40.9',
          diagnosisDescription: 'Unspecified Glaucoma'
        },
        {
          diagnosisCode: 'H42',
          diagnosisDescription: 'Glaucoma In Diseases Classified Elsewhere'
        },
        {
          diagnosisCode: 'Q15.0',
          diagnosisDescription: 'Congenital Glaucoma'
        },

      ]
    },
    {
      conditionDescription: 'High Cholesterol',
      diagnosis: [
        {
          diagnosisCode: 'E78.0',
          diagnosisDescription: 'Pure Hypercholesterolemia'
        },
        {
          diagnosisCode: 'E78.1',
          diagnosisDescription: 'Pure Hyperglyceridemia'
        },
        {
          diagnosisCode: 'E78.2',
          diagnosisDescription: 'Mixed Hyperlipidemia'
        },
        {
          diagnosisCode: 'E78.4',
          diagnosisDescription: 'Other Hyperlipidemia'
        },
        {
          diagnosisCode: 'E78.5',
          diagnosisDescription: 'Hyperlipidemia, Unspecified'
        },

      ]
    },
    {
      conditionDescription: 'Hypertension',
      diagnosis: [
        {
          diagnosisCode: 'I10',
          diagnosisDescription: 'Essential (Primary) Hypertension'
        },
        {
          diagnosisCode: 'I97.3',
          diagnosisDescription: 'Postprocedural Hypertension'
        }
      ]
    },
    {
      conditionDescription: 'Arcus',
      diagnosis: [
        {
          diagnosisCode: 'H18.411',
          diagnosisDescription: 'Arcus Senilis, Right Eye'
        },
        {
          diagnosisCode: 'H18.412',
          diagnosisDescription: 'Arcus Senilis, Left Eye'
        },
        {
          diagnosisCode: 'H18.413',
          diagnosisDescription: 'Arcus Senilis, Bilateral'
        },
        {
          diagnosisCode: 'H18.419',
          diagnosisDescription: 'Arcus Senilis, Unspecified Eye'
        },

      ]
    },
    {
      conditionDescription: 'Diabetes Mellitus Type 1',
      diagnosis: [
        {
          diagnosisCode: 'E10.10',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Ketoacidosis without Coma'
        },
        {
          diagnosisCode: 'E10.11',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Ketoacidosis with Coma'
        },
        {
          diagnosisCode: 'E10.21',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Diabetic Nephropathy'
        },
        {
          diagnosisCode: 'E10.22',
          diagnosisDescription: 'Type 1 Diabetes Mellitus W Diabetic Chronic Kidney Disease'
        },
        {
          diagnosisCode: 'E10.29',
          diagnosisDescription: 'Type 1 Diabetes Mellitus W Oth Diabetic Kidney Complication'
        },
        {
          diagnosisCode: 'E10.311',
          diagnosisDescription: 'Type 1 Diabetes W Unsp Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode: 'E10.319',
          diagnosisDescription: 'Type 1 Diabetes W Unsp Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E10.321',
          diagnosisDescription: 'Type 1 Diab W Mild Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E10.3211',
          diagnosisDescription: 'Type1 DM w mild nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode: 'E10.3212',
          diagnosisDescription: 'Type1 DM w mild nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode: 'E10.3213',
          diagnosisDescription: 'Type1 DM w mild nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3219',
          diagnosisDescription: 'Type1 DM w mild nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E10.329',
          diagnosisDescription: 'Type 1 Diab W Mild Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E10.3291',
          diagnosisDescription: 'Type1 DM w mild nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode: 'E10.3292',
          diagnosisDescription: 'Type1 DM w mild nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode: 'E10.3293',
          diagnosisDescription: 'Type1 DM w mild nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3299',
          diagnosisDescription: 'Type1 DM w mild nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E10.331',
          diagnosisDescription: 'Type 1 Diab W Moderate Nonprlf Diab RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E10.3311',
          diagnosisDescription: 'Type1 DM w mod nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode: 'E10.3312',
          diagnosisDescription: 'Type1 DM w mod nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode: 'E10.3313',
          diagnosisDescription: 'Type1 DM w mod nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3319',
          diagnosisDescription: 'Type1 DM w mod nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E10.339',
          diagnosisDescription: 'Type 1 Diab W Moderate Nonprlf Diab RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E10.3391',
          diagnosisDescription: 'Type1 DM w mod nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode: 'E10.3392',
          diagnosisDescription: 'Type1 DM w mod nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode: 'E10.3393',
          diagnosisDescription: 'Type1 DM w mod nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3399',
          diagnosisDescription: 'Type1 DM w mod nonpro diab retinopathy wo mac edema unsp eye'
        },
        {
          diagnosisCode: 'E10.341',
          diagnosisDescription: 'Type 1 Diab W Severe Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E10.3411',
          diagnosisDescription: 'Type1 DM w sev nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode: 'E10.3412',
          diagnosisDescription: 'Type1 DM w sev nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode: 'E10.3413',
          diagnosisDescription: 'Type1 DM w sev nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3419',
          diagnosisDescription: 'Type1 DM w sev nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E10.349',
          diagnosisDescription: 'Type 1 Diab W Severe Nonprlf DiabRTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E10.3491',
          diagnosisDescription: 'Type1 DM w sev nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode: 'E10.3492',
          diagnosisDescription: 'Type1 DM w sev nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode: 'E10.3493',
          diagnosisDescription: 'Type1 DM w sev nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3499',
          diagnosisDescription: 'Type1 DM w sev nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E10.351',
          diagnosisDescription: 'Type 1 Diabetes W Prolif DiabeticRTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E10.3511',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode: 'E10.3512',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode: 'E10.3513',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3519',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E10.3521',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w trac RD invo macula, RT eye'
        },
        {
          diagnosisCode: 'E10.3522',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w trac RD invo macula, LT eye'
        },
        {
          diagnosisCode: 'E10.3523',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w trac RD invo macula, bilateral'
        },
        {
          diagnosisCode: 'E10.3529',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w trac RD invo macula, unsp eye'
        },
        {
          diagnosisCode: 'E10.3531',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w trac RD not invo mac RT eye'
        },
        {
          diagnosisCode: 'E10.3532',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w trac RD not invo mac LT eye'
        },
        {
          diagnosisCode: 'E10.3533',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w trac RD not invo mac bilateral'
        },
        {
          diagnosisCode: 'E10.3539',
          diagnosisDescription: 'Type1 DM w proli diab retinopathy w trac RD not invo mac unsp eye'
        },
        {
          diagnosisCode: 'E10.3541',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w comb trac RD rhegm rd RT eye'
        },
        {
          diagnosisCode: 'E10.3542',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w comb trac RD rhegm rd LT eye'
        },
        {
          diagnosisCode: 'E10.3543',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy w comb trac RD rhegm rd, bi'
        },
        {
          diagnosisCode: 'E10.3549',
          diagnosisDescription: 'Type1 DM w prol diabretinopathy w com trac RD rhegm rd unsp eye'
        },
        {
          diagnosisCode: 'E10.3551',
          diagnosisDescription: 'Type1 DM w stable prolif diab retinopathy, RT eye'
        },
        {
          diagnosisCode: 'E10.3552',
          diagnosisDescription: 'Type1 DM w stable prolif diab retinopathy, LT eye'
        },
        {
          diagnosisCode: 'E10.3553',
          diagnosisDescription: 'Type1 DM w stable prolif diab retinopathy, bilateral'
        },
        {
          diagnosisCode: 'E10.3559',
          diagnosisDescription: 'Type1 DM w stable prolif diab retinopathy, unsp eye'
        },
        {
          diagnosisCode: 'E10.359',
          diagnosisDescription: 'Type 1 Diabetes W Prolif DiabeticRTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E10.3591',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode: 'E10.3592',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode: 'E10.3593',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3599',
          diagnosisDescription: 'Type1 DM w prolif diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E10.36',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Diabetic Cataract'
        },
        {
          diagnosisCode: 'E10.37X1',
          diagnosisDescription: 'Type1 DM w diabetic mac edema, resolved folw TM, RT eye'
        },
        {
          diagnosisCode: 'E10.37X2',
          diagnosisDescription: 'Type1 DM w diabetic mac edema, resolved folw TM, LT eye'
        },
        {
          diagnosisCode: 'E10.37X3',
          diagnosisDescription: 'Type1 DM w diabetic mac edema, resolved folw TM, bilateral'
        },
        {
          diagnosisCode: 'E10.37X9',
          diagnosisDescription: 'Type1 DM w diabetic mac edema, resolved folw TM, unsp eye'
        },
        {
          diagnosisCode: 'E10.39',
          diagnosisDescription: 'Type 1 Diabetes W Oth Diabetic Ophthalmic Complication'
        },
        {
          diagnosisCode: 'E10.40',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Diabetic Neuropathy, Unsp'
        },
        {
          diagnosisCode: 'E10.41',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Diabetic Mononeuropathy'
        },
        {
          diagnosisCode: 'E10.42',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Diabetic Polyneuropathy'
        },
        {
          diagnosisCode: 'E10.43',
          diagnosisDescription: 'Type 1 Diabetes W Diabetic Autonomic (Poly)Neuropathy'
        },
        {
          diagnosisCode: 'E10.44',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Diabetic Amyotrophy'
        },
        {
          diagnosisCode: 'E10.49',
          diagnosisDescription: 'Type 1 Diabetes W Oth Diabetic Neurological Complication'
        },
        {
          diagnosisCode: 'E10.51',
          diagnosisDescription: 'Type 1 Diabetes W Diabetic Peripheral Angiopath w/o Gangrene'
        },
        {
          diagnosisCode: 'E10.52',
          diagnosisDescription: 'Type 1 Diabetes W Diabetic Peripheral Angiopathy W Gangrene'
        },
        {
          diagnosisCode: 'E10.59',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Othcirculatory Complications'
        },
        {
          diagnosisCode: 'E10.610',
          diagnosisDescription: 'Type 1 Diabetes Mellitus W Diabetic Neuropathic ARThropathy'
        },
        {
          diagnosisCode: 'E10.618',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Other Diabetic ARThropathy'
        },
        {
          diagnosisCode: 'E10.620',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Diabetic Dermatitis'
        },
        {
          diagnosisCode: 'E10.621',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Footulcer'
        },
        {
          diagnosisCode: 'E10.622',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Other Skin Ulcer'
        },
        {
          diagnosisCode: 'E10.628',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Other Skin Complications'
        },
        {
          diagnosisCode: 'E10.630',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Periodontal Disease'
        },
        {
          diagnosisCode: 'E10.638',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Other Oral Complications'
        },
        {
          diagnosisCode: 'E10.641',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Hypoglycemia with Coma'
        },
        {
          diagnosisCode: 'E10.649',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Hypoglycemia without Coma'
        },
        {
          diagnosisCode: 'E10.65',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Hyperglycemia'
        },
        {
          diagnosisCode: 'E10.69',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Other Specified Complication'
        },
        {
          diagnosisCode: 'E10.8',
          diagnosisDescription: 'Type 1 Diabetes Mellitus with Unspecified Complications'
        },
        {
          diagnosisCode: 'E11.3211',
          diagnosisDescription: 'Type2 DM w mild nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode: 'E11.3212',
          diagnosisDescription: 'Type2 DM w mild nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode: 'E11.3213',
          diagnosisDescription: 'Type2 DM w mild nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3219',
          diagnosisDescription: 'Type2 DM w mild nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E11.3291',
          diagnosisDescription: 'Type2 DM w mild nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode: 'E11.3292',
          diagnosisDescription: 'Type2 DM w mild nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode: 'E11.3293',
          diagnosisDescription: 'Type2 DM w mild nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3299',
          diagnosisDescription: 'Type2 DM w mild nonpro diab retinopathy wo mac edema unsp eye'
        },
        {
          diagnosisCode: 'E11.3311',
          diagnosisDescription: 'Type2 DM w mod nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode: 'E11.3312',
          diagnosisDescription: 'Type2 DM w mod nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode: 'E11.3313',
          diagnosisDescription: 'Type2 DM w mod nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3319',
          diagnosisDescription: 'Type2 DM w mod nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E11.3391',
          diagnosisDescription: 'Type2 DM w mod nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode: 'E11.3392',
          diagnosisDescription: 'Type2 DM w mod nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode: 'E11.3393',
          diagnosisDescription: 'Type2 DM w mod nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3399',
          diagnosisDescription: 'Type2 DM w mod nonpro diab retinopathy wo mac edema unsp eye'
        },
        {
          diagnosisCode: 'E11.3411',
          diagnosisDescription: 'Type2 DM w sev nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode: 'E11.3412',
          diagnosisDescription: 'Type2 DM w sev nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode: 'E11.3413',
          diagnosisDescription: 'Type2 DM w sev nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3419',
          diagnosisDescription: 'Type2 DM w sev nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E11.3491',
          diagnosisDescription: 'Type2 DM w sev nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode: 'E11.3492',
          diagnosisDescription: 'Type2 DM w sev nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode: 'E11.3493',
          diagnosisDescription: 'Type2 DM w sev nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3499',
          diagnosisDescription: 'Type2 DM w sev nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E11.3511',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode: 'E11.3512',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode: 'E11.3513',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3519',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E11.3521',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w trac RD invo macula, RT eye'
        },
        {
          diagnosisCode: 'E11.3522',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w trac RD invo macula, LT eye'
        },
        {
          diagnosisCode: 'E11.3523',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w trac RD invo macula, bilateral'
        },
        {
          diagnosisCode: 'E11.3529',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w trac RD invo macula, unsp eye'
        },
        {
          diagnosisCode: 'E11.3531',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w trac RD not invo mac, RT eye'
        },
        {
          diagnosisCode: 'E11.3532',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w trac RD not invo mac, LT eye'
        },
        {
          diagnosisCode: 'E11.3533',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w trac RD not invo mac bilateral'
        },
        {
          diagnosisCode: 'E11.3539',
          diagnosisDescription: 'Type2 DM w proli diab retinopathy w trac RD not invo mac unsp eye'
        },
        {
          diagnosisCode: 'E11.3541',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w comb trac RD/rhegm rd, RT'
        },
        {
          diagnosisCode: 'E11.3542',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w comb trac RD/rhegm rd, LT'
        },
        {
          diagnosisCode: 'E11.3543',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w comb trac RD/rhegm rd, bi'
        },
        {
          diagnosisCode: 'E11.3549',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy w comb trac RD/rhegm rd, unsp'
        },
        {
          diagnosisCode: 'E11.3551',
          diagnosisDescription: 'Type2 DM w stable prolif diab retinopathy, RT eye'
        },
        {
          diagnosisCode: 'E11.3552',
          diagnosisDescription: 'Type2 DM w stable prolif diab retinopathy, LT eye'
        },
        {
          diagnosisCode: 'E11.3553',
          diagnosisDescription: 'Type2 DM w stable prolif diab retinopathy, bilateral'
        },
        {
          diagnosisCode: 'E11.3559',
          diagnosisDescription: 'Type2 DM w stable prolif diab retinopathy, unsp eye'
        },
        {
          diagnosisCode: 'E11.3591',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode: 'E11.3592',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode: 'E11.3593',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3599',
          diagnosisDescription: 'Type2 DM w prolif diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E13.311',
          diagnosisDescription: 'Oth Diabetes W Unsp Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode: 'E13.319',
          diagnosisDescription: 'Oth Diabetes W Unsp Diabetic Retinopathy w/o Macular Edema'
        },
        {
          diagnosisCode: 'E13.321',
          diagnosisDescription: 'Oth Diabetes W Mild Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E13.3211',
          diagnosisDescription: 'Oth DM w mild nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode: 'E13.3212',
          diagnosisDescription: 'Oth DM w mild nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode: 'E13.3213',
          diagnosisDescription: 'Oth DM w mild nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3219',
          diagnosisDescription: 'Oth DM w mild nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E13.329',
          diagnosisDescription: 'Oth Diabetes W Mild Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E13.3291',
          diagnosisDescription: 'Oth DM w mild nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode: 'E13.3292',
          diagnosisDescription: 'Oth DM w mild nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode: 'E13.3293',
          diagnosisDescription: 'Oth DM w mild nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3299',
          diagnosisDescription: 'Oth DM w mild nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E13.331',
          diagnosisDescription: 'Oth Diab W Moderate Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E13.3311',
          diagnosisDescription: 'Oth DM w mod nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode: 'E13.3312',
          diagnosisDescription: 'Oth DM w mod nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode: 'E13.3313',
          diagnosisDescription: 'Oth DM w mod nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3319',
          diagnosisDescription: 'Oth DM w mod nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E13.339',
          diagnosisDescription: 'Oth Diab W Moderate Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E13.3391',
          diagnosisDescription: 'Oth DM w mod nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode: 'E13.3392',
          diagnosisDescription: 'Oth DM w mod nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode: 'E13.3393',
          diagnosisDescription: 'Oth DM w mod nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3399',
          diagnosisDescription: 'Oth DM w mod nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E13.341',
          diagnosisDescription: 'Oth Diabetes W Severe Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode: 'E13.3411',
          diagnosisDescription: 'Oth DM w sev nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode: 'E13.3412',
          diagnosisDescription: 'Oth DM w sev nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode: 'E13.3413',
          diagnosisDescription: 'Oth DM w sev nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3419',
          diagnosisDescription: 'Oth DM w sev nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E13.349',
          diagnosisDescription: 'Oth Diab W Severe Nonprlf DiabeticRTnop w/o Macular Edema'
        },
        {
          diagnosisCode: 'E13.3491',
          diagnosisDescription: 'Oth DM w sev nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode: 'E13.3492',
          diagnosisDescription: 'Oth DM w sev nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode: 'E13.3493',
          diagnosisDescription: 'Oth DM w sev nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3499',
          diagnosisDescription: 'Oth DM w sev nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E13.351',
          diagnosisDescription: 'Oth Diabetes W Prolif Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode: 'E13.3511',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode: 'E13.3512',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode: 'E13.3513',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3519',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode: 'E13.3521',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w trac RD invo macula, RT eye'
        },
        {
          diagnosisCode: 'E13.3522',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w trac RD invo macula, LT eye'
        },
        {
          diagnosisCode: 'E13.3523',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w trac RD invo macula, bilateral'
        },
        {
          diagnosisCode: 'E13.3529',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w trac RD invo macula, unsp eye'
        },
        {
          diagnosisCode: 'E13.3531',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w trac RD not invo macula, RT eye'
        },
        {
          diagnosisCode: 'E13.3532',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w trac RD not invo macula, LT eye'
        },
        {
          diagnosisCode: 'E13.3533',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w trac RD not invo macula, bi'
        },
        {
          diagnosisCode: 'E13.3539',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w trac RD not invo macula, unsp'
        },
        {
          diagnosisCode: 'E13.3541',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w comb trac RD and rhegm rd, RT'
        },
        {
          diagnosisCode: 'E13.3542',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w comb trac RD and rhegm rd, LT'
        },
        {
          diagnosisCode: 'E13.3543',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w comb trac RD and rhegm rd, bi'
        },
        {
          diagnosisCode: 'E13.3549',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy w comb trac RD rhegm rd, unsp'
        },
        {
          diagnosisCode: 'E13.3551',
          diagnosisDescription: 'Oth DM w stable prolif diab retinopathy, RT eye'
        },
        {
          diagnosisCode: 'E13.3552',
          diagnosisDescription: 'Oth DM w stable prolif diab retinopathy, LT eye'
        },
        {
          diagnosisCode: 'E13.3553',
          diagnosisDescription: 'Oth DM w stable prolif diab retinopathy, bilateral'
        },
        {
          diagnosisCode: 'E13.3559',
          diagnosisDescription: 'Oth DM w stable prolif diab retinopathy, unsp eye'
        },
        {
          diagnosisCode: 'E13.359',
          diagnosisDescription: 'Oth Diabetes W Prolif Diabetic Retinopathy w/o Macular Edema'
        },
        {
          diagnosisCode: 'E13.3591',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode: 'E13.3592',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode: 'E13.3593',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3599',
          diagnosisDescription: 'Oth DM w prolif diab retinopathy wo mac edema, unsp eye'
        },

      ]
    },
    {
      conditionDescription: 'Diabetes Mellitus Type 2',
      diagnosis: [
        {
          diagnosisCode:	'E10.3211',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3212',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3213',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3219',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E10.3291',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3292',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3293',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3299',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E10.3311',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3312',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3313',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3319',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E10.3391',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3392',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3393',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3399',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy wo mac edema unsp eye'
        },
        {
          diagnosisCode:	'E10.3411',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3412',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3413',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3419',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E10.3491',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3492',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3493',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3499',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E10.3511',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3512',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3513',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3519',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E10.3521',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD invo macula, RT eye'
        },
        {
          diagnosisCode:	'E10.3522',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD invo macula, LT eye'
        },
        {
          diagnosisCode:	'E10.3523',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD invo macula, bilateral'
        },
        {
          diagnosisCode:	'E10.3529',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD invo macula, unsp eye'
        },
        {
          diagnosisCode:	'E10.3531',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD not invo mac RT eye'
        },
        {
          diagnosisCode:	'E10.3532',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD not invo mac LT eye'
        },
        {
          diagnosisCode:	'E10.3533',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD not invo mac bilateral'
        },
        {
          diagnosisCode:	'E10.3539',
          diagnosisDescription:	'Type1 DM w proli diab retinopathy w trac RD not invo mac unsp eye'
        },
        {
          diagnosisCode:	'E10.3541',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w comb trac RD rhegm rd RT eye'
        },
        {
          diagnosisCode:	'E10.3542',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w comb trac RD rhegm rd LT eye'
        },
        {
          diagnosisCode:	'E10.3543',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w comb trac RD rhegm rd, bi'
        },
        {
          diagnosisCode:	'E10.3549',
          diagnosisDescription:	'Type1 DM w prol diabretinopathy w com trac RD rhegm rd unsp eye'
        },
        {
          diagnosisCode:	'E10.3551',
          diagnosisDescription:	'Type1 DM w stable prolif diab retinopathy, RT eye'
        },
        {
          diagnosisCode:	'E10.3552',
          diagnosisDescription:	'Type1 DM w stable prolif diab retinopathy, LT eye'
        },
        {
          diagnosisCode:	'E10.3553',
          diagnosisDescription:	'Type1 DM w stable prolif diab retinopathy, bilateral'
        },
        {
          diagnosisCode:	'E10.3559',
          diagnosisDescription:	'Type1 DM w stable prolif diab retinopathy, unsp eye'
        },
        {
          diagnosisCode:	'E10.3591',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3592',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3593',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3599',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.00',
          diagnosisDescription:	'Type 2 Diab W Hyprosm w/o Nonket Hyprgly-Hypros Coma (Nkhhc)'
        },
        {
          diagnosisCode:	'E11.01',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Hyperosmolarity with Coma'
        },
        {
          diagnosisCode:	'E11.10',
          diagnosisDescription:	'Type 2 diabetes mellitus with ketoacidosis without coma'
        },
        {
          diagnosisCode:	'E11.11',
          diagnosisDescription:	'Type 2 diabetes mellitus with ketoacidosis with coma'
        },
        {
          diagnosisCode:	'E11.21',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Diabetic Nephropathy'
        },
        {
          diagnosisCode:	'E11.22',
          diagnosisDescription:	'Type 2 Diabetes Mellitus W Diabetic Chronic Kidney Disease'
        },
        {
          diagnosisCode:	'E11.29',
          diagnosisDescription:	'Type 2 Diabetes Mellitus W Oth Diabetic Kidney Complication'
        },
        {
          diagnosisCode:	'E11.311',
          diagnosisDescription:	'Type 2 Diabetes W Unsp Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode:	'E11.319',
          diagnosisDescription:	'Type 2 Diabetes W Unsp Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E11.321',
          diagnosisDescription:	'Type 2 Diab W Mild Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E11.3211',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3212',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3213',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3219',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.329',
          diagnosisDescription:	'Type 2 Diab W Mild Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E11.3291',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3292',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3293',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3299',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy wo mac edema unsp eye'
        },
        {
          diagnosisCode:	'E11.331',
          diagnosisDescription:	'Type 2 Diab W Moderate Nonprlf Diab RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E11.3311',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3312',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3313',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3319',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.339',
          diagnosisDescription:	'Type 2 Diab W Moderate Nonprlf Diab RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E11.3391',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3392',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3393',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3399',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy wo mac edema unsp eye'
        },
        {
          diagnosisCode:	'E11.341',
          diagnosisDescription:	'Type 2 Diab W Severe Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E11.3411',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3412',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3413',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3419',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.349',
          diagnosisDescription:	'Type 2 Diab W Severe Nonprlf DiabRTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E11.3491',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3492',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3493',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3499',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.351',
          diagnosisDescription:	'Type 2 Diabetes W Prolif DiabeticRTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E11.3511',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3512',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3513',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3519',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.3521',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD invo macula, RT eye'
        },
        {
          diagnosisCode:	'E11.3522',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD invo macula, LT eye'
        },
        {
          diagnosisCode:	'E11.3523',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD invo macula, bilateral'
        },
        {
          diagnosisCode:	'E11.3529',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD invo macula, unsp eye'
        },
        {
          diagnosisCode:	'E11.3531',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD not invo mac, RT eye'
        },
        {
          diagnosisCode:	'E11.3532',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD not invo mac, LT eye'
        },
        {
          diagnosisCode:	'E11.3533',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD not invo mac bilateral'
        },
        {
          diagnosisCode:	'E11.3539',
          diagnosisDescription:	'Type2 DM w proli diab retinopathy w trac RD not invo mac unsp eye'
        },
        {
          diagnosisCode:	'E11.3541',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w comb trac RD/rhegm rd, RT'
        },
        {
          diagnosisCode:	'E11.3542',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w comb trac RD/rhegm rd, LT'
        },
        {
          diagnosisCode:	'E11.3543',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w comb trac RD/rhegm rd, bi'
        },
        {
          diagnosisCode:	'E11.3549',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w comb trac RD/rhegm rd, unsp'
        },
        {
          diagnosisCode:	'E11.3551',
          diagnosisDescription:	'Type2 DM w stable prolif diab retinopathy, RT eye'
        },
        {
          diagnosisCode:	'E11.3552',
          diagnosisDescription:	'Type2 DM w stable prolif diab retinopathy, LT eye'
        },
        {
          diagnosisCode:	'E11.3553',
          diagnosisDescription:	'Type2 DM w stable prolif diab retinopathy, bilateral'
        },
        {
          diagnosisCode:	'E11.3559',
          diagnosisDescription:	'Type2 DM w stable prolif diab retinopathy, unsp eye'
        },
        {
          diagnosisCode:	'E11.359',
          diagnosisDescription:	'Type 2 Diabetes W Prolif DiabeticRTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E11.3591',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3592',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3593',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3599',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.36',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Diabetic Cataract'
        },
        {
          diagnosisCode:	'E11.37X1',
          diagnosisDescription:	'Type2 DM w diabetic mac edema, resolved folw TM, RT eye'
        },
        {
          diagnosisCode:	'E11.37X2',
          diagnosisDescription:	'Type2 DM w diabetic mac edema, resolved folw TM, LT eye'
        },
        {
          diagnosisCode:	'E11.37X3',
          diagnosisDescription:	'Type2 DM w diabetic mac edema, resolved folw TM, bilateral'
        },
        {
          diagnosisCode:	'E11.37X9',
          diagnosisDescription:	'Type2 DM w diabetic mac edema, resolved folw TM, unsp eye'
        },
        {
          diagnosisCode:	'E11.39',
          diagnosisDescription:	'Type 2 Diabetes W Oth Diabetic Ophthalmic Complication'
        },
        {
          diagnosisCode:	'E11.40',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Diabetic Neuropathy, Unsp'
        },
        {
          diagnosisCode:	'E11.41',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Diabetic Mononeuropathy'
        },
        {
          diagnosisCode:	'E11.42',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Diabetic Polyneuropathy'
        },
        {
          diagnosisCode:	'E11.43',
          diagnosisDescription:	'Type 2 Diabetes W Diabetic Autonomic (Poly)Neuropathy'
        },
        {
          diagnosisCode:	'E11.44',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Diabetic Amyotrophy'
        },
        {
          diagnosisCode:	'E11.49',
          diagnosisDescription:	'Type 2 Diabetes W Oth Diabetic Neurological Complication'
        },
        {
          diagnosisCode:	'E11.51',
          diagnosisDescription:	'Type 2 Diabetes W Diabetic Peripheral Angiopath w/o Gangrene'
        },
        {
          diagnosisCode:	'E11.52',
          diagnosisDescription:	'Type 2 Diabetes W Diabetic Peripheral Angiopathy W Gangrene'
        },
        {
          diagnosisCode:	'E11.59',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Othcirculatory Complications'
        },
        {
          diagnosisCode:	'E11.610',
          diagnosisDescription:	'Type 2 Diabetes Mellitus W Diabetic Neuropathic ARThropathy'
        },
        {
          diagnosisCode:	'E11.618',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Other Diabetic ARThropathy'
        },
        {
          diagnosisCode:	'E11.620',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Diabetic Dermatitis'
        },
        {
          diagnosisCode:	'E11.621',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Footulcer'
        },
        {
          diagnosisCode:	'E11.622',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Other Skin Ulcer'
        },
        {
          diagnosisCode:	'E11.628',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Other Skin Complications'
        },
        {
          diagnosisCode:	'E11.630',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Periodontal Disease'
        },
        {
          diagnosisCode:	'E11.638',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Other Oral Complications'
        },
        {
          diagnosisCode:	'E11.641',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Hypoglycemia with Coma'
        },
        {
          diagnosisCode:	'E11.649',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Hypoglycemia without Coma'
        },
        {
          diagnosisCode:	'E11.65',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Hyperglycemia'
        },
        {
          diagnosisCode:	'E11.69',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Other Specified Complication'
        },
        {
          diagnosisCode:	'E11.8',
          diagnosisDescription:	'Type 2 Diabetes Mellitus with Unspecified Complications'
        },
        {
          diagnosisCode:	'E13.00',
          diagnosisDescription:	'Oth Diab W Hyprosm w/o Nonket Hyprgly-Hypros Coma (Nkhhc)'
        },
        {
          diagnosisCode:	'E13.01',
          diagnosisDescription:	'Oth Diabetes Mellitus with Hyperosmolarity with Coma'
        },
        {
          diagnosisCode:	'E13.10',
          diagnosisDescription:	'Oth Diabetes Mellitus with Ketoacidosis without Coma'
        },
        {
          diagnosisCode:	'E13.11',
          diagnosisDescription:	'Oth Diabetes Mellitus with Ketoacidosis with Coma'
        },
        {
          diagnosisCode:	'E13.21',
          diagnosisDescription:	'Other Specified Diabetes Mellituswith Diabetic Nephropathy'
        },
        {
          diagnosisCode:	'E13.22',
          diagnosisDescription:	'Oth Diabetes Mellitus with Diabetic Chronic Kidney Disease'
        },
        {
          diagnosisCode:	'E13.29',
          diagnosisDescription:	'Oth Diabetes Mellitus with Oth Diabetic Kidney Complication'
        },
        {
          diagnosisCode:	'E13.311',
          diagnosisDescription:	'Oth Diabetes W Unsp Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode:	'E13.319',
          diagnosisDescription:	'Oth Diabetes W Unsp Diabetic Retinopathy w/o Macular Edema'
        },
        {
          diagnosisCode:	'E13.321',
          diagnosisDescription:	'Oth Diabetes W Mild Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E13.3211',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3212',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3213',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3219',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.329',
          diagnosisDescription:	'Oth Diabetes W Mild Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E13.3291',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3292',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3293',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3299',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.331',
          diagnosisDescription:	'Oth Diab W Moderate Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E13.3311',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3312',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3313',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3319',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.339',
          diagnosisDescription:	'Oth Diab W Moderate Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E13.3391',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3392',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3393',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3399',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.341',
          diagnosisDescription:	'Oth Diabetes W Severe Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E13.3411',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3412',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3413',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3419',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.349',
          diagnosisDescription:	'Oth Diab W Severe Nonprlf DiabeticRTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E13.3491',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3492',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3493',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3499',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.351',
          diagnosisDescription:	'Oth Diabetes W Prolif Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode:	'E13.3511',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3512',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3513',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3519',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.3521',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD invo macula, RT eye'
        },
        {
          diagnosisCode:	'E13.3522',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD invo macula, LT eye'
        },
        {
          diagnosisCode:	'E13.3523',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD invo macula, bilateral'
        },
        {
          diagnosisCode:	'E13.3529',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD invo macula, unsp eye'
        },
        {
          diagnosisCode:	'E13.3531',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD not invo macula, RT eye'
        },
        {
          diagnosisCode:	'E13.3532',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD not invo macula, LT eye'
        },
        {
          diagnosisCode:	'E13.3533',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD not invo macula, bi'
        },
        {
          diagnosisCode:	'E13.3539',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD not invo macula, unsp'
        },
        {
          diagnosisCode:	'E13.3541',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w comb trac RD and rhegm rd, RT'
        },
        {
          diagnosisCode:	'E13.3542',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w comb trac RD and rhegm rd, LT'
        },
        {
          diagnosisCode:	'E13.3543',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w comb trac RD and rhegm rd, bi'
        },
        {
          diagnosisCode:	'E13.3549',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w comb trac RD rhegm rd, unsp'
        },
        {
          diagnosisCode:	'E13.3551',
          diagnosisDescription:	'Oth DM w stable prolif diab retinopathy, RT eye'
        },
        {
          diagnosisCode:	'E13.3552',
          diagnosisDescription:	'Oth DM w stable prolif diab retinopathy, LT eye'
        },
        {
          diagnosisCode:	'E13.3553',
          diagnosisDescription:	'Oth DM w stable prolif diab retinopathy, bilateral'
        },
        {
          diagnosisCode:	'E13.3559',
          diagnosisDescription:	'Oth DM w stable prolif diab retinopathy, unsp eye'
        },
        {
          diagnosisCode:	'E13.359',
          diagnosisDescription:	'Oth Diabetes W Prolif Diabetic Retinopathy w/o Macular Edema'
        },
        {
          diagnosisCode:	'E13.3591',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3592',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3593',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3599',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.36',
          diagnosisDescription:	'Other Specified Diabetes Mellituswith Diabetic Cataract'
        },
        {
          diagnosisCode:	'E13.37X1',
          diagnosisDescription:	'Oth DM w diabetic mac edema, resolved folw TM, RT eye'
        },
        {
          diagnosisCode:	'E13.37X2',
          diagnosisDescription:	'Oth DM w diabetic mac edema, resolved folw TM, LT eye'
        },
        {
          diagnosisCode:	'E13.37X3',
          diagnosisDescription:	'Oth DM w diabetic mac edema, resolved folw TM, bilateral'
        },
        {
          diagnosisCode:	'E13.37X9',
          diagnosisDescription:	'Oth DM w diabetic mac edema, resolved folw TM, unsp eye'
        },
        {
          diagnosisCode:	'E13.39',
          diagnosisDescription:	'Oth Diabetes Mellitus W Oth Diabetic Ophthalmic Complication'
        },
        {
          diagnosisCode:	'E13.40',
          diagnosisDescription:	'Oth Diabetes Mellitus with Diabetic Neuropathy, Unspecified'
        },
        {
          diagnosisCode:	'E13.41',
          diagnosisDescription:	'Oth Diabetes Mellitus with Diabetic Mononeuropathy'
        },
        {
          diagnosisCode:	'E13.42',
          diagnosisDescription:	'Oth Diabetes Mellitus with Diabetic Polyneuropathy'
        },
        {
          diagnosisCode:	'E13.43',
          diagnosisDescription:	'Oth Diabetes Mellitus W Diabetic Autonomic (Poly)Neuropathy'
        },
        {
          diagnosisCode:	'E13.44',
          diagnosisDescription:	'Other Specified Diabetes Mellituswith Diabetic Amyotrophy'
        },
        {
          diagnosisCode:	'E13.49',
          diagnosisDescription:	'Oth Diabetes W Oth Diabetic Neurological Complication'
        },
        {
          diagnosisCode:	'E13.51',
          diagnosisDescription:	'Oth Diabetes W Diabetic Peripheralangiopathy w/o Gangrene'
        },
        {
          diagnosisCode:	'E13.52',
          diagnosisDescription:	'Oth Diabetes W Diabetic Peripheralangiopathy W Gangrene'
        },
        {
          diagnosisCode:	'E13.59',
          diagnosisDescription:	'Oth Diabetes Mellitus with Other Circulatory Complications'
        },
        {
          diagnosisCode:	'E13.610',
          diagnosisDescription:	'Oth Diabetes Mellitus with Diabetic Neuropathic ARThropathy'
        },
        {
          diagnosisCode:	'E13.618',
          diagnosisDescription:	'Oth Diabetes Mellitus with Other Diabetic ARThropathy'
        },
        {
          diagnosisCode:	'E13.620',
          diagnosisDescription:	'Other Specified Diabetes Mellituswith Diabetic Dermatitis'
        },
        {
          diagnosisCode:	'E13.621',
          diagnosisDescription:	'Other Specified Diabetes Mellituswith Foot Ulcer'
        },
        {
          diagnosisCode:	'E13.622',
          diagnosisDescription:	'Other Specified Diabetes Mellituswith Other Skin Ulcer'
        },
        {
          diagnosisCode:	'E13.628',
          diagnosisDescription:	'Oth Diabetes Mellitus with Other Skin Complications'
        },
        {
          diagnosisCode:	'E13.630',
          diagnosisDescription:	'Other Specified Diabetes Mellituswith Periodontal Disease'
        },
        {
          diagnosisCode:	'E13.638',
          diagnosisDescription:	'Oth Diabetes Mellitus with Other Oral Complications'
        },
        {
          diagnosisCode:	'E13.641',
          diagnosisDescription:	'Oth Diabetes Mellitus with Hypoglycemia with Coma'
        },
        {
          diagnosisCode:	'E13.649',
          diagnosisDescription:	'Oth Diabetes Mellitus with Hypoglycemia without Coma'
        },
        {
          diagnosisCode:	'E13.65',
          diagnosisDescription:	'Other Specified Diabetes Mellituswith Hyperglycemia'
        },
        {
          diagnosisCode:	'E13.69',
          diagnosisDescription:	'Oth Diabetes Mellitus with Other Specified Complication'
        },
        {
          diagnosisCode:	'E13.8',
          diagnosisDescription:	'Oth Diabetes Mellitus with Unspecified Complications'
        },

      ]
    },
    {
      conditionDescription: 'Diabetic Retinopathy A',
      diagnosis: [
        {
          diagnosisCode:	'E08.311',
          diagnosisDescription:	'Diab D/T Undrl Cond W Unsp Diabetic RT Nop W Macular Edema'
        },
        {
          diagnosisCode:	'E08.319',
          diagnosisDescription:	'Diab D/T Undrl Cond W Unsp Diab RTnop w/o Mac Ed'
        },
        {
          diagnosisCode:	'E08.321',
          diagnosisDescription:	'Diab D/T Undrl Con W Mild Nonprlfdiab RTnop W Mclr Edema'
        },
        {
          diagnosisCode:	'E08.329',
          diagnosisDescription:	'Diab D/T Undrl Cond W Mild Nonprlfdiab RTnop w/o Mclr Edema'
        },
        {
          diagnosisCode:	'E08.331',
          diagnosisDescription:	'Diab D/T Undrl Cond W Mod Nonprlf Diab RTnop W Mclr Edema'
        },
        {
          diagnosisCode:	'E08.339',
          diagnosisDescription:	'Diab D/T Undrl Cond W Mod Nonprlfdiab RTnop w/o Mclr Edema'
        },
        {
          diagnosisCode:	'E08.341',
          diagnosisDescription:	'Diab D/T Undrl Cond W Severe Nonprlf Diab RTnop W Mclr Edema'
        },
        {
          diagnosisCode:	'E08.349',
          diagnosisDescription:	'Diab D/T Undrl Cond W Sev Nonprlfdiab RTnop w/o Mclr Edema'
        },
        {
          diagnosisCode:	'E08.351',
          diagnosisDescription:	'Diab D/T Undrl Cond W Prolif Diab RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E08.359',
          diagnosisDescription:	'Diab D/T Undrl Cond W Prolif Diab RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E09.311',
          diagnosisDescription:	'Drug/Chem Diabetes W Unsp DiabeticRTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E09.319',
          diagnosisDescription:	'Drug/Chem Diabetes W Unsp DiabeticRTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E09.321',
          diagnosisDescription:	'Drug/Chem Diab W Mild Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E09.329',
          diagnosisDescription:	'Drug/Chem Diab W Mild Nonprlf DiabRTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E09.331',
          diagnosisDescription:	'Drug/Chem Diab W Moderate Nonprlfdiab RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E09.339',
          diagnosisDescription:	'Drug/Chem Diab W Mod Nonprlf DiabRTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E09.341',
          diagnosisDescription:	'Drug/Chem Diab W Severe Nonprlf Diab RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E09.349',
          diagnosisDescription:	'Drug/Chem Diab W Severe Nonprlf Diab RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E09.351',
          diagnosisDescription:	'Drug/Chem Diabetes W Prolif Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E09.359',
          diagnosisDescription:	'Drug/Chem Diabetes W Prolif Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E10.311',
          diagnosisDescription:	'Type 1 Diabetes W Unsp Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode:	'E10.319',
          diagnosisDescription:	'Type 1 Diabetes W Unsp Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E10.321',
          diagnosisDescription:	'Type 1 Diab W Mild Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E10.3211',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3212',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3213',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3219',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E10.329',
          diagnosisDescription:	'Type 1 Diab W Mild Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E10.3291',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3292',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3293',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3299',
          diagnosisDescription:	'Type1 DM w mild nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E10.331',
          diagnosisDescription:	'Type 1 Diab W Moderate Nonprlf Diab RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E10.3311',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3312',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3313',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3319',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E10.339',
          diagnosisDescription:	'Type 1 Diab W Moderate Nonprlf Diab RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E10.3391',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3392',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3393',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3399',
          diagnosisDescription:	'Type1 DM w mod nonpro diab retinopathy wo mac edema unsp eye'
        },
        {
          diagnosisCode:	'E10.341',
          diagnosisDescription:	'Type 1 Diab W Severe Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E10.3411',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3412',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3413',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3419',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E10.349',
          diagnosisDescription:	'Type 1 Diab W Severe Nonprlf DiabRTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E10.3491',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3492',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3493',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3499',
          diagnosisDescription:	'Type1 DM w sev nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E10.351',
          diagnosisDescription:	'Type 1 Diabetes W Prolif DiabeticRTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E10.3511',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3512',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3513',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3519',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E10.3521',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD invo macula, RT eye'
        },
        {
          diagnosisCode:	'E10.3522',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD invo macula, LT eye'
        },
        {
          diagnosisCode:	'E10.3523',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD invo macula, bilateral'
        },
        {
          diagnosisCode:	'E10.3529',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD invo macula, unsp eye'
        },
        {
          diagnosisCode:	'E10.3531',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD not invo mac RT eye'
        },
        {
          diagnosisCode:	'E10.3532',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD not invo mac LT eye'
        },
        {
          diagnosisCode:	'E10.3533',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w trac RD not invo mac bilateral'
        },
        {
          diagnosisCode:	'E10.3539',
          diagnosisDescription:	'Type1 DM w proli diab retinopathy w trac RD not invo mac unsp eye'
        },
        {
          diagnosisCode:	'E10.3541',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w comb trac RD rhegm rd RT eye'
        },
        {
          diagnosisCode:	'E10.3542',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w comb trac RD rhegm rd LT eye'
        },
        {
          diagnosisCode:	'E10.3543',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy w comb trac RD rhegm rd, bi'
        },
        {
          diagnosisCode:	'E10.3549',
          diagnosisDescription:	'Type1 DM w prol diabretinopathy w com trac RD rhegm rd unsp eye'
        },
        {
          diagnosisCode:	'E10.3551',
          diagnosisDescription:	'Type1 DM w stable prolif diab retinopathy, RT eye'
        },
        {
          diagnosisCode:	'E10.3552',
          diagnosisDescription:	'Type1 DM w stable prolif diab retinopathy, LT eye'
        },
        {
          diagnosisCode:	'E10.3553',
          diagnosisDescription:	'Type1 DM w stable prolif diab retinopathy, bilateral'
        },
        {
          diagnosisCode:	'E10.3559',
          diagnosisDescription:	'Type1 DM w stable prolif diab retinopathy, unsp eye'
        },
        {
          diagnosisCode:	'E10.359',
          diagnosisDescription:	'Type 1 Diabetes W Prolif DiabeticRTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E10.3591',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E10.3592',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E10.3593',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E10.3599',
          diagnosisDescription:	'Type1 DM w prolif diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.311',
          diagnosisDescription:	'Type 2 Diabetes W Unsp Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode:	'E11.319',
          diagnosisDescription:	'Type 2 Diabetes W Unsp Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E11.321',
          diagnosisDescription:	'Type 2 Diab W Mild Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E11.3211',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3212',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3213',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3219',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.329',
          diagnosisDescription:	'Type 2 Diab W Mild Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E11.3291',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3292',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3293',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3299',
          diagnosisDescription:	'Type2 DM w mild nonpro diab retinopathy wo mac edema unsp eye'
        },
        {
          diagnosisCode:	'E11.331',
          diagnosisDescription:	'Type 2 Diab W Moderate Nonprlf Diab RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E11.3311',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3312',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3313',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3319',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.339',
          diagnosisDescription:	'Type 2 Diab W Moderate Nonprlf Diab RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E11.3391',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3392',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3393',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3399',
          diagnosisDescription:	'Type2 DM w mod nonpro diab retinopathy wo mac edema unsp eye'
        },
        {
          diagnosisCode:	'E11.341',
          diagnosisDescription:	'Type 2 Diab W Severe Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E11.3411',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3412',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3413',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3419',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.349',
          diagnosisDescription:	'Type 2 Diab W Severe Nonprlf DiabRTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E11.3491',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3492',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3493',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3499',
          diagnosisDescription:	'Type2 DM w sev nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.351',
          diagnosisDescription:	'Type 2 Diabetes W Prolif DiabeticRTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E11.3511',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3512',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3513',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3519',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E11.3521',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD invo macula, RT eye'
        },
        {
          diagnosisCode:	'E11.3522',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD invo macula, LT eye'
        },
        {
          diagnosisCode:	'E11.3523',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD invo macula, bilateral'
        },
        {
          diagnosisCode:	'E11.3529',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD invo macula, unsp eye'
        },
        {
          diagnosisCode:	'E11.3531',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD not invo mac, RT eye'
        },
        {
          diagnosisCode:	'E11.3532',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD not invo mac, LT eye'
        },
        {
          diagnosisCode:	'E11.3533',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w trac RD not invo mac bilateral'
        },
        {
          diagnosisCode:	'E11.3539',
          diagnosisDescription:	'Type2 DM w proli diab retinopathy w trac RD not invo mac unsp eye'
        },
        {
          diagnosisCode:	'E11.3541',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w comb trac RD/rhegm rd, RT'
        },
        {
          diagnosisCode:	'E11.3542',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w comb trac RD/rhegm rd, LT'
        },
        {
          diagnosisCode:	'E11.3543',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w comb trac RD/rhegm rd, bi'
        },
        {
          diagnosisCode:	'E11.3549',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy w comb trac RD/rhegm rd, unsp'
        },
        {
          diagnosisCode:	'E11.3551',
          diagnosisDescription:	'Type2 DM w stable prolif diab retinopathy, RT eye'
        },
        {
          diagnosisCode:	'E11.3552',
          diagnosisDescription:	'Type2 DM w stable prolif diab retinopathy, LT eye'
        },
        {
          diagnosisCode:	'E11.3553',
          diagnosisDescription:	'Type2 DM w stable prolif diab retinopathy, bilateral'
        },
        {
          diagnosisCode:	'E11.3559',
          diagnosisDescription:	'Type2 DM w stable prolif diab retinopathy, unsp eye'
        },
        {
          diagnosisCode:	'E11.359',
          diagnosisDescription:	'Type 2 Diabetes W Prolif DiabeticRTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E11.3591',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E11.3592',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E11.3593',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E11.3599',
          diagnosisDescription:	'Type2 DM w prolif diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.311',
          diagnosisDescription:	'Oth Diabetes W Unsp Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode:	'E13.319',
          diagnosisDescription:	'Oth Diabetes W Unsp Diabetic Retinopathy w/o Macular Edema'
        },
        {
          diagnosisCode:	'E13.321',
          diagnosisDescription:	'Oth Diabetes W Mild Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E13.3211',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3212',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3213',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3219',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.329',
          diagnosisDescription:	'Oth Diabetes W Mild Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E13.3291',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3292',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3293',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3299',
          diagnosisDescription:	'Oth DM w mild nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.331',
          diagnosisDescription:	'Oth Diab W Moderate Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E13.3311',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3312',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3313',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3319',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.339',
          diagnosisDescription:	'Oth Diab W Moderate Nonprlf Diabetic RTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E13.3391',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3392',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3393',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3399',
          diagnosisDescription:	'Oth DM w mod nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.341',
          diagnosisDescription:	'Oth Diabetes W Severe Nonprlf Diabetic RTnop W Macular Edema'
        },
        {
          diagnosisCode:	'E13.3411',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3412',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3413',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3419',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.349',
          diagnosisDescription:	'Oth Diab W Severe Nonprlf DiabeticRTnop w/o Macular Edema'
        },
        {
          diagnosisCode:	'E13.3491',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3492',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3493',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3499',
          diagnosisDescription:	'Oth DM w sev nonpro diab retinopathy wo mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.351',
          diagnosisDescription:	'Oth Diabetes W Prolif Diabetic Retinopathy W Macular Edema'
        },
        {
          diagnosisCode:	'E13.3511',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3512',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3513',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3519',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w mac edema, unsp eye'
        },
        {
          diagnosisCode:	'E13.3521',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD invo macula, RT eye'
        },
        {
          diagnosisCode:	'E13.3522',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD invo macula, LT eye'
        },
        {
          diagnosisCode:	'E13.3523',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD invo macula, bilateral'
        },
        {
          diagnosisCode:	'E13.3529',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD invo macula, unsp eye'
        },
        {
          diagnosisCode:	'E13.3531',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD not invo macula, RT eye'
        },
        {
          diagnosisCode:	'E13.3532',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD not invo macula, LT eye'
        },
        {
          diagnosisCode:	'E13.3533',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD not invo macula, bi'
        },
        {
          diagnosisCode:	'E13.3539',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w trac RD not invo macula, unsp'
        },
        {
          diagnosisCode:	'E13.3541',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w comb trac RD and rhegm rd, RT'
        },
        {
          diagnosisCode:	'E13.3542',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w comb trac RD and rhegm rd, LT'
        },
        {
          diagnosisCode:	'E13.3543',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w comb trac RD and rhegm rd, bi'
        },
        {
          diagnosisCode:	'E13.3549',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy w comb trac RD rhegm rd, unsp'
        },
        {
          diagnosisCode:	'E13.3551',
          diagnosisDescription:	'Oth DM w stable prolif diab retinopathy, RT eye'
        },
        {
          diagnosisCode:	'E13.3552',
          diagnosisDescription:	'Oth DM w stable prolif diab retinopathy, LT eye'
        },
        {
          diagnosisCode:	'E13.3553',
          diagnosisDescription:	'Oth DM w stable prolif diab retinopathy, bilateral'
        },
        {
          diagnosisCode:	'E13.3559',
          diagnosisDescription:	'Oth DM w stable prolif diab retinopathy, unsp eye'
        },
        {
          diagnosisCode:	'E13.359',
          diagnosisDescription:	'Oth Diabetes W Prolif Diabetic Retinopathy w/o Macular Edema'
        },
        {
          diagnosisCode:	'E13.3591',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy wo mac edema, RT eye'
        },
        {
          diagnosisCode:	'E13.3592',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy wo mac edema, LT eye'
        },
        {
          diagnosisCode:	'E13.3593',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy wo mac edema, bilateral'
        },
        {
          diagnosisCode:	'E13.3599',
          diagnosisDescription:	'Oth DM w prolif diab retinopathy wo mac edema, unsp eye'
        },

      ]
    },
    {
      conditionDescription: 'Rubeosis Iridis',
      diagnosis: [
        {
          diagnosisCode: 'H21.1X1',
          diagnosisDescription: 'Oth Vascular Disorders Iris and Ciliary Body RT Eye'
        },
        {
          diagnosisCode: 'H21.1X2',
          diagnosisDescription: 'Oth Vascular Disorders Iris and Ciliary Body LT Eye'
        },
        {
          diagnosisCode: 'H21.1X3',
          diagnosisDescription: 'Oth Vascular Disorders Iris and Ciliary Body Bi'
        },
        {
          diagnosisCode: 'H21.1X9',
          diagnosisDescription: 'Oth Vascular Disord Iris and Ciliary Body Unsp Eye'
        },

      ]
    }
  ] as DiagnosisCodeGrouping[],
  ComputerVisionSyndrome: [
    {
      conditionDescription: 'Astigmatism',
      diagnosis: [
        {
          diagnosisCode: 'H52.201',
          diagnosisDescription:	'Unspecified astigmatism, right eye'
        },
        {
          diagnosisCode: 'H52.202',
          diagnosisDescription:	'Unspecified astigmatism, left eye'
        },
        {
          diagnosisCode: 'H52.203',
          diagnosisDescription:	'Unspecified astigmatism, bilateral'
        },
        {
          diagnosisCode: 'H52.209',
          diagnosisDescription:	'Unspecified astigmatism, unspecified eye'
        },
        {
          diagnosisCode: 'H52.211',
          diagnosisDescription:	'Irregular astigmatism, right eye'
        },
        {
          diagnosisCode: 'H52.212',
          diagnosisDescription:	'Irregular astigmatism, left eye'
        },
        {
          diagnosisCode: 'H52.213',
          diagnosisDescription:	'Irregular astigmatism, bilateral'
        },
        {
          diagnosisCode: 'H52.219',
          diagnosisDescription:	'Irregular astigmatism, unspecified eye'
        },
        {
          diagnosisCode: 'H52.221',
          diagnosisDescription:	'Regular astigmatism, right eye'
        },
        {
          diagnosisCode: 'H52.222',
          diagnosisDescription:	'Regular astigmatism, left eye'
        },
        {
          diagnosisCode: 'H52.223',
          diagnosisDescription:	'Regular astigmatism, bilateral'
        },
        {
          diagnosisCode: 'H52.229',
          diagnosisDescription:	'Regular astigmatism, unspecified eye'
        },

      ]
    },
    {
      conditionDescription: 'Disorders of Accommodation',
      diagnosis: [
        {
          diagnosisCode: 'H52.511',
          diagnosisDescription: 'Internal ophthalmoplegia (complete) (total), right eye'
        },
        {
          diagnosisCode: 'H52.512',
          diagnosisDescription: 'Internal ophthalmoplegia (complete) (total), left eye'
        },
        {
          diagnosisCode: 'H52.513',
          diagnosisDescription: 'Internal ophthalmoplegia (complete) (total), bilateral'
        },
        {
          diagnosisCode: 'H52.519',
          diagnosisDescription: 'Internal ophthalmoplegia (complete) (total), unspecified eye'
        },
        {
          diagnosisCode: 'H52.521',
          diagnosisDescription: 'Paresis of accommodation, right eye'
        },
        {
          diagnosisCode: 'H52.522',
          diagnosisDescription: 'Paresis of accommodation, left eye'
        },
        {
          diagnosisCode: 'H52.523',
          diagnosisDescription: 'Paresis of accommodation, bilateral'
        },
        {
          diagnosisCode: 'H52.529',
          diagnosisDescription: 'Paresis of accommodation, unspecified eye'
        },
        {
          diagnosisCode: 'H52.531',
          diagnosisDescription: 'Spasm of accommodation, right eye'
        },
        {
          diagnosisCode: 'H52.532',
          diagnosisDescription: 'Spasm of accommodation, left eye'
        },
        {
          diagnosisCode: 'H52.533',
          diagnosisDescription: 'Spasm of accommodation, bilateral'
        },
        {
          diagnosisCode: 'H52.539',
          diagnosisDescription: 'Spasm of accommodation, unspecified eye'
        },

      ]
    },
    {
      conditionDescription: 'Heterophoria',
      diagnosis: [
        {
          diagnosisCode: 'H50.50',
          diagnosisDescription: 'Unspecified heterophoria'
        },
        {
          diagnosisCode: 'H50.51',
          diagnosisDescription: 'Esophoria'
        },
        {
          diagnosisCode: 'H50.52',
          diagnosisDescription: 'Exophoria'
        },
        {
          diagnosisCode: 'H50.53',
          diagnosisDescription: 'Vertical heterophoria'
        },
        {
          diagnosisCode: 'H50.54',
          diagnosisDescription: 'Cyclophoria'
        },
        {
          diagnosisCode: 'H50.55',
          diagnosisDescription: 'Alternating heterophoria'
        },

      ]
    },
    {
      conditionDescription: 'Hyperopia',
      diagnosis: [
        {
          diagnosisCode: 'H52.00',
          diagnosisDescription: 'Hypermetropia, unspecified eye'
        },
        {
          diagnosisCode: 'H52.01',
          diagnosisDescription: 'Hypermetropia, right eye'
        },
        {
          diagnosisCode: 'H52.02',
          diagnosisDescription: 'Hypermetropia, left eye'
        },
        {
          diagnosisCode: 'H52.03',
          diagnosisDescription: 'Hypermetropia, bilateral'
        },

      ]
    },
    {
      conditionDescription: 'Other Disorders if Binocular Eye Movements',
      diagnosis: [
        {
          diagnosisCode: 'H51.0',
          diagnosisDescription: 'Palsy (spasm) of conjugate gaze'
        },
        {
          diagnosisCode: 'H51.11',
          diagnosisDescription: 'Convergence insufficiency'
        },
        {
          diagnosisCode: 'H51.12',
          diagnosisDescription: 'Convergence excess'
        },
        {
          diagnosisCode: 'H51.20',
          diagnosisDescription: 'Internuclear ophthalmoplegia, unspecified eye'
        },
        {
          diagnosisCode: 'H51.21',
          diagnosisDescription: 'Internuclear ophthalmoplegia, right eye'
        },
        {
          diagnosisCode: 'H51.22',
          diagnosisDescription: 'Internuclear ophthalmoplegia, left eye'
        },
        {
          diagnosisCode: 'H51.23',
          diagnosisDescription: 'Internuclear ophthalmoplegia, bilateral'
        },
        {
          diagnosisCode: 'H51.8',
          diagnosisDescription: 'Other specified disorders of binocular movement'
        },

      ]
    },
    {
      conditionDescription: 'Presbyopia',
      diagnosis: [
        {
          diagnosisCode: 'H52.4',
          diagnosisDescription: 'Presbyopia'
        }
      ]
    }
  ] as DiagnosisCodeGrouping[],
  WellVision: [
    {
      conditionDescription: 'This list represents commonly utilized codes and is not a complete list of billable diagnoses.'
    },
    {
      conditionDescription: 'Encounters for Eye Exam',
      diagnosis: [
        {
          diagnosisCode: 'Z01.00',
          diagnosisDescription: 'Encounter for examination of eyes and vision without abnormal findings'
        },
        {
          diagnosisCode: 'Z01.01',
          diagnosisDescription: 'Encounter for examination of eyes and vision with abnormal findings'
        },
        {
          diagnosisCode: 'Z01.020',
          diagnosisDescription: 'Encounter for examination of eyes and vision following failed vision screening without abnormal findings'
        },
        {
          diagnosisCode: 'Z01.021',
          diagnosisDescription: 'Encounter for examination of eyes and vision following failed vision screening with abnormal findings'
        },
        {
          diagnosisCode: 'Z13.5',
          diagnosisDescription: 'Encounter for screening for eye and ear disorders'
        },
        {
          diagnosisCode: 'Z46.0',
          diagnosisDescription: 'Encounter for fitting and adjustment of spectacles and contact lenses'
        },
      ]
    },
    {
      conditionDescription: 'Disorders of Refraction & Accomodation',
      diagnosis: [
        {
          diagnosisCode: 'H52.01',
          diagnosisDescription: 'Hypermetropia, right eye'
        },
        {
          diagnosisCode: 'H52.02',
          diagnosisDescription: 'Hypermetropia, left eye'
        },
        {
          diagnosisCode: 'H52.03',
          diagnosisDescription: 'Hypermetropia, bilateral'
        },
        {
          diagnosisCode: 'H52.11',
          diagnosisDescription: 'Myopia, right eye'
        },
        {
          diagnosisCode: 'H52.12',
          diagnosisDescription: 'Myopia, left eye'
        },
        {
          diagnosisCode: 'H52.13',
          diagnosisDescription: 'Myopia, bilateral'
        },
        {
          diagnosisCode: 'H52.201',
          diagnosisDescription: 'Unspecified astigmatism, right eye'
        },
        {
          diagnosisCode: 'H52.202',
          diagnosisDescription: 'Unspecified astigmatism, left eye'
        },
        {
          diagnosisCode: 'H52.203',
          diagnosisDescription: 'Unspecified astigmatism, bilateral'
        },
        {
          diagnosisCode: 'H52.211',
          diagnosisDescription: 'Irregular astigmatism, right eye'
        },
        {
          diagnosisCode: 'H52.212',
          diagnosisDescription: 'Irregular astigmatism, left eye'
        },
        {
          diagnosisCode: 'H52.213',
          diagnosisDescription: 'Irregular astigmatism, bilateral'
        },
        {
          diagnosisCode: 'H52.221',
          diagnosisDescription: 'Regular astigmatism, right eye'
        },
        {
          diagnosisCode: 'H52.222',
          diagnosisDescription: 'Regular astigmatism, left eye'
        },
        {
          diagnosisCode: 'H52.223',
          diagnosisDescription: 'Regular astigmatism, bilateral'
        },
        {
          diagnosisCode: 'H52.31',
          diagnosisDescription: 'Anisometropia'
        },
        {
          diagnosisCode: 'H52.32',
          diagnosisDescription: 'Aniseikonia'
        },
        {
          diagnosisCode: 'H52.4',
          diagnosisDescription: 'Presbyopia'
        },
        {
          diagnosisCode: 'H52.511',
          diagnosisDescription: 'Internal ophthalmoplegia (complete) (total), right eye'
        },
        {
          diagnosisCode: 'H52.512',
          diagnosisDescription: 'Internal ophthalmoplegia (complete) (total), left eye'
        },
        {
          diagnosisCode: 'H52.513',
          diagnosisDescription: 'Internal ophthalmoplegia (complete) (total), bilateral'
        },
        {
          diagnosisCode: 'H52.521',
          diagnosisDescription: 'Paresis of accommodation, right eye'
        },
        {
          diagnosisCode: 'H52.522',
          diagnosisDescription: 'Paresis of accommodation, left eye'
        },
        {
          diagnosisCode: 'H52.523',
          diagnosisDescription: 'Paresis of accommodation, bilateral'
        },
        {
          diagnosisCode: 'H52.531',
          diagnosisDescription: 'Spasm of accommodation, right eye'
        },
        {
          diagnosisCode: 'H52.532',
          diagnosisDescription: 'Spasm of accommodation, left eye'
        },
        {
          diagnosisCode: 'H52.533',
          diagnosisDescription: 'Spasm of accommodation, bilateral'
        },
        {
          diagnosisCode: 'H52.6',
          diagnosisDescription: 'Other disorders of refraction'
        },
        {
          diagnosisCode: 'H52.7',
          diagnosisDescription: 'Unspecified disorder of refraction'
        },

      ]
    },
    {
      conditionDescription: 'Other Conditions and Diseases',
      diagnosis: [
        {
          diagnosisCode: 'H27.00',
          diagnosisDescription: 'Aphakia, unspecified eye'
        },
        {
          diagnosisCode: 'H27.01',
          diagnosisDescription: 'Aphakia, right eye'
        },
        {
          diagnosisCode: 'H27.02',
          diagnosisDescription: 'Aphakia, left eye'
        },
        {
          diagnosisCode: 'H27.03',
          diagnosisDescription: 'Aphakia, bilateral'
        },
        {
          diagnosisCode: 'H49.01',
          diagnosisDescription: 'Third [oculomotor] nerve palsy, right eye'
        },
        {
          diagnosisCode: 'H49.02',
          diagnosisDescription: 'Third [oculomotor] nerve palsy, left eye'
        },
        {
          diagnosisCode: 'H49.03',
          diagnosisDescription: 'Third [oculomotor] nerve palsy, bilateral'
        },
        {
          diagnosisCode: 'H49.11',
          diagnosisDescription: 'Fourth [trochlear] nerve palsy, right eye'
        },
        {
          diagnosisCode: 'H49.12',
          diagnosisDescription: 'Fourth [trochlear] nerve palsy, left eye'
        },
        {
          diagnosisCode: 'H49.13',
          diagnosisDescription: 'Fourth [trochlear] nerve palsy, bilateral'
        },
        {
          diagnosisCode: 'H49.21',
          diagnosisDescription: 'Sixth [abducent] nerve palsy, right eye'
        },
        {
          diagnosisCode: 'H49.22',
          diagnosisDescription: 'Sixth [abducent] nerve palsy, left eye'
        },
        {
          diagnosisCode: 'H49.23',
          diagnosisDescription: 'Sixth [abducent] nerve palsy, bilateral'
        },
        {
          diagnosisCode: 'H49.31',
          diagnosisDescription: 'Total (external) ophthalmoplegia, right eye'
        },
        {
          diagnosisCode: 'H49.32',
          diagnosisDescription: 'Total (external) ophthalmoplegia, left eye'
        },
        {
          diagnosisCode: 'H49.33',
          diagnosisDescription: 'Total (external) ophthalmoplegia, bilateral'
        },
        {
          diagnosisCode: 'H49.41',
          diagnosisDescription: 'Progressive external ophthalmoplegia, right eye'
        },
        {
          diagnosisCode: 'H49.42',
          diagnosisDescription: 'Progressive external ophthalmoplegia, left eye'
        },
        {
          diagnosisCode: 'H49.43',
          diagnosisDescription: 'Progressive external ophthalmoplegia, bilateral'
        },
        {
          diagnosisCode: 'H49.881',
          diagnosisDescription: 'Other paralytic strabismus, right eye'
        },
        {
          diagnosisCode: 'H49.882',
          diagnosisDescription: 'Other paralytic strabismus, left eye'
        },
        {
          diagnosisCode: 'H49.883',
          diagnosisDescription: 'Other paralytic strabismus, bilateral'
        },
        {
          diagnosisCode: 'H49.9',
          diagnosisDescription: 'Unspecified paralytic strabismus'
        },
        {
          diagnosisCode: 'H50.00',
          diagnosisDescription: 'Unspecified esotropia'
        },
        {
          diagnosisCode: 'H50.011',
          diagnosisDescription: 'Monocular esotropia, right eye'
        },
        {
          diagnosisCode: 'H50.012',
          diagnosisDescription: 'Monocular esotropia, left eye'
        },
        {
          diagnosisCode: 'H50.021',
          diagnosisDescription: 'Monocular esotropia with A pattern, right eye'
        },
        {
          diagnosisCode: 'H50.022',
          diagnosisDescription: 'Monocular esotropia with A pattern, left eye'
        },
        {
          diagnosisCode: 'H50.031',
          diagnosisDescription: 'Monocular esotropia with V pattern, right eye'
        },
        {
          diagnosisCode: 'H50.032',
          diagnosisDescription: 'Monocular esotropia with V pattern, left eye'
        },
        {
          diagnosisCode: 'H50.041',
          diagnosisDescription: 'Monocular esotropia with other noncomitancies, right eye'
        },
        {
          diagnosisCode: 'H50.042',
          diagnosisDescription: 'Monocular esotropia with other noncomitancies, left eye'
        },
        {
          diagnosisCode: 'H50.05',
          diagnosisDescription: 'Alternating esotropia'
        },
        {
          diagnosisCode: 'H50.06',
          diagnosisDescription: 'Alternating esotropia with A pattern'
        },
        {
          diagnosisCode: 'H50.07',
          diagnosisDescription: 'Alternating esotropia with V pattern'
        },
        {
          diagnosisCode: 'H50.08',
          diagnosisDescription: 'Alternating esotropia with other noncomitancies'
        },
        {
          diagnosisCode: 'H50.10',
          diagnosisDescription: 'Unspecified exotropia'
        },
        {
          diagnosisCode: 'H50.111',
          diagnosisDescription: 'Monocular exotropia, right eye'
        },
        {
          diagnosisCode: 'H50.112',
          diagnosisDescription: 'Monocular exotropia, left eye'
        },
        {
          diagnosisCode: 'H50.121',
          diagnosisDescription: 'Monocular exotropia with A pattern, right eye'
        },
        {
          diagnosisCode: 'H50.122',
          diagnosisDescription: 'Monocular exotropia with A pattern, left eye'
        },
        {
          diagnosisCode: 'H50.131',
          diagnosisDescription: 'Monocular exotropia with V pattern, right eye'
        },
        {
          diagnosisCode: 'H50.132',
          diagnosisDescription: 'Monocular exotropia with V pattern, left eye'
        },
        {
          diagnosisCode: 'H50.141',
          diagnosisDescription: 'Monocular exotropia with other noncomitancies, right eye'
        },
        {
          diagnosisCode: 'H50.142',
          diagnosisDescription: 'Monocular exotropia with other noncomitancies, left eye'
        },
        {
          diagnosisCode: 'H50.15',
          diagnosisDescription: 'Alternating exotropia'
        },
        {
          diagnosisCode: 'H50.16',
          diagnosisDescription: 'Alternating exotropia with A pattern'
        },
        {
          diagnosisCode: 'H50.17',
          diagnosisDescription: 'Alternating exotropia with V pattern'
        },
        {
          diagnosisCode: 'H50.18',
          diagnosisDescription: 'Alternating exotropia with other noncomitancies'
        },
        {
          diagnosisCode: 'H50.21',
          diagnosisDescription: 'Vertical strabismus, right eye'
        },
        {
          diagnosisCode: 'H50.22',
          diagnosisDescription: 'Vertical strabismus, left eye'
        },
        {
          diagnosisCode: 'H50.30',
          diagnosisDescription: 'Unspecified intermittent heterotropia'
        },
        {
          diagnosisCode: 'H50.311',
          diagnosisDescription: 'Intermittent monocular esotropia, right eye'
        },
        {
          diagnosisCode: 'H50.312',
          diagnosisDescription: 'Intermittent monocular esotropia, left eye'
        },
        {
          diagnosisCode: 'H50.32',
          diagnosisDescription: 'Intermittent alternating esotropia'
        },
        {
          diagnosisCode: 'H50.331',
          diagnosisDescription: 'Intermittent monocular exotropia, right eye'
        },
        {
          diagnosisCode: 'H50.332',
          diagnosisDescription: 'Intermittent monocular exotropia, left eye'
        },
        {
          diagnosisCode: 'H50.34',
          diagnosisDescription: 'Intermittent alternating exotropia'
        },
        {
          diagnosisCode: 'H50.40',
          diagnosisDescription: 'Unspecified heterotropia'
        },
        {
          diagnosisCode: 'H50.411',
          diagnosisDescription: 'Cyclotropia, right eye'
        },
        {
          diagnosisCode: 'H50.412',
          diagnosisDescription: 'Cyclotropia, left eye'
        },
        {
          diagnosisCode: 'H50.42',
          diagnosisDescription: 'Monofixation syndrome'
        },
        {
          diagnosisCode: 'H50.43',
          diagnosisDescription: 'Accommodative component in esotropia'
        },
        {
          diagnosisCode: 'H50.50',
          diagnosisDescription: 'Unspecified heterophoria'
        },
        {
          diagnosisCode: 'H50.51',
          diagnosisDescription: 'Esophoria'
        },
        {
          diagnosisCode: 'H50.52',
          diagnosisDescription: 'Exophoria'
        },
        {
          diagnosisCode: 'H50.53',
          diagnosisDescription: 'Vertical heterophoria'
        },
        {
          diagnosisCode: 'H50.54',
          diagnosisDescription: 'Cyclophoria'
        },
        {
          diagnosisCode: 'H50.55',
          diagnosisDescription: 'Alternating heterophoria'
        },
        {
          diagnosisCode: 'H50.60',
          diagnosisDescription: 'Mechanical strabismus, unspecified'
        },
        {
          diagnosisCode: 'H50.611',
          diagnosisDescription: 'Brown\'s sheath syndrome, right eye'
        },
        {
          diagnosisCode: 'H50.612',
          diagnosisDescription: 'Brown\'s sheath syndrome, left eye'
        },
        {
          diagnosisCode: 'H50.69',
          diagnosisDescription: 'Other mechanical strabismus'
        },
        {
          diagnosisCode: 'H50.811',
          diagnosisDescription: 'Duane\'s syndrome, right eye'
        },
        {
          diagnosisCode: 'H50.812',
          diagnosisDescription: 'Duane\'s syndrome, left eye'
        },
        {
          diagnosisCode: 'H50.89',
          diagnosisDescription: 'Other specified strabismus'
        },
        {
          diagnosisCode: 'H50.9',
          diagnosisDescription: 'Unspecified strabismus'
        },
        {
          diagnosisCode: 'H51.0',
          diagnosisDescription: 'Palsy (spasm) of conjugate gaze'
        },
        {
          diagnosisCode: 'H51.11',
          diagnosisDescription: 'Convergence insufficiency'
        },
        {
          diagnosisCode: 'H51.12',
          diagnosisDescription: 'Convergence excess'
        },
        {
          diagnosisCode: 'H51.21',
          diagnosisDescription: 'Internuclear ophthalmoplegia, right eye'
        },
        {
          diagnosisCode: 'H51.22',
          diagnosisDescription: 'Internuclear ophthalmoplegia, left eye'
        },
        {
          diagnosisCode: 'H51.23',
          diagnosisDescription: 'Internuclear ophthalmoplegia, bilateral'
        },
        {
          diagnosisCode: 'H51.8',
          diagnosisDescription: 'Other specified disorders of binocular movement'
        },
        {
          diagnosisCode: 'H51.9',
          diagnosisDescription: 'Unspecified disorder of binocular movement'
        },
        {
          diagnosisCode: 'H53.001',
          diagnosisDescription: 'Unspecified amblyopia, right eye'
        },
        {
          diagnosisCode: 'H53.002',
          diagnosisDescription: 'Unspecified amblyopia, left eye'
        },
        {
          diagnosisCode: 'H53.003',
          diagnosisDescription: 'Unspecified amblyopia, bilateral'
        },
        {
          diagnosisCode: 'H53.011',
          diagnosisDescription: 'Deprivation amblyopia, right eye'
        },
        {
          diagnosisCode: 'H53.012',
          diagnosisDescription: 'Deprivation amblyopia, left eye'
        },
        {
          diagnosisCode: 'H53.013',
          diagnosisDescription: 'Deprivation amblyopia, bilateral'
        },
        {
          diagnosisCode: 'H53.021',
          diagnosisDescription: 'Refractive amblyopia, right eye'
        },
        {
          diagnosisCode: 'H53.022',
          diagnosisDescription: 'Refractive amblyopia, left eye'
        },
        {
          diagnosisCode: 'H53.023',
          diagnosisDescription: 'Refractive amblyopia, bilateral'
        },
        {
          diagnosisCode: 'H53.031',
          diagnosisDescription: 'Strabismic amblyopia, right eye'
        },
        {
          diagnosisCode: 'H53.032',
          diagnosisDescription: 'Strabismic amblyopia, left eye'
        },
        {
          diagnosisCode: 'H53.033',
          diagnosisDescription: 'Strabismic amblyopia, bilateral'
        },
        {
          diagnosisCode: 'H53.141',
          diagnosisDescription: 'Visual discomfort, right eye'
        },
        {
          diagnosisCode: 'H53.142',
          diagnosisDescription: 'Visual discomfort, left eye'
        },
        {
          diagnosisCode: 'H53.143',
          diagnosisDescription: 'Visual discomfort, bilateral'
        },
        {
          diagnosisCode: 'Q12.3',
          diagnosisDescription: 'Congenital aphakia'
        },
        {
          diagnosisCode: 'Z96.1',
          diagnosisDescription: 'Presence of intraocular lens'
        },

      ]
    },
    {
      conditionDescription: 'WellVision Polycarbonate',
      diagnosis: [
        {
          diagnosisCode: 'H54.10',
          diagnosisDescription: 'Blindness, one eye, low vision other eye, unspecified eyes'
        },
        {
          diagnosisCode: 'H54.1131',
          diagnosisDescription: 'Blindness right eye category 3, low vision left eye category 1'
        },
        {
          diagnosisCode: 'H54.1132',
          diagnosisDescription: 'Blindness right eye category 3, low vision left eye category 2'
        },
        {
          diagnosisCode: 'H54.1141',
          diagnosisDescription: 'Blindness right eye category 4, low vision left eye category 1'
        },
        {
          diagnosisCode: 'H54.1142',
          diagnosisDescription: 'Blindness right eye category 4, low vision left eye category 2'
        },
        {
          diagnosisCode: 'H54.1151',
          diagnosisDescription: 'Blindness right eye category 5, low vision left eye category 1'
        },
        {
          diagnosisCode: 'H54.1152',
          diagnosisDescription: 'Blindness right eye category 5, low vision left eye category 2'
        },
        {
          diagnosisCode: 'H54.1213',
          diagnosisDescription: 'Low vision right eye category 1, blindness left eye category 3'
        },
        {
          diagnosisCode: 'H54.1214',
          diagnosisDescription: 'Low vision right eye category 1, blindness left eye category 4'
        },
        {
          diagnosisCode: 'H54.1215',
          diagnosisDescription: 'Low vision right eye category 1, blindness left eye category 5'
        },
        {
          diagnosisCode: 'H54.1223',
          diagnosisDescription: 'Low vision right eye category 2, blindness left eye category 3'
        },
        {
          diagnosisCode: 'H54.1224',
          diagnosisDescription: 'Low vision right eye category 2, blindness left eye category 4'
        },
        {
          diagnosisCode: 'H54.1225',
          diagnosisDescription: 'Low vision right eye category 2, blindness left eye category 5'
        },
        {
          diagnosisCode: 'H54.2X11',
          diagnosisDescription: 'Low vision right eye category 1, low vision left eye category 1'
        },
        {
          diagnosisCode: 'H54.2X12',
          diagnosisDescription: 'Low vision right eye category 1, low vision left eye category 2'
        },
        {
          diagnosisCode: 'H54.2X21',
          diagnosisDescription: 'Low vision right eye category 2, low vision left eye category 1'
        },
        {
          diagnosisCode: 'H54.2X22',
          diagnosisDescription: 'Low vision right eye category 2, low vision left eye category 2'
        },
        {
          diagnosisCode: 'H54.40',
          diagnosisDescription: 'Blindness, one eye, unspecified eye'
        },
        {
          diagnosisCode: 'H54.413A',
          diagnosisDescription: 'Blindness right eye category 3, normal vision left eye'
        },
        {
          diagnosisCode: 'H54.414A',
          diagnosisDescription: 'Blindness right eye category 4, normal vision left eye'
        },
        {
          diagnosisCode: 'H54.415A',
          diagnosisDescription: 'Blindness right eye category 5, normal vision left eye'
        },
        {
          diagnosisCode: 'H54.42A3',
          diagnosisDescription: 'Blindness left eye category 3, normal vision right eye'
        },
        {
          diagnosisCode: 'H54.42A4',
          diagnosisDescription: 'Blindness left eye category 4, normal vision right eye'
        },
        {
          diagnosisCode: 'H54.42A5',
          diagnosisDescription: 'Blindness left eye category 5, normal vision right eye'
        },

      ]
    },
    {
      conditionDescription: 'Prediabetes',
      diagnosis: [
        {
          diagnosisCode: 'R73.03',
          diagnosisDescription: 'Prediabetes'
        }
      ]
    },
    {
      conditionDescription: 'Diabetes',
      diagnosis: [
        {
          diagnosisCode: 'E10.36',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic cataract'
        },
        {
          diagnosisCode: 'E10.37X1',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic macular edema, resolved following treatment, right eye'
        },
        {
          diagnosisCode: 'E10.37X2',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic macular edema, resolved following treatment, left eye'
        },
        {
          diagnosisCode: 'E10.37X3',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic macular edema, resolved following treatment, bilateral'
        },
        {
          diagnosisCode: 'E10.39',
          diagnosisDescription: 'Type 1 diabetes mellitus with other diabetic ophthalmic complication'
        },
        {
          diagnosisCode: 'E10.40',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic neuropathy, unspecified'
        },
        {
          diagnosisCode: 'E10.41',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic mononeuropathy'
        },
        {
          diagnosisCode: 'E10.42',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic polyneuropathy'
        },
        {
          diagnosisCode: 'E10.43',
          diagnosisDescription: 'Type 1 diabetes mellitus with diabetic autonomic (poly)neuropathy'
        },
        {
          diagnosisCode: 'E10.49',
          diagnosisDescription: 'Type 1 diabetes mellitus with other diabetic neurological complication'
        },
        {
          diagnosisCode: 'E10.59',
          diagnosisDescription: 'Type 1 diabetes mellitus with other circulatory complications'
        },
        {
          diagnosisCode: 'E10.69',
          diagnosisDescription: 'Type 1 diabetes mellitus with other specified complication'
        },
        {
          diagnosisCode: 'E10.8',
          diagnosisDescription: 'Type 1 diabetes mellitus with unspecified complications'
        },
        {
          diagnosisCode: 'E10.9',
          diagnosisDescription: 'Type 1 diabetes mellitus without complications'
        },
        {
          diagnosisCode: 'E11.36',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic cataract'
        },
        {
          diagnosisCode: 'E11.37X1',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, right eye'
        },
        {
          diagnosisCode: 'E11.37X2',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, left eye'
        },
        {
          diagnosisCode: 'E11.37X3',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, bilateral'
        },
        {
          diagnosisCode: 'E11.39',
          diagnosisDescription: 'Type 2 diabetes mellitus with other diabetic ophthalmic complication'
        },
        {
          diagnosisCode: 'E11.40',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic neuropathy, unspecified'
        },
        {
          diagnosisCode: 'E11.41',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic mononeuropathy'
        },
        {
          diagnosisCode: 'E11.42',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic polyneuropathy'
        },
        {
          diagnosisCode: 'E11.43',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic autonomic (poly)neuropathy'
        },
        {
          diagnosisCode: 'E11.49',
          diagnosisDescription: 'Type 2 diabetes mellitus with other diabetic neurological complication'
        },
        {
          diagnosisCode: 'E11.59',
          diagnosisDescription: 'Type 2 diabetes mellitus with other circulatory complications'
        },
        {
          diagnosisCode: 'E11.69',
          diagnosisDescription: 'Type 2 diabetes mellitus with other specified complication'
        },
        {
          diagnosisCode: 'E11.8',
          diagnosisDescription: 'Type 2 diabetes mellitus with unspecified complications'
        },
        {
          diagnosisCode: 'E11.9',
          diagnosisDescription: 'Type 2 diabetes mellitus without complications'
        },
        {
          diagnosisCode: 'E13.36',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic cataract'
        },
        {
          diagnosisCode: 'E13.37X1',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, right eye'
        },
        {
          diagnosisCode: 'E13.37X2',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, left eye'
        },
        {
          diagnosisCode: 'E13.37X3',
          diagnosisDescription: 'Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, bilateral'
        },
        {
          diagnosisCode: 'E13.39',
          diagnosisDescription: 'Other specified diabetes mellitus with other diabetic ophthalmic complication'
        },
        {
          diagnosisCode: 'E13.40',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic neuropathy, unspecified'
        },
        {
          diagnosisCode: 'E13.41',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic mononeuropathy'
        },
        {
          diagnosisCode: 'E13.42',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic polyneuropathy'
        },
        {
          diagnosisCode: 'E13.43',
          diagnosisDescription: 'Other specified diabetes mellitus with diabetic autonomic (poly)neuropathy'
        },
        {
          diagnosisCode: 'E13.59',
          diagnosisDescription: 'Other specified diabetes mellitus with other circulatory complications'
        },
        {
          diagnosisCode: 'E13.69',
          diagnosisDescription: 'Other specified diabetes mellitus with other specified complication'
        },
        {
          diagnosisCode: 'E13.8',
          diagnosisDescription: 'Other specified diabetes mellitus with unspecified complications'
        },
        {
          diagnosisCode: 'E13.9',
          diagnosisDescription: 'Other specified diabetes mellitus without complications'
        },

      ]
    },
    {
      conditionDescription: 'Diabetic Retinopathy',
      diagnosis: [
        {
          diagnosisCode: 'E10.311',
          diagnosisDescription: 'Type 1 diabetes mellitus with unspecified diabetic retinopathy with macular edema'
        },
        {
          diagnosisCode: 'E10.319',
          diagnosisDescription: 'Type 1 diabetes mellitus with unspecified diabetic retinopathy without macular edema'
        },
        {
          diagnosisCode: 'E10.3211',
          diagnosisDescription: 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode: 'E10.3212',
          diagnosisDescription: 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode: 'E10.3213',
          diagnosisDescription: 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3291',
          diagnosisDescription: 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode: 'E10.3292',
          diagnosisDescription: 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode: 'E10.3293',
          diagnosisDescription: 'Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3311',
          diagnosisDescription: 'Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode: 'E10.3312',
          diagnosisDescription: 'Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode: 'E10.3313',
          diagnosisDescription: 'Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3391',
          diagnosisDescription: 'Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode: 'E10.3392',
          diagnosisDescription: 'Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode: 'E10.3393',
          diagnosisDescription: 'Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3411',
          diagnosisDescription: 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode: 'E10.3412',
          diagnosisDescription: 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode: 'E10.3413',
          diagnosisDescription: 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3491',
          diagnosisDescription: 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode: 'E10.3492',
          diagnosisDescription: 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode: 'E10.3493',
          diagnosisDescription: 'Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3511',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode: 'E10.3512',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode: 'E10.3513',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode: 'E10.3521',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, right eye'
        },
        {
          diagnosisCode: 'E10.3522',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, left eye'
        },
        {
          diagnosisCode: 'E10.3523',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, bilateral'
        },
        {
          diagnosisCode: 'E10.3531',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, right eye'
        },
        {
          diagnosisCode: 'E10.3532',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, left eye'
        },
        {
          diagnosisCode: 'E10.3533',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, bilateral'
        },
        {
          diagnosisCode: 'E10.3541',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, right eye'
        },
        {
          diagnosisCode: 'E10.3542',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, left eye'
        },
        {
          diagnosisCode: 'E10.3543',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, bilateral'
        },
        {
          diagnosisCode: 'E10.3551',
          diagnosisDescription: 'Type 1 diabetes mellitus with stable proliferative diabetic retinopathy, right eye'
        },
        {
          diagnosisCode: 'E10.3552',
          diagnosisDescription: 'Type 1 diabetes mellitus with stable proliferative diabetic retinopathy, left eye'
        },
        {
          diagnosisCode: 'E10.3553',
          diagnosisDescription: 'Type 1 diabetes mellitus with stable proliferative diabetic retinopathy, bilateral'
        },
        {
          diagnosisCode: 'E10.3559',
          diagnosisDescription: 'Type 1 diabetes mellitus with stable proliferative diabetic retinopathy, unspecified eye'
        },
        {
          diagnosisCode: 'E10.3591',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode: 'E10.3592',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode: 'E10.3593',
          diagnosisDescription: 'Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode: 'E11.311',
          diagnosisDescription: 'Type 2 diabetes mellitus with unspecified diabetic retinopathy with macular edema'
        },
        {
          diagnosisCode: 'E11.319',
          diagnosisDescription: 'Type 2 diabetes mellitus with unspecified diabetic retinopathy without macular edema'
        },
        {
          diagnosisCode: 'E11.3211',
          diagnosisDescription: 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode: 'E11.3212',
          diagnosisDescription: 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode: 'E11.3213',
          diagnosisDescription: 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3291',
          diagnosisDescription: 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode: 'E11.3292',
          diagnosisDescription: 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode: 'E11.3293',
          diagnosisDescription: 'Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3311',
          diagnosisDescription: 'Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode: 'E11.3312',
          diagnosisDescription: 'Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode: 'E11.3313',
          diagnosisDescription: 'Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3391',
          diagnosisDescription: 'Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode: 'E11.3392',
          diagnosisDescription: 'Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode: 'E11.3393',
          diagnosisDescription: 'Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3411',
          diagnosisDescription: 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode: 'E11.3412',
          diagnosisDescription: 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode: 'E11.3413',
          diagnosisDescription: 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3491',
          diagnosisDescription: 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode: 'E11.3492',
          diagnosisDescription: 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode: 'E11.3493',
          diagnosisDescription: 'Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3511',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode: 'E11.3512',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode: 'E11.3513',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode: 'E11.3521',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, right eye'
        },
        {
          diagnosisCode: 'E11.3522',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, left eye'
        },
        {
          diagnosisCode: 'E11.3523',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, bilateral'
        },
        {
          diagnosisCode: 'E11.3531',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, right eye'
        },
        {
          diagnosisCode: 'E11.3532',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, left eye'
        },
        {
          diagnosisCode: 'E11.3533',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, bilateral'
        },
        {
          diagnosisCode: 'E11.3541',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, right eye'
        },
        {
          diagnosisCode: 'E11.3542',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, left eye'
        },
        {
          diagnosisCode: 'E11.3543',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, bilateral'
        },
        {
          diagnosisCode: 'E11.3551',
          diagnosisDescription: 'Type 2 diabetes mellitus with stable proliferative diabetic retinopathy, right eye'
        },
        {
          diagnosisCode: 'E11.3552',
          diagnosisDescription: 'Type 2 diabetes mellitus with stable proliferative diabetic retinopathy, left eye'
        },
        {
          diagnosisCode: 'E11.3553',
          diagnosisDescription: 'Type 2 diabetes mellitus with stable proliferative diabetic retinopathy, bilateral'
        },
        {
          diagnosisCode: 'E11.3591',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode: 'E11.3592',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode: 'E11.3593',
          diagnosisDescription: 'Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode: 'E13.311',
          diagnosisDescription: 'Other specified diabetes mellitus with unspecified diabetic retinopathy with macular edema'
        },
        {
          diagnosisCode: 'E13.319',
          diagnosisDescription: 'Other specified diabetes mellitus with unspecified diabetic retinopathy without macular edema'
        },
        {
          diagnosisCode: 'E13.3211',
          diagnosisDescription: 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode: 'E13.3212',
          diagnosisDescription: 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode: 'E13.3213',
          diagnosisDescription: 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3291',
          diagnosisDescription: 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode: 'E13.3292',
          diagnosisDescription: 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode: 'E13.3293',
          diagnosisDescription: 'Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3311',
          diagnosisDescription: 'Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode: 'E13.3312',
          diagnosisDescription: 'Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode: 'E13.3313',
          diagnosisDescription: 'Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3391',
          diagnosisDescription: 'Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode: 'E13.3392',
          diagnosisDescription: 'Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode: 'E13.3393',
          diagnosisDescription: 'Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3411',
          diagnosisDescription: 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode: 'E13.3412',
          diagnosisDescription: 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode: 'E13.3413',
          diagnosisDescription: 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3491',
          diagnosisDescription: 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode: 'E13.3492',
          diagnosisDescription: 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode: 'E13.3493',
          diagnosisDescription: 'Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3511',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema, right eye'
        },
        {
          diagnosisCode: 'E13.3512',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema, left eye'
        },
        {
          diagnosisCode: 'E13.3513',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema, bilateral'
        },
        {
          diagnosisCode: 'E13.3521',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, right eye'
        },
        {
          diagnosisCode: 'E13.3522',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, left eye'
        },
        {
          diagnosisCode: 'E13.3523',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment involving the macula, bilateral'
        },
        {
          diagnosisCode: 'E13.3531',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, right eye'
        },
        {
          diagnosisCode: 'E13.3532',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, left eye'
        },
        {
          diagnosisCode: 'E13.3533',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal detachment not involving the macula, bilateral'
        },
        {
          diagnosisCode: 'E13.3541',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, right eye'
        },
        {
          diagnosisCode: 'E13.3542',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, left eye'
        },
        {
          diagnosisCode: 'E13.3543',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal detachment and rhegmatogenous retinal detachment, bilateral'
        },
        {
          diagnosisCode: 'E13.3551',
          diagnosisDescription: 'Other specified diabetes mellitus with stable proliferative diabetic retinopathy, right eye'
        },
        {
          diagnosisCode: 'E13.3552',
          diagnosisDescription: 'Other specified diabetes mellitus with stable proliferative diabetic retinopathy, left eye'
        },
        {
          diagnosisCode: 'E13.3553',
          diagnosisDescription: 'Other specified diabetes mellitus with stable proliferative diabetic retinopathy, bilateral'
        },
        {
          diagnosisCode: 'E13.3591',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy without macular edema, right eye'
        },
        {
          diagnosisCode: 'E13.3592',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy without macular edema, left eye'
        },
        {
          diagnosisCode: 'E13.3593',
          diagnosisDescription: 'Other specified diabetes mellitus with proliferative diabetic retinopathy without macular edema, bilateral'
        },
        {
          diagnosisCode: 'H21.1X1',
          diagnosisDescription: 'Other vascular disorders of iris and ciliary body, right eye'
        },
        {
          diagnosisCode: 'H21.1X2',
          diagnosisDescription: 'Other vascular disorders of iris and ciliary body, left eye'
        },
        {
          diagnosisCode: 'H21.1X3',
          diagnosisDescription: 'Other vascular disorders of iris and ciliary body, bilateral'
        },

      ]
    },
    {
      conditionDescription: 'High Cholesterol',
      diagnosis: [
        {
          diagnosisCode: 'E78.00',
          diagnosisDescription: 'Pure hypercholesterolemia'
        },
        {
          diagnosisCode: 'E78.1',
          diagnosisDescription: 'Pure hyperglyceridemia'
        },
        {
          diagnosisCode: 'E78.2',
          diagnosisDescription: 'Mixed hyperlipidemia'
        },
        {
          diagnosisCode: 'E78.4 ',
          diagnosisDescription: 'Other hyperlipidemia'
        },
        {
          diagnosisCode: 'E78.5 ',
          diagnosisDescription: 'Hyperlipidemia, unspecified'
        },

      ]
    },
    {
      conditionDescription: 'Hypertension',
      diagnosis: [
        {
          diagnosisCode: 'I10',
          diagnosisDescription: 'Essential (primary) hypertension'
        },
        {
          diagnosisCode: 'I97.3',
          diagnosisDescription: 'Postprocedural hypertension'
        },
        {
          diagnosisCode: 'H35.031',
          diagnosisDescription: 'Hypertensive Retinopathy, Right Eye'
        },
        {
          diagnosisCode: 'H35.032',
          diagnosisDescription: 'Hypertensive Retinopathy, Left Eye'
        },
        {
          diagnosisCode: 'H35.033',
          diagnosisDescription: 'Hypertensive Retinopathy, Bilateral'
        },

      ]
    },
    {
      conditionDescription: 'Arcus',
      diagnosis: [
        {
          diagnosisCode: 'H18.411',
          diagnosisDescription: 'Arcus senilis, right eye'
        },
        {
          diagnosisCode: 'H18.412',
          diagnosisDescription: 'Arcus senilis, left eye'
        },
        {
          diagnosisCode: 'H18.413',
          diagnosisDescription: 'Arcus senilis, bilateral'
        },

      ]
    },
    {
      conditionDescription: 'Glaucoma',
      diagnosis: [
        {
          diagnosisCode: 'H40.001',
          diagnosisDescription: 'Preglaucoma, Unspecified, Right Eye'
        },
        {
          diagnosisCode: 'H40.002',
          diagnosisDescription: 'Preglaucoma, Unspecified, Left Eye'
        },
        {
          diagnosisCode: 'H40.003',
          diagnosisDescription: 'Preglaucoma, Unspecified, Bilateral'
        },
        {
          diagnosisCode: 'H40.021',
          diagnosisDescription: 'Open angle with borderline findings, high risk, right eye'
        },
        {
          diagnosisCode: 'H40.022',
          diagnosisDescription: 'Open angle with borderline findings, high risk, left eye'
        },
        {
          diagnosisCode: 'H40.023',
          diagnosisDescription: 'Open angle with borderline findings, high risk, bilateral'
        },
        {
          diagnosisCode: 'H40.051',
          diagnosisDescription: 'Ocular Hypertension, Right Eye'
        },
        {
          diagnosisCode: 'H40.052',
          diagnosisDescription: 'Ocular Hypertension, Left Eye'
        },
        {
          diagnosisCode: 'H40.053',
          diagnosisDescription: 'Ocular Hypertension, Bilateral'
        },
        {
          diagnosisCode: 'H40.061',
          diagnosisDescription: 'Primary angle closure without glaucoma damage, right eye'
        },
        {
          diagnosisCode: 'H40.10X2',
          diagnosisDescription: 'Unspecified open-angle glaucoma, moderate stage'
        },
        {
          diagnosisCode: 'H40.10X3',
          diagnosisDescription: 'Unspecified open-angle glaucoma, severe stage'
        },
        {
          diagnosisCode: 'H40.10X4',
          diagnosisDescription: 'Unspecified open-angle glaucoma, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.1111',
          diagnosisDescription: 'Primary open-angle glaucoma, right eye, mild stage'
        },
        {
          diagnosisCode: 'H40.1112',
          diagnosisDescription: 'Primary open-angle glaucoma, right eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.1113',
          diagnosisDescription: 'Primary open-angle glaucoma, right eye, severe stage'
        },
        {
          diagnosisCode: 'H40.1114',
          diagnosisDescription: 'Primary open-angle glaucoma, right eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.1121',
          diagnosisDescription: 'Primary open-angle glaucoma, left eye, mild stage'
        },
        {
          diagnosisCode: 'H40.1122',
          diagnosisDescription: 'Primary open-angle glaucoma, left eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.1123',
          diagnosisDescription: 'Primary open-angle glaucoma, left eye, severe stage'
        },
        {
          diagnosisCode: 'H40.1124',
          diagnosisDescription: 'Primary open-angle glaucoma, left eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.1131',
          diagnosisDescription: 'Primary open-angle glaucoma, bilateral, mild stage'
        },
        {
          diagnosisCode: 'H40.1132',
          diagnosisDescription: 'Primary open-angle glaucoma, bilateral, moderate stage'
        },
        {
          diagnosisCode: 'H40.1133',
          diagnosisDescription: 'Primary open-angle glaucoma, bilateral, severe stage'
        },
        {
          diagnosisCode: 'H40.1134',
          diagnosisDescription: 'Primary open-angle glaucoma, bilateral, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.1211',
          diagnosisDescription: 'Low-tension glaucoma, right eye, mild stage'
        },
        {
          diagnosisCode: 'H40.1212',
          diagnosisDescription: 'Low-tension glaucoma, right eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.1213',
          diagnosisDescription: 'Low-tension glaucoma, right eye, severe stage'
        },
        {
          diagnosisCode: 'H40.1214',
          diagnosisDescription: 'Low-tension glaucoma, right eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.1221',
          diagnosisDescription: 'Low-tension glaucoma, left eye, mild stage'
        },
        {
          diagnosisCode: 'H40.1222',
          diagnosisDescription: 'Low-tension glaucoma, left eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.1223',
          diagnosisDescription: 'Low-tension glaucoma, left eye, severe stage'
        },
        {
          diagnosisCode: 'H40.1224',
          diagnosisDescription: 'Low-tension glaucoma, left eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.1231',
          diagnosisDescription: 'Low-tension glaucoma, bilateral, mild stage'
        },
        {
          diagnosisCode: 'H40.1232',
          diagnosisDescription: 'Low-tension glaucoma, bilateral, moderate stage'
        },
        {
          diagnosisCode: 'H40.1233',
          diagnosisDescription: 'Low-tension glaucoma, bilateral, severe stage'
        },
        {
          diagnosisCode: 'H40.1234',
          diagnosisDescription: 'Low-tension glaucoma, bilateral, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.1311',
          diagnosisDescription: 'Pigmentary glaucoma, right eye, mild stage'
        },
        {
          diagnosisCode: 'H40.1312',
          diagnosisDescription: 'Pigmentary glaucoma, right eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.1313',
          diagnosisDescription: 'Pigmentary glaucoma, right eye, severe stage'
        },
        {
          diagnosisCode: 'H40.1314',
          diagnosisDescription: 'Pigmentary glaucoma, right eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.1321',
          diagnosisDescription: 'Pigmentary glaucoma, left eye, mild stage'
        },
        {
          diagnosisCode: 'H40.1322',
          diagnosisDescription: 'Pigmentary glaucoma, left eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.1323',
          diagnosisDescription: 'Pigmentary glaucoma, left eye, severe stage'
        },
        {
          diagnosisCode: 'H40.1324',
          diagnosisDescription: 'Pigmentary glaucoma, left eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.1331',
          diagnosisDescription: 'Pigmentary glaucoma, bilateral, mild stage'
        },
        {
          diagnosisCode: 'H40.1332',
          diagnosisDescription: 'Pigmentary glaucoma, bilateral, moderate stage'
        },
        {
          diagnosisCode: 'H40.1333',
          diagnosisDescription: 'Pigmentary glaucoma, bilateral, severe stage'
        },
        {
          diagnosisCode: 'H40.1334',
          diagnosisDescription: 'Pigmentary glaucoma, bilateral, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.1411',
          diagnosisDescription: 'Capsular glaucoma with pseudoexfoliation of lens, right eye, mild stage'
        },
        {
          diagnosisCode: 'H40.1412',
          diagnosisDescription: 'Capsular glaucoma with pseudoexfoliation of lens, right eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.1413',
          diagnosisDescription: 'Capsular glaucoma with pseudoexfoliation of lens, right eye, severe stage'
        },
        {
          diagnosisCode: 'H40.1414',
          diagnosisDescription: 'Capsular glaucoma with pseudoexfoliation of lens, right eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.1421',
          diagnosisDescription: 'Capsular glaucoma with pseudoexfoliation of lens, left eye, mild stage'
        },
        {
          diagnosisCode: 'H40.1422',
          diagnosisDescription: 'Capsular glaucoma with pseudoexfoliation of lens, left eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.1423',
          diagnosisDescription: 'Capsular glaucoma with pseudoexfoliation of lens, left eye, severe stage'
        },
        {
          diagnosisCode: 'H40.1424',
          diagnosisDescription: 'Capsular glaucoma with pseudoexfoliation of lens, left eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.1431',
          diagnosisDescription: 'Capsular glaucoma with pseudoexfoliation of lens, bilateral, mild stage'
        },
        {
          diagnosisCode: 'H40.1432',
          diagnosisDescription: 'Capsular glaucoma with pseudoexfoliation of lens, bilateral, moderate stage'
        },
        {
          diagnosisCode: 'H40.1433',
          diagnosisDescription: 'Capsular glaucoma with pseudoexfoliation of lens, bilateral, severe stage'
        },
        {
          diagnosisCode: 'H40.1434',
          diagnosisDescription: 'Capsular glaucoma with pseudoexfoliation of lens, bilateral, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.151',
          diagnosisDescription: 'Residual stage of open-angle glaucoma, right eye'
        },
        {
          diagnosisCode: 'H40.152',
          diagnosisDescription: 'Residual stage of open-angle glaucoma, left eye'
        },
        {
          diagnosisCode: 'H40.153',
          diagnosisDescription: 'Residual stage of open-angle glaucoma, bilateral'
        },
        {
          diagnosisCode: 'H40.20X1',
          diagnosisDescription: 'Unspecified primary angle-closure glaucoma, mild stage'
        },
        {
          diagnosisCode: 'H40.20X2',
          diagnosisDescription: 'Unspecified primary angle-closure glaucoma, moderate stage'
        },
        {
          diagnosisCode: 'H40.20X3',
          diagnosisDescription: 'Unspecified primary angle-closure glaucoma, severe stage'
        },
        {
          diagnosisCode: 'H40.20X4',
          diagnosisDescription: 'Unspecified primary angle-closure glaucoma, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.211',
          diagnosisDescription: 'Acute angle-closure glaucoma, right eye'
        },
        {
          diagnosisCode: 'H40.212',
          diagnosisDescription: 'Acute angle-closure glaucoma, left eye'
        },
        {
          diagnosisCode: 'H40.213',
          diagnosisDescription: 'Acute angle-closure glaucoma, bilateral'
        },
        {
          diagnosisCode: 'H40.2211',
          diagnosisDescription: 'Chronic angle-closure glaucoma, right eye, mild stage'
        },
        {
          diagnosisCode: 'H40.2212',
          diagnosisDescription: 'Chronic angle-closure glaucoma, right eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.2213',
          diagnosisDescription: 'Chronic angle-closure glaucoma, right eye, severe stage'
        },
        {
          diagnosisCode: 'H40.2214',
          diagnosisDescription: 'Chronic angle-closure glaucoma, right eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.2221',
          diagnosisDescription: 'Chronic angle-closure glaucoma, left eye, mild stage'
        },
        {
          diagnosisCode: 'H40.2222',
          diagnosisDescription: 'Chronic angle-closure glaucoma, left eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.2223',
          diagnosisDescription: 'Chronic angle-closure glaucoma, left eye, severe stage'
        },
        {
          diagnosisCode: 'H40.2224',
          diagnosisDescription: 'Chronic angle-closure glaucoma, left eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.2231',
          diagnosisDescription: 'Chronic angle-closure glaucoma, bilateral, mild stage'
        },
        {
          diagnosisCode: 'H40.2232',
          diagnosisDescription: 'Chronic angle-closure glaucoma, bilateral, moderate stage'
        },
        {
          diagnosisCode: 'H40.2233',
          diagnosisDescription: 'Chronic angle-closure glaucoma, bilateral, severe stage'
        },
        {
          diagnosisCode: 'H40.2234',
          diagnosisDescription: 'Chronic angle-closure glaucoma, bilateral, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.231',
          diagnosisDescription: 'Intermittent angle-closure glaucoma, right eye'
        },
        {
          diagnosisCode: 'H40.232',
          diagnosisDescription: 'Intermittent angle-closure glaucoma, left eye'
        },
        {
          diagnosisCode: 'H40.233',
          diagnosisDescription: 'Intermittent angle-closure glaucoma, bilateral'
        },
        {
          diagnosisCode: 'H40.241',
          diagnosisDescription: 'Residual stage of angle-closure glaucoma, right eye'
        },
        {
          diagnosisCode: 'H40.242',
          diagnosisDescription: 'Residual stage of angle-closure glaucoma, left eye'
        },
        {
          diagnosisCode: 'H40.243',
          diagnosisDescription: 'Residual stage of angle-closure glaucoma, bilateral'
        },
        {
          diagnosisCode: 'H40.31X1',
          diagnosisDescription: 'Glaucoma secondary to eye trauma, right eye, mild stage'
        },
        {
          diagnosisCode: 'H40.31X2',
          diagnosisDescription: 'Glaucoma secondary to eye trauma, right eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.31X3',
          diagnosisDescription: 'Glaucoma secondary to eye trauma, right eye, severe stage'
        },
        {
          diagnosisCode: 'H40.31X4',
          diagnosisDescription: 'Glaucoma secondary to eye trauma, right eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.32X1',
          diagnosisDescription: 'Glaucoma secondary to eye trauma, left eye, mild stage'
        },
        {
          diagnosisCode: 'H40.32X2',
          diagnosisDescription: 'Glaucoma secondary to eye trauma, left eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.32X3',
          diagnosisDescription: 'Glaucoma secondary to eye trauma, left eye, severe stage'
        },
        {
          diagnosisCode: 'H40.32X4',
          diagnosisDescription: 'Glaucoma secondary to eye trauma, left eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.33X1',
          diagnosisDescription: 'Glaucoma secondary to eye trauma, bilateral, mild stage'
        },
        {
          diagnosisCode: 'H40.33X2',
          diagnosisDescription: 'Glaucoma secondary to eye trauma, bilateral, moderate stage'
        },
        {
          diagnosisCode: 'H40.33X3',
          diagnosisDescription: 'Glaucoma secondary to eye trauma, bilateral, severe stage'
        },
        {
          diagnosisCode: 'H40.33X4',
          diagnosisDescription: 'Glaucoma secondary to eye trauma, bilateral, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.41X1',
          diagnosisDescription: 'Glaucoma secondary to eye inflammation, right eye, mild stage'
        },
        {
          diagnosisCode: 'H40.41X2',
          diagnosisDescription: 'Glaucoma secondary to eye inflammation, right eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.41X3',
          diagnosisDescription: 'Glaucoma secondary to eye inflammation, right eye, severe stage'
        },
        {
          diagnosisCode: 'H40.41X4',
          diagnosisDescription: 'Glaucoma secondary to eye inflammation, right eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.42X1',
          diagnosisDescription: 'Glaucoma secondary to eye inflammation, left eye, mild stage'
        },
        {
          diagnosisCode: 'H40.42X2',
          diagnosisDescription: 'Glaucoma secondary to eye inflammation, left eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.42X3',
          diagnosisDescription: 'Glaucoma secondary to eye inflammation, left eye, severe stage'
        },
        {
          diagnosisCode: 'H40.42X4',
          diagnosisDescription: 'Glaucoma secondary to eye inflammation, left eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.43X1',
          diagnosisDescription: 'Glaucoma secondary to eye inflammation, bilateral, mild stage'
        },
        {
          diagnosisCode: 'H40.43X2',
          diagnosisDescription: 'Glaucoma secondary to eye inflammation, bilateral, moderate stage'
        },
        {
          diagnosisCode: 'H40.43X3',
          diagnosisDescription: 'Glaucoma secondary to eye inflammation, bilateral, severe stage'
        },
        {
          diagnosisCode: 'H40.43X4',
          diagnosisDescription: 'Glaucoma secondary to eye inflammation, bilateral, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.51X1',
          diagnosisDescription: 'Glaucoma secondary to other eye disorders, right eye, mild stage'
        },
        {
          diagnosisCode: 'H40.51X2',
          diagnosisDescription: 'Glaucoma secondary to other eye disorders, right eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.51X3',
          diagnosisDescription: 'Glaucoma secondary to other eye disorders, right eye, severe stage'
        },
        {
          diagnosisCode: 'H40.51X4',
          diagnosisDescription: 'Glaucoma secondary to other eye disorders, right eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.52X1',
          diagnosisDescription: 'Glaucoma secondary to other eye disorders, left eye, mild stage'
        },
        {
          diagnosisCode: 'H40.52X2',
          diagnosisDescription: 'Glaucoma secondary to other eye disorders, left eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.52X3',
          diagnosisDescription: 'Glaucoma secondary to other eye disorders, left eye, severe stage'
        },
        {
          diagnosisCode: 'H40.52X4',
          diagnosisDescription: 'Glaucoma secondary to other eye disorders, left eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.53X1',
          diagnosisDescription: 'Glaucoma secondary to other eye disorders, bilateral, mild stage'
        },
        {
          diagnosisCode: 'H40.53X2',
          diagnosisDescription: 'Glaucoma secondary to other eye disorders, bilateral, moderate stage'
        },
        {
          diagnosisCode: 'H40.53X3',
          diagnosisDescription: 'Glaucoma secondary to other eye disorders, bilateral, severe stage'
        },
        {
          diagnosisCode: 'H40.53X4',
          diagnosisDescription: 'Glaucoma secondary to other eye disorders, bilateral, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.61X1',
          diagnosisDescription: 'Glaucoma secondary to drugs, right eye, mild stage'
        },
        {
          diagnosisCode: 'H40.61X2',
          diagnosisDescription: 'Glaucoma secondary to drugs, right eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.61X3',
          diagnosisDescription: 'Glaucoma secondary to drugs, right eye, severe stage'
        },
        {
          diagnosisCode: 'H40.61X4',
          diagnosisDescription: 'Glaucoma secondary to drugs, right eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.62X1',
          diagnosisDescription: 'Glaucoma secondary to drugs, left eye, mild stage'
        },
        {
          diagnosisCode: 'H40.62X2',
          diagnosisDescription: 'Glaucoma secondary to drugs, left eye, moderate stage'
        },
        {
          diagnosisCode: 'H40.62X3',
          diagnosisDescription: 'Glaucoma secondary to drugs, left eye, severe stage'
        },
        {
          diagnosisCode: 'H40.62X4',
          diagnosisDescription: 'Glaucoma secondary to drugs, left eye, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.63X1',
          diagnosisDescription: 'Glaucoma secondary to drugs, bilateral, mild stage'
        },
        {
          diagnosisCode: 'H40.63X2',
          diagnosisDescription: 'Glaucoma secondary to drugs, bilateral, moderate stage'
        },
        {
          diagnosisCode: 'H40.63X3',
          diagnosisDescription: 'Glaucoma secondary to drugs, bilateral, severe stage'
        },
        {
          diagnosisCode: 'H40.63X4',
          diagnosisDescription: 'Glaucoma secondary to drugs, bilateral, indeterminate stage'
        },
        {
          diagnosisCode: 'H40.811',
          diagnosisDescription: 'Glaucoma with increased episcleral venous pressure, right eye'
        },
        {
          diagnosisCode: 'H40.812',
          diagnosisDescription: 'Glaucoma with increased episcleral venous pressure, left eye'
        },
        {
          diagnosisCode: 'H40.813',
          diagnosisDescription: 'Glaucoma with increased episcleral venous pressure, bilateral'
        },
        {
          diagnosisCode: 'H40.821',
          diagnosisDescription: 'Hypersecretion glaucoma, right eye'
        },
        {
          diagnosisCode: 'H40.822',
          diagnosisDescription: 'Hypersecretion glaucoma, left eye'
        },
        {
          diagnosisCode: 'H40.823',
          diagnosisDescription: 'Hypersecretion glaucoma, bilateral'
        },
        {
          diagnosisCode: 'H40.831',
          diagnosisDescription: 'Aqueous misdirection, right eye'
        },
        {
          diagnosisCode: 'H40.832',
          diagnosisDescription: 'Aqueous misdirection, left eye'
        },
        {
          diagnosisCode: 'H40.833',
          diagnosisDescription: 'Aqueous misdirection, bilateral'
        },
        {
          diagnosisCode: 'H40.89',
          diagnosisDescription: 'Other specified glaucoma'
        },
        {
          diagnosisCode: 'H40.9',
          diagnosisDescription: 'Unspecified glaucoma'
        },
        {
          diagnosisCode: 'H42',
          diagnosisDescription: 'Glaucoma in diseases classified elsewhere'
        },
        {
          diagnosisCode: 'Q15.0',
          diagnosisDescription: 'Congenital glaucoma'
        },


      ]
    },
    {
      conditionDescription: 'AMD',
      diagnosis: [

        {
          diagnosisCode: 'H35.30',
          diagnosisDescription: 'Unspecified macular degeneration'
        },
        {
          diagnosisCode: 'H35.3111',
          diagnosisDescription: 'Nonexudative age-related macular degeneration, right eye, early dry stage'
        },
        {
          diagnosisCode: 'H35.3112',
          diagnosisDescription: 'Nonexudative age-related macular degeneration, right eye, intermediate dry stage'
        },
        {
          diagnosisCode: 'H35.3113',
          diagnosisDescription: 'Nonexudative age-related macular degeneration, right eye, advanced atrophic without subfoveal involvement'
        },
        {
          diagnosisCode: 'H35.3114',
          diagnosisDescription: 'Nonexudative age-related macular degeneration, right eye, advanced atrophic with subfoveal involvement'
        },
        {
          diagnosisCode: 'H35.3121',
          diagnosisDescription: 'Nonexudative age-related macular degeneration, left eye, early dry stage'
        },
        {
          diagnosisCode: 'H35.3122',
          diagnosisDescription: 'Nonexudative age-related macular degeneration, left eye, intermediate dry stage'
        },
        {
          diagnosisCode: 'H35.3123',
          diagnosisDescription: 'Nonexudative age-related macular degeneration, left eye, advanced atrophic without subfoveal involvement'
        },
        {
          diagnosisCode: 'H35.3124',
          diagnosisDescription: 'Nonexudative age-related macular degeneration, left eye, advanced atrophic with subfoveal involvement'
        },
        {
          diagnosisCode: 'H35.3131',
          diagnosisDescription: 'Nonexudative age-related macular degeneration, bilateral, early dry stage'
        },
        {
          diagnosisCode: 'H35.3132',
          diagnosisDescription: 'Nonexudative age-related macular degeneration, bilateral, intermediate dry stage'
        },
        {
          diagnosisCode: 'H35.3133',
          diagnosisDescription: 'Nonexudative age-related macular degeneration, bilateral, advanced atrophic without subfoveal involvement'
        },
        {
          diagnosisCode: 'H35.3134',
          diagnosisDescription: 'Nonexudative age-related macular degeneration, bilateral, advanced atrophic with subfoveal involvement'
        },
        {
          diagnosisCode: 'H35.3211',
          diagnosisDescription: 'Exudative age-related macular degeneration, right eye, with active choroidal neovascularization'
        },
        {
          diagnosisCode: 'H35.3212',
          diagnosisDescription: 'Exudative age-related macular degeneration, right eye, with inactive choroidal neovascularization'
        },
        {
          diagnosisCode: 'H35.3213',
          diagnosisDescription: 'Exudative age-related macular degeneration, right eye, with inactive scar'
        },
        {
          diagnosisCode: 'H35.3221',
          diagnosisDescription: 'Exudative age-related macular degeneration, left eye, with active choroidal neovascularization'
        },
        {
          diagnosisCode: 'H35.3222',
          diagnosisDescription: 'Exudative age-related macular degeneration, left eye, with inactive choroidal neovascularization'
        },
        {
          diagnosisCode: 'H35.3223',
          diagnosisDescription: 'Exudative age-related macular degeneration, left eye, with inactive scar'
        },
        {
          diagnosisCode: 'H35.3231',
          diagnosisDescription: 'Exudative age-related macular degeneration, bilateral, with active choroidal neovascularization'
        },
        {
          diagnosisCode: 'H35.3232',
          diagnosisDescription: 'Exudative age-related macular degeneration, bilateral, with inactive choroidal neovascularization'
        },
        {
          diagnosisCode: 'H35.3233',
          diagnosisDescription: 'Exudative age-related macular degeneration, bilateral, with inactive scar'
        },
        {
          diagnosisCode: 'H35.351',
          diagnosisDescription: 'Cystoid macular degeneration, right eye'
        },
        {
          diagnosisCode: 'H35.352',
          diagnosisDescription: 'Cystoid macular degeneration, left eye'
        },
        {
          diagnosisCode: 'H35.353',
          diagnosisDescription: 'Cystoid macular degeneration, bilateral'
        },

      ]
    }
  ] as DiagnosisCodeGrouping[]

};
