import { AbstractControl } from '@angular/forms';
import { DateUtility } from '../../common/utility';
import { ApplicationConstants } from '../../common/constants/application.constants';

export class ClaimFormUtilityFunctions {

  /**
   * Set a date value in a passed in abstract control if different from its current value
   * @param {AbstractControl} control
   * @param {Date} dataModelValue
   * @param {boolean} setValueAsDate - If true, the value is set in the control as a Date object.  Otherwise
   * set as a string in the format 'mm/dd/yyyy'.
   */
  public static setDateFieldIfDifferent(control: AbstractControl, dataModelValue: Date, setValueAsDate = false): void {
    const dateFormValue = new Date(control.value);
    const currentDate = (DateUtility.isValidDate(dateFormValue))
      ? dateFormValue
      : undefined;
    dataModelValue = (DateUtility.isValidDate(dataModelValue))
      ? dataModelValue
      : undefined;
    // if both are valid dates and different, reset
    if (currentDate && dataModelValue && currentDate.valueOf() !== dataModelValue.valueOf()) {
      if (setValueAsDate) {
        control.setValue(dataModelValue, ApplicationConstants.updateFormWithoutEmit);
      } else {
        control.setValue(DateUtility.buildFriendlyDateFromJsDate(dataModelValue), ApplicationConstants.updateFormWithoutEmit);
      }
    }
    // if view model is not valid date but data model is, reset
    if (!currentDate && dataModelValue) {
      if (setValueAsDate) {
        control.setValue(dataModelValue, ApplicationConstants.updateFormWithoutEmit);
      } else {
        control.setValue(DateUtility.buildFriendlyDateFromJsDate(dataModelValue), ApplicationConstants.updateFormWithoutEmit);
      }
    }
    // if view model is valid date but data model is not, reset
    if (currentDate && !dataModelValue) {
      control.setValue(dataModelValue, ApplicationConstants.updateFormWithoutEmit);
    }
  }

  /**
   * Get a Date value from a control, or return undefined if no valid data
   * @param {AbstractControl} control
   * @returns {Date | undefined}
   */
  public static getJsDateFromDateField(control: AbstractControl): Date | undefined {
    const value = control.value;
    if (DateUtility.isValidDate(value)) {
      return DateUtility.buildDateFromDateString(value);
    } else {
      return undefined;
    }
  }

}
