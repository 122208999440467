import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import {CobCoverageComponent} from './cob-coverage/cob-coverage.component';
import { SecureRoutingModule } from './secure-routing.module';
import { SecureComponent } from './secure.component';
import { AuthorizationConfirmationComponent } from './authorization-confirmation/authorization-confirmation.component';
import { ClaimFormComponent } from './claim-form/claim-form.component';
import { CoverageSummaryComponent } from './coverage-summary/coverage-summary.component';
import { MemberSearchComponent } from './member-search/member-search.component';
import { PatientSelectionComponent } from './patient-selection/patient-selection.component';
import { SecureHeaderComponent } from './secure-header/secure-header.component';
import { ErrorWrapperComponent } from '../common/components/error-wrapper/error-wrapper.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DeleteAuthorizationModalComponent } from './patient-selection/delete-authorization-modal/delete-authorization-modal.component';
import { ValidSearchCombinationsComponent } from './member-search/valid-search-combinations/valid-search-combinations.component';
import { InsuredComponent } from './claim-form/insured/insured.component';
import { InputMaskService } from '../common/services/support/input-mask/input-mask.service';
import { PatientComponent } from './claim-form/patient/patient.component';
import { AdditionalInformationComponent } from './claim-form/additional-information/additional-information.component';
import { PrescriptionComponent } from './claim-form/prescription/prescription.component';
import { ExamComponent } from './claim-form/exam/exam.component';
import { MessageService } from '../common/services/support/message/message.service';
import { LensComponent } from './claim-form/lens/lens.component';
import { FrameComponent } from './claim-form/frame/frame.component';
import { FrameSearchHelpModalComponent } from './claim-form/frame/frame-search-help-modal/frame-search-help-modal.component';
import { FrameSearchResultsModalComponent } from './claim-form/frame/frame-search-results-modal/frame-search-results-modal.component';
import { LabComponent } from './claim-form/lab/lab.component';
import { ContactsComponent } from './claim-form/contacts/contacts.component';
import { ClaimActionButtonsComponent } from './claim-form/claim-action-buttons/claim-action-buttons.component';
import { ViewStateService } from '../common/services/view-state/view-state.service';
import { ServicesComponent } from './claim-form/services/services.component';
import { FSAExplanationModalComponent } from './claim-form/services/fsa-explanation-modal/fsa-explanation-modal.component';
import { FacilityBillingComponent } from './claim-form/facility-billing/facility-billing.component';
import { DateOfServiceComponent } from './claim-form/date-of-service/date-of-service.component';
import { UserSessionService } from '../common/services/support/user-session/user-session.service';
import { AppDataService } from '../common/services/data-model/http/http-client-data/app-data/app-data.service';
import { IsAuthenticatedRouteGuard } from '../common/services/data-model/http/route-guards/is-authenticated.guard';
import { ClaimsService } from '../common/services/data-model/app/claims/claims.service';
import { AuthorizationsService } from '../common/services/data-model/app/authorizations/authorizations.service';
import { DataMarshallService } from '../common/services/data-model/http/data-marshall/data-marshall.service';
import { ObjectService } from '../common/services/support/object/object.service';
import { AuthHeaderInterceptor } from '../common/services/data-model/http/http-interceptors/auth-header-interceptor';
import { EInsuranceComponent } from './eInsurance/eInsurance.component';
import { GiftCertificateSubmissionComponent } from './gift-certificate-submission/gift-certificate-submission.component';
import { HcpcCptCodesModalComponent } from './claim-form/services/hcpc-cpt-codes-modal/hcpc-cpt-codes-modal.component';
import {HcpcCptCodesDescriptionsModalComponent} from './claim-form/services/hcpc-cpt-codes-descriptions-modal/hcpc-cpt-codes-descriptions-modal.component';
import { GiftCertificateSubmissionConfirmationComponent } from './gift-certificate-submission/gift-certificate-submission-confirmation/gift-certificate-submission-confirmation.component';
import { SignedPaddedZeroesPipe } from '../common/pipes/signed-padded-zeroes.pipe';
import { UsdCurrencyPipe } from '../common/pipes/usd-currency.pipe';
import { ModifierCodesPipe } from '../common/pipes/modifier-code.pipe';
import { EnumToArrayPipe } from '../common/pipes/enum-to-array.pipe';
import { ServiceLineComponent } from './claim-form/services/service-line/service-line.component';
import { DateUtility } from '../common/utility';
import { CustomValidatorsService } from '../common/services/support/custom-validators/custom-validators.service';
import { DiagnosisCodeComponent } from './claim-form/services/diagnosis-code/diagnosis-code.component';
import { ComponentMaskComponent } from '../common/components/component-mask/component-mask.component';
import { SignaturesComponent } from './claim-form/signatures/signatures.component';
import { SubmitClaimModalComponent } from './claim-form/claim-action-buttons/submit-claim-modal/submit-claim-modal.component';
import { RetrieveSubmittedClaimConfirmationComponent } from './eInsurance/retrieve-submitted-claim-confirmation/retrieve-submitted-claim-confirmation.component';
import { MaterialsModule } from '../materials.module';
import { FrameDetailsDisplayComponent } from './claim-form/frame/frame-details-display/frame-details-display.component';
import { DatePickerComponent } from '../common/components/date-picker/date-picker.component';
import { GiftCertificatesService } from '../common/services/data-model/app/gift-certificates/gift-certificates.service';
import { EligibilityService } from '../common/services/data-model/app/eligibility/eligibility.service';
import { ConfirmationModalComponent } from '../common/components/confirmation-modal/confirmation-modal.component';
import { ClaimFormNavigationService } from '../common/services/support/claim-form-navigation/claim-form-navigation.service';
import { ConsumerService } from '../common/services/data-model/app/consumer/consumer.service';
import { ConsumerDataService } from '../common/services/data-model/http/http-client-data/consumer-data/consumer-data.service';
import { MaskedDatePipe } from '../common/pipes/masked-date.pipe';
import { DiagnosisCodeListModalComponent } from './claim-form/services/diagnosis-code/diagnosis-code-list-modal/diagnosis-code-list-modal.component';
import { ExternalServiceLocationService } from '../common/services/data-model/app/external-service-location/external-service-location.service';
import { LoadingDropdownComponent } from '../common/components/loading-dropdown/loading-dropdown.component';
import { SimpleModalComponent } from '../common/components/simple-modal/simple-modal.component';
import { SupplierWebDataService } from '../common/services/data-model/http/http-client-data/supplier-web/supplier-web-data.service';
import { SupplierWebService } from '../common/services/data-model/app/supplier-web/supplier-web.service';
import { LeaveClaimFormWarningModalComponent } from './claim-form/leave-claim-form-warning-modal/leave-claim-form-warning-modal.component';
import { SuccessfulSubmitClaimModalComponent } from './claim-form/claim-action-buttons/successful-submit-claim-modal/successful-submit-claim-modal.component';
import {LeaveClaimFormWhenInEditModeGuard} from '../common/services/data-model/http/route-guards/leave-claim-form-when-in-edit-mode.guard';
import {NgSelectModule} from '@ng-select/ng-select';
import {PaddedZeroesPipe} from '../common/pipes/padded-zeroes.pipe';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import { ClaimNavigationComponent } from './claim-form/claim-navigation/claim-navigation.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {PrintViewReportsComponent} from './claim-form/print-view-reports/print-view-reports.component';
import {SoftAndHardEditBannerComponent} from './claim-form/soft-and-hard-edit-modal/soft-and-hard-edit-banner.component';
import {ClaimsTrackingComponent} from './claims-tracking/claims-tracking.component';
import {AuthTrackingComponent} from './auth-tracking/auth-tracking.component';
import {ClaimDetailsModalComponent} from './claims-tracking/claim-details-modal/claim-details-modal.component';
import {ClaimsTrackingSearchResultsComponent} from './claims-tracking/claims-tracking-search-results/claims-tracking-search-results.component';
import {AuthDetailsModalComponent} from './auth-tracking/auth-details-modal/auth-details-modal.component';
import {AuthTrackingSearchResultsComponent} from './auth-tracking/auth-tracking-search-results/auth-tracking-search-results.component';
import {ReissueAuthorizationModalComponent} from './auth-tracking/reissue-authorization-modal/reissue-authorization-modal.component';
import {ProvideFeedbackModalComponent} from './claim-form/print-view-reports/provide-feedback-modal/provide-feedback-modal.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import {ReplaceAuthConfirmationModalComponent} from './auth-tracking/reissue-authorization-modal/replace-authorization-confirmation-modal/replace-auth-confirmation-modal';
import {FulfillmentDataService} from '../common/services/data-model/http/http-client-data/fulfillment-data/fulfillment-data.service';
import {KscopeFrameConfirmationModalComponent} from './claim-form/frame/kscope-frame-confirmation-modal/kscope-frame-confirmation-modal';
import {FrameSourceModalComponent} from './claim-form/frame/frame-source-modal/frame-source-modal.component';
import {VspIconSnackbarModule} from './icon-snackbar/vsp.icon-snackbar.module';
import {ReplacementFrameModalComponent} from './claim-form/frame/replacement-frame-modal/replacement-frame-modal.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FlexLayoutModule,
    SecureRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    VspIconSnackbarModule,
    MaterialsModule,
    NgSelectModule,
    MatCardModule,
    NgxCaptchaModule,
    MatPaginatorModule,
    NgOptimizedImage
  ],
  declarations: [
    SecureComponent,
    AuthorizationConfirmationComponent,
    ClaimFormComponent,
    CoverageSummaryComponent,
    CobCoverageComponent,
    MemberSearchComponent,
    PatientSelectionComponent,
    SecureHeaderComponent,
    BreadcrumbComponent,
    ValidSearchCombinationsComponent,
    DeleteAuthorizationModalComponent,
    ErrorWrapperComponent,
    InsuredComponent,
    AdditionalInformationComponent,
    ExamComponent,
    PatientComponent,
    LensComponent,
    FrameComponent,
    FrameSearchHelpModalComponent,
    PrescriptionComponent,
    FrameSearchResultsModalComponent,
    LabComponent,
    ClaimActionButtonsComponent,
    ServicesComponent,
    FSAExplanationModalComponent,
    ContactsComponent,
    FacilityBillingComponent,
    DateOfServiceComponent,
    EInsuranceComponent,
    GiftCertificateSubmissionComponent,
    HcpcCptCodesModalComponent,
    GiftCertificateSubmissionConfirmationComponent,
    UsdCurrencyPipe,
    MaskedDatePipe,
    ModifierCodesPipe,
    EnumToArrayPipe,
    SignedPaddedZeroesPipe,
    PaddedZeroesPipe,
    ServiceLineComponent,
    DiagnosisCodeComponent,
    ComponentMaskComponent,
    SoftAndHardEditBannerComponent,
    SignaturesComponent,
    SubmitClaimModalComponent,
    RetrieveSubmittedClaimConfirmationComponent,
    FrameDetailsDisplayComponent,
    ConfirmationModalComponent,
    DatePickerComponent,
    DiagnosisCodeListModalComponent,
    LoadingDropdownComponent,
    SimpleModalComponent,
    LeaveClaimFormWarningModalComponent,
    SuccessfulSubmitClaimModalComponent,
    PrintViewReportsComponent,
    ClaimNavigationComponent,
    ProvideFeedbackModalComponent,
    ClaimsTrackingComponent,
    AuthTrackingComponent,
    AuthDetailsModalComponent,
    AuthTrackingSearchResultsComponent,
    ClaimDetailsModalComponent,
    ClaimsTrackingSearchResultsComponent,
    ReissueAuthorizationModalComponent,
    ReplaceAuthConfirmationModalComponent,
    HcpcCptCodesDescriptionsModalComponent,
    KscopeFrameConfirmationModalComponent,
    FrameSourceModalComponent,
    ReplacementFrameModalComponent
  ],
  providers: [
    InputMaskService,
    MessageService,
    ViewStateService,
    UserSessionService,
    AppDataService,
    ConsumerDataService,
    ConsumerService,
    FulfillmentDataService,
    IsAuthenticatedRouteGuard,
    LeaveClaimFormWhenInEditModeGuard,
    ClaimsService,
    ExternalServiceLocationService,
    GiftCertificatesService,
    EligibilityService,
    DataMarshallService,
    ObjectService,
    CustomValidatorsService,
    DateUtility,
    AuthorizationsService,
    ClaimFormNavigationService,
    SupplierWebDataService,
    SupplierWebService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true },
  ]
})
export class SecureModule { }
