import { Injectable } from '@angular/core';
import * as InputMask from 'inputmask';
import {isNullOrUndefined} from '../../../utility';

@Injectable()
export class InputMaskService {


  private _InputMask: InputMask;
  private _phoneAlias: string;
  private _calendarAlias: string;
  private _zipCodeAlias: string;
  private _alphabeticalAlias: string;
  private _allAliases: string[];
  private _labAlias: string;


  constructor() {
    this._InputMask = InputMask;
    this._phoneAlias = '(999) 999-9999';
    this._zipCodeAlias = '99999';
    this._calendarAlias = 'mm/dd/yyyy';
    this._alphabeticalAlias = 'a';
    this._labAlias = '9999';
    this._allAliases = [];
    this._allAliases.push(this._phoneAlias);
    this._allAliases.push(this._calendarAlias);
    this._allAliases.push(this._zipCodeAlias);
    this._allAliases.push(this._labAlias);
  }


  /***** START - PUBLIC MEMBERS *****/
  get InputMask(): InputMask {
    return this._InputMask;
  }
  get phoneAlias(): string {
    return this._phoneAlias;
  }
  get calendarAlias(): string {
    return this._calendarAlias;
  }
  get zipCodeAlias(): string {
    return this._zipCodeAlias;
  }
  get alphabeticalAlias(): string {
    return this._alphabeticalAlias;
  }
  get labAlias(): string {
    return this._labAlias;
  }
  get allAliases(): string[] {
    return this._allAliases;
  }
  /***** END - PUBLIC MEMBERS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  createInputMask(element: HTMLElement, alias: string, config?: {
    showMaskOnHover?: boolean;
    showMaskOnFocus?: boolean;
    clearMaskOnLostFocus?: boolean;
    clearIncomplete?: boolean;
    autoUnmask?: boolean;
    placeholder?: string;
  }): void {
    const inputMaskConfig = Object.assign({
      showMaskOnHover: false,
      clearMaskOnLostFocus: true,
      clearIncomplete: false,
      autoUnmask: true
    }, config);
    // Set showMaskOnFocus if passed, do not alter behavior of previous masks not passing showMaskOnFocus
    if (config && !isNullOrUndefined(config.showMaskOnFocus)) {
      inputMaskConfig.showMaskOnFocus = config.showMaskOnFocus;
    }
    if (config && !isNullOrUndefined(config.placeholder)) {
      inputMaskConfig.placeholder = config.placeholder;
    }
    this.InputMask(alias, inputMaskConfig).mask(element);
  }

  unmaskValue(value: string, al: string): string {
    return this.InputMask.unmask(value, { alias: al});
  }
  /***** END - PUBLIC FUNCTIONS *****/

}
