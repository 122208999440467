<app-component-mask></app-component-mask>

<div id="{{id}}">
  <mat-card class="container-white-full-column">

    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings" class="sticky-card-header"
                     [ngClass]="{ 'disabled-card-header': additionalInformationForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="additional-information-header">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits"
                     [ngClass]="{ 'disabled-card-header': additionalInformationForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="additional-information-header-edits">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning"
                     [ngClass]="{ 'disabled-card-header': additionalInformationForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="additional-information-header-warning">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Additional Information Form -->
    <mat-card-content>
      <form fxLayout="column" [formGroup]="additionalInformationForm" novalidate>

        <!-- 19 -->
        <label id="additional-information-claim-label" class="nested-column-12 margin-top-30 margin-bottom-15">19.
          Additional Claim Information</label>
        <mat-form-field appearance="outline" fxFill class="nested-column-12">
          <input matInput formControlName="additionalClaimInformation" id="additional-information-claim-input"
                 type="text"
                 class="form-control" maxlength="{{additionalClaimInformationMaxLength}}"
                 (ngModelChange)="onAdditionalClaimInformationChange()"/>
        </mat-form-field>
        <app-error-wrapper class="nested-column-12"
                           [config]="errorWrapperConfig.additionalClaimInformation"></app-error-wrapper>

        <label id="additional-information-100-char-remaining-label"
               class="nested-column-12 margin-top-10 margin-bottom-15"
               fxLayoutAlign="end">{{numberOfCharactersLeftForAdditionalClaimInformation}} characters remaining</label>

        <!-- 10a -->
        <div class="alternating-background-color">
          <label id="additional-information-patients-condition-employment-label" class="nested-column-12 margin-top-15">
            10a. Is the patient's condition related to employment (current or previous)?</label>

          <mat-radio-group formControlName="conditionRelatedToEmployment"
                           id="additional-information-patients-condition-employment-group"
                           class="nested-column-12 margin-top-15  margin-bottom-30">
            <mat-radio-button #conditionRelatedToEmploymentYes value="{{formStateYesNo.Yes}}"
                              id="additional-information-patients-condition-employment-yes-toggle"
                              (keyup.enter)="onKeypressEventRadiobutton(conditionRelatedToEmploymentYes)">{{formStateYesNo.Yes}}</mat-radio-button>
            <mat-radio-button #conditionRelatedToEmploymentNo value="{{formStateYesNo.No}}" fxFlexOffset="30px"
                              id="additional-information-patients-condition-employment-no-toggle"
                              (keyup.enter)="onKeypressEventRadiobutton(conditionRelatedToEmploymentNo)">{{formStateYesNo.No}}</mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- 10b  -->
        <label id="additional-information-patients-condition-auto-accident-label" class="nested-column-12">
          10b. Is the patient's condition related to an auto accident?</label>

        <mat-radio-group formControlName="conditionRelatedToAutoAccident"
                         id="additional-information-patients-condition-auto-accident-group"
                         class="nested-column-12 margin-top-15"
                         (change)="onConditionRelatedToAutoAccidentChange($event)"
                         [ngClass]="additionalInformationForm.controls[formState.conditionRelatedToAutoAccident].value === formStateYesNo.Yes ? 'margin-bottom-15' : 'margin-bottom-30'">
          <mat-radio-button #conditionRelatedToAutoAccidentYes value="{{formStateYesNo.Yes}}"
                            id="additional-information-patients-condition-auto-accident-yes-toggle"
                            (keyup.enter)="onKeypressEventRadiobutton(conditionRelatedToAutoAccidentYes)">{{formStateYesNo.Yes}}</mat-radio-button>
          <mat-radio-button #conditionRelatedToAutoAccidentNo value="{{formStateYesNo.No}}" fxFlexOffset="30px"
                            id="additional-information-patients-condition-auto-accident-no-toggle"
                            (keyup.enter)="onKeypressEventRadiobutton(conditionRelatedToAutoAccidentNo)">{{formStateYesNo.No}}</mat-radio-button>
        </mat-radio-group>

        <div class="nested-column-3 margin-bottom-30 state-code-dropdown-format"
             *ngIf="additionalInformationForm.controls[formState.conditionRelatedToAutoAccident].value === formStateYesNo.Yes">
          <label fxLayout="row" id="additional-information-state-label"><span
            class="required-label-asterisk">* </span>In what state did this occur?</label>
          <ng-select fxLayout="row"
                     [items]="stateCodes"
                     #conditionRelatedToAutoAccidentAddressStateCode
                     placeholder=""
                     formControlName="conditionRelatedToAutoAccidentStateCode"
                     id="additional-information-state-occurrence-state-input"
                     class="required-form-field margin-top-15">
          </ng-select>
          <app-error-wrapper id="additional-information-state-occurrence-error-msg" [config]="errorWrapperConfig.conditionRelatedToAutoAccidentStateCode"></app-error-wrapper>
        </div>

        <!-- 10c  -->
        <div class="alternating-background-color">
          <label id="additional-information-patients-condition-another-accident-label" class="nested-column-12">
            10c. Is the patient's condition related to another accident?</label>

          <mat-radio-group formControlName="conditionRelatedToOtherAccident"
                           id="additional-information-patients-condition-another-accident-group"
                           class="nested-column-12 margin-top-15 margin-bottom-30">
            <mat-radio-button #conditionRelatedToOtherAccidentYes value="{{formStateYesNo.Yes}}"
                              id="additional-information-patients-condition-another-accident-yes-toggle"
                              (keyup.enter)="onKeypressEventRadiobutton(conditionRelatedToOtherAccidentYes)">{{formStateYesNo.Yes}}</mat-radio-button>
            <mat-radio-button #conditionRelatedToOtherAccidentNo value="{{formStateYesNo.No}}" fxFlexOffset="30px"
                              id="additional-information-patients-condition-another-accident-no-toggle"
                              (keyup.enter)="onKeypressEventRadiobutton(conditionRelatedToOtherAccidentNo)">{{formStateYesNo.No}}</mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- 10d  -->
        <label id="additional-information-claim-repair-replacement-label" class="nested-column-12">
          10d. Is this claim for repair or replacement?</label>

        <mat-radio-group formControlName="repairOrReplacement"
                         class="nested-column-12 margin-top-15 margin-bottom-30">
          <mat-radio-button #repairOrReplacementYes value="{{formStateYesNo.Yes}}"
                            id="additional-information-claim-repair-replacement-yes-toggle"
                            (keyup.enter)="onKeypressEventRadiobutton(repairOrReplacementYes)">{{formStateYesNo.Yes}}</mat-radio-button>
          <mat-radio-button #repairOrReplacementNo value="{{formStateYesNo.No}}" fxFlexOffset="30px"
                            id="additional-information-claim-repair-replacement-no-toggle"
                            (keyup.enter)="onKeypressEventRadiobutton(repairOrReplacementNo)">{{formStateYesNo.No}}</mat-radio-button>
        </mat-radio-group>

        <!-- 14  -->
        <div class="alternating-background-color">
          <label id="additional-information-condition-illness-injury-pregnancy-label" class="nested-column-12">
            14. Is the patient's condition related to illness, injury, or pregnancy?</label>

          <mat-radio-group formControlName="conditionRelatedIllnessInjuryPregnancy"
                           class="nested-column-12 margin-top-15"
                           (change)="onConditionRelatedIllnessInjuryPregnancyChange($event)"
                           [ngClass]="additionalInformationForm.controls[formState.conditionRelatedIllnessInjuryPregnancy].value === formStateYesNo.Yes ? 'margin-bottom-15' : 'margin-bottom-30'">
            <mat-radio-button #conditionRelatedIllnessInjuryPregnancyYes value="{{formStateYesNo.Yes}}"
                              id="additional-information-condition-illness-injury-pregnancy-yes-toggle"
                              (keyup.enter)="onKeypressEventRadiobutton(conditionRelatedIllnessInjuryPregnancyYes)">{{formStateYesNo.Yes}}</mat-radio-button>
            <mat-radio-button #conditionRelatedIllnessInjuryPregnancyNo value="{{formStateYesNo.No}}"
                              fxFlexOffset="30px"
                              id="additional-information-condition-illness-injury-pregnancy-no-toggle"
                              (keyup.enter)="onKeypressEventRadiobutton(conditionRelatedIllnessInjuryPregnancyNo)">{{formStateYesNo.No}}</mat-radio-button>
          </mat-radio-group>

          <div fxLayout="column" class="nested-column-3 margin-bottom-30"
               *ngIf="additionalInformationForm.controls[formState.conditionRelatedIllnessInjuryPregnancy].value=== formStateYesNo.Yes">
            <label id="additional-information-date-of-onset-label" class="margin-bottom-15"><span
              class="required-label-asterisk">* </span>Date of Onset</label>
            <app-date-picker id="additional-information-condition-related-illness-injury-pregnancy-date-input" class="required-mat-form-field"
                             [datePickerConfiguration]="datePickerConfigurationConditionRelatedIllnessInjuryPregnancyDate"></app-date-picker>
          </div>
        </div>

        <!-- 15  -->
        <label id="additional-information-similar-illness-label" class="nested-column-12">
          15. Has the patient had the same or similar illness?</label>

        <mat-radio-group formControlName="similarIllness"
                         class="nested-column-12 margin-top-15"
                         (change)="onSimilarIllnessChange($event)"
                         [ngClass]="additionalInformationForm.controls[formState.similarIllness].value === formStateYesNo.Yes ? 'margin-bottom-15' : 'margin-bottom-30'">
          <mat-radio-button #similarIllnessYes value="{{formStateYesNo.Yes}}"
                            id="additional-information-similar-illness-yes-toggle"
                            (keyup.enter)="onKeypressEventRadiobutton(similarIllnessYes)">{{formStateYesNo.Yes}}</mat-radio-button>
          <mat-radio-button #similarIllnessNo value="{{formStateYesNo.No}}" fxFlexOffset="30px"
                            id="additional-information-similar-illness-no-toggle"
                            (keyup.enter)="onKeypressEventRadiobutton(similarIllnessNo)">{{formStateYesNo.No}}</mat-radio-button>
        </mat-radio-group>

        <div fxLayout="column" class="nested-column-3 margin-bottom-30"
             *ngIf="additionalInformationForm.controls[formState.similarIllness].value === formStateYesNo.Yes">
          <label id="additional-information-similar-illness-date-of-onset-label" class="margin-bottom-15"><span
            class="required-label-asterisk">* </span>Date of Onset</label>
          <app-date-picker id="additional-information-similar-illness-date-input" class="required-mat-form-field"
                           [datePickerConfiguration]="datePickerConfigurationSimilarIllnessDate"></app-date-picker>
        </div>

        <!-- 16  -->
        <div class="alternating-background-color">
          <label id="additional-information-patient-unable-to-work-label" class="nested-column-12">
            16. Was the patient unable to work in their current occupation?</label>

          <mat-radio-group formControlName="patientUnableToWork"
                           class="nested-column-12 margin-top-15"
                           (change)="onPatientUnableToWorkChange($event)"
                           [ngClass]="additionalInformationForm.controls[formState.patientUnableToWork].value === formStateYesNo.Yes ? 'margin-bottom-15' : 'margin-bottom-30'">
            <mat-radio-button #patientUnableToWorkYes value="{{formStateYesNo.Yes}}"
                              id="additional-information-patient-unable-to-work-yes-toggle"
                              (keyup.enter)="onKeypressEventRadiobutton(patientUnableToWorkYes)">{{formStateYesNo.Yes}}</mat-radio-button>
            <mat-radio-button #patientUnableToWorkNo value="{{formStateYesNo.No}}" fxFlexOffset="30px"
                              id="additional-information-patient-unable-to-work-no-toggle"
                              (keyup.enter)="onKeypressEventRadiobutton(patientUnableToWorkNo)">{{formStateYesNo.No}}</mat-radio-button>
          </mat-radio-group>

          <div *ngIf="additionalInformationForm.controls[formState.patientUnableToWork].value === formStateYesNo.Yes">
            <label id="additional-information-dates-unable-to-work-label" class="nested-column-12">Dates Unable to
              Work</label>

            <div fxLayout="row" class="margin-top-15">
              <div fxLayout="column" class="nested-column-3 margin-bottom-30">
                <label id="additional-information-from-label" class="margin-bottom-15"><span
                  class="required-label-asterisk">* </span>From</label>
                <app-date-picker id="additional-information-patient-unable-to-work-from-date-input" class="required-mat-form-field"
                                 [datePickerConfiguration]="datePickerConfigurationPatientUnableToWorkDateFrom"></app-date-picker>
              </div>
              <div fxLayout="column" class="nested-column-3 margin-bottom-30">
                <label id="additional-information-to-label" class="margin-bottom-15"><span
                  class="required-label-asterisk">* </span>To</label>
                <app-date-picker id="additional-information-patient-unable-to-work-to-date-input" class="required-mat-form-field"
                                 [datePickerConfiguration]="datePickerConfigurationPatientUnableToWorkDateTo"></app-date-picker>
              </div>
            </div>
          </div>
        </div>

        <!-- 17  -->
        <label id="additional-information-referred-by-physician-label" class="nested-column-12">
          17. Was the patient referred to you by another physician?</label>

        <mat-radio-group formControlName="patientReferredByPhysician"
                         class="nested-column-12 margin-top-15"
                         (change)="onPatientReferredByPhysician($event)"
                         [ngClass]="additionalInformationForm.controls[formState.patientReferredByPhysician].value === formStateYesNo.Yes ? 'margin-bottom-15' : 'margin-bottom-30'">
          <mat-radio-button #patientReferredByPhysicianYes value="{{formStateYesNo.Yes}}"
                            id="additional-information-referred-by-physician-yes-toggle"
                            (keyup.enter)="onKeypressEventRadiobutton(patientReferredByPhysicianYes)">{{formStateYesNo.Yes}}</mat-radio-button>
          <mat-radio-button #patientReferredByPhysicianNo value="{{formStateYesNo.No}}" fxFlexOffset="30px"
                            id="additional-information-referred-by-physician-no-toggle"
                            (keyup.enter)="onKeypressEventRadiobutton(patientReferredByPhysicianNo)">{{formStateYesNo.No}}</mat-radio-button>
        </mat-radio-group>

        <div
          *ngIf="additionalInformationForm.controls[formState.patientReferredByPhysician].value === formStateYesNo.Yes">
          <label id="additional-information-referring-provider-name-label" class="nested-column-12">17a. Referring Provider Name</label>

          <div fxLayout="row" class="margin-top-15">
            <div fxLayout="column" class="nested-column-3 margin-bottom-30">
              <label id="additional-information-first-name-label" class="margin-bottom-15">
                <span class="required-label-asterisk">* </span>First Name</label>
              <mat-form-field appearance="outline" class="required-mat-form-field">
                <input matInput type="text" formControlName="referringProviderFirstName"
                       id="additional-information-first-name-input"
                       autocomplete="off" placeholder="First Name" maxlength="20"/>
              </mat-form-field>
              <app-error-wrapper id="additional-information-first-name-error-msg"
                                 [config]="errorWrapperConfig.referringProviderFirstName"></app-error-wrapper>
            </div>

            <div fxLayout="column" class="nested-column-1 margin-bottom-30">
              <label id="additional-information-middle-initial-label" class="margin-bottom-15">MI</label>
              <mat-form-field appearance="outline">
                <input matInput type="text" formControlName="referringProviderMiddleInitial"
                       id="additional-information-middle-initial-input"
                       autocomplete="off" maxlength="1" placeholder="MI"/>
              </mat-form-field>
              <app-error-wrapper id="additional-information-middle-initial-error-msg"
                                 [config]="errorWrapperConfig.referringProviderMiddleInitial"></app-error-wrapper>
            </div>

            <div fxLayout="column" class="nested-column-3 margin-bottom-30">
              <label id="additional-information-last-name-label" class="margin-bottom-15">
                <span class="required-label-asterisk">* </span>Last Name</label>
              <mat-form-field appearance="outline" class="required-mat-form-field">
                <input matInput type="text" formControlName="referringProviderLastName"
                       id="additional-information-last-name-input"
                       autocomplete="off" placeholder="Last Name" maxlength="30"/>
              </mat-form-field>
              <app-error-wrapper id="additional-information-last-name-error-msg"
                                 [config]="errorWrapperConfig.referringProviderLastName"></app-error-wrapper>
            </div>
          </div>

          <div fxLayout="column" class="nested-column-3 margin-bottom-30"
               *ngIf="!isEmpty(additionalInformationForm.controls['referringProviderFirstName'].value) && !isEmpty(additionalInformationForm.controls['referringProviderLastName'].value)">
            <label id="additional-information-referring-provider-npi-label" class="margin-bottom-15"><span
              class="required-label-asterisk">* </span>17b. Referring Provider NPI</label>
            <mat-form-field appearance="outline" class="required-mat-form-field">
              <input matInput type="text" formControlName="referringProviderNpi"
                     id="additional-information-referring-provider-npi-input"
                     minlength="10" maxlength="10" autocomplete="off"/>
            </mat-form-field>
            <app-error-wrapper
              id="additional-information-referring-provider-npi-error-msg"
              [config]="errorWrapperConfig.referringProviderNpi"></app-error-wrapper>
            <app-error-wrapper
              id="additional-information-referring-provider-npi-value-error-msg"
              [config]="errorWrapperConfig.referringProviderNpiValue"></app-error-wrapper>
            <app-error-wrapper
              id="additional-information-referring-provider-npi-length-error-msg"
              [hidden]="additionalInformationForm.get('referringProviderNpi').hasError(ERROR_TYPES.Pattern)"
              [config]="errorWrapperConfig.referringProviderNpiLength"></app-error-wrapper>
          </div>
        </div>

        <!-- 18  -->
        <div class="alternating-background-color">
          <label id="additional-information-patient-hospitalized-label" class="nested-column-12"
                 [ngClass]="{'print-page-break': splitPrintedForm}">18. Was the patient hospitalized?</label>

          <mat-radio-group formControlName="patientHospitalized"
                           class="nested-column-12 margin-top-15"
                           (change)="onPatientHospitalizedChange($event)"
                           [ngClass]="additionalInformationForm.controls[formState.patientHospitalized].value === formStateYesNo.Yes ? 'margin-bottom-15' : 'margin-bottom-30'">
            <mat-radio-button #patientHospitalizedYes value="{{formStateYesNo.Yes}}"
                              id="additional-information-patient-hospitalized-yes-toggle"
                              (keyup.enter)="onKeypressEventRadiobutton(patientHospitalizedYes)">{{formStateYesNo.Yes}}</mat-radio-button>
            <mat-radio-button #patientHospitalizedNo value="{{formStateYesNo.No}}" fxFlexOffset="30px"
                              id="additional-information-patient-hospitalized-no-toggle"
                              (keyup.enter)="onKeypressEventRadiobutton(patientHospitalizedNo)">{{formStateYesNo.No}}</mat-radio-button>
          </mat-radio-group>

          <div *ngIf="additionalInformationForm.controls[formState.patientHospitalized].value === formStateYesNo.Yes">
            <label id="additional-information-hospitalization-dates-label" class="nested-column-12">Hospitalization
              Dates</label>

            <div fxLayout="row" class="margin-top-15">
              <div fxLayout="column" class="nested-column-3 margin-bottom-30">
                <label id="additional-information-hospitalization-from-label" class="margin-bottom-15"><span
                  class="required-label-asterisk">* </span>From</label>
                <app-date-picker id="additional-information-patient-hospitalized-from-date-input" class="required-mat-form-field"
                                 [datePickerConfiguration]="datePickerConfigurationPatientHospitalizedDateFrom"></app-date-picker>
              </div>
              <div fxLayout="column" class="nested-column-3 margin-bottom-30">
                <label id="additional-information-hospitalization-to-label" class="margin-bottom-15"><span
                  class="required-label-asterisk">* </span>To</label>
                <app-date-picker id="additional-information-patient-hospitalized-to-date-input" class="required-mat-form-field"
                                 [datePickerConfiguration]="datePickerConfigurationPatientHospitalizedDateTo"></app-date-picker>
              </div>
            </div>
          </div>
        </div>

        <!-- 22 -->
        <label id="additional-information-resubmission-label" class="nested-column-12">
          22. Is this a resubmission?</label>

        <mat-radio-group formControlName="resubmission"
                         class="nested-column-12 margin-top-15"
                         (change)="onResubmissionChange($event)"
                         [ngClass]="additionalInformationForm.controls[formState.resubmission].value === formStateYesNo.Yes ? 'margin-bottom-15' : 'margin-bottom-30'">
          <mat-radio-button #resubmissionYes value="{{formStateYesNo.Yes}}"
                            id="additional-information-resubmission-yes-toggle"
                            (keyup.enter)="onKeypressEventRadiobutton(resubmissionYes)">{{formStateYesNo.Yes}}</mat-radio-button>
          <mat-radio-button #resubmissionNo value="{{formStateYesNo.No}}" fxFlexOffset="30px"
                            id="additional-information-resubmission-no-toggle"
                            (keyup.enter)="onKeypressEventRadiobutton(resubmissionNo)">{{formStateYesNo.No}}</mat-radio-button>
        </mat-radio-group>

        <div fxLayout="row"
             *ngIf="additionalInformationForm.controls[formState.resubmission].value === formStateYesNo.Yes">
          <div fxLayout="column" class="nested-column-3 margin-bottom-30">
            <label id="additional-information-original-ref-num-label" class="margin-bottom-15"><span
              class="required-label-asterisk">* </span>Original
              Ref. No.</label>
            <mat-form-field appearance="outline" class="required-mat-form-field">
              <input matInput id="additional-information-resubmission-original-ref-num-input"
                     maxlength="15"
                     formControlName="resubmissionOriginalRefNum" autocomplete="off"/>
            </mat-form-field>
            <app-error-wrapper id="additional-information-resubmission-original-ref-num-error-msg"
                               [config]="errorWrapperConfig.resubmissionOriginalRefNum"></app-error-wrapper>
          </div>

          <div fxLayout="column" class="nested-column-3 margin-bottom-30">
            <label id="additional-information-code-label" class="margin-bottom-15">Code</label>
            <mat-form-field appearance="outline">
              <input matInput id="additional-information-code-input" maxlength="15"
                     formControlName="resubmissionCode"
                     autocomplete="off"/>
            </mat-form-field>
            <app-error-wrapper id="additional-information-code-error-msg"
                               [config]="errorWrapperConfig.resubmissionCode"></app-error-wrapper>
          </div>
        </div>

        <!-- 23 -->
        <div class="alternating-background-color">
          <label id="additional-information-service-verification-label" class="nested-column-12">
            23. Do you have a Service Verification?</label>

          <mat-radio-group formControlName="serviceVerification"
                           class="nested-column-12 margin-top-15"
                           (change)="onServiceVerificationChange($event)"
                           [ngClass]="additionalInformationForm.controls[formState.serviceVerification].value === formStateYesNo.Yes ? 'margin-bottom-15' : 'margin-bottom-30'">
            <mat-radio-button #serviceVerificationYes value="{{formStateYesNo.Yes}}"
                              id="additional-information-service-verification-yes-toggle"
                              (keyup.enter)="onKeypressEventRadiobutton(serviceVerificationYes)">{{formStateYesNo.Yes}}</mat-radio-button>
            <mat-radio-button #serviceVerificationNo value="{{formStateYesNo.No}}" fxFlexOffset="30px"
                              id="additional-information-service-verification-no-toggle"
                              (keyup.enter)="onKeypressEventRadiobutton(serviceVerificationNo)">{{formStateYesNo.No}}</mat-radio-button>
          </mat-radio-group>

          <div fxLayout="column" class="nested-column-3 margin-bottom-30"
               *ngIf="additionalInformationForm.controls[formState.serviceVerification].value ===formStateYesNo.Yes">
            <label id="additional-information-service-verification-input-label" class="margin-bottom-15">Service
              Verification</label>
            <mat-form-field appearance="outline">
              <input id="additional-information-service-verification-input" matInput type="text"
                     formControlName="serviceVerificationValue" class="form-control
                full-width"
                     maxlength="7" autocomplete="off"/>
            </mat-form-field>
            <app-error-wrapper id="additional-information-service-verification-error-msg"
                               [config]="errorWrapperConfig.serviceVerificationValue"></app-error-wrapper>
          </div>
        </div>

      </form>
    </mat-card-content>
  </mat-card>
</div>
