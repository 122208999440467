import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-frame-search-help-modal',
  templateUrl: './frame-search-help-modal.component.html',
  styleUrls: ['./frame-search-help-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FrameSearchHelpModalComponent {

  constructor(
    public dialogRef: MatDialogRef<FrameSearchHelpModalComponent>,
  ) { }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }

}
