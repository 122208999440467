import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewRef,
  SimpleChanges,
  AfterContentInit, OnDestroy, OnChanges
} from '@angular/core';
import {Frame, FrameAPIResponse, FrameDataModel, FrameMaterials, Shapes, Suppliers} from 'src/app/models/frame';
import {FormBuilder, FormGroup, Validators, FormArray, FormControl, ValidatorFn} from '@angular/forms';
import { ApplicationConstants } from '../../../../common/constants/application.constants';
import { CustomValidatorsService } from '../../../../common/services/support/custom-validators/custom-validators.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent, ConfirmationModalOptions } from '../../../../common/components/confirmation-modal/confirmation-modal.component';
import { FrameConstants } from '../frame.constants';
import {
  isNullOrUndefined,
  isStringNullUndefinedOrEmpty,
  isStringNullUndefinedOrEmptyWithTrim,
  isValueZero,
  twoDecimalPlaceFormat
} from 'src/app/common/utility';
import { Subject} from 'rxjs';
import {ViewStateService} from '../../../../common/services/view-state/view-state.service';
import {ClaimsService} from '../../../../common/services/data-model/app/claims/claims.service';
import {Claim} from '../../../../models/claim';
import {FramesService} from '../../../../common/services/data-model/app/frames/frames.service';
import {ClaimFormNavigationService} from '../../../../common/services/support/claim-form-navigation/claim-form-navigation.service';
import {FulfillmentService} from '../../../../common/services/data-model/app/fulfillment/fulfillment.service';
import {UserSessionService} from '../../../../common/services/support/user-session/user-session.service';
import {
  LoadingModalComponent,
  LoadingModalOptions
} from '../../../../common/components/loading-modal/loading-modal/loading-modal.component';

@Component({
  selector: 'app-frame-details-display',
  templateUrl: './frame-details-display.component.html',
  styleUrls: ['./frame-details-display.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FrameDetailsDisplayComponent implements OnInit, OnDestroy, AfterContentInit, OnChanges {

  private _frame: Frame;
  private _inEditMode = false;
  private _supplier: string = null;
  private destroyObservables$: Subject<void> = new Subject();

  editFrame: Frame;
  frameDetailsDisplayForm: FormGroup;
  showForm = true;
  wholeSaleFrameCostRequired = false;
  twoDecimalPlaceFormat = twoDecimalPlaceFormat;
  suppliers = Suppliers;

  selectOptions = {
    frameMaterials: FrameConstants.frameMaterials,
    shapes: FrameConstants.shapes
  };

  componentMessaging = {
    tableHeaderText: 'Confirm frame details and <strong>Add to Lab Order</strong> or select&nbsp;<strong>Edit Manually</strong>&nbsp;to make necessary changes.',
    btnAddToOrder: 'Add to Lab Order',
    btnAddFrame: 'Add Frame',
    btnRemove: 'Remove Frame',
    btnEdit: 'Edit Manually',
    btnSave: 'Save Changes',
    btnReset: 'Reset This Form',
    noImage: 'IMAGE NOT AVAILABLE',
    imageDisclaimer: 'Selected color may not match displayed image',
    requiredFieldsError: 'Required Data must be entered',
    frameEditTooltip: {
      temple: 'Valid temple measurements are between 0-999 mm or "std".',
      eyeSize: 'Valid Eye Size measurements are typically 32-78 mm. Entries accepted between 0-999 mm.',
      b: 'Valid B measurements are typically 18-69 mm. Entries accepted between 0-99.9 mm.',
      ed: 'Valid ED measurements are typically 20-77 mm. Entries accepted between 0-99.9 mm.',
      dbl: 'Valid DBL measurements are typically 6-32 mm. Entries accepted between 0-99.9 mm.',
      wholeSalePrice: 'Please enter a numeric amount greater than 1.'
    }
  };


  // #region **** PUBLIC PROPERTIES ****/
  @Input()
  set frame(frame: Frame) {

    let frameCopy = null;

    // We need to copy frame and truncate props that have data beyond max length.. otherwise form validity will be 'false'
    if (!isNullOrUndefined(frame)) {
      frameCopy = JSON.parse(JSON.stringify(frame));
      frameCopy.manufacturer  = this.truncateString(frameCopy.manufacturer, 25);
      frameCopy.color         = this.truncateString(frameCopy.color, 20);
      frameCopy.collection    = this.truncateString(frameCopy.collection, 25);
      frameCopy.model         = this.truncateString(frameCopy.model, 25);
      frameCopy.wholeSalePrice = (!isStringNullUndefinedOrEmpty(frameCopy.wholeSalePrice)) ? parseFloat(frame.wholeSalePrice).toFixed(2) : '0.00';
    }

    this._frame = frameCopy;
    this.editFrame = frameCopy;

    // re-build form here so the values will change even if the form is in edit mode when selecting a new frame.
    this.buildForm();
  }

  get frame() {
    return this._frame;
  }

  @Input()
  set supplier(supplier: string) {
    this._supplier = supplier;
  }

  get supplier() {
    return this._supplier;
  }

  @Input() addedToOrder: boolean = false;
  @Input() isFrameSearchApiOffline: boolean = false;
  @Input() allowFullFrameDetailEdit: boolean = false;
  @Input() startFrameDetailsComponenetInEditMode: boolean = false;
  @Input() isOnFrameResultsModal: boolean = false;
  @Input() isFrameCardDisabled: boolean = false;

  @Output() addToOrder: EventEmitter<Frame> = new EventEmitter<Frame>();
  @Output() saveAfterEdit: EventEmitter<Frame> = new EventEmitter<Frame>();
  @Output() clear: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isEditing: EventEmitter<boolean> = new EventEmitter<boolean>();

  set inEditMode(value) {
    this._inEditMode = value;
    this.isEditing.emit(value);
  }

  get inEditMode(): boolean {
    return this._inEditMode;
  }

  get formIsValid(): boolean {
    return !this.frameDetailsDisplayForm.invalid;
  }

  // #endregion

  constructor(
    private formBuilder: FormBuilder,
    private customValidators: CustomValidatorsService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private viewStateService: ViewStateService,
    private claimService: ClaimsService,
    private framesService: FramesService,
    private claimFormNavigationService: ClaimFormNavigationService,
    private fulfillmentService: FulfillmentService,
    private userSessionService: UserSessionService
  ) { }

  // #region **** COMPONENT LIFECYCLE EVENTS  ****/

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (!isNullOrUndefined(this.frameDetailsDisplayForm)) {

      if (changes.supplier && (changes.supplier.currentValue === Suppliers.LENS_ONLY || changes.supplier.currentValue === Suppliers.PATIENT) && !isValueZero(this.editFrame.wholeSalePrice)) {
        this.editFrame.wholeSalePrice = '0.00';
        this.frameDetailsDisplayForm.get('wholeSalePrice').setValue(this.editFrame.wholeSalePrice);
        const activeClaim: Claim  = this.claimService.getActiveClaim();
        activeClaim.labOrderInformation.frame.options[0].wholeSalePrice = (!isStringNullUndefinedOrEmpty(this.editFrame.wholeSalePrice)) ? parseFloat(this.editFrame.wholeSalePrice) : undefined;
        this.claimService.setActiveClaim(activeClaim, ApplicationConstants.componentIDs.frameDetails);
      }

      this.setupWholesaleCostValidators();
    }
    if (this.isFrameCardDisabled) {
      Object.keys(this.frameDetailsDisplayForm.controls).forEach(field => {
        const control = this.frameDetailsDisplayForm.get(field);
        if (control instanceof FormControl) {
          control.disable();
        }
      });
    } else {
      this.frameDetailsDisplayForm.enable();
    }
  }

  ngAfterContentInit() {
    this.initializeFormState();
    this.setupWholesaleCostValidators();
    this.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroyObservables$.unsubscribe();
    this.viewStateService.setManualEntryState(false);
  }

  /***** END - EVENT HANDLERS *****/


  // #endregion

  // #region **** PRIVATE METHODS ****/
  private buildForm(): void {
    this.editFrame = { ...this.frame };
    this.editFrame.material = (this.editFrame.material ? (FrameConstants.frameMaterials.filter(element => element.label === this.editFrame.material)[0] ? this.editFrame.material : FrameConstants.frameMaterials.filter(element => element.value === FrameMaterials.ZYL)[0].label) : undefined);
    this.editFrame.shape = (this.editFrame.shape ? (FrameConstants.shapes.filter(element => element.label === this.editFrame.shape)[0] ? this.editFrame.shape : FrameConstants.shapes.filter(element => element.value === Shapes.SQUARE)[0].label) : undefined);
    this.frameDetailsDisplayForm = this.formBuilder.group({
      manufacturer: [
        this.editFrame.manufacturer
      ],
      collection: [
        this.editFrame.collection
      ],
      color: [
        this.editFrame.color, [
          Validators.required,
          Validators.pattern(/^[ A-Za-z0-9.&()#+/-]*$/)
        ]
      ],
      eyeSize: [
        this.editFrame.eyesize, [
          Validators.required,
          Validators.min(0),
          Validators.max(999),
          Validators.pattern(/^\d{1,3}$/)

        ]
      ],
      temple: [
        this.editFrame.temple, [
          Validators.required,
          Validators.min(0),
          Validators.max(999),
          Validators.pattern(/(^\d{1,3}$)|(^std$)/)
        ]
      ],
      b: [
        this.editFrame.b, [
          this.customValidators.overZeroUnder100UpToOneDecimalPlaceValidator,
        ]
      ],
      model: [
        this.editFrame.model
      ],
      ed: [
        this.editFrame.ed, [
          this.customValidators.overZeroUnder100UpToOneDecimalPlaceValidator,
        ]
      ],
      materialType: [
        this.editFrame.material, [
          Validators.required
        ]
      ],
      dbl: [
        this.editFrame.dbl, [
          Validators.required,
          this.customValidators.overZeroUnder100UpToOneDecimalPlaceValidator,
        ]
      ],
      shape: [
        this.editFrame.shape
      ],
      upc: [
        this.editFrame.upc,
      ],
      sku: [
        this.editFrame.sku
      ],
      wholeSalePrice: [
        this.editFrame.wholeSalePrice
      ],
    });
  }

  private setupWholesaleCostValidators() {

    if (this.frameDetailsDisplayForm) {
      if (this.supplier === Suppliers.LAB || this.supplier === Suppliers.DOCTOR) {
        this.frameDetailsDisplayForm.get('wholeSalePrice').setValidators([
          Validators.required,
          Validators.pattern(ApplicationConstants.wholesaleFrameCostRegex) // Numbers and a decimal only (great than 1)
        ]);
        this.wholeSaleFrameCostRequired = true;
        this.keepInEditModeIfWholesaleFrameInvalid();
        if (!this.startFrameDetailsComponenetInEditMode) {
          this.setValidatorsAsTouched(this.frameDetailsDisplayForm);
        }
      } else {
        this.frameDetailsDisplayForm.get('wholeSalePrice').clearValidators();
        this.wholeSaleFrameCostRequired = false;
      }

      this.frameDetailsDisplayForm.get('wholeSalePrice').updateValueAndValidity();

    }
  }

  private setValidatorsAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched();
    for (const i in group.controls) {
      if (group.controls[i] instanceof FormControl) {
        group.controls[i].markAsTouched();
      } else {
        this.setValidatorsAsTouched(group.controls[i]);
      }
    }
  }

  private keepInEditModeIfWholesaleFrameInvalid() {
    // We do this in a setTimeout to prevent the "Expression has changed after it was checked" error
    setTimeout(() => {
      if (this.frameDetailsDisplayForm.controls.wholeSalePrice.invalid) {
        this.inEditMode = true;
      } else {
        this.inEditMode = false;
      }
    });
  }

  private detectChanges() {
    setTimeout(() => {
      if (!isNullOrUndefined(this.cdRef) && !(this.cdRef as ViewRef).destroyed) {
        this.cdRef.detectChanges();
      }
    }, ApplicationConstants.userInteractionDebounceTime);
  }

  private truncateString(value: string, maxLength: number): string {
    if (!isNullOrUndefined(value)) {
      return (value.length > maxLength) ? value.substring(0, maxLength - length) : value;
    } else {
      return value;
    }
  }

  private initializeFormState(): void {
    this.inEditMode = this.startFrameDetailsComponenetInEditMode;
    if (this.isFrameSearchApiOffline || this.startFrameDetailsComponenetInEditMode) {
      this.allowFullFrameDetailEdit = true;
      this.frameDetailsDisplayForm.reset();
      this.frameDetailsDisplayForm.controls.manufacturer.setValidators(frameConditionalValidators.manufacturer);
      this.frameDetailsDisplayForm.controls.collection.setValidators(frameConditionalValidators.collection);
      this.frameDetailsDisplayForm.controls.model.setValidators(frameConditionalValidators.model);
      this.cdRef.detectChanges();
    }
  }

  private copyFormToObject(frame: Frame): void {

    frame.b                 = this.frameDetailsDisplayForm.get('b').value;
    frame.collection        = this.frameDetailsDisplayForm.get('collection').value;
    frame.color             = this.frameDetailsDisplayForm.get('color').value;
    frame.dbl               = this.frameDetailsDisplayForm.get('dbl').value;
    frame.ed                = this.frameDetailsDisplayForm.get('ed').value;
    frame.eyesize           = this.frameDetailsDisplayForm.get('eyeSize').value;
    frame.manufacturer      = this.frameDetailsDisplayForm.get('manufacturer').value;
    frame.material          = this.frameDetailsDisplayForm.get('materialType').value;
    frame.model             = this.frameDetailsDisplayForm.get('model').value;
    frame.shape             = this.frameDetailsDisplayForm.get('shape').value;
    frame.upc               = this.frameDetailsDisplayForm.get('upc').value;
    frame.sku               = this.frameDetailsDisplayForm.get('sku').value;
    frame.wholeSalePrice     = this.frameDetailsDisplayForm.get('wholeSalePrice').value;
    frame.temple            = this.frameDetailsDisplayForm.get('temple').value;
  }

  private copyObjectToForm(frame: Frame): void {

    this.frameDetailsDisplayForm.get('b').setValue(frame.b);
    this.frameDetailsDisplayForm.get('collection').setValue(frame.collection);
    this.frameDetailsDisplayForm.get('color').setValue(frame.color);
    this.frameDetailsDisplayForm.get('dbl').setValue(frame.dbl);
    this.frameDetailsDisplayForm.get('ed').setValue(frame.ed);
    this.frameDetailsDisplayForm.get('eyeSize').setValue(frame.eyesize);
    this.frameDetailsDisplayForm.get('manufacturer').setValue(frame.manufacturer);
    this.frameDetailsDisplayForm.get('materialType').setValue(frame.material);
    this.frameDetailsDisplayForm.get('model').setValue(frame.model);
    this.frameDetailsDisplayForm.get('shape').setValue(frame.shape);
    this.frameDetailsDisplayForm.get('upc').setValue(frame.upc);
    this.frameDetailsDisplayForm.get('sku').setValue(frame.sku);
    this.frameDetailsDisplayForm.get('wholeSalePrice').setValue(frame.wholeSalePrice);
    this.frameDetailsDisplayForm.get('temple').setValue(frame.temple);
  }


  private addToOrderAndClose(): void {
    // copy the changes back to the original frame object
    this.frame = { ...this.editFrame };

    // tell the parent we're done here.
    this.addToOrder.emit(this.frame);

    // Per team discussion we moved the getFrameDispositions call to fulfillment api to the 'Add to Lab Order' button click.
    if (this.userSessionService.isKaleyedoscopePractice && (!isNullOrUndefined(this.frame) && !isStringNullUndefinedOrEmptyWithTrim(this.frame.sku) && !isStringNullUndefinedOrEmptyWithTrim(this.frame.manufacturer))) {
      // Set the supplier from the active claim.
      this.fulfillmentService.selectedSupplier = this.supplier;
      // This was also added for for ECLAIM-60 to reset the fulfillment service to its original state.
      this.fulfillmentService.resetServiceValuesToOriginalState();
      // Needed to override the 'openDialog' utility function so that we can just close the one dialog.
      const loadingFrameDetailsDialog = this.dialog.open<LoadingModalComponent, LoadingModalOptions>(LoadingModalComponent,
        {
          data: {
            modalMessageText: 'Loading Frame Details'
          },
          width: '250px',
          panelClass: 'loadingModal',
          disableClose: true
        });
      // FulfillmentService will take care of the subscription. No need to subscribe here.
      this.fulfillmentService.getFrameDispositions(this.frame.sku, this.frame.manufacturer).subscribe((hasCallCompleted) => {
        if (hasCallCompleted) {
          loadingFrameDetailsDialog.close();
        }
      }, () => {
          // on observer error
          loadingFrameDetailsDialog.close();
        },
        () => {
          // Close dialog for LoadingModalComponent - Spinner
          loadingFrameDetailsDialog.close();
        });
    }
  }

  private saveFrameAfterEdit(): void {
    // Per ECLAIM-246 we want to check if the frame has been edited. if it has we want to clear out the UPC and SKU fields.
    const editedFrame: Frame = {...this.editFrame};
    if (!isNullOrUndefined(this.frame) &&
      (!isStringNullUndefinedOrEmptyWithTrim(this.frame.sku) || !isStringNullUndefinedOrEmptyWithTrim(this.frame.upc))
      && this.removeUpcAndSku(this.frame, editedFrame)) {
          editedFrame.upc = undefined;
          editedFrame.sku = undefined;
          // Per new requirements on ECLAIM-246 we also want to clear out the frame image if the SKU and UPC get cleared out.
          // We only want to execute this logic if the image is available. If it is already undefined the existing code will take care of it.
          if (!isNullOrUndefined(editedFrame) && !isNullOrUndefined(editedFrame.imageLink) && !isStringNullUndefinedOrEmptyWithTrim(editedFrame.imageLink.href)) {
            editedFrame.imageLink.href = undefined;
          }
    }
    // copy the changes back to the original frame object
    this.frame = editedFrame;
    this.saveAfterEdit.emit(this.frame);
  }

  public removeUpcAndSku(existingFrame: Frame, editedFrame: Frame): boolean {
   let removeUpcAndSku: boolean = false;
   // Top level check. Can be combined all together but for readability purposes we should keep at the top.
   if (!isNullOrUndefined(existingFrame) && !isNullOrUndefined(editedFrame)) {
     // Compare the field level values and see if they are different.
     if (
       // Frame 'color' comparison.
       (existingFrame.color || undefined) !== (editedFrame.color || undefined) ||
       // Frame 'temple' comparison.
       (existingFrame.temple || undefined) !== (editedFrame.temple || undefined) ||
       // Frame 'shape' comparison.
       (existingFrame.shape || undefined) !== (editedFrame.shape || undefined) ||
       // Frame 'material' comparison.
       (existingFrame.material || undefined) !== (editedFrame.material || undefined) ||
       // Frame 'eye size' comparison.
       (existingFrame.eyesize || undefined) !== (editedFrame.eyesize || undefined) ||
       // Frame 'B' comparison.
       (existingFrame.b || undefined) !== (editedFrame.b || undefined) ||
       // Frame 'ED' comparison.
       (existingFrame.ed || undefined) !== (editedFrame.ed || undefined) ||
       // Frame 'DBL' comparison.
       (existingFrame.dbl || undefined) !== (editedFrame.dbl || undefined)
     ) {
       removeUpcAndSku = true;
     }
   }
    return removeUpcAndSku;
  }

  private confirmAndRemove(): void {
    this.dialog.open<ConfirmationModalComponent, ConfirmationModalOptions>(ConfirmationModalComponent, {
      data: {
        modalMessageText: 'All the frame data is about to be cleared, do you want to continue?',
        modalHeaderText: 'Remove Frame'
      },
      width: '40vw',
      panelClass: 'eclaim-popup-modal',
      // prevent the modal from closing automatically if user accidentally clicks outside the modal boundary.
      disableClose: true
    }).afterClosed().subscribe((okClicked: boolean) => {
      if (okClicked) {
        this.frame = null;
        this.clear.emit(true);
      }
    });
  }

  private loadFrameImageUrl() {
    const originalClaim: Claim = this.claimService.getOriginalClaim();
    if (!isNullOrUndefined(originalClaim.labOrderInformation) && !isNullOrUndefined(originalClaim.labOrderInformation.frame)) {
      const frameDataModel: FrameDataModel = originalClaim.labOrderInformation.frame;
      if ((!isNullOrUndefined(frameDataModel.options) && !isNullOrUndefined(frameDataModel.options[0]) && isNullOrUndefined(frameDataModel.options[0].image))) {
        const upcIdentifier: string = frameDataModel.options[0].upc;
        const skuIdentifier: string = frameDataModel.options[0].sku;
        if (!isStringNullUndefinedOrEmpty(upcIdentifier) && !isStringNullUndefinedOrEmpty(skuIdentifier)) {
          this.framesService.loadFramesUsingUpcAndSkuParameters(upcIdentifier, skuIdentifier).subscribe((frameSearchResponseUsingUpcAndSkuParameters) => {
            this.setFrameImageFromFrameAPIResponse(frameSearchResponseUsingUpcAndSkuParameters);
          });
        } else if (!isStringNullUndefinedOrEmpty(upcIdentifier) || !isStringNullUndefinedOrEmpty(skuIdentifier)) {
          const queryParameter: string = !isStringNullUndefinedOrEmpty(upcIdentifier) ? upcIdentifier : skuIdentifier;
          this.framesService.loadFrames(queryParameter).subscribe((frameSearchResponse) => {
            this.setFrameImageFromFrameAPIResponse(frameSearchResponse);
          });
        }
      }
    }
  }

  private setFrameImageFromFrameAPIResponse(frameAPIResponse: FrameAPIResponse) {
    if (!isNullOrUndefined(frameAPIResponse) && frameAPIResponse.count === 1) {
      const retrievedFrameDataModel: Frame = frameAPIResponse.frames[0];
      const imageUrl: string = !isStringNullUndefinedOrEmpty(retrievedFrameDataModel.imageLink.href) ? retrievedFrameDataModel.imageLink.href : undefined;
      const currentFrame: Frame = this.frame;
      if (!isStringNullUndefinedOrEmpty(imageUrl) && !isNullOrUndefined(currentFrame)) {
        currentFrame.imageLink.href = imageUrl;
        currentFrame.imageLink.type = !isNullOrUndefined(currentFrame.imageLink.type) ? currentFrame.imageLink.type : undefined;
        currentFrame.imageLink.contextRootPlus = !isNullOrUndefined(currentFrame.imageLink.contextRootPlus) ? currentFrame.imageLink.contextRootPlus : undefined;
        currentFrame.imageLink.rel = !isNullOrUndefined(currentFrame.imageLink.rel) ? currentFrame.imageLink.rel : undefined;
        this.frame = currentFrame;
        this.addToOrder.emit(this.frame);
      }
    }
  }

// #endregion

// #region **** PUBLIC METHODS ****/
  onWholesaleCostBlur(): void {
    // Format dollar amounts
    twoDecimalPlaceFormat(this.frameDetailsDisplayForm.get('wholeSalePrice'));
    return;
  }

  addClick(): void {
    this.addToOrderAndClose();
  }

  removeClick(): void {
    this.confirmAndRemove();
  }

  editClick(): void {
    this.inEditMode = !this.inEditMode;
    this.copyObjectToForm(this.editFrame);
  }

  doneClick(): void {
    this.copyFormToObject(this.editFrame);
    this.inEditMode = false;
    if (this.isOnFrameResultsModal && this.allowFullFrameDetailEdit) {
      if (this.userSessionService.isKaleyedoscopePractice) {
        // This was also added for for ECLAIM-60 to reset the fulfillment service to its original state on a manual search.
        this.fulfillmentService.resetServiceValuesToOriginalState();
        // Added this flag for ECLAIM-60 so we can display the appropriate message on the frame component on a manual search where SKU is missing.
        this.fulfillmentService.onIsSkuMissingInFrame.next(true);
      }
      this.addToOrderAndClose();
    } else {
      this.saveFrameAfterEdit();
    }
  }

  resetClick(): void {
    if (this.isFrameSearchApiOffline) {
      if (this.editFrame.manufacturer) {
        // If we are already editing an existing object, on cancelClick, let's put the original
        // object back into the form ojbect (i.e. undo changes from a user perspective)
        this.copyObjectToForm(this.editFrame);
      } else {
        // Reset form but also hide & re-show to re-init all validtors and form status. THis is done as
        // follows because angular 6 doesn't have a clean why to reset the form, re-set all validators,
        // and make form pristine (non-submitted), etc.
        this.frameDetailsDisplayForm.reset();
        this.showForm = false;
        setTimeout(() => this.showForm = true);
      }
    } else {
      this.copyObjectToForm(this.editFrame);
    }
  }

  /*
  This method was added as a temporary fix to handle the situation where the image link is correctly built but we get and error trying to retrieve the image.
   */
  setBrokenImageDefaultValue() {
    this.frame.imageLink.href = this.componentMessaging.noImage;
  }

  getSelectedFrameImage(): string | null {
    if (this.claimFormNavigationService.initialClaimFormLoad) {
      this.loadFrameImageUrl();
      this.claimFormNavigationService.initialClaimFormLoad = false;
    }
    return (this.frame && this.frame.imageLink.href && this.frame.imageLink.href.toLowerCase().includes('catalog')) ? this.frame.imageLink.href : null;
  }

  // #endregion
}

export const frameConditionalValidators = {
  manufacturer: [Validators.required, Validators.pattern(/^[ A-Za-z0-9.',/&-]*$/)] as ValidatorFn[],
  collection: [Validators.required, Validators.pattern(/^[ A-Za-z0-9.:',/&-]*$/)] as ValidatorFn[],
  model: [Validators.required, Validators.pattern(/^[ A-Za-z0-9.',/&-]*$/)] as ValidatorFn[]
};
