import {ServiceLine, ApiFormattedServiceLine} from './serviceLine';
import {ServiceLocation, ApiFormattedServiceLocation} from './serviceLocation';
import {Membership, ApiFormattedMembership} from './membership';
import { UIFormattedDiagnosis } from './UIFormattedDiagnosis';
import { ContactLens } from './contactLens';
import { Signature, ApiFormattedSignature } from './signature';
import { Exam } from './exam';
import { OtherInsured } from './otherInsured';
import { Name } from './name';
import { Doctor } from './externalServiceLocation';
import {LabOrderInformation} from './labOrderInformation';
import { Link } from './link';

export interface PatientConditions {
  ageRelatedMacularDegenerationIndicator: boolean;
  arcusIndicator: boolean;
  prediabetesIndicator: boolean;
  diabetesIndicator: boolean;
  diabeticRetinopathyIndicator: boolean;
  glaucomaIndicator: boolean;
  highCholesterolIndicator: boolean;
  hypertensionIndicator: boolean;
  noConditionIndicator: boolean;
}

export interface ConditionRelatedTo {
  employment: boolean;
  autoAccident: boolean;
  autoAccidentStateCode: string;
  otherAccident: boolean;
}

export interface ValidationMessage {
  errorNumber ?: string;
  description: string;
  levelCode: string;
  fieldIds?: string[];
  acknowledged?: boolean;
}

export interface SoftAndHardValidationMessages {
  hardEditMessages ?: ValidationMessage[];
  unacknowledgedSoftEdits ?: ValidationMessage[];
}

export interface ApiFormattedClaims {
  patientEncounters: any[];
  totalElements: number;
}

export interface ApiFormattedClaim {
  action: string;
  additionalClaimInformationHCFABox19: string;
  claimSubmissionReceiptDcn: string;
  claimSubmissionReceiptId: string;
  claimSubmissionReceiptTimestamp: string;
  clientFsaIndicator?: string;
  cms1500Diagnoses?: UIFormattedDiagnosis[];
  cobAuthorizationNumber?: string;
  conditionRelatedTo: ConditionRelatedTo;
  contactLens: ContactLens;
  dateOfService?: string;
  doctor: Doctor;
  doctorAcceptAssignmentIndicator: boolean;
  doctorSignature: ApiFormattedSignature;
  exam: Exam;
  examType?: string;
  frameServiceIndicator: boolean;
  hospitalizationAdmitDate: string;
  hospitalizationReleaseDate: string;
  illnessInjuryOnsetDate: string;
  insuranceTypeCode: HealthCoverage;
  labOrderInformation?: LabOrderInformation;
  lastDoctorUpdatedDateTime: string;
  lastUpdateDateTime: string;
  lensServiceIndicator: boolean;
  medicaidResubmissionCode: string;
  member: ApiFormattedMembership;
  memberFsaAmount?: string;
  memberInsurancePlanProgramName?: string;
  memberPolicyGroupNumber?: string;
  memberSignature: ApiFormattedSignature;
  originalReferenceNumber: string;
  originatingSystemCode?: string;
  otherInsuranceIndicator?: boolean;
  otherInsured?: OtherInsured;
  patient: ApiFormattedMembership;
  patientAccountNumber: string;
  patientConditions: PatientConditions;
  patientEncounterValidationMessages?: ValidationMessage[];
  patientPaymentAmount: number;
  patientSignature: ApiFormattedSignature;
  primaryCarePhysicianFormIndicator: boolean;
  priorAuthorizationNumber: string;
  priorIllnessInjuryOnsetDate: string;
  productPackageName: string;
  providerNetworkId: string;
  providerOfficeBillingName: string;
  providerTaxIdType: string;
  referringDoctorName: Name;
  referringDoctorNPI: string;
  repairOrReplaceIndicator: boolean;
  serviceLines?: ApiFormattedServiceLine[];
  serviceLocation: ApiFormattedServiceLocation;
  status: string;
  trackingNumber: string;
  unableToWorkEndDate: string;
  unableToWorkStartDate: string;
  visionSavingsStatementIndicator: boolean;
  vsrIssueTimeStamp: string;
}

export interface Claim {
  action: string;
  additionalClaimInformationHCFABox19: string;
  claimSubmissionReceiptDcn: string;
  claimSubmissionReceiptId: string;
  claimSubmissionReceiptTimestamp: Date;
  clientFsaIndicator?: string;
  cms1500Diagnoses?: UIFormattedDiagnosis[];
  cobAuthorizationNumber?: string;
  conditionRelatedTo: ConditionRelatedTo;
  contactLens: ContactLens;
  dateOfService?: Date;
  doctor: Doctor;
  doctorAcceptAssignmentIndicator: boolean;
  doctorSignature: Signature;
  exam: Exam;
  examType?: string;
  frameServiceIndicator: boolean;
  hospitalizationAdmitDate: Date;
  hospitalizationReleaseDate: Date;
  illnessInjuryOnsetDate: Date;
  insuranceTypeCode: string;
  labOrderInformation?: LabOrderInformation;
  lastDoctorUpdatedDateTime: Date;
  lastUpdateDateTime: Date;
  lensServiceIndicator: boolean;
  medicaidResubmissionCode: string;
  member: Membership;
  memberFsaAmount?: number;
  memberInsurancePlanProgramName?: string;
  memberPolicyGroupNumber?: string;
  memberSignature: Signature;
  originalReferenceNumber: string;
  originatingSystemCode?: string;
  otherInsuranceIndicator?: boolean;
  otherInsured?: OtherInsured;
  patient: Membership;
  patientAccountNumber: string;
  patientConditions: PatientConditions;
  patientEncounterValidationMessages?: ValidationMessage[];
  patientPaymentAmount: number;
  patientSignature: Signature;
  primaryCarePhysicianFormIndicator: boolean;
  priorIllnessInjuryOnsetDate: Date;
  productPackageName: string;
  providerNetworkId: string;
  providerOfficeBillingName: string;
  providers: Link;
  providerTaxIdType: string;
  referringDoctorName: Name;
  referringDoctorNPI: string;
  repairOrReplaceIndicator: boolean;
  serviceLines?: ServiceLine[];
  serviceLocation: ServiceLocation;
  serviceVerificationValue: string;
  status: string;
  trackingNumber: string;
  unableToWorkEndDate: Date;
  unableToWorkStartDate: Date;
  visionSavingsStatementIndicator: boolean;
  vsrIssueTimeStamp: Date;
}

export enum HealthCoverage {
  MEDICARE = 'MEDICARE',
  MEDICAID = 'MEDICAID',
  TRICARE = 'TRICARE',
  CHAMPVA = 'CHAMPVA',
  GROUP_HEALTH_PLAN = 'GROUP_HEALTH_PLAN',
  FECA_BLK_LUNG = 'FECA_BLK_LUNG',
  OTHER = 'OTHER'
}

export enum ValidationMessageLevelCode {
  ERROR = 'E',
  INFO = 'I',
  WARNING = 'W' //TODO: This type of errors are not being returned currently, needed for future
}
