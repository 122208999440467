import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ErrorWrapperConfig } from '../../../common/components/error-wrapper/error-wrapper.component';
import { ContactsConstants } from './contacts.constants';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApplicationConstants, ErrorTypes } from '../../../common/constants/application.constants';
import { Subscription } from 'rxjs';
import { ComponentMaskComponent } from '../../../common/components/component-mask/component-mask.component';
import { ClaimFormItem } from '../../../common/classes/claim-form-item';
import {Claim, SoftAndHardValidationMessages, ValidationMessage} from '../../../models/claim';
import { ClaimsService } from '../../../common/services/data-model/app/claims/claims.service';
import {
  Brand,
  ContactLens,
  ContactLensManufacturer, ContactLensOperation, Manufacturer, MaterialType, Service, Reason, Modality
} from '../../../models/contactLens';
import { isNullOrUndefined, isStringNullUndefinedOrEmpty, setToUndefinedOrValue } from '../../../common/utility';
import { InputMaskService } from '../../../common/services/support/input-mask/input-mask.service';
import {MessageService} from '../../../common/services/support/message/message.service';
import {ContactsService} from '../../../common/services/data-model/app/contacts/contacts.service';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';
import {ScratchCoating} from '../../../models/lens';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsComponent extends ClaimFormItem implements OnInit, OnDestroy, AfterViewInit {


  /***** START - PRIVATE MEMBERS *****/
  private _originalClaim: Claim;
  private _activeClaim: Claim;
  private _isReasonRequired: boolean;
  private _isManufacturerRequired: boolean;
  private _isRequiredByManufacturer: boolean;
  private _knownConditionOptionSelected = false;
  private observableSubscriptions: Subscription[] = [];
  private _materialType: MaterialType[];
  private _service: Service[];
  private _reason: Reason[];
  private _manufacturer: Manufacturer[];
  private _brand: Brand[];
  private _modality: Modality[];
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;

  private mask = true;


  /***** END - PRIVATE MEMBERS *****/


  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private inputMaskService: InputMaskService,
    private claimService: ClaimsService,
    private changeDetector: ChangeDetectorRef,
    private contactsService: ContactsService,
    private messageService: MessageService,
    private claimEditService: ClaimEditService
  ) {
    super(injector);
  }
  /***** START - PUBLIC MEMBERS *****/
  id = ApplicationConstants.componentIDs.contacts;
  title = 'Contacts';
  contactsForm: FormGroup;
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;

  @ViewChild('numberOfBoxesHtmlRef', {read: ElementRef, static: true}) numberOfBoxesHtmlRef: ElementRef;

  // Form state/data variables
  errorWrapperConfig = {
    reason: new ErrorWrapperConfig()
  };
  _loadingMaterialTypes = false;
  _loadingServices = false;
  _loadingReasons = false;
  _loadingManufacturers = false;
  _loadingModalities = false;

  /***** START - PROPERTY ACCESSORS *****/
  get materialTypeFormValue(): string {
    return this.contactsForm.get(ContactsConstants.MATERIAL_TYPE).value as string;
  }

  get isReasonRequired(): boolean {
    return this._isReasonRequired;
  }

  set isReasonRequired(isRequired: boolean) {
    this._isReasonRequired = isRequired;
  }

  get isManufacturerRequired(): boolean {
    return this._isManufacturerRequired;
  }

  set isManufacturerRequired(isRequired: boolean) {
    this._isManufacturerRequired = isRequired;
  }

  get isRequiredByManufacturer(): boolean {
    return this._isRequiredByManufacturer;
  }

  set isRequiredByManufacturer(isRequired: boolean) {
    this._isRequiredByManufacturer = isRequired;
  }

  get knownConditionOptionSelected(): boolean {
    return this._knownConditionOptionSelected;
  }

  set knownConditionOptionSelected(newVal: boolean) {
    this._knownConditionOptionSelected = newVal;
    const hasValidators = this.contactsForm.controls.diabetesIndicator.validator !== null;
    if (this._knownConditionOptionSelected && !hasValidators) {
      this.contactsForm.controls.diabetesIndicator.setValidators([Validators.required]);
      this.contactsForm.controls.diabeticRetinopathyIndicator.setValidators([Validators.required]);
      this.contactsForm.controls.hypertensionIndicator.setValidators([Validators.required]);
      this.contactsForm.controls.highCholesterolIndicator.setValidators([Validators.required]);
      this.contactsForm.controls.patientConditionNone.setValidators([Validators.required]);
      this.contactsForm.updateValueAndValidity();
    } else if (!this._knownConditionOptionSelected && hasValidators) {
      this.contactsForm.controls.diabetesIndicator.clearValidators();
      this.contactsForm.controls.diabeticRetinopathyIndicator.clearValidators();
      this.contactsForm.controls.hypertensionIndicator.clearValidators();
      this.contactsForm.controls.highCholesterolIndicator.clearValidators();
      this.contactsForm.controls.patientConditionNone.clearValidators();
      this.contactsForm.updateValueAndValidity();
    }
  }

  get originalClaim(): Claim {
    return this._originalClaim;
  }

  set originalClaim(value: Claim) {
    this._originalClaim = value;
  }

  get activeClaim(): Claim {
    return this._activeClaim;
  }

  set activeClaim(value: Claim) {
    this._activeClaim = value;
  }

  get materialType(): MaterialType[] {
    return this._materialType;
  }

  set materialType (items: MaterialType[]) {
    this._materialType = items;
  }

  get loadingMaterialTypes(): boolean {
    return this._loadingMaterialTypes;
  }

  set loadingMaterialTypes(isLoading: boolean) {
    this._loadingMaterialTypes = isLoading;
  }

  get service(): Service[] {
    return this._service;
  }

  set service(items: Service[]) {
    this._service = items;
  }

  get loadingServices(): boolean {
    return this._loadingServices;
  }

  set loadingServices(isLoading: boolean) {
    this._loadingServices = isLoading;
  }

  get reason(): Reason[] {
    return this._reason;
  }

  set reason(reasons: Reason[]) {
    this._reason = reasons;
  }

  get loadingReasons(): boolean {
    return this._loadingReasons;
  }

  set loadingReasons(isLoading: boolean) {
    this._loadingReasons = isLoading;
  }

  get manufacturer(): Manufacturer[] {
    return this._manufacturer;
  }

  set manufacturer(manufacturers: Manufacturer[]) {
    this._manufacturer = manufacturers;
  }

  get modality(): Modality[] {
    return this._modality;
  }

  set modality(modalities: Modality[]) {
    this._modality = modalities;
  }

  get loadingManufacturers(): boolean {
    return this._loadingManufacturers;
  }

  set loadingManufacturers(isLoading: boolean) {
    this._loadingManufacturers = isLoading;
  }

  get brands(): Brand[] {
    return this._brand;
  }

  set brands(brands: Brand[]) {
    this._brand = brands;
  }

  get loadingModalities(): boolean {
    return this._loadingModalities;
  }

  set loadingModalities(value: boolean) {
    this._loadingModalities = value;
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }
  /***** END - PROPERT ACCESSORS *****/


  /***** START - PRIVATE FUNCTIONS *****/
  private buildForm(): void {
    let materialType: string, reasonCode: string, serviceCode: string, manufacturer: string, brandName: string, modality: string;
    let numberOfBoxes: number;

    if (this.originalClaim.contactLens) {
      materialType = isNullOrUndefined(this.originalClaim.contactLens.materialType) ? null : this.originalClaim.contactLens.materialType;
      reasonCode = isNullOrUndefined(this.originalClaim.contactLens.clReasonCode) || isNullOrUndefined(this.originalClaim.contactLens.clReasonCode.code) ? null : this.originalClaim.contactLens.clReasonCode.code;
      serviceCode = isNullOrUndefined(this.originalClaim.contactLens.serviceCode) ? null : this.originalClaim.contactLens.serviceCode;
      manufacturer = isNullOrUndefined(this.originalClaim.contactLens.clManufacturer) || isNullOrUndefined(this.originalClaim.contactLens.clManufacturer.name) ? null : this.originalClaim.contactLens.clManufacturer.name;
      brandName = isNullOrUndefined(this.originalClaim.contactLens.brandName) ? null : this.originalClaim.contactLens.brandName;
      modality = isNullOrUndefined(this.originalClaim.contactLens.clModality) || isNullOrUndefined(this.originalClaim.contactLens.clModality.code) ? null : this.originalClaim.contactLens.clModality.code;
      numberOfBoxes = isNullOrUndefined(this.originalClaim.contactLens.numberOfBoxes) ? null : this.originalClaim.contactLens.numberOfBoxes;
    }

    // Update the brandName display value if on the originalClaim
    if (!isStringNullUndefinedOrEmpty(manufacturer) && !isNullOrUndefined(this.manufacturer)) {
        this.brands = this.manufacturer.find((selection: Manufacturer) => selection.name === manufacturer).brands;
    }

    this.contactsForm = this.formBuilder.group({
      materialType: materialType,
      reason: reasonCode,
      service: serviceCode,
      manufacturer: { value: manufacturer, disabled: true },
      brand: { value: brandName, disabled: true },
      modality: { value: modality, disabled: true },
      numberBoxes: { value: numberOfBoxes, disabled: true }
    });
  }

  /**
   * Enables, or resets and disables, a form control without triggering `valueChanges` on the built form.
   *
   * @param isRequired - condition to trigger an enable/disable
   * @param controlName - contact control name mapped to `ContactsConstants`
   * @param hasServiceValue - flag to add/clear validators for the reason control (ECR-8370 AC4)
   */
  private enableOrDisableFieldBasedOnRequiredFormValues(isRequired: boolean, controlName: string, hasServiceValue: boolean = false): void {
    const control: AbstractControl = this.contactsForm.get(controlName);

    if (isRequired) {
      control.enable(ApplicationConstants.updateFormWithoutEmit);
    } else {
      control.setValue(null, ApplicationConstants.updateFormWithoutEmit);
      control.disable(ApplicationConstants.updateFormWithoutEmit);
    }

    // Add/clear the validators for the reason control, the only control that required
    // UI edits for now until we can put these in OE/CV
    if (controlName === ContactsConstants.REASON) {
      if (control.enabled && hasServiceValue) {
        control.setValidators(Validators.required);
      } else {
        control.clearValidators();
      }

      // Update any DOM UI edits
      control.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);
    }
  }

  private updateDataModelFromViewModel(): void {
    // Lazy init the contact lens if not available
    if (isNullOrUndefined(this.activeClaim.contactLens)) {
      this.activeClaim.contactLens = { } as ContactLens;
    }

    // Grab a reference to the control values and set the updated values to persist to PE
    const { materialType, reason, service, manufacturer, brand, modality, numberBoxes } = this.contactsForm.controls;
    const updatedMaterialType: string | undefined = setToUndefinedOrValue(materialType.value);
    const updatedReasonCode: string | undefined = setToUndefinedOrValue(reason.value);
    const updatedServiceCode: string | undefined = setToUndefinedOrValue(service.value);
    const updatedManufacturer: string | undefined = setToUndefinedOrValue(manufacturer.value);
    let updatedBrandName: string | undefined = setToUndefinedOrValue(brand.value);
    const updatedModality: string | undefined = setToUndefinedOrValue(modality.value);
    const updatedNumberOfBoxes: number | undefined = isNaN(parseInt(numberBoxes.value)) ? undefined : parseInt(numberBoxes.value);

    // Update the brands dropdown selection list if the manufacturer was updated
    if (this.isRequiredByManufacturer && !isNullOrUndefined(this.manufacturer) && !isStringNullUndefinedOrEmpty(updatedManufacturer)) {
      this.brands = this.manufacturer.find((manufacturers) => manufacturers.name === updatedManufacturer).brands;
      // If the brand form control value is not actually a part of the selected manufacturer we do not want to persist that
      if (!isStringNullUndefinedOrEmpty(updatedBrandName)) {
        const brandFromSelectedManufacturer: Brand = this.brands.find((matchingBrand: Brand) => matchingBrand.name === updatedBrandName);
        updatedBrandName = isNullOrUndefined(brandFromSelectedManufacturer) ? undefined : updatedBrandName;
      }
    }

    // Set the Reason object that PE expects
    if (!isNullOrUndefined(this.reason) && !isStringNullUndefinedOrEmpty(updatedReasonCode)) {
      this.activeClaim.contactLens.clReasonCode = this.reason.find((item) => item.code === updatedReasonCode);
    } else {
      this.setReasonCodeInActiveClaim(updatedReasonCode);
    }

    // Set the Manufacturer object that PE expects
    if (!isNullOrUndefined(this.manufacturer) && !isStringNullUndefinedOrEmpty(updatedManufacturer)) {
      this.activeClaim.contactLens.clManufacturer = this.manufacturer.find((item) => item.name === updatedManufacturer);
    } else {
      this.setManufacturerInActiveClaim(updatedManufacturer);
    }

    // Set the Modality object that PE expects
    if (!isNullOrUndefined(this.modality) && !isStringNullUndefinedOrEmpty(updatedModality)) {
      this.activeClaim.contactLens.clModality = this.modality.find((item) => item.code === updatedModality);
    } else {
      this.setModalityInActiveClaim(updatedModality);
    }

    this.activeClaim.contactLens.materialType = updatedMaterialType;
    this.activeClaim.contactLens.serviceCode = updatedServiceCode;
    this.activeClaim.contactLens.brandName = updatedBrandName;
    this.activeClaim.contactLens.numberOfBoxes = updatedNumberOfBoxes;

    // Update the active claim in the claim service
    if (this.contactsCardHasChanged()) {
      this.claimService.setActiveClaim(this.activeClaim, this.id);
    }
  }

  private setManufacturerInActiveClaim(selectedManufacturer: string): void {
    if (isNullOrUndefined(selectedManufacturer)) {
      this.activeClaim.contactLens.clManufacturer = undefined;
      return;
    }
  }

  private setReasonCodeInActiveClaim(selectedReasonCode: string): void {
    if (isNullOrUndefined(selectedReasonCode)) {
      this.activeClaim.contactLens.clReasonCode = undefined;
      return;
    }
  }

  private setModalityInActiveClaim(selectedModality: string): void {
    if (isNullOrUndefined(selectedModality)) {
      this.activeClaim.contactLens.clModality = undefined;
      return;
    }
  }

  private contactsCardHasChanged(): boolean {
    const { materialType: materialTypeFromCard, clReasonCode: reasonCodeFromCard, serviceCode: serviceCodeFromCard, clManufacturer: manufacturerFromCard,
      brandName: brandNameFromCard, clModality: modalityFromCard, numberOfBoxes: numberOfBoxesFromCard } = this.activeClaim.contactLens;

    const activeClaimFromService = this.claimService.getActiveClaim();
    // Instantiate claim objects if they don't exist
    if (isNullOrUndefined(activeClaimFromService.contactLens)) { activeClaimFromService.contactLens = {} as ContactLens; }
    const { materialType: materialTypeFromService, clReasonCode: reasonCodeFromService, serviceCode: serviceCodeFromService, clManufacturer: manufacturerFromService,
      brandName: brandNameFromService, clModality: modalityFromService, numberOfBoxes: numberOfBoxesFromService } = activeClaimFromService.contactLens;

    // Evaluate Reason Code to avoid null pointers
    const processedReasonCodeFromCard = ((!isNullOrUndefined(reasonCodeFromCard) && !isStringNullUndefinedOrEmpty(reasonCodeFromCard.code)) ? reasonCodeFromCard.code : reasonCodeFromCard);
    const processedReasonCodeFromServiced = ((!isNullOrUndefined(reasonCodeFromService) && !isStringNullUndefinedOrEmpty(reasonCodeFromService.code)) ? reasonCodeFromService.code : reasonCodeFromService);

    // Evaluate Manufacturer to avoid null pointers
    const processedManufacturerFromCard = ((!isNullOrUndefined(manufacturerFromCard) && !isStringNullUndefinedOrEmpty(manufacturerFromCard.code)) ? manufacturerFromCard.code : manufacturerFromCard);
    const processedManufacturerFromService = ((!isNullOrUndefined(manufacturerFromService) && !isStringNullUndefinedOrEmpty(manufacturerFromService.code)) ? manufacturerFromService.code : manufacturerFromService);

    // Evaluate Modality to avoid null pointers
    const processedModalityFromCard = ((!isNullOrUndefined(modalityFromCard) && !isStringNullUndefinedOrEmpty(modalityFromCard.code)) ? modalityFromCard.code : modalityFromCard);
    const processedModalityFromService = ((!isNullOrUndefined(modalityFromService) && !isStringNullUndefinedOrEmpty(modalityFromService.code)) ? modalityFromService.code : modalityFromService);

    return (materialTypeFromCard || undefined) !== (materialTypeFromService || undefined) ||
      (processedReasonCodeFromCard || undefined) !== (processedReasonCodeFromServiced || undefined) ||
      (serviceCodeFromCard || undefined) !== (serviceCodeFromService || undefined) ||
      (processedManufacturerFromCard || undefined) !== (processedManufacturerFromService || undefined) ||
      (brandNameFromCard || undefined) !== (brandNameFromService || undefined) ||
      (processedModalityFromCard || undefined) !== (processedModalityFromService || undefined) ||
      (numberOfBoxesFromCard || undefined) !== (numberOfBoxesFromService || undefined);
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      reason: {
        control: this.contactsForm.get(ContactsConstants.REASON),
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Contact Lens Reason is required'
        }]
      }
    };
  }

  /**
   * Programmatically enable, or disable, individual contact inputs based on the conditions set in ECR-8370.
   */
  private enableOrDisableFields(): void {
    if (isNullOrUndefined(this.activeClaim.contactLens)) {
      this.activeClaim.contactLens = { } as ContactLens;
    }

    const { materialType, service, manufacturer} = this.contactsForm.controls;
    // Enable/disable the reason dropdown if no selection is present in material/type or services
    const hasServiceValue: boolean = !isStringNullUndefinedOrEmpty(service.value);
    const hasMaterialTypeValue: boolean = !isStringNullUndefinedOrEmpty(materialType.value);

    // Update '*' indicators based on control values
    this.isReasonRequired = hasMaterialTypeValue || hasServiceValue;
    this.isManufacturerRequired = hasMaterialTypeValue;
    this.isRequiredByManufacturer = hasMaterialTypeValue && !isNullOrUndefined(this.manufacturer) && !isStringNullUndefinedOrEmpty(manufacturer.value) && ContactLensManufacturer.NONE !== setToUndefinedOrValue(manufacturer.value);

    // Toggle dropdown availability based on the required fields above
    this.enableOrDisableFieldBasedOnRequiredFormValues(this.isManufacturerRequired, ContactsConstants.MANUFACTURER);
    this.enableOrDisableFieldBasedOnRequiredFormValues(this.isRequiredByManufacturer, ContactsConstants.BRAND);
    this.enableOrDisableFieldBasedOnRequiredFormValues(this.isRequiredByManufacturer, ContactsConstants.MODALITY);
    this.enableOrDisableFieldBasedOnRequiredFormValues(this.isRequiredByManufacturer, ContactsConstants.NUMBER_BOXES);
    this.enableOrDisableFieldBasedOnRequiredFormValues(hasMaterialTypeValue || hasServiceValue, ContactsConstants.REASON, hasServiceValue);
  }

  private getMatchingMaterialTypeFromActiveClaim(): MaterialType {
    if ((!isNullOrUndefined(this.originalClaim.contactLens.contactLensMaterialType)) && !isNullOrUndefined(this.materialType)) {
      return this.materialType.find((item) => item.code === this.activeClaim.contactLens.contactLensMaterialType.code);
    }
    return undefined;
  }
  private initializeContactLensData(): void {
    this.materialType = this.contactsService.materialType;
    this.service = this.contactsService.service;
    this.reason = this.contactsService.reason;
    this.manufacturer = this.contactsService.manufacturer;
    this.modality = this.contactsService.modality;
  }
  private getMatchingServiceFromActiveClaim(): Service {
    if ((!isNullOrUndefined(this.originalClaim.contactLens.contactLensService)) && !isNullOrUndefined(this.service)) {
      return this.service.find((item) => item.code === this.activeClaim.contactLens.contactLensService.code);
    }
    return undefined;
  }

  private getMatchingReasonFromActiveClaim(): Reason {
    if ((!isNullOrUndefined(this.originalClaim.contactLens.clReasonCode)) && !isNullOrUndefined(this.reason)) {
      return this.reason.find((item) => item.code === this.activeClaim.contactLens.clReasonCode.code);
    }
    return undefined;
  }

  private getMatchingManufacturerFromActiveClaim(): Manufacturer {
    if ((!isNullOrUndefined(this.originalClaim.contactLens.clManufacturer)) && !isNullOrUndefined(this.service)) {
      return this.manufacturer.find((item) => item.name === this.activeClaim.contactLens.clManufacturer.name);
    }
    return undefined;
  }

  private getMatchingModalityFromActiveClaim(): Modality {
    if ((!isNullOrUndefined(this.originalClaim.contactLens.clModality)) && !isNullOrUndefined(this.service)) {
      return this.modality.find((item) => item.code === this.activeClaim.contactLens.clModality.code);
    }
    return undefined;
  }
  /***** END - PRIVATE FUNCTIONS *****/

  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.originalClaim = this.claimService.getOriginalClaim();
    this.registerWithClaimProgressService();
    this.initializeContactLensData();
    this.buildForm();
    this.buildErrorWrapperConfig();

    this.observableSubscriptions.push(this.claimService.onCardsToUpdate.subscribe(() => {
      this.activeClaim = this.claimService.getActiveClaim();
    }));

    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      this.mask = mask;
      if (mask) {
        this.disableFormGroupComponents(this.contactsForm);
        this.componentMask.show();
      } else {
        this.contactsForm.enable();
        this.componentMask.hide();
      }
    }));

    this.observableSubscriptions.push(this.contactsForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe(() => {
      // Check if any of the data should be masked (ie: Date of Service is missing) and if so, then don't disable/enable fields - See VEC-314 "Contact Cards - Disabling incorrectly
      if ( !this.mask ) {
        // Update the data model based on the form data
        this.enableOrDisableFields();
        this.updateDataModelFromViewModel();
        this.changeDetector.detectChanges();
      }
    }));
    // When submit finds UI edits detect changes on component
    this.observableSubscriptions.push(this.viewStateService.onSubmitFoundUIEdits.subscribe((hasUIEdits: boolean) => {
      if (hasUIEdits) {
        this.changeDetector.detectChanges();
      }
    }));
    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
          (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }
      }));
    // TODO: The 'cptHcpcsCode' property will need to be made available via an observable.  If it changes, run the rebuildKnownConditions function
    // claimLinesService.registerClaimLineListener(['cptHcpcsCode'], knownConditionsChanged);
  }

  ngAfterViewInit(): void {
    this.inputMaskService.createInputMask(this.numberOfBoxesHtmlRef.nativeElement, '999', { placeholder: '' });
  }

  onServicesClick(): void {
    if (isNullOrUndefined(this.service) || (this.service.length < 1)) {
      this.loadingServices = true;
      this.contactsForm.get('service').disable();
      this.contactsService.contactLensServiceFactory(ContactLensOperation.Service).subscribe((services: Service[]) => {
        this.contactsForm.get('service').enable();
        this.service = services;
        this.loadingServices = false;
        if (isNullOrUndefined(services)) {
          this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
        } else {
          this.contactsForm.get('service').setValue( this.getMatchingServiceFromActiveClaim());
        }
        this.changeDetector.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach( subscription => subscription.unsubscribe() );
  }

  onMaterialTypeClick(): void {
    if (isNullOrUndefined(this.materialType) || !(this.materialType.length > 0)) {
      this.loadingMaterialTypes = true;
      this.contactsForm.get('materialType').disable();
      this.contactsService.contactLensServiceFactory(ContactLensOperation.MaterialType).subscribe((materialTypes: MaterialType[]) => {
        this.contactsForm.controls.materialType.enable();
        this.materialType = materialTypes;
        this.loadingMaterialTypes = false;
        if (isNullOrUndefined(materialTypes)) {
          this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
        } else {
          this.contactsForm.get('materialType').setValue( this.getMatchingMaterialTypeFromActiveClaim());
        }
        this.changeDetector.detectChanges();
      });
    }
  }

  onReasonClick(): void {
    if (isNullOrUndefined(this.reason) || (this.reason.length < 1)) {
      this.loadingReasons = true;
      this.contactsForm.get('reason').disable();
      this.contactsService.contactLensServiceFactory(ContactLensOperation.Reason).subscribe((reasons: Reason[]) => {
        this.contactsForm.get('reason').enable();
        this.reason = reasons;
        this.loadingReasons = false;
        if (isNullOrUndefined(reasons)) {
          this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
        } else {
          this.contactsForm.get('reason').setValue( this.getMatchingReasonFromActiveClaim());
        }
        this.changeDetector.detectChanges();
      });
    }
  }

  onManufacturersClick(): void {
    if (isNullOrUndefined(this.manufacturer) || (this.manufacturer.length < 1)) {
      this.loadingManufacturers = true;
      this.contactsForm.get('manufacturer').disable();
      this.contactsService.contactLensServiceFactory(ContactLensOperation.Manufacturer).subscribe((manufacturers: Manufacturer[]) => {
        this.contactsForm.get('manufacturer').enable();
        this.manufacturer = manufacturers;
        this.loadingManufacturers = false;
        if (isNullOrUndefined(manufacturers)) {
          this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
        } else {
          this.contactsForm.get('manufacturer').setValue( this.getMatchingManufacturerFromActiveClaim());
        }
        this.changeDetector.detectChanges();
      });
    }
  }

  onModalityClick(): void {
    if (isNullOrUndefined(this.modality) || (this.modality.length < 1)) {
      this.loadingModalities = true;
      this.contactsForm.get('modality').disable();
      this.contactsService.contactLensServiceFactory(ContactLensOperation.Modality).subscribe((modalities: Modality[]) => {
        this.contactsForm.get('modality').enable();
        this.modality = modalities;
        this.loadingModalities = false;
        if (isNullOrUndefined(modalities)) {
          this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
        } else {
          this.contactsForm.get('modality').setValue( this.getMatchingModalityFromActiveClaim());
        }
        this.changeDetector.detectChanges();
      });
    }
  }
  /***** END - EVENT HANDLERS *****/
}
