import { Suppliers, FrameMaterials, Shapes } from 'src/app/models/frame';
import { ReferenceListViewModel, ReferenceListValue } from 'src/app/models/reference-list-view-model';

export const FrameConstants = {
  supplier: 'supplier',
  query: 'query',
  suppliers: [
    new ReferenceListValue(Suppliers.DOCTOR, 'Doctor Supplied - To Come').viewModel,
    new ReferenceListValue(Suppliers.PATIENT, 'Patient Supplied - To Come').viewModel,
    new ReferenceListValue(Suppliers.LAB, 'Lab Supplied').viewModel,
    new ReferenceListValue(Suppliers.LENS_ONLY, 'Lenses Only').viewModel
  ] as ReferenceListViewModel[],
  vendorSupplier: new ReferenceListValue(Suppliers.VENDOR, 'Order from Vendor').viewModel,
  frameMaterials: [
    new ReferenceListValue(FrameMaterials.ZYL, 'Zyl').viewModel,
    new ReferenceListValue(FrameMaterials.METAL, 'Metal').viewModel,
    new ReferenceListValue(FrameMaterials.DRILLED_RIMLESS, 'Drilled Rimless').viewModel,
    new ReferenceListValue(FrameMaterials.GROOVED_RIMLESS, 'Grooved Rimless').viewModel
  ] as ReferenceListViewModel[],
  shapes: [
    new ReferenceListValue(Shapes.SQUARE, 'Square').viewModel,
    new ReferenceListValue(Shapes.MODERATE, 'Moderate').viewModel,
    new ReferenceListValue(Shapes.AVIATOR, 'Aviator').viewModel,
    new ReferenceListValue(Shapes.CAT, 'Cat').viewModel,
    new ReferenceListValue(Shapes.OVAL, 'Oval').viewModel,
    new ReferenceListValue(Shapes.GOGGLE, 'Goggle').viewModel,
    new ReferenceListValue(Shapes.RECTANGLE, 'Rectangle').viewModel,
    new ReferenceListValue(Shapes.LADY, 'Lady').viewModel,
    new ReferenceListValue(Shapes.P_3, 'P 3').viewModel,
    new ReferenceListValue(Shapes.CORNERED_RECT, 'Cornered Rectangle').viewModel,
    new ReferenceListValue(Shapes.TAPERED_OVAL, 'Tapered Oval').viewModel
  ] as ReferenceListViewModel[],
  emptyFrame: {
    imageLink: {
      href: null,
      rel: null,
      type: null,
      contextRootPlus: null
    }
  },
  attributes: {
   kscopeFrameLink: 'kscope-frame-link',
   framesFrameLink: 'frame-frame-link',
   tabIndex: 'tabindex'
  }
};
