<div class="claim-action-buttons-container" fxLayout="column" fxLayoutAlign="center none" *ngIf="!readonly">

      <!-- Claim Action Buttons (hidden in readonly mode)  -->
      <div fxLayout="row" fxLayoutAlign="end center" id="claim-tracker-action-buttons">

        <!-- Save -->
        <button color="primary" class="secondary-cta-button" (click)="onSaveButtonClick()" [disabled]="cardsMasked" id="claimTracker-saveClaim">
          <span>Save Claim</span>
        </button>

        <!-- Calculate -->
        <button mat-stroked-button color="primary" fxFlexOffset="30px" class="claim-progress-action-button" (click)="onCalculateButtonClick()" [disabled]="cardsMasked" matTooltip={{calculateTooltipMessage}} id="claim-tracker-calculate">
          <span>Calculate</span>
        </button>

        <!-- Submit -->
        <button mat-flat-button fxFlexOffset="30px" class="claim-progress-action-button mat-flat-button mat-primary" (click)="onSubmitButtonClick()" [disabled]="cardsMasked" id="claimTracker-submitClaim">
          <span>Submit Claim</span>
        </button>

        <!-- spacing to the right of the submit button -->
        <div fxFlexOffset="30px"></div>

      </div>

</div>

<app-component-mask></app-component-mask>
