import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './footer/footer.component';
import { PublicModule } from './public/public.module';
import { SecureModule } from './secure/secure.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FPOService } from './services/fpo.service';
import { MaterialsModule } from './materials.module';
import {CookieService} from 'ngx-cookie-service';
import {FlexLayoutModule} from '@angular/flex-layout';
import {VspIconSnackbarModule} from './secure/icon-snackbar/vsp.icon-snackbar.module';

@NgModule({
  providers: [
    FPOService,
    CookieService
  ],
  declarations: [
    AppComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    PublicModule,
    AppRoutingModule,
    SecureModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CdkTableModule,
    VspIconSnackbarModule,
    MaterialsModule,
    FlexLayoutModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
