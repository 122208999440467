import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ClaimFormItemDataFormat, ViewStateService} from '../../../common/services/view-state/view-state.service';
import {ComponentMaskComponent} from '../../../common/components/component-mask/component-mask.component';
import {Subscription} from 'rxjs';
import {isNullOrUndefined} from '../../../common/utility';

@Component({
  selector: 'app-claim-navigation',
  templateUrl: './claim-navigation.component.html',
  styleUrls: ['./claim-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClaimNavigationComponent implements OnInit, OnDestroy {

  constructor(
    private viewStateService: ViewStateService,
  ) { }
  /***** START - PRIVATE MEMBERS *****/
  private observableSubscriptions: Subscription[] = [];
  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;
  @ViewChild('claimNavBar', { read: ElementRef, static: true}) claimNavbar: ElementRef;
  /***** END - PRIVATE MEMBERS *****/


  /***** START - PUBLIC MEMBERS *****/
  claimFormSectionItems: ClaimFormItemDataFormat[];
  readonly = false;
  // flags to indicate if we are waiting on API calls to finish
  cardsMasked = false;
  /***** END - PUBLIC MEMBERS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  scrollToElement(item: ClaimFormItemDataFormat): void {
    if (this.claimNavbar && this.claimNavbar.nativeElement) {
      // We are trying to capture the height of the DIV which wraps Error, Warning and Navigation bars.
      const navBarScrollHeight: number = document.getElementById('editBannerAndNavBarWrapper').offsetHeight;

      // Grab a reference the navbar height and smooth scroll to the element
      const elements = document.querySelectorAll('#' + item.id);
      const elementToScrollIntoView = !isNullOrUndefined(elements) && elements.length > 1 ? elements[1] as HTMLElement : elements[0] as HTMLElement;
      const scrollCoordinate: number = elementToScrollIntoView.getBoundingClientRect().top + window.pageYOffset;
      const offsetToSelectedCard = scrollCoordinate - navBarScrollHeight;
      window.scrollTo({
        behavior: 'smooth',
        top: offsetToSelectedCard
      });
    }
  }
  /***** END - PUBLIC FUNCTIONS *****/

  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    // Update the visual display for each card
    this.observableSubscriptions.push(this.viewStateService.onClaimFormItemsUpdated.subscribe((updatedClaimFormItems: ClaimFormItemDataFormat[]) => {
      this.claimFormSectionItems = updatedClaimFormItems;
    }));
  }

  ngOnDestroy() {
    this.observableSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
  /***** END - EVENT HANDLERS *****/

}


