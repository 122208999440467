import {ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ErrorWrapperConfig} from '../../../common/components/error-wrapper/error-wrapper.component';
import {InputMaskService} from '../../../common/services/support/input-mask/input-mask.service';
import {MatDialog} from '@angular/material/dialog';
import {FrameSearchResultsModalComponent} from './frame-search-results-modal/frame-search-results-modal.component';
import {FrameSearchHelpModalComponent} from './frame-search-help-modal/frame-search-help-modal.component';
import {ClaimFormItem} from '../../../common/classes/claim-form-item';
import {Subscription} from 'rxjs';
import {ComponentMaskComponent} from '../../../common/components/component-mask/component-mask.component';
import {ApplicationConstants, ErrorTypes} from '../../../common/constants/application.constants';
import {Claim, SoftAndHardValidationMessages, ValidationMessage} from '../../../models/claim';
import {Frame, FrameDataModel, Suppliers} from '../../../models/frame';
import {FrameConstants} from './frame.constants';
import {ClaimsService} from '../../../common/services/data-model/app/claims/claims.service';
import {take} from 'rxjs/operators';
import {
  delay,
  isNullOrUndefined,
  isStringNullUndefinedOrEmpty,
  isStringNullUndefinedOrEmptyWithTrim
} from '../../../common/utility';
import {LabOrderInformation} from '../../../models/labOrderInformation';
import {ClaimCardsToUpdate} from '../../../models/claimCardsToUpdate';
import {LensOrFrame} from '../exam/exam.constants';
import {LensFinishingTypes} from '../../../models/lens';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';
import {UserSessionService} from '../../../common/services/support/user-session/user-session.service';
import {FulfillmentService} from '../../../common/services/data-model/app/fulfillment/fulfillment.service';
import {FrameSourceModalComponent} from './frame-source-modal/frame-source-modal.component';
import {HtmlConstants} from '../../../common/constants/html.constants';
import {KscopeFrameConfirmationModalComponent} from './kscope-frame-confirmation-modal/kscope-frame-confirmation-modal';
import {KScopeBanners} from '../../../common/enum/kscope-banners';
import {ReplacementFrameService} from '../../../common/services/support/replacement-frame/replacement.frame.service';
import {ReplaceFrameEnum, ReplacementChoiceIndicatorEnum} from '../../../models/fulfillment';
import {ReplacementFrameModalComponent} from './replacement-frame-modal/replacement-frame-modal.component';

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FrameComponent extends ClaimFormItem implements OnInit, OnDestroy {

  // #region ***** START - PRIVATE MEMBERS *****/
  private _isFrameSupplierRequired: boolean;
  private observableSubscriptions: Subscription[] = [];
  private _originalClaim: Claim;
  private _activeClaim: Claim;
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;
  // #endregion END - PRIVATE MEMBERS *****/

  // #region ***** START - PUBLIC MEMBERS *****/
  claim: Claim;
  id = 'frame-section';
  title = 'Frame';
  frameForm: FormGroup;
  selectedFrame: Frame;
  frameIsSelected = false;
  searchingFrames = false;
  editingDetails = false;
  document: Document;
  dateOfService: string;

  // Form state/data variables
  errorWrapperConfig = {
    supplier: new ErrorWrapperConfig(),
    query: new ErrorWrapperConfig()
  };

  selectOptions = {
    suppliers: FrameConstants.suppliers,
  };

  queryMaxLength: number = ApplicationConstants.queryMaxLength;
  // TODO: a "readonly" scope variable from the old claim-form controller is used here for toggling form fields.  Move this logic into a service.
  readOnly = false;
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;
  isKaleyedoscopePractice: boolean = false;
  isTopSellingFrame: boolean = false;
  isFrameDispositionHasErrors: boolean = false;
  isManufacturerNotParticipating: boolean = false;
  isSlowSellingFrameAtPractice: boolean = false;
  isSlowSellingFrameNotAtPracticeOrderable: boolean = false;
  isSlowSellingFrameNotAtPracticeNotOrderable: boolean = false;
  isBackOrderedFrame: boolean = false;
  isSkuMissingInFrame: boolean = false;
  // ECLAIM-191 - These two were added to handle IOF KScope Lenses.
  isIOFLensAndFrameIsTopSellerOrFrameIsSlowSellerAndAvailableInOffice: boolean = false;
  isIOFLensAndFrameIsSlowSellerAndNotAvailableInOffice: boolean = false;
  // ECLAIM-275 and ECLAIM-276 - This parameter was added to either display or not display the 'View replacement frame options' link on slow sellers and overrides.
  displayViewReplacementFrameOptionsLink: boolean = false;
  // #endregion ***** END - PUBLIC MEMBERS *****/

  private isFrameSearchApiOffline = false;
  private startFrameDetailsComponenetInEditMode = false;
  private allowFullFrameDetailEdit = false;
  private userSessionService: UserSessionService;


  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public inputMaskService: InputMaskService,
    private claimService: ClaimsService,
    private claimEditService: ClaimEditService,
    private changeDetector: ChangeDetectorRef,
    private fulfillmentService: FulfillmentService,
    private replacementFrameService: ReplacementFrameService
  ) {
    super(injector);
    this.userSessionService = this.injector.get(UserSessionService);
  }

  // #region **** LIFECYCLE EVENTS  ****/
  ngOnInit() {
    this.originalClaim = this.claimService.getOriginalClaim();
    this.activeClaim = this.claimService.getActiveClaim();
    this.registerWithClaimProgressService();
    this.buildForm();
    this.buildErrorWrapperConfig();
    // Check if the practice is Kaleyedoscope
    if (this.userSessionService.isKaleyedoscopePractice) {
      this.isKaleyedoscopePractice = true;
    }
    // Register the claim service
    this.observableSubscriptions.push(this.claimService.onCardsToUpdate.subscribe((onCardsToUpdate: ClaimCardsToUpdate) => {
      this.activeClaim = this.claimService.getActiveClaim();

      if (onCardsToUpdate.all) {
        if (!this.activeClaim.labOrderInformation) {
          this.activeClaim.labOrderInformation = {} as LabOrderInformation;
        }
        this.selectedFrame = (!isNullOrUndefined(this.activeClaim.labOrderInformation.frame)) ? this.setViewModelFromDataModel(this.activeClaim.labOrderInformation.frame) : FrameConstants.emptyFrame;
        this.updateIfFrameSupplierRequired();
      }
      if (onCardsToUpdate.frame) {
        this.updateIfFrameSupplierRequired();
      }
    }));

    // ECR-10044 if frame is saved, then we need to initialize the frame supplier as required
    if (!isNullOrUndefined(this.activeClaim.labOrderInformation) &&
      !isNullOrUndefined(this.activeClaim.labOrderInformation.frame) &&
      !isNullOrUndefined(this.activeClaim.labOrderInformation.frame.manufacturer) &&
      !isStringNullUndefinedOrEmpty(this.activeClaim.labOrderInformation.frame.manufacturer.name)) {
      this.frameIsSelected = true;
    }
    this.updateIfFrameSupplierRequired();

    if (this.frameForm.disabled) {
      if (this.isKaleyedoscopePractice) {
        document.getElementById(FrameConstants.attributes.kscopeFrameLink).setAttribute(FrameConstants.attributes.tabIndex, '-1');
      } else {
        document.getElementById(FrameConstants.attributes.framesFrameLink).setAttribute(FrameConstants.attributes.tabIndex, '-1');
      }
    } else {
      if (this.isKaleyedoscopePractice) {
        this.setAttributeForHTMLElement(FrameConstants.attributes.kscopeFrameLink, '0');
      } else {
        this.setAttributeForHTMLElement(FrameConstants.attributes.framesFrameLink, '0');
      }
    }

    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      if (mask) {
        this.disableFormGroupComponents(this.frameForm);
        if (this.isKaleyedoscopePractice) {
          // Do - Nothing
        } else {
          this.setAttributeForHTMLElement(FrameConstants.attributes.framesFrameLink, '-1');
        }
        this.componentMask.show();
      } else {
        this.frameForm.enable();
        if (this.isKaleyedoscopePractice) {
          this.setAttributeForHTMLElement(FrameConstants.attributes.kscopeFrameLink, '0');
        } else {
          this.setAttributeForHTMLElement(FrameConstants.attributes.framesFrameLink, '0');
        }
        this.componentMask.hide();
      }
    }));



    // Register updates to the confirmation of the lens/frame checkbox removals, regardless of material being removed
    this.observableSubscriptions.push(this.viewStateService.onLensOrFrameCheckboxRemovalConfirmation.subscribe((lensOrFrame: LensOrFrame) => {
      // TODO: Add unit test to assert the clearing happens when we re-enable the frame spec
      if (lensOrFrame === LensOrFrame.Frame) {
        this.clearSelectedFrame(true);
        this.viewStateService.setMaterialToRemoveState(null);
      }
    }));

    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
          (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }
    }));

    // If the selected frame is Vendor supplied set the supplier dropdown to vendor supplied.
    this.observableSubscriptions.push(this.fulfillmentService.onIsVendorSupplied.subscribe((isVendorSupplied: boolean) => {
      if (isVendorSupplied) {
        // ECLAIM-191 - This logic was added to handle IOF KScope Lenses.
        if (this.fulfillmentService.isInOfficeStockLens || this.fulfillmentService.isInOfficeUncutLens) {
          this.removeVendorSupplierFromDropDown();
          this.frameForm.controls.supplier.setValue(Suppliers.DOCTOR);
          // Set the fulfillment service selected supplier to what is selected in the supplier dropdown.
          this.fulfillmentService.selectedSupplier = Suppliers.DOCTOR;
        } else {
          this.frameForm.controls.supplier.setValue(Suppliers.VENDOR);
          // Set the fulfillment service selected supplier to what is selected in the supplier dropdown.
          this.fulfillmentService.selectedSupplier = Suppliers.VENDOR;
        }
        this.updateDataModelFromViewModel();
        // In order to get around the issue where the kScope guidance banner is taking forever to load we need to force the change detector to trigger.
        this.changeDetector.detectChanges();
      }
    }));

    // If the selected frame is Slow Selling Frame.
    this.observableSubscriptions.push(this.fulfillmentService.onIsSlowSellingFrame.subscribe((isSlowSellingFrame: boolean) => {
      if (isSlowSellingFrame) {
        this.dialog.open(KscopeFrameConfirmationModalComponent, {
          width: '480px',
          panelClass: ApplicationConstants.eClaimPopupModal,
          // prevent the modal from closing automatically if user accidentally clicks outside the modal boundary.
          disableClose: true
        }).afterClosed().subscribe((onYesClick: boolean) => {
          this.resetKScopeValuesToOriginalValue();
          if (onYesClick) {
            this.fulfillmentService.practiceHasFrame = true;
            // ECLAIM-7 - Setting the supplier to doctor supplied if a low selling frame and the practice has or does not have the frame in office
            // ECLAIM-6 - Slow selling and in the practices inventory - Setting the supplier to Doctor Supplied
            this.resetKScopeValuesToOriginalValue();
            // ECLAIM-191 - This logic was added to handle IOF KScope Lenses.
            if (this.fulfillmentService.isInOfficeStockLens || this.fulfillmentService.isInOfficeUncutLens) {
              this.isIOFLensAndFrameIsTopSellerOrFrameIsSlowSellerAndAvailableInOffice = true;
            } else {
              this.isSlowSellingFrameAtPractice = true;
              // This is to correctly display the 'View replacement frame options' link in the dialog.
              this.setDisplayViewReplacementFrameOptionsLink();
            }
            this.removeVendorSupplierFromDropDown();
            this.frameForm.controls.supplier.setValue(Suppliers.DOCTOR);
            // Set the fulfillment service selected supplier to what is selected in the supplier dropdown.
            this.fulfillmentService.selectedSupplier = Suppliers.DOCTOR;
            // Per New Acs we need to update the lab list to remove the categories when the supplier is doctor supplied and banner is send this frame to the lab.
            this.fulfillmentService.onUpdateLabListToRemoveCategories.next(true);
          } else {
            // On No Click
            // Back Ordered Frame - Out Of Stock Status
            if (this.fulfillmentService.isBackOrderedFrameOutOfStock) {
              this.resetKScopeValuesToOriginalValue();
              this.isSlowSellingFrameNotAtPracticeNotOrderable = true;
              // Set the supplier dropdown to 'Doctor Supplied'
              this.frameForm.controls.supplier.setValue(Suppliers.DOCTOR);
              // Set the fulfillment service selected supplier to what is selected in the supplier dropdown.
              this.fulfillmentService.selectedSupplier = Suppliers.DOCTOR;
              this.removeVendorSupplierFromDropDown();
              // Need to add this flag to true so we submit the frame correctly to the fulfilment api on submit.
              this.fulfillmentService.participatesInKScope = false;
              // We added this observable for ECLAIM-10 to notify the lab.component that a change was made so that it can update the lab list appropriately.
              this.fulfillmentService.onUpdateLabList.next(true);
            }
            // Back Ordered Frame - Available Status
            if (this.fulfillmentService.isBackOrderedFrameAvailable) {
              this.resetKScopeValuesToOriginalValue();
              // ECLAIM-191 - This logic was added to handle IOF KScope Lenses.
              if (this.fulfillmentService.isInOfficeStockLens || this.fulfillmentService.isInOfficeUncutLens) {
                this.isIOFLensAndFrameIsSlowSellerAndNotAvailableInOffice = true;
                // Set the supplier dropdown to 'Doctor Supplied'
                // Note: We are not overriding the doctor supplied values in this method but are instead doing it at the end of all these conditions
              } else {
                this.isSlowSellingFrameNotAtPracticeOrderable = true;
                // Set the supplier dropdown to 'Order from Vendor'
                this.addVendorSupplierToDropDown();
                this.frameForm.controls.supplier.setValue(Suppliers.VENDOR);
                // Set the fulfillment service selected supplier to what is selected in the supplier dropdown.
                this.fulfillmentService.selectedSupplier = Suppliers.VENDOR;
              }
            }
            // Back Ordered Frame - Backorder Status
            if (this.fulfillmentService.isBackOrderedFrame) {
              this.resetKScopeValuesToOriginalValue();
              this.isBackOrderedFrame = true;
              // Set the supplier dropdown to 'Order from Vendor'
              this.addVendorSupplierToDropDown();
              this.frameForm.controls.supplier.setValue(Suppliers.VENDOR);
              // Set the fulfillment service selected supplier to what is selected in the supplier dropdown.
              this.fulfillmentService.selectedSupplier = Suppliers.VENDOR;
              if (!isStringNullUndefinedOrEmptyWithTrim(this.fulfillmentService.isBackOrderDate)) {
                this.dateOfService = this.fulfillmentService.isBackOrderDate;
              }
            }
            // ECLAIM-191 - This logic was added to handle IOF KScope Lenses.. We need to set the doctor supplied to
            // DOCTOR regardless of the message displayed in the slow selling and "No" condition.
            if (this.fulfillmentService.isInOfficeStockLens || this.fulfillmentService.isInOfficeUncutLens) {
              this.removeVendorSupplierFromDropDown();
              this.frameForm.controls.supplier.setValue(Suppliers.DOCTOR);
              // Set the fulfillment service selected supplier to what is selected in the supplier dropdown.
              this.fulfillmentService.selectedSupplier = Suppliers.DOCTOR;
            }
          }
          this.fulfillmentService.onIsSlowSellingFrame.next(false);
          this.updateDataModelFromViewModel();
          this.changeDetector.detectChanges();
        });
      }
    }));

    // If the selected frame is Top Selling.
    this.observableSubscriptions.push(this.fulfillmentService.onIsTopSellingFrame.subscribe((isTopSellingFrame: boolean) => {
      if (isTopSellingFrame) {
        this.resetKScopeValuesToOriginalValue();
        // ECLAIM-191 - This logic was added to handle IOF KScope Lenses.
        if (this.fulfillmentService.isInOfficeStockLens || this.fulfillmentService.isInOfficeUncutLens) {
          this.isIOFLensAndFrameIsTopSellerOrFrameIsSlowSellerAndAvailableInOffice = true;
          this.removeVendorSupplierFromDropDown();
          this.frameForm.controls.supplier.setValue(Suppliers.DOCTOR);
          // Set the fulfillment service selected supplier to what is selected in the supplier dropdown.
          this.fulfillmentService.selectedSupplier = Suppliers.DOCTOR;
        } else {
          this.isTopSellingFrame = true;
          this.addVendorSupplierToDropDown();
        }
        // Set observable back to false;
        this.fulfillmentService.onIsTopSellingFrame.next(false);
      }
    }));

    // If the selected frame is Doctor supplied set the supplier dropdown to doctor supplied.
    this.observableSubscriptions.push(this.fulfillmentService.onIsDoctorSupplied.subscribe((isDoctorSupplied: boolean) => {
      if (isDoctorSupplied) {
        this.frameForm.controls.supplier.setValue(Suppliers.DOCTOR);
        // Set the fulfillment service selected supplier to what is selected in the supplier dropdown.
        this.fulfillmentService.selectedSupplier = Suppliers.DOCTOR;
        this.updateDataModelFromViewModel();
        // In order to get around the issue where the kScope guidance banner is taking forever to load we need to force the change detector to trigger.
        this.changeDetector.detectChanges();
      }
    }));

    // If the Frame Dispositions throw error
    this.observableSubscriptions.push(this.fulfillmentService.onIsDispositionError.subscribe((isFrameDispositionError: boolean) => {
      if (isFrameDispositionError) {
        this.resetKScopeValuesToOriginalValue();
        this.isFrameDispositionHasErrors = true;
        this.removeVendorSupplierFromDropDown();
        // Set observable back to false;
        this.fulfillmentService.onIsDispositionError.next(false);
      }
    }));

    // If the Frame Manufacturer is not participating
    this.observableSubscriptions.push(this.fulfillmentService.onIsManufacturerNotParticipating.subscribe((isManufacturerNotParticipating: boolean) => {
      if (isManufacturerNotParticipating) {
        this.resetKScopeValuesToOriginalValue();
        this.isManufacturerNotParticipating = true;
        // We need to remove vendor from the dropdown since the frame selected does not participate in KScope
        this.removeVendorSupplierFromDropDown();
        // Set observable back to false;
        this.fulfillmentService.onIsManufacturerNotParticipating.next(false);
      }
    }));

    // This will set the focus to the frame card if the following conditions are met.
    // If isKaleyedoscope Practice && dispositionCallFinishedSuccessfully && isFulfillmentApiCallFromClaimNavigationService (i.e coming from save or refresh)
    this.observableSubscriptions.push(this.fulfillmentService.onAllClaimFormCardsHaveLoaded.subscribe((allClaimFormCardsHaveLoaded: boolean) => {
      if (allClaimFormCardsHaveLoaded) {
        // This was added for ECLAIM-27 to navigate the user to the frames card if the call to the fulfillment api was successful.
        if ((this.isKaleyedoscopePractice && this.fulfillmentService.isFulfillmentApiCallFromClaimNavigationService)) {
          this.setFocusOnFrameCard();
          // Added this for ECLAIM-60 to make sure we display 'Kaleyedoscope information is unavailable for this frame' on saved frame with no SKU.
          if (this.fulfillmentService.isFrameMissingSkuNumber) {
            this.isSkuMissingInFrame = true;
          }
        }
        // Set observable back to false;
        this.fulfillmentService.onAllClaimFormCardsHaveLoaded.next(false);
        this.updateDataModelFromViewModel();
        // In order to get around the issue where the kScope guidance banner is taking forever to load we need to force the change detector to trigger.
        this.changeDetector.detectChanges();
      }
    }));

    // This was added for ECLAIM-60 to display an error message on a manual frame search where no SKU is available.
    this.observableSubscriptions.push(this.fulfillmentService.onIsSkuMissingInFrame.subscribe((isSkuMissingInFrame: boolean) => {
      if (isSkuMissingInFrame) {
        // We only want to rest values on a true instance because at that point is where we want to reset the items to only display that error banner for missing sku in frame.
        this.resetKScopeValuesToOriginalValue();
        this.removeVendorSupplierFromDropDown();
        this.frameForm.controls.supplier.setValue(Suppliers.DOCTOR);
        // Set the fulfillment service selected supplier to what is selected in the supplier dropdown.
        this.fulfillmentService.selectedSupplier = Suppliers.DOCTOR;
        this.changeDetector.detectChanges();
      }
      this.isSkuMissingInFrame = isSkuMissingInFrame;
    }));

    // Added this for ECLAIM-275 to hide the 'View replacement frame options' link in the 'Send this frame to the lab' dialog.
    this.observableSubscriptions.push(this.fulfillmentService.onHideReplacementFrameOptionsLink.subscribe((hideReplacementFrameOptionsLink: boolean) => {
      if (hideReplacementFrameOptionsLink) {
        // This is needed to reset the value to false on the field due to the fact that this field sometimes sticks as true when multiple frame searches are performed back to back.
        this.displayViewReplacementFrameOptionsLink = false;
        // Set observable back to false;
        this.fulfillmentService.onHideReplacementFrameOptionsLink.next(false);
      }
    }));
  }

  get isFrameSupplierRequired(): boolean {
    return this._isFrameSupplierRequired;
  }

  set isFrameSupplierRequired(value: boolean) {
    this._isFrameSupplierRequired = value;
  }

  get originalClaim(): Claim {
    return this._originalClaim;
  }

  set originalClaim(value: Claim) {
    this._originalClaim = value;
  }

  get activeClaim(): Claim {
    return this._activeClaim;
  }

  set activeClaim(value: Claim) {
    this._activeClaim = value;
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }

  // #endregion

  /***** START - PRIVATE FUNCTIONS *****/
  private buildForm(): void {
    // pull data from the data model
    let frameSupplier = '';
    const query = ApplicationConstants.emptyString;
    if (this.originalClaim && this.originalClaim.labOrderInformation) {
      ({frameSupplier} = this.originalClaim.labOrderInformation);
      if (frameSupplier === undefined) {
        frameSupplier = null;
      }
    }
    this.frameForm = this.formBuilder.group({
      supplier: [frameSupplier],
      query: [query],
    });
  }

  private updateDataModelFromViewModel(): void {
    const {supplier} = this.frameForm.controls;
    if (isNullOrUndefined(this.activeClaim.labOrderInformation)) {
      this.activeClaim.labOrderInformation = {} as LabOrderInformation;
    }
    this.activeClaim.labOrderInformation.frameSupplier = isStringNullUndefinedOrEmpty(supplier.value) ? undefined : (supplier.value === Suppliers.VENDOR ? Suppliers.DOCTOR : supplier.value);
    if (this.selectedFrame) {
      this.activeClaim.labOrderInformation.frame = this.setDataModelFromViewModel();
    }

    // ECR-9672 we can set the active claim without checking if the values changed as this card doesn't fuel the cyclical behavior from before
    this.claimService.setActiveClaim(this.activeClaim, this.id);
  }

  private setDataModelFromViewModel(): FrameDataModel {
    return {
      manufacturer: {
        name: (this.selectedFrame.manufacturer) ? this.selectedFrame.manufacturer : undefined
      },
      name: (this.selectedFrame.model) ? this.selectedFrame.model : undefined,
      material: (this.selectedFrame.material) ? FrameConstants.frameMaterials.filter(element => element.label === this.selectedFrame.material)[0].value : undefined,
      shape: {
        shape: (this.selectedFrame.shape) ? FrameConstants.shapes.filter(element => element.label === this.selectedFrame.shape)[0].value : undefined,
      },
      brand: {
        name: (this.selectedFrame.collection) ? this.selectedFrame.collection : undefined
      },
      options: [{
        upc: (this.selectedFrame.upc) ? this.selectedFrame.upc : undefined,
        sku: (this.selectedFrame.sku) ? this.selectedFrame.sku : undefined,
        wholeSalePrice: (this.selectedFrame.wholeSalePrice) ? parseFloat(this.selectedFrame.wholeSalePrice) : undefined,
        color: {
          name: (this.selectedFrame.color) ? this.selectedFrame.color : undefined
        },
        size: {
          temple: (this.selectedFrame.temple) ? this.selectedFrame.temple : undefined,
          eye: (this.selectedFrame.eyesize) ? this.selectedFrame.eyesize.toString() : undefined,
          dbl: (this.selectedFrame.dbl) ? this.selectedFrame.dbl : undefined,
          b: (this.selectedFrame.b) ? this.selectedFrame.b.toString() : undefined,
          ed: (this.selectedFrame.ed) ? this.selectedFrame.ed.toString() : undefined
        }
      }],
      images: [{
        mainUrl: (this.selectedFrame.imageLink.href) ? this.selectedFrame.imageLink.href : undefined
      }]
    };
  }

  private setViewModelFromDataModel(frameDataModel: FrameDataModel): Frame {
    return {
      collection: (frameDataModel.brand) ? frameDataModel.brand.name : undefined,
      color: (frameDataModel.options && frameDataModel.options[0] && frameDataModel.options[0].color) ? frameDataModel.options[0].color.name : undefined,
      dbl: (frameDataModel.options && frameDataModel.options[0] && frameDataModel.options[0].size) ? frameDataModel.options[0].size.dbl : undefined,
      ed: (frameDataModel.options && frameDataModel.options[0] && frameDataModel.options[0].size) ? +frameDataModel.options[0].size.ed : undefined,
      b: (frameDataModel.options && frameDataModel.options[0] && frameDataModel.options[0].size) ? +frameDataModel.options[0].size.b : undefined,
      eyesize: (frameDataModel.options && frameDataModel.options[0] && frameDataModel.options[0].size) ? +frameDataModel.options[0].size.eye : undefined,
      upc: (frameDataModel.options && frameDataModel.options[0]) ? frameDataModel.options[0].upc : undefined,
      sku: (frameDataModel.options && frameDataModel.options[0]) ? frameDataModel.options[0].sku : undefined,
      temple: (frameDataModel.options && frameDataModel.options[0] && frameDataModel.options[0].size) ? frameDataModel.options[0].size.temple : undefined,
      wholeSalePrice: (frameDataModel.options && frameDataModel.options[0] && frameDataModel.options[0].wholeSalePrice) ? frameDataModel.options[0].wholeSalePrice.toString() : undefined,
      manufacturer: (frameDataModel.manufacturer) ? frameDataModel.manufacturer.name : undefined,
      material: (frameDataModel.material) ? FrameConstants.frameMaterials.filter(element => element.value === frameDataModel.material)[0].label : undefined,
      model: (frameDataModel.name) ? frameDataModel.name : undefined,
      shape: (frameDataModel.shape && frameDataModel.shape.shape) ? FrameConstants.shapes.filter(element => element.value === frameDataModel.shape.shape)[0].label : undefined,
      imageLink: {
        href: (frameDataModel.images && frameDataModel.images.length > 0) ? frameDataModel.images[0].mainUrl : undefined,
        type: undefined,
        contextRootPlus: undefined,
        rel: undefined
      }
    };
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      supplier: {
        control: this.frameForm.controls.supplier,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'The frame supplier is required'
        }]
      },
      query: {
        control: this.frameForm.controls.query,
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'Enter a valid frame attribute'
        }]
      }
    };
  }


  private updateIfFrameSupplierRequired(): void {
    const lensSelectedWithFinishingNotInOfficeStockLens = (
      !isNullOrUndefined(this.activeClaim) &&
      !isNullOrUndefined(this.activeClaim.labOrderInformation) &&
      !isNullOrUndefined(this.activeClaim.labOrderInformation.lens) &&
      !isStringNullUndefinedOrEmpty(this.activeClaim.labOrderInformation.lens.externalId) &&
      this.activeClaim.labOrderInformation.lensFinishing !== LensFinishingTypes.InOfficeStockLenses
    );
    this.isFrameSupplierRequired = (lensSelectedWithFinishingNotInOfficeStockLens || this.frameIsSelected);
    if (this.isFrameSupplierRequired) {
      this.frameForm.controls.supplier.setValidators(Validators.required);
      this.frameForm.controls.supplier.updateValueAndValidity();
    } else {
      this.frameForm.controls.supplier.clearValidators();
      this.frameForm.controls.supplier.updateValueAndValidity();
    }
  }

  private removeVendorSupplierFromDropDown() {
    let indexOfNewSupplierElement = -1;
    this.selectOptions.suppliers.forEach((e, index) => {
      if (e.value === FrameConstants.vendorSupplier.value && e.label === FrameConstants.vendorSupplier.label) {
        indexOfNewSupplierElement = index;
      }
    });
    if (indexOfNewSupplierElement > 0) {
      this.selectOptions.suppliers.splice(indexOfNewSupplierElement, 1);
    }
  }

  private addVendorSupplierToDropDown() {
    let indexOfNewSupplierElement = -1;
    this.selectOptions.suppliers.forEach((e, index) => {
      if (e.value === FrameConstants.vendorSupplier.value && e.label === FrameConstants.vendorSupplier.label) {
        indexOfNewSupplierElement = index;
      }
    });
    if (indexOfNewSupplierElement < 0) {
      this.selectOptions.suppliers.push(FrameConstants.vendorSupplier);
    }
  }

  /**
   * Helper method to focus an element by ID.
   *
   * @param elementId
   * @param value
   */
  private setAttributeForHTMLElement(elementId: string, value: string): void {
    const htmlElement: HTMLElement = document.getElementById(elementId) as HTMLElement;
    if (htmlElement) {
      htmlElement.setAttribute(FrameConstants.attributes.tabIndex, value);
    }
  }

  /**
   * Function was added to reset the KScope values to the original values.
   *
   * @private
   */
  private resetKScopeValuesToOriginalValue(): void {
    this.isTopSellingFrame = false;
    this.isFrameDispositionHasErrors = false;
    this.isSkuMissingInFrame = false;
    this.isManufacturerNotParticipating = false;
    this.isSlowSellingFrameAtPractice = false;
    this.isSlowSellingFrameNotAtPracticeOrderable = false;
    this.isSlowSellingFrameNotAtPracticeNotOrderable = false;
    this.isBackOrderedFrame = false;
    // ECLAIM-191 - This logic was added to handle IOF KScope Lenses.
    this.isIOFLensAndFrameIsTopSellerOrFrameIsSlowSellerAndAvailableInOffice = false;
    this.isIOFLensAndFrameIsSlowSellerAndNotAvailableInOffice = false;
  }

  /**
   * Function was added to set the focus to the frame card.
   *
   * @private
   */
  private setFocusOnFrameCard(): void {
    // Needed to a delay here to avoid the debounce time we have on all the components.
    delay(ApplicationConstants.oneThousand).then(() => {
      const frameCardTittle: HTMLElement = document.getElementById(HtmlConstants.attributes.frameCardTitle);
      if (frameCardTittle) {
        this.fulfillmentService.isFulfillmentApiCallFromClaimNavigationService = false;
        frameCardTittle.parentElement.parentElement.parentElement.scrollIntoView({behavior: 'smooth'});
        frameCardTittle.focus();
      }
    });
  }

  /**
   * Method saves off the current KScope banner in the fulfillment service to be used later.
   *
   * @private
   */
  private setCurrentKScopeBanner(): void {
    let currentKScopeBanner: string;

    if (this.isTopSellingFrame === true) {
      currentKScopeBanner = KScopeBanners.TOP_SELLER_KEEP_FRAME_AT_OFFICE;

    } else if (this.isFrameDispositionHasErrors === true) {
      currentKScopeBanner = KScopeBanners.K_SCOPE_IS_UNAVAILABLE_FOR_THIS_FRAME;

    } else if (this.isSkuMissingInFrame === true) {
      currentKScopeBanner = KScopeBanners.K_SCOPE_INF0_IS_UNAVAILABLE_FOR_THIS_FRAME;

    } else if (this.isManufacturerNotParticipating === true) {
      currentKScopeBanner = KScopeBanners.THE_SELECTED_FRAME_IS_NOT_ELIGIBLE_FOR_K_SCOPE;

    } else if (this.isSlowSellingFrameAtPractice === true) {
      currentKScopeBanner = KScopeBanners.SEND_THIS_FRAME_TO_THE_LAB;

    } else if (this.isSlowSellingFrameNotAtPracticeOrderable === true) {
      currentKScopeBanner = KScopeBanners.FRAME_AVAILABLE_FROM_VENDOR;

    } else if (this.isSlowSellingFrameNotAtPracticeNotOrderable === true) {
      currentKScopeBanner = KScopeBanners.FRAME_NOT_AVAILABLE_FROM_VENDOR;

    } else if (this.isBackOrderedFrame === true) {
      currentKScopeBanner = KScopeBanners.FRAME_BACK_ORDERED_FROM_VENDOR;
    }
    this.fulfillmentService.currentKScopeBanner = currentKScopeBanner;
  }

  /**
   * Method uses the saved off KScope banner in the service to render it in the frame component.
   * NOTE: This is intended to be used by the supplier drop down. If you are planning to use this
   * somewhere else please make sure Tendencies are met before hand.
   * @private
   */
  private renderCurrentKScopeBanner(): void {
    const serviceCurrentKScopeBanner: string = this.fulfillmentService.currentKScopeBanner;

    if (serviceCurrentKScopeBanner === KScopeBanners.TOP_SELLER_KEEP_FRAME_AT_OFFICE) {
      this.isTopSellingFrame = true;
      // This was added as a just in case here. We want to make sure that this flag is set in this condition.
      this.fulfillmentService.participatesInKScope = true;
      // We added this observable for ECLAIM-10 to notify the lab.component that a change was made so that it can update the lab list appropriately.
      this.fulfillmentService.onUpdateLabList.next(true);

    } else if (serviceCurrentKScopeBanner === KScopeBanners.K_SCOPE_IS_UNAVAILABLE_FOR_THIS_FRAME) {
      this.isFrameDispositionHasErrors = true;

    } else if (serviceCurrentKScopeBanner === KScopeBanners.K_SCOPE_INF0_IS_UNAVAILABLE_FOR_THIS_FRAME) {
      this.isSkuMissingInFrame = true;

    } else if (serviceCurrentKScopeBanner === KScopeBanners.THE_SELECTED_FRAME_IS_NOT_ELIGIBLE_FOR_K_SCOPE) {
      this.isManufacturerNotParticipating = true;

    } else if (serviceCurrentKScopeBanner === KScopeBanners.SEND_THIS_FRAME_TO_THE_LAB) {
      this.isSlowSellingFrameAtPractice = true;
      // This is to correctly display the 'View replacement frame options' link in the dialog.
      this.setDisplayViewReplacementFrameOptionsLink();
      // Need to add this flag to true, so we submit the frame correctly to the fulfilment api on submit.
      this.fulfillmentService.participatesInKScope = true;
      // Per New Acs we need to update the lab list to remove the categories when the supplier is doctor supplied and banner is 'send this frame to the lab'.
      this.fulfillmentService.onUpdateLabListToRemoveCategories.next(true);

    } else if (serviceCurrentKScopeBanner === KScopeBanners.FRAME_AVAILABLE_FROM_VENDOR) {
      this.isSlowSellingFrameNotAtPracticeOrderable = true;
      // Need to add this flag to true, so we submit the frame correctly to the fulfilment api on submit.
      this.fulfillmentService.participatesInKScope = true;
      // We added this observable for ECLAIM-10 to notify the lab.component that a change was made so that it can update the lab list appropriately.
      this.fulfillmentService.onUpdateLabList.next(true);

    } else if (serviceCurrentKScopeBanner === KScopeBanners.FRAME_NOT_AVAILABLE_FROM_VENDOR) {
      this.isSlowSellingFrameNotAtPracticeNotOrderable = true;
      // Need to add this flag to true, so we submit the frame correctly to the fulfilment api on submit.
      this.fulfillmentService.participatesInKScope = false;
      // We added this observable for ECLAIM-10 to notify the lab.component that a change was made so that it can update the lab list appropriately.
      this.fulfillmentService.onUpdateLabList.next(true);

    } else if (serviceCurrentKScopeBanner === KScopeBanners.FRAME_BACK_ORDERED_FROM_VENDOR) {
      this.isBackOrderedFrame = true;
      // Need to add this flag to true, so we submit the frame correctly to the fulfilment api on submit.
      this.fulfillmentService.participatesInKScope = true;
      // We added this observable for ECLAIM-10 to notify the lab.component that a change was made so that it can update the lab list appropriately.
      this.fulfillmentService.onUpdateLabList.next(true);
    }
  }

  /**
   * This function was created to extract the common doctor supplied override logic from the 'onSupplierChange' function into a common function.
   *
   * @private
   */
  private doctorSuppliedOverrideUsed(): void {
    // If the user clicks on use Doctor Supplied button after selecting Doctor supplied from the supplier dropdown,
    // we need to update the Fulfillment service with the doctor supplied doctor selection,
    // so it can be used on the submit claim portion. We also added another flag, 'isDoctorSuppliedFromSupplierOverride',
    // so the service knows that we need so request a replacement for the frame on submit.
    this.fulfillmentService.selectedSupplier = Suppliers.DOCTOR;
    // This flag was added for ECLAIM-28 newly added AC: If doctor supplied and an override then send a replacement call.
    this.fulfillmentService.isDoctorSuppliedFromSupplierOverride = true;
    // Per team discussion this was added to change KScope banner message from top-selling frame to 'Send this frame to the lab' message.
    this.resetKScopeValuesToOriginalValue();
    this.fulfillmentService.currentKScopeBanner = KScopeBanners.SEND_THIS_FRAME_TO_THE_LAB;
    this.renderCurrentKScopeBanner();
    // After changes are made we need to update the data model to make sure it represents what was selected.
    this.updateDataModelFromViewModel();
    // In order to get around the issue where the kScope guidance banner is taking forever to load we need to force the change detector to trigger.
    this.changeDetector.detectChanges();
  }

  /**
   * This function was created to extract the common use vendor supplied logic from the 'onSupplierChange' function into a common function.
   *
   * @private
   */
  private resetToVendorSuppliedSelection(): void {
    // Since the dropdown value has been selected already to doctor supplied,
    // we want to revert the dropdown back to what we have in the fulfillment service which was set after the
    // dispositions call had finished.
    this.frameForm.controls.supplier.setValue(this.fulfillmentService.selectedSupplier);
    // Make sure to reset the lab list to have categories if there was an override that already happened.
    if (this.fulfillmentService.isDoctorSuppliedFromSupplierOverride) {
      // We want to set this flag back to false, so we are not making a supplier call everytime the user dismisses the modal.
      // We only want to do this if the user has overridden the selection, and then they decide to go back to the original selection.
      this.fulfillmentService.isDoctorSuppliedFromSupplierOverride = false;
      // Per New Acs we need to update the lab list to remove the categories when the user overrides the order from vendor selection to doctor supplied
      this.fulfillmentService.onUpdateLabList.next(true);
    }
    // After changes are made we need to update the data model to make sure it represents what was selected.
    this.updateDataModelFromViewModel();
    // In order to get around the issue where the kScope guidance banner is taking forever to load we need to force the change detector to trigger.
    this.changeDetector.detectChanges();
  }

  private setDisplayViewReplacementFrameOptionsLink(): void {
    if (this.replacementFrameService.displayViewReplacementFrameOptionsLink === true) {
      this.displayViewReplacementFrameOptionsLink = true;
    }
  }

  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - EVENT HANDLERS *****/
  onSearchClick(): void {
    // set the searching flag to true
    this.searchingFrames = true;

    // invoke the API to perform the search with the given query
    this.searchingFrames = false;

    // initialize the labOrderInformation object if it doesn't already exist on a claim:
    if (!this.activeClaim.labOrderInformation) {
      this.activeClaim.labOrderInformation = {} as LabOrderInformation;
    }
    this.dialog.open(FrameSearchResultsModalComponent, {
      data: {
        frameSearchResults: {
          results: [],
          lastQuery: ApplicationConstants.emptyString,
        },
        query: this.frameForm.controls.query.value,
        selectedFrame: this.selectedFrame,
        frameSupplier: this.activeClaim.labOrderInformation.frameSupplier
      },
      width: '97%',
      maxHeight: window.innerHeight + 'px',
      // prevent the modal from closing automatically if user accidentally clicks outside the modal boundary.
      disableClose: true
    }).afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data) {

          this.allowFullFrameDetailEdit = data.allowFullFrameDetailEdit;
          this.selectedFrame = null;
          if (!isNullOrUndefined(data.frame)) {
            this.selectedFrame = data.frame;
            // ECR-10044
            this.frameIsSelected = true;
          }
          this.updateIfFrameSupplierRequired();

          setTimeout(() => {
            this.updateDataModelFromViewModel();
            // In order to get around the issue where the kScope guidance banner is taking forever to load we need to force the change detector to trigger.
            this.changeDetector.detectChanges();
          }, 350);
        }
      });
    this.frameForm.controls.query.setValue(ApplicationConstants.emptyString);
  }

  clearSelectedFrame(removeFrameConfirmed: boolean) {
    if (removeFrameConfirmed) {

      this.selectedFrame = FrameConstants.emptyFrame;

      // ECR-6077 AC4
      // ECLAIM-191 the below if statement was added to not add focus to the frame.
      // This was added to handle the situation where focus was placed when a non
      // IOF lens finishing wass elected and the user was navigated away from the
      // lens section.
      if (!this.userSessionService.isKaleyedoscopePractice) {
        const el = document.getElementById('frame-details-display-remove-button');
        if (el) {
          el.focus();
        }
      }
      this.frameForm.get('query').setValue(null); // ECR-6077 AC4a
      this.frameForm.get('supplier').setValue(null); // ECR-6077 AC4b

      // ECR-10044
      this.frameIsSelected = false;
      this.removeVendorSupplierFromDropDown();
      this.updateIfFrameSupplierRequired();
      // Added this to remove the KScope information from the frame.
      this.resetKScopeValuesToOriginalValue();
      this.fulfillmentService.resetServiceValuesToOriginalState();
      // We want to reset the lab list to its original state when frame is removed.
      this.fulfillmentService.onUpdateLabList.next(true);

      this.updateDataModelFromViewModel();
      // In order to get around the issue where the kScope guidance banner is taking forever to load we need to force the change detector to trigger.
      this.changeDetector.detectChanges();
    }
  }

  onSupplierChange() {
    const {supplier} = this.frameForm.controls;
    // This condition was added for ECLAIM-28 to accommodate KScope changes.
    if (this.isKaleyedoscopePractice && this.fulfillmentService.dispositionCallFinishedSuccessfully) {
      // This was added for ECLAIM-28 to set the current banner in the service to be used later in the logic.
      if (isStringNullUndefinedOrEmptyWithTrim(this.fulfillmentService.currentKScopeBanner)) {
        this.setCurrentKScopeBanner();
      }
      // This check was added to make sure we render the Frame Source warning modal on specific conditions.
      if (this.fulfillmentService.isTopSellingFrame && supplier.value === Suppliers.DOCTOR && this.fulfillmentService.selectedSupplier === Suppliers.VENDOR) {
        // Per ECLAIM-275 - We want to reset some values in the service and set the replacementModalRenderedFromSupplierOverrideModal flag to true.
        this.replacementFrameService.resetReplacementFrameServiceValues();
        this.replacementFrameService.replacementModalRenderedFromSupplierOverrideModal = true;
        this.dialog.open(FrameSourceModalComponent, {
          width: '680px',
          disableClose: true,
          panelClass: 'eclaim-popup-modal'
        }).afterClosed().subscribe((onUseDoctorSuppliedButtonClick: boolean) => {
          // NOTE: This button is now the Use Doctor Supplied Button
          if (onUseDoctorSuppliedButtonClick) {
            // Per ECLAIM-275 - We now want to check to see if the user has the '' flag turned on, so they can select their replacement frame.
            // If not business will continue as usual.
            // NOTE: The 'displayReplacementChoiceIndicator' will only be set to 'Y' if there is also frame choices returned.
            if (this.replacementFrameService.displayReplacementChoiceIndicator === ReplacementChoiceIndicatorEnum.Y) {
              this.dialog.open(ReplacementFrameModalComponent, {
                width: '680px',
                disableClose: true,
                panelClass: 'eclaim-popup-modal'
              }).afterClosed().subscribe((onReplacementFrameModalButtonClick: string) => {
                if (onReplacementFrameModalButtonClick === ReplaceFrameEnum.Cancel) {
                  // If cancel is pressed we want to close the modal, reset the user selected option to undefined and leave the frame card as it was.
                  this.replacementFrameService.userSelectedReplacementSku = undefined;
                  // Reset to Vendor supplied if cancel is clicked.
                  this.resetToVendorSuppliedSelection();
                } else if (onReplacementFrameModalButtonClick === ReplaceFrameEnum.Continue) {
                  // If continue is pressed we want to close the modal, and continue with the same doctor supplied override logic we had in place.
                  // We do not need to do anything with the member selected frame as that will be taken care of by the replacement frame modal and service.
                  this.doctorSuppliedOverrideUsed();
                }
              });
            } else {
              this.doctorSuppliedOverrideUsed();
            }
          } else {
            this.resetToVendorSuppliedSelection();
          }
          // After changes are made we need to update the data model to make sure it represents what was selected.
          this.updateDataModelFromViewModel();
          // In order to get around the issue where the kScope guidance banner is taking forever to load we need to force the change detector to trigger.
          this.changeDetector.detectChanges();
        });
      } else {
        // Added for ECLAIM-275. If the 'displayReplacementChoiceIndicator' flag is set to 'Y', we want to rest it.
        // This was to catch the situations when the user selects doctor supplied when it is vendor supplied and then the user selects vendor supplied again.
        // We want to make sure that we reset the flag accordingly.
        if (this.replacementFrameService.displayReplacementChoiceIndicator === ReplacementChoiceIndicatorEnum.Y && !isStringNullUndefinedOrEmptyWithTrim(this.replacementFrameService.userSelectedReplacementSku)) {
          this.replacementFrameService.userSelectedReplacementSku = undefined;
        }
        // This was added to accommodate the condition where the frame is back ordered and frame available to order flags are set and the user overrides
        if ((this.isSlowSellingFrameNotAtPracticeOrderable === true || this.isBackOrderedFrame === true) && supplier.value === Suppliers.DOCTOR && this.fulfillmentService.selectedSupplier === Suppliers.VENDOR) {
          // Setting this flag to false means on submission we will not do anything with DropShip or Replace order endpoints.
          this.fulfillmentService.participatesInKScope = false;
          // We added this observable for ECLAIM-10 to notify the lab.component that a change was made so that it can update the lab list appropriately.
          this.fulfillmentService.onUpdateLabList.next(true);
          this.resetKScopeValuesToOriginalValue();
          // This is needed to remove all KScope banners if the doctor uses any of the below options.
        } else if (supplier.value !== Suppliers.DOCTOR && supplier.value !== Suppliers.VENDOR) {
          this.removeVendorSupplierFromDropDown();
          // Setting this flag to false means on submission we will not do anything with DropShip or Replace order endpoints.
          this.fulfillmentService.participatesInKScope = false;
          // We added this observable for ECLAIM-10 to notify the lab.component that a change was made so that it can update the lab list appropriately.
          this.fulfillmentService.onUpdateLabList.next(true);
          this.resetKScopeValuesToOriginalValue();
          // Only do this if it is a top-selling frame.
        } else if (this.fulfillmentService.isTopSellingFrame) {
          // This was added to remove the vendor supplied item in hee supplier dropdown and to remove KScope banners if the provider selects Patient Supplied.
          this.resetKScopeValuesToOriginalValue();
          if (supplier.value === Suppliers.DOCTOR) {
            // ECLAIM-191 - This logic was added to handle IOF KScope Lenses.
            if (!this.fulfillmentService.isInOfficeStockLens && !this.fulfillmentService.isInOfficeUncutLens) {
              // Per team discussion this was added to change KScope banner message from top-selling frame to 'Send this frame to the lab' message.
              this.fulfillmentService.currentKScopeBanner = KScopeBanners.SEND_THIS_FRAME_TO_THE_LAB;
              // Added this to make sure we add the 'Order from vendor' option if selected frame is top-selling
              this.addVendorSupplierToDropDown();
            }
          } else if (supplier.value === Suppliers.VENDOR) {
            // Per team discussion this was added to change KScope banner message from top-selling frame to 'Top seller. Keep this frame at your office' message.
            this.fulfillmentService.currentKScopeBanner = KScopeBanners.TOP_SELLER_KEEP_FRAME_AT_OFFICE;
          }
          // Set the participates in KScope flag we use on submission to true for dropShip and replace order calls.
          this.fulfillmentService.participatesInKScope = true;
          this.renderCurrentKScopeBanner();
        } else {
          this.renderCurrentKScopeBanner();
          // We want to add the vendor supplied back to the dropdown if the below flags are true.
          if (this.isSlowSellingFrameNotAtPracticeOrderable === true || this.isBackOrderedFrame === true) {
            this.addVendorSupplierToDropDown();
            if (supplier.value === Suppliers.DOCTOR) {
              // Setting this flag to false means on submission we will not do anything with DropShip or Replace order endpoints.
              this.fulfillmentService.participatesInKScope = false;
              // We added this observable for ECLAIM-10 to notify the lab.component that a change was made so that it can update the lab list appropriately.
              this.fulfillmentService.onUpdateLabList.next(true);
              this.resetKScopeValuesToOriginalValue();
            }
          }
        }
        // We need to update the selectedSupplier flag in Fulfillment service when the above condition is not met, so we
        // can properly build the submit order request.
        this.fulfillmentService.selectedSupplier = supplier.value;
        // After changes are made we need to update the data model to make sure it represents what was selected.
        this.updateDataModelFromViewModel();
        // In order to get around the issue where the kScope guidance banner is taking forever to load we need to force the change detector to trigger.
        this.changeDetector.detectChanges();
      }
      // Continue on your merry way when it is not KScope related.
    } else {
      this.updateDataModelFromViewModel();
      // In order to get around the issue where the kScope guidance banner is taking forever to load we need to force the change detector to trigger.
      this.changeDetector.detectChanges();
    }
  }

  onHelpLinkClick(event: Event): void {
    event.preventDefault();
    this.dialog.open( FrameSearchHelpModalComponent, { panelClass: 'sticky-footer-popup-modal' } );
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onDetailsEdit(inEditMode: boolean) {
    this.editingDetails = inEditMode;
  }

  onSaveAfterEdit(frame: Frame) {
    this.editingDetails = false;
    this.selectedFrame = frame;
    this.updateDataModelFromViewModel();
  }

  onViewFrameReplacementOptionsClick(): void {
    // Per ECLAIM-276 - We want to reset some values in the service and set the replacementModalRenderedFromFrameCard flag to true.
    this.replacementFrameService.resetReplacementFrameServiceValues();
    this.replacementFrameService.replacementModalRenderedFromFrameCard = true;
    // Get the current 'userSelectedReplacementSku' if there is any already saved from the doctor supplied override modal flow.
    let currentUserSelectedReplacementSku: string;
    if (!isStringNullUndefinedOrEmptyWithTrim(this.replacementFrameService.userSelectedReplacementSku)) {
      // Note we need to use deep clone in this situation since when the modal is rendered we override what is currently saved there.
      // Deep clone insures we do not lose the original value.
      currentUserSelectedReplacementSku = Object.deepClone(this.replacementFrameService.userSelectedReplacementSku);
    }
    this.dialog.open(ReplacementFrameModalComponent, {
      width: '680px',
      disableClose: true,
      panelClass: 'eclaim-popup-modal'
    }).afterClosed().subscribe((onReplacementFrameModalButtonClick: string) => {
      console.log('resetToVendorSuppliedSelection was triggered Again');
      if (onReplacementFrameModalButtonClick === ReplaceFrameEnum.Cancel) {
        // If cancel is pressed we want to close the modal, reset the user selected option to what it was originally if it is available.
        this.replacementFrameService.userSelectedReplacementSku = currentUserSelectedReplacementSku;
      } else if (onReplacementFrameModalButtonClick === ReplaceFrameEnum.Continue) {
        // If continue is pressed we want to close the modal, and for now, do nothing as the modal window will take care of setting the correct replacement sku if cancel is not selected.
      }
    });
  }

  /***** END - EVENT HANDLERS *****/
}
