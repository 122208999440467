import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ServicesConstants } from '../services.constants';
import { ApplicationConstants, ErrorTypes } from '../../../../common/constants/application.constants';
import { MatDialog } from '@angular/material/dialog';
import { DiagnosisCodeListModalComponent } from './diagnosis-code-list-modal/diagnosis-code-list-modal.component';
import { isNullOrUndefined, isStringNullUndefinedOrEmpty } from '../../../../common/utility';
import { HtmlConstants } from '../../../../common/constants/html.constants';

@Component({
  selector: 'app-diagnosis-code',
  templateUrl: './diagnosis-code.component.html',
  styleUrls: ['./diagnosis-code.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DiagnosisCodeComponent implements OnInit {

  /***** START - PUBLIC MEMBERS *****/
  @Input() diagnosisCode: FormGroup;
  @Input() index: number;
  public position: string;
  public errorWrapperConfig;
  /***** END - PUBLIC MEMBERS *****/

  constructor(
    private dialog: MatDialog
  ) { }

  /***** START - PRIVATE METHODS *****/
  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      invalidDiagnosisCode: {
        control: this.diagnosisCode.get(ServicesConstants.DIAGNOSIS_CODE),
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'Please enter a valid diagnosis code'
        }]
      }
    };
  }
  /***** END - PRIVATE METHODS *****/

  /***** START - LIFECYCLE HOOKS *****/
  ngOnInit() {
    this.position = this.diagnosisCode.get(ServicesConstants.DIAGNOSIS_POSITION).value;
    this.buildErrorWrapperConfig();

    // Register a control value update of DX codes if entered manually with lowercase alpha to normalize the case
    const dxCodeControlReference: AbstractControl = this.diagnosisCode.get(ServicesConstants.DIAGNOSIS_CODE);
    dxCodeControlReference.valueChanges.subscribe((dxCode: any) => {
      if (!isStringNullUndefinedOrEmpty(dxCode as string) && new RegExp(/^.*[a-z].*$/).test(dxCode as string)) {
        const normalizedControlValue: string = (dxCode as string).toUpperCase();
        dxCodeControlReference.patchValue(normalizedControlValue, ApplicationConstants.updateFormWithoutEmit);
      }
    });
  }
  /***** END - LIFECYCLE HOOKS *****/


  /***** START - EVENT HANDLERS *****/
  openDiagnosisCodeListModal() {
    const dialogRef = this.dialog.open(DiagnosisCodeListModalComponent, {
      panelClass: 'sticky-footer-popup-modal'
    });
    dialogRef.afterClosed().subscribe((selectedDiagnosisCode) => {
      if (!isStringNullUndefinedOrEmpty(selectedDiagnosisCode)) {
        this.diagnosisCode.get(ServicesConstants.DIAGNOSIS_CODE).setValue(selectedDiagnosisCode);
      }
    });
  }

  /**
   * Blur event handler for the DX code inputs, used to target the first service line charges field when the user is tab navigating.
   *
   * @param {FocusEvent} event - Emitted blur event used to determine next element to target
   */
  onDiagnosisCodeBlur(event: FocusEvent): void {
    // Return the UI thread if no target focus is available
    if (isNullOrUndefined(event.relatedTarget)) {
      return;
    }

    // Focus the first charges field if the user is on the last diagnosis code and tab focusing to the service lines
    if (event.relatedTarget['id'] === HtmlConstants.attributes.firstEmgCheckboxInputId) {
      const firstChargesField: HTMLElement = document.getElementById(HtmlConstants.attributes.firstBilledAmountId);
      if (firstChargesField) {
        firstChargesField.focus();
      }
    }
  }
  /***** END - EVENT HANDLERS *****/
}
