import { Injectable } from '@angular/core';
import {CanActivate, CanActivateChild} from '@angular/router';
import {UserSessionService} from '../../../support/user-session/user-session.service';
import {Observable} from 'rxjs';

@Injectable()
export class IsAuthenticatedRouteGuard implements CanActivate, CanActivateChild {


  constructor(
    private userSessionService: UserSessionService,
  ) { }


  /***** START - PUBLIC FUNCTIONS *****/
  /**
   * Implementing canActivate
   * @returns {Observable<boolean>}
   */
  canActivate(): Observable<boolean> {
    return this.userSessionService.isSessionActive();
  }

  /**
   * Implementing canActivateChild
   * @returns {Observable<boolean>}
   */
  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
  /***** END - PUBLIC FUNCTIONS *****/

}
