import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {IBreadCrumb} from '../../models/breadcrumb';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {getFormattedFullName, isNullOrUndefined, isStringNullUndefinedOrEmpty} from '../../common/utility';
import {ConsumerService} from '../../common/services/data-model/app/consumer/consumer.service';
import {CookieService} from 'ngx-cookie-service';
import {BusinessMemberPoliciesResults} from '../../models/consumer';
import {ApplicationConstants} from '../../common/constants/application.constants';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IBreadCrumb[];
  private _businessMemberPoliciesResults: BusinessMemberPoliciesResults;
  private _route: ActivatedRoute;
  private eyefinityUrl: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private consumerService: ConsumerService,
    private cookieService: CookieService
  ) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  get businessMemberPoliciesResults(): BusinessMemberPoliciesResults {
    return (this._businessMemberPoliciesResults) ? Object.deepClone(this._businessMemberPoliciesResults) : this._businessMemberPoliciesResults;
  }

  set businessMemberPoliciesResults(businessMemberPoliciesResults: BusinessMemberPoliciesResults) {
    this._businessMemberPoliciesResults = businessMemberPoliciesResults;
  }

  get route(): ActivatedRoute {
    return this._route;
  }

  set route(route: ActivatedRoute) {
    this._route = route;
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.route = this.activatedRoute.root;
      this.breadcrumbs = this.buildBreadCrumb(this.route);
    });
  }

  /**
   * Recursively build breadcrumb according to activated route. This accepts an ActiveRoute (required),
   * which it uses to recursively go through the Route and check to see if there is a breadcrumb
   * defined in the router config, if so it will recursively build a Breadcrumb list with a label
   * and a path variables for each breadcrumb
   * @param route
   * @param url
   * @param breadcrumbs
   * @param reminderUrl
   * @param reminderBreadcrumb
   * @returns IBreadcrumb[]
   */
  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = [], reminderUrl: string = '', reminderBreadcrumb: string = ''): IBreadCrumb[] {
    let urlPath = reminderUrl;
    let breadcrumbPath = reminderBreadcrumb;

    // Check to see if there is a breadcrumb set in the route config,
    // if so retrieve the url and the breadcrumb paths from the router config
    if (isStringNullUndefinedOrEmpty(reminderUrl)) {
      urlPath = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
      breadcrumbPath = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    }

    // creates a list out of the urlPath and then retrieves and removes the first value
    const urlPathList = urlPath.split('/');
    const nextUrlPath = urlPathList.shift();

    // creates a list out of the breadcrumbPath and then retrieves and removes the first value
    const breadcrumbPathList = breadcrumbPath.split('/');
    let label = breadcrumbPathList.shift();

    // In the routeConfig the complete path might not available,
    // so we rebuild it each time
    const nextUrl = nextUrlPath ? `${url}/${nextUrlPath}` : url;

    // Changes "patient-selection" into the patient's last, first name
    // Retrieves the patient's info from the session storage
    // if its undefined the label stays as "patient-selection"
    if (nextUrlPath === 'patient-selection') {
      this.consumerService.getMemberRetrieveResultsOrRecallApiIfDataIsNotInMemory().subscribe( (businessMemberRetrieveResults: BusinessMemberPoliciesResults) => {
        if (businessMemberRetrieveResults) {
          this.businessMemberPoliciesResults = businessMemberRetrieveResults;
          label = getFormattedFullName(businessMemberRetrieveResults.firstName, businessMemberRetrieveResults.lastName);
          if (!isNullOrUndefined(this.breadcrumbs) && this.breadcrumbs.length > 0) {
            // tslint:disable-next-line:no-shadowed-variable
            const patientSelectionBreadcrumb = this.breadcrumbs.find((breadcrumb: IBreadCrumb) => breadcrumb.label === 'patient-selection');
            if (!isNullOrUndefined(patientSelectionBreadcrumb) && !isNullOrUndefined(patientSelectionBreadcrumb.label)) {
              patientSelectionBreadcrumb.label = label;
            }
          }
        }
      });
    }

    // Creates the breadcrumb variable to be added to the list of breadcrumbs
    const breadcrumb: IBreadCrumb = {
      label: label,
      url: '/secure' + nextUrl,
    };

    // Only adding route/breadcrumb with non-empty label to the list of breadcrumbs
    const newBreadcrumbs = breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
    // If we are not on our last child yet we need to go through the remaining
    // children to build all of the breadcrumbs
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    } else if (urlPathList.length > 0) {
      // We have reached the last child we need to recursively go through the urlPathList
      // to create all the remaining breadcrumbs
      return this.buildBreadCrumb(route, nextUrl, newBreadcrumbs, urlPathList.join('/'), breadcrumbPathList.join('/'));
    }
    return newBreadcrumbs;
  }

  // Navigates to eInsurance when clicked on 'eClaim' breadcrumb on Member Search Page
  navigateToLegacyEInsurance() {
    this.eyefinityUrl = this.cookieService.get(ApplicationConstants.efSurlCookie);
    window.location.href = this.eyefinityUrl + '/servlet/COREServlet?msgProc=520&pageMode=NEXTINFO';
  }
}
