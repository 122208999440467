import { Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {MessageService} from '../message/message.service';
import {ApplicationConstants, SessionStorageKeys} from '../../../constants/application.constants';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DrReportsNavigationService {
  constructor(private cookieService: CookieService,
              private messageService: MessageService) { }

  navigateToPCPForm(authorizationNumber: string): void {
    let phone: string = this.cookieService.get('VSPIDC_PHONE');
    if (phone.length > 10) {
      phone = phone.substring(0, 10);
    }

    const windowSize = 'status=0,title=0,height=600,width=800,scrollbars=1';
    const url = `/reports/html/reports.jsp?msgProcessor=1&rep=rep&authNum=${authorizationNumber}&phone=${phone}&origSys=C&msgProc=pcp&newEclaim=Y`;
    this.navigateToDrReports(url, 'Patient Record Report', windowSize);
  }

  navigateToPatientRecordReport(authorizationNumber: string, showFailedToOpenDrWindowErrorMessage: boolean = false): void {
    let phone: string = this.cookieService.get('VSPIDC_PHONE');
    if (phone.length > 10) {
      phone = phone.substring(0, 10);
    }

    const windowSize = 'status=0,title=0,height=600,width=800,scrollbars=1';
    const url = `/reports/html/reports.jsp?msgProcessor=1&rep=rep&authNum=${authorizationNumber}&phone=${phone}&origSys=C&msgProc=ptrec&newEclaim=Y`;
    this.navigateToDrReports(url, 'Patient Record Report', windowSize, showFailedToOpenDrWindowErrorMessage );
  }

  navigateToVSPPlanSummary(asOfDate: string, firstName: string, lastName: string): void {
    const subscriberClientId = sessionStorage.getItem(SessionStorageKeys.SubscriberClientId);
    const subscriberDivisionId = sessionStorage.getItem(SessionStorageKeys.SubscriberDivisionId);
    const subscriberConsumerId = sessionStorage.getItem(SessionStorageKeys.SubscriberConsumerId);
    const patientConsumerId = sessionStorage.getItem(SessionStorageKeys.PatientConsumerId);
    const patientName = lastName + ', ' + firstName;
    // Passing an empty string as a window size will result in a new tab rather than a new window.
    const windowSize = '';
    const url = `/servlet/Reports?msgProc=wvps&subscriberconsumerid=${subscriberConsumerId}&clientid=${subscriberClientId}&divisionid=${subscriberDivisionId}&patientname=${patientName}&patientconsumerid=${patientConsumerId}&asofdate=${asOfDate}&newEclaim=Y`;
    // Navigate to the VSP Plan Summary Doctor Report
    this.navigateToDrReports(url, 'VSP Plan Summary', windowSize);
  }

  navigateToDrReports(url: string, reportErrorMessage: string, windowSize: string, showFailedToOpenDrWindowErrorMessage: boolean = false): void {
    const doctorDomain: string = environment.doctorUrl;

    const eyefinityHandOffForm = document.createElement('form');
    eyefinityHandOffForm.target = 'reportWindow';
    eyefinityHandOffForm.method = 'POST';
    eyefinityHandOffForm.action = `${doctorDomain}/servlet/EyefinityHandoff`;

    const cookiesInput = document.createElement('input');
    cookiesInput.type = 'text';
    cookiesInput.name = 'cookies';
    cookiesInput.value = this.buildEyefinityCookiesString();
    eyefinityHandOffForm.appendChild(cookiesInput);

    const destInput = document.createElement('input');
    destInput.type = 'text';
    destInput.name = 'dest';
    destInput.value = url;
    eyefinityHandOffForm.appendChild(destInput);

    document.body.appendChild(eyefinityHandOffForm);

    const doctorReportsWindow = window.open('', 'reportWindow', windowSize);
    if (doctorReportsWindow) {
      eyefinityHandOffForm.submit();
    } else if (showFailedToOpenDrWindowErrorMessage) {
      this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.doctorReportsFailMessage);
    } else {
      this.messageService.showErrorSnackbar(`There was an error trying to display the ${reportErrorMessage}. This may be due to a pop-up
      blocker installed on your computer. If you have a pop-up blocker, please disable it for the Eyefinity website.\\n For assistance,
      please contact Eyefinity’s Customer Care Team.`);
    }
    document.body.removeChild(eyefinityHandOffForm);
  }

  private buildEyefinityCookiesString(): string {
    const vspidc_access = `VSPIDC_ACCESS=${this.cookieService.get('VSPIDC_ACCESS')}`;
    const vspidc_phone = `VSPIDC_PHONE=${this.cookieService.get('VSPIDC_PHONE')}`;
    const vspidc_plans = `VSPIDC_PLANS=${this.cookieService.get('VSPIDC_PLANS')}`;
    const vspidc_officeid = `VSPIDC_OFFICEID=${this.cookieService.get('VSPIDC_OFFICEID')}`;
    const acct_nbr = `ACCT_NBR=${this.cookieService.get('ACCT_NBR')}`;
    const tef_surl = `TEF_SURL=${this.cookieService.get(ApplicationConstants.tefSurlCookie)}`;
    const cef_url = `CEF_URL=${this.cookieService.get('CEF_URL')}`;
    const vspidc_greeting = `VSPIDC_GREETING=${this.cookieService.get('VSPIDC_GREETING')}`;
    const batch = `BATCH=${this.cookieService.get('BATCH')}`;
    return [vspidc_access, vspidc_phone, vspidc_plans, vspidc_officeid, acct_nbr, tef_surl, cef_url, vspidc_greeting, batch].filter(Boolean).join(';');
  }
}
