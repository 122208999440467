import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {
  Claim,
  SoftAndHardValidationMessages,
  ValidationMessage,
  ValidationMessageLevelCode
} from '../../../models/claim';
import {ApplicationConstants, FormFieldType} from '../../../common/constants/application.constants';
import {isNullOrUndefined, isStringNullUndefinedOrEmpty} from '../../../common/utility';
import {ClaimsService} from '../../../common/services/data-model/app/claims/claims.service';
import {ServiceLine} from '../../../models/serviceLine';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';
import {Subscription} from 'rxjs';
import {ViewStateService} from '../../../common/services/view-state/view-state.service';
import {LensService} from '../../../common/services/data-model/app/lens/lens.service';

@Component({
  selector: 'app-soft-and-hard-edit-banner',
  templateUrl: './soft-and-hard-edit-banner.component.html',
  styleUrls: ['./soft-and-hard-edit-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SoftAndHardEditBannerComponent implements OnInit, OnDestroy {
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private _hardEditLength: number;
  private _currentHardEditPage: number;
  private _softEditLength: number;
  private _currentSoftEditPage: number;
  private _showHardEdits: boolean = false;
  private _showSoftEdits: boolean = false;
  private _showLensExpiredEdit: boolean = false;
  showErrorLeftArrow: boolean = false;
  showErrorRightArrow: boolean = false;
  showWarnLeftArrow: boolean = false;
  showWarnRightArrow: boolean = false;
  private observableSubscriptions: Subscription[] = [];
  private softAndHardEdits: SoftAndHardValidationMessages;

  constructor(
    private claimService: ClaimsService,
    private claimEditService: ClaimEditService,
    private viewStateService: ViewStateService,
    private lensService: LensService) {
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }

  get hardEditLength(): number {
    return this._hardEditLength;
  }

  set hardEditLength(value: number) {
    this._hardEditLength = value;
  }

  get displayHardEditMessageArrows(): boolean {
    return (this.hardEditLength > 1);
  }

  get softEditLength(): number {
    return this._softEditLength;
  }

  set softEditLength(value: number) {
    this._softEditLength = value;
  }

  get displaySoftEditMessageArrows(): boolean {
    return (this.softEditLength > 1);
  }

  get currentSoftEditPage(): number {
    return this._currentSoftEditPage;
  }

  set currentSoftEditPage(value: number) {
    this._currentSoftEditPage = value;
  }

  /*public dismissErrorPanel(): void {
    this._showHardEdits = false;
  }*/

  get showHardEdits(): boolean {
    return this._showHardEdits;
  }

  set showHardEdits(value: boolean) {
    this._showHardEdits = value;
  }

  get showSoftEdits(): boolean {
    return this._showSoftEdits;
  }

  set showSoftEdits(value: boolean) {
    this._showSoftEdits = value;
  }

  get currentHardEditPage(): number {
    return this._currentHardEditPage;
  }

  set currentHardEditPage(value: number) {
    this._currentHardEditPage = value;
  }

  get editTypeSoft() {
    return  'SOFT';
  }

  get editTypeHard() {
    return  'HARD';
  }

  get showLensExpiredEdit(): boolean {
    return this._showLensExpiredEdit;
  }

  set showLensExpiredEdit(value: boolean) {
    this._showLensExpiredEdit = value;
  }

  /**
   * When this method is being called for 2nd from the beginning element(page), it will move to the first.
   * After that we don't want the left arrow to work.
   * Thats why the condition is checking for > 2
   * @param editType
   */
  public moveLeft(editType: string): void {
    if (editType === this.editTypeHard && this.currentHardEditPage >= 2) {
      this.showErrorRightArrow = true;
      this.currentHardEditPage = this.currentHardEditPage - 1;
      if (this.currentHardEditPage === 1) {// when reached first page, block left arrow
        this.showErrorLeftArrow = false;
      }
    } else if (this.currentSoftEditPage >= 2) {
      this.showWarnRightArrow = true;
      this.currentSoftEditPage = this.currentSoftEditPage - 1;
      if (this.currentSoftEditPage === 1) {// when reached first page, block left arrow
        this.showWarnLeftArrow = false;
      }
    }
  }

  /**
   * When this method is being called for 2nd last element(page), it will move to the last.
   * After that we don't want the right arrow to work.
   * Thats why the condition is checking for < (last - 1)
   * @param editType
   */
  public moveRight(editType: string): void {
    if (editType === this.editTypeHard && this.currentHardEditPage <= this.hardEditLength - 1) {
      this.showErrorLeftArrow = true;
      this.currentHardEditPage = this.currentHardEditPage + 1;
      if (this.currentHardEditPage === this.hardEditLength) {// when reached last page, block right arrow
        this.showErrorRightArrow = false;
      }
    } else if (this.currentSoftEditPage <= this.softEditLength - 1) {
      this.showWarnLeftArrow = true;
      this.currentSoftEditPage = this.currentSoftEditPage + 1;
      if (this.currentSoftEditPage === this.softEditLength) {// when reached last page, block right arrow
        this.showWarnRightArrow = false;
      }
    }
  }

  public focusOnFieldWithError(errorMessage: ValidationMessage): void {
    if (errorMessage.fieldIds && errorMessage.fieldIds.length > 0) {
      // focus on the field that was either set as the headerId property in the config or the first in the list of ids for that given field
      const mappedFieldWithAPIError = this.getClaimFormFieldFromFieldIds(errorMessage.fieldIds);
      if (!isNullOrUndefined(mappedFieldWithAPIError)) {
        const idToFocus = (mappedFieldWithAPIError.headerId) ? mappedFieldWithAPIError.headerId : mappedFieldWithAPIError.ids[0];
        const invalidFormElements = document.querySelectorAll(idToFocus);
        if (!isNullOrUndefined(invalidFormElements)) {
          invalidFormElements.item(0).focus();
          invalidFormElements.item(0).scrollIntoView({ block: 'center', behavior: 'smooth'});
        }
      }
    }
  }

  isMappable(hardEdit: ValidationMessage): boolean {
    return ( !isNullOrUndefined(hardEdit.fieldIds) &&
      hardEdit.fieldIds.length > 0 &&
      !isNullOrUndefined(this.getClaimFormFieldFromFieldIds(hardEdit.fieldIds)));
  }

  ngOnInit() {
    this.showHardEdits = false;
    this.showSoftEdits = false;
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
        if (hasEdits) {
          this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
          if (this.softAndHardEdits) {
            this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
            this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
            // if we have hard edits returning, clean out missing error numbers coming back so we don't display undefined in UI
            if (this.hardEditMessages && this.hardEditMessages.length > 0) {
              this.currentHardEditPage = 1;
              this.hardEditLength = this.hardEditMessages.length;
              this.hardEditMessages.forEach((message) => {
                message.errorNumber = isStringNullUndefinedOrEmpty(message.errorNumber) ? '' : message.errorNumber;
              });
              this.showHardEdits = true;
              this.showErrorRightArrow = (this.hardEditLength > 1);
            }
            // if we have unacknolwedged soft edits returning, clean out missing error numbers coming back so we don't display undefined in UI
            if (this.softEditMessages && this.softEditMessages.length > 0) {
              this.currentSoftEditPage = 1;
              this.softEditLength = this.softEditMessages.length;
              this.softEditMessages.forEach((message) => {
                message.errorNumber = isStringNullUndefinedOrEmpty(message.errorNumber) ? '' : message.errorNumber;
              });
              this.showSoftEdits = true;
              this.showWarnRightArrow = (this.softEditLength > 1);
            }
          }
        } else {// This to make banner disappear when you click on action buttons.
          this.showHardEdits = false;
          this.showSoftEdits = false;
        }
      }
    ));

    // Per ECLAIM-269 we will be adding this observer that will be listening to display or not display the show lens removed edit.
    this.observableSubscriptions.push(this.lensService.onShowLensExpiredEdit.subscribe((onShowLensExpiredEdit: boolean) => {
      if (onShowLensExpiredEdit) {
        this.showLensExpiredEdit = true;
        this.lensService.onShowLensExpiredEdit.next(false);
      }
    }));

    let combinedEditMessages: ValidationMessage[] = [];
    combinedEditMessages = (!isNullOrUndefined(this.hardEditMessages) && this.hardEditMessages.length > 0) ? combinedEditMessages.concat(this.hardEditMessages) : combinedEditMessages;
    combinedEditMessages = (!isNullOrUndefined(this.softEditMessages) && this.softEditMessages.length > 0) ? combinedEditMessages.concat(this.softEditMessages) : combinedEditMessages;
    combinedEditMessages.forEach( (editMessage: ValidationMessage) => {
      if (editMessage.fieldIds && editMessage.fieldIds.length > 0) {
        editMessage.fieldIds.forEach( (fieldId: string) => {
          const idOfFieldWithAPIError = this.getClaimFormFieldFromFieldId(fieldId);
          if (idOfFieldWithAPIError) {
            idOfFieldWithAPIError.ids.forEach( (id: string) => {
              const invalidFormElements = document.querySelectorAll(id);
              if (!isNullOrUndefined(invalidFormElements)) {
                const editType = (editMessage.levelCode === ValidationMessageLevelCode.ERROR) ? 'hard' : 'soft';
                if (idOfFieldWithAPIError.fieldType === FormFieldType.textBox) {
                  invalidFormElements.item(0).parentElement.previousElementSibling.classList.add(`${editType}EditTextBoxField`);
                } else if (idOfFieldWithAPIError.fieldType === FormFieldType.radioButton) {
                  invalidFormElements.item(0).classList.add(`${editType}EditRadioButtonField`);
                } else if (idOfFieldWithAPIError.fieldType === FormFieldType.checkBox) {
                  invalidFormElements.item(0).firstElementChild.firstElementChild.lastElementChild.previousElementSibling.classList.add(`${editType}EditCheckBoxField`);
                } else if (idOfFieldWithAPIError.fieldType === FormFieldType.dropDown) {
                  invalidFormElements.item(0).classList.add(`${editType}EditDropDownField`);
                }
              }
            });
          }
        });
      }
    });
  }

  private getClaimFormFieldFromFieldIds(fieldIds: string[]): any {
    let mappedField;
    // starting from the beginning of the list of all the fieldIds (from validation API) use the first fieldId to have mapping to form field configured and return the form field id
    for (let i = 0; i < fieldIds.length; i++) {
      const fieldId = fieldIds[i];
      mappedField = this.getClaimFormFieldFromFieldId(fieldId);
      if (!isStringNullUndefinedOrEmpty(mappedField)) {
        break;
      }
    }
    return mappedField;
  }

  private getClaimFormFieldFromFieldId(fieldId: string): any {
    let mappedField = Object.deepClone(ApplicationConstants.patientEncounterAPIModelToUIFieldMapper[fieldId]);
    if (!mappedField) {
      // map service line field id to eclaim ui id
      if (!isStringNullUndefinedOrEmpty(fieldId) && fieldId.indexOf('serviceLines') > -1) {
        const indexOfFirstOpenBracket = fieldId.indexOf('[');
        const indexOfFirstCloseBracket = fieldId.indexOf(']');
        const indexOfServiceLine = fieldId.slice(indexOfFirstOpenBracket + 1, indexOfFirstCloseBracket);
        const fieldIdModified = `${fieldId.slice(0, indexOfFirstOpenBracket + 1)}${fieldId.slice(indexOfFirstCloseBracket, fieldId.length)}`;
        mappedField = Object.deepClone(ApplicationConstants.patientEncounterAPIModelToUIFieldMapper[fieldIdModified]);
        if (mappedField && mappedField.ids && mappedField.ids.length > 0) {
          for (let i = 0; i < mappedField.ids.length; i++) {
            mappedField.ids[i] = mappedField.ids[i].replace('?', indexOfServiceLine);
          }
        }
      } else {
        // unmapped field id
        mappedField = undefined;
      }
    }
    return mappedField;
  }

  unacknowledgedSoftAndHardEditsBothExist(): boolean {
    let unacknowledgedSoftEdits: ValidationMessage[] = [];
    if (!isNullOrUndefined(this.softEditMessages)) {
      unacknowledgedSoftEdits = this.softEditMessages.filter( (softEdit: ValidationMessage) => {
        return !softEdit.acknowledged;
      });
    }
    return unacknowledgedSoftEdits.length > 0 && this.hardEditMessages.length > 0;
  }

  onShowLensExpiredEditAcknowledge(): void {
    this.showLensExpiredEdit = false;
  }

  onSoftEditAcknowledge(softEditMessage: ValidationMessage): void {
    if (this.currentSoftEditPage === this.softEditLength) {// if edit on last page gets acknowledged, then move to the page before
      this.currentSoftEditPage = this.currentSoftEditPage - 1;
    }
    this.softEditLength = this.softEditLength - 1; // decrease the size of the total number of element for pagination
    if (this.softEditLength === 0) {// when last soft edit gets acknowledged, then close the banner
      this.showSoftEdits = false;
    }
    // acknowledge the soft edit on the active claim
    const activeClaim: Claim = this.claimService.getActiveClaim();
    if (!isNullOrUndefined(activeClaim.patientEncounterValidationMessages)) {
      activeClaim.patientEncounterValidationMessages.forEach((validationMessage: ValidationMessage) => {
        if (!isNullOrUndefined(softEditMessage.errorNumber) && validationMessage.errorNumber === softEditMessage.errorNumber) {
          validationMessage.acknowledged = true;
        }
      });
    }
    if (!isNullOrUndefined(activeClaim.serviceLines)) {
      activeClaim.serviceLines.forEach((serviceLine: ServiceLine) => {
        if (!isNullOrUndefined(serviceLine.serviceLineValidationMessages)) {
          serviceLine.serviceLineValidationMessages.forEach((validationMessage: ValidationMessage) => {
            if (!isNullOrUndefined(softEditMessage.errorNumber) && validationMessage.errorNumber === softEditMessage.errorNumber) {
              validationMessage.acknowledged = true;
            }
          });
        }
      });
    }
    this.claimService.setActiveClaim(activeClaim, ApplicationConstants.componentIDs.softAndHardEdits);
    // remove field highlighting from form field if fieldId exists for the soft edit
    if (softEditMessage.fieldIds && softEditMessage.fieldIds.length > 0) {
      softEditMessage.fieldIds.forEach( (fieldId: string) => {
        const idOfFieldWithAPIError = this.getClaimFormFieldFromFieldId(fieldId);
        if (idOfFieldWithAPIError) {
          idOfFieldWithAPIError.ids.forEach( (id: string) => {
            const invalidFormElements = document.querySelectorAll(id);
            if (!isNullOrUndefined(invalidFormElements)) {
              if (idOfFieldWithAPIError.fieldType === FormFieldType.textBox) {
                invalidFormElements.item(0).parentElement.previousElementSibling.classList.remove(`softEditTextBoxField`);
              } else if (idOfFieldWithAPIError.fieldType === FormFieldType.radioButton) {
                invalidFormElements.item(0).classList.remove(`softEditRadioButtonField`);
              } else if (idOfFieldWithAPIError.fieldType === FormFieldType.checkBox) {
                invalidFormElements.item(0).firstElementChild.firstElementChild.lastElementChild.previousElementSibling.classList.remove(`softEditCheckBoxField`);
              } else if (idOfFieldWithAPIError.fieldType === FormFieldType.dropDown) {
                invalidFormElements.item(0).classList.remove(`softEditDropDownField`);
              }
            }
          });
        }
      });
    }
    // remove soft edit from edit banner
    this.softEditMessages.forEach( (softEdit: ValidationMessage, index: number) => {
      if (softEdit.errorNumber === softEditMessage.errorNumber) {
        this.softEditMessages.splice(index, 1);
      }
    });
  }

  ngOnDestroy() {
    this.viewStateService.setHasEdits(false);
    this.observableSubscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
