import {Component, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ApplicationConstants} from '../../../../common/constants/application.constants';

@Component({
  selector: 'app-fsa-explanation-modal',
  templateUrl: './fsa-explanation-modal.component.html',
  styleUrls: ['./fsa-explanation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FSAExplanationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<FSAExplanationModalComponent>
  ) {
  }

  public confirm() {
    this.dialogRef.close(ApplicationConstants.modalSelectionOk);
  }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }

}
