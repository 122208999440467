export const HtmlConstants = {
  serviceLine: (isCobClaim: boolean) => {
    let defaultServiceLineWidths: any;

    if (isCobClaim) {
      defaultServiceLineWidths = {
        serviceDateWidth: 8,
        placeOfServiceWidth: 4,
        cptHcpcsCodeWidth: 5,
        modifierCodesWidth: 8,
        diagnosisPointersWidth: 12,
        billedAmountWidth: 8,
        unitCountWidth: 3.5,
        checkboxWidth: 3.5
      };
    } else {
      defaultServiceLineWidths = {
        serviceDateWidth: 9.5,
        placeOfServiceWidth: 5,
        cptHcpcsCodeWidth: 8.5,
        modifierCodesWidth: 13.56,
        diagnosisPointersWidth: 16.72,
        billedAmountWidth: 14.68,
        unitCountWidth: 6,
        checkboxWidth: 4.18
      };
    }

    return {
      ...defaultServiceLineWidths,
      cobInputWidth: 8,
      requiredCurrency: 8,
      deniedOrPaidReasonWidth: 16,
      deniedOrPaidReasonOffset: 0.5,
      cobInputOffset: 1,
    };
  },
  attributes: {
    addProcedureButtonId: 'services-add-procedures-button',
    fsaPaidLinkId: 'services-fsa-paid-link',
    fsaPaidAmountId: 'services-fsa-paid-input',
    patientPaymentAmountId: 'services-patient-paid-amount-input',
    patientSexToggleButtonId: 'patient-sex-male-toggle-button',
    firstBilledAmountId: 'service-line-0-billed-amount-input',
    firstEmgCheckboxInputId: 'service-line-0-emg-checkbox-input',
    firstUnitCountInputId: 'service-line-0-unit-count-input',
    firstSetDiagnosisCodeLastInputId: 'services-diagnosis-code-F-textbox',
    secondSetDiagnosisCodeLastInputId: 'services-diagnosis-code-L-textbox',
    diagnosisPointerId: (serviceLineIndex: string, dxPointerIndex: string): string => `service-line-${serviceLineIndex}-diagnosis-pointer-${dxPointerIndex}-input`,
    frameCardTitle: 'frame-title'
  },
  prescriptionColumnWidths: {
    sphereLeftRightColumnWidth: '0 0 11.5%',
    cylinderLeftRightColumnWidth: '0 0 11.5%',
    axisLeftRightColumnWidth: '0 0 11.5%',
    addLeftRightColumnWidth: '0 0 11.5%',
    prismBaseLeftRightColumnWidth: '1 1 9%',
    horizontalPrismColumnWidth: '1 1 11.5%',
    horizontalBaseColumnWidth: '1 1 11.5%',
    verticalPrismColumnWidth: '1 1 11.5%',
    verticalBaseColumnWidth: '1 1 11.5%',
    safetyThicknessColumnWidth: '1 1 40%',
    rightEndSpaceColumnWidth: '1 1 0',
    specifiedThicknessColumnWidth: '1 1 20%'
  }
};
