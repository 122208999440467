import {Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {MessageService} from '../common/services/support/message/message.service';
import {Subscription} from 'rxjs';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {VspIconSnackbarComponent} from './icon-snackbar/vsp.icon-snackbar.component';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SecureComponent implements OnInit, OnDestroy {


  constructor(
    private messageService: MessageService,
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }


  /***** START - PRIVATE MEMBERS *****/
  private observableSubscriptions: Subscription[] = [];
  /***** START - PRIVATE MEMBERS *****/


  /***** START - PUBLIC MEMBERS *****/
  @ViewChild(VspIconSnackbarComponent, {static: true}) iconSnackbar: VspIconSnackbarComponent;
  /***** END - PUBLIC MEMBERS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    window.addEventListener('popstate', () => {
      // TODO when we get angular-core project updated to have the VspIconSnackbarComponent close operation public, we can replace this nasty code.
      this.iconSnackbar.snackbarElement.nativeElement.classList.remove('transition');
      this.iconSnackbar.snackbarElement.nativeElement.classList.remove('error');
      this.iconSnackbar.snackbarElement.nativeElement.parentElement.classList.remove('error');
      }
    );

    this.observableSubscriptions.push(this.messageService.onShowConfirmationSnackbar.subscribe((confirmationMessage: string) => {
      if (confirmationMessage !== undefined) {
        this.iconSnackbar.showConfirmationSnackbar(confirmationMessage);
      }
    }));

    this.observableSubscriptions.push(this.messageService.onShowErrorSnackbar.subscribe((errorMessage: string) => {
      if (errorMessage !== undefined) {
        this.iconSnackbar.showErrorSnackbar(errorMessage);
      }
    }));
  }

  ngOnDestroy() {
    this.observableSubscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
  /***** END - EVENT HANDLERS *****/

}
