<section
  *ngIf="!controlHasFocus"
  [ngClass]="{ 'touched': controlHasBeenTouched, 'invalid-date-error': hasDateErrors(), 'invalid-dollar-error': hasDollarAmountErrors() }"
  class="error-messages">
  <ng-container *ngFor="let error of config.errors">
    <label *ngIf="config.control.hasError(error.validatorType) && (containsClass('ng-touched') || containsClass('touched') || config.control.touched)">
      {{error.errorMessage}}
    </label>
  </ng-container>
</section>
