import {Component, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ReplaceFrameEnum, ReplacementFrameChoice} from '../../../../models/fulfillment';
import {ApplicationConstants} from '../../../../common/constants/application.constants';
import {ReplacementFrameService} from '../../../../common/services/support/replacement-frame/replacement.frame.service';
import {MatTableDataSource} from '@angular/material/table';
import {isNullOrUndefined, isStringNullUndefinedOrEmptyWithTrim} from '../../../../common/utility';

@Component({
  selector: 'app-frame-source-modal',
  templateUrl: './replacement-frame-modal.component.html',
  styleUrls: ['./replacement-frame-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReplacementFrameModalComponent {
  // Replacement Header we will be using on model when rendered.
  public replacementFrameModalHeader: string;
  // Boolean flag to display model content from override model or from the view replacement frame options.
  public replacementModalRenderedFromSupplierOverrideModal: boolean;
  public replacementModalRenderedFromFrameCard: boolean;
  // Boolean flag to display or not display the Please choose a replacement frame content.
  public showPleaseSelectReplacementContent: boolean;
  // ReplacementFrameChoice array we will be using in the model.
  public replacementFrameChoices: ReplacementFrameChoice[];
  // DisplayReplacementChoiceIndicator we will be using to display or not display the ReplacementFrameChoices.
  public displayReplacementChoiceIndicator: string;
  // Content to be displayed on the model if the Replacement Frame Modal is rendered from the Frame Card.
  public modelContentIfRouteIsFromFrameCard: string;
  // This is the datasource we will be using to display the replacement frames.
  public dataSource: MatTableDataSource<ReplacementFrameChoice> = new MatTableDataSource();
  // The columns of the table.
  tableColumns: string[] = ['image', 'styleName', 'skuNumber', 'size', 'color'];

  constructor(
    public dialogRef: MatDialogRef<ReplacementFrameModalComponent>,
    private replacementFrameService: ReplacementFrameService
  ) {
    // Reset Model properties
    this.resetModalProperties();
    // Set needed properties from the ReplacementFrameService.
    this.replacementModalRenderedFromFrameCard = this.replacementFrameService.replacementModalRenderedFromFrameCard;
    this.replacementModalRenderedFromSupplierOverrideModal = this.replacementFrameService.replacementModalRenderedFromSupplierOverrideModal;
    this.replacementFrameChoices = this.replacementFrameService.replacementFrameChoices;
    this.displayReplacementChoiceIndicator = this.replacementFrameService.displayReplacementChoiceIndicator;
    // Set the datasource needed for the modal.
    this.dataSource.data = this.replacementFrameChoices;
    // Select the First item on the list
    if (!isNullOrUndefined(this.dataSource.data) && this.dataSource.data.length > 0) {
      // If there is already an item selected, then select that item when modal renders. Else select the first item on the list
      if (!isStringNullUndefinedOrEmptyWithTrim(this.replacementFrameService.userSelectedReplacementSku)) {
        let preSelectReplacementFrameChoice: ReplacementFrameChoice;
        for (const replacementFrameChoiceInDataSource of this.dataSource.data) {
          if (!isNullOrUndefined(replacementFrameChoiceInDataSource) && !isStringNullUndefinedOrEmptyWithTrim(replacementFrameChoiceInDataSource.skuNumber)
            && replacementFrameChoiceInDataSource.skuNumber.trim() === this.replacementFrameService.userSelectedReplacementSku.trim()) {
            preSelectReplacementFrameChoice = replacementFrameChoiceInDataSource;
            break;
          }
        }
        // Once last check to make sure we found a match. if not match was found then select the first item on the list.
        if (!isNullOrUndefined(preSelectReplacementFrameChoice)) {
          this.makeFrameSelection(preSelectReplacementFrameChoice);
        } else {
          this.makeFrameSelection(this.dataSource.data[0]);
          // Set the preselected item, so it will show active when modal renders.
          this.replacementFrameService.userSelectedReplacementSku = this.dataSource.data[0].skuNumber;
        }
      } else {
        this.makeFrameSelection(this.dataSource.data[0]);
        // We also want to set the replacement sku override if the user proceeds with the selection.
        // If the user cancels the selection then replacement selection, the replacement sku that will be sued is the one
        // provided by initial disposition call from the guidance.
        this.replacementFrameService.userSelectedReplacementSku = this.dataSource.data[0].skuNumber;
      }
    }
    // Set the models header accordingly.
    const isThereOnlyOneReplacementFrameOption: boolean = this.replacementFrameService.isThereOnlyOneReplacementFrameOption;
    const headerValue: string = 'Replacement Frame';
    if (this.replacementModalRenderedFromFrameCard) {
      const defaultModelContentIfRouteIsFromFrameCard = 'Marchon/Altair will send you this frame to replace your board sample.';
      if (isThereOnlyOneReplacementFrameOption) {
        this.replacementFrameModalHeader = headerValue;
        this.modelContentIfRouteIsFromFrameCard = defaultModelContentIfRouteIsFromFrameCard;
      } else {
        this.replacementFrameModalHeader = headerValue + ' Options';
        this.modelContentIfRouteIsFromFrameCard = 'Select a frame below. ' + defaultModelContentIfRouteIsFromFrameCard;
      }
    } else if (this.replacementModalRenderedFromSupplierOverrideModal) {
      if (isThereOnlyOneReplacementFrameOption) {
        this.replacementFrameModalHeader = headerValue;
      } else {
        this.replacementFrameModalHeader = 'Select ' + headerValue;
        this.showPleaseSelectReplacementContent = true;
      }
    }
  }

  cancelButtonClick(): void {
    this.dialogRef.close(ReplaceFrameEnum.Cancel);
    // Set the user select frame replacement to undefined.
    // NOTE: If this flag is set to null in the fulfilment service then,
    // the replacement sku from the guidance will be used.
    this.replacementFrameService.userSelectedReplacementSku = undefined;
  }

  continueButtonClick(): void {
    this.dialogRef.close(ReplaceFrameEnum.Continue);
  }

  onSeeProgramFAQsClick(): void {
    window.open(ApplicationConstants.kscopeProgramFAQsUrl, 'blank');
  }

  private resetModalProperties(): void {
    // Replacement Header we will be using on model when rendered.
    this.replacementFrameModalHeader = undefined;
    // Boolean flag to display model content from override model or from the view replacement frame options.
    this.replacementModalRenderedFromSupplierOverrideModal = false;
    this.replacementModalRenderedFromFrameCard = false;
    // Boolean flag to display or not display the Please choose a replacement frame content.
    this.showPleaseSelectReplacementContent = false;
    // ReplacementFrameChoice array we will be using in the model.
    this.replacementFrameChoices = undefined;
    // DisplayReplacementChoiceIndicator we will be using to display or not display the ReplacementFrameChoices.
    this.displayReplacementChoiceIndicator = undefined;
    // Content to be displayed on the model if the Replacement Frame Modal is rendered from the Frame Card.
    this.modelContentIfRouteIsFromFrameCard = undefined;
  }

  onFramesTableRowClick(selectedReplacementFrame: ReplacementFrameChoice): void {
    this.makeFrameSelection(selectedReplacementFrame);
  }

  private makeFrameSelection(selectedReplacementFrame: ReplacementFrameChoice) {
    this.setFrameAsActive(selectedReplacementFrame);
    // Update the fulfillment service with the new replacement SKU.
    this.replacementFrameService.userSelectedReplacementSku = selectedReplacementFrame.skuNumber;
  }

  private setFrameAsActive(selectedReplacementFrame: ReplacementFrameChoice) {
    this.dataSource.data.forEach(replacementFrameChoice => {
      replacementFrameChoice.isActiveSelection = false;
    });
    selectedReplacementFrame.isActiveSelection = true;
  }
}
