import {
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {CustomValidatorsService} from '../../../common/services/support/custom-validators/custom-validators.service';
import {ExamConstants, LensOrFrame} from './exam.constants';
import {MessageService} from '../../../common/services/support/message/message.service';
import {ClaimFormItem} from '../../../common/classes/claim-form-item';
import {Subscription} from 'rxjs';
import {ClaimsService} from '../../../common/services/data-model/app/claims/claims.service';
import {ApplicationConstants, ErrorTypes} from '../../../common/constants/application.constants';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Claim, SoftAndHardValidationMessages, ValidationMessage} from '../../../models/claim';
import {Exam} from '../../../models/exam';
import {ComponentMaskComponent} from '../../../common/components/component-mask/component-mask.component';
import {FormStateYesNo} from '../../../common/enum/form-state-yes-no';
import {
  returnBooleanFromFormState,
  returnFormStateFromBoolean,
  isNullOrUndefined, isStringNullUndefinedOrEmpty, onKeypressEventCheckbox, onKeypressEventRadiobutton
} from '../../../common/utility';
import {ClaimCardsToUpdate} from '../../../models/claimCardsToUpdate';
import {ExternalServiceLocation, Doctor} from 'src/app/models/externalServiceLocation';
import {ExternalServiceLocationService} from 'src/app/common/services/data-model/app/external-service-location/external-service-location.service';
import {Suppliers} from 'src/app/models/frame';
import {MatDialog} from '@angular/material/dialog';
import {
  ConfirmationModalComponent,
  ConfirmationModalOptions
} from 'src/app/common/components/confirmation-modal/confirmation-modal.component';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';


@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExamComponent extends ClaimFormItem implements OnInit, OnDestroy {

  /***** START - PRIVATE MEMBERS *****/
  private _dilationRequired = false;
  private _containsMultipleDoctors: boolean = true;
  private refractionExamTypes = ['S0620', 'S0621'];
  private observableSubscriptions: Subscription[] = [];
  private originalClaim: Claim;
  private activeClaim: Claim;
  private isMasked: boolean;
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  @ViewChild('patientDilatedButton', {read: ElementRef, static: true}) private patientDilatedButton: ElementRef;
  @ViewChild('patientNotDilatedButton', {read: ElementRef, static: true}) private patientNotDilatedButton: ElementRef;
  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;

  /***** END - PRIVATE MEMBERS *****/

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private claimService: ClaimsService,
    private externalServiceLocationService: ExternalServiceLocationService,
    private customValidatorService: CustomValidatorsService,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private claimEditService: ClaimEditService
  ) {
    super(injector);
  }

  /***** START - PUBLIC MEMBERS *****/
  id = ApplicationConstants.componentIDs.exam;
  title = 'Exam';
  examForm: FormGroup;
  examConstants = ExamConstants;
  lensCheckboxId: string = 'exam-lens-checkbox';
  frameCheckboxId: string = 'exam-frame-checkbox';
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;

  // Form state/data variables
  formState = FormStateYesNo;
  errorWrapperConfig = {
    selectedDoctor: undefined,
    physicianInvoicePatientDilated: undefined
  };
  examTypeGroup1 = ExamConstants.types.group1;
  examTypeGroup2 = ExamConstants.types.group2;
  doctorList = [];
  refractionRemovedMessage = 'The exam service code billed (S0620 or S0621) includes a refraction. Refraction indicator has been removed.';
  // Assigning functions from utility class to variables so that these can be accessed  in template
  onKeypressEventCheckbox = onKeypressEventCheckbox;
  onKeypressEventRadiobutton = onKeypressEventRadiobutton;

  get dilationRequired(): boolean {
    return this._dilationRequired;
  }

  set dilationRequired(newVal: boolean) {
    this._dilationRequired = newVal;
    const hasValidators = !isNullOrUndefined(this.examForm.controls.physicianInvoicePatientDilated.validator);
    if (this._dilationRequired && !hasValidators) {
      this.examForm.controls.physicianInvoicePatientDilated.setValidators([Validators.required]);
      this.examForm.controls.physicianInvoicePatientDilated.updateValueAndValidity();
    } else if (!this._dilationRequired && hasValidators) {
      this.examForm.controls.physicianInvoicePatientDilated.clearValidators();
      this.examForm.controls.physicianInvoicePatientDilated.updateValueAndValidity();
    }
  }

  get containsMultipleDoctors(): boolean {
    return this._containsMultipleDoctors;
  }

  set containsMultipleDoctors(value: boolean) {
    this._containsMultipleDoctors = value;
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }

  /***** END - PUBLIC MEMBERS *****/


  /***** START - PRIVATE FUNCTIONS *****/
  private buildForm(): void {
    // pull data from the data model
    let dilation: boolean, examServiceCode: string, refraction: boolean, lensServiceIndicator: boolean,
      frameServiceIndicator: boolean;
    if (this.originalClaim) {
      if (this.originalClaim.exam) {
        ({dilation, examServiceCode, refraction} = this.originalClaim.exam);
      }
      // Assign references to the original PE indicators, set within PE
      ({lensServiceIndicator, frameServiceIndicator} = this.originalClaim);
    }

    // If dilation is not undefined then get a corresponding Yes, No value for it.
    let dilationPerformed: string;
    if (dilation) {
      dilationPerformed = returnFormStateFromBoolean(dilation);
    }
    // build form with claim data
    this.examForm = this.formBuilder.group({
      examType: [examServiceCode],
      refractionTestPerformed: [refraction],
      physicianInvoicePatientDilated: dilationPerformed,
      selectedDoctor: [0, this.customValidatorService.DropdownSelectionRequired],
      lensIndicator: [lensServiceIndicator],
      frameIndicator: [frameServiceIndicator]
    });
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      selectedDoctor: {
        control: this.examForm.controls.selectedDoctor,
        errors: [{
          validatorType: ErrorTypes.DropdownSelectionRequired,
          errorMessage: 'Please select a Doctor'
        }]
      },
      physicianInvoicePatientDilated: {
        control: this.examForm.controls.physicianInvoicePatientDilated,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please indicate if Dilation was performed'
        }]
      },
    };
  }

  private setViewModelFromDataModel(dataModel: Claim): void {
    // pull data from the data model
    let dilation: boolean, examServiceCode: string, refraction: boolean;
    if (dataModel.exam) {
      ({dilation, examServiceCode, refraction} = dataModel.exam);
    }
    // If dilation is not undefined then get a corresponding Yes, No value for it.
    let dilationPerformed: string;
    if (!isNullOrUndefined(dilation)) {
      dilationPerformed = returnFormStateFromBoolean(dilation);
    }
    const {doctor} = dataModel;
    const doctorId = (doctor) ? doctor.nationalProviderId : undefined;
    // pull data from the view model
    const {examType, refractionTestPerformed, physicianInvoicePatientDilated, selectedDoctor, lensIndicator, frameIndicator} = this.examForm.controls;
    const currentExamType = examType.value;
    const currentRefractionTestPerformed = refractionTestPerformed.value;
    const currentphysicianInvoicePatientDilated = physicianInvoicePatientDilated.value;
    const currentSelectedDoctor = selectedDoctor.value;
    const currentFrameIndicatorValue: boolean = frameIndicator.value;
    const currentLensIndicatorValue: boolean = lensIndicator.value;
    // set data in the view model only if different from the data model
    if (currentExamType !== examServiceCode) {
      this.examForm.controls.examType.setValue(examServiceCode, ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentRefractionTestPerformed !== refraction) {
      this.examForm.controls.refractionTestPerformed.setValue(refraction, ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentphysicianInvoicePatientDilated !== dilationPerformed) {
      this.examForm.controls.physicianInvoicePatientDilated.setValue(dilationPerformed, ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentSelectedDoctor !== doctorId) {
      this.examForm.controls.selectedDoctor.setValue(doctorId, ApplicationConstants.updateFormWithoutEmit);
    }

    // Set the lens and frame indicators if the user has selected a lens from the dropdown or chosen a frame based on the frame name/model
    let hasLensSelected: boolean = false, hasFrameSelected: boolean = false, hasLabOrDoctorSupplied: boolean = false;
    if (!isNullOrUndefined(dataModel.labOrderInformation)) {
      hasLensSelected = !isNullOrUndefined(dataModel.labOrderInformation.lens) && !isStringNullUndefinedOrEmpty(dataModel.labOrderInformation.lens.externalId);
      hasFrameSelected = !isNullOrUndefined(dataModel.labOrderInformation.frame) && !isStringNullUndefinedOrEmpty(dataModel.labOrderInformation.frame.name);
      hasLabOrDoctorSupplied = dataModel.labOrderInformation.frameSupplier === Suppliers.DOCTOR || dataModel.labOrderInformation.frameSupplier === Suppliers.LAB;
    }
    if (currentFrameIndicatorValue !== (hasFrameSelected && hasLabOrDoctorSupplied)) {
      frameIndicator.setValue(hasFrameSelected && hasLabOrDoctorSupplied, ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentLensIndicatorValue !== hasLensSelected) {
      lensIndicator.setValue(hasLensSelected, ApplicationConstants.updateFormWithoutEmit);
    }
  }

  private updateDataModelFromViewModel(): void {
    const {examType, refractionTestPerformed, physicianInvoicePatientDilated, selectedDoctor, lensIndicator, frameIndicator} = this.examForm.controls;
    if (isNullOrUndefined(this.activeClaim.exam)) {
      this.activeClaim.exam = {} as Exam;
    }
    this.activeClaim.exam.dilation = returnBooleanFromFormState(physicianInvoicePatientDilated.value);
    this.activeClaim.exam.examServiceCode = isStringNullUndefinedOrEmpty(examType.value as string) ? undefined : examType.value;
    this.activeClaim.exam.refraction = refractionTestPerformed.value;
    if (isNullOrUndefined(this.activeClaim.doctor)) {
      this.activeClaim.doctor = {} as Doctor;
    }
    this.activeClaim.doctor.nationalProviderId = selectedDoctor.value;
    this.activeClaim.frameServiceIndicator = frameIndicator.value;
    this.activeClaim.lensServiceIndicator = lensIndicator.value;

    // Update the active claim in the claim service
    if (this.examCardHasChanged()) {
      this.claimService.setActiveClaim(this.activeClaim, this.id);
    }
  }

  private examCardHasChanged(): boolean {
    const {dilation: dilationFromCard, examServiceCode: examServiceCodeFromCard, refraction: refractionFromCard} = this.activeClaim.exam;
    const npiFromCard = this.activeClaim.doctor.nationalProviderId;
    const {frameServiceIndicator: frameServiceIndicatorFromCard, lensServiceIndicator: lensServiceIndicatorFromCard} = this.activeClaim;

    const activeClaimFromService = this.claimService.getActiveClaim();
    // Instantiate claim objects if they don't exist
    if (isNullOrUndefined(activeClaimFromService.exam)) {
      activeClaimFromService.exam = {} as Exam;
    }
    if (isNullOrUndefined(activeClaimFromService.doctor)) {
      activeClaimFromService.doctor = {} as Doctor;
    }
    const {dilation: dilationFromService, examServiceCode: examServiceCodeFromService, refraction: refractionFromService} = activeClaimFromService.exam;
    const npiFromService = activeClaimFromService.doctor.nationalProviderId;
    const {frameServiceIndicator: frameServiceIndicatorFromService, lensServiceIndicator: lensServiceIndicatorFromService} = activeClaimFromService;

    return (dilationFromCard) !== (dilationFromService) ||
      (examServiceCodeFromCard || undefined) !== (examServiceCodeFromService || undefined) ||
      (refractionFromCard || undefined) !== (refractionFromService || undefined) ||
      (npiFromCard || undefined) !== (npiFromService || undefined) ||
      (frameServiceIndicatorFromCard || undefined) !== (frameServiceIndicatorFromService || undefined) ||
      (lensServiceIndicatorFromCard || undefined) !== (lensServiceIndicatorFromService || undefined);
  }

  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.originalClaim = this.claimService.getOriginalClaim();
    this.registerWithClaimProgressService();
    this.buildForm();
    this.buildErrorWrapperConfig();

    // Register for updates to the active claim
    // NOTE: uiFormattedClaim will be undefined if any errors occurred upstream
    this.observableSubscriptions.push(this.claimService.onCardsToUpdate.subscribe((onCardsToUpdate: ClaimCardsToUpdate) => {
      this.activeClaim = this.claimService.getActiveClaim();
      // Set form data if the data exists
      if (onCardsToUpdate.exam || onCardsToUpdate.all) {
        this.setViewModelFromDataModel(this.activeClaim);
        this.changeDetector.detectChanges();
      }
    }));

    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      if (mask) {
        this.disableFormGroupComponents(this.examForm);
        this.isMasked = true;
        this.componentMask.show();
      } else {
        this.examForm.enable();
        this.isMasked = false;
        this.componentMask.hide();
      }
    }));

    // watch for updates of doctor list
    this.observableSubscriptions.push(this.externalServiceLocationService.onExternalServiceLocation.subscribe((externalServiceLocation: ExternalServiceLocation) => {
      if (externalServiceLocation) {
        this.doctorList = externalServiceLocation.uiFormattedDoctors;
        if (externalServiceLocation.uiFormattedDoctors.length === 1) {
          this.examForm.controls.selectedDoctor.setValue(this.doctorList[0].value);
          this.containsMultipleDoctors = false;
        } else if (this.activeClaim.doctor) {
          this.examForm.controls.selectedDoctor.setValue(this.activeClaim.doctor.nationalProviderId);
          this.containsMultipleDoctors = true;
        } else {
          this.examForm.controls.selectedDoctor.setValue(0);
          this.containsMultipleDoctors = true;
        }
      }
    }));

    // disable doctor list as we are calling the API and enable when the doctor list is loaded from API
    this.observableSubscriptions.push(this.externalServiceLocationService.onLoadingServiceLocationCallInProgress.subscribe((callInProgress) => {
      if (callInProgress) {
        this.examForm.controls.selectedDoctor.disable();
      } else if (!this.isMasked) {
        this.examForm.controls.selectedDoctor.enable();
      }
    }));

    // When submit finds UI edits detect changes on component
    this.observableSubscriptions.push(this.viewStateService.onSubmitFoundUIEdits.subscribe((hasUIEdits: boolean) => {
      if (hasUIEdits) {
        this.changeDetector.detectChanges();
      }
    }));
    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
          (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }
      }));

    // Form value changes
    this.observableSubscriptions.push(this.examForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe(() => {
      // Update the data model based on the form data
      this.updateDataModelFromViewModel();
    }));
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onExamTypeChange(): void {
    const value = this.examForm.controls.examType.value;
    const includesRefraction = this.refractionExamTypes.find((type: string) => type === value);
    if (includesRefraction) {
      const refractionTestPerformed = this.examForm.controls.refractionTestPerformed.value;
      if (refractionTestPerformed) {
        this.messageService.showConfirmationSnackbar(this.refractionRemovedMessage);
        this.examForm.controls.refractionTestPerformed.setValue(false);
      }
      this.examForm.controls.refractionTestPerformed.disable();
    } else {
      this.examForm.controls.refractionTestPerformed.enable();
    }
  }

  onphysicianInvoicePatientDilatedClicked(): void {
    let dilated: boolean;
    // translate the data model value for dilated to the equivalent view model value
    if (this.examForm.controls.physicianInvoicePatientDilated.value === FormStateYesNo.Yes) {
      dilated = true;
    } else if (this.examForm.controls.physicianInvoicePatientDilated.value === FormStateYesNo.No) {
      dilated = false;
    } else {
      dilated = undefined;
    }

    // if there is a value for dilated, proceed
    if (!isNullOrUndefined(dilated)) {
      // if the user is selecting a button that is already selected, deselect it
      if (this.activeClaim.exam && this.activeClaim.exam.dilation === dilated) {
        this.examForm.controls.physicianInvoicePatientDilated.setValue(undefined);
      }
    }
  }

  onCheckboxClick(checkboxId: string, control: AbstractControl): void {
    const materialToRemove: string = checkboxId === this.lensCheckboxId ? 'lens' : 'frame';
    if (!control.value) {
      this.dialog.open<ConfirmationModalComponent, ConfirmationModalOptions>(ConfirmationModalComponent, {
        data: {
          modalMessageText: `All ${materialToRemove} data will be cleared. Do you wish to continue?`,
          modalHeaderText: `Remove ${materialToRemove}?`
        },
        width: '40vw',
        panelClass: 'eclaim-popup-modal',
        // prevent the modal from closing automatically if user accidentally clicks outside the modal boundary.
        disableClose: true
      }).afterClosed().subscribe((okClicked: boolean) => {
        if (okClicked) {
          // Emit the material to remove side effect based on checkbox ID
          if (checkboxId === this.lensCheckboxId) {
            this.viewStateService.setMaterialToRemoveState(LensOrFrame.Lens);
          } else {
            this.viewStateService.setMaterialToRemoveState(LensOrFrame.Frame);
          }
        } else {
          // Keep the checkbox checked if the user hits 'Cancel'
          control.setValue(true);
        }
      });
    }
  }

  /***** END - EVENT HANDLERS *****/
}
