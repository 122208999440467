import { Injectable, Injector } from '@angular/core';
import { HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClientService } from '../http-client-service';
import { ApplicationConstants } from '../../../../../constants/application.constants';
import {
  VisionServiceDeleteRequest,
  VisionServiceRequest,
  VisionServiceRequestPayload
} from '../../../../../../models/VisionServiceRequestResults';
import {DateUtility, getApiUrl, isNullOrUndefined, isStringNullUndefinedOrEmpty} from '../../../../../utility';
import {AuthTrackingSearchRequest} from '../../../../../../models/AuthTrackingSearchRequest';

@Injectable({
  providedIn: 'root'
})
export class VisionServiceRequestDataService extends HttpClientService {

  constructor(
    public injector: Injector
  ) {
    super(injector, ApplicationConstants.dataServiceClientIDs.visionServiceRequestDataService);
  }

  /***** START - PRIVATE FUNCTIONS *****/
  private getVisionServiceRequestSearchUrl(): string {
    const domain = (!isStringNullUndefinedOrEmpty(this.environment.visionServiceRequestUrl)) ? this.environment.visionServiceRequestUrl : this.environment.apiUrl;
    return `${domain}/${ApplicationConstants.api.visionServiceRequest}/visionservicerequests`;
  }

  private createVsrResponseContainsAuthorizationNumber = (vsrResponse: VisionServiceRequest): boolean =>
    vsrResponse && vsrResponse.vsrNumber && vsrResponse.vsrNumber.length > 0

  private getRetrieveVsrErrorMessage(error: any): string {
    let errorMessage = this.getErrorMessage(error);
    if (this.isNotFoundOrBadRequest(error)) {
      errorMessage = ApplicationConstants.errorMessages.invalidOrDeletedAuthorization;
    } else if (this.isForbidden(error)) {
      errorMessage = ApplicationConstants.errorMessages.retrievedAuthBelongsToOtherDoctor;
    }
    return errorMessage;
  }

  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  searchVisionServiceRequests(vsrApiUrl: string, useVersion: boolean = true, useGenericApiFailMessage: boolean = true): Observable<VisionServiceRequest[]> {
    const httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders.append('Prefer', 'return=full');
    if (useVersion) {
      httpHeaders.append('Accept', 'application/json;version=5.4');
    } else {
      httpHeaders.append('Accept', 'application/json');
    }
    vsrApiUrl = getApiUrl(vsrApiUrl, this.environment.visionServiceRequestUrl);
    return this.http.get(vsrApiUrl, { headers: httpHeaders})
      .pipe(
        map((vsrSearchApiResult: VisionServiceRequest[]) => {
          // TODO move data interrogation logic into data layer
          if (vsrSearchApiResult) {
            this.log(`VSR search successful using url: ${vsrApiUrl}`);
            return vsrSearchApiResult;
          } else {
            this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
            return undefined;
          }
        }),
        catchError((error: any) => {
          let errorMessage: string;
          if (useGenericApiFailMessage) {
            errorMessage = this.getErrorMessage(error);
          } else {
            errorMessage = this.getDetailDescriptionFromError(error);
          }
          this.handleError('searchVisionServiceRequests', error);
          this.messageService.showErrorSnackbar(`${errorMessage}`);
          return of(undefined);
        })
      );
  }


  searchVisionServiceRequestsForOutstandingAuths(authTrackingSearchRequest: AuthTrackingSearchRequest): Observable<VisionServiceRequest[]> {
    let updatedVsrApiUrl: string;
    const httpHeaders: HttpHeaders = new HttpHeaders;
    httpHeaders.append('Prefer', 'return=full');
    httpHeaders.append('Accept', 'application/json');
    const vsrApiUrl: string = this.getVisionServiceRequestSearchUrl();
    if (!isStringNullUndefinedOrEmpty(authTrackingSearchRequest._issueDate)) {
      const issueDate = DateUtility.buildYyyyMmDdDateFromDate(authTrackingSearchRequest._issueDate);
      updatedVsrApiUrl = vsrApiUrl + '?' + ApplicationConstants.enableProviderSearchTrue + ApplicationConstants.ampersandSymbol + ApplicationConstants.providerSearchExactEffectiveDate + issueDate;
    } else if (!isStringNullUndefinedOrEmpty(authTrackingSearchRequest._issueDateStart) && !isStringNullUndefinedOrEmpty(authTrackingSearchRequest._issueDateEnd)) {
      const issueDateStart = DateUtility.buildYyyyMmDdDateFromDate(authTrackingSearchRequest._issueDateStart);
      const issueDateEnd = DateUtility.buildYyyyMmDdDateFromDate(authTrackingSearchRequest._issueDateEnd);
      updatedVsrApiUrl = vsrApiUrl + '?' + ApplicationConstants.enableProviderSearchTrue + ApplicationConstants.ampersandSymbol + ApplicationConstants.providerSearchStartDate + issueDateStart +
                                          ApplicationConstants.ampersandSymbol + ApplicationConstants.providerSearchEndDate + issueDateEnd;
    }
    return this.searchVisionServiceRequests(updatedVsrApiUrl, false, false);
  }

  retrieveVisionServiceRequest(authorizationNumber: string, showSnackbar: boolean = true): Observable<VisionServiceRequest> {
    return this.http.get<VisionServiceRequest>(`${this.getVisionServiceRequestSearchUrl()}/${authorizationNumber}`, { headers: this.getHttpHeaders(ApplicationConstants.api.visionServiceRequest)})
      .pipe(
        map((visionServiceRequest: VisionServiceRequest) => {
          // TODO move data interrogation logic into data layer
          if (visionServiceRequest) {
            this.log(`VSR retrieve successful for ${authorizationNumber}`);
            return visionServiceRequest;
          } else {
            this.messageService.setErrorMessage(showSnackbar, ApplicationConstants.errorMessages.genericApiFailMessage);
            return undefined;
          }
        }),
        catchError((error: any) => {
          const errorMessage = this.getRetrieveVsrErrorMessage(error);
          this.handleError('retrieveVisionServiceRequest', error);
          this.messageService.setErrorMessage(showSnackbar, `${errorMessage}`);
          return of(undefined);
        })
      );
  }

  createVisionServiceRequest(vsrLinkFromEligibilityResponse: string, vsrEligibleServicesPayload: VisionServiceRequestPayload): Observable<VisionServiceRequest> {
    vsrLinkFromEligibilityResponse = getApiUrl(vsrLinkFromEligibilityResponse, this.environment.visionServiceRequestUrl);
    return this.http.post<VisionServiceRequest>(vsrLinkFromEligibilityResponse, vsrEligibleServicesPayload, { headers: this.getHttpHeaders(ApplicationConstants.api.visionServiceRequest)})
      .pipe(
        map((createVsrApiResponse: VisionServiceRequest) => {
          // TODO move data interrogation logic into data layer
          // Throw the generic failure message if no response was returned
          if (isNullOrUndefined(createVsrApiResponse)) {
            this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
            return undefined;
          }

          // Return the VSR response as successful if the response contains a valid authorization number
          if (this.createVsrResponseContainsAuthorizationNumber(createVsrApiResponse)) {
            this.log(`VSR successfully created for request authorization ${createVsrApiResponse.vsrNumber}`);
            return createVsrApiResponse;
          } else {
            this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
            return undefined;
          }
        }),
        catchError((error: any) => {
          const errorMessage = this.getErrorMessage(error);
          this.handleError('createVisionServiceRequest', error);
          this.messageService.showErrorSnackbar(`${errorMessage}`);
          return of(undefined);
        })
      );
  }

  deleteVisionServiceRequest(apiUrl: string, isAuthTrackingDelete: boolean = false): Observable<VisionServiceDeleteRequest> {
    return this.http.delete(apiUrl, { headers: this.getHttpHeaders(ApplicationConstants.api.visionServiceRequest)})
      .pipe(
        map((vsrDeleteApiResult: VisionServiceDeleteRequest) => {
          // TODO move data interrogation logic into data layer
          if (vsrDeleteApiResult) {
            this.log(`VSR successfully deleted authorization`);
            return vsrDeleteApiResult;
          } else {
            this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
            return undefined;
          }
        }),
        catchError((error: any) => {
          let errorMessage: string;
          if (isAuthTrackingDelete) {
            errorMessage = ApplicationConstants.errorMessages.authTrackingDeleteFailMessage;
          } else {
            errorMessage = this.getErrorMessage(error);
          }
          this.handleError('deleteVisionServiceRequest', error);
          this.messageService.showErrorSnackbar(`${errorMessage}`);
          return of(undefined);
        })
      );
  }
  /***** END - PUBLIC FUNCTIONS *****/
}
