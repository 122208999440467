import {Injectable} from '@angular/core';
import {
  Claim,
  SoftAndHardValidationMessages,
  ValidationMessage,
  ValidationMessageLevelCode
} from '../../../../models/claim';
import {ViewStateService} from '../../view-state/view-state.service';
import {isNullOrUndefined} from '../../../utility';

@Injectable({
  providedIn: 'root'
})
export class ClaimEditService {

  private hasHardEdits: boolean;
  private hasSoftEdits: boolean;
  private softAndHardEdits: SoftAndHardValidationMessages;

  constructor(
    private viewStateService: ViewStateService
  ) { }

  handleSoftAndHardEdits(activeClaim: Claim) {
    try {
      if (activeClaim) {
        this.hasHardEdits = false;
        this.hasSoftEdits = false;
        const hardEditMessages: ValidationMessage[] = [];
        const unacknowledgedSoftEdits: ValidationMessage[] = [];

        // look for all Info and Error level validation messages in the patientEncounterValidationMessages from PE API response
        if (!isNullOrUndefined(activeClaim.patientEncounterValidationMessages) && activeClaim.patientEncounterValidationMessages.length > 0) {
          activeClaim.patientEncounterValidationMessages.forEach((patientEncounterValidationMessage: ValidationMessage) => {
            if (patientEncounterValidationMessage.levelCode === ValidationMessageLevelCode.ERROR) {
              hardEditMessages.push(patientEncounterValidationMessage);
              this.hasHardEdits = true;
            }
            if (patientEncounterValidationMessage.levelCode === ValidationMessageLevelCode.INFO &&
              !patientEncounterValidationMessage.acknowledged) {
              unacknowledgedSoftEdits.push(patientEncounterValidationMessage);
              this.hasSoftEdits = true;
            }
          });
        }

        // look for all Info and Error level validation messages at the service line level in the serviceLineValidationMessages from PE API response
        if (!isNullOrUndefined(activeClaim.serviceLines) && activeClaim.serviceLines.length > 0) {
          for (let i = 0; i < activeClaim.serviceLines.length; i++) {
            const serviceLine = activeClaim.serviceLines[i];
            if (!isNullOrUndefined(serviceLine.serviceLineValidationMessages) && serviceLine.serviceLineValidationMessages.length > 0) {
              serviceLine.serviceLineValidationMessages.forEach((serviceLineValidationMessage: ValidationMessage) => {
                if (serviceLineValidationMessage.levelCode === ValidationMessageLevelCode.ERROR) {
                  if (serviceLineValidationMessage.fieldIds && serviceLineValidationMessage.fieldIds.length > 0) {
                    serviceLineValidationMessage.fieldIds[0] = `serviceLines[${i}].${serviceLineValidationMessage.fieldIds[0]}`;
                  }
                  hardEditMessages.push(serviceLineValidationMessage);
                  this.hasHardEdits = true;
                }
                if (serviceLineValidationMessage.levelCode === ValidationMessageLevelCode.INFO &&
                  !serviceLineValidationMessage.acknowledged) {
                  if (serviceLineValidationMessage.fieldIds && serviceLineValidationMessage.fieldIds.length > 0) {
                    serviceLineValidationMessage.fieldIds[0] = `serviceLines[${i}].${serviceLineValidationMessage.fieldIds[0]}`;
                  }
                  unacknowledgedSoftEdits.push(serviceLineValidationMessage);
                  this.hasSoftEdits = true;
                }
              });
            }
          }
        }
        if (this.hasHardEdits || this.hasSoftEdits) {
          this.clearSoftAndHardEditHighlights();
          this.softAndHardEdits = {
            hardEditMessages: hardEditMessages,
            unacknowledgedSoftEdits: unacknowledgedSoftEdits
          };
          this.viewStateService.setHasEdits(true);
        }
      }
    } catch (error) {

    }
  }

  hasHardEdit(): boolean {
    return this.hasHardEdits;
  }

  hasSoftEdit(): boolean {
    return this.hasSoftEdits;
  }

  getSoftAndHardEdits(): SoftAndHardValidationMessages {
    return this.softAndHardEdits;
  }

  clearSoftAndHardEditHighlights(): void {
    const invalidFormElements = document.querySelectorAll('' +
      '.hardEditTextBoxField, ' +
      '.hardEditRadioButtonField, ' +
      '.hardEditCheckBoxField, ' +
      '.hardEditDropDownField, ' +
      '.softEditTextBoxField, ' +
      '.softEditRadioButtonField, ' +
      '.softEditCheckBoxField, ' +
      '.softEditDropDownField');
    if (!isNullOrUndefined(invalidFormElements)) {
      for (let i = 0; i < invalidFormElements.length; i++) {
        invalidFormElements.item(i).classList.remove(
          'hardEditTextBoxField',
          'hardEditRadioButtonField',
          'hardEditCheckBoxField',
          'hardEditDropDownField',
          'softEditTextBoxField',
          'softEditRadioButtonField',
          'softEditCheckBoxField',
          'softEditDropDownField');
      }
    }
  }
}
