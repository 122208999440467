<div fxLayout="column">
  <mat-card>

    <!-- Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 class="nested-column-11"
            id="gift-certificate-submission-confirmation-modal-header" mat-dialog-title>Gift Certificate Submission</h1>
      </mat-card-title>
      <!-- Close Icon -->
      <button [disableRipple]="true" class="close-button" mat-icon-button>
        <mat-icon (click)="onCloseClick()" id="gift-certificate-submission-confirmation-modal-close-icon"
                  svgIcon="eclaim-icons:close"></mat-icon>
      </button>
    </mat-card-header>


    <!-- Body -->
    <mat-card-content>
      <!-- Authorization Recap -->
      <label class="gift-certificate-submission-confirmation-modal"
             id="gift-certificate-submission-confirmation-message">
        <strong>Please Verify all the information you’ve entered is accurate. If you’d like to make a change click
          on cancel, otherwise, click on OK to continue.</strong>
      </label>
    </mat-card-content>

    <!-- Footer Line -->
    <hr/>

    <!-- OK/Cancel buttons  -->
    <mat-card-actions>
      <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="end center" class="margin-top-20 margin-bottom-20">

        <!-- Cancel Button  -->
        <button class="cancel-button" id="gift-certificate-submission-confirmation-modal-cancel-button"
                mat-dialog-close>Cancel
        </button>
        <div fxFlexOffset="30px"></div>

        <!-- OK Button-->
        <button class="btn btn-primary btn-extra-pad" id="gift-certificate-submission-confirmation-modal-ok-button"
                mat-dialog-close (click)="onOkClick()">OK
        </button>
        <div fxFlexOffset="20px"></div>

      </div>
    </mat-card-actions>

  </mat-card>
</div>
