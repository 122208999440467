<div fxLayout="column">

  <mat-card>
    <!-- Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 class="nested-column-11"
            id="kscope-confirmation-modal-header" mat-dialog-title>Frame Source</h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Body -->
    <mat-card-content>
      <label id="frame-source-modal-message-text">Is this frame at your practice?</label>
    </mat-card-content>

    <!-- Footer Line -->
    <div class="margin-top-50">
      <hr/>
    </div>


    <!-- KScope Logo/Cancel/Continue buttons -->
    <mat-card-actions>
      <!-- Top Level Row-->
      <div fxLayout="row">
        <!-- KScope Logo-->
        <div class="nested-column-6">
          <img class="margin-top-5" id="frame-source-kaleyedoscope-logo" height="50px" width="120px" src="assets/images/kaleyedoscopecircle.svg">
        </div>
        <!-- Cancel/Continue buttons -->
        <div class="nested-column-6">
          <div fxLayout="row" fxLayoutAlign="end center"
               class="margin-top-15 margin-bottom-15">
            <!-- Cancel Button  -->
            <button mat-button color="primary" class="cancel-button" (click)="onCloseClick()"
                    id="frame-source-modal-cancel-button" mat-dialog-close>No</button>
            <!-- Continue Button  -->
            <button mat-dialog-close class="btn btn-primary btn-extra-pad margin-left-30" (click)="onYesClick()"
                    id="frame-source-modal-continue-button">Yes</button>
          </div>
        </div>
      </div>
    </mat-card-actions>

  </mat-card>

</div>
