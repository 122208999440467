import {
  AfterViewInit, ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
  OnDestroy
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  allStringArrayElementsEmpty,
  twoDecimalPlaceFormat as twoDecimalPlaceFormatUtility,
  isDollarAmountEmptyOrNull,
  isNullOrUndefined,
  isStringNullUndefinedOrEmpty,
  simpleSlugFromString, onKeypressEventCheckbox
} from '../../../../common/utility';
import {ModifierCodesPipe} from '../../../../common/pipes/modifier-code.pipe';
import {UsdCurrencyPipe} from '../../../../common/pipes/usd-currency.pipe';
import {ServicesConstants} from '../services.constants';
import {InputMaskService} from '../../../../common/services/support/input-mask/input-mask.service';
import {merge, Subscription} from 'rxjs';
import {
  ApplicationConstants,
  ErrorTypes,
  OtherInsuranceReasonCode
} from '../../../../common/constants/application.constants';
import {HtmlConstants} from '../../../../common/constants/html.constants';
import {ViewStateService} from '../../../../common/services/view-state/view-state.service';
import {CobValidators} from '../services.validators';

interface OtherInsuranceReason {
  code: string;
  description: string;
}

@Component({
  selector: 'app-service-line',
  templateUrl: './service-line.component.html',
  styleUrls: ['./service-line.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceLineComponent implements OnInit, AfterViewInit, OnDestroy {

  /***** START - PRIVATE MEMBERS *****/
  private readonly _otherInsuranceReasons: OtherInsuranceReason[];
  private _isInitialPageLoad: boolean;
  private observableSubscriptions: Subscription[] = [];
  /***** START - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/
  @Input() isCobClaim: boolean;
  @Input() detectChanges: boolean;
  @Input() serviceLine: FormGroup;
  @Input() servicesErrorWrapperConfig;
  @Input() index: number;
  @Input() serviceLineHtmlConstants: any;
  @Output() lastSelectedProcServiceLineIndex = new EventEmitter<number>();
  public errorWrapperConfig;
  procBlurTimeout = null;

  @ViewChild('unitCountInput', {read: ElementRef, static: true}) unitCountInput: ElementRef;
  @ViewChild('billedAmount', {read: ElementRef, static: true}) chargesControl: ElementRef;
  @ViewChild('epsdtCheckboxElement', {read: ElementRef, static: true}) epsdtCheckboxElement: ElementRef;
  @ViewChild('emgCheckboxElement', {read: ElementRef, static: true}) emgCheckboxElement: ElementRef;
  @ViewChildren('diagnosisPointerInputs', {read: ElementRef}) diagnosisPointerInputs: QueryList<ElementRef>;

  // Needed for the template even though it says unused
  allStringArrayElementsEmpty = allStringArrayElementsEmpty;
  isStringEmptyOrNull = isStringNullUndefinedOrEmpty;
  isDollarAmountEmptyOrNull = isDollarAmountEmptyOrNull;
  DIAGNOSIS_CODES = ServicesConstants.DIAGNOSIS_CODES;
  SERVICE_LINES = ServicesConstants.SERVICE_LINES;
  CPT_HCPCS_CODE = ServicesConstants.CPT_HCPCS_CODE;
  MODIFIER_CODES = ServicesConstants.MODIFIER_CODES;
  DIAGNOSIS_POINTERS = ServicesConstants.DIAGNOSIS_POINTERS;
  DIAGNOSIS_POINTERS_MAX_LENGTH = ServicesConstants.MAX_DIAGNOSIS_POINTER_INPUT_LENGTH;
  EMERGENCY_INDICATOR = ServicesConstants.EMERGENCY_INDICATOR;
  EPSDT_INDICATOR = ServicesConstants.EPSDT_INDICATOR;
  BILLED_AMOUNT = ServicesConstants.BILLED_AMOUNT;
  UNIT_COUNT = ServicesConstants.UNIT_COUNT;
  SERVICE_START_DATE = ServicesConstants.SERVICE_START_DATE;
  SERVICE_END_DATE = ServicesConstants.SERVICE_END_DATE;
  OTHER_INSURANCE_ALLOWED_AMOUNT = ServicesConstants.OTHER_INSURANCE_ALLOWED_AMOUNT;
  OTHER_INSURANCE_PAID_AMOUNT = ServicesConstants.OTHER_INSURANCE_PAID_AMOUNT;
  OTHER_INSURANCE_PATIENT_PAID_AMOUNT = ServicesConstants.OTHER_INSURANCE_PATIENT_PAID_AMOUNT;
  OTHER_INSURANCE_DENIED_NOT_COVERED_REASON = ServicesConstants.OTHER_INSURANCE_DENIED_NOT_COVERED_REASON;
  ERROR_TYPES = ErrorTypes;
  twoDecimalPlaceFormat = twoDecimalPlaceFormatUtility;
  precedingZeroForDecimalPipe = UsdCurrencyPipe.precedingZeroForDecimalPipe;
  dxPointerIndex: number;
  // Assigning functions from utility class to variables so that these can be accessed  in template
  onKeypressEventCheckbox = onKeypressEventCheckbox;
  /***** END - PUBLIC MEMBERS *****/

  constructor(
    private inputMaskService: InputMaskService,
    private changeDetector: ChangeDetectorRef,
    private viewStateService: ViewStateService,
  ) {
    this._otherInsuranceReasons = [
      {
        code: OtherInsuranceReasonCode.NotCovered,
        description: 'Not Covered'
      },
      {
        code: OtherInsuranceReasonCode.Deductible,
        description: 'Deductible'
      },
      {
        code: OtherInsuranceReasonCode.MaxAllowanceMet,
        description: 'Max Allowance Met'
      },
      {
        code: OtherInsuranceReasonCode.BundledService,
        description: 'Bundled Service'
      },
      {
        code: OtherInsuranceReasonCode.TimelyFiling,
        description: 'Timely Filing'
      },
      {
        code: OtherInsuranceReasonCode.Caption,
        description: 'Capitation' // Deliberately named 'Capitation' as the backend enumeration has this value named Caption
      }
    ];
  }

  /***** START - PROPERTY ACCESSORS *****/
  get otherInsuranceReasons(): OtherInsuranceReason[] {
    return this._otherInsuranceReasons;
  }

  // Convenience getter for the COB controls
  get cobControls(): AbstractControl[] {
    return [
      this.serviceLine.get(this.OTHER_INSURANCE_ALLOWED_AMOUNT),
      this.serviceLine.get(this.OTHER_INSURANCE_PAID_AMOUNT),
      this.serviceLine.get(this.OTHER_INSURANCE_PATIENT_PAID_AMOUNT),
      this.serviceLine.get(this.OTHER_INSURANCE_DENIED_NOT_COVERED_REASON),
    ];
  }

  get conditionallyRequiredControlsIfCobClaim(): AbstractControl[] {
    return [
      this.serviceLine.get(this.CPT_HCPCS_CODE),
      this.serviceLine.get(this.BILLED_AMOUNT),
      this.serviceLine.get(this.UNIT_COUNT)
    ];
  }

  get isInitialPageLoad(): boolean {
    return this._isInitialPageLoad;
  }

  set isInitialPageLoad(isInitialLoad: boolean) {
    this._isInitialPageLoad = isInitialLoad;
  }

  get serviceLinesValidatorsMap(): Map<AbstractControl, ValidatorFn[] | null> {
    return new Map<AbstractControl, ValidatorFn[]>()
      .set(this.serviceLine.get(this.CPT_HCPCS_CODE), CobValidators.cptHcpcCode)
      .set(this.serviceLine.get(this.BILLED_AMOUNT), CobValidators.billedAmount)
      .set(this.serviceLine.get(this.UNIT_COUNT), CobValidators.unitCountRequired);
  }

  get currentIndex(): number {
    return this.index;
  }

  // Convenience getter for COB claim state
  get cobClaim(): boolean {
    return this.isCobClaim;
  }

  /***** END - PROPERTY ACCESSORS *****/

  /***** START - PRIVATE METHODS *****/
  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      cptHcpcsCodeRequired: {
        control: this.serviceLine.get(this.CPT_HCPCS_CODE),
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a HCPC/CPT code'
        }]
      },
      allOrNoneDiagnosisPointers: {
        control: this.serviceLine.get(this.DIAGNOSIS_POINTERS),
        errors: [{
          validatorType: ErrorTypes.ServiceLinesAllOrNone,
          errorMessage: 'Please enter at least one Diagnosis'
        }]
      },
      billedAmountRequired: {
        control: this.serviceLine.get(this.BILLED_AMOUNT),
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a value for Charges'
        }]
      },
      unitCountRequired: {
        control: this.serviceLine.get(this.UNIT_COUNT),
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the number of Units'
        }]
      },
      cptHcpcsCode: {
        control: this.serviceLine.get(this.CPT_HCPCS_CODE),
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'Procedure code must be 5 characters'
        }]
      },
      modifierCodes: {
        control: this.serviceLine.get(this.MODIFIER_CODES),
        errors: [
          {
            validatorType: ErrorTypes.ModifierCodes,
            errorMessage: 'Please enter a valid two character modifier'
          },
          {
            validatorType: ErrorTypes.ModifierCodesLength,
            errorMessage: 'Please enter up to 4 modifier codes'
          }]
      },
      billedAmount: {
        control: this.serviceLine.get(this.BILLED_AMOUNT),
        errors: [
          {
            validatorType: ErrorTypes.DollarAmount,
            errorMessage: 'Please enter a valid amount'
          }
        ]
      },
      billedAmountMax: {
        control: this.serviceLine.get(this.BILLED_AMOUNT),
        errors: [
          {
            validatorType: ErrorTypes.MaxDollarAmount,
            errorMessage: `The maximum value accepted is $${ApplicationConstants.maxDollarAmountAllowed}`
          }
        ]
      },
      otherInsuranceAllowedAmount: {
        control: this.serviceLine.get(this.OTHER_INSURANCE_ALLOWED_AMOUNT),
        errors: [
          {
            validatorType: ErrorTypes.DollarAmount,
            errorMessage: 'Please enter a valid dollar value.'
          }
        ]
      },
      otherInsurancePaidAmount: {
        control: this.serviceLine.get(this.OTHER_INSURANCE_PAID_AMOUNT),
        errors: [
          {
            validatorType: ErrorTypes.DollarAmount,
            errorMessage: 'Please enter a valid dollar value.'
          }
        ]
      },
      otherInsurancePatientPaidAmount: {
        control: this.serviceLine.get(this.OTHER_INSURANCE_PATIENT_PAID_AMOUNT),
        errors: [
          {
            validatorType: ErrorTypes.DollarAmount,
            errorMessage: 'Please enter a valid dollar value.'
          }
        ]
      },
      otherInsuredAllowedAmountRequired: {
        control: this.serviceLine.get(this.OTHER_INSURANCE_ALLOWED_AMOUNT),
        errors: [
          {
            validatorType: ErrorTypes.Required,
            errorMessage: 'Please enter a value for Other Ins Allowed'
          }
        ]
      },
      otherInsuredPaidAmountRequired: {
        control: this.serviceLine.get(this.OTHER_INSURANCE_PAID_AMOUNT),
        errors: [
          {
            validatorType: ErrorTypes.Required,
            errorMessage: 'Please enter a value for Other Ins Paid'
          }
        ]
      },
      otherInsuredPatientPaidRequired: {
        control: this.serviceLine.get(this.OTHER_INSURANCE_PATIENT_PAID_AMOUNT),
        errors: [
          {
            validatorType: ErrorTypes.Required,
            errorMessage: 'Please enter a value for Other Ins Pat Resp'
          }
        ]
      },
      otherInsuredAllowedAmountMax: {
        control: this.serviceLine.get(this.OTHER_INSURANCE_ALLOWED_AMOUNT),
        errors: [
          {
            validatorType: ErrorTypes.MaxDollarAmount,
            errorMessage: `The maximum value accepted is $${ApplicationConstants.maxDollarAmountAllowed}`
          }
        ]
      },
      otherInsuredPaidAmountMax: {
        control: this.serviceLine.get(this.OTHER_INSURANCE_PAID_AMOUNT),
        errors: [
          {
            validatorType: ErrorTypes.MaxDollarAmount,
            errorMessage: `The maximum value accepted is $${ApplicationConstants.maxDollarAmountAllowed}`
          }
        ]
      },
      otherInsuredPatientPaidAmountMax: {
        control: this.serviceLine.get(this.OTHER_INSURANCE_PATIENT_PAID_AMOUNT),
        errors: [
          {
            validatorType: ErrorTypes.MaxDollarAmount,
            errorMessage: `The maximum value accepted is $${ApplicationConstants.maxDollarAmountAllowed}`
          }
        ]
      },
      otherInsuranceDeniedNotCoveredReason: {
        control: this.serviceLine.get(this.OTHER_INSURANCE_DENIED_NOT_COVERED_REASON),
        errors: [
          {
            validatorType: ErrorTypes.DeniedOrPaidReasonConditionallyRequired,
            errorMessage: 'Please select a Denied or Paid $0.00 Reason code.'
          }
        ]
      },
      unitCount: {
        control: this.serviceLine.get(this.UNIT_COUNT),
        errors: [{
          validatorType: ErrorTypes.UnitCountsValidator,
          errorMessage: 'Please enter a valid value for Units'
        }]
      }
    };
  }

  /**
   * Helper method to focus an element by ID.
   *
   * @param elementId
   */
  private focusServiceLineHTMLElementById(elementId: string): void {
    const elementToFocus: HTMLElement = document.getElementById(elementId) as HTMLElement;
    if (elementToFocus) {
      elementToFocus.focus();
    }
  }

  /**
   * Helper method to check if element is hidden
   *
   * @param elementId
   */
  private isHTMLElementVisible(elementId: string): boolean {
    const elementToCheck: HTMLElement = document.getElementById(elementId) as HTMLElement;
    return elementToCheck ? true : false;
  }

  /**
   * Helper method to check if element is editable
   *
   * @param elementId
   */
  private isHTMLElementDisabled(elementId: string): boolean {
    const elementToCheck: HTMLElement = document.getElementById(elementId) as HTMLElement;
    return elementToCheck['disabled'];
  }

  /**
   * Retrieve the service line number from the blur event targeted checkbox input element. Used by the registered
   * blur event handler for the EMG and EPSDT material checkboxes for targeting their respective next element.
   *
   * @see ServiceLineComponent#onCheckboxInputBlur
   * @param {string} targetId - FocusEvent#relatedTarget element ID
   */
  private getServiceLineFromTargetedCheckboxInputElement(targetId: string): string {
    const serviceLineIndexMatch: RegExpMatchArray = targetId.match(/\d{1,2}/);
    return (serviceLineIndexMatch && serviceLineIndexMatch.length > 0) ? serviceLineIndexMatch[0] : undefined;
  }

  private hasRequiredValidator(control: AbstractControl): boolean {
    const compositeValidator: ValidationErrors = control.validator({} as AbstractControl);
    return compositeValidator && compositeValidator.hasOwnProperty('required');
  }

  /***** START - PRIVATE METHODS *****/

  /***** START - LIFECYCLE HOOKS *****/
  ngOnInit() {
    this.isInitialPageLoad = true;
    this.buildErrorWrapperConfig();

    // Register view state changes from the COB button
    this.observableSubscriptions.push(this.viewStateService.onIsCobClaim.subscribe(() => {
      // As the style attributes on the rendered page are updated depending on value, we need to tell the framework we've
      // triggered a change after the page has been checked for form validation. Without programmatically forcing the
      // change detection, a claim that is COB will cause an expressionChangedAfterViewInit exception.
      if (this.isInitialPageLoad) {
        this.changeDetector.detectChanges();
      }
    }));

    // Reference the COB form control value change observables
    const cobAndConditionallyRequiredControlValueChangeReferences = merge(
      this.serviceLine.get(this.OTHER_INSURANCE_ALLOWED_AMOUNT).valueChanges,
      this.serviceLine.get(this.OTHER_INSURANCE_PAID_AMOUNT).valueChanges,
      this.serviceLine.get(this.OTHER_INSURANCE_PATIENT_PAID_AMOUNT).valueChanges,
      this.serviceLine.get(this.OTHER_INSURANCE_DENIED_NOT_COVERED_REASON).valueChanges,
      this.serviceLine.get(this.CPT_HCPCS_CODE).valueChanges,
      this.serviceLine.get(this.BILLED_AMOUNT).valueChanges,
      this.serviceLine.get(this.UNIT_COUNT).valueChanges
    );

    // Register validator updates to the conditionally required controls on page load
    this.observableSubscriptions.push(cobAndConditionallyRequiredControlValueChangeReferences.subscribe(() => {
      if (this.cobControls.some((control: AbstractControl) => !isStringNullUndefinedOrEmpty(control.value as string))) {
        this.conditionallyRequiredControlsIfCobClaim.forEach((control: AbstractControl) => {
          // If the control already has the required validators, continue to the next iteration
          if (this.hasRequiredValidator(control)) {
            return;
          }

          // Add the required validator if a value is detected
          const validatorsToSet: ValidatorFn | ValidatorFn[] = isNullOrUndefined(control.validator) ? Validators.required : [control.validator, Validators.required];
          control.setValidators(validatorsToSet);
          control.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);
        });
      } else if (this.conditionallyRequiredControlsIfCobClaim.some((control: AbstractControl) => this.hasRequiredValidator(control))) {
        this.conditionallyRequiredControlsIfCobClaim
          .filter((control: AbstractControl) => this.hasRequiredValidator(control))
          .forEach((control: AbstractControl) => {
            control.clearValidators();
            control.setValidators(this.serviceLinesValidatorsMap.get(control));
            control.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);
          });
      }


    }));


    this.observableSubscriptions.push(this.serviceLine.get(this.CPT_HCPCS_CODE).valueChanges.subscribe((changed) => {
      if (isStringNullUndefinedOrEmpty(changed)) {
        // Disable and reset each COB control if enabled
        this.cobControls.forEach((control: AbstractControl) => {
          control.reset();
          control.disable();
        });

        this.serviceLine.patchValue({
          emergencyIndicator: false,
          modifierCodes: null,
          billedAmount: null,
          unitCount: null,
          epsdtIndicator: false
        });
        const diagnosisPointersFormArray = <FormArray>this.serviceLine.get(this.DIAGNOSIS_POINTERS);
        Object.keys(diagnosisPointersFormArray.controls).forEach((diagnosisPointer) => {
          if (this.index === 0 && diagnosisPointer === '0') {
            diagnosisPointersFormArray.controls[diagnosisPointer].setValue('A');
          } else {
            diagnosisPointersFormArray.controls[diagnosisPointer].setValue(null);
          }
        });
      } else {
        // Enable each COB control if not already enabled
        this.cobControls.filter((control: AbstractControl) => control.disabled)
          .forEach((control: AbstractControl) => control.enable());
      }
    }));

    // When submit finds UI edits detect changes on component
    this.observableSubscriptions.push(this.viewStateService.onSubmitFoundUIEdits.subscribe((hasUIEdits: boolean) => {
      if (hasUIEdits) {
        this.changeDetector.detectChanges();
      }
    }));
  }

  ngAfterViewInit(): void {
    this.isInitialPageLoad = false;
    if (this.diagnosisPointerInputs) {
      this.diagnosisPointerInputs.forEach(diagnosisPointerInput => {
        // Assign alpha-only mask to each diagnosis pointer input
        this.inputMaskService.createInputMask(diagnosisPointerInput.nativeElement, this.inputMaskService.alphabeticalAlias, {
          showMaskOnFocus: false,
          placeholder: ''
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /***** END - LIFECYCLE HOOKS *****/

  /***** START - EVENT HANDLERS *****/
  modifierCodesPipeWrapper(formControl): any {
    return new ModifierCodesPipe().transform(formControl.value);
  }

  cancelProcBlurTimeout(): void {
    if (this.procBlurTimeout) {
      clearTimeout(this.procBlurTimeout);
      this.procBlurTimeout = null;
    }
  }

  onPROCBlur(serviceLineIndex: number): void {
    this.procBlurTimeout = null;
    this.lastSelectedProcServiceLineIndex.emit(serviceLineIndex);
    this.startProcBlurTimeout();
  }

  startProcBlurTimeout(): void {
    this.cancelProcBlurTimeout();
    this.procBlurTimeout = setTimeout(() => {
      this.lastSelectedProcServiceLineIndex.emit(null);
    }, 200);
  }

  onDollarAmountFieldsBlur(formFieldId: string): void {
    const formField = this.serviceLine.get(formFieldId);
    this.twoDecimalPlaceFormat(formField);
  }

  /**
   * Keydown forward tab event handler for the billed amount fields, focuses the user on the next service line billed amount.
   * Navigate user to FSA/Paid amount if on the last service line billed amount field and navigating in the forward direction.
   *
   * @param {string} billedAmountElementId - Current element ID used to target the next billed amount it
   * @param {number} serviceLineIndex - Current service line position
   * @return false - Returning false to the keydown event handler calls preventPropagation internally, preventing tab skipping
   */
  onBilledAmountForwardTabNavigation(billedAmountElementId: string, serviceLineIndex: number): boolean {
    if (serviceLineIndex === (this.serviceLine.parent as FormArray).length - 1) {
      if (this.isHTMLElementVisible(HtmlConstants.attributes.fsaPaidAmountId) && !this.isHTMLElementDisabled(HtmlConstants.attributes.fsaPaidAmountId)) {
        // Focus on the FSA paid amount, if FSA is visible and enabled
        this.focusServiceLineHTMLElementById(HtmlConstants.attributes.fsaPaidLinkId);
      } else {
        // Focus the patient paid amount, if on the FSA is hidden or disabled
        this.focusServiceLineHTMLElementById(HtmlConstants.attributes.patientPaymentAmountId);
      }
    } else {
      // Grab a reference to the next billed amount input and focus it, if it exists
      const nextBilledAmountId = billedAmountElementId.replace(serviceLineIndex.toString(), (++serviceLineIndex).toString());
      this.focusServiceLineHTMLElementById(nextBilledAmountId);
    }

    return false;
  }

  /**
   * Keydown reverse tab event handler for the billed amount fields, focuses the user on the previous service line billed amount.
   * Navigate user to the last diagnosis code if on the first service line billed amount field.
   *
   * @param {string} billedAmountElementId - Current element ID used to target the next billed amount it
   * @param {number} serviceLineIndex - Current service line position
   * @return false - Returning false to the keydown event handler calls preventPropagation internally, preventing tab skipping
   */
  onBilledAmountReverseTabNavigation(billedAmountElementId: string, serviceLineIndex: number): boolean {
    // Grab a reference to the next billed amount input and focus it, if it exists
    if (serviceLineIndex > 0) {
      const previousBilledAmount: string = billedAmountElementId.replace(serviceLineIndex.toString(), (--serviceLineIndex).toString());
      this.focusServiceLineHTMLElementById(previousBilledAmount);
    } else {
      // Gets the last EPSDT checkbox and Denied or Paid Dropdown
      const serviceLineFormArray = this.serviceLine.parent as FormArray;
      const lastServiceLine: FormGroup = (serviceLineFormArray.controls && serviceLineFormArray.controls.length > 0) ? serviceLineFormArray.controls[serviceLineFormArray.controls.length - 1] as FormGroup : undefined;
      const lastEPSDTCheckbox: HTMLElement = lastServiceLine ? lastServiceLine.get(this.EPSDT_INDICATOR)['nativeElement'] as HTMLElement : undefined;
      const lastDeniedOrPaidDropdown: HTMLElement = lastServiceLine ? lastServiceLine.get(this.OTHER_INSURANCE_DENIED_NOT_COVERED_REASON)['nativeElement'] as HTMLElement : undefined;

      // Checks if the last Denied or Paid dropdown is available and enabled if so focus on it,
      // else focus on the last EPSDT checkbox element
      if (!isNullOrUndefined(lastDeniedOrPaidDropdown) && !lastDeniedOrPaidDropdown['disabled']) {
        lastDeniedOrPaidDropdown.focus();
      } else if (!isNullOrUndefined(lastEPSDTCheckbox) && !lastEPSDTCheckbox['disabled']) {
        this.focusServiceLineHTMLElementById(lastEPSDTCheckbox.id + '-input');
      }
    }

    return false;
  }

  /**
   * TAB event handler for the EPSDT checkbox.
   *
   * @param {FocusEvent} event
   * @param {number} serviceLineIndex
   */
  onTabOfLastEPSDTCheckbox(serviceLineIndex: number): boolean {
    if (serviceLineIndex === (this.serviceLine.parent as FormArray).length - 1) {
      const serviceLineFormArray = this.serviceLine.parent as FormArray;
      const lastServiceLine: FormGroup = (serviceLineFormArray.controls && serviceLineFormArray.controls.length > 0) ? serviceLineFormArray.controls[serviceLineFormArray.controls.length - 1] as FormGroup : undefined;
      const otherInsuredInputElement: HTMLElement = lastServiceLine ? lastServiceLine.get(this.OTHER_INSURANCE_ALLOWED_AMOUNT)['nativeElement'] as HTMLElement : undefined;

      // Checks if COB fields are visible and enabled, if not focus on first Charges element
      if (isNullOrUndefined(otherInsuredInputElement) || otherInsuredInputElement['disabled']) {
        this.focusServiceLineHTMLElementById(HtmlConstants.attributes.firstBilledAmountId);
        return false;
      }
    }
    return true;
  }

  /**
   * Blur event handler for the Other Insurance Denied Or Paid Reason dropdown box.
   *
   * @param {FocusEvent} event
   * @param {number} serviceLineIndex
   */
  onBlurOfLastDeniedOrPaidDropdown(event: FocusEvent, serviceLineIndex: number): void {
    if (isNullOrUndefined(event.relatedTarget)) {
      return;
    }

    if (event.relatedTarget['id'] === HtmlConstants.attributes.addProcedureButtonId) {
      this.focusServiceLineHTMLElementById(HtmlConstants.attributes.firstBilledAmountId);
    }
  }

  /**
   * Checks to see if any of the Diagnosis pointers have an invalid Diagnosis Code
   * if so, return true, else return false
   *
   */
  invalidDiagnosisCodeErrorIsVisible(): boolean {
    const diagnosisPointerElements = this.serviceLine.get(this.DIAGNOSIS_POINTERS)['controls'];
    let isVisible: boolean = false;

    diagnosisPointerElements.forEach(diagnosisPointerElement => {
      if (!isNullOrUndefined(diagnosisPointerElement) && diagnosisPointerElement.hasError(this.ERROR_TYPES.InvalidDiagnosisCode)) {
        isVisible = true;
      }
      // }
    });
    return isVisible;
  }

  /***** END - EVENT HANDLERS *****/

  /***** START - PUBLIC METHODS *****/
  toSlugId(stringToSlug: string): string {
    return simpleSlugFromString(stringToSlug);
  }

  public checkUnitCount(unitCountCtl: AbstractControl, hcpcCode: AbstractControl): boolean {
    let hideIt = true;
    if (isStringNullUndefinedOrEmpty(hcpcCode.value) && hcpcCode.touched) {
      unitCountCtl.reset();
      hcpcCode.markAsUntouched();
    }

    if (!isStringNullUndefinedOrEmpty(hcpcCode.value) && isStringNullUndefinedOrEmpty(unitCountCtl.value)
      && this.unitCountInput.nativeElement.id === document.activeElement.id) {
      unitCountCtl.setErrors(Validators.required(unitCountCtl));
      hideIt = false;
    }
    return !isStringNullUndefinedOrEmpty(unitCountCtl.value) && hideIt;
  }

  /***** END - PUBLIC METHODS *****/
}
