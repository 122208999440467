<div fxLayout="column" class="patient-selection">

  <!-- Header Section -->
  <div fxLayout="column" class="margin-left-10 margin-right-10">
    <!-- Member Name Header and info -->
    <h1 fxLayout="row" id="patientSelect-memberName" class="eclaim-header-text margin-bottom-0">{{memberFullName}}</h1>
    <div fxLayout="row" fxLayoutAlign="space-between" fxFlexOffset="15px">
      <p id="patientSelect-planDetails" class="eclaim-body-text margin-bottom-0">{{membershipCoverageText}}</p>
      <p id="patientSelect-hipaaCompliant" class="eclaim-body-text margin-bottom-0"><i>This eligibility transaction is
        HIPAA compliant</i></p>
    </div>
  </div>

  <!-- No Authorizations Message -->
  <p class="patient-selection-no-authorizations" fxFlexOffset="20px"
     *ngIf="!hasAuthorizations() && !initialLoadingOfAuthorizations">There are no authorizations to display.</p>

  <!-- Authorization Table -->
  <mat-card fxFlexOffset="20px" *ngIf="hasAuthorizations() || initialLoadingOfAuthorizations">

    <!-- Authorizations Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 id="list-of-authorizations-header">
          Retrieve or Delete an Existing Authorization
        </h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Authorizations -->
    <mat-card-content fxLayout="column" class="eclaim-card-content-with-table" *ngIf="!loadingAuthorizations">

      <!-- Authorizations Table -->
      <mat-table matSort [dataSource]="authorizationSelectionTableDataSource" id="auth-selection-table">

        <!-- Authorization Number -->
        <ng-container matColumnDef="authorization" *ngIf="!loadingAuthorizations;">
          <mat-header-cell *matHeaderCellDef id="auth-search-result-auth-number-header" class="nested-column-2">
            Authorization
          </mat-header-cell>
          <mat-cell
            id="auth-search-result-auth-number-data-{{baseID}}"
            class="action-link nested-column-2"
            *matCellDef="let vsr; index as baseID"
            (click)="retrieveAuthorization(vsr)">
            {{vsr.vsrNumber}}
          </mat-cell>
        </ng-container>

        <!-- Name of patient the authorization belongs to -->
        <ng-container matColumnDef="name" *ngIf="!loadingAuthorizations">
          <mat-header-cell *matHeaderCellDef id="auth-search-result-name-header" class="nested-column-3">Name (Last,
            First)
          </mat-header-cell>
          <mat-cell *matCellDef="let vsr" id="auth-search-result-name-data"
                    class="nested-column-3">{{getFullName(vsr.reifiedPatient.name)}}</mat-cell>
        </ng-container>

        <!-- Business product name tied to the authorization -->
        <ng-container matColumnDef="product" *ngIf="!loadingAuthorizations">
          <mat-header-cell *matHeaderCellDef id="auth-search-result-product-header" class="nested-column-4">Product
          </mat-header-cell>
          <mat-cell *matCellDef="let vsr" id="auth-search-result-product-data"
                    class="nested-column-4">{{vsr.businessProductPackageName}}</mat-cell>
        </ng-container>

        <!-- Date the authorization expires on -->
        <ng-container matColumnDef="expires" *ngIf="!loadingAuthorizations">
          <mat-header-cell *matHeaderCellDef id="auth-search-result-expires-header" class="nested-column-2">Expires
          </mat-header-cell>
          <mat-cell *matCellDef="let vsr" id="auth-search-result-expires-data"
                    class="nested-column-2">{{vsr.expirationDate}}</mat-cell>
        </ng-container>

        <!-- Delete the authorization -->
        <ng-container matColumnDef="delete" *ngIf="!loadingAuthorizations">
          <mat-header-cell *matHeaderCellDef id="auth-search-result-delete-header"
                           class="nested-column-1"></mat-header-cell>
          <mat-cell
            id="auth-search-result-delete-data-{{baseID}}"
            class="trash-column nested-column-1"
            *matCellDef="let vsr; index as baseID"
            (click)="deleteAuthorization(vsr)"
            fxLayout="row"
            fxLayoutAlign="center center">
            <mat-icon svgIcon="eclaim-icons:trash" class="trash-icon"></mat-icon>
          </mat-cell>
        </ng-container>

        <mat-header-row fxLayout="row" *matHeaderRowDef="authorizationSelectionColumnHeaders"></mat-header-row>
        <mat-row fxLayout="row"
                 *matRowDef="let authSelectionRow; columns: authorizationSelectionColumnHeaders"></mat-row>

      </mat-table>

      <div fxLayout="row" fxLayoutAlign="end center" fxFlexOffset="40px">

        <!-- Refresh -->
        <button mat-flat-button color="primary"
                class="mat-flat-button mat-primary"
                (click)="refreshAuthorizations()" id="patientSelection-refreshButton">
          Refresh
        </button>
        <div fxFlexOffset="20px"></div>
      </div>
      <div fxFlexOffset="30px"></div>

    </mat-card-content>

  </mat-card>

  <!-- Patient Selection Table -->
  <mat-card fxFlexOffset="30px">

    <!-- Coverage Summary Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 id="patientSelection-view-coverage-summary">
          View Coverage Summary and Issue Authorization
        </h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Display member policy retrieval results, if any -->
    <mat-card-content *ngIf="businessMemberPoliciesResults">
      <mat-table matSort [dataSource]="patientSelectionTableDataSource" id="patient-selection-member-result-table">

        <!-- Member name -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header id="member-search-result-name-header"
                           class="nested-column-7">Name (Last, First)
          </mat-header-cell>
          <mat-cell id="patient-selection-result-name-data" class="action-link nested-column-7" *matCellDef="let patient"
                    (click)="retrieveEligibilityOnPatientSelection(patient)">
            {{patient.name}}
          </mat-cell>
        </ng-container>

        <!-- Relation to member -->
        <ng-container matColumnDef="relationToSubscriber">
          <mat-header-cell *matHeaderCellDef mat-sort-header id="patient-selection-result-relation-header"
                           class="nested-column-3">Relation
          </mat-header-cell>
          <mat-cell *matCellDef="let member" id="patient-selection-result-relation-data"
                    class="nested-column-3">{{getRelationToMatchRelationCode(member.relationCode)}}</mat-cell>
        </ng-container>

        <!-- Member date of birth (non-sortable) -->
        <ng-container matColumnDef="dateOfBirth">
          <mat-header-cell *matHeaderCellDef id="patient-selection-result-city-dob-header" class="nested-column-2">Date
            of Birth
          </mat-header-cell>
          <mat-cell *matCellDef="let member; index as i" id="patient-selection-result-city-dob-data-{{i}}"
                    class="nested-column-2">{{member.dateOfBirth | date: 'MM/dd/yyyy'}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="patientSelectionColumnHeaders"></mat-header-row>
        <mat-row *matRowDef="let patientSelectionRow; columns: patientSelectionColumnHeaders"></mat-row>
      </mat-table>

      <div *ngIf="patientSelectionTableDataSource != null && patientSelectionTableDataSource.data.length === 0"
           class="no-records"
           fxLayout="row" id="patient-selection-no-result">
        No Results Found
      </div>
    </mat-card-content>
  </mat-card>

  <!-- Search Plans Section - only show if tracksDependents flag is false -->
  <!-- TODO: Need to update this if condition to use the tracks dependents value that should return from member policies retrieve after consumer team implements it -->
  <mat-card fxFlexOffset="30px" id="add-dependent-section" *ngIf="isNonTrackingDependentClient">

    <!-- Add Dependent Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 id="add-dependent-header">
          Add a Dependent
        </h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Search Plans Instructions -->
    <label fxLayout="row" class="nested-column-12 margin-top-30 margin-bottom-20">
      To view plans and issue an Authorization for a dependent not listed above, enter the required information below,
      then click ‘View plans and issue Authorization.’
    </label>

    <!-- Search Plans Form -->
    <form #addDependentForm fxLayout="column" [formGroup]="patientSelectionAddDependentForm"
          (ngSubmit)="onViewPlansAndIssueAuthorization()" novalidate>

      <div fxLayout="row">

        <div fxLayout="column" class="nested-column-3">
          <label for="patient-select-search-plans-last-name"><span class="required-label-asterisk">* </span>Last
            Name:</label>
          <mat-form-field appearance="outline" fxFlexOffset="15px" class="required-mat-form-field">
            <input matInput
                   [maxLength]="lastNameMaxLength"
                   (keyup)="trimName(this.patientSelectionAddDependentForm.get('lastName'))"
                   formControlName="lastName"
                   id="patient-select-search-plans-last-name"
                   type="text"
                   autocomplete="off"/>
          </mat-form-field>
          <app-error-wrapper [config]="errorWrapperConfig.lastName"></app-error-wrapper>
        </div>

        <div fxLayout="column" class="nested-column-3">
          <label for="patient-select-search-plans-first-name"><span class="required-label-asterisk">* </span>First Name:</label>
          <mat-form-field appearance="outline" fxFlexOffset="15px" class="required-mat-form-field">
            <input matInput
                   [maxLength]="firstNameMaxLength"
                   (keyup)="trimName(this.patientSelectionAddDependentForm.get('firstName'))"
                   formControlName="firstName"
                   id="patient-select-search-plans-first-name"
                   type='text'
                   autocomplete="off"/>
          </mat-form-field>
          <app-error-wrapper [config]="errorWrapperConfig.firstName"></app-error-wrapper>
        </div>

        <div fxLayout="column" class="nested-column-3">
          <label for="patient-select-search-plans-relationship"><span class="required-label-asterisk">* </span>Relationship:</label>
          <select matSelect
                  fxFlexOffset="15px"
                  formControlName="relationship"
                  class="custom-select required-form-field"
                  id="patient-select-search-plans-relationship">
            <option selected disabled>Select Relationship</option>
            <option></option>
            <option *ngFor="let relation of searchPlansRelationshipOptions"
                    [value]="relation.relationCode">{{relation.relation}}</option>
          </select>
          <app-error-wrapper [config]="errorWrapperConfig.relationship"></app-error-wrapper>
        </div>

        <div fxLayout="column" class="nested-column-3">
          <label [for]="datePickerConfigurationDateOfBirth.attributes.id"><span
            class="required-label-asterisk">* </span>Date of Birth:</label>
          <app-date-picker class="required-mat-form-field" id="patient-selection-date-picker"
                           [datePickerConfiguration]="datePickerConfigurationDateOfBirth"
                           fxFlexOffset="15px"></app-date-picker>
        </div>

      </div>

      <div fxLayout="row" fxLayoutAlign="end center" fxFlexOffset="40px">

        <!-- Issue Authorization Button -->
        <button mat-flat-button
                class="mat-flat-button mat-primary"
                type="submit"
                [disabled]="!patientSelectionAddDependentForm.valid"
                id="patient-selection-view-plans-and-issue-authorization-button"
                color="primary">
          View plans and issue Authorization
        </button>
        <div fxFlexOffset="20px"></div>
      </div>
      <div fxFlexOffset="30px"></div>

    </form>
  </mat-card>
</div>
