import {Component, Injector, OnInit} from '@angular/core';
import {Environment} from '../environments/environment.interface';
import {environment} from '../environments/environment';
import {ApplicationConstants} from './common/constants/application.constants';
import {isNullOrUndefined, isStringNullUndefinedOrEmptyWithTrim} from './common/utility';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

export let InjectorInstance: Injector;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly SCRIPT_TAG = 'script';
  private BLANK = '';
  private _environment: Environment;

  constructor(
    private injector: Injector,
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // Capturing an instance of the dependency injector to use in un instantiated classes with static methods
    InjectorInstance = this.injector;
    this._environment = environment;
    this.registerIcons();
  }

  title = 'app';

  ngOnInit(): void {
    this.loadDynatraceLibrary();
  }

  /**
   * Note this logic was added for ECLAIM-165 to add Dynatrace traceability into the app.
   * @private
   */
  private loadDynatraceLibrary() {
    if (!isNullOrUndefined(environment) && !isStringNullUndefinedOrEmptyWithTrim(environment.envName) && !isStringNullUndefinedOrEmptyWithTrim(environment.dynatraceUrl)) {
      const lowerCaseEnvironmentName: string = environment.envName.trim().toLowerCase();
      if (lowerCaseEnvironmentName === ApplicationConstants.staging || lowerCaseEnvironmentName === ApplicationConstants.production) {
        const body = <HTMLDivElement> document.body;
        const dynatraceScript = document.createElement(this.SCRIPT_TAG);
        dynatraceScript.innerHTML = this.BLANK;
        dynatraceScript.async = true;
        dynatraceScript.crossOrigin = 'anonymous';
        dynatraceScript.src = environment.dynatraceUrl;
        body.appendChild(dynatraceScript);
      }
    }
  }

  /**
   * Function registers the icon sets with the MatIconRegistry so that the namespaces are accessible in the DOM.
   * @private
   */
  private registerIcons(): void {
    this.iconRegistry.addSvgIconSetInNamespace('eclaim-icons', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eclaim-icons.svg'));
    this.iconRegistry.addSvgIconSetInNamespace('vsp-icons', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/vsp.icons.svg'));
    this.iconRegistry.addSvgIconSetInNamespace('vsp-branding-images', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/vsp.branding-images.svg'));
  }
}
