<form [formGroup]="diagnosisCode">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start stretch">
      <button mat-button
              [disabled]="diagnosisCode.disabled"
              id="services-diagnosis-code-{{position}}-button"
              class="diagnosis-button"
              (click)="openDiagnosisCodeListModal()">
        <span id="services-diagnosis-code-{{position}}-label" class="diagnosis-label">{{position}}</span>
      </button>
      <mat-form-field appearance="outline" class="diagnosis-input">
        <input matInput (blur)="onDiagnosisCodeBlur($event)" type="text" fxLayoutAlign="start stretch" formControlName="diagnosisCode" id="services-diagnosis-code-{{position}}-textbox">
      </mat-form-field>
    </div>
    <app-error-wrapper id="services-diagnosis-code-{{position}}-input-error" [config]="errorWrapperConfig.invalidDiagnosisCode"></app-error-wrapper>
  </div>
</form>
