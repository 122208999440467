import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidatorsService } from '../../../common/services/support/custom-validators/custom-validators.service';
import { InputMaskService } from '../../../common/services/support/input-mask/input-mask.service';
import { FormConstants } from '../../../common/constants/form.constants';
import { ApplicationConstants, ErrorTypes } from '../../../common/constants/application.constants';
import { ClaimFormItem } from '../../../common/classes/claim-form-item';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ClaimsService } from '../../../common/services/data-model/app/claims/claims.service';
import {Claim, SoftAndHardValidationMessages, ValidationMessage} from '../../../models/claim';
import { Subscription } from 'rxjs';
import { Signature } from '../../../models/signature';
import { ComponentMaskComponent } from '../../../common/components/component-mask/component-mask.component';
import {
  DateUtility,
  isNullOrUndefined,
  onKeypressEventCheckbox,
  returnBooleanFromFormState,
} from '../../../common/utility';
import { FormStateYesNo } from '../../../common/enum/form-state-yes-no';
import { ClaimCardsToUpdate } from '../../../models/claimCardsToUpdate';
import { DatePickerConfiguration } from '../../../common/components/date-picker/date-picker.component';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';

@Component({
  selector: 'app-facility-billing-address',
  templateUrl: './facility-billing.component.html',
  styleUrls: ['./facility-billing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FacilityBillingComponent extends ClaimFormItem implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
    public inputMaskService: InputMaskService,
    private claimsService: ClaimsService,
    private claimEditService: ClaimEditService
  ) {
    super(injector);
  }

  private observableSubscriptions: Subscription[] = [];
  private originalClaim: Claim;
  private activeClaim: Claim;

  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;
  /***** START - PUBLIC MEMBERS *****/
  title = 'Facility and Billing';
  id = ApplicationConstants.componentIDs.facilityAndBilling;
  facilityAndBillingForm: FormGroup;
  errorWrapperConfig = {
    doctorAcceptAssignmentIndicator: undefined,
    doctorSignatureIndicator: undefined,
    patientAccountNumber: undefined,
  };
  readonly = false;
  formStateYesNo = FormStateYesNo;
  stateCodes = FormConstants.stateCodes;
  @ViewChild('doctorSignatureDateControl', {read: ElementRef, static: true})
  doctorSignatureDateControl: ElementRef;
  @ViewChild('facilityAddressZipCodeControl', {read: ElementRef, static: true})
  facilityAddressZipCodeControl: ElementRef;
  showFacilityBillingAddress = false;
  datePickerConfigurationDoctorSignatureDate: DatePickerConfiguration;
  // Assigning functions from utility class to variables so that these can be accessed  in template
  onKeypressEventCheckbox = onKeypressEventCheckbox;
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;
  /***** END - PUBLIC MEMBERS *****/

  /***** START - PRIVATE FUNCTIONS *****/
  private buildDatePickerConfigurationForDoctorSignatureDate(): void {
    this.datePickerConfigurationDoctorSignatureDate = {
      control: this.facilityAndBillingForm.controls.doctorSignatureDate,
      controlName: 'doctorSignatureDate',
      errorWrapperId: {
        defaultValidations: 'facility-and-billing-patient-signature-date-error',
        minDate: 'facility-and-billing-patient-signature-min-date-error'
      },
      attributes: {
        id: 'facility-and-billing-signature-input'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please indicate Signature on File'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Provider Signature Date must be in MM/DD/YYYY format'
        }
      ]
    };
  }

  // TODO: This information has to be populated from UserInfo Service/API
  private buildForm(): void {
    const physicalAddress = this.originalClaim.serviceLocation.physicalAddress;
    const mailingAddress = this.originalClaim.serviceLocation.mailingAddress;
    const doctorSignatureIndicator: boolean = (this.originalClaim.doctorSignature && !isNullOrUndefined(this.originalClaim.doctorSignature.indicator)) ? this.originalClaim.doctorSignature.indicator : true;
    const doctorSignatureDate = (this.originalClaim.doctorSignature && DateUtility.isValidDate(this.originalClaim.doctorSignature.dateSigned)) ? this.originalClaim.doctorSignature.dateSigned : new Date();
    const doctorAcceptAssignmentIndicator = this.originalClaim.doctorAcceptAssignmentIndicator != null ? returnBooleanFromFormState(this.originalClaim.doctorAcceptAssignmentIndicator) : true;

    this.facilityAndBillingForm = this.formBuilder.group({
      patientAccountNumber: [this.originalClaim.patientAccountNumber, [Validators.pattern(/^[a-zA-Z0-9 .\',-]+$/)]],
      doctorAcceptAssignmentIndicator: [doctorAcceptAssignmentIndicator, [this.customValidatorsService.CheckboxRequired]],
      doctorSignatureIndicator: [doctorSignatureIndicator, [this.customValidatorsService.CheckboxRequired]],
      doctorSignatureDate: [DateUtility.buildFriendlyDateFromJsDate(doctorSignatureDate), [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(ApplicationConstants.minDate)]],
      facilityName: [{value: this.originalClaim.serviceLocation.name, disabled: true}, [Validators.required, Validators.pattern(/^[a-zA-Z0-9 .\',-\\(\\)\#\"\&;:]+$/)]],
      facilityAddressStreet1: [{value: physicalAddress.street1, disabled: true}, [Validators.required, Validators.pattern(ApplicationConstants.addressStreetAndCityValidRegex)]],
      facilityAddressStreet2: [{value: physicalAddress.street2, disabled: true}, [Validators.pattern(ApplicationConstants.addressStreetAndCityValidRegex)]],
      facilityAddressCity: [{value: physicalAddress.city, disabled: true}, [Validators.required, Validators.pattern(ApplicationConstants.addressStreetAndCityValidRegex)]],
      facilityAddressStateCode: [{value: physicalAddress.stateCode, disabled: true}],
      facilityAddressZipCode: [{value: physicalAddress.zipCode.zipCode, disabled: true}, [Validators.required, this.customValidatorsService.ZipCodeInvalid]],
      serviceLocationProviderName: [{value: this.originalClaim.providerOfficeBillingName, disabled: true}],
      billingAddressStreet1: [{value: mailingAddress.street1, disabled: true}],
      billingAddressStreet2: [{value: mailingAddress.street2, disabled: true}],
      billingAddressCity: [{value: mailingAddress.city, disabled: true}],
      billingAddressStateCode: [{value: mailingAddress.stateCode, disabled: true}],
      billingAddressZipCode: [{value: mailingAddress.zipCode.zipCode, disabled: true}],
      serviceLocationProviderPhone: [{value: this.originalClaim.serviceLocation.phoneNumber, disabled: true}]
    });
    this.facilityAndBillingForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe(() => {
      this.updateDataModelFromViewModel();
    });
    this.updateDataModelFromViewModel();
  }

  onProviderSignatureChange() {
    const value = this.facilityAndBillingForm.controls.doctorSignatureIndicator.value;
    if (value === true) {
      this.facilityAndBillingForm.controls.doctorSignatureDate.enable();
      this.facilityAndBillingForm.controls.doctorSignatureDate.setValue(DateUtility.buildFriendlyDateFromJsDate(this.activeClaim.doctorSignature.dateSigned));
    } else {
      this.facilityAndBillingForm.controls.doctorSignatureDate.reset();
      this.facilityAndBillingForm.controls.doctorSignatureDate.disable();
    }
  }

  public updateDataModelFromViewModel() {
    const {patientAccountNumber, doctorAcceptAssignmentIndicator, doctorSignatureIndicator, doctorSignatureDate } = this.facilityAndBillingForm.controls;
    const doctorSignature: Signature = {
      indicator: doctorSignatureIndicator.value,
      dateSigned: (doctorSignatureDate.value && doctorSignatureDate.valid) ? DateUtility.buildDateFromDateString(doctorSignatureDate.value) : this.activeClaim.doctorSignature.dateSigned
    };
    this.activeClaim.patientAccountNumber = patientAccountNumber.value;
    this.activeClaim.doctorAcceptAssignmentIndicator = returnBooleanFromFormState(doctorAcceptAssignmentIndicator.value);
    this.activeClaim.doctorSignature = doctorSignature;
    // Update the active claim in the claim service
    if (this.facilityAndBillingCardHasChanged()) {
      this.claimsService.setActiveClaim(this.activeClaim, this.id);
    }
  }

  private facilityAndBillingCardHasChanged(): boolean {
    const { indicator: signatureIndicatorFromCard, dateSigned: signatureDateFromCard } = this.activeClaim.doctorSignature;
    const signatureDateFromCardString = DateUtility.buildFriendlyDateFromJsDate(signatureDateFromCard);
    const { patientAccountNumber: patientAccountNumberFromCard = null, doctorAcceptAssignmentIndicator: doctorAcceptIndicatorFromCard } = this.activeClaim;

    const activeClaimFromService = this.claimsService.getActiveClaim();
    // Instantiate claim objects if they don't exist
    if (isNullOrUndefined(activeClaimFromService.doctorSignature)) { activeClaimFromService.doctorSignature = {} as Signature; }
    const { indicator: signatureIndicatorFromService, dateSigned: signatureDateFromService } = activeClaimFromService.doctorSignature;
    const signatureDateFromServiceString = DateUtility.buildFriendlyDateFromJsDate(signatureDateFromService);
    const { patientAccountNumber: patientAccountNumberFromService = null, doctorAcceptAssignmentIndicator: doctorAcceptIndicatorFromService } = activeClaimFromService;

    return (signatureIndicatorFromCard || undefined) !== (signatureIndicatorFromService || undefined) ||
      (signatureDateFromCardString || undefined) !== (signatureDateFromServiceString || undefined) ||
      (patientAccountNumberFromCard || undefined) !== (patientAccountNumberFromService || undefined) ||
      (doctorAcceptIndicatorFromCard || undefined) !== (doctorAcceptIndicatorFromService || undefined);
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      patientAccountNumber: {
        control: this.facilityAndBillingForm.controls.patientAccountNumber,
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'Please enter a valid Patient Account number'
        }]
      },
      doctorSignatureIndicator: {
        control: this.facilityAndBillingForm.controls.doctorSignatureIndicator,
        errors: [{
          validatorType: ErrorTypes.CheckboxRequired,
          errorMessage: 'Please indicate Signature on File'
        }]
      },
      doctorAcceptAssignmentIndicator: {
        control: this.facilityAndBillingForm.controls.doctorAcceptAssignmentIndicator,
        errors: [{
          validatorType: ErrorTypes.CheckboxRequired,
          errorMessage: 'You must accept assignment to submit the claim'
        }]
      }
    };
  }

  private enableFieldsOnUnmask() {
    this.facilityAndBillingForm.controls.patientAccountNumber.enable();
    this.facilityAndBillingForm.controls.doctorAcceptAssignmentIndicator.enable();
    this.facilityAndBillingForm.controls.doctorSignatureIndicator.enable();
    this.onProviderSignatureChange();
  }

  private buildInputMasks(): void {
    this.inputMaskService.createInputMask(this.facilityAddressZipCodeControl.nativeElement, this.inputMaskService.zipCodeAlias);
  }

  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  getExpandAddressText(): string {
    // show different verbiage in readonly mode (edit doesn't make sense there)
    return 'View Facility and Billing Addresses';
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }
  /***** END - PUBLIC FUNCTIONS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.registerWithClaimProgressService();
    this.originalClaim = this.claimsService.getOriginalClaim();
    // Setting original claim to active claim initially.
    this.activeClaim = this.claimsService.getOriginalClaim();
    this.buildForm();
    this.buildErrorWrapperConfig();
    this.buildDatePickerConfigurationForDoctorSignatureDate();
    this.observableSubscriptions.push(this.claimsService.onCardsToUpdate.subscribe((onCardsToUpdate: ClaimCardsToUpdate) => {
      this.activeClaim = this.claimsService.getActiveClaim();
    }));
    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      if (mask) {
        this.disableFormGroupComponents(this.facilityAndBillingForm);
        this.componentMask.show();
      } else {
        this.enableFieldsOnUnmask();
        this.componentMask.hide();
      }
    }));
    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
          (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }
      }));
  }

  ngAfterViewInit(): void {
    this.buildInputMasks();
  }

  toggleEditAddressesExpanded(): void {
    this.showFacilityBillingAddress = !this.showFacilityBillingAddress;
  }
  /***** END - EVENT HANDLERS *****/

  ngOnDestroy() {
    this.observableSubscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
