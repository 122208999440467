import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidatorsService} from '../../../common/services/support/custom-validators/custom-validators.service';
import {InputMaskService} from '../../../common/services/support/input-mask/input-mask.service';
import {ApplicationConstants, ErrorTypes} from '../../../common/constants/application.constants';
import {ClaimFormItem} from '../../../common/classes/claim-form-item';
import {DateUtility, isNullOrUndefined, onKeypressEventRadiobutton} from '../../../common/utility';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Claim, SoftAndHardValidationMessages, ValidationMessage} from '../../../models/claim';
import {Subscription} from 'rxjs';
import {ClaimsService} from '../../../common/services/data-model/app/claims/claims.service';
import {Membership} from '../../../models/membership';
import {Address} from '../../../models/address';
import {ComponentMaskComponent} from '../../../common/components/component-mask/component-mask.component';
import {ClaimFormUtilityFunctions} from '../claim-form.utilities';
import {ClaimCardsToUpdate} from '../../../models/claimCardsToUpdate';
import {DatePickerConfiguration} from '../../../common/components/date-picker/date-picker.component';
import {FormConstants} from '../../../common/constants/form.constants';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';

export enum ApiFormattedMembershipRelationships {
  Member = 'Member',
  Spouse = 'Spouse',
  Child = 'Child',
  Student = 'Student',
  DependentParent = 'DependentParent',
  DomesticPartner = 'DomesticPartner',
  DisabledDependent = 'DisabledDependent',
  Other = 'Other',
  Unknown = 'Unknown',
}

enum ControlDefaults {
  Male = 'M',
  Female = 'F',
  Member = 'Member',
  Spouse = 'Spouse',
  Child = 'Child',
  Other = 'Other',
}

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PatientComponent extends ClaimFormItem implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
    private claimService: ClaimsService,
    public inputMaskService: InputMaskService,
    private claimEditService: ClaimEditService
  ) {
    super(injector);
    this._stateCodes = FormConstants.stateCodes;
  }


  /***** START - PRIVATE MEMBERS *****/
  private observableSubscriptions: Subscription[] = [];
  private originalClaim: Claim;
  private activeClaim: Claim;
  // flag to represent the first time data has come back from the Claim service
  private initialLoad = true;
  private readonly _stateCodes: string[];
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  @ViewChild('patientSexMale', {read: ElementRef, static: true}) private patientSexMale: ElementRef;
  @ViewChild('patientSexFemale', {read: ElementRef, static: true}) private patientSexFemale: ElementRef;
  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;
  @ViewChild('patientStateCodeDropdown', {static: true}) private patientStateCodeDropdown: NgSelectComponent;
  /***** END - PRIVATE MEMBERS *****/


  /***** START - PUBLIC MEMBERS *****/
  id = ApplicationConstants.componentIDs.patient;
  title = 'Patient';
  patientForm: FormGroup;
  errorWrapperConfig = {
    patientDateOfBirth: undefined,
    patientDateOfBirthMinDate: undefined,
    patientGender: undefined,
    patientAddressStreet1: undefined,
    patientAddressStreet2: undefined,
    patientAddressCity: undefined,
    patientAddressZipCode: undefined,
    patientAddressStateCode: undefined,
    patientPhone: undefined,
  };
  datePickerConfiguration: DatePickerConfiguration;
  patientAddressMaxLength: number = ApplicationConstants.addressMaxLength;
  patientCityMaxLength: number = ApplicationConstants.cityMaxLength;

  @ViewChild('patientDateOfBirthInput', {read: ElementRef, static: true}) patientDateOfBirthInput: ElementRef;
  @ViewChild('patientAddressZipCodeControl', {read: ElementRef, static: true}) patientAddressZipCodeControl: ElementRef;
  @ViewChild('patientPhoneControl', {read: ElementRef, static: true}) patientPhoneControl: ElementRef;

  controlDefaults = ControlDefaults;

  patientRelationshipOtherValue: string = ApiFormattedMembershipRelationships.Other;
  // Assigning functions from utility class to variables so that these can be accessed  in template
  onKeypressEventRadiobutton = onKeypressEventRadiobutton;
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;
  showRequiredDateOfBirthAsterisk: boolean = true;
  get stateCodes(): string[] {
    return this._stateCodes;
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }

  /***** END - PUBLIC MEMBERS *****/

  /***** START - PRIVATE FUNCTIONS *****/
  private buildDatePickerConfiguration(): void {
    this.datePickerConfiguration = {
      control: this.patientForm.controls.patientDateOfBirth,
      controlName: 'patientDateOfBirth',
      errorWrapperId: {
        defaultValidations: 'patient-dob-error',
        minDate: 'patient-dob-min-error'
      },
      attributes: {
        id: 'patient-dob-input'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Date Of Birth must be in MM/DD/YYYY format'
        }
      ]
    };
  }

  private buildForm(): void {
    // pull data from the original claim
    let dateOfBirth, gender, relationToSubscriber, addresses, phones;
    if (this.originalClaim.patient) {
      ({dateOfBirth, gender, relationToSubscriber, addresses, phones} = this.originalClaim.patient);
    }
    let street1, street2, city, stateCode, zipCode = '';
    if (addresses[0]) {
      ({street1, street2, city, stateCode} = addresses[0]);
      if (addresses[0].zipCode) {
        ({zipCode = ''} = addresses[0].zipCode);
      }
    }
    if (zipCode === undefined) {
      zipCode = '';
    }
    // build the form
    this.patientForm = this.formBuilder.group({
      patientDateOfBirth: [
        DateUtility.isValidDate(dateOfBirth)
          ? DateUtility.buildFriendlyDateFromJsDate(dateOfBirth)
          : undefined,
        [this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(ApplicationConstants.minDate)]],
      patientGender: [gender, [Validators.required]],
      patientRelationToSubscriber: [relationToSubscriber],
      patientAddressStreet1: [street1, [Validators.required, Validators.pattern(ApplicationConstants.addressStreetAndCityValidRegex)]],
      patientAddressStreet2: [street2, [Validators.pattern(ApplicationConstants.addressStreetAndCityValidRegex)]],
      patientAddressCity: [city, [Validators.required, Validators.pattern(ApplicationConstants.addressStreetAndCityValidRegex)]],
      patientAddressStateCode: [stateCode, [Validators.required, Validators.pattern(ApplicationConstants.stateCodeRegex)]],
      patientAddressZipCode: [`${zipCode}`, [Validators.required, this.customValidatorsService.ZipCodeInvalid]],
      patientPhone: [phones[0], [this.customValidatorsService.PhoneInvalid(this.inputMaskService)]]
    });
    this.patientForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged(),
    ).subscribe((viewModel) => {
      this.updateDataModelFromViewModel();
    });

  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      patientDateOfBirth: {
        control: this.patientForm.controls.patientDateOfBirth,
        errors: [{
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Date Of Birth must be in MM/DD/YYYY format'
        }, {
          validatorType: ErrorTypes.InvalidDate,
          errorMessage: 'Not a valid calendar date'
        }, {
          validatorType: ErrorTypes.NoFutureDate,
          errorMessage: 'Date cannot be in the future'
        }]
      },
      patientDateOfBirthMinDate: {
        control: this.patientForm.controls.patientDateOfBirth,
        errors: [{
          validatorType: ErrorTypes.MinDate,
          errorMessage: `Date must be greater than ${DateUtility.buildFriendlyDateFromJsDate(ApplicationConstants.errorMessageDisplayMinDate)}`
        }]
      },
      patientGender: {
        control: this.patientForm.controls.patientGender,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the Patient\'s Sex'
        }]
      },
      patientAddressStreet1: {
        control: this.patientForm.controls.patientAddressStreet1,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the Patient\'s Address'
        },
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid Address'
          },
        ]
      },
      patientAddressStreet2: {
        control: this.patientForm.controls.patientAddressStreet2,
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid Address'
          }
        ]
      },
      patientAddressCity: {
        control: this.patientForm.controls.patientAddressCity,
        errors: [
          {
            validatorType: ErrorTypes.Required,
            errorMessage: 'Please enter the Patient\'s City'
          },
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid City'
          },
        ]
      },
      patientAddressStateCode: {
        selectInputField: this.patientStateCodeDropdown,
        control: this.patientForm.controls.patientAddressStateCode,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the Patient\'s State'
        }]
      },
      patientAddressZipCode: {
        control: this.patientForm.controls.patientAddressZipCode,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the Patient\'s Zip Code'
        },
          {
            validatorType: ErrorTypes.ZipCodeInvalid,
            errorMessage: 'Please enter a valid Zip Code'
          }]
      },
      patientPhone: {
        control: this.patientForm.controls.patientPhone,
        errors: [{
          validatorType: ErrorTypes.PhoneInvalid,
          errorMessage: 'Please enter a valid phone number'
        }]
      },
    };
  }

  private buildInputMasks(): void {
    this.inputMaskService.createInputMask(this.patientPhoneControl.nativeElement, this.inputMaskService.phoneAlias, {
      autoUnmask: false,
      clearIncomplete: false
    });
    this.inputMaskService.createInputMask(this.patientAddressZipCodeControl.nativeElement, this.inputMaskService.zipCodeAlias, {placeholder: ''});
  }

  private setViewModelFromDataModel(dataModel: Claim): void {
    // // pull data from the data model
    let dateOfBirth, gender, relationToSubscriber, addresses, phones;
    if (dataModel.patient) {
      ({dateOfBirth, gender, relationToSubscriber, addresses, phones} = dataModel.patient);
    }
    let street1, street2, city, stateCode, zipCode = '';
    if (addresses[0]) {
      ({street1, street2, city, stateCode} = addresses[0]);
      if (addresses[0].zipCode) {
        ({zipCode = ''} = addresses[0].zipCode);
      }
    }
    if (zipCode === undefined) {
      zipCode = '';
    }
    // pull data from the view model
    const {
      patientDateOfBirth, patientGender, patientRelationToSubscriber, patientAddressStreet1, patientAddressStreet2,
      patientAddressCity, patientAddressStateCode, patientAddressZipCode, patientPhone
    } = this.patientForm.controls;
    const currentGender = patientGender.value;
    const currentRelationToSubscriber = patientRelationToSubscriber.value;
    const currentZipCode = patientAddressZipCode.value;
    const currentAddress: Address = {
      street1: patientAddressStreet1.value,
      street2: patientAddressStreet2.value,
      city: patientAddressCity.value,
      stateCode: patientAddressStateCode.value,
      zipCode: {
        zipCode: currentZipCode
      }
    };
    const currentPhoneNumber = patientPhone.value;
    // set data in the view model only if different from the data model
    ClaimFormUtilityFunctions.setDateFieldIfDifferent(patientDateOfBirth, dateOfBirth);
    if (currentGender !== gender) {
      patientGender.setValue(gender, ApplicationConstants.updateFormWithoutEmit);
    }
    // set the value used by the 'other' button in the patientRelationToSubscriber control to the data model if not
    // one of the defaults
    if (relationToSubscriber && relationToSubscriber !== ControlDefaults.Member &&
      relationToSubscriber !== ControlDefaults.Spouse &&
      relationToSubscriber !== ControlDefaults.Child) {
      this.patientRelationshipOtherValue = relationToSubscriber;
    }
    if (currentRelationToSubscriber !== relationToSubscriber) {
      patientRelationToSubscriber.setValue(relationToSubscriber, ApplicationConstants.updateFormWithoutEmit);
    }
    if (JSON.stringify(currentAddress) !== JSON.stringify(addresses[0])) {
      patientAddressStreet1.setValue(street1, ApplicationConstants.updateFormWithoutEmit);
      patientAddressStreet2.setValue(street2, ApplicationConstants.updateFormWithoutEmit);
      patientAddressCity.setValue(city, ApplicationConstants.updateFormWithoutEmit);
      patientAddressStateCode.setValue(stateCode, ApplicationConstants.updateFormWithoutEmit);
      patientAddressZipCode.setValue(`${zipCode}`, ApplicationConstants.updateFormWithoutEmit);
    }
    if (currentPhoneNumber !== phones[0]) {
      patientPhone.setValue(phones[0], ApplicationConstants.updateFormWithoutEmit);
    }
  }

  private setDisabledFieldsFromDataModel(dataModel: Claim): void {
    // pull data from the data model
    let dateOfBirth, gender, relationToSubscriber, addresses, phones;
    if (dataModel.patient) {
      ({dateOfBirth, gender, relationToSubscriber, addresses, phones} = dataModel.patient);
    }
    // get handle to controls
    const {patientDateOfBirth, patientGender, patientRelationToSubscriber, patientPhone} = this.patientForm.controls;
    // if a claim exists on load, some existing data should not be editable
    if (DateUtility.isValidDate(dateOfBirth) || isNullOrUndefined(dateOfBirth)) {
      patientDateOfBirth.disable(ApplicationConstants.updateFormWithoutEmit);
      if (isNullOrUndefined(dateOfBirth)) {
        this.showRequiredDateOfBirthAsterisk = false;
      }
    }
    if (relationToSubscriber) {
      patientRelationToSubscriber.disable(ApplicationConstants.updateFormWithoutEmit);
    }
  }

  private enableFieldsOnUnmask(): void {
    // pull data from the data model
    let dateOfBirth, relationToSubscriber;
    if (this.activeClaim.patient) {
      ({dateOfBirth, relationToSubscriber} = this.activeClaim.patient);
    }
    // get handle to controls
    const {
      patientDateOfBirth, patientRelationToSubscriber, patientGender, patientAddressStreet1, patientAddressStreet2,
      patientAddressCity, patientAddressStateCode, patientAddressZipCode, patientPhone
    } = this.patientForm.controls;
    // enable all enableable form fields
    patientGender.enable(ApplicationConstants.updateFormWithoutEmit);
    patientAddressStreet1.enable(ApplicationConstants.updateFormWithoutEmit);
    patientAddressStreet2.enable(ApplicationConstants.updateFormWithoutEmit);
    patientAddressCity.enable(ApplicationConstants.updateFormWithoutEmit);
    patientAddressStateCode.enable(ApplicationConstants.updateFormWithoutEmit);
    patientAddressZipCode.enable(ApplicationConstants.updateFormWithoutEmit);
    patientPhone.enable(ApplicationConstants.updateFormWithoutEmit);
    // some existing data should be editable
    if (!DateUtility.isValidDate(dateOfBirth) && !isNullOrUndefined(dateOfBirth)) {
      patientDateOfBirth.enable(ApplicationConstants.updateFormWithoutEmit);
    }
    if (!relationToSubscriber) {
      patientRelationToSubscriber.enable(ApplicationConstants.updateFormWithoutEmit);
    }
  }

  updatePatientGenderOnActiveClaim(selectedPatientGender: string): void {
    const patientGender = this.patientForm.controls.patientGender;
    patientGender.setValue(selectedPatientGender, ApplicationConstants.updateFormWithoutEmit);
    this.activeClaim.patient.gender = selectedPatientGender;
    if (this.patientCardHasChanged()) {
      this.claimService.setActiveClaim(this.activeClaim, this.id);
    }
  }

  private updateDataModelFromViewModel(): void {
    const {
      patientDateOfBirth, patientGender, patientRelationToSubscriber, patientAddressStreet1, patientAddressStreet2,
      patientAddressCity, patientAddressStateCode, patientAddressZipCode, patientPhone
    } = this.patientForm.controls;
    // Gather and convert view model data
    const zipCode = patientAddressZipCode.value;
    const dateOfBirthValue = (patientDateOfBirth.valid)
      ? new Date(patientDateOfBirth.value)
      : this.activeClaim.patient.dateOfBirth;
    const patientPhoneFormValue = (patientPhone.value)
      ? patientPhone.value
      : undefined;
    const address: Address = {
      street1: patientAddressStreet1.value,
      street2: patientAddressStreet2.value,
      city: patientAddressCity.value,
      stateCode: patientAddressStateCode.value,
      zipCode: {
        zipCode: zipCode
      }
    };
    if (patientRelationToSubscriber.value === ControlDefaults.Member) {
      this.activeClaim.member.addresses[0] = address;
    }
    // Instantiate claim objects if they don't exist
    if (!this.activeClaim.patient) {
      this.activeClaim.patient = {} as Membership;
    }
    // Populate claim data from the converted view model data
    this.activeClaim.patient.addresses[0] = address;
    this.activeClaim.patient.dateOfBirth = dateOfBirthValue;
    this.activeClaim.patient.gender = patientGender.value;
    this.activeClaim.patient.relationToSubscriber = patientRelationToSubscriber.value;
    this.activeClaim.patient.phones[0] = patientPhoneFormValue;
    // Update the active claim in the claim service
    if (this.patientCardHasChanged()) {
      this.claimService.setActiveClaim(this.activeClaim, this.id);
    }
  }

  private patientCardHasChanged(): boolean {
    const {
      addresses: patientAddressesFromCard, dateOfBirth: dateOfBirthFromCard, gender: genderFromCard, phones: phonesFromCard,
      relationToSubscriber: relationToSubscriberFromCard
    } = this.activeClaim.patient;
    const dateOfBirthFromCardString = DateUtility.buildFriendlyDateFromJsDate(dateOfBirthFromCard);
    const {addresses: memberAddressesFromCard} = this.activeClaim.member;


    const activeClaimFromService = this.claimService.getActiveClaim();
    // Instantiate claim objects if they don't exist
    if (isNullOrUndefined(activeClaimFromService.member)) {
      activeClaimFromService.member = {} as Membership;
    }
    if (isNullOrUndefined(activeClaimFromService.member.addresses)) {
      activeClaimFromService.member.addresses = [{}] as Address[];
    }
    if (isNullOrUndefined(activeClaimFromService.member.addresses[0].zipCode)) {
      activeClaimFromService.member.addresses[0].zipCode = {};
    }
    if (isNullOrUndefined(activeClaimFromService.patient)) {
      activeClaimFromService.patient = {} as Membership;
    }
    if (isNullOrUndefined(activeClaimFromService.patient.addresses)) {
      activeClaimFromService.patient.addresses = [{}] as Address[];
    }
    if (isNullOrUndefined(activeClaimFromService.patient.addresses[0].zipCode)) {
      activeClaimFromService.patient.addresses[0].zipCode = {};
    }
    const {
      addresses: patientAddressesFromService, dateOfBirth: dateOfBirthFromService, gender: genderFromService, phones: phonesFromService,
      relationToSubscriber: relationToSubscriberFromService
    } = activeClaimFromService.patient;
    const dateOfBirthFromServiceString = DateUtility.buildFriendlyDateFromJsDate(dateOfBirthFromService);
    const {addresses: memberAddressesFromService} = activeClaimFromService.member;

    return (patientAddressesFromCard[0].city || undefined) !== (patientAddressesFromService[0].city || undefined) ||
      (patientAddressesFromCard[0].stateCode || undefined) !== (patientAddressesFromService[0].stateCode || undefined) ||
      (patientAddressesFromCard[0].street1 || undefined) !== (patientAddressesFromService[0].street1 || undefined) ||
      (patientAddressesFromCard[0].street2 || undefined) !== (patientAddressesFromService[0].street2 || undefined) ||
      (patientAddressesFromCard[0].zipCode.zipCode || undefined) !== (patientAddressesFromService[0].zipCode.zipCode || undefined) ||
      (patientAddressesFromCard[0].zipCode.zipExtension || undefined) !== (patientAddressesFromService[0].zipCode.zipExtension || undefined) ||
      (dateOfBirthFromCardString || undefined) !== (dateOfBirthFromServiceString || undefined) ||
      (genderFromCard || undefined) !== (genderFromService || undefined) ||
      (phonesFromCard[0] || undefined) !== (phonesFromService[0] || undefined) ||
      (relationToSubscriberFromCard || undefined) !== (relationToSubscriberFromService || undefined) ||
      (memberAddressesFromCard[0].city || undefined) !== (memberAddressesFromService[0].city || undefined) ||
      (memberAddressesFromCard[0].stateCode || undefined) !== (memberAddressesFromService[0].stateCode || undefined) ||
      (memberAddressesFromCard[0].street1 || undefined) !== (memberAddressesFromService[0].street1 || undefined) ||
      (memberAddressesFromCard[0].street2 || undefined) !== (memberAddressesFromService[0].street2 || undefined) ||
      (memberAddressesFromCard[0].zipCode.zipCode || undefined) !== (memberAddressesFromService[0].zipCode.zipCode || undefined) ||
      (memberAddressesFromCard[0].zipCode.zipExtension || undefined) !== (memberAddressesFromService[0].zipCode.zipExtension || undefined);
  }

  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.originalClaim = this.claimService.getOriginalClaim();
    this.registerWithClaimProgressService();
    this.buildForm();
    this.buildErrorWrapperConfig();
    this.buildDatePickerConfiguration();
    // Register for updates to the active claim
    // NOTE: uiFormattedClaim will be undefined if any errors occurred upstream
    this.observableSubscriptions.push(this.claimService.onCardsToUpdate.subscribe((onCardsToUpdate: ClaimCardsToUpdate) => {
      this.activeClaim = this.claimService.getActiveClaim();
      // Set form data if the data exists
      if (onCardsToUpdate.all) {
        this.setViewModelFromDataModel(this.activeClaim);
      }
      // TODO: assuming that an empty claim is undefined from the service side.  This will likely need to be updated
      // to a different checking mechanism once claims are created for the first time
      if (this.initialLoad && this.activeClaim) {
        this.initialLoad = false;
        // On initial load, if the claim exists, disable any prefilled fields
        this.setDisabledFieldsFromDataModel(this.activeClaim);
      }
    }));
    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      if (mask) {
        this.disableFormGroupComponents(this.patientForm);
        this.componentMask.show();
      } else {
        this.enableFieldsOnUnmask();
        this.componentMask.hide();
      }
    }));
    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
          (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }

    }));
  }

  ngAfterViewInit(): void {
    this.buildInputMasks();
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /***** END - EVENT HANDLERS *****/
}
