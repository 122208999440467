import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {GiftCertificatesService} from '../../common/services/data-model/app/gift-certificates/gift-certificates.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApplicationConstants, SessionStorageKeys} from '../../common/constants/application.constants';
import {MatDialog} from '@angular/material/dialog';
import {ClaimFormNavigationService} from '../../common/services/support/claim-form-navigation/claim-form-navigation.service';
import {
  isNullOrUndefined,
  isStringNullUndefinedOrEmpty,
  openDialog,
  returnTrueFalseOrUndefinedFromStringValue
} from '../../common/utility';
import {DrReportsNavigationService} from '../../common/services/support/dr-reports-navigation/dr-reports-navigation.service';
import {CookieService} from 'ngx-cookie-service';
import {MessageService} from '../../common/services/support/message/message.service';

@Component({
  selector: 'app-einsurance',
  templateUrl: './eInsurance.component.html',
  styleUrls: ['./eInsurance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EInsuranceComponent implements OnInit, OnDestroy {

  @ViewChild('authControl', {read: ElementRef, static: true}) authorizationControl: ElementRef;
  @ViewChild('memberSearch', {read: ElementRef, static: true}) private memberSearch: ElementRef;
  @ViewChild('giftCertificate', {read: ElementRef, static: true}) private giftCertificate: ElementRef;
  @ViewChild('accessClaimForm', {read: ElementRef, static: true}) private accessClaimForm: ElementRef;
  @ViewChild('viewReports', {read: ElementRef, static: true}) private viewReports: ElementRef;

  /***** START - PUBLIC MEMBERS *****/
  authLoadRequestCompleted: boolean = true;
  cannotContinueForTheFollowingReason: string = ApplicationConstants.routingPageContent.cannotContinueForTheFollowingReason;
  pleaseReturnToEInsurancePage: string = ApplicationConstants.routingPageContent.pleaseReturnToEInsurancePage;
  buttonContent: string = ApplicationConstants.routingPageContent.buttonContent;
  /***** END - PUBLIC MEMBERS *****/

  /***** START - PRIVATE MEMBERS *****/
  private _loadingGcOrClaimFormHadErrors: boolean = false;
  private _gcOrClaimFormErrorMessage: string;
  /***** END - PRIVATE MEMBERS *****/

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private giftCertificateService: GiftCertificatesService,
    private claimFormNavigationService: ClaimFormNavigationService,
    private drReportsNavigationService: DrReportsNavigationService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private messageService: MessageService
  ) {
  }

  /***** START - PROPERTY ACCESSORS *****/

  get loadingGcOrClaimFormHadErrors(): boolean {
    return this._loadingGcOrClaimFormHadErrors;
  }

  set loadingGcOrClaimFormHadErrors(value: boolean) {
    this._loadingGcOrClaimFormHadErrors = value;
  }

  get gcOrClaimFormErrorMessage(): string {
    return this._gcOrClaimFormErrorMessage;
  }

  set gcOrClaimFormErrorMessage(value: string) {
    this._gcOrClaimFormErrorMessage = value;
  }
  /***** END - PROPERTY ACCESSORS *****/

  ngOnInit() {
    const isRequestIsComingInFromPingFedLogInPage: string = sessionStorage.getItem(SessionStorageKeys.RequestIsComingInFromPingFedLogInPage);
    if (isRequestIsComingInFromPingFedLogInPage === ApplicationConstants.trueString) {
      openDialog('Getting your account ready...', this.dialog);
      sessionStorage.setItem(SessionStorageKeys.RequestIsComingInFromPingFedLogInPage, undefined);
      const windowUrlBeforeNavigatingToPingFedLogInPage: string = sessionStorage.getItem(SessionStorageKeys.WindowUrlBeforeNavigatingToPingFedLogInPage);
      if (!isStringNullUndefinedOrEmpty(windowUrlBeforeNavigatingToPingFedLogInPage)) {
        this.loadingGcOrClaimFormHadErrors = false;
        // Route the user to the original url they were going to before being sent to the PingFed log in page.
        // Note: I had to use window.location.href to navigate to the url since the angular router was not working as designed.
        window.location.href = windowUrlBeforeNavigatingToPingFedLogInPage;
      } else {
        // Set this flag to true just in case the url we are navigating to is null or empty. This is a just in case. Should not happen.
        this.loadingGcOrClaimFormHadErrors = true;
      }
    } else {
      // Close any spinners if they are still open. Its a just in case.
      // this.dialog.closeAll();
      if (this.giftCertificateService.navigationCameFromGcFlow) {
        // This flag was used to handle an edge case in the GC flow. If it goes down this path it will not reset the
        // error message and use what it actually is. We reset the flag to make sure on a next error message we handle it correctly.
        this.giftCertificateService.navigationCameFromGcFlow = false;
      } else {
        if (!isNullOrUndefined(this.messageService.isSubmitAndGatewayTimeOutHappenedInClaimForm) && this.messageService.isSubmitAndGatewayTimeOutHappenedInClaimForm) {
          this.messageService.isSubmitAndGatewayTimeOutHappenedInClaimForm = false;
        } else {
          this.messageService.currentErrorMessageString = undefined;
        }
      }
      // This is to pre set the error message so that we have a generic message if preset to render if a refresh or something unexpected goes wrong.
      this.setErrorMessagesIfAnyArePresent();
      this.loadingGcOrClaimFormHadErrors = false;
      let authorizationNumber: string;
      let giftCertificateNumber: string;
      let viewingDoctorReports: string;
      if (!isNullOrUndefined(this.route) && !isNullOrUndefined(this.route.snapshot) && !isNullOrUndefined(this.route.snapshot.queryParamMap)) {
        authorizationNumber = this.route.snapshot.queryParamMap.get(ApplicationConstants.authorizationNumber);
        giftCertificateNumber = this.route.snapshot.queryParamMap.get(ApplicationConstants.giftCertificateNumber);
        viewingDoctorReports = this.route.snapshot.queryParamMap.get(ApplicationConstants.viewingDoctorReports);
      } else {
        authorizationNumber = undefined;
        giftCertificateNumber = undefined;
        viewingDoctorReports = undefined;
      }

      // If both the authorizationNumber and giftCertificateNumber query params are passed in the URL then have the page render generic error.
      // Also if both the authorizationNumber and giftCertificateNumber query params are NOT passed in the URL then have the page render generic error as well.
      const areBothAuthAndGcParamsPresent: boolean = (!isNullOrUndefined(authorizationNumber) && !isNullOrUndefined(giftCertificateNumber));
      const areBothAuthAndGcParamsMissing: boolean = (isNullOrUndefined(authorizationNumber) && isNullOrUndefined(giftCertificateNumber));
      if (areBothAuthAndGcParamsPresent || areBothAuthAndGcParamsMissing) {
        // This check was done in order to handle an edge case where we would temporarily display an error message when we try to navigate to the claim form on a submitted claim.
        if (this.claimFormNavigationService.isRouteComingFromTheSubmittedClaimModal || this.claimFormNavigationService.isNavigationComingFromTheSubmitClaimModal) {
          this.loadingGcOrClaimFormHadErrors = false;
          this.claimFormNavigationService.isRouteComingFromTheSubmittedClaimModal = false;
          this.claimFormNavigationService.isNavigationComingFromTheSubmitClaimModal = false;
        } else {
          this.loadingGcOrClaimFormHadErrors = true;
        }
      } else if (!isNullOrUndefined(authorizationNumber)) {
        let displaySubmittedClaimModal: boolean = true;
        if (!isStringNullUndefinedOrEmpty(viewingDoctorReports)) {
          const viewingDoctorReportsValue: boolean = returnTrueFalseOrUndefinedFromStringValue(viewingDoctorReports);
          if (!isNullOrUndefined(viewingDoctorReportsValue) && viewingDoctorReportsValue) {
            displaySubmittedClaimModal = false;
          }
        }
        this.navigateToClaimForm(authorizationNumber, false, displaySubmittedClaimModal);
      } else if (!isNullOrUndefined(giftCertificateNumber)) {
        this.giftCertificateSearch(giftCertificateNumber, false);
      } else {
        this.loadingGcOrClaimFormHadErrors = true;
      }
    }
  }

  setErrorMessagesIfAnyArePresent() {
    if (!isNullOrUndefined(this.messageService.currentErrorMessageString)) {
      this.gcOrClaimFormErrorMessage = this.messageService.currentErrorMessageString;
      this.loadingGcOrClaimFormHadErrors = true;
    } else {
      // Set generic API error message just in case we fail to get the correct error message from the API.
      this.gcOrClaimFormErrorMessage = ApplicationConstants.errorMessages.genericApiFailMessage;
    }
  }

  giftCertificateSearch(giftCertificateNumber: string, showSnackbar: boolean): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Loading Gift Certificate...', this.dialog);
    this.giftCertificateService.loadGiftCertificate(giftCertificateNumber, showSnackbar).subscribe(giftCertificate => {
        this.setErrorMessagesIfAnyArePresent();
        if (this.giftCertificateService.giftCertificateIsValid(giftCertificate)) {
          this.router.navigate([ApplicationConstants.routing.secure.giftCertificateSubmissionPageUrl], {
            queryParams: {
              giftCertificateNumber: giftCertificateNumber
            }
          });
        }
        this.dialog.closeAll();
      }, () => {
        // on observer error
      },
      () => {
        // Close dialog for LoadingModalComponent - Spinner
        this.dialog.closeAll();
      });
  }

  navigateToLegacyEInsurancePage() {
    window.location.href = this.cookieService.get(ApplicationConstants.efSurlCookie) + ApplicationConstants.legacyEInsurancePageURL;
  }

  /**
   * This method is doing following
   * 1. Search a claim
   * 2. If found, then retrieve it and go to Claim Form page
   * 3. If not found, then create and got to Claim Form
   * 4. After claim is returned, call external service location API to load doctor list
   * 5. If any of the API call fails then render error on this page
   */
  navigateToClaimForm(authorizationNumber: string, showSnackbar: boolean, displaySubmittedClaimModal: boolean): void {
    this.authLoadRequestCompleted = false;
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Loading Authorization...', this.dialog);
    this.claimFormNavigationService.searchAndRetrieveOrCreatePatientEncounterThenLoadDoctorsThenNavigateToClaimForm(
      authorizationNumber, this.authorizationControl, undefined, showSnackbar, displaySubmittedClaimModal).subscribe(
      () => {
        // since the method returns an Observable<void>, this will never be called, but is needed to get to the error and onComplete
      },
      () => {
        // on observer error
        this.authLoadRequestCompleted = true;
      },
      () => {
        // on observer complete
        this.authLoadRequestCompleted = true;
        // Close the dialog when the observer completes
        this.dialog.closeAll();
        this.setErrorMessagesIfAnyArePresent();
      }
    );
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }
}
