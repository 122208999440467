<div fxLayout="row" class="error-panel" id="error-message-container" *ngIf="showHardEdits">
  <div class="nested-column-10">
    <mat-icon svgIcon="eclaim-icons:alertCircle"></mat-icon>
    Error message {{currentHardEditPage}} of {{hardEditLength}}:
    <span [ngClass]="{'action-link': isMappable(hardEditMessages[currentHardEditPage-1])}" (click)="focusOnFieldWithError(hardEditMessages[currentHardEditPage-1])">
          {{hardEditMessages[currentHardEditPage-1].errorNumber}} </span>{{hardEditMessages[currentHardEditPage-1].description}}
  </div>
  <div *ngIf="displayHardEditMessageArrows" class="nested-column-2" style="text-align: right;">
    <mat-icon [ngClass]="{'mat-icon-pointer': showErrorLeftArrow, 'not-allowed': !showErrorLeftArrow, 'margin-right-28': true}" (click)="moveLeft(editTypeHard)" svgIcon="eclaim-icons:leftArrowError" id="hard-edit-left-arrow-{{currentHardEditPage}}"></mat-icon>
    <mat-icon [ngClass]="{'mat-icon-pointer': showErrorRightArrow, 'not-allowed': !showErrorRightArrow}" (click)="moveRight(editTypeHard)" svgIcon="eclaim-icons:rightArrowError" id="hard-edit-right-arrow-{{currentHardEditPage}}"></mat-icon>
    <!--Keeping this code commented out just in case we need to enable this X -->
    <!--<mat-icon class="mat-icon-pointer margin-right-28" (click)="dismissErrorPanel()" svgIcon="eclaim-icons:closePopup"></mat-icon>-->
  </div>
</div>

<div fxLayout="row" class="warning-panel" id="warning-message-container" *ngIf="showSoftEdits">
  <div class="nested-column-7" style="padding-top: 18px;">
    <mat-icon svgIcon="eclaim-icons:warningTriangle"></mat-icon>
    Alert message {{currentSoftEditPage}} of {{softEditLength}}:
    {{softEditMessages[currentSoftEditPage-1].errorNumber}} {{softEditMessages[currentSoftEditPage-1].description}}
  </div>

  <div class="nested-column-5" style="padding-top: 8px; text-align: right;">
    <button mat-stroked-button color="primary" class="acknowledge-button" id="soft-edit-ackn-{{currentSoftEditPage}}" (click)="onSoftEditAcknowledge(softEditMessages[currentSoftEditPage-1])">
      <span>Acknowledge</span>
    </button>

    <button mat-flat-button class="mat-flat-button mat-primary" style="margin-right: 10px;" id="soft-edit-resolve-{{currentSoftEditPage}}" (click)="focusOnFieldWithError(softEditMessages[currentSoftEditPage-1])"
            *ngIf="isMappable(softEditMessages[currentSoftEditPage-1])">
      <span>Resolve</span>
    </button>
    <mat-icon *ngIf="displaySoftEditMessageArrows" [ngClass]="{'mat-icon-pointer': showWarnLeftArrow, 'not-allowed': !showWarnLeftArrow, 'margin-right-28': true}" (click)="moveLeft(editTypeSoft)" svgIcon="eclaim-icons:leftArrowWarning" id="soft-edit-left-arrow-{{currentSoftEditPage}}"></mat-icon>
    <mat-icon *ngIf="displaySoftEditMessageArrows" [ngClass]="{'mat-icon-pointer': showWarnRightArrow, 'not-allowed': !showWarnRightArrow}" (click)="moveRight(editTypeSoft)" svgIcon="eclaim-icons:rightArrowWarning" id="soft-edit-right-arrow-{{currentSoftEditPage}}"></mat-icon>
  </div>
</div>

<div fxLayout="row" class="warning-panel" id="warning-message-container-for-removal-of-lens" *ngIf="showLensExpiredEdit">
  <div class="nested-column-7" style="padding-top: 18px;">
    <mat-icon svgIcon="eclaim-icons:warningTriangle"></mat-icon>
    Inactive lenses have been removed. Please enter a valid lens selection.
  </div>
  <div class="nested-column-5" style="padding-top: 8px; text-align: right;">
    <button mat-stroked-button color="primary" class="acknowledge-button" id="lens-removal-edit-acknowledge-button" (click)="onShowLensExpiredEditAcknowledge()">
      <span>Acknowledge</span>
    </button>
  </div>
</div>
