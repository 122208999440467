import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ServicesConstants } from '../services.constants';

@Component({
  selector: 'app-hcpc-cpt-codes-modal',
  templateUrl: './hcpc-cpt-codes-modal.component.html',
  styleUrls: ['./hcpc-cpt-codes-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HcpcCptCodesModalComponent {
  public filteredHcpcCodeGroups = Object.deepClone(ServicesConstants.HcpcCodeGroups);
  public selectedHcpcCode: string;
  @Output() closeModal = new EventEmitter<string>();

  constructor(
    public dialogRef: MatDialogRef<HcpcCptCodesModalComponent>
  ) { }

  public hasResultsAfterFilter(): boolean {
    let hasResultsAfterFilter = false;
    Object.keys(this.filteredHcpcCodeGroups).forEach((key) => {
      if (!this.filteredHcpcCodeGroups[key].every(element => element.length === 0)) {
        hasResultsAfterFilter = true;
      }
    });
    return hasResultsAfterFilter;
  }

  public filteredGroupHasCodes(hcpcCodeGroupName: string): boolean {
    return !this.filteredHcpcCodeGroups[hcpcCodeGroupName].every(element => element.length === 0);
  }

  public getGroupNameFormatted(groupName: string): string {
    return groupName.replace(/ /g, '');
  }

  public printModal(): void {
    window.print();
  }

  public filterHcpcCodes(filterBy: string): void {
    if (filterBy) {
      Object.keys(ServicesConstants.HcpcCodeGroups).forEach((group) => {
        for (let codeGroupIndex = 0; codeGroupIndex < ServicesConstants.HcpcCodeGroups[group].length; codeGroupIndex++) {
          this.filteredHcpcCodeGroups[group][codeGroupIndex] = ServicesConstants.HcpcCodeGroups[group][codeGroupIndex].filter(hcpcCodeGroup =>
            hcpcCodeGroup.code.toLowerCase().includes(filterBy.toLowerCase()) || hcpcCodeGroup.value.toLowerCase().includes(filterBy.toLowerCase()));
        }
      });
    }
  }

  public doubleClickHcpcCode(): void {
    this.confirmSelectedHcpcCode();
  }

  public selectHcpcCode(hcpcCode: string): void {
    this.selectedHcpcCode = hcpcCode;
  }

  public isHcpcCodeSelected(hcpcCode: string): boolean {
    return this.selectedHcpcCode === hcpcCode;
  }

  public confirmSelectedHcpcCode(): void {
    this.dialogRef.close(this.selectedHcpcCode);
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

}
