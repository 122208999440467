<app-component-mask xmlns="http://www.w3.org/1999/html"></app-component-mask>

<!-- Show/Hide CMS Numbers -->
<div cms-number-toggle id="exam-cmsNumberToggle"></div>

<div id="{{id}}">
  <mat-card class="container-white-full-column">

    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings" class="sticky-card-header" [ngClass]="{ 'disabled-card-header': labForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="lab-title">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits" [ngClass]="{ 'disabled-card-header': labForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="lab-title-edits">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning" [ngClass]="{ 'disabled-card-header': labForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="lab-title-warning">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Main Form -->
    <mat-card-content>
      <form [formGroup]="labForm" fxLayout="column" novalidate>

        <div *ngIf="!viewFreeShippingList" fxLayout="row" class="margin-top-30">
          <label id="lab-select-lab-label" class="nested-column-4" for="lab-select-lab-dropdown">Select Lab</label>
          <label id="lab-lab-label" class="nested-column-2"><span class="required-label-asterisk">* </span>Lab</label>
        </div>

        <div *ngIf="viewFreeShippingList" fxLayout="row" class="margin-top-30">
          <label id="lab-select-free-shipping-lab-label" class="nested-column-4" for="lab-select-free-shipping-lab-dropdown">Select Lab</label>
          <label id="lab-lab-free-shipping-label" class="nested-column-2"><span class="required-label-asterisk">* </span>Lab</label>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-30">
          <div *ngIf="!viewFreeShippingList" class="nested-column-4">
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="selectLab"
                    id="lab-select-lab-dropdown"
                    class="custom-select"
                    (change)="onSelectLabClick()"
                    placeholder="" style="...">
              <option selected [ngValue]="undefined"></option>
                <option *ngFor="let labOption of labOptions" [value]="labOption.labId" >
                  <div>
                    <div>
                      {{labOption.labName}}
                    </div>
                    <div>
                      {{labOption.labPhysicalAddressStateCode}}
                    </div>
                    <div> - </div>
                    <div>
                      {{labOption.labPhysicalAddressCity}}
                    </div>
                  </div>
                </option>
            </select>
            <app-loading-dropdown *ngIf="loadingSelectLab" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="165"></app-loading-dropdown>
          </div>
          <div *ngIf="viewFreeShippingList" class="nested-column-4">
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="selectLab"
                    id="lab-select-free-shipping-lab-dropdown"
                    class="custom-select"
                    (change)="onSelectLabClick()"
                    placeholder="" style="...">
              <option selected [ngValue]="undefined"></option>
              <optgroup *ngFor="let labCategory of labCategories" label="{{labCategory.labCategoryLabel}}">
                <option *ngFor="let labOption of labCategory.lab" [value]="labOption.labId" >
                  <div>
                    <div>
                      {{labOption.labName}}
                    </div>
                    <div>
                      {{labOption.labPhysicalAddressStateCode}}
                    </div>
                    <div> - </div>
                    <div>
                      {{labOption.labPhysicalAddressCity}}
                    </div>
                  </div>
                </option>
                <option disabled value></option>
              </optgroup>
            </select>
            <app-loading-dropdown *ngIf="loadingSelectLab" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="165"></app-loading-dropdown>
          </div>
          <div fxLayout="column" class="nested-column-2">
            <mat-form-field appearance="outline" class="required-mat-form-field">
              <input matInput
                     formControlName="lab"
                     id="lab-lab-textbox"
                     #labNumberControl
                     (blur)="labFieldBlur()"
                     autocomplete="off"/>
            </mat-form-field>
            <app-error-wrapper id="lab-lab-error-msg"[config]="errorWrapperConfig.lab"></app-error-wrapper>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
