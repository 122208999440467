import { Injectable, Injector } from '@angular/core';
import { HttpClientService } from '../http-client-service';
import { ApplicationConstants } from '../../../../../constants/application.constants';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiFormattedClaim } from '../../../../../../models/claim';
import {isStringNullUndefinedOrEmpty} from '../../../../../utility';

@Injectable({
  providedIn: 'root'
})
export class SupplierWebDataService extends HttpClientService {

  constructor(protected injector: Injector) {
    super(injector, ApplicationConstants.dataServiceClientIDs.supplierWebDataService);
  }

  /***** START - PRIVATE FUNCTIONS *****/
  private getSupplierWebEndpointFromTrackingNumber(trackingNumber: string): string {
    const patientEncounterApi = ApplicationConstants.api.patientEncounterApi;
    const domain = (!isStringNullUndefinedOrEmpty(this.environment.supplierWebUrl)) ? this.environment.supplierWebUrl : this.environment.apiUrl;
    return `${domain}/${patientEncounterApi}/patientencounter/${trackingNumber}/labs`;
  }
  /***** START - END FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  retrieveLabList(apiFormattedPatientEncounter: ApiFormattedClaim): Observable<ApiFormattedClaim> {
    const endpoint: string = this.getSupplierWebEndpointFromTrackingNumber(apiFormattedPatientEncounter.trackingNumber);

    return this.http.post<ApiFormattedClaim>(endpoint, apiFormattedPatientEncounter, { headers: this.getHttpHeaders(ApplicationConstants.api.patientEncounterApi)})
      .pipe(
        map((patientEncounter: ApiFormattedClaim) => {
          return patientEncounter;
        }),
        catchError((error: any) => {
          const errorMessage: string = ApplicationConstants.errorMessages.failSafeLabList;
          this.handleError('retrieveLabList', error);
          this.messageService.showErrorSnackbar(`${errorMessage}`);
          return of(undefined);
        })
      );
  }
  /***** END - PUBLIC FUNCTIONS *****/
}
