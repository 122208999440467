<div fxLayout="column">

  <mat-card>
    <!-- Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 class="nested-column-11"
            id="submitted-claim-header" mat-dialog-title>Retrieve Submitted Claim</h1>
      </mat-card-title>
      <!-- Close Icon -->
      <button [disableRipple]="true" class="close-button" mat-icon-button>
        <mat-icon (click)="onCloseClick()" id="submitted-claim-close-button"
                  svgIcon="eclaim-icons:close"></mat-icon>
      </button>
    </mat-card-header>


    <!-- Body -->
    <mat-card-content id="submitted-claim-modal-message">
      <label>An order has been submitted for this authorization <strong>{{vsrNumber}}</strong>. Please select OK to view
        the submitted
        order. </label>
    </mat-card-content>

    <!-- Footer Line -->
    <hr/>

    <!-- OK/Cancel buttons  -->
    <mat-card-actions>

      <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="end center" class="margin-top-20 margin-bottom-20">

        <!-- Cancel Button  -->
        <button mat-dialog-close class="cancel-button" (click)="cancel()"
                id="submitted-claim-modal-cancel-button">Cancel
        </button>
        <div fxFlexOffset="30px"></div>

        <!-- OK Button-->
        <button mat-dialog-close class="btn btn-primary btn-extra-pad" (click)="confirm()"
                id="submitted-claim-modal-ok-button">OK
        </button>
        <div fxFlexOffset="20px"></div>

      </div>
    </mat-card-actions>

  </mat-card>

</div>
