<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start center" class="header-menu">
    <img id="eyefinity-logo" src="assets/images/newEyefinityLogo.svg" fxFlex="17">

    <div class="header-grey-divider" fxFlexOffset="30px"></div>

    <button mat-button (click)="homeLinkClick()" id="header-home-link">HOME</button>

    <div class="header-grey-divider"></div>

    <button mat-button [matMenuTriggerFor]="eInsuranceMenu" id="header-legacy-einsurance-link">eINSURANCE</button>
    <mat-menu #eInsuranceMenu="matMenu" yPosition="below" xPosition="after" overlapTrigger="false">
      <button mat-menu-item (click)="eClaimLinkClick()" id="sub-header-eclaim-link">eCLAIM</button>
      <button mat-menu-item (click)="eClaimTrackingLinkClick()" id="sub-header-eclaim-tracking-link">eCLAIM TRACKING</button>
      <button mat-menu-item (click)="vspOnlineLinkClick()" id="sub-header-vsp-online-link">VSP ONLINE</button>
      <button mat-menu-item (click)="claimResourceCenterLinkClick()" id="sub-header-claim-resource-center-link">CLAIM RESOURCE CENTER</button>
      <button mat-menu-item (click)="authTrackingLinkClick()" id="sub-header-auth-tracking-link">AUTHORIZATION TRACKING</button>
    </mat-menu>

    <div class="header-grey-divider"></div>
    <button mat-button (click)="eLabLinkClick()" id="header-eLab">eLAB</button>

    <div class="header-grey-divider"></div>
    <button mat-button (click)="eBuyLinkClick()" id="header-eBuy">eBUY</button>

    <div class="header-grey-divider"></div>
    <button mat-button (click)="eLearnLinkClick()" id="header-eLearn">eLEARN</button>

    <div class="header-grey-divider"></div>
    <button mat-button (click)="eWebLinkClick()" id="header-eWeb">eWEB</button>

    <div class="header-grey-divider"></div>
    <button mat-button (click)="eHRSolutionsLinkClick()" id="header-eHRSolutions">eHR SOLUTIONS</button>

  </div>
  <mat-divider fxLayout="row" fxFlexOffset="20px" id="header-divider"></mat-divider>

</div>
