export class ConsumerSearchRequest {
  private policyid: string;
  private expandedId: string;
  private dob: string;
  private firstName: string;
  private lastName: string;
  private lastfour: string;
  private size: number;
  private membershipInd: string;
  private searchOnlyActiveMembers: boolean;
  private asOfDate: string;
  private activeDependentFilter: boolean;
  private searchFirstInitialFirstName: boolean;
  private searchEmptyDob: boolean;

  set _policyId(policyId: string) {
    this.policyid = policyId;
  }

  get _policyId(): string {
    return this.policyid;
  }

  set _expandedId(expandedId: string) {
    this.expandedId = expandedId;
  }

  get _expandedId(): string {
    return this.expandedId;
  }

  set _dob(dob: string) {
    this.dob = dob;
  }

  get _dob(): string {
    return this.dob;
  }

  set _firstName(firstName: string) {
    this.firstName = firstName;
  }

  get _firstName(): string {
    return this.firstName;
  }

  set _lastName(lastName: string) {
    this.lastName = lastName;
  }

  get _lastName(): string {
    return this.lastName;
  }

  set _lastFour(lastFour: string) {
    this.lastfour = lastFour;
  }

  get _lastFour(): string {
    return this.lastfour;
  }

  set _size(size: number) {
    this.size = size;
  }

  get _size(): number {
    return this.size;
  }

  set _membershipInd(membershipInd: string) {
    this.membershipInd = membershipInd;
  }

  get _membershipInd(): string {
    return this.membershipInd;
  }

  set _searchOnlyActiveMembers(searchOnlyActiveMembers: boolean) {
    this.searchOnlyActiveMembers = searchOnlyActiveMembers;
  }

  get _searchOnlyActiveMembers(): boolean {
    return this.searchOnlyActiveMembers;
  }

  get _asOfDate(): string {
    return this.asOfDate;
  }

  set _asOfDate(asOfDate: string) {
    this.asOfDate = asOfDate;
  }
  get _activeDependentFilter(): boolean {
    return this.activeDependentFilter;
  }

  set _activeDependentFilter(value: boolean) {
    this.activeDependentFilter = value;
  }

  get _searchFirstInitialFirstName(): boolean {
    return this.searchFirstInitialFirstName;
  }

  set _searchFirstInitialFirstName(value: boolean) {
    this.searchFirstInitialFirstName = value;
  }

  get _searchEmptyDob(): boolean {
    return this.searchEmptyDob;
  }

  set _searchEmptyDob(value: boolean) {
    this.searchEmptyDob = value;
  }
}
