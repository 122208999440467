import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserSessionService } from '../../../support/user-session/user-session.service';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {


  constructor(
    private userSessionService: UserSessionService,
  ) { }


  /***** START - PUBLIC FUNCTIONS *****/
  // Implemented from the HttpInterceptor interface
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userInfoHeaders = {
      'Authorization': this.userSessionService.accessToken,
      'Accept': 'application/json'
    };
    const defaultHeaders = {
      'Content-Type': 'application/json',
      'Authorization': this.userSessionService.accessToken,
      'Accept': 'application/json'
    };
    const headers = request.url.includes('userinfo') ? userInfoHeaders : defaultHeaders;
  /*  if (!request.url.includes('contact-lens-api/contactlens/materials')) {
      headers['Authorization'] = this.userSessionService.accessToken;
    }*/
    if (request.url.includes('environment.json')) {
      headers['Cache-Control'] = 'no-cache';
      headers['Pragma'] = 'no-cache';
      headers['Expires'] = 'Sat, 01 Jan 2000 00:00:00 GMT';
      headers['If-Modified-Since'] = '0';
    }
    headers['x-eyefinity-correlation-id'] = this.userSessionService.guid;
    // append all headers originally passed with the http request
    for ( const headerKey of request.headers.keys()) {
      headers[headerKey] = request.headers.get(headerKey);
    }
    const httpHeaders = new HttpHeaders(headers);
    const authorizedReq = request.clone({
      headers: httpHeaders
    });

    return next.handle(authorizedReq);
  }
  /***** END - PUBLIC FUNCTIONS *****/
}
