import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateUtility, isNullOrUndefined, isStringNullUndefinedOrEmpty, openDialog, getFormattedFullName} from '../../../common/utility';
import {MatDialog} from '@angular/material/dialog';
import {ClaimFormNavigationService} from '../../../common/services/support/claim-form-navigation/claim-form-navigation.service';
import {CreateVisionServiceRequest, VisionServiceRequest} from '../../../models/VisionServiceRequestResults';
import {AuthorizationsService} from '../../../common/services/data-model/app/authorizations/authorizations.service';
import {Eligibility, EligibilityCoverage, EligibilityProductPackage, EligibleItem, EligibleItemsVsr, EligibleItemVsrDto} from '../../../models/eligibility';
import {ApplicationConstants, AuthorizedItemStatus, ErrorTypes, SessionStorageKeys} from '../../../common/constants/application.constants';
import {EligibilityService} from '../../../common/services/data-model/app/eligibility/eligibility.service';
import {VisionServiceRequestDataService} from '../../../common/services/data-model/http/http-client-data/vision-service-request-data/vision-service-request-data.service';
import {ConsumerService} from '../../../common/services/data-model/app/consumer/consumer.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DatePickerConfiguration} from '../../../common/components/date-picker/date-picker.component';
import {CustomValidatorsService} from '../../../common/services/support/custom-validators/custom-validators.service';
import {EligibilityStatusDescription} from '../../coverage-summary/coverage-summary.component';
import {ReplaceAuthConfirmationModalComponent} from './replace-authorization-confirmation-modal/replace-auth-confirmation-modal';
import {LoadingModalComponent, LoadingModalOptions} from '../../../common/components/loading-modal/loading-modal/loading-modal.component';

export interface DialogData {
  authorization: VisionServiceRequest;
}


@Component({
  selector: 'app-reissue-authorization',
  templateUrl: 'reissue-authorization-warning-modal.html',
  styleUrls: ['reissue-authorization-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ReissueAuthorizationModalComponent implements OnInit, OnDestroy {
  /***** START - PUBLIC MEMBERS *****/
  reissueAuthorizationForm: FormGroup;
  /***** START - PRIVATE MEMBERS *****/
  private _lastValidDateOfServiceWherePatientIsEligibleForServices: string;
  private _memberEligibility: Eligibility;
  private _loadingEligibility: boolean;
  private _vsrSelectedProductPackage: EligibilityProductPackage[];
  private _selectedProductPackage: EligibilityProductPackage[];
  private _eligibleItemAuthorizable: EligibleItemVsrDto[];
  private _eligibleItemsVsr: EligibleItemsVsr[];
  private _dialogRefLoadingCoverage: MatDialogRef<LoadingModalComponent>;
  /***** END - PRIVATE MEMBERS *****/

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ReissueAuthorizationModalComponent>,
    private eligibilityService: EligibilityService,
    private consumerService: ConsumerService,
    private authorizationService: AuthorizationsService,
    private claimFormNavigationService: ClaimFormNavigationService,
    private visionServiceRequestDataService: VisionServiceRequestDataService,
    private customValidatorsService: CustomValidatorsService,
    @Inject(MAT_DIALOG_DATA) public vsrInfo: DialogData
  ) {
  }

  datePickerConfigurationDateOfService: DatePickerConfiguration;
  minDate = ApplicationConstants.minDate;

  /***** START - PUBLIC MEMBERS *****/
  @ViewChild('dateOfServiceControl', {read: ElementRef, static: true}) dateOfServiceControl: ElementRef;


  /***** START - PROPERTY ACCESSORS *****/
  get lastValidDateOfServiceWherePatientIsEligibleForServices(): string {
    return this._lastValidDateOfServiceWherePatientIsEligibleForServices;
  }

  set lastValidDateOfServiceWherePatientIsEligibleForServices(lastValidDateOfServiceWherePatientIsEligibleForServices: string) {
    this._lastValidDateOfServiceWherePatientIsEligibleForServices = lastValidDateOfServiceWherePatientIsEligibleForServices;
  }

  // build the full name of the patient
  getPatientFullName(vsr: VisionServiceRequest): string {
    const middleInitial = vsr.reifiedPatient.name.middle ? vsr.reifiedPatient.name.middle : null;
    return getFormattedFullName(vsr.reifiedPatient.name.firstName, vsr.reifiedPatient.name.lastName, middleInitial);
  }

  get memberEligibility(): Eligibility {
    return this._memberEligibility;
  }

  set memberEligibility(eligibility: Eligibility) {
    this._memberEligibility = eligibility;
  }

  // get eligibility product packages
  get eligibilityProductPackages(): EligibilityProductPackage[] {
    if (this.memberEligibility.productPackageViewModelList) {
      return this.memberEligibility.productPackageViewModelList;
    }
  }

  get dialogLoadingCoverage(): MatDialogRef<LoadingModalComponent> {
    return this._dialogRefLoadingCoverage;
  }

  set dialogLoadingCoverage(dialog: MatDialogRef<LoadingModalComponent>) {
    this._dialogRefLoadingCoverage = dialog;
  }

  get selectedProductPackage(): EligibilityProductPackage[] {
    return this._selectedProductPackage;
  }

  set selectedProductPackage(selectedProductOPackage: EligibilityProductPackage[]) {
    this._selectedProductPackage = selectedProductOPackage;
  }

  get vsrSelectedProductPackage(): EligibilityProductPackage[] {
    return this._vsrSelectedProductPackage;
  }

  set vsrSelectedProductPackage(selectedProductPackage: EligibilityProductPackage[]) {
    this._vsrSelectedProductPackage = selectedProductPackage;
  }

  get eligibleItemVsr(): EligibleItemsVsr[] {
    return this._eligibleItemsVsr;
  }

  get eligibleItemAuthorizable(): EligibleItemVsrDto[] {
    return this._eligibleItemAuthorizable;
  }

  set eligibleItemAuthorizable(eligibleItemAuthorizable: EligibleItemVsrDto[]) {
    this._eligibleItemAuthorizable = eligibleItemAuthorizable;
  }

  get loadingEligibility(): boolean {
    return this._loadingEligibility;
  }

  set loadingEligibility(loadingEligibility: boolean) {
    this._loadingEligibility = loadingEligibility;
  }

  /***** END - PROPERTY ACCESSORS *****/

  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.buildForm();
    this.buildDatePickerConfiguration();
    this.loadEligibility(this.vsrInfo.authorization);
  }

  ngOnDestroy(): void {
    // Remove the in-memory cached eligibility on navigation from replace authorization modal, forcing navigation back to the page
    // to reload the eligibility displayed on the page from the eligibility link
    this.eligibilityService.memberEligibility = undefined;
    this.selectedProductPackage = undefined;
  }

  /***** START - PRIVATE FUNCTIONS *****/
  private buildDatePickerConfiguration(): void {
    this.datePickerConfigurationDateOfService = {
      control: this.reissueAuthorizationForm.controls.dateOfService,
      controlName: 'dateOfService',
      errorWrapperId: {
        defaultValidations: 'reissue-auth-date-of-service-error',
        minDate: 'date-of-service-min-date-error'
      },
      attributes: {
        id: 'date-of-service',
        datePickerId: 'date-of-service-date-picker',
        datePickerToggleId: 'date-of-service-date-picker-toggle',
        name: 'dateOfService'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the date of service'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Date of Service must be in MM/DD/YYYY format'
        }
      ]
    };
  }

  private buildForm(): void {
    this.reissueAuthorizationForm = this.formBuilder.group({
      dateOfService: [DateUtility.buildFriendlyDateFromJsDate(new Date()), [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(ApplicationConstants.minDate)]],
    });
    this.reissueAuthorizationForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe(() => {
    });
  }

  private isEligibleItemAuthorizableAndNotSelected = (eligibleItem: EligibleItem): boolean =>
    eligibleItem && eligibleItem.authorizable && !eligibleItem.isSelected;

  private closeAllPendingDialogs = () => {
    if (this.dialog.openDialogs && this.dialog.openDialogs.length > 0) {
      this.dialog.closeAll();
    }
  };

  public onCloseClick(): void {
    this.dialogRef.close(false);
  }

  // Claim Form Navigation
  navigateToClaimForm(vsr: string): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Opening Claim Form', this.dialog);
    this.claimFormNavigationService.searchAndRetrieveOrCreatePatientEncounterThenLoadDoctorsThenNavigateToClaimForm(vsr, undefined, this.authorizationService.getEffectiveDateFromVsr(vsr)).subscribe(() => {
      // Broadcast value is null, no need for closure
    }, () => {
      // Errors are handled in the HTTP layer for PE, no need for error closure
    }, () => {
      // Close the dialog when the observer completes
      this.dialog.closeAll();
    });
  }

  openDialogForSpinnerWhenLoadingEligibility(): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    this.dialogLoadingCoverage = this.dialog.open<LoadingModalComponent, LoadingModalOptions>(LoadingModalComponent,
      {
        id: ApplicationConstants.loadingCoverage,
        data: {
          modalMessageText: 'Loading Coverage...'
        },
        width: '250px',
        panelClass: 'loadingModal',
        disableClose: true
      });
  }

  // Retrieve the VSR, then load Eligibility with the effective date on the VSR
  searchAndRetrieveVSRForPatientSelected(vsr: VisionServiceRequest): void {
    const authNumber = vsr.vsrNumber;
    const eligibleItemAuthorizable: EligibleItemVsrDto[] = [];
    // Retrieve Vsr
    this.visionServiceRequestDataService.retrieveVisionServiceRequest(authNumber).subscribe((vsrResponse: VisionServiceRequest) => {
      // Retrieve eligibility stored in service
      this.memberEligibility = this.eligibilityService.memberEligibility;
      if (vsrResponse && vsrResponse.reifiedEligibility.authorizedItems) {

        // Iterate through the eligible items on the product package to look for un-checked items
        for (const currentEligibleItem in vsrResponse.reifiedEligibility.authorizedItems) {
          // Initialize a reference to the current eligible item, and if the item is able to be authorized and is not selected, un-check all available services
          const currentEligibleItems: EligibleItemVsrDto = vsrResponse.reifiedEligibility.authorizedItems[currentEligibleItem];
          if (currentEligibleItems &&  currentEligibleItems.status === AuthorizedItemStatus.Available ) {
              eligibleItemAuthorizable.push(currentEligibleItems);
            this.eligibleItemAuthorizable = eligibleItemAuthorizable;
          }
        }
      }
    }, () => {
      // Errors are handled in the HTTP layer for PE, no need for error closure
      return undefined;
    }, () => {
      // Close the dialog when the observer completes
      if (!isNullOrUndefined(this.eligibleItemAuthorizable) ) {
        this.setDefaultAuthorizedBenefitsCheckboxForEachProductPackageEligibleItem();
      }
    });
  }

  // Update Status Description for Eligible Items
  updateStatusDescriptionForEligibleItems(vsr: VisionServiceRequest): void {
    const selectedProductPackage: EligibilityProductPackage[] = [];
    if (this.selectedProductPackage) {
      this.selectedProductPackage.forEach((selectedProductPackage: EligibilityProductPackage) => {
        if (selectedProductPackage.eligibleItems && selectedProductPackage.eligibleItems.length > 0) {
          for (const currentEligibleItem in selectedProductPackage.eligibleItems) {
            const currentEligibleItems: EligibleItem = selectedProductPackage.eligibleItems[currentEligibleItem];
            const existingItemIndex = this.sortEligibilityProductPackages().find(arrItem => arrItem.businessName === vsr.businessProductPackageName);
            currentEligibleItems.statusDescription = EligibilityStatusDescription.YES;
            selectedProductPackage.eligibleItems.push(currentEligibleItems);
            this.selectedProductPackage.push(existingItemIndex, selectedProductPackage);
          }
        }
      });
    }
  }

  private loadEligibility(vsr: VisionServiceRequest): void {
    const dateOfService = vsr.effectiveDate;
    const externalEligibilityURL: string = vsr.reifiedPatient.externalEligibility.href;
    const patientEligibilityLinkAsOfDate = this.consumerService.changeUrlWithSpecifiedAsOfDate(externalEligibilityURL, dateOfService);

    this.loadingEligibility = true;
    // Retrieve eligibility stored in service
    this.memberEligibility = this.eligibilityService.memberEligibility;
    // Retrieve VSR info for patient selected
    this.searchAndRetrieveVSRForPatientSelected(vsr);
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    this.openDialogForSpinnerWhenLoadingEligibility();

    if (!this.memberEligibility) {
      this.eligibilityService.retrieveMemberEligibilityFromConsumerLink(patientEligibilityLinkAsOfDate).subscribe((memberEligibility: Eligibility) => {
        if (memberEligibility) {
          this.lastValidDateOfServiceWherePatientIsEligibleForServices = this.reissueAuthorizationForm.controls.dateOfService.value;
          this.memberEligibility = memberEligibility;
          // Sort selected current product package from returned list
          if (this.eligibilityProductPackages) {
            this.selectCurrentProductPackage(vsr);
          }
        }
      }, () => {
        // on observer error
      }, () => {
        // Close the dialog when the observer completes
        if (isNullOrUndefined(this.memberEligibility)) {
          this.closeAllPendingDialogs();
        }
        // Close the loading coverage spinner
        if (this.dialogLoadingCoverage.componentInstance.id === ApplicationConstants.loadingCoverage) {
          this.dialogLoadingCoverage.close();
        }
        this.loadingEligibility = false;
      });

    } else {
      // Sort selected current product package from returned list
      if (this.eligibilityProductPackages) {
        this.selectCurrentProductPackage(vsr);
      }
     // Close the loading coverage spinner
      if (this.dialogLoadingCoverage.componentInstance.id === ApplicationConstants.loadingCoverage) {
        this.dialogLoadingCoverage.close();
      }
      this.loadingEligibility = false;
    }
  }

  // Reload Eligibility after DateOfService Update
  private reloadEligibility(updateDateOfService: string, vsr: DialogData): void {
    this.loadingEligibility = true;
    const dateOfService = vsr.authorization.effectiveDate;
    const externalEligibilityURL: string = vsr.authorization.reifiedPatient.externalEligibility.href;
    const patientEligibilityLinkAsOfDate = this.consumerService.changeUrlWithSpecifiedAsOfDate(externalEligibilityURL, updateDateOfService);

    // Retrieve VSR info for patient selected
    this.searchAndRetrieveVSRForPatientSelected(vsr.authorization);
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    this.openDialogForSpinnerWhenLoadingEligibility();

    // if we have eligibility link in session storage, then recall the eligibility API to get data using new as of date.
    if (patientEligibilityLinkAsOfDate) {
      this.eligibilityService.retrieveMemberEligibilityWithSpecifiedAsOfDate(patientEligibilityLinkAsOfDate, updateDateOfService).subscribe((memberEligibility: Eligibility) => {
        if (memberEligibility) {
          this.memberEligibility = memberEligibility;
          // Sort selected current product package from returned list
          if (this.eligibilityProductPackages) {
            this.lastValidDateOfServiceWherePatientIsEligibleForServices = updateDateOfService;
            this.selectCurrentProductPackage(vsr.authorization);
          }
        } else {
          this.reissueAuthorizationForm.controls.dateOfService.setValue(updateDateOfService);
        }
      }, () => {
        // on observer error
      }, () => {
        // Close the dialog when the observer completes
        this.dialogLoadingCoverage.close();
        this.loadingEligibility = false;
      });
    }
  }

  // Sort Product Packages by alphabetical order
  private sortEligibilityProductPackages(): EligibilityProductPackage[] {
    let sortedEligibilityProductPackages: EligibilityProductPackage[] = [];
    sortedEligibilityProductPackages = this.eligibilityProductPackages.sort(function (a, b) {

        if (a.businessName.toLowerCase() < b.businessName.toLowerCase()) {
          return -1;
        }
        if (a.businessName.toLowerCase() > b.businessName.toLowerCase()) {
          return 1;
        }
        return 0;
      }
    );
    return sortedEligibilityProductPackages;
  }

  getSessionStoredCreateVsrLink(): any {
    const stringifiedCreateVsrUrlFromSessionStorage = sessionStorage.getItem(SessionStorageKeys.CreateVsrLink);
    if (!isStringNullUndefinedOrEmpty(stringifiedCreateVsrUrlFromSessionStorage)) {
      return JSON.parse(stringifiedCreateVsrUrlFromSessionStorage);
    }
    return undefined;
  }

  // Select current ProductPackage from return Eligibility Product Packages list
  private selectCurrentProductPackage(vsr: VisionServiceRequest): void {
    const selectedProductPackage: EligibilityProductPackage[] = [];
    let existingItemIndex: EligibilityProductPackage;
    if (this.eligibilityProductPackages) {
      existingItemIndex = this.sortEligibilityProductPackages().find(arrItem => arrItem.businessName === vsr.businessProductPackageName);
      this.eligibilityProductPackages.forEach((currentProductPackage: EligibilityProductPackage) => {
        if (existingItemIndex === currentProductPackage) {
          selectedProductPackage.push(currentProductPackage);
          this.selectedProductPackage = selectedProductPackage;
          sessionStorage.setItem(SessionStorageKeys.CreateVsrLink, JSON.stringify({ href: currentProductPackage.createVisionServiceRequest }));
        }
      });

      if (!isNullOrUndefined(this.selectedProductPackage)) {
        this.dialogLoadingCoverage.close();
      }

      if (this.selectedProductPackage && this.eligibleItemAuthorizable) {
          this.setDefaultAuthorizedBenefitsCheckboxForEachProductPackageEligibleItem();
      }
    }
  }

  /**
   * Prototypes the eligible items on the response from eligibility-web with a view model property for checkboxes.
   *
   * @see ReissueAuthorizationModalComponent#toggleAuthorizeBenefitCheckboxForAllProductPackageEligibleItems
   * @see ReissueAuthorizationModalComponent#toggleAuthorizedBenefitCheckboxForSingleEligibleItem
   */
  private setDefaultAuthorizedBenefitsCheckboxForEachProductPackageEligibleItem(): void {
    if (!isNullOrUndefined(this.selectedProductPackage)) {
      this.selectedProductPackage.forEach((productPackage: EligibilityProductPackage) => {
        productPackage.eligibleItemInLieuOfs = new Map<string, string[]>();
        // Check if we have coverages returned for the product package
        if (productPackage.coverages && productPackage.coverages.length > 0) {
          // Iterate through the coverages on the product package to check if there are any in lieu of relation to the eligible item that has just been updated
          productPackage.coverages.forEach((coverageItem: EligibilityCoverage) => {
            // If the coverage has a productCatalogKey and an inLieuOf with product catalog key(s) then we can set the inLieuOf relation in the eligibleItemInLieuOfs map
            if (coverageItem && coverageItem.productCatalogKeys && coverageItem.productCatalogKeys.length > 0 &&
              coverageItem.inLieuOf && coverageItem.inLieuOf.productCatalogKeys && coverageItem.inLieuOf.productCatalogKeys.length > 0) {
              productPackage.eligibleItemInLieuOfs.set(coverageItem.productCatalogKeys[0], coverageItem.inLieuOf.productCatalogKeys);
            }
          });
        }
        // Initialize all available services checkbox
        productPackage.allAvailableServices = false;
        if (productPackage.eligibleItems && productPackage.eligibleItems.length > 0) {
          productPackage.eligibleItems.forEach((eligibleItem: EligibleItem) => {
            // Initialize each individual service checkbox
            eligibleItem.isSelected = false;
            // Initialize a view property to check if a product package has any available services
            if (isNullOrUndefined(productPackage.hasAnyAvailableServices)) {
              if (!isNullOrUndefined(this.eligibleItemAuthorizable)) {
                this.eligibleItemAuthorizable.forEach((eligibleItemVsr: EligibleItemVsrDto) => {
                  if (eligibleItemVsr.status === AuthorizedItemStatus.Available
                    && eligibleItemVsr.name === eligibleItem.productCatalogKey) {
                    productPackage.hasAnyAvailableServices = true;
                  }
                });
              }
            }
          });
        }
      });
    }
  }


  /**
   * Toggles the checkbox status for a single eligible item for the selected product package. If all eligible items are
   * in a checked state, update the all available services checkbox to checked. Conversely, if all eligible items are
   * checked and the user un-checks a box, the method will trigger the all available services checkbox to un-check itself.
   *
   * @param eligibleItem
   * @param productPackage
   */
  toggleAuthorizedBenefitCheckboxForSingleEligibleItem(eligibleItem: EligibleItem, productPackage: EligibilityProductPackage): void {
    // Toggle the individual service checkbox, and set all available services checkbox to match the eligible item checked status
    eligibleItem.isSelected = !eligibleItem.isSelected;
    productPackage.allAvailableServices = eligibleItem.isSelected;

    this.updateAvailabilityBasedOnInLieuOf(eligibleItem, productPackage);
    // Iterate through the eligible items on the product package to look for un-checked items
    for (const currentEligibleItemIndex in productPackage.eligibleItems) {
      // Initialize a reference to the current eligible item, and if the item is able to be authorized and is not selected, un-check all available services
      const currentEligibleItem: EligibleItem = productPackage.eligibleItems[currentEligibleItemIndex];
      if (this.isEligibleItemAuthorizableAndNotSelected(currentEligibleItem)) {
        productPackage.allAvailableServices = false;
        break;
      }
    }
  }

  /**
   * This method is invoked user interacts with one of the authorizable checkboxes.
   * If the eligible item in question that is passed in has any other eligible items from the same package that are in lieu of this eligible item,
   * then we need to either check and disable the in lieu of eligible item's checkboxes or uncheck and enable the in lieu of eligible item's checkboxes
   * depending on if the eligible item interacted with is being checked or unchecked.
   * @param eligibleItem
   * @param productPackage
   */
  updateAvailabilityBasedOnInLieuOf(eligibleItem: EligibleItem, productPackage: EligibilityProductPackage): void {
    if (!isNullOrUndefined(productPackage.eligibleItemInLieuOfs)) {
      const eligibleItemsInLieOfThisEligibileItem: string[] = productPackage.eligibleItemInLieuOfs.get(eligibleItem.productCatalogKey);
      // check if the eligible item passed in has any other eligible items it is in lieu of
      if (eligibleItemsInLieOfThisEligibileItem && eligibleItemsInLieOfThisEligibileItem.length > 0) {
        // Iterate through the eligible items in the package
        productPackage.eligibleItems.forEach((currentEligibleItem: EligibleItem) => {
          // If we find an eligible item that is in lieu of the eligible item passed into this method
          if (eligibleItemsInLieOfThisEligibileItem.includes(currentEligibleItem.productCatalogKey)) {
            // If the eligible item passed in is checked by the user, then we need to set the in lieu of fields to checked and disabled also
            if (eligibleItem.isSelected && this.isAvailableToAuthorize(eligibleItem)) {
              currentEligibleItem.isSelected = true;
              currentEligibleItem.inLieuOfIsSelected = true;
            } else {
              currentEligibleItem.isSelected = false;
              currentEligibleItem.inLieuOfIsSelected = false;
            }
          }
        });
      }
    }
  }

  /**
   * Matches on any eligible items having been selected via their respective checkbox and enables the Issue Authorizations
   * button if any have been checked.
   *
   * @param productPackage
   */
  hasAnyEligibleItemsSelected(productPackage: EligibilityProductPackage): boolean {
    if (productPackage.eligibleItems && productPackage.eligibleItems.length > 0) {
      for (let eligibleItemIndex = 0; eligibleItemIndex < productPackage.eligibleItems.length; eligibleItemIndex++) {
        if (productPackage.eligibleItems[eligibleItemIndex].isSelected) {
          return true;
        }
      }
    }
    return false;
  }


// Reissue Authorization when replace button is clicked
  replaceAuthorization(vsr: DialogData, productPackage: EligibilityProductPackage): void {
    this.dialog.open(ReplaceAuthConfirmationModalComponent, {
      width: '350px',
      height: '175px',
      panelClass: 'eclaim-popup-modal',
      disableClose: true,
      data: {
        authNumber: vsr.authorization.vsrNumber
      }
    }).afterClosed().subscribe((okClicked: boolean) => {
      if (okClicked) {
        // Delete The Authorization & Create New Vsr
        const url = vsr.authorization.self.href;
        if (url) {
          this.visionServiceRequestDataService.deleteVisionServiceRequest(url).subscribe((visionServiceDeleteRequestResults) => {
            if (visionServiceDeleteRequestResults) {
              this.dialog.closeAll();
              // Retrieve Eligibility & Issue Authorization
              this.retrieveEligibilityForSelectedVsr(productPackage, vsr.authorization);
            }
          });
        }
      }
    });
  }

  retrieveEligibilityForSelectedVsr(productPackage: EligibilityProductPackage, vsr: VisionServiceRequest): void {
    // Retrieve Member Eligibility
    const externalEligibilityURL: string = vsr.reifiedPatient.externalEligibility.href;
    const selectedProductPackageVsr: EligibilityProductPackage[] = [];
    let existingItemIndex: EligibilityProductPackage;

    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Retrieving Eligibility...', this.dialog);
      if (externalEligibilityURL) {
        this.eligibilityService.retrieveMemberEligibilityFromConsumerLink(externalEligibilityURL).subscribe((memberEligibility: Eligibility) => {
          if (memberEligibility) {
            this.memberEligibility = undefined;
            this.memberEligibility = memberEligibility;
            existingItemIndex = this.sortEligibilityProductPackages().find(arrItem => arrItem.businessName === vsr.businessProductPackageName);
            let keepGoing = true;
            this.eligibilityProductPackages.forEach((currentProductPackage: EligibilityProductPackage) => {
              if (keepGoing) {
                if (existingItemIndex === currentProductPackage) {
                  selectedProductPackageVsr.push(currentProductPackage);
                  this.vsrSelectedProductPackage = selectedProductPackageVsr;
                  if (productPackage) {
                    productPackage.createVisionServiceRequest = currentProductPackage.createVisionServiceRequest;
                  }
                  keepGoing = false;
                }
            }
            });
          }
        }, () => {
          // on observer error
          this.dialog.closeAll();
          return undefined;
        }, () => {
          // Issue Authorization for the selected & auth(authorized items)
          if (!isNullOrUndefined(productPackage)) {
            this.dialog.closeAll();
            this.issueAuthorization(productPackage, vsr);
          }
        });
      } else {
        this.dialog.closeAll();
      }
  }


  /**
   * Issues an authorization using the VSR link returned from eligibility-web. Retrieves a list of all selected and available
   * to authorize items sent from the UI on the product package, and pushes them on the VSR eligible items payload.
   *
   * @param productPackage
   * @param vsr
   */
  issueAuthorization(productPackage: EligibilityProductPackage, vsr: VisionServiceRequest): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Reissuing Auth...', this.dialog);
    const selectedEligibleItems: EligibleItemVsrDto[] = [];
    const currentEligibleItems: EligibleItemVsrDto[] = [];

     // Retrieve CreateVsrLink from Eligibility Session Storage
         if (isNullOrUndefined(productPackage.createVisionServiceRequest)) {
           productPackage.createVisionServiceRequest = this.getSessionStoredCreateVsrLink();
         }

        if (productPackage.eligibleItems && productPackage.eligibleItems.length > 0) {
          productPackage.eligibleItems.forEach((eligibleItem: EligibleItem) => {
            if (eligibleItem.authorizable && eligibleItem.isSelected) {
              selectedEligibleItems.push({name: eligibleItem.productCatalogKey});
            }
          });
        }


    if (this.eligibleItemAuthorizable) {
      this.eligibleItemAuthorizable.forEach((eligibleItemVsr: EligibleItemVsrDto) => {
        if (eligibleItemVsr && ((eligibleItemVsr.status === AuthorizedItemStatus.Authorized) ||
          eligibleItemVsr.status === AuthorizedItemStatus.Available)) {
          selectedEligibleItems.push({name: eligibleItemVsr.name});
        }
      });
    }

    // Filter Array for Duplicates
     selectedEligibleItems.filter((item, index) => {
       if (currentEligibleItems.findIndex(i => i.name === item.name) === -1) {
         currentEligibleItems.push(item);
       }
     });

    // Check to see if the URL is available on the product package, supplied by eligibility-web
      if (productPackage.createVisionServiceRequest && productPackage.createVisionServiceRequest.href) {
        const vsrLink: string = this.updateVSRLinkReturnedFromEligibilityWithDateOfService(productPackage.createVisionServiceRequest.href);
        const createVsrRequest = new CreateVisionServiceRequest(currentEligibleItems);
        // Verify the create request contains the necessary information to send in the payload to VSR
        if (createVsrRequest.vsrPayload) {
          this.authorizationService.createAuthorization(vsrLink, createVsrRequest.vsrPayload).subscribe((vsrNumber: string) => {
            this.closeAllPendingDialogs();
            if (!isStringNullUndefinedOrEmpty(vsrNumber)) {
              // Navigate to Claim Form
              this.navigateToClaimForm(vsrNumber);
            }
          });
        } else {
          // Close dialogs if the payload was not valid
          this.closeAllPendingDialogs();
        }
      } else {
        // Close dialogs if the VSR link on the product package was not available
        this.closeAllPendingDialogs();
      }
  }

  private updateVSRLinkReturnedFromEligibilityWithDateOfService(vsrLink: string): string {
    // Instantiate the link as a url
    const vsrLinkAsUrl: URL = new URL(vsrLink);

    // If the date of service is valid, update the link with it
    if (!isStringNullUndefinedOrEmpty(this.lastValidDateOfServiceWherePatientIsEligibleForServices)) {
      vsrLinkAsUrl.searchParams.set('effectiveDate', DateUtility.buildYyyyMmDdDateFromDate(this.lastValidDateOfServiceWherePatientIsEligibleForServices));
      return vsrLinkAsUrl.toString();
    }

    // Default to returning the link passed from eligibility if date of service is not available
    return vsrLink;
  }

  /**
   * Enables the Issue Authorization button based on the product package view model property for any available services.
   *
   * @param productPackage
   */
  hasAnyAvailableServices(productPackage: EligibilityProductPackage): boolean {
    return !isNullOrUndefined(productPackage.hasAnyAvailableServices);
  }

  /**
   * Toggles the checkbox status for all eligible items for the selected product package.
   *
   * @param productPackage
   */
  toggleAuthorizeBenefitCheckboxForAllProductPackageEligibleItems(productPackage: EligibilityProductPackage): void {
    productPackage.allAvailableServices = !productPackage.allAvailableServices;
    productPackage.eligibleItems.forEach((eligibleItem: EligibleItem) => {
      if (this.isAvailableToAuthorize(eligibleItem)) {
        // For each available service, set the checkbox to match the state of the all available services checkbox
        eligibleItem.isSelected = productPackage.allAvailableServices;
      } else if (eligibleItem.isSelected && eligibleItem.inLieuOfIsSelected) {
        eligibleItem.isSelected = productPackage.allAvailableServices;
        eligibleItem.inLieuOfIsSelected = productPackage.allAvailableServices;
      }
    });
  }

  /**
   * Used to disable checkboxes that are not available to authorize due to non-available status descriptions.
   *
   * @param eligibleItem
   */
  isAvailableToAuthorize(eligibleItem: EligibleItem): boolean {
    return (eligibleItem.authorizable && eligibleItem.statusDescription === EligibilityStatusDescription.YES && !eligibleItem.inLieuOfIsSelected) || this.isAvailableToAuthorizeVsr(eligibleItem);
  }

  isAvailableToAuthorizeVsr(eligibleItem: EligibleItem): boolean {
    let eligibleItemAuthorizable: boolean = false;
    if (this.eligibleItemAuthorizable) {
      this.eligibleItemAuthorizable.forEach((eligibleItemVsr: EligibleItemVsrDto) => {
        if ((eligibleItemVsr.status === AuthorizedItemStatus.Available || eligibleItemVsr.status === AuthorizedItemStatus.Authorized)
          && eligibleItemVsr.name === eligibleItem.productCatalogKey) {
          eligibleItemAuthorizable = true;
        }
      });
    }
    return eligibleItemAuthorizable;
  }

  updateEligibilityUsingAsOfDateFromDateOfService(vsr: DialogData): void {
    if (this.reissueAuthorizationForm.controls.dateOfService.valid) {
      this.reloadEligibility(this.reissueAuthorizationForm.controls.dateOfService.value, vsr);
    }
  }

  /***** END - PUBLIC MEMBERS *****/

}
