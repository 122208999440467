import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MockDiagnosisCodeList} from './mock-diagnosis-code-list';
import {
  DiagnosisCodeGroup,
  ProductPackage
} from '../../../../../common/constants/application.constants';
import {DiagnosisCodeGrouping} from '../../../../../models/diagnosisCodeList';
import {ClaimsService} from '../../../../../common/services/data-model/app/claims/claims.service';
import {MatDialogRef} from '@angular/material/dialog';
import {isStringNullUndefinedOrEmpty} from '../../../../../common/utility';

@Component({
  selector: 'app-diagnosis-code-list-modal',
  templateUrl: './diagnosis-code-list-modal.component.html',
  styleUrls: ['./diagnosis-code-list-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DiagnosisCodeListModalComponent implements OnInit {

  public diagnosisCodeList: DiagnosisCodeGrouping[];
  // TODO Remove once Provider Conditions API is integrated
  public diagnosisCodeListMap = new Map()
    .set(ProductPackage.VisionTherapy, MockDiagnosisCodeList.VisionTherapy)
    .set(ProductPackage.PEC, MockDiagnosisCodeList.PrimaryEyeCare)
    .set(ProductPackage.DEPPlus, MockDiagnosisCodeList.DiabeticEyeCarePlus)
    .set(DiagnosisCodeGroup.ComputerVisionSyndrome, MockDiagnosisCodeList.ComputerVisionSyndrome)
    .set(DiagnosisCodeGroup.WellVision, MockDiagnosisCodeList.WellVision);
  private selectedDiagnosisCode: string;

  constructor(
    private claimsService: ClaimsService,
    public dialogRef: MatDialogRef<DiagnosisCodeListModalComponent>
  ) {
  }

  ngOnInit(): void {
    this.getDiagnosisCodeList(this.claimsService.getActiveClaim().productPackageName);
  }

  public getDiagnosisCodeList(productPackage: string) {
    let productPackageKey = '';
    switch (productPackage) {
      case ProductPackage.VisionTherapy:
        productPackageKey = ProductPackage.VisionTherapy;
        break;
      case ProductPackage.PEC:
        productPackageKey = ProductPackage.PEC;
        break;
      case ProductPackage.DEPPlus:
        productPackageKey = ProductPackage.DEPPlus;
        break;
      case ProductPackage.ComputerVisionCarePlan:
      case ProductPackage.ComputerVisionCarePlanSupplemental:
      case ProductPackage.VDT:
      case ProductPackage.VDTSupplemental:
        productPackageKey = DiagnosisCodeGroup.ComputerVisionSyndrome;
        break;
      default:
        productPackageKey = DiagnosisCodeGroup.WellVision;
    }

    this.diagnosisCodeList = this.diagnosisCodeListMap.get(productPackageKey);
  }

  public setSelectedDiagnosisCode(diagnosisCode: string) {
    this.selectedDiagnosisCode = diagnosisCode;
  }

  public confirmSelectedDiagnosisCode() {
    this.dialogRef.close(this.selectedDiagnosisCode);
  }

  public isDiagnosisCodeSelected(): boolean {
    return !isStringNullUndefinedOrEmpty(this.selectedDiagnosisCode);
  }

  public isSelectedDiagnosisCode(diagnosisCode: string): boolean {
    return this.selectedDiagnosisCode === diagnosisCode;
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }

}
