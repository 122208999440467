import { Injectable } from '@angular/core';
import {
  VisionServiceRequest,
  VisionServiceRequestClaimDto,
  VisionServiceRequestPayload
} from '../../../../../models/VisionServiceRequestResults';
import { Observable } from 'rxjs';
import { VisionServiceRequestDataService } from '../../http/http-client-data/vision-service-request-data/vision-service-request-data.service';
import { ApplicationConstants, SessionStorageKeys } from '../../../../constants/application.constants';
import { isNullOrUndefined, isStringNullUndefinedOrEmpty } from '../../../../utility';
import { MessageService } from '../../../support/message/message.service';
import {AuthTrackingSearchRequest} from '../../../../../models/AuthTrackingSearchRequest';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationsService {

  constructor(
    private visionServiceRequestDataService: VisionServiceRequestDataService,
    private messageService: MessageService
  ) { }

  private _authorizations: VisionServiceRequest[];
  private _vsrNumber: string;
  private _vsrMapping: Map<string, string> = new Map();

  /***** START - PROPERTY ACCESSORS *****/
  get authorizations(): VisionServiceRequest[] {
    return (this._authorizations) ? Object.deepClone(this._authorizations) : this._authorizations;
  }

  set authorizations(authorizations: VisionServiceRequest[]) {
    this._authorizations = authorizations;
  }

  get vsrNumber(): string {
    return this._vsrNumber;
  }

  set vsrNumber(newVsrNumber: string) {
    this._vsrNumber = newVsrNumber;
  }
  /***** END - PROPERTY ACCESSORS *****/

  searchAuthorizations(vsrApiUrl: string): Observable<VisionServiceRequest[]> {
    // resetting back to undefined for next request
    return new Observable((observer) => {
      this.visionServiceRequestDataService.searchVisionServiceRequests(vsrApiUrl)
        .subscribe((visionServiceRequestResults: VisionServiceRequest[]) => {
          if (visionServiceRequestResults) {
            this.authorizations = visionServiceRequestResults;
            sessionStorage.setItem(SessionStorageKeys.AuthorizationSearchUrl, JSON.stringify({ href: vsrApiUrl }));
            visionServiceRequestResults.forEach((vsr: VisionServiceRequest) => {
              this._vsrMapping.set(vsr.vsrNumber, vsr.effectiveDate);
            });
          }
          observer.next(visionServiceRequestResults);
          observer.complete();
        });
    });
  }

  searchOutstandingAuthorizations(authTrackingSearchRequest: AuthTrackingSearchRequest): Observable<VisionServiceRequest[]> {
    // resetting back to undefined for next request
    return new Observable((observer) => {
    this.visionServiceRequestDataService.searchVisionServiceRequestsForOutstandingAuths(authTrackingSearchRequest).subscribe((visionServiceRequestResults) => {
      if (visionServiceRequestResults) {
        this.authorizations = visionServiceRequestResults;
        visionServiceRequestResults.forEach((vsr: VisionServiceRequest) => {
          this._vsrMapping.set(vsr.vsrNumber, vsr.effectiveDate);
        });
      }
      observer.next(visionServiceRequestResults);
      observer.complete();
    });
    });
  }

  retrieveAuthorization(authorizationNumber: string, showSnackbar: boolean = true): Observable<VisionServiceRequestClaimDto> {
    return new Observable(observer => {
      this.visionServiceRequestDataService.retrieveVisionServiceRequest(authorizationNumber, showSnackbar).subscribe((vsrResponse: VisionServiceRequest) => {
        let emittedVsr: VisionServiceRequestClaimDto;
        if (vsrResponse) {
          this._vsrMapping.set(vsrResponse.vsrNumber, vsrResponse.effectiveDate);
          emittedVsr = new VisionServiceRequestClaimDto(vsrResponse.vsrNumber, this.getEffectiveDateFromVsr(vsrResponse.vsrNumber));
        }
        observer.next(emittedVsr);
        observer.complete();
      });
    });
  }

  createAuthorization(vsrLinkToFollow: string, vsrPayload: VisionServiceRequestPayload): Observable<string> {
    return new Observable((observer) => {
      this.visionServiceRequestDataService.createVisionServiceRequest(vsrLinkToFollow, vsrPayload).subscribe((vsrResponse: VisionServiceRequest) => {
        let emittedVsr: VisionServiceRequestClaimDto = new VisionServiceRequestClaimDto(undefined, undefined);
        if (vsrResponse) {
          let currentAuthorizations: VisionServiceRequest[] = this.authorizations;
          if ( isNullOrUndefined(currentAuthorizations)) {
            currentAuthorizations = [];
          }
          currentAuthorizations.push(vsrResponse);
          this.authorizations = currentAuthorizations;
          this._vsrMapping.set(vsrResponse.vsrNumber, vsrResponse.effectiveDate);
          this.vsrNumber = vsrResponse.vsrNumber;
          emittedVsr = new VisionServiceRequestClaimDto(vsrResponse.vsrNumber, vsrResponse.effectiveDate);
        }
        observer.next(emittedVsr.authorizationNumber);
        observer.complete();
      });
    });
  }

  deleteAuthorization(index: number, isAuthTrackingDelete: boolean = false): Observable<VisionServiceRequest[]> {
    if (this._authorizations && this._authorizations.length && this._authorizations[index].self) {
      const url = this._authorizations[index].self.href;
      return new Observable((observer) => {
        this.visionServiceRequestDataService.deleteVisionServiceRequest(url, isAuthTrackingDelete).subscribe((visionServiceDeleteRequestResults) => {
          if (visionServiceDeleteRequestResults) {
            this._authorizations.splice(index, 1);
          }
          observer.next(this._authorizations);
          observer.complete();
        });
      });
    }
  }

  getSessionStoredAuthorizationSearchLink(): any {
    const stringifiedAuthorizationSearchUrlFromSessionStorage = sessionStorage.getItem(SessionStorageKeys.AuthorizationSearchUrl);
    if (!isStringNullUndefinedOrEmpty(stringifiedAuthorizationSearchUrlFromSessionStorage)) {
      return JSON.parse(stringifiedAuthorizationSearchUrlFromSessionStorage);
    }
    return undefined;
  }

  getEffectiveDateFromVsr(vsrNumber: string): string {
    if (vsrNumber && !isStringNullUndefinedOrEmpty(this._vsrMapping.get(vsrNumber))) {
      return this._vsrMapping.get(vsrNumber);
    } else {
      this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.invalidOrDeletedAuthorization);
      return undefined;
    }
  }
}
