<app-component-mask></app-component-mask>

<!-- Show/Hide CMS Numbers -->
<div class="cms-number-toggle" id="facilityAndBilling-cmsNumberToggle"></div>

<div id="{{id}}">
  <mat-card class="container-white-full-column">

    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings" class="sticky-card-header" [ngClass]="{ 'disabled-card-header': facilityAndBillingForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="facility-and-billing-title">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits" [ngClass]="{ 'disabled-card-header': facilityAndBillingForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="facility-and-billing-title-edits">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning" [ngClass]="{ 'disabled-card-header': facilityAndBillingForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="facility-and-billing-title-warning">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Main Form -->
    <mat-card-content>
      <form fxLayout="column" [formGroup]="facilityAndBillingForm" novalidate>

        <!-- Physician / Signature / Patient's Account No. / Accept Assignment -->
        <div fxLayout="row" fxLayoutAlign="start center" class="margin-top-30">
          <label id="facility-and-billing-acct-number-label" class="nested-column-5">26. Patient Account Number</label>
          <label id="facility-and-billing-signature-label" class="nested-column-3"><span
            class="required-label-asterisk">* </span>31. Provider Signature on File</label>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-15">
          <!-- Patient's Account No -->
          <div fxLayout="column" class="nested-column-5">
            <mat-form-field appearance="outline">
              <input matInput id="facility-and-billing-acct-number-input"
                     type="text"
                     formControlName="patientAccountNumber"
                     maxlength="15"/>
            </mat-form-field>
            <app-error-wrapper [config]="errorWrapperConfig.patientAccountNumber"></app-error-wrapper>
          </div>

          <!-- Provider Signature on File-->
          <div fxLayout="column" class="nested-column-3">
            <div fxLayout="row">
              <mat-checkbox id="facility-and-billing-signature-checkbox"
                            formControlName="doctorSignatureIndicator"
                            (change)="onProviderSignatureChange()" class="margin-top-10 margin-right-10"
                            (keyup.enter)="onKeypressEventCheckbox( facilityAndBillingForm.get('doctorSignatureIndicator'))"></mat-checkbox>
              <app-date-picker class="required-mat-form-field" id="facility-billing-date-picker"
                               [datePickerConfiguration]="datePickerConfigurationDoctorSignatureDate"></app-date-picker>
            </div>
            <app-error-wrapper id="provider-signature-error"
                               [config]="errorWrapperConfig.doctorSignatureIndicator"></app-error-wrapper>
          </div>

          <!-- Accept Assignment -->
          <div fxlayout="column" class="nested-column-4">
            <div fxLayout="row" class="margin-top-10">
              <mat-checkbox formControlName="doctorAcceptAssignmentIndicator"
                            id="facility-and-billing-accept-assignment-checkbox"
                            class="required-mat-form-field margin-right-10"
                            (keyup.enter)="onKeypressEventCheckbox( facilityAndBillingForm.get('doctorAcceptAssignmentIndicator'))"></mat-checkbox>
              <label id="facility-and-billing-accept-assignment-label"><span class="required-label-asterisk">* </span>27.
                I Accept Assignment to Submit Claim</label>
            </div>
            <app-error-wrapper id="accept-assignment-error"
                               [config]="errorWrapperConfig.doctorAcceptAssignmentIndicator"></app-error-wrapper>

          </div>
        </div>


        <!-- Show Addresses Link -->
        <div fxLayout="row" class="nested-column-12 margin-top-15 margin-bottom-30" fxLayoutAlign="end"
             *ngIf="!showFacilityBillingAddress">
          <button mat-flat-button class="mat-flat-button mat-primary" id="facility-editAddresses" [disabled]="facilityAndBillingForm.disabled"
                  (click)="toggleEditAddressesExpanded()">{{getExpandAddressText()}}</button>
        </div>

        <!-- Edit Addresses Section -->
        <div class="margin-top-15" [ngClass]="{ 'hidden': !showFacilityBillingAddress }">

          <!-- Services Facility -->
          <div fxLayout="row">
            <div class="nested-column-6" fxLayoutAlign="stretch">
              <mat-card fxFlexAlign="stretch">
                <mat-card-header [ngClass]="{ 'disabled-card-header': facilityAndBillingForm.disabled }">
                  <mat-card-title>
                    <h1 id="facility-and-billing-services-facility-header">Services Facility</h1>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column">
                  <!-- Name -->
                  <label id="facility-name-label" class="margin-top-15 nested-column-12"><span
                    class="required-label-asterisk">* </span>32. Facility
                    Name</label>
                  <mat-form-field appearance="outline" class="margin-top-15 margin-bottom-15 nested-column-12">
                    <input id="facility-name-input" formControlName="facilityName" matInput type="text"
                           maxlength="50"/>
                  </mat-form-field>

                  <!-- Address-->
                  <label id="facility-address-label" class="nested-column-12 margin-top-15 margin-bottom-15"><span
                    class="required-label-asterisk">* </span>Facility
                    Address</label>
                  <mat-form-field appearance="outline" class="margin-bottom-15 nested-column-12">
                    <input matInput type="text" class="facility-address1" id="facility-address1"
                           formControlName="facilityAddressStreet1" maxlength="30"/>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="margin-bottom-15 nested-column-12">
                    <input matInput type="text" id="facility-address2" formControlName="facilityAddressStreet2"
                           maxlength="30"/>
                  </mat-form-field>

                  <!-- Zip / City / State-->
                  <div fxLayout="row" class="margin-top-15">
                    <label id="facility-city-label" class="nested-column-6"><span
                      class="required-label-asterisk">* </span>City</label>
                    <label id="facility-state-label" class="nested-column-3"><span
                      class="required-label-asterisk">* </span>State</label>
                    <label id="facility-zip-code-label" class="nested-column-3"><span
                      class="required-label-asterisk">* </span>Zip Code</label>
                  </div>

                  <div fxLayout="row" class="margin-top-15">

                    <mat-form-field appearance="outline" class="nested-column-6">
                      <input matInput
                             formControlName="facilityAddressCity"
                             id="facility-city-input"
                             maxlength="20"/>
                    </mat-form-field>

                    <div class="nested-column-3">
                      <select matSelect formControlName="facilityAddressStateCode" class="custom-select"
                              id="facility-state-dropdown">
                        <option selected disabled value>Select State</option>
                        <option *ngFor="let stateCode of stateCodes" id="facility-state-{{stateCode}}"
                                [value]="stateCode">{{stateCode}}</option>
                      </select>
                    </div>

                    <mat-form-field appearance="outline" class="nested-column-3">
                      <input formControlName="facilityAddressZipCode"
                             matInput
                             id="facility-zip-code-input"
                             #facilityAddressZipCodeControl>
                    </mat-form-field>

                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <!-- Billing Information -->
            <div class="nested-column-6" fxLayoutAlign="stretch">
              <mat-card fxFlexAlign="stretch">
                <mat-card-header [ngClass]="{ 'disabled-card-header': facilityAndBillingForm.disabled }">
                  <mat-card-title>
                    <h1 id="facility-and-billing-billing-info-address-header">Billing Information</h1>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column">
                  <!-- Name -->
                  <label id="billing-name-label" class="nested-column-12 margin-top-15"><span
                    class="required-label-asterisk">* </span>33. Billing
                    Name</label>
                  <mat-form-field appearance="outline" class="margin-top-15 margin-bottom-15 nested-column-12">
                    <input id="billing-name-input" matInput type="text"
                           formControlName="serviceLocationProviderName"/>
                  </mat-form-field>

                  <!-- Address-->
                  <label id="billing-address-label" class="nested-column-12 margin-top-15 margin-bottom-15"><span
                    class="required-label-asterisk">* </span>Billing
                    Address</label>
                  <mat-form-field appearance="outline" class="margin-bottom-15 nested-column-12">
                    <input matInput type="text" class="street1-margin billing-address1" id="billing-address1"
                           formControlName="billingAddressStreet1" maxlength="30"/>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="margin-bottom-15 nested-column-12">
                    <input matInput type="text" formControlName="billingAddressStreet2"
                           id="billing-address2" maxlength="30"/>
                  </mat-form-field>

                  <!-- Zip / City / State-->
                  <div fxLayout="row" class="margin-top-15">
                    <label id="billing-city-label" class="nested-column-6"><span
                      class="required-label-asterisk">* </span>City</label>
                    <label id="billing-state-label" class="nested-column-3"><span
                      class="required-label-asterisk">* </span>State</label>
                    <label id="billing-zip-code" class="nested-column-3"><span
                      class="required-label-asterisk">* </span>Zip Code</label>
                  </div>

                  <div fxLayout="row" class="margin-top-15">

                    <mat-form-field appearance="outline" class="margin-bottom-15 nested-column-6">
                      <input matInput type="text"
                             formControlName="billingAddressCity"
                             id="billing-city-input"
                             maxlength="30"/>
                    </mat-form-field>

                    <div class="nested-column-3">
                      <select matSelect formControlName="billingAddressStateCode"
                              class="custom-select margin-bottom-15"
                              id="billing-state-dropdown">
                        <option selected disabled value>Select State</option>
                        <option *ngFor="let stateCode of stateCodes" id="billing-state-{{stateCode}}"
                                [value]="stateCode">{{stateCode}}</option>
                      </select>
                    </div>

                    <mat-form-field appearance="outline" class="margin-bottom-15 nested-column-3">
                      <input formControlName="billingAddressZipCode"
                             matInput type="text"
                             id="billing-zip-code-input"/>
                    </mat-form-field>
                  </div>

                  <!-- Phone Number -->
                  <label id="billing-phone-label" class="nested-column-12 margin-top-15 margin-bottom-15">Phone</label>
                  <mat-form-field appearance="outline" class="margin-bottom-30 nested-column-12">
                    <input formControlName="serviceLocationProviderPhone"
                           matInput type="text" id="billing-phone-input"/>
                  </mat-form-field>

                </mat-card-content>
              </mat-card>
            </div>
          </div>

          <!-- Hide Addresses Link -->
          <div fxLayout="row" fxLayoutAlign="start center" class="margin-top-15 margin-bottom-30">
            <div class="nested-column-12">
              <button mat-flat-button class="mat-flat-button mat-primary" id="facility-hideAddresses" (click)="toggleEditAddressesExpanded()" [disabled]="facilityAndBillingForm.disabled">
                <span>Hide Facility and Billing Addresses</span>
              </button>
            </div>
          </div>
        </div>

      </form>
    </mat-card-content>
  </mat-card>

</div>
