import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ClaimsService} from '../../../../common/services/data-model/app/claims/claims.service';
import {Claim} from '../../../../models/claim';
import {ApplicationConstants} from '../../../../common/constants/application.constants';
import {isStringNullUndefinedOrEmpty} from '../../../../common/utility';

@Component({
  selector: 'app-submit-claim-modal',
  templateUrl: './submit-claim-modal.component.html',
  styleUrls: ['./submit-claim-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubmitClaimModalComponent implements OnInit {

  /***** START - PRIVATE MEMBERS *****/
  private originalClaim: Claim;
  private _vsrNumber: string;
  private _name: string;
  private readonly _labNumber: string;
  private _submitClaimMainMessage: string;
  /***** START - PRIVATE MEMBERS *****/

  constructor(
    private dialogRef: MatDialogRef<SubmitClaimModalComponent>,
    private claimsService: ClaimsService,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this._labNumber = data;
  }

  /***** START - PROPERTY ACCESSORS *****/
  get vsrNumber(): string {
    return this._vsrNumber;
  }

  set vsrNumber(value: string) {
    this._vsrNumber = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get labNumber(): string {
    return this._labNumber;
  }

  get submitClaimMainMessage(): string {
    return this._submitClaimMainMessage;
  }

  set submitClaimMainMessage(value: string) {
    this._submitClaimMainMessage = value;
  }
  /***** END - PROPERTY ACCESSORS *****/

  private partialSubmitClaimMainMessage(): string {
    return 'Your order ' + this.vsrNumber + ' for patient ' + this.name + ' will be sent to ';
  }

  /***** START - LIFECYCLE HOOKS *****/
  ngOnInit() {
    this.originalClaim = this.claimsService.getOriginalClaim();
    if (this.originalClaim) {
      this.vsrNumber = this.originalClaim.trackingNumber;
      this.name = this.originalClaim.patient.name.lastName + ', ' + this.originalClaim.patient.name.firstName;
      if (!isStringNullUndefinedOrEmpty(this.labNumber)) {
        this.submitClaimMainMessage = this.partialSubmitClaimMainMessage() + 'lab ' + this.labNumber + '.';
      } else {
        this.submitClaimMainMessage = this.partialSubmitClaimMainMessage() + 'VSP.';
      }
    }
  }
  /***** END - LIFECYCLE HOOKS *****/

  /***** START - EVENT HANDLERS *****/
  public closeDialog() {
    this.dialogRef.close(ApplicationConstants.modalSelectionOk);
  }
  public onCloseClick(): void {
    this.dialogRef.close(false);
  }
  public cancel() {
    this.dialogRef.close();
  }
  /***** END - EVENT HANDLERS *****/
}
