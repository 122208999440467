<app-component-mask></app-component-mask>
<div id="{{id}}">
  <mat-card class="container-white-full-column">

    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings" class="sticky-card-header" [ngClass]="{ 'disabled-card-header': servicesForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="services-title">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits" [ngClass]="{ 'disabled-card-header': servicesForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="services-title-edits">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning" [ngClass]="{ 'disabled-card-header': servicesForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="services-title-warning">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="servicesForm" fxLayout="column" novalidate>
        <div fxLayout="row" class="margin-top-30 margin-bottom-15">
          <label id="services-pcp-communication-completed-planned-label" class="nested-column-3">PCP Communication Completed/Planned</label>
          <label id="services-known-conditions-label" class="nested-column-8"><span *ngIf="cptHcpcsCodeMatches" class="required-label-asterisk">* </span>Known Conditions</label>
        </div>

        <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------------- --->
        <!-- The Known Conditions section was reworked Per ECLAIM-107 -->
        <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------------- --->
        <div fxLayout="row">
          <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------------- --->
          <!-- This is the first two Columns under the PCP Communication Completed/Planned -->
          <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------------- --->
          <div class="nested-column-3">
            <!-- PCP Communication Completed/Planned Dropdown Column Section -->
            <div fxLayout="row">
              <div class="nested-column-12">
                <select matSelect id="services-pcp-communication-completed-planned-dropdown"  formControlName="pcpCommunicationCompleted"
                        class="custom-select" placeholder="Please Select">
                  <option selected value></option>
                  <option *ngFor="let option of selectOptions.pcpCommunicationOptions"
                          [value]="option">{{option}}</option>
                </select>
              </div>
            </div>
            <!-- View PCP Form Column Section -->
            <div fxLayout="row" class="margin-top-10 margin-bottom-30">
              <div class="nested-column-12" fxLayoutAlign="end center">
                <label id="services-pcp-form-link"
                       class="action-link view-pcp-label"
                       [ngClass]="{ 'disabled-view-pcp-label': servicesForm.disabled }"
                       (keydown.space)="openPCPForm()"
                       (keydown.enter)="openPCPForm()"
                       (click)="openPCPForm()">View PCP Form</label>
              </div>
            </div>
          </div>

          <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------------- --->
          <!-- This is second columns where the known conditions check boxes are -->
          <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------------- --->
          <div class="nested-column-9">
            <!-- First Known Conditions Row -->
            <div fxLayout="row">
                <label class="custom-nested-column-2-no-padding" fxLayoutAlign="start center">Systemic Conditions:</label>
                <mat-checkbox formControlName="prediabetesIndicator" id="known-conditions-preDiabetes-checkbox" class="custom-nested-column-3-no-padding-left" (change)="onKnownConditionChange()"
                              (keyup.enter)="onKeypressKnownConditionsCheckbox(servicesForm.get(servicesConstants.PREDIABETES_INDICATOR))">High Risk for Prediabetes</mat-checkbox>
                <mat-checkbox formControlName="diabetesIndicator" id="known-conditions-diabetes-checkbox" class="custom_nested-column-1_8-no-left-padding" (change)="onKnownConditionChange()"
                              (keyup.enter)="onKeypressKnownConditionsCheckbox(servicesForm.get(servicesConstants.DIABETES_INDICATOR))">Diabetes</mat-checkbox>
                <mat-checkbox formControlName="hypertensionIndicator" id="known-conditions-hypertension-checkbox" class="custom_nested-column-2-no-left-padding" (change)="onKnownConditionChange()"
                              (keyup.enter)="onKeypressKnownConditionsCheckbox(servicesForm.get(servicesConstants.HYPERTENSION_INDICAOTR))">Hypertension</mat-checkbox>
                <mat-checkbox formControlName="highCholesterolIndicator" id="known-conditions-high-cholesterol-checkbox" class="custom-nested-column-3_2-no-left-padding" (change)="onKnownConditionChange()"
                              (keyup.enter)="onKeypressKnownConditionsCheckbox(servicesForm.get(servicesConstants.HIGH_CHOLESTROL_INDICATOR))">High Cholesterol</mat-checkbox>
            </div>
            <!-- Second Known Conditions Row -->
            <div fxLayout="row" class="margin-top-10 margin-bottom-30">
              <label class="custom-nested-column-2-no-padding" fxLayoutAlign="start center">Ocular Conditions:</label>
              <mat-checkbox formControlName="diabeticRetinopathyIndicator" id="known-conditions-diabetic-retinopathy-checkbox" class="custom-nested-column-3-no-padding-left" (change)="onKnownConditionChange()"
                            (keyup.enter)="onKeypressKnownConditionsCheckbox(servicesForm.get(servicesConstants.DIABETIC_RETINOPATHY_INDICATOR))">Diabetic Retinopathy</mat-checkbox>
              <mat-checkbox formControlName="glaucomaIndicator" id="known-conditions-glaucoma-checkbox" class="custom_nested-column-1_8-no-left-padding" (change)="onKnownConditionChange()"
                            (keyup.enter)="onKeypressKnownConditionsCheckbox(servicesForm.get(servicesConstants.GLAUCOMA_INDICATOR))">Glaucoma</mat-checkbox>
              <mat-checkbox formControlName="ageRelatedMacularDegenerationIndicator" id="known-conditions-amd-checkbox" class="custom_nested-column-2-no-left-padding" (change)="onKnownConditionChange()"
                            (keyup.enter)="onKeypressKnownConditionsCheckbox(servicesForm.get(servicesConstants.AGE_RELATED_MACULAR_DEGENERATION_INDICATOR))">AMD</mat-checkbox>
              <mat-checkbox formControlName="patientConditionNone" id="known-conditions-none-checkbox" class="custom-nested-column-3_2-no-left-padding" (change)="onKnownConditionChange()"
                            (keyup.enter)="onKeypressKnownConditionsCheckbox(servicesForm.get(servicesConstants.NONE_INDICATOR))">None of these 7 conditions</mat-checkbox>
            </div>
          </div>
        </div>

        <div fxLayout="row" class="margin-bottom-15">
          <div class="nested-column-6">
            <span class="required-label-asterisk">* </span>
            <span id="services-illness-diagnosis-nature-label">21. Diagnosis or Nature of Illness or Injury (Relate items to diagnosis box 24e.)</span>
          </div>
          <label id="services-show-hcpcs-cpt-description-label" class="action-link nested-column-6" (click)="showHcpcCodesDescriptionsModal()">Show HCPCS/CPT Descriptions</label>
        </div>
        <div formArrayName="diagnosisCodes" fxLayout="row" fxLayoutAlign="space-between" class="diagnosis-codes-parent" fxLayoutAlign="start">
          <div *ngFor="let diagnosis of servicesForm.get(DIAGNOSIS_CODES)['controls']; let diagnosisCodeIndex=index" class="nested-column-3 margin-bottom-15">
            <div [formGroupName]="diagnosisCodeIndex" fxLayout="column">
              <app-diagnosis-code [diagnosisCode]="diagnosis" [index]="diagnosisCodeIndex" class="diagnosis-code"></app-diagnosis-code>
              <app-error-wrapper id="diagnosis-error" class="diagnosis-error" *ngIf="diagnosisCodeIndex == 0" [config]="errorWrapperConfig.noEmptyDiagnosisCodes"></app-error-wrapper>
            </div>
          </div>
        </div>
        <div id="services-add-diagnosis-codes-parent" *ngIf="addDiagnosisCodes" class="margin-top-15 margin-right-15 margin-bottom-15" fxLayout="row" fxLayoutAlign="end center">
          <button mat-flat-button [disabled]="servicesForm.disabled" (click)="addMoreDiagnosisCodes()" class="mat-flat-button mat-primary" id="services-add-diagnosis-codes-button">
            <span>Add Diagnosis Rows</span>
          </button>
          <!-- TODO ECR-6480: Temporarily hidden until ready -->
          <!-- <div fxFlex id="services-show-hcpc-description-link" class="action-link fsa-explanation-wrapper" (click)="showHcpcDescriptionModal($event)">Show HCPCS/CPT Descriptions</div> -->
        </div>
        <!-- Procedures -->
        <div class="nested-column-12 not-cob-claim margin-top-15" fxLayout="row" fxLayoutAlign="end" fxFill>
          <label id="services-service-line-from-label" fxFlex={{serviceLineHtmlConstants.serviceDateWidth}} fxLayout="column" fxLayoutAlign="end start"><span >24a.</span><span>From</span></label>
          <label id="services-service-line-to-label" fxFlex={{serviceLineHtmlConstants.serviceDateWidth}} fxLayout="column" fxLayoutAlign="end start">To</label>
          <label id="services-service-line-pos-label" fxFlex={{serviceLineHtmlConstants.placeOfServiceWidth}} fxLayout="column" fxLayoutAlign="end start"><span >24b.</span><span>POS</span></label>
          <label id="services-service-line-emg-label" fxFlex={{serviceLineHtmlConstants.checkboxWidth}} fxLayout="column" fxLayoutAlign="end start"><span >24c.</span><span>EMG</span></label>
          <label id="services-service-line-proc-label" fxFlex="{{serviceLineHtmlConstants.cptHcpcsCodeWidth}}" fxLayout="column">
            <!-- PROC label/link - opens the HCPC Codes Modal when clicked -->
            <span><mat-icon
              id="services-service-line-proc-link"
              class="help-circle"
              [ngClass]="{ 'disabled-help-circle': servicesForm.disabled }"
              (keydown.space)="showHcpcCodesModal()"
              (keydown.enter)="showHcpcCodesModal()"
              (click)="showHcpcCodesModal()"
              svgIcon="eclaim-icons:helpCircle">
              </mat-icon>
              24d.</span>
            <span>PROC</span>
          </label>
          <label id="services-service-line-mods-label" fxFlex={{serviceLineHtmlConstants.modifierCodesWidth}} fxLayout="column" fxLayoutAlign="end start">Mods</label>
          <label id="services-service-line-diagnosis-label" fxFlex={{serviceLineHtmlConstants.diagnosisPointersWidth}} fxLayout="column" fxLayoutAlign="end start"><span >24e.</span><span>Diagnosis</span></label>
          <label id="services-service-line-charges-label" fxFlex={{serviceLineHtmlConstants.billedAmountWidth}} fxLayout="column" fxLayoutAlign="end start"><span >24f.</span><span>Charges</span></label>
          <label id="services-service-line-units-label" fxFlex="{{serviceLineHtmlConstants.unitCountWidth}}" fxLayout="column" fxLayoutAlign="end start"><span >24g.</span><span>Units</span></label>
          <label id="services-service-line-epsdt-label" fxFlex={{serviceLineHtmlConstants.checkboxWidth}} fxLayout="column" fxLayoutAlign="end start"><span >24h.</span><span>EPSDT</span></label>
          <ng-container *ngIf="isCobClaim">
            <label id="services-service-line-other-insured-allowed-label" fxFlex={{serviceLineHtmlConstants.cobInputWidth}} fxLayout="column" fxLayoutAlign="end start">
              <span>
                <mat-icon
                  id="services-service-line-other-insured-allowed-link"
                  class="help-circle"
                  [ngClass]="{ 'disabled-help-circle': servicesForm.disabled }"
                  (keydown.space)="onCobFieldLinkClicked(OTHER_INSURANCE_ALLOWED_AMOUNT)"
                  (keydown.enter)="onCobFieldLinkClicked(OTHER_INSURANCE_ALLOWED_AMOUNT)"
                  (click)="onCobFieldLinkClicked(OTHER_INSURANCE_ALLOWED_AMOUNT)"
                  svgIcon="eclaim-icons:helpCircle">
                </mat-icon>Other Ins</span>
              <span>&nbsp;&nbsp;&nbsp;Allowed</span>
            </label>
            <label id="services-service-line-other-insured-paid-label" fxFlex={{serviceLineHtmlConstants.cobInputWidth}} fxLayout="column" fxLayoutAlign="end start">
              <span>
                <mat-icon
                  id="services-service-line-other-insured-paid-link"
                  class="help-circle"
                  [ngClass]="{ 'disabled-help-circle': servicesForm.disabled }"
                  (keydown.space)="onCobFieldLinkClicked(OTHER_INSURANCE_PAID_AMOUNT)"
                  (keydown.enter)="onCobFieldLinkClicked(OTHER_INSURANCE_PAID_AMOUNT)"
                  (click)="onCobFieldLinkClicked(OTHER_INSURANCE_PAID_AMOUNT)"
                  svgIcon="eclaim-icons:helpCircle">
                </mat-icon>Other</span>
              <span>Ins Paid</span>
            </label>
            <label id="services-service-line-other-insured-patient-responsibility" fxFlex={{serviceLineHtmlConstants.cobInputWidth}} fxLayout="column" fxLayoutAlign="end start">
              <span>
                <mat-icon
                  id="services-service-line-other-insured-patient-responsibility-link"
                  class="help-circle"
                  [ngClass]="{ 'disabled-help-circle': servicesForm.disabled }"
                  (keydown.space)="onCobFieldLinkClicked(OTHER_INSURANCE_PATIENT_PAID_AMOUNT)"
                  (keydown.enter)="onCobFieldLinkClicked(OTHER_INSURANCE_PATIENT_PAID_AMOUNT)"
                  (click)="onCobFieldLinkClicked(OTHER_INSURANCE_PATIENT_PAID_AMOUNT)"
                  svgIcon="eclaim-icons:helpCircle">
                </mat-icon>Other</span>
              <span>Ins Pat Resp</span>
            </label>
            <label id="services-service-line-denied-or-paid" fxFlex={{serviceLineHtmlConstants.deniedOrPaidReasonWidth}} fxLayout="column" fxLayoutAlign="end start">
              <span>
                <mat-icon
                  id="services-service-line-denied-or-paid-link"
                  class="help-circle"
                  [ngClass]="{ 'disabled-help-circle': servicesForm.disabled }"
                  (keydown.space)="onCobFieldLinkClicked(OTHER_INSURANCE_DENIED_NOT_COVERED_REASON)"
                  (keydown.enter)="onCobFieldLinkClicked(OTHER_INSURANCE_DENIED_NOT_COVERED_REASON)"
                  (click)="onCobFieldLinkClicked(OTHER_INSURANCE_DENIED_NOT_COVERED_REASON)"
                  svgIcon="eclaim-icons:helpCircle">
                </mat-icon>Denied or</span>
              <span>Paid $0.00 Reason</span>
            </label>
          </ng-container>
        </div>
        <div formArrayName="serviceLines" fxLayout="row"
             *ngFor="let serviceLine of servicesForm.get(SERVICE_LINES)['controls']; let serviceLineIndex = index; odd as isOdd;">
          <div [formGroupName]="serviceLineIndex" fxLayout="row" fxLayoutAlign="center" [ngClass]="{'service-line-parent': isOdd === true}" class="nested-column-12" fxFill>
            <app-service-line
              fxLayout="row"
              fxFill
              [serviceLine]="serviceLine"
              [servicesErrorWrapperConfig]="errorWrapperConfig"
              [index]="serviceLineIndex"
              [serviceLineHtmlConstants]="serviceLineHtmlConstants"
              [isCobClaim]="isCobClaim"
              [detectChanges]="detectChangesForServiceLines"
              (lastSelectedProcServiceLineIndex)="onProcServiceLineIndexUpdated($event)">
            </app-service-line>
          </div>
        </div>

        <!--Add Procedures Button-->
        <div *ngIf="allowAddServiceLines()" class="margin-top-30 margin-right-15" fxLayout="row" fxLayoutAlign="end center">
          <button mat-flat-button
                  class="mat-flat-button mat-primary"
                  id="services-add-procedures-button"
                  [disabled]="servicesForm.disabled"
                  (click)="addMoreServiceLines()">
            <span>Add Procedures</span>
          </button>
        </div>

        <!------------------Totals fields--------------->
        <div fxLayout="row" fxLayoutAlign="end start" class="margin-top-30 margin-bottom-30">
          <!-- FSA Paid -->
          <div *ngIf="fsaIndicator" class="nested-column-3">
            <div fxLayout="column">
              <label class="totals-label" fxLayoutAlign="end center">
              <span id="services-fsa-paid-label" fxLayoutAlign="center"><span class="required-label-asterisk">* </span>
                FSA Paid
                <mat-icon
                  id="services-fsa-paid-link"
                  class="padding-right-5 help-circle"
                  [ngClass]="{ 'disabled-help-circle': servicesForm.disabled }"
                  (click)="openFsaExplanationModal()"
                  (keydown.shift.tab)="onFsaPaidAmountReverseTabNavigation()"
                  (keydown.space)="openFsaExplanationModal()"
                  (keydown.enter)="openFsaExplanationModal()"
                  svgIcon="eclaim-icons:helpCircle">
                </mat-icon>
              </span>
                <mat-form-field fxFlex="56" appearance="outline" class="required-mat-form-field">
                  <span fxLayoutAlign="center center" class="dollar-sign">$</span>
                  <input matInput
                         fxFlex="100"
                         formControlName="fsaPaid"
                         class="charges-alignment"
                         id="services-fsa-paid-input"
                         #fsaPaidInput
                         maxlength="8"
                         type="text"
                         [value]="precedingZeroForDecimalPipe(servicesForm.get(FSA_PAID))"
                         (blur)="twoDecimalPlaceFormat(servicesForm.get(FSA_PAID))"/>
                </mat-form-field>
              </label>
              <app-error-wrapper id="services-fsa-paid-required-error" [config]="errorWrapperConfig.fsaPaid" fxLayoutAlign="end center" ></app-error-wrapper>
              <app-error-wrapper id="services-fsa-paid-amount-max-value-error" [hidden]="servicesForm.get(FSA_PAID).hasError(ERROR_TYPES.Required) || servicesForm.get(FSA_PAID).hasError(ERROR_TYPES.DollarAmount)" [config]="errorWrapperConfig.fsaPaidMaxAmount" fxLayoutAlign="end center" ></app-error-wrapper>
            </div>
          </div>

          <!-- Total -->
          <label id="services-total-amount-label" class="nested-column-3 totals-label" fxLayoutAlign="end center">
            <span class="padding-right-5" fxLayoutAlign="center">28. Total</span>
            <mat-form-field appearance="outline" fxFlex="56">
              <span fxLayoutAlign="center center" class="dollar-sign">$</span>
              <input matInput id="services-total-amount-input"
                     type="text"
                     fxFlex="100"
                     formControlName="totalAmount"
                     class="charges-alignment"
                     [value]="precedingZeroForDecimalPipe(servicesForm.get(TOTAL_AMOUNT))"/>
            </mat-form-field>
          </label>

          <!-- Paid -->
          <div class="nested-column-3">
            <div fxLayout="column">
              <label class="totals-label" fxLayoutAlign="end center">
                <span class="required-label-asterisk" fxLayoutAlign="center">* </span>
                <span id="services-patient-paid-amount-label" class="padding-right-5" fxLayoutAlign="center">29. Paid </span>
                <mat-form-field fxFlex="56" appearance="outline" class="required-mat-form-field">
                  <span fxLayoutAlign="center center" class="dollar-sign">$</span>
                  <input matInput
                         fxFlex="100"
                         formControlName="patientPaymentAmount"
                         class="charges-alignment"
                         [id]="serviceLineHtmlAttributes.patientPaymentAmountId"
                         #amountPaidControl
                         maxlength="8"
                         type="text"
                         (keydown.shift.tab)="onPaidAmountReverseTabNavigation()"
                         (blur)="onPaidAmountBlur()"
                         [value]="precedingZeroForDecimalPipe(servicesForm.get(PATIENT_PAYMENT_AMOUNT))"/>
                </mat-form-field>
              </label>
              <app-error-wrapper id="services-patient-paid-amount-value-error" [config]="errorWrapperConfig.patientPaymentAmount" fxLayoutAlign="end center"></app-error-wrapper>
              <app-error-wrapper id="services-patient-paid-amount-max-value-error" [hidden]="servicesForm.get(PATIENT_PAYMENT_AMOUNT).hasError(PATIENT_PAYMENT_AMOUNT)" [config]="errorWrapperConfig.patientPaymentMaxAmount" fxLayoutAlign="end center"></app-error-wrapper>
            </div>
          </div>

          <!-- Due -->
          <label id="services-total-due-label" class="nested-column-3 totals-label" fxLayoutAlign="end center">
            <span class="padding-right-5" fxLayoutAlign="center">Due</span>
            <mat-form-field appearance="outline" fxFlex="56">
              <span fxLayoutAlign="center center" class="dollar-sign">$</span>
              <input matInput id="services-total-due-input"
                     fxFlex="100"
                     formControlName="totalDue"
                     class="charges-alignment"
                     [value]="precedingZeroForDecimalPipe(servicesForm.get(TOTAL_DUE))"/>
            </mat-form-field>
          </label>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
