<app-component-mask></app-component-mask>
<!-- TODO uncomment all app-error-wrappers when mask is disabled -->

<!-- Show/Hide CMS Numbers -->
<div cms-number-toggle id="exam-cmsNumberToggle"></div>

<div id="{{id}}">
  <mat-card class="container-white-full-column">

    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings" class="sticky-card-header" [ngClass]="{ 'disabled-card-header': lensForm.disabled }">
      <mat-card-title>
        <h1 id="lens-title">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits" [ngClass]="{ 'disabled-card-header': lensForm.disabled }">
      <mat-card-title>
        <h1 id="lens-title-edits">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning" [ngClass]="{ 'disabled-card-header': lensForm.disabled }">
      <mat-card-title>
        <h1 id="lens-title-warning">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Main Form -->
    <mat-card-content>
      <form fxLayout="column" class="claim-form-child-form" [formGroup]="lensForm" novalidate>

        <div fxLayout="row" class="margin-top-30 margin-bottom-15">
          <!-- Finishing -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-finishing-label">Finishing</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="finishing"
                    id="lens-finishing-dropdown"
                    class="custom-select"
                    (change)="onFinishingChange()">
              <option id="lens-finishing-option-{{toSlugId(finishingType.label)}}" *ngFor="let finishingType of selectOptions.finishing"
                      [value]="finishingType.value">{{finishingType.label}}</option>
            </select>
          </div>
          <!-- Vision Type -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-vision-type-label">Vision Type</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="visionType"
                    id="lens-vision-dropdown"
                    class="custom-select"
                    (change)="onVisionTypeChange()">
              <option selected value></option>
              <option *ngFor="let lensVisionType of lensVisionTypes"
                      [value]="lensVisionType.externalId">{{lensVisionType.name}}</option>
            </select>
          </div>
          <!-- Material -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-material-label">
              <span class="required-label-asterisk" *ngIf="!isEmpty(lensForm.controls['visionType'].value)">* </span>Material
            </label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="material"
                    id="lens-material-dropdown"
                    class="custom-select"
                    [ngClass]="{'required-form-field' : !isEmpty(lensForm.controls['visionType'].value)}"
                    (change)="onMaterialChange()">
              <option selected value></option>
              <option *ngFor="let lensMaterialType of lensMaterialTypes"
                      [value]="lensMaterialType.externalId">{{lensMaterialType.name}}</option>
            </select>
            <app-error-wrapper id="lens-material-dropdown-error" [config]="errorWrapperConfig.material"></app-error-wrapper>
          </div>
        </div>
          <!-- Filters - All Lenses, Photochromics, Polarized, Blue Filter, Mirrors, Clear/Other  -->
          <div fxLayout="row" class="margin-top-15 margin-bottom-15">
              <div fxLayout="column" class="nested-column-12">
                  <div fxLayout="row">
                      <label id="lens-filters-label" class="eclaim-body-text-bold">Filters</label>
                      <div class="margin-right-15"></div>
                      <mat-checkbox formControlName="allLensesIndicator" [id]="allLensesCheckboxId" class="nested-column-2 margin-right-5 checkbox-alignment" [(ngModel)]="isAllLensesChecked" (change)="onFiltersCheckBoxChange('allLensesIndicator')"
                                    (keyup.enter)="onKeypressEventCheckbox( lensForm.get(lensConstants.ALL_LENSES_INDICATOR)); onFiltersCheckBoxChange('allLensesIndicator')" >All Lenses</mat-checkbox>
                      <mat-checkbox formControlName="photochromicsIndicator" [id]="photochromicsCheckboxId" class="nested-column-2 checkbox-alignment" [(ngModel)]="isPhotochromicChecked" (change)="onFiltersCheckBoxChange('photochromicsIndicator')"
                                    (keyup.enter)="onKeypressEventCheckbox( lensForm.get(lensConstants.PHOTOCHROMICS_INDICATOR)); onFiltersCheckBoxChange('photochromicsIndicator')">Photochromics</mat-checkbox>
                      <mat-checkbox formControlName="polarizedIndicator" [id]="polarizedCheckboxId" class="nested-column-2 margin-right-5 checkbox-alignment" [(ngModel)]="isPolarizedChecked"  (change)="onFiltersCheckBoxChange('polarizedIndicator')"
                                    (keyup.enter)="onKeypressEventCheckbox( lensForm.get(lensConstants.POLARIZED_INDICATOR)); onFiltersCheckBoxChange('polarizedIndicator')">Polarized</mat-checkbox>
                      <mat-checkbox formControlName="blueFilterIndicator" [id]="blueFilterCheckboxId" class="nested-column-2 margin-right-5 checkbox-alignment" [(ngModel)]="isBlueFilterChecked"  (change)="onFiltersCheckBoxChange('blueFilterIndicator')"
                                    (keyup.enter)="onKeypressEventCheckbox( lensForm.get(lensConstants.BLUE_FILTER_INDICATOR)); onFiltersCheckBoxChange('blueFilterIndicator')">Blue Filter</mat-checkbox>
                      <mat-checkbox formControlName="mirrorsIndicator" [id]="mirrorsCheckboxId" class="nested-column-2 checkbox-alignment" [(ngModel)]="isMirrorChecked"  (change)="onFiltersCheckBoxChange('mirrorsIndicator')"
                                    (keyup.enter)="onKeypressEventCheckbox( lensForm.get(lensConstants.BLUE_FILTER_INDICATOR)); onFiltersCheckBoxChange('mirrorsIndicator')">Mirrors</mat-checkbox>
                      <mat-checkbox formControlName="clearOtherIndicator" [id]="clearOtherCheckboxId" class="nested-column-2 margin-right-5 checkbox-alignment" [(ngModel)]="isClearOtherChecked"  (change)="onFiltersCheckBoxChange('clearOtherIndicator')"
                                    (keyup.enter)="onKeypressEventCheckbox( lensForm.get(lensConstants.CLEAR_OTHER_INDICATOR)); onFiltersCheckBoxChange('clearOtherIndicator')">Clear/Other</mat-checkbox>
                  </div>
              </div>
          </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-15">
          <!-- Lens using ng-select so that you can filter down the options as you type into the dropdown -->
          <div fxLayout="column" class="nested-column-8">
            <label id="lens-lens-label">
              <span class="required-label-asterisk" *ngIf="!isEmpty(lensForm.controls['material'].value)">* </span>Lens
            </label>
            <ng-select [items]="lensTypes"
                       #lensDropdown
                       fxFlexOffset="15px"
                       bindValue="externalId"
                       bindLabel="productDescription"
                       placeholder=""
                       formControlName="lens"
                       id="lens-lens-dropdown"
                       [ngClass]="{'required-form-field' : !isEmpty(lensForm.controls['material'].value)}"
                       (change)="onLensChange()"></ng-select>
            <app-loading-dropdown *ngIf="loadingLens" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="325" ></app-loading-dropdown>
            <app-error-wrapper id="lens-error" [config]="errorWrapperConfig.lens"></app-error-wrapper>
          </div>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-15">
          <!-- One Lens -->
          <div fxLayout="column" class="nested-column-4">
            <label id="one-lens-label">One Lens</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="oneLens"
                    id="lens-one-lens-dropdown"
                    class="custom-select"
                    placeholder="" (change)="onOneLensChange()">
              <option selected value></option>
              <option *ngFor="let lens of selectOptions.oneLens"
                      [value]="lens.value">{{lens.label}}</option>
            </select>
          </div>
          <!-- Right Base Curve -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-right-base-curve-label">Right Base Curve</label>
            <mat-form-field appearance="outline" fxFlexOffset="15px">
              <input matInput
                   type="text"
                   id="lens-right-base-curve-textbox"
                   formControlName="rightBaseCurve"
                   autocomplete="off"
                   [maxLength]="rightLeftBaseCurveFieldsAndCustomizableLensFieldsMaxLength"/>
            </mat-form-field>
            <app-error-wrapper id="lens-right-base-curve-textbox-error-msg" [config]="errorWrapperConfig.rightBaseCurve"></app-error-wrapper>
          </div>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-15">
          <!-- Balance Lens-->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-balance-lens-label">Balance Lens</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="balanceLens"
                    id="lens-balance-lens-dropdown"
                    class="custom-select"
                    placeholder=""
                    (change)="onBalanceLensChange()">
              <option selected value></option>
              <option *ngFor="let lens of selectOptions.balanceLens"
                      [value]="lens.value">{{lens.label}}</option>
            </select>
          </div>
          <!-- Left Base Curve -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-left-base-curve-label">Left Base Curve</label>
            <mat-form-field appearance="outline" fxFlexOffset="15px">
              <input matInput
                   type="text"
                   id="lens-left-base-curve-textbox"
                   formControlName="leftBaseCurve"
                   autocomplete="off"
                   [maxLength]="rightLeftBaseCurveFieldsAndCustomizableLensFieldsMaxLength"/>
            </mat-form-field>
            <app-error-wrapper id="lens-left-base-curve-textbox-error-msg" [config]="errorWrapperConfig.leftBaseCurve"></app-error-wrapper>
          </div>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-15">
          <!-- Bevel -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-bevel-label">Bevel</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="bevel"
                    id="lens-bevel-dropdown"
                    class="custom-select"
                    placeholder=""
                    (click)="onBevelClick()">
              <option selected [ngValue]="undefined"></option>
              <option *ngFor="let bevel of bevels"
                      [ngValue]="bevel">{{bevel.labServiceDescription}}</option>
            </select>
            <app-loading-dropdown *ngIf="loadingBevels" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
          </div>
          <!-- Edge -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-edge-label">Edge</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="edge"
                    class="custom-select"
                    placeholder=""
                    (click)="onEdgeCoatingClick()"
                    id="lens-edge-dropdown">
              <option selected [ngValue]="undefined"></option>
              <option *ngFor="let edgeCoating of edgeCoatings"
                      [ngValue]="edgeCoating">{{edgeCoating.labServiceDescription}}</option>
            </select>
            <app-loading-dropdown *ngIf="loadingEdgeCoatings" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
          </div>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-15">
          <!-- Dye Color -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-dye-color-label">Dye Color</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="dyeColor"
                    id="lens-dye-color-dropdown"
                    class="custom-select"
                    (change)="onTintColorChange()"
                    (click)="onTintColorClick()"
                    placeholder="">
              <option selected [ngValue]="undefined"></option>
              <option *ngFor="let tintColor of tintColors"
                      [ngValue]="tintColor">{{tintColor.labServiceDescription}}</option>
            </select>
            <app-loading-dropdown *ngIf="loadingTintColors" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
          </div>
          <!-- Dye Not Listed -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-dye-not-listed-label">Dye Not Listed</label>
            <mat-form-field appearance="outline" fxFlexOffset="15px" disabled>
              <input matInput
                     type="text"
                     id="lens-dye-not-listed-textbox"
                     formControlName="dyeColorOther"
                     autocomplete="off" />
            </mat-form-field>
            <app-error-wrapper id="lens-dye-not-listed-error" [config]="errorWrapperConfig.dyeColorOther"></app-error-wrapper>
          </div>
          <!-- Dye Type -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-dye-type-label">Dye Type</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="dyeType"
                    id="lens-dye-type-dropdown"
                    class="custom-select"
                    (click)="onDyeTypeClick()"
                    placeholder="">
              <option selected [ngValue]="undefined"></option>
              <option *ngFor="let dyeType of dyeTypes"
                      [ngValue]="dyeType">{{dyeType.labServiceDescription}}</option>
            </select>
            <app-loading-dropdown *ngIf="loadingDyeTypes" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
          </div>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-15">
          <!-- Dye Details -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-dye-details-label">Dye Details</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="dyeDetails"
                    id="lens-dye-details-dropdown"
                    class="custom-select"
                    (change)="onDyeDetailsChange()"
                    placeholder="">
              <option selected [ngValue]="undefined"></option>
              <option *ngFor="let dyeDetail of selectOptions.dyeDetails"
                      [ngValue]="dyeDetail">{{dyeDetail}}</option>
            </select>
          </div>
          <!-- Dye Lighten/Darken % -->
          <div fxLayout="column" class="nested-column-4">
            <!--<app-error-wrapper [config]="errorWrapperConfig.dyeLightenDarkenPercent"></app-error-wrapper>-->
            <label id="lens-lighten-dark-percent-label" class="text-nowrap">Lighten/Darken %</label>
            <mat-form-field appearance="outline" fxFlexOffset="15px">
              <input matInput
                #dyeLightenDarkenPercentControl
                type="text"
                id="lens-lighten-darken-textbox"
                formControlName="dyeLightenDarkenPercent"
                autocomplete="off"/>
            </mat-form-field>
            <app-error-wrapper id="lens-lighten-darken-percent-error-msg" [config]="errorWrapperConfig.dyeLightenDarkenPercent"></app-error-wrapper>
          </div>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-15">
          <!-- AR Coating -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-AR-coating-label">A/R Coating</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="arCoating"
                    id="lens-AR-coating-dropdown"
                    class="custom-select"
                    (change)="onARCoatingChange()"
                    (click)="onARCoatingClick()"
                    placeholder="" style="margin-top: 5px">
              <option selected [ngValue]="undefined"></option>
              <optgroup *ngFor="let arCategory of arCoatingsCategories" label="{{arCategory.arCoatingCategoryLabel}}">
                <option *ngFor="let arCoating of arCategory.arCoatings"
                        [ngValue]="arCoating">{{arCoating.brandDescription}}</option>
                <option disabled value></option>
              </optgroup>
            </select>
            <app-loading-dropdown *ngIf="loadingARCoatings" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
          </div>
          <!-- UV Coating -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-UV-label">UV</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="uvCoating"
                    id="lens-UV-dropdown"
                    class="custom-select"
                    (click)="onUVCoatingClick()"
                    placeholder="">
              <option selected [ngValue]="undefined"></option>
              <option *ngFor="let uvCoating of uvCoatings"
                      [ngValue]="uvCoating">{{uvCoating.labServiceDescription}}</option>
            </select>
            <app-loading-dropdown *ngIf="loadingUVCoatings" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
          </div>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-15">
          <!-- Scratch Coating -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-scratch-coating-label">Scratch Coating</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="scratchCoating"
                    id="lens-scratch-coating-dropdown"
                    class="custom-select"
                    placeholder=""
                    (change)="onScratchCoatingChange()" (click)="onScratchCoatingClick()" >
              <option selected [ngValue]="undefined"></option>
              <option *ngFor="let scratchCoating of scratchCoatings"
                      [ngValue]="scratchCoating">{{scratchCoating.brandDescription}}</option>
            </select>
            <app-loading-dropdown *ngIf="loadingScratchCoatings" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
          </div>
          <!-- Press-on Prism -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-press-on-prism-label">Press-On Prism</label>
            <mat-form-field appearance="outline" fxFlexOffset="15px">
              <input matInput
                     type="text"
                     id="lens-press-on-prism-textbox"
                     class="form-control full-width"
                     formControlName="pressOnPrismValue"
                     autocomplete="off"
                     [maxLength]= "maxLensCardDescriptionLength"
                     (keypress)="fieldKeyPress($event, meta.regexp.lensString)"
                     (paste)="fieldPaste($event, lensForm.controls.pressOnPrismValue, meta.formControlMaxLength.pressOnPrismValue, meta.regexp.lensChar)"
                     (drop)="ignoreDrop($event)"/>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-15">
          <!-- Glass Coating -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-glass-coating-label">Glass Coating</label>
            <select matSelect
                    fxFlexOffset="15px"
                    formControlName="glassCoating"
                    class="custom-select form-control full-width"
                    id="lens-glass-coating-dropdown"
                    placeholder=""
                    (click)="onGlassCoatingClick()">
              <option selected [ngValue]="undefined"></option>
              <option *ngFor="let glassCoating of glassCoatings"
                      [ngValue]="glassCoating">{{glassCoating.labServiceDescription}}</option>
            </select>
            <app-loading-dropdown *ngIf="loadingGlassCoatings" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
          </div>
          <!-- Nose Pads -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-nose-pads-label">Nose Pads</label>
            <mat-form-field appearance="outline" fxFlexOffset="15px">
              <input matInput
                     type="text"
                     id="lens-nose-pads-textbox"
                     formControlName="nosePadsValue"
                     autocomplete="off"
                     [maxLength]= "maxLensCardDescriptionLength"
                     (keypress)="fieldKeyPress($event, meta.regexp.lensString)"
                     (paste)="fieldPaste($event, lensForm.controls.nosePadsValue, meta.formControlMaxLength.nosePadsValue, meta.regexp.lensChar)"
                     (drop)="ignoreDrop($event)"/>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-15">
          <!--Glass Coating Description-->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-glass-coating-description-label">Glass Coating Description</label>
            <mat-form-field appearance="outline" fxFlexOffset="15px">
              <input matInput
                   type="text"
                   id="lens-glass-coating-description-textbox"
                   formControlName="glassCoatingDescription"
                   autocomplete="off" [maxLength]="maxGlassCoatingDescriptionLength" />
            </mat-form-field>
          </div>
          <!-- Slab Off -->
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-slab-off-label">Slab Off</label>
            <mat-form-field appearance="outline" fxFlexOffset="15px">
              <input matInput
                     type="text"
                     id="lens-slab-off-textbox"
                     formControlName="slabOffValue"
                     autocomplete="off"
                     [maxLength]= "maxLensCardDescriptionLength"
                     (keypress)="fieldKeyPress($event, meta.regexp.lensString)"
                     (paste)="fieldPaste($event, lensForm.controls.slabOffValue, meta.formControlMaxLength.slabOffValue, meta.regexp.lensChar)"
                     (drop)="ignoreDrop($event)"/>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" class="margin-top-15 margin-bottom-30">
          <div fxLayout="column" class="nested-column-4">
            <label id="lens-mirror-ski-coat-label">Mirror/Ski Coat</label>
            <select matSelect
              fxFlexOffset="15px"
              fxLayout="row"
              fxLayoutAlign="center center"
              id="lens-mirror-ski-coating-dropdown"
              formControlName="mirrorSkiCoating"
              class="custom-select form-control full-width"
              (click)="onMirrorSkiCoatingClick()">
              <option selected [ngValue]="undefined"></option>
              <option
                *ngFor="let mirrorSkiCoating of mirrorSkiCoatings"
                id="lens-mirror-ski-coating-value-{{ toSlugId(mirrorSkiCoating.labServiceDescription) }}"
                [ngValue]="mirrorSkiCoating">{{ mirrorSkiCoating.labServiceDescription }}</option>
              <optgroup *ngFor="let mirrorSkiCoatingCategory of mirrorSkiCoatingsCategories" label="{{mirrorSkiCoatingCategory.mirrorSkiCoatingCategoryLabel}}">
                <option *ngFor="let mirrorSkiCoating of mirrorSkiCoatingCategory.mirrorSkiCoatings"
                        [ngValue]="mirrorSkiCoating">{{mirrorSkiCoating.labServiceDescription}}</option>
                <option disabled value></option>
              </optgroup>
              </select>
            <app-loading-dropdown *ngIf="loadingMirrorSkiCoatings" [topOffset]="-30" [rightOffset]="0" [bottomOffset]="10"  [leftOffset]="145" ></app-loading-dropdown>
          </div>
        </div>

        <div fxLayout="row" [ngClass]="{'margin-bottom-30' : isOtherMirrorSkiCoating}">
          <div *ngIf="isOtherMirrorSkiCoating" fxLayout="column" class="nested-column-4">
            <label id="lens-mirror-ski-coating-description-label">
              <span class="required-label-asterisk">* </span>Mirror/Ski Coat Description
            </label>
            <mat-form-field appearance="outline" fxFlexOffset="15px" class="required-mat-form-field">
              <input matInput
                id="lens-mirror-ski-coating-other-description"
                formControlName="mirrorSkiCoatingOtherDescription"
                maxlength="30">
            </mat-form-field>
            <app-error-wrapper id="lens-mirror-ski-coating-other-description-error" [config]="errorWrapperConfig.mirrorSkiCoatingOtherDescription"></app-error-wrapper>
          </div>
        </div>

      </form>
    </mat-card-content>

  </mat-card>

</div>
