import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Subscription } from 'rxjs';
import {ClaimsService} from '../../common/services/data-model/app/claims/claims.service';
import {ClaimFormNavigationService} from '../../common/services/support/claim-form-navigation/claim-form-navigation.service';
import {ViewStateService} from '../../common/services/view-state/view-state.service';
import {
  isStringNullUndefinedOrEmpty,
  openDialog
} from '../../common/utility';
import {ApplicationConstants } from '../../common/constants/application.constants';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-claim-form',
  templateUrl: './claim-form.component.html',
  styleUrls: ['./claim-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClaimFormComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private claimService: ClaimsService,
    private claimFormNavigationService: ClaimFormNavigationService,
    private viewStateService: ViewStateService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
  private route: ActivatedRoute
  ) {
  }


  /***** START - PRIVATE MEMBERS *****/
  private observableSubscriptions: Subscription[] = [];
  /***** END - PRIVATE MEMBERS *****/


  /***** START - PUBLIC MEMBERS *****/
  claimLoaded = false;
  loadingSpinnerValue: string;

  @ViewChild('claimFormContainer', {read: ElementRef, static: false}) claimFormContainer: ElementRef;

  /***** END - PUBLIC MEMBERS *****/

  /***** START - PRIVATE METHODS *****/
  ngOnInit() {
    // Simple message.
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog(this.loadingSpinnerValue = 'Loading Claim Details...', this.dialog);

    // Retrieving the query parameters from the URL
    let authorizationNumber: string;
    let claimAlreadyLoadedParam: string;
    this.route.queryParamMap.subscribe(queryParam => {
      authorizationNumber = queryParam.get('authorizationNumber');
      claimAlreadyLoadedParam = queryParam.get('claimAlreadyLoaded');
    });
    const claimAlreadyLoaded = claimAlreadyLoadedParam && claimAlreadyLoadedParam.toLowerCase() === 'true' ? true : false;

    // Checks to see if an auth was passed, if not navigate back to eInsurance page
    if (isStringNullUndefinedOrEmpty(authorizationNumber)) {
      this.dialog.closeAll();
      this.router.navigate([ApplicationConstants.routing.secure.eInsurancePageUrl]);
    } else {

      // Call API again if refresh button is pressed on browser and active Claim is not populated in Claim Service.
      if (!this.claimFormNavigationService.claimFormLoaded) {
        this.observableSubscriptions.push(this.claimFormNavigationService.searchAndRetrieveOrCreatePatientEncounterThenLoadDoctorsThenNavigateToClaimForm(authorizationNumber).subscribe(() => {

          // Next closure only need to execute logic with the observable of the claim form navigation service
        }, () => {
          // error
        }, () => {
          // Close dialog for LoadingModalComponent - Spinner
          this.dialog.closeAll();
          this.changeDetectorRef.detectChanges();
          if (this.claimFormNavigationService.claimFormLoaded) {
            this.claimLoaded = true;
            this.changeDetectorRef.detectChanges();
            this.observeStickyHeaderChanges(this.claimFormContainer.nativeElement);
          } else {
            this.router.navigate([ApplicationConstants.routing.secure.eInsurancePageUrl]);
          }
        }));
      } else {
        this.claimLoaded = true;
        // Close dialog for LoadingModalComponent - Spinner
        this.dialog.closeAll();
        this.changeDetectorRef.detectChanges();
        this.observeStickyHeaderChanges(this.claimFormContainer.nativeElement);
      }
      this.viewStateService.setCalculateFailed(false);
      this.viewStateService.setSubmitFailed(false);
      this.viewStateService.setSaveFromLeaveClaimFormWarningModalWasSuccessfulState(undefined);
    }
  }

  ngOnDestroy() {
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  observeStickyHeaderChanges(container) {
    this.observeHeaders(container);
    this.observeFooters(container);
  }

  /**
   * Sets up an intersection observer to notify when elements with the class
   * `.sticky_sentinel--top` become visible/invisible at the top of the container.
   * @param {!Element} container
   */
  observeHeaders(container) {
    const observer = new IntersectionObserver((records, observer) => {
      for (const record of records) {
        const targetInfo = record.boundingClientRect;
        const stickyTarget = record.target.parentElement.querySelector('.sticky-card-header');
        const rootBoundsInfo = record.rootBounds;

        // Started sticking.
        if (targetInfo.bottom < rootBoundsInfo.top) {
          this.fireEvent(true, stickyTarget);
        }

        // Stopped sticking.
        if (targetInfo.bottom >= rootBoundsInfo.top &&
          targetInfo.bottom < rootBoundsInfo.bottom) {
          this.fireEvent(false, stickyTarget);
        }
      }
    }, {threshold: [0], root: container});

    // Add the top sentinels to each section and attach an observer.
    const sentinels = this.addSentinels(container, 'sticky_sentinel--top');
    sentinels.forEach(el => observer.observe(el));
  }

  /**
   * Sets up an intersection observer to notify when elements with the class
   * `.sticky_sentinel--bottom` become visible/invisible at the bottom of the
   * container.
   * @param {!Element} container
   */
  observeFooters(container) {
    const observer = new IntersectionObserver((records, observer) => {
      for (const record of records) {
        const targetInfo = record.boundingClientRect;
        const stickyTarget = record.target.parentElement.querySelector('.sticky-card-header');
        const rootBoundsInfo = record.rootBounds;
        const ratio = record.intersectionRatio;

        // Started sticking.
        if (targetInfo.bottom > rootBoundsInfo.top && ratio === 1) {
          this.fireEvent(true, stickyTarget);
        }

        // Stopped sticking.
        if (targetInfo.top < rootBoundsInfo.top &&
          targetInfo.bottom < rootBoundsInfo.bottom) {
          this.fireEvent(false, stickyTarget);
        }
      }
    }, {threshold: [1], root: container});

    // Add the bottom sentinels to each section and attach an observer.
    const sentinels = this.addSentinels(container, 'sticky_sentinel--bottom');
    sentinels.forEach(el => observer.observe(el));
  }

  /**
   * @param {!Element} container
   * @param {string} className
   */
  addSentinels(container, className) {
    return Array.from(container.querySelectorAll('.sticky-card-header')).map((el: HTMLElement) => {
      const sentinel = document.createElement('div');
      sentinel.classList.add('sticky_sentinel', className);
      return el.parentElement.appendChild(sentinel);
    });
  }

  /**
   * Dispatches the `sticky-event` custom event on the target element.
   * @param {boolean} stuck True if `target` is sticky.
   * @param {!Element} target Element to fire the event on.
   */
  fireEvent(stuck, target) {
    const e = new CustomEvent('sticky-change', {detail: {stuck, target}});
    document.dispatchEvent(e);
  }

  /***** END - PRIVATE METHODS *****/

}
