<app-component-mask></app-component-mask>
<div id="{{id}}" fxLayout="row">
  <mat-card fxLayout="column" fxFlex="100">
    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings" class="sticky-card-header" [ngClass]="{ 'disabled-card-header': patientForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="patient-title">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits" [ngClass]="{ 'disabled-card-header': patientForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="patient-title-edits">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning" [ngClass]="{ 'disabled-card-header': patientForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="patient-title-warning">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="patientForm" novalidate fxLayout="column">
        <!-- ############################################################################################################################################################################## -->
        <!-- First Row In Patient Card: "Field Labels" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-top-30 margin-bottom-15">
          <!-- Date of Birth Label -->
          <label id="patient-dob-label" class="nested-column-3"><span *ngIf="showRequiredDateOfBirthAsterisk" class="required-label-asterisk">* </span>3. Date of Birth</label>
          <!-- Patient Relationship Label -->
          <label id="patient-relationship-label" class="nested-column-3">6. Patient Relationship to Insured</label>
          <!-- Primary Phone Label -->
          <label id="patient-primary-phone-label" class="nested-column-3">Primary Phone</label>
          <!-- Patient Gender Label -->
          <label id="patient-sex-label" class="nested-column-3"><span class="required-label-asterisk">* </span>Sex</label>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Second Row In Patient Card: "Field Inputs, Dropdown's and Radio Buttons" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row">
          <!-- Date of Birth Input Field -->
          <app-date-picker class="nested-column-3" id="patient-dob-date-picker"
                           [datePickerConfiguration]="datePickerConfiguration"></app-date-picker>
          <!-- Patient Relationship Dropdown Field -->
          <div fxLayout="column" class="nested-column-3">
            <select matSelect class="custom-select required-form-field" id="patient-relationship-group"
                    formControlName="patientRelationToSubscriber">
              <option id="patient-relationship-self-select-option"
                      [value]="controlDefaults.Member">{{controlDefaults.Member}}</option>
              <option id="patient-relationship-spouse-select-option"
                      [value]="controlDefaults.Spouse">{{controlDefaults.Spouse}}</option>
              <option id="patient-relationship-child-select-option"
                      [value]="controlDefaults.Child">{{controlDefaults.Child}}</option>
              <option id="patient-relationship-other-select-option"
                      [value]="patientRelationshipOtherValue">{{controlDefaults.Other}}</option>
            </select>
          </div>
          <!-- Primary Phone Input Field -->
          <div fxLayout="column" class="nested-column-3">
            <mat-form-field appearance="outline">
              <input matInput #patientPhoneControl formControlName="patientPhone" id="patient-primary-phone-input">
            </mat-form-field>
            <app-error-wrapper id="patient-phone-error" [config]="errorWrapperConfig.patientPhone"></app-error-wrapper>
          </div>
          <!-- Patient Gender Radio Buttons -->
          <div fxLayout="column" class="nested-column-3">
            <mat-radio-group fxLayout="row" fxLayoutAlign="start center" name="fontStyle" id="patient-sex-group"
                             formControlName="patientGender">
              <mat-radio-button #patientSexMale id="patient-sex-male-toggle" value="{{controlDefaults.Male}}"
                                (keyup.enter)="onKeypressEventRadiobutton(patientSexMale);
                                updatePatientGenderOnActiveClaim(controlDefaults.Male)">Male</mat-radio-button>
              <mat-radio-button #patientSexFemale id="patient-sex-female-toggle" value="{{controlDefaults.Female}}"
                                fxFlexOffset="30px"
                                (keyup.enter)="onKeypressEventRadiobutton(patientSexFemale);
                                updatePatientGenderOnActiveClaim(controlDefaults.Female)">Female</mat-radio-button>
            </mat-radio-group>
            <app-error-wrapper id="patient-sex-error" [config]="errorWrapperConfig.patientGender"></app-error-wrapper>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Third Row In Patient Card: "Field Labels, Inputs and dropdown's" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-top-30 margin-bottom-30">
          <!-- Patient Address 1 Section -->
          <div fxLayout="column" class="nested-column-3">
            <label id="patient-address-1-label"><span class="required-label-asterisk">* </span>Patient Address 1</label>
            <mat-form-field fxFlexOffset="15px" appearance="outline" class="required-mat-form-field">
              <input matInput id="patient-address1" formControlName="patientAddressStreet1"
                     [maxLength]="patientAddressMaxLength">
            </mat-form-field>
            <app-error-wrapper id="patient-address1-error"
                               [config]="errorWrapperConfig.patientAddressStreet1"></app-error-wrapper>
          </div>
          <!-- Patient Address 2 Section -->
          <div fxLayout="column" class="nested-column-3">
            <label id="patient-address-2-label">Patient Address 2</label>
            <mat-form-field fxFlexOffset="15px" appearance="outline">
              <input matInput id="patient-address2" formControlName="patientAddressStreet2"
                     [maxLength]="patientAddressMaxLength">
            </mat-form-field>
            <app-error-wrapper id="patient-address2-error"
                               [config]="errorWrapperConfig.patientAddressStreet2"></app-error-wrapper>
          </div>
          <!-- Patient Address City Section -->
          <div fxLayout="column" class="nested-column-3">
            <label id="patient-city-label"><span class="required-label-asterisk">* </span>City</label>
            <mat-form-field fxFlexOffset="15px" appearance="outline" class="required-mat-form-field">
              <input matInput formControlName="patientAddressCity" [maxLength]="patientCityMaxLength"
                     id="patient-city-input">
            </mat-form-field>
            <app-error-wrapper id="patient-city-error"
                               [config]="errorWrapperConfig.patientAddressCity"></app-error-wrapper>
          </div>
          <!-- Patient Address State Code Section -->
          <div fxLayout="column" class="nested-column-1 state-code-dropdown-format">
            <label id="patient-state-label"><span class="required-label-asterisk">* </span>State</label>
            <ng-select [items]="stateCodes"
                       #patientStateCodeDropdown
                       placeholder=""
                       formControlName="patientAddressStateCode"
                       id="patient-state-input"
                       class="required-form-field margin-top-15">
            </ng-select>
            <app-error-wrapper id="patient-state-error" [config]="errorWrapperConfig.patientAddressStateCode"></app-error-wrapper>
          </div>
          <!-- Patient Address State Zip Code Section -->
          <div fxLayout="column" class="nested-column-2">
            <label id="patient-zip-code-label"><span class="required-label-asterisk">* </span>Zip Code</label>
            <mat-form-field fxFlexOffset="15px" appearance="outline" class="required-mat-form-field">
              <input matInput
                     formControlName="patientAddressZipCode"
                     id="patient-zip-code-input"
                     #patientAddressZipCodeControl>
            </mat-form-field>
            <app-error-wrapper id="patient-zip-code-error"
                               [config]="errorWrapperConfig.patientAddressZipCode"></app-error-wrapper>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
