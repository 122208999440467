import { Injectable } from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {ClaimSummarySearchRequest} from '../../../../../models/ClaimSummarySearchRequest';
import {ClaimSummaries, ClaimSummary} from '../../../../../models/claimSummary';
import {ClaimSummaryDataService} from '../../http/http-client-data/claim-summary-data/claim-summary-data.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimSummaryService {

  constructor(
    private claimSummaryDataService: ClaimSummaryDataService
  ) { }

  private _claimSummary: ClaimSummary;
  private _claimNumber: string;
  private _claimExtensionNumber: string;
  private _claimSummaries: ClaimSummaries;
  private _serviceStartDateFrom: string;
  private _serviceStartDateTo: string;

  /***** START - PROPERTY ACCESSORS *****/
  get claimSummary(): ClaimSummary {
    return (this._claimSummary) ? Object.deepClone(this._claimSummary) : this._claimSummary;
  }

  set claimSummary(value: ClaimSummary) {
    this._claimSummary = value;
  }

  get claimNumber(): string {
    return this._claimNumber;
  }

  set claimNumber(value: string) {
    this._claimNumber = value;
  }

  get claimExtensionNumber(): string {
    return this._claimExtensionNumber;
  }

  set claimExtensionNumber(value: string) {
    this._claimExtensionNumber = value;
  }

  get claimSummaries(): ClaimSummaries {
    return this._claimSummaries;
  }

  set claimSummaries(value: ClaimSummaries) {
    this._claimSummaries = value;
  }

  get serviceStartDateFrom(): string {
    return this._serviceStartDateFrom;
  }

  set serviceStartDateFrom(value: string) {
    this._serviceStartDateFrom = value;
  }

  get serviceStartDateTo(): string {
    return this._serviceStartDateTo;
  }

  set serviceStartDateTo(value: string) {
    this._serviceStartDateTo = value;
  }

  /***** END - PROPERTY ACCESSORS *****/

  /***** START - PUBLIC FUNCTIONS *****/

  /**
   * Method searches the claims summary with a given request.
   *
   * @param claimsSummarySearchRequest
   */
  searchClaimSummaries(claimsSummarySearchRequest: ClaimSummarySearchRequest): Observable<ClaimSummaries> {
    return new Observable<ClaimSummaries>((observer: Subscriber<ClaimSummaries>) => {
      this.claimSummaryDataService.searchClaimSummaries(claimsSummarySearchRequest).subscribe((claimSummaries) => {
        this.claimSummaries = claimSummaries;
        observer.next(claimSummaries);
        observer.complete();
      });
    });
  }

  /**
   * Method retrieves the claims summary detail with a given url.
   *
   * @param claimSummaryDetailRetrieveUrl
   */
  retrieveClaimSummaryDetails(claimSummaryDetailRetrieveUrl: string): Observable<ClaimSummary> {
    return new Observable<ClaimSummary>((observer: Subscriber<ClaimSummary>) => {
      this.claimSummaryDataService.retrieveClaimSummaryDetail(claimSummaryDetailRetrieveUrl).subscribe((claimSummary) => {
        this.claimSummary = claimSummary;
        observer.next(claimSummary);
        observer.complete();
      });
    });
  }

  /***** END - PUBLIC FUNCTIONS *****/

}
