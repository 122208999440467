import { ViewStateService } from '../services/view-state/view-state.service';
import { Injector } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

export class ClaimFormItem {

  /***** START - PUBLIC MEMBERS *****/
  public viewStateService: ViewStateService;
  public title: string;
  public isActive: boolean = false;
  public isComplete: boolean = false;
  public hasInputError: boolean = false;
  public id: string;
  /***** START - PUBLIC MEMBERS *****/

  constructor(injector: Injector) {
    this.viewStateService = injector.get(ViewStateService);
  }

  /***** START - PRIVATE FUNCTIONS *****/
  registerWithClaimProgressService(): void {
    this.viewStateService.registerClaimFormItem({
      title: this.title,
      isActive: this.isActive,
      isComplete: this.isComplete,
      hasInputError: this.hasInputError,
      id: this.id
    });
  }

  disableFormGroupComponents(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.disable();
      }
    });
  }

  /***** END - PRIVATE FUNCTIONS *****/
}
