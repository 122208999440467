import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {PublicRoutingModule} from './public-routing.module';
import {PublicHeaderComponent} from './public-header/public-header.component';
import {ExpiredSessionComponent} from './expired-session/expired-session.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PublicErrorContainerComponent} from './public-error-container/public-error-container.component';
import { LoadingModalComponent } from '../common/components/loading-modal/loading-modal/loading-modal.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {VspIconSnackbarModule} from '../secure/icon-snackbar/vsp.icon-snackbar.module';

@NgModule({
  declarations: [
    PublicHeaderComponent,
    ExpiredSessionComponent,
    PublicErrorContainerComponent,
    LoadingModalComponent
  ],
  imports: [
    VspIconSnackbarModule,
    BrowserModule,
    PublicRoutingModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    FlexLayoutModule
  ]
})
export class PublicModule { }
