export interface LensPrescription {

  addRight?: number;
  addLeft?: number;
  planoLensSelected?: boolean;
  binocularPdDistant?: number;
  binocularPdNear?: number;
  monocularPdDistantRight?: number;
  monocularPdDistantLeft?: number;
  monocularPdNearRight?: number;
  monocularPdNearLeft?: number;
  segmentHeightRight?: string;
  segmentHeightLeft?: string;
  opticalCenterHeightRight?: string;
  opticalCenterHeightLeft?: string;
  horizontalPrismRight?: number;
  horizontalPrismLeft?: number;
  verticalPrismRight?: number;
  verticalPrismLeft?: number;
  thickness?: number;
  wrapAngle?: string;
  pantoscopicTilt?: string;
  backVertexDistance?: string;
  segmentMeasurementType?: string;
  opticalCenterMeasurementType?: string;
  horizontalPrismDirRight?: string;
  horizontalPrismDirLeft?: string;
  verticalPrismDirRight?: string;
  verticalPrismDirLeft?: string;
  thicknessTypeCode?: string;
  pupillaryDistanceType?: string;
}

export interface BasePrescription {
  sphereRight?: string;
  sphereLeft?: string;
  cylinderRight?: string;
  cylinderLeft?: string;
  axisRight?: string | number;
  axisLeft?: string | number;
}

export interface EclaimLensPrescription extends LensPrescription, BasePrescription {
  bimono?: string;
  distanceRight?: string;
  distanceLeft?: string;
  nearRight?: string;
  nearLeft?: string;
  opticalCenterStartingPoint?: string;
  segmentHeightStartingPoint?: string;
  planoLensSelected?: boolean;
}

export enum SegmentMeasurementType {
  BOTTOM_OF_FRAME = 'BOTTOM_OF_FRAME',
  GEOMETRIC_CENTER = 'GEOMETRIC_CENTER'
}

export enum OpticalCenterMeasurementType {
  BOTTOM_OF_FRAME = 'BOTTOM_OF_FRAME',
  GEOMETRIC_CENTER = 'GEOMETRIC_CENTER'
}
