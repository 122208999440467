import {Link} from './link';

export interface ServiceLocationInfo {
  practiceName: string;
  serviceLocationTaxId: string;
  serviceLocationHashCode: string;
  serviceLocationSequence: string;
}

export interface MemberPatientInfo {
  memberFirstName: string;
  memberMiddleInitial?: string;
  memberLastName: string;
  patientFirstName: string;
  patientMiddleInitial?: string;
  patientLastName: string;
  patientRelationship: string;
}

export interface CopayInfo {
  copays: Copay[];
  totalCopayAmountPerClaim: number;
}

export interface Copay {
  copayServiceItem: string;
  copayAmount: number;
  copayDisplay: string;
}

export interface FeeInfo {
  professionalFees: ProfessionalFee[];
  totalProfessionalFeeAmountPerClaim: number;
  materialFees: MaterialFee[];
  totalMaterialFeeAmountPerClaim: number;
}

export interface ProfessionalFee {
  professionalFeeServiceDescription: string;
  professionalFeeServiceAmount: number;
}

export interface MaterialFee {
  materialFeeServiceDescription: string;
  materialFeeServiceAmount: number;
}

export interface ClaimSummaryError {
  errorId: string;
  errorMessage: string;
}

export interface ClaimSummary {
  claimNumber: string;
  claimExtensionNumber: string;
  authorizationNumber: string;
  dateOfService: string;
  statusCode: string;
  statusCodeDescription: string;
  memberPatientInfo: MemberPatientInfo;
  programId?: string;
  productPackageBusinessName: string;
  labName?: string;
  serviceItemCodes: string;
  serviceItemDescriptions?: string;
  paidDate?: string;
  physicianInvoiceCheckNumber?: string;
  copayInfo?: CopayInfo;
  feeInfo?: FeeInfo;
  totalEstimatedPayableAmountPerClaim: number;
  self: Link;
}

export interface ClaimSummaries {
  pricedClaimSummaries: ClaimSummary[];
  totalEstimatedPayableAmount: number;
}

export enum ClaimSummaryStatusCodeEnum {
  Paid = 'PD',
  Priced = 'PR'
}
