<div fxLayout="column">

  <!-- Header Section -->
  <mat-card>
    <!-- Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 class="nested-column-11"
            id="leave-claim-form" mat-dialog-title>Leave Claim Form?</h1>
      </mat-card-title>
      <!-- Close Icon -->
      <button [disableRipple]="true" class="close-button" mat-icon-button>
        <mat-icon (click)="onCloseClick()" id="leave-claim-form-close-button"
                  svgIcon="eclaim-icons:close"></mat-icon>
      </button>
    </mat-card-header>


    <!-- Body -->
    <mat-card-content>
      <!-- First Row - Body of the modal -->
      <label id="would-you-like-to-save-this-claim-label">
        You are about to leave the claim form. Would you like to save this claim, or continue to leave?</label>
    </mat-card-content>

    <!--Footer Line-->
    <hr/>

    <!-- Cancel/Leave Form/Save buttons -->
    <mat-card-actions>
      <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="end center"
           class="margin-top-20 margin-bottom-20 leave-claim-form-warning-modal">

        <!--Cancel Button-->
        <button mat-button color="primary" class="cancel-button"
                id="leave-claim-form-warning-modal-cancel-button" mat-dialog-close>
          <span>Cancel</span>
        </button>
        <div fxFlexOffset="30px"></div>

        <!--Leave Form Button-->
        <button mat-stroked-button color="primary" class="btn btn-outline-info btn-extra-pad"
                id="leave-claim-form-warning-modal-leave-form-button" (click)="leaveFormButton()">Leave Form</button>
        <div fxFlexOffset="30px"></div>

        <!--Save Button-->
        <button mat-button class="btn btn-primary btn-extra-pad"
                id="leave-claim-form-warning-modal-save-button" (click)="saveButton()">Save</button>
        <div fxFlexOffset="20px"></div>
      </div>
    </mat-card-actions>

  </mat-card>

</div>
