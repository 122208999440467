import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-component-mask',
  templateUrl: './component-mask.component.html',
  styleUrls: ['./component-mask.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ComponentMaskComponent {


  constructor(
    private rootElement: ElementRef,
  ) { }


  /***** START - PUBLIC FUNCTIONS *****/
  public show(): void {
    this.rootElement.nativeElement.classList.add('shown');
  }

  public hide(): void {
    this.rootElement.nativeElement.classList.remove('shown');
  }
  /***** END - PUBLIC FUNCTIONS *****/

}
