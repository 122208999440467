<div class="vsp-container frame-search-results-modal" id="frameSearchResultsModal">

  <!-- Header -->
  <h3 mat-dialog-title id="frame-search-header-text">Frame Selection</h3>


  <!-- Close Button -->
  <a class="modal-close-button hidden-print" (click)="onCloseClick()" id="frameSearchResultsModal-closeButton">
    <mat-icon svgIcon="eclaim-icons:close" id="frame-search-close-button"></mat-icon>
  </a>

  <!-- Header Underline -->
  <hr/>

  <div *ngIf="isFrameSearchApiOffline">
    Frame search is currently unavailable, please enter your frame manually using this form.
  </div>

  <!-- Search Form -->
  <form id="search-results-modal-form" class="claim-form-child-form" [formGroup]="searchForm" novalidate>
    <div *ngIf="!isFrameSearchApiOffline && !isManualEnterFrameClick">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4%" class="search-results-input-row">
        <!-- Frame Search -->
        <div fxLayout="column" fxFlex="41">
          <label id="frame-search-text-label" class="control-label label-on-top-close-fit">Frame Search</label>
          <mat-form-field appearance="outline" class="form-field-full-width">
            <input matInput #searchFramesTextControl class="form-control full-width text-indent"
                   id="search-frames-textbox" name="searchFramesText" placeholder="Enter frame data to begin search"
                   formControlName="query">
          </mat-form-field>

        </div>
        <div fxLayout="column" fxFlex="18">
          <button mat-flat-button class="form-control full-width search-button mat-flat-button mat-primary" (click)="onSearchClick()"
                  id="searchButton">
            <ng-container *ngIf="!framesLoading">Search</ng-container>
          </button>
        </div>
        <!-- Manually Enter Frame -->
        <div fxLayout="column" fxFlex="18">
          <button mat-flat-button class="form-control full-width search-button mat-flat-button mat-primary" (click)="onManualFrameClick()"
                  id="search-manual-frames">
            <ng-container *ngIf="!framesLoading">Manually Enter Frame</ng-container>
          </button>
        </div>
        <div fxLayout="column" fxFlex="41" class="frame-search-results-modal-frames-count">
          <p class="c-red" *ngIf="hasTooManyOrNoResults" id="search-frames-number-found-label">
            <strong>{{numberOfFramesFound}}</strong> frames were found.
          </p>
        </div>
      </div>
    </div>

    <mat-dialog-content>

      <ng-container class="search-results-modal-spinner-container" *ngIf="framesLoading">
        <mat-spinner class="search-results-modal-spinner" [diameter]="35" [strokeWidth]="4"></mat-spinner>
      </ng-container>
      <div [hidden]="framesLoading">

        <!-- zero or too many frame search results-->
        <h3 class="c-red" *ngIf="zeroFramesFound && !isManualEnterFrameClick" id="frame-search-no-match-message">Your
          search did not match any frames in our database.</h3>
        <h3 class="c-red" *ngIf="tooManyFramesFound && !isManualEnterFrameClick"
            id="frame-search-too-many-match-message">Too many results found.</h3>
        <div *ngIf="(tooManyFramesFound || zeroFramesFound) && !isManualEnterFrameClick">
          <p id="frame-search-search-again-message">You may search again by entering frame information in the above
            Frame Search field.</p>
          <p id="frame-search-narrow-down-message">Please include additional details about the frame to narrow down the
            search results.</p>
        </div>

        <!-- frame search results table -->
        <div fxLayout="row"
             fxLayoutAlign="start center"
             class="search-results-table-row"
             [hidden]="zeroFramesFound || tooManyFramesFound || isFrameSearchApiOffline || isManualEnterFrameClick">
          <!-- do not use *ngIf when nesting a mat table inside of it, this will break sorting!!! -->

          <div class="frame-search-results-table-container mat-elevation-z8">
            <mat-table matSort id="frame-search-results-table" [dataSource]="dataSource" class="mat-elevation-z8">

              <ng-container matColumnDef="manufacturer">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Manufacturer</mat-header-cell>
                <mat-cell *matCellDef="let frame">{{frame.manufacturer }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="collection">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Collection</mat-header-cell>
                <mat-cell *matCellDef="let frame">{{frame.collection}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="model">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Model</mat-header-cell>
                <mat-cell *matCellDef="let frame">{{frame.model}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="eyeSize">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Eye</mat-header-cell>
                <mat-cell *matCellDef="let frame">{{frame.eyesize ? frame.eyesize : ''}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="temple">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Temple</mat-header-cell>
                <mat-cell *matCellDef="let frame">{{frame.temple ? frame.temple : ''}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="color">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Color</mat-header-cell>
                <mat-cell *matCellDef="let frame; index as i"
                          id="frame-search-results-table-row-color-{{i}}">{{frame.color}}</mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let frameRow; columns: tableColumns; index as i"
                       (click)="onFramesTableRowClick(frameRow)"
                       class="frame-search-results-table-row" id="frame-search-results-table-row-{{i}}"
                       [ngClass]="{'is-selected':frameRow.isActiveSelection}"></mat-row>

            </mat-table>
          </div>

        </div>
      </div>
    </mat-dialog-content>

  </form>

  <!-- frame details -->
  <div fxLayout="row" fxLayoutAlign="start center" class="search-results-selected-frame-details-row"
       *ngIf="data.selectedFrame">
    <div fxLayout="column" fxFlex="100">
      <app-frame-details-display
        [frame]="data.selectedFrame"
        [supplier]="data.frameSupplier"
        [isFrameSearchApiOffline]="isFrameSearchApiOffline"
        [allowFullFrameDetailEdit]="allowFullFrameDetailEdit"
        [startFrameDetailsComponenetInEditMode]="startFrameDetailsComponenetInEditMode"
        [isOnFrameResultsModal]="true"
        (addToOrder)="frameSelected($event)">
      </app-frame-details-display>
    </div>
  </div>


</div>
