export const FulfillmentConstants = {
// Disposition Code & Description
  returnTheFrameToTheBoard: '224e7f81-e05d-444b-809c-eaca44ef9b31',
  manufacturerNotParticipating: 'f61e638a-22db-4776-876b-85bbb001233d',
  requestedSkuAndReplacementSkuAvailable: '2ddca61c-6dac-425c-974e-ba3a84cc87f4',
  requestedSkuIsOutOfStockReplacementSkuAvailable: '406a566d-63f7-4e38-b270-c4400c4031a3',
  requestedSkuIsBackOrderedAndReplacementSkuAvailable: 'bc312db9-6b2a-443f-ae3d-71703df79315',
  // Participating Lab Code
  freeShipping: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
  notParticipating: 'fdfee821-909E-42C4-9165-0b9d17f95d9a'
};
