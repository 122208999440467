<form [formGroup]="serviceLine" fxFill>
  <div fxLayout="row" fxLayoutAlign="center" class="margin-top-20 margin-bottom-20 not-cob-claim">

    <!-- Service start date -->
    <div fxFlex={{serviceLineHtmlConstants.serviceDateWidth}}>
      <div (mouseenter)="$event.stopImmediatePropagation()"
           (mouseleave)="$event.stopImmediatePropagation()"
           matTooltip="The Date of Service must be changed at the top of the claim form. If you have multiple Dates of Service, multiple claims will need to be submitted"
           #tooltip="matTooltip" (click)="tooltip.toggle()">
        <mat-form-field appearance="outline" fxFill>
          <input matInput
                 id="service-line-{{index}}-start-date-input"
                 type="text"
                 formControlName="serviceStartDate"
                 [value]="serviceLine.get(SERVICE_START_DATE).value">
        </mat-form-field>
      </div>
    </div>

    <!-- Service end date -->
    <div fxFlex={{serviceLineHtmlConstants.serviceDateWidth}}>
      <div fxLayoutAlign="stretch"
           matTooltip="The Date of Service must be changed at the top of the claim form. If you have multiple Dates of Service, multiple claims will need to be submitted"
           #tooltip="matTooltip" (click)="tooltip.toggle()"
           (mouseenter)="$event.stopImmediatePropagation()"
           (mouseleave)="$event.stopImmediatePropagation()">
        <mat-form-field appearance="outline" fxFill>
          <input matInput
                 id="service-line-{{index}}-end-date-input"
                 type="text"
                 formControlName="serviceEndDate"
                 [value]="serviceLine.get(SERVICE_END_DATE).value">
        </mat-form-field>
      </div>
    </div>

    <!-- Place of service code -->
    <div fxFlex={{serviceLineHtmlConstants.placeOfServiceWidth}}>
      <mat-form-field appearance="outline" fxFill>
        <input matInput
               id="service-line-{{index}}-pos-input"
               type="text"
               formControlName="placeOfServiceCode">
      </mat-form-field>
    </div>

    <div fxFlex={{serviceLineHtmlConstants.checkboxWidth}} class="checkbox-padding">
      <mat-checkbox #emgCheckboxElement id="service-line-{{index}}-emg-checkbox"
                    class="claim-service-line-checkbox"
                    formControlName="emergencyIndicator"
                    (keyup.enter)="onKeypressEventCheckbox( serviceLine.get(EMERGENCY_INDICATOR))"></mat-checkbox>
    </div>

    <!-- CPT/HCPC codes -->
    <div fxFlex={{serviceLineHtmlConstants.cptHcpcsCodeWidth}} fxLayout="column">
      <mat-form-field appearance="outline" fxFill>
        <input matInput
               id="service-line-{{index}}-hcpc-input"
               type="text"
               maxlength="5"
               formControlName="cptHcpcsCode"
               (blur)="onPROCBlur(index)">
      </mat-form-field>
      <app-error-wrapper id="service-line-{{index}}-hcpc-required-error" [hidden]="!isStringEmptyOrNull(serviceLine.get(CPT_HCPCS_CODE).value)" [config]="errorWrapperConfig.cptHcpcsCodeRequired"></app-error-wrapper>
      <app-error-wrapper id="service-line-{{index}}-hcpc-error" [config]="errorWrapperConfig.cptHcpcsCode"></app-error-wrapper>
    </div>

    <!-- Modifier codes -->
    <div fxFlex={{serviceLineHtmlConstants.modifierCodesWidth}} fxLayout="column">
      <mat-form-field appearance="outline" fxFill>
        <input matInput
               id="service-line-{{index}}-mod-input"
               type="text"
               class="upper-case"
               formControlName="modifierCodes"
               maxlength="11"
               [value]="modifierCodesPipeWrapper(serviceLine.get(MODIFIER_CODES))">
      </mat-form-field>
      <app-error-wrapper id="service-line-{{index}}-mod-error" [hidden]="!serviceLine.get(MODIFIER_CODES).hasError(MODIFIER_CODES) && !serviceLine.get(MODIFIER_CODES).hasError(ERROR_TYPES.ModifierCodesLength)" [config]="errorWrapperConfig.modifierCodes"></app-error-wrapper>
    </div>

    <!-- Diagnosis pointers -->
    <div fxFlex={{serviceLineHtmlConstants.diagnosisPointersWidth}} fxLayout="column">
      <div class="diagnosis-pointer-parent" fxLayout="row" >
        <div formArrayName="diagnosisPointers" class="diagnosis-pointer-individual" fxFlex="25"
             *ngFor="let diagnosisPointer of serviceLine.get(DIAGNOSIS_POINTERS)['controls']; index as dxPointerIndex; first as isFirst; last as isLast">
          <mat-form-field appearance="outline" fxFill>
            <input
              matInput
              #diagnosisPointerInputs
              formControlName="{{dxPointerIndex}}"
              id="service-line-{{index}}-diagnosis-pointer-{{dxPointerIndex}}-input"
              class="claim-service-line-input diagnosis upper-case"
              [ngClass]="{'first-diagnosis': isFirst, 'last-diagnosis': isLast}"
              [maxLength]="DIAGNOSIS_POINTERS_MAX_LENGTH">
          </mat-form-field>
        </div>
      </div>
      <app-error-wrapper id="service-line-{{index}}-diagnosis-pointer-invalid-error" [hidden]="!invalidDiagnosisCodeErrorIsVisible()" [config]="servicesErrorWrapperConfig.invalidDiagnosisCode"></app-error-wrapper>
      <app-error-wrapper id="service-line-{{index}}-diagnosis-pointer-required-error" [hidden]="!allStringArrayElementsEmpty(serviceLine.get(DIAGNOSIS_POINTERS).value)" [config]="errorWrapperConfig.allOrNoneDiagnosisPointers"></app-error-wrapper>
    </div>

    <!-- Charges -->
    <div fxFlex={{serviceLineHtmlConstants.billedAmountWidth}} fxLayout="column">
      <mat-form-field appearance="outline" fxFill>
        <span fxLayoutAlign="center center" class="dollar-sign">$</span>
        <input matInput id="service-line-{{index}}-billed-amount-input"
               #billedAmount
               maxlength="8"
               tabIndex="-1"
               fxFlex="100"
               formControlName="billedAmount"
               class="charges-alignment"
               type="text"
               (blur)="onDollarAmountFieldsBlur(BILLED_AMOUNT)"
               (keydown.tab)="onBilledAmountForwardTabNavigation($event.target.id, index)"
               (keydown.shift.tab)="onBilledAmountReverseTabNavigation($event.target.id, index)"
               [value]="precedingZeroForDecimalPipe(serviceLine.get(BILLED_AMOUNT))"/>
      </mat-form-field>
      <app-error-wrapper id="service-line-{{index}}-billed-amount-error" [hidden]="!isDollarAmountEmptyOrNull(serviceLine.get(BILLED_AMOUNT).value)" [config]="errorWrapperConfig.billedAmountRequired"></app-error-wrapper>
      <app-error-wrapper id="service-line-{{index}}-billed-amount-error1" [config]="errorWrapperConfig.billedAmount"></app-error-wrapper>
      <app-error-wrapper id="service-line-{{index}}-billed-amount-max-error1" [hidden]="serviceLine.get(BILLED_AMOUNT).hasError(BILLED_AMOUNT)" [config]="errorWrapperConfig.billedAmountMax"></app-error-wrapper>
    </div>

    <!-- Unit count -->
    <div fxFlex={{serviceLineHtmlConstants.unitCountWidth}} fxLayout="column">
      <mat-form-field appearance="outline" fxFill>
        <input matInput
               #unitCountInput
               id="service-line-{{index}}-unit-count-input" type="text"
               formControlName="unitCount"
               maxlength="3">
      </mat-form-field>
      <app-error-wrapper id="service-line-{{index}}unit-count-value-error" [config]="errorWrapperConfig.unitCount"></app-error-wrapper>
      <app-error-wrapper id="service-line-{{index}}unit-count-required-error" [hidden]="checkUnitCount(serviceLine.get(UNIT_COUNT), serviceLine.get(CPT_HCPCS_CODE))" [config]="errorWrapperConfig.unitCountRequired"></app-error-wrapper>
    </div>

    <!-- EPSDT checkbox -->
    <div fxFlex={{serviceLineHtmlConstants.checkboxWidth}} class="checkbox-padding">
      <mat-checkbox id="service-line-{{index}}-epsdt-checkbox"
                    class="claim-service-line-checkbox"
                    formControlName="epsdtIndicator"
                    (keydown.tab)="onTabOfLastEPSDTCheckbox(index)"
                    (keyup.enter)="onKeypressEventCheckbox( serviceLine.get(EPSDT_INDICATOR))"></mat-checkbox>
    </div>

    <!-- Toggle the COB fields if 11d. is selected from the insured card -->

      <!-- Other insured allowed -->
      <div *ngIf="isCobClaim" fxFlex="{{serviceLineHtmlConstants.cobInputWidth}}" fxLayout="column">
        <mat-form-field appearance="outline" class="required-mat-form-field" fxFill>
          <span fxLayoutAlign="center center" class="dollar-sign">$</span>
          <input matInput id="service-line-{{index}}-other-insured-allowed-input"
                 maxlength="8"
                 fxFlex="100"
                 formControlName="otherInsuranceAllowedAmount"
                 class="charges-alignment"
                 type="text"
                 [value]="precedingZeroForDecimalPipe(serviceLine.get(OTHER_INSURANCE_ALLOWED_AMOUNT))"
                 (blur)="onDollarAmountFieldsBlur(OTHER_INSURANCE_ALLOWED_AMOUNT)">
        </mat-form-field>
        <app-error-wrapper id="service-line-{{index}}-other-insured-allowed-required-error" [hidden]="!isDollarAmountEmptyOrNull(serviceLine.get(OTHER_INSURANCE_ALLOWED_AMOUNT).value)" [config]="errorWrapperConfig.otherInsuredAllowedAmountRequired"></app-error-wrapper>
        <app-error-wrapper id="service-line-{{index}}-other-insured-allowed-invalid-error" [config]="errorWrapperConfig.otherInsuranceAllowedAmount"></app-error-wrapper>
        <app-error-wrapper id="service-line-{{index}}-other-insured-allowed-max-dollar-amount-error" [hidden]="serviceLine.get(OTHER_INSURANCE_ALLOWED_AMOUNT).hasError(OTHER_INSURANCE_ALLOWED_AMOUNT)" [config]="errorWrapperConfig.otherInsuredAllowedAmountMax"></app-error-wrapper>
      </div>

      <!-- Other insured paid -->
      <div *ngIf="isCobClaim" fxFlex="{{serviceLineHtmlConstants.cobInputWidth}}" fxLayout="column">
        <mat-form-field appearance="outline" class="required-mat-form-field" fxFill>
          <span fxLayoutAlign="center center" class="dollar-sign">$</span>
          <input matInput id="service-line-{{index}}-other-insured-paid-input"
                 maxlength="8"
                 fxFlex="100"
                 formControlName="otherInsurancePaidAmount"
                 class="charges-alignment"
                 type="text"
                 [value]="precedingZeroForDecimalPipe(serviceLine.get(OTHER_INSURANCE_PAID_AMOUNT))"
                 (blur)="onDollarAmountFieldsBlur(OTHER_INSURANCE_PAID_AMOUNT)">
        </mat-form-field>
        <app-error-wrapper id="service-line-{{index}}-other-insured-paid-required-error" [hidden]="!isDollarAmountEmptyOrNull(serviceLine.get(OTHER_INSURANCE_PAID_AMOUNT).value)" [config]="errorWrapperConfig.otherInsuredPaidAmountRequired"></app-error-wrapper>
        <app-error-wrapper id="service-line-{{index}}-other-insured-paid-invalid-error" [config]="errorWrapperConfig.otherInsurancePaidAmount"></app-error-wrapper>
        <app-error-wrapper id="service-line-{{index}}-other-insured-paid-max-dollar-amount-error" [hidden]="serviceLine.get(OTHER_INSURANCE_PAID_AMOUNT).hasError(OTHER_INSURANCE_PAID_AMOUNT)" [config]="errorWrapperConfig.otherInsuredPaidAmountMax"></app-error-wrapper>
      </div>

      <!-- Other insured patient responsibility -->
      <div *ngIf="isCobClaim" fxFlex="{{serviceLineHtmlConstants.cobInputWidth}}" fxLayout="column">
        <mat-form-field appearance="outline" class="required-mat-form-field" fxFill>
          <span fxLayoutAlign="center center" class="dollar-sign">$</span>
          <input matInput id="service-line-{{index}}-other-insured-patient-responsibility-input"
                 maxlength="8"
                 fxFlex="100"
                 formControlName="otherInsurancePatientPaidAmount"
                 class="charges-alignment"
                 type="text"
                 [value]="precedingZeroForDecimalPipe(serviceLine.get(OTHER_INSURANCE_PATIENT_PAID_AMOUNT))"
                 (blur)="onDollarAmountFieldsBlur(OTHER_INSURANCE_PATIENT_PAID_AMOUNT)">
        </mat-form-field>
        <app-error-wrapper id="service-line-{{index}}-other-insured-patient-responsibility-required-error" [hidden]="!isDollarAmountEmptyOrNull(serviceLine.get(OTHER_INSURANCE_PATIENT_PAID_AMOUNT).value)" [config]="errorWrapperConfig.otherInsuredPatientPaidRequired"></app-error-wrapper>
        <app-error-wrapper id="service-line-{{index}}-other-insured-patient-responsibility-invalid-error" [config]="errorWrapperConfig.otherInsurancePatientPaidAmount"></app-error-wrapper>
        <app-error-wrapper id="service-line-{{index}}-other-insured-patient-responsibility-max-dollar-amount-error" [hidden]="serviceLine.get(OTHER_INSURANCE_PATIENT_PAID_AMOUNT).hasError(OTHER_INSURANCE_PATIENT_PAID_AMOUNT)" [config]="errorWrapperConfig.otherInsuredPatientPaidAmountMax"></app-error-wrapper>
      </div>

      <!-- Denied or paid reason -->
      <div *ngIf="isCobClaim" fxFlex="{{serviceLineHtmlConstants.deniedOrPaidReasonWidth}}">
        <div fxLayout="column">
          <select matSelect
                  (blur)="onBlurOfLastDeniedOrPaidDropdown($event, index)"
                  id="service-line-{{index}}-denied-or-paid-dropdown"
                  formControlName="otherInsuranceDeniedNotCoveredReason"
                  class="custom-select">
            <option selected value></option>
            <option *ngFor="let reason of otherInsuranceReasons" [value]="reason.code" id="service-line-{{index}}-denied-or-paid-reason-{{toSlugId(reason.description)}}">
              {{reason.description}}
            </option>
          </select>
          <app-error-wrapper id="service-line-{{index}}-denied-or-paid-conditionally-required-error" [config]="errorWrapperConfig.otherInsuranceDeniedNotCoveredReason"></app-error-wrapper>
        </div>
      </div>

  </div>
</form>
