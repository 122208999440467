<div class="claim-navigation-container">

  <!-- Claim Items -->
  <div #claimNavBar fxLayout="column" fxLayoutAlign="center none" fxLayoutGap="1%">

    <!-- Claim Form Navigation Items -->
    <div id="claim-navbar-items" fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="row"
           fxLayoutAlign="center center"
           class="claim-navbar-item"
           (click)="scrollToElement(item)"
           *ngFor="let item of claimFormSectionItems; let claimSectionIndex=index"
           [ngClass]="{'active' : item.isActive}"
           id="navbar-link-{{item.id}}">
        <span fxFlexOffset="10px" class="claim-navbar-item-title">{{item.title}}</span>
        <div fxFlexOffset="10px" class="claim-form-anchor-black-divider" *ngIf="claimSectionIndex !== claimFormSectionItems.length - 1"></div>
      </div>
    </div>
  </div>

</div>
