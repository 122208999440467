<div fxLayout="column" class="valid-search-combinations-info-modal">

  <mat-card>
    <!-- Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 class="nested-column-11"
            id="valid-search-combinations-header">Valid Search Combinations</h1>
      </mat-card-title>
      <!-- Close Icon -->
      <button [disableRipple]="true" class="close-button" mat-icon-button>
        <mat-icon (click)="onCloseClick()" id="valid-search-combinations-close-icon"
                  svgIcon="eclaim-icons:close"></mat-icon>
      </button>
    </mat-card-header>

    <!-- Body -->
    <mat-card-content class="valid-search-list">
      <!-- Authorization Recap -->
      <mat-list role="list">
        <mat-list-item role="listitem" id="full-member-id-only">&bull; Full Member ID only</mat-list-item>
        <mat-list-item role="listitem" id="member-name-dob">&bull; Member First
          Name, Member Last Name, and Date of Birth
        </mat-list-item>
        <mat-list-item role="listitem" id="last-4-ssn-member-name">&bull; Last 4 SSN, Member First
          Name, and Member Last Name
        </mat-list-item>
        <mat-list-item role="listitem" id="last-4-ssn-member-name-dob">&bull; Last 4 SSN, Member First
          Name, Member Last Name, and Date of Birth
        </mat-list-item>
        <mat-list-item role="listitem" id="last-4-ssn-member-lastName-dob">&bull; Last 4 SSN, Member Last Name, and Date
          of Birth
        </mat-list-item>
      </mat-list>

      <p>
        <strong>Tip: Enter more information for best results.</strong>
      </p>
    </mat-card-content>

    <!-- Footer Line -->
    <hr/>

    <!-- OK/Cancel buttons  -->
    <mat-card-actions>
      <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="end center" class="margin-top-20 margin-bottom-20">

        <!-- Close button -->
        <button type="button" class="cancel-button" id="valid-search-combinations-cancel-button"
                mat-dialog-close>Cancel
        </button>
        <div fxFlexOffset="30px"></div>

        <!-- OK Button-->
        <button mat-dialog-close class="btn btn-primary btn-extra-pad" (click)="confirm()"
                id="valid-search-combinations-ok-button">OK
        </button>
        <div fxFlexOffset="20px"></div>

      </div>
    </mat-card-actions>

  </mat-card>
</div>
