import { MembershipName } from './membership';
import { EligibleItemVsrDto } from './eligibility';
import {Link} from './link';

export interface ReifiedPatient {
  name: MembershipName;
  subscriberName: MembershipName;
  relationToSubscriber: string;
  eligibility?: Link;
  externalEligibility?: Link;
  consumerId?: string;
}
export interface VisionServiceRequest {
  vsrNumber: string;
  businessProductPackageName: string;
  expirationDate: string;
  effectiveDate: string;
  networkId?: string;
  reifiedPatient: ReifiedPatient;
  reifiedEligibility?: ReifiedEligibility;
  self: {
    href: string;
    type: string;
    rel: string;
  };
}

export interface VisionServiceDeleteRequest {
  vsrNumber: string;
  sourceId: string;
}

/***** Create VSR Request *****/
export interface VisionServiceRequestPayload {
  issuedTo: string;
  reifiedEligibility: ReifiedEligibility;
}

export interface ReifiedEligibility {
  authorizedItems: EligibleItemVsrDto[];
}

export enum IssuedTo {
  Doctor = 'DOCTOR',
  Member = 'MEMBER'
}

/**
 * Convenience class for constructing a post request to create a VSR
 */
export class CreateVisionServiceRequest {

  private readonly _authorizedItems: EligibleItemVsrDto[];

  constructor(private $authorizedItems: EligibleItemVsrDto[]) {
    this._authorizedItems = $authorizedItems;
  }

  private get reifiedEligibility(): ReifiedEligibility {
    return {
      authorizedItems: this._authorizedItems
    };
  }

  get vsrPayload(): any {
    return {
      issuedTo: IssuedTo.Doctor,
      reifiedEligibility: this.reifiedEligibility
    };
  }
}

export class VisionServiceRequestClaimDto {

  private readonly _authorizationNumber: string;
  private readonly _effectiveDate: string;

  constructor(private authorizationNumber$: string, private effectiveDate$: string) {
    this._authorizationNumber = authorizationNumber$;
    this._effectiveDate = effectiveDate$;
  }

  get authorizationNumber(): string {
    return this._authorizationNumber;
  }

  get effectiveDate(): string {
    return this._effectiveDate;
  }

}
