import {Component, EventEmitter, Inject, Output, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ReplaceAuthModalDataOptions {
  authNumber: string;
}

@Component({
  templateUrl: './replace-auth-confirmation-modal.html',
  encapsulation: ViewEncapsulation.None
})
export class ReplaceAuthConfirmationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ReplaceAuthConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReplaceAuthModalDataOptions,
  ) {}

  @Output() hide: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  okClick() {
    this.dialogRef.close(true);
    this.hide.emit(true);
  }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }
}
