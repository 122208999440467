<div fxLayout="column">

  <mat-card>
    <!-- Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 mat-dialog-title class="nested-column-11"
            id="fsa-explanation-header">Flexible Spending Account (FSA)</h1>
      </mat-card-title>
      <!-- Close Icon -->
      <button [disableRipple]="true" class="close-button" mat-icon-button>
        <mat-icon (click)="onCloseClick()" id="fsa-explanation-close-button"
                  svgIcon="eclaim-icons:close"></mat-icon>
      </button>
    </mat-card-header>


    <mat-card-content class="modal-body services-fsa-explanation-modal" id="FSA-modal-body">
      <div>
        <p>Please enter the patient's total out-of-pocket expenses that are FSA reimbursable. This amount will be
          submitted on the patient's behalf to their FSA administrator for reimbursement.</p>
        <p>FSA is a healthcare savings account that allows members to set aside pre-tax dollars to use for
          out-of-pocket medical expenses, including vision.</p>
        <p>Eligible out-of-pocket expenses* for FSA reimbursement include, but is not limited to:</p>
        <ul>
          <li>Copays</li>
          <li>Non-covered lens options</li>
          <li>Frame overage</li>
          <li>Contact lens overage</li>
          <li>Contact lens solution</li>
          <li>Additional prescription glasses not covered by the benefit</li>
          <li>Prescription sunglasses not covered by the benefit</li>
          <li>Plano sunglasses not covered by the benefit (if deemed medically necessary by the doctor)</li>
        </ul>
        <p class="margin-top-15"><strong>Example:</strong></p>
        <div fxLayout="column">
          <div fxLayoutAlign="row">
            <div fxFlex="15">Copays:</div>
            <div fxFlex="85">$20</div>
          </div>
          <div fxLayoutAlign="row">
            <div fxFlex="15">Frame Overage:</div>
            <div fxFlex="85">$50</div>
          </div>
          <div fxLayoutAlign="row">
            <div fxFlex="15">Contact lens solution:</div>
            <div fxFlex="85">$20</div>
          </div>
          <div fxLayoutAlign="row">
            <div fxFlex="15">Total Eligible OOP:</div>
            <div fxFlex="85"><strong>$90</strong> (This is the amount to enter into the FSA area)</div>
          </div>
        </div>

        <div class="margin-top-25">
          <p>Questions? Call us at 800.615.1883</p>
          <p class="fsa-modal-small-text"><i>*Subject to Change by the IRS</i></p>
        </div>
      </div>
    </mat-card-content>

    <!-- Footer Line -->
    <hr/>

    <!-- OK button  -->
    <mat-dialog-actions>
      <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="end center" class="margin-top-20 margin-bottom-20">

        <button mat-dialog-close class="btn btn-primary btn-extra-pad" (click)="confirm()"
                id="fsa-explanation-ok-button">OK
        </button>
        <div fxFlexOffset="20px"></div>

      </div>
    </mat-dialog-actions>

  </mat-card>
</div>
