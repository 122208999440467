import {Injectable, Injector} from '@angular/core';
import {EligibilityDataService} from '../../http/http-client-data/eligibility-data/eligibility-data.service';
import {
  Eligibility, EligibilityCoverage,
  EligibilityNetwork,
  EligibilityProductPackage, EligibilitySuspend, EligibilitySuspendCode
} from '../../../../../models/eligibility';
import {Observable, of, Subscriber} from 'rxjs';
import {SessionStorageKeys, ApplicationConstants} from '../../../../constants/application.constants';
import {Link} from '../../../../../models/link';
import {convertToCamelCase, DateUtility, isNullOrUndefined, isStringNullUndefinedOrEmpty} from '../../../../utility';
import {Router} from '@angular/router';
import {MessageService} from '../../../support/message/message.service';

@Injectable({
  providedIn: 'root'
})
export class EligibilityService {

  /***** START - PRIVATE MEMBERS *****/
  private _memberEligibility: Eligibility;

  /***** END - PRIVATE MEMBERS *****/

  constructor(
    private eligibilityDataService: EligibilityDataService,
    private messageService: MessageService,
    private router: Router
  ) {
  }

  /***** START - PROPERTY ACCESSORS *****/
  get memberEligibility(): Eligibility {
    return (this._memberEligibility) ? Object.deepClone(this._memberEligibility) : this._memberEligibility;
  }

  set memberEligibility(eligibility: Eligibility) {
    this._memberEligibility = eligibility;
  }

  /***** START - PRIVATE METHODS *****/
  private readonly eligibilitySuspendMessages: Map<string, string> = new Map()
    .set(EligibilitySuspendCode.ELIGIB0002, ApplicationConstants.eligibilitySuspendMessages.noMatchingNetworkFoundMessage)
    .set(EligibilitySuspendCode.AUTHOR0001, ApplicationConstants.eligibilitySuspendMessages.dateOutsideAllowedPaymentPeriodMessage);


  /***** END - PROPERTY ACCESSORS *****/

  private eligibleNetworkContainsValidProductPackages = (eligibilityNetwork: EligibilityNetwork) =>
    eligibilityNetwork.packages && eligibilityNetwork.packages.length > 0

  private productPackageContainsValidEligibleItems = (productPackage: EligibilityProductPackage) =>
    productPackage && productPackage.eligibleItems && productPackage.eligibleItems.length > 0

  /**
   * Filters out primary product packages that are in lieu of another package,
   * as we do not want them to display on the coverage summary page.
   *
   * @param memberEligibility
   */
  private filterOutPrimaryProductPackagesWithInLieuOfs(memberEligibility: Eligibility): void {
    memberEligibility.networks.forEach((eligibilityNetwork: EligibilityNetwork) => {
      if (this.eligibleNetworkContainsValidProductPackages(eligibilityNetwork)) {
        // Initialize a reference to the primary product package (eligibility-web will return primary package as first index, see ECR-4241 AC9)
        const primaryProductPackage: EligibilityProductPackage = eligibilityNetwork.packages[0];
        eligibilityNetwork.packages = eligibilityNetwork.packages.filter((productPackage: EligibilityProductPackage) => {
          // If a product package contains packageInLieuOfs, filter out that package if we find a match on the package name and the primary package name
          return isNullOrUndefined(productPackage.packageInLieuOfs) ? true : !(productPackage.packageInLieuOfs.indexOf(primaryProductPackage.name) > -1);
        });
      }
    });
  }

  private setProductPackageHasOnlyNonAuthorizableItems(productPackage: EligibilityProductPackage): void {
    productPackage.hasOnlyNonAuthorizableItems = true;
    productPackage.eligibleItems.forEach(item => {
      if (item.authorizable) {
        productPackage.hasOnlyNonAuthorizableItems = false;
      }
    });
  }

  // Get the long description stored in memberEligibility.networks[x].packages[x].coverages[x].productCatalogKeys[x].description
  private getNonAuthProductPackageDescriptions(memberEligibility: Eligibility): void {
    memberEligibility.networks.forEach((eligbilityNetwork: EligibilityNetwork) => {
      eligbilityNetwork.packages.forEach((productPackage: EligibilityProductPackage) => {
        this.setProductPackageHasOnlyNonAuthorizableItems(productPackage);
        if (productPackage.hasOnlyNonAuthorizableItems) {
          productPackage.hasOnlyNonAuthorizableItemsAndNoDescription = true;
          if (productPackage.coverages && productPackage.coverages.length > 0) {
            productPackage.coverages.forEach((eligibilityCoverage: EligibilityCoverage) => {
              if (isStringNullUndefinedOrEmpty(productPackage.nonAuthPackageLongDescription) && !isStringNullUndefinedOrEmpty(eligibilityCoverage.description)) {
                productPackage.nonAuthPackageLongDescription = eligibilityCoverage.description;
                productPackage.hasOnlyNonAuthorizableItemsAndNoDescription = false;
              }
            });
          }
        }
      });
    });
  }

  /**
   * Traverse all networks and append the scrubbed product packages to a view model property on the response.
   *
   * @param memberEligibility
   */
  private buildProductPackageViewModelList(memberEligibility: Eligibility): void {
    memberEligibility.productPackageViewModelList = [] as EligibilityProductPackage[];
    memberEligibility.networks.forEach((eligibilityNetwork: EligibilityNetwork) => {
      if (this.eligibleNetworkContainsValidProductPackages(eligibilityNetwork)) {
        eligibilityNetwork.packages.forEach((productPackage: EligibilityProductPackage) => {
          memberEligibility.productPackageViewModelList.push(productPackage);
        });
      }
    });
  }
  private getAllErrorMessagesFromEligibilitySuspendCode(eligibilitySuspends: EligibilitySuspend[]): boolean {
    let hasSuspends = false;
    let errorMessages = '';

    for (const eligibilitySuspend in eligibilitySuspends) {
      const currentSuspend: EligibilitySuspend = eligibilitySuspends[eligibilitySuspend];
      if (currentSuspend && this.eligibilitySuspendMessages.get(currentSuspend.code)) {
        if ( hasSuspends ) {
          errorMessages += '\n\n' + currentSuspend.code + ' - ' + this.eligibilitySuspendMessages.get(currentSuspend.code);
        } else {
          errorMessages = currentSuspend.code + ' - ' + this.eligibilitySuspendMessages.get(currentSuspend.code);
        }
        hasSuspends = true;
      } else if (!isNullOrUndefined(currentSuspend) && (currentSuspend.code === EligibilitySuspendCode.SRVCLT0010)) {
        if ( hasSuspends ) {
          errorMessages += '\n\n' + currentSuspend.code + ' - ' + currentSuspend.description;
        } else {
          errorMessages = currentSuspend.code + ' - ' + currentSuspend.description;
        }
        hasSuspends = true;
      } else if (!isNullOrUndefined(currentSuspend) && (currentSuspend.code === EligibilitySuspendCode.PBELIG0005)) {
        if ( hasSuspends ) {
          errorMessages += '\n\n' + currentSuspend.code + ' - ' + ApplicationConstants.eligibilitySuspendMessages.dateOfBirthIsMissingEligibilitySuspendCustomMessage;
        } else {
          errorMessages = currentSuspend.code + ' - ' + ApplicationConstants.eligibilitySuspendMessages.dateOfBirthIsMissingEligibilitySuspendCustomMessage;
        }
        hasSuspends = true;
      } else {
        if (!isNullOrUndefined(currentSuspend) ) {
          if (hasSuspends) {
            errorMessages += '\n\n' + currentSuspend.code + ' - ' + convertToCamelCase(currentSuspend.description);
          } else {
            errorMessages = currentSuspend.code + ' - ' + convertToCamelCase(currentSuspend.description);
          }
          hasSuspends = true;
        }
      }
    }
    if ( !isNullOrUndefined(errorMessages ) ) {
      this.messageService.showErrorSnackbar( errorMessages );
    }
    return hasSuspends;
  }
  private isNecessaryEligibilityDataAvailable = (response: Eligibility): boolean => !isNullOrUndefined(response.networks) && response.networks.length > 0;

  private hasEligibilitySuspendOnResponse = (response: Eligibility): boolean => !isNullOrUndefined(response.suspends) && response.suspends.length > 0;

  /***** END - PRIVATE METHODS *****/

  /***** START - PUBLIC METHODS *****/
  retrieveMemberEligibilityFromConsumerLink(memberEligibilityLink: string): Observable<Eligibility> {
    return new Observable<Eligibility>((observer: Subscriber<Eligibility>) => {
      this.eligibilityDataService.retrieveMemberEligibility(memberEligibilityLink).subscribe((memberEligibility: Eligibility) => {
        if (isNullOrUndefined(memberEligibility)) {
          this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
          observer.next(undefined);
          observer.complete();
        }

        if (memberEligibility) {
          if ( this.hasEligibilitySuspendOnResponse(memberEligibility) &&
            this.getAllErrorMessagesFromEligibilitySuspendCode(memberEligibility.suspends) ) {
            observer.next(undefined);
            observer.complete();
          } else {
            // Handle valid eligibility responses
            if (this.isNecessaryEligibilityDataAvailable(memberEligibility)) {
              // Remove product packages containing package in lieu ofs on the primary package on the response
              this.filterOutPrimaryProductPackagesWithInLieuOfs(memberEligibility);

              // Get the long description for non-authorizable product packages
              this.getNonAuthProductPackageDescriptions(memberEligibility);

              // Append all product packages to the view model list of product packages to display on the coverage summary page
              this.buildProductPackageViewModelList(memberEligibility);

              // Set the member eligibility to be consumed by coverage summary
              this.memberEligibility = memberEligibility;
              sessionStorage.setItem(SessionStorageKeys.EligibilityRetrieveLink, JSON.stringify({href: memberEligibilityLink}));
              observer.next(memberEligibility);
              observer.complete();
            } else {
              this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
              observer.next(undefined);
              observer.complete();
            }
          }
        }
      });
    });
  }

  retrieveMemberEligibilityWithSpecifiedAsOfDate(memberEligibilityLink: string, asOfDate: string): Observable<Eligibility> {
    const memberEligibilityLinkWithoutDomain = memberEligibilityLink.replace(ApplicationConstants.domainOfUrlRegex, '');
    const memberEligibilityLinkQueryParams = this.router.parseUrl(memberEligibilityLinkWithoutDomain).queryParams;
    asOfDate = (asOfDate) ? DateUtility.buildYyyyMmDdDateFromDate(asOfDate) : memberEligibilityLinkQueryParams.asOfDate;
    const updatedURL = memberEligibilityLink.split('?', 1)[0] + '?asofdate=' + asOfDate;
    return this.retrieveMemberEligibilityFromConsumerLink(updatedURL);
  }

  getEligibilityRetrieveLinkFromSessionStorage(): Link {
    const cachedEligibilityRetrieveLink = sessionStorage.getItem(SessionStorageKeys.EligibilityRetrieveLink);
    if (!isNullOrUndefined(cachedEligibilityRetrieveLink)) {
      return JSON.parse(cachedEligibilityRetrieveLink);
    }
    return undefined;
  }

  /***** END - PUBLIC METHODS *****/
}
