import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthorizationsService } from '../../common/services/data-model/app/authorizations/authorizations.service';
import { ClaimFormNavigationService } from '../../common/services/support/claim-form-navigation/claim-form-navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApplicationConstants } from '../../common/constants/application.constants';
import {DrReportsNavigationService} from '../../common/services/support/dr-reports-navigation/dr-reports-navigation.service';
import {openDialog} from '../../common/utility';

@Component({
  selector: 'app-authorization-confirmation',
  templateUrl: './authorization-confirmation.component.html',
  styleUrls: ['./authorization-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthorizationConfirmationComponent implements OnInit {

  private _authorizationNumber: string;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authorizationService: AuthorizationsService,
    private claimFormNavigationService: ClaimFormNavigationService,
    private drReportsNavigationService: DrReportsNavigationService
  ) { }


  get authorizationNumber(): string {
    return this._authorizationNumber;
  }

  set authorizationNumber(number: string) {
    this._authorizationNumber = number;
  }

  ngOnInit() {
    if (this.authorizationService.vsrNumber) {
      this.authorizationNumber = this.authorizationService.vsrNumber;
    } else {
      this.router.navigate([ApplicationConstants.routing.secure.memberSearchPageUrl]);
    }
  }

  // TODO: This is temporary to go to legacy CMS1500 page; Auth Number is hardcoded currently, when plugged with APIs, get the real one.
  goToClaimForm() {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('', this.dialog);
    this.claimFormNavigationService.searchAndRetrieveOrCreatePatientEncounterThenLoadDoctorsThenNavigateToClaimForm(this.authorizationNumber, undefined, this.authorizationService.getEffectiveDateFromVsr(this.authorizationNumber)).subscribe(() => {
      // Broadcast value is null, no need for closure
    }, () => {
      // Errors are handled in the HTTP layer for PE, no need for error closure
    }, () => {
      // Close the dialog when the observer completes
      this.dialog.closeAll();
    });
  }

  showPatientRecordReport() {
    this.drReportsNavigationService.navigateToPatientRecordReport(this._authorizationNumber);
    /*let url: string = $cookies.get("EF_SURL");
    let phone: string = $cookies.get("VSPIDC_PHONE");
    if (phone.length > 10) {
      phone = phone.substring(0, 10);
    }
    let officeId: string = $cookies.get("VSPIDC_OFFICEID");*/
    // TODO: Once API is plugged in, get the dynamic data Patient and Member Name, Relationship, benefitType, authNumber, Date Of Service
    /*let location: string = url + '/servlet/OAServlet?verb=reports' ;
    location += '&authNum=11519685';
    location += '&dateOfSvc=08/17/2017';
    location += '&mbrName=YASHTES, YAXXX';
    location += '&patName=YASHTES, YAXXX';
    location += '&patRel=member';
    location += '&benType=VSP%20CHOICE%20PLAN';
    location += '&phone=' + phone;
    location += '&OfficeId=' + officeId;

    let childWin = $window.open(location,'PatientRecordReport',"toolbar=0,menubar=0,status=1,location=0,scrollbars=1,resizable=1,width=730,height=530");
    childWin.focus();*/ //this will cause the exception to be thrown if there is a pop up blocker
  }

}
