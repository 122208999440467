<div fxLayout="column" fxFlex="100">
  <div fxLayout="row">
    <div class="nested-column-12">
      <h1 id="cob-coverage-title" class="eclaim-header-text margin-top-10 margin-bottom-15">POSSIBLE COORDINATION OF BENEFITS</h1>

      <!-- Patient Name -->
      <p id="cob-coverage-patient-name-label" class="eclaim-body-text-bold margin-bottom-20">Patient Name:<label class="margin-left-3">{{getFullName()}}</label></p>

      <!-- Authorization # -->
      <p id="cob-coverage-authorization-number-label" class="eclaim-body-text-bold margin-bottom-20">Authorization #:<label class="margin-left-3">{{authorizationNumber}}</label></p>

      <!-- View Patient Record Report -->
      <p id="cob-coverage-patient-report-link" class="eclaim-body-text margin-bottom-20">
        <label (click)="onDoctorReportClick()" class="action-link" id="cob-coverage-view-patient-record-report-link">View Patient Record Report</label> for this authorization.
      </p>

      <!-- Please Verify That Your Service Will Be covered -->
      <p id="cob-coverage-dos-instructions" class="eclaim-body-text margin-bottom-20">
        Please verify that your services will be covered by the patient's health plan, {{planName}}. If {{planName}} covers your services, coordinate benefits with VSP after you receive payment from {{planName}}.
      </p>

      <!-- If Plan Does Not Cover Your Service -->
      <p id="cob-coverage-bill-vsp-instructions" class="eclaim-body-text">
        If {{planName}} does not cover your services, please <label href="" (click)="navigateToClaimForm()" class="action-link" id="cob-coverage-navigate-to-claim-link">Click here</label> to bill VSP.
      </p>
    </div>
  </div>
</div>
