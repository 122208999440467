import { Injectable } from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, AbstractControl, ValidatorFn} from '@angular/forms';
import { InputMaskService } from '../input-mask/input-mask.service';
import { DateUtility, isNullOrUndefined, isStringNullUndefinedOrEmpty, returnBooleanFromFormState } from '../../../utility';
import { FormConstants } from '../../../constants/form.constants';
import { ApplicationConstants } from '../../../constants/application.constants';

@Injectable({
  providedIn: 'root'
})
export class CustomValidatorsService {

  static prismDecimalAmount(form: FormControl): ValidationErrors {
    const val = form.value;
    const validCharacterRegex = ApplicationConstants.decimalNumberRegex;
    if (!isStringNullUndefinedOrEmpty(val)) {
      if (!validCharacterRegex.test(val)) {
        return {
          'prismDecimalAmount': true
        };
      }
    }
  }

  static dependencyRequirementValidation(formGroup: FormGroup, formControlWithDependency: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any} => {
      if (formGroup && formGroup.get(formControlWithDependency)) {
        const valueOfFieldToValidate = control.value;
        const valueOfFieldWithDependency = formGroup.get(formControlWithDependency).value;
        if (isStringNullUndefinedOrEmpty(valueOfFieldToValidate) && !isStringNullUndefinedOrEmpty(valueOfFieldWithDependency)) {
          return {'dependencyRequirementValidation': true};
        } else if (!isStringNullUndefinedOrEmpty(valueOfFieldToValidate) && isStringNullUndefinedOrEmpty(valueOfFieldWithDependency)) {
          formGroup.get(formControlWithDependency).setErrors({ 'dependencyRequirementValidation': true});
        } else {
          if (control.getError('dependencyRequirementValidation')) {
            control.setErrors(null);
          }
          if (formGroup.get(formControlWithDependency).getError('dependencyRequirementValidation')) {
            formGroup.get(formControlWithDependency).setErrors(null);
          }
        }
      }
      return null;
    };
  }

  constructor() { }

  /***** START - PUBLIC FUNCTIONS *****/

  public validMemberID(form: FormControl): ValidationErrors {
    const memberID: string = form.value;
    if (memberID && memberID.length < 9) {
      let atLeastOneAlpha = ApplicationConstants.atLeastOneAlphaRegex;
      const hasAtLeastOneAlpha = atLeastOneAlpha.test(memberID);
      if (!hasAtLeastOneAlpha) {
        return {
          invalidMemberId: 'ID must contain at least one alpha character when fewer than 9 characters'
        };
      }
    }
    return null;
  }

  public dateFormatAndValidity(control: FormControl): ValidationErrors {
    const controlDate = control.value;
    // This should be a 'Required' validation, will interfere with that error message
    if (isNullOrUndefined(controlDate)) {
      return null;
    }
    const dateInstance = controlDate instanceof Date;
    if (!dateInstance && isStringNullUndefinedOrEmpty(controlDate)) {
      return null;
    }
    // This will format the date to always have two digits for day and month and four digits for year
    const formattedStringDateValue = (dateInstance) ? DateUtility.buildFriendlyDateFromJsDate(controlDate) : DateUtility.formatRawDateToMmDdYyyy(controlDate);
    const dateRegex = ApplicationConstants.mmDdYyyyDateRegex;
    if (!dateRegex.test(formattedStringDateValue)) {
      return {
        invalidDateFormat: 'Date must be in MM/DD/YYYY format'
      };
    }
    // If date is in the correct format, check for validity
    const dateObjectValue = new Date(controlDate);
    const validDate = !isNaN(dateObjectValue.valueOf());
    // If the date happens to be Feb 29 for a non leap year, js will convert that to March 1 within the
    // buildFriendlyDateFromJsDate() that creates a new Date object from the given parameter
    const validInvalidDate = DateUtility.buildFriendlyDateFromJsDate(dateObjectValue);
    // Second conditional compares original user input with js converted date to catch 2/29 -> 3/1
    if (!validDate || validInvalidDate != formattedStringDateValue) {
      return {
        invalidDate: 'Not a valid calendar date'
      };
    }
    // If the date is valid, check that it is not a future date
    const today = new Date();
    const dateToCompare = (controlDate instanceof Date) ? controlDate : dateObjectValue;
    if (dateToCompare.valueOf() > today.valueOf()) {
      return {
        noFutureDate: 'Cannot be a future date'
      };
    }
    return null;
  }

  public MinDate(minDate: Date): ValidationErrors {
    return (control: FormControl) => {
      const controlDate = control.value;
      if (controlDate instanceof Date) {
        if (controlDate.valueOf() < minDate.valueOf()) {
          return {
            minDate: 'Less than the min date'
          };
        }
      } else if (typeof (controlDate) === 'string') {
        const dateValue = new Date(controlDate);
        if (isNaN(dateValue.valueOf())) {
          // Not a valid date; return null since a date check cannot be made
          return null;
        } else if (dateValue.valueOf() < minDate.valueOf()) {
          return {
            minDate: 'Less than the min date'
          };
        }
      }
      return null;
    };
  }

  public MaxDate(maxDate: Date): ValidationErrors {
    return (control: FormControl) => {
      const controlDate = control.value;
      if (controlDate instanceof Date) {
        if (controlDate.valueOf() > maxDate.valueOf()) {
          return {
            maxDate: 'Greater than the max date'
          };
        }
      } else if (typeof (controlDate) === 'string') {
        const dateValue = new Date(controlDate);
        if (isNaN(dateValue.valueOf())) {
          // Not a valid date; return null since a date check cannot be made
          return null;
        } else if (dateValue.valueOf() > maxDate.valueOf()) {
          return {
            maxDate: 'Greater than the max date'
          };
        }
      }
      return null;
    };
  }

  // Disabled components for Mobile Clinic release still using this. Need to move it over to dateFormatAndValidity and
  // then can remove noFutureDate().
  public noFutureDate(control: FormControl): ValidationErrors {
    const today = new Date();
    const controlDate = control.value;
    if (controlDate instanceof Date) {
      if (controlDate.valueOf() > today.valueOf()) {
        return {
          noFutureDate: 'Cannot be a future date'
        };
      }
    } else if (typeof (controlDate) === 'string') {
      const dateValue = new Date(controlDate);
      if (isNaN(dateValue.valueOf())) {
        // Not a valid date; return null since a date check cannot be made
        return null;
      } else if (dateValue.valueOf() > today.valueOf()) {
        return {
          noFutureDate: 'Cannot be a future date'
        };
      }
    }
    return null;
  };

  public ZipCodeInvalid(control: FormControl): ValidationErrors {
    const controlVal = control.value;
    if (controlVal.length !== 5 && controlVal.length !== 0) {
      return {
        zipCodeInvalid: 'Please enter a valid Zip Code'
      };
    }
    return null;
  }

  public PhoneInvalid(inputMaskService: InputMaskService): ValidationErrors {
    return (control: FormControl) => {
      let controlVal = control.value;
      if (controlVal) {
        controlVal = inputMaskService.unmaskValue(controlVal, inputMaskService.phoneAlias);
        if (controlVal.length !== 10) {
          return {
            phoneInvalid: 'Please enter a valid phone number'
          };
        }
      }
      return null;
    };
  }

  public CheckboxRequired(control: FormControl): ValidationErrors {
    let controlVal = control.value;
    if (!controlVal) {
      return {
        checkboxRequired: 'Please select checkbox'
      };
    }
    return null;
  }

  public AlphaNumeric(control: FormControl): ValidationErrors {
    const validCharacterRegex = /^([A-Za-z]|[0-9])+$/;

    let val = control.value;
    if (val && val !== null && val.toString().trim().length !== 0) {
      if (!validCharacterRegex.test(val)) {
        return {
          alphaNumeric: 'Only alpha-numeric values are allowed'
        };
      }
    }
    return null;
  }

  public MiddleInitial(control: FormControl): ValidationErrors {
    const controlValue = control.value;
    const nameRegEx = /^[a-zA-Z]*$/;
    if (controlValue) {
      const result = controlValue.match(nameRegEx);
      if (result === null) {
        return {
          middleInitial: 'Not a valid middle initial'
        }
      }
    }
    return null;
  }

  public StateCodeInvalid(control: FormControl): ValidationErrors {
    const controlValue: string = <string>control.value;
    if (controlValue) {
      const isValidStateAbbreviation: boolean = FormConstants.stateCodes.indexOf(controlValue.toUpperCase()) > -1;
      if (!isValidStateAbbreviation) {
        return {
          stateCodeInvalid: 'Not a valid state abbreviation'
        }
      }
    }
    return null;
  }

  public shouldBeTrue(control: FormControl): ValidationErrors {
    const controlValue = returnBooleanFromFormState(control.value);
    if (isNullOrUndefined(controlValue) || !controlValue) {
      return {
        valueNotTrue: 'Value is not true'
      }
    }
  }

  // TODO - joeymc: Discuss with team on using Validators.required on dropdown items with blank options
  public DropdownSelectionRequired(control: FormControl): ValidationErrors {
    const controlValue = <string>control.value;
    if (isNullOrUndefined(controlValue) || controlValue.length === 0) {
      return {
        dropdownSelectionRequired: 'Dropdown selection is required'
      }
    }
    return null;
  }

  // the regex here will allow the following patterns:  1, 1.1, 1.11, 0.1, 1.0 and 1.
  // The following patterns are excluded by the regex:  100, 1000, -1, 3.36, etc., and of course anything non-numeric
  // note, a 'trailing' decimal (as in `3.` or `10.`) is allowed since javascript
  //    treats it as a valid numeric value.
  public overZeroUnder100UpToOneDecimalPlaceValidator(control: FormControl): ValidationErrors {
    const controlValue: string = control.value ? control.value.toString() : '';
    if (controlValue) {
      const bValueRegex = /^\d{1,2}((\.)(\d{1})?)?$/;
      const regexMatch = controlValue.match(bValueRegex);
      const isValidBValue: boolean = !isNullOrUndefined(regexMatch);
      if (!isValidBValue) {
        return {
          overZeroUnder100UpToOneDecimalPlaceValidator: 'This measurement must be blank or in the range of 0-99'
        };
      }
    }
    return null;
  }

  /***** END - PUBLIC FUNCTIONS *****/
}
