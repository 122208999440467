import { ReferenceListValue, ReferenceListViewModel } from '../../../models/reference-list-view-model';
import {HealthCoverage} from '../../../models/claim';

export enum HealthCoverageVisibleValues {
  MEDICARE = 'Medicare (Medicare#)',
  MEDICAID = 'Medicaid (Medicaid#)',
  TRICARE = 'Tricare (ID# DOD#)',
  CHAMPVA = 'Champ VA (Member ID#)',
  GROUP_HEALTH_PLAN = 'Group Health Plan (ID#)',
  FECA_BLK_LUNG = 'FECA Black Lung (ID#)',
  OTHER = 'Other (ID#)'
}

export const DateOfServiceConstants = {
  healthCoverageTypes: [
    new ReferenceListValue(HealthCoverage.MEDICARE, HealthCoverageVisibleValues.MEDICARE).viewModel,
    new ReferenceListValue(HealthCoverage.MEDICAID, HealthCoverageVisibleValues.MEDICAID).viewModel,
    new ReferenceListValue(HealthCoverage.TRICARE, HealthCoverageVisibleValues.TRICARE).viewModel,
    new ReferenceListValue(HealthCoverage.CHAMPVA, HealthCoverageVisibleValues.CHAMPVA).viewModel,
    new ReferenceListValue(HealthCoverage.GROUP_HEALTH_PLAN, HealthCoverageVisibleValues.GROUP_HEALTH_PLAN).viewModel,
    new ReferenceListValue(HealthCoverage.FECA_BLK_LUNG, HealthCoverageVisibleValues.FECA_BLK_LUNG).viewModel,
    new ReferenceListValue(HealthCoverage.OTHER, HealthCoverageVisibleValues.OTHER).viewModel,
  ] as ReferenceListViewModel[]
};
