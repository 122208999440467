import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApplicationConstants } from '../../../../../constants/application.constants';
import { of } from 'rxjs';
import { HttpClientService } from '../http-client-service';
import {ContactLensResponse} from '../../../../../../models/contactLens';

@Injectable({
  providedIn: 'root'
})
export class ContactLensDataService extends HttpClientService {

  constructor(protected injector: Injector) {
    super(injector, ApplicationConstants.dataServiceClientIDs.contactLensServiceDataServices);
  }

  private getContactLensUrl(): string {
    const contactLensApi = `${this.environment.apiUrl}/${ApplicationConstants.api.contactLensApi}`;
    return `${contactLensApi}/contactlens`;
  }

  private getClaimsOnlineReferenceUrl(): string {
    const claimsOnlineReferenceApi = `${this.environment.apiUrl}/${ApplicationConstants.api.claimsOnlineReferenceApi}`;
    return `${claimsOnlineReferenceApi}/claims-online-reference`;
  }

  /**
   * Generic implementation of API calls to the lens catalog.
   *
   * @param operation - typed endpoint to retrieve from
   * @param endpoint - path the resource
   */
  getContactLensItem<T>(operation: string, endpoint: string): Observable<ContactLensResponse<T>> {
    const url: string = `${this.getContactLensUrl()}/${endpoint}`;

    return this.http.get<ContactLensResponse<T>>(url, { headers: this.getHttpHeaders(ApplicationConstants.api.contactLensApi)}).pipe(
      map((contactLensResponse: ContactLensResponse<T>) => {
        return contactLensResponse;
      }),
      catchError((error: any) => {
        this.handleError(operation, error);
        return of(undefined);
      })
    );
  }

  getClaimsOnlineReferenceItem<T>(operation: string, endpoint: string): Observable<ContactLensResponse<T>> {
    const url: string = `${this.getClaimsOnlineReferenceUrl()}/${endpoint}`;

    return this.http.get<ContactLensResponse<T>>(url, { headers: this.getHttpHeaders(ApplicationConstants.api.claimsOnlineReferenceApi)}).pipe(
      map((contactLensResponse: ContactLensResponse<T>) => {
        return contactLensResponse;
      }),
      catchError((error: any) => {
        this.handleError(operation, error);
        return of(undefined);
      })
    );
  }
}
