import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {ApplicationConstants, ErrorTypes, UserTypeQualifier} from '../../../../common/constants/application.constants';
import {MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  isNullOrUndefined,
  trimWhitespaceFromControlValue,
  onKeypressEventRadiobutton,
} from '../../../../common/utility';
import {ErrorWrapperConfig} from '../../../../common/components/error-wrapper/error-wrapper.component';
import {environment} from '../../../../../environments/environment';
import {HttpClientService} from '../../../../common/services/data-model/http/http-client-data/http-client-service';
import {FeedBackFormRequest} from '../../../../models/FeedbackFormRequest';
import {AppDataService} from '../../../../common/services/data-model/http/http-client-data/app-data/app-data.service';

enum ControlDefaults {
  Compliment = 'Compliment',
  Suggestion = 'Suggestion',
  Problem = 'Problem',
  Other = 'Other'
}

@Component({
  selector: 'app-provide-feedback-modal',
  templateUrl: 'provide-feedback.-modal.component.html',
  styleUrls: ['provide-feedback-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProvideFeedbackModalComponent extends HttpClientService implements OnInit {

  errorWrapperConfig = {
    firstName: new ErrorWrapperConfig(),
    lastName: new ErrorWrapperConfig(),
    feedbackOptions: new ErrorWrapperConfig(),
    feedbackFormMessage: new ErrorWrapperConfig()
  };

  provideFeedbackForm: FormGroup;
  // Assigning functions from utility class to variables so that these can be accessed  in template
  onKeypressEventRadiobutton = onKeypressEventRadiobutton;
  controlDefaults = ControlDefaults;
  title = 'recaptcha';

  /***** START - PRIVATE MEMBERS *****/
  private readonly _maxNameLength: number;
  private readonly _maxMessageLength: number;
  private readonly _siteKey: string;
  private feedbackOptions: any;
  private feedbackFormDetails = new FeedBackFormRequest();

  /***** END - PRIVATE MEMBERS *****/

  get maxNameLength(): number {
    return this._maxNameLength;
  }

  get maxMessageLength(): number {
    return this._maxMessageLength;
  }

  get siteKey(): string {
    return this._siteKey;
  }

  get formIsValid(): boolean {
    let checkForm: boolean = false;
    if (this.provideFeedbackForm.value.feedbackFormMessage && this.provideFeedbackForm.value.feedbackOptions &&
        this.provideFeedbackForm.value.firstName && this.provideFeedbackForm.value.lastName && this.provideFeedbackForm.controls.recaptcha.touched &&
        !this.isCaptchaExpired ) {
        checkForm = true;
    }
    return checkForm;
  }

  /***** START - PUBLIC MEMBERS *****/
  constructor(
    private dialogRef: MatDialogRef<ProvideFeedbackModalComponent>,
    private formBuilder: FormBuilder,
    private appDataService: AppDataService,
    public injector: Injector,
  ) {
    super(injector, ApplicationConstants.dataServiceClientIDs.appDataService);
    this._maxNameLength = ApplicationConstants.addressMaxLength;
    this._maxMessageLength = ApplicationConstants.messageMaxLength;
    this._siteKey = environment.siteKey;
  }

  public isCaptchaExpired: boolean;

  /***** START - PRIVATE FUNCTIONS *****/
  private buildForm(): void {
    this.provideFeedbackForm = this.formBuilder.group({
      firstName: [undefined, [Validators.required, Validators.pattern(ApplicationConstants.ValidNameRegex)]],
      lastName: [undefined, [Validators.required, Validators.pattern(ApplicationConstants.ValidNameRegex)]],
      feedbackOptions: [this.feedbackOptions, [Validators.required]],
      feedbackFormMessage: [undefined, [Validators.required, Validators.minLength(10)]],
      recaptcha: [this.siteKey, Validators.required ]
    });
  }

  private buildErrorWrapperConfig(): void {
      this.errorWrapperConfig = {
        firstName: {
          control: this.provideFeedbackForm.controls.firstName,
          errors: [{
            validatorType: ErrorTypes.Required,
            errorMessage: ApplicationConstants.invalidFirstNameMessage(UserTypeQualifier.Patient)
          },
          ]
        },
        lastName: {
          control: this.provideFeedbackForm.controls.lastName,
          errors: [
            {
              validatorType: ErrorTypes.Required,
              errorMessage: ApplicationConstants.invalidLastNameMessage(UserTypeQualifier.Patient)
            },
          ]
        },
        feedbackOptions: {
          control: this.provideFeedbackForm.controls.feedbackOptions,
          errors: [{
            validatorType: ErrorTypes.Required,
            errorMessage: 'A Type of Feedback is Required'
          },
          ]
        },
        feedbackFormMessage: {
          control: this.provideFeedbackForm.controls.feedbackFormMessage,
          errors: [{
            validatorType: ErrorTypes.Required,
            errorMessage: 'Please enter a feedback message'
          },
          ]
        },
      };
    }

  /***** END - PRIVATE FUNCTIONS *****/

  /***** START - PUBLIC FUNCTIONS *****/
  // removes space if name starts with space
  trimNameControl(control: AbstractControl): void {
    trimWhitespaceFromControlValue(control);
  }

  public recaptchaExpired() {
    this.isCaptchaExpired = true;
  }

  public reCaptchaOnValid() {
    this.isCaptchaExpired = false;
  }

  // On Submit button clicked
  public onSubmitClick() {
    this.feedbackFormDetails = new FeedBackFormRequest();
    this.feedbackFormDetails._firstName = this.provideFeedbackForm.controls.firstName.value;
    this.feedbackFormDetails._lastName = this.provideFeedbackForm.controls.lastName.value;
    this.feedbackFormDetails._typeOfFeedback = this.provideFeedbackForm.controls.feedbackOptions.value;
    this.feedbackFormDetails._feedbackMessage = this.provideFeedbackForm.controls.feedbackFormMessage.value;
    // Subscribe to the Feedback Form Post and handle response appropriately
    this.appDataService.postFeedbackForm(this.feedbackFormDetails).subscribe((feedbackFormResponse) => {
      if (!isNullOrUndefined(feedbackFormResponse)) {
      this.messageService.showConfirmationSnackbar(`Feedback Form submitted successfully`);
    } else {
      throw new Error(ApplicationConstants.errorMessages.feedbackFormPeFailMessage);
    }
    });
  }

  // On Close button clicked
  public onCloseClick(): void {
    this.dialogRef.close(false);
  }

  /***** END - PUBLIC FUNCTIONS *****/

  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.buildForm();
    this.buildErrorWrapperConfig();
  }

  /***** END - EVENT HANDLERS *****/
}
