import {Component, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ApplicationConstants} from '../../../common/constants/application.constants';

@Component({
  selector: 'dialog-overview-example',
  templateUrl: 'leave-claim-form-warning-modal.html',
  styleUrls: ['leave-claim-form-warning-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LeaveClaimFormWarningModalComponent {

  constructor(private dialogRef: MatDialogRef<LeaveClaimFormWarningModalComponent>) {}

  public saveButton() {
    this.dialogRef.close(ApplicationConstants.modalSelectionSave);
  }

  public leaveFormButton() {
    this.dialogRef.close(ApplicationConstants.modalSelectionLeaveForm);
  }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }
}
