import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClaimFormItem } from '../../../common/classes/claim-form-item';
import { ClaimsService } from '../../../common/services/data-model/app/claims/claims.service';
import {Claim, SoftAndHardValidationMessages, ValidationMessage} from '../../../models/claim';
import { Subscription } from 'rxjs';
import { ApplicationConstants, ClaimStatus, ErrorTypes } from '../../../common/constants/application.constants';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { InputMaskService } from '../../../common/services/support/input-mask/input-mask.service';
import { CustomValidatorsService } from '../../../common/services/support/custom-validators/custom-validators.service';
import { ComponentMaskComponent } from '../../../common/components/component-mask/component-mask.component';
import { ClaimCardsToUpdate } from '../../../models/claimCardsToUpdate';
import { DatePickerConfiguration } from '../../../common/components/date-picker/date-picker.component';
import { ExternalServiceLocation } from 'src/app/models/externalServiceLocation';
import { ExternalServiceLocationService } from 'src/app/common/services/data-model/app/external-service-location/external-service-location.service';
import {DateOfServiceConstants} from './date-of-service.constants';
import {DateUtility, isNullOrUndefined, simpleSlugFromString} from '../../../common/utility';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';

@Component({
  selector: 'app-date-of-service',
  templateUrl: './date-of-service.component.html',
  styleUrls: ['./date-of-service.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateOfServiceComponent extends ClaimFormItem implements OnInit, OnDestroy {

  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    private claimsService: ClaimsService,
    private externalServiceLocationService: ExternalServiceLocationService,
    private customValidatorsService: CustomValidatorsService,
    public inputMaskService: InputMaskService,
    private changeDetector: ChangeDetectorRef,
    private claimEditService: ClaimEditService
  ) {
    super(injector);
  }


  /***** START - PRIVATE MEMBERS *****/
  private _originalDateOfService: string;
  private activeClaim: Claim;
  private originalClaim: Claim;
  private observableSubscriptions: Subscription[] = [];
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;
  /***** END - PRIVATE MEMBERS *****/

  @ViewChild('dateOfServiceControl', {read: ElementRef, static: true}) dateOfServiceControl: ElementRef;

  /***** START - PUBLIC MEMBERS *****/
  id = ApplicationConstants.componentIDs.dateOfService;
  title = 'Patient Name';
  name: string;
  authorization: string;
  dateOfServiceForm: FormGroup;
  errorWrapperConfig = {
    healthCoverages: undefined
  };
  dateOfServiceDatePickerConfig: DatePickerConfiguration;
  selectOptions = {
    healthCoverages: DateOfServiceConstants.healthCoverageTypes
  };
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;
  /***** END - PUBLIC MEMBERS *****/


  /***** START - PROPERTY ACCESSORS *****/
  get originalDateOfService(): string {
    return this._originalDateOfService;
  }

  set originalDateOfService(newDateOfService: string) {
    this._originalDateOfService = newDateOfService;
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }
  /***** END - PROPERTY ACCESSORS *****/


  /***** START - PRIVATE FUNCTIONS *****/
  private buildDatePickerConfiguration(): void {
    this.dateOfServiceDatePickerConfig = {
      control: this.dateOfServiceForm.controls.dateOfService,
      controlName: 'dateOfService',
      errorWrapperId: {
        defaultValidations: 'date-of-service-error',
        minDate: 'date-of-service-min-date-error'
      },
      attributes: {
        id: 'date-of-service',
        datePickerId: 'date-of-service-date-picker',
        datePickerToggleId: 'date-of-service-date-picker-toggle',
        name: 'dateOfService'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the date of service'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Date of Service must be in MM/DD/YYYY format'
        }
      ]
    };
  }

  private buildPatientData(claim: Claim): void {
    let firstName = '', middle = '', lastName = '';
    if (claim.patient && claim.patient.name) {
      firstName = claim.patient.name.firstName;
      middle = claim.patient.name.middle ? claim.patient.name.middle : '';
      lastName = claim.patient.name.lastName;
    }
    this.name = `${firstName} ${middle} ${lastName}`;
    this.authorization = claim.trackingNumber;
  }

  private buildForm(): void {
    const { lastDoctorUpdatedDateTime } = this.originalClaim;
    let dateOfService = DateUtility.isValidDate(this.originalClaim.dateOfService) ? this.originalClaim.dateOfService : undefined;
    this.originalDateOfService = DateUtility.buildFriendlyDateFromJsDate(dateOfService);
    const insuranceTypeCode = this.originalClaim.insuranceTypeCode;

    // When lastDoctorUpdatedDateTime is not set yet consider DOS as empty. This is the case when PatientEncounter gets created initially with a VSR effectiveDate as DOS, we do not want to consider that as DOS,
    // we want to enforce user to enter DOS.
    if (lastDoctorUpdatedDateTime === undefined) {
      dateOfService = undefined;
    }
    this.dateOfServiceForm = this.formBuilder.group({
      dateOfService: [DateUtility.buildFriendlyDateFromJsDate(dateOfService),
        [
          Validators.required,
          this.customValidatorsService.dateFormatAndValidity,
          this.customValidatorsService.MinDate(ApplicationConstants.minDate)
        ]],
      healthCoverages: [insuranceTypeCode, [Validators.required]]
    });
    this.dateOfServiceForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe((viewModel) => {
      // Build up the data model based on changes to the view model
      this.updateDataModelFromViewModel();
    });
    this.dateOfServiceForm.controls.dateOfService.valueChanges.subscribe(() => {
      this.dateOfServiceUpdate();
    });
  }

  private setViewModelFromDataModel(dataModel: Claim) {
    this.buildPatientData(dataModel);
  }

  private updateDataModelFromViewModel() {
    const dateOfServiceControl = this.dateOfServiceForm.controls.dateOfService;
    const healthCoverages = this.dateOfServiceForm.controls.healthCoverages;

    Object.assign(this.activeClaim, {
      dateOfService: DateUtility.isValidDate(dateOfServiceControl.value) ? dateOfServiceControl.value : this.activeClaim.dateOfService
    });
    this.activeClaim.insuranceTypeCode = healthCoverages.value.toString();
    // Update the active claim in the claim service
    if (this.dateOfServiceCardHasChanged()) {
      this.claimsService.setActiveClaim(this.activeClaim, this.id);
    }
    if (dateOfServiceControl.value === undefined || dateOfServiceControl.invalid) {
      this.viewStateService.maskCards();
    } else if (dateOfServiceControl.enabled && this.originalClaim.status !== ClaimStatus.SubmittedClaim && this.originalClaim.status !== ClaimStatus.SubmittedLab ) {
      this.viewStateService.unmaskCards();
    }
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      healthCoverages: {
        control: this.dateOfServiceForm.controls.healthCoverages,
        errors: [{
          validatorType: ErrorTypes.AtLeastOneHealthCoverage,
          errorMessage: 'Please select the patient\'s Health Coverage'
        }
        ]
      }
    };
  }

  private dateOfServiceCardHasChanged(): boolean {
    const { insuranceTypeCode: insuranceTypeCodeFromCard, dateOfService: dateOfServiceFromCard } = this.activeClaim;
    const dateOfServiceFromCardString = DateUtility.buildFriendlyDateFromJsDate(dateOfServiceFromCard);

    const { insuranceTypeCode: insuranceTypeCodeFromService, dateOfService: dateOfServiceFromService } = this.claimsService.getActiveClaim();
    const dateOfServiceFromServiceString = DateUtility.buildFriendlyDateFromJsDate(dateOfServiceFromService);

    return (insuranceTypeCodeFromCard || undefined) !== (insuranceTypeCodeFromService || undefined) ||
    (dateOfServiceFromCardString || undefined) !== (dateOfServiceFromServiceString || undefined);
  }

  /***** END - PRIVATE FUNCTIONS *****/

  toSlugId(value: string): string {
    return simpleSlugFromString(value);
  }

  /**
   * Update the the provider list in the exam card on a date of service change. Calls service locations if the input date
   * has been changed, or if the current doctor list does not exist when a user inputs a previous date that did not return
   * a list of doctors.
   */
  dateOfServiceUpdate(): void {
    const currentDateOfService = this.dateOfServiceForm.controls.dateOfService.value;
    if (DateUtility.isValidDate(currentDateOfService) && (currentDateOfService !== this.originalDateOfService ||
      isNullOrUndefined(this.externalServiceLocationService.onExternalServiceLocation.value))) {
      this.originalDateOfService = currentDateOfService;
      this.externalServiceLocationService.loadExternalServiceLocation(this.activeClaim.providers.href, DateUtility.buildYyyyMmDdDateFromDate(currentDateOfService))
        .subscribe((externalServiceLocation: ExternalServiceLocation) => {
          // Set the date of service to the current valid date passed in on API failure
          if (isNullOrUndefined(externalServiceLocation)) {
            this.dateOfServiceForm.controls.dateOfService.reset();
          }
      });
    }
  }

  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    // when initial landing on claim form, we need to reset date of service card mask to default value
    this.viewStateService.unmaskDateOfServiceCard();
    this.originalClaim = this.claimsService.getOriginalClaim();
    this.registerWithClaimProgressService();
    this.buildPatientData(this.originalClaim);
    this.buildForm();
    this.buildErrorWrapperConfig();
    this.buildDatePickerConfiguration();
    this.observableSubscriptions.push(this.claimsService.onCardsToUpdate.subscribe((onCardsToUpdate: ClaimCardsToUpdate) => {
      this.activeClaim = this.claimsService.getActiveClaim();
      // Set form data if the data exists
      if (onCardsToUpdate.all) {
        this.setViewModelFromDataModel(this.activeClaim);
      }
    }));
    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskDateOfServiceCard.subscribe((mask: boolean) => {
      if (mask) {
        this.disableFormGroupComponents(this.dateOfServiceForm);
        this.componentMask.show();
      } else {
        this.dateOfServiceForm.enable();
        this.componentMask.hide();
      }
    }));
    // When submit finds UI edits detect changes on component
    this.observableSubscriptions.push(this.viewStateService.onSubmitFoundUIEdits.subscribe((hasUIEdits: boolean) => {
      if (hasUIEdits) {
        this.changeDetector.detectChanges();
      }
    }));
    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
          (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }
      }));

    if (this.originalClaim.status === ClaimStatus.SubmittedClaim || this.originalClaim.status === ClaimStatus.SubmittedLab) {
      // When claim is submitted make entire form disabled or read-only including DateOfService component
      this.disableFormGroupComponents(this.dateOfServiceForm);
      this.viewStateService.maskCards();
      this.componentMask.show();
    } else {
      if (this.originalClaim.dateOfService === undefined || !DateUtility.isValidDate(this.originalClaim.dateOfService)
        || this.originalClaim.lastDoctorUpdatedDateTime === undefined) {
        this.viewStateService.maskCards();
      } else {
        this.dateOfServiceForm.enable();
        this.viewStateService.unmaskCards();
      }
    }
  }

  ngOnDestroy() {
    this.observableSubscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
  /***** END - EVENT HANDLERS *****/

}
