<div fxLayout="column">
  <form novalidate [formGroup]="provideFeedbackForm" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
    <mat-card>

      <!-- ############################################################################################################################################################################## -->
      <!-- Title Row for Provider Feedback Modal  -->
      <!-- ############################################################################################################################################################################## -->
      <mat-card-header>
        <mat-card-title>
          <h1 class="nested-column-11"
              id="feedback-modal-title" mat-dialog-title>eClaim Form Feedback</h1>
        </mat-card-title>
        <!-- Close Icon -->
        <button [disableRipple]="true" class="close-button" mat-icon-button>
          <mat-icon (click)="onCloseClick()" id="feedback-modal-close-icon"
                    svgIcon="eclaim-icons:close"></mat-icon>
        </button>
      </mat-card-header>

      <!-- Content -->
      <mat-card-content>

        <!-- ############################################################################################################################################################################## -->
        <!-- First Row In Provider Feedback Modal: "Field Labels" -->
        <!-- ############################################################################################################################################################################## -->
        <div class="margin-top-30"></div>
        <div fxLayout="row" class="margin-top-20 margin-left-20 margin-right-20">
          <!-- First Name Label -->
          <div fxLayout="column" class="nested-column-6">
            <label id="feedback-modal-first-name-label"><span class="required-label-asterisk">*</span>First Name</label>
            <mat-form-field appearance="outline">
              <input matInput id="feedback-modal-first-name" formControlName="firstName" type="text"
                     class="form-control margin-left-20"
                     [maxlength]="maxNameLength" (keyup)="trimNameControl(this.provideFeedbackForm.get('firstName'))"/>
            </mat-form-field>
            <app-error-wrapper id="feedback-modal-first-name-error"
                               [config]="errorWrapperConfig.firstName"></app-error-wrapper>
          </div>

          <!-- Last Name Label -->
          <div fxLayout="column" class="nested-column-6">
            <label id="feedback-modal-last-name-label"><span class="required-label-asterisk">*</span>Last Name</label>
            <mat-form-field appearance="outline">
              <input matInput id="feedback-modal-last-name" formControlName="lastName" type="text" class="form-control"
                     [maxlength]="maxNameLength" (keyup)="trimNameControl(this.provideFeedbackForm.get('lastName'))"/>
            </mat-form-field>
            <app-error-wrapper id="feedback-modal-last-name-error"
                               [config]="errorWrapperConfig.lastName"></app-error-wrapper>
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Second Row In Provider Feedback Modal: "Type of Feedback" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-top-20 margin-left-20 margin-right-20 nested-column-12">
          <!-- Type of Feedback Label -->
          <label id="feedback-modal-type-of-feedback-label"><span class="required-label-asterisk">*</span>Type of Feedback</label>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Third Row In Provider Feedback Card: "Radio Buttons" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="column" class="nested-column-12 margin-left-20 margin-right-20">
          <mat-radio-group fxLayout="row" fxLayoutAlign="start center" name="fontStyle" id="type-of-feedback-options"
                           formControlName="feedbackOptions">
            <mat-radio-button #feedbackCompliment id="type-of-feedback-compliment"
                              value="{{controlDefaults.Compliment}}"
                              (keyup.enter)="onKeypressEventRadiobutton(feedbackCompliment)">Compliment
            </mat-radio-button>
            <mat-radio-button #feedbackSuggestion id="type-of-feedback-suggestion"
                              value="{{controlDefaults.Suggestion}}"
                              fxFlexOffset="30px"
                              (keyup.enter)="onKeypressEventRadiobutton(feedbackSuggestion)">Suggestion
            </mat-radio-button>
            <mat-radio-button #feedbackProblem id="type-of-feedback-problem" value="{{controlDefaults.Problem}}"
                              fxFlexOffset="30px"
                              (keyup.enter)="onKeypressEventRadiobutton(feedbackProblem)">Problem
            </mat-radio-button>
            <mat-radio-button #feedbackOther id="type-of-feedback-other" value="{{controlDefaults.Other}}"
                              fxFlexOffset="30px"
                              (keyup.enter)="onKeypressEventRadiobutton(feedbackOther)">Other
            </mat-radio-button>
          </mat-radio-group>
          <app-error-wrapper id="feedback-modal-feedbackOptions-error" [config]="errorWrapperConfig.feedbackOptions"></app-error-wrapper>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Fourth Row In Provider Feedback Modal: "Message" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="column" class="nested-column-12 margin-top-20 margin-left-20 margin-right-20">
          <!-- Message -->
          <label id="feedback-modal-message-label" class="nested-column-12"><span
            class="required-label-asterisk">*</span>Message</label>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Fifth Row In Provider Feedback Modal: "Message Box" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="column" class="nested-column-12 margin-top-10 margin-left-20 margin-right-20">
          <textarea matInput id="feedback-modal-message-box" [maxlength]="maxMessageLength" class="messageBox " rows="6"
                    cols="8" formControlName="feedbackFormMessage"></textarea>
          <app-error-wrapper id="feedback-modal-message-box-error" [config]="errorWrapperConfig.feedbackFormMessage"></app-error-wrapper>
        </div>


        <!-- ############################################################################################################################################################################## -->
        <!-- Sixth Row In Provider Feedback Modal: "Required Field" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row">
          <div fxLayout="column" class="nested-column-9"></div>
          <!-- Message -->
          <div fxLayout="column" class="nested-column-3 margin-left-20">
            <label id="feedback-modal-required-field-label"><span
              class="required-label-asterisk">*</span>Required Field</label>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Seventh Row In Provider Feedback Modal: "Recaptcha" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="column" class="nested-column-12 margin-top-20 margin-left-20 margin-right-20">
          <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha" on-expire="recaptchaExpired()" on-success="reCaptchaOnValid()">
          </ngx-recaptcha2>
        </div>
      </mat-card-content>

      <!-- Footer Line -->
      <div class="margin-top-20"></div>
      <hr/>

      <!-- ############################################################################################################################################################################## -->
      <!-- Eight Row In Provider Feedback Modal: "Submit & Cancel Buttons" -->
      <!-- ############################################################################################################################################################################## -->
      <mat-card-actions>
        <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="end center"
             class="margin-top-20 margin-bottom-20 margin-right-20">

          <!-- Cancel Button  -->
          <button mat-stroked-button color="primary" class="btn btn-outline-info btn-extra-pad" (click)="onCloseClick()"
                  id="feedback-modal-cancel-button">Cancel</button>
          <div fxFlexOffset="30px"></div>

          <!-- Submit Button-->
          <button mat-dialog-close class="btn btn-primary btn-extra-pad" (click)="onSubmitClick()"
                  id="feedback-modal-submit-button" [disabled]="!formIsValid">Submit</button>
          <div fxFlexOffset="15px"></div>

        </div>
      </mat-card-actions>

      <div class="margin-bottom-30"></div>

    </mat-card>
  </form>
</div>
