/***** Eligibility domain models *****/
export interface Eligibility {
  networks: EligibilityNetwork[];
  suspends: EligibilitySuspend[];
  productPackageViewModelList?: EligibilityProductPackage[];
  clientMessages?: string[];
}

export interface EligibilityNetwork {
  packages: EligibilityProductPackage[];
  networkId: string;
}

export interface EligibilityProductPackage {
  name: string;
  eligibleItems: EligibleItem[];
  packageInLieuOfs?: string[];
  businessName: string;
  description: string;
  primaryProduct: boolean;
  createVisionServiceRequest: {
    href: string;
    type: string;
    rel: string;
  };
  allAvailableServices?: boolean;
  hasAnyAvailableServices?: boolean;
  hasOnlyNonAuthorizableItems?: boolean;
  hasOnlyNonAuthorizableItemsAndNoDescription?: boolean;
  nonAuthPackageLongDescription?: string;
  coverages?: EligibilityCoverage[];
  eligibleItemInLieuOfs?: Map<string, string[]>;
}

export interface EligibleItem {
  description: string;
  statusDescription: string;
  authorizable: boolean;
  productCatalogKey: string;
  isSelected?: boolean;
  inLieuOfIsSelected?: boolean;
}

export interface EligibleItemsVsr {
  eligibleItemVsrDto: EligibleItemVsrDto;
  hasAnyAvailableServices?: boolean;
}

export interface EligibleItemVsrDto {
  name: string;
  description?: string;
  status?: string;
  availability?: string;
}

export interface EligibilitySuspend {
  code: string;
  description: string;
}

export interface EligibilityCoverage {
  productCatalogKeys: string[];
  description: string;
  inLieuOf?: {
    productCatalogKeys: string[];
  };
}

/***** Eligibility suspend codes, map to suspend response in application constants (used in EligibilityService) *****/
export enum EligibilitySuspendCode {
  ELIGIB0001 = 'ELIGIB0001',
  ELIGIB0002 = 'ELIGIB0002',
  AUTHOR0001 = 'AUTHOR0001',
  SRVCLT0010 = 'SRVCLT0010',
  PBELIG0005 = 'PBELIG0005',
}
