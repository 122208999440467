<div fxLayout="column">
  <mat-card>

    <!-- ############################################################################################################################################################################## -->
    <!-- Header -->
    <!-- ############################################################################################################################################################################## -->
    <mat-card-header>
      <mat-card-title>
        <h1 class="nested-column-11" id="claim-details-patient-name-header-title" mat-dialog-title>{{claimStatus}} Claim Detail</h1>
      </mat-card-title>
      <!-- Close Icon -->
      <button [disableRipple]="true" class="close-button" mat-icon-button>
        <mat-icon id="claim-details-close-icon" (click)="onCloseClick()" svgIcon="eclaim-icons:close"></mat-icon>
      </button>
    </mat-card-header>

    <!-- ############################################################################################################################################################################## -->
    <!-- Body -->
    <!-- ############################################################################################################################################################################## -->
    <mat-card-content>

      <!-- ############################################################################################################# -->
      <!-- First Row - Top Level Info -->
      <!-- ############################################################################################################# -->
      <div fxLayout="row" class="margin-bottom-30 margin-top-30">

        <!-- ################################################################################## -->
        <!-- First Column - Program Id, Patient Name, Relationship, Claim Number etc... -->
        <!-- ################################################################################## -->
        <div class="nested-column-4">
          <div fxLayout="row">
            <!-- Patient Name Label -->
            <label class="eclaim-body-text-bold custom-nested-column-3_80" id="claim-details-patient-name-label">Patient Name:</label>
            <label class="eclaim-body-text custom-nested-column-8_20" id="claim-details-patient-name-label-value">{{formattedPatientName}}</label>
          </div>
          <!-- Relationship Label -->
          <div fxLayout="row">
            <label class="eclaim-body-text-bold custom-nested-column-3_80" id="claim-details-patient-relationship-label">Relationship:</label>
            <label class="eclaim-body-text custom-nested-column-8_20" id="claim-details-patient-relationship-label-value">{{claimSummary.memberPatientInfo.patientRelationship}}</label>
          </div>
          <!-- Claim Number Label -->
          <div fxLayout="row">
            <label class="eclaim-body-text-bold custom-nested-column-3_80" id="claim-details-claim-number-label">Claim Number:</label>
            <label class="eclaim-body-text custom-nested-column-8_20" id="claim-details-claim-number-label-value">{{claimSummary.authorizationNumber}}</label>
          </div>
          <!-- Service Date Label -->
          <div fxLayout="row">
            <label class="eclaim-body-text-bold custom-nested-column-3_80" id="claim-details-service-date-label">Service Date:</label>
            <label class="eclaim-body-text custom-nested-column-8_20" id="claim-details-service-date-label-value">{{formattedServiceDate}}</label>
          </div>

        </div>

        <!-- ################################################################################## -->
        <!-- Second Column - Service Date, Lab, Co-Pay etc... -->
        <!-- ################################################################################## -->
        <div class="nested-column-4">
          <!-- Lab Label -->
          <div fxLayout="row">
            <label [ngClass]="isPaidClaimSummary ? 'nested-column-4' : 'nested-column-2'" class="eclaim-body-text-bold" id="claim-details-lab-name-label">Lab:</label>
            <label [ngClass]="isPaidClaimSummary ? 'nested-column-8' : 'nested-column-10'" class="eclaim-body-text" id="claim-details-lab-name-label-value">{{claimSummary.labName}}</label>
          </div>
          <!-- Status Label -->
          <div fxLayout="row">
            <label [ngClass]="isPaidClaimSummary ? 'nested-column-4' : 'nested-column-2'" class="eclaim-body-text-bold" id="claim-details-status-label">Status:</label>
            <label [ngClass]="isPaidClaimSummary ? 'nested-column-8' : 'nested-column-10'" class="eclaim-body-text" id="claim-details-status-label-value">{{claimSummary.statusCodeDescription}}</label>
          </div>
          <!-- Check Number Label -->
          <div *ngIf="isPaidClaimSummary" fxLayout="row">
            <label class="eclaim-body-text-bold nested-column-4" id="claim-details-check-number-label">Check Number:</label>
            <label class="eclaim-body-text nested-column-8" id="claim-details-check-number-label-value">{{claimSummary.physicianInvoiceCheckNumber}}</label>
          </div>
          <!-- Paid Date Label -->
          <div *ngIf="isPaidClaimSummary" fxLayout="row">
            <label class="eclaim-body-text-bold nested-column-4" id="claim-details-paid-date-label">Paid Date:</label>
            <label class="eclaim-body-text nested-column-8" id="claim-details-paid-date-label-span">{{formattedPaidDate}}</label>
          </div>
        </div>

        <!-- ################################################################################## -->
        <!-- Third Column - Service Date, Lab, Co-Pay etc... -->
        <!-- ################################################################################## -->
        <div class="nested-column-4">
          <!-- Program Id Label -->
          <div fxLayout="row">
            <label class="eclaim-body-text-bold custom-nested-column-2_2" id="claim-details-program-id-label">Plan:</label>
            <label class="custom-nested-column-9_8" id="claim-details-program-id-label-value">{{claimSummary.productPackageBusinessName}}</label>
          </div>
          <!-- Co-Pay Label -->
          <div fxLayout="row">
            <label class="eclaim-body-text-bold custom-nested-column-2_2" id="claim-details-co-pay-label">Co-Pay:</label>
            <label class="eclaim-body-text custom-nested-column-9_8" id="claim-details-co-pay-label-value">{{formattedCopayDisplay}}</label>
          </div>

        </div>
      </div>

      <!-- ############################################################################################################# -->
      <!-- Second Row - Professional, Material and Total Payable Info -->
      <!-- ############################################################################################################# -->
      <div fxLayout="row" class="eclaim-body-text">

        <!-- ################################################################################## -->
        <!-- Professional Column -->
        <!-- ################################################################################## -->
        <div class="custom-nested-column-4">
          <mat-list class="nested-column-12">
            <div fxLayout="row" class="eclaim-body-text-bold">
              <label class="custom-nested-column-9" id="claim-details-professional-services">Professional Services</label>
              <label class="custom-nested-column-3" id="claim-details-professional-fees">Fees</label>
            </div>
            <div *ngIf="areProfessionalFeesAvailable">
              <div *ngFor="let professionalFee of claimSummary.feeInfo.professionalFees; index as baseId; let lastItem = last">
                <div fxLayout="row">
                  <mat-list-item class="custom-nested-column-9" id="claim-details-professional-service-description-{{baseId}}">{{professionalFee.professionalFeeServiceDescription}}</mat-list-item>
                  <mat-list-item class="custom-nested-column-3" id="claim-details-professional--service-fee-{{baseId}}">${{formatAmount(professionalFee.professionalFeeServiceAmount)}}</mat-list-item>
                </div>
                <mat-divider *ngIf="!lastItem"></mat-divider>
              </div>
            </div>
          </mat-list>
        </div>

        <!-- ################################################################################## -->
        <!-- Material Column -->
        <!-- ################################################################################## -->
        <div class="custom-nested-column-4">
          <mat-list class="nested-column-12">
            <div fxLayout="row" class="eclaim-body-text-bold">
              <label class="custom-nested-column-9" id="claim-details-material-services">Material</label>
              <label class="custom-nested-column-3" id="claim-details-material-fees">Fees</label>
            </div>
            <div *ngIf="areMaterialFeesAvailable">
              <div *ngFor="let materialFess of claimSummary.feeInfo.materialFees; index as baseId; let lastItem = last">
                <div fxLayout="row">
                  <mat-list-item class="custom-nested-column-9" id="claim-details-material-service-description-{{baseId}}">{{materialFess.materialFeeServiceDescription}}</mat-list-item>
                  <mat-list-item class="custom-nested-column-3" id="claim-details-material--service-fee-{{baseId}}">${{formatAmount(materialFess.materialFeeServiceAmount)}}</mat-list-item>
                </div>
                <mat-divider *ngIf="!lastItem"></mat-divider>
              </div>
            </div>
          </mat-list>
        </div>

        <!-- ################################################################################## -->
        <!-- Total Payable Column -->
        <!-- ################################################################################## -->
        <div class="custom-nested-column-4">
          <mat-list class="nested-column-12">
            <label fxLayout="row" class="eclaim-body-text-bold" id="claim-details-total-payable-header">Payable</label>
            <div fxLayout="row">
              <mat-list-item class="custom-nested-column-9" id="claim-details-total-payable-professional-services">Professional Services</mat-list-item>
              <mat-list-item class="custom-nested-column-3" id="claim-details-total-payable-professional-services-amount">${{totalProfessionalFeesAmount}}</mat-list-item>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row">
              <mat-list-item class="custom-nested-column-9" id="claim-details-total-payable-co-pay">Co-Pay</mat-list-item>
              <mat-list-item class="custom-nested-column-3" id="claim-details-total-payable-co-pay-amount">${{totalCopayAmount}}</mat-list-item>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row">
              <mat-list-item class="custom-nested-column-9" id="claim-details-total-payable-materials">Materials</mat-list-item>
              <mat-list-item class="custom-nested-column-3" id="claim-details-total-payable-materials-amount">${{totalMaterialFeesAmount}}</mat-list-item>
            </div>
          </mat-list>
        </div>
      </div>

      <!-- ############################################################################################################# -->
      <!-- Third Row - Professional Subtotals, Material Subtotals and Total Payable Totals -->
      <!-- ############################################################################################################# -->
      <div fxLayout="row" class="margin-bottom-30 eclaim-body-text">

        <!-- ################################################################################## -->
        <!-- Professional Column -->
        <!-- ################################################################################## -->
        <div class="custom-nested-column-4 totals">
          <mat-divider></mat-divider>
          <div fxLayout="row" class="margin-top-15 margin-bottom-15">
            <mat-list-item class="custom-nested-column-9" id="claim-details-professional-subtotal">Subtotal</mat-list-item>
            <mat-list-item class="custom-nested-column-3" id="claim-details-professional-subtotal-amount">${{totalProfessionalFeesAmount}}</mat-list-item>
          </div>
        </div>

        <!-- ################################################################################## -->
        <!-- Material Column -->
        <!-- ################################################################################## -->
        <div class="custom-nested-column-4 totals">
          <mat-divider></mat-divider>
          <div fxLayout="row" class="margin-top-15 margin-bottom-15">
            <mat-list-item class="custom-nested-column-9" id="claim-details-material-subtotal">Subtotal</mat-list-item>
            <mat-list-item class="custom-nested-column-3" id="claim-details-material-subtotal-amount">${{totalMaterialFeesAmount}}</mat-list-item>
          </div>
        </div>

        <!-- ################################################################################## -->
        <!-- Total Payable Column -->
        <!-- ################################################################################## -->
        <div class="custom-nested-column-4 totals">
          <mat-divider></mat-divider>
          <div fxLayout="row" class="eclaim-body-text-bold margin-top-15 margin-bottom-15">
            <mat-list-item class="custom-nested-column-9" id="claim-details-total-payable">Total Payable</mat-list-item>
            <mat-list-item class="custom-nested-column-3" id="claim-details-total-payable-amount">${{totalPayableAmount}}</mat-list-item>
          </div>
        </div>
      </div>
    </mat-card-content>

    <!-- ############################################################################################################################################################################## -->
    <!--Footer Line-->
    <!-- ############################################################################################################################################################################## -->
    <hr/>

    <!-- ############################################################################################################################################################################## -->
    <!-- Close button -->
    <!-- ############################################################################################################################################################################## -->
    <mat-card-actions>
      <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="end center"
           class="margin-top-20 margin-bottom-20 claim-details-modal">
        <button mat-button class="btn btn-primary btn-extra-pad" id="claim-details-close-button" mat-dialog-close>Close</button>
        <div fxFlexOffset="30px"></div>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
