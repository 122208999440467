import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ExpiredSessionComponent} from './expired-session/expired-session.component';
import {ApplicationConstants} from '../common/constants/application.constants';

const routes: Routes = [
  {
    path: ApplicationConstants.routing.public.expiredSessionPageUrl,
    component: ExpiredSessionComponent
  }, {
    path: '',
    redirectTo: ApplicationConstants.routing.public.expiredSessionPageUrl,
    pathMatch: 'full'
  }, {
    path: ApplicationConstants.routing.public.baseRoute,
    redirectTo: ApplicationConstants.routing.public.expiredSessionPageUrl,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class PublicRoutingModule { }
