<div fxLayout="column">
  <form [formGroup]="giftCertificateSubmissionForm" *ngIf="showForm">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <h1>Gift Certificate Submission</h1>
        </mat-card-title>
      </mat-card-header>

      <!-- ############################################################################################################################################################################## -->
      <!-- First Row In The Gift Certificate Submission Card: "Field Labels" -->
      <!-- ############################################################################################################################################################################## -->
      <div fxLayout="row" class="margin-top-30 margin-bottom-15">
        <!-- Patient ID Label -->
        <label class="input-wrapper-label nested-column-3"><span class="required-label-asterisk">* </span>Patient
          ID</label>
        <!-- Patient First Name Label -->
        <label class="input-wrapper-label nested-column-3"><span class="required-label-asterisk">* </span>Patient First
          Name</label>
        <!-- Patient Last Name Label -->
        <label class="input-wrapper-label nested-column-3"><span class="required-label-asterisk">* </span>Patient Last
          Name</label>
      </div>

      <!-- ############################################################################################################################################################################## -->
      <!-- Second Row In The Gift Certificate Submission Card: "Field Inputs" -->
      <!-- ############################################################################################################################################################################## -->
      <div fxLayout="row">
        <!-- Patient ID Field -->
        <div fxLayout="column" class="nested-column-3">
          <mat-form-field appearance="outline" class="required-mat-form-field">
            <input matInput formControlName="patientId"
                   [maxlength]="9" id="patient-id">
          </mat-form-field>
          <app-error-wrapper [config]="errorWrapperConfig.patientId"></app-error-wrapper>
        </div>

        <!-- Patient First Name Field -->
        <div fxLayout="column" class="nested-column-3">
          <mat-form-field appearance="outline" class="required-mat-form-field">
            <input matInput formControlName="patientFirstName"
                   [maxlength]="firstNameMaxLength"
                   (keyup)="trimName(this.giftCertificateSubmissionForm.get('patientFirstName'))"
                   id="patient-first-name">
          </mat-form-field>
          <app-error-wrapper [config]="errorWrapperConfig.patientFirstName"></app-error-wrapper>
        </div>

        <!-- Patient Last Name Field -->
        <div fxLayout="column" class="nested-column-3">
          <mat-form-field appearance="outline" class="required-mat-form-field">
            <input matInput formControlName="patientLastName"
                   [maxlength]="lastNameMaxLength"
                   (keyup)="trimName(this.giftCertificateSubmissionForm.get('patientLastName'))"
                   id="patient-last-name">
          </mat-form-field>
          <app-error-wrapper [config]="errorWrapperConfig.patientLastName"></app-error-wrapper>
        </div>
      </div>

      <!-- ############################################################################################################################################################################## -->
      <!-- Third Row In The Gift Certificate Submission Card -->
      <!-- ############################################################################################################################################################################## -->
      <div fxLayout="row" class="margin-top-30">
        <!-- Date Of Birth Label -->
        <div fxLayout="column" class="nested-column-3">
          <label class="input-wrapper-label margin-bottom-15"><span class="required-label-asterisk">* </span>Date of
            Birth</label>
          <app-date-picker class="required-mat-form-field" id="gc-submission-dob-date-picker"
                           [datePickerConfiguration]="datePickerConfigurationDateOfBirth"></app-date-picker>
        </div>
        <!-- Patient Effective Date Label -->
        <div fxLayout="column" class="nested-column-3">
          <label class="input-wrapper-label margin-bottom-15"><span class="required-label-asterisk">* </span>Patient
            Effective Date</label>
          <app-date-picker class="required-mat-form-field" id="gc-submission-patient-effective-date-date-picker"
                           [datePickerConfiguration]="datePickerConfigurationPatientEffectiveDate"></app-date-picker>
        </div>
      </div>

      <!-- ############################################################################################################################################################################## -->
      <!-- Fourth Row In The Gift Certificate Submission Card-->
      <!-- ############################################################################################################################################################################## -->
      <div fxLayout="row" class="margin-top-30">
        <!-- spacer -->
        <div class="nested-column-8"></div>
        <!-- Reset Button -->
        <div fxLayout="column" class="margin-bottom-20 nested-column-2">
          <button type="button" class="form-control secondary-cta-button" (click)="resetGCSubmissionForm()">Reset</button>
        </div>
        <!-- Submit Button -->
        <div fxLayout="column" class="margin-bottom-20 nested-column-2">
          <button mat-flat-button type="submit"  class="form-control search-button mat-flat-button mat-primary" (click)="showConfirmationMessage()"
                  [disabled]="!hasValidGiftCertificateSubmission()">Submit
          </button>
        </div>
      </div>
    </mat-card>
  </form>

</div>

