<div fxLayout="column">

  <!-- Header -->
  <div fxLayout="row" class="header-content">
    <div class="nested-column-11 header-font-size">
      <div *ngIf="didDropShipOrReplacementCallFail;then partialSuccess else success" id="successful-submitted-header" mat-dialog-title></div>
      <ng-template #success>Successful Submitted Claim</ng-template>
      <ng-template #partialSuccess>Partial Submission Successful</ng-template>
    </div>

    <div class="nested-column-1">
      <!-- Close Icon -->
      <a class="close-button margin-top-5" (click)="onCloseClick()"
         id="hcpc-cpt-codes-closeButton">
        <mat-icon svgIcon="eclaim-icons:close" id="hcpc-cpt-codes-close-icon"></mat-icon>
      </a>
    </div>

  </div>

  <!-- Body -->
  <mat-dialog-content id="successfully-submitted-claim-modal-message" class="successful-submitted-modal">
    <div fxLayout="column" class="margin-bottom-15">
      <!-- ############################################################################################################################################################################## -->
      <!-- Kaleyedoscope Practice - Fulfillment API Successful -->
      <!-- ############################################################################################################################################################################## -->
      <div fxLayout="row" class=" margin-bottom-10 margin-top-5"
           *ngIf="(practiceParticipatesInKScope && !didDropShipOrReplacementCallFail)">
        <div class="nested-column-9">
          <mat-form-field appearance="outline" fxFill
                          class="kscope-submit-success-control custom-mat-form-field-success-outline">
            <mat-icon matPrefix class="info-circle reports-icon padding-right-5"
                      svgIcon="eclaim-icons:infoCircleIcon">
            </mat-icon>
            <input matInput type="text" autocomplete="off" readonly="readonly"
                   id="frame-submit-success-message"
                   value="{{kScopeBannerTopMessage}}"/>
            <label id="frame-submit-success-message2">{{kScopeBannerBottomMessage}}</label>

          </mat-form-field>
        </div>
        <div class="nested-column-3">
          <img id="kaleyedoscope-logo-slow-selling-frame-orderable" height="50px" width="100px"
               src="assets/images/kaleyedoscopecircle.svg">
        </div>
      </div>

      <!-- ############################################################################################################################################################################## -->
      <!-- Kaleyedoscope Practice - Fulfillment API Fails -->
      <!-- ############################################################################################################################################################################## -->
      <div fxLayout="row" class="margin-bottom-10 margin-top-5"
           *ngIf="(practiceParticipatesInKScope && didDropShipOrReplacementCallFail)">
        <div class="nested-column-9">
          <mat-form-field appearance="outline" fxFill
                          class="kscope-submit-error-control custom-mat-form-field-error-outline">
            <mat-icon matPrefix class="info-circle reports-icon padding-right-5 margin-top-5"
                      svgIcon="eclaim-icons:infoCircleIcon">
            </mat-icon>

            <input matInput type="text" autocomplete="off" readonly="readonly"
                   id="top-selling-unavailable-error-message"
                   value="The frame order cannot be submitted."/>
            <label id="frame-submit-error-message">Please go to <a href="https://account.mymarchon.com/"
                                                                               target="_blank" rel="noopener noreferrer"
                                                                               class="action-link"
                                                                               id="navigate-to-marchon-link">account.mymarchon.com</a> to order the frame.
            </label>
          </mat-form-field>
        </div>
        <div class="nested-column-3">
          <img id="kaleyedoscope-logo-error" height="50px" width="100px" src="assets/images/kaleyedoscopecircle.svg">
        </div>
      </div>


      <div fxLayout="row" class="margin-left-15">
        <div class="nested-column-12">
          <label>This Authorization/Claim has been submitted.
            <br>
            <br>Reports are available. Do you want to view the reports?
          </label>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <!-- Footer Line -->
  <hr class="bottom-margin-hr">

  <!-- Yes/No Buttons -->
  <mat-dialog-actions>

    <!-- YES Button -->
    <button mat-dialog-close class="btn btn-primary btn-extra-pad" (click)="closeDialog()"
            id="successfully-submitted-claim-modal-yes-button">Yes
    </button>

    <!-- NO Button-->
    <div class="margin-left-30">
      <button mat-dialog-close class="btn btn-primary btn-extra-pad" (click)="onCloseClick()"
              id="successfully-submitted-claim-modal-no-button">No
      </button>
    </div>

  </mat-dialog-actions>

</div>
