import { Component, Injector, OnDestroy, OnInit, ViewChild, ViewEncapsulation, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ClaimFormItem } from '../../../common/classes/claim-form-item';
import { ComponentMaskComponent } from '../../../common/components/component-mask/component-mask.component';
import { Subscription } from 'rxjs';
import {Claim, SoftAndHardValidationMessages, ValidationMessage} from '../../../models/claim';
import {
  EclaimLensPrescription,
  SegmentMeasurementType,
  OpticalCenterMeasurementType, BasePrescription
} from '../../../models/lensPrescription';
import { BinocularMonocularEnum, BinocularMonocularTypes } from '../../../models/prescription';
import { LabOrderInformation } from '../../../models/labOrderInformation';
import { ClaimsService } from '../../../common/services/data-model/app/claims/claims.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApplicationConstants, ErrorTypes } from '../../../common/constants/application.constants';
import { ClaimCardsToUpdate } from '../../../models/claimCardsToUpdate';
import { SignedPaddedZeroesPipe } from '../../../common/pipes/signed-padded-zeroes.pipe';
import { CustomValidatorsService } from '../../../common/services/support/custom-validators/custom-validators.service';
import {
  fieldKeyPress,
  ignoreDrop,
  fieldPaste,
  onKeypressEventCheckbox,
  isNullOrUndefined,
  isStringNullUndefinedOrEmptyWithTrim
} from '../../../common/utility';
import {
  isStringNullUndefinedOrEmpty,
  twoDecimalPlaceFormat,
  decimalPipeWrapper,
  setToNullOrValue,
  toDollarAmount, setToUndefinedOrValue,
  oneDecimalPlaceFormatIfDecimalPresentInString
} from '../../../common/utility';
import {
  LensDto,
  CustomizableLensType,
  LensVisionType,
  RightLeftEnum, SpectacleLens,
} from '../../../models/lens';
import { ReferenceListValue } from '../../../models/reference-list-view-model';
import { LabSpecialInstructions, LabSpecialInstructionsType } from '../../../models/labSpecialInstructions';
import { PrescriptionConstants } from './prescription.constants';
import { LensVisionTypes } from '../lens/lens.constants';
import { UsdCurrencyPipe } from '../../../common/pipes/usd-currency.pipe';
import { LensService } from '../../../common/services/data-model/app/lens/lens.service';
import { HtmlConstants } from '../../../common/constants/html.constants';
import {PaddedZeroesPipe} from '../../../common/pipes/padded-zeroes.pipe';
import {ContactLens} from '../../../models/contactLens';
import {LabOrderLabServices} from '../../../models/labOrderLabServices';
import { ClaimEditService } from 'src/app/common/services/support/claim-edit/claim-edit.service';

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SignedPaddedZeroesPipe, PaddedZeroesPipe]
})
export class PrescriptionComponent extends ClaimFormItem implements OnInit, AfterViewInit, OnDestroy {

  /***** START - PRIVATE MEMBERS *****/
  private _originalClaim: Claim;
  private _activeClaim: Claim;
  private _isCustomizableLens: boolean;
  private _oneLensDropdownSelectedValue: string;
  private _balanceLensDropdownSelectedValue: string;
  private _visionTypeDropdownSelectedValue: string;
  private _isContactLensSelected: boolean;
  private _lensValue: LensDto = undefined;
  private observableSubscriptions: Subscription[] = [];
  private isMasked = false;
  private unmasking = false;
  private visionTypeChanged: boolean = false;
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;
  /***** END - PRIVATE MEMBERS *****/

  // Assigning functions from utility class to variables so that these can be accessed  in template
  public fieldKeyPress = fieldKeyPress;
  public ignoreDrop = ignoreDrop;
  public fieldPaste = fieldPaste;
  public onKeypressEventCheckbox = onKeypressEventCheckbox;
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private claimService: ClaimsService,
    private signedPaddedZeroes: SignedPaddedZeroesPipe,
    private paddedZeroes: PaddedZeroesPipe,
    private customValidators: CustomValidatorsService,
    private changeDetector: ChangeDetectorRef,
    private lensService: LensService,
    private claimEditService: ClaimEditService,
  ) {
    super(injector);
    this._oneLensDropdownSelectedValue = null;
    this._balanceLensDropdownSelectedValue = null;
  }


  /**** START - PUBLIC MEMBERS ****/
  id = ApplicationConstants.componentIDs.prescription;
  title = 'Prescription';
  prescriptionForm: FormGroup;
  errorWrapperConfig: any = {};
  maxLabSpecialInstructionOrderLength: number = ApplicationConstants.maxLabSpecialInstructionOrderLength;
  oneLensRightOnlySelected: boolean;
  oneLensLeftOnlySelected: boolean;
  balanceLensLeftSelected: boolean;
  precedingZeroForDecimalPipe = UsdCurrencyPipe.precedingZeroForDecimalPipe;
  twoDecimalPlaceFormat = twoDecimalPlaceFormat;
  decimalPipeWrapper = decimalPipeWrapper;
  prescriptionConstants = PrescriptionConstants;
  prescriptionHtmlConstants = HtmlConstants.prescriptionColumnWidths;
  rightLeftBaseCurveFieldsAndCustomizableLensFieldsMaxLength: number = ApplicationConstants.rightLeftBaseCurveAndCustomizableLensFieldsMaxLength;

  // Customizable lens min/max lengths
  wrapAngleMinLength: number = ApplicationConstants.prescriptionWrapAngleMinimumValue;
  wrapAngleMaxLength: number = ApplicationConstants.prescriptionWrapAngleMaximumValue;
  pantoMinLength: number = ApplicationConstants.prescriptionPantoscopicTiltMinimumValue;
  pantoAndVertexMaxLength: number = ApplicationConstants.prescriptionPantoscopicTiltAndVertexDistanceMaximumValue;
  vertexMinLength: number = ApplicationConstants.prescriptionBackVertexDistanceMinimumValue;
  // Boolean flag indicating if we added the 'Special Measurement' item in the 'lab special instructions' dropdown for the current selected lens.
  currentlySelectedLensHasSpecialMeasurement: boolean = false;
  /**** START - PUBLIC MEMBERS ****/

    // TODO Change horizontalPrismTypes, binocularMonocularOptions, safetyThicknessOptions, verticalPrismTypes
    //  to objects so that you can easily access .property instead of having to know which element equates to what value
    //  example: heightMeasurementStartingPoints.bottomOfFrame.value instead of heightMeasurementStartingPoints[0].value
  horizontalPrismTypes = [
    { label: 'In', value: 'IN' },
    { label: 'Out', value: 'OUT' }
  ];

  opticalCenterHeightDropdown = [
    { value: OpticalCenterMeasurementType.BOTTOM_OF_FRAME, label: 'Bottom of Frame' },
    { value: OpticalCenterMeasurementType.GEOMETRIC_CENTER, label: 'Geometric Center' }
  ];

  segmentHeightDropdown = [
    { value: SegmentMeasurementType.BOTTOM_OF_FRAME, label: 'Bottom of Frame' },
    { value: SegmentMeasurementType.GEOMETRIC_CENTER, label: 'Geometric Center' }
  ];

  binocularMonocularOptions = [
    new ReferenceListValue(BinocularMonocularEnum.BINOCULAR, BinocularMonocularTypes.binocularPD).viewModel,
    new ReferenceListValue(BinocularMonocularEnum.MONOCULAR, BinocularMonocularTypes.monocularPDToCenterOfBridge).viewModel
  ];

  safetyThicknessOptions = [
    { label: 'Basic Impact 3.0 monogram lenses', value: 'BASIC_IMPACT' },
    { label: 'High Impact 2.0 monogram lenses', value: 'HIGH_IMPACT' },
    { label: 'Non-Industrial 3.0 not monogrammed', value: 'NON_INDUSTRIAL' },
  ];

  verticalPrismTypes = [
    { label: 'Up', value: 'UP' },
    { label: 'Down', value: 'DOWN' }
  ];

  originalLabDescriptionTypes = [
    { value: LabSpecialInstructionsType.BACKSIDE, label: 'Backside AR Only' },
    { value: LabSpecialInstructionsType.REDO, label: 'Redo' },
    { value: LabSpecialInstructionsType.OTHER, label: 'Other' }
  ];

  labDescriptionTypeDropdown = this.originalLabDescriptionTypes;

  labDescriptionSpecialMeasurementTypeDropdown = [
    { value: LabSpecialInstructionsType.SPECIAL_MEASUREMENT, label: 'Special Measurement' }
  ];

  labDescriptionTypeEnum = LabSpecialInstructionsType;
  segmentHeightEnum = SegmentMeasurementType;
  opticalCenterHeightEnum = OpticalCenterMeasurementType;
  activePrescription: EclaimLensPrescription;

  /***** START - PROPERTY ACCESSORS *****/
  get originalClaim(): Claim {
    return this._originalClaim;
  }

  set originalClaim(claim: Claim) {
    this._originalClaim = claim;
  }

  get activeClaim(): Claim {
    return this._activeClaim;
  }

  set activeClaim(updatedClaim: Claim) {
    this._activeClaim = updatedClaim;
  }

  get isCustomizableLens(): boolean {
    return this._isCustomizableLens;
  }

  set isCustomizableLens(isCustomizable: boolean) {
    this._isCustomizableLens = isCustomizable;
  }

  get isContactLensSelected(): boolean {
    return this._isContactLensSelected;
  }

  set isContactLensSelected(isContactLens: boolean) {
    this._isContactLensSelected = isContactLens;
  }

  get controlsDependentOnCustomizableLens(): Map<string, AbstractControl> {
    return new Map()
      .set(PrescriptionConstants.WRAP_ANGLE, this.prescriptionForm.get('wrapAngle'))
      .set(PrescriptionConstants.PANTOSCOPIC_TILT, this.prescriptionForm.get('pantoscopicTilt'))
      .set(PrescriptionConstants.BACK_VERTEX_DISTANCE, this.prescriptionForm.get('backVertexDistance'));
  }

  get meta() {
    return PrescriptionConstants.prescriptionFormMeta;
  }

  get lensSelected(): boolean {
    return !isNullOrUndefined(this.activeClaim) &&
      !isNullOrUndefined(this.activeClaim.labOrderInformation) &&
      !isNullOrUndefined(this.activeClaim.labOrderInformation.lens) &&
      !isStringNullUndefinedOrEmpty(this.activeClaim.labOrderInformation.lens.externalId);
  }

  get progressiveVisionTypeSelected(): boolean {
    return this.visionTypeDropdownSelectedValue === PrescriptionConstants.lensOptionValues.progressive;
  }

  get singleLensOptionSelected(): boolean {
    return this.oneLensRightOnlySelected || this.oneLensLeftOnlySelected || this.balanceLensLeftSelected;
  }

  private get rightCylinderAxisOrAddPopulated() {
    return this.prescriptionForm && (!
      (isStringNullUndefinedOrEmpty(this.prescriptionForm.get(this.meta.formControlName.cylinderRight).value)
        && isStringNullUndefinedOrEmpty(this.prescriptionForm.get(this.meta.formControlName.axisRight).value)
        && isStringNullUndefinedOrEmpty(this.prescriptionForm.get(this.meta.formControlName.addRight).value)));
  }

  private get leftLensPrescriptionPartiallyPopulated() {
    return this.prescriptionForm && (!(isStringNullUndefinedOrEmpty(this.prescriptionForm.get(this.meta.formControlName.cylinderLeft).value) && isStringNullUndefinedOrEmpty(this.prescriptionForm.get('prescriptionLeftEyeAdd').value) && isStringNullUndefinedOrEmpty(this.prescriptionForm.get('prescriptionLeftEyeAxis').value)));
  }

  get leftCylinderHasValue() {
    return this.prescriptionForm && !isStringNullUndefinedOrEmpty(this.prescriptionForm.get(this.meta.formControlName.cylinderLeft).value);
  }

  get rightCylinderHasValue() {
    return this.prescriptionForm && !isStringNullUndefinedOrEmpty(this.prescriptionForm.get(this.meta.formControlName.cylinderRight).value);
  }

  get leftAxisHasValue() {
    return this.prescriptionForm && !isStringNullUndefinedOrEmpty(this.prescriptionForm.get(this.meta.formControlName.axisLeft).value);
  }

  get rightAxisHasValue() {
    return this.prescriptionForm && !isStringNullUndefinedOrEmpty(this.prescriptionForm.get(this.meta.formControlName.axisRight).value);
  }

  get binocularSelected(): boolean {
    return (this.prescriptionForm && this.prescriptionForm.get(this.meta.formControlName.biMono).value === BinocularMonocularEnum.BINOCULAR);
  }

  get oneLensDropdownSelectedValue(): string {
    return this._oneLensDropdownSelectedValue;
  }

  set oneLensDropdownSelectedValue(value: string) {
    this._oneLensDropdownSelectedValue = value;
  }

  get balanceLensDropdownSelectedValue(): string {
    return this._balanceLensDropdownSelectedValue;
  }

  set balanceLensDropdownSelectedValue(value: string) {
    this._balanceLensDropdownSelectedValue = value;
  }

  get visionTypeDropdownSelectedValue(): string {
    return this._visionTypeDropdownSelectedValue;
  }

  set visionTypeDropdownSelectedValue(value: string) {
    this._visionTypeDropdownSelectedValue = value;
  }

  get lensValue(): LensDto {
    return this._lensValue;
  }

  set lensValue(value: LensDto) {
    this._lensValue = value;
  }

  get singleVisionSelected() {
    return this.visionTypeDropdownSelectedValue === LensVisionTypes.SINGLE_VISION || this.visionTypeDropdownSelectedValue === LensVisionTypes.LENTICUALR_SINGLE_VISION;
  }

  get singleVisionOrBlankSelected() {
    return this.visionTypeDropdownSelectedValue === LensVisionTypes.SINGLE_VISION || this.visionTypeDropdownSelectedValue === LensVisionTypes.LENTICUALR_SINGLE_VISION || this.visionTypeDropdownSelectedValue === LensVisionTypes.NO_SELECTION;
  }

  /* Convenience form getters */

  get prescriptionBinocularMonocularDropdownValue(): string {
    return this.prescriptionForm ? this.prescriptionForm.get(this.meta.formControlName.biMono).value as string : '';
  }

  get opticalCenterSelectedValue(): string {
    return this.prescriptionForm ? this.prescriptionForm.get(this.meta.formControlName.opticalCenterStartingPoint).value : '';
  }

  get segmentHeightSelectedValue(): string {
    return this.prescriptionForm ? this.prescriptionForm.get(this.meta.formControlName.segmentHeightStartingPoint).value : '';
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }
  /***** END - PROPERTY ACCESSORS *****/

  /***** START - LIFECYCLE HOOKS *****/
  ngOnInit() {
    this.originalClaim = this.claimService.getOriginalClaim();
    this.activeClaim = this.claimService.getActiveClaim();
    this.registerWithClaimProgressService();
    this.buildForm();
    this.initializeValues();
    this.buildErrorWrapperConfig();

    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      this.maskUnmaskComponent(mask);
      // Per ECLAIM-286 - We added this to assure that when save, calculate and submit buttons are clicked we set the correct lab special instructions indicators in the UI and this observable for sure executes regardless if the calls to PE were successful or not.
      // We only want to execute this logic when this flag is true
      if (this.currentlySelectedLensHasSpecialMeasurement) {
        // Set up the UI accordingly when this logic is triggered.
        this.setupLabSpecialInstructionSpecialMeasurementItemInUI();
      }
    }));

    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
          (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }
      }));

    this.observableSubscriptions.push(this.prescriptionForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe(() => {
      // Update the data model based on the form data
      if (!this.isMasked) {
        this.updateDataModelFromViewModel();
      }
    }));

    this.observableSubscriptions.push(this.claimService.onCardsToUpdate.subscribe((onCardsToUpdate: ClaimCardsToUpdate) => {
      this.visionTypeChanged = this.isVisionTypeChanged();

      // This Preps the old and new activeClaim so there wont be console errors such as
      // "Can't read xxx of undefined"
      this.prepareActiveClaim();
      const oldActiveClaim: Claim = this.activeClaim;
      this.activeClaim = this.claimService.getActiveClaim();
      this.prepareActiveClaim();

      if (onCardsToUpdate.all) {
        this.setViewModelFromDataModel();
      }

      // Only triggers when Lens or Contacts are changed
      if (onCardsToUpdate.prescription && !this.isMasked) {

        if (this.singleVisionSelected) {
          this.prescriptionForm.get(this.meta.formControlName.segmentHeightStartingPoint).setValue(null);
        }

        if (isNullOrUndefined(this.activeClaim.labOrderInformation.lens.visionType)) {
          this.visionTypeDropdownSelectedValue = '';
        } else if (!isStringNullUndefinedOrEmpty(this.activeClaim.labOrderInformation.lens.visionType.name)) {
          this.visionTypeDropdownSelectedValue = this.activeClaim.labOrderInformation.lens.visionType.name;
        }

        if (oldActiveClaim.labOrderInformation.oneLens !== this.activeClaim.labOrderInformation.oneLens) {
          this.oneLensDropdownSelectedValue = this.activeClaim.labOrderInformation.oneLens;
          this.onOneLensChange();
        }

        if (oldActiveClaim.labOrderInformation.balanceLens !== this.activeClaim.labOrderInformation.balanceLens) {
          this.balanceLensDropdownSelectedValue = this.activeClaim.labOrderInformation.balanceLens;
          this.onBalanceLensChange();
        }

        this.onVisionTypeChangeUpdateSegmentHeightFields();
        this.onVisionTypeChangeUpdateBinocularMonocularFields();

        this.prescriptionForm.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);

        this.updateLensPrescriptionDataModelFromView();
        this.updateContactPrescriptionDataModelFromView();

        this.setViewModelFromDataModel();

        if (this.prescriptionCardHasChanged()) {
          this.claimService.setActiveClaim(this.activeClaim, this.id);
        }
      }
    }));

    // Listen for lens selections from the lens component to hide/display the customizable lens fields and enable validation
    // based on if the lens has a positionOfWearMeasurement equal to 'required' returned from LCAT lens retrieve
    this.observableSubscriptions.push(this.viewStateService.onSelectedSpectacleLens.subscribe((lens: LensDto) => {
      this.lensValue = lens;
      this.isCustomizableLens = !isNullOrUndefined(this.lensValue) && (this.lensValue.positionOfWear === CustomizableLensType.Optional || this.lensValue.positionOfWear === CustomizableLensType.Required);
      this.changeDetector.detectChanges();

      this.customizableLensValidators();
    }));

    // Observable to 'ADD' the 'Special Measurement' item in the 'lab special instructions' dropdown and disable dropdown.
    this.observableSubscriptions.push(this.lensService.onAddSpecialMeasurementItem.subscribe((onAddSpecialMeasurementItem: boolean) => {
      // Only try to add the 'Special Measurement' item in the 'lab special instructions' dropdown if it has not already been added.
      if (onAddSpecialMeasurementItem && !this.currentlySelectedLensHasSpecialMeasurement) {
        // Execute required data to set up the UI when Special measurement comes into play.
        this.processLabSpecialInstructionSpecialMeasurementItem();
        // update the data model so the correct values are set for the PE call.
        this.updateDataModelFromViewModel();
        // In order to get the UI to get new update immediately, force the change detector to trigger.
        this.changeDetector.detectChanges();
        // Reset observable back to false.
        this.lensService.onAddSpecialMeasurementItem.next(false);
      }
    }));

    // Observable to 'REMOVE' the 'Special Measurement' item in the 'lab special instructions' dropdown and reset it to its original values and enable it.
    this.observableSubscriptions.push(this.lensService.onRemoveSpecialMeasurementItem.subscribe((onRemoveSpecialMeasurementItem: boolean) => {
      // Only try to remove the 'Special Measurement' item in the 'lab special instructions' dropdown if it really is there.
      if (onRemoveSpecialMeasurementItem && (this.currentlySelectedLensHasSpecialMeasurement || this.doesOriginalClaimHaveLabSpecialInstructionsSpecialMeasurement())) {
        // Execute required data to set up the UI when Special measurement does not come into to play.
        this.processLabSpecialInstructionNoneSpecialMeasurementItem();
        // update the data model so the correct values are set for the PE call.
        this.updateDataModelFromViewModel();
        // In order to get the UI to get new update immediately, force the change detector to trigger.
        this.changeDetector.detectChanges();
        // Reset observable back to false.
        this.lensService.onRemoveSpecialMeasurementItem.next(false);
      }
    }));
  }

  ngAfterViewInit() {
    this.maskUnmaskComponent(this.isMasked);
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }
  /***** END - LIFECYCLE HOOKS *****/


  /***** START - PRIVATE METHODS *****/
  private maskUnmaskComponent(masked: boolean): void {
    this.isMasked = masked;
    if (!this.prescriptionForm) { return; }
    if (masked) {
      this.disableFormGroupComponents(this.prescriptionForm);
      this.componentMask.show();
    } else if (this.prescriptionForm) {
      this.unmasking = true;
      this.prescriptionForm.enable();
      this.componentMask.hide();
      this.onOneLensChange();
      this.onBalanceLensChange();
      this.onPrescriptionBinocularMonocularChange();
      this.onVisionTypeChangeUpdateSegmentHeightFields();

      this.unmasking = false;
    }
    this.changeDetector.detectChanges();
  }

  private hasValidator(control: AbstractControl, errorType: string): boolean {
    if (isNullOrUndefined(control.validator)) {
      return false;
    }

    // Project the composite validator function into a ValidationError object and check for validator property types
    const compositeValidators: ValidationErrors = control.validator({} as AbstractControl);
    return compositeValidators && compositeValidators.hasOwnProperty(errorType);
  }

  /*  ALL VALIDATORS ASSIGNED HERE IN THE FORM BUILDER ARE "STANDARD" CUSTOM VALIDATORS (OR NATIVE ANGULAR VALIDATORS).  THEY ARE
      NOT PART OF THE GROUP VALIDATION SCHEME, WHICH IS DEFINED BELOW IN defineValidationGroups() */
  private buildForm(): void {

    // pull data from the data model
    let type: string, redoOrderNumber: string, details: string, wrapAngle: string, pantoscopicTilt: string, backVertexDistance: string;
    let prescriptionRightEyeDistanceValue: number, prescriptionRightEyeNearValue: number;
    this.visionTypeDropdownSelectedValue = '';
    if (!isNullOrUndefined(this.originalClaim) && !isNullOrUndefined(this.originalClaim.labOrderInformation)) {
      // if lab special instructions are already filled in on the form
      if (!isNullOrUndefined(this.originalClaim.labOrderInformation.labSpecialInstructions)) {
        ({ type, redoOrderNumber, details } = this.originalClaim.labOrderInformation.labSpecialInstructions);
        type = isStringNullUndefinedOrEmpty(type) ? null : type;
        redoOrderNumber = isStringNullUndefinedOrEmpty(redoOrderNumber) ? null : redoOrderNumber;
        details = isStringNullUndefinedOrEmpty(details) ? null : details;
      }
      // if lens vision type is already filled in on the form
      if (!isNullOrUndefined(this.originalClaim.labOrderInformation.lens) && !isNullOrUndefined(this.originalClaim.labOrderInformation.lens.visionType) && !isNullOrUndefined(this.originalClaim.labOrderInformation.lens.visionType.externalId)) {
        const visionTypeExternalId = this.originalClaim.labOrderInformation.lens.visionType.externalId;
        const visionTypeList = this.lensService.lensVisionTypes;
        if (!isNullOrUndefined(visionTypeList)) {
          this.visionTypeDropdownSelectedValue = visionTypeList.find((visionType: LensVisionType) => visionTypeExternalId === visionType.externalId).name;
        }
      }
    }

    if (isNullOrUndefined(this.originalClaim.labOrderInformation)) {
      this.originalClaim.labOrderInformation = {} as LabOrderInformation;
    }
    if (isNullOrUndefined(this.originalClaim.labOrderInformation.lensPrescription)) {
      this.originalClaim.labOrderInformation.lensPrescription = {} as EclaimLensPrescription;
    }
    if (isNullOrUndefined(this.originalClaim.labOrderInformation.labSpecialInstructions)) {
      this.originalClaim.labOrderInformation.labSpecialInstructions = {} as LabSpecialInstructions;
    }

    this.activePrescription = { ...this.originalClaim.labOrderInformation.lensPrescription };

    // Reassign the activePrescription values if a contact reason code was selected (RX information will be nested underneath contactLens.necessaryContactLensPrescription)
    const isContactLensClaim: boolean = !isNullOrUndefined(this.originalClaim.contactLens) && !isNullOrUndefined(this.originalClaim.contactLens.clReasonCode) && !isStringNullUndefinedOrEmpty(this.originalClaim.contactLens.clReasonCode.code);
    if (isContactLensClaim) {
      const hasContactLensprescription: boolean = !isNullOrUndefined(this.originalClaim.contactLens.necessaryContactLensPrescription);
      this.activePrescription.sphereRight = hasContactLensprescription ? setToNullOrValue(this.originalClaim.contactLens.necessaryContactLensPrescription.sphereRight) : null;
      this.activePrescription.sphereLeft = hasContactLensprescription ? setToNullOrValue(this.originalClaim.contactLens.necessaryContactLensPrescription.sphereLeft) : null;
      this.activePrescription.cylinderRight = hasContactLensprescription ? setToNullOrValue(this.originalClaim.contactLens.necessaryContactLensPrescription.cylinderRight) : null;
      this.activePrescription.cylinderLeft = hasContactLensprescription ? setToNullOrValue(this.originalClaim.contactLens.necessaryContactLensPrescription.cylinderLeft) : null;
      this.activePrescription.axisRight = hasContactLensprescription ? setToNullOrValue(this.originalClaim.contactLens.necessaryContactLensPrescription.axisRight) : null;
      this.activePrescription.axisLeft = hasContactLensprescription ? setToNullOrValue(this.originalClaim.contactLens.necessaryContactLensPrescription.axisLeft) : null;
    }

    const isPlanoLensSelected: boolean = isNullOrUndefined(this.activePrescription.planoLensSelected) ? false : (this.activePrescription.planoLensSelected);
    // this.binocularMonocularOptions[0].value is 'Binocular', this.binocularMonocularOptions[1].value is 'Monocular'
    if (this.activePrescription.pupillaryDistanceType === this.binocularMonocularOptions[0].value) {
      prescriptionRightEyeDistanceValue = this.activePrescription.binocularPdDistant;
      prescriptionRightEyeNearValue = this.activePrescription.binocularPdNear;
    } else if (this.activePrescription.pupillaryDistanceType === this.binocularMonocularOptions[1].value) {
      prescriptionRightEyeDistanceValue = this.activePrescription.monocularPdDistantRight;
      prescriptionRightEyeNearValue = this.activePrescription.monocularPdNearRight;
    } else {
      prescriptionRightEyeDistanceValue = null;
      prescriptionRightEyeNearValue = null;
    }

    wrapAngle = this.activePrescription.wrapAngle;
    pantoscopicTilt = this.activePrescription.pantoscopicTilt;
    backVertexDistance = this.activePrescription.backVertexDistance;

    this.prescriptionForm = this.formBuilder.group({
      prescriptionRightEyeSphere: [this.activePrescription.sphereRight,
        [
          Validators.pattern(ApplicationConstants.anySignedOrUnsignedNumber),
        ]],
      prescriptionLeftEyeSphere: [{ value: this.activePrescription.sphereLeft, disabled: this.singleLensOptionSelected },
        [
          Validators.pattern(ApplicationConstants.anySignedOrUnsignedNumber),
        ]],
      prescriptionRightEyeCylinder: [this.activePrescription.cylinderRight, [
        Validators.pattern(ApplicationConstants.anySignedOrUnsignedNumber)
      ]],
      prescriptionLeftEyeCylinder: [{ value: this.activePrescription.cylinderLeft, disabled: this.singleLensOptionSelected }, [
        Validators.pattern(ApplicationConstants.anySignedOrUnsignedNumber)
      ]],
      prescriptionRightEyeAxis: [this.activePrescription.axisRight,
        [
          Validators.pattern(/^[0-9]+$/),
        ]],
      prescriptionLeftEyeAxis: [{ value: this.activePrescription.axisLeft, disabled: this.singleLensOptionSelected }, [
        Validators.pattern(/^[0-9]+$/),
      ]],
      prescriptionRightEyeAdd: [this.activePrescription.addRight, [Validators.pattern(ApplicationConstants.decimalNumberRegex)]],
      prescriptionLeftEyeAdd: [{ value: this.activePrescription.addLeft, disabled: this.singleLensOptionSelected }, [Validators.pattern(ApplicationConstants.decimalNumberRegex)]],
      prescriptionBinocularMonocular: [this.activePrescription.pupillaryDistanceType],
      prescriptionRightEyeDistance: [prescriptionRightEyeDistanceValue, [Validators.pattern(ApplicationConstants.decimalNumberRegex)]],
      // Default the left eye distance to true if the distance type is 'Monocular'
      prescriptionLeftEyeDistance: [this.activePrescription.pupillaryDistanceType === this.binocularMonocularOptions[1].value ? this.activePrescription.monocularPdDistantLeft : null, [Validators.pattern(ApplicationConstants.decimalNumberRegex)]],
      prescriptionRightEyeNear: [prescriptionRightEyeNearValue, [Validators.pattern(ApplicationConstants.decimalNumberRegex)]],
      // Default the left eye near value to true if the distance type if 'Monocular'
      prescriptionLeftEyeNear: [this.activePrescription.pupillaryDistanceType === this.binocularMonocularOptions[1].value ? this.activePrescription.monocularPdNearLeft : null, [ Validators.pattern(ApplicationConstants.decimalNumberRegex)]],
      prescriptionSegmentHeightStartingPoint: [{ value: this.activePrescription.segmentMeasurementType ? this.activePrescription.segmentMeasurementType : '', disabled: this.singleVisionSelected }],
      prescriptionRightEyeSegmentHeight: [{ value: this.activePrescription.segmentHeightRight, disabled: this.singleVisionSelected }, [Validators.pattern(ApplicationConstants.anySignedOrUnsignedNumber)]],
      prescriptionLeftEyeSegmentHeight: [{ value: this.activePrescription.segmentHeightLeft, disabled: this.singleVisionSelected }, [Validators.pattern(ApplicationConstants.anySignedOrUnsignedNumber)]],
      prescriptionOpticalCenterHeightStartingPoint: [this.activePrescription.opticalCenterMeasurementType ? this.activePrescription.opticalCenterMeasurementType : ''],
      prescriptionRightEyeOpticalCenterHeight: [this.activePrescription.opticalCenterHeightRight, [Validators.pattern(ApplicationConstants.anySignedOrUnsignedNumber)]],
      prescriptionLeftEyeOpticalCenterHeight: [this.activePrescription.opticalCenterHeightLeft, [Validators.pattern(ApplicationConstants.anySignedOrUnsignedNumber)]],
      planoLensInd: [{ value: isPlanoLensSelected, disabled: !isNullOrUndefined(this.oneLensDropdownSelectedValue) }],
      prescriptionRightEyeHPrism: [this.activePrescription.horizontalPrismRight ? this.activePrescription.horizontalPrismRight : ''],
      prescriptionLeftEyeHPrism: [{ value: this.activePrescription.horizontalPrismLeft ? this.activePrescription.horizontalPrismLeft : '', disabled: this.singleLensOptionSelected }],
      prescriptionRightEyeHBase: [this.activePrescription.horizontalPrismDirRight],
      prescriptionLeftEyeHBase: [{ value: this.activePrescription.horizontalPrismDirLeft, disabled: this.singleLensOptionSelected }],
      prescriptionRightEyeVPrism: [this.activePrescription.verticalPrismRight ? this.activePrescription.verticalPrismRight : ''],
      prescriptionLeftEyeVPrism: [{ value: this.activePrescription.verticalPrismLeft ? this.activePrescription.verticalPrismLeft : '', disabled: this.singleLensOptionSelected }],
      prescriptionLeftEyeVBase: [{ value: this.activePrescription.verticalPrismDirLeft, disabled: this.singleLensOptionSelected }],
      prescriptionRightEyeVBase: [this.activePrescription.verticalPrismDirRight],
      prescriptionSafetyThickness: [this.activePrescription.thicknessTypeCode],
      prescriptionSpecifiedThickness: [this.activePrescription.thickness],
      wrapAngle: [wrapAngle],
      pantoscopicTilt: [pantoscopicTilt],
      backVertexDistance: [backVertexDistance],
      labSpecialInstructionIndicator: [!isStringNullUndefinedOrEmpty(type)],
      labSpecialInstructionSelect: [{ value: type, disabled: isStringNullUndefinedOrEmpty(type) }],
      labSpecialInstructionRedoOrder: [{ value: redoOrderNumber, disabled: !(type === this.labDescriptionTypeEnum.REDO) }, [Validators.required, Validators.pattern(ApplicationConstants.labSpecialInstructionOrderRegex)]],
      labSpecialInstructionDetails: [{ value: details, disabled: !(type === this.labDescriptionTypeEnum.REDO || type === this.labDescriptionTypeEnum.OTHER) }],
      labSpecialInstructionSpecialMeasurementDetails: [{ value: details, disabled: !(type === this.labDescriptionTypeEnum.SPECIAL_MEASUREMENT)}, [Validators.required]]
    });

    // add validators to fields that have required co dependency between two fields because they depend on the form group already being initialized to work
    // TODO: it seems kinda silly that this is being done here, and in the setViewModelFromDataModel function.  Seems like some opportunity for cleanup here.
    //       Like move all this setValidators stuff to a single function and just call it from each of the two places.  Or, maybe come up with some scheme
    //       where it's all centrally managed ("grouped", if you will)...

    this.prescriptionForm.controls.prescriptionRightEyeHPrism.setValidators([
      CustomValidatorsService.prismDecimalAmount
    ]);
    this.prescriptionForm.controls.prescriptionLeftEyeHPrism.setValidators([
      CustomValidatorsService.prismDecimalAmount
    ]);
    this.prescriptionForm.controls.prescriptionRightEyeVPrism.setValidators([
      CustomValidatorsService.prismDecimalAmount
    ]);
    this.prescriptionForm.controls.prescriptionLeftEyeVPrism.setValidators([
      CustomValidatorsService.prismDecimalAmount
    ]);


    this.twoDecimalPlaceFormat(this.prescriptionForm.controls.prescriptionRightEyeVPrism);
    this.twoDecimalPlaceFormat(this.prescriptionForm.controls.prescriptionLeftEyeVPrism);
    this.twoDecimalPlaceFormat(this.prescriptionForm.controls.prescriptionRightEyeHPrism);
    this.twoDecimalPlaceFormat(this.prescriptionForm.controls.prescriptionLeftEyeHPrism);
    if (this.isMasked) { this.prescriptionForm.disable(); }
  }

  private updateDataModelFromViewModel(): void {

    this.updateLensPrescriptionDataModelFromView();
    this.updateContactPrescriptionDataModelFromView();

    // Update the active claim in the claim service
    if (this.prescriptionCardHasChanged()) {
      this.claimService.setActiveClaim(this.activeClaim, this.id);
    }
  }

  private updateLensPrescriptionDataModelFromView(): void {
    // Sets the Rx info of Lens
    if (isNullOrUndefined(this.activeClaim.labOrderInformation)) {
      this.activeClaim.labOrderInformation = {} as LabOrderInformation;
      this.activeClaim.labOrderInformation.labSpecialInstructions = {} as LabSpecialInstructions;
    } else if (isNullOrUndefined(this.activeClaim.labOrderInformation.labSpecialInstructions)) {
      this.activeClaim.labOrderInformation.labSpecialInstructions = {} as LabSpecialInstructions;
    }

    const lensPrescription = {} as EclaimLensPrescription;
    lensPrescription.planoLensSelected = this.prescriptionForm.controls.planoLensInd.value;

    if (this.lensSelected) {
      if (this.prescriptionForm.controls.labSpecialInstructionIndicator.value) {
        const {
          labSpecialInstructionSelect, labSpecialInstructionRedoOrder,
          labSpecialInstructionDetails, labSpecialInstructionSpecialMeasurementDetails
        } = this.prescriptionForm.controls;
        this.activeClaim.labOrderInformation.labSpecialInstructions.type = setToUndefinedOrValue(labSpecialInstructionSelect.value);
        // Per ECLAIM-286 when 'SPECIAL_MEASUREMENTS' we want to use the new field to set the details in the PE model.
        if (!isNullOrUndefined(labSpecialInstructionSelect) && !isNullOrUndefined(labSpecialInstructionSelect.value)) {
          if (labSpecialInstructionSelect.value === LabSpecialInstructionsType.SPECIAL_MEASUREMENT) {
            this.activeClaim.labOrderInformation.labSpecialInstructions.details = setToUndefinedOrValue(labSpecialInstructionSpecialMeasurementDetails.value);
          } else {
            this.activeClaim.labOrderInformation.labSpecialInstructions.details = setToUndefinedOrValue(labSpecialInstructionDetails.value);
          }
        }
        this.activeClaim.labOrderInformation.labSpecialInstructions.redoOrderNumber = setToUndefinedOrValue(labSpecialInstructionRedoOrder.value);

        if (labSpecialInstructionSelect.value === this.labDescriptionTypeEnum.BACKSIDE &&
          !isNullOrUndefined(this.activeClaim.labOrderInformation.lensAntiReflectiveCoating) &&
          !isNullOrUndefined(this.activeClaim.labOrderInformation.lensAntiReflectiveCoating.labServiceId)) {
          this.activeClaim.labOrderInformation.labSpecialInstructions.backsideAntiReflectiveOnly = true;
        } else {
          this.activeClaim.labOrderInformation.labSpecialInstructions.backsideAntiReflectiveOnly = false;
        }
      } else {
        this.activeClaim.labOrderInformation.labSpecialInstructions.backsideAntiReflectiveOnly = false;
        this.activeClaim.labOrderInformation.labSpecialInstructions.type = undefined;
        this.activeClaim.labOrderInformation.labSpecialInstructions.redoOrderNumber = undefined;
        this.activeClaim.labOrderInformation.labSpecialInstructions.details = undefined;
      }

      lensPrescription.pupillaryDistanceType = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionBinocularMonocular.value);

      // this.binocularMonocularOptions[0].value is 'Binocular'
      if (this.prescriptionForm.controls.prescriptionBinocularMonocular.value === this.binocularMonocularOptions[0].value) {
        lensPrescription.binocularPdDistant = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionRightEyeDistance.value);
        lensPrescription.binocularPdNear = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionRightEyeNear.value);
        // set undefined for all monocular values
        lensPrescription.monocularPdDistantRight = undefined;
        lensPrescription.monocularPdDistantLeft = undefined;
        lensPrescription.monocularPdNearRight = undefined;
        lensPrescription.monocularPdNearLeft = undefined;
      } else if (this.prescriptionForm.controls.prescriptionBinocularMonocular.value === this.binocularMonocularOptions[1].value) {
        // this.binocularMonocularOptions[1].value is 'Monocular'
        lensPrescription.monocularPdDistantRight = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionRightEyeDistance.value);
        lensPrescription.monocularPdDistantLeft = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionLeftEyeDistance.value);
        lensPrescription.monocularPdNearRight = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionRightEyeNear.value);
        lensPrescription.monocularPdNearLeft = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionLeftEyeNear.value);
        // set undefined for all binocular values
        lensPrescription.binocularPdDistant = undefined;
        lensPrescription.binocularPdNear = undefined;
      }

      lensPrescription.sphereRight = this.prescriptionForm.controls.prescriptionRightEyeSphere.value;
      lensPrescription.sphereLeft = this.prescriptionForm.controls.prescriptionLeftEyeSphere.value;
      lensPrescription.cylinderRight = this.prescriptionForm.controls.prescriptionRightEyeCylinder.value;
      lensPrescription.cylinderLeft = this.prescriptionForm.controls.prescriptionLeftEyeCylinder.value;
      lensPrescription.axisRight = this.prescriptionForm.controls.prescriptionRightEyeAxis.value;
      lensPrescription.axisLeft = this.prescriptionForm.controls.prescriptionLeftEyeAxis.value;
      lensPrescription.addRight = this.prescriptionForm.controls.prescriptionRightEyeAdd.value;
      lensPrescription.addLeft = this.prescriptionForm.controls.prescriptionLeftEyeAdd.value;
      lensPrescription.segmentMeasurementType = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionSegmentHeightStartingPoint.value);
      lensPrescription.segmentHeightRight = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionRightEyeSegmentHeight.value);
      lensPrescription.segmentHeightLeft = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionLeftEyeSegmentHeight.value);
      lensPrescription.opticalCenterMeasurementType = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionOpticalCenterHeightStartingPoint.value);
      lensPrescription.opticalCenterHeightRight = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionRightEyeOpticalCenterHeight.value);
      lensPrescription.opticalCenterHeightLeft = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionLeftEyeOpticalCenterHeight.value);
      lensPrescription.horizontalPrismRight = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionRightEyeHPrism.value);
      lensPrescription.horizontalPrismLeft = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionLeftEyeHPrism.value);
      lensPrescription.horizontalPrismDirLeft = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionLeftEyeHBase.value);
      lensPrescription.horizontalPrismDirRight = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionRightEyeHBase.value);
      lensPrescription.verticalPrismRight = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionRightEyeVPrism.value);
      lensPrescription.verticalPrismLeft = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionLeftEyeVPrism.value);
      lensPrescription.verticalPrismDirLeft = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionLeftEyeVBase.value);
      lensPrescription.verticalPrismDirRight = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionRightEyeVBase.value);
      lensPrescription.thicknessTypeCode = this.prescriptionForm.controls.prescriptionSafetyThickness.value;
      lensPrescription.thickness = setToUndefinedOrValue(this.prescriptionForm.controls.prescriptionSpecifiedThickness.value);
      lensPrescription.wrapAngle = setToUndefinedOrValue(this.prescriptionForm.get(PrescriptionConstants.WRAP_ANGLE).value);
      lensPrescription.pantoscopicTilt = setToUndefinedOrValue(this.prescriptionForm.get(PrescriptionConstants.PANTOSCOPIC_TILT).value);
      lensPrescription.backVertexDistance = setToUndefinedOrValue(this.prescriptionForm.get(PrescriptionConstants.BACK_VERTEX_DISTANCE).value);
    }

    this.activeClaim.labOrderInformation.lensPrescription = lensPrescription;
  }

  private updateContactPrescriptionDataModelFromView(): void {
    // Sets the Rx info of Contact Lens
    if (isNullOrUndefined(this.activeClaim.contactLens)) {
      this.activeClaim.contactLens = {} as ContactLens;
      this.activeClaim.contactLens.necessaryContactLensPrescription = {} as BasePrescription;
    } else if (isNullOrUndefined(this.activeClaim.contactLens.necessaryContactLensPrescription)) {
      this.activeClaim.contactLens.necessaryContactLensPrescription = {} as BasePrescription;
    }

    const necessaryContactLensPrescription = {} as BasePrescription;

    if (!isStringNullUndefinedOrEmpty(this.activeClaim.contactLens.materialType)) {
      necessaryContactLensPrescription.sphereRight = this.prescriptionForm.controls.prescriptionRightEyeSphere.value;
      necessaryContactLensPrescription.sphereLeft = this.prescriptionForm.controls.prescriptionLeftEyeSphere.value;
      necessaryContactLensPrescription.cylinderRight = this.prescriptionForm.controls.prescriptionRightEyeCylinder.value;
      necessaryContactLensPrescription.cylinderLeft = this.prescriptionForm.controls.prescriptionLeftEyeCylinder.value;
      necessaryContactLensPrescription.axisRight = this.prescriptionForm.controls.prescriptionRightEyeAxis.value;
      necessaryContactLensPrescription.axisLeft = this.prescriptionForm.controls.prescriptionLeftEyeAxis.value;
    }

    this.activeClaim.contactLens.necessaryContactLensPrescription = necessaryContactLensPrescription;
  }

  private prescriptionCardHasChanged(): boolean {
    // Instantiate claim objects if they don't exist
    if (isNullOrUndefined(this.activeClaim.labOrderInformation)) { this.activeClaim.labOrderInformation = {} as LabOrderInformation; }
    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensPrescription)) { this.activeClaim.labOrderInformation.lensPrescription = {} as EclaimLensPrescription; }
    if (isNullOrUndefined(this.activeClaim.labOrderInformation.labSpecialInstructions)) { this.activeClaim.labOrderInformation.labSpecialInstructions = {} as LabSpecialInstructions; }
    const { addRight: addRightFromCard, addLeft: addLeftFromCard,
      pupillaryDistanceType: pupillaryDistanceTypeFromCard, binocularPdDistant: binocularPdDistantFromCard, binocularPdNear: binocularPdNearFromCard,
      monocularPdDistantRight: monocularPdDistantRightFromCard, monocularPdDistantLeft: monocularPdDistantLeftFromCard,
      monocularPdNearRight: monocularPdNearRightFromCard, monocularPdNearLeft: monocularPdNearLeftFromCard,
      segmentMeasurementType: segmentMeasurementTypeFromCard, segmentHeightRight: segmentHeightRightFromCard, segmentHeightLeft: segmentHeightLeftFromCard,
      opticalCenterMeasurementType: opticalCenterMeasurementTypeFromCard, opticalCenterHeightRight: opticalCenterHeightRightFromCard,
      opticalCenterHeightLeft: opticalCenterHeightLeftFromCard, planoLensSelected: planoLensSelectedFromCard, horizontalPrismRight: horizontalPrismRightFromCard,
      horizontalPrismLeft: horizontalPrismLeftFromCard, horizontalPrismDirLeft: horizontalPrismDirLeftFromCard,
      horizontalPrismDirRight: horizontalPrismDirRightFromCard, verticalPrismRight: verticalPrismRightFromCard,
      verticalPrismLeft: verticalPrismLeftFromCard, verticalPrismDirLeft: verticalPrismDirLeftFromCard, verticalPrismDirRight: verticalPrismDirRightFromCard,
      thicknessTypeCode: thicknessTypeCodeFromCard, thickness: thicknessFromCard, wrapAngle: wrapAngleFromCard, pantoscopicTilt: pantoscopicTiltFromCard,
      backVertexDistance: backVertexDistanceFromCard} = this.activeClaim.labOrderInformation.lensPrescription;
    let { sphereRight: sphereRightFromCard, sphereLeft: sphereLeftFromCard, cylinderRight: cylinderRightFromCard, cylinderLeft: cylinderLeftFromCard,
      axisRight: axisRightFromCard, axisLeft: axisLeftFromCard} = this.activeClaim.labOrderInformation.lensPrescription;
    const { backsideAntiReflectiveOnly: backsideAntiReflectiveOnlyFromCard, type: specialInstructionsTypeFromCard, redoOrderNumber: redoOrderNumberFromCard,
      details: specialInstructionsDetailsFromCard} = this.activeClaim.labOrderInformation.labSpecialInstructions;

    // if sphere, cylinder or axis left/rgiht values are not present on the labOrderInformation lensPrescription, check to see if it is in
    // the contactLens necessaryContactLensPrescription object that gets set in the claim service in the setBasePrescription method
    if ( !isNullOrUndefined(this.activeClaim.contactLens) && !isNullOrUndefined(this.activeClaim.contactLens.necessaryContactLensPrescription)) {
      sphereRightFromCard = !isNullOrUndefined(sphereRightFromCard) ? sphereRightFromCard : this.activeClaim.contactLens.necessaryContactLensPrescription.sphereRight;
      sphereLeftFromCard = !isNullOrUndefined(sphereLeftFromCard) ? sphereLeftFromCard : this.activeClaim.contactLens.necessaryContactLensPrescription.sphereLeft;
      cylinderRightFromCard = !isNullOrUndefined(cylinderRightFromCard) ? cylinderRightFromCard : this.activeClaim.contactLens.necessaryContactLensPrescription.cylinderRight;
      cylinderLeftFromCard = !isNullOrUndefined(cylinderLeftFromCard) ? cylinderLeftFromCard : this.activeClaim.contactLens.necessaryContactLensPrescription.cylinderLeft;
      axisRightFromCard = !isNullOrUndefined(axisRightFromCard) ? axisRightFromCard : this.activeClaim.contactLens.necessaryContactLensPrescription.axisRight;
      axisLeftFromCard = !isNullOrUndefined(axisLeftFromCard) ? axisLeftFromCard : this.activeClaim.contactLens.necessaryContactLensPrescription.axisLeft;
    }

    const activeClaimFromService = this.claimService.getActiveClaim();
    // Instantiate claim objects if they don't exist
    if (isNullOrUndefined(activeClaimFromService.labOrderInformation)) { activeClaimFromService.labOrderInformation = {} as LabOrderInformation; }
    if (isNullOrUndefined(activeClaimFromService.labOrderInformation.lensPrescription)) { activeClaimFromService.labOrderInformation.lensPrescription = {} as EclaimLensPrescription; }
    if (isNullOrUndefined(activeClaimFromService.labOrderInformation.labSpecialInstructions)) { activeClaimFromService.labOrderInformation.labSpecialInstructions = {} as LabSpecialInstructions; }
    const { addRight: addRightFromService, addLeft: addLeftFromService, pupillaryDistanceType: pupillaryDistanceTypeFromService,
      binocularPdDistant: binocularPdDistantFromService, binocularPdNear: binocularPdNearFromService,
      monocularPdDistantRight: monocularPdDistantRightFromService, monocularPdDistantLeft: monocularPdDistantLeftFromService,
      monocularPdNearRight: monocularPdNearRightFromService, monocularPdNearLeft: monocularPdNearLeftFromService,
      segmentMeasurementType: segmentMeasurementTypeFromService, segmentHeightRight: segmentHeightRightFromService, segmentHeightLeft: segmentHeightLeftFromService,
      opticalCenterMeasurementType: opticalCenterMeasurementTypeFromService, opticalCenterHeightRight: opticalCenterHeightRightFromService,
      opticalCenterHeightLeft: opticalCenterHeightLeftFromService, planoLensSelected: planoLensSelectedFromService, horizontalPrismRight: horizontalPrismRightFromService,
      horizontalPrismLeft: horizontalPrismLeftFromService, horizontalPrismDirLeft: horizontalPrismDirLeftFromService,
      horizontalPrismDirRight: horizontalPrismDirRightFromService, verticalPrismRight: verticalPrismRightFromService,
      verticalPrismLeft: verticalPrismLeftFromService, verticalPrismDirLeft: verticalPrismDirLeftFromService, verticalPrismDirRight: verticalPrismDirRightFromService,
      thicknessTypeCode: thicknessTypeCodeFromService, thickness: thicknessFromService, wrapAngle: wrapAngleFromService, pantoscopicTilt: pantoscopicTiltFromService,
      backVertexDistance: backVertexDistanceFromService} = activeClaimFromService.labOrderInformation.lensPrescription;
    let { sphereRight: sphereRightFromService, sphereLeft: sphereLeftFromService, cylinderRight: cylinderRightFromService, cylinderLeft: cylinderLeftFromService,
      axisRight: axisRightFromService, axisLeft: axisLeftFromService } = activeClaimFromService.labOrderInformation.lensPrescription;
    // if sphere, cylinder or axis left/right values are not present on the labOrderInformation lensPrescription, check to see if it is in
    // the contactLens necessaryContactLensPrescription object that gets set in the claim service in the setBasePrescription method
    if ( !isNullOrUndefined(activeClaimFromService.contactLens) && !isNullOrUndefined(activeClaimFromService.contactLens.necessaryContactLensPrescription)) {
      sphereRightFromService = !isNullOrUndefined(sphereRightFromService) ? sphereRightFromService : activeClaimFromService.contactLens.necessaryContactLensPrescription.sphereRight;
      sphereLeftFromService = !isNullOrUndefined(sphereLeftFromService) ? sphereLeftFromService : activeClaimFromService.contactLens.necessaryContactLensPrescription.sphereLeft;
      cylinderRightFromService = !isNullOrUndefined(cylinderRightFromService) ? cylinderRightFromService : activeClaimFromService.contactLens.necessaryContactLensPrescription.cylinderRight;
      cylinderLeftFromService = !isNullOrUndefined(cylinderLeftFromService) ? cylinderLeftFromService : activeClaimFromService.contactLens.necessaryContactLensPrescription.cylinderLeft;
      axisRightFromService = !isNullOrUndefined(axisRightFromService) ? axisRightFromService : activeClaimFromService.contactLens.necessaryContactLensPrescription.axisRight;
      axisLeftFromService = !isNullOrUndefined(axisLeftFromService) ? axisLeftFromService : activeClaimFromService.contactLens.necessaryContactLensPrescription.axisLeft;
    }
    const { backsideAntiReflectiveOnly: backsideAntiReflectiveOnlyFromService, type: specialInstructionsTypeFromService, redoOrderNumber: redoOrderNumberFromService,
      details: specialInstructionsDetailsFromService} = activeClaimFromService.labOrderInformation.labSpecialInstructions;

    // Note: For the values using the signedPaddedZeroes.transform() and toDollarAmount() methods below they are needed in order to successfully compare a stringed number (Example: '2.00')
    // to the actual number returned from lab order in the PE response (Example: 2). If we do not do that on the fields we expect a number to be returned from lab order we may end up in
    // an endless save loop cycle with the "Leave Claim Form Warning Modal". Again this will only happen with fields that expect a number and not a string value in the Lens Prescription object.
    // You can also look at ECR-11189 for more documentation on why this was done.
    return (sphereRightFromCard || undefined) !== (sphereRightFromService || undefined) ||
      (sphereLeftFromCard || undefined) !== (sphereLeftFromService || undefined) ||
      (cylinderRightFromCard || undefined) !== (cylinderRightFromService || undefined) ||
      (cylinderLeftFromCard || undefined) !== (cylinderLeftFromService || undefined) ||
      (toDollarAmount(axisRightFromCard) || undefined) !== (toDollarAmount(axisRightFromService) || undefined) ||
      (toDollarAmount(axisLeftFromCard) || undefined) !== (toDollarAmount(axisLeftFromService) || undefined) ||
      (this.signedPaddedZeroes.transform(addRightFromCard, 1, 2, false, 10) || undefined) !== (this.signedPaddedZeroes.transform(addRightFromService, 1, 2, false, 10) || undefined) ||
      (this.signedPaddedZeroes.transform(addLeftFromCard, 1, 2, false, 10) || undefined) !== (this.signedPaddedZeroes.transform(addLeftFromService, 1, 2, false, 10) || undefined) ||
      (pupillaryDistanceTypeFromCard || undefined) !== (pupillaryDistanceTypeFromService || undefined) ||
      (this.signedPaddedZeroes.transform(binocularPdDistantFromCard, 1, 1, false, 8) || undefined) !== (this.signedPaddedZeroes.transform(binocularPdDistantFromService, 1, 1, false, 8) || undefined) ||
      (this.signedPaddedZeroes.transform(binocularPdNearFromCard, 1, 1, false, 8) || undefined) !== (this.signedPaddedZeroes.transform(binocularPdNearFromService, 1, 1, false, 8) || undefined) ||
      (this.signedPaddedZeroes.transform(monocularPdDistantRightFromCard, 1, 1, false, 8) || undefined) !== (this.signedPaddedZeroes.transform(monocularPdDistantRightFromService, 1, 1, false, 8) || undefined) ||
      (this.signedPaddedZeroes.transform(monocularPdDistantLeftFromCard, 1, 1, false, 8) || undefined) !== (this.signedPaddedZeroes.transform(monocularPdDistantLeftFromService, 1, 1, false, 8) || undefined) ||
      (this.signedPaddedZeroes.transform(monocularPdNearRightFromCard, 1, 1, false, 8) || undefined) !== (this.signedPaddedZeroes.transform(monocularPdNearRightFromService, 1, 1, false, 8) || undefined) ||
      (this.signedPaddedZeroes.transform(monocularPdNearLeftFromCard, 1, 1, false, 8) || undefined) !== (this.signedPaddedZeroes.transform(monocularPdNearLeftFromService, 1, 1, false, 8) || undefined) ||
      (segmentMeasurementTypeFromCard || undefined) !== (segmentMeasurementTypeFromService || undefined) ||
      (segmentHeightRightFromCard || undefined) !== (segmentHeightRightFromService || undefined) ||
      (segmentHeightLeftFromCard || undefined) !== (segmentHeightLeftFromService || undefined) ||
      (opticalCenterMeasurementTypeFromCard || undefined) !== (opticalCenterMeasurementTypeFromService || undefined) ||
      (opticalCenterHeightRightFromCard || undefined) !== (opticalCenterHeightRightFromService || undefined) ||
      (opticalCenterHeightLeftFromCard || undefined) !== (opticalCenterHeightLeftFromService || undefined) ||
      (planoLensSelectedFromCard || undefined) !== (planoLensSelectedFromService || undefined) ||
      (toDollarAmount(horizontalPrismRightFromCard) || undefined) !== (toDollarAmount(horizontalPrismRightFromService) || undefined) ||
      (toDollarAmount(horizontalPrismLeftFromCard) || undefined) !== (toDollarAmount(horizontalPrismLeftFromService) || undefined) ||
      (horizontalPrismDirLeftFromCard || undefined) !== (horizontalPrismDirLeftFromService || undefined) ||
      (horizontalPrismDirRightFromCard || undefined) !== (horizontalPrismDirRightFromService || undefined) ||
      (toDollarAmount(verticalPrismRightFromCard) || undefined) !== (toDollarAmount(verticalPrismRightFromService) || undefined) ||
      (toDollarAmount(verticalPrismLeftFromCard) || undefined) !== (toDollarAmount(verticalPrismLeftFromService) || undefined) ||
      (verticalPrismDirLeftFromCard || undefined) !== (verticalPrismDirLeftFromService || undefined) ||
      (verticalPrismDirRightFromCard || undefined) !== (verticalPrismDirRightFromService || undefined) ||
      (thicknessTypeCodeFromCard || undefined) !== (thicknessTypeCodeFromService || undefined) ||
      (toDollarAmount(thicknessFromCard) || undefined) !== (toDollarAmount(thicknessFromService) || undefined) ||
      (wrapAngleFromCard || undefined) !== (wrapAngleFromService || undefined) ||
      (pantoscopicTiltFromCard || undefined) !== (pantoscopicTiltFromService || undefined) ||
      (backVertexDistanceFromCard || undefined) !== (backVertexDistanceFromService || undefined) ||
      (backsideAntiReflectiveOnlyFromCard || undefined) !== (backsideAntiReflectiveOnlyFromService || undefined) ||
      (specialInstructionsTypeFromCard || undefined) !== (specialInstructionsTypeFromService || undefined) ||
      (redoOrderNumberFromCard || undefined) !== (redoOrderNumberFromService || undefined) ||
      (specialInstructionsDetailsFromCard || undefined) !== (specialInstructionsDetailsFromService || undefined);
  }

  /**
   * Build up the error messaging config for each form
   */
  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      prescriptionRightEyeSphere: {
        control: this.prescriptionForm.get(this.meta.formControlName.sphereRight),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }
        ]
      },
      prescriptionLeftEyeSphere: {
        control: this.prescriptionForm.get(this.meta.formControlName.sphereLeft),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }
        ]
      },
      prescriptionRightEyeCylinder: {
        control: this.prescriptionForm.get(this.meta.formControlName.cylinderRight),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }
        ]
      },
      prescriptionLeftEyeCylinder: {
        control: this.prescriptionForm.get(this.meta.formControlName.cylinderLeft),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }
        ]
      },
      prescriptionRightEyeAxis: {
        control: this.prescriptionForm.get(this.meta.formControlName.axisRight),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }
        ]
      },
      prescriptionLeftEyeAxis: {
        control: this.prescriptionForm.get(this.meta.formControlName.axisLeft),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }
        ]
      },
      prescriptionRightEyeAdd: {
        control: this.prescriptionForm.get(this.meta.formControlName.addRight),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }]
      },
      prescriptionLeftEyeAdd: {
        control: this.prescriptionForm.get(this.meta.formControlName.addLeft),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }]
      },
      prescriptionRightEyeDistance: {
        control: this.prescriptionForm.get(this.meta.formControlName.distanceRight),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }]
      },
      prescriptionLeftEyeDistance: {
        control: this.prescriptionForm.get(this.meta.formControlName.distanceLeft),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }]
      },
      prescriptionRightEyeNear: {
        control: this.prescriptionForm.get(this.meta.formControlName.nearRight),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }]
      },
      prescriptionLeftEyeNear: {
        control: this.prescriptionForm.get(this.meta.formControlName.nearLeft),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }]
      },
      labSpecialInstructionRedoOrder: {
        control: this.prescriptionForm.get(this.meta.formControlName.labSpecialInstructionRedoOrder),
        errors: [
          {
            validatorType: ErrorTypes.Required,
            errorMessage: 'The original order # is required for a Redo'
          },
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Enter a valid order #'
          }
        ]
      },

      prescriptionOpticalCenterHeightStartingPoint: {
        control: this.prescriptionForm.get(PrescriptionConstants.prescriptionFormMeta.formControlName.opticalCenterStartingPoint)
      },
      prescriptionRightEyeOpticalCenterHeight: {
        control: this.prescriptionForm.get(this.meta.formControlName.opticalCenterRight),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }
        ]
      },
      prescriptionLeftEyeOpticalCenterHeight: {
        control: this.prescriptionForm.get(this.meta.formControlName.opticalCenterLeft),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }
        ]
      },
      prescriptionRightEyeSegmentHeight: {
        control: this.prescriptionForm.get(PrescriptionConstants.prescriptionFormMeta.formControlName.segmentHeightRight),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }
        ]
      },
      prescriptionLeftEyeSegmentHeight: {
        control: this.prescriptionForm.get(PrescriptionConstants.prescriptionFormMeta.formControlName.segmentHeightLeft),
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid numeric value'
          }
        ]
      },
      prescriptionLeftEyeHPrism: {
        control: this.prescriptionForm.get('prescriptionLeftEyeHPrism'),
        errors: [
          {
            validatorType: ErrorTypes.PrismDecimalAmount,
            errorMessage: 'Please enter a valid Prism value'
          }
        ]
      },
      prescriptionRightEyeHPrism: {
        control: this.prescriptionForm.get('prescriptionRightEyeHPrism'),
        errors: [
          {
            validatorType: ErrorTypes.PrismDecimalAmount,
            errorMessage: 'Please enter a valid Prism value'
          }
        ]
      },
      prescriptionLeftEyeVPrism: {
        control: this.prescriptionForm.get('prescriptionLeftEyeVPrism'),
        errors: [
          {
            validatorType: ErrorTypes.PrismDecimalAmount,
            errorMessage: 'Please enter a valid Prism value'
          }
        ]
      },
      prescriptionRightEyeVPrism: {
        control: this.prescriptionForm.get('prescriptionRightEyeVPrism'),
        errors: [
          {
            validatorType: ErrorTypes.PrismDecimalAmount,
            errorMessage: 'Please enter a valid Prism value'
          }
        ]
      },
      labSpecialInstructionSpecialMeasurementDetails: {
        control: this.prescriptionForm.get('labSpecialInstructionSpecialMeasurementDetails'),
        errors: [
          {
            validatorType: ErrorTypes.Required,
            errorMessage: 'Special Measurement value is required'
          }
        ]
      },
      // TODO: Commenting out edits until further notice as part of VEC-94
      // wrapAngle: {
      //   control: this.prescriptionForm.get(PrescriptionConstants.WRAP_ANGLE),
      //   errors: [
      //     {
      //       validatorType: ErrorTypes.IsRequiredCustomizableLens,
      //       errorMessage: 'Wrap angle is required for the selected lens'
      //     }
      //   ]
      // },
      // pantoscopicTilt: {
      //   control: this.prescriptionForm.get(PrescriptionConstants.PANTOSCOPIC_TILT),
      //   errors: [
      //     {
      //       validatorType: ErrorTypes.IsRequiredCustomizableLens,
      //       errorMessage: 'Pantoscopic tilt is required for the selected lens'
      //     }
      //   ]
      // },
      // backVertexDistance: {
      //   control: this.prescriptionForm.get(PrescriptionConstants.BACK_VERTEX_DISTANCE),
      //   errors: [
      //     {
      //       validatorType: ErrorTypes.IsRequiredCustomizableLens,
      //       errorMessage: 'Vertex is required for the selected lens'
      //     }
      //   ]
      // },
    };
  }
  /***** END - PRIVATE METHODS *****/


  /***** START - PUBLIC METHODS *****/
  initializeValues() {
    this.prescriptionForm.get(this.meta.formControlName.sphereLeft).setValue(this.paddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.sphereLeft).value, 1, 2));
    this.prescriptionForm.get(this.meta.formControlName.sphereRight).setValue(this.paddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.sphereRight).value, 1, 2));
    this.prescriptionForm.get(this.meta.formControlName.cylinderLeft).setValue(this.paddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.cylinderLeft).value, 1, 2));
    this.prescriptionForm.get(this.meta.formControlName.cylinderRight).setValue(this.paddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.cylinderRight).value, 1, 2));
    // Add values
    this.prescriptionForm.get(this.meta.formControlName.addRight).setValue(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.addRight).value, 1, 2, false));
    this.prescriptionForm.get(this.meta.formControlName.addLeft).setValue(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.addLeft).value, 1, 2, false));
    // Binocular/Monocular Distance and Near values
    this.prescriptionForm.get(this.meta.formControlName.distanceRight).setValue(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.distanceRight).value, 1, 1, false));
    this.prescriptionForm.get(this.meta.formControlName.distanceLeft).setValue(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.distanceLeft).value, 1, 1, false));
    this.prescriptionForm.get(this.meta.formControlName.nearRight).setValue(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.nearRight).value, 1, 1, false));
    this.prescriptionForm.get(this.meta.formControlName.nearLeft).setValue(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.nearLeft).value, 1, 1, false));
    // Horizontal Prism Right and Left values
    this.prescriptionForm.get(this.meta.formControlName.horizontalPrismRight).setValue(toDollarAmount(this.prescriptionForm.get(this.meta.formControlName.horizontalPrismRight).value));
    this.prescriptionForm.get(this.meta.formControlName.horizontalPrismLeft).setValue(toDollarAmount(this.prescriptionForm.get(this.meta.formControlName.horizontalPrismLeft).value));
    // Vertical Prism Right and Left values
    this.prescriptionForm.get(this.meta.formControlName.verticalPrismRight).setValue(toDollarAmount(this.prescriptionForm.get(this.meta.formControlName.verticalPrismRight).value));
    this.prescriptionForm.get(this.meta.formControlName.verticalPrismLeft).setValue(toDollarAmount(this.prescriptionForm.get(this.meta.formControlName.verticalPrismLeft).value));

    if (this.prescriptionForm && !this.prescriptionForm.get(this.meta.formControlName.biMono).value) {
      this.prescriptionForm.get(this.meta.formControlName.biMono).setValue(BinocularMonocularEnum.BINOCULAR);
    }

  }

  setViewModelFromDataModel(): void {
    this.prepareActiveClaim();

    const lensPrescription = this.activeClaim.labOrderInformation.lensPrescription;
    const labSpecialInstructions = this.activeClaim.labOrderInformation.labSpecialInstructions;
    const necessaryContactLensPrescription =  this.activeClaim.contactLens.necessaryContactLensPrescription;

    const { prescriptionRightEyeSphere, prescriptionLeftEyeSphere, prescriptionRightEyeCylinder, prescriptionLeftEyeCylinder,
      prescriptionRightEyeAxis, prescriptionLeftEyeAxis, prescriptionRightEyeAdd, prescriptionLeftEyeAdd, prescriptionBinocularMonocular,
      prescriptionRightEyeDistance,  prescriptionLeftEyeDistance, prescriptionRightEyeNear, prescriptionLeftEyeNear,
      prescriptionSegmentHeightStartingPoint, prescriptionRightEyeSegmentHeight, prescriptionLeftEyeSegmentHeight,
      prescriptionOpticalCenterHeightStartingPoint, prescriptionRightEyeOpticalCenterHeight, prescriptionLeftEyeOpticalCenterHeight,
      planoLensInd, prescriptionRightEyeHPrism, prescriptionLeftEyeHPrism, prescriptionLeftEyeHBase, prescriptionRightEyeHBase,
      prescriptionRightEyeVPrism, prescriptionLeftEyeVPrism, prescriptionLeftEyeVBase, prescriptionRightEyeVBase, prescriptionSafetyThickness,
      prescriptionSpecifiedThickness, wrapAngle, pantoscopicTilt, backVertexDistance, labSpecialInstructionIndicator, labSpecialInstructionSelect,
      labSpecialInstructionRedoOrder, labSpecialInstructionDetails, labSpecialInstructionSpecialMeasurementDetails} = this.prescriptionForm.controls;

    // Setting LabSpecialInstruction values
    if (!isNullOrUndefined(labSpecialInstructions.type)) {
      labSpecialInstructionIndicator.setValue(true);
    } else {
      labSpecialInstructionIndicator.setValue(false);
    }

    labSpecialInstructionSelect.setValue(labSpecialInstructions.type);
    labSpecialInstructionRedoOrder.setValue(labSpecialInstructions.redoOrderNumber);
    // Per ECLAIM-286 we want to set the correct details with the correct field.
    if (!isNullOrUndefined(labSpecialInstructions) && !isNullOrUndefined(labSpecialInstructions.type)) {
      if (labSpecialInstructions.type === LabSpecialInstructionsType.SPECIAL_MEASUREMENT) {
        labSpecialInstructionSpecialMeasurementDetails.setValue(labSpecialInstructions.details);
      } else {
        labSpecialInstructionDetails.setValue(labSpecialInstructions.details);
      }
    }

    // Setting bi/monocular
    // Per ECLAIM-106 - This was added to make sure the Bi/Monocular dropdown is set correctly on a vision type change
    let pupillaryDistanceType;
    if (!isStringNullUndefinedOrEmptyWithTrim(lensPrescription.pupillaryDistanceType)) {
      pupillaryDistanceType = lensPrescription.pupillaryDistanceType;

    } else if (!isStringNullUndefinedOrEmptyWithTrim(this.prescriptionForm.controls.prescriptionBinocularMonocular.value)) {
      pupillaryDistanceType = this.prescriptionForm.controls.prescriptionBinocularMonocular.value;

    } else {
      pupillaryDistanceType = BinocularMonocularEnum.BINOCULAR;
    }
    prescriptionBinocularMonocular.setValue(pupillaryDistanceType);
    prescriptionRightEyeDistance.setValue(isNullOrUndefined(lensPrescription.binocularPdDistant) ? lensPrescription.monocularPdDistantRight : lensPrescription.binocularPdDistant);
    prescriptionRightEyeNear.setValue(isNullOrUndefined(lensPrescription.binocularPdNear) ? lensPrescription.monocularPdNearRight : lensPrescription.binocularPdNear);
    prescriptionLeftEyeDistance.setValue(lensPrescription.monocularPdDistantLeft);
    prescriptionLeftEyeNear.setValue(lensPrescription.monocularPdNearLeft);

    // Setting the Base Rx depending on if lens is selected or if contacts has Rx info
    if (this.lensSelected) {
      prescriptionRightEyeSphere.setValue(lensPrescription.sphereRight);
      prescriptionLeftEyeSphere.setValue(lensPrescription.sphereLeft);
      prescriptionRightEyeCylinder.setValue(lensPrescription.cylinderRight);
      prescriptionLeftEyeCylinder.setValue(lensPrescription.cylinderLeft);
      prescriptionRightEyeAxis.setValue(lensPrescription.axisRight);
      prescriptionLeftEyeAxis.setValue(lensPrescription.axisLeft);
    } else {
      prescriptionRightEyeSphere.setValue(necessaryContactLensPrescription.sphereRight);
      prescriptionLeftEyeSphere.setValue(necessaryContactLensPrescription.sphereLeft);
      prescriptionRightEyeCylinder.setValue(necessaryContactLensPrescription.cylinderRight);
      prescriptionLeftEyeCylinder.setValue(necessaryContactLensPrescription.cylinderLeft);
      prescriptionRightEyeAxis.setValue(necessaryContactLensPrescription.axisRight);
      prescriptionLeftEyeAxis.setValue(necessaryContactLensPrescription.axisLeft);
    }

    // Setting the rest of the Rx Card
    prescriptionRightEyeAdd.setValue(lensPrescription.addRight);
    prescriptionLeftEyeAdd.setValue(lensPrescription.addLeft);

    prescriptionSegmentHeightStartingPoint.setValue(lensPrescription.segmentMeasurementType);
    prescriptionRightEyeSegmentHeight.setValue(lensPrescription.segmentHeightRight);
    prescriptionLeftEyeSegmentHeight.setValue(lensPrescription.segmentHeightLeft);
    prescriptionOpticalCenterHeightStartingPoint.setValue(lensPrescription.opticalCenterMeasurementType);
    prescriptionRightEyeOpticalCenterHeight.setValue(lensPrescription.opticalCenterHeightRight);
    prescriptionLeftEyeOpticalCenterHeight.setValue(lensPrescription.opticalCenterHeightLeft);
    planoLensInd.setValue(lensPrescription.planoLensSelected);
    prescriptionRightEyeHPrism.setValue(lensPrescription.horizontalPrismRight ? lensPrescription.horizontalPrismRight : null);
    prescriptionLeftEyeHPrism.setValue(lensPrescription.horizontalPrismLeft ? lensPrescription.horizontalPrismLeft : null);
    prescriptionLeftEyeHBase.setValue(lensPrescription.horizontalPrismDirLeft);
    prescriptionRightEyeHBase.setValue(lensPrescription.horizontalPrismDirRight);
    prescriptionRightEyeVPrism.setValue(lensPrescription.verticalPrismRight ? lensPrescription.verticalPrismRight : null);
    prescriptionLeftEyeVPrism.setValue(lensPrescription.verticalPrismLeft ? lensPrescription.verticalPrismLeft : null);
    prescriptionLeftEyeVBase.setValue(lensPrescription.verticalPrismDirLeft);
    prescriptionRightEyeVBase.setValue(lensPrescription.verticalPrismDirRight);
    prescriptionSafetyThickness.setValue(lensPrescription.thicknessTypeCode);
    prescriptionSpecifiedThickness.setValue(lensPrescription.thickness);

    wrapAngle.setValue(lensPrescription.wrapAngle);
    pantoscopicTilt.setValue(lensPrescription.pantoscopicTilt);
    backVertexDistance.setValue(lensPrescription.backVertexDistance);

    this.initializeValues();

    if (this.singleVisionSelected) {
      this.prescriptionForm.get(this.meta.formControlName.segmentHeightStartingPoint).setValue(null);
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lens.visionType)) {
      this.visionTypeDropdownSelectedValue = '';
    } else if (!isStringNullUndefinedOrEmpty(this.activeClaim.labOrderInformation.lens.visionType.name)) {
      this.visionTypeDropdownSelectedValue = this.activeClaim.labOrderInformation.lens.visionType.name;
      this.onVisionTypeChangeUpdateBinocularMonocularFields();
    }

    this.onVisionTypeChangeUpdateSegmentHeightFields();

    this.prescriptionForm.controls.prescriptionRightEyeAdd.setValidators([Validators.pattern(ApplicationConstants.decimalNumberRegex)]);
    this.prescriptionForm.controls.prescriptionLeftEyeAdd.setValidators([Validators.pattern(ApplicationConstants.decimalNumberRegex)]);

    this.prescriptionForm.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);
  }

  onLabSpecialInstructionsCheckboxChange() {
    const value = this.prescriptionForm.controls.labSpecialInstructionIndicator.value;
    if (value) {
      this.prescriptionForm.controls.labSpecialInstructionSelect.setValue(null);
      this.prescriptionForm.controls.labSpecialInstructionSelect.enable();
    } else {
      this.prescriptionForm.controls.labSpecialInstructionRedoOrder.setValue(null);
      this.prescriptionForm.controls.labSpecialInstructionDetails.setValue(null);
      this.prescriptionForm.controls.labSpecialInstructionSelect.setValue(null);
      this.prescriptionForm.controls.labSpecialInstructionSpecialMeasurementDetails.setValue(null);
    }
  }

  labSpecialInstructionSelectChange() {
    const value = this.prescriptionForm.controls.labSpecialInstructionSelect.value;
    if (value === this.labDescriptionTypeEnum.REDO) {
      this.prescriptionForm.controls.labSpecialInstructionRedoOrder.setValidators([Validators.required, Validators.pattern(ApplicationConstants.labSpecialInstructionOrderRegex)]);
      this.prescriptionForm.controls.labSpecialInstructionRedoOrder.enable();
      this.prescriptionForm.controls.labSpecialInstructionDetails.enable();
      this.prescriptionForm.controls.labSpecialInstructionSpecialMeasurementDetails.disable();

    } else if (value === this.labDescriptionTypeEnum.OTHER) {
      this.prescriptionForm.controls.labSpecialInstructionRedoOrder.clearValidators();
      this.prescriptionForm.controls.labSpecialInstructionRedoOrder.setValue(null);
      this.prescriptionForm.controls.labSpecialInstructionDetails.enable();
      this.prescriptionForm.controls.labSpecialInstructionSpecialMeasurementDetails.disable();

    } else if (value === this.labDescriptionTypeEnum.BACKSIDE) {
      this.prescriptionForm.controls.labSpecialInstructionRedoOrder.clearValidators();
      this.prescriptionForm.controls.labSpecialInstructionRedoOrder.setValue(null);
      this.prescriptionForm.controls.labSpecialInstructionDetails.setValue(null);
      this.prescriptionForm.controls.labSpecialInstructionSpecialMeasurementDetails.setValue(null);

    } else if (value === this.labDescriptionTypeEnum.SPECIAL_MEASUREMENT) {
      this.prescriptionForm.controls.labSpecialInstructionRedoOrder.clearValidators();
      this.prescriptionForm.controls.labSpecialInstructionRedoOrder.setValue(null);
      this.prescriptionForm.controls.labSpecialInstructionDetails.setValue(null);
      this.prescriptionForm.controls.labSpecialInstructionDetails.disable();
      this.prescriptionForm.controls.labSpecialInstructionSpecialMeasurementDetails.enable();
      this.prescriptionForm.controls.labSpecialInstructionSpecialMeasurementDetails.setValue(null);

    } else {
      this.prescriptionForm.controls.labSpecialInstructionRedoOrder.clearValidators();
      this.prescriptionForm.controls.labSpecialInstructionRedoOrder.setValue(null);
      this.prescriptionForm.controls.labSpecialInstructionDetails.setValue(null);
      this.prescriptionForm.controls.labSpecialInstructionSpecialMeasurementDetails.setValue(null);
    }
  }

  isVisionTypeChanged(): boolean {

    let currentLensTypeName: String;
    let newLensTypeName: String;

    if (isNullOrUndefined(this.activeClaim) || isNullOrUndefined(this.activeClaim.labOrderInformation) ||
      isNullOrUndefined(this.activeClaim.labOrderInformation.lens) || isNullOrUndefined(this.activeClaim.labOrderInformation.lens.visionType)) {
      currentLensTypeName = null;
    } else {
      currentLensTypeName = this.activeClaim.labOrderInformation.lens.visionType.name;
    }

    const newActiveClaim = this.claimService.getActiveClaim();
    if (isNullOrUndefined(newActiveClaim) || isNullOrUndefined(newActiveClaim.labOrderInformation) ||
      isNullOrUndefined(newActiveClaim.labOrderInformation.lens) || isNullOrUndefined(newActiveClaim.labOrderInformation.lens.visionType)) {
      newLensTypeName = null;
    } else {
      newLensTypeName = newActiveClaim.labOrderInformation.lens.visionType.name;
    }

   return ((currentLensTypeName != null) && (newLensTypeName != null) && (currentLensTypeName !== newLensTypeName));
  }

  prescriptionFormFieldUpdate(event): void {
    let signed: boolean = false;
    switch (event.currentTarget.id) {
      case this.meta.inputId.sphereRight:
        this.prescriptionForm.get(this.meta.formControlName.sphereRight).setValue(this.paddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.sphereRight).value, 1, 2, 10), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.sphereLeft:
        this.prescriptionForm.get(this.meta.formControlName.sphereLeft).setValue(this.paddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.sphereLeft).value, 1, 2, 10), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.cylinderRight:
        this.prescriptionForm.get(this.meta.formControlName.cylinderRight).setValue(this.paddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.cylinderRight).value, 1, 2, 10), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.cylinderLeft:
        this.prescriptionForm.get(this.meta.formControlName.cylinderLeft).setValue(this.paddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.cylinderLeft).value, 1, 2, 10), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.addRight:
        this.prescriptionForm.get(this.meta.formControlName.addRight).setValue(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.addRight).value, 1, 2, false, 10), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.addLeft:
        this.prescriptionForm.get(this.meta.formControlName.addLeft).setValue(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.addLeft).value, 1, 2, false, 10), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.distanceRight:
        this.prescriptionForm.get(this.meta.formControlName.distanceRight).setValue(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.distanceRight).value, 1, 1, false, 8), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.distanceLeft:
        this.prescriptionForm.get(this.meta.formControlName.distanceLeft).setValue(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.distanceLeft).value, 1, 1, false, 8), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.nearRight:
        this.prescriptionForm.get(this.meta.formControlName.nearRight).setValue(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.nearRight).value, 1, 1, false, 8), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.nearLeft:
        this.prescriptionForm.get(this.meta.formControlName.nearLeft).setValue(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.nearLeft).value, 1, 1, false, 8), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.biMono:
        this.activePrescription.bimono = this.prescriptionForm.get(this.meta.formControlName.biMono).value;
        break;
      case this.meta.inputId.opticalCenterRight:
        signed = this.opticalCenterSelectedValue === this.opticalCenterHeightEnum.GEOMETRIC_CENTER;
        this.prescriptionForm.get(this.meta.formControlName.opticalCenterRight).setValue(oneDecimalPlaceFormatIfDecimalPresentInString(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.opticalCenterRight).value, 1, 1, signed, 9)), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.opticalCenterLeft:
        signed = this.opticalCenterSelectedValue === this.opticalCenterHeightEnum.GEOMETRIC_CENTER;
        this.prescriptionForm.get(this.meta.formControlName.opticalCenterLeft).setValue(oneDecimalPlaceFormatIfDecimalPresentInString(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.opticalCenterLeft).value, 1, 1, signed, 9)), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.segmentHeightLeft:
        signed = this.segmentHeightSelectedValue === this.segmentHeightEnum.GEOMETRIC_CENTER;
        this.prescriptionForm.get(this.meta.formControlName.segmentHeightLeft).setValue(oneDecimalPlaceFormatIfDecimalPresentInString(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.segmentHeightLeft).value, 1, 1, signed, 9)), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.segmentHeightRight:
        signed = this.segmentHeightSelectedValue === this.segmentHeightEnum.GEOMETRIC_CENTER;
        this.prescriptionForm.get(this.meta.formControlName.segmentHeightRight).setValue(oneDecimalPlaceFormatIfDecimalPresentInString(this.signedPaddedZeroes.transform(this.prescriptionForm.get(this.meta.formControlName.segmentHeightRight).value, 1, 1, signed, 9)), ApplicationConstants.updateFormWithoutEmit);
        break;
      case this.meta.inputId.opticalCenterStartingPoint:
        this.prescriptionForm.get(this.meta.formControlName.opticalCenterRight).setValue(null);
        this.prescriptionForm.get(this.meta.formControlName.opticalCenterLeft).setValue(null);
        this.prescriptionForm.get(this.meta.formControlName.opticalCenterLeft).updateValueAndValidity();
        this.prescriptionForm.get(this.meta.formControlName.opticalCenterRight).updateValueAndValidity();
        break;
      case this.meta.inputId.segmentHeightStartingPoint:
        this.prescriptionForm.get(this.meta.formControlName.segmentHeightLeft).setValue('');
        this.prescriptionForm.get(this.meta.formControlName.segmentHeightRight).setValue('');
        break;
    }
    this.prescriptionForm.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);
    this.updateDataModelFromViewModel();
  }

  customizableLensValidators(): void {
    // If a customizable lens is selected, enable the controls if they are not already enabled
    if (this.isCustomizableLens) {
      this.controlsDependentOnCustomizableLens.forEach((control: AbstractControl, key: string) => {
        if (control.disabled) {
          control.enable(ApplicationConstants.updateFormWithoutEmit);
        }

        // If we're initially loading the claim with a selected lens from the lens component, set the customizable
        // lens dependent fields with there original claim values, if they exist
        if (this.lensValue.initialLoad && this.originalClaim.labOrderInformation && this.originalClaim.labOrderInformation.lensPrescription) {
          const wrapAngle: string = this.originalClaim.labOrderInformation.lensPrescription.wrapAngle;
          const pantoscopicTilt: string = this.originalClaim.labOrderInformation.lensPrescription.pantoscopicTilt;
          const backVertexDistance: string = this.originalClaim.labOrderInformation.lensPrescription.backVertexDistance;

          // Set the initial value of the form
          this.prescriptionForm.get(PrescriptionConstants.WRAP_ANGLE).setValue(wrapAngle, ApplicationConstants.updateFormWithoutEmit);
          this.prescriptionForm.get(PrescriptionConstants.PANTOSCOPIC_TILT).setValue(pantoscopicTilt, ApplicationConstants.updateFormWithoutEmit);
          this.prescriptionForm.get(PrescriptionConstants.BACK_VERTEX_DISTANCE).setValue(backVertexDistance, ApplicationConstants.updateFormWithoutEmit);
        }

        // TODO: Removed validators as part of VEC-94
        // // Add the validators here so we can react to updates made to the lens selection
        // // Check for the existence of just one validator type, as they all get added at once
        // if (!this.hasValidator(control, ErrorTypes.IsRequiredCustomizableLens)) {
        //
        //   // Add the required customizable lens validators and update error validations
        //   control.setValidators([
        //     PrescriptionValidators.requiredCustomizableLens(this.lensValue.positionOfWear),
        //   ]);
        //   control.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);
        // }
      });
    } else {
      // Disable each control if the selected lens is not customizable (positionOfWearMeasurement !== 'required' || 'optional')
      this.controlsDependentOnCustomizableLens.forEach((control: AbstractControl, key: string) => {
        control.clearValidators();
        control.reset();
        control.disable(ApplicationConstants.updateFormWithoutEmit);
      });
    }
  }

  /***** START - EVENT HANDLERS *****/
  onOneLensChange(): void {
    let oneLensValue: string;
    if (!isNullOrUndefined(this.originalClaim) && !isNullOrUndefined(this.originalClaim.labOrderInformation)) {
      oneLensValue = isNullOrUndefined(this.oneLensDropdownSelectedValue) ? this.originalClaim.labOrderInformation.oneLens : this.oneLensDropdownSelectedValue;
      this.oneLensDropdownSelectedValue = oneLensValue;
    }
    this.oneLensRightOnlySelected = false;
    this.oneLensLeftOnlySelected = false;
    if (oneLensValue === RightLeftEnum.RIGHT) {
      this.clearAllLeftRxFields();
      this.disableAllLeftRxFields();
      this.enableAllRightRxFields();
      this.clearPlanoLensesCheckBox();
      this.disablePlanoLensesCheckBox();
      this.oneLensRightOnlySelected = true;
    } else if (oneLensValue === RightLeftEnum.LEFT) {
      this.clearAllRightRxFields();
      this.disableAllRightRxFields();
      this.enableAllLeftRxFields();
      this.clearPlanoLensesCheckBox();
      this.disablePlanoLensesCheckBox();
      this.oneLensLeftOnlySelected = true;
    } else if (isStringNullUndefinedOrEmpty(this.balanceLensDropdownSelectedValue)) {
      this.enableAllRightRxFields();
      this.enableAllLeftRxFields();
      this.enablePlanoLensesCheckBox();
      this.balanceLensLeftSelected = false;
    }
  }

  onBalanceLensChange(): void {
    let balanceLensValue: string;
    if (!isNullOrUndefined(this.originalClaim) && !isNullOrUndefined(this.originalClaim.labOrderInformation)) {
      balanceLensValue = isNullOrUndefined(this.balanceLensDropdownSelectedValue) ? this.originalClaim.labOrderInformation.balanceLens : this.balanceLensDropdownSelectedValue;
      this.balanceLensDropdownSelectedValue = balanceLensValue;
    }
    if (balanceLensValue === RightLeftEnum.RIGHT) {
      this.clearAllRightRxFields();
      this.disableAllRightRxFields();
      this.enableAllLeftRxFields();
      this.clearPlanoLensesCheckBox();
      this.disablePlanoLensesCheckBox();

    } else if (balanceLensValue === RightLeftEnum.LEFT) {
      this.clearAllLeftRxFields();
      this.disableAllLeftRxFields();
      this.enableAllRightRxFields();
      this.clearPlanoLensesCheckBox();
      this.disablePlanoLensesCheckBox();

    } else {
      if (isStringNullUndefinedOrEmpty(this.oneLensDropdownSelectedValue)) {
        this.enableAllRightRxFields();
        this.enableAllLeftRxFields();
        this.enablePlanoLensesCheckBox();
      }
    }
  }

  onVisionTypeChangeUpdateSegmentHeightFields(): void {
    if (isNullOrUndefined(this.activeClaim.labOrderInformation)) {
      this.activeClaim.labOrderInformation = {} as LabOrderInformation;
    }
    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensPrescription)) {
      this.activeClaim.labOrderInformation.lensPrescription = {} as EclaimLensPrescription;
    }
    if (isNullOrUndefined(this.activeClaim.labOrderInformation.labSpecialInstructions)) {
      this.activeClaim.labOrderInformation.labSpecialInstructions = {} as LabSpecialInstructions;
    }

    const segmentHeightDropdownControl = this.prescriptionForm.get(PrescriptionConstants.prescriptionFormMeta.formControlName.segmentHeightStartingPoint);
    const segmentHeightRightControl = this.prescriptionForm.get(PrescriptionConstants.prescriptionFormMeta.formControlName.segmentHeightRight);
    const segmentHeightLeftControl = this.prescriptionForm.get(PrescriptionConstants.prescriptionFormMeta.formControlName.segmentHeightLeft);

    if (!this.singleVisionSelected) {
      segmentHeightDropdownControl.enable(ApplicationConstants.updateFormWithoutEmit);
      if (!isStringNullUndefinedOrEmpty(this.activeClaim.labOrderInformation.lensPrescription.segmentMeasurementType)) {
        segmentHeightDropdownControl.setValue(this.activeClaim.labOrderInformation.lensPrescription.segmentMeasurementType, ApplicationConstants.updateFormWithoutEmit);
      } else {
        segmentHeightDropdownControl.setValue(this.segmentHeightEnum.BOTTOM_OF_FRAME, ApplicationConstants.updateFormWithoutEmit);
      }
      segmentHeightRightControl.enable(ApplicationConstants.updateFormWithoutEmit);
      segmentHeightLeftControl.enable(ApplicationConstants.updateFormWithoutEmit);
    } else {
      segmentHeightDropdownControl.disable(ApplicationConstants.updateFormWithoutEmit);
      segmentHeightRightControl.disable(ApplicationConstants.updateFormWithoutEmit);
      segmentHeightLeftControl.disable(ApplicationConstants.updateFormWithoutEmit);
      segmentHeightDropdownControl.reset();
      segmentHeightRightControl.reset();
      segmentHeightLeftControl.reset();
    }
  }

  onVisionTypeChangeUpdateBinocularMonocularFields(): void {
    if (this.visionTypeChanged) {
      if (this.progressiveVisionTypeSelected) {
        this.prescriptionForm.controls.prescriptionBinocularMonocular.setValue(BinocularMonocularEnum.MONOCULAR, ApplicationConstants.updateFormWithoutEmit);
      } else {
        this.prescriptionForm.controls.prescriptionBinocularMonocular.setValue(BinocularMonocularEnum.BINOCULAR, ApplicationConstants.updateFormWithoutEmit);
      }
    }
    this.onPrescriptionBinocularMonocularChange();
  }

  onPrescriptionBinocularMonocularChange() {
    if (!isStringNullUndefinedOrEmpty(this.oneLensDropdownSelectedValue)) {
      this.onOneLensChange();
    }
    if (!isStringNullUndefinedOrEmpty(this.balanceLensDropdownSelectedValue)) {
      this.onBalanceLensChange();
    }
    if (this.binocularSelected) {
      this.enablePrescriptionRightEyeDistanceNearFields();
      this.clearPrescriptionLeftEyeDistanceNearFields();
    } else {
      if ((isStringNullUndefinedOrEmpty(this.oneLensDropdownSelectedValue) && isStringNullUndefinedOrEmpty(this.balanceLensDropdownSelectedValue))
        || (isStringNullUndefinedOrEmpty(this.balanceLensDropdownSelectedValue) && this.oneLensDropdownSelectedValue !== RightLeftEnum.RIGHT)
        || (isStringNullUndefinedOrEmpty(this.oneLensDropdownSelectedValue) && this.balanceLensDropdownSelectedValue !== RightLeftEnum.LEFT)) {
        this.enablePrescriptionLeftEyeDistanceNearFields();
      }
    }

    this.prescriptionForm.controls.prescriptionRightEyeDistance.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);
    this.prescriptionForm.controls.prescriptionLeftEyeDistance.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);

    this.prescriptionForm.controls.prescriptionRightEyeNear.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);
    this.prescriptionForm.controls.prescriptionLeftEyeNear.updateValueAndValidity(ApplicationConstants.updateFormWithoutEmit);
  }

  onPlanoLensesCheckBoxChange() {
    const {prescriptionRightEyeSphere, prescriptionLeftEyeSphere, planoLensInd} = this.prescriptionForm.controls;

    this.activeClaim.labOrderInformation.lensPrescription.planoLensSelected = planoLensInd.value;

    if (planoLensInd.value && this.lensSelected) {
      prescriptionRightEyeSphere.setValue('0.00');
      prescriptionLeftEyeSphere.setValue('0.00');
    } else {
      prescriptionRightEyeSphere.setValue('');
      prescriptionLeftEyeSphere.setValue('');
    }

    this.viewStateService.setIsPlanoLensesCheckBoxChecked(this.prescriptionForm.controls.planoLensInd.value);
  }

  /***** END - EVENT HANDLERS *****/

  enablePrescriptionRightEyeDistanceNearFields() {
    this.prescriptionForm.controls.prescriptionRightEyeDistance.enable(ApplicationConstants.updateFormWithoutEmit);
    this.prescriptionForm.controls.prescriptionRightEyeNear.enable(ApplicationConstants.updateFormWithoutEmit);
  }

  clearPrescriptionRightEyeDistanceNearFields() {
    this.prescriptionForm.controls.prescriptionRightEyeDistance.reset();
    this.prescriptionForm.controls.prescriptionRightEyeNear.reset();
  }

  enablePrescriptionLeftEyeDistanceNearFields() {
    this.prescriptionForm.controls.prescriptionLeftEyeDistance.enable();
    this.prescriptionForm.controls.prescriptionLeftEyeNear.enable();
  }

  clearPrescriptionLeftEyeDistanceNearFields() {
    this.prescriptionForm.controls.prescriptionLeftEyeDistance.reset();
    this.prescriptionForm.controls.prescriptionLeftEyeDistance.disable(ApplicationConstants.updateFormWithoutEmit);
    this.prescriptionForm.controls.prescriptionLeftEyeNear.reset();
    this.prescriptionForm.controls.prescriptionLeftEyeNear.disable(ApplicationConstants.updateFormWithoutEmit);
  }

  enableAllRightRxFields() {
    this.prescriptionForm.controls.prescriptionRightEyeSphere.enable();
    this.prescriptionForm.controls.prescriptionRightEyeCylinder.enable();
    this.prescriptionForm.controls.prescriptionRightEyeAxis.enable();
    this.prescriptionForm.controls.prescriptionRightEyeAdd.enable();
    this.enablePrescriptionRightEyeDistanceNearFields();
    if (!this.singleVisionSelected) {
      this.prescriptionForm.controls.prescriptionRightEyeSegmentHeight.enable();
    }
    this.prescriptionForm.controls.prescriptionRightEyeOpticalCenterHeight.enable();
    this.prescriptionForm.controls.prescriptionRightEyeHPrism.enable();
    this.prescriptionForm.controls.prescriptionRightEyeHBase.enable();
    this.prescriptionForm.controls.prescriptionRightEyeVPrism.enable();
    this.prescriptionForm.controls.prescriptionRightEyeVBase.enable();
  }

  disableAllRightRxFields() {
    this.prescriptionForm.controls.prescriptionRightEyeSphere.disable();
    this.prescriptionForm.controls.prescriptionRightEyeCylinder.disable();
    this.prescriptionForm.controls.prescriptionRightEyeAxis.disable();
    this.prescriptionForm.controls.prescriptionRightEyeAdd.disable();
    this.enablePrescriptionLeftEyeDistanceNearFields();
    if (!this.binocularSelected) {
      this.prescriptionForm.controls.prescriptionRightEyeDistance.disable();
      this.prescriptionForm.controls.prescriptionRightEyeNear.disable();
    }
    this.prescriptionForm.controls.prescriptionRightEyeSegmentHeight.disable();
    this.prescriptionForm.controls.prescriptionRightEyeOpticalCenterHeight.disable();
    this.prescriptionForm.controls.prescriptionRightEyeHPrism.disable();
    this.prescriptionForm.controls.prescriptionRightEyeHBase.disable();
    this.prescriptionForm.controls.prescriptionRightEyeVPrism.disable();
    this.prescriptionForm.controls.prescriptionRightEyeVBase.disable();
  }

  clearAllRightRxFields() {
    this.prescriptionForm.controls.prescriptionRightEyeSphere.reset();
    this.prescriptionForm.controls.prescriptionRightEyeCylinder.reset();
    this.prescriptionForm.controls.prescriptionRightEyeAxis.reset();
    this.prescriptionForm.controls.prescriptionRightEyeAdd.reset();
    if (!this.binocularSelected) {
      this.clearPrescriptionRightEyeDistanceNearFields();
    }
    this.prescriptionForm.controls.prescriptionRightEyeSegmentHeight.reset();
    this.prescriptionForm.controls.prescriptionRightEyeOpticalCenterHeight.reset();
    this.prescriptionForm.controls.prescriptionRightEyeHPrism.reset();
    this.prescriptionForm.controls.prescriptionRightEyeHBase.reset();
    this.prescriptionForm.controls.prescriptionRightEyeVPrism.reset();
    this.prescriptionForm.controls.prescriptionRightEyeVBase.reset();
  }

  enableAllLeftRxFields() {
    this.prescriptionForm.controls.prescriptionLeftEyeSphere.enable();
    this.prescriptionForm.controls.prescriptionLeftEyeCylinder.enable();
    this.prescriptionForm.controls.prescriptionLeftEyeAxis.enable();
    this.prescriptionForm.controls.prescriptionLeftEyeAdd.enable();
    this.enablePrescriptionLeftEyeDistanceNearFields();
    if (!this.singleVisionSelected) {
      this.prescriptionForm.controls.prescriptionLeftEyeSegmentHeight.enable();
    }
    this.prescriptionForm.controls.prescriptionLeftEyeOpticalCenterHeight.enable();
    this.prescriptionForm.controls.prescriptionLeftEyeHPrism.enable();
    this.prescriptionForm.controls.prescriptionLeftEyeHBase.enable();
    this.prescriptionForm.controls.prescriptionLeftEyeVPrism.enable();
    this.prescriptionForm.controls.prescriptionLeftEyeVBase.enable();
  }

  disableAllLeftRxFields() {
    this.prescriptionForm.controls.prescriptionLeftEyeSphere.disable();
    this.prescriptionForm.controls.prescriptionLeftEyeCylinder.disable();
    this.prescriptionForm.controls.prescriptionLeftEyeAxis.disable();
    this.prescriptionForm.controls.prescriptionLeftEyeAdd.disable();
    if (!this.binocularSelected) {
      this.prescriptionForm.controls.prescriptionLeftEyeDistance.disable();
      this.prescriptionForm.controls.prescriptionLeftEyeNear.disable();
    }
    this.prescriptionForm.controls.prescriptionLeftEyeSegmentHeight.disable();
    this.prescriptionForm.controls.prescriptionLeftEyeOpticalCenterHeight.disable();
    this.prescriptionForm.controls.prescriptionLeftEyeHPrism.disable();
    this.prescriptionForm.controls.prescriptionLeftEyeHBase.disable();
    this.prescriptionForm.controls.prescriptionLeftEyeVPrism.disable();
    this.prescriptionForm.controls.prescriptionLeftEyeVBase.disable();
  }

  clearAllLeftRxFields() {
    this.prescriptionForm.controls.prescriptionLeftEyeSphere.reset();
    this.prescriptionForm.controls.prescriptionLeftEyeCylinder.reset();
    this.prescriptionForm.controls.prescriptionLeftEyeAxis.reset();
    this.prescriptionForm.controls.prescriptionLeftEyeAdd.reset();
    if (!this.binocularSelected) {
      this.clearPrescriptionLeftEyeDistanceNearFields();
    }
    this.prescriptionForm.controls.prescriptionLeftEyeSegmentHeight.reset();
    this.prescriptionForm.controls.prescriptionLeftEyeOpticalCenterHeight.reset();
    this.prescriptionForm.controls.prescriptionLeftEyeHPrism.reset();
    this.prescriptionForm.controls.prescriptionLeftEyeHBase.reset();
    this.prescriptionForm.controls.prescriptionLeftEyeVPrism.reset();
    this.prescriptionForm.controls.prescriptionLeftEyeVBase.reset();
  }

  disablePlanoLensesCheckBox() {
    this.prescriptionForm.controls.planoLensInd.disable();
  }

  enablePlanoLensesCheckBox() {
    this.prescriptionForm.controls.planoLensInd.enable();
  }

  clearPlanoLensesCheckBox() {
    this.prescriptionForm.controls.planoLensInd.reset();
  }

  prepareActiveClaim(): void {
    if (isNullOrUndefined(this.activeClaim)) {
      this.activeClaim = {} as Claim;
      this.activeClaim.labOrderInformation = {} as LabOrderInformation;
      this.activeClaim.labOrderInformation.labSpecialInstructions = {} as LabSpecialInstructions;
      this.activeClaim.labOrderInformation.lens = {} as SpectacleLens;
      this.activeClaim.labOrderInformation.lensAntiReflectiveCoating = {} as LabOrderLabServices;
      this.activeClaim.labOrderInformation.lensPrescription = {} as EclaimLensPrescription;
    } else if (isNullOrUndefined(this.activeClaim.labOrderInformation)) {
      this.activeClaim.labOrderInformation = {} as LabOrderInformation;
      this.activeClaim.labOrderInformation.labSpecialInstructions = {} as LabSpecialInstructions;
      this.activeClaim.labOrderInformation.lens = {} as SpectacleLens;
      this.activeClaim.labOrderInformation.lensAntiReflectiveCoating = {} as LabOrderLabServices;
      this.activeClaim.labOrderInformation.lensPrescription = {} as EclaimLensPrescription;
    } else {
      if (isNullOrUndefined(this.activeClaim.labOrderInformation.labSpecialInstructions)) {
        this.activeClaim.labOrderInformation.labSpecialInstructions = {} as LabSpecialInstructions;
      }
      if (isNullOrUndefined(this.activeClaim.labOrderInformation.lens)) {
        this.activeClaim.labOrderInformation.lens = {} as SpectacleLens;
      }

      if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensAntiReflectiveCoating)) {
        this.activeClaim.labOrderInformation.lensAntiReflectiveCoating = {} as LabOrderLabServices;
      }

      if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensPrescription)) {
        this.activeClaim.labOrderInformation.lensPrescription = {} as EclaimLensPrescription;
      }
    }

    if (isNullOrUndefined(this.activeClaim.contactLens)) {
      this.activeClaim.contactLens = {} as ContactLens;
      this.activeClaim.contactLens.necessaryContactLensPrescription = {} as BasePrescription;
    } else if (isNullOrUndefined(this.activeClaim.contactLens.necessaryContactLensPrescription)) {
      this.activeClaim.contactLens.necessaryContactLensPrescription = {} as BasePrescription;
    }
  }

  processLabSpecialInstructionSpecialMeasurementItem(): void {
    // Set Dropdown to only have the 'Special Measurement' item in it.
    this.setValuesOnLabDescriptionTypeDropdown(this.labDescriptionSpecialMeasurementTypeDropdown);
    // Set up the UI accordingly when this logic is triggered.
    this.setupLabSpecialInstructionSpecialMeasurementItemInUI();
    // Set boolean flag to true indicating current lens has special measurements.
    this.currentlySelectedLensHasSpecialMeasurement = true;

    // Before we update the data model we want to check the original claim to see if we need to clear out the details info if the type does not match.
    if (this.doesOriginalClaimHaveLabSpecialInstructionsDetails()) {
      // Clear out the details field if the type does not match 'special measurement', which Lens Catalog is telling us to use.
      if (isStringNullUndefinedOrEmptyWithTrim(this.originalClaim.labOrderInformation.labSpecialInstructions.type) || this.originalClaim.labOrderInformation.labSpecialInstructions.type !== this.labDescriptionTypeEnum.SPECIAL_MEASUREMENT) {
        this.setLabSpecialInstructionDetailsFieldsToValue(undefined);
      }
    }
  }

  setupLabSpecialInstructionSpecialMeasurementItemInUI(): void {
    // Check the checkbox and disable it.
    this.prescriptionForm.controls.labSpecialInstructionIndicator.setValue(true);
    this.prescriptionForm.controls.labSpecialInstructionIndicator.disable();
    // Set the value of the dropdown to special measurement and disable the field so the user cannot unselect the item selected.
    this.prescriptionForm.controls.labSpecialInstructionSelect.setValue(this.labDescriptionTypeEnum.SPECIAL_MEASUREMENT);
    this.prescriptionForm.controls.labSpecialInstructionSelect.disable();
  }

  processLabSpecialInstructionNoneSpecialMeasurementItem(): void {
    // Set Dropdown to include the original list and not just the 'Special Measurement' item in it.
    this.setValuesOnLabDescriptionTypeDropdown(this.originalLabDescriptionTypes);
    // Set up the UI accordingly when this logic is triggered.
    this.setupLabSpecialInstructionNoneSpecialMeasurementItemInUI();
    // Set boolean flag back to false since new lens does not have special measurement.
    this.currentlySelectedLensHasSpecialMeasurement = false;

    // Before we update the data model we want to check the original claim to see if we need to clear out the details info if the type does not match.
    if (this.doesOriginalClaimHaveLabSpecialInstructionsDetails()) {
      // Clear out the details field if the type matches 'special measurement', which Lens Catalog is telling us not to use.
      if (isStringNullUndefinedOrEmptyWithTrim(this.originalClaim.labOrderInformation.labSpecialInstructions.type) || this.originalClaim.labOrderInformation.labSpecialInstructions.type === this.labDescriptionTypeEnum.SPECIAL_MEASUREMENT) {
        this.setLabSpecialInstructionDetailsFieldsToValue(undefined);
      }
    }
  }

  setupLabSpecialInstructionNoneSpecialMeasurementItemInUI(): void {
    // Uncheck the checkbox and enable it.
    this.prescriptionForm.controls.labSpecialInstructionIndicator.setValue(false);
    this.prescriptionForm.controls.labSpecialInstructionIndicator.enable();
    // Set the value of the dropdown to undefined and enable the field so the user cannot unselect the item selected.
    this.prescriptionForm.controls.labSpecialInstructionSelect.setValue(undefined);
    this.prescriptionForm.controls.labSpecialInstructionSelect.enable();
  }

  doesOriginalClaimHaveLabSpecialInstructionsDetails(): boolean {
    return this.doesOriginalClaimHaveLabSpecialInstructions()
      && !isStringNullUndefinedOrEmptyWithTrim(this.originalClaim.labOrderInformation.labSpecialInstructions.details);
  }

  doesOriginalClaimHaveLabSpecialInstructions(): boolean {
    return !isNullOrUndefined(this.originalClaim)
      && !isNullOrUndefined(this.originalClaim.labOrderInformation)
      && !isNullOrUndefined(this.originalClaim.labOrderInformation.labSpecialInstructions);
  }

  doesOriginalClaimHaveLabSpecialInstructionsSpecialMeasurement(): boolean {
    return this.doesOriginalClaimHaveLabSpecialInstructions()
      && !isStringNullUndefinedOrEmptyWithTrim(this.originalClaim.labOrderInformation.labSpecialInstructions.type)
      && this.originalClaim.labOrderInformation.labSpecialInstructions.type.trim() === this.labDescriptionTypeEnum.SPECIAL_MEASUREMENT;
  }

  setLabSpecialInstructionDetailsFieldsToValue(value: any): void {
    this.prescriptionForm.controls.labSpecialInstructionDetails.setValue(value);
    this.prescriptionForm.controls.labSpecialInstructionSpecialMeasurementDetails.setValue(value);
  }

  setValuesOnLabDescriptionTypeDropdown(dropDownValues: { label: string; value: LabSpecialInstructionsType }[]): void {
    // Reset labDescriptionTypeDropdown to undefined as a just in case.
    this.labDescriptionTypeDropdown = undefined;
    this.labDescriptionTypeDropdown = dropDownValues;
  }
}
