import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
  authorization: {
    vsrNumber: '',
    businessProductPackageName: '',
    effectiveDate: '',
    expirationDate: '',

  };
  patientFullName: string;
}

@Component({
  selector: 'dialog-overview-example',
  templateUrl: 'delete-authorization-warning-modal.html',
  styleUrls: ['delete-authorization-modal.component.scss'],
})
export class DeleteAuthorizationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteAuthorizationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onCloseClick(): void {
    this.dialogRef.close(false);
  }
}
