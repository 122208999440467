import {Injectable} from '@angular/core';
import {ReplacementFrameChoice} from '../../../../models/fulfillment';

@Injectable({
  providedIn: 'root'
})
export class ReplacementFrameService {

  constructor() {
  }

  /***** START - PRIVATE MEMBERS *****/
  // Boolean flag to display model content from override model or from the view replacement frame options.
  private _replacementModalRenderedFromSupplierOverrideModal: boolean;
  private _replacementModalRenderedFromFrameCard: boolean;
  // DisplayReplacementChoiceIndicator we will be using to display or not display the ReplacementFrameChoices.
  private _displayReplacementChoiceIndicator: string;
  // ReplacementFrameChoice Array we will be using on the Replacement Frame Model.
  private _replacementFrameChoices: ReplacementFrameChoice[];
  // ReplacementFrameChoice Array boolean flag indicating size is equal to 1.
  private _isThereOnlyOneReplacementFrameOption: boolean;
  // This was added to house the selected replacement frame they want to receive.
  private _userSelectedReplacementSku: string = undefined;
  // This flag was added, so we can show or not show the 'displayViewReplacementFrameOptionsLink' link in the 'Send this frame to the lab' dialog.
  private _displayViewReplacementFrameOptionsLink: boolean = false;

  /***** END - PRIVATE MEMBERS *****/

  /***** START - PUBLIC FUNCTIONS *****/
  // Getters and Setters
  get replacementModalRenderedFromSupplierOverrideModal(): boolean {
    return this._replacementModalRenderedFromSupplierOverrideModal;
  }

  set replacementModalRenderedFromSupplierOverrideModal(value: boolean) {
    this._replacementModalRenderedFromSupplierOverrideModal = value;
  }

  get replacementModalRenderedFromFrameCard(): boolean {
    return this._replacementModalRenderedFromFrameCard;
  }

  set replacementModalRenderedFromFrameCard(value: boolean) {
    this._replacementModalRenderedFromFrameCard = value;
  }

  get replacementFrameChoices(): ReplacementFrameChoice[] {
    return this._replacementFrameChoices;
  }

  set replacementFrameChoices(value: ReplacementFrameChoice[]) {
    this._replacementFrameChoices = value;
  }

  get displayReplacementChoiceIndicator(): string {
    return this._displayReplacementChoiceIndicator;
  }

  set displayReplacementChoiceIndicator(value: string) {
    this._displayReplacementChoiceIndicator = value;
  }

  get isThereOnlyOneReplacementFrameOption(): boolean {
    return this._isThereOnlyOneReplacementFrameOption;
  }

  set isThereOnlyOneReplacementFrameOption(value: boolean) {
    this._isThereOnlyOneReplacementFrameOption = value;
  }

  get userSelectedReplacementSku(): string {
    return this._userSelectedReplacementSku;
  }

  set userSelectedReplacementSku(value: string) {
    this._userSelectedReplacementSku = value;
  }

  get displayViewReplacementFrameOptionsLink(): boolean {
    return this._displayViewReplacementFrameOptionsLink;
  }

  set displayViewReplacementFrameOptionsLink(value: boolean) {
    this._displayViewReplacementFrameOptionsLink = value;
  }

  resetReplacementFrameServiceValues() {
    // Reset Boolean Flags.
    this.replacementModalRenderedFromSupplierOverrideModal = false;
    this.replacementModalRenderedFromFrameCard = false;
  }

  /***** END - PUBLIC FUNCTIONS *****/
}
