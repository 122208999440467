import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObjectService {


  constructor() { }


  /***** START - PRIVATE FUNCTIONS *****/
  private getNestedPropertiesRecursively(obj, property): any[] {
    // split the property by period and loop through each property piece
    return property.split('.').reduce( function( prev, curr ) {
      // return undefined if prev is undefined
      if (prev === undefined){
        return prev;
      }
      // check if the last found object is an array
      if (prev instanceof Array){
        // start a new array to return
        const returnArray = [];
        // loop through each property and recursively get the nested property on each array item
        for (let i = 0, l = prev.length; i < l; i++){
          returnArray.push(this.getNestedPropertiesRecursively(prev[i], curr));
        }
        // flatten the array in case it is a nested array so that we only have a one-dimensional array
        return [].concat.apply([], returnArray);
      }
      else {
        // object is not an array, just return the nested property from it
        return prev[curr];
      }
    }, obj);
  }
  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  /**
   * Returns a boolean value indicating if the passed in key exists in the object structure
   * @param {object} objectToSearch
   * @param {string} keyToMatch
   * @returns {boolean}
   */
  objectContains(objectToSearch: any, keyToMatch: string): boolean {
    for (let key in objectToSearch) {
      if (key === keyToMatch) {
        return true;
      }
      if (typeof(objectToSearch[key]) === 'object' && objectToSearch[key] !== null) {
        const innerObject = objectToSearch[key];
        const result = this.objectContains(innerObject, keyToMatch);
        if (result === true) {
          return true;
        }
      }
    }
    return false;
  }

  // returns a nested property from the given object
  // example: obj = {property1: {property2: 'test'}}
  //          property = 'property1.property2'
  //          this would return 'test' (object.property1.property2)
  getNestedProperty(obj, property): any {
    return property.split('.').reduce( function( prev, curr ) {
      return prev[curr];
    }, obj);
  }

  // similar to getNestedProperty but can be used when nested properties are arrays. Returns either an array of nested values
  // or undefined (if no matching properties found on the object)
  // example:
  //    obj = {property1: [{arrayProperty1: 'test1'}, {arrayProperty2: 'test2'}]}
  //    property = 'property1.arrayProperty1'
  //    this would return: ['test1', 'test2']
  getNestedProperties(obj, property): any[] | undefined {
    // get the nested properties recursively
    let returnValue = this.getNestedPropertiesRecursively(obj, property);
    // if the value is defined but not an array, make it an array before returning
    // this way the return value is always an array and consumers of this function don't need to check if it's an array
    if (!(returnValue === undefined) && !(returnValue instanceof Array)){
      returnValue = [returnValue];
    }
    return returnValue;
  }

  // options (all optional):
  //      considerFalseBlank: if this is true, a boolean value of false will be considered blank
  isBlank(value, options?): boolean {
    options = options || {};
    // default options.considerFalseBlank to false
    if (options.considerFalseBlank === undefined){
      options.considerFalseBlank = false;
    }
    return (value === undefined || value === null || (typeof value === 'string' && value.trim().length === 0) ||
      (options.considerFalseBlank && value === false));
  }

  // checks each property (and each property in nested objects) to see if it has a value (not null, undefined or empty string)
  // options (all optional):
  //      considerFalseBlank: if this is true, a boolean value of false will be considered blank
  isEmptyObject(obj, options?): boolean {
    options = options || {};
    let propertyValue;
    // loop through each property in the object
    for (let property in obj){
      // if object has it's own property (not inherited properties) and is not an angular property (starts with $$)...
      if (obj.hasOwnProperty(property) && property.indexOf('$$') !== 0){
        // get the value of the property on the object
        propertyValue = obj[property];
        // if the value isn't blank...
        if (!this.isBlank(propertyValue, options)){
          if (propertyValue instanceof Array){
            // if the property value is an array, loop through each item and check for a non-blank value
            for (let i = 0, l = propertyValue.length; i < l; i++){
              if (!this.isBlank(propertyValue[i])){
                // found a non-blank array item - return false, this object isn't empty
                return false;
              }
            }
          }
          else if (propertyValue instanceof Date){
            // if the property value is a Date instance, return false if it is a finite value (invalid dates
            // will be NaN, which will return false for isFinite). This needs to occur before the isObject check
            // below, since a Date is also an object
            if (isFinite(propertyValue as any)){
              return false;
            }
          }
          else if (propertyValue !== null && typeof propertyValue === 'object'){
            // if the property value is another object, recursively call isEmptyObject on it
            if (!this.isEmptyObject(propertyValue)){
              // child object is not empty - return false
              return false;
            }
          }
          else {
            // this is a non-blank primitive - return false, object is not empty
            return false;
          }
        }
      }
    }
    // if we got this far, we didn't find any valid values on the object. return true, object is empty
    return true;
  }

  // compares an object with the previous version of the object and returns a list of properties that changed
  getChangedProperties(newObject, oldObject): any[] {
    const changedProperties = [];
    // create a copy of the old object so that we can modify it without changing the original
    const oldObjectCopy = Object.deepClone(oldObject);
    // loop through each property in the new object
    for (var property in newObject){
      // check that the property isn't an inherited property or angular property (angular properties start with $$)
      if (newObject.hasOwnProperty(property) && property.indexOf('$$') !== 0){
        // check if the object is equal
        if (!(newObject[property] === oldObjectCopy[property])){
          // object is not equal - add the property to the list of changed properties
          changedProperties.push(property);
        }
        // remove the property from the old object - we will loop through the remaining properties later
        delete oldObjectCopy[property];
      }
    }
    // loop through the remaining properties on the old object. any properties that were in the new object will
    // have been removed above, which means that any property left in the old object has been removed in the new object,
    // therefore that property has changed
    for (let oldProperty in oldObjectCopy){
      // check that the property isn't an inherited property or angular property (angular properties start with $$)
      if (oldObjectCopy.hasOwnProperty(oldProperty) && oldProperty.indexOf('$$') !== 0){
        changedProperties.push(oldProperty);
      }
    }
    return changedProperties;
  }
  /***** END - PUBLIC FUNCTIONS *****/

}
