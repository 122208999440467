import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FulfillmentDataService} from '../../http/http-client-data/fulfillment-data/fulfillment-data.service';
import {
  FrameDropshipOrderRequest,
  FrameOrderRequest,
  FrameReplacementOrderRequest,
  ReplacementChoiceIndicatorEnum
} from '../../../../../models/fulfillment';
import {DateUtility, isNullOrUndefined, isStringNullUndefinedOrEmptyWithTrim} from '../../../../utility';
import {FulfillmentConstants} from '../../http/http-client-data/fulfillment-data/fulfillment.constants';
import {Suppliers} from '../../../../../models/frame';
import {ReplacementFrameService} from '../../../support/replacement-frame/replacement.frame.service';

@Injectable({
  providedIn: 'root'
})
export class FulfillmentService {

  constructor(
    private fulfillmentDataService: FulfillmentDataService,
    private replacementFrameService: ReplacementFrameService
  ) { }

  /***** START - PRIVATE MEMBERS *****/

  private _dispositionCode: string;
  private _dispositionDescription: string;
  private _originalSku: string; // Selected frame
  private _replacementSku: string; // null for top-selling
  private _selectedSupplier: string;
  private _practiceHasFrame: boolean = false;
  private _participatesInKScope: boolean = false; // Added this flag in this service to accommodate the practices participating in KScope.
  private _isTopSellingFrame: boolean = false;
  private _dispositionCallFinishedSuccessfully: boolean = false;
  private _isFulfillmentApiCallFromClaimNavigationService: boolean = false;
  private _isDoctorSuppliedFromSupplierOverride: boolean = false;
  private _isSlowSellingFrameOrderable: boolean = false;
  private _isSlowSellingFrameNotOrderable: boolean = false;
  private _isBackOrderedFrameAvailable: boolean = false;
  private _isBackOrderedFrame: boolean = false;
  private _isBackOrderedFrameOutOfStock: boolean = false;
  private _backOrderDate: string;
  private _frameDropShipOrderCallFailed: boolean = false;
  private _frameReplacementOrderCallFailed: boolean = false;
  private _wasFrameDropShipOrderCall: boolean = false;
  private _wasFrameReplacementOrderCall: boolean = false;
  private _isFrameMissingSkuNumber: boolean = false;
  private _currentKScopeBanner: string;
  // ECLAIM-191 - The below 3 boolean parameters where added.
  private _isInOfficeStockLens: boolean = false;
  private _isInOfficeUncutLens: boolean = false;
  private _canFrameBeClearedOnLensFinishingChange: boolean = false;

  /***** END - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/

  onIsVendorSupplied: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onIsDoctorSupplied: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onIsTopSellingFrame: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onIsSlowSellingFrame: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onIsManufacturerNotParticipating: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onIsDispositionError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onAllClaimFormCardsHaveLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onUpdateLabList: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onUpdateLabListToRemoveCategories: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onIsSkuMissingInFrame: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onHideReplacementFrameOptionsLink: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /***** END - PUBLIC MEMBERS *****/

  /***** START - PROPERTY ACCESSORS *****/


  get dispositionCode(): string {
    return this._dispositionCode;
  }

  set dispositionCode(value: string) {
    this._dispositionCode = value;
  }

  get dispositionDescription(): string {
    return this._dispositionDescription;
  }

  set dispositionDescription(value: string) {
    this._dispositionDescription = value;
  }

  get originalSku(): string {
    return this._originalSku;
  }

  set originalSku(value: string) {
    this._originalSku = value;
  }

  get replacementSku(): string {
    return this._replacementSku;
  }

  set replacementSku(value: string) {
    this._replacementSku = value;
  }

  get selectedSupplier(): string {
    return this._selectedSupplier;
  }

  set selectedSupplier(value: string) {
    this._selectedSupplier = value;
  }

  get practiceHasFrame(): boolean {
    return this._practiceHasFrame;
  }

  set practiceHasFrame(value: boolean) {
    this._practiceHasFrame = value;
  }

  get participatesInKScope(): boolean {
    return this._participatesInKScope;
  }

  set participatesInKScope(value: boolean) {
    this._participatesInKScope = value;
  }

  get isTopSellingFrame(): boolean {
    return this._isTopSellingFrame;
  }

  set isTopSellingFrame(value: boolean) {
    this._isTopSellingFrame = value;
  }

  get dispositionCallFinishedSuccessfully(): boolean {
    return this._dispositionCallFinishedSuccessfully;
  }

  set dispositionCallFinishedSuccessfully(value: boolean) {
    this._dispositionCallFinishedSuccessfully = value;
  }

  get isFulfillmentApiCallFromClaimNavigationService(): boolean {
    return this._isFulfillmentApiCallFromClaimNavigationService;
  }

  set isFulfillmentApiCallFromClaimNavigationService(value: boolean) {
    this._isFulfillmentApiCallFromClaimNavigationService = value;
  }

  get isDoctorSuppliedFromSupplierOverride(): boolean {
    return this._isDoctorSuppliedFromSupplierOverride;
  }

  set isDoctorSuppliedFromSupplierOverride(value: boolean) {
    this._isDoctorSuppliedFromSupplierOverride = value;
  }

  get isSlowSellingFrameOrderable(): boolean {
    return this._isSlowSellingFrameOrderable;
  }

  set isSlowSellingFrameOrderable(value: boolean) {
    this._isSlowSellingFrameOrderable = value;
  }

  get isSlowSellingFrameNotOrderable(): boolean {
    return this._isSlowSellingFrameNotOrderable;
  }

  set isSlowSellingFrameNotOrderable(value: boolean) {
    this._isSlowSellingFrameNotOrderable = value;
  }

  get isBackOrderedFrameAvailable(): boolean {
    return this._isBackOrderedFrameAvailable;
  }

  set isBackOrderedFrameAvailable(value: boolean) {
    this._isBackOrderedFrameAvailable = value;
  }

  get isBackOrderedFrame(): boolean {
    return this._isBackOrderedFrame;
  }

  set isBackOrderedFrame(value: boolean) {
    this._isBackOrderedFrame = value;
  }

  get isBackOrderedFrameOutOfStock(): boolean {
    return this._isBackOrderedFrameOutOfStock;
  }

  set isBackOrderedFrameOutOfStock(value: boolean) {
    this._isBackOrderedFrameOutOfStock = value;
  }

  get isBackOrderDate(): string {
    return this._backOrderDate;
  }

  set isBackOrderDate(value) {
    this._backOrderDate = value;
  }

  get frameDropShipOrderCallFailed(): boolean {
    return this._frameDropShipOrderCallFailed;
  }

  set frameDropShipOrderCallFailed(value: boolean) {
    this._frameDropShipOrderCallFailed = value;
  }

  get frameReplacementOrderCallFailed(): boolean {
    return this._frameReplacementOrderCallFailed;
  }

  set frameReplacementOrderCallFailed(value: boolean) {
    this._frameReplacementOrderCallFailed = value;
  }

  get wasFrameDropShipOrderCall(): boolean {
    return this._wasFrameDropShipOrderCall;
  }

  set wasFrameDropShipOrderCall(value: boolean) {
    this._wasFrameDropShipOrderCall = value;
  }

  get wasFrameReplacementOrderCall(): boolean {
    return this._wasFrameReplacementOrderCall;
  }

  set wasFrameReplacementOrderCall(value: boolean) {
    this._wasFrameReplacementOrderCall = value;
  }

  get isFrameMissingSkuNumber(): boolean {
    return this._isFrameMissingSkuNumber;
  }

  set isFrameMissingSkuNumber(value: boolean) {
    this._isFrameMissingSkuNumber = value;
  }

  get currentKScopeBanner(): string {
    return this._currentKScopeBanner;
  }

  set currentKScopeBanner(value: string) {
    this._currentKScopeBanner = value;
  }

  get isInOfficeStockLens(): boolean {
    return this._isInOfficeStockLens;
  }

  set isInOfficeStockLens(value: boolean) {
    this._isInOfficeStockLens = value;
  }

  get isInOfficeUncutLens(): boolean {
    return this._isInOfficeUncutLens;
  }

  set isInOfficeUncutLens(value: boolean) {
    this._isInOfficeUncutLens = value;
  }

  get canFrameBeClearedOnLensFinishingChange(): boolean {
    return this._canFrameBeClearedOnLensFinishingChange;
  }

  set canFrameBeClearedOnLensFinishingChange(value: boolean) {
    this._canFrameBeClearedOnLensFinishingChange = value;
  }

  /***** END - PROPERTY ACCESSORS *****/

  /***** START - PUBLIC FUNCTIONS *****/

  /**
   * Method searches for dispositions for a given SKU.
   *
   * @param sku - Frame SKU we want to get the dispositions from.
   * @param manufacturer - Manufacturer of the frame we are doing a look-up on.
   */
  getFrameDispositions(sku: string, manufacturer: string): Observable<boolean> {
    return new Observable((observer) => {
      this.fulfillmentDataService.getFrameDispositions(sku, manufacturer).subscribe((frameDispositionResponse) => {
        // This flag was added, so we can show or not show the 'displayViewReplacementFrameOptionsLink' link in the 'Send this frame to the lab' dialog.
        this.replacementFrameService.displayViewReplacementFrameOptionsLink = false;
        // We also added this observable, so we can let the frame card know to reset the value to false.
        this.onHideReplacementFrameOptionsLink.next(true);
        // This was added for ECLAIM-275. We want to reset the 'ReplacementFrameChoices' in the 'ReplacementFrameService' regardless if disposition call was successful or not.
        this.replacementFrameService.replacementFrameChoices = [];
        // Per ECLAIM-275 we also need to set the displayReplacementChoiceIndicator to N regardless of the response coming back.
        this.replacementFrameService.displayReplacementChoiceIndicator = ReplacementChoiceIndicatorEnum.N;
        // Per ECLAIM-275 we also need to set the isThereOnlyOneReplacementFrameOption to false regardless of the response coming back.
        this.replacementFrameService.isThereOnlyOneReplacementFrameOption = false;
        // This flag was added for ECLAIM-60 and we want to reset it back to its original value.
        this.onIsSkuMissingInFrame.next(false);
        // This was added for ECLAIM-28. We need to reset it to undefined so that if a selection is done in the supplier drop down after the disposition call has been made we can set the correct banner in it.
        this.currentKScopeBanner = undefined;
        if (isNullOrUndefined(frameDispositionResponse)) {
          // Per ECLAIM-84 we want to reset the service properties to their original values when a call fails. This was added to safeguard the submission process without any KScope issues.
          this.resetServiceValuesToOriginalState();
          this.onIsDispositionError.next(true);
          this.selectedSupplier = Suppliers.DOCTOR;
          this.onIsDoctorSupplied.next(true);
          // Per ECLAIM-84 we want to remove the KScope categories in the lab dropdown if they exist when the dispositions call fails or times out.lai
          this.onUpdateLabList.next(true);
        } else {
          // Added the 'dispositionCallFinishedSuccessfully' flag for ECLAIM-28, so we can determine if the call to dispositions has finished
          // and KScope suggestions have been made to the user. Its main intention is to make sure we prompt the user correctly
          // when the user tries to change the supplier drop down after it has been set via the KScope suggestion.
          this.dispositionCallFinishedSuccessfully = true;
          // Per ECLAIM-275 we want to check the displayReplacementChoiceIndicator and replacementFrameChoices returned in the dispositions' response.
          if (!isStringNullUndefinedOrEmptyWithTrim(frameDispositionResponse.displayReplacementChoiceIndicator)
            && frameDispositionResponse.displayReplacementChoiceIndicator === ReplacementChoiceIndicatorEnum.Y
            && !isNullOrUndefined(frameDispositionResponse.replacementFrameChoices) && frameDispositionResponse.replacementFrameChoices.length > 0) {
            // This flag was added, so we can show or not show the 'displayViewReplacementFrameOptionsLink' link in the 'Send this frame to the lab' dialog.
            this.replacementFrameService.displayViewReplacementFrameOptionsLink = true;
            // Set the DisplayReplacementChoiceIndicator and ReplacementFrameChoices in the ReplacementFrameService.
            this.replacementFrameService.displayReplacementChoiceIndicator = ReplacementChoiceIndicatorEnum.Y;
            this.replacementFrameService.replacementFrameChoices = frameDispositionResponse.replacementFrameChoices;
            // Set the appropriate flags to display the correct Replacement Frame Model Header
            if (frameDispositionResponse.replacementFrameChoices.length === 1) {
              this.replacementFrameService.isThereOnlyOneReplacementFrameOption = true;
            }
          }
          if (frameDispositionResponse.dispositionCode !== FulfillmentConstants.manufacturerNotParticipating) {
            this.participatesInKScope = true;
            this.dispositionCode = frameDispositionResponse.dispositionCode;
            if (isStringNullUndefinedOrEmptyWithTrim(frameDispositionResponse.replacementSku)) {
              this.isTopSellingFrame = true;
              this.onIsTopSellingFrame.next(true);
              this.selectedSupplier = Suppliers.VENDOR;
              this.onIsVendorSupplied.next(true);
            } else {
              this.onIsSlowSellingFrame.next(true);
              if (frameDispositionResponse.dispositionCode === FulfillmentConstants.requestedSkuAndReplacementSkuAvailable) {
                // Slow Selling Frame Not In Practice & Frame Orderable
                this.isSlowSellingFrameOrderable = true;
                this.isBackOrderedFrameAvailable = true;
                this.isBackOrderedFrameOutOfStock = false;
                this.isBackOrderedFrame = false;
                this.onIsVendorSupplied.next(true);
              } else if (frameDispositionResponse.dispositionCode === FulfillmentConstants.requestedSkuIsOutOfStockReplacementSkuAvailable) {
                // Slow Selling Frame Not in Practice & Out Of Stock
                this.isBackOrderedFrameOutOfStock = true;
                this.isBackOrderedFrame = false;
                this.isBackOrderedFrameAvailable = false;
                this.isSlowSellingFrameOrderable = false;
              } else if (frameDispositionResponse.dispositionCode === FulfillmentConstants.requestedSkuIsBackOrderedAndReplacementSkuAvailable) {
                // Slow Selling Frame - Backorder
                this.isBackOrderedFrame = true;
                // Back Order Date from Response
                if (!isStringNullUndefinedOrEmptyWithTrim(frameDispositionResponse.backOrderDate)) {
                  this.isBackOrderDate = DateUtility.buildFriendlyDateFromJsDate(frameDispositionResponse.backOrderDate);
                }
                this.isBackOrderedFrameAvailable = false;
                this.isBackOrderedFrameOutOfStock = false;
                this.isSlowSellingFrameOrderable = false;
              }
            }
            this.originalSku = sku;
            this.replacementSku =  frameDispositionResponse.replacementSku;
          } else {
            this.participatesInKScope = false;
            this.onIsManufacturerNotParticipating.next(true);
            this.selectedSupplier = Suppliers.DOCTOR;
            this.onIsDoctorSupplied.next(true);
          }
          // We added this observable for ECLAIM-10 to notify the lab.component that a change was made so that it can update the lab list appropriately.
          this.onUpdateLabList.next(true);
        }
        observer.next(true);
        observer.complete();
      });
    });
  }

  /***
   * Method responsible for placing dropship or replacement order depending upon the disposition type and
   * supplier option that was chosen.
   *
   * @param frameOrderRequest - the FrameOrderRequest received
   */
  finalizeFrameFulfillment(frameOrderRequest: FrameOrderRequest): Observable<boolean> {
    return new Observable((observer) => {
      if (this.isTopSellingFrame) {
        if (this.selectedSupplier === Suppliers.VENDOR) {
          this.orderFrameDropship(frameOrderRequest).subscribe((dropShipCallHasFinished) => {
            observer.next(dropShipCallHasFinished);
            observer.complete();
          });
          // This else if was added for ECLAIM-28 newly added AC: If doctor supplied and an override then send a replacement call.
        } else if (this.selectedSupplier === Suppliers.DOCTOR && this.isDoctorSuppliedFromSupplierOverride) {
          // Reset flag just in case.
          this.isDoctorSuppliedFromSupplierOverride = false;
          // The below line was added for ECLAIM-96 to make sure we set the replacement sku when we override the supplier dropdown on a top selling frame.
          this.replacementSku = this.originalSku;
          this.orderFrameReplacement(frameOrderRequest).subscribe((frameReplacementCallHasFinished) => {
            observer.next(frameReplacementCallHasFinished);
            observer.complete();
          });
        } else {
          // For top-selling frame when supplier is NOT vendor and isDoctorSuppliedFromSupplierOverride flag is false.
          // ECLAIM-191 - Handle the IOF Stock lenses on Submission.
          if (this.isInOfficeStockLens || this.isInOfficeUncutLens) {
            this.orderFrameReplacement(frameOrderRequest).subscribe((frameReplacementCallHasFinished) => {
              observer.next(frameReplacementCallHasFinished);
              observer.complete();
            });
          }
        }
      } else {
        // For slow selling frame
        // This logic was added for ECLAIM-7.
        // ECLAIM-191 - Second condition was added to handle the IOF Stock lenses on Submission.
        if (this.practiceHasFrame || (this.isInOfficeStockLens || this.isInOfficeUncutLens)) {
          this.orderFrameReplacement(frameOrderRequest).subscribe((frameReplacementCallHasFinished) => {
            observer.next(frameReplacementCallHasFinished);
            observer.complete();
          });
        } else {
          this.orderFrameDropship(frameOrderRequest).subscribe((dropShipCallHasFinished) => {
            observer.next(dropShipCallHasFinished);
            observer.complete();
          });
        }
      }
    });
  }

  /**
   * Method makes the call to order a replacement and will handle logging when it is available.
   *
   * @param frameOrderRequest - Request we are going to use to build the replacement request.
   */
  orderFrameReplacement(frameOrderRequest: FrameOrderRequest): Observable<boolean> {
    return new Observable((observer) => {
      this.fulfillmentDataService.orderFrameReplacement(this.getReplacementRequest(frameOrderRequest)).subscribe((response) => {
        // Set these flags to true. We only want to set them to true is the call is successful.
        this.wasFrameReplacementOrderCall = false;
        this.wasFrameDropShipOrderCall = false;
        // If Response from the call comes back as null or undefined then set the frameReplacementOrderCallFailed flag to true.
        if (isNullOrUndefined(response)) {
          this.frameReplacementOrderCallFailed = true;
        }
        // This was added for ECLAIM-73, so we can determine what message we should render on the submit modal depending on submission call.
        this.wasFrameReplacementOrderCall = true;
        // Set this flag to false as a just in case, so we can render correct message.
        this.wasFrameDropShipOrderCall = false;
        observer.next(true);
        observer.complete();
      });
    });
  }

  /**
   * Method makes the call to dropShip and will handle logging when it is available.
   *
   * @param frameOrderRequest - Request we are going to use to build the dropShip request.
   */
  orderFrameDropship(frameOrderRequest: FrameOrderRequest): Observable<boolean> {
    return new Observable((observer) => {
      this.fulfillmentDataService.orderFrameDropship(this.getDropshipRequest(frameOrderRequest)).subscribe((response) => {
        // Set these flags to true. We only want to set them to true is the call is successful.
        this.wasFrameDropShipOrderCall = false;
        this.wasFrameReplacementOrderCall = false;
        // If Response from the call comes back as null or undefined then set the frameReplacementOrderCallFailed flag to true.
        if (isNullOrUndefined(response)) {
          this.frameDropShipOrderCallFailed = true;
        }
        // This was added for ECLAIM-73, so we can determine what message we should render on the submit modal depending on submission call.
        this.wasFrameDropShipOrderCall = true;
        // Set this flag to false as a just in case, so we can render correct message.
        this.wasFrameReplacementOrderCall = false;
        observer.next(true);
        observer.complete();
      });
    });
  }

  /***** END - PUBLIC FUNCTIONS *****/

  getDropshipRequest (frameOrderRequest: FrameOrderRequest): FrameDropshipOrderRequest {
    const frameDropshipOrderRequest: FrameDropshipOrderRequest = new FrameDropshipOrderRequest();
    // Per ECLAIM-84 we want to add the missing value in the request.
    frameDropshipOrderRequest.setExternalLabId = frameOrderRequest.getExternalLabId;
    frameDropshipOrderRequest.setFrameSku = frameOrderRequest.getFrameSku;
    frameDropshipOrderRequest.setOrderNumber = frameOrderRequest.getOrderNumber;
    frameDropshipOrderRequest.setNotes = frameOrderRequest.getNotes;
    frameDropshipOrderRequest.setManufacturer = frameOrderRequest.getManufacturer;
    return frameDropshipOrderRequest;
  }

  getReplacementRequest (frameOrderRequest: FrameOrderRequest): FrameReplacementOrderRequest {
    const frameReplacementOrderRequest: FrameReplacementOrderRequest = new FrameReplacementOrderRequest();
    //  Per ECLAIM-84 we want to map to the correct values in the request.
    frameReplacementOrderRequest.setFrameSku = this.replacementSku;
    frameReplacementOrderRequest.setOriginalSku = this.originalSku;
    frameReplacementOrderRequest.setManufacturer = frameOrderRequest.getManufacturer;
    // ECLAIM-191 - Added this new parameter.
    if (this.isInOfficeStockLens || this.isInOfficeUncutLens) {
      // ECLAIM-191 - We want to make sure we set the FrameSku to the original sku being passed in the search if the replacement sku is null.
      // This is so we can handle the situation when the frame being searched for is a top seller.
      if (isStringNullUndefinedOrEmptyWithTrim(frameReplacementOrderRequest.getFrameSku)) {
        frameReplacementOrderRequest.setFrameSku = this.originalSku;
      }
      let stockOrUncut: string;
      if (this.isInOfficeStockLens) {
        stockOrUncut = 'IOF';
      } else if (this.isInOfficeUncutLens) {
        stockOrUncut = 'Uncut';
      }
      frameReplacementOrderRequest.setProgramMode = stockOrUncut + ': Auth: ' + this.fulfillmentDataService.vsrNumber;
    }
    // ECLAIM-275 - We want to override the replacement sku previously set by the service response if the user selects a different sku from the list provided.
    if (!isStringNullUndefinedOrEmptyWithTrim(this.replacementFrameService.userSelectedReplacementSku)) {
      frameReplacementOrderRequest.setFrameSku = this.replacementFrameService.userSelectedReplacementSku;
    }
    return frameReplacementOrderRequest;
  }

  resetServiceValuesToOriginalState(): void {
    this.practiceHasFrame = false;
    this.participatesInKScope = false;
    this.isTopSellingFrame = false;
    this.dispositionCallFinishedSuccessfully = false;
    this.isFulfillmentApiCallFromClaimNavigationService = false;
    this.isDoctorSuppliedFromSupplierOverride = false;
    this.isSlowSellingFrameOrderable = false;
    this.isSlowSellingFrameNotOrderable = false;
    this.isBackOrderedFrameAvailable = false;
    this.isBackOrderedFrame = false;
    this.isBackOrderedFrameOutOfStock = false;
    this.frameDropShipOrderCallFailed = false;
    this.frameReplacementOrderCallFailed = false;
    this.wasFrameDropShipOrderCall = false;
    this.wasFrameReplacementOrderCall = false;
    this.isFrameMissingSkuNumber = false;
    this.currentKScopeBanner = undefined;
  }

}
