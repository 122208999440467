import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {DrReportsNavigationService} from '../../../common/services/support/dr-reports-navigation/dr-reports-navigation.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Claim} from '../../../models/claim';
import {ClaimsService} from '../../../common/services/data-model/app/claims/claims.service';
import {MatDialog} from '@angular/material/dialog';
import {ProvideFeedbackModalComponent} from './provide-feedback-modal/provide-feedback-modal.component';

@Component({
  selector: 'app-print-view-reports',
  templateUrl: './print-view-reports.component.html',
  styleUrls: ['./print-view-reports.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrintViewReportsComponent implements OnInit {

  constructor(
    private claimsService: ClaimsService,
    private drReportsNavigationService: DrReportsNavigationService,
    private deviceService: DeviceDetectorService,
    private dialog: MatDialog
  ) { }

  private originalClaim: Claim;

  ngOnInit() {
  }

  onReportsButtonClick(): void {
    this.originalClaim = this.claimsService.getOriginalClaim();
    this.drReportsNavigationService.navigateToPatientRecordReport(this.originalClaim.trackingNumber);
  }

  print(): void {
    if (this.deviceService.browser.indexOf('Firefox') <= -1) {
      window.print();
    }
  }

  onProvideFeedbackClick(): void {
    const dialogRef = this.dialog.open(ProvideFeedbackModalComponent, {
      height: '710px',
      width: '720px',
      panelClass: 'eclaim-popup-modal',
      disableClose: true,
    });
  }
}
