<div fxLayout="column" class="modal-parent">

  <!-- Header Section -->
  <mat-card>
    <!-- Header -->

    <mat-card-header>

      <mat-card-title>
        <div class="delete-authorization-warning-modal">
          <h1 class="nested-column-11 padding-right-0 padding-left-0 padding-top-0 padding-bottom-0"
              id="delete-this-authorization" mat-dialog-title>Delete This Authorization</h1>
        </div>
      </mat-card-title>
      <!-- Close Icon -->
      <div class="delete-authorization-warning-modal">
        <button [disableRipple]="true" class="close-button" mat-icon-button>
          <mat-icon (click)="onCloseClick()" id="delete-authorization-close-button"
                    svgIcon="eclaim-icons:close"></mat-icon>
        </button>
      </div>
    </mat-card-header>

    <!-- Body -->
    <mat-card-content>
      <div class="delete-authorization-warning-modal">

        <!-- Authorization Recap -->
        <div class="delete-authorization-warning-modal-table">

          <!-- Authorization Number -->
          <div class="delete-authorization-warning-modal-table-row">
            <label>Authorization:</label>
            <p>{{data.authorization.vsrNumber}}</p>
          </div>

          <!-- Patient Name -->
          <div class="delete-authorization-warning-modal-table-row">
            <label>Patient Name:</label>
            <p>{{data.patientFullName}}</p>
          </div>

          <!-- Product -->
          <div class="delete-authorization-warning-modal-table-row">
            <label>Product:</label>
            <p>{{data.authorization.businessProductPackageName}}</p>
          </div>

          <!-- Effective Date -->
          <div class="delete-authorization-warning-modal-table-row">
            <label>Effective Date:</label>
            <p>{{data.authorization.effectiveDate}}</p>
          </div>

          <!-- Expiration Date -->
          <div class="delete-authorization-warning-modal-table-row">
            <label>Expiration Date:</label>
            <p>{{data.authorization.expirationDate}}</p>
          </div>

        </div>
      </div>
    </mat-card-content>

    <!-- Footer Line -->
    <hr/>

    <!-- OK/Cancel buttons  -->
    <mat-card-actions>
      <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="end center" class="margin-top-20 margin-bottom-20">

        <!--  ########################################################################################################## -->
        <!--  Cancel Button -->
        <!--  ########################################################################################################## -->
        <button mat-dialog-close class="cancel-button" id="cancelButton">Cancel
        </button>
        <div fxFlexOffset="30px"></div>

        <!--  ########################################################################################################## -->
        <!--  Ok Button -->
        <!--  ########################################################################################################## -->
        <button mat-dialog-close class="btn btn-primary btn-extra-pad"
                id="okButton" [mat-dialog-close]="true">OK
        </button>
        <div fxFlexOffset="20px"></div>

      </div>
    </mat-card-actions>

  </mat-card>

</div>
