import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { ApplicationConstants } from '../../../../constants/application.constants';
import {
  ContactLensOperation,
  ContactLensResponse,
  ContactLensService,
  MaterialType, Reason, Service, Manufacturer, Modality
} from '../../../../../models/contactLens';
import {ContactLensDataService} from '../../http/http-client-data/contacts-data/contact-lens-data.service';
import {isNullOrUndefined} from '../../../../utility';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {


  /***** START - PRIVATE MEMBERS *****/
  private _materialType: MaterialType[];
  private _service: Service[];
  private _reason: Reason[];
  private _manufacturer: Manufacturer[];
  private _modality: Modality[];
  /***** END - PRIVATE MEMBERS *****/


  constructor(
    private contactLensService: ContactLensDataService
  ) { }


  /***** START - PROPERTY ACCESSORS *****/
  get materialType(): MaterialType[] {
    return(this._materialType) ? JSON.parse(JSON.stringify(this._materialType)) : this._materialType;
  }

  set materialType(items: MaterialType[]) {
    this._materialType = items;
  }

  get service(): Service[] {
    return(this._service) ? JSON.parse(JSON.stringify(this._service)) : this._service;
  }

  set service(items: Service[]) {
    this._service = items;
  }

  get reason(): Reason[] {
    return(this._reason) ? JSON.parse(JSON.stringify(this._reason)) : this._reason;
  }

  set reason(items: Reason[]) {
    this._reason = items;
  }

  get manufacturer(): Manufacturer[] {
    return(this._manufacturer) ? JSON.parse(JSON.stringify(this._manufacturer)) : this._manufacturer;
  }

  set manufacturer(items: Manufacturer[]) {
    this._manufacturer = items;
  }

  get modality(): Modality[] {
    return(this._modality) ? JSON.parse(JSON.stringify(this._modality)) : this._modality;
  }

  set modality(items: Modality[]) {
    this._modality = items;
  }

  /***** END - PROPERTY ACCESSORS *****/

  /***** START - PRIVATE FUNCTIONS *****/

  /**
   * Generic data layer implementation of loading data from the contacts API.
   *
   * @param property - member property to set
   * @param operation - typed endpoint to call
   * @param endpoint - path to retrieve lens catalog data
   * @param params - optional query parameters to pass along to the HTTP layer
   * @param isNonLabService - optional flag to call lab service
   */
  private loadContactLensService<T>(property: string, operation: string, endpoint: string ): Observable<T[]> {
    return new Observable<T[]>((observer: Subscriber<T[]>) => {
      this.contactLensService.getContactLensItem<T>(operation, endpoint ).subscribe((response: ContactLensResponse<T>) => {
        if (response) {
          if (isNullOrUndefined(response.items) || response.items.length < 1) {
            Reflect.set(this, property, undefined);
            observer.next(undefined);
          } else {
            Reflect.set(this, property, response.items);
            observer.next(response.items);
          }
        } else {
          observer.next(undefined);
        }

        observer.complete();
      });
    });
  }

  private loadClaimsOnlineReferenceService<T>(property: string, operation: string, endpoint: string ): Observable<T[]> {
    return new Observable<T[]>((observer: Subscriber<T[]>) => {
      this.contactLensService.getClaimsOnlineReferenceItem<T>(operation, endpoint ).subscribe((response: ContactLensResponse<T>) => {
        if (response) {
          if (isNullOrUndefined(response.items) || response.items.length < 1) {
            Reflect.set(this, property, undefined);
            observer.next(undefined);
          } else {
            Reflect.set(this, property, response.items);
            observer.next(response.items);
          }
        } else {
          observer.next(undefined);
        }

        observer.complete();
      });
    });
  }

  /*
  * Manufacturer API for what ever reason returns a different model from the rest of the eclaim reference/contacts APIs, so it has its own special method
   */
  private loadContactLensManufacturer<T>(property: string, operation: string, endpoint: string ): Observable<Manufacturer[]> {
    return new Observable<Manufacturer[]>((observer: Subscriber<Manufacturer[]>) => {
      this.contactLensService.getContactLensItem<T>(operation, endpoint ).subscribe((response: ContactLensResponse<T>) => {
        if (response) {
          if (isNullOrUndefined(response.manufacturers) || response.manufacturers.length < 1) {
            Reflect.set(this, property, undefined);
            observer.next(undefined);
          } else {
            Reflect.set(this, property, response.manufacturers);
            observer.next(response.manufacturers);
          }
        } else {
          observer.next(undefined);
        }

        observer.complete();
      });
    });
  }

  private loadContactLensModality<T>(property: string, operation: string, endpoint: string ): Observable<T[]> {
    return new Observable<T[]>((observer: Subscriber<T[]>) => {
      this.contactLensService.getContactLensItem<T>(operation, endpoint ).subscribe((response: ContactLensResponse<T>) => {
        if (response) {
          if (isNullOrUndefined(response.items) || response.items.length < 1) {
            Reflect.set(this, property, undefined);
            observer.next(undefined);
          } else {
            Reflect.set(this, property, response.items);
            observer.next(response.items);
          }
        } else {
          observer.next(undefined);
        }

        observer.complete();
      });
    });
  }

  /***** END - PRIVATE FUNCTIONS *****/

  /***** START - PUBLIC METHODS *****/

  /**
   * Factory method for loading contacts API data based on operation passed in by the caller.
   *
   * @param operation - enum operation to perform, one of the four endpoints we currently call
   * @return reference observable to be executed upon subscription
   */
  contactLensServiceFactory(operation: string): Observable<ContactLensService[]> {

    switch (operation) {
      case ContactLensOperation.MaterialType:
        return this.loadContactLensService<MaterialType>(
          'materialType',
          operation,
          ApplicationConstants.api.contactLensEndpoint.materials
        );
      case ContactLensOperation.Service:
        return this.loadClaimsOnlineReferenceService<Service>(
          'service',
          operation,
          ApplicationConstants.api.claimsOnlineReferenceEndpoint.services
        );
      case ContactLensOperation.Reason:
        return this.loadClaimsOnlineReferenceService<Reason>(
          'reason',
          operation,
          ApplicationConstants.api.claimsOnlineReferenceEndpoint.reasons
        );
      case ContactLensOperation.Manufacturer:
        return this.loadContactLensManufacturer<Manufacturer>(
          'manufacturer',
          operation,
          ApplicationConstants.api.contactLensEndpoint.manufacturers
        );
      case ContactLensOperation.Modality:
        return this.loadContactLensModality<Modality>(
          'modality',
          operation,
          ApplicationConstants.api.contactLensEndpoint.modality
        );
    }
  }

  /***** END - PUBLIC METHODS *****/
}
