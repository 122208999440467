<!-- Page Contents -->
<div class="eclaim-page-contents">

  <div class="container-white-full-column">
    <div class="sign-in-container">

      <div fxLayout="row" fxLayoutAlign="center center">
        <h3 class="margin-bottom-20">Cannot Continue for the Following Reason</h3>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center">
        <p>Your logon has expired.</p>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center">
        <p >Please go to the Logon Screen and try again.</p>
      </div>

      <!-- Sign In Button -->
      <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-flat-button class="full-width search-button mat-flat-button mat-primary" (click)="navigateToLogin()">
          <ng-container>Go back to Logon Page</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
<app-public-error-container *ngIf="showSnackbar"></app-public-error-container>
