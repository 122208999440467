import {Component, ElementRef, OnInit, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {of, Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'vsp-icon-snackbar',
  templateUrl: './vsp.icon-snackbar.component.html',
  styleUrls: ['./vsp.icon-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VspIconSnackbarComponent implements OnInit, OnDestroy {

  private _errorMode = false;
  private _warningMode = false;
  private opened = false;
  private defaultHeight = '5rem';
  private confirmationCloseSubscription: Subscription;

  constructor(
    private iconRegistry: MatIconRegistry,
    private rootElement: ElementRef,
  ) {
    this.iconRegistry.getNamedSvgIcon('closeAlternate', 'vsp-icons')
      .subscribe(() => {}, () => {
        throw Error(`The closeAlternate icon isn't available in the vsp-icons namespace for the MatIconRegistry.`);
    });
  }

  /***** START - PUBLIC MEMBERS *****/
  message: string;
  @ViewChild('messageContainer', {read: ElementRef, static: true}) messageContainer: ElementRef;
  @ViewChild('iconSnackbar', {read: ElementRef, static: true}) snackbarElement: ElementRef;
  /***** END - PUBLIC MEMBERS *****/


  /***** START - PRIVATE FUNCTIONS *****/
  private close(): void {
    this.opened = false;
    this.snackbarElement.nativeElement.classList.remove('transition');
  }

  private open(): void {
    this.opened = true;
    this.snackbarElement.nativeElement.style.width = 'auto';
    this.snackbarElement.nativeElement.style.height = 'auto';
    // Allow an event cycle for the transition to/from values and width to be applied
    of({}).pipe(
      delay(0)
    ).subscribe(() => {
      this.snackbarElement.nativeElement.classList.add('transition');
      // NOTE: Additional 2 pixels to keep text-overflow from being triggered at the precise width of the message container
      this.snackbarElement.nativeElement.style.width = this.messageContainer.nativeElement.offsetWidth + 2 + 'px';
      this.snackbarElement.nativeElement.style.height = this.messageContainer.nativeElement.offsetHeight + 'px';
    });
  }

  private checkForCloseSubscription(): void {
    if (this.confirmationCloseSubscription) {
      this.confirmationCloseSubscription.unsubscribe();
      this.confirmationCloseSubscription = undefined;
    }
  }

  private clearModes(): void {
    this.errorMode = false;
    this.warningMode = false;
  }
  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  get errorMode(): boolean {
    return this._errorMode;
  }
  set errorMode(newErrorMode: boolean) {
    this._errorMode = newErrorMode;
    if (this._errorMode) {
      this.rootElement.nativeElement.classList.add('error');
      this.snackbarElement.nativeElement.classList.add('error');
    } else {
      this.rootElement.nativeElement.classList.remove('error');
      this.snackbarElement.nativeElement.classList.remove('error');
    }
  }

  get warningMode(): boolean {
    return this._warningMode;
  }
  set warningMode(newWarningMode: boolean) {
    this._warningMode = newWarningMode;
    if (this._warningMode) {
      this.rootElement.nativeElement.classList.add('warning');
      this.snackbarElement.nativeElement.classList.add('warning');
    } else {
      this.rootElement.nativeElement.classList.remove('warning');
      this.snackbarElement.nativeElement.classList.remove('warning');
    }
  }

  /**
   * Display the icon snackbar as an error message at the top on the screen. Persist the error message
   * until the user closes it.
   * @param message - The error message to display inside of the icon snackbar
   */
  showWarningSnackbar(message = 'Notification'): void {
    this.message = message;
    this.clearModes();
    this.warningMode = true;
    this.checkForCloseSubscription();
    this.open();
  }

  /**
   * Display the icon snackbar as an error message at the top on the screen. Persist the error message
   * until the user closes it.
   * @param message - The error message to display inside of the icon snackbar
   */
  showErrorSnackbar(message = 'Notification'): void {
    this.message = message;
    this.clearModes();
    this.errorMode = true;
    this.checkForCloseSubscription();
    this.open();
  }

  /**
   * Display the icon snackbar as a confirmation message at the bottom on the screen
   * @param message - The confirmation message to display inside of the icon snackbar
   */
  showConfirmationSnackbar(message = 'Notification'): void {
    this.message = message;
    this.clearModes();
    this.checkForCloseSubscription();
    this.open();
    // Auto close the snackbar after a duration
    this.confirmationCloseSubscription = of({}).pipe(
      delay(3000)
    ).subscribe(() => {
      this.confirmationCloseSubscription = undefined;
      this.close();
    });
  }
  /***** END - PUBLIC FUNCTIONS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit(): void {
    this.snackbarElement.nativeElement.addEventListener('transitionend', this.closeTransitionEventListener.bind(this));
  }

  onIconClick(): void {
    this.close();
  }

  closeTransitionEventListener(): void {
    if (!this.opened) {
      this.snackbarElement.nativeElement.style.height = this.defaultHeight;
      this.snackbarElement.nativeElement.style.width = 'auto';
      this.message = '';
      this.clearModes();
    }
  }

  ngOnDestroy(): void {
    this.snackbarElement.nativeElement.removeEventListener('transitionend', this.closeTransitionEventListener.bind(this));
  }
  /***** START - EVENT HANDLERS *****/

}
