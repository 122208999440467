import {Component, ViewEncapsulation} from '@angular/core';
import {environment} from '../../environments/environment';

// @ts-ignore
const data = require('../../../package.json');

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {

  constructor() {}

  /***** START - PRIVATE MEMBERS *****/
  private config = environment;
  /***** END - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/
  showBuildNumbers: boolean = this.config.showBuildNumbers;
  buildNumber: string = data.version;
  /***** END - PUBLIC MEMBERS *****/

}
