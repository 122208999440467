import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MessageService} from '../../common/services/support/message/message.service';
import {ApplicationConstants} from '../../common/constants/application.constants';
import {of, Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
import {
  IconsSnackbarMode,
  PublicErrorContainerComponent
} from '../public-error-container/public-error-container.component';
import {CookieService} from 'ngx-cookie-service';
import {isStringNullUndefinedOrEmpty} from '../../common/utility';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-expired-session',
  templateUrl: './expired-session.component.html',
  styleUrls: ['./expired-session.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExpiredSessionComponent implements OnInit, OnDestroy {


  constructor(
    private router: Router,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private messageService: MessageService,
  ) { }

  /***** START - PRIVATE MEMBERS *****/
  private observableSubscriptions: Subscription[] = [];
  /***** END - PRIVATE MEMBERS *****/


  /***** START - PUBLIC MEMBERS *****/
  errorContainer: PublicErrorContainerComponent;
  @ViewChild(PublicErrorContainerComponent, {static: true}) set content(content: PublicErrorContainerComponent) {
    this.errorContainer = content;
  }
  showSnackbar: boolean = false;
  /***** END - PUBLIC MEMBERS *****/

  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.observableSubscriptions.push(this.messageService.onShowWarningSnackbar.subscribe((warningMessage: string) => {
      if (warningMessage !== undefined) {
        // TODO:  Ugly hack to get around ExpressionChanged... error.  Binding inside of init event, and also
        // need the delay to get a handle on the errorContainer
        setTimeout(() => {
          this.showSnackbar = true;
          of({}).pipe(
            delay(ApplicationConstants.userInteractionDebounceTime)
          ).subscribe(() => {
            this.errorContainer.showSnackbar(IconsSnackbarMode.Warning, warningMessage);
          });
        });
      }
    }));

    this.observableSubscriptions.push(this.messageService.onShowErrorSnackbar.subscribe((errorMessage: string) => {
      if (errorMessage !== undefined) {
        // TODO:  Ugly hack to get around ExpressionChanged... error.  Binding inside of init event, and also
        // need the delay to get a handle on the errorContainer
        setTimeout(() => {
          this.showSnackbar = true;
          of({}).pipe(
            delay(ApplicationConstants.userInteractionDebounceTime)
          ).subscribe(() => {
            this.errorContainer.showSnackbar(IconsSnackbarMode.Error, errorMessage);
          });
        });
      }
    }));
  }

  ngOnDestroy() {
    this.observableSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
  /***** END - EVENT HANDLERS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  navigateToLogin() {
    if (!isStringNullUndefinedOrEmpty(environment.newTrueFarmLogin)) {
      document.location.href = environment.newTrueFarmLogin;
    } else {
      let eyefinityUrl: string = this.cookieService.get(ApplicationConstants.efSurlCookie);
      if (isStringNullUndefinedOrEmpty(eyefinityUrl)) {
        eyefinityUrl = environment.trueLoginUrl;
      }
      document.location.href = `${eyefinityUrl}/eyefinity/html/eyefinity_logon.htm`;
    }
  }
  /***** START - PUBLIC FUNCTIONS *****/
}
