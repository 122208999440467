import { Injectable } from '@angular/core';
import { ObjectService } from '../../../support/object/object.service';
import { ApiFormattedClaim, Claim } from '../../../../../models/claim';
import { UIFormattedDiagnosis } from '../../../../../models/UIFormattedDiagnosis';
import { ApiFormattedPhoneNumber } from '../../../../../models/phoneNumber';
import { Address, ApiFormattedAddress } from '../../../../../models/address';
import { ServicesConstants } from '../../../../../secure/claim-form/services/services.constants';
import { DateUtility, toDollarAmount, isNullOrUndefined } from '../../../../utility';


// TODO: Needs to implement the Claim interface
export class DefaultClaim {
  patient = {
    name: {},
    addresses: [{}],
    phones: ['']
  };
  member = {
    name: {},
    addresses: [{}],
    phones: ['']
  };
  serviceLocation = {
    physicalAddress: {},
    mailingAddress: {},
    provider: {}
  };
  doctor = {};
  cms1500Diagnoses = [
    /*{position: 'A', diagnosisCode: ''},
    {position: 'B', diagnosisCode: ''},
    {position: 'C', diagnosisCode: ''},
    {position: 'D', diagnosisCode: ''}*/
  ];
  serviceLines = [];
  doctorSignature = {};
  memberSignature = {};
  patientSignature = {};
  patientEncounterValidationMessages = []; // need this to support edge cases for soft edit acknowledgements getting to Patient Encounter
}

@Injectable({
  providedIn: 'root'
})
export class DataMarshallService {


  constructor(
    private objectService: ObjectService,
  ) { }


  /***** START - PRIVATE MEMBERS *****/
  private DIAGNOSIS_CODE_LABEL_BASE = 65; // "A" in ASCII
  // nested date properties on the patient encounter object to format
  // '2017-01-02' - > Date Object
  private simpleDatesToFormat = [
    'dateOfService',
    'serviceStartDate',
    'member.dateOfBirth',
    'patient.dateOfBirth',
    'serviceLines.serviceEndDate',
    'serviceLines.serviceStartDate',
    'doctorSignature.dateSigned',
    'memberSignature.dateSigned',
    'patientSignature.dateSigned',
    'illnessInjuryOnsetDate',
    'priorIllnessInjuryOnsetDate',
    'unableToWorkStartDate',
    'unableToWorkEndDate',
    'hospitalizationAdmitDate',
    'hospitalizationReleaseDate',
  ];
  // ISO date properties on the patient encounter object to format
  // '2017-04-01T00:00:00.000Z' - > Date Object
  private isoDatesToFormat = [
    'claimSubmissionReceiptTimestamp',
    'lastDoctorUpdatedDateTime',
    'lastUpdateDateTime',
    'vsrIssueTimeStamp'
  ];
  // nested phone number properties on the patient encounter object to format
  // {prefix: '123', number: '4567890'} - > '1234567890'
  private phoneNumbersToFormat = [
    'member.phones',
    'patient.phones'
  ];
  private addressesToFormat = [
    'member.addresses',
    'patient.addresses',
    'serviceLocation.physicalAddress',
    'serviceLocation.mailingAddress'
  ];
  /***** END - PRIVATE MEMBERS *****/

  /***** START - PRIVATE FUNCTIONS *****/
  // converts the label character ("A", "B", "C", etc.) to an index value (starting at 0 for "A")
  public getDiagnosisCodeIndexFromLabel(label: string): number {
    return label.charCodeAt(0) - this.DIAGNOSIS_CODE_LABEL_BASE;
  }

  // converts the index of the diagnosis code to the ASCII character representation
  // i.e. the first item will be "A", the second item will be "B", etc.
  public getDiagnosisCodeLabelFromIndex(index: number): string {
    return index < ServicesConstants.MAX_DIAGNOSIS_CODES ? String.fromCharCode(this.DIAGNOSIS_CODE_LABEL_BASE + index) : null;
  }

  private addDiagnosisCodeByIndex(diagnosisCodes: UIFormattedDiagnosis[], index: number): void {
    // get the label value from the index
    const label = this.getDiagnosisCodeLabelFromIndex(index);
    // add an empty diagnosis code with the label
    diagnosisCodes.push({ position: label, diagnosisCode: '' });
  }

  // formats diagnosis codes to add any missing fields between saved codes (for example, if the user saved code "A","B", and "F",
  // we need to add empty fields for "C", "D", and "E"
  public formatDiagnosisCodes(uiFormattedClaim: Claim): void {
    // build an array of diagnosis indices (starting at 0 for "A") for the current diagnosis codes
    const diagnosisLabelIndices = uiFormattedClaim.cms1500Diagnoses.map((diagnosis: UIFormattedDiagnosis) => {
      return this.getDiagnosisCodeIndexFromLabel(diagnosis.position);
    });
    // sort the indices numerically
    diagnosisLabelIndices.sort(function (a, b) {
      return a - b;
    });
    // get the highest index
    const highestIndex = diagnosisLabelIndices.length > 0 ? diagnosisLabelIndices[diagnosisLabelIndices.length - 1] : 0;
    const lastIndex = highestIndex < ServicesConstants.DEFAULT_DIAGNOSIS_CODES ? ServicesConstants.DEFAULT_DIAGNOSIS_CODES : ServicesConstants.MAX_DIAGNOSIS_CODES;
    // build a list of missing indices - start with the code right before the highest index and loop backwards
    // until we reach 0
    const missingCodeIndices = [];
    for (let i = lastIndex - 1; i >= 0; i--) {
      // if we don't have a code for this index, add the index to the list of missing indices
      if (diagnosisLabelIndices.indexOf(i) === -1) {
        missingCodeIndices.push(i);
      }
    }
    // loop through each missing index, adding a diagnosis code for each one
    missingCodeIndices.forEach((missingCodeIndex) => {
      this.addDiagnosisCodeByIndex(uiFormattedClaim.cms1500Diagnoses, missingCodeIndex);
    });
    // now we need to re-sort the list of diagnosis codes alphabetically by label ("A", "B", "C", etc.)
    uiFormattedClaim.cms1500Diagnoses.sort(function (a, b) {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    });
  }

  private getParentObjects(claim: Claim | ApiFormattedClaim, propertyPieces: string[]): any {
    // get the parent property - one level up from the final property
    // i.e. if the property name was 'member.property1.property2', propertyPieces would equal ['member', 'property1', 'property2']
    // we want one level up - member.property1, so remove the last piece (property2) from the array and then join the array by period to get 'member.property1'
    const parentProperty = propertyPieces.slice(0, propertyPieces.length - 1).join('.');
    // if the parent property is blank, the patient encounter itself is the parent object. otherwise, find the nested parent object(s)
    return parentProperty.length === 0 ? [claim] : this.objectService.getNestedProperties(claim, parentProperty);
  }

  // loops through each object in the parentObjects list and formats the indicated property
  // parentObjects: array of objects to set the new values on
  // propertyName: the name of the property to format and set on each parent object
  // formatFunction: the function to invoke to get the formatted value for each property
  private formatPropertyValuesOnParentObjects(parentObjects: any[], propertyName: string, formatFunction: any): void {
    if(parentObjects) {
      let parentObject, propertyValue;
      // loop through each parent object
      for (let i = 0, l = parentObjects.length; i < l; i++) {
        // get the parent object and the property value on the parent object by property name
        parentObject = parentObjects[i];
        propertyValue = parentObject[propertyName];
        // if propertyValue isn't falsey, do the formatting
        if (propertyValue) {
          // if the property value is an array, we need to format each item in the array
          if (propertyValue instanceof Array) {
            // loop through each property in the array and format it
            for (let p = 0, pl = propertyValue.length; p < pl; p++) {
              const childPropertyValue = propertyValue[p];
              if (childPropertyValue !== undefined) {
                propertyValue[p] = formatFunction(childPropertyValue);
              }
            }
          } else {
            // property value is not an array, set the property to the formatted value
            parentObject[propertyName] = formatFunction(propertyValue);
          }
        }
      }
    }
  }

  // formats all property values on the patient encounter in the list of properties to format
  // uiFormattedClaim: the full patient encounter object
  // propertiesToFormat: an array of strings indicating each property to format
  // formatFunction: the function to invoke to get the formatted value for each property
  private formatAllPropertyValues(claim: Claim | ApiFormattedClaim, propertiesToFormat: string[], formatFunction: any): void {
    let propertyName;
    // loop through each property in the list of properties to format
    for (let i = 0, l = propertiesToFormat.length; i < l; i++) {
      // get the property name
      propertyName = propertiesToFormat[i];
      // split the property name into an array by period
      const propertyPieces = propertyName.split('.');
      // get the parent objects (one level up from the full property name) to set the new values on
      const parentObjects = this.getParentObjects(claim, propertyPieces);
      // make sure our parent objects were found
      // TODO - commenting out this check for now. it is impossible to trigger because of the merge
      // with the default claim structure. May need this later though, but need to remove for unit testing coverage
      //if (parentObjects){
      // get the last property piece - this is the property to set on each parent object
      const propertyToSet = propertyPieces[propertyPieces.length - 1];
      // format the property values
      this.formatPropertyValuesOnParentObjects(parentObjects, propertyToSet, formatFunction);
      //}
    }
  }

  // formats COB amounts to '0.00' when it's a COB claim and the amount is null/undefined.
  private formatCobAmounts(uiFormattedClaim: Claim): void {
    if (uiFormattedClaim.otherInsuranceIndicator && uiFormattedClaim.serviceLines) {
      uiFormattedClaim.serviceLines.forEach(serviceline => {
        serviceline.otherInsuranceAllowedAmount = toDollarAmount(isNullOrUndefined(serviceline.otherInsuranceAllowedAmount) ? '0' : serviceline.otherInsuranceAllowedAmount);
        serviceline.otherInsurancePaidAmount = toDollarAmount(isNullOrUndefined(serviceline.otherInsurancePaidAmount) ? '0' : serviceline.otherInsurancePaidAmount);
        serviceline.otherInsurancePatientPaidAmount = toDollarAmount(isNullOrUndefined(serviceline.otherInsurancePatientPaidAmount) ? '0' : serviceline.otherInsurancePatientPaidAmount);
      });
    }
  }

  // formats date values to conform to the UI
  private formatDatesForUi(uiFormattedClaim: Claim): void {
    // format all date values
    this.formatAllPropertyValues(uiFormattedClaim, this.simpleDatesToFormat.concat(this.isoDatesToFormat), (dateString) => {
      return DateUtility.buildDateFromDateString(dateString);
    });
  }

  // formats date values to conform to the API
  private formatDatesForApi(apiFormattedClaim: ApiFormattedClaim): void {
    // format all the ISO date values
    this.formatAllPropertyValues(apiFormattedClaim, this.isoDatesToFormat, (date) => {
      return DateUtility.buildServerDateFromJsDate(date);
    });

    // format all the simple date values
    this.formatAllPropertyValues(apiFormattedClaim, this.simpleDatesToFormat, (date) => {
      return DateUtility.buildYyyyMmDdDateFromDate(date);
    });
  }

  // converts an object in format: {prefix: '123', number: '4567890'} to a string with the values combined ('1234567890')
  // returns an empty string if phoneNumberObject is not defined or not in the correct format/has empty values
  private convertPhoneNumberObjectToString(phoneNumberObject: ApiFormattedPhoneNumber): string {
    // initialize the value to an empty string
    let phoneNumberString = '';
    // make sure the phone number object is defined
    if (phoneNumberObject) {
      // if the prefix exists add it to the string
      if (phoneNumberObject.prefix) {
        phoneNumberString += phoneNumberObject.prefix;
      }
      // if the number exists add it to the string
      if (phoneNumberObject.number) {
        phoneNumberString += phoneNumberObject.number;
      }
    }
    return phoneNumberString;
  }

  // converts a string in format '1234567890' to an object with format: {prefix: '123', number: '4567890'}
  private convertPhoneNumberStringToObject(phoneNumberString: string): ApiFormattedPhoneNumber {
    // set phone number values to empty strings if not a valid phone number
    if (!phoneNumberString) {
      return {
        prefix: '',
        number: '',
      };
    } else {
      // remove non numeric characters
      phoneNumberString = phoneNumberString.replace(/\D/g, '');
      // set prefix to first 3 digits and number to the rest of the string
      return {
        prefix: phoneNumberString.substr(0, 3),
        number: phoneNumberString.substr(3),
      };
    }
  }

  // formats phone number values to conform to the UI
  private formatPhoneNumbersForUi(uiFormattedClaim: Claim): void {
    this.formatAllPropertyValues(uiFormattedClaim, this.phoneNumbersToFormat, (phoneNumber: ApiFormattedPhoneNumber) => {
      return this.convertPhoneNumberObjectToString(phoneNumber);
    });
  }

  // formats phone number values to conform to the API
  private formatPhoneNumbersForApi(apiFormattedClaim: ApiFormattedClaim): void {
    this.formatAllPropertyValues(apiFormattedClaim, this.phoneNumbersToFormat, (phoneNumberString: string) => {
      return this.convertPhoneNumberStringToObject(phoneNumberString);
    });
  }

  private formatAddressesForUi(uiFormattedClaim: Claim): void {
    this.formatAllPropertyValues(uiFormattedClaim, this.addressesToFormat, (address: ApiFormattedAddress): Address => {
      return {
        city: address.city,
        stateCode: address.stateCode,
        street1: address.street1,
        street2: address.street2,
        zipCode: {
          zipCode: address.zipCode,
          zipExtension: address.zipExtension,
        }
      };
    });
  }

  private formatAddressesForApi(apiFormattedClaim): void {
    this.formatAllPropertyValues(apiFormattedClaim, this.addressesToFormat, (address: Address): ApiFormattedAddress => {
      const zipCodeObject = Object.deepClone(address.zipCode) || {};
      return {
        city: address.city,
        stateCode: address.stateCode,
        street1: address.street1,
        street2: address.street2,
        zipCode: zipCodeObject.zipCode,
        zipExtension: zipCodeObject.zipExtension,
      };
    });
  }

  private removeContactLensAnnualSupplyCountForApi(apiFormattedClaim): void {
    if ( !isNullOrUndefined(apiFormattedClaim.contactLens)) {
      apiFormattedClaim.contactLens.annualSupplyCount = undefined;
    }
  }

  // recursively traverses the object and trims any string values
  private trimAllStrings(object: any): void {
    // loop through each property on the object
    for (const property in object) {
      if (object.hasOwnProperty(property)) {
        // get the value of the current property
        const propertyValue = object[property];
        // if the value is a string, trim it
        if (typeof propertyValue === 'string') {
          object[property] = propertyValue.trim();
        } else if (typeof propertyValue === 'object' || propertyValue instanceof Array) {
          this.trimAllStrings(propertyValue);
        }
      }
    }
  }
  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  formatClaimForUi(apiFormattedClaim: ApiFormattedClaim): Claim {
    if (!apiFormattedClaim) {
      return;
    }
    // merge the patient encounter into the default claim structure. this adds any nested objects that are missing
    const uiFormattedClaim = Object.assign(new DefaultClaim(), Object.deepClone(apiFormattedClaim));
    this.formatDiagnosisCodes(uiFormattedClaim);
    this.formatDatesForUi(uiFormattedClaim);
    this.formatPhoneNumbersForUi(uiFormattedClaim);
    this.formatAddressesForUi(uiFormattedClaim);
    this.formatCobAmounts(uiFormattedClaim);
    // trim all strings in the Service Lines - some of them are padded to a certain length, causing validation
    // errors for inputs that don't allow spaces
    this.trimAllStrings(uiFormattedClaim.serviceLines);
    uiFormattedClaim.serviceVerificationValue = apiFormattedClaim.priorAuthorizationNumber;
    return uiFormattedClaim;
  }

  formatClaimForApi(uiFormattedClaim: Claim): ApiFormattedClaim {
    if (!uiFormattedClaim) {
      return;
    }
    const apiFormattedClaim = Object.deepClone(uiFormattedClaim);
    this.formatDatesForApi(apiFormattedClaim);
    this.formatPhoneNumbersForApi(apiFormattedClaim);
    this.formatAddressesForApi(apiFormattedClaim);
    this.removeContactLensAnnualSupplyCountForApi(apiFormattedClaim);
    apiFormattedClaim.priorAuthorizationNumber = uiFormattedClaim.serviceVerificationValue;
    if(apiFormattedClaim.serviceLines) {
      apiFormattedClaim.serviceLines.forEach(sl => {
        sl.cptHcpcsCode = sl.cptHcpcsCode ? sl.cptHcpcsCode.toUpperCase() : sl.cptHcpcsCode;
        if (sl.modifierCodes && sl.modifierCodes.length) {
          sl.modifierCodes.forEach((val, idx) => {
            sl.modifierCodes[idx] = val.toUpperCase();
          });
        }
      });
    }
    return apiFormattedClaim;
  }
  /***** END - PUBLIC FUNCTIONS *****/

}
