
export class ParticipatingLabsRequest {
  private manufacturer: string;
  private externalLabIds: string [];

  get getManufacturer(): string {
    return this.manufacturer;
  }

  set setManufacturer(value: string) {
    this.manufacturer = value;
  }

  get getExternalLabIds(): string[] {
    return this.externalLabIds;
  }

  set setExternalLabIds(value: string[]) {
    this.externalLabIds = value;
  }
}
