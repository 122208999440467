<div fxLayout="column">
  <mat-card>
    <!-- Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 class="nested-column-11" id="cob-claim-header" mat-dialog-title
            *ngFor="let header of data.header">{{ header }}</h1>
      </mat-card-title>
      <!-- Close Icon -->
      <button [disableRipple]="true" class="close-button" mat-icon-button>
        <mat-icon (click)="onClose()" id="cob-claim-close-button"
                  svgIcon="eclaim-icons:close"></mat-icon>
      </button>
    </mat-card-header>

    <!-- Body -->
    <mat-card-content class="simple-modal">
      <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let message of data.messages">&bull; {{ message }}</mat-list-item>
      </mat-list>
    </mat-card-content>

    <!-- Footer Line -->
    <hr/>

    <mat-card-actions>
      <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="end center" class="margin-top-20 margin-bottom-20">

        <!-- Cancel Button  -->
        <button mat-dialog-close class="cancel-button" (click)="onClose()"
                id="cob-claim-modal-cancel-button">Cancel
        </button>
        <div fxFlexOffset="30px"></div>

        <!-- OK Button-->
        <button mat-dialog-close class="btn btn-primary btn-extra-pad" (click)="confirm()"
                id="cob-claim-modal-ok-button">OK
        </button>
        <div fxFlexOffset="20px"></div>

      </div>
    </mat-card-actions>

  </mat-card>
</div>
