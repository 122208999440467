export const PLACE_OF_SERVICE_CODE = '11';
export const MINIMUM_SERVICE_LINE_COUNT = 6;

export const ServicesConstants = {
  SERVICELINE_EXCEEDED_ERROR_MESSAGE: 'Services billed exceed the number of claim lines on the claim form.' +
    'Submitting this claim electronically will result in incomplete payment.' +
    'Please call the Provider Services Support Line at 800.615.1883 for assistance.',

  MAX_SERVICE_LINES: 15,
  DEFAULT_SERVICE_LINES: 3,
  MAX_DIAGNOSIS_CODES: 12,
  DEFAULT_DIAGNOSIS_CODES: 8,
  MAX_DIAGNOSIS_POINTER_INPUT_LENGTH: 1,

  DIAGNOSIS_CODES: 'diagnosisCodes',
  DIAGNOSIS_CODE: 'diagnosisCode',
  DIAGNOSIS_POSITION: 'position',
  SERVICE_LINES: 'serviceLines',
  SERVICE_START_DATE: 'serviceStartDate',
  SERVICE_END_DATE: 'serviceEndDate',
  EMERGENCY_INDICATOR: 'emergencyIndicator',
  EPSDT_INDICATOR: 'epsdtIndicator',
  NONE_INDICATOR: 'patientConditionNone',
  PREDIABETES_INDICATOR: 'prediabetesIndicator',
  DIABETES_INDICATOR: 'diabetesIndicator',
  DIABETIC_RETINOPATHY_INDICATOR: 'diabeticRetinopathyIndicator',
  HYPERTENSION_INDICAOTR: 'hypertensionIndicator',
  HIGH_CHOLESTROL_INDICATOR: 'highCholesterolIndicator',
  GLAUCOMA_INDICATOR: 'glaucomaIndicator',
  AGE_RELATED_MACULAR_DEGENERATION_INDICATOR: 'ageRelatedMacularDegenerationIndicator',
  CPT_HCPCS_CODE: 'cptHcpcsCode',
  MODIFIER_CODES: 'modifierCodes',
  DIAGNOSIS_POINTERS: 'diagnosisPointers',
  BILLED_AMOUNT: 'billedAmount',
  UNIT_COUNT: 'unitCount',
  OTHER_INSURANCE_ALLOWED_AMOUNT: 'otherInsuranceAllowedAmount',
  OTHER_INSURANCE_PAID_AMOUNT: 'otherInsurancePaidAmount',
  OTHER_INSURANCE_PATIENT_PAID_AMOUNT: 'otherInsurancePatientPaidAmount',
  OTHER_INSURANCE_DENIED_NOT_COVERED_REASON: 'otherInsuranceDeniedNotCoveredReason',
  FSA_PAID: 'fsaPaid',
  TOTAL_AMOUNT: 'totalAmount',
  PATIENT_PAYMENT_AMOUNT: 'patientPaymentAmount',
  TOTAL_DUE: 'totalDue',

  EDITABLE_SERVICELINE_FIELDS: [
    'emergencyIndicator',
    'cptHcpcsCode',
    'modifierCodes',
    'diagnosisPointers',
    'billedAmount',
    'unitCount',
    'epsdtIndicator'
  ],

  cobHelpMessages: {
    otherInsuredAllowedAmountHeader: ['Other Insured Allowed Amount'],
    otherInsuranceAllowedAmount: [
      'The maximum allowed for a service by the Other Insurance as indicated on primary explanation of payment (EOP).'
    ],
    otherInsurancePaidAmountHeader: ['Other Insured Paid Amount'],
    otherInsurancePaidAmount: [
      'The amount paid for a service by the Other Insurance as indicated on primary explanation of payment (EOP).'
    ],
    otherInsurancePatientPaidAmountHeader: ['Other Insured Patient Paid Amount'],
    otherInsurancePatientPaidAmount: [
      'The remaining balance the patient is responsible to pay for a service by the Other Insurance as indicated on primary explanation of payment (EOP).'
    ],
    otherInsuranceDeniedNotCoveredReasonHeader: ['Reason primary explanation of payment (EOP) indicates claim denied or paid at $0.00.'],
    otherInsuranceDeniedNotCoveredReason: [
      'Not Covered - Primary explanation of payment (EOP) indicates claim denied due to patient not covered on date of service or services billed not covered by primary insurance.',
      'Deductible - Primary explanation of payment (EOP) indicates service applied to deductible and paid $0.',
      'Max Allowance Met - Primary explanation of payment (EOP) indicates maximum allowance met and paid $0.',
      'Bundled Service - Primary explanation of payment (EOP) indicates the payment for this service is included in the reimbursement of another service/procedure billed.',
      'Timely Filing - Primary explanation of payment (EOP) indicates claim denied for timely filing.',
      'Capitation - Primary explanation of payment (EOP) indicates claim denied due to capitation.'
    ]
  },

  HcpcCodeGroups:
    {
      'WellVision Exam Services': [
        [
          {code: '92002', value: 'New patient - intermediate'},
          {code: '92004', value: 'New patient - comprehensive'},
          {code: '92012', value: 'Established patient - intermediate'},
          {code: '92014', value: 'Established patient - comprehensive'},
          {code: '92015', value: 'Determination of refractive state'},
          {code: 'S0620', value: 'Routine ophthalmological examination including refraction; new patient'},
          {code: 'S0621', value: 'Routine ophthalmological examination including refraction; existing patient'}
        ]
      ],
      'Eye Exam for Patients with Diabetes Reporting': [
        [
          {code: '2022F', value: 'Dilated retinal eye exam with evidence of retinopathy'},
          {code: '2023F', value: 'Dilated retinal eye exam without evidence of retinopathy'},
          {code: '2024F', value: '7 standard field stereoscopic retinal photos with evidence of retinopathy'},
          {code: '2025F', value: '7 standard field stereoscopic retinal photos without evidence of retinopathy'},
          {code: '2026F', value: 'Eye imaging validated to match diagnosis from 7 standard field stereoscopic retinal photos with evidence of retinopathy'},
          {code: '2033F', value: 'Eye imaging validated to match diagnosis from 7 standard field stereoscopic retinal photos without evidence of retinopathy'},
          {code: '3072F', value: 'Low risk for retinopathy (no evidence of retinopathy in the prior year)'}
        ]
      ],
      'Evaluation and Management': [
        [
          {code: '99202', value: 'New patient - 15-29 mins; MDM straightforward'},
          {code: '99203', value: 'New patient - 30-44 mins; MDM low '},
          {code: '99204', value: 'New patient - 45-59 mins; MDM moderate'},
          {code: '99205', value: 'New patient - 60-74 mins; MDM high'},
          {code: '99211', value: 'New patient - may not require the presence of a physician, presenting problem(s) are minimal'},
          {code: '99212', value: 'Established patient - 10-19 mins; MDM straightforward'},
          {code: '99213', value: 'Established patient - 20-29 mins; MDM low'},
          {code: '99214', value: 'Established patient - 30-39 mins; MDM moderate'},
          {code: '99215', value: 'Established patient - 40-54 mins; MDM high'}
        ]
      ],
      'Contact Lens Services': [
        [
          {code: '92072', value: 'Fitting of contact lens for management of keratoconus, initial fitting'},
          {code: '92310', value: 'Prescription of optical and physical characteristics of and fitting of contacts'},
          {code: '92311', value: 'Corneal lens for aphakia one eye'},
          {code: '92312', value: 'Corneal lens for aphakia, both eyes'},
          {code: '92313', value: 'Corneoscleral, new fit or refit'},
          {code: '92326', value: 'Replacement of contact lens'}
        ]
      ],
      'Fitting of Spectacles': [
        [
          {code: '92340', value: 'Fitting of spectacle, except for aphakia; single vision'},
          {code: '92341', value: 'Fitting of spectacle, bifocal'},
          {code: '92342', value: 'Fitting of spectacle, multifocal other than bifocal'},
          {code: '92352', value: 'Fitting of spectacles, prosthesis for aphakia, monofocal per pair'},
          {code: '92353', value: 'Fitting of spectacles, prosthesis for aphakia, multifocal'}
        ]
      ],
      'Frames': [
        [
          {code: 'V2020', value: 'Frames, purchases'},
          {code: 'V2025', value: 'Deluxe Frames'}
        ]
      ],
      'Single Vision Lens': [
        [
          {code: 'V2100', value: 'Sphere, single vision, plano to + or - 4.00, per lens'},
          {code: 'V2101', value: 'Sphere, single vision, + or - 4.12 to + or - 7.00d, per lens'},
          {code: 'V2102', value: 'Sphere, single vision, + or - 7.12 to + or - 20.00d, per lens'},
          {
            code: 'V2103',
            value: 'Spherocylinder, single vision, plano to + or - 4.00d sphere, .12 to 2.00d cylinder, per lens'
          },
          {
            code: 'V2104',
            value: 'Spherocylinder, single vision, plano to + or - 4.00d sphere, 2.12 to 4.00d cylinder, per lens'
          },
          {
            code: 'V2105',
            value: 'Spherocylinder, single vision, plano to + or - 4.00d sphere, 4.25 to 6.00d cylinder, per lens'
          },
          {
            code: 'V2106',
            value: 'Spherocylinder, single vision, plano to + or - 4.00d sphere, over 6.00d cylinder, per lens'
          },
          {
            code: 'V2107',
            value: 'Spherocylinder, single vision, + or - 4.25 to + or - 7.00d sphere, .12 to 2.00d cylinder, per lens'
          },
          {
            code: 'V2108',
            value: 'Spherocylinder, single vision, + or - 4.25 to + or - 7.00d sphere, 2.12 to 4.00d cylinder, per lens'
          },
          {
            code: 'V2109',
            value: 'Spherocylinder, single vision, + or - 4.25 to + or - 7.00d sphere, 4.25 to 6.00d cylinder, per lens'
          },
          {
            code: 'V2110',
            value: 'Spherocylinder, single vision, + or - 4.25 to + or - 7.00d sphere, over 6.00d cylinder, per lens'
          },
          {
            code: 'V2111',
            value: 'Spherocylinder, single vision, + or - 7.25 to + or - 12.00d sphere, .25 to 2.25d cylinder, per lens'
          },
          {
            code: 'V2112',
            value: 'Spherocylinder, single vision, + or - 7.25 to + or - 12.00d sphere, 2.25 to 4.00d cylinder, per lens'
          },
          {
            code: 'V2113',
            value: 'Spherocylinder, single vision, + or - 7.25 to + or - 12.00d sphere, 4.25 to 6.00d cylinder, per lens'
          },
          {code: 'V2114', value: 'Spherocylinder, single vision, sphere over + or - 12.00d, per lens'},
          {code: 'V2115', value: 'Lenticular, (myodisc), per lens, single vision'},
          {code: 'V2118', value: 'Aniseikonic lens, single vision'},
          {code: 'V2121', value: 'Lenticular lens, per lens, single'},
          {code: 'V2199', value: 'Not otherwise classified, single vision lens'}
        ]
      ],
      'Bifocal Lens': [
        [
          {code: 'V2200', value: 'Sphere, bifocal, plano to + or - 4.00d, per lens'},
          {code: 'V2201', value: 'Sphere, bifocal, + or - 4.12 to + or - 7.00, per lens'},
          {code: 'V2202', value: 'Sphere, bifocal, + or - 7.12 to + or - 20.00d, per lens'},
          {code: 'V2203', value: 'Spherocylinder, bifocal, plano to + or - 4.00d sphere, .12 to 2.00d cylinder, per lens'},
          {
            code: 'V2204',
            value: 'Spherocylinder, bifocal, plano to + or - 4.00d sphere, 2.12 to 4.00d cylinder, per lens'
          },
          {
            code: 'V2205',
            value: 'Spherocylinder, bifocal, plano to + or - 4.00d sphere, 4.25 to 6.00d cylinder, per lens'
          },
          {code: 'V2206', value: 'Spherocylinder, bifocal, plano to + or - 4.00d sphere, over 6.00d cylinder, per lens'},
          {
            code: 'V2207',
            value: 'Spherocylinder, bifocal, + or - 4.25 to + or - 7.00d sphere, .12 to 2.00d cylinder, per lens'
          },
          {
            code: 'V2208',
            value: 'Spherocylinder, bifocal, + or - 4.25 to + or - 7.00d sphere, 2.12 to 4.00d cylinder, per lens'
          },
          {
            code: 'V2209',
            value: 'Spherocylinder, bifocal, + or - 4.25 to + or - 7.00d sphere, 4.25 to 6.00d cylinder, per lens'
          },
          {
            code: 'V2210',
            value: 'Spherocylinder, bifocal, + or - 4.25 to + or - 7.00d sphere, over 6.00d cylinder, per lens'
          },
          {
            code: 'V2211',
            value: 'Spherocylinder, bifocal, + or - 7.25 to + or - 12.00d sphere, .25 to 2.25d cylinder, per lens'
          },
          {
            code: 'V2212',
            value: 'Spherocylinder, bifocal, + or - 7.25 to + or - 12.00d sphere, 2.25 to 4.00d cylinder, per lens'
          },
          {
            code: 'V2213',
            value: 'Spherocylinder, bifocal, + or - 7.25 to + or - 12.00d sphere, 4.25 to 6.00d cylinder, per lens'
          },
          {code: 'V2214', value: 'Spherocylinder, bifocal, sphere over + or12.00, per lens'},
          {code: 'V2215', value: 'Lenticular, (myodisc), per lens, bifocal'},
          {code: 'V2218', value: 'Aniseikonic, per lens, bifocal'},
          {code: 'V2219', value: 'Bifocal seg with over 28 mm'},
          {code: 'V2220', value: 'Bifocal add over 3.25d'},
          {code: 'V2221', value: 'Lenticular lens, per lens, bifocal'},
          {code: 'V2299', value: 'Specialty bifocal (by report)'}
        ]
      ],
      'Trifocal Lens': [
        [
          {code: 'V2300', value: 'Sphere, trifocal, plano to + or - 4.00d, per lens'},
          {code: 'V2301', value: 'Sphere, trifocal, + or - 4.12 to + or - 7.00d per lens'},
          {code: 'V2302', value: 'Sphere, trifocal, + or - 7.12 to + or - 20.00d, per lens'},
          {
            code: 'V2303',
            value: 'Spherocylinder, trifocal, plano to + or - 4.00d sphere, .12 to 2.00d cylinder, per lens'
          },
          {
            code: 'V2304',
            value: 'Spherocylinder, trifocal, plano to + or - 4.00d sphere, 2.25 to 4.00d cylinder, per lens'
          },
          {
            code: 'V2305',
            value: 'Spherocylinder, trifocal, plano to + or - 4.00d sphere, 4.25 to 6.00d cylinder, per lens'
          },
          {code: 'V2306', value: 'Spherocylinder, trifocal, plano to + or - 4.00d sphere, over 6.00d cylinder, per lens'},
          {code: 'V2307', value: 'Spherocylinder, trifocal, + or - 4.25 to + or - 7.00d sphere, .12 to 2.00d cyl, per lens'},
          {code: 'V2308', value: 'Spherocylinder, trifocal, + or - 4.25 to + or - 7.00d sphere, 2.12 to 4.00d cyl, per lens'},
          {code: 'V2309', value: 'Spherocylinder, trifocal, + or - 4.25 to + or - 7.00d sphere, 4.25 to 6.00d cyl, per lens'},
          {code: 'V2310', value: 'Spherocylinder, trifocal, + or - 4.25 to + or - 7.00d sphere, over 6.00d cyl, per lens'},
          {code: 'V2311', value: 'Spherocylinder, trifocal, + or - 7.25 to + or - 12.00d sphere, .25 to 2.25d cyl, per lens'},
          {
            code: 'V2312',
            value: 'Spherocylinder, trifocal, + or - 7.25 to + or - 12.00d sphere, 2.25 to 4.00d cyl, per lens'
          },
          {
            code: 'V2313',
            value: 'Spherocylinder, trifocal, + or - 7.25 to + or - 12.00d sphere, 4.25 to 6.00d cyl, per lens'
          },
          {code: 'V2314', value: 'Spherocylinder, trifocal, sphere, over + or - 12.00d, per lens'},
          {code: 'V2315', value: 'Lenticular (myodisc), per lens, trifocal'},
          {code: 'V2318', value: 'Aniseikonic lens, trifocal'},
          {code: 'V2319', value: 'Trifocal seg width over 28 mm'},
          {code: 'V2320', value: 'Trifocal add over 3.25d'},
          {code: 'V2321', value: 'Lenticular lens, per lens, trifocal'},
          {code: 'V2399', value: 'Specialty trifocal (by report)'}
        ]
      ],
      'Variable Lens': [
        [
          {code: 'V2410', value: 'Variable asphericity lens, single vision, full field, glass or plastic, per lens'},
          {code: 'V2430', value: 'Variable asphericity lens, bifocal, full field, glass or plastic, per lens'},
          {code: 'V2499', value: 'Variable sphericity lens, other type'}
        ]
      ],
      'Lens Options': [
        [
          {code: 'V2700', value: 'Balance lens, , glass or plastic, per lens'},
          {code: 'V2702', value: 'Deluxe lens feature'},
          {code: 'V2710', value: 'Slab off prism, glass or plastic, per lens'},
          {code: 'V2715', value: 'Prism, per lens'},
          {code: 'V2718', value: 'Press-on lens, Fresnel prism, per lens'},
          {code: 'V2730', value: 'Special base curve, glass or plastic, per lens'},
          {code: 'V2744', value: 'Tint, photochromatic, per lens'},
          {
            code: 'V2745',
            value: 'Addition to lens; tint, any color, solid, gradient or equal, excludes photochromatic, any lens material, per lens'
          },
          {code: 'V2750', value: 'Anti-reflective coating, per lens'},
          {code: 'V2755', value: 'Ultra-violet lens, per lens'},
          {code: 'V2756', value: 'Eye glass case'},
          {code: 'V2760', value: 'Scratch resistant coating, per lens'},
          {code: 'V2761', value: 'Mirror coating, any type, solid, gradient or equal, any lens material, per lens'},
          {code: 'V2762', value: 'Polarization, any lens material, per lens'},
          {code: 'V2770', value: 'Occluder lens, per lens'},
          {code: 'V2780', value: 'Oversize lens, per lens'},
          {code: 'V2781', value: 'Progressive lens, each lens'},
          {code: 'V2782', value: 'Lens, index 1.54 to 1.65 plastic or 1.60 to 1.79 glass, excludes polycarbonate, per lens'},
          {code: 'V2783', value: 'Lens, index greater than or equal to 1.66 plastic or greater than or equal to 1.80 glass, excludes polycarbonate, per lens'},
          {code: 'V2784', value: 'Lens, polycarbonate or equal, any index, per lens'},
          {code: 'V2786', value: 'Specialty occupational multifocal lens, per lens'},
          {code: 'V2799', value: 'Vision item or service, miscellaneous'}
        ]
      ],
      'Contact Lens': [
        [
          {code: 'V2500', value: 'Contact lens, PMMA, spherical, per lens'},
          {code: 'V2501', value: 'Contact lens, PMMA, toric or prism ballast, per lens'},
          {code: 'V2502', value: 'Contact lens, PMMA, bifocal, per lens'},
          {code: 'V2503', value: 'Contact lens, PMMA, color vision deficiency, per lens'},
          {code: 'V2510', value: 'Contact lens, gas permeable, spherical, per lens'},
          {code: 'V2511', value: 'Contact lens, gas permeable, toric, prism ballast, per lens'},
          {code: 'V2512', value: 'Contact lens, gas permeable, bifocal, per lens'},
          {code: 'V2513', value: 'Contact lens, gas permeable, extended wear, per lens'},
          {code: 'V2520', value: 'Contact lens, hydrophilic, spherical, per lens'},
          {code: 'V2521', value: 'Contact lens, hydrophilic, toric, or prism ballast, per lens'},
          {code: 'V2522', value: 'Contact lens, hydrophilic, bifocal, per lens'},
          {code: 'V2523', value: 'Contact lens, hydrophilic, extended wear, per lens'},
          {
            code: 'V2530',
            value: 'Contact lens, scleral, gas impermeable, per lens'
          },
          {
            code: 'V2531',
            value: 'Contact lens, scleral, gas permeable, per lens'
          },
          {code: 'V2599', value: 'Contact lens, other type'}
        ]
      ],
      Telepath: [
        [
          {code: '92227', value: 'Remote imaging for detection of retinal disease'},
          {code: '92228', value: 'Remote imaging for monitoring and management of active retinal disease'}
        ]
      ]
    },
  pcpCommunicationOptions: [
    'Yes',
    'No - Reason on File'
  ],
};
