<div fxLayout="column" class="modal-parent">

  <form novalidate [formGroup]="reissueAuthorizationForm">
    <mat-card>
      <!-- ############################################################################################################################################################################## -->
      <!-- Header Section -->
      <!-- ############################################################################################################################################################################## -->
      <mat-card-header>
        <!-- Replace this Authorization -->
        <mat-card-title>
          <div class="app-reissue-authorization-warning-modal">
            <h1 class="nested-column-11 padding-right-0 padding-left-0 padding-top-0 padding-bottom-0"
                id="reissue-this-authorization" mat-dialog-title>Replace this Authorization</h1>
          </div>
        </mat-card-title>

        <!-- Close Icon -->
        <div class="app-reissue-authorization-warning-modal">
          <button [disableRipple]="true" class="close-button" mat-icon-button>
            <mat-icon (click)="onCloseClick()" id="reissue-auth-close-button"
                      svgIcon="eclaim-icons:close"></mat-icon>
          </button>
        </div>
      </mat-card-header>


      <!--  ############################################################################################################################################################################## -->
      <!--  Authorization Data Section -->
      <!--  ############################################################################################################################################################################## -->

      <div fxLayout="column">
        <!-- Patient Name -->
        <div fxLayout="row" class="nested-column-6 margin-bottom-20 margin-top-30">
          <p id="reissue-auth-patientName-label">
            <b>Patient Name: </b>{{getPatientFullName(vsrInfo.authorization)}}</p>
        </div>

        <div fxLayout="row">
          <!-- Authorization Number -->
          <div fxLayout="column" class="nested-column-6">
            <p id="reissue-auth-authorizationNumber-label">
              <b>Authorization Number: </b>{{vsrInfo.authorization.vsrNumber}}</p>
          </div>

          <!-- Benefit Type -->
          <div fxLayout="column" class="nested-column-6">
            <p id="reissue-auth-benefitType-label">
              <b>Benefit Type: </b>{{vsrInfo.authorization.businessProductPackageName}}</p>
          </div>
        </div>

        <div fxLayout="row">
          <!-- Effective Date -->
          <div fxLayout="column" class="nested-column-6">
            <p id="reissue-auth-effectiveDate-label">
              <b>Effective Date: </b>{{vsrInfo.authorization.effectiveDate}}</p>
          </div>

          <!-- Expiration Date -->
          <div fxLayout="column" class="nested-column-6">
            <p id="reissue-auth-expirationDate-label">
              <b>Expiration Date: </b>{{vsrInfo.authorization.expirationDate}}</p>
          </div>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Date Picker -->
        <!-- ############################################################################################################################################################################## -->

        <!-- To backdate the authorization, change the date of service -->
        <div fxLayout="row" class="card-body-text nested-column-12 margin-top-20">
          <p>To backdate the authorization, change the default date of service below.</p>
        </div>

        <!-- Date Of Service -->
        <div fxLayout="row" class="card-body-text nested-column-12 margin-bottom-20">
          <p id="reissue-auth-dos-label" class="label-title-font">Date Of Service (mm/dd/yyyy) </p>
          <app-date-picker class="required-mat-form-field margin-left-3" id="reissue-auth-dos-date-picker"
                           [datePickerConfiguration]="datePickerConfigurationDateOfService"></app-date-picker>
          <button mat-stroked-button color="primary" fxFlexOffset="30px" class="claim-progress-action-button"
                  [disabled]="reissueAuthorizationForm.controls.dateOfService.invalid"
                  (click)="updateEligibilityUsingAsOfDateFromDateOfService(vsrInfo)"
                  id="reissue-auth-date-update-button">
            <span>Update</span>
          </button>
        </div>
      </div>


      <!-- ############################################################################################################################################################################## -->
      <!-- Product Package Eligible Items -->
      <!-- ############################################################################################################################################################################## -->

      <div fxLayout="column" *ngIf="!loadingEligibility">
        <div fxLayout="row" *ngFor="let productPackage of selectedProductPackage; index as baseId ">
          <div class="nested-column-12"
               id="reissue-auth-product-package-panel-{{productPackage.name}}"
               *ngIf="!productPackage.hasOnlyNonAuthorizableItemsAndNoDescription">

            <!-- Authorization Text for all services available -->
            <p>To authorize all services available for a specific service(s), please select at least one of the
              following:</p>


            <!-- ############################################################################################################################################################################## -->
            <!-- First Row In The Package Eligible Items -->
            <!-- ############################################################################################################################################################################## -->
            <div fxLayout="row" *ngIf="!productPackage.hasOnlyNonAuthorizableItems"
                 class="align-label-text-in-center" id="{{baseId}}-table">
              <!-- Empty Label for First Column -->
              <p class="custom-nested-column-1_6"></p>
              <!-- All Available Services -->
              <p fxLayoutAlign="center center" id="reissue-auth-all-available-services-label"
                     class="custom-nested-column-1_8 eclaim-body-text-bold align-label-text-in-center">All Available
                Services</p>
              <!-- Dynamic Service Headers -->
              <div fxLayoutAlign="center center"
                   class="custom-nested-column-1_4 align-label-text-in-center eclaim-body-text-bold "
                   id="{{baseId}}-service-header" *ngFor="let eligibleItem of productPackage.eligibleItems">
                <p *ngIf="eligibleItem.authorizable">{{eligibleItem.description}}</p>
              </div>
            </div>

            <!-- ################################################## -->
            <!-- Row Divider -->
            <!-- ################################################## -->
            <div fxLayout="row">
              <mat-divider></mat-divider>
            </div>


            <!-- ############################################################################################################################################################################## -->
            <!-- Second Row In The Package Eligible Items -->
            <!-- ############################################################################################################################################################################## -->
            <div fxLayout="row">
              <!-- "Availability" -->
              <p fxLayoutAlign="start center" id="reissue-auth-availability"
                     class="custom-nested-column-1_6 eclaim-body-text-bold">Availability</p>
              <!--Empty Label Under the All Available Services-->
              <p class="custom-nested-column-1_8"></p>
              <!-- Status Description Dynamic Values -->
              <div fxLayoutAlign="center center"
                   class="custom-nested-column-1_4 eclaim-body-text align-label-text-in-center"
                   *ngFor="let eligibleItem of productPackage.eligibleItems; index as i"
                   id="{{baseId}}-availability-{{i}}">
                <p *ngIf="eligibleItem.authorizable">{{eligibleItem.statusDescription}}</p>
              </div>
            </div>

            <!-- ################################################## -->
            <!-- Row Divider -->
            <!-- ################################################## -->
            <div fxLayout="row">
              <mat-divider></mat-divider>
            </div>

            <!-- ############################################################################################################################################################################## -->
            <!-- Third Row In The Package Eligible Items -->
            <!-- ############################################################################################################################################################################## -->
            <div fxLayout="row">
              <!--  Authorize Benefit row -->
              <p fxLayoutAlign="start center" id="reissue-auth-authorize-benefit-label"
                     class="custom-nested-column-1_6 eclaim-body-text-bold">Authorize
                Benefit</p>
              <!--  All Available Services Checkbox -->
              <div fxLayoutAlign="center center" class="custom-nested-column-1_8">
                <mat-checkbox id="{{baseId}}-all-available-services-checkbox"
                              [checked]="productPackage.allAvailableServices"
                              [disabled]="!hasAnyAvailableServices(productPackage)"
                              (change)="toggleAuthorizeBenefitCheckboxForAllProductPackageEligibleItems(productPackage)"
                              (keyup.enter)="toggleAuthorizeBenefitCheckboxForAllProductPackageEligibleItems(productPackage)">
                </mat-checkbox>
              </div>
              <!--  Dynamic Service Checkboxes -->
              <div fxLayoutAlign="center center" class="custom-nested-column-1_4"
                   *ngFor="let eligibleItem of productPackage.eligibleItems; index as i">
                <mat-checkbox *ngIf="eligibleItem.authorizable"
                              id="{{baseId}}-service-checkbox-{{i}}"
                              [checked]="eligibleItem.authorizable && eligibleItem.isSelected"
                              [disabled]="!isAvailableToAuthorize(eligibleItem)"
                              (change)="toggleAuthorizedBenefitCheckboxForSingleEligibleItem(eligibleItem, productPackage)"
                              (keyup.enter)="toggleAuthorizedBenefitCheckboxForSingleEligibleItem(eligibleItem, productPackage)">
                </mat-checkbox>
              </div>
            </div>

            <!-- ################################################## -->
            <!-- Row Divider -->
            <!-- ################################################## -->
            <div fxLayout="row">
              <mat-divider></mat-divider>
            </div>

            <!--  ########################################################################################################## -->
            <!--  Contact Lens Notes -->
            <!--  ########################################################################################################## -->
            <div fxLayout="row" class="margin-top-15">
              <p class="eclaim-body-text" id="reissueAuthorization-contactLens-text">
                <span class="required-label-asterisk">* </span>Contact Lens TOTAL Plans utilize EXAM eligibility, see
                patient plan information for details</p>
            </div>

            <!--  ########################################################################################################## -->
            <!--  Replace Button Notes -->
            <!--  ########################################################################################################## -->
            <div class="margin-bottom-20"></div>
            <div fxLayout="row" class="eclaim-body-text">
              <p id="reissueAuthorization-replaceButon-text">
                Select the Replace button to authorize all available services for the Date of Service entered
                above</p>
            </div>

            <!--  ########################################################################################################## -->
            <!--  Replace & Cancel Button -->
            <!--  ########################################################################################################## -->
            <div fxLayout="row" class="eclaim-body-text margin-top-15 margin-bottom-30"
                 *ngIf="!productPackage.hasOnlyNonAuthorizableItems">

              <button mat-flat-button class="nested-column-2 mat-flat-button mat-primary"
                      id="{{baseId}}-replace-authorization-button"
                      [disabled]="!hasAnyEligibleItemsSelected(productPackage)"
                      (click)="replaceAuthorization(vsrInfo, productPackage)">Replace

              </button>

              <!-- Cancel Button -->
              <button mat-button color="primary" class="cancel-button margin-left-30 margin-bottom-25 nested-column-2"
                      id="reissue-auth-cancel-buton" mat-dialog-close="">Cancel
              </button>
            </div>
          </div>

        </div>
      </div>

    </mat-card>
  </form>

</div>
