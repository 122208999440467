<div fxLayout="column" fxLayoutAlign="space-between stretch">

  <mat-divider fxLayout="row"></mat-divider>

  <div fxLayout="row" fxFlexOffset="20px" fxLayoutAlign="space-between stretch" class="footer-text">

    <!-- Copyright Text -->
    <span class="float-left">© Vision Service Plan<span current-year></span>. All rights reserved </span>

    <!-- Build Numbers - Show/Hide per environment config -->
    <div *ngIf="showBuildNumbers" class="float-right">
      <span>Build #: {{buildNumber}} </span>
    </div>

  </div>
</div>
