<form (keydown.enter)="$event.preventDefault()" *ngIf="showForm" fxLayout="row" class="frame-details-component" [formGroup]="frameDetailsDisplayForm">
  <div fxLayout="column" fxFlex="25" class="frame-details-image-cell">
    <!-- API ONLINE AND FRAME IMAGE IS AVAILABLE -->
    <div *ngIf="getSelectedFrameImage() && !isFrameSearchApiOffline" class="search-results-selected-frame-image-container">
      <img class="search-results-selected-frame-image" [src]="frame.imageLink.href" height="98px" width="358px" (error)="setBrokenImageDefaultValue()"/>
      <div class="selected-frame-image-color-disclaimer">
        {{componentMessaging.imageDisclaimer}}
      </div>
    </div>
    <!-- API OFFLINE OR NO FRAME IMAGE AVAILABLE -->
    <div class="no-image-message" *ngIf="!getSelectedFrameImage() || isFrameSearchApiOffline">
      {{componentMessaging.noImage}}
    </div>
    <!-- EDIT FRAME DETAILS BUTTONS -->
    <div [hidden]="inEditMode">
      <button mat-flat-button
              class="frame-details-button mat-flat-button mat-primary"
              [hidden]="addedToOrder"
              (click)="addClick()"
              id="frame-details-add-to-lab-order-button"
              [disabled]="!formIsValid || isFrameCardDisabled">
        <span>{{componentMessaging.btnAddToOrder}}</span>
      </button>
      <button mat-flat-button
              class="frame-details-button mat-flat-button mat-primary"
              (click)="removeClick()"
              [disabled]="!formIsValid || isFrameCardDisabled"
              [hidden]="!addedToOrder" id="frame-details-display-remove-button">
        <span>{{componentMessaging.btnRemove}}</span>
      </button>
      <button mat-stroked-button
              color="primary"
              class="frame-details-button"
              (click)="editClick()"
              [disabled]="isFrameCardDisabled"
              id="frame-details-display-edit-button">
        <span>{{componentMessaging.btnEdit}}</span>
      </button>
    </div>
    <!-- SAVED FRAME DETAILS BUTTONS -->
    <div [hidden]="!inEditMode">
      <button
        mat-flat-button
        class="frame-details-button mat-flat-button mat-primary"
        [disabled]="!formIsValid || isFrameCardDisabled"
        title="{{ (formIsValid ? 'Click to save your edits' : 'Cannot save until all errors are corrected') }}"
        type="button"
        (click)="doneClick()"
        id="frame-details-display-done-button">
        <span *ngIf="!startFrameDetailsComponenetInEditMode">{{componentMessaging.btnSave}}</span>
        <span *ngIf="startFrameDetailsComponenetInEditMode">{{componentMessaging.btnAddFrame}}</span>
      </button>
      <button
        mat-stroked-button
        color="primary"
        class="frame-details-button"
        [disabled]="isFrameCardDisabled"
        type="button"
        (click)="resetClick()"
        id="frame-details-display-reset-button">
        <span>{{componentMessaging.btnReset}}</span>
      </button>
    </div>
  </div>

  <div fxLayout="column" fxFlex="75" id="frame-details-fields">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div style="margin-bottom: 10px;" [innerHTML]="componentMessaging.tableHeaderText" *ngIf="!isFrameSearchApiOffline"></div>
    </div>

      <!-- MANUFACTURER/COLOR/EYE SIZE ROW-->
      <div fxLayout="row" fxLayoutAlign="default center" class="frame-detail-row">
        <!-- MANUFACTURER -->
        <div class="frame-detail-label frame-detail-col-2" [ngClass]="{'required': allowFullFrameDetailEdit && inEditMode}" id="frame-details-manufacturer-label">Manufacturer: </div>
        <div class="frame-detail-value frame-detail-col-2_5" [ngClass]="{'no-data':(!inEditMode && (editFrame && !editFrame.manufacturer))}">
          <mat-form-field appearance="outline"
                          [hidden]="!(inEditMode && allowFullFrameDetailEdit)" class="frame-display-mat-form-field" [ngClass]="{'required-mat-form-field': inEditMode && allowFullFrameDetailEdit}">
            <input matInput
                   maxlength="25"
                   #manufacturerControl
                   formControlName="manufacturer"
                   tabindex="10"
                   name="manufacturer"
                   id="frame-display-form-manufacturer"
                   placeholder="Manufacturer Name"
                   [ngClass]="{'no-data': frameDetailsDisplayForm.controls.manufacturer.valid}">
          </mat-form-field>
          <span [hidden]="(inEditMode && allowFullFrameDetailEdit)" id="frame-display-field-label-manufacturer">{{editFrame.manufacturer}}</span>
        </div>

        <!-- COLOR -->
        <div id="frame-search-details-color-label" class="frame-detail-label frame-detail-col-1_5" [ngClass]="{'required': inEditMode}">Color: </div>
        <div class="frame-detail-value frame-detail-col-3" [ngClass]="{'no-data':(!inEditMode && (editFrame && !editFrame.color))}">
          <mat-form-field [hidden]="!inEditMode" appearance="outline" class="frame-display-mat-form-field required-mat-form-field frame-display-mat-form-field-without-help-icon">
            <input matInput
                   #colorControl
                   formControlName="color"
                   tabindex="40"
                   maxlength="20"
                   name="color"
                   id="frame-display-form-color"
                   placeholder="Color">
          </mat-form-field>
          <span [hidden]="inEditMode" id="frame-display-field-label-color">{{editFrame.color}}</span>
        </div>

        <!-- EYE SIZE -->
        <div id="frame-search-details-eye-size-label" class="frame-detail-label frame-detail-col-1" [ngClass]="{'required': inEditMode}">Eye Size: </div>
        <div class="frame-detail-value frame-detail-col-2" [ngClass]="{'no-data':(!inEditMode && (editFrame && !editFrame.eyesize))}">
          <div fxLayout="row" fxLayoutAlign="default center" [hidden]="!inEditMode">
            <mat-form-field appearance="outline"
                            class="frame-display-mat-form-field required-mat-form-field frame-mat-form-field-with-help-icon">
              <input matInput
                     #eyeSizeControl
                     maxlength="5"
                     formControlName="eyeSize"
                     class="form-control full-width text-indent"
                     tabindex="80"
                     name="eyesize"
                     id="frame-display-form-eyesize"
                     placeholder="Eye Size">
            </mat-form-field>
            <mat-icon
              id="frame-details-eye-size-info-icon"
              class="help-circle"
              [ngClass]="{ 'disabled-help-circle': isFrameCardDisabled }"
              [matTooltipClass]="'edit-frame-tooltip'"
              matTooltip="{{componentMessaging.frameEditTooltip.eyeSize}}"
              svgIcon="eclaim-icons:helpCircle">
            </mat-icon>
          </div>
          <span [hidden]="inEditMode" id="frame-display-field-label-eyeSize">{{editFrame.eyesize ? editFrame.eyesize : ''}}</span>
        </div>
      </div>

      <!-- COLLECTION/TEMPLE/B ROW -->
      <div fxLayout="row" fxLayoutAlign="default center" class="frame-detail-row">
        <!-- Collection -->
        <div class="frame-detail-label frame-detail-col-2" [ngClass]="{'required': allowFullFrameDetailEdit && inEditMode}" id="frame-details-collection-label">Collection: </div>
        <div class="frame-detail-value frame-detail-col-2_5" [ngClass]="{'no-data':(!inEditMode && (editFrame && !editFrame.collection))}">
          <mat-form-field appearance="outline"
                          [hidden]="!(inEditMode && allowFullFrameDetailEdit)" class="frame-display-mat-form-field" [ngClass]="{'required-mat-form-field': inEditMode && allowFullFrameDetailEdit}">
            <input matInput
                   #collectionControl
                   formControlName="collection"
                   tabindex="20"
                   class="form-control full-width text-indent"
                   maxlength="25"
                   name="collection"
                   id="frame-display-form-collection"
                   placeholder="Collection Name">
          </mat-form-field>
          <span [hidden]="(inEditMode && allowFullFrameDetailEdit)" id="frame-display-field-label-collection">{{editFrame.collection}}</span>
        </div>

        <!-- Temple -->
        <div id="frame-search-details-temple-label" class="frame-detail-label frame-detail-col-1_5" [ngClass]="{'required': inEditMode}">Temple: </div>
        <div class="frame-detail-value frame-detail-col-3" [ngClass]="{'no-data':(!inEditMode && (editFrame && !editFrame.temple))}">
          <div fxLayout="row" fxLayoutAlign="default center" [hidden]="!inEditMode">
            <mat-form-field appearance="outline" class="frame-display-mat-form-field required-mat-form-field frame-mat-form-field-with-help-icon">
              <input matInput
                     #templeControl
                     formControlName="temple"
                     class="form-control full-width text-indent"
                     maxlength="3"
                     tabindex="50"
                     name="temple"
                     id="frame-display-form-temple"
                     placeholder="Temple Size">
            </mat-form-field>
            <mat-icon
              id="frame-details-temple-info-icon"
              class="help-circle"
              [ngClass]="{ 'disabled-help-circle': isFrameCardDisabled }"
              [matTooltipClass]="'edit-frame-tooltip'"
              matTooltip="{{componentMessaging.frameEditTooltip.temple}}"
              svgIcon="eclaim-icons:helpCircle">
            </mat-icon>
          </div>
          <span [hidden]="inEditMode" id="frame-display-field-label-temple">{{editFrame.temple ? editFrame.temple : ''}}</span>
        </div>

        <!-- B -->
        <div class="frame-detail-label frame-detail-col-1" id="frame-search-details-b-label">B: </div>
        <div class="frame-detail-value frame-detail-col-2"
            [ngClass]="{'no-data':(!inEditMode && (editFrame && editFrame.b && !editFrame.b.toString()))}">
          <div fxLayout="row" fxLayoutAlign="default center" [hidden]="!inEditMode">
            <mat-form-field appearance="outline"
                            class="frame-display-mat-form-field frame-mat-form-field-with-help-icon">
              <input matInput
                     #bControl
                     formControlName="b"
                     class="form-control full-width text-indent"
                     name="b"
                     tabindex="90"
                     id="frame-display-form-b"
                     placeholder="B"
                     maxlength="4">
            </mat-form-field>
            <mat-icon
              id="frame-details-b-info-icon"
              class="help-circle"
              [ngClass]="{ 'disabled-help-circle': isFrameCardDisabled }"
              [matTooltipClass]="'edit-frame-tooltip'"
              matTooltip="{{componentMessaging.frameEditTooltip.b}}"
              svgIcon="eclaim-icons:helpCircle">
            </mat-icon>
          </div>
          <span [hidden]="inEditMode" id="frame-display-field-label-b">{{(editFrame.b && editFrame.b.toString()) ? editFrame.b : ''}}</span>
        </div>
      </div>

      <!-- MODEL/SHAPE/ED ROW -->
      <div fxLayout="row" fxLayoutAlign="default center" class="frame-detail-row">
        <!-- MODEL -->
        <div class="frame-detail-label frame-detail-col-2" [ngClass]="{'required': allowFullFrameDetailEdit && inEditMode}" id="frame-details-model-label">Model: </div>
        <div class="frame-detail-value frame-detail-col-2_5" [ngClass]="{'no-data':(!inEditMode && (editFrame && !editFrame.model))}">
          <mat-form-field appearance="outline" [hidden]="!(inEditMode && allowFullFrameDetailEdit)"
                          class="frame-display-mat-form-field" [ngClass]="{'required-mat-form-field': inEditMode && allowFullFrameDetailEdit}">
            <input matInput
                   #modelControl
                   formControlName="model"
                   class="form-control full-width text-indent"
                   tabindex="30"
                   name="model"
                   maxlength="25"
                   id="frame-display-form-model"
                   placeholder="Model Name">
          </mat-form-field>
          <span [hidden]="(inEditMode && allowFullFrameDetailEdit)" id="frame-display-field-label-model">{{editFrame.model}}</span>
        </div>

        <!-- SHAPE -->
        <div class="frame-detail-label frame-detail-col-1_5" id="frame-details-shape-label">Shape: </div>
        <div class="frame-detail-value frame-detail-col-3 frame-display-mat-form-field">
          <select matSelect
                  [hidden]="!inEditMode"
                  formControlName="shape"
                  class="custom-select frame-display-mat-form-field-without-help-icon"
                  tabindex="60"
                  id="frame-display-form-shape"
                  [ngClass]="{'invalidSelect': !frameDetailsDisplayForm.controls.shape.valid}">
            <option [ngValue]="null"></option>
            <option *ngFor="let shape of selectOptions.shapes" [value]="shape.label">
              {{shape.label}}
            </option>
          </select>
          <span [hidden]="inEditMode" id="frame-display-field-label-shape">{{editFrame.shape}}</span>
        </div>

        <!-- ED -->
        <div class="frame-detail-label frame-detail-col-1" id="frame-search-details-ed-label">ED: </div>
        <div class="frame-detail-value frame-detail-col-2"
            [ngClass]="{'no-data':(!inEditMode && (editFrame && editFrame.ed && !editFrame.ed.toString() ))}">
          <div fxLayout="row" fxLayoutAlign="default center" [hidden]="!inEditMode">
            <mat-form-field appearance="outline"
                            class="frame-display-mat-form-field frame-mat-form-field-with-help-icon">
              <input matInput
                     #edControl
                     formControlName="ed"
                     class="form-control full-width   text-indent"
                     name="ed"
                     tabindex="100"
                     id="frame-display-form-ed"
                     placeholder="ED"
                     maxlength="4">
            </mat-form-field>
            <mat-icon
              id="frame-details-ed-info-icon"
              class="help-circle"
              [ngClass]="{ 'disabled-help-circle': isFrameCardDisabled }"
              [matTooltipClass]="'edit-frame-tooltip'"
              matTooltip="{{componentMessaging.frameEditTooltip.ed}}"
              svgIcon="eclaim-icons:helpCircle">
            </mat-icon>
          </div>
          <span [hidden]="inEditMode" id="frame-display-field-label-ed">{{(editFrame.ed && editFrame.ed.toString()) ? editFrame.ed : ''}}</span>
        </div>
      </div>

      <!-- UPC/MATERIAL/DBL ROW -->
      <div fxLayout="row" fxLayoutAlign="default center" class="frame-detail-row">
        <!-- UPC -->
        <span class="frame-detail-label frame-detail-col-2" *ngIf="!allowFullFrameDetailEdit" id="frame-details-UPC-label">UPC: </span>
        <div class="frame-detail-col-2" *ngIf="allowFullFrameDetailEdit"></div>
        <div class="frame-detail-value frame-detail-col-2_5"
             [ngClass]="{'no-data':(!isFrameSearchApiOffline && !inEditMode && (editFrame && !editFrame.upc))}">
          <ng-template *ngIf="!allowFullFrameDetailEdit">
            <mat-form-field appearance="outline" [hidden]="!(inEditMode && allowFullFrameDetailEdit)"
                            class="frame-display-mat-form-field">
              <input matInput
                     #upcControl
                     formControlName="upc"
                     class="form-control full-width text-indent"
                     name="upc"
                     tabindex="13"
                     id="frame-display-form-upc"
                     placeholder="UPC">
            </mat-form-field>
          </ng-template>
          <span [hidden]="(inEditMode && allowFullFrameDetailEdit)"
                id="frame-display-field-label-upc">{{editFrame.upc ? editFrame.upc : ''}}</span>
        </div>

        <!-- Material -->
        <div id="frame-search-details-material-label" class="frame-detail-label frame-detail-col-1_5" [ngClass]="{'required': inEditMode}">Material: </div>
        <div class="frame-detail-value frame-detail-col-3 frame-display-mat-form-field" [ngClass]="{'no-data':(!inEditMode && (editFrame && !editFrame.material))}">
          <select matSelect
                  [hidden]="!inEditMode"
                  formControlName="materialType"
                  class="custom-select required-form-field frame-display-mat-form-field-without-help-icon"
                  tabindex="70"
                  id="frame-display-form-materialType"
                  [ngClass]="{'invalidSelect': !frameDetailsDisplayForm.controls.materialType.valid}">
            <option [ngValue]="null">&nbsp;</option>
            <option *ngFor="let frameMaterial of selectOptions.frameMaterials" [value]="frameMaterial.label">
              {{frameMaterial.label}}
            </option>
          </select>
          <span [hidden]="inEditMode" id="frame-display-field-label-materialType">{{editFrame.material}}</span>
        </div>

        <!-- DBL -->
        <div id="frame-search-details-dbl-label" class="frame-detail-label frame-detail-col-1" [ngClass]="{'required': inEditMode}">DBL: </div>
        <div class="frame-detail-value frame-detail-col-2"
            [ngClass]="{'no-data':(!inEditMode && (editFrame && editFrame.dbl && !editFrame.dbl.toString() ))}">
          <div fxLayout="row" fxLayoutAlign="default center" [hidden]="!inEditMode">
            <mat-form-field appearance="outline" class="frame-display-mat-form-field required-mat-form-field frame-mat-form-field-with-help-icon">
              <input matInput
                     #dblControl
                     formControlName="dbl"
                     maxlength="4"
                     name="dbl"
                     tabindex="110"
                     id="frame-display-form-dbl"
                     placeholder="DBL">
            </mat-form-field>
            <mat-icon
              id="frame-details-dbl-info-icon"
              class="help-circle"
              [ngClass]="{ 'disabled-help-circle': isFrameCardDisabled }"
              [matTooltipClass]="'edit-frame-tooltip'"
              matTooltip="{{componentMessaging.frameEditTooltip.dbl}}"
              svgIcon="eclaim-icons:helpCircle">
            </mat-icon>
          </div>
          <span [hidden]="inEditMode" id="frame-display-field-label-dbl">{{(editFrame.dbl && editFrame.dbl.toString()) ? editFrame.dbl : ''}}</span>
        </div>
      </div>

      <!-- SKU -->
      <div fxLayout="row" fxLayoutAlign="default center" class="frame-detail-row" *ngIf="!allowFullFrameDetailEdit">
        <div class="frame-detail-label frame-detail-col-2" id="frame-details-SKU-label">SKU: </div>
        <div class="frame-detail-value frame-detail-col-2_5"
            [ngClass]="{'no-data':(!isFrameSearchApiOffline && !inEditMode && (editFrame && !editFrame.sku))}">
          <mat-form-field appearance="outline" [hidden]="!(inEditMode && allowFullFrameDetailEdit)"
                          class="frame-display-mat-form-field">
            <input matInput
                   #skuControl
                   formControlName="sku"
                   name="sku"
                   tabindex="14"
                   id="frame-display-form-sku"
                   placeholder="Enter SKU">
          </mat-form-field>
          <span [hidden]="(inEditMode && allowFullFrameDetailEdit)" id="frame-display-field-label-sku">{{editFrame.sku ? editFrame.sku : ''}}</span>
        </div>
      </div>
      <!-- Wholesale Frame Cost -->
      <div fxLayout="row" fxLayoutAlign=" center" class="frame-detail-row">
        <div id="frame-wholesale-frame-cost-label" class="frame-detail-label frame-detail-col-2" [ngClass]="{'required-wholesale': wholeSaleFrameCostRequired && inEditMode}">Wholesale Frame Cost</div>:
        <div class="frame-detail-value frame-detail-col-2_5" [ngClass]="{'no-data':(!inEditMode && (editFrame && !editFrame.wholeSalePrice))}">
          <div fxLayout="row" fxLayoutAlign="default center" [hidden]="!inEditMode">
            <mat-form-field appearance="outline" class="frame-display-mat-form-field frame-mat-form-field-with-help-icon" [ngClass]="{'required-mat-form-field': (supplier === suppliers.LAB || supplier === suppliers.DOCTOR)}">
              <input matInput id="frame-display-form-wholesale-cost"
                        #wholeSalePrice
                        formControlName="wholeSalePrice"
                        tabindex="35"
                        maxlength="7"
                        name="wholeSalePrice"
                        (blur)="onWholesaleCostBlur($event, $event.target.id)"
                        placeholder="Wholesale Cost">
            </mat-form-field>
            <mat-icon
              id="frame-details-wholesale-frame-cost-info-icon"
              class="help-circle"
              [ngClass]="{ 'disabled-help-circle': isFrameCardDisabled }"
              [matTooltipClass]="'edit-frame-tooltip'"
              matTooltip="{{componentMessaging.frameEditTooltip.wholeSalePrice}}"
              svgIcon="eclaim-icons:helpCircle">
            </mat-icon>
          </div>
          <span [hidden]="inEditMode" id="frame-display-form-wholesale-cost-label">{{editFrame.wholeSalePrice}}</span>
        </div>
      </div>


      <div fxLayout="row" fxLayoutAlign="default center">
        <div *ngIf="!formIsValid" colspan="6" class="frame-details-edit-required-fields-error"
            id="frame-details-edit-required-fields-error-cell">
          <br/><span>{{componentMessaging.requiredFieldsError}}</span>
        </div>
      </div>
  </div>

</form>
