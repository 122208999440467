import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConstants } from '../../../../../constants/application.constants';
import { HttpClientService } from '../http-client-service';
import {Inventory, ServiceCode} from '../../../../../../models/Inventory';
import {ServicesConstants} from '../../../../../../secure/claim-form/services/services.constants';
import {Subscriber} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InventoryDataService extends HttpClientService {

  constructor(protected injector: Injector) {
    super(injector, ApplicationConstants.dataServiceClientIDs.inventoryServiceDataServices);
  }

  // TODO: Once the new API is built we will uncomment this below and update it to how it is supposed to be
  // private getInventoryUrl(): string {
  //   const inventoryApi = `${this.environment.apiUrl}/${ApplicationConstants.api.inventoryApi}`;
  //   return `${inventoryApi}/inventoryitems/search?serviceCode=`;
  // }

  getHcpcCptCodeDescription(hcpcCptCode: string): Observable<Inventory> {
    // TODO: Once we have the real api. This observer can be deleted and the code below used.
    return new Observable<Inventory>((observer: Subscriber<Inventory>) => {
      // Set the Inventory Information
      const inventory: Inventory = {} as Inventory;
      const serviceCode: ServiceCode = {} as ServiceCode;
      inventory.description = this.filterHcpcCodes(hcpcCptCode);
      serviceCode.code = hcpcCptCode;
      inventory.serviceCodes = serviceCode;
      observer.next(inventory);
      observer.complete();
    });

    // TODO: Once the new API is built we will uncomment this below and update it to how it is supposed to be (i.e. change the model to match)
    // const url: string = `${this.getInventoryUrl()}${hcpcCptCode}`;
    //
    // return this.http.get<Inventory>(url, { headers: this.getHttpHeaders(ApplicationConstants.api.inventoryApi)}).pipe(
    //   map((inventoryResponse: Inventory) => {
    //     return inventoryResponse;
    //   }),
    //   catchError((error: any) => {
    //     this.handleError('getHcpcCptCodeDescription', error);
    //     this.messageService.currentErrorMessageString = ApplicationConstants.errorMessages.showHcpcCptDescriptionErrorMessage;
    //     this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.showHcpcCptDescriptionErrorMessage);
    //     return of(undefined);
    //   })
    // );
  }

  // TODO: Once we have the new api ready we can remove this method using the static list in the app.
  public filterHcpcCodes(filterBy: string): string {
    let filterByResults: string = ApplicationConstants.noDescriptionFound;
    if (filterBy) {
      Object.keys(ServicesConstants.HcpcCodeGroups).forEach((group) => {
        for (let codeGroupIndex = 0; codeGroupIndex < ServicesConstants.HcpcCodeGroups[group].length; codeGroupIndex++) {
          Object.deepClone(ServicesConstants.HcpcCodeGroups)[group][codeGroupIndex] = ServicesConstants.HcpcCodeGroups[group][codeGroupIndex].filter(hcpcCodeGroup => {
            if (hcpcCodeGroup.code.toLowerCase() === (filterBy.toLowerCase()) || hcpcCodeGroup.value.toLowerCase() === (filterBy.toLowerCase())) {
              filterByResults = hcpcCodeGroup.value;
            }
          });
        }
      });
    }
    return filterByResults;
  }

}
