import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidatorsService} from '../../common/services/support/custom-validators/custom-validators.service';
import {
  ApplicationConstants,
  ErrorTypes,
  UserTypeQualifier
} from '../../common/constants/application.constants';
import {MatDialog} from '@angular/material/dialog';
import {GiftCertificateSubmissionConfirmationComponent} from './gift-certificate-submission-confirmation/gift-certificate-submission-confirmation.component';
import {GiftCertificatesService} from '../../common/services/data-model/app/gift-certificates/gift-certificates.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GiftCertificateRedemption} from '../../models/giftCertificate';
import {DateUtility} from '../../common/utility';
import {ClaimsService} from '../../common/services/data-model/app/claims/claims.service';
import {DatePickerConfiguration} from '../../common/components/date-picker/date-picker.component';
import {openDialog, isNullOrUndefined, trimWhitespaceFromControlValue} from '../../common/utility';
import {Claim} from '../../models/claim';
import {ErrorWrapperConfig} from '../../common/components/error-wrapper/error-wrapper.component';

@Component({
  selector: 'app-gift-certificate-submission',
  templateUrl: './gift-certificate-submission.component.html',
  styleUrls: ['./gift-certificate-submission.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GiftCertificateSubmissionComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
    private router: Router,
    private giftCertificateService: GiftCertificatesService,
    private claimsService: ClaimsService,
    private route: ActivatedRoute
  ) {
  }

  /***** START - PRIVATE MEMBERS *****/
  private giftCertificateNumber: string;
  /***** END - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/
  showForm = true;
  @ViewChild('patientDateOfBirthControl', {read: ElementRef, static: true}) patientDateOfBirthControl: ElementRef;
  @ViewChild('patientEffectiveDateControl', {read: ElementRef, static: true}) patientEffectiveDateControl: ElementRef;

  giftCertificateSubmissionForm: FormGroup;
  // Form state/data variables
  errorWrapperConfig = {
    patientId: new ErrorWrapperConfig(),
    patientFirstName: new ErrorWrapperConfig(),
    patientLastName: new ErrorWrapperConfig()
  };
  datePickerConfigurationDateOfBirth: DatePickerConfiguration;
  datePickerConfigurationPatientEffectiveDate: DatePickerConfiguration;
  vsrNumber = undefined;
  readonly firstNameMaxLength: number = ApplicationConstants.maxFirstNameLength;
  readonly lastNameMaxLength: number = ApplicationConstants.maxLastNameLength;

  /***** END - PUBLIC MEMBERS *****/

  /***** START - PRIVATE FUNCTIONS *****/
  private buildDatePickerConfigurations(): void {
    this.datePickerConfigurationDateOfBirth = {
      control: this.giftCertificateSubmissionForm.controls.dateOfBirth,
      controlName: 'dateOfBirth',
      errorWrapperId: {
        defaultValidations: 'gift-certificate-dob-error',
        minDate: 'gift-certificate-min-date-error'
      },
      attributes: {
        id: 'date-of-birth'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a Date of Birth'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Date Of Birth must be in MM/DD/YYYY format'
        }
      ]
    };

    this.datePickerConfigurationPatientEffectiveDate = {
      control: this.giftCertificateSubmissionForm.controls.patientEffectiveDate,
      controlName: 'patientEffectiveDate',
      errorWrapperId: {
        defaultValidations: 'gift-certificate-patient-effective-date-error',
        minDate: 'gift-certificate-patient-effective-date-min-date-error',
      },
      attributes: {
        id: 'patient-effective-date',
        name: 'dateOfService'
      },
      minDate: new Date(ApplicationConstants.thirtyDaysPriorToTodayDate),
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter an Effective Date'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Patient Effective Date must be in MM/DD/YYYY format'
        },
        {
          validatorType: ErrorTypes.MinDate,
          errorMessage: 'Date entered cannot be older than 30 days'
        }
      ]
    };
  }

  private buildForm(): void {
    const patientEffectiveDate = DateUtility.buildFriendlyDateFromJsDate(ApplicationConstants.todaysDate);
    this.giftCertificateSubmissionForm = this.formBuilder.group({
      patientId: ['', [Validators.required,
        Validators.pattern(/^[0-9]{9}$/)]],
      patientFirstName: ['', [Validators.required,
        Validators.pattern(ApplicationConstants.ValidNameRegex)]],
      patientLastName: ['', [Validators.required,
        Validators.pattern(ApplicationConstants.ValidNameRegex)]],
      dateOfBirth: ['', [Validators.required,
        this.customValidatorsService.dateFormatAndValidity,
        this.customValidatorsService.MinDate(ApplicationConstants.minDate)]],
      patientEffectiveDate: [patientEffectiveDate, [Validators.required,
        this.customValidatorsService.dateFormatAndValidity,
        this.customValidatorsService.MinDate(new Date(ApplicationConstants.thirtyDaysPriorToTodayDate))]]
    });
    this.giftCertificateSubmissionForm.valueChanges.subscribe((viewModel) => {
      // Build up the data model based on changes to the view model
      Object.keys(viewModel).forEach((viewModelKey) => {
        this.giftCertificateSubmissionForm[viewModelKey] = viewModel[viewModelKey];
      });
    });
  }


  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      patientId: {
        control: this.giftCertificateSubmissionForm.controls.patientId,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a Patient ID'
        },
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter a valid Patient ID'
          }]
      },
      patientFirstName: {
        control: this.giftCertificateSubmissionForm.controls.patientFirstName,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a Patient First Name'
        },
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: ApplicationConstants.invalidFirstNameMessage(UserTypeQualifier.Patient)
          },
        ]
      },
      patientLastName: {
        control: this.giftCertificateSubmissionForm.controls.patientLastName,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a Patient Last Name'
        },
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: ApplicationConstants.invalidLastNameMessage(UserTypeQualifier.Patient)
          },
        ]
      }
    };
  }

  private buildGiftCertificateRequestBody(): GiftCertificateRedemption {
    return {
      gcNumber: this.giftCertificateNumber,
      patientId: this.giftCertificateSubmissionForm.controls.patientId.value,
      memberCovEffectiveStartDate: DateUtility.buildYyyyMmDdDateFromDate(this.giftCertificateSubmissionForm.controls.patientEffectiveDate.value),
      memberName: {
        firstName: this.giftCertificateSubmissionForm.controls.patientFirstName.value,
        lastName: this.giftCertificateSubmissionForm.controls.patientLastName.value,
      },
      dateOfBirth: DateUtility.buildYyyyMmDdDateFromDate(this.giftCertificateSubmissionForm.controls.dateOfBirth.value)
    };
  }

  private redeemGiftCertificate(showSnackbar: boolean = true): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Redeeming Gift Certificate...', this.dialog);
    // call redeem GC API and navigate to the claim form upon successful gift certificate redemption
    this.giftCertificateService.redeemGiftCertificate(this.buildGiftCertificateRequestBody(), showSnackbar)
      .subscribe((giftCertificateRedemptionResponse: any) => {
        // got GC api response
        if (giftCertificateRedemptionResponse) {
          if (giftCertificateRedemptionResponse.vsrNumber) {
            this.dialog.closeAll();
            this.createPatientEncounterForGiftCertificate(giftCertificateRedemptionResponse.vsrNumber, false);
          } else {
            // GC redemption failed
            this.giftCertificateService.navigationCameFromGcFlow = true;
            this.router.navigate([ApplicationConstants.routing.secure.eInsurancePageUrl]);
          }
        }
        this.dialog.closeAll();
      }, () => {
        // on observer error
      }, () => {
        // Close the dialog when the observer completes
        this.dialog.closeAll();
      });
  }

  private createPatientEncounterForGiftCertificate(vsrNumber: string, showSnackbar: boolean = true): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Loading Claim Details...', this.dialog);
    this.vsrNumber = vsrNumber;
    this.claimsService.createClaim(vsrNumber, new Date(), showSnackbar).subscribe((activeClaim: Claim) => {
      if (activeClaim) {
        if (activeClaim.trackingNumber) {
          this.router.navigate([ApplicationConstants.routing.secure.claimFormEditPageUrl], {
            queryParams: {
              authorizationNumber: vsrNumber,
              claimAlreadyLoaded: true
            }
          });
        } else {
          // creation of PE failed
          this.giftCertificateService.navigationCameFromGcFlow = true;
          this.router.navigate([ApplicationConstants.routing.secure.eInsurancePageUrl]);
        }
      }
    }, () => {
      // on observer error
    }, () => {
      // Close the dialog when the observer completes
      this.dialog.closeAll();
    });
  }

  /***** END - PRIVATE FUNCTIONS *****/

  /***** START - PUBLIC FUNCTIONS *****/

  ngOnInit(): void {
    this.giftCertificateNumber = this.giftCertificateService.giftCertificateNumber;
    this.buildForm();
    this.buildErrorWrapperConfig();
    this.buildDatePickerConfigurations();
    // setup observable on the gift certificate service and navigate to the submission form upon successful gift certificate search
    const searchedGiftCertificate = this.giftCertificateService.giftCertificate;
    if (!searchedGiftCertificate) {
      let giftCertificateNumber: string;
      if (!isNullOrUndefined(this.route) && !isNullOrUndefined(this.route.snapshot) && !isNullOrUndefined(this.route.snapshot.queryParamMap)) {
        giftCertificateNumber = this.route.snapshot.queryParamMap.get(ApplicationConstants.giftCertificateNumber);
      } else {
        giftCertificateNumber = undefined;
      }
      if (isNullOrUndefined(giftCertificateNumber)) {
        this.navigateToEInsurancePage();
      } else {
        // Hide form until observable is resolved
        this.showForm = false;
        openDialog('Reloading Gift Certificate...', this.dialog);
        this.giftCertificateService.loadGiftCertificate(giftCertificateNumber, false).subscribe(giftCertificate => {
            if (this.giftCertificateService.giftCertificateIsValid(giftCertificate)) {
              this.router.navigate([ApplicationConstants.routing.secure.giftCertificateSubmissionPageUrl], {
                queryParams: {
                  giftCertificateNumber: giftCertificateNumber
                }
              });
              this.showForm = true;
            } else {
              this.navigateToEInsurancePage();
            }
            this.dialog.closeAll();
          }, () => {
            // on observer error
            this.dialog.closeAll();
            this.navigateToEInsurancePage();
          },
          () => {
            // Close dialog for LoadingModalComponent - Spinner
            this.dialog.closeAll();
          });
      }
    }
  }

  public navigateToEInsurancePage(): void {
    this.giftCertificateService.navigationCameFromGcFlow = true;
    this.router.navigate([ApplicationConstants.routing.secure.eInsurancePageUrl]);
  }

  public resetGCSubmissionForm(): void {
    this.giftCertificateSubmissionForm.reset();
    // Hide/Show form to reset validators and UI to pristine state (this workaround is fixed in future versions of angular using resetForm())
    this.showForm = false;
    setTimeout(() => this.showForm = true);
    this.giftCertificateSubmissionForm.controls.patientEffectiveDate.setValue(DateUtility.buildFriendlyDateFromJsDate(ApplicationConstants.todaysDate));
  }

  trimName(control: AbstractControl): void {
    trimWhitespaceFromControlValue(control);
  }

  hasValidGiftCertificateSubmission(): boolean {
    return this.giftCertificateSubmissionForm.valid;
  }

  showConfirmationMessage(): void {
    // show the Valid Search Combinations modal
    const dialogRef = this.dialog.open(GiftCertificateSubmissionConfirmationComponent, {
      width: '700px',
      panelClass: 'eclaim-popup-modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (ApplicationConstants.modalSelectionOk === result) {
        this.redeemGiftCertificate(false);
      }
    });
  }

  /***** END - PUBLIC FUNCTIONS *****/

}


