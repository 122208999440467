import { Injectable } from '@angular/core';
import { AppDataService } from '../../http/http-client-data/app-data/app-data.service';
import {Observable, Subscriber} from 'rxjs';
import {
  GiftCertificate,
  GiftCertificateRedemption,
  GiftCertificateRedemptionResponse,
  GiftCertificateStatusEnum
} from '../../../../../models/giftCertificate';

@Injectable({
  providedIn: 'root'
})
export class GiftCertificatesService {

  constructor(
    private appDataService: AppDataService,
  ) { }


  /***** START - PRIVATE MEMBERS *****/
  private _giftCertificate: GiftCertificate;
  private _giftCertificateNumber: string;
  private _giftCertificateRedemption: GiftCertificateRedemptionResponse;
  private _navigationCameFromGcFlow: boolean = false;
  /***** END - PRIVATE MEMBERS *****/

  /***** START - PUBLIC FUNCTIONS *****/
  get giftCertificate(): GiftCertificate {
    return (this._giftCertificate) ? Object.deepClone(this._giftCertificate) : this._giftCertificate;
  }

  set giftCertificate(giftCertificate: GiftCertificate) {
    this._giftCertificate = giftCertificate;
  }

  get giftCertificateNumber(): string {
    return this._giftCertificateNumber;
  }

  set giftCertificateNumber(giftCertificateNumber: string) {
    this._giftCertificateNumber = giftCertificateNumber;
  }

  get giftCertificateRedemption(): GiftCertificateRedemptionResponse {
    return (this._giftCertificateRedemption) ? Object.deepClone(this._giftCertificateRedemption) : this._giftCertificateRedemption;
  }

  set giftCertificateRedemption(giftCertificateRedemption: GiftCertificateRedemptionResponse) {
    this._giftCertificateRedemption = giftCertificateRedemption;
  }

  get navigationCameFromGcFlow(): boolean {
    return this._navigationCameFromGcFlow;
  }

  set navigationCameFromGcFlow(value: boolean) {
    this._navigationCameFromGcFlow = value;
  }

  loadGiftCertificate(giftCertificateNumber: string, showSnackbar: boolean = true): Observable<GiftCertificate> {
    this.giftCertificate = undefined; // resetting back to undefined for next request
    this.giftCertificateNumber = giftCertificateNumber;
    return new Observable<GiftCertificate>( (observer: Subscriber<GiftCertificate>) => {
      this.appDataService.getGiftCertificate(giftCertificateNumber, showSnackbar).subscribe((uiFormattedGiftCertificate) => {
        this.giftCertificate = uiFormattedGiftCertificate;
        observer.next(this.giftCertificate);
      });
    });
  }

  redeemGiftCertificate(giftCertificateRedemption: GiftCertificateRedemption, showSnackbar: boolean = true): Observable<GiftCertificateRedemptionResponse> {
    this.giftCertificateRedemption =  { gcNumber: undefined, vsrNumber: undefined, status: undefined, error: undefined };
    const redemptionUrl = this.giftCertificate.redeem.href;
    return new Observable<GiftCertificateRedemptionResponse>( (observer: Subscriber<GiftCertificateRedemptionResponse>) => {
      this.appDataService.postGiftCertificate(redemptionUrl, giftCertificateRedemption, showSnackbar)
      .subscribe((uiFormattedGiftCertificateRedepmtion) => {
        this.giftCertificateRedemption =  uiFormattedGiftCertificateRedepmtion;
        observer.next(uiFormattedGiftCertificateRedepmtion);
      });
    });
  }

  giftCertificateIsValid(giftCertificate: any) {
    return giftCertificate &&
      giftCertificate.giftCertificateStatus &&
      giftCertificate.giftCertificateStatus === GiftCertificateStatusEnum.VALID;
  }
  /***** END - PUBLIC FUNCTIONS *****/
}
