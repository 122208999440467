<!-- Register the date picker as a form component so we can access the formControlName attribute used by the polyfill and ErrorWrapperComponent -->
<form novalidate [formGroup]="datePickerConfiguration.control.parent" autocomplete="off">
  <mat-form-field appearance="outline" class="form-control-full-width">
    <!-- Custom input field to satisfy date requirements/format -->
    <input matInput
           type="text"
           (blur)="formatDateOnBlur()"
           #dateTextBox
           [maxLength]="maxDateLength"
           [id]="datePickerConfiguration.attributes?.id"
           [formControlName]="datePickerConfiguration.controlName"
           placeholder="mm/dd/yyyy"/>
    <!-- Hide the implemented date picker field required for the mat-calendar functionality with min/max date validation and dateChange event emitter -->
    <input type="hidden"
           id="date-picker-mock"
           (dateChange)="onDateChange($event)"
           [value]="lastValidJsDate"
           [matDatepicker]="eClaimDatePicker"
           [disabled]="datePickerConfiguration.control.disabled"
           [max]="maxDate"
           [min]="minDate"/>
    <mat-datepicker-toggle [id]="datePickerConfiguration.attributes?.datePickerToggleId" matSuffix [for]="eClaimDatePicker"></mat-datepicker-toggle>
    <mat-datepicker [id]="datePickerConfiguration.attributes?.datePickerId" #eClaimDatePicker (opened)="onMatCalendarOpen()" (closed)="onMatCalendarClose()"></mat-datepicker>
  </mat-form-field>
  <!-- House the error wrappers within this component for separating concerns from dependent components -->
  <app-error-wrapper [id]="datePickerConfiguration.errorWrapperId.defaultValidations" [config]="errorWrapperConfig['date']"></app-error-wrapper>
  <app-error-wrapper [id]="datePickerConfiguration.errorWrapperId.minDate" [hidden]="hasInvalidDate(datePickerConfiguration.control)" [config]="errorWrapperConfig['minDate']"></app-error-wrapper>
  <app-error-wrapper [id]="datePickerConfiguration.errorWrapperId.maxDate" [hidden]="hasInvalidDate(datePickerConfiguration.control)" [config]="errorWrapperConfig['maxDate']"></app-error-wrapper>
</form>

