import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConsumerService} from '../../common/services/data-model/app/consumer/consumer.service';
import {MembershipName} from '../../models/membership';
import {getFormattedFullName, isNullOrUndefined, isStringNullUndefinedOrEmpty, openDialog} from '../../common/utility';
import {EligibilityService} from '../../common/services/data-model/app/eligibility/eligibility.service';
import {
  Eligibility,
  EligibilityCoverage,
  EligibilityProductPackage,
  EligibleItem,
  EligibleItemVsrDto
} from '../../models/eligibility';
import {DatePickerConfiguration} from '../../common/components/date-picker/date-picker.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicationConstants, ErrorTypes, SessionStorageKeys} from '../../common/constants/application.constants';
import {CustomValidatorsService} from '../../common/services/support/custom-validators/custom-validators.service';
import {AuthorizationsService} from '../../common/services/data-model/app/authorizations/authorizations.service';
import {CreateVisionServiceRequest} from '../../models/VisionServiceRequestResults';
import {MatDialog} from '@angular/material/dialog';
import {DateUtility} from '../../common/utility';
import {BusinessMemberPoliciesResults, ConsumerName} from '../../models/consumer';
import {Link} from '../../models/link';
import {DrReportsNavigationService} from '../../common/services/support/dr-reports-navigation/dr-reports-navigation.service';

export enum EligibilityStatusDescription {
  YES = 'Yes',
  AUTHORIZED = 'Authorized',
}

@Component({
  selector: 'app-coverage-summary',
  templateUrl: './coverage-summary.component.html',
  styleUrls: ['./coverage-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CoverageSummaryComponent implements OnInit, OnDestroy {


  constructor(
    private router: Router,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private eligibilityService: EligibilityService,
    private consumerService: ConsumerService,
    private authorizationService: AuthorizationsService,
    private customValidatorsService: CustomValidatorsService,
    private drReportsNavigationService: DrReportsNavigationService
  ) {
  }

  /***** START - PRIVATE MEMBERS *****/
  private _memberName: MembershipName = {firstName: '', lastName: ''};
  private _consumerName: ConsumerName = {firstName: '', lastName: '', firstNameSrc: '', lastNameSrc: ''};
  private _memberEligibility: Eligibility;
  private _loadingEligibility: boolean;
  private _lastValidDateOfServiceWherePatientIsEligibileForServices: string;
  /***** START - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/
  today = new Date();
  coverageSummaryFormGroup: FormGroup;
  dateOfService = new Date();
  datePickerConfiguration: DatePickerConfiguration;

  /***** END - PUBLIC MEMBERS *****/


  /***** START - PROPERTY ACCESSORS *****/
  get memberEligibility(): Eligibility {
    return this._memberEligibility;
  }

  set memberEligibility(eligibility: Eligibility) {
    this._memberEligibility = eligibility;
  }

  get loadingEligibility(): boolean {
    return this._loadingEligibility;
  }

  set loadingEligibility(loadingEligibility: boolean) {
    this._loadingEligibility = loadingEligibility;
  }

  get lastValidDateOfServiceWherePatientIsEligibileForServices(): string {
    return this._lastValidDateOfServiceWherePatientIsEligibileForServices;
  }

  set lastValidDateOfServiceWherePatientIsEligibileForServices(lastValidDateOfServiceWherePatientIsEligibileForServices: string) {
    this._lastValidDateOfServiceWherePatientIsEligibileForServices = lastValidDateOfServiceWherePatientIsEligibileForServices;
  }

  get eligibilityProductPackages(): EligibilityProductPackage[] {
    if (this.memberEligibility.productPackageViewModelList) {
      return this.memberEligibility.productPackageViewModelList;
    }
  }

  get clientMessages(): string[] {
    if (this.memberEligibility.clientMessages) {
      return this.memberEligibility.clientMessages;
    }
  }

  get memberName(): MembershipName {
    return this._memberName;
  }

  set memberName(name: MembershipName) {
    this._memberName = name;
  }

  get consumerName(): ConsumerName {
    return this._consumerName;
  }

  set consumerName(name: ConsumerName) {
    this._consumerName = name;
  }

  /***** END - PROPERTY ACCESSORS *****/

  /***** START - PRIVATE METHODS *****/
  private isEligibleItemAuthorizableAndNotSelected = (eligibleItem: EligibleItem): boolean =>
    eligibleItem && eligibleItem.authorizable && !eligibleItem.isSelected

  private closeAllPendingDialogs = () => {
    if (this.dialog.openDialogs && this.dialog.openDialogs.length > 0) {
      this.dialog.closeAll();
    }
  }

  /**
   * Prototypes the eligible items on the response from eligibility-web with a view model property for checkboxes.
   *
   * @see CoverageSummaryComponent#toggleAuthorizeBenefitCheckboxForAllProductPackageEligibleItems
   * @see CoverageSummaryComponent#toggleAuthorizedBenefitCheckboxForSingleEligibleItem
   */
  private setDefaultAuthorizedBenefitsCheckboxForEachProductPackageEligibleItem(): void {
    this.eligibilityProductPackages.forEach((productPackage: EligibilityProductPackage) => {
      productPackage.eligibleItemInLieuOfs = new Map<string, string[]>();
      // Check if we have coverages returned for the product package
      if (productPackage.coverages && productPackage.coverages.length > 0) {
        // Iterate through the coverages on the product package to check if there are any in lieu of relation to the eligible item that has just been updated
        productPackage.coverages.forEach((coverageItem: EligibilityCoverage) => {
          // If the coverage has a productCatalogKey and an inLieuOf with product catalog key(s) then we can set the inLieuOf relation in the eligibleItemInLieuOfs map
          if (coverageItem && coverageItem.productCatalogKeys && coverageItem.productCatalogKeys.length > 0 &&
            coverageItem.inLieuOf && coverageItem.inLieuOf.productCatalogKeys && coverageItem.inLieuOf.productCatalogKeys.length > 0) {
            productPackage.eligibleItemInLieuOfs.set(coverageItem.productCatalogKeys[0], coverageItem.inLieuOf.productCatalogKeys);
          }
        });
      }
      // Initialize all available services checkbox
      productPackage.allAvailableServices = false;
      if (productPackage.eligibleItems && productPackage.eligibleItems.length > 0) {
        productPackage.eligibleItems.forEach((eligibleItem: EligibleItem) => {
          // Initialize each individual service checkbox
          eligibleItem.isSelected = false;
          // Initialize a view property to check if a product package has any available services
          if (isNullOrUndefined(productPackage.hasAnyAvailableServices)) {
            productPackage.hasAnyAvailableServices = this.isAvailableToAuthorize(eligibleItem) ? true : undefined;
          }
        });
      }
    });
  }

  private buildDatePickerConfiguration(): void {
    this.datePickerConfiguration = {
      control: this.coverageSummaryFormGroup.controls.dateOfService,
      controlName: 'dateOfService',
      errorWrapperId: {
        defaultValidations: 'coverage-summary-date-of-service-date-error',
        minDate: 'coverage-summary-date-of-service-min-date-error'
      },
      attributes: {
        id: 'date-of-service'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the date of service'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Date of Service must be in MM/DD/YYYY format'
        }
      ]
    };
  }

  private buildForm(): void {
    this.coverageSummaryFormGroup = this.formBuilder.group({
      dateOfService: [DateUtility.buildFriendlyDateFromJsDate(sessionStorage.getItem(SessionStorageKeys.MemberDateOfService)),
        [
          Validators.required,
          this.customValidatorsService.dateFormatAndValidity,
          this.customValidatorsService.MinDate(ApplicationConstants.minDate)
        ]]
    });
  }

  private updateVSRLinkReturnedFromEligibilityWithDateOfService(vsrLink: string): string {
    // Instantiate the link as a url
    const vsrLinkAsUrl: URL = new URL(vsrLink);

    // If the date of service is valid, update the link with it
    if (!isStringNullUndefinedOrEmpty(this.lastValidDateOfServiceWherePatientIsEligibileForServices) && this.coverageSummaryFormGroup.valid) {
      vsrLinkAsUrl.searchParams.set('effectiveDate', DateUtility.buildYyyyMmDdDateFromDate(this.lastValidDateOfServiceWherePatientIsEligibileForServices));
      return vsrLinkAsUrl.toString();
    }

    // Default to returning the link passed from eligibility if date of service is not available
    return vsrLink;
  }

  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  // checks if there are any client messages to show
  hasClientMessages(): boolean {
    return !isNullOrUndefined(this.memberEligibility) &&
      !isNullOrUndefined(this.memberEligibility.clientMessages) &&
      this.memberEligibility.clientMessages.length > 0;
  }

  // build the full name of the patient/member
  getFullName(): string {
    const middleInitial = this.memberName.middleInitial ? this.memberName.middleInitial : null;
    return getFormattedFullName(this.memberName.firstName, this.memberName.lastName, middleInitial);
  }

  private loadEligibility(): void {
    this.loadingEligibility = true;
    // Retrieve eligibility stored in service
    this.memberEligibility = this.eligibilityService.memberEligibility;

    if (!this.memberEligibility) {
      // if there is no eligibility stored in the service, then check session storage
      const sessionStoredEligibilityRetrieveLink = this.eligibilityService.getEligibilityRetrieveLinkFromSessionStorage();
      // if we have elgibility link in session storage, then recall the eligibility API to get data.
      if (sessionStoredEligibilityRetrieveLink && sessionStoredEligibilityRetrieveLink.href) {
        // Calling LoadingModalComponent for Spinner (Preload Icon)
        openDialog('Loading Coverage...', this.dialog);
        this.eligibilityService.retrieveMemberEligibilityFromConsumerLink(sessionStoredEligibilityRetrieveLink.href).subscribe((memberEligibility: Eligibility) => {
          if (memberEligibility) {
            this.lastValidDateOfServiceWherePatientIsEligibileForServices = this.coverageSummaryFormGroup.controls.dateOfService.value;
            this.memberEligibility = memberEligibility;
            if (this.eligibilityProductPackages) {
              this.setDefaultAuthorizedBenefitsCheckboxForEachProductPackageEligibleItem();
            }
          }
        }, () => {
          // on observer error
        }, () => {
          // Close the dialog when the observer completes
          this.dialog.closeAll();
          this.loadingEligibility = false;
        });
      } else {
        this.router.navigate([ApplicationConstants.routing.secure.memberSearchPageUrl]);
      }
    } else {
      if (this.eligibilityProductPackages) {
        this.setDefaultAuthorizedBenefitsCheckboxForEachProductPackageEligibleItem();
      }
      this.loadingEligibility = false;
    }
  }

  private reloadEligibility(updateDateOfService: string): void {
    this.loadingEligibility = true;
    // get eligibility link from session storage
    const sessionStoredEligibilityRetrieveLink = this.eligibilityService.getEligibilityRetrieveLinkFromSessionStorage();
    // if we have elgibility link in session storage, then recall the eligibility API to get data using new as of date.
    if (sessionStoredEligibilityRetrieveLink && sessionStoredEligibilityRetrieveLink.href) {
      // Calling LoadingModalComponent for Spinner (Preload Icon)
      openDialog('Loading Coverage...', this.dialog);
      this.eligibilityService.retrieveMemberEligibilityWithSpecifiedAsOfDate(sessionStoredEligibilityRetrieveLink.href, updateDateOfService).subscribe((memberEligibility: Eligibility) => {
        if (memberEligibility) {
          this.lastValidDateOfServiceWherePatientIsEligibileForServices = this.coverageSummaryFormGroup.controls.dateOfService.value;
          this.memberEligibility = memberEligibility;
          if (this.eligibilityProductPackages) {
            this.setDefaultAuthorizedBenefitsCheckboxForEachProductPackageEligibleItem();
          }
        } else {
          this.coverageSummaryFormGroup.controls.dateOfService.setValue(this.lastValidDateOfServiceWherePatientIsEligibileForServices);
        }
      }, () => {
        // on observer error
      }, () => {
        // Close the dialog when the observer completes
        this.dialog.closeAll();
        this.loadingEligibility = false;
      });
    } else {
      this.router.navigate([ApplicationConstants.routing.secure.memberSearchPageUrl]);
    }
  }

  /**
   * Get the eligibility link from session storage, no need to check for validity as that is done in loadEligibility()
   * If the eligibility link is not available and/or valid, this method will not be called and users will be navigated back to member search
   */
  private setMemberNameFromMemberPolicyUsingMatchOnCachedEligibilityLink(): void {
     this.consumerService.getMemberRetrieveResultsOrRecallApiIfDataIsNotInMemory().subscribe( ( memberRetrieveResults: BusinessMemberPoliciesResults ) => {
       if (memberRetrieveResults) {
         let patientFirstName: string = ApplicationConstants.emptyString;
         let patientLastName: string = ApplicationConstants.emptyString;
         const eligibilityLink: Link = this.eligibilityService.getEligibilityRetrieveLinkFromSessionStorage();
         const patientEligibilityHref: string = eligibilityLink.href;
         const memberEligibilityHref: string = (!isNullOrUndefined(memberRetrieveResults.externalEligibilityLink)
                                               && !isStringNullUndefinedOrEmpty(memberRetrieveResults.externalEligibilityLink.href))
                                               ? memberRetrieveResults.externalEligibilityLink.href : ApplicationConstants.emptyString;
         if (patientEligibilityHref === memberEligibilityHref) {
           patientFirstName = memberRetrieveResults.firstName;
           patientLastName = memberRetrieveResults.lastName;
         } else {
           memberRetrieveResults.dependents.forEach(dependent => {
             if (!isNullOrUndefined(dependent.externalEligibilityLink) && !isStringNullUndefinedOrEmpty(dependent.externalEligibilityLink.href)) {
               if (patientEligibilityHref === dependent.externalEligibilityLink.href) {
                 patientFirstName = dependent.dependentsName.firstName;
                 patientLastName = dependent.dependentsName.lastName;
               }
             }
           });
         }
         this.memberName.firstName = patientFirstName;
         this.memberName.lastName = patientLastName;
       }
     });
  }

  viewPlanSummary(): void {
    const asOfDate = DateUtility.buildFriendlyDateFromJsDate(this.coverageSummaryFormGroup.get('dateOfService').value);
    this.drReportsNavigationService.navigateToVSPPlanSummary(asOfDate, this.memberName.firstName, this.memberName.lastName);
  }

  /***** END - PUBLIC FUNCTIONS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit(): void {
    this.buildForm();
    this.buildDatePickerConfiguration();
    this.loadEligibility();
    this.setMemberNameFromMemberPolicyUsingMatchOnCachedEligibilityLink();
  }

  ngOnDestroy(): void {
    // Remove the in-memory cached eligibility on navigation from coverage summary, forcing navigation back to the page
    // to reload the eligibility displayed on the page from the link in session storage
    this.eligibilityService.memberEligibility = undefined;
  }

  /**
   * Toggles the checkbox status for all eligible items for the selected product package.
   *
   * @param productPackage
   */
  toggleAuthorizeBenefitCheckboxForAllProductPackageEligibleItems(productPackage: EligibilityProductPackage): void {
    productPackage.allAvailableServices = !productPackage.allAvailableServices;
    productPackage.eligibleItems.forEach((eligibleItem: EligibleItem) => {
      if (this.isAvailableToAuthorize(eligibleItem)) {
        // For each available service, set the checkbox to match the state of the all available services checkbox
        eligibleItem.isSelected = productPackage.allAvailableServices;
      } else if (eligibleItem.isSelected && eligibleItem.inLieuOfIsSelected) {
        eligibleItem.isSelected = productPackage.allAvailableServices;
        eligibleItem.inLieuOfIsSelected = productPackage.allAvailableServices;
      }
    });
  }

  /**
   * Toggles the checkbox status for a single eligible item for the selected product package. If all eligible items are
   * in a checked state, update the all available services checkbox to checked. Conversely, if all eligible items are
   * checked and the user un-checks a box, the method will trigger the all available services checkbox to un-check itself.
   *
   * @param eligibleItem
   * @param productPackage
   */
  toggleAuthorizedBenefitCheckboxForSingleEligibleItem(eligibleItem: EligibleItem, productPackage: EligibilityProductPackage): void {
    // Toggle the individual service checkbox, and set all available services checkbox to match the eligible item checked status
    eligibleItem.isSelected = !eligibleItem.isSelected;
    productPackage.allAvailableServices = eligibleItem.isSelected;

    this.updateAvailabilityBasedOnInLieuOf(eligibleItem, productPackage);
    // Iterate through the eligible items on the product package to look for un-checked items
    for (const currentEligibleItemIndex in productPackage.eligibleItems) {
      // Initialize a reference to the current eligible item, and if the item is able to be authorized and is not selected, un-check all available services
      const currentEligibleItem: EligibleItem = productPackage.eligibleItems[currentEligibleItemIndex];
      if (this.isEligibleItemAuthorizableAndNotSelected(currentEligibleItem)) {
        productPackage.allAvailableServices = false;
        break;
      }
    }
  }

  /**
   * This method is invoked user interacts with one of the authorizable checkboxes.
   * If the eligible item in question that is passed in has any other eligible items from the same package that are in lieu of this eligible item,
   * then we need to either check and disable the in lieu of eligible item's checkboxes or uncheck and enable the in lieu of eligible item's checkboxes
   * depending on if the eligible item interacted with is being checked or unchecked.
   * @param eligibleItem
   * @param productPackage
   */
  updateAvailabilityBasedOnInLieuOf(eligibleItem: EligibleItem, productPackage: EligibilityProductPackage): void {
    if (!isNullOrUndefined(productPackage.eligibleItemInLieuOfs)) {
      const eligibleItemsInLieOfThisEligibileItem: string[] = productPackage.eligibleItemInLieuOfs.get(eligibleItem.productCatalogKey);
      // check if the eligibile item passed in has any other eligibile items it is in lieu of
      if (eligibleItemsInLieOfThisEligibileItem && eligibleItemsInLieOfThisEligibileItem.length > 0) {
        // Iterate through the eligible items in the package
        productPackage.eligibleItems.forEach((currentEligibleItem: EligibleItem) => {
          // If we find an eligible item that is in lieu of the eligible item passed into this method
          if (eligibleItemsInLieOfThisEligibileItem.includes(currentEligibleItem.productCatalogKey)) {
            // If the eligible item passed in is checked by the user, then we need to set the in lieu of fields to checked and disabled also
            if (eligibleItem.isSelected && this.isAvailableToAuthorize(currentEligibleItem)) {
              currentEligibleItem.isSelected = true;
              currentEligibleItem.inLieuOfIsSelected = true;
            } else {
              currentEligibleItem.isSelected = false;
              currentEligibleItem.inLieuOfIsSelected = false;
            }
          }
        });
      }
    }
  }

  /**
   * Matches on any eligible items having been selected via their respective checkbox and enables the Issue Authorizations
   * button if any have been checked.
   *
   * @param productPackage
   */
  hasAnyEligibleItemsSelected(productPackage: EligibilityProductPackage): boolean {
    if (productPackage.eligibleItems && productPackage.eligibleItems.length > 0) {
      for (let eligibleItemIndex = 0; eligibleItemIndex < productPackage.eligibleItems.length; eligibleItemIndex++) {
        if (productPackage.eligibleItems[eligibleItemIndex].isSelected) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Enables the Issue Authorization button based on the product package view model property for any available services.
   *
   * @param productPackage
   */
  hasAnyAvailableServices(productPackage: EligibilityProductPackage): boolean {
    return !isNullOrUndefined(productPackage.hasAnyAvailableServices);
  }

  /**
   * Used to disable checkboxes that are not available to authorize due to non-available status descriptions.
   *
   * @param eligibleItem
   */
  isAvailableToAuthorize(eligibleItem: EligibleItem): boolean {
    return eligibleItem.authorizable && eligibleItem.statusDescription === EligibilityStatusDescription.YES && !eligibleItem.inLieuOfIsSelected;
  }

  /**
   * Issues an authorization using the VSR link returned from eligibility-web. Retrieves a list of all selected and available
   * to authorize items sent from the UI on the product package, and pushes them on the VSR eligible items payload.
   *
   * @param productPackage
   */
  issueAuthorization(productPackage: EligibilityProductPackage): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Loading Authorization...', this.dialog);
    const selectedEligibleItems: EligibleItemVsrDto[] = [];

    if (productPackage.eligibleItems && productPackage.eligibleItems.length > 0) {
      productPackage.eligibleItems.forEach((eligibleItem: EligibleItem) => {
        if (eligibleItem.authorizable && eligibleItem.isSelected) {
          selectedEligibleItems.push({name: eligibleItem.productCatalogKey});
        }
      });
    }

    // Check to see if the URL is available on the product package, supplied by eligibility-web
    if (productPackage.createVisionServiceRequest && productPackage.createVisionServiceRequest.href) {
      const vsrLink: string = this.updateVSRLinkReturnedFromEligibilityWithDateOfService(productPackage.createVisionServiceRequest.href);
      const createVsrRequest = new CreateVisionServiceRequest(selectedEligibleItems);
      // Verify the create request contains the necessary information to send in the payload to VSR
      if (createVsrRequest.vsrPayload) {
        this.authorizationService.createAuthorization(vsrLink, createVsrRequest.vsrPayload).subscribe((vsrNumber: string) => {
          this.closeAllPendingDialogs();
          if (!isStringNullUndefinedOrEmpty(vsrNumber)) {
            if (!isNullOrUndefined(this.consumerService.businessMemberRetrieveResults)
              && !isNullOrUndefined(this.consumerService.businessMemberRetrieveResults.fehb)
              && this.consumerService.businessMemberRetrieveResults.fehb.fehbEnrollInd
              && !isNullOrUndefined(this.consumerService.businessMemberRetrieveResults.fehb.fehbPlanLink)) {
              this.consumerService.retrieveClientWebFEHBPlan(this.consumerService.businessMemberRetrieveResults.fehb.fehbPlanLink.href).subscribe((federalEnrolleeHealthBenefitPlan) => {
                if (federalEnrolleeHealthBenefitPlan && federalEnrolleeHealthBenefitPlan.planName) {
                  this.router.navigate([ApplicationConstants.routing.secure.cobCoveragePageUrl]);
                } else {
                  this.router.navigate([[ApplicationConstants.routing.secure.baseRoute, ApplicationConstants.routing.secure.authorizationConfirmation].join('/')]);
                }
              });
            } else {
              this.router.navigate([[ApplicationConstants.routing.secure.baseRoute, ApplicationConstants.routing.secure.authorizationConfirmation].join('/')]);
            }
          }
        });
      } else {
        // Close dialogs if the payload was not valid
        this.closeAllPendingDialogs();
      }
    } else {
      // Close dialogs if the VSR link on the product package was not available
      this.closeAllPendingDialogs();
    }
  }

  onCoverageSummaryFormSubmit(): void {
    // TODO - implement form submit logic
  }

  updateEligibilityUsingAsOfDateFromDateOfService(): void {
    if (this.coverageSummaryFormGroup.controls.dateOfService.valid) {
      this.reloadEligibility(this.coverageSummaryFormGroup.controls.dateOfService.value);
    }
  }

  /***** END - EVENT HANDLERS *****/
}
