import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {DialogData} from '../../member-search/valid-search-combinations/valid-search-combinations.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApplicationConstants} from '../../../common/constants/application.constants';

@Component({
  selector: 'app-gift-certificate-submission-confirmation',
  templateUrl: './gift-certificate-submission-confirmation.component.html',
  styleUrls: ['./gift-certificate-submission-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GiftCertificateSubmissionConfirmationComponent {

  constructor(
    public dialogRef: MatDialogRef<GiftCertificateSubmissionConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onOkClick(): void {
    this.dialogRef.close(ApplicationConstants.modalSelectionOk);
  }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }


}
