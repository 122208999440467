import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApplicationConstants} from '../../constants/application.constants';

export interface SimpleModalDialogData {
  header: string[];
  messages: string[];
}

@Component({
  selector: 'app-simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimpleModalComponent {

  constructor(
    private dialogRef: MatDialogRef<SimpleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimpleModalDialogData
  ) {
  }

  public confirm() {
    this.dialogRef.close(ApplicationConstants.modalSelectionOk);
  }

  onClose(): void {
    this.dialogRef.close(false);
  }
}
