import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import {
  ApiFormattedClaim,
  ApiFormattedClaims,
  Claim
} from '../../../../../../models/claim';
import {
  GiftCertificate,
  GiftCertificateErrorMessageEnum,
  GiftCertificateRedemption,
  GiftCertificateRedemptionErrorMessage,
  GiftCertificateRedemptionResponse,
  GiftCertificateSearchResponse,
  GiftCertificateStatusEnum
} from '../../../../../../models/giftCertificate';
import { ApplicationConstants, ClaimAction } from '../../../../../constants/application.constants';
import { HttpClientService } from '../http-client-service';
import {
  LensVisionTypeResponse,
  LensMaterialTypeResponse,
  LensTypeResponse
} from 'src/app/models/lens';
import {DateUtility, isNullOrUndefined, isStringNullUndefinedOrEmpty} from '../../../../../utility';
import {environment} from '../../../../../../../environments/environment';
import {FeedBackFormRequest} from '../../../../../../models/FeedbackFormRequest';
import {FeedBackFormResult} from '../../../../../../models/FeedbackFormResult';


@Injectable({
  providedIn: 'root'
})
export class AppDataService extends HttpClientService {

  constructor(
    public injector: Injector,
  ) {
    super(injector, ApplicationConstants.dataServiceClientIDs.appDataService);
  }

  /***** START - PRIVATE FUNCTIONS *****/
  private getGiftCertificateRedemptionErrorMessage(error: any): string {
    let errorMessage = ApplicationConstants.errorMessages.giftCertificateApiFailMessage;
    if (error instanceof HttpErrorResponse && Object.keys(GiftCertificateRedemptionErrorMessage).includes(error.error.error)) {
      errorMessage = GiftCertificateRedemptionErrorMessage[error.error.error];
    }
    return errorMessage;
  }

  private getGiftCertificateSearchErrorMessage(giftCertificateError: string): string {
    return (giftCertificateError) ? giftCertificateError : ApplicationConstants.errorMessages.giftCertificateApiFailMessage;
  }

  private getPatientEncounterErrorMessage(error: any): string {
    let errorMessage = ApplicationConstants.errorMessages.genericApiFailMessage;
    if (!isNullOrUndefined(error) && !isNullOrUndefined(error.error) && !isStringNullUndefinedOrEmpty(error.error.description)) {
      errorMessage = error.error.errorNumber + ': ' + error.error.description;
    }
    return errorMessage;
  }

  private getClaimsBaseUrl(): string {
    const patientEncounterApi = ApplicationConstants.api.patientEncounterApi;
    const domain = (!isStringNullUndefinedOrEmpty(environment.patientEncounterUrl)) ? environment.patientEncounterUrl : environment.apiUrl;
    return `${domain}/${patientEncounterApi}/patientencounter`;
  }

  private getGiftCertificateBaseUrl(): string {
    const claimGiftCertificatesApi = ApplicationConstants.api.claimGiftCertificatesApi;
    const domain = (!isStringNullUndefinedOrEmpty(environment.giftCertificateUrl)) ? environment.giftCertificateUrl : environment.apiUrl;
    return `${domain}/${claimGiftCertificatesApi}/giftcertificates`;
  }

  private getLensVisionTypesBaseUrl(): string {
    const catalogLensApi = ApplicationConstants.api.catalogLensApi;
    const domain = (!isStringNullUndefinedOrEmpty(environment.lensCatalogUrl)) ? environment.lensCatalogUrl : environment.apiUrl;
    return `${domain}/${catalogLensApi}/lensvisiontypes`;
  }

  private getLensMaterialTypesBaseUrl(): string {
    const catalogLensApi = ApplicationConstants.api.catalogLensApi;
    const domain = (!isStringNullUndefinedOrEmpty(environment.lensCatalogUrl)) ? environment.lensCatalogUrl : environment.apiUrl;
    return `${domain}/${catalogLensApi}/lensmaterialtypes`;
  }

  private getSpectacleLensesBaseUrl(): string {
    const catalogLensApi = ApplicationConstants.api.catalogLensApi;
    const domain = (!isStringNullUndefinedOrEmpty(environment.lensCatalogUrl)) ? environment.lensCatalogUrl : environment.apiUrl;
    return `${domain}/${catalogLensApi}/spectaclelenses/summaries`;
  }
  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  getClaim(authorizationNumber: string, showSnackbar: boolean = true): Observable<Claim> {
    const url = `${this.getClaimsBaseUrl()}/${authorizationNumber}`;
    return this.http.get<ApiFormattedClaim>(url, { headers: this.getHttpHeaders(ApplicationConstants.api.patientEncounterApi)}).pipe(
      map((apiFormattedClaim: ApiFormattedClaim) => {
        this.log(`Fetched Claim from the API`);
        // TODO move data formatting logic into data layer
        return this.dataMarshallService.formatClaimForUi(apiFormattedClaim);
      }),
      catchError((error: any) => {
        const errorMessage = this.getPatientEncounterErrorMessage(error);
        this.handleError('getClaim', error);
        this.messageService.setErrorMessage(showSnackbar, `${errorMessage}`);
        return of(undefined);
      })
    );
  }

  searchClaim(authorizationNumber: string, showSnackbar: boolean = true): Observable<ApiFormattedClaims> {
    const url = `${this.getClaimsBaseUrl()}?trackingNumber=${authorizationNumber}`;
    return this.http.get<ApiFormattedClaims>(url, { headers: this.getHttpHeaders(ApplicationConstants.api.patientEncounterApi)}).pipe(
      map((apiFormattedClaims: ApiFormattedClaims) => {
        return apiFormattedClaims;
      }),
      catchError((error: any) => {
        const errorMessage = this.getPatientEncounterErrorMessage(error);
        this.handleError('getClaim', error);
        this.messageService.setErrorMessage(showSnackbar, `${errorMessage}`);
        return of(undefined);
      })
    );
  }

  createClaim(trackingNumber: string, dateOfService: Date, showSnackbar: boolean = true): Observable<Claim> {
    const createClaimHttpOptions = {
      trackingNumber: trackingNumber,
      dateOfService: DateUtility.buildYyyyMmDdDateFromDate(dateOfService),
    };
    return this.http.post<ApiFormattedClaim>(this.getClaimsBaseUrl(), createClaimHttpOptions, { headers: this.getHttpHeaders(ApplicationConstants.api.patientEncounterApi)}).pipe(
      map((apiFormattedClaim: ApiFormattedClaim) => {
        this.log(`Created new claim`);
        this.messageService.showConfirmationSnackbar(`Claim created successfully`);
        // TODO move data formatting logic into data layer
        return this.dataMarshallService.formatClaimForUi(apiFormattedClaim);
      }),
      catchError((error: any) => {
        const errorMessage = this.getPatientEncounterErrorMessage(error);
        this.handleError('createClaim', error);
        this.messageService.setErrorMessage(showSnackbar, errorMessage);
        return of({} as Claim);
      })
    );
  }

  updateClaim(uiFormattedClaim: Claim, action: string): Observable<any> {
    const actionErrorMessages = {'SAVE' : 'Claim update failed.', 'SUBMIT': 'Claim submission failed.', 'CALCULATE': 'Calculate claim failed.'};
    const apiFormattedClaim = this.dataMarshallService.formatClaimForApi(uiFormattedClaim);
    // sets the action property on the claim to the specified action
    apiFormattedClaim.action = action;
    const { trackingNumber } = apiFormattedClaim;
    const url: string = action === ClaimAction.Calculate ? `${this.getClaimsBaseUrl()}/hcpc-cpt-calculator/${trackingNumber}` : `${this.getClaimsBaseUrl()}/${trackingNumber}`;
    return this.http.put<ApiFormattedClaim>(url, apiFormattedClaim, { headers: this.getHttpHeaders(ApplicationConstants.api.patientEncounterApi)}).pipe(
      map((apiFormattedClaim: ApiFormattedClaim) => {
        this.log(`Updated Claim`);
        return apiFormattedClaim;
      }),
      catchError((error: any) => {
        const isSubmitAndGatewayTimeOutHappened: boolean = action === ClaimAction.Submit && this.isGatewayTimeOut(error);
        const errorMessage = this.getPatientEncounterErrorMessage(error);
        this.handleError('updateClaim', error);
        if (isSubmitAndGatewayTimeOutHappened) {
          this.messageService.setErrorMessage(false, ApplicationConstants.errorMessages.gatewayTimeOutMessage);
          this.messageService.isSubmitAndGatewayTimeOutHappenedInClaimForm = true;
        } else {
          this.messageService.showErrorSnackbar(`${actionErrorMessages[action]} ${errorMessage}`);
        }
        return of(undefined);
      })
    );
  }

  getGiftCertificate(giftCertificateNumber: string, showSnackbar: boolean = true): Observable<GiftCertificate> {
    const url = `${this.getGiftCertificateBaseUrl()}?gcNumber=${giftCertificateNumber}`;
    return this.http.get<GiftCertificateSearchResponse>(url, { headers: this.getHttpHeaders(ApplicationConstants.api.claimGiftCertificatesApi)}).pipe(
      map((giftCertificateSearchResponse: GiftCertificateSearchResponse) => {
        this.log(`Fetched Gift Certificate from the API`);
        // TODO move logic of interrogating response data to potentially show errors to data layer
        if (giftCertificateSearchResponse) {
          const giftCertificateStatus = (giftCertificateSearchResponse.totalItems > 0) ? giftCertificateSearchResponse.items[0].giftCertificateStatus : GiftCertificateStatusEnum.NOTFOUND;
          if (giftCertificateSearchResponse.totalItems > 0 && giftCertificateSearchResponse.items.length === 1 && giftCertificateStatus === GiftCertificateStatusEnum.VALID) {
            return giftCertificateSearchResponse.items[0];
          }
          const errorMessage = this.getGiftCertificateSearchErrorMessage(GiftCertificateErrorMessageEnum[giftCertificateStatus]);
          this.messageService.setErrorMessage(showSnackbar, errorMessage);
          return undefined;
        } else {
          this.messageService.setErrorMessage(showSnackbar, ApplicationConstants.errorMessages.giftCertificateApiFailMessage);
          return undefined;
        }
      }),
      catchError((error: any) => {
        const errorMessage = ApplicationConstants.errorMessages.giftCertificateApiFailMessage;
        this.handleError('getGiftCertificate', error);
        this.messageService.setErrorMessage(showSnackbar, `${errorMessage}`);
        return of(undefined);
      })
    );
  }

  postGiftCertificate(redemptionUrl: string, giftCertificateRedemption: GiftCertificateRedemption, showSnackbar: boolean = true): Observable<GiftCertificateRedemptionResponse> {
    return this.http.post<GiftCertificateRedemptionResponse>(redemptionUrl, giftCertificateRedemption, { headers: this.getHttpHeaders(ApplicationConstants.api.claimGiftCertificatesApi)}).pipe(
      map((giftCertificateRedemptionResponse: GiftCertificateRedemptionResponse) => {
        // TODO move interrogation of vsrNumber causing error snack bar into data layer
        if (giftCertificateRedemptionResponse.vsrNumber) {
          return giftCertificateRedemptionResponse;
        } else {
          this.messageService.setErrorMessage(showSnackbar, 'Gift Certificate Redemption failed.');
          return {} as GiftCertificateRedemptionResponse;
        }
      }),
      catchError((error: any) => {
        const errorMessage = this.getGiftCertificateRedemptionErrorMessage(error);
        this.handleError('postGiftCertificate', error);
        this.messageService.setErrorMessage(showSnackbar, `${errorMessage}`);
        return of({} as GiftCertificateRedemptionResponse);
      })
    );
  }

  getLensVisionTypes(): Observable<LensVisionTypeResponse> {
    const url = `${this.getLensVisionTypesBaseUrl()}`;
    return this.http.get<LensVisionTypeResponse>(url, { headers: this.getHttpHeaders(ApplicationConstants.api.catalogLensApi)}).pipe(
      map((lensVisionTypeResponse: LensVisionTypeResponse) => {
        if (lensVisionTypeResponse && lensVisionTypeResponse.items && lensVisionTypeResponse.items.length > 0) {
          return lensVisionTypeResponse;
        } else {
          throw new Error(ApplicationConstants.errorMessages.genericApiFailMessage);
        }
      }),
      catchError((error: any) => {
        const errorMessage = this.getErrorMessage(error);
        this.messageService.showErrorSnackbar(`${errorMessage}`);
        this.handleError('getLensVisionTypes', error);
        return of(undefined);
      })
    );
  }

  getLensMaterialTypes(): Observable<LensMaterialTypeResponse> {
    const url = `${this.getLensMaterialTypesBaseUrl()}`;
    return this.http.get<LensMaterialTypeResponse>(url, { headers: this.getHttpHeaders(ApplicationConstants.api.catalogLensApi)}).pipe(
      map((lensMaterialTypeResponse: LensMaterialTypeResponse) => {
        if (lensMaterialTypeResponse && lensMaterialTypeResponse.items && lensMaterialTypeResponse.items.length > 0) {
          return lensMaterialTypeResponse;
        } else {
          throw new Error(ApplicationConstants.errorMessages.genericApiFailMessage);
        }
      }),
      catchError((error: any) => {
        const errorMessage = this.getErrorMessage(error);
        this.messageService.showErrorSnackbar(`${errorMessage}`);
        this.handleError('getLensMaterialTypes', error);
        return of(undefined);
      })
    );
  }

  getLensTypes(channelName: string, visionType: string, materialType: string, dateOfService: Date, lensFilter: string): Observable<LensTypeResponse> {
    const asOfDate = DateUtility.buildYyyyMmDdDateFromDate(dateOfService);
    let url: string = '';
    if (!isStringNullUndefinedOrEmpty(asOfDate) && !isStringNullUndefinedOrEmpty(channelName) && !isStringNullUndefinedOrEmpty(visionType) && !isStringNullUndefinedOrEmpty(materialType)) {
       url = `${this.getSpectacleLensesBaseUrl()}?pageSize=10000&channelName=${channelName}&asOfDate=${asOfDate}&materialType=${materialType}&visionType=${visionType}`;
    } else {
      return of(undefined);
    }
    if (!isStringNullUndefinedOrEmpty(lensFilter)) {
        url += `${lensFilter}`;
    }
    return this.http.get<LensTypeResponse>(url, { headers: this.getHttpHeaders(ApplicationConstants.api.catalogLensApi)}).pipe(
      map((lensTypeResponse: LensTypeResponse) => {
        if (lensTypeResponse && lensTypeResponse.items && lensTypeResponse.items.length > 0) {
          return lensTypeResponse;
        } else {
          this.messageService.showErrorSnackbar(`${ApplicationConstants.errorMessages.noLensFoundMessage}`);
        }
      }),
      catchError((error: any) => {
        const errorMessage = this.getErrorMessage(error);
        this.messageService.showErrorSnackbar(`${errorMessage}`);
        this.handleError('getLensTypes', error);
        return of(undefined);
      })
    );
  }

  getFeedbackFormUrl(): string {
    const patientEncounterApi = ApplicationConstants.api.patientEncounterApi;
    const domain = (!isStringNullUndefinedOrEmpty(environment.patientEncounterUrl)) ? environment.patientEncounterUrl : environment.apiUrl;
    return `${domain}/${patientEncounterApi}/feedback`;
  }

  postFeedbackForm(feedbackFormObject: FeedBackFormRequest): Observable<FeedBackFormResult> {
    return this.http.post<FeedBackFormResult>(this.getFeedbackFormUrl(), feedbackFormObject, {headers: this.getHttpHeaders(ApplicationConstants.api.patientEncounterApi)}).pipe(
      map((feedbackFormResponse: FeedBackFormResult) => {
        // Return the feedback Form Response from PE
        return feedbackFormResponse;
      }),
      catchError((error: any) => {
        this.handleError('FeedBackSubmission', error);
        return of(undefined);
      })
    );
  }
  /***** END - PUBLIC FUNCTIONS *****/
}
