<!-- ########################################################################################################## -->
<!-- Second Disclaimer Again Right Before Search Results -->
<!-- ########################################################################################################## -->

<div class="nested-column-12">
  <!-- Sub Header Two Before Search Results-->
  <label fxLayout="row" id="claims-tracking-track-claims-sub-header-two-before-search-results"
         class="margin-top-20 margin-bottom-20
               eclaim-sub-header-text">{{subHeaderTwo}}</label>
</div>

<!-- ########################################################################################################## -->
<!-- Search Result Mat Card Section -->
<!-- ########################################################################################################## -->

<mat-card>
  <!-- Search Results Section Header -->
  <mat-card-header>
    <mat-card-title>
      <h1 class="margin-bottom-0" id="claims-tracking-search-results-header">Search Results</h1>
    </mat-card-title>
  </mat-card-header>

  <!-- Additional info-->
  <div fxLayout="row">
    <!-- Practice Name -->
    <div class="nested-column-12 margin-bottom-20 margin-top-20 eclaim-body-text-bold">
      <label fxLayout="row" id="claims-tracking-provider-name-label-two">{{practiceName}}</label>
      <label fxLayout="row" id="claims-tracking-estimated-payable-label">Estimated Payable: ${{formatAmount(totalEstimatedPayableAmount)}}</label>
    </div>
  </div>

  <!-- Filter Section -->
  <div *ngIf="displayFilterSection()" class="filter-card-section">
    <form novalidate [formGroup]="claimsTrackingSearchResultsForm">
      <div fxLayout="row">
        <div fxLayout="column" class="nested-column-5">
          <label class="eclaim-body-text margin-bottom-5" id="claims-tracking-search-results-patient-name-label">Patient Name
            <mat-icon
            id="claims-tracking-search-results-patient-name-label-info-icon"
            class="help-circle"
            matTooltip="Patient name must contains at least 2 characters, can be separated by a comma or space."
            svgIcon="eclaim-icons:helpCircle">
          </mat-icon>
          </label>
          <mat-form-field appearance="outline" class="field-background">
            <input formControlName="patientNameFilter" matInput id="claims-tracking-search-results-patient-name-field" type="text" placeholder="Enter Patient Name"
                   class="form-control"/>
          </mat-form-field>
          <!-- Error Wrapper For The Search By Member Id Field -->
          <app-error-wrapper id="claims-tracking-search-results-patient-name-field-error-message"
                             [config]="errorWrapperConfig.patientNameFilter"></app-error-wrapper>
        </div>
        <!-- Date of Service Dropdown -->
        <div fxLayout="column" class="nested-column-2_3">
          <label class="eclaim-body-text margin-bottom-5" id="claims-tracking-search-results-date-of-service-label">Date of Service</label>
          <app-date-picker class="custom-datepicker" id="claims-tracking-search-results-date-of-service-filter-date-picker"
                           [datePickerConfiguration]="datePickerConfigurationDateOfServiceFilter"></app-date-picker>
        </div>
        <!-- Filter Button -->
        <div class="custom-nested-column-1_4">
          <button mat-flat-button id="claims-tracking-search-results-filter-button"
                  (click)="filterResults()"
                  [disabled]="!hasValidFilterCombination"
                  class="form-control search-button mat-flat-button mat-primary"
                  type="submit">Filter</button>
        </div>
        <!-- Reset Button -->
        <div fxLayout="column" class="custom-nested-column-1_4">
          <button id="claims-tracking-search-results-clear-button"
                  type="button"
                  (click)="clearFilter()"
                  class="form-control clear-action-button">Clear</button>
        </div>
      </div>
    </form>
  </div>

  <!-- Search Result Table -->
  <mat-table [dataSource]="dataSource" id="claims-tracking-result-table" matSort>

    <!-- Claim Number Container -->
    <ng-container matColumnDef="claimNumber">
      <mat-header-cell *matHeaderCellDef id="claims-tracking-result-claim-number-header"
                       class="nested-column-2">Claim Number</mat-header-cell>
      <mat-cell *matCellDef="let claimSummary; index as i" class="action-link nested-column-2"
                (click)="onClaimNumberClick(claimSummary)"
                id="claims-tracking-result-claim-number-data-{{i}}">{{claimSummary.authorizationNumber}}</mat-cell>
    </ng-container>

    <!-- Patient Name Container -->
    <ng-container matColumnDef="patientName">
      <mat-header-cell *matHeaderCellDef id="claims-tracking-result-patient-name-header"
                       class="nested-column-2">Patient Name</mat-header-cell>
      <mat-cell *matCellDef="let claimSummary; index as i" class="nested-column-2"
                id="claims-tracking-result-patient-name-data-{{i}}">{{claimSummary.memberPatientInfo.patientLastName}}, {{claimSummary.memberPatientInfo.patientFirstName}}</mat-cell>
    </ng-container>

    <!-- Date of Service Container -->
    <ng-container matColumnDef="dateOfService">
      <mat-header-cell *matHeaderCellDef id="claims-tracking-result-date-of-service-header"
                       class="nested-column-2">Date of Service</mat-header-cell>
      <mat-cell *matCellDef="let claimSummary; index as i" class="nested-column-2"
                id="claims-tracking-result-date-of-service-data-{{i}}">{{formatDateForUi(claimSummary.dateOfService)}}</mat-cell>
    </ng-container>

    <!-- Services Container -->
    <ng-container matColumnDef="serviceItemCodes">
      <mat-header-cell *matHeaderCellDef id="claims-tracking-result-services-header"
                       class="nested-column-2">Services</mat-header-cell>
      <!-- A 'span' had to be added inside the mat-cell body inorder to get the tool tip to work correctly' -->
      <mat-cell *matCellDef="let claimSummary; index as i" class="nested-column-2"
                id="claims-tracking-result-services-data-{{i}}"><span id="claims-tracking-result-services-data-span-{{i}}"
                                                                      [matTooltip]="claimSummary.serviceItemDescriptions"
                                                                      matTooltipPosition="above"
                                                                      matTooltipClass="tooltip-font">{{claimSummary.serviceItemCodes}}</span></mat-cell>
    </ng-container>

    <!-- Product Container -->
    <ng-container matColumnDef="programId">
      <mat-header-cell *matHeaderCellDef id="claims-tracking-result-product-header"
                       class="nested-column-2">Plan</mat-header-cell>
      <mat-cell *matCellDef="let claimSummary; index as i" class="nested-column-2"
                id="claims-tracking-result-product-data-{{i}}">{{claimSummary.productPackageBusinessName}}</mat-cell>
    </ng-container>

    <!-- Total Payable Container -->
    <ng-container matColumnDef="totalEstimatedPayableAmountPerClaim">
      <mat-header-cell *matHeaderCellDef id="claims-tracking-result-total-payable-header"
                       class="nested-column-2">Total Payable</mat-header-cell>
      <mat-cell *matCellDef="let claimSummary; index as i" class="nested-column-2"
                id="claims-tracking-result-total-payable-data-{{i}}">${{formatAmount(claimSummary.totalEstimatedPayableAmountPerClaim)}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></mat-row>

  </mat-table>

  <div fxLayout="row" fxLayoutAlign="center center" [hidden]="dataSource == null || dataSource.data.length <= 5">
    <mat-paginator [pageSizeOptions]="[15, 10, 25, 50]" showFirstLastButtons></mat-paginator>
  </div>

  <!-- No Results Found Section -->
  <div *ngIf="(isDataSourceNullOrUndefined() || noResultsFoundWithFilter)" class="no-records"
       fxLayout="row" fxLayoutAlign="center center" id="claims-tracking-no-result">No Results Found</div>

</mat-card>
