
<div fxLayout="row" >
<!-- Provide Feedback -->
  <button mat-flat-button class="print-view-reports-button mat-flat-button mat-primary" (click)="onProvideFeedbackClick()" id="claimTracker-provide-feedback">
    <div fxLayout="row" class="report-button-margin">
      <mat-icon svgIcon="eclaim-icons:feedbackIcon" class="reports-icon feedback-icon"></mat-icon>
      <span class="action-link">Provide Feedback</span>
    </div>
  </button>
  <!-- Reports -->
  <button mat-flat-button class="print-view-reports-button mat-flat-button mat-primary" (click)="onReportsButtonClick()" id="claimTracker-reports">
    <div fxLayout="row" class="report-button-margin">
      <mat-icon svgIcon="eclaim-icons:reports" class="reports-icon"></mat-icon>
      <span>View Doctor Reports</span>
    </div>
  </button>
  <span class="margin-left-10"></span>
  <!-- Print -->
  <button mat-flat-button class="print-view-reports-button mat-flat-button mat-primary" (click)="print()" id="claimTracker-print">
    <div fxLayout="row" class="print-button-margin">
      <mat-icon svgIcon="eclaim-icons:printer" class="printer-icon"></mat-icon>
      <span>Print</span>
    </div>
  </button>
</div>
