import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import {InventoryDataService} from '../../http/http-client-data/inventory-data/inventory-data.service';
import {ServiceLine} from '../../../../../models/serviceLine';
import {Inventories, Inventory} from '../../../../../models/Inventory';
import {isNullOrUndefined, isStringNullUndefinedOrEmpty} from '../../../../utility';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
    private inventoryDataService: InventoryDataService
  ) {}

  /***** START - PUBLIC METHODS *****/

  public SearchForServiceLinesHcpcAndCptCodes(serviceLines: ServiceLine[]): Observable<Inventories> {
    return new Observable<Inventories>((observer: Subscriber<Inventories>) => {
      // Initialize Inventory Objects
      const inventories: Inventories = {} as Inventories;
      const inventoryList: Inventory[] = [];
      let hasLoopFinished: boolean = false;
      // Loop through the Service Lines to see if there is Hcpc Or Cpt codes
      for (let i = 0; i < serviceLines.length; i++) {
        const serviceLineInList: ServiceLine = serviceLines[i];
        // Check to see if there is Hcpc Or Cpt codes
        if (!isStringNullUndefinedOrEmpty(serviceLineInList.cptHcpcsCode)) {
          this.inventoryDataService.getHcpcCptCodeDescription(serviceLineInList.cptHcpcsCode).subscribe((inventory: Inventory) => {
            if (!isNullOrUndefined(inventory)) {
              inventoryList.push(inventory);
            }
          });
        }
        if ((i + 1) === serviceLines.length) {
          hasLoopFinished = true;
        }
      }

      if (hasLoopFinished) {
        // Add Inventory List to the Inventories object
        inventories.inventory = inventoryList;
        observer.next(inventories);
        observer.complete();
      }
    });
  }

  public getHcpcCptCodeDescription(hcpcCptCode: string): Observable<Inventory>  {
    return new Observable<Inventory>((observer: Subscriber<Inventory>) => {
      this.inventoryDataService.getHcpcCptCodeDescription(hcpcCptCode).subscribe((inventory: Inventory) => {
        if (!isNullOrUndefined(inventory)) {
          observer.next(inventory);
        } else {
          observer.next(undefined);
        }
        observer.complete();
      });
    });
  }

  /***** END - PUBLIC METHODS *****/
}
