import {Component, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ClaimSummaries, ClaimSummary} from '../../../models/claimSummary';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ExternalServiceLocationService} from '../../../common/services/data-model/app/external-service-location/external-service-location.service';
import {
  DateUtility, formatNumberWithTwoDecimalPlaces,
  isNullOrUndefined, isStringNullUndefinedOrEmpty, isStringNullUndefinedOrEmptyWithTrim,
  openDialog
} from '../../../common/utility';
import {ClaimSummaryService} from '../../../common/services/data-model/app/claim-summary/claim-summary.service';
import {ClaimDetailsModalComponent} from '../claim-details-modal/claim-details-modal.component';
import {ApplicationConstants, ErrorTypes} from '../../../common/constants/application.constants';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ErrorWrapperConfig} from '../../../common/components/error-wrapper/error-wrapper.component';
import {DatePickerConfiguration} from '../../../common/components/date-picker/date-picker.component';
import {CustomValidatorsService} from '../../../common/services/support/custom-validators/custom-validators.service';

@Component({
  selector: 'app-claims-tracking-claims-tracking-search-results',
  templateUrl: 'claims-tracking-search-results.component.html',
  styleUrls: ['claims-tracking-search-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClaimsTrackingSearchResultsComponent implements OnInit, OnDestroy, OnChanges {

  constructor(
    private externalServiceLocationService: ExternalServiceLocationService,
    private claimSummaryService: ClaimSummaryService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
  ) {
    this.practiceName  = this.externalServiceLocationService.practiceName;
  }

  /***** START - PUBLIC MEMBERS *****/

  @Input() public claimSummaries: ClaimSummaries;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<ClaimSummary>;
  columnsToDisplay: string[] = ['claimNumber', 'patientName', 'dateOfService', 'serviceItemCodes', 'programId', 'totalEstimatedPayableAmountPerClaim'];
  subHeaderTwo: string;
  totalEstimatedPayableAmount: number = undefined;
  practiceName: string;
  noResultsFoundWithFilter: boolean = false;
  isAFilteredResultsSectionButtonClick: boolean = false;
  claimsTrackingSearchResultsForm: FormGroup;
  errorWrapperConfig = {
    patientNameFilter: new ErrorWrapperConfig(),
  };
  datePickerConfigurationDateOfServiceFilter: DatePickerConfiguration;

  public isDataSourceNullOrUndefined(): boolean {
    return (isNullOrUndefined(this.dataSource));
  }

  public displayFilterSection(): boolean {
    return (!this.isDataSourceNullOrUndefined() && !isNullOrUndefined(this.dataSource.data) && (this.dataSource.data.length > 1 || this.isAFilteredResultsSectionButtonClick));
  }

  public formatDateForUi(yyyyMmDdDate: string): string {
    return DateUtility.buildFriendlyDateFromJsDate(yyyyMmDdDate);
  }

  public formatAmount(numberToFormat: number): string {
    return formatNumberWithTwoDecimalPlaces(numberToFormat);
  }

  /***** END - PUBLIC MEMBERS *****/

  /***** START - EVENT HANDLERS *****/

  ngOnInit(): void {
    // Per ECLAIM-64 this was added to build the subheader correctly.
    this.buildSubHeader();
    this.buildForm();
    this.buildDatePickerConfiguration();
    this.buildErrorWrapperConfig();
  }

  ngOnChanges(): void {
    // Reset these values to original values as a just in case.
    this.noResultsFoundWithFilter = false;
    this.isAFilteredResultsSectionButtonClick = false;
    if (!isNullOrUndefined(this.claimSummaries) && !isNullOrUndefined(this.claimSummaries.pricedClaimSummaries)) {
      this.dataSource = new MatTableDataSource(this.claimSummaries.pricedClaimSummaries);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.totalEstimatedPayableAmount = this.claimSummaries.totalEstimatedPayableAmount;
      this.setUiClaimSummaryData(this.claimSummaries);
    } else {
      this.dataSource = undefined;
      this.totalEstimatedPayableAmount = undefined;
    }
  }

  ngOnDestroy(): void {
    this.dataSource = undefined;
  }

  // invoked when Authorization Number is clicked
  onClaimNumberClick(claimSummary: ClaimSummary): void {
    const clickedClaimNumber: string = claimSummary.claimNumber;
    const clickedClaimExtensionNumber: string = claimSummary.claimExtensionNumber;
    const serviceClaimNumber: string = this.claimSummaryService.claimNumber;
    const serviceClaimExtensionNumber: string = this.claimSummaryService.claimExtensionNumber;
    const serviceClaimSummary: ClaimSummary = this.claimSummaryService.claimSummary;
    // Check if user clicked on the same auth. If user clicks on the already retrieved auth,
    // And if that ClaimSummary still exists, then render that auth so we do not have to recall the api again to get it.
    if ((!isStringNullUndefinedOrEmpty(serviceClaimNumber)
      && !isStringNullUndefinedOrEmpty(serviceClaimExtensionNumber)
      && !isNullOrUndefined(serviceClaimSummary))
      && (clickedClaimNumber === serviceClaimNumber
        && clickedClaimExtensionNumber === serviceClaimExtensionNumber)) {
      this.renderClaimDetailsModal(serviceClaimSummary);
    } else {
      this.claimSummaryService.claimNumber = claimSummary.claimNumber;
      this.claimSummaryService.claimExtensionNumber = claimSummary.claimExtensionNumber;
      openDialog('Loading Claim Summary Details', this.dialog);
      this.claimSummaryService.retrieveClaimSummaryDetails(claimSummary.self.href).subscribe((pricedClaimSummaryDetail) => {
        this.dialog.closeAll();
        if (!isNullOrUndefined(pricedClaimSummaryDetail)) {
          this.renderClaimDetailsModal(pricedClaimSummaryDetail);
        }
      }, () => {
        // on observer error
        this.dialog.closeAll();
      });
    }
  }

  renderClaimDetailsModal(claimSummary: ClaimSummary): void {
    this.dialog.open(ClaimDetailsModalComponent, {
      width: '1170px',
      panelClass: 'eclaim-popup-modal',
      disableClose: true,
      data: {
        claimSummary: claimSummary
      }
    });
  }

  setUiClaimSummaryData(claimSummaries: ClaimSummaries) {
    if (!isNullOrUndefined(claimSummaries) && !isNullOrUndefined(claimSummaries.pricedClaimSummaries)) {
      this.claimSummaries = claimSummaries;
      this.totalEstimatedPayableAmount = claimSummaries.totalEstimatedPayableAmount;
    }
  }

  filterResults() {
    this.isAFilteredResultsSectionButtonClick = true;
    this.noResultsFoundWithFilter = false;
    const patientNameFilter: string = this.claimsTrackingSearchResultsForm.controls.patientNameFilter.value;
    const dateOfServiceFilter: string = this.claimsTrackingSearchResultsForm.controls.dateOfServiceFilter.value;
    const claimSummaries: ClaimSummaries = Object.deepClone(this.claimSummaryService.claimSummaries);
    let claimSummaryList: ClaimSummary[] = claimSummaries.pricedClaimSummaries;
    let filteredClaimSummaryList: ClaimSummary[];
    let filteringHasHappened: boolean = false;
    // Filter by Date of Service if filter is present.
    if (!isNullOrUndefined(dateOfServiceFilter) && dateOfServiceFilter !== ApplicationConstants.emptyString) {
      filteredClaimSummaryList = this.filterByDateOfService(claimSummaryList, dateOfServiceFilter);
      filteringHasHappened = true;
    }
    // Filter by Patient Name if filter is present.
    if (!isStringNullUndefinedOrEmptyWithTrim(patientNameFilter)) {
      let filteredClaimSummaryListTwo: ClaimSummary[] = claimSummaryList;
      // If the filter list is not null and empty after the date of service logic executed, then pass that filtered list to get more refined by the patient name filter.
      if (filteringHasHappened) {
        filteredClaimSummaryListTwo = filteredClaimSummaryList;
      }
      filteredClaimSummaryList = this.filterByPatientName(filteredClaimSummaryListTwo, patientNameFilter);
      filteringHasHappened = true;
    }
    // If we have a list of matched Claim Summaries, then rest the original claim summary list.
    if (!isNullOrUndefined(filteredClaimSummaryList) && (filteredClaimSummaryList.length > 0 || filteringHasHappened)) {
      if (filteredClaimSummaryList.length === 0) {
        this.noResultsFoundWithFilter = true;
      }
      claimSummaryList = filteredClaimSummaryList;
      claimSummaries.pricedClaimSummaries = claimSummaryList;
    }
    this.setDataSource(claimSummaries);
  }

  setDataSource(claimSummaries: ClaimSummaries) {
    if (!isNullOrUndefined(claimSummaries) && !isNullOrUndefined(claimSummaries.pricedClaimSummaries)) {
      // Reset the search results with the filtered information
      this.dataSource = new MatTableDataSource(claimSummaries.pricedClaimSummaries);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.totalEstimatedPayableAmount = claimSummaries.totalEstimatedPayableAmount;
      this.setUiClaimSummaryData(claimSummaries);
    } else {
      this.dataSource = undefined;
      this.totalEstimatedPayableAmount = undefined;
    }
  }

  clearFilter() {
    this.isAFilteredResultsSectionButtonClick = true;
    this.noResultsFoundWithFilter = false;
    this.claimsTrackingSearchResultsForm.controls.patientNameFilter.reset(ApplicationConstants.emptyString);
    this.claimsTrackingSearchResultsForm.controls.dateOfServiceFilter.reset(ApplicationConstants.emptyString);
    this.setDataSource(this.claimSummaryService.claimSummaries);
  }

  get hasValidFilterCombination(): boolean {
    let isValidInput: boolean = false;
    const isValidPatientNameFilter = this.isValidFormControlValue(this.claimsTrackingSearchResultsForm.controls.patientNameFilter);
    const isValidDateOfServiceFilter = this.isValidFormControlValue(this.claimsTrackingSearchResultsForm.controls.dateOfServiceFilter);
    const dateOfServiceFilterValue = this.claimsTrackingSearchResultsForm.controls.dateOfServiceFilter.value;
    if (isValidPatientNameFilter) {
      isValidInput = true;
    } else if (!isNullOrUndefined(dateOfServiceFilterValue) && dateOfServiceFilterValue !== ApplicationConstants.emptyString && isValidDateOfServiceFilter) {
      isValidInput = true;
    }
    if ((!isValidPatientNameFilter && this.claimsTrackingSearchResultsForm.controls.patientNameFilter.value !== ApplicationConstants.emptyString)
      && ((!isValidDateOfServiceFilter) && !isNullOrUndefined(dateOfServiceFilterValue) && dateOfServiceFilterValue !== ApplicationConstants.emptyString)) {
      isValidInput = false;
    }
    return isValidInput;
  }

  /***** END - EVENT HANDLERS *****/

  /***** START - PRIVATE FUNCTIONS *****/

  private buildForm(): void {
    this.claimsTrackingSearchResultsForm = this.formBuilder.group({
      patientNameFilter: [ApplicationConstants.emptyString, [Validators.pattern(/^([A-Za-z-]|[A-Za-z-]+([,]+[ ]|[,]|[ ])+[A-Za-z-])+$/), Validators.minLength(2)]],
      dateOfServiceFilterOld: [ApplicationConstants.emptyString],
      dateOfServiceFilter: [DateUtility.buildFriendlyDateFromJsDate(undefined),
        [
          this.customValidatorsService.dateFormatAndValidity,
          this.customValidatorsService.MinDate(DateUtility.buildDateFromDateString(this.claimSummaryService.serviceStartDateFrom)),
          this.customValidatorsService.MaxDate(!DateUtility.isStringDateInFuture(this.claimSummaryService.serviceStartDateTo) ? DateUtility.buildDateFromDateString(this.claimSummaryService.serviceStartDateTo) : DateUtility.buildDateFromDateString(new Date()))
        ]]
    });
    this.claimsTrackingSearchResultsForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe((viewModel) => {
    });
  }

  private buildSubHeader() {
    const claimSummary: ClaimSummary = (
      !isNullOrUndefined(this.claimSummaries)
      && !isNullOrUndefined(this.claimSummaries.pricedClaimSummaries)
      && this.claimSummaries.pricedClaimSummaries.length > 0
      && !isNullOrUndefined(this.claimSummaries.pricedClaimSummaries[0])
      ) ? this.claimSummaries.pricedClaimSummaries[0] : undefined;
    const subHeaderValue: string = (!isNullOrUndefined(claimSummary) && !isStringNullUndefinedOrEmptyWithTrim(claimSummary.statusCodeDescription)) ? claimSummary.statusCodeDescription.toLowerCase() : ApplicationConstants.emptyString;
    this.subHeaderTwo = 'Only claims submitted through eClaim on eyefinity.com and through Eyefinity practice ' +
      'management systems are included in the Claim Tracker. Paper claims are not included and therefore, ' +
      'the total ' + subHeaderValue + ' claims shown may not match what appears on your EOP.';
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      patientNameFilter: {
        control: this.claimsTrackingSearchResultsForm.controls.patientNameFilter,
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: 'Please enter valid Patient Name: Al Doe; Doe, Mary-Jo'
          }, {
            validatorType: ErrorTypes.MinLength,
            errorMessage: 'Patient Name must contain at least two characters'
          },
        ]
      }
    };
  }

  private filterByPatientName(listOfClaimSummaries: ClaimSummary[], patientNameFilter: string): ClaimSummary[] {
    let claimSummaryList: ClaimSummary[] = listOfClaimSummaries;
    let matchedClaimSummaryList: ClaimSummary[];
    // Filter by Patient Name if filter is present.
    if (!isNullOrUndefined(listOfClaimSummaries) && !isStringNullUndefinedOrEmptyWithTrim(patientNameFilter)) {
      // Check to see if Patient Name Filter contains spaces
      if (patientNameFilter.includes(ApplicationConstants.comma) || patientNameFilter.includes(ApplicationConstants.blankSpace)) {
        let splitPatientNameFilterArray: string[];
        if (patientNameFilter.includes(ApplicationConstants.comma)) {
          splitPatientNameFilterArray = patientNameFilter.split(ApplicationConstants.comma);
        } else {
          splitPatientNameFilterArray = patientNameFilter.split(ApplicationConstants.blankSpace);
        }
        matchedClaimSummaryList = this.filterByPatientFirstAndLasNames(claimSummaryList, splitPatientNameFilterArray[0], splitPatientNameFilterArray[1]);
      } else {
        matchedClaimSummaryList = this.filterByPartialPatientName(claimSummaryList, patientNameFilter);
      }
      // If we have a list of matched Claim Summaries, then rest the original claim summary list.
      claimSummaryList = matchedClaimSummaryList;
      return claimSummaryList;
    }
    // Return the Claim summary list if it is present. If not return undefined.
    return !isNullOrUndefined(listOfClaimSummaries) ? listOfClaimSummaries : undefined;
  }

  protected filterByPatientFirstAndLasNames(listOfClaimSummaries: ClaimSummary[], patientFirstOrLastName: string, patientLastOrFirstName: string): ClaimSummary[] {
    if (!isNullOrUndefined(listOfClaimSummaries) && !isStringNullUndefinedOrEmptyWithTrim(patientFirstOrLastName) && !isStringNullUndefinedOrEmptyWithTrim(patientLastOrFirstName)) {
      let claimSummaryList: ClaimSummary[] = listOfClaimSummaries;
      const matchedClaimSummaryList: ClaimSummary[] = [];
      const patientFirstOrLastNameToUpper: string = patientFirstOrLastName.trim().toUpperCase();
      const patientLastOrFirstNameToUpper: string = patientLastOrFirstName.trim().toUpperCase();
      const patientFirstOrLastNameToUpperLength: number = patientFirstOrLastNameToUpper.length;
      const patientLastOrFirstNameToUpperLength: number = patientLastOrFirstNameToUpper.length;
      let loopHappened: boolean = false;

      // Loop through the Claim Summary List to match on the patient name information
      for (const claimSummary of claimSummaryList) {
        const claimSummaryPatientFirstName: string = (!isNullOrUndefined(claimSummary) && !isNullOrUndefined(claimSummary.memberPatientInfo) && !isStringNullUndefinedOrEmptyWithTrim(claimSummary.memberPatientInfo.patientFirstName)) ? claimSummary.memberPatientInfo.patientFirstName.trim().toUpperCase() : undefined;
        const claimSummaryPatientLastName: string = (!isNullOrUndefined(claimSummary) && !isNullOrUndefined(claimSummary.memberPatientInfo) && !isStringNullUndefinedOrEmptyWithTrim(claimSummary.memberPatientInfo.patientLastName)) ? claimSummary.memberPatientInfo.patientLastName.trim().toUpperCase() : undefined;
        // Only go down this route and match the patient First Names and Last Names. If they do not match exactly then move on to the next item on the list.
        if (!isStringNullUndefinedOrEmptyWithTrim(claimSummaryPatientFirstName) && !isStringNullUndefinedOrEmptyWithTrim(claimSummaryPatientLastName)) {
          if (!loopHappened) {
            loopHappened = true;
          }
          // Get truncated Patient First Name and Last Name from Length of passed in First Name and Last Name Filters.
          const truncatedClaimSummaryPatientFirstNameOne: string =  claimSummaryPatientFirstName.substring(0, patientFirstOrLastNameToUpperLength);
          const truncatedClaimSummaryPatientFirstNameTwo: string =  claimSummaryPatientFirstName.substring(0, patientLastOrFirstNameToUpperLength);
          const truncatedClaimSummaryPatientLastNameOne: string =  claimSummaryPatientLastName.substring(0, patientFirstOrLastNameToUpperLength);
          const truncatedClaimSummaryPatientLastNameTwo: string =  claimSummaryPatientLastName.substring(0, patientLastOrFirstNameToUpperLength);
          // Check to see if the first names and last match interchangeably.
          if ((claimSummaryPatientFirstName === patientFirstOrLastNameToUpper && claimSummaryPatientLastName === patientLastOrFirstNameToUpper)
            || (claimSummaryPatientFirstName === patientLastOrFirstNameToUpper && claimSummaryPatientLastName === patientFirstOrLastNameToUpper)
            || ((patientFirstOrLastNameToUpper === truncatedClaimSummaryPatientFirstNameOne
                || patientFirstOrLastNameToUpper === truncatedClaimSummaryPatientFirstNameTwo
                || patientFirstOrLastNameToUpper === truncatedClaimSummaryPatientLastNameOne
                || patientFirstOrLastNameToUpper === truncatedClaimSummaryPatientLastNameTwo)
                   && (patientLastOrFirstNameToUpper === truncatedClaimSummaryPatientFirstNameOne
                     || patientLastOrFirstNameToUpper === truncatedClaimSummaryPatientFirstNameTwo
                     || patientLastOrFirstNameToUpper === truncatedClaimSummaryPatientLastNameOne
                     || patientLastOrFirstNameToUpper === truncatedClaimSummaryPatientLastNameTwo))) {
            matchedClaimSummaryList.push(claimSummary);
          }
        // This else is a just in case if either the First or Last Names are empty or null to try to match on at least one of the two fields. Agin this is a just in case. Should never happen.
        } else {
          if (!isStringNullUndefinedOrEmptyWithTrim(claimSummaryPatientFirstName)) {
            if (!loopHappened) {
              loopHappened = true;
            }
            if (claimSummaryPatientFirstName === patientFirstOrLastNameToUpper || claimSummaryPatientFirstName === patientLastOrFirstNameToUpper) {
              matchedClaimSummaryList.push(claimSummary);
            }
          } else if (!isStringNullUndefinedOrEmptyWithTrim(claimSummaryPatientLastName)) {
            if (!loopHappened) {
              loopHappened = true;
            }
            if (claimSummaryPatientLastName === patientFirstOrLastNameToUpper || claimSummaryPatientLastName === patientLastOrFirstNameToUpper) {
              matchedClaimSummaryList.push(claimSummary);
            }
          }
        }
      }
      // If we have a list of matched Claim Summaries, then rest the original claim summary list.
      if (!isNullOrUndefined(matchedClaimSummaryList) && (matchedClaimSummaryList.length > 0 || loopHappened)) {
        claimSummaryList = matchedClaimSummaryList;
      }
      return claimSummaryList;
    }
    // Return the Claim summary list if it is present. If not return undefined.
    return !isNullOrUndefined(listOfClaimSummaries) ? listOfClaimSummaries : undefined;
  }

  protected filterByPartialPatientName(listOfClaimSummaries: ClaimSummary[], patientNameFilter: string): ClaimSummary[] {
    if (!isNullOrUndefined(listOfClaimSummaries) && !isStringNullUndefinedOrEmptyWithTrim(patientNameFilter)) {
      let claimSummaryList: ClaimSummary[] = listOfClaimSummaries;
      const matchedClaimSummaryList: ClaimSummary[] = [];
      const patientNameFilterToUpper = patientNameFilter.trim().toUpperCase();
      const patientNameFilterToUpperSize: number = patientNameFilterToUpper.length;
      let loopHappened: boolean = false;
      // Loop through the Claim Summary List to match on the patient name information
      for (const claimSummary of claimSummaryList) {
        const truncatedClaimSummaryPatientFirstName: string = (!isNullOrUndefined(claimSummary) && !isNullOrUndefined(claimSummary.memberPatientInfo) && !isStringNullUndefinedOrEmptyWithTrim(claimSummary.memberPatientInfo.patientFirstName)) ? claimSummary.memberPatientInfo.patientFirstName.trim().toUpperCase().substring(0, patientNameFilterToUpperSize) : undefined;
        const truncatedClaimSummaryPatientLastName: string = (!isNullOrUndefined(claimSummary) && !isNullOrUndefined(claimSummary.memberPatientInfo) && !isStringNullUndefinedOrEmptyWithTrim(claimSummary.memberPatientInfo.patientLastName)) ? claimSummary.memberPatientInfo.patientLastName.trim().toUpperCase().substring(0, patientNameFilterToUpperSize) : undefined;
        // if the truncated claim summary first name is not null, undefined or empty, then try to match the patient search filter.
        if (!isStringNullUndefinedOrEmptyWithTrim(truncatedClaimSummaryPatientFirstName)) {
          if (!loopHappened) {
            loopHappened = true;
          }
          if (truncatedClaimSummaryPatientFirstName === patientNameFilterToUpper) {
            matchedClaimSummaryList.push(claimSummary);
            // We want to not move on to the rest of the logic if we have a match.
            continue;
          }
        }
        // if the truncated claim summary last name is not null, undefined or empty and the above match did not match anything, then try to match the patient search filter.
        if (!isStringNullUndefinedOrEmptyWithTrim(truncatedClaimSummaryPatientLastName)) {
          if (!loopHappened) {
            loopHappened = true;
          }
          if (truncatedClaimSummaryPatientLastName === patientNameFilterToUpper) {
            matchedClaimSummaryList.push(claimSummary);
          }
        }
      }
      // If we have a list of matched Claim Summaries, then rest the original claim summary list.
      if (!isNullOrUndefined(matchedClaimSummaryList) && (matchedClaimSummaryList.length > 0 || loopHappened)) {
        claimSummaryList = matchedClaimSummaryList;
      }
      return claimSummaryList;
    }
    // Return the Claim summary list if it is present. If not return undefined.
    return !isNullOrUndefined(listOfClaimSummaries) ? listOfClaimSummaries : undefined;
  }

  protected filterByDateOfService(listOfClaimSummaries: ClaimSummary[], dateOfServiceFilter: string) {
    if (!isNullOrUndefined(listOfClaimSummaries) && !isNullOrUndefined(dateOfServiceFilter)) {
      let claimSummaryList: ClaimSummary[] = listOfClaimSummaries;
      const matchedClaimSummaryList: ClaimSummary[] = [];
      let loopHappened: boolean = false;
      // Loop through the Claim Summary List to match on the patient name information
      for (const claimSummary of claimSummaryList) {
        const claimSummaryDateOfService: string = (!isNullOrUndefined(claimSummary) && !isStringNullUndefinedOrEmptyWithTrim(claimSummary.dateOfService)) ? claimSummary.dateOfService.trim() : undefined;
        if (!isStringNullUndefinedOrEmptyWithTrim(claimSummaryDateOfService)) {
          if (!loopHappened) {
            loopHappened = true;
          }
          // Check to see if the Date of Service Filter Matches the Date of Service on the claim summary
          if (claimSummaryDateOfService === DateUtility.buildYyyyMmDdDateFromDate(dateOfServiceFilter.trim())) {
            matchedClaimSummaryList.push(claimSummary);
          }
        }
      }
      // If we have a list of matched Claim Summaries, then rest the original claim summary list.
      if (!isNullOrUndefined(matchedClaimSummaryList) && (matchedClaimSummaryList.length > 0 || loopHappened)) {
        claimSummaryList = matchedClaimSummaryList;
      }
      return claimSummaryList;
    }
    // Return the Claim summary list if it is present. If not return undefined.
    return !isNullOrUndefined(listOfClaimSummaries) ? listOfClaimSummaries : undefined;
  }

  private isValidFormControlValue = (formControl: AbstractControl) => (formControl.value && formControl.valid);

  private buildDatePickerConfiguration(): void {
    this.datePickerConfigurationDateOfServiceFilter = {
      control: this.claimsTrackingSearchResultsForm.controls.dateOfServiceFilter,
      minDate: DateUtility.buildDateFromDateString(this.claimSummaryService.serviceStartDateFrom),
      maxDate: (!DateUtility.isStringDateInFuture(this.claimSummaryService.serviceStartDateTo) ? DateUtility.buildDateFromDateString(this.claimSummaryService.serviceStartDateTo) : DateUtility.buildDateFromDateString(DateUtility.buildYyyyMmDdDateFromDate(new Date()))),
      controlName: 'dateOfServiceFilter',
      errorWrapperId: {
        defaultValidations: 'claims-tracking-search-results-date-of-service-filter-error',
        minDate: 'claims-tracking-search-results-date-of-service-filter-min-date-error',
        maxDate: 'claims-tracking-search-results-date-of-service-filter-max-date-error'
      },
      attributes: {
        id: 'claims-tracking-search-results-date-of-service-filter',
        datePickerId: 'claims-tracking-search-results-date-of-service-filter-picker',
        datePickerToggleId: 'claims-tracking-search-results-date-of-service-filter-picker-toggle',
        name: 'dateOfServiceFilter'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Date of Service filter must be in MM/DD/YYYY format.'
        },
        {
          validatorType: ErrorTypes.MinDate,
          errorMessage: `Date of Service filter must be after or equal to ${DateUtility.buildFriendlyDateFromJsDate(this.claimSummaryService.serviceStartDateFrom)}.`
        },
        {
          validatorType: ErrorTypes.MaxDate,
          errorMessage: `Date of Service filter must be before or equal to ${!DateUtility.isStringDateInFuture(this.claimSummaryService.serviceStartDateTo) ? DateUtility.buildFriendlyDateFromJsDate(this.claimSummaryService.serviceStartDateTo) : DateUtility.buildYyyyMmDdDateFromDate(new Date())}.`
        },
        {
          validatorType: ErrorTypes.NoFutureDate,
          errorMessage: 'Date of Service filter cannot be in the future.'
        }
      ]
    };
  }

  /***** END - PRIVATE FUNCTIONS *****/
}
