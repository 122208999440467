import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from '../utility';


  /*  Parameters:
      - 'value' is a string that holds numbers, decimal (optional), and or +/- sign.
      - paddingLeft: how many places to the left should be filled in with zeroes, if they're empty (i.e., parameter value of 3 turns 1 to 001).
      - paddingRight:  same as left, but on the right. (parameter value of 3 turns 1.2 into 1.200)
      - positiveSign: If true, will insert a + sign to the left of the first digit of a positive number in 'value' if it's not already there.
      - maximum length:  This was implemented to keep the pipe from increasing the length of the string beyond the max length allowed in the textbox,
                          causing an additional error.  The string, if longer than the max allowed, is truncated at the max lenght.  This usually can
                          only happen if the user puts in a value that is higher than the max value allowed.
  */


@Pipe({
    name: 'signedPaddedZeroes'
})
export class SignedPaddedZeroesPipe implements PipeTransform {
    transform(value: string | number, paddingLeft: number, paddingRight: number, positiveSign: boolean = true, maxLength: number = -1): string {
        if (isNullOrUndefined(value)) { return ''; }
        value = value.toString();
        if (isNaN(Number.parseFloat(value))) { return value; }
        value = Number.parseFloat(value).toString();
        let transformedValue = '';
        if (value) {
            const sign = ((Number.parseFloat(value) > 0 && positiveSign) ? '+' : (Number.parseFloat(value) < 0 ? '-' : ''));
            const valParts = value.toString().replace(/[+-]/g, '').split('.');
            const leftPart = valParts[0] ? valParts[0] : '';
            const rightPart = valParts[1] ? valParts[1] : '';
            const leftPad = paddingLeft > leftPart.length ? ('0'.repeat(paddingLeft - leftPart.length)) : '';
            const rightPad = paddingRight > rightPart.length ? ('0'.repeat(paddingRight - rightPart.length)) : '';
            transformedValue = `${sign}${leftPad}${leftPart}.${rightPart}${rightPad}`;
            if (maxLength > -1) {
                transformedValue = transformedValue.substr(0, maxLength);
            }
        }

        return transformedValue;
    }
}
