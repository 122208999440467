import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, take} from 'rxjs/operators';
import {ClaimFormItem} from '../../../common/classes/claim-form-item';
import {ComponentMaskComponent} from '../../../common/components/component-mask/component-mask.component';
import {ErrorWrapperConfig} from '../../../common/components/error-wrapper/error-wrapper.component';
import {ApplicationConstants, ErrorTypes} from '../../../common/constants/application.constants';
import {ClaimsService} from '../../../common/services/data-model/app/claims/claims.service';
import {SupplierWebService} from '../../../common/services/data-model/app/supplier-web/supplier-web.service';
import {InputMaskService} from '../../../common/services/support/input-mask/input-mask.service';
import {delay, isNullOrUndefined, isStringNullUndefinedOrEmpty} from '../../../common/utility';
import {Claim, SoftAndHardValidationMessages, ValidationMessage} from '../../../models/claim';
import {ClaimCardsToUpdate} from '../../../models/claimCardsToUpdate';
import {FrameDataModel, Manufacturer, Suppliers} from '../../../models/frame';
import {
  LabCategory,
  LabOrderInformation,
  PatientEncounterLab
} from '../../../models/labOrderInformation';
import {ARCoatingCategory, SpectacleLens} from '../../../models/lens';
import {LabOrderLabServices} from '../../../models/labOrderLabServices';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';
import {UserSessionService} from '../../../common/services/support/user-session/user-session.service';
import {FulfillmentService} from '../../../common/services/data-model/app/fulfillment/fulfillment.service';

@Component({
  selector: 'app-lab',
  templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LabComponent extends ClaimFormItem implements OnInit, AfterViewInit, OnDestroy {

  /***** START - PRIVATE MEMBERS *****/
    // DATA MODEL
  private activeClaim: Claim;
  private originalClaim: Claim;
  private observableSubscriptions: Subscription[] = [];
  private _hardEditMessages: ValidationMessage[];
  private _softEditMessages: ValidationMessage[];
  private softAndHardEdits: SoftAndHardValidationMessages;
  private _labCategories: LabCategory[];
  // This variable is used to make sure the disableLabAndSelectLabFields() function
  // does not trigger when the Lens Card first initializes

  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;
  @ViewChild('labNumberControl', {read: ElementRef, static: true}) labNumberControl: ElementRef;

  /***** END - PRIVATE MEMBERS *****/


  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public inputMaskService: InputMaskService,
    private claimService: ClaimsService,
    private supplierWebService: SupplierWebService,
    private changeDetectorRef: ChangeDetectorRef,
    private claimEditService: ClaimEditService,
    private userSessionService: UserSessionService,
    private fulfillmentService: FulfillmentService
  ) {
    super(injector);
  }


  /***** START - PUBLIC MEMBERS *****/
  id = 'lab-section';
  title = 'Lab';
  labForm: FormGroup;
  labOptions: PatientEncounterLab[];
  loadingSelectLab = false;
  viewFreeShippingList = false;

  // Form state/data variables
  errorWrapperConfig = {
    lab: new ErrorWrapperConfig(),
  };
  claimHasEdits: boolean = false;
  claimHasWarnings: boolean = false;

  /***** END - PUBLIC MEMBERS *****/


  /***** START - PRIVATE FUNCTIONS *****/

  private buildForm(): void {
    this.labForm = this.formBuilder.group({
      selectLab: [
        {
          value: null,
          disabled: this.isShowLabsDisabled
        }
      ],
      lab: [
        {
          value: isStringNullUndefinedOrEmpty(this.activeClaim.labOrderInformation.labNumber) ? null : this.activeClaim.labOrderInformation.labNumber,
          disabled: this.isShowLabsDisabled
        }, Validators.required
      ],
    });
    this.observableSubscriptions.push(this.labForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe((viewModel) => {
      // Update the data model based on the form data
      this.updateDataModelFromViewModel();
    }));
  }

  private updateDataModelFromViewModel(): void {
    // Update the active claim in the claim service
    if (this.activeClaim && this.activeClaim.labOrderInformation) {
      this.activeClaim.labOrderInformation.labNumber = this.labForm.get('lab').value;
    }
    if (this.labCardHasChanged()) {
      this.claimService.setActiveClaim(this.activeClaim, this.id);
    }
  }

  private labCardHasChanged(): boolean {
    if (isNullOrUndefined(this.activeClaim.labOrderInformation)) {
      this.activeClaim.labOrderInformation = {} as LabOrderInformation;
    }
    const labNumberFromCard = this.activeClaim.labOrderInformation.labNumber;

    const activeClaimFromService = this.claimService.getActiveClaim();
    // Instantiate claim objects if they don't exist
    if (isNullOrUndefined(activeClaimFromService.labOrderInformation)) {
      activeClaimFromService.labOrderInformation = {} as LabOrderInformation;
    }
    const labNumberFromService = activeClaimFromService.labOrderInformation.labNumber;

    return (labNumberFromCard || undefined) !== (labNumberFromService || undefined);
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      lab: {
        control: this.labForm.controls.lab,
        errors: [{
          validatorType: ErrorTypes.Required,
          errorMessage: 'A lab must be selected'
        }, {
          validatorType: ErrorTypes.Pattern,
          errorMessage: 'Lab must be 1 to 4 digits long'
        }]
      }
    };
  }

  private buildInputMasks(): void {
    this.inputMaskService.createInputMask(this.labNumberControl.nativeElement, this.inputMaskService.labAlias, {placeholder: ''});
  }

  disableLabAndSelectLabFields(): void {
    this.labForm.controls.selectLab.reset();
    this.labForm.controls.lab.reset();
    if (this.isShowLabsDisabled) {
      this.labForm.controls.lab.disable();
      this.labForm.controls.selectLab.disable();
    } else {
      this.labForm.controls.lab.enable();
      this.labForm.controls.selectLab.enable();
    }
  }

  get isShowLabsDisabled(): boolean {
    this.prepareActiveClaim();
    return (isStringNullUndefinedOrEmpty(this.activeClaim.labOrderInformation.lens.externalId) &&
      (this.activeClaim.labOrderInformation.frameSupplier !== Suppliers.LAB ||
        isNullOrUndefined(this.activeClaim.labOrderInformation.frame.manufacturer) ||
        isNullOrUndefined(this.activeClaim.labOrderInformation.frame.manufacturer.name)));
  }

  get softEditMessages(): ValidationMessage[] {
    return this._softEditMessages;
  }

  set softEditMessages(softEditMessages: ValidationMessage[]) {
    this._softEditMessages = softEditMessages;
  }

  get hardEditMessages(): ValidationMessage[] {
    return this._hardEditMessages;
  }

  set hardEditMessages(hardEditMessages: ValidationMessage[]) {
    this._hardEditMessages = hardEditMessages;
  }
  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  compareUILabIdWithPELabId(): void {
    const labId = this.labForm.get('lab').value;
    const formattedLabId = this.zeroFill(labId, 4);
    if (!isStringNullUndefinedOrEmpty(labId) && !isStringNullUndefinedOrEmpty(this.activeClaim.labOrderInformation.labNumber)
      && labId !== this.activeClaim.labOrderInformation.labNumber) {
      this.labForm.get('lab').setValue(this.activeClaim.labOrderInformation.labNumber);
      this.labForm.get('selectLab').setValue(this.activeClaim.labOrderInformation.labNumber);
    } else if (isStringNullUndefinedOrEmpty(labId)) {
      this.labForm.get('lab').setValue(null);
      this.labForm.get('selectLab').setValue(null);
    } else if (isStringNullUndefinedOrEmpty(this.activeClaim.labOrderInformation.labNumber)) {
      this.labForm.get('lab').setValue(formattedLabId);
      this.labForm.get('selectLab').setValue(formattedLabId);
    }
    this.updateDataModelFromViewModel();
  }

  /***** END - PUBLIC FUNCTIONS *****/

  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.originalClaim = this.claimService.getOriginalClaim();
    this.activeClaim = this.claimService.getActiveClaim();

    this.registerWithClaimProgressService();
    this.buildForm();
    this.buildErrorWrapperConfig();
    this.onInitRetrieveLabList();

    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      if (mask) {
        this.disableFormGroupComponents(this.labForm);
        this.componentMask.show();
      } else {
        this.labForm.enable();
        this.componentMask.hide();
        if (this.isShowLabsDisabled) {
          this.labForm.controls.selectLab.disable();
          this.labForm.controls.lab.disable();
        }
        this.changeDetectorRef.detectChanges();
      }
    }));

    this.observableSubscriptions.push(this.claimService.onCardsToUpdate.subscribe((onCardsToUpdate: ClaimCardsToUpdate) => {
      this.prepareActiveClaim();
      const oldActiveClaim: Claim = this.activeClaim;
      this.activeClaim = this.claimService.getActiveClaim();

      // Set form data if the data exists
      if (onCardsToUpdate.lab) {
        if (this.isShowLabsDisabled) {
          this.disableLabAndSelectLabFields();
        } else if ((this.activeClaim.labOrderInformation.frameSupplier === Suppliers.LAB && this.activeClaim.labOrderInformation.frame) ||
          this.activeClaim.labOrderInformation.lens.externalId !== oldActiveClaim.labOrderInformation.lens.externalId ||
          this.activeClaim.labOrderInformation.lensAntiReflectiveCoating.labServiceId !== oldActiveClaim.labOrderInformation.lensAntiReflectiveCoating.labServiceId) {
          this.retrieveLabList(this.createLabRoutingRequest());
        }
        this.changeDetectorRef.detectChanges();
      }
    }));

    this.observableSubscriptions.push(this.fulfillmentService.onUpdateLabList.subscribe((onUpdateLabList: boolean) => {
      if (onUpdateLabList) {
        // Needed to a delay here to avoid the debounce time we have on all the components.
        delay(ApplicationConstants.oneThousand).then(() => {
          this.retrieveLabList(this.createLabRoutingRequest());
          this.fulfillmentService.onUpdateLabList.next(false);
        });
      }
    }));

    this.observableSubscriptions.push(this.fulfillmentService.onUpdateLabListToRemoveCategories.subscribe((onUpdateLabListRemoveCategories: boolean) => {
      if (onUpdateLabListRemoveCategories) {
        // Needed to a delay here to avoid the debounce time we have on all the components.
        delay(ApplicationConstants.oneThousand).then(() => {
          // This was added for ECLAIM-68 to get around the categories set in the lab component when a practice participates in KScope we set this flag to false when the user overrides the supplier drop down.
          // We will be setting it back to true once that is all said and done since we want to submit at the end in the retrieve labs function.
          this.fulfillmentService.participatesInKScope = false;
          this.retrieveLabList(this.createLabRoutingRequest(), true);
          this.fulfillmentService.onUpdateLabListToRemoveCategories.next(false);
        });
      }
    }));

    this.observableSubscriptions.push(this.viewStateService.onCalculateFailed.subscribe((failed: boolean) => {
        if (failed) {
          this.compareUILabIdWithPELabId();
        }
      }
    ));

    this.observableSubscriptions.push(this.viewStateService.onSubmitFailed.subscribe((failed: boolean) => {
        if (failed) {
          this.compareUILabIdWithPELabId();
        }
      }
    ));

    // Edits Banner
    this.observableSubscriptions.push(this.viewStateService.onHasEdits.subscribe((hasEdits: boolean) => {
      if (hasEdits) {
        this.softAndHardEdits = this.claimEditService.getSoftAndHardEdits();
        if (this.softAndHardEdits) {
          this.hardEditMessages = this.softAndHardEdits.hardEditMessages;
          this.softEditMessages = this.softAndHardEdits.unacknowledgedSoftEdits;
        }
        // Error or Warning
        if ((this.hardEditMessages && this.hardEditMessages.length > 0) ||
          (this._softEditMessages && this.softEditMessages.length > 0)) {
          this.claimHasEdits = hasEdits;
        }
        // Error & Warning
        if (this.hardEditMessages && this.hardEditMessages.length > 0 &&
          this._softEditMessages && this.softEditMessages.length > 0) {
          this.claimHasWarnings = hasEdits;
        }
      } else {
        this.claimHasEdits = false;
        this.claimHasWarnings = false;
      }
    }));
  }

  ngAfterViewInit(): void {
    this.buildInputMasks();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  labFieldBlur() {
    const formattedLabId = this.zeroFill(this.labForm.get('lab').value, 4);
    this.labForm.get('lab').setValue(formattedLabId);
    this.labForm.get('selectLab').setValue(formattedLabId);
  }

  retrieveLabList(labRoutingRequest: Claim, removeLabListCategories: boolean = false): void {
    this.loadingSelectLab = true;
    this.labForm.get('selectLab').disable();
    this.supplierWebService.retrieveLabListFromCurrentClaim(labRoutingRequest)
      .pipe(take(1))
      .subscribe((labList: PatientEncounterLab[]) => {
          this.labOptions = labList;
          this.loadKScopeLabs();
          // This was added for ECLAIM-68. This flag is to make sure we do not set the categories for KScope when the user overrides the supplier dropdown.
          if (removeLabListCategories) {
            // To get around the categories set in the lab component when a practice participates in KScope we set this flag to false when the user overrides the supplier drop down.
            // This is setting it back to true once that is all said and done since we want to submit at the end.
            this.fulfillmentService.participatesInKScope = true;
          }
          this.changeDetectorRef.detectChanges();
        },
        (err) => {

        },
        () => {
          this.disableLabAndSelectLabFields();
          this.loadingSelectLab = false;
        });
  }

  loadKScopeLabs() {
    if (this.userSessionService.isKaleyedoscopePractice && this.fulfillmentService.participatesInKScope) {
      this.labCategories = this.getSelectLabView();
    } else {
      this.viewFreeShippingList = false;
    }
  }

  onInitRetrieveLabList(): void {
    this.labForm.get('selectLab').disable();
    if (!this.isShowLabsDisabled) {
      this.loadingSelectLab = true;
      this.supplierWebService.retrieveLabListFromCurrentClaim(this.createLabRoutingRequest())
        .pipe(take(1))
        .subscribe((labList: PatientEncounterLab[]) => {
            this.labOptions = labList;
            this.loadKScopeLabs();
            this.changeDetectorRef.detectChanges();
          },
          (err) => {

          },
          () => {
            if (this.labForm.enabled) {
              this.labForm.controls.lab.enable();
              this.labForm.controls.selectLab.enable();
            }
            this.labForm.get('selectLab').setValue(this.labForm.get('lab').value);
            this.loadingSelectLab = false;
          });
    }
  }

  onSelectLabClick(): void {
    const labId = this.labForm.get('selectLab').value;
    this.labForm.get('lab').setValue(labId);
  }

  /***** END - EVENT HANDLERS *****/

  zeroFill(str: string, width: number): string {
    if (isStringNullUndefinedOrEmpty(str)) {
      return str;
    }

    width -= str.length;
    if (str.length === 0) {
      return '';
    }
    if (width > 0) {
      return new Array(width + (/\./.test(str) ? 2 : 1)).join('0') + str;
    }
    return str;
  }

  prepareActiveClaim(): void {
    if (isNullOrUndefined(this.activeClaim)) {
      this.activeClaim = {} as Claim;
      this.activeClaim.labOrderInformation = {} as LabOrderInformation;
      this.activeClaim.labOrderInformation.lens = {} as SpectacleLens;
      this.activeClaim.labOrderInformation.lensAntiReflectiveCoating = {} as LabOrderLabServices;
      this.activeClaim.labOrderInformation.frame = {} as FrameDataModel;
    } else if (isNullOrUndefined(this.activeClaim.labOrderInformation)) {
      this.activeClaim.labOrderInformation = {} as LabOrderInformation;
      this.activeClaim.labOrderInformation.lens = {} as SpectacleLens;
      this.activeClaim.labOrderInformation.lensAntiReflectiveCoating = {} as LabOrderLabServices;
      this.activeClaim.labOrderInformation.frame = {} as FrameDataModel;
      this.activeClaim.labOrderInformation.frame.manufacturer = {} as Manufacturer;
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lens)) {
      this.activeClaim.labOrderInformation.lens = {} as SpectacleLens;
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.frame)) {
      this.activeClaim.labOrderInformation.frame = {} as FrameDataModel;
      this.activeClaim.labOrderInformation.frame.manufacturer = {} as Manufacturer;
    } else if (isNullOrUndefined(this.activeClaim.labOrderInformation.frame.manufacturer)) {
      this.activeClaim.labOrderInformation.frame.manufacturer = {} as Manufacturer;
    }

    if (isNullOrUndefined(this.activeClaim.labOrderInformation.lensAntiReflectiveCoating)) {
      this.activeClaim.labOrderInformation.lensAntiReflectiveCoating = {} as LabOrderLabServices;
    }
  }

  private createLabRoutingRequest(): Claim {
    const labRoutingRequest = {} as Claim;
    labRoutingRequest.labOrderInformation = {} as LabOrderInformation;
    labRoutingRequest.labOrderInformation.lens = {} as SpectacleLens;
    labRoutingRequest.labOrderInformation.lensAntiReflectiveCoating = {} as LabOrderLabServices;
    labRoutingRequest.labOrderInformation.lens.externalId = this.activeClaim.labOrderInformation.lens.externalId;
    labRoutingRequest.labOrderInformation.lensAntiReflectiveCoating.labServiceId = this.activeClaim.labOrderInformation.lensAntiReflectiveCoating.labServiceId;
    labRoutingRequest.trackingNumber = this.activeClaim.trackingNumber;
    labRoutingRequest.dateOfService = this.activeClaim.dateOfService;
    labRoutingRequest.labOrderInformation.lensFinishing = this.activeClaim.labOrderInformation.lensFinishing;
    if ( this.activeClaim.labOrderInformation.frame != null ) {
      labRoutingRequest.labOrderInformation.frame = this.activeClaim.labOrderInformation.frame;
    }
    return labRoutingRequest;
  }

  getSelectLabView(): LabCategory[] {
    const labCategories: LabCategory[] = [];

    for (const labOption of this.labOptions) {
      let labCategoryNotFound = true;
      let labCategoryItem = ApplicationConstants.labCategoryForPreferredItem;

      if (labOption.labDescription != null && labOption.labDescription.toUpperCase().indexOf(ApplicationConstants.freeShipping) >= 0 ) {
        labCategoryItem = ApplicationConstants.labCategoryForShipsFreeItem;
        this.viewFreeShippingList = true;
      }

      for (const labCategory of labCategories ) {
        if ( labCategory.labCategoryLabel === labCategoryItem ) {
          labCategory.lab.push(labOption);
          labCategoryNotFound = false;
        }
      }

      if ( labCategoryNotFound ) {
        const newLabCategory: LabCategory = {
          labCategoryLabel: undefined,
          lab: []
        };
        newLabCategory.labCategoryLabel = labCategoryItem;
        newLabCategory.lab.push(labOption);
        labCategories.push(newLabCategory);
      }
    }

    labCategories.sort( function(a: LabCategory, b: LabCategory) {
      if (a.labCategoryLabel < b.labCategoryLabel) { return 1; }
      if (a.labCategoryLabel > b.labCategoryLabel) { return -1; }
      return 0;
    });
    return labCategories;
  }

  get labCategories(): LabCategory[] {
    return this._labCategories;
  }

  set labCategories(items: LabCategory[]) {
    this._labCategories = items;
  }
}
