<app-component-mask></app-component-mask>

<!-- Show/Hide CMS Numbers -->
<div cms-number-toggle id="insured-cmsNumberToggle"></div>
<div id="{{id}}">
  <mat-card class="container-white-full-column">
    <!-- ############################################################################################################################################################################## -->
    <!-- ############################################################################################################################################################################## -->
    <!-- Insured Card Main Header -->
    <!-- ############################################################################################################################################################################## -->
    <!-- ############################################################################################################################################################################## -->
    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings" class="sticky-card-header" [ngClass]="{ 'disabled-card-header': insuredForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="insured-title">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits" [ngClass]="{ 'disabled-card-header': insuredForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="insured-title-edits">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning" [ngClass]="{ 'disabled-card-header': insuredForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="insured-title-warning">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <!-- Insured Form -->
      <form [formGroup]="insuredForm" novalidate fxLayout="column">
        <!-- ############################################################################################################################################################################## -->
        <!-- First Row In The Insured Card: "Field Labels" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-top-30 margin-bottom-15">
          <!-- Members First Name Label -->
          <label id="insured-first-name-label" class="nested-column-3"><span class="required-label-asterisk">* </span>4. First Name</label>
          <!-- Members Middle Name Label -->
          <label id="insured-middle-initial-label" class="nested-column-1">MI</label>
          <!-- Members Last Name Label -->
          <label id="insured-last-name-label" class="nested-column-3"><span class="required-label-asterisk">* </span>Last Name</label>
          <!-- Insured's Policy Group or FECA Number Label -->
          <label id="insured-policy-feca-label" class="nested-column-5">11. Insured's Policy Group or FECA Number</label>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Second Row In The Insured Card: "Field Inputs" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row">
          <!-- Members First Name Field -->
          <div fxLayout="column" class="nested-column-3">
            <mat-form-field appearance="outline">
              <input matInput formControlName="insuredFirstName" maxlength="20" id="insured-first-name">
            </mat-form-field>
            <app-error-wrapper [config]="errorWrapperConfig.insuredFirstName"></app-error-wrapper>
          </div>
          <!-- Members Middle Name Field -->
          <div fxLayout="column" class="nested-column-1">
            <mat-form-field appearance="outline">
              <input matInput formControlName="insuredMiddleInitial" maxlength="1" id="insured-middle-initial">
            </mat-form-field>
            <app-error-wrapper [config]="errorWrapperConfig.insuredMiddleInitial"></app-error-wrapper>
          </div>
          <!-- Members Last Name Field -->
          <div fxLayout="column" class="nested-column-3">
            <mat-form-field appearance="outline">
              <input matInput formControlName="insuredLastName" maxlength="30" id="insured-last-name">
            </mat-form-field>
            <app-error-wrapper [config]="errorWrapperConfig.insuredLastName"></app-error-wrapper>
          </div>
          <!-- Insured's Policy Group or FECA Number Field -->
          <div fxLayout="column" class="nested-column-5">
            <mat-form-field appearance="outline" fxFill>
              <input matInput formControlName="memberPolicyGroupNumber" id="insured-policy-feca-input" maxlength="30">
            </mat-form-field>
            <app-error-wrapper id="insured-policy-feca-error"
                               [config]="errorWrapperConfig.memberPolicyGroupNumber"></app-error-wrapper>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Third Row In The Insured Card: "Field Labels" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-top-30 margin-bottom-15">
          <!-- Address 1 Label -->
          <label id="insured-address1-label" class="nested-column-7">Insured Address 1</label>
          <!-- Date of Birth Label -->
          <label id="insured-dob-label" class="nested-column-5"><span *ngIf="showRequiredDateOfBirthAsterisk" class="required-label-asterisk">* </span>11a. Date of Birth</label>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Fourth Row In The Insured Card: "Field Inputs" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row">
          <!-- Address 1 Field -->
          <div fxLayout="column" class="nested-column-7">
            <mat-form-field appearance="outline">
              <input matInput id="insured-address1" formControlName="memberAddressStreet1"
                     [maxLength]="insuredAddressMaxLength">
            </mat-form-field>
            <app-error-wrapper id="insured-address1-error"
                               [config]="errorWrapperConfig.memberAddressStreet1"></app-error-wrapper>
          </div>
          <!-- Date of Birth Field -->
          <app-date-picker class="nested-column-5" id="insured-date-picker"
                           [datePickerConfiguration]="datePickerConfigurationDateOfBirth"></app-date-picker>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Fifth Row In The Insured Card: "Field Labels" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-top-30 margin-bottom-15">
          <!-- Address 2 Label -->
          <label id="insured-address2-label" class="nested-column-7">Insured Address 2</label>
          <!-- Sex Label -->
          <label id="insured-sex-label" class="nested-column-5">Sex</label>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Sixth Row In The Insured Card: "Field Input and Radio Buttons" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row">
          <!-- Address 2 Field -->
          <div fxLayout="column" class="nested-column-7">
            <mat-form-field appearance="outline" fxFill>
              <input matInput id="insured-address2" formControlName="memberAddressStreet2"
                     [maxLength]="insuredAddressMaxLength">
            </mat-form-field>
            <app-error-wrapper id="insured-address2-error"
                               [config]="errorWrapperConfig.memberAddressStreet2"></app-error-wrapper>
          </div>
          <!-- Sex Field -->
          <div fxLayout="column" class="nested-column-5">
            <mat-radio-group fxLayout="row" fxLayoutAlign="start center" id="insured-sex-group" formControlName="memberGender">
              <mat-radio-button #insuredSexMale id="insured-sex-male-toggle"
                                value="{{formState.Male}}" (keyup.enter)="onKeypressEventRadiobutton(insuredSexMale);
                                updateInsuredGenderOnActiveClaim(formState.Male)">Male</mat-radio-button>
              <mat-radio-button #insuredSexFemale id="insured-sex-female-toggle" value="{{formState.Female}}"
                                fxFlexOffset="30px" (keyup.enter)="onKeypressEventRadiobutton(insuredSexFemale);
                                updateInsuredGenderOnActiveClaim(formState.Female)">Female</mat-radio-button>
            </mat-radio-group>
            <app-error-wrapper id="insured-sex-error" [config]="errorWrapperConfig.memberGender"></app-error-wrapper>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Seventh Row In The Insured Card: "Field Labels, Inputs and dropdown's" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-top-30">
          <!-- City Section -->
          <div fxLayout="column" class="nested-column-3">
            <label id="insured-city-label">City</label>
            <mat-form-field appearance="outline" fxFlexOffset="15px">
              <input matInput formControlName="memberAddressCity" [maxLength]="insuredCityMaxLength"
                     id="insured-city-input">
            </mat-form-field>
            <app-error-wrapper id="insured-city-error"
                               [config]="errorWrapperConfig.memberAddressCity"></app-error-wrapper>
          </div>
          <!-- State Section -->
          <div fxLayout="column" class="nested-column-1 state-code-dropdown-format">
            <label id="insured-state-label">State</label>
            <ng-select [items]="stateCodes"
                       #memberStateCodeDropdown
                       placeholder=""
                       formControlName="memberAddressStateCode"
                       id="insured-state-input"
                       class="margin-top-15">
            </ng-select>
            <app-error-wrapper id="insured-state-error" [config]="errorWrapperConfig.memberAddressStateCode"></app-error-wrapper>
          </div>
          <!-- Zip Code Section -->
          <div fxLayout="column" class="nested-column-3">
            <label id="insured-zip-code-label">Zip Code</label>
            <mat-form-field appearance="outline" fxFlexOffset="15px">
              <input matInput formControlName="memberAddressZipCode" id="insured-zip-code-input"
                     #memberAddressZipCodeControl>
            </mat-form-field>
            <app-error-wrapper id="insured-zip-code-error"
                               [config]="errorWrapperConfig.memberAddressZipCode"></app-error-wrapper>
          </div>
          <!-- Insurance Plan Name or Program Name Section -->
          <div fxLayout="column" class="nested-column-5">
            <label id="insured-plan-program-label">11c. Insurance Plan Name or Program Name</label>
            <mat-form-field appearance="outline" fxFlexOffset="15px">
              <input matInput formControlName="memberInsurancePlan" id="insured-plan-program-input" maxlength="30">
            </mat-form-field>
            <app-error-wrapper id="insured-plan-program-error"
                               [config]="errorWrapperConfig.memberInsurancePlan"></app-error-wrapper>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Eighth Row In The Insured Card: "Field Labels" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-top-30 margin-bottom-15">
          <!-- Phone Number Label -->
          <label id="insured-phone-label" class="nested-column-7">Phone Number</label>
          <!-- Is there another Health Benefit Plan for Eye Care Label -->
          <label id="insured-another-eyecare-label" class="nested-column-5"><span
            class="required-label-asterisk">* </span>11d. Is There Another Health Benefit Plan for Eyecare?</label>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Ninth Row In The Insured Card: "Field Input and Radio Buttons" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row">
          <!-- Phone Number Field -->
          <div fxLayout="column" class="nested-column-7">
            <mat-form-field appearance="outline" fxFill>
              <input matInput formControlName="insuredPhone" #insuredPhoneControl id="insured-phone-input">
            </mat-form-field>
            <app-error-wrapper id="insured-phone-error" [config]="errorWrapperConfig.insuredPhone"></app-error-wrapper>
          </div>
          <!-- Is there another Health Benefit Plan for Eye Care Fields -->
          <div fxLayout="column" class="nested-column-5">
            <mat-radio-group fxLayout="row" fxLayoutAlign="start center" id="insured-another-eyecare-group"
                             formControlName="otherInsurance" (change)="onOtherInsuranceChange($event)">
              <mat-radio-button #insuredAnotherEyecareYes id="insured-another-eyecare-yes-toggle"
                                value="{{formStateYesNo.Yes}}"
                                (keyup.enter)="onKeypressEventRadiobutton(insuredAnotherEyecareYes)">{{formStateYesNo.Yes}}</mat-radio-button>
              <mat-radio-button #insuredAnotherEyecareNo id="insured-another-eyecare-no-toggle"
                                value="{{formStateYesNo.No}}"
                                fxFlexOffset="30px"
                                (keyup.enter)="onKeypressEventRadiobutton(insuredAnotherEyecareNo)">{{formStateYesNo.No}}</mat-radio-button>
            </mat-radio-group>
            <app-error-wrapper id="insured-another-eyecare-error"
                               [config]="errorWrapperConfig.otherInsurance"></app-error-wrapper>
          </div>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Tenth Row In The Insured Card: "Field Labels" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-top-30 margin-bottom-15">
          <!-- VSP Coordination of Benefits Secondary Authorization Label -->
          <label id="insured-secondary-vsr-label" class="nested-column-7">VSP Coordination of Benefits Secondary Authorization</label>
        </div>
        <!-- ############################################################################################################################################################################## -->
        <!-- Eleventh Row In The Insured Card: "Field Inputs" -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-bottom-30">
          <!-- VSP Coordination of Benefits Secondary Authorization Field -->
          <div fxLayout="column" class="nested-column-7">
            <mat-form-field appearance="outline" fxFill>
              <input matInput formControlName="otherInsuredVsrNumber" id="insured-secondary-vsr-input" maxlength="15">
            </mat-form-field>
            <app-error-wrapper id="insured-vsp-coordination-of-benefits-secondary-vsr"
                               [config]="errorWrapperConfig.otherInsuredVsrNumber"></app-error-wrapper>
          </div>
        </div>


        <!-- ############################################################################################################################################################################## -->
        <!-- ############################################################################################################################################################################## -->
        <!-- Other Insured Card Header -->
        <!-- ############################################################################################################################################################################## -->
        <!-- ############################################################################################################################################################################## -->
        <div *ngIf="otherInsurance === formStateYesNo.Yes">
          <mat-card class="margin-bottom-30">
            <mat-card-header [ngClass]="{ 'disabled-card-header': insuredForm.disabled }">
              <mat-card-title>
                <h1 class="float-left" id="insured-other-insured-title">Other Insured</h1>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <form [formGroup]="insuredForm" novalidate fxLayout="column" fxFlex="100">
                <!-- ############################################################################################################################################################################## -->
                <!-- First Row In The Other Insured Card: "Field Labels" -->
                <!-- ############################################################################################################################################################################## -->
                <div fxLayout="row" class="margin-top-30 margin-bottom-15">
                  <!-- Other Insured First Name Label -->
                  <label id="insured-other-insured-first-name-label" class="nested-column-3"><span
                    class="required-label-asterisk">* </span>9. First Name</label>
                  <!-- Other Insured Middle Name Label -->
                  <label id="insured-other-insured-middle-name-label" class="nested-column-1">MI</label>
                  <!-- Other Insured Last Name Label -->
                  <label id="insured-other-insured-last-name-label" class="nested-column-3"><span
                    class="required-label-asterisk">* </span>Last Name</label>
                  <!-- Other Insured's Policy or Group Number Label -->
                  <label id="insured-other-insured-policy-group-number-label" class="nested-column-5"><span
                    class="required-label-asterisk">* </span>9a. Other Insured's Policy or Group Number</label>
                </div>
                <!-- ############################################################################################################################################################################## -->
                <!-- Second Row In The Other Insured Card: "Field Inputs" -->
                <!-- ############################################################################################################################################################################## -->
                <div fxLayout="row">
                  <!-- Members First Name Field -->
                  <div fxLayout="column" class="nested-column-3">
                    <mat-form-field appearance="outline" class="required-mat-form-field">
                      <input matInput formControlName="otherInsuredFirstName" maxlength="20" placeholder="First Name"
                             id="member-other-insured-first-name">
                    </mat-form-field>
                    <app-error-wrapper id="member-other-insured-first-name-error"
                                       [config]="errorWrapperConfig.otherInsuredFirstName"></app-error-wrapper>
                  </div>
                  <!-- Members Middle Name Field -->
                  <div fxLayout="column" class="nested-column-1">
                    <mat-form-field appearance="outline">
                      <input matInput formControlName="otherInsuredMiddleInitial" placeholder="MI" maxlength="1"
                             id="member-other-insured-middle-initial">
                    </mat-form-field>
                    <app-error-wrapper id="member-other-insured-middle-initial-error"
                                       [config]="errorWrapperConfig.otherInsuredMiddleInitial"></app-error-wrapper>
                  </div>
                  <!-- Members Last Name Field -->
                  <div fxLayout="column" class="nested-column-3">
                    <mat-form-field appearance="outline" class="required-mat-form-field">
                      <input matInput formControlName="otherInsuredLastName" maxlength="30" placeholder="Last Name"
                             id="insured-other-insured-last-name-input">
                    </mat-form-field>
                    <app-error-wrapper id="insured-other-insured-last-name-error"
                                       [config]="errorWrapperConfig.otherInsuredLastName"></app-error-wrapper>
                  </div>
                  <!-- Insured's Policy Group or FECA Number Field -->
                  <div fxLayout="column" class="nested-column-5">
                    <mat-form-field appearance="outline" fxFill class="required-mat-form-field">
                      <input matInput formControlName="otherInsuredPolicyNumber"
                             id="insured-other-insured-policy-group-number-input" maxlength="20">
                    </mat-form-field>
                    <app-error-wrapper id="insured-other-insured-policy-group-number-error"
                                       [config]="errorWrapperConfig.otherInsuredPolicyNumber"></app-error-wrapper>
                  </div>
                </div>
                <!-- ############################################################################################################################################################################## -->
                <!-- Third Row In The Other Insured Card: "Field Labels" -->
                <!-- ############################################################################################################################################################################## -->
                <div fxLayout="row" class="margin-top-30 margin-bottom-15">
                  <!-- Other Insured First Name Label -->
                  <label id="insured-other-insured-plan-program-name-label" class="nested-column-7"><span
                    class="required-label-asterisk">* </span>9d. Insurance Plan Name or Program Name</label>
                </div>
                <!-- ############################################################################################################################################################################## -->
                <!-- Fourth Row In The Other Insured Card: "Field Inputs and Descriptions" -->
                <!-- ############################################################################################################################################################################## -->
                <div fxLayout="row" class="margin-bottom-30">
                  <!-- Other Insured First Name Label -->
                  <div fxLayout="column" class="nested-column-7">
                    <mat-form-field appearance="outline" class="required-mat-form-field">
                      <input matInput maxlength="30" formControlName="otherInsuredPlanName"
                             id="insured-other-insured-plan-program-name-input">
                    </mat-form-field>
                    <app-error-wrapper id="insured-other-insured-plan-program-name-error"
                                       [config]="errorWrapperConfig.otherInsuredPlanName"></app-error-wrapper>
                  </div>
                  <!-- Other Insured Middle Name Label -->
                  <label id="insured-other-for-box-11d-info" class="nested-column-5">For box "11d – Is there another Health Benefit Plan for Eyecare", select Yes if billing VSP as secondary to another insurance carrier. Select No when coordinating two VSP plans.</label>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>

