import {Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {ApplicationConstants} from '../../../../common/constants/application.constants';


@Component({
  templateUrl: './kscope-frame-confirmation-modal.html',
  encapsulation: ViewEncapsulation.None
})
export class KscopeFrameConfirmationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<KscopeFrameConfirmationModalComponent>
  ) {}

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  onYesClick() {
    this.dialogRef.close(true);
  }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }
}
