import {Injectable, Injector} from '@angular/core';
import {HttpClientService} from '../http-client-service';
import {ApplicationConstants} from '../../../../../constants/application.constants';
import {Observable} from 'rxjs';
import {
  FrameDispositionResponse,
  FrameDropshipOrderRequest,
  FrameReplacementOrderRequest
} from '../../../../../../models/fulfillment';
import {catchError, map, retry} from 'rxjs/operators';
import {of} from 'rxjs';
import {ParticipatingLabsRequest} from '../../../../../../models/ParticipatingLabsRequest';
import {ParticipatingLabsResponse} from '../../../../../../models/ParticipatingLabsResponse';
import {KaleyedoscopeParticipatingLabsConstant} from '../../../../../../secure/claim-form/lab/lab.constants';
import {HttpHeaders} from '@angular/common/http';
import {isNullOrUndefined, isStringNullUndefinedOrEmptyWithTrim} from '../../../../../utility';

@Injectable({
  providedIn: 'root'
})
export class FulfillmentDataService extends HttpClientService {

  // Per ECLAIM-142 we added the below two parameters so we can send them down as a header to the fulfilment api.
  private _vsrNumber: string;
  private _vsrNumberDateOfService: string;

  constructor(protected injector: Injector) {
    super(injector, ApplicationConstants.dataServiceClientIDs.fulfillmentService);
  }

  get vsrNumber(): string {
    return this._vsrNumber;
  }

  set vsrNumber(value: string) {
    this._vsrNumber = value;
  }

  get vsrNumberDateOfService(): string {
    return this._vsrNumberDateOfService;
  }

  set vsrNumberDateOfService(value: string) {
    this._vsrNumberDateOfService = value;
  }

  getFrameDispositions(sku: string, manufacturer: string): Observable<FrameDispositionResponse> {
    return this.http.get(this.environment.fulfillmentApiUrl + ApplicationConstants.api.fulfillmentApi.frameDisposition
      + '?frameSku=' + sku + ApplicationConstants.ampersandSymbol + 'manufacturer=' + manufacturer,
      {headers: this.getHttpHeaders(ApplicationConstants.api.fulfillmentApi.frameDisposition)})
      .pipe(
        map((frameDispositionResponse: FrameDispositionResponse) => {
          return frameDispositionResponse;
        }),
        catchError((error: any) => {
          // TODO: We need to figure out what we will do if error returns with the api. Commenting out generic error message until we have it figured out.
          // const errorMessage = ApplicationConstants.errorMessages.genericApiFailMessage;
          this.handleError('getFrameDispositions', error);
          return of(undefined);
        })
      );
  }

  orderFrameDropship(searchQueryObject: FrameDropshipOrderRequest): Observable<any> {
    return this.http.post(this.environment.fulfillmentApiUrl + ApplicationConstants.api.fulfillmentApi.frameDropshipOrder,
      searchQueryObject, { headers: this.setAdditionalHeaders(this.getHttpHeaders(ApplicationConstants.api.fulfillmentApi.frameDropshipOrder))})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        map((frameDropshipResponse) => {
          this.log(ApplicationConstants.fulfillmentApiMessage.frameDropShipRequestWasSuccessfullySent);
          return isNullOrUndefined(frameDropshipResponse) ? ApplicationConstants.fulfillmentApiMessage.frameDropShipRequestWasSuccessfullySent : frameDropshipResponse;
        }),
        catchError((error: any) => {
          // TODO: We need to figure out what we will do if error returns with the api. Commenting out generic error message until we have it figured out.
          // const errorMessage = ApplicationConstants.errorMessages.genericApiFailMessage;
          this.handleError('dropshipOrder', error);
          return of(undefined);
        })
      );
  }


  orderFrameReplacement(searchQueryObject: FrameReplacementOrderRequest): Observable<any> {
    return this.http.post(this.environment.fulfillmentApiUrl + ApplicationConstants.api.fulfillmentApi.frameReplacementOrder,
      searchQueryObject, { headers: this.setAdditionalHeaders(this.getHttpHeaders(ApplicationConstants.api.fulfillmentApi.frameReplacementOrder))})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        map((frameReplacementResponse) => {
          this.log(ApplicationConstants.fulfillmentApiMessage.frameReplacementRequestWasSuccessfullySent);
          return isNullOrUndefined(frameReplacementResponse) ? ApplicationConstants.fulfillmentApiMessage.frameReplacementRequestWasSuccessfullySent : frameReplacementResponse;
        }),
        catchError((error: any) => {
          // TODO: We need to figure out what we will do if error returns with the api. Commenting out generic error message until we have it figured out.
          // const errorMessage = ApplicationConstants.errorMessages.genericApiFailMessage;
          this.handleError('replacementOrder', error);
          return of(undefined);
        })
      );
  }

  getStaticParticipatingLabs():  Observable<ParticipatingLabsResponse> {
    return new Observable((observer) => {
        observer.next(KaleyedoscopeParticipatingLabsConstant);
      });
  }

  getParticipatingLabs(participatingLabsRequest: ParticipatingLabsRequest): Observable<ParticipatingLabsResponse> {
    const participatingLabsURL = this.environment.fulfillmentApiUrl + ApplicationConstants.api.fulfillmentParticipatingLabsApi.participatingLabsDisposition;
    return this.http.post(participatingLabsURL, participatingLabsRequest,
      {headers: this.getHttpHeaders(ApplicationConstants.api.fulfillmentParticipatingLabsApi.participatingLabsDisposition)})
      .pipe(
        map((participatingLabsResponse: ParticipatingLabsResponse) => {
          return participatingLabsResponse;
        }),
        catchError((error: any) => {
          // TODO: leaving commented out until we figure out the logging situation
          // const errorMessage = ApplicationConstants.errorMessages.genericApiFailMessage;
          this.handleError('getParticipatingLabs', error);
          return of(undefined);
        })
      );
  }

  /**
   * This was added for ECLAIM-142 so we can set an additional header for the fulfilment api.
   *
   * @param httpHeaders - Additional headers we want to pass down to the fulfilment api.
   */
  setAdditionalHeaders(httpHeaders: HttpHeaders) {
    if (!isStringNullUndefinedOrEmptyWithTrim(this.vsrNumber) && !isStringNullUndefinedOrEmptyWithTrim(this.vsrNumberDateOfService)) {
      httpHeaders = httpHeaders.append('eyefinity-workflow-id', 'VsrNumber:' + this.vsrNumber + ';DOS:' + this.vsrNumberDateOfService);
    }
    return httpHeaders;
  }
}
