<!-- Main Content Shell -->
<div fxLayout="column" id="fileClaim-content" *ngIf="claimLoaded">
  <!-- Print and Reports buttons -->
  <div fxLayout="row" fxLayoutAlign="end center">
    <app-print-view-reports></app-print-view-reports>
  </div>
  <div id="editBannerAndNavBarWrapper" fxLayout="column" class="claim-navigation-wrapper">
    <!-- Edit banners -->
    <app-soft-and-hard-edit-banner></app-soft-and-hard-edit-banner>
    <!-- Navbar -->
    <app-claim-navigation></app-claim-navigation>
  </div>
  <!-- Forms -->
  <div fxLayout="column" #claimFormContainer id="claim-form-container" fxFlexOffset="20px">

    <!-- Date of Service -->
    <app-date-of-service></app-date-of-service>

    <!-- Exam Form -->
    <app-exam fxFlexOffset="30px"></app-exam>

    <!-- Lens -->
    <app-lens fxFlexOffset="30px" class="print-page-break"></app-lens>

    <!-- Frame -->
    <app-frame fxFlexOffset="30px" class="print-page-break"></app-frame>

    <!-- Frame -->
    <app-lab fxFlexOffset="30px" class="print-page-break"></app-lab>

    <!-- Exam and Contacts Form -->
    <app-contacts fxFlexOffset="30px"></app-contacts>

    <!-- Prescription -->
    <app-prescription fxFlexOffset="30px"></app-prescription>

    <!-- Services -->
    <app-services fxFlexOffset="30px" class="print-page-break"></app-services>

    <!-- Patient Form -->
    <app-patient fxFlexOffset="30px" class="print-page-break"></app-patient>

    <!-- Insured Form -->
    <app-insured fxFlexOffset="30px"></app-insured>

    <!-- Facility And Billing -->
    <app-facility-billing-address fxFlexOffset="30px" class="print-page-break"></app-facility-billing-address>

    <!-- Additional Information -->
    <app-additional-information fxFlexOffset="30px" class="print-page-break"></app-additional-information>

    <!-- Signatures -->
    <app-signatures fxFlexOffset="30px" class="print-page-break"></app-signatures>

    <!-- space between the signatures card and the claim action footer at the end of the page-->
    <div fxFlexOffset="40px"></div>

  </div>

  <!-- Footer Claim Action Buttons  -->
  <div class="claim-action-buttons">
    <app-claim-action-buttons></app-claim-action-buttons>
  </div>
  <!-- space between the claim action buttons footer and the end of the page-->
  <div fxFlexOffset="100px"></div>

</div>
