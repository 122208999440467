import {ElementRef, Injectable} from '@angular/core';
import {ClaimsService} from '../../data-model/app/claims/claims.service';
import {Claim} from '../../../../models/claim';
import {MatDialog} from '@angular/material/dialog';
import {RetrieveSubmittedClaimConfirmationComponent} from '../../../../secure/eInsurance/retrieve-submitted-claim-confirmation/retrieve-submitted-claim-confirmation.component';
import {ApplicationConstants, ClaimStatus } from '../../../constants/application.constants';
import {Router} from '@angular/router';
import {
  DateUtility,
  isNullOrUndefined,
  isStringNullUndefinedOrEmpty,
  isStringNullUndefinedOrEmptyWithTrim,
  openDialog
} from '../../../utility';
import {Observable} from 'rxjs';
import {Observer} from 'rxjs';
import {AuthorizationsService} from '../../data-model/app/authorizations/authorizations.service';
import {VisionServiceRequestClaimDto} from '../../../../models/VisionServiceRequestResults';
import {ExternalServiceLocationService} from '../../data-model/app/external-service-location/external-service-location.service';
import {combineLatest} from 'rxjs';
import {LensService} from '../../data-model/app/lens/lens.service';
import {LensCatalogOperation, LensLabService} from '../../../../models/lens';
import {take} from 'rxjs/operators';
import {SuccessfulSubmitClaimModalComponent} from '../../../../secure/claim-form/claim-action-buttons/successful-submit-claim-modal/successful-submit-claim-modal.component';
import {DrReportsNavigationService} from '../dr-reports-navigation/dr-reports-navigation.service';
import {ContactLensOperation, ContactLensService} from '../../../../models/contactLens';
import {ContactsService} from '../../data-model/app/contacts/contacts.service';
import {UserSessionService} from '../user-session/user-session.service';
import {LeaveClaimFormWarningModalComponent} from '../../../../secure/claim-form/leave-claim-form-warning-modal/leave-claim-form-warning-modal.component';
import {ViewStateService} from '../../view-state/view-state.service';
import {ExternalServiceLocation} from '../../../../models/externalServiceLocation';
import {CookieService} from 'ngx-cookie-service';
import {FrameDataModel} from '../../../../models/frame';
import {FulfillmentService} from '../../data-model/app/fulfillment/fulfillment.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimFormNavigationService {

  constructor(
    private claimsService: ClaimsService,
    private externalServiceLocationService: ExternalServiceLocationService,
    private authorizationService: AuthorizationsService,
    private dialog: MatDialog,
    private router: Router,
    private lensService: LensService,
    private drReportsNavigationService: DrReportsNavigationService,
    private contactsService: ContactsService,
    private userSessionService: UserSessionService,
    private viewStateService: ViewStateService,
    private cookieService: CookieService,
    private fulfillmentService: FulfillmentService
  ) {
  }

  private _claimFormLoaded = false;
  private _initialClaimFormLoad: boolean = false;
  private _isRouteComingFromTheSubmittedClaimModal = false;
  private _isNavigatingToClaimFormFromTheSubmittedClaimModal = false;
  private _isNavigationComingFromTheSubmitClaimModal = false;

  get claimFormLoaded(): boolean {
    return this._claimFormLoaded;
  }

  set claimFormLoaded(value: boolean) {
    this._claimFormLoaded = value;
  }

  get initialClaimFormLoad(): boolean {
    return this._initialClaimFormLoad;
  }

  set initialClaimFormLoad(value: boolean) {
    this._initialClaimFormLoad = value;
  }

  get isRouteComingFromTheSubmittedClaimModal(): boolean {
    return this._isRouteComingFromTheSubmittedClaimModal;
  }

  set isRouteComingFromTheSubmittedClaimModal(value: boolean) {
    this._isRouteComingFromTheSubmittedClaimModal = value;
  }

  get isNavigatingToClaimFormFromTheSubmittedClaimModal(): boolean {
    return this._isNavigatingToClaimFormFromTheSubmittedClaimModal;
  }

  set isNavigatingToClaimFormFromTheSubmittedClaimModal(value: boolean) {
    this._isNavigatingToClaimFormFromTheSubmittedClaimModal = value;
  }

  get isNavigationComingFromTheSubmitClaimModal(): boolean {
    return this._isNavigationComingFromTheSubmitClaimModal;
  }

  set isNavigationComingFromTheSubmitClaimModal(value: boolean) {
    this._isNavigationComingFromTheSubmitClaimModal = value;
  }
  /**
   * This method is doing following
   * 1. Search a claim
   * 2. If found, then retrieve it and go to Claim Form page
   * 3. If not found, then create and got to Claim Form
   * 4. After claim is returned, call external service location API to load doctor list
   * 5. If any of the API call fails then stay on this page
   */
  searchAndRetrieveOrCreatePatientEncounterThenLoadDoctorsThenNavigateToClaimForm(authorizationNumber: string,
                                                                                  formControlToFocusAfterFail?: ElementRef,
                                                                                  vsrEffectiveDate?: string,
                                                                                  showSnackbar: boolean = true,
                                                                                  displaySubmittedClaimModal: boolean = true): Observable<void> {
    return new Observable((observer) => {
      this.claimFormLoaded = false;
      this.claimsService.searchClaim(authorizationNumber, showSnackbar).subscribe((claimFound: boolean) => {
        if (!isNullOrUndefined(claimFound)) {
          if (claimFound) {
            this.claimsService.loadClaim(authorizationNumber, showSnackbar).subscribe((claim: Claim) => {
              this.retrieveOrCreatePatientEncounterThenLoadDoctorsThenNavigateToClaimForm(authorizationNumber, claim,
                formControlToFocusAfterFail, observer, displaySubmittedClaimModal, showSnackbar);
            });
          } else {
            if (vsrEffectiveDate) {
              this.claimsService.createClaim(authorizationNumber, DateUtility.buildDateFromDateString(vsrEffectiveDate), showSnackbar).subscribe((claim: Claim) => {
                this.retrieveOrCreatePatientEncounterThenLoadDoctorsThenNavigateToClaimForm(authorizationNumber, claim,
                  formControlToFocusAfterFail, observer, displaySubmittedClaimModal, showSnackbar);
              });
            } else {
              // Retrieve the VSR, then create the PE with the effective date on the VSR
              this.authorizationService.retrieveAuthorization(authorizationNumber, showSnackbar).subscribe((vsrResponse: VisionServiceRequestClaimDto) => {
                if (vsrResponse) {
                  this.claimsService.createClaim(authorizationNumber, DateUtility.buildDateFromDateString(vsrResponse.effectiveDate), showSnackbar).subscribe((claim: Claim) => {
                    this.retrieveOrCreatePatientEncounterThenLoadDoctorsThenNavigateToClaimForm(authorizationNumber, claim,
                      formControlToFocusAfterFail, observer, displaySubmittedClaimModal, showSnackbar);
                  });
                } else {
                  observer.complete();
                }
              });
            }
          }
        } else {
          observer.complete();
        }
      });
    });
  }

  /*
   * This method takes the claim that was either retrieve or created and handles the process of navigating to the claim form based on if
   * the claim is in a submitted status or not. If the claim is submitted display modal before navigating them to claim form. If the claim
   * is not submitted, then call API for getting the list of doctors.
   */
  private retrieveOrCreatePatientEncounterThenLoadDoctorsThenNavigateToClaimForm(authorizationNumber: string,
                                                                                 claim: Claim,
                                                                                 formControlToFocusAfterFail: ElementRef,
                                                                                 observer: Observer<void>,
                                                                                 displaySubmittedClaimModal: boolean = true,
                                                                                 showSnackbar: boolean = true): void {
    if (claim && claim.trackingNumber) {
      if ((claim.status && claim.status === ClaimStatus.SubmittedClaim || claim.status === ClaimStatus.SubmittedLab) && displaySubmittedClaimModal) {
        this.displaySubmittedClaimDialogBeforeNavigatingToClaimForm(authorizationNumber, formControlToFocusAfterFail, observer, claim, showSnackbar);
      } else {
        this.initializeProviderAndLensCatalogReferenceListsThenNavigateToClaimForm(authorizationNumber, claim, observer, showSnackbar);
      }
    } else {
      observer.complete();
    }
  }

  private displaySubmittedClaimDialogBeforeNavigatingToClaimForm(authorizationNumber: string,
                                                                 formControlToFocusAfterFail: ElementRef,
                                                                 observer: Observer<void>,
                                                                 claim: Claim,
                                                                 showSnackbar: boolean = true): void {
    const retrieveClaimPopup = this.dialog.open(RetrieveSubmittedClaimConfirmationComponent, {
      panelClass: 'eclaim-popup-modal',
      width: '600px',
      disableClose: true
    });
    retrieveClaimPopup.afterClosed().subscribe((result: string | undefined) => {
      this.isRouteComingFromTheSubmittedClaimModal = true;
      if (ApplicationConstants.modalSelectionOk === result) {
        this.isNavigatingToClaimFormFromTheSubmittedClaimModal = true;
        this.initializeProviderAndLensCatalogReferenceListsThenNavigateToClaimForm(authorizationNumber, claim, observer, showSnackbar);
      } else if (!isNullOrUndefined(formControlToFocusAfterFail)) {
        formControlToFocusAfterFail.nativeElement.focus();
      }
      observer.next(null);
      observer.complete();
    });
  }

  private loadDoctors(extSvcLocURL: string, showSnackbar: boolean = true): Observable<ExternalServiceLocation> {
    return new Observable((observer) => {
      if (this.providerNetworkUrlIsValid(extSvcLocURL)) {
        this.externalServiceLocationService.loadExternalServiceLocation(extSvcLocURL, undefined, showSnackbar)
          .subscribe((extSvcLocResponse: ExternalServiceLocation) => {
            observer.next(extSvcLocResponse);
            observer.complete();
          });
      } else {
        observer.next(null);
        observer.complete();
      }
    });
  }

  /**
   * Initializes all calls to the LCAT reference lists and External Service Location for both created, saved, and submitted claims.
   * For previous saved and submitted claims, the LCAT calls are necessary to match and populate dropdown values based on previously
   * selected user values within the Lens card while the component is bootstrapping.
   *
   * @param {string} authorizationNumber - tracking number on the Patient Encounter
   * @param {Claim} claim - UI formatted Patient Encounter used to retrive the link for External Service Location
   * @param {Observer<void>} observer - reference to the original observer passed in from the eInsurance compnent
   * @param showSnackbar - Option parameter that will set a flag to either display or not display the error snack bar
   */
  private initializeProviderAndLensCatalogReferenceListsThenNavigateToClaimForm(authorizationNumber: string, claim: Claim, observer: Observer<void>, showSnackbar: boolean = true): void {
    // Initialize all the awaitable tasks to execute
    const providerNetworkHref: string = claim.providers.href;
    const dateOfService: string = DateUtility.buildYyyyMmDdDateFromDate(claim.dateOfService);
    this.loadDoctors(providerNetworkHref, showSnackbar).subscribe((extSvcLocResponse: ExternalServiceLocation) => {
      if (!isNullOrUndefined(extSvcLocResponse)) {
        if (this.isNavigatingToClaimFormFromTheSubmittedClaimModal) {
          openDialog('Loading Submitted Authorization...', this.dialog);
        }
        const loadScratchCoatings: Observable<LensLabService[]> = this.lensService.lensLabServiceFactory(LensCatalogOperation.ScratchCoatings, dateOfService);
        const loadARCoatings: Observable<LensLabService[]> = this.lensService.lensLabServiceFactory(LensCatalogOperation.ARCoatings, dateOfService);
        const loadTintColors: Observable<LensLabService[]> = this.lensService.lensLabServiceFactory(LensCatalogOperation.TintColors, dateOfService);
        const loadMirrorSkiCoatings: Observable<LensLabService[]> = this.lensService.lensLabServiceFactory(LensCatalogOperation.MirrorSkiCoatings, dateOfService);
        const loadDyeTypes: Observable<LensLabService[]> = this.lensService.lensLabServiceFactory(LensCatalogOperation.DyeTypes, dateOfService);
        const loadUVCoatings: Observable<LensLabService[]> = this.lensService.lensLabServiceFactory(LensCatalogOperation.UVCoatings, dateOfService);
        const loadGlassCoatings: Observable<LensLabService[]> = this.lensService.lensLabServiceFactory(LensCatalogOperation.GlassCoatings, dateOfService);
        const loadEdgeCoatings: Observable<LensLabService[]> = this.lensService.lensLabServiceFactory(LensCatalogOperation.EdgeCoatings, dateOfService);
        const loadBevels: Observable<LensLabService[]> = this.lensService.lensLabServiceFactory(LensCatalogOperation.Bevels, dateOfService);
        const loadVisionTypes: Observable<LensLabService[]> = this.lensService.lensLabServiceFactory(LensCatalogOperation.VisionTypes, dateOfService);
        const loadMaterialTypes: Observable<LensLabService[]> = this.lensService.lensLabServiceFactory(LensCatalogOperation.MaterialTypes, dateOfService);

        // Contacts Card
        const loadContactsMaterialTypes: Observable<ContactLensService[]> = this.contactsService.contactLensServiceFactory(ContactLensOperation.MaterialType);
        const loadContactsServices: Observable<ContactLensService[]> = this.contactsService.contactLensServiceFactory(ContactLensOperation.Service);
        const loadContactsReasons: Observable<ContactLensService[]> = this.contactsService.contactLensServiceFactory(ContactLensOperation.Reason);
        const loadContactsManufacturers: Observable<ContactLensService> = this.contactsService.contactLensServiceFactory(ContactLensOperation.Manufacturer);
        const loadContactsModalities: Observable<ContactLensService> = this.contactsService.contactLensServiceFactory(ContactLensOperation.Modality);

        // Execute all queued tasks
        combineLatest(loadScratchCoatings, loadARCoatings, loadTintColors, loadMirrorSkiCoatings, loadDyeTypes, loadUVCoatings, loadGlassCoatings, loadEdgeCoatings, loadBevels, loadVisionTypes, loadMaterialTypes, loadContactsMaterialTypes, loadContactsServices, loadContactsReasons, loadContactsManufacturers, loadContactsModalities,
          (loadDoctorsComplete, loadScratchCoatingComplete, loadARCoatingsComplete, loadTintColorsComplete, loadMirrorSkiCoatingsComplete, loadDyeTypesComplete, loadUVCoatingsComplete, loadGlassCoatingsComplete, loadEdgeCoatingsComplete, loadBevelsComplete, loadVisionTypesComplete, loadMaterialTypesComplete, loadContactsMaterialTypesComplete, loadContactsServicesComplete, loadContactsReasonsComplete, loadContactsManufacturersComplete, loadContactsModalitiesComplete) => ({
            loadDoctorsComplete,
            loadScratchCoatingComplete,
            loadARCoatingsComplete,
            loadTintColorsComplete,
            loadMirrorSkiCoatingsComplete,
            loadDyeTypesComplete,
            loadUVCoatingsComplete,
            loadGlassCoatingsComplete,
            loadEdgeCoatingsComplete,
            loadBevelsComplete,
            loadVisionTypesComplete,
            loadMaterialTypesComplete,
            loadContactsMaterialTypesComplete,
            loadContactsServicesComplete,
            loadContactsReasonsComplete,
            loadContactsManufacturersComplete,
            loadContactsModalitiesComplete
          }))
          .pipe(take(1))
          .subscribe(() => {
            this.claimFormLoaded = true;
            this.initialClaimFormLoad = true;
            this.claimsService.allowClaimFormToBeDeactivated = true;
            observer.complete();
            // ECLAIM-191 - We want to reset the canFrameBeClearedOnLensFinishingChange to its original value regardless if
            // the claim is submitted or not so the frame section does not get cleared out on a KScope frame when
            // the claim form loads.
            if (this.userSessionService.isKaleyedoscopePractice) {
              this.fulfillmentService.canFrameBeClearedOnLensFinishingChange = false;
            }
            this.router.navigate([ApplicationConstants.routing.secure.claimFormEditPageUrl], {
              queryParams: {
                authorizationNumber: authorizationNumber,
                claimAlreadyLoaded: ApplicationConstants.trueString
              }
            });
            if (this.isNavigatingToClaimFormFromTheSubmittedClaimModal) {
              this.dialog.closeAll();
              this.isNavigatingToClaimFormFromTheSubmittedClaimModal = false;
            }
            // This was added for ECLAIM-27. Per business decision we only want to make the KScope call on a non submitted claim.
            if ((claim.status !== ClaimStatus.SubmittedClaim && claim.status !== ClaimStatus.SubmittedLab) && this.userSessionService.isKaleyedoscopePractice && !isNullOrUndefined(claim) && !isNullOrUndefined(claim.labOrderInformation) && !isNullOrUndefined(claim.labOrderInformation.frame)) {
              const frameDataModel: FrameDataModel = claim.labOrderInformation.frame;
              if (!isNullOrUndefined(frameDataModel.manufacturer) && !isStringNullUndefinedOrEmptyWithTrim(frameDataModel.manufacturer.name) && !isNullOrUndefined(frameDataModel.options) && !isNullOrUndefined(frameDataModel.options[0]) && !isStringNullUndefinedOrEmptyWithTrim(frameDataModel.options[0].sku)) {
                // This was also added for for ECLAIM-60 to reset the fulfillment service to its original state.
                this.fulfillmentService.resetServiceValuesToOriginalState();
                this.fulfillmentService.isFulfillmentApiCallFromClaimNavigationService = true;
                this.fulfillmentService.getFrameDispositions(frameDataModel.options[0].sku, frameDataModel.manufacturer.name).subscribe((hasCallCompleted) => {
                  if (hasCallCompleted) {
                    // TODO: PHASE 2: Implement logic on low selling frames to navigate correctly to the frame card
                  }
                });
              } else if (!isNullOrUndefined(frameDataModel.options) && !isNullOrUndefined(frameDataModel.options[0]) && isStringNullUndefinedOrEmptyWithTrim(frameDataModel.options[0].sku)) {
                // This was also added for for ECLAIM-60 to reset the fulfillment service to its original state.
                this.fulfillmentService.resetServiceValuesToOriginalState();
                this.fulfillmentService.isFulfillmentApiCallFromClaimNavigationService = true;
                this.fulfillmentService.isFrameMissingSkuNumber = true;
              }
            }
          });
      } else {
        observer.complete();
      }
    });
  }

  private providerNetworkUrlIsValid = (providerNetworkHref: string): boolean =>
    !isStringNullUndefinedOrEmpty(providerNetworkHref)

  openSuccessfullySubmittedClaimModal(authNumber: string): void {
    const successfullySubmittedClaimPopup = this.dialog.open(SuccessfulSubmitClaimModalComponent, {
      width: '650px',
      panelClass: 'sticky-footer-popup-modal',
      disableClose: true
    });
    successfullySubmittedClaimPopup.afterClosed().subscribe(result => {
      if (ApplicationConstants.modalSelectionYes === result) {
        this.drReportsNavigationService.navigateToPatientRecordReport(authNumber);
        window.location.href = this.cookieService.get(ApplicationConstants.efSurlCookie) + ApplicationConstants.legacyEInsurancePageURL;
      }
    });
  }

  allowUserToLeaveClaimFormWarningModal(): Observable<boolean> {
    return new Observable((observer) => {
      if (this.claimsService.allowClaimFormToBeDeactivated) {
        // This rests the observable back to and undefine status.
        this.userSessionService.autoSavedClaimForm = undefined;
        this.viewStateService.setHasUIEdits(false);
        observer.next(true);
        observer.complete();
      } else {
        const leaveClaimFormPromptModalPopup = this.dialog.open(LeaveClaimFormWarningModalComponent, {
          width: '600px',
          disableClose: true,
          panelClass: 'eclaim-popup-modal'
        });
        leaveClaimFormPromptModalPopup.afterClosed().subscribe(result => {
          if (result === ApplicationConstants.modalSelectionSave) {
            observer.next(false);
            this.userSessionService.isAutoSaveFromSessionTimeOut = false;
            // To save the claim we are piggybacking of existing auto save functionality.
            this.userSessionService.autoSaveClaimForm.next(true);
            this.userSessionService.onAutoSavedClaimForm.subscribe((saved) => {
              if (!isNullOrUndefined(saved)) {
                if (saved) {
                  this.viewStateService.setSaveFromLeaveClaimFormWarningModalWasSuccessfulState(true);
                }
                // This rests the observable back to and undefine status.
                this.userSessionService.autoSavedClaimForm = undefined;
              }
            });
            // This resets it back to how it was after the saving happens. This was done so auto saving does not happen on every page reload/navigation
            this.userSessionService.autoSaveClaimForm.next(false);
            observer.complete();
          } else if (result === ApplicationConstants.modalSelectionLeaveForm) {
            this.claimsService.allowClaimFormToBeDeactivated = true;
            this.viewStateService.setHasUIEdits(false);
            observer.next(true);
            observer.complete();
          } else {
            observer.next(false);
            observer.complete();
          }
        });
      }
    });
  }

}
