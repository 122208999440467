export interface FrameDispositionResponse {
  dispositionCode: string;
  replacementSku: string;
  dispositionDescription: string;
  backOrderDate: string;
  displayReplacementChoiceIndicator?: string;
  replacementFrameChoices?: ReplacementFrameChoice[];
}

export enum ReplaceFrameEnum {
  Cancel = 'Cancel',
  Continue = 'Continue'
}

export enum ReplacementChoiceIndicatorEnum {
  Y = 'Y',
  N = 'N',
}

export interface ReplacementFrameChoice {
  isActiveSelection?: boolean;
  style?: string;
  size?: string;
  color?: string;
  frameColorDescription?: string;
  styleName?: string;
  imageUrl?: string;
  skuNumber?: string;
}

export class FrameOrderRequest {
  private frameSku: string;
  private originalSku: string;
  private externalLabId: string;
  private orderNumber: string;
  private manufacturer: string;
  private manufacturerId: string;
  private notes: string;

  get getFrameSku(): string {
    return this.frameSku;
  }

  set setFrameSku(value: string) {
    this.frameSku = value;
  }

  get getOriginalSku(): string {
    return this.originalSku;
  }

  set setOriginalSku(value: string) {
    this.originalSku = value;
  }

  get getExternalLabId(): string {
    return this.externalLabId;
  }

  set setExternalLabId(value: string) {
    this.externalLabId = value;
  }

  get getOrderNumber(): string {
    return this.orderNumber;
  }

  set setOrderNumber(value: string) {
    this.orderNumber = value;
  }

  get getManufacturer(): string {
    return this.manufacturer;
  }

  set setManufacturer(value: string) {
    this.manufacturer = value;
  }

  get getManufacturerId(): string {
    return this.manufacturerId;
  }

  set setManufacturerId(value: string) {
    this.manufacturerId = value;
  }

  get getNotes(): string {
    return this.notes;
  }

  set setNotes(value: string) {
    this.notes = value;
  }
}

export class FrameDropshipOrderRequest {
  private frameSku: string;
  private externalLabId: string;
  private orderNumber: string;
  private manufacturer: string;
  private manufacturerId: string;
  private notes: string;

  get getFrameSku(): string {
    return this.frameSku;
  }

  set setFrameSku(value: string) {
    this.frameSku = value;
  }

  get getExternalLabId(): string {
    return this.externalLabId;
  }

  set setExternalLabId(value: string) {
    this.externalLabId = value;
  }

  get getOrderNumber(): string {
    return this.orderNumber;
  }

  set setOrderNumber(value: string) {
    this.orderNumber = value;
  }

  get getManufacturer(): string {
    return this.manufacturer;
  }

  set setManufacturer(value: string) {
    this.manufacturer = value;
  }

  get getManufacturerId(): string {
    return this.manufacturerId;
  }

  set setManufacturerId(value: string) {
    this.manufacturerId = value;
  }

  get getNotes(): string {
    return this.notes;
  }

  set setNotes(value: string) {
    this.notes = value;
  }
}

export class FrameReplacementOrderRequest {
  private frameSku: string;
  private originalSku: string;
  private manufacturer: string;
  private manufacturerId: string;
  // Added this new parameter per ECLAIM-191.
  private programMode: string;

  get getFrameSku(): string {
    return this.frameSku;
  }

  set setFrameSku(value: string) {
    this.frameSku = value;
  }

  get getOriginalSku(): string {
    return this.originalSku;
  }

  set setOriginalSku(value: string) {
    this.originalSku = value;
  }

  get getManufacturer(): string {
    return this.manufacturer;
  }

  set setManufacturer(value: string) {
    this.manufacturer = value;
  }

  get getManufacturerId(): string {
    return this.manufacturerId;
  }

  set setManufacturerId(value: string) {
    this.manufacturerId = value;
  }

  get getProgramMode(): string {
    return this.programMode;
  }

  set setProgramMode(value: string) {
    this.programMode = value;
  }
}
