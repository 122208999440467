import { PatientEncounterLab } from '../../../models/labOrderInformation';
import {Claim} from '../../../models/claim';
import {ParticipatingLabsResponse} from '../../../models/ParticipatingLabsResponse';

export const LabConstants = {
  accountOptions: [
    {value: 'Account 1', label: 'Account 1'},
    {value: 'Account 2', label: 'Account 2'},
    {value: 'Account 3', label: 'Account 3'},
    {value: 'Account 4', label: 'Account 4'},
    {value: 'Account 5', label: 'Account 5'}
  ],
  failSafeLabList: [
    {
      labName: 'VSPOne Columbus',
      labId: '0199',
      labPhysicalAddressCity: 'Lockbourne',
      labPhysicalAddressStateCode: 'OH'
    },
    {
      labName: 'VSPOne Dallas',
      labId: '0201',
      labPhysicalAddressCity: 'Lewisville',
      labPhysicalAddressStateCode: 'TX'
    },
    {
      labName: 'VSPOne Fort Lauderdale',
      labId: '0821',
      labPhysicalAddressCity: 'Fort Lauderdale',
      labPhysicalAddressStateCode: 'FL'
    },
    {
      labName: 'VSPOne Olympia',
      labId: '0505',
      labPhysicalAddressCity: 'Lacey',
      labPhysicalAddressStateCode: 'WA'
    },
    {
      labName: 'VSPOne Sacramento',
      labId: '0999',
      labPhysicalAddressCity: 'Folsom',
      labPhysicalAddressStateCode: 'CA'
    },
    {
      labName: 'VSPOne San Diego',
      labId: '0961',
      labPhysicalAddressCity: 'Vista',
      labPhysicalAddressStateCode: 'CA'
    },
    {
      labName: 'VSPOne Tampa Bay',
      labId: '0862',
      labPhysicalAddressCity: 'Clearwater',
      labPhysicalAddressStateCode: 'FL'
    }
  ] as PatientEncounterLab[],
};

export const labUnitTestConstant = {
  labOrderInformation: {
    lensFinishing: 'LAB_FINISHED_LENS',
    lens: {
      materialType: {
        externalId: 0
      },
      visionType: {
        externalId: 0
      },
      externalId: 'DFD9'
    },
    labNumber: '0124'
  }
} as Claim;


export const KaleyedoscopeParticipatingLabsConstant = {
  labs: [
    {
      labid: '0383',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0104',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0109',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0114',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0115',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0201',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0821',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0220',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0242',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0310',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0318',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0406',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0408',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0417',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0436',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0443',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0460',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0465',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0481',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0862',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0610',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0621',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0650',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0718',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0770',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0999',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0867',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0880',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0950',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0901',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0915',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0918',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0994',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0929',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0935',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0940',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0941',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0973',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0199',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0966',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0744',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0609',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0687',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0635',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0960',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0967',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0213',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0292',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0636',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0132',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0660',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0645',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0321',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0431',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0605',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0228',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0701',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0626',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0275',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0286',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0737',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0920',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0741',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0713',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0529',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0568',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0712',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0618',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0437',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0280',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0212',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0216',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0438',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '9018',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0178',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0125',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0306',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0539',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0581',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0594',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0604',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0624',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0625',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0628',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0632',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0646',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0647',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0649',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0657',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0656',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0670',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0678',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0688',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0659',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0658',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0769',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0815',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0705',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0124',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0948',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0710',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0524',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0720',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0739',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0778',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    },
    {
      labid: '0953',
      code: '5129c660-d6a4-4577-8d8a-4b7df901c7c1',
      description: 'Free Shipping'
    }]
} as ParticipatingLabsResponse;
