<div fxLayout="column">

  <!-- Header Text -->
  <div fxLayout="row" class="header-content">
    <h1 class="nested-column-12" id="hcpc-codes-modal-header" mat-dialog-title>Search Tool Help</h1>

    <!-- Close Icon -->
    <a class="close-button margin-top-5" (click)="onCloseClick()"
       id="hcpc-cpt-codes-closeButton">
      <mat-icon svgIcon="eclaim-icons:close" id="hcpc-cpt-codes-close-icon"></mat-icon>
    </a>
  </div>

  <mat-dialog-content class="modal-body frame-search-help-modal" id="frameSearchHelpModal">

    <h4>Instructions:</h4>

    <p>
      In the field labeled <strong>Frame</strong>, enter frame information such as collection, style or model name or
      number. This information is commonly found on the frame temple or
      packaging.
      Once entered, click the “Search” button.
    </p>

    <h4>Suggested search queries include:</h4>

    <ul>
      <li>Collection and Model Name or Number
        <p>For example: <strong>Disney Ariel</strong></p>
      </li>


      <li>Collection, Model Name or Number, and Eye Size or Temple
        <p>For example: <strong>Altair A114 53</strong></p>
      </li>

      <li>UPC (12-digit bar code) number from product packaging or temple tag (Do not use dashes or spaces.)
        <p>For example: <strong>0883121277014</strong> for the Marchon Tres Jolie 119 Smokey Grey</p>
      </li>

      <li>SKU number from manufacturer's paperwork
        <p>For example: <strong>BLOTNAV52</strong> for the REM Eyewear Converse Backlot</p>
      </li>
    </ul>

    <h4>Hints:</h4>

    <ul>
      <li>As with any search engine, the more specific your search descriptions, the closer the results will be to the
        exact frame you want.
      </li>

      <li>Use the collection's exact name such as D&G rather than Dolce & Gabbana</li>

      <li>If your first search does not yield any results, try omitting the additional characters associated with the
        frame or style name as different manufacturers shorten collection names differently.
        <p>For example: Rather than Christian Dior CD3137, enter <strong>Dior 3137</strong></p>
      </li>

      <li>If you know that the frame you are searching for is available in many sizes and colors, include
        <strong>more</strong>
        frame details in the query such as the specific eye size and manufacturer's color you are seeking.
        <p>For example: <strong>Flexon 606 54 Light Bronze</strong></p>
      </li>

      <li>Patient's Own Frame (or POF) notations are not required in the frame information fields.
        Please select “Patient Supplied – To Come” from the <strong>Supplier</strong> drop down box to indicate to the
        lab that you will be sending a patient frame. Select the patient frame from the search tool.
      </li>
    </ul>

  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-flat-button mat-dialog-close class="btn btn-primary btn-extra-pad mat-flat-button mat-primary">OK</button>
  </mat-dialog-actions>

</div>
