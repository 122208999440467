<div fxLayout="column" class="patient-selection" fxFlex="100">

  <!-- ############################################################################################################################################################################## -->
  <!-- Coverage Summary Verbiage On Top Of The Product Package Eligible Items -->
  <!-- ############################################################################################################################################################################## -->
  <div fxLayout="row">
    <div class="nested-column-12">
      <h1 id="coverage-summary-title" class="eclaim-header-text margin-top-10 margin-bottom-15">COVERAGE SUMMARY</h1>
      <!-- Date of Service Instructions -->
      <p id="coverage-summary-dos-instructions" class="eclaim-body-text margin-bottom-20">To view eligibility for past
        Date of Service, enter date and click on the Update button.</p>
      <!-- Date of Service Form -->
      <form fxLayout="column" class="eclaim-body-text" novalidate [formGroup]="coverageSummaryFormGroup"
            (ngSubmit)="onCoverageSummaryFormSubmit()">
        <p class="eclaim-body-text margin-bottom-15" id="coverage-summary-for-date-of-service-text"><span
          class="required-label-asterisk">* </span>Date of Service</p>
        <div fxLayout="row">
          <app-date-picker class="required-mat-form-field" id="coverage-summary-date-picker"
                           [datePickerConfiguration]="datePickerConfiguration"></app-date-picker>
          <button mat-stroked-button color="primary" fxFlexOffset="30px" class="claim-progress-action-button"
                  [disabled]="coverageSummaryFormGroup.controls.dateOfService.invalid"
                  (click)="updateEligibilityUsingAsOfDateFromDateOfService()" id="coverage-summary-date-update-button">
            <span>Update</span>
          </button>
        </div>
      </form>
      <!-- Client Messages - Only show if there are client messages in the eligibility data -->
      <div fxLayout="row" class="margin-top-20" *ngIf="hasClientMessages()">
        <div class="container-light-blue">
          <!-- Client Messages -->
          <div fxLayout="row" class="clientMessageList" *ngFor="let clientMessage of clientMessages">
            <mat-icon fxFlex="30px" svgIcon="eclaim-icons:infoCircle"></mat-icon>
            <strong class="client-message-text">{{clientMessage}}</strong>
          </div>
        </div>
      </div>
      <!-- Patient Name -->
      <p id="coverage-summary-patient-name-label" class="eclaim-body-text-bold margin-top-20 margin-bottom-15">Patient
        Name:<span class="margin-left-3">{{getFullName()}}</span></p>
      <p id="coverage-summary-print-vsp-plan-summary-label" class="eclaim-body-text margin-bottom-15">Print <label
        class="action-link" (click)="viewPlanSummary()" id="coverage-summary-vsp-plan-summary-link">VSP Plan
        Summary</label> to give to your patient</p>
      <!-- Instructions -->
      <p *ngIf="!loadingEligibility" id="coverage-summary-select-available-services-text"
         class="eclaim-body-text margin-bottom-20">Select available services to be included in the Authorization.</p>
    </div>
  </div>

  <!-- ############################################################################################################################################################################## -->
  <!-- Coverage Summary Product Package Eligible Items -->
  <!-- ############################################################################################################################################################################## -->
  <div fxLayout="column" *ngIf="!loadingEligibility">
    <div fxLayout="row" *ngFor="let productPackage of eligibilityProductPackages; index as baseId">
      <mat-card class="margin-bottom-30 mat-card nested-column-12"
                id="coverage-summary-product-package-panel-{{productPackage.name}}"
                *ngIf="!productPackage.hasOnlyNonAuthorizableItemsAndNoDescription">
        <mat-card-header>
          <mat-card-title>
            <h1 class="float-left"
                id="coverage-summary-product-package-title-index-{{baseId}}">{{productPackage.businessName}}</h1>
          </mat-card-title>
        </mat-card-header>

        <!-- ############################################################################################################################################################################## -->
        <!-- First Row In The Package Eligible Items -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" *ngIf="!productPackage.hasOnlyNonAuthorizableItems"
             class="margin-top-18 margin-bottom-18 align-label-text-in-center" id="{{baseId}}-table">
          <!-- Empty Label for First Column -->
          <label class="custom-nested-column-1_6"></label>
          <!-- All Available Services -->
          <label fxLayoutAlign="center center" id="coverage-summary-all-available-services-label"
                 class="custom-nested-column-1_8 eclaim-body-text-bold align-label-text-in-center">All Available
            Services</label>
          <!-- Dynamic Service Headers -->
          <div fxLayoutAlign="center center"
               class="custom-nested-column-1_4 eclaim-body-text-bold align-label-text-in-center"
               id="{{baseId}}-service-header" *ngFor="let eligibleItem of productPackage.eligibleItems">
            <label *ngIf="eligibleItem.authorizable">{{eligibleItem.description}}</label>
          </div>
        </div>

        <!-- ################################################## -->
        <!-- Row Divider -->
        <!-- ################################################## -->
        <div fxLayout="row">
          <mat-divider></mat-divider>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Second Row In The Package Eligible Items -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" class="margin-top-18 margin-bottom-18">
          <!-- "Availability" -->
          <label fxLayoutAlign="start center" id="coverage-summary-availability"
                 class="custom-nested-column-1_6 eclaim-body-text-bold">Availability</label>
          <!--Empty Label Under the All Available Services-->
          <label class="custom-nested-column-1_8"></label>
          <!-- Status Description Dynamic Values -->
          <div fxLayoutAlign="center center"
               class="custom-nested-column-1_4 eclaim-body-text align-label-text-in-center"
               *ngFor="let eligibleItem of productPackage.eligibleItems; index as i" id="{{baseId}}-availability-{{i}}">
            <label *ngIf="eligibleItem.authorizable">{{eligibleItem.statusDescription}}</label>
          </div>
        </div>

        <!-- ################################################## -->
        <!-- Row Divider -->
        <!-- ################################################## -->
        <div fxLayout="row">
          <mat-divider></mat-divider>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Third Row In The Package Eligible Items -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row">
          <!-- Authorize Benefit row -->
          <label fxLayoutAlign="start center" id="coverage-summary-authorize-benefit-label"
                 class="custom-nested-column-1_6 margin-top-18 margin-bottom-18 eclaim-body-text-bold">Authorize
            Benefit</label>
          <!-- All Available Services Checkbox -->
          <!-- This Div Was Needed to Address ECR-10776 Issue-->
          <div fxLayoutAlign="center center" class="custom-nested-column-1_8 margin-top-18 margin-bottom-18">
            <mat-checkbox id="{{baseId}}-all-available-services-checkbox"
                          [checked]="productPackage.allAvailableServices"
                          [disabled]="!hasAnyAvailableServices(productPackage)"
                          (change)="toggleAuthorizeBenefitCheckboxForAllProductPackageEligibleItems(productPackage)"
                          (keyup.enter)="toggleAuthorizeBenefitCheckboxForAllProductPackageEligibleItems(productPackage)">
            </mat-checkbox>
          </div>
          <!-- Dynamic Service Checkboxes -->
          <div fxLayoutAlign="center center" class="custom-nested-column-1_4 margin-top-18 margin-bottom-18"
               *ngFor="let eligibleItem of productPackage.eligibleItems; index as i">
            <mat-checkbox *ngIf="eligibleItem.authorizable"
                          id="{{baseId}}-service-checkbox-{{i}}"
                          [checked]="eligibleItem.authorizable && eligibleItem.isSelected"
                          [disabled]="!isAvailableToAuthorize(eligibleItem)"
                          (change)="toggleAuthorizedBenefitCheckboxForSingleEligibleItem(eligibleItem, productPackage)"
                          (keyup.enter)="toggleAuthorizedBenefitCheckboxForSingleEligibleItem(eligibleItem, productPackage)">
            </mat-checkbox>

          </div>
        </div>

        <!-- ################################################## -->
        <!-- Row Divider -->
        <!-- ################################################## -->
        <div fxLayout="row">
          <mat-divider></mat-divider>
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Conditional Fourth Row In The Package Eligible Items -->
        <!-- ############################################################################################################################################################################## -->
        <div *ngIf="productPackage.hasOnlyNonAuthorizableItems" class="non-authroizable-only-description">
          {{productPackage.nonAuthPackageLongDescription}}
        </div>

        <!-- ############################################################################################################################################################################## -->
        <!-- Fifth Row In The Package Eligible Items -->
        <!-- ############################################################################################################################################################################## -->
        <div fxLayout="row" fxLayoutAlign="end center" *ngIf="!productPackage.hasOnlyNonAuthorizableItems"
             id="issue-authorization-button-and-package-name" class="coverage-summary-package-bottom-bar">
          <button mat-flat-button class="nested-column-2 mat-flat-button mat-primary" [disabled]="!hasAnyEligibleItemsSelected(productPackage)"
                  id="{{baseId}}-issue-authorization-button"
                  (click)="issueAuthorization(productPackage)">Issue Authorization
          </button>
        </div>
      </mat-card>
    </div>
  </div>

</div>
