export const ExamConstants = {
  types: {
    group1: [
      {value: '92002', label: '92002 - New Patient - Intermediate Exam'},
      {value: '92004', label: '92004 - New Patient - Comprehensive Exam'},
      {value: '92012', label: '92012 - Existing Patient - Intermediate Exam'},
      {value: '92014', label: '92014 - Existing Patient - Comprehensive Exam'},
      {value: 'S0620', label: 'S0620 - Routine ophthalmological examination including refraction; new patient'},
      {value: 'S0621', label: 'S0621 - Routine ophthalmological examination including refraction; existing patient'}
    ],
    group2: [
      {value: '99202', label: '99202 - New patient - 15-29 mins; MDM straightforward'},
      {value: '99203', label: '99203 - New patient - 30-44 mins; MDM low'},
      {value: '99204', label: '99204 - New patient - 45-59 mins; MDM moderate'},
      {value: '99205', label: '99205 - New paitent - 60-74 mins; MDM high'},
      {value: '99211', label: '99211 - New patient - may not require the presence of a physician, presenting problem(s) are minimal'},
      {value: '99212', label: '99212 - Established patient - 10-19 mins; MDM straightforward'},
      {value: '99213', label: '99213 - Established patient - 20-29 mins; MDM low'},
      {value: '99214', label: '99214 - Established patient - 30-39 mins; MDM moderate'},
      {value: '99215', label: '99215 - Established patient - 40-54 mins; MDM high'}
    ],
  },

  // Control references
  REFRACTION_INDICATOR: 'refractionTestPerformed',
  LENS_INDICATOR: 'lensIndicator',
  FRAME_INDICATOR: 'frameIndicator'
};

export enum LensOrFrame {
  Lens,
  Frame
}
