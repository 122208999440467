<!-- ################################################################################################################################################################################################################################################ -->
<!-- Auth Tracking Page -->
<!-- ################################################################################################################################################################################################################################################ -->

<div fxLayout="column">
  <form novalidate [formGroup]="authTrackingForm">

    <!-- ############################################################################################################################################################################## -->
    <!-- Header Section -->
    <!-- ############################################################################################################################################################################## -->
    <div class="nested-column-12">
      <label fxLayout="row" id="auth-tracking-track-authorizations-header" class="margin-bottom-20 eclaim-header-text">Track Authorizations</label>
    </div>

    <mat-card>
      <mat-card-header id="auth-tracking-card-header">
        <mat-card-title>
          <h1 id="auth-tracking-view-outstanding-authorizations">View Outstanding Authorizations</h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">

        <!--  ############################################################################################################################################################################## -->
        <!--  Radio Buttons Section -->
        <!--  ############################################################################################################################################################################## -->

        <label fxLayout="row" class="nested-column-12 margin-top-30 margin-bottom-20 eclaim-body-text"
               id="auth-tracking-please-start-search-by-selecting-radio-button-label">Start your search by selecting a radio button</label>

        <!--  ########################################################################################################## -->
        <!--  Search By Issue Date -->
        <!--  ########################################################################################################## -->
        <mat-radio-group formControlName="searchByIssueDateRadioButton"
                         fxLayout="row"
                         class="nested-column-12"
                         (change)="onIssueDateRadioButtonChange($event)"
                         [ngClass]="authTrackingForm.controls[formState.searchByIssueDateRadioButton].value === formStateYesNo.Yes ? 'margin-bottom-15' : 'margin-bottom-30'">
          <!--  Search By Issue Date Radio Button -->
          <mat-radio-button #searchByIssueDateRadioButton value="{{formStateYesNo.Yes}}"
                            id="auth-tracking-search-by-issue-date-radio-button"
                            (keyup.enter)="onKeypressEventRadiobutton(searchByIssueDateRadioButton)">{{issueDate}}</mat-radio-button>
        </mat-radio-group>

        <!--   Dynamic Issue Date <-->
        <div fxLayout="column" class="nested-column-3 margin-bottom-10"
             *ngIf="authTrackingForm.controls[formState.searchByIssueDateRadioButton].value === formStateYesNo.Yes">
          <label id="auth-tracking-issue-date-label" class="margin-bottom-10"><span
            class="required-label-asterisk">* </span>Issue Date</label>
          <app-date-picker class="required-mat-form-field" id="auth-tracking-issue-date-picker"
                           [datePickerConfiguration]="datePickerConfigurationIssueDate"></app-date-picker>
        </div>

        <!--  ########################################################################################################## -->
        <!--  Search By Date Range -->
        <!--  ########################################################################################################## -->
        <mat-radio-group formControlName="searchByDateRangeRadioButton"
                         fxLayout="row"
                         class="nested-column-12"
                         (change)="onDateRangeRadioButtonChange($event)"
                         [ngClass]="authTrackingForm.controls[formState.searchByDateRangeRadioButton].value === formStateYesNo.Yes ? 'margin-bottom-15' : 'margin-bottom-20'">
          <!--  Search By Date Range Radio Button -->
          <mat-radio-button #searchByDateRangeRadioButton value="{{formStateYesNo.Yes}}"
                            id="auth-tracking-search-by-date-range-radio-button"
                            (keyup.enter)="onKeypressEventRadiobutton(searchByDateRangeRadioButton)">{{dateRange}}</mat-radio-button>
        </mat-radio-group>

        <div *ngIf="authTrackingForm.controls[formState.searchByDateRangeRadioButton].value === formStateYesNo.Yes">
          <label fxLayout="row" class="nested-column-12 margin-top-10 margin-bottom-20 eclaim-body-text"
                 id="auth-tracking-maximum-date-range-is-three-months-label">Maximum Date Range is 30 days</label>
        </div>

        <!-- Dynamic Date Range <-->
        <div fxLayout="row">
          <div class="nested-column-3 margin-bottom-20"
               *ngIf="authTrackingForm.controls[formState.searchByDateRangeRadioButton].value === formStateYesNo.Yes">
            <!--  Issue Date Start Label -->
            <label id="auth-tracking-issue-date-start-label" class="margin-bottom-10"><span
              class="required-label-asterisk">* </span>Issue Date Start</label>
            <app-date-picker class="required-mat-form-field" id="auth-tracking-issue-date-start-picker"
                             [datePickerConfiguration]="datePickerConfigurationIssueDateStart"></app-date-picker>
          </div>
          <div class="nested-column-3 margin-bottom-20"
               *ngIf="authTrackingForm.controls[formState.searchByDateRangeRadioButton].value === formStateYesNo.Yes">
            <!--  Issue Date End Label -->
            <label id="auth-tracking-issue-date-end-label" class="margin-bottom-10"><span
              class="required-label-asterisk">* </span>Issue Date End</label>
            <app-date-picker class="required-mat-form-field" id="auth-tracking-issue-date-end-picker"
                             [datePickerConfiguration]="datePickerConfigurationIssueDateEnd"></app-date-picker>
          </div>
          <div class="nested-column-6"></div>
        </div>


        <!--  ############################################################################################################################################################################## -->
        <!--  Reset and Search Button Section -->
        <!--  ############################################################################################################################################################################## -->

        <div fxLayout="row">
          <!--  spacer needed to push buttons to the right -->
          <div class="nested-column-8"></div>
          <!--  Reset Button -->
          <div fxLayout="column" class="nested-column-2">
            <button id="auth-tracking-reset-button"
                    type="button"
                    (click)="resetForm()"
                    class="form-control secondary-cta-button">Reset</button>
          </div>
          <!--  Search Button -->
          <div fxLayout="column" class="margin-bottom-20 nested-column-2">
            <button mat-flat-button id="auth-tracking-search-button"
                    (click)="onSearchButtonClick()"
                    class="form-control search-button mat-flat-button mat-primary"
                    type="submit" [disabled]="!hasValidIssueDateStartAndDateEnd">Search</button>
          </div>
        </div>

      </mat-card-content>

    </mat-card>
  </form>


  <!-- ############################################################################################################################################################################## -->
  <!-- Search Results Section -->
  <!-- ############################################################################################################################################################################## -->

  <div *ngIf="hasSearchFinished" fxLayout="column">
    <!--    Render Outstanding Authorizations Search Results-->
    <app-auth-tracking-auth-tracking-search-results [visionServiceRequestSearchResults]="visionServiceRequestSearchResults"></app-auth-tracking-auth-tracking-search-results>

  </div>
</div>
