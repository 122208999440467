import {Component, Input, ViewChild, ViewEncapsulation, AfterViewInit} from '@angular/core';
import {VspIconSnackbarComponent} from '../../secure/icon-snackbar/vsp.icon-snackbar.component';


export enum IconsSnackbarMode {
  Warning = 'warning',
  Error = 'error',
}


@Component({
  selector: 'app-public-error-container',
  templateUrl: './public-error-container.component.html',
  styleUrls: ['./public-error-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublicErrorContainerComponent implements AfterViewInit {

  constructor() { }

  /***** START - PUBLIC MEMBERS *****/
  @ViewChild(VspIconSnackbarComponent, {static: true}) iconSnackbar: VspIconSnackbarComponent;
  /***** END - PUBLIC MEMBERS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  showSnackbar(mode: string, message: string): void {
    if (mode === IconsSnackbarMode.Warning) {
      this.iconSnackbar.showWarningSnackbar(message);
    } else if (mode === IconsSnackbarMode.Error) {
      this.iconSnackbar.showErrorSnackbar(message);
    }
  }
  /***** END - PUBLIC FUNCTIONS *****/


  /***** START - EVENT HANDLERS *****/
  ngAfterViewInit() {

  }
  /***** END - EVENT HANDLERS *****/

}
