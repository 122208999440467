<!-- ########################################################################################################## -->
<!-- Search Result Mat Card Section -->
<!-- ########################################################################################################## -->
<div class="margin-top-40 margin-bottom-20"></div>
<mat-card>
  <!-- Search Results Section Header -->
  <mat-card-header>
    <mat-card-title>
      <h1 class="margin-bottom-0" id="auth-tracking-search-results-header">Search Results</h1>
    </mat-card-title>
  </mat-card-header>

  <!-- Search Result Table -->
  <mat-table [dataSource]="dataSource" id="auth-tracking-result-table" matSort>

    <!-- Authorization Number Container -->
    <ng-container matColumnDef="authorizationNumber">
      <mat-header-cell *matHeaderCellDef id="auth-tracking-result-auth-number-header "
                       class="nested-column-2 eclaim-body-text">Authorization Number</mat-header-cell>
      <mat-cell *matCellDef="let authTracking; index as i" class="nested-column-2"
                (click)="onAuthNumberClick(authTracking.vsrNumber)"
                id="auth-tracking-result-claim-number-data-{{i}}">
        <span class="action-link" id="auth-tracking-result-claim-number-data-span-{{i}}">{{authTracking.vsrNumber}}</span></mat-cell>
    </ng-container>

    <!-- Member Name Container -->
    <ng-container matColumnDef="memberName">
      <mat-header-cell *matHeaderCellDef id="auth-tracking-result-member-name-header"
                       class="nested-column-2 eclaim-body-text">Member Name</mat-header-cell>
      <mat-cell *matCellDef="let authTracking; index as i" class="nested-column-2 eclaim-body-text"
                id="auth-tracking-result-member-name-data-{{i}}">{{getMemberFullName(authTracking)}}</mat-cell>
    </ng-container>

    <!-- Patient Name Container -->
    <ng-container matColumnDef="patientName">
      <mat-header-cell *matHeaderCellDef id="auth-tracking-result-patient-name-header"
                       class="nested-column-2 eclaim-body-text">Patient Name</mat-header-cell>
      <mat-cell *matCellDef="let authTracking; index as i" class="nested-column-2 eclaim-body-text"
                id="auth-tracking-result-patient-name-data-{{i}}">{{getPatientFullName(authTracking)}}</mat-cell>
    </ng-container>

    <!-- Relation Container -->
    <ng-container matColumnDef="relation">
      <mat-header-cell *matHeaderCellDef id="auth-tracking-result-relation-header"
                       class="nested-column-1 eclaim-body-text">Relation</mat-header-cell>
      <mat-cell *matCellDef="let authTracking; index as i" class="nested-column-1 eclaim-body-text"
                id="auth-tracking-result-relation-data-{{i}}">{{authTracking.reifiedPatient.relationToSubscriber}}</mat-cell>
    </ng-container>

    <!-- Product Container -->
    <ng-container matColumnDef="programId">
      <mat-header-cell *matHeaderCellDef id="auth-tracking-result-product-header"
                       class="nested-column-2 eclaim-body-text">Product</mat-header-cell>
      <mat-cell *matCellDef="let authTracking; index as i" class="nested-column-2 eclaim-body-text"
                id="auth-tracking-result-product-data-{{i}}">{{authTracking.businessProductPackageName}}</mat-cell>
    </ng-container>

    <!-- Issue Date Container -->
    <ng-container matColumnDef="dateOfService">
      <mat-header-cell *matHeaderCellDef id="auth-tracking-result-date-of-service-header"
                       class="nested-column-1_5 eclaim-body-text">Issue Date</mat-header-cell>
      <mat-cell *matCellDef="let authTracking; index as i" class="nested-column-1_5 eclaim-body-text"
                id="auth-tracking-result-date-of-service-data-{{i}}">{{formatDateForUi(authTracking.effectiveDate)}}</mat-cell>
    </ng-container>

    <!-- Reissue Authorization Icon Container -->
    <ng-container matColumnDef="reissueAuthorization">
      <mat-header-cell *matHeaderCellDef id="auth-tracking-result-reissueAuthorization-header"
                       class="nested-column-0_5"></mat-header-cell>
      <mat-cell
        id="auth-tracking-result-reissueAuthorization-data-{{i}}"
        class="reports-column nested-column-0_5"
        *matCellDef="let vsr; index as i"
        (click)="onReissueAuthorizationIconClick(vsr)"
        fxLayout="row"
        fxLayoutAlign="center center">
        <mat-icon color="primary"  svgIcon="eclaim-icons:reissueAuthIcon" matTooltip="Reissue Authorization" matTooltipPosition="above"></mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Reports Icon Container -->
    <ng-container matColumnDef="reports">
      <mat-header-cell *matHeaderCellDef id="auth-tracking-result-reports-header"
                       class="nested-column-0_5"></mat-header-cell>
      <mat-cell
        id="auth-tracking-result-reports-data-{{i}}"
        class="reports-column nested-column-0_5"
        *matCellDef="let vsr; index as i"
        (click)="onReportsButtonClick(vsr)"
        fxLayout="row"
        fxLayoutAlign="center center">
        <mat-icon color="primary"  svgIcon="eclaim-icons:reports" class="reports-icon" matTooltip="View Dr. Reports" matTooltipPosition="above"></mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Delete the authorization -->
    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef id="auth-tracking-result-delete-header"
                       class="nested-column-0_5"></mat-header-cell>
      <mat-cell
        id="auth-tracking-result-delete-data-{{i}}"
        class="trash-column nested-column-0_5"
        *matCellDef="let vsr; index as i"
        (click)="deleteAuthorization(vsr)"
        fxLayout="row"
        fxLayoutAlign="center center">
        <mat-icon color="primary" style="transform: rotate(180deg)" svgIcon="eclaim-icons:trash" class="trash-icon icon-tooltip" matTooltip="Delete" matTooltipPosition="above"></mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></mat-row>

  </mat-table>

  <div fxLayout="row" fxLayoutAlign="center center" [hidden]="dataSource == null || dataSource.data.length <= 5">
    <mat-paginator [pageSizeOptions]="[25, 5, 10, 50]" showFirstLastButtons></mat-paginator>
  </div>

  <!-- No Results Found Section -->
  <div *ngIf="isDataSourceNullOrUndefined()" class="no-records"
       fxLayout="row" fxLayoutAlign="center center" id="auth-tracking-no-result">No Results Found</div>

</mat-card>
