import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {ValidSearchCombinationsComponent} from './valid-search-combinations/valid-search-combinations.component';
import {
  Address,
  BusinessMemberPoliciesResults,
  ClientInfo,
  Subscriber,
  SubscriberSearchResult
} from '../../models/consumer';
import {Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {CustomValidatorsService} from '../../common/services/support/custom-validators/custom-validators.service';
import {InputMaskService} from '../../common/services/support/input-mask/input-mask.service';
import {
  DateUtility, isNullOrUndefined, isStringNullUndefinedOrEmptyWithTrim,
  openDialog,
  trimWhitespaceFromControlValue
} from '../../common/utility';
import {getFormattedFullName, isStringNullUndefinedOrEmpty} from '../../common/utility';
import {Subscription} from 'rxjs';
import {ErrorWrapperConfig} from '../../common/components/error-wrapper/error-wrapper.component';
import {combineLatest} from 'rxjs';
import {AuthorizationsService} from '../../common/services/data-model/app/authorizations/authorizations.service';
import {
  ApplicationConstants,
  ErrorTypes,
  SessionStorageKeys,
  UserTypeQualifier
} from '../../common/constants/application.constants';
import {DatePickerConfiguration} from '../../common/components/date-picker/date-picker.component';
import {ConsumerService} from '../../common/services/data-model/app/consumer/consumer.service';
import {ConsumerSearchRequest} from '../../models/ConsumerSearchRequest';

@Component({
  selector: 'app-member-search',
  templateUrl: './member-search.component.html',
  styleUrls: ['./member-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MemberSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public dialog: MatDialog,
    private consumerService: ConsumerService,
    private authorizationsService: AuthorizationsService,
    private router: Router,
    private inputMaskService: InputMaskService,
    private formBuilder: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
  ) {
  }

  /***** START - PRIVATE MEMBERS *****/
  private _alerts = {
    memberPolicySearchError: null
  };
  // define the model value to API query parameter mappings
  private MEMBER_POLICIES_API_MAP = {
    memberId: 'policyid',
    expandedId: 'expid',
    lastFour: 'lastfour',
    firstName: 'first',
    lastName: 'last',
    dateOfBirth: 'partialDob'
  };
  private searchCombination = new ConsumerSearchRequest();
  private observableSubscriptions: Subscription[] = [];
  /***** END - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/
  @ViewChild('dateOfService', {read: ElementRef, static: true}) dateOfService: ElementRef;
  @ViewChild('dateOfBirth', {read: ElementRef, static: true}) dateOfBirth: ElementRef;
  @ViewChild('lastFour', {read: ElementRef, static: true}) lastFour: ElementRef;
  @ViewChild(MatTable, {static: true}) memberSearchResultTable: MatTable<any>;
  @ViewChild(MatSort, {static: true}) memberSearchResultSort: MatSort;

  errorWrapperConfig = {
    lastFour: new ErrorWrapperConfig(),
    memberId: new ErrorWrapperConfig(),
    memberFirstName: new ErrorWrapperConfig(),
    memberLastName: new ErrorWrapperConfig(),
  };
  datePickerConfigurationDateOfService: DatePickerConfiguration;
  datePickerConfigurationDateOfBirth: DatePickerConfiguration;

  memberSearchForm: FormGroup;
  searchResults: BusinessMemberPoliciesResults[] = [];
  minDate = ApplicationConstants.minDate;
  todayDate = ApplicationConstants.todaysDate;
  columnsToDisplay: string[] = ['name', 'dateOfBirth', 'cityState', 'clientName'];
  dataSource: MatTableDataSource<BusinessMemberPoliciesResults> = new MatTableDataSource();
  searchPerformed: boolean = false;

  private isValidFormControlValue = (formControl: AbstractControl) => (formControl.value && formControl.valid);

  /**
   * Conditions required for a valid search combo:
   * 1. Full Member ID only
   * 2. Last 4 SSN, Member Last Name, and Member First Name
   * 3. Last 4 SSN, Member Last Name, Member First Name, and Date of Birth
   * 4. Last 4 SSN, Member Last Name, and Date of Birth
   * 5. Member First Name, Member Last Name, and Date of Birth
   * @returns {boolean}
   */
  get hasValidSearchCombination(): boolean {
    let valid: boolean = false;
    if (!this.memberSearchForm.valid) {
      valid = false;
    } else {
      const isValidMemberId = this.isValidFormControlValue(this.memberSearchForm.controls.memberId);
      const isValidNonMemberIdSearchWithDob = this.isValidFormControlValue(this.memberSearchForm.controls.lastFour) &&
        this.isValidFormControlValue(this.memberSearchForm.controls.lastName) &&
        (this.isValidFormControlValue(this.memberSearchForm.controls.dateOfBirth));
      const isValidNonMemberIdSearchWithFirstName = this.isValidFormControlValue(this.memberSearchForm.controls.lastFour) &&
        this.isValidFormControlValue(this.memberSearchForm.controls.lastName) &&
        this.isValidFormControlValue(this.memberSearchForm.controls.firstName);
      // This was added for VEC-3397 #5 First Name, Last Name and Date of Birth
      const isValidFirstNameLastNameDobSearch = this.isValidFormControlValue(this.memberSearchForm.controls.firstName) &&
        this.isValidFormControlValue(this.memberSearchForm.controls.lastName) &&
        this.isValidFormControlValue(this.memberSearchForm.controls.dateOfBirth);
      // Date of Service is always required
      if (!this.isValidFormControlValue(this.memberSearchForm.controls.dateOfService)) {
        valid = false;
        // if we have a Member ID, that is valid
      } else if (isValidMemberId) {
        valid = true;
        // if we don't have a member ID, all other valid combinations require an SSN and Last Name
      } else if (isValidNonMemberIdSearchWithDob) {
        valid = true;
        // now we need at least a date of birth OR a first name to complete the valid combination
      } else if (isValidNonMemberIdSearchWithFirstName) {
        valid = true;
      // This was added for VEC-3397 #5 First Name, Last Name and Date of Birth
      } else if (isValidFirstNameLastNameDobSearch) {
        valid = true;
      }
    }
    // if the above conditions are met, we're valid
    return valid;
  }

  get searchingByFullIdOnly(): boolean {
    let fieldOtherThanIdHasValue = false;
    ['firstName', 'lastName', 'lastFour', 'dateOfBirth'].forEach(fieldName => {
      fieldOtherThanIdHasValue = (fieldOtherThanIdHasValue || !isStringNullUndefinedOrEmpty(this.memberSearchForm.get(fieldName).value));
    });

    return this.searchCombination && !isStringNullUndefinedOrEmpty(this.searchCombination._policyId) && fieldOtherThanIdHasValue;
  }

  public sortSearchResultsInSpecificOrder(searchResults: BusinessMemberPoliciesResults[]): BusinessMemberPoliciesResults[] {
    let processedSearchResults: BusinessMemberPoliciesResults[] = searchResults;
    if (!isNullOrUndefined(searchResults) && searchResults.length > 0) {
      const searchResultsWithSavingsPass: BusinessMemberPoliciesResults[] = [];
      const searchResultsWithNoneSavingsPass: BusinessMemberPoliciesResults[] = [];
      // Separate client names that contain 'Savings Pass' from the rest of the results.
      searchResults.forEach(businessMemberPoliciesResults => {
        if (!isNullOrUndefined(businessMemberPoliciesResults)) {
          if (!isNullOrUndefined(businessMemberPoliciesResults.clientInfo)
            && !isStringNullUndefinedOrEmptyWithTrim(businessMemberPoliciesResults.clientInfo.clientName)
            && businessMemberPoliciesResults.clientInfo.clientName.toUpperCase().includes(ApplicationConstants.savingsPass)) {
            searchResultsWithSavingsPass.push(businessMemberPoliciesResults);
          } else {
            searchResultsWithNoneSavingsPass.push(businessMemberPoliciesResults);
          }
        }
      });
      // Override 'processedSearchResults' list only if we have results placed in either of the new lists.
      if (searchResultsWithNoneSavingsPass.length > 0 || searchResultsWithSavingsPass.length > 0) {
        // Reset results to empty array if new lists have values.
        processedSearchResults = [];
        // Combine both lists making sure the client names that contain 'Savings Pass' are last.
        // None Savings Pass First.
        searchResultsWithNoneSavingsPass.forEach(processedSearchResultsWithNoneSavingsPass => {
          processedSearchResults.push(processedSearchResultsWithNoneSavingsPass);
        });
        // Savings Pass Last
        searchResultsWithSavingsPass.forEach(processedSearchResultsWithSavingsPass => {
          processedSearchResults.push(processedSearchResultsWithSavingsPass);
        });
      }
    }
    return processedSearchResults;
  }

  /***** END - PUBLIC MEMBERS *****/


  /***** START - PRIVATE FUNCTIONS *****/
  private buildDatePickerConfigurations(): void {
    this.datePickerConfigurationDateOfBirth = {
      control: this.memberSearchForm.controls.dateOfBirth,
      controlName: 'dateOfBirth',
      errorWrapperId: {
        defaultValidations: 'member-search-dob-error',
        minDate: 'member-search-dob-min-date-error'
      },
      attributes: {
        id: 'member-search-dob'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a Date of Birth'
        },
        {
          validatorType: ErrorTypes.NoFutureDate,
          errorMessage: 'Date of Birth cannot be a future date'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Date of Birth must be in MM/DD/YYYY format'
        },
        {
          validatorType: ErrorTypes.MinDate,
          errorMessage: `Date of Birth cannot be before ${DateUtility.buildFriendlyDateFromJsDate(this.minDate)}`
        }
      ]
    };

    this.datePickerConfigurationDateOfService = {
      control: this.memberSearchForm.controls.dateOfService,
      controlName: 'dateOfService',
      errorWrapperId: {
        defaultValidations: 'member-search-dos-error',
        minDate: 'member-search-dos-min-date-error'
      },
      attributes: {
        id: 'member-search-dos'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter a Date of Service'
        },
        {
          validatorType: ErrorTypes.NoFutureDate,
          errorMessage: 'Date of Service cannot be a future date'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Date of Service must be in MM/DD/YYYY format'
        },
        {
          validatorType: ErrorTypes.MinDate,
          errorMessage: `Date of Service cannot be before ${DateUtility.buildFriendlyDateFromJsDate(this.minDate)}`
        }
      ]
    };
  }

  private buildForm(): void {
    this.memberSearchForm = this.formBuilder.group({
      dateOfService: [DateUtility.buildFriendlyDateFromJsDate(this.todayDate), [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(ApplicationConstants.minDate)]],
      firstName: [undefined, [Validators.pattern(ApplicationConstants.ValidNameRegex)]],
      lastName: [undefined, [Validators.pattern(ApplicationConstants.ValidNameRegex)]],
      dateOfBirth: [undefined, [this.customValidatorsService.MinDate(this.minDate), this.customValidatorsService.dateFormatAndValidity]],
      lastFour: [undefined, [Validators.minLength(4), Validators.maxLength(4)]],
      memberId: [undefined, [this.customValidatorsService.AlphaNumeric, this.customValidatorsService.validMemberID]],
    });
    this.memberSearchForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe((viewModel) => {
    });
  }

  private buildErrorWrapperConfig(): void {
    this.errorWrapperConfig = {
      memberFirstName: {
        control: this.memberSearchForm.controls.firstName,
        errors: [{
          validatorType: ErrorTypes.Pattern,
          errorMessage: ApplicationConstants.invalidFirstNameMessage(UserTypeQualifier.Member)
        },
        ]
      },
      memberLastName: {
        control: this.memberSearchForm.controls.lastName,
        errors: [
          {
            validatorType: ErrorTypes.Pattern,
            errorMessage: ApplicationConstants.invalidLastNameMessage(UserTypeQualifier.Member)
          },
        ]
      },
      lastFour: {
        control: this.memberSearchForm.controls.lastFour,
        errors: [{
          validatorType: ErrorTypes.MinLength,
          errorMessage: `Last 4 SSN requires 4 digits`
        }, {
          validatorType: ErrorTypes.MaxLength,
          errorMessage: 'Last 4 SSN requires 4 digits'
        }]
      },
      memberId: {
        control: this.memberSearchForm.controls.memberId,
        errors: [{
          validatorType: ErrorTypes.AlphaNumeric,
          errorMessage: ApplicationConstants.errorMessages.memberIdAlphaNumericErrorMessage
        }, {
          validatorType: ErrorTypes.InvalidMemberId,
          errorMessage: ApplicationConstants.errorMessages.memberIdInvalidMemberIdErrorMessage
        }]
      }
    };
  }

  private buildInputMasks(): void {
    this.inputMaskService.createInputMask(this.lastFour.nativeElement, '9999', {placeholder: ''});
  }

  private clearSearchResults(): void {
    this.searchResults = [];
    this.dataSource.data = this.searchResults;
    this.searchPerformed = false;
  }

  // checks if we have a valid search combination and returns an object with the properties to search by
  // (or returns null if not a valid search combination)
  private setSearchCombination() {
    this.searchCombination = new ConsumerSearchRequest();

    const dateOfService = this.memberSearchForm.controls.dateOfService.value;
    const memberId = this.memberSearchForm.controls.memberId.value;
    const lastFour = this.memberSearchForm.controls.lastFour.value;
    const dateOfBirth = this.memberSearchForm.controls.dateOfBirth.value;
    const firstName = this.memberSearchForm.controls.firstName.value;
    const lastName = this.memberSearchForm.controls.lastName.value;

    // Pass dateOfService to searchCombination asOfDate
    this.searchCombination._asOfDate = dateOfService;
    // if we have a Member ID, just return that. when a member ID is present, we ignore all other data
    if (memberId) {
      if (memberId.length !== 9 || this.isFullIdNineCharactersInLengthAndAlphaNumeric(memberId)) {
        this.searchCombination._expandedId = memberId;
      } else {
        this.searchCombination._policyId = memberId;
      }
    } else {
      if (!isStringNullUndefinedOrEmpty(dateOfBirth) && !isNullOrUndefined(dateOfBirth)) {
        this.searchCombination._dob = dateOfBirth;
      }
      if (!isStringNullUndefinedOrEmpty(firstName) && !isNullOrUndefined(firstName)) {
        this.searchCombination._firstName = firstName;
      }
      if (!isStringNullUndefinedOrEmpty(lastFour) && !isNullOrUndefined(lastFour)) {
        this.searchCombination._lastFour = lastFour;
      }
      if (!isStringNullUndefinedOrEmpty(lastName) && !isNullOrUndefined(lastName)) {
        this.searchCombination._lastName = lastName;
      }
    }
  }

  isFullIdNineCharactersInLengthAndAlphaNumeric(fullId: string): boolean {
    return (fullId.length === 9 && new RegExp(/^\d*[a-zA-Z][a-zA-Z0-9]*$/).test(fullId));
  }

  // returns true if we are doing a member ID search and other data is entered on the form as well
  // (used to show the "Searching by Full ID Only" message while searching)
  private searchCombinationIncludesMemberId(): boolean {
    return this.searchCombination && !isStringNullUndefinedOrEmpty(this.searchCombination._policyId);
  }

  private setMemberSearchResultData(subscriberSearchResults: Subscriber[]): void {
    if (subscriberSearchResults) {
      let nameContainsSavingsPass: boolean = false;
      subscriberSearchResults.forEach(subscriberSearchResult => {
        let homeAddress: Address = null;
        const clientInfo: ClientInfo = new ClientInfo();
        // Get the home address of the subscriber. If the home type does not exist, grab the unknown type, which should be the home address (Contact consumer RTB for inquiries)
        if (subscriberSearchResult.address) {
          homeAddress = subscriberSearchResult.address.find(address => (address.type === ApplicationConstants.homeAddressTypeCode ||
            address.type === ApplicationConstants.unknownAddressTypeCode) &&
            !isStringNullUndefinedOrEmpty(address.city) &&
            !isStringNullUndefinedOrEmpty(address.state));
        }
        if (subscriberSearchResult.clientName === ApplicationConstants.notFound) {
          clientInfo.clientName = '';
        } else {
          clientInfo.clientName = subscriberSearchResult.clientName;
          // Per ECLAIM-187 This flag was added to be used later in a sorting condition. We only want to sort if all the conditions are met.
          if (!isStringNullUndefinedOrEmptyWithTrim(subscriberSearchResult.clientName) && subscriberSearchResult.clientName.toUpperCase().includes(ApplicationConstants.savingsPass)) {
            nameContainsSavingsPass = true;
          }
        }
        const memberSearchResult: BusinessMemberPoliciesResults = {
          consumerId: subscriberSearchResult.membersConsumerId,
          firstName: subscriberSearchResult.firstName,
          lastName: subscriberSearchResult.lastName,
          memberName: getFormattedFullName(subscriberSearchResult.firstName, subscriberSearchResult.lastName),
          dateOfBirth: subscriberSearchResult.partialDob ? DateUtility.formatMemberPartialDOB(subscriberSearchResult.partialDob) : null,
          cityState: homeAddress ? homeAddress.city + ', ' + homeAddress.state : null,
          clientInfo: clientInfo,
          memberPoliciesLink: subscriberSearchResult.memberPolicyLink,
          businessApiSecureMemberPolicyLink: subscriberSearchResult.businessApiSecureMemberPolicyLink,
          visionServiceRequestLink: subscriberSearchResult.visionServiceRequestsLink,
          clientId: subscriberSearchResult.clientId,
          clientDivisionId: subscriberSearchResult.divId,
          subscriberConsumerId: subscriberSearchResult.membersConsumerId

        };
        this.searchResults.push(memberSearchResult);
      });
      // Per ECLAIM-187 we want to make sure if there is any members underneath a Client Name that has "Savings Pass" in it, that result should be the LAST result shown.
      // NOTE: We only want to do this if the searchResult is greater or equal to two items and if any of the client names contain 'Savings Pass'.
      if (!isNullOrUndefined(this.searchResults) && this.searchResults.length >= 2 && nameContainsSavingsPass) {
        this.searchResults = this.sortSearchResultsInSpecificOrder(this.searchResults);
      }
    } else {
      this.searchResults = [];
    }
    this.dataSource.data = this.searchResults;
  }

  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.buildForm();
    this.buildErrorWrapperConfig();
    this.buildDatePickerConfigurations();
    // watch for member search results to update page with
    this.observableSubscriptions.push(this.consumerService.onMemberSearch.subscribe(
      (subscriberSearchResults: SubscriberSearchResult) => {
        if (subscriberSearchResults !== undefined) {
          this.setMemberSearchResultData(subscriberSearchResults.subscribers);
          this.searchPerformed = true;
        }
        this.dialog.closeAll();
      }));
    this.buildInputMasks();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.memberSearchResultSort;
  }

  ngOnDestroy(): void {
    // Clear the current result set stored in the obeservable so it's empty when user returns/reloads componenet.
    this.consumerService.onMemberSearch.next(undefined);
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onResetButtonClick(): void {
    this.memberSearchForm.reset();
    this.memberSearchForm.markAsUntouched();
    this.memberSearchForm.updateValueAndValidity();
    this.memberSearchForm.controls.dateOfService.setValue(DateUtility.buildFriendlyDateFromJsDate(this.todayDate));
    // clear any search results
    this.clearSearchResults();
    // clear the flag to remove the "Searching by Full ID Only" element
  }

  // TODO Refactor the data flow for member search to use an observable instead of a behavior subject
  onSearchButtonClick(): void {
    // set the searching flag to true
    openDialog('', this.dialog);
    // used to render the search result portion. should not set it back to false.
    this.setSearchCombination();
    // clear any search results
    this.clearSearchResults();
    // call the search API
    // If the search criteria contains last 4 ssn, per VEC-3397, we must do additional search combinations if no results return from the first search.
    if (!isStringNullUndefinedOrEmpty(this.searchCombination._lastFour)) {
      this.consumerService.searchForMembersWithLastFourInTheSearchCriteria(this.searchCombination);
    } else {
      this.consumerService.searchMembersOnly(this.searchCombination);
    }
  }

  onValidSearchCombinationsLinkClick(): void {
    // show the Valid Search Combinations modal
    this.dialog.open(ValidSearchCombinationsComponent, {
      width: '700px',
      panelClass: 'eclaim-popup-modal'
    });
  }

  onMemberClick(membersSearchResult: BusinessMemberPoliciesResults): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('', this.dialog);
    // call the search vision service request API
    const visionServiceRequestSearchCall = this.authorizationsService.searchAuthorizations(membersSearchResult.visionServiceRequestLink.href);

    combineLatest(visionServiceRequestSearchCall,
      (searchAuthorizationsResponse) => ({
        searchAuthorizationsResponse
      }))
      .subscribe(responses => {
        if (responses.searchAuthorizationsResponse) {
          const dateOfService = this.memberSearchForm.controls.dateOfService.value;

          // Set the Member Policy Key (i.e. subscriber client id, subscriber division id, subscriber consumer id) in session storage so it can be used for the VPS report
          sessionStorage.setItem(SessionStorageKeys.SubscriberClientId, membersSearchResult.clientId);
          sessionStorage.setItem(SessionStorageKeys.SubscriberDivisionId, membersSearchResult.clientDivisionId);
          sessionStorage.setItem(SessionStorageKeys.SubscriberConsumerId, membersSearchResult.subscriberConsumerId);
          sessionStorage.setItem(SessionStorageKeys.MemberDateOfService, dateOfService);

          // change asOfDate used in calling consumer business api with DateOfService on Member Search
          const businessApiSecureMemberPolicyLinkAsOfDate = this.consumerService.changeUrlWithSpecifiedAsOfDate(membersSearchResult.businessApiSecureMemberPolicyLink.href, dateOfService);

          this.consumerService.retrieveBusinessApiMemberPolicyAddDependentsLink(businessApiSecureMemberPolicyLinkAsOfDate).subscribe(() => {
            this.dialog.closeAll();
            this.router.navigate([[ApplicationConstants.routing.secure.baseRoute, ApplicationConstants.routing.secure.patientSelection].join('/')]);
          });
        } else {
          this.dialog.closeAll();
        }
      });
  }

  /***** END - EVENT HANDLERS *****/

  // removes space if name starts with space
  trimNameControl(control: AbstractControl): void {
    trimWhitespaceFromControlValue(control);
  }
}
