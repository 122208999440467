import {Injectable, isDevMode} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class MessageService {


  constructor() {}

  /***** START - PRIVATE MEMBERS *****/
  private _currentErrorMessageString: string;
  private _isSubmitAndGatewayTimeOutHappenedInClaimForm: boolean = false;
  /***** END - PRIVATE MEMBERS *****/

  /***** START - PUBLIC MEMBERS *****/
  messages: string[] = [];
  onShowConfirmationSnackbar = new BehaviorSubject(undefined);
  onShowWarningSnackbar = new BehaviorSubject(undefined);
  onShowErrorSnackbar = new BehaviorSubject(undefined);
  onShowSessionAlmostExpiredModal = new BehaviorSubject(false);
  onUnauthorizedError = new BehaviorSubject(false);
  /***** END - PUBLIC MEMBERS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  get currentErrorMessage(): string {
    return this.onShowErrorSnackbar.value;
  }

  get currentErrorMessageString(): string {
    return this._currentErrorMessageString;
  }

  set currentErrorMessageString(value: string) {
    this._currentErrorMessageString = value;
  }

  get isSubmitAndGatewayTimeOutHappenedInClaimForm(): boolean {
    return this._isSubmitAndGatewayTimeOutHappenedInClaimForm;
  }

  set isSubmitAndGatewayTimeOutHappenedInClaimForm(value: boolean) {
    this._isSubmitAndGatewayTimeOutHappenedInClaimForm = value;
  }

  setErrorMessage(showSnackbar: boolean, errorMessage: string): void {
    showSnackbar ? this.showErrorSnackbar(errorMessage) : this.currentErrorMessageString = errorMessage;
  }

  add(message: string, messageObject?: any): void {
    this.messages.push(message);
    if (isDevMode() === true) {
    }
  }

  clear(): void {
    this.messages = [];
    this.onShowWarningSnackbar = new BehaviorSubject(undefined);
    this.onShowErrorSnackbar = new BehaviorSubject(undefined);
  }

  showConfirmationSnackbar(message: string): void {
    this.onShowConfirmationSnackbar.next(message);
  }

  showWarningSnackbar(message: string): void {
    this.onShowWarningSnackbar.next(message);
  }

  hideWarningSnackbar(): void {
    this.onShowWarningSnackbar.next(undefined);
  }

  showErrorSnackbar(message: string): void {
    this.onShowErrorSnackbar.next(message);
  }

  hideErrorSnackbar(): void {
    this.onShowErrorSnackbar.next(undefined);
  }

  emitUnauthorizedError(isError: boolean): void {
    this.onUnauthorizedError.next(isError);
  }

  openSessionAlmostExpiredModal(): void {
    this.onShowSessionAlmostExpiredModal.next(true);
  }

  closeSessionAlmostExpiredModal(): void {
    this.onShowSessionAlmostExpiredModal.next(false);
  }
  /***** END - PUBLIC FUNCTIONS *****/

}
