import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidatorsService} from '../../common/services/support/custom-validators/custom-validators.service';
import {MatRadioChange} from '@angular/material/radio';
import {DateUtility, isNullOrUndefined, isStringNullUndefinedOrEmpty, onKeypressEventRadiobutton, openDialog} from '../../common/utility';
import {ApplicationConstants, ErrorTypes} from '../../common/constants/application.constants';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FormStateYesNo} from '../../common/enum/form-state-yes-no';
import {DatePickerConfiguration} from '../../common/components/date-picker/date-picker.component';
import {ExternalServiceLocationService} from '../../common/services/data-model/app/external-service-location/external-service-location.service';
import {CookieService} from 'ngx-cookie-service';
import {MatDialog} from '@angular/material/dialog';
import {AuthorizationsService} from '../../common/services/data-model/app/authorizations/authorizations.service';
import {VisionServiceRequest} from '../../models/VisionServiceRequestResults';
import {AuthTrackingSearchRequest} from '../../models/AuthTrackingSearchRequest';

enum FormState {
  searchByIssueDateRadioButton = 'searchByIssueDateRadioButton',
  searchByDateRangeRadioButton = 'searchByDateRangeRadioButton'
}

@Component({
  selector: 'app-auth-tracking',
  templateUrl: './auth-tracking.component.html',
  styleUrls: ['./auth-tracking.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AuthTrackingComponent implements OnInit {
  /***** START - PUBLIC MEMBERS *****/
  @ViewChild('issueDateControl', {read: ElementRef, static: true}) issueDateControl: ElementRef;

  authTrackingForm: FormGroup;
  /***** START - PRIVATE MEMBERS *****/

  /***** END - PRIVATE MEMBERS *****/
  issueDate: string = 'Search By Issue Date';
  dateRange: string = 'Search By Date Range';
  formState = FormState;
  formStateYesNo = FormStateYesNo;
  onKeypressEventRadiobutton = onKeypressEventRadiobutton;
  datePickerConfigurationIssueDate: DatePickerConfiguration;
  datePickerConfigurationIssueDateStart: DatePickerConfiguration;
  datePickerConfigurationIssueDateEnd: DatePickerConfiguration;
  visionServiceRequestSearchResults: VisionServiceRequest[];
  minDate = ApplicationConstants.minDate;
  hasSearchFinished: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
    private dialog: MatDialog,
    private externalServiceLocationService: ExternalServiceLocationService,
    private cookieService: CookieService,
    private authorizationService: AuthorizationsService
  ) {
  }

  public resetIssueDateFields(): void {
    this.authTrackingForm.controls.issueDate.reset(ApplicationConstants.emptyString);
    this.authTrackingForm.controls.issueDateStart.reset(ApplicationConstants.emptyString);
    this.authTrackingForm.controls.issueDateEnd.reset(ApplicationConstants.emptyString);
  }

  public resetForm(): void {
    this.resetIssueDateFields();
    this.clearSearchResults();
    this.authTrackingForm.controls.searchByIssueDateRadioButton.setValue(this.formStateYesNo.Yes);
    this.authTrackingForm.controls.searchByDateRangeRadioButton.setValue(this.formStateYesNo.No);
    this.hasSearchFinished = false;
  }

  public onSearchButtonClick(): void {
    // Clear existing results
    this.clearSearchResults();
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Searching For Authorizations', this.dialog);
    // Search Outstanding Authorizations
      this.authorizationService.searchOutstandingAuthorizations(this.buildClaimSummaryRequest()).subscribe(authSearchResults => {
          if (!isNullOrUndefined(authSearchResults)) {
            this.visionServiceRequestSearchResults = authSearchResults;
            this.hasSearchFinished = true;
          }
        }, () => {
          // on observer error
          this.dialog.closeAll();
        },
        () => {
          // Close dialog for LoadingModalComponent - Spinner
          this.dialog.closeAll();
        });
  }

  public buildClaimSummaryRequest(): AuthTrackingSearchRequest {
    const authTrackingSearchRequest: AuthTrackingSearchRequest = new AuthTrackingSearchRequest();
    if (!isStringNullUndefinedOrEmpty(this.authTrackingForm.controls.issueDate.value)) {
      authTrackingSearchRequest._issueDate = this.authTrackingForm.controls.issueDate.value;
    } else if (!isStringNullUndefinedOrEmpty(this.authTrackingForm.controls.issueDateStart.value) &&
      !isStringNullUndefinedOrEmpty(this.authTrackingForm.controls.issueDateEnd.value) ) {
      authTrackingSearchRequest._issueDateStart = this.authTrackingForm.controls.issueDateStart.value;
      authTrackingSearchRequest._issueDateEnd = this.authTrackingForm.controls.issueDateEnd.value;
    }
    return authTrackingSearchRequest;
  }

  /**
   * Dates required for search:
   * 1. Issue Date Start
   * 2. Issue Date End
   * @returns {boolean}
   */
  get hasValidIssueDateStartAndDateEnd(): boolean {
    let validIssueDates: boolean = false;
    // Issue Date
    const isValidIssueDate = this.isValidFormControlValue(this.authTrackingForm.controls.issueDate);
    // Issue Date Start
    const isValidIssueDateStart = this.isValidFormControlValue(this.authTrackingForm.controls.issueDateStart);
    // Issue Date End
    const isValidIssueDateEnd = this.isValidFormControlValue(this.authTrackingForm.controls.issueDateEnd);

    if (isValidIssueDate || (isValidIssueDateStart && isValidIssueDateEnd)) {
      validIssueDates = true;
    }
    return validIssueDates;
  }

  /***** START - PROPERTY ACCESSORS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.buildForm();
    this.buildDatePickerConfiguration();
  }

  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {string} newVal - FormState.Yes || FormState.No
   */
  onIssueDateRadioButtonChange(newVal: MatRadioChange): void {
    const issueDateRadioButtonChangeValue: string = newVal.value;
    if (issueDateRadioButtonChangeValue === this.formStateYesNo.Yes) {
      this.resetIssueDateFields();
      this.authTrackingForm.controls.searchByIssueDateRadioButton.setValue(this.formStateYesNo.Yes);
      this.authTrackingForm.controls.searchByDateRangeRadioButton.setValue(this.formStateYesNo.No);
    }
  }

  private isValidFormControlValue = (formControl: AbstractControl) => (formControl.value && formControl.valid);

  private clearSearchResults(): void {
    this.visionServiceRequestSearchResults = undefined;
  }

  /***** END - PRIVATE FUNCTIONS *****/

  /**
   * This is bound to the valueChange event, so parameter will be the new value of the control
   * @param {string} newVal - FormState.Yes || FormState.No
   */
  onDateRangeRadioButtonChange(newVal: MatRadioChange): void {
    const dateRangeRadioButtonChangeValue: string = newVal.value;
    if (dateRangeRadioButtonChangeValue === this.formStateYesNo.Yes) {
      this.resetIssueDateFields();
      this.authTrackingForm.controls.searchByIssueDateRadioButton.setValue(this.formStateYesNo.No);
      this.authTrackingForm.controls.searchByDateRangeRadioButton.setValue(this.formStateYesNo.Yes);
    }
  }

  /***** END - PUBLIC MEMBERS *****/
  private buildForm(): void {
    const issueDate: Date = undefined;
    this.authTrackingForm = this.formBuilder.group({
      issueDate: [DateUtility.buildFriendlyDateFromJsDate(issueDate), [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(DateUtility.getTwoYearAgoDate())]],
      issueDateStart: [DateUtility.buildFriendlyDateFromJsDate(issueDate), [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(DateUtility.getTwoYearAgoDate())]],
      issueDateEnd: [DateUtility.buildFriendlyDateFromJsDate(issueDate), [Validators.required, this.customValidatorsService.dateFormatAndValidity, this.customValidatorsService.MinDate(DateUtility.getTwoYearAgoDate())]],
      searchByIssueDateRadioButton: this.formStateYesNo.Yes,
      searchByDateRangeRadioButton: this.formStateYesNo.No
    });
    this.authTrackingForm.valueChanges.pipe(
      debounceTime(ApplicationConstants.userInteractionDebounceTime),
      distinctUntilChanged()
    ).subscribe(() => {
    });
  }

  private buildDatePickerConfiguration(): void {
    this.datePickerConfigurationIssueDate = {
      control: this.authTrackingForm.controls.issueDate,
      minDate: DateUtility.getTwoYearAgoDate(),
      controlName: 'issueDate',
      errorWrapperId: {
        defaultValidations: 'auth-tracking-issue-date-error',
        minDate: 'issue-date-min-date-error'
      },
      attributes: {
        id: 'issue-date',
        datePickerId: 'issue-date-picker',
        datePickerToggleId: 'issue-date-picker-toggle',
        name: 'issueDate'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the issue date'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Issue Date must be in MM/DD/YYYY format'
        },
        {
          validatorType: ErrorTypes.MinDate,
          errorMessage: 'Unable to retrieve authorizations issued over two years'
        }
      ]
    };
    this.datePickerConfigurationIssueDateStart = {
      control: this.authTrackingForm.controls.issueDateStart,
      minDate: DateUtility.getTwoYearAgoDate(),
      controlName: 'issueDateStart',
      errorWrapperId: {
        defaultValidations: 'auth-tracking-issue-date-start-error',
        minDate: 'issue-date-start-min-date-error'
      },
      attributes: {
        id: 'issue-date-start',
        datePickerId: 'issue-date-start-picker',
        datePickerToggleId: 'issue-date-start-picker-toggle',
        name: 'issueDateStart'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the issue date start'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Issue Date Start must be in MM/DD/YYYY format'
        },
        {
          validatorType: ErrorTypes.MinDate,
          errorMessage: 'Unable to retrieve authorizations issued over two years'
        }
      ]
    };
    this.datePickerConfigurationIssueDateEnd = {
      control: this.authTrackingForm.controls.issueDateEnd,
      minDate: DateUtility.getTwoYearAgoDate(),
      controlName: 'issueDateEnd',
      errorWrapperId: {
        defaultValidations: 'auth-tracking-issue-date-end-error',
        minDate: 'issue-date-end-min-date-error'
      },
      attributes: {
        id: 'issue-date-end',
        datePickerId: 'issue-date-end-picker',
        datePickerToggleId: 'issue-date-end-picker-toggle',
        name: 'issueDateEnd'
      },
      customErrorMessages: [
        {
          validatorType: ErrorTypes.Required,
          errorMessage: 'Please enter the issue date end'
        },
        {
          validatorType: ErrorTypes.InvalidDateFormat,
          errorMessage: 'Issue Date End must be in MM/DD/YYYY format'
        },
        {
          validatorType: ErrorTypes.MinDate,
          errorMessage: 'Unable to retrieve authorizations issued over two years'
        }
      ]
    };
  }
}
