
export interface LabSpecialInstructions {
  type: string;
  redoOrderNumber: string;
  details: string;
  backsideAntiReflectiveOnly: boolean;
}

export enum LabSpecialInstructionsType {
  BACKSIDE = 'BACKSIDE',
  REDO = 'REDO',
  OTHER = 'OTHER',
  SPECIAL_MEASUREMENT = 'SPECIAL_MEASUREMENT'
}
