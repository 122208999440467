import { NgModule } from '@angular/core';
import {ExpiredSessionComponent} from '../public/expired-session/expired-session.component';
import {RouterModule, Routes} from '@angular/router';
import {CobCoverageComponent} from './cob-coverage/cob-coverage.component';
import {SecureComponent} from './secure.component';
import {MemberSearchComponent} from './member-search/member-search.component';
import {CoverageSummaryComponent} from './coverage-summary/coverage-summary.component';
import {PatientSelectionComponent} from './patient-selection/patient-selection.component';
import {AuthorizationConfirmationComponent} from './authorization-confirmation/authorization-confirmation.component';
import {ClaimFormComponent} from './claim-form/claim-form.component';
import {ApplicationConstants} from '../common/constants/application.constants';
import {IsAuthenticatedRouteGuard} from '../common/services/data-model/http/route-guards/is-authenticated.guard';
import {EInsuranceComponent} from './eInsurance/eInsurance.component';
import {GiftCertificateSubmissionComponent} from './gift-certificate-submission/gift-certificate-submission.component';
import {LeaveClaimFormWhenInEditModeGuard} from '../common/services/data-model/http/route-guards/leave-claim-form-when-in-edit-mode.guard';
import {ClaimsTrackingComponent} from './claims-tracking/claims-tracking.component';
import {AuthTrackingComponent} from './auth-tracking/auth-tracking.component';

const routes: Routes = [
  {
    path: ApplicationConstants.routing.secure.baseRoute,
    component: SecureComponent,
    canActivate: [IsAuthenticatedRouteGuard],
    canActivateChild: [IsAuthenticatedRouteGuard],
    children: [
      {
        path: ApplicationConstants.routing.secure.eInsurance,
        component: EInsuranceComponent
      },
      {
        path: ApplicationConstants.routing.secure.memberSearch,
        component: MemberSearchComponent,
        data: {
          breadcrumb: ApplicationConstants.routing.secure.memberSearchBreadcrumb
        }
      },
      {
        path: ApplicationConstants.routing.secure.patientSelection,
        component: PatientSelectionComponent,
        data: {
          breadcrumb: ApplicationConstants.routing.secure.patientSelectionBreadcrumb
        }
      },
      {
        path: ApplicationConstants.routing.secure.coverageSummary,
        component: CoverageSummaryComponent,
        data: {
          breadcrumb: ApplicationConstants.routing.secure.coverageSummaryBreadcrumb
        }
      },
      {
        path: ApplicationConstants.routing.secure.cobCoverage,
        component: CobCoverageComponent,
        data: {
          breadcrumb: ApplicationConstants.routing.secure.cobCoverageBreadcrumb
        }
      },
      {
        path: ApplicationConstants.routing.secure.claimForm,
        children: [
          {
            canDeactivate: [LeaveClaimFormWhenInEditModeGuard],
            path: ApplicationConstants.routing.secure.claimFormEdit,
            component: ClaimFormComponent
          }, {
            path: ApplicationConstants.routing.secure.claimFormView,
            component: ExpiredSessionComponent
          }
        ]
      },
      {
        path: ApplicationConstants.routing.secure.authorizationConfirmation,
        component: AuthorizationConfirmationComponent,
        data: {
          breadcrumb: ApplicationConstants.routing.secure.authorizationConfirmationBreadcrumb
        }
      },
      {
        path: ApplicationConstants.routing.secure.giftCertificateSubmission,
        component: GiftCertificateSubmissionComponent,
        data: {
          breadcrumb: ApplicationConstants.routing.secure.giftCertificateSubmissionBreadcrumb
        }
      },
      {
        path: ApplicationConstants.routing.secure.claimsTracking,
        component: ClaimsTrackingComponent,
        data: {
          breadcrumb: ApplicationConstants.routing.secure.claimsTrackingBreadcrumb
        }
      },
      {
        path: ApplicationConstants.routing.secure.authTracking,
        component: AuthTrackingComponent,
        data: {
          breadcrumb: ApplicationConstants.routing.secure.authTrackingBreadCrumb
        }
      },
      {
        path: '',
        redirectTo: ApplicationConstants.routing.secure.eInsurance,
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: ApplicationConstants.routing.secure.eInsurance
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class SecureRoutingModule { }
