<div fxLayout="column">

  <!-- Header Text -->
  <div fxLayout="row" class="header-content">
    <h1 class="nested-column-12" id="hcpc-codes-modal-header" mat-dialog-title>HCPC/CPT Codes</h1>

    <!-- Close Icon -->
    <a class="close-button margin-top-5" (click)="onCloseClick()"
       id="hcpc-cpt-codes-closeButton">
      <mat-icon svgIcon="eclaim-icons:close" id="hcpc-cpt-codes-close-icon"></mat-icon>
    </a>
  </div>


  <!-- Search -->
  <div class="search-HCPCS-CPT-code-parent hidden-print">
    <div class="search-HCPCS-CPT-code-child">
      <input matInput class="form-control" id="hcpcCodesDescriptionsModal-search" #hcpcCodeSearch
             (input)="filterHcpcCodes(hcpcCodeSearch.value)"/>
      <mat-icon matSuffix svgIcon="eclaim-icons:search"></mat-icon>
    </div>
  </div>


  <!-- Body -->
  <mat-dialog-content>
    <!-- HCPC Codes List -->
    <div class="hcpc-codes print-class-1" *ngIf="hasResultsAfterFilter()">
      <!-- HCPC Code Groups -->
      <div class="hcpc-codes-parent" *ngFor="let obj of filteredHcpcCodeGroups | keyvalue">
        <!-- HCPC Code Group Name -->
        <p *ngIf="obj.key !== 'GENERAL' && filteredGroupHasCodes(obj.key)"><strong>{{obj.key}}</strong></p>

        <!-- HCPC Code Sub-Groups -->
        <div class="hcpc-codes-group" *ngFor="let hcpcCodeSubGroup of obj.value">
          <ul class="list-unstyled hcpc-codes-sub-group">
            <div *ngFor="let hcpcCode of hcpcCodeSubGroup">
              <!--TODO bold search text-->
              <li [ngClass]="{'selectedHcpcCode': isHcpcCodeSelected(hcpcCode.code)}" class="hcpc-codes-individual"
                  (click)="selectHcpcCode(hcpcCode.code)" (dblclick)="doubleClickHcpcCode(hcpcCode.code)">
                {{hcpcCode.code}} - {{hcpcCode.value}}
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>

    <!-- No Results - Only show if no results after filtering -->
    <p class="hcpc-codes-no-results" *ngIf="!hasResultsAfterFilter()" id="hcpc-modes-modal-no-codes-match-warning">No
      codes match the search criteria.</p>
  </mat-dialog-content>


  <!-- Action Buttons -->
  <mat-dialog-actions>

      <!-- Cancel -->
      <button mat-dialog-close class="cancel-button"
              ngDisabled="!selectedHcpcCode" id="hcpc-modes-modal-cancel-button">Cancel
      </button>
      <div fxFlexOffset="30px"></div>

      <!-- Print -->
      <button mat-flat-button class="btn btn-info btn-extra-pad mat-flat-button mat-primary" (click)="printModal()"
              id="hcpc-modes-modal-print-button">
        <i class="vspicon-vsp_print"></i>Print
      </button>
      <div fxFlexOffset="30px"></div>

      <!-- OK -->
      <button mat-flat-button mat-dialog-close class="btn btn-primary btn-extra-pad mat-flat-button mat-primary"
              (click)="confirmSelectedHcpcCode()"
              [disabled]="!selectedHcpcCode" id="hcpc-codes-modal-ok-button">OK
      </button>

  </mat-dialog-actions>

</div>
