import { AbstractControl } from '@angular/forms';

export interface MultiFieldValidationGroup {
    formControlNames: Array<string>;
    groupValidationFunction?: Function | Array<Function>;
    groupValidationFunctionParamData?: any;
  }

  export interface PrescriptCustomValidationData {
    control: AbstractControl, value: string
  }

/**************** Binocular/Monocular Types/Enum ****************/
export enum BinocularMonocularTypes {
  binocularPD = 'Binocular PD',
  monocularPDToCenterOfBridge = 'Monocular PD (to center of bridge)'
}

export enum BinocularMonocularEnum {
  BINOCULAR = 'BINOCULAR',
  MONOCULAR = 'MONOCULAR'
}
