import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { FormControlName } from '@angular/forms';
import { isNullOrUndefined } from './app/common/utility';

/**
 * Adding the nativeElement to the AbstractControl reference used by the FormControlName directive.
 * NOTE: Need to add type definition to the AbstractControl class.  Currently accessed by using
 * this notation: control['nativeElement']
 * This was originally implemented in the polyfill.ts file and moved to here to get it working for local environment.
 */
const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function() {
  const result =  originFormControlNameNgOnChanges.apply(this, arguments);
  if (this.valueAccessor && this.control) {
    // Set the nativeElement on the AbstractControl for normal and Material cases (material aliases _elementRef as _element)
    if (this.valueAccessor._elementRef && this.valueAccessor._elementRef.nativeElement) {
      this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
    } else if (this.valueAccessor._element && this.valueAccessor._element.nativeElement) {
      this.control.nativeElement = this.valueAccessor._element.nativeElement;
    } else {
      this.control.nativeElement = undefined;
    }

    // If control is a checkbox (non-blurrable field), add the checked property to force validation updates on change
    if (!isNullOrUndefined(this.valueAccessor.checked)) {
      this.control.checked = this.valueAccessor.checked;
    }
  }
  return result;
};

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
