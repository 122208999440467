export class AuthTrackingSearchRequest {
  private issueDate: string;
  private issueDateStart: string;
  private issueDateEnd: string;

  get _issueDate(): string {
    return this.issueDate;
  }

  set _issueDate(value: string) {
    this.issueDate = value;
  }

  get _issueDateStart(): string {
    return this.issueDateStart;
  }

  set _issueDateStart(value: string) {
    this.issueDateStart = value;
  }

  get _issueDateEnd(): string {
    return this.issueDateEnd;
  }

  set _issueDateEnd(value: string) {
    this.issueDateEnd = value;
  }
}
