<div fxLayout="column">

  <!-- Header Text -->
  <div fxLayout="row" class="header-content">
    <h1 class="nested-column-12" id="diagnosis-modal-header" mat-dialog-title>Diagnosis Code</h1>
    <!-- Close Icon -->
    <a class="close-button margin-top-5" (click)="onCloseClick()"
       id="hcpc-cpt-codes-closeButton">
      <mat-icon svgIcon="eclaim-icons:close" id="hcpc-cpt-codes-close-icon"></mat-icon>
    </a>
  </div>

  <mat-dialog-content>
    <!-- Diagnosis Code List -->
    <div *ngFor="let diagnosisCodeObj of diagnosisCodeList">
      <!-- Diagnosis Code Group Name -->
      <span><b>{{diagnosisCodeObj.conditionDescription}}</b></span>
      <hr class="bottom-margin-hr">
      <!-- Diagnosis Code Sub-Groups -->
      <div *ngFor="let diagnosis of diagnosisCodeObj.diagnosis">
        <span fxFlex class="diagnosis-code-list-selection"
              [ngClass]="{'selected-diagnosis-code': isSelectedDiagnosisCode(diagnosis.diagnosisCode)}"
              (click)="setSelectedDiagnosisCode(diagnosis.diagnosisCode)">{{diagnosis.diagnosisCode}}
          - {{diagnosis.diagnosisDescription}}</span>
      </div>
      <p></p>
    </div>
  </mat-dialog-content>

  <!-- Action Buttons -->
  <mat-dialog-actions>

    <!-- Cancel -->
    <button mat-dialog-close class="cancel-button"
            id="diagnosis-code-list-modal-cancel-button">Cancel
    </button>
    <div fxFlexOffset="30px"></div>

    <!-- OK -->
    <button mat-flat-button mat-dialog-close class="btn btn-primary btn-extra-pad mat-flat-button mat-primary"
            (click)="confirmSelectedDiagnosisCode()"
            [disabled]="!isDiagnosisCodeSelected()" id="diagnosis-code-list-modal-ok-button">OK
    </button>

  </mat-dialog-actions>

</div>
