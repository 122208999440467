<div fxLayout="column" class="replacement-frame-modal">

  <mat-card>
    <!-- Header -->
    <mat-card-header>
      <mat-card-title>
        <h1 class="nested-column-12"
            id="replacement-frame-modal-header" mat-dialog-title>{{replacementFrameModalHeader}}</h1>
      </mat-card-title>

    </mat-card-header>

    <!-- Body -->
    <mat-card-content>
      <div fxLayout="row"
           fxLayoutAlign="start center">
        <div class="eclaim-body-text nested-column-12">
          <!-- Content that will be displayed if the Replacement Frame Modal is Rendered from the Supplier Override Modal -->
          <div *ngIf="replacementModalRenderedFromSupplierOverrideModal">
            <label id="replacement-frame-modal-from-supplier-override-modal-content" class="first-paragraph">Because you selected Doctor Supplied, Marchon/Altair will send you this curated frame to replace your board sample.
              <span *ngIf="showPleaseSelectReplacementContent" class="eclaim-body-text-bold" id="replacement-frame-modal-please-choose-replacement-content"> Please choose a replacement frame.</span>
              <span *ngIf="!showPleaseSelectReplacementContent" id="replacement-frame-modal-from-supplier-override-modal-content-2">You may be charged a shipping fee. See <span class="action-link see-program-faqs" (click)="onSeeProgramFAQsClick()" id="frame-source-modal-see-program-faqs-link">program FAQs</span>.</span>
            </label>
            <!-- New Line for the last part of the message -->
            <label *ngIf="showPleaseSelectReplacementContent" id="replacement-frame-modal-from-supplier-override-modal-content-3" class="second-paragraph">You may be charged a shipping fee. See <span class="action-link see-program-faqs" (click)="onSeeProgramFAQsClick()" id="frame-source-modal-see-program-faqs-link-2">program FAQs</span>.</label>
          </div>

          <!-- Content that will be displayed if the Replacement Frame Modal is Rendered from the Frame Card -->
          <label *ngIf="replacementModalRenderedFromFrameCard" id="replacement-frame-modal-from-frame-card-content" class="eclaim-body-text first-paragraph">{{modelContentIfRouteIsFromFrameCard}}</label>
        </div>
      </div>

      <!-- frame search results table -->
      <div fxLayout="row"
           fxLayoutAlign="start center"
           class="replacement-frame-results-table-row">
        <!-- do not use *ngIf when nesting a mat table inside of it, this will break sorting!!! -->
        <div class="replacement-frame-results-table-container margin-bottom-25 nested-column-12">
          <mat-table class="custom-border-around-table" id="replacement-frame-results-table" [dataSource]="dataSource">

            <ng-container matColumnDef="image">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let replacementFrameChoice">
                <img ngSrc="{{replacementFrameChoice.imageUrl}}" height="auto" width="auto" alt="" onerror="this.src='assets/images/frameImageNotAvailable.png';"/>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="styleName">
              <mat-header-cell *matHeaderCellDef>Style Name</mat-header-cell>
              <mat-cell *matCellDef="let replacementFrameChoice">{{replacementFrameChoice.styleName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="skuNumber">
              <mat-header-cell *matHeaderCellDef>SKU</mat-header-cell>
              <mat-cell *matCellDef="let replacementFrameChoice">{{replacementFrameChoice.skuNumber}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="size">
              <mat-header-cell *matHeaderCellDef>Size</mat-header-cell>
              <mat-cell *matCellDef="let replacementFrameChoice">{{replacementFrameChoice.size}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="color">
              <mat-header-cell *matHeaderCellDef>Color</mat-header-cell>
              <mat-cell *matCellDef="let replacementFrameChoice">{{replacementFrameChoice.color}}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let replacementFrameRow; columns: tableColumns; index as i"
                     (click)="onFramesTableRowClick(replacementFrameRow)"
                     class="replacement-frame-results-table-row" id="replacement-frame-results-table-row-{{i}}"
                     [ngClass]="{'is-selected':replacementFrameRow.isActiveSelection}">></mat-row>

          </mat-table>
        </div>

      </div>

    </mat-card-content>

    <!-- Footer Line -->
    <hr/>

    <!-- KScope Logo/Cancel/Continue buttons -->
    <mat-card-actions>
      <!-- Top Level Row-->
      <div fxLayout="row">
        <!-- KScope Logo-->
        <div class="nested-column-2">
          <img class="margin-top-5" id="replacement-frame-kaleyedoscope-logo" height="50px" width="120px"
               ngSrc="assets/images/kaleyedoscopecircle.svg" alt="">
        </div>
        <!-- Cancel/Continue buttons -->
        <div class="nested-column-10">
          <div fxLayout="row" fxLayoutAlign="end center"
               class="margin-top-15 margin-bottom-15">
            <!-- Cancel Button -->
            <button mat-dialog-close class="cancel-button" (click)="cancelButtonClick()"
                    id="replacement-frame-modal-cancel-button">Cancel</button>
            <!-- Continue Button -->
            <button mat-dialog-close class="btn btn-primary margin-left-30" (click)="continueButtonClick()"
                    id="replacement-frame-modal-continue-button">Continue</button>
          </div>
        </div>
      </div>
    </mat-card-actions>

  </mat-card>

</div>
