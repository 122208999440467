export const ContactsConstants = {

  // Contacts form value constants
  MATERIAL_TYPE: 'materialType',
  REASON: 'reason',
  SERVICE: 'service',
  MANUFACTURER: 'manufacturer',
  BRAND: 'brand',
  MODALITY: 'modality',
  NUMBER_BOXES: 'numberBoxes',
};
