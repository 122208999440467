import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Frame } from '../../../../models/frame';
import { FramesService } from '../../../../common/services/data-model/app/frames/frames.service';
import { ApplicationConstants } from '../../../../common/constants/application.constants';
import { FrameConstants } from '../frame.constants';
import { ViewStateService } from '../../../../common/services/view-state/view-state.service';
import { UserSessionService } from '../../../../common/services/support/user-session/user-session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-frame-search-results-modal',
  templateUrl: './frame-search-results-modal.component.html',
  styleUrls: ['./frame-search-results-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FrameSearchResultsModalComponent implements OnInit, AfterViewInit, OnDestroy {

  /***** START - PRIVATE MEMBERS *****/
  private MAX_RESULTS = ApplicationConstants.maxFrameSearchResults;
  /***** END - PRIVATE MEMBERS *****/


  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<FrameSearchResultsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      query: any;
      frameSearchResults: any;
      selectedFrame: any;
      frameSupplier: any;
    },
    private viewStateService: ViewStateService,
    private framesService: FramesService,
    private sessionService: UserSessionService,
  ) {
    this.lastQuery = data.query;
  }


  /***** START - PUBLIC MEMBERS *****/
  searchForm: FormGroup;
  @ViewChild(MatSort, {static: true}) frameSearchResultsTableSort: MatSort;

  framesLoading: boolean;
  zeroFramesFound = false;
  tooManyFramesFound = false;
  numberOfFramesFound: number;
  frameSearchTimeout = null;
  isFrameSearchApiOffline = false;  // Set to true if search API is offline
  isManualEnterFrameClick = false;
  allowFullFrameDetailEdit = false;
  startFrameDetailsComponenetInEditMode = false;  // Passed to FrameDetailsDisplay component to force edit mode on load
  // Used to not show "no results" if we haven't even tried searching yet.
  lastQuery: string;
  tableColumns: string[] = ['manufacturer', 'collection', 'model', 'eyeSize', 'temple', 'color'];
  dataSource: MatTableDataSource<Frame> = new MatTableDataSource();
  observableSubscriptions: Array<Subscription> = new Array<Subscription>();
  /***** END - PUBLIC MEMBERS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.dialogRef.updatePosition({ top: '10px' });
    this.buildForm();
    if (this.data.query) {
      this.searchFrames(this.data.query);
    } else {
      this.zeroFramesFound = true;
      this.numberOfFramesFound = 0;
    }

    this.observableSubscriptions.push(this.sessionService.sessionTimeoutNotifier.subscribe((sessionTimedOut: boolean) => {
      if (sessionTimedOut) {
        this.dialogRef.close(false);
      }
    }));
  }

  ngOnDestroy(): void {
    this.observableSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.frameSearchResultsTableSort;

  }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }

  onSearchClick(): void {
    this.searchFrames(this.searchForm.controls.query.value);
  }

  onManualFrameClick(): void {
    this.allowFullFrameDetailEdit = true;
    this.isManualEnterFrameClick = true;
    this.data.selectedFrame = undefined;
    setTimeout(() => this.data.selectedFrame = FrameConstants.emptyFrame);
    this.startFrameDetailsComponenetInEditMode = true;
  }

  onFramesTableRowClick(selectedFrame: Frame): void {
    this.makeFrameSelection(selectedFrame);
  }

  frameSelected(frame: Frame) {
    this.dialogRef.close({
      frame: frame,
      allowFullFrameDetailEdit: this.allowFullFrameDetailEdit
    });
  }

  hasTooManyOrNoResults(): boolean {
    return this.tooManyFramesFound || this.zeroFramesFound;
  }

  /***** START - PRIVATE FUNCTIONS *****/
  private buildForm(): void {
    this.searchForm = this.formBuilder.group({
      query: (this.data.query) ? this.data.query : '',
    });
    this.searchForm.valueChanges.subscribe((viewModel) => {
      this.callApiAfterUserStopsTyping();
      // this may be needed once we're wired up to an api
    });
  }

  callApiAfterUserStopsTyping(): void {
    if (this.frameSearchTimeout) {
      window.clearTimeout(this.frameSearchTimeout);
    }
    this.frameSearchTimeout = window.setTimeout(() => {
      this.frameSearchTimeout = null;
      if (this.searchForm.controls.query.value.length > 3 && !this.framesLoading) {
        this.searchFrames(this.searchForm.controls.query.value);
      }
    }, 1000);
  }

  private searchFrames(frameSearchQuery: string): void {
    // reset form
    this.dataSource.data = [];
    this.data.selectedFrame = null;
    // start spinner while waiting for frame search response
    this.framesLoading = true;
    // start searching for frame
    this.framesService.loadFrames(frameSearchQuery).subscribe(frameSearchResults => {
      if (frameSearchResults) {
        if (frameSearchResults.frames) {
          this.dataSource.data = frameSearchResults.frames;
        }

        this.numberOfFramesFound = (frameSearchResults.count) ? frameSearchResults.count : 0;
        this.zeroFramesFound = (this.numberOfFramesFound === 0);
        this.tooManyFramesFound = (this.numberOfFramesFound > this.MAX_RESULTS);
        this.isFrameSearchApiOffline = false;
        this.allowFullFrameDetailEdit = false;
        // requirement to make the first frame in the list active
        if (!this.tooManyFramesFound && !this.zeroFramesFound && this.dataSource.data && this.dataSource.data[0]) {
          this.makeFrameSelection(this.dataSource.data[0]);
        }
      } else {
        // Since frame search api is down, we need to pass the following to frame-search-details.componenet
        // to force it start in edit mode for manual frame entry.
        this.isFrameSearchApiOffline = true;
        this.allowFullFrameDetailEdit = true;
        this.startFrameDetailsComponenetInEditMode = true;
        this.data.selectedFrame = FrameConstants.emptyFrame;
      }
      this.framesLoading = false;
    });
  }

  // This method has code to allow for a selected frame to be un-selected.  I have hard coded it to not use it
  // until we know if this functionality is desired.
  private setFrameAsActive(selectedFrame: Frame) {
    this.dataSource.data.forEach(frame => {
      frame.isActiveSelection = false;
    });
    // hard coded to not un-select for now.
    selectedFrame.isActiveSelection = true;
  }

  private makeFrameSelection(selectedFrame: Frame) {
    this.setFrameAsActive(selectedFrame);
    this.data.selectedFrame = selectedFrame;
  }

  /***** END - PRIVATE FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/


  /***** END - PUBLIC FUNCTIONS *****/



}
