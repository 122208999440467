import {Component, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ExternalServiceLocationService} from '../../../common/services/data-model/app/external-service-location/external-service-location.service';
import {DateUtility, isNullOrUndefined, getFormattedFullName, openDialog} from '../../../common/utility';
import {ClaimSummaryService} from '../../../common/services/data-model/app/claim-summary/claim-summary.service';
import {VisionServiceRequest} from '../../../models/VisionServiceRequestResults';
import {DeleteAuthorizationModalComponent} from '../../patient-selection/delete-authorization-modal/delete-authorization-modal.component';
import {MembershipName} from '../../../models/membership';
import {AuthorizationsService} from '../../../common/services/data-model/app/authorizations/authorizations.service';
import {DrReportsNavigationService} from '../../../common/services/support/dr-reports-navigation/dr-reports-navigation.service';
import {ReissueAuthorizationModalComponent} from '../reissue-authorization-modal/reissue-authorization-modal.component';
import {EligibilityService} from '../../../common/services/data-model/app/eligibility/eligibility.service';
import {
  ClaimFormNavigationService
} from '../../../common/services/support/claim-form-navigation/claim-form-navigation.service';

@Component({
  selector: 'app-auth-tracking-auth-tracking-search-results',
  templateUrl: 'auth-tracking-search-results.component.html',
  styleUrls: ['auth-tracking-search-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthTrackingSearchResultsComponent implements OnInit, OnDestroy, OnChanges {
  /***** START - PRIVATE MEMBERS *****/
  /***** END - PRIVATE MEMBERS *****/
  constructor(
    private externalServiceLocationService: ExternalServiceLocationService,
    private claimSummaryService: ClaimSummaryService,
    private dialog: MatDialog,
    private eligibilityService: EligibilityService,
    private authorizationService: AuthorizationsService,
    private drReportsNavigationService: DrReportsNavigationService,
    private claimFormNavigationService: ClaimFormNavigationService,
  ) {}
  /***** START - PUBLIC MEMBERS *****/

  @Input() public visionServiceRequestSearchResults: VisionServiceRequest[] = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<VisionServiceRequest>;
  columnsToDisplay: string[] = ['authorizationNumber', 'memberName', 'patientName', 'relation', 'programId', 'dateOfService', 'reissueAuthorization', 'reports', 'delete'];

  public isDataSourceNullOrUndefined(): boolean {
    return (isNullOrUndefined(this.dataSource));
  }

  public formatDateForUi(yyyyMmDdDate: string): string {
    return DateUtility.buildFriendlyDateFromJsDate(yyyyMmDdDate);
  }
  /***** END - PUBLIC MEMBERS *****/

  /***** START - EVENT HANDLERS *****/

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (!isNullOrUndefined(this.visionServiceRequestSearchResults) && !isNullOrUndefined(this.visionServiceRequestSearchResults) && this.visionServiceRequestSearchResults.length !== 0) {
      this.dataSource = new MatTableDataSource(this.visionServiceRequestSearchResults);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } else {
      this.dataSource = undefined;
    }
  }

  ngOnDestroy(): void {
    this.dataSource = undefined;
  }

  /***** START - PROPERTY ACCESSORS *****/
  // build the full name of the patient/member
  getFullName(name: MembershipName): string {
    const middleInitial = name.middleInitial ? name.middleInitial : null;
    return getFormattedFullName(name.firstName, name.lastName, middleInitial);
  }

  // build the full name of the member
  getMemberFullName(vsr: VisionServiceRequest): string {
    const middleInitial = vsr.reifiedPatient.subscriberName.middle ? vsr.reifiedPatient.subscriberName.middle : null;
    return getFormattedFullName(vsr.reifiedPatient.subscriberName.firstName, vsr.reifiedPatient.subscriberName.lastName, middleInitial);
  }

  // build the full name of the patient
  getPatientFullName(vsr: VisionServiceRequest): string {
    const middleInitial = vsr.reifiedPatient.name.middle ? vsr.reifiedPatient.name.middle : null;
    return getFormattedFullName(vsr.reifiedPatient.name.firstName, vsr.reifiedPatient.name.lastName, middleInitial);
  }

  /***** END - PROPERTY ACCESSORS *****/

  // invoke when 'Reports Icon' is clicked
  onReportsButtonClick(vsr: VisionServiceRequest): void {
    this.drReportsNavigationService.navigateToPatientRecordReport(vsr.vsrNumber, true);
  }

  // invoked when Authorization Number is clicked
  onAuthNumberClick(vsr: string): void {
    // Navigate to Claim Form
    this.navigateToClaimForm(vsr);
  }

  // Claim Form Navigation
  navigateToClaimForm(vsr: string): void {
    // Calling LoadingModalComponent for Spinner (Preload Icon)
    openDialog('Opening Claim Form', this.dialog);
    // const authNumber: string = vsr;
    this.claimFormNavigationService.searchAndRetrieveOrCreatePatientEncounterThenLoadDoctorsThenNavigateToClaimForm(vsr, undefined, this.authorizationService.getEffectiveDateFromVsr(vsr)).subscribe(() => {
      // Broadcast value is null, no need for closure
    }, () => {
      // Errors are handled in the HTTP layer for PE, no need for error closure
    }, () => {
      // Close the dialog when the observer completes
      this.dialog.closeAll();
    });
  }

  // invoked when Reissue Icon is clicked
  onReissueAuthorizationIconClick(vsr: VisionServiceRequest): void {
    this.dialog.open(ReissueAuthorizationModalComponent, {
      width: '850px',
      height: '580px',
      panelClass: 'eclaim-popup-modal',
      disableClose: true,
      data: {
        authorization: vsr
      }
    });
  }

  // invoked when 'Delete' is clicked
  deleteAuthorization(vsr: VisionServiceRequest): void {
    const dialogRef = this.dialog.open(DeleteAuthorizationModalComponent, {
      width: '500px',
      panelClass: 'eclaim-popup-modal',
      disableClose: true,
      data: {
        authorization: vsr,
        patientFullName: this.getFullName(vsr.reifiedPatient.name)
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authorizationService.deleteAuthorization(this.visionServiceRequestSearchResults.indexOf(vsr), true ).subscribe(result => {
          if (result) {
            this.visionServiceRequestSearchResults = result;
            this.dataSource.data = this.visionServiceRequestSearchResults;
          }
        });
      }
    });
  }
  /***** END - EVENT HANDLERS *****/
}

