<app-component-mask></app-component-mask>

<div id="{{id}}" fxLayout="column">
  <mat-card class="container-white-full-column mat-card">

    <mat-card-header *ngIf="!claimHasEdits && !claimHasWarnings" class="sticky-card-header" [ngClass]="{ 'disabled-card-header': signaturesForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="signature-title">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasEdits && !claimHasWarnings" class="sticky-card-header-edits" [ngClass]="{ 'disabled-card-header': signaturesForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="signature-title-edits">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <mat-card-header *ngIf="claimHasWarnings" class="sticky-card-header-warning" [ngClass]="{ 'disabled-card-header': signaturesForm.disabled }">
      <mat-card-title>
        <h1 class="float-left" id="signature-title-warning">{{title}}</h1>
      </mat-card-title>
    </mat-card-header>

    <!-- Signatures Form -->
    <mat-card-content>
      <form fxLayout="column" class="claim-form-child-form" [formGroup]="signaturesForm" novalidate>
        <!-- patient signature -->
        <div fxLayout="row" class="margin-top-30 margin-bottom-20">
          <div class="nested-column-6" >
            <mat-checkbox class="margin-right-10" id="patient-signature-checkbox" formControlName="patientSignature" (change)="onPatientSignatureChange()"
                          (keyup.enter)="onKeypressEventCheckbox( signaturesForm.get('patientSignature'))"></mat-checkbox>
            <span class="required-label-asterisk">* </span>
            <label id="patient-signature-label">12. Patient or Authorized Person's Signature on File</label>
            <app-error-wrapper id="patient-signature-error" [config]="errorWrapperConfig.patientSignature"></app-error-wrapper>
          </div>
        </div>
        <div fxLayout="row" class="margin-bottom-20 margin-left-30">
          <div class="nested-column-4">
            <span class="required-label-asterisk">* </span>
            <label id="patient-signature-date-label" class="margin-bottom-10">Date of Patient or Authorized Person's Signature</label>
            <app-date-picker id="signatures-date-picker" class="required-mat-form-field" [datePickerConfiguration]="datePickerConfigurationPatientSignatureDate"></app-date-picker>
          </div>
        </div>

        <!-- patient signature verbiage -->
        <div fxLayout="row" class="margin-left-45 margin-bottom-30">
          <label id="patient-signature-verbiage" class="signature-verbiage">Signature authorizes the release of any medical or other information
            necessary to process the claim. Where applicable, also requests payment of government benefits to the party who
            accepts assignment.</label>
        </div>
        <!-- insured signature -->
        <div fxLayout="row" class="margin-bottom-20 text-nowrap">
          <div class="nested-column-6">
            <mat-checkbox class="margin-right-10" id="insured-other-insured-signature-checkbox" formControlName="insuredSignature"
                          (keyup.enter)="onKeypressEventCheckbox( signaturesForm.get('insuredSignature'))"></mat-checkbox>
            <label id="insured-other-insured-signature-label">13. Insured or Authorized Person's Signature on File</label>
          </div>
        </div>

        <!-- insured signature verbiage -->
        <div fxLayout="row" class="margin-left-45 margin-bottom-30">
          <label id="insured-signature-verbiage" class="signature-verbiage">Signature authorizes the payment of medical benefits to the physician or supplier for the services indicated.</label>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  <div fxLayout="row" class="margin-top-20">
    <label class="required-form-field-label margin-left-15" id="claim-form-required-field">
      <span class="required-label-asterisk">* </span>Required Field</label>
  </div>
</div>
