import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {ClaimsService} from '../../../common/services/data-model/app/claims/claims.service';
import {ComponentMaskComponent} from '../../../common/components/component-mask/component-mask.component';
import {MatDialog} from '@angular/material/dialog';
import {Claim} from '../../../models/claim';
import {ApplicationConstants, ClaimStatus} from '../../../common/constants/application.constants';
import {SubmitClaimModalComponent} from './submit-claim-modal/submit-claim-modal.component';
import {MessageService} from '../../../common/services/support/message/message.service';
import {ClaimCardsToUpdate} from '../../../models/claimCardsToUpdate';
import {Router} from '@angular/router';
import {UserSessionService} from '../../../common/services/support/user-session/user-session.service';
import {ClaimEditService} from '../../../common/services/support/claim-edit/claim-edit.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DrReportsNavigationService} from '../../../common/services/support/dr-reports-navigation/dr-reports-navigation.service';
import {LabOrderInformation} from '../../../models/labOrderInformation';
import {ClaimFormNavigationService} from '../../../common/services/support/claim-form-navigation/claim-form-navigation.service';
import {ViewStateService} from '../../../common/services/view-state/view-state.service';
import {DateUtility, isNullOrUndefined, delay, openDialog} from '../../../common/utility';
import {
  LoadingModalComponent,
  LoadingModalOptions
} from '../../../common/components/loading-modal/loading-modal/loading-modal.component';
import {TintColors} from '../lens/lens.constants';
import {FulfillmentService} from '../../../common/services/data-model/app/fulfillment/fulfillment.service';
import {FrameOrderRequest} from '../../../models/fulfillment';
import {FulfillmentDataService} from '../../../common/services/data-model/http/http-client-data/fulfillment-data/fulfillment-data.service';

@Component({
  selector: 'app-claim-action-buttons',
  templateUrl: './claim-action-buttons.component.html',
  styleUrls: ['./claim-action-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClaimActionButtonsComponent implements OnInit, OnDestroy {

  constructor(
    private viewStateService: ViewStateService,
    private dialog: MatDialog,
    private claimsService: ClaimsService,
    private router: Router,
    private messageService: MessageService,
    private userSessionService: UserSessionService,
    private claimEditService: ClaimEditService,
    private deviceService: DeviceDetectorService,
    private drReportsNavigationService: DrReportsNavigationService,
    private claimFormNavigationService: ClaimFormNavigationService,
    private fulfillmentService: FulfillmentService,
    private fulfillmentDataService: FulfillmentDataService
  ) {
  }

  /***** START - PRIVATE MEMBERS *****/
  private observableSubscriptions: Subscription[] = [];
  private activeClaimAttributeFromFormControlMap;
  private activeClaim: Claim;
  private originalClaim: Claim;
  private autoSave: boolean = false;
  @ViewChild(ComponentMaskComponent, {static: true}) private componentMask: ComponentMaskComponent;
  /***** END - PRIVATE MEMBERS *****/


  /***** START - PUBLIC MEMBERS *****/
  readonly = false;
  cardsMasked = false;
  submitButtonHasBeenClicked = false;
  successfulCalculate = false;

  /***** END - PUBLIC MEMBERS *****/


  /***** START - PUBLIC FUNCTIONS *****/


  /***** END - PUBLIC FUNCTIONS *****/

  /***** START - PRIVATE FUNCTIONS *****/
  private maskCardsAndDisableStatus(): void {
    this.cardsMasked = true;
    this.viewStateService.maskCards();
    this.viewStateService.maskDateOfServiceCard();
  }

  private unmaskCardsAndDisableStatus(): void {
    this.cardsMasked = false;
    this.viewStateService.unmaskCards();
    this.viewStateService.unmaskDateOfServiceCard();
  }

  private idIsServiceLineBilledAmount(id: string): boolean {
    return (id.indexOf('service-line') > -1 && id.indexOf('billed-amount-error1') > -1);
  }

  private idIsPaidAmount(id: string): boolean {
    return (id.indexOf('services-patient-paid-amount-value-error') > -1);
  }

  private handleInvalidDollarAmounts(): void {
    const invalidDollarFormElements = document.querySelectorAll('.invalid-dollar-error');
    if (invalidDollarFormElements.length !== 0) {
      for (let i = 0; invalidDollarFormElements.length > i; i++) {
        const invalidDollarId = invalidDollarFormElements.item(i).parentElement.id;
        if (invalidDollarId && this.idIsServiceLineBilledAmount(invalidDollarId)) {
          const invalidChargesErrorSplit = invalidDollarFormElements.item(i).parentElement.id.split('-');
          const invalidChargesErrorIndex = invalidChargesErrorSplit[2];
          this.activeClaim.serviceLines[invalidChargesErrorIndex].billedAmount = '';
        } else if (invalidDollarId && this.idIsPaidAmount(invalidDollarId)) {
          this.activeClaim.patientPaymentAmount = null;
        }
      }
      this.claimsService.setActiveClaim(this.activeClaim, ApplicationConstants.componentIDs.updateAllSections);
    }
  }

  private handleInvalidDateValues(): void {
    const invalidDateFormControlNames = this.claimsService.dateErrorFormControlNameList;
    if (invalidDateFormControlNames.length !== 0) {
      for (const invalidDateFormControlName of invalidDateFormControlNames) {
        const activeClaimAttributeToUpdate = this.getActiveClaimAttribute(invalidDateFormControlName);
        if (activeClaimAttributeToUpdate.length > 0) {
          // Assumption: There will only be max of one level of hierarchy for the date attribute, so nestedAttribute.length <= 2
          if (activeClaimAttributeToUpdate.length === 1) {
            this.activeClaim[activeClaimAttributeToUpdate[0]] = null;
          } else if (activeClaimAttributeToUpdate.length === 2) {
            this.activeClaim[activeClaimAttributeToUpdate[0]][activeClaimAttributeToUpdate[1]] = null;
          }
        }
      }
      this.claimsService.setActiveClaim(this.activeClaim, ApplicationConstants.componentIDs.updateAllSections);
    }
  }

  private getActiveClaimAttribute(formControlName: string): string[] {
    let nestedAttribute = [];
    const claimAttribute = this.activeClaimAttributeFromFormControlMap.get(formControlName);
    if (claimAttribute) {
      const hasNestedAttributes = claimAttribute.indexOf('.') > -1;
      nestedAttribute = hasNestedAttributes ? claimAttribute.split('.') : [claimAttribute];
    }

    return nestedAttribute;
  }

  private updateTintColorDescription(): void {
    const labOrderInformation: LabOrderInformation = isNullOrUndefined(this.activeClaim.labOrderInformation) ? undefined : this.activeClaim.labOrderInformation;
    if (labOrderInformation && labOrderInformation.lensTintColor && labOrderInformation.lensTintColor.labServiceId !== TintColors.OTHER) {
      labOrderInformation.lensTintColor.labServiceDescription = undefined;
    }
  }

  /***** END - PRIVATE FUNCTIONS *****/

  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    this.originalClaim = this.claimsService.getOriginalClaim();
    this.readonly = (this.originalClaim.status === ClaimStatus.SubmittedClaim || this.originalClaim.status === ClaimStatus.SubmittedLab); // Submitted claims are readonly
    this.activeClaimAttributeFromFormControlMap = new Map()
      .set('dateOfService', 'dateOfService')
      .set('patientDateOfBirth', 'patient.dateOfBirth')
      .set('memberDateOfBirth', this.originalClaim.member ? 'member.dateOfBirth' : 'patient.dateOfBirth')
      .set('doctorSignatureDate', 'doctorSignature.dateSigned')
      .set('conditionRelatedIllnessInjuryPregnancyDate', 'illnessInjuryOnsetDate')
      .set('similarIllnessDate', 'priorIllnessInjuryOnsetDate')
      .set('patientUnableToWorkDateFrom', 'unableToWorkStartDate')
      .set('patientUnableToWorkDateTo', 'unableToWorkEndDate')
      .set('patientHospitalizedDateFrom', 'hospitalizationAdmitDate')
      .set('patientHospitalizedDateTo', 'hospitalizationReleaseDate')
      .set('patientSignatureDate', 'patientSignature.dateSigned');
    // Store the active claim as it gets update so it can be saved
    this.observableSubscriptions.push(this.claimsService.onCardsToUpdate.subscribe((onCardsToUpdate: ClaimCardsToUpdate) => {
      this.activeClaim = this.claimsService.getActiveClaim();
      if (this.activeClaim.status === ClaimStatus.SubmittedClaim || this.activeClaim.status === ClaimStatus.SubmittedLab) {// When claim successfully gets submitted disable other cards
        this.maskCardsAndDisableStatus();
        if (this.submitButtonHasBeenClicked) {
          this.router.navigate([ApplicationConstants.routing.secure.giftCertificateSubmissionPageUrl]);
        }
      }
    }));
    // Mask/unmask the component
    this.observableSubscriptions.push(this.viewStateService.onMaskCards.subscribe((mask: boolean) => {
      if (this.readonly) {// When claim is submitted DO NOT disable this component, so that uses can navigate to different Cards
        this.cardsMasked = false;
        this.componentMask.hide();
      } else {
        if (mask) {
          this.cardsMasked = true;
          this.componentMask.show();
        } else {
          this.cardsMasked = false;
          this.componentMask.hide();
          // This was added for ECLAIM-27 and ECLAIM-60. This will set an observable to true that the frame component is listing to to set the focus on the frame card.
          if (this.userSessionService.isKaleyedoscopePractice) {
            // ECLAIM-191 - This was added to be able to know when to clear or not clear the frame on a lens finishing change.
            // Needed to a delay here to avoid the debounce time we have on all the components.
            delay(ApplicationConstants.twoThousand).then(() => {
              this.fulfillmentService.canFrameBeClearedOnLensFinishingChange = true;
            });
            // We moved this condition down to help resolve the issue where the frame was not saved yet and this value is never set to true in the claim form navigation service
            if (this.fulfillmentService.isFulfillmentApiCallFromClaimNavigationService) {
              // Needed to a delay here to avoid the debounce time we have on all the components.
              delay(ApplicationConstants.oneThousand).then(() => {
                this.fulfillmentService.onAllClaimFormCardsHaveLoaded.next(true);
              });
            }
          }
        }
      }
    }));

    this.observableSubscriptions.push(this.userSessionService.autoSaveClaimForm.subscribe((autoSave) => {
      if (autoSave) {
        this.autoSave = true;
        this.handleInvalidDateValues();
        this.onSaveButtonClick();
      }
    }));
  }

  ngOnDestroy() {
    this.observableSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  formHasInvalidDates(): boolean {
    const invalidDateFormElements = document.querySelectorAll('.invalid-date-error');
    if (invalidDateFormElements.length !== 0) {
      for (let i = 0; invalidDateFormElements.length > i; i++) {
        invalidDateFormElements.item(i).classList.add('touched');
      }
      invalidDateFormElements.item(0).parentElement.parentElement.parentElement.scrollIntoView({behavior: 'smooth'});
      this.messageService.showErrorSnackbar('Please complete all required fields below.');
      return true;
    } else {
      return false;
    }
  }

  onSaveButtonClick(): void {
    this.viewStateService.setHasEdits(false);
    let invalidDates: boolean = false;
    if (!this.autoSave) {
      invalidDates = this.formHasInvalidDates();
    }
    if (!invalidDates) {
      this.updateTintColorDescription();
      this.handleInvalidDollarAmounts();
      this.maskCardsAndDisableStatus();
      // Calling LoadingModalComponent for Spinner (Preload Icon)
      openDialog('Saving Claim...', this.dialog);
      this.claimsService.saveClaim(this.activeClaim).subscribe(saveClaimSuccessfullyComplete => {
        if (saveClaimSuccessfullyComplete) {
          this.messageService.showConfirmationSnackbar(`Claim saved successfully`);
          if (this.autoSave) {
            this.userSessionService.autoSavedClaimForm = true;
          }
        } else {
          if (this.autoSave) {
            this.claimsService.allowClaimFormToBeDeactivated = this.userSessionService.isAutoSaveFromSessionTimeOut;
            this.userSessionService.autoSavedClaimForm = false;
          }
        }
        this.unmaskCardsAndDisableStatus();
        // Close dialog for LoadingModalComponent - Spinner
        this.dialog.closeAll();
      }, () => {
        // on observer error
      }, () => {
        // Close the dialog when the observer completes
        this.dialog.closeAll();
      });
      const formElements = document.querySelectorAll('.error-messages');
      if (formElements[0]) {
        for (let i = 0; formElements.length > i; i++) {
          formElements[i].classList.remove('touched');
        }
      }
    }
  }

  onCalculateButtonClick(): void {
    this.viewStateService.setHasEdits(false);
    const invalidDates = this.formHasInvalidDates();
    if (!invalidDates) {
      this.updateTintColorDescription();
      this.handleInvalidDollarAmounts();
      this.maskCardsAndDisableStatus();

      // Creating and opening a spinner dialog
      const calculateDialog = this.dialog.open<LoadingModalComponent, LoadingModalOptions>(LoadingModalComponent,
        {
          data: {
            modalMessageText: 'Calculating Claim...'
          },
          width: '250px',
          panelClass: 'loadingModal',
          disableClose: true
        });
      this.claimsService.calculateClaim(this.activeClaim).subscribe((calculateClaimSuccessfullyComplete) => {
        if (calculateClaimSuccessfullyComplete) {
          this.claimsService.onSuccessfulCalculate.next(true);
          this.successfulCalculate = true;
          // handle Hard and Soft Edits if there are any
          this.claimEditService.handleSoftAndHardEdits(this.claimsService.getActiveClaim());
          if (!this.claimEditService.hasHardEdit()) {
            this.claimEditService.clearSoftAndHardEditHighlights();
            this.messageService.showConfirmationSnackbar(`Claim calculated successfully`);
          } else {
            this.viewStateService.setCalculateFailed(true);
            calculateDialog.close();
          }
        } else {
          calculateDialog.close();
          this.viewStateService.setCalculateFailed(true);
        }
        // Close dialog for LoadingModalComponent - Spinner
        calculateDialog.close();

        // Calls the unmasking and re-focus on Charges field after the dialog is closed.
        // Its necessary to call it after the close, or else the focus will go back to the last
        // place before the Calculate Button was clicked.
        calculateDialog.afterClosed().subscribe(() => {
          this.unmaskCardsAndDisableStatus();
        });
      });
    }
  }

  onSubmitButtonClick(): void {
    this.viewStateService.setHasEdits(false);
    let hasError = false;
    const formElements = document.querySelectorAll('.error-messages, .mat-radio-group, .mat-form-field, .custom-select, .mat-checkbox, .ng-select');
    const formElementsWithErrors = document.querySelectorAll('.ng-invalid.mat-radio-group, .ng-invalid.mat-form-field, .ng-invalid.custom-select, .ng-invalid.mat-checkbox, .ng-invalid.ng-select');
    if (formElementsWithErrors.length > 0) {
      hasError = true;
      for (let i = 0; formElements.length > i; i++) {
        if (!formElements.item(i).hasAttribute('disabled')) {
          formElements.item(i).classList.add('touched');
          formElements.item(i).classList.add('ng-touched');
          formElements.item(i).classList.remove('ng-untouched');
        }
      }
      formElementsWithErrors.item(0).scrollIntoView({block: 'center', behavior: 'smooth'});
      this.messageService.showErrorSnackbar('Please complete all required fields below.');
    }
    if (!hasError) {
      this.submitClaim();
    } else {
      this.viewStateService.setHasUIEdits(true);
    }
  }

  submitClaim() {
    const labOrderInformation: LabOrderInformation = isNullOrUndefined(this.activeClaim.labOrderInformation) ? undefined : this.activeClaim.labOrderInformation;
    let labNumber: string = null;
    if (!isNullOrUndefined(labOrderInformation) && !isNullOrUndefined(labOrderInformation.labNumber)) {
      labNumber = labOrderInformation.labNumber;
    }
    const submitClaimPopup = this.dialog.open(SubmitClaimModalComponent, {
      width: '800px',
      data: labNumber,
      panelClass: 'eclaim-popup-modal'
    });
    submitClaimPopup.afterClosed().subscribe(result => {
      if (ApplicationConstants.modalSelectionOk === result) {
        this.updateTintColorDescription();
        this.maskCardsAndDisableStatus();
        // Calling LoadingModalComponent for Spinner (Preload Icon)
        // openDialog('Submitting Claim...', this.dialog);
        // Creating and opening a spinner dialog
        const submitDialog = this.dialog.open(LoadingModalComponent,
          {
            data: {
              modalMessageText: 'Submitting Claim...'
            },
            width: '250px',
            panelClass: 'loadingModal',
            disableClose: true
          });
        this.claimsService.submitClaim(this.activeClaim).subscribe(submitClaimSuccessfullyComplete => {
          if (submitClaimSuccessfullyComplete) {
            // handle Hard and Soft Edits if there are any
            this.claimEditService.handleSoftAndHardEdits(this.claimsService.getActiveClaim());
            if (!this.claimEditService.hasHardEdit() && !this.claimEditService.hasSoftEdit()) {
              this.claimEditService.clearSoftAndHardEditHighlights();
              this.messageService.showConfirmationSnackbar(`Claim submitted successfully`);
              this.claimFormNavigationService.isNavigationComingFromTheSubmitClaimModal = true;
              // Validate practice participates in KScope
              if (this.userSessionService.isKaleyedoscopePractice && this.fulfillmentService.participatesInKScope && this.doesActiveClaimContainFrameInformation(this.activeClaim)) {
                // Per ECLAIM-142 we want to send over the VSR number and Date of Service in a header to the Fulfilment api on a submit.
                this.fulfillmentDataService.vsrNumber = this.activeClaim.trackingNumber;
                this.fulfillmentDataService.vsrNumberDateOfService = DateUtility.buildYyyyMmDdDateFromDate(this.activeClaim.dateOfService);
                // fulfill the frame order after successful submission
                this.fulfillmentService.finalizeFrameFulfillment(this.buildFrameOrderRequest(this.activeClaim)).subscribe((orderFulfillmentHasBeenCompleted) => {
                  // Per ECLAIM-142 we want to reset values to undefined after subscription has finished as a just in case.
                  this.fulfillmentDataService.vsrNumber = undefined;
                  this.fulfillmentDataService.vsrNumberDateOfService = undefined;
                  // At this point the orderFulfillmentHasBeenCompleted observer value will always be true;
                  if (orderFulfillmentHasBeenCompleted) {
                    // We had to move the open successfully submitted claim modal to this location so that all the parameters needed to be set get set before opening modal.
                    this.claimFormNavigationService.openSuccessfullySubmittedClaimModal(this.activeClaim.trackingNumber);
                    // When navigation happens at this point we will have all the fields set we need in the fulfillment service to properly display in the correct message in the 'Successful Submitted Claim' modal.
                    this.router.navigate([ApplicationConstants.routing.secure.baseRoute, ApplicationConstants.routing.secure.eInsurance]);
                    submitDialog.close();
                  }
                });
              } else {
                this.claimFormNavigationService.openSuccessfullySubmittedClaimModal(this.activeClaim.trackingNumber);
                this.router.navigate([ApplicationConstants.routing.secure.baseRoute, ApplicationConstants.routing.secure.eInsurance]);
                submitDialog.close();
              }
            } else {
              this.viewStateService.setSubmitFailed(true);
              // Close dialog for LoadingModalComponent - Spinner
              this.dialog.closeAll();
            }
          } else {
            this.viewStateService.setSubmitFailed(true);
            // Close dialog for LoadingModalComponent - Spinner
            this.dialog.closeAll();
            if (this.messageService.isSubmitAndGatewayTimeOutHappenedInClaimForm) {
              // Per ECLAIM-104 we want to navigate the user out of the claim form if a gateway timeout happened on a submit.
              this.router.navigate([ApplicationConstants.routing.secure.baseRoute, ApplicationConstants.routing.secure.eInsurance]);
            }
          }

          this.unmaskCardsAndDisableStatus();
        });
      }
    });
  }

  /***** END - EVENT HANDLERS *****/

  /**
   * Method validates Active Claim Contains Frame Information.
   *
   * @param activeClaim - Active claim we want to validate contains frame information.
   */
  doesActiveClaimContainFrameInformation(activeClaim: Claim): boolean {
    const labOrderInformation: LabOrderInformation = isNullOrUndefined(activeClaim.labOrderInformation) ? undefined : activeClaim.labOrderInformation;
    return !isNullOrUndefined(labOrderInformation) && !isNullOrUndefined(labOrderInformation.frame) && !isNullOrUndefined(activeClaim.labOrderInformation.frame.options);
  }

  /**
   * Method builds Frame Order Request using a past in Active Claim.
   *
   * @param activeClaim - Active Claim we want to use to build the Frame Order Request.
   */
  buildFrameOrderRequest(activeClaim: Claim): FrameOrderRequest {
    const frameOrderRequest: FrameOrderRequest = new FrameOrderRequest();
    if (this.doesActiveClaimContainFrameInformation(this.claimsService.getActiveClaim())) {
      frameOrderRequest.setFrameSku = activeClaim.labOrderInformation.frame.options[0].sku;
      frameOrderRequest.setManufacturer = activeClaim.labOrderInformation.frame.manufacturer.name;
      frameOrderRequest.setOrderNumber = activeClaim.trackingNumber;
      frameOrderRequest.setExternalLabId = activeClaim.labOrderInformation.labNumber;
      frameOrderRequest.setNotes = activeClaim.patient.name.firstName + ' ' + activeClaim.patient.name.lastName;
    }
    return frameOrderRequest;
  }

}
