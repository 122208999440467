import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {ApplicationConstants} from '../../../../common/constants/application.constants';

@Component({
  selector: 'app-frame-source-modal',
  templateUrl: './frame-source-modal.component.html',
  styleUrls: ['./frame-source-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FrameSourceModalComponent {

  constructor(
    public dialogRef: MatDialogRef<FrameSourceModalComponent>,
  ) { }

  onUseDoctorSuppliedButtonClick() {
    this.dialogRef.close(true);
  }

  onUseOrderFromVendorButtonClick(): void {
    this.dialogRef.close(false);
  }

  onSeeProgramFAQsClick(): void {
    window.open(ApplicationConstants.kscopeProgramFAQsUrl, 'blank');
  }

}
