import {Injectable, Injector} from '@angular/core';
import {FrameAPIResponse} from '../../../../../../models/frame';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpClientService} from '../http-client-service';
import {ApplicationConstants} from '../../../../../constants/application.constants';
import {isStringNullUndefinedOrEmpty} from '../../../../../utility';

@Injectable({
  providedIn: 'root'
})
export class FramesDataService extends HttpClientService {

  constructor(protected injector: Injector) {
    super(injector, ApplicationConstants.dataServiceClientIDs.frameCatalogServiceDataService);
  }

  searchFrames(searchQuery: string): Observable<FrameAPIResponse> {
    const urlParameters = ApplicationConstants.api.frameSearchQueryParameter + searchQuery;
    return this.callFrameCatalog(urlParameters);
  }

  searchFramesUsingUpcAndSkuParameters(upc: string, sku: string): Observable<FrameAPIResponse> {
    const upcParameter = ApplicationConstants.api.frameSearchUpcParameter + upc;
    const skuParameter = ApplicationConstants.api.frameSearchSkuParameter + sku;
    const urlParameters = upcParameter + ApplicationConstants.ampersandSymbol + skuParameter;
    return this.callFrameCatalog(urlParameters);
  }

  callFrameCatalog(urlParameters: string): Observable<FrameAPIResponse> {
    const domain = (!isStringNullUndefinedOrEmpty(this.environment.frameCatalogUrl)) ? this.environment.frameCatalogUrl : this.environment.apiUrl;
    const processedUrl = `${domain}/${ApplicationConstants.api.frameSearchPartialEndpoint}${urlParameters}`;

    return this.http.get<FrameAPIResponse>(processedUrl, { headers: this.getHttpHeaders(ApplicationConstants.api.frameSearchPartialEndpoint)}).pipe(
      map((frameSearchResponse: FrameAPIResponse) => {
        return frameSearchResponse;
      }),
      catchError((error: any) => {
        // Per ECR-7226, if there is an error calling frame search, just return undefined.
        // No need to show error snack bar.
        return of(undefined);
      })
    );
  }
}
