import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {isStringNullUndefinedOrEmpty} from '../../utility';

@Component({
  selector: 'app-loading-dropdown',
  templateUrl: './loading-dropdown.component.html',
  styleUrls: ['./loading-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingDropdownComponent implements OnInit {

  constructor() { }

  @Input() leftOffset: string;
  @Input() rightOffset: string;
  @Input() topOffset: string;
  @Input() bottomOffset: string;

  ngOnInit() {
  }

  getLeftOffset(): string {
    return !isStringNullUndefinedOrEmpty(this.leftOffset) ? `${this.leftOffset}` : '0';
  }
  getRightOffset(): string {

    return !isStringNullUndefinedOrEmpty(this.rightOffset) ? `${this.rightOffset}` : '0';
  }
  getTopOffset(): string {
    return !isStringNullUndefinedOrEmpty(this.topOffset) ? `${this.topOffset}` : '0';
  }
  getBottomOffset(): string {
    return !isStringNullUndefinedOrEmpty(this.bottomOffset) ? `${this.bottomOffset}` : '0';
  }

}
