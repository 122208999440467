import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ApplicationConstants} from '../../../common/constants/application.constants';
import {Claim} from '../../../models/claim';
import {ClaimsService} from '../../../common/services/data-model/app/claims/claims.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-retrieve-submitted-claim-confirmation',
  templateUrl: './retrieve-submitted-claim-confirmation.component.html',
  styleUrls: ['./retrieve-submitted-claim-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetrieveSubmittedClaimConfirmationComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<RetrieveSubmittedClaimConfirmationComponent>,
    private claimsService: ClaimsService,
    private cookieService: CookieService
  ) {
  }

  private originalClaim: Claim;

  public vsrNumber;

  ngOnInit() {
    this.originalClaim = this.claimsService.getOriginalClaim();
    if (this.originalClaim) {
      this.vsrNumber = this.originalClaim.trackingNumber;
    }
  }

  public confirm() {
    this.dialogRef.close(ApplicationConstants.modalSelectionOk);
  }

  public cancel() {
    this.dialogRef.close();
    this.navigateToEInsurancePage();
  }

  onCloseClick(): void {
    this.dialogRef.close(false);
    this.navigateToEInsurancePage();
  }

  public navigateToEInsurancePage() {
    window.location.href = this.cookieService.get(ApplicationConstants.efSurlCookie) + ApplicationConstants.legacyEInsurancePageURL;
  }
}
