export interface ReferenceListViewModel {
  value: string;
  label: string;
}

export class ReferenceListValue {

  private readonly _value: string;
  private readonly _label: string;

  constructor(value$: string, label$: string) {
    this._value = value$;
    this._label = label$;
  }

  get viewModel(): ReferenceListViewModel {
    return {
      value: this._value,
      label: this._label
    } as ReferenceListViewModel;
  }
}
