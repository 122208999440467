import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApplicationConstants} from '../../../../common/constants/application.constants';
import {MatDialogRef} from '@angular/material/dialog';
import {CookieService} from 'ngx-cookie-service';
import {FulfillmentService} from '../../../../common/services/data-model/app/fulfillment/fulfillment.service';
import {UserSessionService} from '../../../../common/services/support/user-session/user-session.service';

@Component({
  selector: 'app-successful-submit-claim-modal',
  templateUrl: './successful-submit-claim-modal.component.html',
  styleUrls: ['./successful-submit-claim-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SuccessfulSubmitClaimModalComponent implements OnInit {

  practiceParticipatesInKScope: boolean;
  didDropShipOrReplacementCallFail: boolean;
  kScopeBannerTopMessage: string;
  kScopeBannerBottomMessage: string;

  constructor(
    private dialogRef: MatDialogRef<SuccessfulSubmitClaimModalComponent>,
    private cookieService: CookieService,
    private fulfillmentService: FulfillmentService,
    private userSessionService: UserSessionService
  ) {
  }

  ngOnInit() {
    this.practiceParticipatesInKScope = this.userSessionService.isKaleyedoscopePractice && this.fulfillmentService.participatesInKScope;
    this.didDropShipOrReplacementCallFail = this.fulfillmentService.frameDropShipOrderCallFailed || this.fulfillmentService.frameReplacementOrderCallFailed;
    if (this.fulfillmentService.wasFrameDropShipOrderCall) {
      this.setDefaultKScopeBannerMessage();
    } else if (this.fulfillmentService.wasFrameReplacementOrderCall) {
      this.kScopeBannerTopMessage = 'The frame has been successfully ordered from the vendor and';
      this.kScopeBannerBottomMessage = 'will be sent to your practice.';
    } else {
      // Default behavior will be the same as it was before.
      this.setDefaultKScopeBannerMessage();
    }
  }

  private setDefaultKScopeBannerMessage(): void {
    this.kScopeBannerTopMessage = 'The frame has been ordered from the vendor and the order was';
    this.kScopeBannerBottomMessage = 'successfully submitted to the lab.';
  }

  public closeDialog() {
    this.reSetFulfillmentServiceValues();
    this.dialogRef.close(ApplicationConstants.modalSelectionYes);
  }

  onCloseClick(): void {
    this.reSetFulfillmentServiceValues();
    window.location.href = this.cookieService.get(ApplicationConstants.efSurlCookie) + ApplicationConstants.legacyEInsurancePageURL;
    this.dialogRef.close(false);
  }

  /**
   * Method rests the needed KScope information in the fulfillment service back to its original values.
   *
   * THIS IS A JUST IN CASE SO WE DO NOT GET BURNED FOR SOME WEIRD ISSUES.
   *
   * @private
   */
  private reSetFulfillmentServiceValues() {
    if (this.practiceParticipatesInKScope) {
      this.fulfillmentService.frameDropShipOrderCallFailed = false;
      this.fulfillmentService.frameReplacementOrderCallFailed = false;
      this.fulfillmentService.wasFrameDropShipOrderCall = false;
      this.fulfillmentService.wasFrameReplacementOrderCall = false;
      this.fulfillmentService.participatesInKScope = false;
    }
  }
}
