import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CopayInfo, FeeInfo, ClaimSummary} from '../../../models/claimSummary';
import {
  DateUtility, formatNumberWithTwoDecimalPlaces,
  getFullNameFormattedInFirstMiddleAndLast,
  isNullOrUndefined,
  isStringNullUndefinedOrEmptyWithTrim
} from '../../../common/utility';
import {ApplicationConstants} from '../../../common/constants/application.constants';

@Component({
  selector: 'app-claims-tracking-claim-details-modal',
  templateUrl: 'claim-details-modal.component.html',
  styleUrls: ['claim-details-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClaimDetailsModalComponent {

  /***** START - PUBLIC MEMBERS *****/
  claimSummary: ClaimSummary;
  formattedPatientName: string;
  formattedCopayDisplay: string;
  formattedServiceDate: string;
  formattedPaidDate: string;
  areProfessionalFeesAvailable: boolean = false;
  areMaterialFeesAvailable: boolean = false;
  totalProfessionalFeesAmount: number;
  totalMaterialFeesAmount: number;
  totalCopayAmount: number;
  totalPayableAmount: number;
  defaultTotalAmount: any = ApplicationConstants.zeroStringTwoDecimalPlaces;
  claimStatus: string;
  isPaidClaimSummary: boolean;

  /***** END - PUBLIC MEMBERS *****/

  constructor(
    private dialogRef: MatDialogRef<ClaimDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    // Set the claim status so it can be used
    this.claimStatus = this.data.claimSummary.statusCodeDescription;
    this.isPaidClaimSummary = this.data.claimSummary.statusCodeDescription === 'Paid';
    // Needed to use the generic data type 'any' in order to get to the ClaimSummary object
    this.claimSummary = this.data.claimSummary;
    this.formattedPatientName = getFullNameFormattedInFirstMiddleAndLast(
                                    this.claimSummary.memberPatientInfo.patientFirstName,
                                    this.claimSummary.memberPatientInfo.patientLastName,
                                    this.claimSummary.memberPatientInfo.patientMiddleInitial
                                );
    this.formattedCopayDisplay = this.buildCopayDisplay(this.claimSummary.copayInfo);
    this.formattedServiceDate = DateUtility.buildFriendlyDateFromJsDate(this.claimSummary.dateOfService);
    this.formattedPaidDate = DateUtility.buildFriendlyDateFromJsDate(this.claimSummary.paidDate);
    const feeInfo: FeeInfo = this.claimSummary.feeInfo;
    if (!isNullOrUndefined(feeInfo)) {
      this.areProfessionalFeesAvailable = (!isNullOrUndefined(feeInfo.professionalFees) && feeInfo.professionalFees.length > 0);
      this.areMaterialFeesAvailable = (!isNullOrUndefined(feeInfo.materialFees) && feeInfo.materialFees.length > 0);
    }
    this.totalProfessionalFeesAmount = (!isNullOrUndefined(feeInfo) && !isNullOrUndefined(feeInfo.totalProfessionalFeeAmountPerClaim)) ? this.formatAmount(feeInfo.totalProfessionalFeeAmountPerClaim) : this.defaultTotalAmount;
    this.totalMaterialFeesAmount = (!isNullOrUndefined(feeInfo) && !isNullOrUndefined(feeInfo.totalMaterialFeeAmountPerClaim)) ? this.formatAmount(feeInfo.totalMaterialFeeAmountPerClaim) : this.defaultTotalAmount;
    const copayInfo: CopayInfo = this.claimSummary.copayInfo;
    this.totalCopayAmount = (!isNullOrUndefined(copayInfo) && !isNullOrUndefined(copayInfo.totalCopayAmountPerClaim)) ? this.formatAmount(copayInfo.totalCopayAmountPerClaim) : this.defaultTotalAmount;
    this.totalPayableAmount = !isNullOrUndefined(this.claimSummary.totalEstimatedPayableAmountPerClaim) ? this.formatAmount(this.claimSummary.totalEstimatedPayableAmountPerClaim) : this.defaultTotalAmount;
  }

  /***** START - PUBLIC METHODS *****/

  public closeButton() {
    this.onCloseClick();
  }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }

  public formatAmount(numberToFormat: number): string {
    return formatNumberWithTwoDecimalPlaces(numberToFormat);
  }

  public buildCopayDisplay(copayInfo: CopayInfo): string {
    let copayDisplay: string = 'None';
    if (!isNullOrUndefined(copayInfo) && !isNullOrUndefined(copayInfo.copays) && copayInfo.copays.length > 0) {
      const copayDisplayList: string[] = [];
      for (const copay of copayInfo.copays) {
        const copayDisplayInSet: string = copay.copayDisplay;
        if (!isStringNullUndefinedOrEmptyWithTrim(copayDisplayInSet)) {
          copayDisplayList.push(copayDisplayInSet.trim());
        }
      }
      copayDisplay = copayDisplayList.join(ApplicationConstants.pipeWithLeadingAndEndingSpace);
    }
    return copayDisplay;
  }

  // TODO: Phase 2: Uncomment this peace of logic to check if amount passed in is a negative numbers.
  // isNegativeNumber(number: number): boolean {
  //   return Math.sign(number) === -1;
  // }

  /***** END - PUBLIC METHODS *****/

}
