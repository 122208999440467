<div fxLayout="column">
  <form novalidate [formGroup]="memberSearchForm" (ngSubmit)="onSearchButtonClick()">
    <mat-card>
      <mat-card-header id="lookupMemberSearch">
        <mat-card-title>
          <h1 id="member-search-header">Member Search</h1>
        </mat-card-title>
      </mat-card-header>

      <mat-card-content fxLayout="column">
        <div fxLayout="row" fxFlexOffset="30px">
          <!-- Date of Service -->
          <div fxLayout="column" class="nested-column-3">
            <label id="member-search-dos-label"><span class="required-label-asterisk">* </span>Date of Service</label>
            <app-date-picker class="required-mat-form-field" id="member-search-dos-date-picker"
                             [datePickerConfiguration]="datePickerConfigurationDateOfService"></app-date-picker>
          </div>
          <!-- First Name Control -->
          <div fxLayout="column" class="nested-column-3">
            <label id="member-search-first-name-label">First Name</label>
            <mat-form-field appearance="outline">
              <input matInput id="member-search-first-name" formControlName="firstName" type="text" class="form-control"
                     maxlength="20" (keyup)="trimNameControl(this.memberSearchForm.get('firstName'))"/>
            </mat-form-field>
            <app-error-wrapper id="member-search-first-name-error"
                               [config]="errorWrapperConfig.memberFirstName"></app-error-wrapper>
          </div>
          <!-- Last Name Control -->
          <div fxLayout="column" class="nested-column-3">
            <label id="member-search-last-name-label">Last Name</label>
            <mat-form-field appearance="outline">
              <input matInput id="member-search-last-name" formControlName="lastName" type="text" class="form-control"
                     maxlength="30" (keyup)="trimNameControl(this.memberSearchForm.get('lastName'))"
                     input-message="value:Please enter a valid Member's Last Name;
                              name:An invalid name has been entered. Please supply the Member's Last Name"/>
            </mat-form-field>
            <app-error-wrapper id="member-search-last-name-error"
                               [config]="errorWrapperConfig.memberLastName"></app-error-wrapper>
          </div>
          <!-- Date of Birth Control -->
          <div fxLayout="column" class="nested-column-3">
            <label id="member-search-dob-label">Date of Birth</label>
            <app-date-picker id="member-search-dob-date-picker"
                             [datePickerConfiguration]="datePickerConfigurationDateOfBirth"></app-date-picker>
          </div>
        </div>
        <!-- Member ID Section -->
        <div fxLayout="row" fxFlexOffset="30px" class="nested-column-3">
          <!-- Member ID Section Label -->
          <label class="margin-bottom-10 member-id" id="member-search-member-id-label">Member ID</label>
        </div>
        <!--Member ID Description -->
        <div fxLayout="row" class="card-body-text nested-column-12 margin-bottom-20">
          <label>Please search by the last 4 of the SSN or the Full ID</label>
        </div>
        <!--Last 4 SSN -->
        <div fxLayout="row" class="member-search-id-group-background">
          <div fxLayout="column" class="nested-column-3">
            <label class="margin-bottom-5" id="member-search-last-four-label">Last 4 SSN</label>
            <mat-form-field appearance="outline">
              <input matInput id="member-search-last-four" #lastFour formControlName="lastFour" type="text"
                     class="form-control"
                     maxlength="4" minlength="4"/>
            </mat-form-field>
            <app-error-wrapper id="member-search-last-four-error"
                               [config]="errorWrapperConfig.lastFour"></app-error-wrapper>
          </div>

          <!--Full ID -->
          <div fxLayout="column" class="nested-column-3">
            <label class="margin-bottom-5" id="member-search-full-id-label">Full ID</label>
            <mat-form-field appearance="outline">
              <input matInput id="member-search-full-id" formControlName="memberId" type="text"
                     class="form-control full-width" maxlength="30">
            </mat-form-field>
            <label class="or-text" id="member-search-or-label">or</label>
            <app-error-wrapper id="member-search-full-id-error"
                               [config]="errorWrapperConfig.memberId"></app-error-wrapper>
          </div>
        </div>

        <div fxLayout="row" fxFlexOffset="30px">
          <!-- Valid Search Combinations Link -->
          <div fxLayout="column" class="nested-column-3">
            <p class="action-link" (click)="onValidSearchCombinationsLinkClick()"
               id="member-search-valid-search-combinations-link">Valid Search Combinations</p>
          </div>
          <!-- spacer -->
          <div class="nested-column-5"></div>
          <!-- Reset Button -->
          <div fxLayout="column" class="nested-column-2">
            <button id="member-search-reset-button" type="button" class="form-control secondary-cta-button"
                    (click)="onResetButtonClick()">Reset
            </button>
          </div>
          <!-- Search Button -->
          <div fxLayout="column" class="margin-bottom-20 nested-column-2">
            <button mat-flat-button id="member-search-search-button" class="form-control search-button mat-flat-button mat-primary" type="submit"
                    input-message-trigger [disabled]="!hasValidSearchCombination">Search
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  <div fxLayout="row" fxFlexOffset="20px">
    <label class="eclaim-body-text margin-left-15" id="member-search-required-field">
      <span class="required-label-asterisk">* </span>Required Field</label>
  </div>


  <!-- Search Results Section -->
  <div [hidden]="!searchPerformed" fxFlexOffset="30px" class="margin-top-30" fxLayout="column"
       id="member-search-search-results-html-div">

    <mat-card>

      <!-- Search Results Section Header -->
      <mat-card-header>
        <mat-card-title>
          <h1 class="margin-bottom-0" id="member-search-search-results-header">Search Results</h1>
        </mat-card-title>
      </mat-card-header>

      <!-- Members Table -->
      <mat-table [dataSource]="dataSource" id="member-search-result-table" matSort>

        <!-- Name of patient -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef id="member-search-result-name-header" class="nested-column-3"
                           mat-sort-header>Name
          </mat-header-cell>
          <mat-cell *matCellDef="let member" id="member-search-result-name-data" class="action-link nested-column-3"
                    (click)="onMemberClick(member)">
            {{member.memberName}}
          </mat-cell>
        </ng-container>


        <!-- Date of Birth of patient -->
        <ng-container matColumnDef="dateOfBirth">
          <mat-header-cell *matHeaderCellDef id="member-search-result-dob-header" class="nested-column-2"
                           >Date of Birth
          </mat-header-cell>
          <mat-cell *matCellDef="let member; index as i" class="nested-column-2"
                    id="member-search-result-dob-data-{{i}}">{{member.dateOfBirth}}</mat-cell>
        </ng-container>

        <!-- City, State of patient -->
        <ng-container matColumnDef="cityState">
          <mat-header-cell *matHeaderCellDef id="member-search-result-city-state-header" class="nested-column-2">City,
            State
          </mat-header-cell>
          <mat-cell *matCellDef="let member" class="nested-column-2"
                    id="member-search-result-city-state-data">{{member.cityState}}</mat-cell>
        </ng-container>

        <!-- Client Name of patient-->
        <ng-container matColumnDef="clientName">
          <mat-header-cell *matHeaderCellDef id="member-search-result-client-name-header" class="nested-column-5">Client
            Name
          </mat-header-cell>
          <mat-cell *matCellDef="let member" class="nested-column-5"
                    id="member-search-result-client-name-data">{{member.clientInfo.clientName}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></mat-row>

      </mat-table>

      <!-- No Results Found -->
      <div *ngIf="dataSource != null && dataSource.data.length === 0" class="no-records"
           fxLayout="row" fxLayoutAlign="center center" id="member-search-no-result">No Results Found</div>

    </mat-card>

    <!-- Note for Date of Birth -->
    <div class="margin-left-15" fxLayout="row" fxFlexOffset="20px">
      <label class="footer-text" id="member-search-birth-year-omitted-label">For member privacy, the birth year is
        omitted.</label>
    </div>

  </div>

</div>
