<div fxLayout="column">
  <form [formGroup]="hcpcCptCodeDescriptionForm" fxFill>
    <mat-card>

      <!-- ############################################################################################################################################################################## -->
      <!-- Header -->
      <!-- ############################################################################################################################################################################## -->
      <mat-card-header>
        <mat-card-title>
          <h1 class="nested-column-11" id="hcpc-cpt-codes-description-modal-header-title" mat-dialog-title>HCPCS/CPT Description</h1>
        </mat-card-title>
        <!-- Close Icon -->
        <button [disableRipple]="true" class="close-button" mat-icon-button>
          <mat-icon id="hcpc-cpt-codes-description-modal-close-icon" (click)="onCloseClick()" svgIcon="eclaim-icons:close"></mat-icon>
        </button>
      </mat-card-header>

      <!-- ############################################################################################################################################################################## -->
      <!-- Body -->
      <!-- ############################################################################################################################################################################## -->
      <mat-card-content>

        <!-- ############################################################################################################# -->
        <!-- First Section - Modal Body Header -->
        <!-- ############################################################################################################# -->
        <div fxLayout="row">
          <h2 class="eclaim-body-text-bold" id="hcpc-cpt-codes-description-modal-body-header-title" mat-dialog-title>HCPCS/CPT Description(s)</h2>
        </div>

        <!-- ############################################################################################################# -->
        <!-- Second Section - Display Existing HCPC/CPT Codes in the Service Lines if they Exist -->
        <!-- ############################################################################################################# -->

        <div fxLayout="row" *ngIf="doServiceLinesContainHcpcOrCptCodes" [ngClass]="doServiceLinesContainHcpcOrCptCodes ? 'margin-bottom-30' : 'margin-bottom-0'">
          <div class="nested-column-12">
            <div *ngFor="let inventory of inventories.inventory; index as baseId">
              <div fxLayout="row" id="hcpc-cpt-codes-description-modal-hcpc-cpt-code-and-description-{{baseId}}">
                <mat-list-item fxLayoutAlign="end" class="custom-nested-column-1_5" id="hcpc-cpt-codes-description-modal-hcpc-cpt-code-{{baseId}}">{{inventory.serviceCodes.code}}</mat-list-item>
                <mat-list-item fxLayoutAlign="center" class="custom-nested-column-0_5" id="hcpc-cpt-codes-description-modal-hcpc-cpt-code-separator-{{baseId}}">-</mat-list-item>
                <mat-list-item fxLayoutAlign="start" class="custom-nested-column-9" id="hcpc-cpt-codes-description-modal-hcpc-cpt-code-description-{{baseId}}">{{inventory.description}}</mat-list-item>
              </div>
            </div>
          </div>
        </div>

        <!-- ################################################## -->
        <!-- Third Section - Row Divider -->
        <!-- ################################################## -->
        <div fxLayout="row" class="nested-column-12">
          <mat-divider></mat-divider>
        </div>

        <!-- ############################################################################################################# -->
        <!-- Fourth Section - Search HCPC/CPT Codes -->
        <!-- ############################################################################################################# -->

        <!-- ################################################## -->
        <!-- Enter HCPCS/CPT Code Label -->
        <!-- ################################################## -->

        <div fxLayout="row" class="nested-column-12 margin-top-30">
          <label class="eclaim-body-text"
                 id="hcpc-cpt-codes-description-modal-enter-hcpc-cpt-code-label"><span class="required-label-asterisk">* </span>Enter HCPCS/CPT Code</label>
        </div>

        <!-- ################################################## -->
        <!-- Input Field and Search Button -->
        <!-- ################################################## -->

        <div fxLayout="row" class="margin-top-15">
          <!-- HCPCS/CPT Code Search Field -->
          <div class="nested-column-6">
            <mat-form-field appearance="outline" class="required-mat-form-field">
              <input matInput
                     id="hcpc-cpt-codes-description-modal-enter-hcpc-cpt-code-input-field"
                     placeholder="Enter HCPCS/CPT Code"
                     formControlName="hcpcCptCode"
                     (keydown.enter)="getDescriptions()"
                     maxlength="5"/>
            </mat-form-field>
            <app-error-wrapper id="hcpc-cpt-codes-description-modal-enter-hcpc-cpt-code-input-field-error" [config]="errorWrapperConfig.hcpcCptCode"></app-error-wrapper>
            <app-error-wrapper id="hcpc-cpt-codes-description-modal-enter-hcpc-cpt-code-input-field-required-error" [config]="errorWrapperConfig.hcpcCptCodeRequired"></app-error-wrapper>
          </div>

          <!-- HCPCS/CPT Code Search Button -->
          <div class="nested-column-4">
            <button (click)="getDescriptions()" mat-flat-button
                    id="hcpc-cpt-codes-description-modal-enter-hcpc-cpt-code-search-button"
                    class="search-button mat-flat-button mat-primary"
                    [disabled]="!isSearchCombinationValid()">Get Description</button>
          </div>

        </div>

        <!-- ############################################################################################################# -->
        <!-- Sixth Section - Search HCPC/CPT Code Results -->
        <!-- ############################################################################################################# -->

        <!-- ################################################## -->
        <!-- This is the Spinner section -->
        <!-- ################################################## -->

        <div fxLayout="row" *ngIf="hasSearchBegan">
          <div class="custom-nested-column-12">
            <div fxLayout="row">
              <mat-spinner [diameter]="50"></mat-spinner>
              <p style="margin-top: 15px; margin-bottom: 15px; margin-left: 15px">Searching for code...</p>
            </div>
          </div>
        </div>

        <!-- ################################################## -->
        <!-- This is the Results section -->
        <!-- ################################################## -->

        <div fxLayout="row" *ngIf="hasHcpcOrCptCodeSearchCompleted" [ngClass]="hasHcpcOrCptCodeSearchCompleted ? 'margin-top-30' : 'margin-top-0'">
          <div class="nested-column-12">
            <div fxLayout="row" id="hcpc-cpt-codes-description-modal-hcpc-cpt-code-and-description-search-result">
              <mat-list-item fxLayoutAlign="end" class="custom-nested-column-1_5" id="hcpc-cpt-codes-description-modal-hcpc-cpt-code-search-result">{{inventory.serviceCodes.code}}</mat-list-item>
              <mat-list-item fxLayoutAlign="center" class="custom-nested-column-0_5" id="hcpc-cpt-codes-description-modal-hcpc-cpt-code-separator-search-result">-</mat-list-item>
              <mat-list-item fxLayoutAlign="start" class="custom-nested-column-9" id="hcpc-cpt-codes-description-modal-hcpc-cpt-code-description-search-result">{{inventory.description}}</mat-list-item>
            </div>
          </div>
        </div>

      </mat-card-content>

      <!-- ############################################################################################################################################################################## -->
      <!--Footer Line-->
      <!-- ############################################################################################################################################################################## -->
      <hr/>

      <!-- ############################################################################################################################################################################## -->
      <!-- Close button -->
      <!-- ############################################################################################################################################################################## -->
      <mat-card-actions>
        <div fxLayout="row" fxFlexOffset="30px" fxLayoutAlign="end center"
             class="margin-top-20 margin-bottom-20 claim-details-modal">
          <button mat-button class="btn btn-primary btn-extra-pad" id="hcpc-cpt-codes-description-modal-close-button" mat-dialog-close>Close</button>
          <div fxFlexOffset="30px"></div>
        </div>
      </mat-card-actions>

    </mat-card>
  </form>
</div>

