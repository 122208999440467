import { Injectable, Injector } from '@angular/core';
import {
  AddDependent,
  BusinessMemberPoliciesResults, FederalEnrolleeHealthBenefitPlan,
  SubscriberSearchResult
} from '../../../../../../models/consumer';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { ApplicationConstants } from '../../../../../constants/application.constants';
import { HttpClientService } from '../http-client-service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ConsumerSearchRequest } from '../../../../../../models/ConsumerSearchRequest';
import {getApiUrl, isStringNullUndefinedOrEmpty} from '../../../../../utility';

@Injectable({
  providedIn: 'root'
})
export class ConsumerDataService extends HttpClientService {

  constructor(protected injector: Injector) {
    super(injector, ApplicationConstants.dataServiceClientIDs.consumerDataService);
  }

  /***** START - PRIVATE FUNCTIONS *****/
  public getMemberPolicySearchUrl(): string {
    const domain = (!isStringNullUndefinedOrEmpty(this.environment.consumerUrl)) ? this.environment.consumerUrl : this.environment.apiUrl;
    return `${domain}/${ApplicationConstants.api.consumerSearchApiWeb}/subscribersearch`;
  }
  /***** START - END FUNCTIONS *****/


  /***** START - PUBLIC FUNCTIONS *****/
  searchMembers(searchQueryObject: ConsumerSearchRequest): Observable<SubscriberSearchResult> {
    return this.http.post(this.getMemberPolicySearchUrl(), searchQueryObject, { headers: this.getHttpHeaders(ApplicationConstants.api.consumerSearchApiWeb)})
      .pipe(
        map((memberSearchApiResult: SubscriberSearchResult) => {
          // TODO move data interrogation logic into data layer
          if (memberSearchApiResult) {
            this.log('Member search successful from the API');
            return memberSearchApiResult;
          } else {
            this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
            return undefined;
          }
        }),
        catchError((error: any) => {
          const errorMessage = ApplicationConstants.errorMessages.genericApiFailMessage;
          this.handleError('searchMembers', error);
          this.messageService.showErrorSnackbar(`${errorMessage}`);
          return of(undefined);
        })
      );
  }

  retrieveMember(memberRetrievePartialUrl: string): Observable<BusinessMemberPoliciesResults> {
    const memberRetrieveHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Accept': 'application/json;version=6.3',
      'detail': ApplicationConstants.api.consumerBusinessApiDetailHeader
    });
    memberRetrievePartialUrl = getApiUrl(memberRetrievePartialUrl, this.environment.consumerUrl);
    return this.http.get(memberRetrievePartialUrl, { headers: memberRetrieveHeaders })
      .pipe(
        map((businessMemberRetrieveApiResult: BusinessMemberPoliciesResults) => {
          // TODO move data interrogation logic into data layer
        if (businessMemberRetrieveApiResult) {
            this.log('Member retrieve successful from the API');
            return businessMemberRetrieveApiResult;
          } else {
            this.messageService.showErrorSnackbar(ApplicationConstants.errorMessages.genericApiFailMessage);
            return undefined;
          }
        }),
        catchError((error: any) => {
          const errorMessage = this.getErrorMessage(error);
          this.handleError('retrieveMember', error);
          this.messageService.showErrorSnackbar(`${errorMessage}`);
          return of(undefined);
        })
      );
  }

  retrieveAddDependentsLink(businessMemberRetrieveResults: string): Observable<BusinessMemberPoliciesResults> {
    businessMemberRetrieveResults = getApiUrl(businessMemberRetrieveResults, this.environment.consumerUrl);
    return this.http.get<BusinessMemberPoliciesResults>(businessMemberRetrieveResults, { params: new HttpParams().set('showntd', 't'),  headers: this.getHttpHeaders(ApplicationConstants.api.consumerBusinessApi, true)})
      .pipe(
        map((businessMemberResults: BusinessMemberPoliciesResults) => {
          // TODO move data interrogation logic into data layer
          if (businessMemberResults) {
            this.log('Business member policy for add dependents link successful from the API');
            return businessMemberResults;
          } else {
            throw new Error(ApplicationConstants.errorMessages.genericApiFailMessage);
          }
        }),
        catchError((error: any) => {
          const errorMessage = this.getErrorMessage(error);
          this.handleError('retrieveAddDependentsLink', error);
          this.messageService.showErrorSnackbar(`${errorMessage}`);
          return of(undefined);
        })
      );
  }

  retrieveClientWebFEHBPlan(clientWebFEHBPlanUrl: string): Observable<FederalEnrolleeHealthBenefitPlan> {
    const fehbPlanRetrieveHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Accept': 'application/json;version=5.4'
    });
    clientWebFEHBPlanUrl = getApiUrl(clientWebFEHBPlanUrl, this.environment.clientWebUrl);
    return this.http.get(clientWebFEHBPlanUrl, { headers: fehbPlanRetrieveHeaders })
      .pipe(
        map((federalEnrolleeHealthBenefitPlan: FederalEnrolleeHealthBenefitPlan) => {
          // TODO move data interrogation logic into data layer
          if (federalEnrolleeHealthBenefitPlan) {
            this.log('Member federal health benefit plan from the client-web API');
            return federalEnrolleeHealthBenefitPlan;
          } else {
            return undefined;
          }
        }),
        catchError((error: any) => {
          // We don't care if we receive an error and we let it go as if not an FedVIP patient
          return of(undefined);
        })
      );
  }

  addDependent(addDependentLink: string, addDependentPayload: AddDependent): Observable<string> {
    // User header is required for the add dependent operation, value corresponds to application making the call (per consumer team)
    const addDependentHeaders = new HttpHeaders({
      'user': ApplicationConstants.applicationId,
      'Accept': 'application/json;version=5.4'
    });
    addDependentLink = getApiUrl(addDependentLink, this.environment.consumerUrl);
    return this.http.post<BusinessMemberPoliciesResults>(addDependentLink, addDependentPayload, { headers: addDependentHeaders })
      .pipe(
        map((addDependentApiResult: BusinessMemberPoliciesResults) => {
          // TODO move data interrogation logic into data layer
          if (addDependentApiResult) {
            this.log('Add dependent successful from the API');
            return addDependentApiResult.consumerId;
          } else {
            throw new Error(ApplicationConstants.errorMessages.genericApiFailMessage);
          }
        }),
        catchError((error: any) => {
          const errorMessage = this.getErrorMessage(error);
          this.handleError('addDependent', error);
          this.messageService.showErrorSnackbar(`${errorMessage}`);
          return of(undefined);
        })
      );
  }
  /***** END - PUBLIC FUNCTIONS *****/
}
